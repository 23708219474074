// @flow

import { NotificationSystem } from '@r1/ui-kit'
import type { WebUiApi as ManufacturerManagementApi } from '../api/manufacturerManagement/types'
import { processResponse } from '../components/utils/webUi'

const manufacturerManagementHandler = response => {
  // eslint-disable-next-line default-case
  switch (response.status) {
    case 200:
      return response.body
    case 409:
      NotificationSystem.addNotification({
        level: 'error',
        message: response.body.defaultFormat,
      })
  }
  throw new Error('error')
}

export const manufacturerManagementService = (api: ManufacturerManagementApi) => {
  const createManufacturer = async (name: string) =>
    api
      .createManufacturer({ name })
      .then(processResponse)
      .then(manufacturerManagementHandler)
      .then(response => {
        NotificationSystem.addNotification({
          title: `${name} manufacturer was created successfully`,
          level: 'success',
        })
        return response
      })

  const deleteManufacturer = async (manufacturerId: string, deleteFromProductId: string) => {
    return api
      .deleteManufacturer({ manufacturerId }, { deleteFromProductId })
      .then(processResponse)
      .then(manufacturerManagementHandler)
      .then(response => {
        NotificationSystem.addNotification({
          title: `Manufacturer was deleted successfully`,
          level: 'success',
        })
        return response
      })
  }

  const createModel = async ({
    manufacturerId,
    modelName,
  }: {
    manufacturerId: string,
    modelName: string,
  }) =>
    api
      .createModel({ manufacturerId, modelName })
      .then(processResponse)
      .then(manufacturerManagementHandler)
      .then(response => {
        NotificationSystem.addNotification({
          title: `${modelName} model was created successfully`,
          level: 'success',
        })
        return response
      })

  const deleteModel = async (modelId: string, productId: string) =>
    api
      .deleteModel({ modelId }, { deleteFromProductId: productId })
      .then(processResponse)
      .then(manufacturerManagementHandler)
      .then(response => {
        NotificationSystem.addNotification({
          title: `Model was deleted successfully`,
          level: 'success',
        })
        return response
      })

  return {
    createManufacturer,
    deleteManufacturer,
    createModel,
    deleteModel,
  }
}
