import React from 'react'
import { ReportGroup } from '@r1-webui/reports-v1'
import { ReportGroupCard } from './ReportGroupCard'
import { CardsLayout } from './CardsLayout'

type Props = {
  reportGroups: ReportGroup[]
}

export function ReportGroupsPage({ reportGroups }: Props) {
  return (
    <CardsLayout>
      {reportGroups.map(({ id, name, reportsCount, description }) => (
        <ReportGroupCard
          key={id}
          id={id}
          name={name}
          reportsCount={reportsCount}
          description={description}
        />
      ))}
    </CardsLayout>
  )
}
