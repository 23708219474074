import * as React from 'react'
import { memo } from 'react'
import { FormManager } from '@r1/form-manager'
import {
  Flex,
  Box,
  ControlGroup,
  Button,
  Select,
  Link,
  Icon,
  Placeholder,
  Label,
  // $FlowFixMe
  SortableContainer,
  // $FlowFixMe
  SortableElement,
  // $FlowFixMe
  SortableHandle,
  BottomButtonsBar,
} from '@r1/ui-kit'
import { drawerWidth } from '../constants/common'
import { useDebounce } from '../../../../../utils/hooks/useDebounce'
import { Footer, Option, Sortable, Priority, DeleteBlock, RulesContainer } from './Common'

export const RulesForm = memo(props => {
  const { rule, info, errors, initialValues, initialRule, onClose, onSubmit } = props
  const debounce = useDebounce(100)

  if (initialValues.options) {
    initialValues.options = initialValues.options.sort((a, b) =>
      a.priority === b.priority ? 0 : Number(a.priority > b.priority) || -1,
    )
  }

  return (
    <Flex column minWidth={drawerWidth} maxWidth={drawerWidth}>
      {rule.loading || !initialValues.options ? (
        <Flex column px="XXL">
          <Placeholder type="form" />
        </Flex>
      ) : (
        <FormManager initialValues={initialValues} onSubmit={onSubmit}>
          {({ values, push, remove, isSubmitting, handleChange, handleSubmit }) => {
            const selectedIds = values.options.map(({ globalRuleId }) => globalRuleId)
            const vacantOpt = info.filter(({ priceRuleId }) => !selectedIds.includes(priceRuleId))
            const mappedOptions = info.reduce(
              (acc, val) => ({ ...acc, [val.priceRuleId]: val }),
              {},
            )
            const bottomActionButtons = [
              {
                title: 'Cancel',
                align: 'right',
                color: 'secondary',
                transparent: true,
                onClick: onClose,
              },
              {
                title: 'Save',
                align: 'right',
                loading: isSubmitting,
                disabled:
                  values.options.length === 0 ||
                  values.options.find(({ globalRuleId }) => !globalRuleId),
                onClick: handleSubmit,
              },
            ]

            const sortableItems = values.options.map(option => ({
              ...option,
              id: option.globalRuleId,
            }))

            return (
              <Flex column justify="space-between" basis={1}>
                <RulesContainer column px="XXL">
                  <Box pb="M">
                    <Link
                      onClick={() =>
                        push('options', {
                          ...initialRule,
                          priority:
                            values.options.reduce((acc, val) => Math.max(acc, val.priority), 0) + 1,
                          globalRuleId: values.options.length + 1,
                        })
                      }
                    >
                      + Add Price Option
                    </Link>
                  </Box>
                  <Box pt="S" pb="S">
                    {(values.options.length === 0 || errors.globalRuleId) && (
                      <Label type="error">minimum 1 option required</Label>
                    )}
                  </Box>
                  <SortableContainer
                    isSortableHandleUsed
                    lockAxis="y"
                    items={sortableItems}
                    onSortEnd={({ oldIndex, newIndex }) => {
                      const { options } = values

                      options.splice(newIndex, 0, options.splice(oldIndex, 1)[0])

                      options.map((item, index) => ({ ...item, priority: index + 1 }))
                      handleChange(['options'])(options)
                    }}
                  >
                    <div>
                      {values.options.map((option, optionIndex) => (
                        <SortableElement
                          key={option.globalRuleId}
                          id={option.globalRuleId}
                          index={optionIndex}
                        >
                          <Option>
                            <SortableHandle>
                              <Sortable>
                                <Icon type="drag" />
                              </Sortable>
                            </SortableHandle>
                            <Priority>{optionIndex + 1}</Priority>
                            <Box minWidth={280} maxWidth={280} mr="M">
                              <Select
                                clearable={false}
                                filterable={false}
                                value={mappedOptions[option.globalRuleId] || ''}
                                options={
                                  option.globalRuleId && mappedOptions[option.globalRuleId]
                                    ? [mappedOptions[option.globalRuleId]].concat(vacantOpt)
                                    : vacantOpt
                                }
                                error={!mappedOptions[option.globalRuleId]}
                                valueKey="priceRuleId"
                                labelKey="name"
                                onChange={globalRuleId => {
                                  handleChange(['options', optionIndex, 'globalRuleId'])(
                                    globalRuleId,
                                  )
                                }}
                              />
                            </Box>
                            <Box minWidth={100} maxWidth={280}>
                              <ControlGroup disabled={!option.globalRuleId}>
                                <ControlGroup.NumericInput
                                  value={option.percentage}
                                  min={0}
                                  max={10000}
                                  width={100}
                                  onChange={value =>
                                    debounce(() =>
                                      handleChange(['options', optionIndex, 'percentage'])(value),
                                    )
                                  }
                                />
                                <ControlGroup.Label>%</ControlGroup.Label>
                              </ControlGroup>
                            </Box>
                            <DeleteBlock>
                              <Button
                                transparent
                                color="secondary"
                                onClick={() => remove('options', optionIndex)}
                              >
                                <Icon type="trash" />
                              </Button>
                            </DeleteBlock>
                          </Option>
                        </SortableElement>
                      ))}
                    </div>
                  </SortableContainer>
                </RulesContainer>
                <Footer px="XL" py="L">
                  <Flex justify="flex-end" basis={1}>
                    <BottomButtonsBar bottomActionButtons={bottomActionButtons} />
                  </Flex>
                </Footer>
              </Flex>
            )
          }}
        </FormManager>
      )}
    </Flex>
  )
})
