// @flow

import React from 'react'

import type { OrderInfoProps } from '../../types/ClaimCreation'
import { OrderInfoWrapper } from './StyledComponents'
import { MarketplaceInfoBlock } from './Blocks/MarketplaceInfoBlock'
import { OrderInfoBlock } from './Blocks/OrderInfoBlock'
import { CustomerInfoBlock } from './Blocks/CustomerInfoBlock'

export const OrderInfo = (props: OrderInfoProps) => {
  const { order, rmaProfile, orderStatus, shippingAddress } = props
  const {
    createdOn,
    purchaseOrderId,
    externalId: marketplaceOrderId,
    orderTotal,
    shippingInfo,
  } = order
  const {
    sellerName: marketplaceName,
    venueTypeInfo: { svgId: marketplaceSvgId },
  } = rmaProfile

  return (
    <OrderInfoWrapper mb="XXL">
      <MarketplaceInfoBlock
        marketplaceName={marketplaceName}
        marketplaceSvgId={marketplaceSvgId || ''}
      />
      <OrderInfoBlock
        marketplaceOrderId={marketplaceOrderId || ''}
        purchaseOrderId={purchaseOrderId}
        createdOn={createdOn}
        orderStatus={orderStatus}
        orderTotal={orderTotal}
      />
      <CustomerInfoBlock
        fullName={shippingInfo.fullName || ''}
        email={shippingInfo.email || ''}
        phone={shippingInfo.phone || ''}
        shippingAddress={shippingAddress}
      />
    </OrderInfoWrapper>
  )
}
