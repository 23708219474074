import React from 'react'
import { connect } from 'react-redux'

import { FormField, Flex, Box } from '@r1/ui-kit'
import { Ids, InputType } from '../../constants'
import { changePageSetting, changeName } from '../../actions'
import Container from './Container'
import Input from './Input'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState
  const { pageSettings } = state.labelManagement

  if (documentTemplate.selectedBlockId === Ids.DRAG_CONTAINER_ID) {
    return {
      documentTemplate,
      pageSettings,
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onNameChange: (oldName, newName) => dispatch(changeName(oldName, newName)),
  onPageSettingChange: (oldPageSetting, newPageSetting) =>
    dispatch(changePageSetting(oldPageSetting, newPageSetting)),
})
export const DocumentTemplatePanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ documentTemplate, pageSettings, onNameChange, onPageSettingChange, ...props }) => {
  if (!documentTemplate || !pageSettings) {
    return null
  }

  return (
    <Container {...props}>
      <Flex align="center" spaceBetween="S">
        <FormField.Label>Name</FormField.Label>
        <Box minWidth={210}>
          <Input
            type={InputType.TEXT}
            data={documentTemplate.name}
            onChange={newName => onNameChange(documentTemplate.name, newName)}
          />
        </Box>
      </Flex>
      <Flex align="center" mt="XS" spaceBetween="S">
        <FormField.Label>Page size</FormField.Label>
        <Box minWidth={210}>
          <Input
            width={210}
            type={InputType.SELECT}
            data={documentTemplate.pageSetting.id}
            options={pageSettings.map(ps => ({
              id: ps.id,
              name: ps.name,
            }))}
            onChange={newPageSettingId =>
              onPageSettingChange(
                documentTemplate.pageSetting,
                pageSettings.find(ps => ps.id === newPageSettingId),
              )
            }
          />
        </Box>
      </Flex>
    </Container>
  )
})
/*
DocumentTemplatePanel.propTypes = {
  documentTemplate: PropTypes.shape({
    name: PropTypes.string,
    pageSettingId: PropTypes.string,
  }),
  pageSettings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
      butterflyOffset: PropTypes.number,
    }),
  ),
  onNameChange: PropTypes.func.isRequired,
  onPageSettingChange: PropTypes.func.isRequired,
} */
