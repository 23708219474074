import { replaceTemplateValuesBadProperties } from '../../../utils/responseUtils.ts'

export function proceedError(error) {
  if (error == null) return []
  const errorMessages = []
  let status

  // support all legacy and new api formats
  if (error.response && error.response.status) {
    status = error.response.status
  } else if (error.status) {
    status = error.status
  }

  let data = {}

  // support all legacy and new api formats
  if (error.response && error.response.data) {
    data = error.response.data
  } else if (error.response && error.response.body) {
    data = error.response.body
  } else if (error.data) {
    data = error.data
  } else if (error.body) {
    data = error.body
  }

  const fieldsErrors = data?.fieldErrors || data?.fieldsErrors

  const handleFieldsErrors = () => {
    fieldsErrors.forEach(errObj => {
      Array.isArray(errObj?.errors)
        ? errObj.errors.forEach(message => {
            errorMessages.push({ title: errObj?.name, message })
          })
        : errorMessages.push({ title: data?.defaultFormat, message: errObj })
    })
  }

  if (status === 400 && (data.errors || data.fieldErrors || data.badProperties)) {
    if (fieldsErrors) {
      handleFieldsErrors()
    }

    if (data.badProperties) {
      const isLegacyBadPropertiesFormat = data.badProperties.some(
        badProperty => typeof badProperty?.name === 'string',
      )

      isLegacyBadPropertiesFormat
        ? data.badProperties.forEach(errObj => {
            if (errObj && errObj.name) {
              errorMessages.push({ message: `${errObj.name.split('.').pop()}: ${errObj.message}` })
            }
          })
        : errorMessages.push({ message: replaceTemplateValuesBadProperties(data.badProperties) })
    }
    if (data.errors) {
      data.errors.forEach(errMsg => {
        errorMessages.push({ title: 'Error', message: errMsg })
      })
    }
  } else if (status === 409) {
    errorMessages.push({
      title: 'Error',
      message: data.message || 'Conflict. Something went wrong. See information in console.',
    })
  } else if (status === 404) {
    errorMessages.push({ title: 'Error', message: (data && data.Message) || 'Data not found' })
  } else if (status === 432 || status === 422) {
    if (fieldsErrors && fieldsErrors.length === 0 && data.commonErrors.length > 0) {
      data.commonErrors.forEach(message => {
        const title = data?.message || data?.defaultFormat || 'Error'

        errorMessages.push({ title, message })
      })
    }

    if (fieldsErrors && fieldsErrors.length > 0) {
      handleFieldsErrors()
    }
  } else if (status === 500) {
    errorMessages.push({
      title: 'Error',
      message: (data && data.message) || 'Internal server error',
    })
  } else {
    throw error
  }

  return errorMessages
}
