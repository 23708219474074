// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fileToBase64(file: File): Promise<Record<string, any>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return new Promise<Record<string, any>>(resolve => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve({
        $type: 'Success',
        payload: reader.result,
      })
    }

    reader.onerror = () => {
      resolve({
        $type: 'Common',
        payload: new Error('File reading Error'),
      })
    }

    reader.readAsDataURL(file)
  })
}
