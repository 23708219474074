// @flow

import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Box, Button, Flex, Link } from '@r1/ui-kit'
import { Main, ContentHeader } from '@r1/wireframe-primary'
import { GenericIcon } from '@r1/core-blocks'

import { browserHistory } from '../browserHistory'
import { LinkText, StyledContent } from '../../components/StatusCodesStyled'

import { redirect } from '../../utils/windowUtils'

const Text = styled('span')`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  text-align: center;
  font-size: ${({ theme }) => theme.space.L};
  color: ${({ theme }) => theme.palette.grey[700]};
`

const R1_HOME_ROUTE = '/r1/home'

export const NoPermission = () => {
  const redirectToHomePage = useCallback(() => redirect(R1_HOME_ROUTE), [])
  const redirectToLastPage = () => browserHistory.goBack()

  return (
    <Main>
      <ContentHeader.Title browserTabTitle="403 No Permission" />
      <StyledContent spaceBetween="XL" justify="center" align="center">
        <Box>
          <GenericIcon svgId="403_error" />
        </Box>

        <Flex column>
          <Text>Sorry, but you don’t have permission to access this page.</Text>
          <Text>
            You can go back to
            <Link onClick={redirectToLastPage}>
              <LinkText> previous page </LinkText>
            </Link>
          </Text>
        </Flex>

        <Box>
          <Button onClick={redirectToHomePage}>Take me back to homepage</Button>
        </Box>
      </StyledContent>
    </Main>
  )
}
