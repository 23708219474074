/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  createRolesApi,
  createPermissionsApi,
  RolePermission,
  EntityPermissions,
} from '@r1-webui/usermanagement-v1'

import { handleServerError } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'
import { httpClient } from '../../../../../../httpClient'
import { Role, RoleId, SelectedEntityActions } from '../types'

const API_PREFIX = '/uisvc/user-management'

const rolesApi = createRolesApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})

const permissionsApi = createPermissionsApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})

function getSortedEntityPermissions(entityPermissions: EntityPermissions[]): EntityPermissions[] {
  const withSortedActions = entityPermissions.map(entityPermission => {
    return {
      ...entityPermission,
      actions: entityPermission.actions.sort((a, b) => a.name.localeCompare(b.name)),
    }
  })
  withSortedActions.sort((a, b) => a.entityType.name.localeCompare(b.entityType.name))
  return withSortedActions
}

export async function fetchAllEntityActionsInfo(): Promise<EntityPermissions[]> {
  const resp = await permissionsApi.getAllEntityPermissions()
  if (resp.status === 200) {
    return getSortedEntityPermissions(resp.body)
  }

  return []
}

function mapToSelectedEntityActions(rolePermissions: RolePermission[]): SelectedEntityActions {
  const selectedEntityActions: SelectedEntityActions = {}
  rolePermissions.forEach(({ entityTypeId, entityActionId }) => {
    if (selectedEntityActions[entityTypeId] === undefined) {
      selectedEntityActions[entityTypeId] = []
    }
    selectedEntityActions[entityTypeId].push(entityActionId)
  })

  return selectedEntityActions
}

function mapToRolePermissions(entityActions: SelectedEntityActions): RolePermission[] {
  const rolePermissions = []
  for (const [entityId, actionIds] of Object.entries(entityActions)) {
    for (const actionId of actionIds) {
      rolePermissions.push({
        entityTypeId: entityId,
        entityActionId: actionId,
      })
    }
  }

  return rolePermissions
}

export async function fetchRole(roleId: RoleId): Promise<Role> {
  const resp = await rolesApi.getRole({ roleId })

  if (resp.status === 200) {
    const { role, permissions } = resp.body

    return {
      name: role.name,
      description: role.description || '',
      entityTypeActions: mapToSelectedEntityActions(permissions),
    }
  }

  handleServerError(resp)

  return {
    name: '',
    description: '',
    entityTypeActions: {},
  }
}

export async function createRole({
  entityTypeActions,
  name,
  description,
}: Role): Promise<string | null> {
  const resp = await rolesApi.createRole({
    name,
    description: description || undefined,
    permissions: mapToRolePermissions(entityTypeActions),
  })
  if (resp.status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      title: `Role has been created`,
    })
    return resp.body
  }
  handleServerError(resp)
  return null
}

export async function updateRole(
  roleId: RoleId,
  { name, description, entityTypeActions }: Role,
): Promise<boolean> {
  const resp = await rolesApi.updateRole(
    { roleId },
    {
      name,
      description: description || undefined,
      permissions: mapToRolePermissions(entityTypeActions),
    },
  )
  if (resp.status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      title: `Role has been updated`,
    })
    return true
  }

  handleServerError(resp)
  return false
}

export async function deleteRole(roleId: RoleId): Promise<boolean> {
  const resp = await rolesApi.deleteRole({ roleId })
  if (resp.status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      title: `Role has been removed`,
    })
    return true
  }
  handleServerError(resp)
  return false
}
