// @flow

import { browserHistory } from '../browserHistory'

import { createReducer, createTypes } from '../../redux/utils'
import { current } from '../../modules/identity/current'

const initialState = {
  dialog: null,
}

const types = createTypes(['setFetching', 'setDialog'], 'app')

export const reducer = createReducer(initialState, {
  [types.setFetching]: (state, payload) => ({ ...state, ...payload }),
  [types.setDialog]: (state, payload) => ({ ...state, ...payload }),
})

export function setDefaultPage(): Function {
  return async dispatch => {
    const currentRes = await dispatch(current())

    // user unauthorized and will redirected to /login
    // it prevent to set default page before redirect
    if (!currentRes) return

    if (currentRes.defaultPage === null) {
      browserHistory.push('/r1/home')
    } else {
      window.location.href = currentRes.defaultPage
    }
  }
}

export function setFetching({ isFetching, showProgress }: any): Object {
  return {
    type: types.setFetching,
    isFetching,
    showProgress,
  }
}

export function showDialog(dialog: any): Function {
  return async (dispatch, getState) => {
    if (getState().app.dialog) {
      throw new Error('Dialog is opened. Cannot open one more.')
    }

    dispatch({
      type: types.setDialog,
      dialog,
    })
  }
}

export function closeDialog(result: any): Function {
  return async (dispatch, getState) => {
    const { dialog } = getState().app

    if (!dialog) {
      throw new Error('Dialog doesnt exist. Something goes wrong.')
    }

    dialog.resolve(result)

    dispatch({
      type: types.setDialog,
      dialog: null,
    })
  }
}
