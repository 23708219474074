// @flow

import React, { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text, H3, Container } from '@r1/ui-kit'
import type { MarketplaceMappingFormEditProps } from '../../../../types/product.type'
import { MarketplaceCategoriesTreeSelect } from './children/MarketplaceCategoriesTreeSelect'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const StyledLabel = styled('div')`
  box-shadow: inset 0px -1px 0px 0px ${({ theme }) => theme.palette.grey[300]};
`

export const MarketplaceMapping = ({
  values,
  handleChange,
  getError,
  disabled,
}: MarketplaceMappingFormEditProps) => {
  const header = useMemo(
    () => (
      <StyledLabel>
        <Flex spaceBetween="XL">
          <Box minWidth={108} maxWidth={108} display="block" mb="M">
            <Text weight="medium" align="center">
              Auto Update
            </Text>
          </Box>
          <Box minWidth={250} maxWidth={250}>
            <Text weight="medium">Marketplace</Text>
          </Box>
          <Box minWidth={497} maxWidth={497}>
            <Text weight="medium">Category</Text>
          </Box>
        </Flex>
      </StyledLabel>
    ),
    [],
  )

  const getMapping = useCallback(
    mapping => (
      <MarketplaceCategoriesTreeSelect
        key={mapping.index}
        disabled={disabled}
        width={486}
        mapping={mapping}
        placeholder="Select Category Mapping"
        error={getError(['MarketplaceCategoryMappingsValues', mapping.index, 'autoUpdate'])}
        onChangeCategoryId={handleChange([
          'MarketplaceCategoryMappingsValues',
          mapping.index,
          'categoryId',
        ])}
        onChangeAutoUpdate={handleChange([
          'MarketplaceCategoryMappingsValues',
          mapping.index,
          'autoUpdate',
        ])}
      />
    ),
    [disabled, getError, handleChange],
  )

  return (
    <>
      <Box>
        <StyledH3>Category Mapping</StyledH3>
      </Box>
      <Box>
        <Container>
          {/* <Collapse>
            <Collapse.Panel id="supported" title="SUPPORTED MARKETPLACES">
              <Box mb="XL">{header}</Box>
              {values.MarketplaceCategoryMappingsValues.filter(
                ({ isMarketplace }) => isMarketplace,
              ).map(getMapping)}
            </Collapse.Panel>
            <Collapse.Panel id="unsupported" title="UNSUPPORTED MARKETPLACES"> */}
          <Box mb="XL">{header}</Box>
          {values.MarketplaceCategoryMappingsValues.filter(
            ({ isMarketplace }) => !isMarketplace,
          ).map(getMapping)}
          {/* </Collapse.Panel>
          </Collapse> */}
        </Container>
      </Box>
    </>
  )
}
