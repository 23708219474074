import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { FormField, Input, Textarea, Select, Flex } from '@r1/ui-kit'
import { Priority, RulePriority } from '@r1-webui/shipping-returnshippingrules-v1'

import { ChangeValueChildrenHandler } from '@r1/form-manager'
import { FormLoadingPlaceholder } from '../../../../../../components/FormLoadingPlaceholder'
import { FormValues } from '../../types'

const EXECUTE_FIRST_ID = 'execute_first_id'

type Props = {
  isLoading: boolean
  arePrioritiesLoading: boolean
  name: string
  ruleId: string
  description?: string
  priority: Priority | { type: null }
  rulePriorities: RulePriority[]
  nameError: string | null | undefined
  descriptionError: string | null | undefined
  priorityError: string | null | undefined
  onChange: ChangeValueChildrenHandler<FormValues>
}

const getPriorityDescription = (
  name: string,
  ruleId: string,
  selectedPriority: Priority | { type: null },
  priorities: RulePriority[],
) => {
  if (selectedPriority.type === 'ExecuteFirst') {
    return `“${name}” rule will be executed first and will have a priority of 1`
  }

  const currentRulePriority = priorities.find(rule => rule.id === ruleId)
  const selectedRulePriority = priorities.find(
    rule => selectedPriority.type && rule.id === selectedPriority.ruleId,
  )

  if (!ruleId && selectedRulePriority) {
    const num = selectedRulePriority.priority
    return `“${name}” rule will have a priority of ${num}`
  }

  if (!currentRulePriority || !selectedRulePriority) return ''

  if (currentRulePriority.priority < selectedRulePriority.priority) {
    const num = selectedRulePriority.priority
    return `“${name}” rule will have a priority of ${num}`
  }
  const num = selectedRulePriority.priority + 1
  return `“${name}” rule will have a priority of ${num}`
}

export const OverviewSection = React.memo(
  ({
    isLoading,
    arePrioritiesLoading,
    name,
    ruleId,
    description,
    priority,
    rulePriorities,
    nameError,
    descriptionError,
    priorityError,
    onChange,
  }: Props) => {
    const priorityOptions = useMemo(
      () =>
        [{ id: EXECUTE_FIRST_ID, name: 'Execute this rule first' }].concat(
          rulePriorities
            .filter(({ id }) => id !== ruleId)
            .map(item => ({ ...item, name: `[${item.priority}] ${item.name}` }))
            .sort((a, b) => a.priority - b.priority),
        ),
      [ruleId, rulePriorities],
    )

    const onChangePriority = useCallback(
      (id: string) => {
        if (id === EXECUTE_FIRST_ID) {
          /* @ts-ignore */
          onChange(['priority'], { type: 'ExecuteFirst' })
        } else if (id) {
          /* @ts-ignore */
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          onChange(['priority'], { type: 'ExecuteAfterRuleId', ruleId: id })
        } else {
          /* @ts-ignore */
          onChange(['priority'], { type: null, ruleId: '' })
        }
      },
      [onChange],
    )

    if (isLoading) {
      return <FormLoadingPlaceholder numberOfFields={5} />
    }

    return (
      <>
        <FormField>
          <FormField.Label>Name</FormField.Label>
          {/* @ts-ignore */}
          <Input value={name} error={!!nameError} onChange={v => onChange(['name'], v)} />
          <FormField.Error>{nameError}</FormField.Error>
        </FormField>

        <Flex mb="L">
          <FormField>
            <FormField.Label>Execute the rule right after</FormField.Label>
            <Select
              clearable={false}
              loading={arePrioritiesLoading}
              value={
                (priority.type === 'ExecuteAfterRuleId' && priority.ruleId) ||
                (priority.type === 'ExecuteFirst' && EXECUTE_FIRST_ID) ||
                null
              }
              options={arePrioritiesLoading ? [] : priorityOptions}
              error={!!priorityError}
              onChange={onChangePriority}
            />
            {priorityError ? (
              <FormField.Error>priorityError</FormField.Error>
            ) : (
              <FormField.Description>
                {getPriorityDescription(name, ruleId, priority, rulePriorities)}
              </FormField.Description>
            )}
          </FormField>
        </Flex>
        <FormField>
          <FormField.Label>Description (Optional)</FormField.Label>
          <Textarea
            value={description}
            error={!!descriptionError}
            // @ts-ignore
            onChange={v => onChange(['description'], v)}
          />
          <FormField.Error>{descriptionError}</FormField.Error>
        </FormField>
      </>
    )
  },
)
