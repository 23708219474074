// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { FormManagerProvider } from '@r1/form-manager'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'
import { EditAttributeForm, FORM_ID } from './EditAttributeForm'

const formManagerConfig = [{ formId: FORM_ID }]

type Props = {|
  attributeId: string,
|}

export const EditAttributeView = ({ attributeId }: Props) => {
  const navigate = useNavigate()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowAttributeDefinitionEdit]}>
      <FormManagerProvider config={formManagerConfig}>
        {attributeId ? (
          <EditAttributeForm
            attributeId={attributeId}
            httpClient={httpClient}
            onSubmit={() => {
              navigate(ROUTES.ATTRIBUTES_DEFINITIONS)
            }}
            onSuccessfulDelete={() => {
              navigate(ROUTES.ATTRIBUTES_DEFINITIONS)
            }}
            onCancel={() => {
              navigate(ROUTES.ATTRIBUTES_DEFINITIONS)
            }}
          />
        ) : null}
      </FormManagerProvider>
    </PageAccessControl>
  )
}
