import { createProgramsApi, baseUri } from '@r1-webui/programprovider-v1'
import { httpClient } from '../../httpClient'

export const legacyBrowserUIApi = createProgramsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `/uisvc/legacy-browserui/${baseUri}`,
})

const API_PREFIX = `/ui/api/program`

export const legacyPrograms = {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  getCurrentProgramId: (): Promise<string> => httpClient.get(`${API_PREFIX}/current`),
  ...legacyBrowserUIApi,
}
