/* eslint-disable global-require */
export const reducers = {
  ...require('./dictionaries/countries').default,
  ...require('./dictionaries/consigneeUpcClassifications').default,
  ...require('./dictionaries/consigneeUpcMarketplaces').default,

  ...require('./products/categories').default,
  ...require('./products/models').default,
  ...require('./products/products').default,
  ...require('./products/classifications').default,
  ...require('./products/images').default,

  ...require('./productCatalog/dataSources/categories').default,
  ...require('./productCatalog/dataSources').default,

  ...require('./productTemplates/consigneeUpc').default,
  ...require('./productTemplates/productTemplates').default,
  ...require('./productTemplates/images').default,

  ...require('./warehouses').default,
  ...require('./manufacturers').default,
}
