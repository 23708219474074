// @flow

import { createUsersApi } from '@r1-webui/usermanagement-v1'
import { type Api as UsersApi } from '@r1-webui/usermanagement-v1/src/users'

import {
  createClaimHistoryApi,
  baseUri as rmaVendorPortalClaimHistoryApi,
} from '@r1-webui/rmavendorportal-claimhistory-v1'
import { type Api as ClaimHistoryApi } from '@r1-webui/rmavendorportal-claimhistory-v1/src/claimHistory'

import {
  createClaimsApi,
  createClaimMessagesApi,
  createLabelsApi,
  createClaimStateApi,
  createClaimDecisionItemsApi,
  createClaimChatApi,
  baseUri as rmaVendorPortalClaimManagementBaseUri,
} from '@r1-webui/rmavendorportal-claimmanagement-v1'
import type { Api as ClaimsApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claims'
import type { Api as LabelsApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/labels'
import type { Api as ClaimStateApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimState'
import type { Api as ClaimMessagesApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimMessages'
import type { Api as ClaimDecisionItemsApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimDecisionItems'
import type { Api as ClaimChatApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimChat'

import {
  createRmaProfilesApi,
  baseUri as rmaVendorPortalBaseUri,
} from '@r1-webui/rmavendorportal-v1'
import type { Api as ApiRmaProfiles } from '@r1-webui/rmavendorportal-v1/src/rmaProfiles'
import {
  baseUri as rmaVendorPortalOrdersBaseUri,
  createOrdersApi,
  createOrderSearchApi,
} from '@r1-webui/rmavendorportal-orders-v1'
import type { Api as OrdersApi } from '@r1-webui/rmavendorportal-orders-v1/src/orders'
import type { Api as OrderSearchApi } from '@r1-webui/rmavendorportal-orders-v1/src/orderSearch'

import {
  baseUri as addressManagementBaseUri,
  createAddressApi,
} from '@r1-webui/addressmanagement-v1'
import type { Api as AddressApi } from '@r1-webui/addressmanagement-v1/src/address'

import type { HttpClient } from '../types/common'

let cachedApi = null

export type VendorPortalApi = {
  users: UsersApi,
  claimHistory: ClaimHistoryApi,
  claims: ClaimsApi,
  chat: ClaimChatApi,
  labels: LabelsApi,
  messages: ClaimMessagesApi,
  claimState: ClaimStateApi,
  decisionItems: ClaimDecisionItemsApi,
  rmaProfiles: ApiRmaProfiles,
  orders: OrdersApi,
  orderSearch: OrderSearchApi,
  address: AddressApi,
}

export const createApi = (http: HttpClient): VendorPortalApi => {
  if (cachedApi === null) {
    cachedApi = {
      users: createUsersApi({
        http,
        urlPrefix: `/uisvc/user-management/`,
      }),
      claimHistory: createClaimHistoryApi({
        http,
        urlPrefix: `/uisvc/rma-claim-grid/${rmaVendorPortalClaimHistoryApi}`,
      }),
      claims: createClaimsApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      messages: createClaimMessagesApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      labels: createLabelsApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      chat: createClaimChatApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      claimState: createClaimStateApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      decisionItems: createClaimDecisionItemsApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalClaimManagementBaseUri}`,
      }),
      rmaProfiles: createRmaProfilesApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalBaseUri}`,
      }),
      orders: createOrdersApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalOrdersBaseUri}`,
      }),
      orderSearch: createOrderSearchApi({
        http,
        urlPrefix: `/uisvc/rma-vendor-portal/${rmaVendorPortalOrdersBaseUri}`,
      }),
      address: createAddressApi({
        http,
        urlPrefix: `/uisvc/address-management/${addressManagementBaseUri}`,
      }),
    }
  }
  return cachedApi
}
