// @flow

import React, { memo } from 'react'
import { Box, Flex, FormField, H2, ImageCarousel, Switch, Text } from '@r1/ui-kit'
import type { ImageItem } from '@r1/ui-kit/build/types/ImageCarousel'

type HeaderBlockProps = {
  images: ImageItem[],
  title: string,
  identifier: string,
  isLock: boolean,
  allowProductUnlock: boolean,
  lockSwitch: () => void,
}

export const HeaderBlock = memo<HeaderBlockProps>(
  ({ images, title, identifier, isLock, allowProductUnlock, lockSwitch }: HeaderBlockProps) => {
    return (
      <Flex spaceBetween="XL">
        <Box shrink>
          <ImageCarousel zoomable images={images} defaultImage="0" />
        </Box>
        <Box display="block" maxWidth={540}>
          <Text>Identifier – {identifier}</Text>
          <H2>{title}</H2>
          <Box>
            <FormField>
              <FormField.Label>Status</FormField.Label>
              <Box>
                <Switch
                  label={isLock ? 'Locked' : 'Unlocked'}
                  checked={isLock}
                  disabled={isLock && !allowProductUnlock}
                  onChange={lockSwitch}
                />
              </Box>
            </FormField>
          </Box>
        </Box>
      </Flex>
    )
  },
)
