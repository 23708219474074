// @flow

import * as React from 'react'
import { handleServerError } from '@r1/core-blocks'
import { createApi } from '../api'
import { isResponse } from '../../utils/api'
import { mapControllerStateToViewProps } from './mapControllerStateToViewProps'
import type {
  AttributeDefinitionScreenProps,
  AttributeDefinitionScreenState,
  GetAttributeDefinitionOptions,
  AttributeType,
  WrappedComponentType,
} from './types'

export const withAttributeDefinitionController = (Component: WrappedComponentType) => {
  return class AttributeDefinitionScreen extends React.Component<
    AttributeDefinitionScreenProps,
    AttributeDefinitionScreenState,
  > {
    api = createApi(this.props.httpClient)

    state = {
      screenType: 'placeholder',
      attributeDefinition: null,
      allUnitsOfMeasurement: [],
      availableUnitOfMeasurementTypes: [],
    }

    componentDidMount() {
      return this.fetchData(this.props.attributeId)
    }

    // eslint-disable-next-line consistent-return
    componentDidUpdate(prevProps: AttributeDefinitionScreenProps) {
      if (this.props.attributeId !== prevProps.attributeId) {
        return this.fetchData(this.props.attributeId)
      }
    }

    fetchAttributeDefinition = async ({ attributeDefinitionId }: GetAttributeDefinitionOptions) => {
      const response = await this.api.getAttributeDefinition({ attributeDefinitionId })

      if (isResponse(response)) {
        switch (response.$type) {
          case 'Success':
            return response.payload
          case 'Forbidden':
            return response
          case 'Common':
            handleServerError(response)
            break
          case 'Unauthorized':
            break
          default:
        }
      }
      return null
    }

    fetchAllUnitsOfMeasurement = async () => {
      const response = await this.api.getAllUnitsOfMeasurement()

      if (isResponse(response)) {
        switch (response.$type) {
          case 'Success':
            return response.payload
          case 'Common':
            handleServerError(response)
            break
          case 'Unauthorized':
            break
          default:
        }
      }
      return null
    }

    fetchAvailableUnitOfMeasurementTypes = async (attributeType: AttributeType) => {
      const response = await this.api.getUnitOfMeasurementTypes()

      if (isResponse(response)) {
        switch (response.$type) {
          case 'Success':
            return response.payload.reduce((types, item) => {
              if (item.attributeType === attributeType) {
                types.push(...item.unitOfMeasurementTypes)
              }

              return types
            }, [])
          case 'NotSupported':
            return []
          case 'Common':
            handleServerError(response)
            break
          case 'Unauthorized':
            break
          default:
        }
      }
      return null
    }

    // eslint-disable-next-line consistent-return
    fetchData = async (attributeId: string) => {
      const attributeDefinition = await this.fetchAttributeDefinition({
        attributeDefinitionId: attributeId,
      })

      if (attributeDefinition == null) return null
      if (attributeDefinition.$type === 'Forbidden') {
        return this.setState({ screenType: 'forbidden' })
      }
      const availableUnitOfMeasurementTypes = await this.fetchAvailableUnitOfMeasurementTypes(
        attributeDefinition.valueType,
      )
      let allUnitsOfMeasurement = []
      if (availableUnitOfMeasurementTypes != null && availableUnitOfMeasurementTypes.length > 0) {
        allUnitsOfMeasurement = await this.fetchAllUnitsOfMeasurement()
      }
      this.setState({
        screenType: 'default',
        attributeDefinition,
        allUnitsOfMeasurement: allUnitsOfMeasurement || [],
        availableUnitOfMeasurementTypes: availableUnitOfMeasurementTypes || [],
      })
    }

    render() {
      const { screenType } = this.state

      switch (screenType) {
        case 'placeholder':
          return <Component screenType={(screenType: 'placeholder')} />
        case 'forbidden':
          return <Component screenType={(screenType: 'forbidden')} />
        case 'default':
          // $FlowFixMe[incompatible-call]
          return <Component {...mapControllerStateToViewProps(this.state)} />
        default:
          return null
      }
    }
  }
}
