// @flow

import React, { useCallback } from 'react'
import { FormManager } from '@r1/form-manager'
import { FormField, Input, Select, TagInput } from '@r1/ui-kit'
import { clientValidate } from '../../../utils/validation'
import { schema } from '../schema'
import {
  attributeCanHaveEnumValues,
  attributeCanHaveUnitOfMeasurement,
} from '../../AttributeDefinition/utils'
import type { AttributeCreationFormViewProps } from '../../../types/public/AttributeCreationFormView'

import type { FormManagerValues } from '../../../types/public/attributeCreationFormCommon'
import { testIds } from './testIds'

const initialValues: FormManagerValues = {
  attributeName: '',
  attributeType: null,
  attributeBindingType: null,
  attributeEnumValues: [],
  unitOfMeasurement: null,
  unitOfMeasurementType: null,
}

const attributeTypeMapping: Object[] = [
  { id: 'Integer', name: 'Integer number' },
  { id: 'Decimal', name: 'Decimal number' },
  { id: 'String', name: 'Free text' },
  { id: 'IntEnumeration', name: 'Integer number list' },
  { id: 'DecimalEnumeration', name: 'Decimal number list' },
  { id: 'StringEnumeration', name: 'Text List' },
  { id: 'Boolean', name: 'Boolean' },
  { id: 'Date', name: 'Date' },
  { id: 'DateRange', name: 'Date range' },
  { id: 'IntRange', name: 'Integer number range' },
  { id: 'DecimalRange', name: 'Decimal number range' },
]

const attributeBindingTypes: Object[] = [
  { id: 'Product', name: 'Product' },
  { id: 'Unit', name: 'Unit' },
]

const SELECT_WIDTH = 255

const getRegexp = type => {
  switch (type) {
    case 'IntEnumeration':
      return /^\d+?$/
    case 'DecimalEnumeration':
      return /^\d+(\.\d)?$/
    default:
      return undefined
  }
}

const trimMultiSpaces = str => str.trim().replace(/ +(?= )/g, '')

export const AttributeCreationFormView = (props: AttributeCreationFormViewProps) => {
  const { handlers, dataSources, formId } = props
  const { unitOfMeasurements, unitOfMeasurementTypes } = dataSources
  const { onSubmit, onChangeUnitOfMeasurementType } = handlers

  const onEnumChange = useCallback(
    handleChange => values => {
      const newValues = []
      // check and don't add duplicate and extra spaces
      values.forEach(value => {
        if (
          newValues.find(
            enumValue =>
              enumValue.value.toLowerCase() === trimMultiSpaces(value.value.toLowerCase()),
          ) === undefined
        )
          newValues.push({ id: value.id, value: trimMultiSpaces(value.value) })
      })

      handleChange('attributeEnumValues')(newValues)
    },
    [],
  )

  return (
    <FormManager
      formId={formId}
      initialValues={{ ...initialValues }}
      clientValidate={value => clientValidate(schema, value)}
      onSubmit={onSubmit}
    >
      {({ values, getError, handleChange, handleBlur }) => {
        return (
          <>
            <FormField>
              <FormField.Label>Name</FormField.Label>
              <Input
                data-test-id={testIds.nameInput}
                placeholder="Enter attribute name"
                value={String(values.attributeName)}
                error={!!getError('attributeName')}
                onChange={value => handleChange('attributeName', value)}
                onBlur={handleBlur('attributeName')}
              />
              <FormField.Error>{getError('attributeName')}</FormField.Error>
            </FormField>

            <FormField>
              <FormField.Label>Type</FormField.Label>
              <Select
                data-test-id={testIds.typeSelect}
                width={SELECT_WIDTH}
                clearable={false}
                placeholder="Choose attribute type"
                value={values.attributeType}
                error={!!getError('attributeType')}
                options={attributeTypeMapping}
                onChange={value => {
                  handleChange('attributeType', value)
                  handleChange('attributeEnumValues', [])
                }}
              />
              <FormField.Error>{getError('attributeType')}</FormField.Error>
            </FormField>

            {values.attributeType && attributeCanHaveEnumValues(values.attributeType) && (
              <FormField>
                <FormField.Label>Available Values</FormField.Label>
                <TagInput
                  editable
                  $type="complexTagValue"
                  data-test-id={testIds.valuesInput}
                  value={values.attributeEnumValues
                    .map(item => ({ ...item, color: 'default', icon: null }))
                    .sort((a, b) => (a.value > b.value ? 1 : -1))}
                  accept={getRegexp(values.attributeType)}
                  placeholder="Enter values"
                  error={!!getError('attributeEnumValues')}
                  onChange={onEnumChange(handleChange)}
                />
                <FormField.Error>{getError('attributeEnumValues')}</FormField.Error>
              </FormField>
            )}

            {values.attributeType && attributeCanHaveUnitOfMeasurement(values.attributeType) && (
              <FormField>
                <FormField.Label>Unit of Measurement Type</FormField.Label>
                <Select
                  data-test-id={testIds.unitOfMeasurementTypeSelect}
                  width={SELECT_WIDTH}
                  clearable={false}
                  placeholder="Select measurement type"
                  value={values.unitOfMeasurementType}
                  error={!!getError('unitOfMeasurementType')}
                  options={unitOfMeasurementTypes}
                  onChange={value => {
                    onChangeUnitOfMeasurementType(value)
                    return handleChange('unitOfMeasurementType', value)
                  }}
                />
                <FormField.Error>{getError('unitOfMeasurementType')}</FormField.Error>
              </FormField>
            )}

            {values.attributeType &&
              attributeCanHaveUnitOfMeasurement(values.attributeType) &&
              values.unitOfMeasurementType && (
                <FormField>
                  <FormField.Label>Unit of Measurement</FormField.Label>
                  <Select
                    data-test-id={testIds.unitOfMeasurementSelect}
                    width={SELECT_WIDTH}
                    clearable={false}
                    placeholder="Select unit of measurement"
                    value={values.unitOfMeasurement}
                    error={!!getError('unitOfMeasurement')}
                    options={unitOfMeasurements}
                    onChange={handleChange('unitOfMeasurement')}
                  />
                  <FormField.Error>{getError('unitOfMeasurement')}</FormField.Error>
                </FormField>
              )}

            <FormField>
              <FormField.Label>Refers To</FormField.Label>
              <Select
                data-test-id={testIds.bindingTypeSelect}
                width={SELECT_WIDTH}
                clearable={false}
                placeholder="Select product or unit"
                value={values.attributeBindingType}
                error={!!getError('attributeBindingType')}
                options={attributeBindingTypes}
                onChange={handleChange('attributeBindingType')}
              />
              <FormField.Error>{getError('attributeBindingType')}</FormField.Error>
            </FormField>
          </>
        )
      }}
    </FormManager>
  )
}
