// @flow

import { baseUri } from '@r1-webui/productcatalog-categories-v1'

import { UI_API_PREFIXES } from '../../../api'
import type { Api } from '../../../types/internal/api/categories/index'
import { mapForbiddenError, mapToCommonErrors, successResponseInterceptor } from '../../utils/api'
import type { InternalApi } from './types'

export const baseURL = `${UI_API_PREFIXES.CATALOG}/${baseUri}`

export type CategoryApi = {| ...Api, ...InternalApi |}
export function createApi(httpClient: any): CategoryApi {
  return {
    getRootCategories: () =>
      httpClient
        .get(`${baseURL}/categories/root`)
        .then(successResponseInterceptor)
        .catch(mapToCommonErrors),
    getChildrenCategories: ({ categoryId }) =>
      httpClient
        .get(`${baseURL}/categories/${categoryId}/children`)
        .then(successResponseInterceptor)
        .catch(mapForbiddenError)
        .catch(mapToCommonErrors),
    getCategoryInfo: ({ categoryId }) =>
      httpClient
        .get(`${baseURL}/categories/${categoryId}`)
        .then(successResponseInterceptor)
        .catch(mapForbiddenError)
        .catch(mapToCommonErrors),
    getCategoryPath: ({ categoryId }) =>
      httpClient
        .get(`${baseURL}/categories/${categoryId}/path`)
        .then(data => successResponseInterceptor(data.path))
        .catch(mapToCommonErrors),
    getDirectAttributeBindings: ({ categoryId }) =>
      httpClient
        .get(`${baseURL}/categories/${categoryId}/attribute-bindings`)
        .then(successResponseInterceptor)
        .catch(mapToCommonErrors),
    getInheritedAttributeBindings: ({ categoryId }) =>
      httpClient
        .get(`${baseURL}/categories/${categoryId}/attribute-bindings/inherited`)
        .then(successResponseInterceptor)
        .catch(mapToCommonErrors),
  }
}
