// @flow

import { css } from '@emotion/css'
import styled from '@emotion/styled'

export const ReplacementItemWrapper = styled('div')`
  ${({ theme, editMode }) => css`
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 50px;
    padding: 3px 14px;
    border-radius: 14px;
    min-width: 36px;
    background-color: ${editMode ? theme.primaryColor[100] : theme.palette.white};
    color: ${editMode ? theme.primaryColor[600] : theme.palette.grey[800]};
    border: ${editMode
      ? `1px solid ${theme.primaryColor[500]}`
      : `1px solid ${theme.palette.grey[300]}`};
    display: inline-flex;
    justify-content: center;
    padding-right: ${editMode ? `3px` : `14px`};
  `}
`
