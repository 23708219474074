// @flow

import React, { memo } from 'react'
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  Text,
  CartesianGrid,
} from 'recharts'

type DeviationLineChartsProps = {|
  lowLevel: number,
  hiLevel: number,
  lowDeviation: number,
  hiDeviation: number,
  width?: number,
  height?: number,
|}

const LOW_LEVEL_ON_CHART = 50
const HI_LEVEL_ON_CHART = 150
const END_LEVEL_ON_CHART = 200
const COLOR = '#5390FD'

export const DeviationLineCharts = memo<DeviationLineChartsProps>(
  (props: DeviationLineChartsProps) => {
    const { hiDeviation, lowDeviation, lowLevel, hiLevel, width = 412, height = 242 } = props

    const data = [
      { Price: LOW_LEVEL_ON_CHART, 'Maximum Deviation': lowDeviation }, // string key for tooltip
      { Price: HI_LEVEL_ON_CHART, 'Maximum Deviation': hiDeviation },
    ]

    return (
      <ScatterChart width={width} height={height} margin={{ right: 30, left: 20, bottom: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis
          dataKey="Price"
          type="number"
          domain={[0, END_LEVEL_ON_CHART]}
          ticks={[LOW_LEVEL_ON_CHART, HI_LEVEL_ON_CHART]}
          tickFormatter={val => (val === LOW_LEVEL_ON_CHART ? lowLevel : hiLevel)}
          unit="$"
          label={{ value: 'Price', offset: 0, position: 'bottom' }}
        />

        <YAxis
          dataKey="Maximum Deviation"
          type="number"
          domain={[0, 100]}
          ticks={[lowDeviation, hiDeviation]}
          unit="%"
          label={
            <Text x={0} y={0} dx={30} dy={height / 2 + 30} offset={0} angle={-90}>
              Max. deviation
            </Text>
          }
        />
        <Tooltip
          formatter={(value, name) => {
            if (name === 'Price') return `${value === LOW_LEVEL_ON_CHART ? lowLevel : hiLevel}`
            return `${value}`
          }}
        />

        <Scatter data={data} fill={COLOR} line={{ stroke: COLOR, strokeWidth: 3 }} />

        <ReferenceLine
          stroke={COLOR}
          strokeWidth={3}
          segment={[
            { x: 0, y: lowDeviation },
            { x: LOW_LEVEL_ON_CHART, y: lowDeviation },
          ]}
        />

        <ReferenceLine
          stroke={COLOR}
          strokeWidth={3}
          segment={[
            { x: HI_LEVEL_ON_CHART, y: hiDeviation },
            { x: END_LEVEL_ON_CHART, y: hiDeviation },
          ]}
        />
      </ScatterChart>
    )
  },
)
