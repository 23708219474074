// @flow weak

import * as React from 'react'
import { compose } from 'recompose'
import * as R from 'ramda'
import { FormGenerator } from './FormGenerator'

type ScaleItem = {
  id: string,
  value: string,
}

type ScaleDictionary = {
  id: number,
  data: ScaleItem[],
}

type ClassificationValueType =
  | 'RangeOption'
  | 'Option'
  | 'Decimal'
  | 'Text'
  | 'Date'
  | 'MultipleOption'
  | 'Range'
  | 'Integer'

type ClassificationModel = {
  classificationId: number,
  classificationName: string,
  valueType: ClassificationValueType,
  value: Object,
  allowMoreThanOnce: boolean,
  parentValue: string,
  children: ClassificationModel[],
  // unused
  type: any,
  parentClassificationId?: number,
  mapId?: number,
  useForRma: boolean,
}

type ClassificationInputProps = {
  classifications: ClassificationModel[],
  schema: ClassificationModel[],
  dictionaries: ScaleDictionary[],
  // internal
}

function transformDictionaries(dictionaries) {
  return Object.keys(dictionaries).reduce(
    (acc, key) => ({
      ...acc,
      [key]: dictionaries[key].map(entity => ({
        id: entity.id,
        value: entity.value.toUpperCase(),
      })),
    }),
    {},
  )
}

function unfoldScheme(scheme = []) {
  return scheme
    ? scheme.reduce(
        (acc, node) => ({
          ...acc,
          [node.ClassificationId]: node,
          ...(node.Children.length > 0 && unfoldScheme(node.Children)),
        }),
        {},
      )
    : undefined
}

function traverseScheme(scheme = [], original = {}) {
  return scheme.map(node => {
    if (node.AllowMoreThanOnce) {
      const exist = node.Children.map(el => el.ClassificationId)

      const looseChildren = (
        original[node.ClassificationId] ? original[node.ClassificationId].Children : []
      ).filter(el => !exist.includes(el.ClassificationId))

      return {
        ...node,
        Children: [...looseChildren, ...node.Children],
      }
    }
    return node
  })
}

const mapKeys = R.curry((fn, obj) => {
  if (typeof obj === 'string') return obj
  return Array.isArray(obj)
    ? R.map(mapKeys(fn), obj)
    : R.fromPairs(
        R.map(
          // $FlowFixMe[invalid-tuple-arity]
          compose(R.adjust(0, fn), v => (Array.isArray(v[1]) ? R.adjust(1, mapKeys(fn))(v) : v)),
          R.toPairs(obj),
        ),
      )
})

const toIndexedArray = compose(R.fromPairs, R.map(R.props(['id', 'data'])))
// $FlowFixMe[incompatible-type]
const toUpperFirstLetter = str => R.toUpper(R.head(str)) + R.tail(str)
// $FlowFixMe[incompatible-type]
const toLowerCaseLetter = str => R.toLower(R.head(str)) + R.tail(str)
function mapClassification({ dictionaries, schema, classifications, ...props }) {
  return {
    ...props,
    // $FlowFixMe[invalid-tuple-arity]
    classifications: mapKeys(toUpperFirstLetter, classifications),
    dictionaries: transformDictionaries(toIndexedArray(dictionaries)),
    // $FlowFixMe[invalid-tuple-arity]
    schema: mapKeys(toUpperFirstLetter, schema),
  }
}

export class ClassificationInput extends React.Component<ClassificationInputProps> {
  classifications: ClassificationModel[]

  constructor(props) {
    super(props)
    this.classifications = props.classifications
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getClassifications: () => ClassificationModel[] = () =>
    // $FlowFixMe[invalid-tuple-arity]
    mapKeys(toLowerCaseLetter, this.classifications)

  handleChange = classifications => {
    this.classifications = classifications
  }

  render() {
    if (!this.props.schema || !this.props.dictionaries) return null
    const { schema, dictionaries, disabled, classifications } = mapClassification(this.props)
    return (
      <FormGenerator
        name="ClassificationValues"
        disabled={disabled}
        scheme={traverseScheme(classifications, unfoldScheme(schema)) || []}
        dictionaries={dictionaries}
        meta={{ classifications, schema }}
        onAddDirtyTab={() => true}
        onChange={this.handleChange}
      />
    )
  }
}
