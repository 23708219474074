// @flow

import * as React from 'react'
import { format } from 'date-fns'
import { Table } from '@r1/grid'
import { Flex, Link } from '@r1/ui-kit'

import type { LoadSteps, AllCalculationsGridProps, ConsolidationRule } from './types'

const getDisplaySettings = (loadSteps: LoadSteps, rules: ConsolidationRule[]) => ({
  showCounter: true,
  columnsSettings: [
    {
      width: 150,
      header: {
        content: 'Identifier',
        sorting: 'none',
      },
      cell: {
        $type: 'custom',
        field: 'identifier',
        renderer: (row, index) => (
          <Flex align="center">
            {/* $FlowFixMe[incompatible-call] */}
            <Link onClick={e => loadSteps(index, e)}>{row.identifier}</Link>
          </Flex>
        ),
      },
    },
    {
      width: 250,
      header: {
        content: 'Rule',
        sorting: 'none',
      },
      cell: {
        $type: 'custom',
        field: 'ruleId',
        renderer: row => {
          const rule = rules.find(({ priceRuleId }) => priceRuleId === row.ruleId)
          return <Flex align="center">{rule ? rule.name : ''}</Flex>
        },
      },
    },
    {
      width: 250,
      header: {
        content: 'Created At',
        sorting: 'none',
      },
      cell: {
        $type: 'custom',
        field: 'createdAt',
        renderer: row => {
          return (
            <Flex align="center">{format(new Date(row.createdAt), 'MM/dd/yyyy, hh:mm a')}</Flex>
          )
        },
      },
    },
    {
      width: 150,
      header: {
        content: 'Status',
        sorting: 'none',
      },
      cell: {
        $type: 'text',
        field: 'status',
      },
    },
  ],
  rowSettings: { highlightOnHover: true },
})

const mapIdxToField = ['identifier', 'ruleId', 'createdAt', 'status']

const compare = (a, b, inverse) => {
  if (a < b) {
    return inverse ? 1 : -1
  }
  if (a > b) {
    return inverse ? -1 : 1
  }

  return 0
}

export const AllCalculationsGrid = ({
  data: initData,
  loading,
  loadSteps,
  rules,
}: AllCalculationsGridProps) => {
  const [data, setData] = React.useState(initData)
  const [displaySettings, setDisplaySettings] = React.useState(getDisplaySettings(loadSteps, rules))

  const onChangeSorting = sorting => {
    const field = mapIdxToField[sorting.columnIndex]

    const newData = data.slice().sort((a, b) => {
      const ruleA = rules.find(({ priceRuleId }) => priceRuleId === a[field])
      const ruleAName = ruleA ? ruleA.name : ''
      const ruleB = rules.find(({ priceRuleId }) => priceRuleId === b[field])
      const ruleBName = ruleB ? ruleB.name : ''

      const el1 = field === mapIdxToField[1] ? ruleAName : a[field]
      const el2 = field === mapIdxToField[1] ? ruleBName : b[field]
      const inverse = sorting.type === 'desc'

      return compare(el1, el2, inverse)
    })

    const columnsSettings = displaySettings.columnsSettings.map(x => {
      if (x.cell.field === field) {
        return {
          ...x,
          header: { ...x.header, sorting },
        }
      }
      return x
    })

    setDisplaySettings({ ...displaySettings, columnsSettings })
    setData(newData)
  }

  return (
    <Table
      suppressVirtualization
      // $FlowFixMe[prop-missing]
      // $FlowFixMe[incompatible-type]
      displaySettings={displaySettings}
      data={data}
      loading={loading}
      onChangeSorting={onChangeSorting}
    />
  )
}
