// @flow

import * as React from 'react'
import { Select, Flex, H2 } from '@r1/ui-kit'
import { RemovableBlock } from './RemovableBlock'

type Primitive = number | string

type Item = {
  id: number,
  name: string,
}

type ClassificationValue = {
  classificationId: number,
  valueIds: Primitive[],
}

type Props = {
  renderClassificationValues: ({
    classificationId: number,
    classificationName: string,
    disabled: boolean,
    classificationValueIds: Primitive[],
    onChangeClassificationValueIds: (value: Primitive[]) => void,
  }) => React.Node | null,
  classificationData: Item[],
  value: ClassificationValue[],
  onChange: (value: ClassificationValue[]) => void,
  disabled: boolean,
}

export class CommonClassifications extends React.Component<Props> {
  onChange = (classificationIds: number[]) => {
    const { onChange, value } = this.props
    const newValue = classificationIds.map(
      classificationId =>
        value.find(
          classificationValue => classificationValue.classificationId === classificationId,
        ) || { classificationId, valueIds: [] },
    )

    onChange(newValue)
  }

  renderClassifications = ({ classificationIds }: { classificationIds: number[] }) => {
    const { classificationData, value, onChange, renderClassificationValues, disabled } = this.props

    return classificationIds.map<React.Node>(classificationId => {
      const classification = classificationData.find(({ id }) => id === classificationId) || {}
      const classificationName = classification.name
      const shouldRenderClassification =
        classificationId > -1 && Object.keys(classification).length > 0

      const currentClassificationValue = value.find(
        classificationValue => classificationValue.classificationId === classificationId,
      )

      const classificationValueIds = currentClassificationValue
        ? currentClassificationValue.valueIds
        : []

      const onRemove = () =>
        onChange(
          value.filter(
            classificationValue => classificationValue.classificationId !== classificationId,
          ),
        )

      const onChangeClassificationValueIds = valueIds =>
        onChange(
          value.map(classificationValue => {
            if (classificationValue.classificationId === classificationId) {
              return {
                classificationId,
                valueIds,
              }
            }

            return classificationValue
          }),
        )

      return shouldRenderClassification ? (
        <div key={classificationId}>
          <RemovableBlock disabled={disabled} onRemove={onRemove}>
            {renderClassificationValues({
              classificationId,
              classificationName,
              disabled,
              classificationValueIds,
              onChangeClassificationValueIds,
            })}
          </RemovableBlock>
        </div>
      ) : null
    })
  }

  render() {
    const { disabled, value, classificationData } = this.props
    const classificationIds = value.map(({ classificationId }) => classificationId)

    return (
      <Flex column spaceBetween="S">
        <H2>Classifications</H2>
        <Select
          multiple
          tagMode="none"
          width={300}
          disabled={disabled}
          placeholder="ADD CLASSIFICATION (0)"
          renderValue={values => `ADD CLASSIFICATION (${values.length})`}
          value={classificationIds}
          options={classificationData}
          onChange={this.onChange}
        />
        {this.renderClassifications({ classificationIds })}
      </Flex>
    )
  }
}
