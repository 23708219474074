import * as React from 'react'
import { useCallback } from 'react'

import { ShippingOption as OptionType } from '@r1-webui/shipping-returnshippingrules-v1'
import { ChangeValueChildrenHandler } from '@r1/form-manager'
import { Box, Button, Flex, Icon } from '@r1/ui-kit'
import { Profile } from '../../../../services/espmService'
import { ShippingCarrier } from '../../hooks/useShippingOptions'
import { ShippingOption, Paragraph } from '../components'
import { FormLoadingPlaceholder } from '../../../../../../components/FormLoadingPlaceholder'
import { FormValues } from '../../types'

type Props = {
  isLoading: boolean
  shippingCarriers: ShippingCarrier[]
  shippingProfiles: Profile[]
  thirdPartyShippingProfiles: Profile[]
  shippingOptions: OptionType[]
  areShippingProfilesLoading: boolean
  areThirdPartyShippingProfilesLoading: boolean
  areShippingCarriersLoading: boolean
  getShippingOptionsError: (i: number) => string | null
  onChange: ChangeValueChildrenHandler<FormValues>
  onAddShippingOption: (value: OptionType) => void
  onDeleteShippingOption: (index: number) => void
}

export const ShippingOptionsSection = React.memo(
  ({
    isLoading,
    shippingProfiles,
    thirdPartyShippingProfiles,
    shippingOptions,
    shippingCarriers,
    areShippingProfilesLoading,
    areThirdPartyShippingProfilesLoading,
    areShippingCarriersLoading,
    getShippingOptionsError,
    onChange,
    onAddShippingOption,
    onDeleteShippingOption,
  }: Props) => {
    const onClick = useCallback(() => {
      onAddShippingOption({
        shippingProfileId: '',
        shippingServices: { type: 'ExcludeShippingServices', shippingServices: [] },
      })
    }, [onAddShippingOption])

    if (isLoading) {
      return <FormLoadingPlaceholder numberOfFields={5} />
    }
    const hideDeleteButton = shippingOptions.length < 2

    return (
      <Flex column>
        <Paragraph>Calculate the optimum rate from the following shipping options.</Paragraph>

        <Paragraph>
          If selected, Third-PartyShipping Profiles will be prioritized by the system.
        </Paragraph>
        {shippingOptions.map((option, index) => (
          <ShippingOption
            // @todo remove index
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            option={option}
            index={index}
            hideDeleteButton={hideDeleteButton}
            shippingCarriers={shippingCarriers}
            shippingProfiles={shippingProfiles}
            thirdPartyShippingProfiles={thirdPartyShippingProfiles}
            areShippingProfilesLoading={areShippingProfilesLoading}
            areThirdPartyShippingProfilesLoading={areThirdPartyShippingProfilesLoading}
            areShippingCarriersLoading={areShippingCarriersLoading}
            getShippingOptionsError={() => getShippingOptionsError(index)}
            onChange={onChange}
            onDeleteShippingOption={onDeleteShippingOption}
          />
        ))}
        <Box mt="S">
          <Button transparent size="M" onClick={onClick}>
            <Icon type="plus" />
            Add shipping option
          </Button>
        </Box>
      </Flex>
    )
  },
)
