// @flow

import type { Warehouse } from '@r1/wireframe-primary/build/types/Warehouse'

import { httpClient } from '../../httpClient'

const API_PREFIX = `/ui/api/menu`

type LegacyErrorResponse = {| status: 401 | 403 | 500, body: $AnyObject |}

export type AccountType = {|
  account: {|
    id: number,
    name: string,
    activeWarehouseId: number | null,
    messageCounter: number,
  |},
  warehouses: Warehouse[],
  isFetched: boolean,
|}

const mapResponseToSuccess = res => {
  return {
    status: 200,
    body: res,
  }
}

const mapResponseToError = err => {
  const { status, data } = err.response
  return {
    status,
    body: data,
  }
}

// eslint-disable-next-line import/no-unused-modules
export type R1UserAccountApi = {|
  getAccount: () => Promise<{| status: 200, body: AccountType |} | LegacyErrorResponse>,
|}

export const r1UserAccountApi = {
  getAccount: () => {
    return httpClient
      .get(`${API_PREFIX}/account`)
      .then(mapResponseToSuccess)
      .catch(mapResponseToError)
  },
}
