// @flow

import * as React from 'react'
import { useCallback } from 'react'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Flex, Modal, Placeholder, Link } from '@r1/ui-kit'
import { SubmissionButton } from '@r1/form-manager'
import { ROUTES } from '../../../../../navigation'
import {
  AttributeCreationFormController,
  AttributeCreationFormView,
} from '../../../components/AttributeCreationForm'
import { testIds } from './testIds'

const FORM_ID = 'attributeCreationForm'

type CreateAttributeFormProps = {|
  httpClient: $AnyObject,
  onSubmit: () => void,
  onCancel: () => void,
|}

export const CreateAttributeForm = ({
  httpClient,
  onCancel,
  onSubmit,
}: CreateAttributeFormProps) => {
  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Close',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Got it',
          color: 'danger',
          onClick: () => {
            onCancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onCancel],
  )

  return (
    <Main>
      <ContentHeader />
      <ContentHeader.Breadcrumbs>
        <Link to={ROUTES.ATTRIBUTES_DEFINITIONS}>Attributes</Link>
      </ContentHeader.Breadcrumbs>
      <ContentHeader.Title>Create New Attribute</ContentHeader.Title>

      <Content>
        <ContentHeader.ActionButtons>
          <Modal
            isControlled={false}
            title="All settings you've made will be lost"
            actionButtons={modalActionButtons}
            buttonElement={({ onOpen }) => (
              <Button data-test-id={testIds.cancelButton} color="secondary" onClick={onOpen}>
                Cancel
              </Button>
            )}
          >
            You want to change attribute type. It will destroy all existing settings.
          </Modal>
        </ContentHeader.ActionButtons>
        <Flex column maxWidth={540}>
          <AttributeCreationFormController httpClient={httpClient}>
            {props => {
              if (props.screenType === 'default') {
                return (
                  <>
                    <AttributeCreationFormView
                      formId={FORM_ID}
                      dataSources={props.dataSources}
                      handlers={props.handlers}
                    />
                    <ContentHeader.ActionButtons>
                      <SubmissionButton onSuccessfulSubmit={onSubmit}>
                        {({ handleSubmit, isSubmitting }) => (
                          <Button
                            data-test-id={testIds.submitButton}
                            loading={isSubmitting}
                            onClick={handleSubmit}
                          >
                            Create
                          </Button>
                        )}
                      </SubmissionButton>
                    </ContentHeader.ActionButtons>
                  </>
                )
              }
              return <Placeholder type="form" />
            }}
          </AttributeCreationFormController>
        </Flex>
      </Content>
    </Main>
  )
}
