// @flow

import type {
  AttributeBinding as BindingFromCategoriesApi,
  AttributeValueConstraint as ConstraintFromCategoriesApi,
} from '@r1-webui/productcatalog-categories-v1/src/types'

import type {
  AttributeBinding as BindingFromCategoryManagementApi,
  AttributeValueConstraint as ConstraintFromCategoryManagementApi,
  AttributeValue,
} from '@r1-webui/productcatalog-categorymanagement-v1/src/types'

import type { AttributeDefinitionType } from '../types'

// don't use this for unions!!
function transformConstraintType<T, VC: { valueType: T }>(
  valueConstraint: VC,
): {| ...$Diff<$Exact<VC>, { valueType: T }>, type: T |} {
  const { valueType, ...constraint } = valueConstraint

  // $FlowFixMe[incompatible-exact]
  return { ...constraint, type: valueType }
}

/**
 * transform decimal to string for raml
 */

const transformValue = (value: AttributeValue): AttributeValue => {
  switch (value.valueType) {
    case 'DecimalValue':
      return { valueType: 'DecimalValue', value: value.value.toString() }
    case 'DecimalRange':
      return {
        valueType: 'DecimalRange',
        from: value.from != null ? value.from.toString() : undefined,
        to: value.to != null ? value.to.toString() : undefined,
      }
    default:
      return value
  }
}

/**
 *
 * ))))))))))))))))))
 * @todo rm string cast
 */

function transformConstraint(
  valueConstraint: ConstraintFromCategoriesApi,
): ConstraintFromCategoryManagementApi {
  switch (valueConstraint.valueType) {
    case 'DateConstraint':
      return transformConstraintType(valueConstraint)
    case 'DateRangeConstraint':
      return transformConstraintType(valueConstraint)
    case 'EnumerationConstraint':
      return transformConstraintType(valueConstraint)
    case 'StringConstraint':
      return transformConstraintType(valueConstraint)
    case 'NumberConstraint': {
      const copy = { ...valueConstraint }
      if (copy.forbiddenValues) {
        copy.forbiddenValues =
          copy.forbiddenValues.length > 0 ? copy.forbiddenValues.map(s => String(s)) : []
      }

      if (copy.minAllowedValue != null) {
        copy.minAllowedValue = String(copy.minAllowedValue)
      }

      if (copy.maxAllowedValue != null) {
        copy.maxAllowedValue = String(copy.maxAllowedValue)
      }

      // $FlowFixMe[incompatible-return]
      return transformConstraintType(copy)
    }

    case 'NumberRangeConstraint': {
      const copy = { ...valueConstraint }

      if (copy.minAllowedValue != null) {
        copy.minAllowedValue = String(copy.minAllowedValue)
      }

      if (copy.maxAllowedValue != null) {
        copy.maxAllowedValue = String(copy.maxAllowedValue)
      }

      return transformConstraintType(copy)
    }
    default:
      throw new Error(
        `[product-catalog] unexpected value constraint type: ${valueConstraint.valueType}`,
      )
  }
}

export function transformBinding({
  binding,
  type,
}: {
  binding: BindingFromCategoriesApi,
  type: AttributeDefinitionType,
}): BindingFromCategoryManagementApi {
  const { childBindings: _, attributeDefinition, ...copy } = binding

  if (copy.valueConstraint) {
    // $FlowFixMe[incompatible-type]
    copy.valueConstraint = transformConstraint(copy.valueConstraint)
  }
  if (copy.defaultValue) {
    copy.defaultValue = transformValue(copy.defaultValue)
  }

  copy.type =
    type === 'ProductAttributeDefinition' ? 'ProductAttributeBinding' : 'UnitAttributeBinding'

  // $FlowFixMe[prop-missing]
  copy.attributeDefinitionId = attributeDefinition.id

  return (copy: any)
}
