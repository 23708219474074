// @flow

import { NotificationSystem } from '@r1/ui-kit'

import type { FormManagerClientValidateHandler } from '../types/Form'

export const uiValidators = {
  name: (name: string) => {
    if (!name) {
      return 'Need to be filled'
    } else if (name.length > 250) {
      return 'Need to be less than 251 symbols'
      // Test for duplication should be case-insensitive
    }
    return null
  },

  productIdentifiers: (identifiers: string[]) => {
    if (identifiers.length > 10000) {
      return 'Need to be less than 10000'
    }
    return null
  },

  filterCombination: (filters: Array<string[]>) => {
    if (filters.some(filter => filter.length > 0)) {
      return null
    }
    return 'One of the filters must be specified'
  },

  sameExcludeInclude: (include: string[], exclude: string[]) => {
    if (include.some(value => exclude.includes(value)))
      return 'Field "UPCs (Exclude)" cannot contain the same values as field "UPCs (Include)"'
    return null
  },

  marketplaces: (marketplaces: Array<string>) => {
    if (marketplaces.length > 0) {
      return null
    }
    return 'Need to choose marketplace'
  },
}

export const validate: FormManagerClientValidateHandler = (values, params) => {
  const { name, categories, includeUPC, excludeUPC, marketplaces } = values
  const { invokedBySubmissionButton } = params

  const filtersConsolidatedError = uiValidators.filterCombination([
    categories,
    includeUPC,
    excludeUPC,
  ])

  const errors = {
    name: uiValidators.name(name),
    categories: filtersConsolidatedError,
    includeUPC: uiValidators.productIdentifiers(includeUPC) || filtersConsolidatedError,
    excludeUPC:
      uiValidators.productIdentifiers(excludeUPC) ||
      uiValidators.sameExcludeInclude(includeUPC, excludeUPC) ||
      filtersConsolidatedError,
    marketplaces: uiValidators.marketplaces(marketplaces),
  }

  const errorMessages = new Set()
  Object.values(errors).forEach(error => {
    if (error !== null) {
      errorMessages.add(error)
    }
  })

  if (errorMessages.size > 0) {
    if (invokedBySubmissionButton) {
      errorMessages.forEach(message => {
        if (message !== null) {
          NotificationSystem.addNotification({ title: String(message), level: 'error' })
        }
      })
    }
    return errors
  }

  return null
}
