// @flow

import React, { useState, useEffect, useCallback } from 'react'
import { has } from 'ramda'
import { Table } from '@r1/grid'
import { handleServerError, cookie } from '@r1/core-blocks'
import { Flex, Icon, NotificationSystem, Button } from '@r1/ui-kit'
import { useAccountInfo } from '@r1/wireframe-primary'

import type { VendorPortalApi } from '../../api/api'
import type { ReplacementLabelData } from '../../types/ClaimScreen'

import { printJobIds } from '../../../../utils'
import { TrackingNumber } from '../TrackingNumber'
import { UnitLinks } from '../UnitLinks'
import { ConfirmationModal } from '../ConfirmationModal'

const getTableProps = (claimId, onConfirmSent, onConfirmRejected, onPrintLabel) => {
  const columnsSettings = [
    {
      width: 110,
      header: {
        content: 'Replacement ID',
      },
      cell: {
        $type: 'custom',
        renderer: ({ unitId, productId }) => <UnitLinks unitId={unitId} productId={productId} />,
      },
    },
    {
      header: {
        content: 'Replacement Title',
      },
      cell: {
        $type: 'text',
        field: 'title',
      },
    },
    {
      width: 100,
      header: {
        content: 'Tracking Number',
      },
      cell: {
        $type: 'custom',
        renderer: ({ trackingNumber }) =>
          trackingNumber && <TrackingNumber trackingNumber={trackingNumber} />,
      },
    },
    {
      width: 100,
      header: {
        content: 'Label',
      },
      cell: {
        $type: 'custom',
        renderer: ({ labelId, label, isUnitSent }) =>
          label && (
            <Flex spaceBetween="XXS">
              <Button
                size="S"
                shape="square"
                color="primary"
                disabled={isUnitSent}
                onClick={() => onPrintLabel(labelId)}
              >
                Print
              </Button>
            </Flex>
          ),
      },
    },
    {
      width: 100,
      header: {
        content: 'Confirm',
      },
      horizontalAlign: 'center',
      cell: {
        $type: 'custom',
        renderer: ({ unitId, isUnitSent, sendingStatus, isSendingStatusUpdating }) => {
          // eslint-disable-next-line no-nested-ternary
          return isUnitSent ? (
            sendingStatus || 'Unknown'
          ) : isSendingStatusUpdating ? (
            <Flex>
              <Icon type="loaders/circle" />
            </Flex>
          ) : (
            <Flex spaceBetween="XXS">
              <Button size="S" shape="square" onClick={() => onConfirmSent(unitId)}>
                <Icon color="white" type="checkmark" />
              </Button>
              <Button
                size="S"
                shape="square"
                color="danger"
                onClick={() => onConfirmRejected(unitId)}
              >
                <Icon color="white" type="clear" />
              </Button>
            </Flex>
          )
        },
      },
    },
  ]

  const tableProps = {
    displaySettings: {
      showCounter: false,
      columnsSettings,
    },
  }

  return tableProps
}

const hasLabelId = has('labelId')

type Props = {|
  api: VendorPortalApi,
  claimId: string,
|}

export const ReplacementGrid = ({ api, claimId }: Props) => {
  const account = useAccountInfo()
  const [gridData, setGridData] = useState<ReplacementLabelData[]>([])
  const [loading, setLoading] = useState(false)
  const [isConfirmSentDialogOpen, setIsConfirmSentDialogOpen] = useState(false)
  const [isConfirmRejectedDialogOpen, setIsConfirmRejectedDialogOpen] = useState(false)
  const [currentReplacementUnitId, setCurrentReplacementUnitId] = useState('')

  const printLabel = useCallback(
    async (labelId = '') => {
      const accountId = account.id

      let machineId = cookie.getItem(`UserPrinterSettings${accountId}`)
      if (!machineId) {
        machineId = localStorage.getItem(`ReconMain: MACHINE_ID_${accountId}`)
      }
      if (!machineId) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Please set up printer settings',
        })
        return
      }

      const response = await api.labels.printLabelById({ labelId }, { machineId })
      if (!response || response.status !== 200) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Something wrong with printing',
        })
        return
      }

      const jobId = response.body
      printJobIds({ jobIds: [jobId] })
    },
    [api.labels, account.id],
  )

  const confirmReplacementSent = useCallback(async () => {
    const row = gridData.find(d => d.unitId === currentReplacementUnitId)
    if (!row) return

    row.isSendingStatusUpdating = true
    setGridData([...gridData])

    try {
      const response = await api.claims.setSentReplacementSendingStatus({
        claimId,
        unitId: currentReplacementUnitId,
      })
      if (response && response.status === 200) {
        row.isUnitSent = true
        row.sendingStatus = 'Sent'
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Something wrong with cofirmation of sending operation. Please, try again later.',
        })
      }
    } finally {
      row.isSendingStatusUpdating = false
      setGridData([...gridData])
    }
  }, [api.claims, claimId, currentReplacementUnitId, gridData])

  const confirmReplacementRejected = useCallback(async () => {
    const row = gridData.find(d => d.unitId === currentReplacementUnitId)
    if (!row) return

    row.isSendingStatusUpdating = true
    setGridData([...gridData])

    try {
      const response = await api.claims.setRejectedReplacementSendingStatus({
        claimId,
        unitId: currentReplacementUnitId,
      })
      if (response && response.status === 200) {
        row.isUnitSent = true
        row.sendingStatus = 'Rejected'
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Something wrong with cofirmation of sending operation. Please, try again later.',
        })
      }
    } finally {
      row.isSendingStatusUpdating = false
      setGridData([...gridData])
    }
  }, [api.claims, claimId, currentReplacementUnitId, gridData])

  const closeDialog = useCallback(() => {
    setCurrentReplacementUnitId('')
    setIsConfirmSentDialogOpen(false)
    setIsConfirmRejectedDialogOpen(false)
  }, [])

  const openConfirmSentDialog = useCallback((unitId: string) => {
    setCurrentReplacementUnitId(unitId)
    setIsConfirmSentDialogOpen(true)
  }, [])

  const openConfirmRejectedDialog = useCallback((unitId: string) => {
    setCurrentReplacementUnitId(unitId)
    setIsConfirmRejectedDialogOpen(true)
  }, [])

  const confirmReplacementSentAndClose = useCallback(async () => {
    await confirmReplacementSent()
    closeDialog()
  }, [confirmReplacementSent, closeDialog])

  const confirmReplacementRejectedAndClose = useCallback(async () => {
    await confirmReplacementRejected()
    closeDialog()
  }, [confirmReplacementRejected, closeDialog])

  const tableProps = getTableProps(
    claimId,
    openConfirmSentDialog,
    openConfirmRejectedDialog,
    printLabel,
  )

  useEffect(() => {
    const getLabel = async (labelId = '') => {
      const response = await api.labels.getLabelById({ labelId })
      if (response && response.status === 200) {
        return response.body
      }
      return ''
    }

    setLoading(true)

    const fetchData = async () => {
      const response = await api.claims.getReplacementLabelData({ claimId })

      if (response && response.status === 200) {
        const gridDataWithLabel = await Promise.all(
          response.body.map(async el =>
            hasLabelId(el)
              ? { ...el, label: await getLabel(el.labelId), isSendingStatusUpdating: false }
              : { ...el, label: '', isSendingStatusUpdating: false },
          ),
        )

        setGridData(gridDataWithLabel)
        setLoading(false)
      } else {
        handleServerError(response)
        setLoading(false)
      }
    }

    fetchData()
  }, [api.claims, api.labels, claimId])

  return (
    <>
      <Table loading={loading} {...tableProps} data={gridData} />
      <ConfirmationModal
        show={isConfirmSentDialogOpen}
        message={'Do you really want to mark the replacement as "Sent"?'}
        onConfirm={confirmReplacementSentAndClose}
        onCancel={closeDialog}
      />
      <ConfirmationModal
        show={isConfirmRejectedDialogOpen}
        message={'Do you really want to mark the replacement as "Rejected"?'}
        onConfirm={confirmReplacementRejectedAndClose}
        onCancel={closeDialog}
      />
    </>
  )
}
