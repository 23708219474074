// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { FormField, Flex, HR } from '@r1/ui-kit'

import { Section } from '../../../Section'

import type { ChildAttributes as ChildAttributesMapping } from '../../../../services/categoryAttributesService'

const Label = styled('span')`
  color: ${({ theme }) => theme.palette.grey[900]};
  line-height: 24px;
  font-size: 14px;
  word-break: break-word;
  font-weight: 500;
`

const Attributes = styled('span')`
  color: ${({ theme }) => theme.palette.black[800]};
`

const Values = styled('span')`
  color: ${({ theme }) => theme.palette.black[900]};
  font-weight: normal;
`

type Props = {|
  childAttributes: ChildAttributesMapping,
  as?: 'section' | 'content',
|}

const stringifyAttributes = attributeNames => {
  const clone = [...attributeNames]
  clone.sort()
  return clone.join(',')
}

function groupChildAttributes(
  childAttributes: ChildAttributesMapping,
): [$Keys<ChildAttributesMapping>[], $Values<ChildAttributesMapping>][] {
  const reversedMap = {}
  const arraysMap = {}

  Object.keys(childAttributes).forEach(value => {
    const stringifiedArray = stringifyAttributes(childAttributes[value])
    arraysMap[stringifiedArray] = arraysMap[stringifiedArray] || childAttributes[value]
    reversedMap[stringifiedArray] = reversedMap[stringifiedArray] || []
    reversedMap[stringifiedArray].push(value)
  })

  const result = []

  Object.keys(reversedMap).forEach(arrayString => {
    result.push([reversedMap[arrayString], arraysMap[arrayString]])
  })

  return result
}

function stringifyValues(values) {
  return values
    .map(value => {
      if (value === 'true') {
        return 'Yes'
      }

      if (value === 'false') {
        return 'No'
      }

      return value
    })
    .join(', ')
}

export const ChildAttributes = (props: Props) => {
  const groupedChilden = groupChildAttributes(props.childAttributes)
  const len = groupedChilden.length
  const content = (
    <React.Fragment>
      <Flex>
        <FormField.Label>Linked Value</FormField.Label>
        <Label>Assigned Attributes</Label>
      </Flex>
      <HR />

      {groupedChilden.map(([values, attributeNames], index) => {
        const valuesString = stringifyValues(values)

        return (
          <Flex key={valuesString} column>
            <Flex>
              <FormField.Label>
                <Values>{valuesString}</Values>
              </FormField.Label>
              <Attributes>{attributeNames.join(', ')}</Attributes>
            </Flex>
            {index !== len - 1 ? <HR /> : null}
          </Flex>
        )
      })}
    </React.Fragment>
  )

  return props.as === 'content' ? content : <Section title="Child Attributes">{content}</Section>
}
