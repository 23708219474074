// @flow

import React, { memo } from 'react'
import { DatePicker, FormField } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { DateRangeAttributeChildrenProps } from '../../../../../../types/attribute.type'

const InputRangeWrapper = styled('div')`
  label {
    width: 255px;
  }
`

export const DateRange = memo<DateRangeAttributeChildrenProps>(
  ({
    error,
    title,
    handleChange,
    handleBlur,
    optionIndex,
    disabled,
    value,
    valueConstraint,
  }: DateRangeAttributeChildrenProps) => {
    const min =
      valueConstraint && valueConstraint.minAllowedDate ? valueConstraint.minAllowedDate : undefined
    const max =
      valueConstraint && valueConstraint.maxAllowedDate ? valueConstraint.maxAllowedDate : undefined

    return (
      <FormField>
        <FormField.Label>{title}</FormField.Label>
        <InputRangeWrapper>
          <DatePicker
            range
            min={min}
            placeholderFrom="YYYY-MM-DD"
            placeholderTo="YYYY-MM-DD"
            error={!!error}
            disabled={disabled}
            max={max}
            value={{
              from: value && value.from ? value.from : null,
              to: value && value.to ? value.to : null,
            }}
            onChange={val => {
              ;(handleChange: any)(['Attributes', optionIndex, 'formValue', 'from'])(val.from)
              ;(handleChange: any)(['Attributes', optionIndex, 'formValue', 'to'])(val.to)
            }}
            onRemove={() => {
              ;(handleChange: any)(['Attributes', optionIndex, 'formValue', 'from'])(null)
              ;(handleChange: any)(['Attributes', optionIndex, 'formValue', 'to'])(null)
            }}
            onBlur={handleBlur(['Attributes', optionIndex, 'formValue'])}
          />
        </InputRangeWrapper>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
