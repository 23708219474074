// @flow

import React, { useCallback, useState, useContext, useMemo } from 'react'
import type { AttributeDefinition } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'
import type {
  AttributeBinding,
  InheritedAttributeBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'

import { ServiceContext } from '../../../containers/CategoryContainer/provider'
import { useToggle } from '../../../../../utils/hooks'
import type { ExtendedAttributeDefinition } from '../../../services/categoryAttributesService'
import { CategoryAttributesGridView } from './CategoryAttributesGridView'
import { BindingAttributeDrawer } from './BindingAttributeDrawer'
import { CategoriesAttributeDrawer } from './CategoriesAttributeDrawer'
import { SetupAttributeDrawer } from './SetupAttributeDrawer'
import { getAvailableChildAttributes } from './module/getAvailableChildAttributes'

type CommonProps = {|
  categoryId: string,
  getAttributeDetailsUrl: (id: string) => string,
|}

type Props =
  | { ...CommonProps, mode: 'view' }
  | {
      ...CommonProps,
      mode: 'edit',
      version: string,
      onUpdate: (newVersion: string) => void,
    }

export function CategoryAttributesGrid(props: Props) {
  const currentMode = props.mode || 'view'
  const { categoryAttributesService } = useContext(ServiceContext)
  const [boundAttributes, setBoundAttributes] = useState(([]: ExtendedAttributeDefinition[]))
  const [availableAttributes, setAvailableAttributes] = useState(([]: AttributeDefinition[]))
  const [bindings, setBindings] = useState(([]: AttributeBinding[]))
  const [inheritedBindings, setInheritedBindings] = useState(([]: InheritedAttributeBinding[]))
  const { state: showAddAttributesDrawer, toggle: toggleShowAddAttributesDrawer } = useToggle(false)
  const [attributeDrawerParams, setAttributeDrawerParams] = useState(null)
  const { state: isUpdating, toggle: toggleIsUpdating } = useToggle(false)
  const { state: isLoading, toggle: toggleIsLoading } = useToggle(false)

  const { state: tableLoading, toggle: setTableLoading } = useToggle(false)

  const currentAttribute = useMemo(() => {
    if (attributeDrawerParams) {
      return boundAttributes.find(attr => attr.id === attributeDrawerParams.attributeId)
    }

    return null
  }, [attributeDrawerParams, boundAttributes])

  const toggleAttributeDrawer = useCallback(({ attributeId, mode }) => {
    setAttributeDrawerParams(params => {
      if (params) {
        return null
      }

      return { attributeId, mode }
    })
  }, [])

  const toggleViewBinding = useCallback(
    attributeId => {
      toggleAttributeDrawer({ attributeId, mode: 'view' })
    },
    [toggleAttributeDrawer],
  )

  const toggleEditBinding = useCallback(
    attributeId => {
      toggleAttributeDrawer({ attributeId, mode: 'setup' })
    },
    [toggleAttributeDrawer],
  )

  const getRoot = useCallback(async () => {
    toggleIsLoading(true)
    const attributesInfo = await categoryAttributesService.findAttributesInfoForCategory({
      categoryId: props.categoryId,
    })
    toggleIsLoading(false)

    setBindings(attributesInfo.bindings)
    setInheritedBindings(attributesInfo.inheritedBindings)
    setAvailableAttributes(attributesInfo.availableAttributes)
    setBoundAttributes(attributesInfo.boundAttributes)

    return attributesInfo.boundAttributes.filter(attr => !attr.parentId)
  }, [categoryAttributesService, props.categoryId, toggleIsLoading])

  const getChildren = useCallback(
    parentId => Promise.resolve(boundAttributes.filter(attr => parentId === attr.parentId)),
    [boundAttributes],
  )

  const addAttributes = useCallback(
    (attributeDefinitionIds: string[]) => {
      if (currentMode === 'view') {
        return
      }

      if (attributeDefinitionIds.length === 0) {
        toggleShowAddAttributesDrawer()
        return
      }

      toggleIsUpdating(true)

      categoryAttributesService
        .bindAttributesToCategory({
          categoryId: props.categoryId,
          bindings,
          boundAttributes,
          availableAttributes,
          attributeDefinitionsToAdd: attributeDefinitionIds,

          // $FlowFixMe[prop-missing]
          version: props.version,
        })
        .then(response => {
          toggleIsUpdating(false)

          if (response.status === 200) {
            // $FlowFixMe[prop-missing]
            props.onUpdate(response.body.latestVersion)
          }
        })
    },
    [
      currentMode,
      toggleIsUpdating,
      categoryAttributesService,
      props,
      bindings,
      boundAttributes,
      availableAttributes,
      toggleShowAddAttributesDrawer,
    ],
  )

  const avaiableAttributes = useMemo(() => {
    if (currentAttribute) {
      return getAvailableChildAttributes({
        attributes: boundAttributes,
        bindings,
        inheritedBindings,
        targetAttributeId: currentAttribute.id,
      })
    }

    return []
  }, [currentAttribute, boundAttributes, bindings, inheritedBindings])

  const setupBinding = useCallback(
    ({ binding, childrenUpdated }) => {
      if (currentMode === 'view') {
        return Promise.resolve()
      }
      toggleIsUpdating(true)
      return categoryAttributesService
        .updateBinding({
          categoryId: props.categoryId,
          bindingToUpdate: binding,
          bindings,
          boundAttributes,
          needToUpdateChildBindings: childrenUpdated,

          // $FlowFixMe[prop-missing]
          version: props.version,
        })
        .then(response => {
          toggleIsUpdating(false)
          if (response.status === 200) {
            // $FlowFixMe[prop-missing]
            props.onUpdate(response.body.latestVersion)
          }
        })
    },
    [currentMode, toggleIsUpdating, categoryAttributesService, props, bindings, boundAttributes],
  )

  const onDeleteBinding = useCallback(
    deletedAttributeDefinitionId => {
      toggleIsUpdating(true)
      setTableLoading(true)
      categoryAttributesService
        .deleteBinding({
          categoryId: props.categoryId,
          bindings,
          boundAttributes,
          deletedAttributeDefinitionId,

          // $FlowFixMe[prop-missing]
          version: props.version,
        })
        .then(response => {
          toggleIsUpdating(false)
          setTableLoading(false)
          if (response.status === 200) {
            // $FlowFixMe[prop-missing]
            props.onUpdate(response.body.latestVersion)
          }
        })
    },
    [
      bindings,
      boundAttributes,
      categoryAttributesService,
      props,
      setTableLoading,
      toggleIsUpdating,
    ],
  )

  const mode = attributeDrawerParams ? attributeDrawerParams.mode : null

  return (
    <React.Fragment>
      <CategoryAttributesGridView
        mode={currentMode}
        getRoot={getRoot}
        getChildren={getChildren}
        count={boundAttributes.length}
        getAttributeDetailsUrl={props.getAttributeDetailsUrl}
        externalLoading={tableLoading}
        onAddClick={currentMode === 'edit' ? toggleShowAddAttributesDrawer : null}
        onEditClick={toggleEditBinding}
        onViewDetailsClick={toggleViewBinding}
        onDeleteBinding={onDeleteBinding}
      />

      <BindingAttributeDrawer
        show={showAddAttributesDrawer}
        attributes={availableAttributes}
        attributesIsLoading={isLoading}
        isSubmitting={isUpdating}
        onAddAttributes={addAttributes}
        onClose={toggleShowAddAttributesDrawer}
      />

      {mode === 'view' && currentAttribute && (
        <CategoriesAttributeDrawer
          show={!!currentAttribute}
          attributeDefinition={currentAttribute}
          onClose={() => toggleViewBinding(currentAttribute.id)}
        />
      )}

      {mode === 'setup' && currentAttribute && (
        <SetupAttributeDrawer
          show={!!currentAttribute}
          attributeDefinition={currentAttribute}
          availableChildAttributes={avaiableAttributes}
          isSubmitting={isUpdating}
          onClose={() => toggleEditBinding(currentAttribute.id)}
          onSetupBinding={setupBinding}
        />
      )}
    </React.Fragment>
  )
}
