import { BlockType } from '../../constants'

const PT_IN_INCH = 72
const PRECISION = 1 / 0.05

const round = num => Math.round(num * PRECISION) / PRECISION

export default class UnitsConverter {
  constructor(factor) {
    this.factor = factor
  }

  setZoom(zoom) {
    this.zoom = zoom
  }

  fromModel(num) {
    return num * this.factor * this.zoom
  }

  fromModelPoint(point) {
    return {
      x: this.fromModel(point.x),
      y: this.fromModel(point.y),
    }
  }

  fromModelSize(size) {
    return {
      width: this.fromModel(size.width),
      height: this.fromModel(size.height),
    }
  }

  fromModelFontSize(fontSize) {
    return this.fromModel(fontSize / PT_IN_INCH)
  }

  fromModelRotation = (rotation, type) => {
    return type === BlockType.IMAGE_BLOCK ? (rotation / 180) * Math.PI : (-rotation / 180) * Math.PI
  }

  fromModelOffset = (rotation, size, type) => {
    if (type === BlockType.IMAGE_BLOCK) {
      switch (rotation) {
        case 90:
          return { x: this.fromModel(size.height), y: 0 }
        case 180:
          return { x: this.fromModel(size.width), y: this.fromModel(size.height) }
        case 270:
          return { x: 0, y: this.fromModel(size.width) }
        case 0:
        default:
          return { x: 0, y: 0 }
      }
    } else {
      switch (rotation) {
        case 90:
          return { x: 0, y: 0 }
        case 180:
          return { x: this.fromModel(size.width), y: this.fromModel(size.height) }
        case 270:
          return { x: this.fromModel(size.height), y: -this.fromModel(size.height) }
        case 0:
        default:
          return { x: 0, y: 0 }
      }
    }
  }

  fromModelTransform(transform) {
    return {
      position: this.fromModelPoint(transform.position),
      rotation: this.fromModelRotation(transform.rotation, transform.type),
      size: this.fromModelSize(transform.size),
      offset: this.fromModelOffset(transform.rotation, transform.size, transform.type),
      zoom: this.zoom,
    }
  }

  toModel(num) {
    return round(num / this.factor / this.zoom)
  }

  toModelPoint(point) {
    return {
      x: this.toModel(point.x),
      y: this.toModel(point.y),
    }
  }
}
