import * as React from 'react'
import styled from '@emotion/styled'
import { SortableContainer } from 'react-sortable-hoc'
import { forEach } from 'ramda'
import { Button, withTheme, PhotoMaker, Flex, Icon } from '@r1/ui-kit'
import uuid from 'uuid'
import { compose, withProps, withState, withHandlers, defaultProps } from 'recompose'
import { ImageWrapperEnhanced as ImageWrapper } from './ImageWrapper'

const Container = styled('div')`
  & > * + * {
    margin-top: 15px;
  }
`

const List = styled('div')`
  background-color: ${({ theme }) => theme.palette.white};
  display: inline-block;
  max-width: 100%;
  padding: 0 16px 16px 0;
  user-select: none;
`

const enhance = compose(
  defaultProps({
    axis: 'xy',
    distance: 1,
    actives: [],
  }),
  SortableContainer,
  withTheme,
  withState('refInput', 'setRefInput', null),
  withState('photomaker', 'togglePhotomaker', false),
  withProps(({ onUpload }) => ({
    onImgLoaded: ({ target: { result } }) => {
      onUpload({ id: uuid.v4(), src: result })
    },
    onImgAdd: src => {
      onUpload({ id: uuid.v4(), src })
    },
  })),
  withHandlers({
    closePhotomaker:
      ({ togglePhotomaker }) =>
      _ =>
        togglePhotomaker(false),
    openPhotomaker:
      ({ togglePhotomaker }) =>
      _ =>
        togglePhotomaker(true),
    openUploader:
      ({ refInput }) =>
      _ =>
        refInput.click(),
    onInputFile:
      ({ refInput, onImgLoaded }) =>
      () => {
        forEach(
          file => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = onImgLoaded
          },
          [...refInput.files],
        )
        // eslint-disable-next-line
      refInput.value = null
      },
  }),
)

class ListingImages extends React.PureComponent {
  drawImagesList() {
    const { list, size, actives, buttons } = this.props

    if (Array.isArray(list) === false) {
      return null
    }

    const nodes = new Array(list.length)

    for (let index = 0, image; index < list.length; index += 1) {
      image = list[index]

      if (image) {
        nodes.push(
          <ImageWrapper
            key={image.id}
            id={image.id}
            src={image.src}
            url={image.url}
            width={image.width}
            height={image.height}
            size={size}
            index={index}
            active={actives.includes(index)}
            buttons={buttons}
          />,
        )
      }
    }

    return nodes
  }

  render() {
    const {
      onImgAdd,
      theme,
      list,
      setRefInput,
      openUploader,
      onInputFile,
      photomaker,
      closePhotomaker,
      openPhotomaker,
      onUpload,
      disabled,
    } = this.props

    return (
      <Container>
        {list.length > 0 && <List>{this.drawImagesList()}</List>}
        {onUpload && (
          <Flex spaceBetween="XS">
            <Button disabled={disabled} onClick={openPhotomaker}>
              &nbsp;&nbsp;MAKE PHOTO
              <Icon type="photo" size="M" color={theme.palette.white} />
            </Button>
            <Button transparent disabled={disabled} onClick={openUploader}>
              &nbsp;&nbsp;UPLOAD PHOTO
              <Icon type="uploadToCloud" size="M" color={theme.palette.blue[600]} />
            </Button>
          </Flex>
        )}
        <PhotoMaker show={photomaker} onClose={closePhotomaker} onPhotoCreate={onImgAdd} />
        <input
          ref={setRefInput}
          hidden
          multiple
          type="file"
          accept="image/*"
          onChange={onInputFile}
        />
      </Container>
    )
  }
}

export const ListingImagesEnhanced = enhance(ListingImages)
