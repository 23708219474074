// @flow

// Common styled components

import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

export const Property = styled('span')`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-right: ${({ theme }) => theme.space.XS};
`

export const OrderInfoWrapper = styled(Flex)`
  padding: ${({ theme }) => theme.space.L};
  overflow-x: hidden;
  border-radius: 2px;
  background: white;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  box-shadow: rgb(19 41 63 / 20%) 0px 1px 2px 0px;
  width: 100%;
`

export const InfoBlockTitle = styled(Flex)`
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.space.S};
`
