import * as React from 'react'
import { FormField, Select, TreeSelect, Flex, NumericInput } from '@r1/ui-kit'
import { RuleConstraints } from '@r1-webui/shipping-returnshippingrules-v1'
import { Paragraph, Subheader } from '../components'
import { Profile } from '../../../../services/espmService'
import { useRuleConstrainsHandlers } from '../../hooks/useRuleConstrainsHandlers'
import { FormLoadingPlaceholder } from '../../../../../../components/FormLoadingPlaceholder'
import { ProgramTreeItem } from '../../../../services/helpers'

type Props = {
  isLoading: boolean
  ruleConstraints: RuleConstraints
  marketplaces: Profile[]
  programs: ProgramTreeItem[]
  areMarketplacesLoading: boolean
  areProgramsLoading: boolean
  marketplaceProfilesIdsError: string | null | undefined
  weightRangeError: string | null | undefined
  dimensionalWeightRangeError: string | null | undefined
  salePriceRangeError: string | null | undefined
  maximumShippingPricePercentError: string | null | undefined
  maximumShippingPriceError: string | null | undefined
  onChange: (v: Record<string, unknown>) => void
}

export const RuleConstraintsSection = React.memo(
  ({
    isLoading,
    ruleConstraints,
    programs,
    marketplaces,
    areMarketplacesLoading,
    areProgramsLoading,
    marketplaceProfilesIdsError,
    weightRangeError,
    dimensionalWeightRangeError,
    salePriceRangeError,
    maximumShippingPricePercentError,
    maximumShippingPriceError,
    onChange,
  }: Props) => {
    const weightRangeMeasure = ruleConstraints.weightRange?.measure ?? 'Lb'
    const dimensionalWeightRangeMeasure = ruleConstraints.dimensionalWeightRange?.measure ?? 'Lb'
    const salesPriceCurrency = ruleConstraints.salePriceRange?.currency || 'USD'
    const maximumShippingPriceCurrency = ruleConstraints.maximumShippingPrice?.currency ?? 'USD'

    const {
      onChangeMarketplaces,
      onChangePrograms,
      onChangeWeightRangeFrom,
      onChangeWeightRangeTo,
      onChangeSalesRangeFrom,
      onChangeSalesRangeTo,
      onChangeWeightDimensionFrom,
      onChangeWeightDimensionTo,
      onChangeMaximumShippingPrice,
      onChangeMaximumShippingPricePercent,
    } = useRuleConstrainsHandlers(
      ruleConstraints,
      onChange,
      weightRangeMeasure,
      salesPriceCurrency,
      dimensionalWeightRangeMeasure,
      maximumShippingPriceCurrency,
    )

    if (isLoading) {
      return <FormLoadingPlaceholder numberOfFields={5} />
    }

    return (
      <>
        <Paragraph>Restrict rule effect to the following scope:</Paragraph>

        <Flex mt="L" mb="L">
          <Subheader>Ownership Constraints</Subheader>
        </Flex>

        <FormField>
          <FormField.Label>Marketplace Seller Profiles</FormField.Label>
          <Select
            multiple
            data-test-id="marketplace-seller-profiles"
            loading={areMarketplacesLoading}
            value={ruleConstraints.marketplaceProfilesIds}
            options={marketplaces}
            error={!!marketplaceProfilesIdsError}
            onChange={onChangeMarketplaces}
          />
          <FormField.Error>{marketplaceProfilesIdsError}</FormField.Error>
        </FormField>

        <FormField>
          <FormField.Label>Programs</FormField.Label>
          <TreeSelect
            multiple
            data-test-id="programs"
            loading={areProgramsLoading}
            value={ruleConstraints.programsIds}
            options={programs}
            onChange={ids => onChangePrograms(ids, programs)}
          />
          <FormField.Error />
        </FormField>

        <Flex mt="L" mb="L">
          <Subheader>Product Constraints</Subheader>
        </Flex>

        <FormField>
          <FormField.Label>Weight Range</FormField.Label>
          <Flex spaceBetween="S" align="center">
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="weight-range-from"
                value={ruleConstraints.weightRange?.from ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!weightRangeError}
                onChange={onChangeWeightRangeFrom}
              />
            </Flex>
            <Paragraph>to</Paragraph>
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="weight-range-to"
                value={ruleConstraints.weightRange?.to ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!weightRangeError}
                onChange={onChangeWeightRangeTo}
              />
            </Flex>
            <Paragraph>{weightRangeMeasure}</Paragraph>
          </Flex>
          <FormField.Error>{weightRangeError}</FormField.Error>
        </FormField>
        <FormField>
          <FormField.Label>Dimensional Weight Range</FormField.Label>
          <Flex align="center" spaceBetween="S">
            <Flex maxWidth={100} minWidth={100}>
              <FormField>
                <NumericInput
                  data-test-id="dimensional-weight-range-from"
                  value={ruleConstraints.dimensionalWeightRange?.from ?? null}
                  minFractionDigits={0}
                  maxFractionDigits={0}
                  min={0}
                  error={!!dimensionalWeightRangeError}
                  onChange={onChangeWeightDimensionFrom}
                />
              </FormField>
            </Flex>
            <Paragraph>to</Paragraph>
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="dimensional-weight-range-to"
                value={ruleConstraints.dimensionalWeightRange?.to ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!dimensionalWeightRangeError}
                onChange={onChangeWeightDimensionTo}
              />
            </Flex>
            <Paragraph>{dimensionalWeightRangeMeasure}</Paragraph>
          </Flex>
          <FormField.Error>{dimensionalWeightRangeError}</FormField.Error>
        </FormField>

        <Flex mt="L" mb="L">
          <Subheader>Pricing Constraints</Subheader>
        </Flex>

        <FormField>
          <FormField.Label>Sales Price Range</FormField.Label>
          <Flex align="center" spaceBetween="S">
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="sale-price-range-from"
                value={ruleConstraints.salePriceRange?.from ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!salePriceRangeError}
                onChange={onChangeSalesRangeFrom}
              />
            </Flex>
            <Paragraph>to</Paragraph>
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="sale-price-range-to"
                value={ruleConstraints.salePriceRange?.to ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!salePriceRangeError}
                onChange={onChangeSalesRangeTo}
              />
            </Flex>
            <Paragraph>{salesPriceCurrency}</Paragraph>
          </Flex>
          <FormField.Error>{salePriceRangeError}</FormField.Error>
        </FormField>
        <FormField>
          <FormField.Label>Maximum Shipping Price</FormField.Label>
          <Flex align="center" spaceBetween="S">
            <Flex maxWidth={100} minWidth={100}>
              <NumericInput
                data-test-id="maximum-shipping-price"
                value={ruleConstraints.maximumShippingPrice?.amount ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!maximumShippingPriceError}
                onChange={onChangeMaximumShippingPrice}
              />
            </Flex>
            <Paragraph>{maximumShippingPriceCurrency}</Paragraph>
          </Flex>
          <FormField.Error>{maximumShippingPriceError}</FormField.Error>
        </FormField>
        <FormField>
          <FormField.Label>Maximum Shipping Price (% of Sales Price)</FormField.Label>
          <Flex align="center" spaceBetween="S">
            <Flex maxWidth={100} minWidth={100} mr="XS">
              <NumericInput
                data-test-id="maximum-shipping-price-percent"
                value={ruleConstraints.maximumShippingPricePercent ?? null}
                minFractionDigits={0}
                maxFractionDigits={0}
                min={0}
                error={!!maximumShippingPricePercentError}
                onChange={onChangeMaximumShippingPricePercent}
              />
            </Flex>
            <Paragraph>%</Paragraph>
          </Flex>
          <FormField.Error>{maximumShippingPricePercentError}</FormField.Error>
        </FormField>
      </>
    )
  },
)
