import * as React from 'react'
import styled from '@emotion/styled'
import { Image, Flex, H1, Icon, Button } from '@r1/ui-kit'
import { ProductHistoryModal } from '../ProductHistoryModal'

const CardHeaderWrapper = styled(Flex)`
  position: relative;
`

export const CardHeader = ({
  title,
  topContent,
  imageUrl,
  children,
  fetchData,
  historyDialogTitle,
  dataTestId,
}) => {
  const [showHistoryModal, setShowHistoryModal] = React.useState(false)

  const closeHistoryModal = React.useCallback(
    () => setShowHistoryModal(false),
    [setShowHistoryModal],
  )

  const openHistoryModal = React.useCallback(() => setShowHistoryModal(true), [setShowHistoryModal])

  return (
    <>
      <CardHeaderWrapper spaceBetween="M" p="L" data-test-id="content-card-header">
        <Flex column minWidth={240} maxWidth={240}>
          {imageUrl ? <Image width={240} height={240} src={imageUrl} /> : null}
        </Flex>
        <Flex column spaceBetween="M" basis={0.85}>
          <Flex justify="flex-end">
            <Button transparent size="M" shape="square" onClick={openHistoryModal}>
              <Icon type="history" size="S" cursor="pointer" />
            </Button>
          </Flex>

          <div>{topContent}</div>
          <H1>{title}</H1>
          <div>{children}</div>
        </Flex>
      </CardHeaderWrapper>
      <ProductHistoryModal
        title={historyDialogTitle}
        show={showHistoryModal}
        fetchHistory={fetchData}
        dataTestId={dataTestId}
        onClose={closeHistoryModal}
      />
    </>
  )
}
