import {
  DiscountRule as ApiDiscountRule,
  DiscountRuleSettings,
} from '@r1-webui/discountsrulesmanagement-v1'

import { DiscountRule } from '../../types/DiscountRule'

const fromState = (discountRule: DiscountRule): DiscountRuleSettings => {
  return {
    name: discountRule.name,
    categoryIds: discountRule.categories,
    includeUPCs: discountRule.includeUPC,
    excludeUPCs: discountRule.excludeUPC,
    steps: discountRule.steps.map(step => ({
      fromQuantity: step.count,
      percent: step.percent,
    })),
    marketplaceProfileIds: discountRule.marketplaces,
  }
}

const toState = (discountsSettings: ApiDiscountRule): DiscountRule => {
  return {
    name: discountsSettings.settings.name,
    categories: discountsSettings.settings.categoryIds,
    includeUPC: discountsSettings.settings.includeUPCs,
    excludeUPC: discountsSettings.settings.excludeUPCs,
    steps: discountsSettings.settings.steps.map(step => ({
      count: step.fromQuantity,
      percent: step.percent,
    })),
    marketplaces: discountsSettings.settings.marketplaceProfileIds,
    updatedOn: discountsSettings.updatedOn,
    priority: discountsSettings.priority,
    version: discountsSettings.version,
    id: discountsSettings.ruleId,
  }
}
export const discountRuleMapper = { toState, fromState }
