// @flow

import React, { useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { Link } from '@r1/ui-kit'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Wizard } from '../../wizard'
import { handleError } from '../../../../modules/utils'
import type { CreateProfileScreenProps } from '../../types/CreateProfileScreen'
import { getPurposeType } from '../ProfileListScreen/helpers'

export const CreateProfileScreen = ({
  httpClient,
  profileTypeId,
  purposeId,
  onError,
  onCreationComplete,
}: CreateProfileScreenProps) => {
  const [purposeName, setPurposeName] = useState<string>('')

  useEffect(() => {
    getPurposeType({ httpClient, purposeId, onError }).then(initialData =>
      setPurposeName(initialData.purposeInfo.name),
    )
  }, [httpClient, purposeId, onError])

  const errorHandler = (error = {}) => {
    const { message = '', $type } = error

    if ($type === 'Unauthorized' && onError) {
      onError({ $type: 'Unauthorized', message })
    } else {
      handleError(error)
    }
  }
  const nextStepHandler = (step = {}) => {
    const { informationPopupMarkdown } = step

    if (
      informationPopupMarkdown &&
      informationPopupMarkdown.indexOf('saved') > -1 &&
      onCreationComplete
    ) {
      onCreationComplete()
    }
  }

  return (
    <Main>
      <ContentHeader />
      {!isEmpty(purposeName) && (
        <ContentHeader.Breadcrumbs>
          <Link to="/r1/admin/profiles">External Service Integrations</Link>
          <Link to={`/r1/admin/profiles/${purposeId}`}>{purposeName}</Link>
        </ContentHeader.Breadcrumbs>
      )}
      <ContentHeader.Title />
      <Content>
        <Wizard
          httpClient={httpClient}
          wizard={profileTypeId}
          baseUrlPrefix="/uisvc/espm"
          onNextStep={nextStepHandler}
          onError={errorHandler}
        />
      </Content>
    </Main>
  )
}
