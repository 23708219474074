import styled from '@emotion/styled'

import * as React from 'react'
import { Loader as UiKitLoader } from '@r1/ui-kit'

export const Overlay = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  width: 100%;
  height: 600px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`

export const Loader = () => {
  return (
    <Overlay>
      <UiKitLoader />
    </Overlay>
  )
}
