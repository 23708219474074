// @flow

import React from 'react'
import { Button, Icon } from '@r1/ui-kit'
import { HistoryModal } from '@r1/core-blocks'
import type { CategoryViewScreenProps } from '../../../types/public/CategoryViewScreen'
import { CategoryContainer } from '../CategoryContainer'
import { CategoryView } from '../children/CategoryView'

export const CategoryViewScreen = (props: CategoryViewScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const HistoryOpenBtn = ({ onOpen }) => {
    return (
      <Button transparent onClick={onOpen}>
        <Icon type="history" />
      </Button>
    )
  }

  return (
    <CategoryContainer httpClient={props.httpClient}>
      <CategoryView
        getAttributeDetailsUrl={props.getAttributeDetailsUrl}
        categoryId={props.categoryId}
      >
        {({ category: { name = '' } }) => (
          <>
            <Title>{`Category Details: ${name}`}</Title>
            <ActionButtons>
              <Button color="secondary" onClick={props.onClose}>
                Close
              </Button>
            </ActionButtons>
            <ActionButtons>
              <HistoryModal
                isControlled={false}
                title={`Category ${name} History`}
                httpClient={props.httpClient}
                getHistoryReceivingToken={() => Promise.resolve('')}
                buttonElement={HistoryOpenBtn}
              />
            </ActionButtons>
          </>
        )}
      </CategoryView>
    </CategoryContainer>
  )
}
