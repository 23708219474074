// @flow

import type { ShapeAttributeValue } from '../types'
import { isRangeValue } from './isRangeValue'

export const valueIsEmpty = (defaultValue: ShapeAttributeValue): boolean => {
  if (isRangeValue(defaultValue)) return defaultValue.from == null && defaultValue.to == null
  return (
    typeof defaultValue.value === 'undefined' ||
    defaultValue.value == null ||
    defaultValue.value === '' ||
    defaultValue.value === 'null' // for select
  )
}
