import { AllowedPermissions } from '@r1/core-blocks/typings/UserStateProvider/useAccessControl'
import { GwsPageEditorPermission } from './gws-page-editor'
import { GwsNotificationsUserRole } from './types'

export const getNotificationsUserRole = (
  allowedPermissions: AllowedPermissions,
): GwsNotificationsUserRole | null => {
  if (allowedPermissions.allowGwsNotificationsEditAdminSettings) {
    return 'super_admin'
  }
  if (allowedPermissions.allowGwsNotificationsEdit) {
    return 'editor'
  }
  if (allowedPermissions.allowGwsNotificationsView) {
    return 'viewer'
  }
  return null
}

export const getNotificationsAutologinUrl = ({
  baseUrl,
  apiKey,
  userName,
  userEmail,
  allowedPermissions,
}: {
  baseUrl: string
  apiKey: string
  userName: string
  userEmail: string | null
  allowedPermissions: AllowedPermissions
}): string | null => {
  const userRole = getNotificationsUserRole(allowedPermissions)
  if (!userRole) {
    return null
  }

  const loginParams = new URLSearchParams({
    api_key: apiKey,
    name: userName,
    role: userRole,
  })

  if (userEmail) {
    loginParams.append('email', userEmail)
  }

  return `${baseUrl}/autologin?${loginParams}`
}

export const getPageEditorPermission = (
  allowedPermissions: AllowedPermissions,
): GwsPageEditorPermission | null => {
  if (allowedPermissions.allowGwsHomePageEditorEdit) {
    return 'edit'
  }
  if (allowedPermissions.allowGwsHomePageEditorView) {
    return 'view'
  }
  return null
}
