// @flow

import React from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Modal, Text } from '@r1/ui-kit'

type Props = {|
  show: boolean,
  onConfirm: () => void,
  onCancel: () => void,
|}

export const ConfirmationModal = ({ show, onConfirm, onCancel }: Props) => {
  const modalActionButtons: BottomActionButton[] = [
    {
      title: 'Cancel',
      color: 'secondary',
      onClick: onCancel,
      align: 'right',
    },
    {
      title: 'Confirm',
      onClick: onConfirm,
      align: 'right',
    },
  ]

  return (
    <Modal
      isControlled
      show={show}
      title="Are you sure?"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onCancel}
    >
      <Text type="paragraph">
        You are going to start the process of synchronization of big amount of data. This process
        could take a while. Do you want to continue?
      </Text>
    </Modal>
  )
}
