// @flow

import React, { memo } from 'react'
import { Flex, FormField, HR, Link } from '@r1/ui-kit'
import { Value } from '../../Common'

import type { ProductFieldsProps } from '../../../../../../types/product.type'

function mapKeyToLabel(key: string): string {
  const schema = {
    title: 'Title',
    categoryPath: 'Category',
    model: 'Model',
    brand: 'Brand',
    manufacturer: 'Manufacturer',
    countryOfOrigin: 'Country of Origin',
    description: 'Description',
    url: 'URL',
    features: 'Features',
  }

  return schema[key]
}

const REG_LINK = /(http(s?)):\/\//gi

function extractValue(field: any, key) {
  switch (key) {
    case 'description':
      return <span dangerouslySetInnerHTML={{ __html: field }} />
    case 'categoryPath':
      return field.join(' / ')
    case 'url':
      return (
        <Link target="_blank" href={REG_LINK.test(field) ? field : `//${field}`}>
          {field}
        </Link>
      )
    default:
      return field
  }
}

export const BasicInfo = memo<ProductFieldsProps>((props: ProductFieldsProps) => {
  const { fields } = props

  const mappedFields = Object.keys(fields).reduce((acc, key) => {
    const field = fields[key]
    const label = mapKeyToLabel(key)
    if (label && field && field.length !== 0) acc.push({ key, label, field })
    return acc
  }, [])

  return mappedFields.map(({ key, label, field }, index) => {
    return (
      <Flex key={key}>
        {index !== 0 ? <HR /> : null}
        <Flex spaceBetween="XXS">
          <FormField.Label>{label}</FormField.Label>
          <Value>{extractValue(field, key)}</Value>
        </Flex>
      </Flex>
    )
  })
})
