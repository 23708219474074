// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import {
  Checkbox,
  Flex,
  Button,
  Select,
  FormField,
  TreeSelect,
  Text,
  Modal,
  NotificationSystem,
} from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import type { FilterStatus } from '@r1-webui/datasourceinfo-categorymapping-v1/src/types'
import { Loader } from '../../../../components/Loader'
import { CategoryMappingTable, MIN_PAGE_SIZE } from './CategoryMappingTable'
import type { PaginationType } from './CategoryMappingTable'
import * as api from './api'

const CheckboxContainer = styled('div')`
  & > label > div {
    width: 44px;
    height: 44px;
    max-width: 44px;
    max-height: 44px;
  }
`

const statusOptions: $ReadOnlyArray<$AnyObject> = [
  { id: 'Mapped', name: 'Mapped' },
  { id: 'NotMapped', name: 'Not mapped' },
]

export const CategoryMapping = () => {
  // requested data state
  const [dataSources, setDataSources] = React.useState<$ReadOnlyArray<$AnyObject>>([])
  const [r1Categories, setR1Categories] = React.useState<$ReadOnlyArray<$AnyObject>>([])
  const [categoryTrees, setCategoryTrees] = React.useState<$ReadOnlyArray<$AnyObject>>([])
  const [dataSourceCategories, setDataSourceCategories] = React.useState<
    $ReadOnlyArray<$AnyObject>,
  >([])

  // modal state
  const [actionModal, setActionModal] = React.useState<boolean>(false)
  const [actionModalType, setActionModalType] = React.useState<'map' | 'delete'>('map')
  const [actionsDisabled, setActionsDisabled] = React.useState<boolean>(false)
  const [modalTitle, setModalTitle] = React.useState<string>('')

  // control data state
  const [dataSource, setDataSource] = React.useState<string>('')
  const [r1Category, setR1Category] = React.useState<string | number | null>('')
  const [dataSourceCategory, setDataSourceCategory] = React.useState<string[] | number[] | null>(
    null,
  )
  const [status, setStatus] = React.useState<FilterStatus | null>(null)
  const [selectAllCategories, toggleSelectAllCategories] = React.useState<boolean>(false)

  // data for table
  const [dataSourceMappings, setDataSourceMappings] = React.useState([])
  const [computedCategories, setComputedCategories] = React.useState<$ReadOnlyArray<$AnyObject>>([])
  const [isAllSelected, setIsAllSelected] = React.useState<boolean>(false)
  const [selectedIds, setSelectedIds] = React.useState<string[]>([])
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(MIN_PAGE_SIZE)
  const [itemsCount, setItemsCount] = React.useState(0)

  // utility state
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isMarketplaceSelected, setIsMarketplaceSelected] = React.useState(true)

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedDataSources = await api.getAllDataProviders()
      const fetchedR1Categories = await api.getAllCategories()
      const fetchedCategoryTrees = await api.getAllCategoryTrees()

      setDataSources(
        fetchedDataSources
          ? fetchedDataSources.map(({ title, ...ds }) => ({ ...ds, name: title }))
          : [],
      )

      setR1Categories(fetchedR1Categories || [])
      setCategoryTrees(fetchedCategoryTrees || [])
    }

    fetchData()
  }, [])

  const fetchDataSourceMappings = async (dataProviderId: string) => {
    const fetchedDataSourceMappings = await api.getDataProviderMappings({ dataProviderId })
    setDataSourceMappings(fetchedDataSourceMappings || [])
  }

  React.useEffect(() => {
    let ignore = false

    const fetchDataSourceCategories = async (dataProviderId: string) => {
      setIsLoading(true)
      try {
        const categoryTree = categoryTrees.find(ct => ct.dataSourceId === dataProviderId)

        const fetchedDataSourceCategories = categoryTree
          ? await api.getAllTreeCategories({ categoryTreeId: categoryTree.id })
          : []

        await fetchDataSourceMappings(dataSource)
        if (!ignore) {
          setDataSourceCategories(fetchedDataSourceCategories || [])
        }
      } finally {
        setIsLoading(false)
      }
    }

    const matchedDataSource = dataSources.find(ds => ds.id === dataSource) || {}

    if (matchedDataSource.dataSourceId) {
      fetchDataSourceCategories(matchedDataSource.dataSourceId)
    }

    return () => {
      ignore = true
    }
  }, [categoryTrees, dataSource, dataSources])

  const resetFilter = () => {
    setSelectedIds([])
    setIsAllSelected(false)
  }

  const onDataSourceChange = value => {
    setIsMarketplaceSelected(true)
    setDataSource(value)
    setItemsCount(0)
    toggleSelectAllCategories(false)
    setDataSourceCategory(null)
    setComputedCategories([])
    resetFilter()
  }

  const onR1CategoryChange = (value: string | number) => {
    setR1Category(value)
  }

  const fetchChildPaths = React.useCallback(async () => {
    if (!dataSource) return
    let childPaths = null
    const dataSourceCategoriesIds = dataSourceCategory ? dataSourceCategory.map(id => `${id}`) : []
    setIsLoading(true)
    try {
      const skip = pageSize * (page - 1)
      if (status) {
        childPaths = await api.getChildsPath({
          dataProviderId: `${dataSource}`,
          isAllSelected: selectAllCategories,
          includedCategoryIds: selectAllCategories ? [] : dataSourceCategoriesIds,
          excludedCategoryIds: selectAllCategories ? dataSourceCategoriesIds : [],
          skip,
          take: pageSize,
          filterByStatus: status,
        })
      } else {
        childPaths = await api.getChildsPath({
          dataProviderId: `${dataSource}`,
          isAllSelected: selectAllCategories,
          includedCategoryIds: selectAllCategories ? [] : dataSourceCategoriesIds,
          excludedCategoryIds: selectAllCategories ? dataSourceCategoriesIds : [],
          skip,
          take: pageSize,
        })
      }
    } finally {
      setComputedCategories(
        childPaths ? childPaths.categories.reduce((acc, val) => acc.concat(val.childs), []) : [],
      )
      setItemsCount(childPaths ? childPaths.totalChilds : 0)
      setIsLoading(false)
    }

    resetFilter()
  }, [dataSource, page, pageSize, selectAllCategories, dataSourceCategory, status])

  React.useEffect(() => {
    fetchChildPaths()
  }, [fetchChildPaths, dataSource, page, pageSize, selectAllCategories, dataSourceCategory, status])

  const onDataSourceCategoryChange = (value: string[] | number[]) => {
    setDataSourceCategory(value)
  }

  const onStatusChange = (value: FilterStatus | null) => {
    setStatus(value)
  }

  const onChangePagination = (pagination: PaginationType) => {
    setPage(pagination.page)
    setPageSize(pagination.pageSize)
  }

  const handleMapCategories = React.useCallback(async () => {
    if (!r1Category) {
      return
    }

    setActionsDisabled(true)

    const settedMappings = selectedIds.map(id => ({
      dataProviderCategoryId: `${id}`,
      catalogCategoryId: `${r1Category}`,
    }))

    await api.setCategoryMapping({ dataProviderId: `${dataSource}`, mappings: settedMappings })
    await fetchDataSourceMappings(dataSource)

    if (status) {
      await fetchChildPaths()
    }

    setActionModal(false)
    setActionsDisabled(false)
  }, [dataSource, fetchChildPaths, r1Category, selectedIds, status])

  const handleDeleteCategories = React.useCallback(async () => {
    setActionsDisabled(true)

    const selectedMappings = dataSourceMappings.filter(({ dataProviderCategoryId }) =>
      selectedIds.includes(dataProviderCategoryId),
    )

    await api.deleteCategoryMapping({ dataProviderId: dataSource, mappings: selectedMappings })
    await fetchDataSourceMappings(dataSource)
    await fetchChildPaths()

    setActionModal(false)
    setActionsDisabled(false)
  }, [dataSource, dataSourceMappings, fetchChildPaths, selectedIds])

  const marketplace = dataSources.find(({ id }) => id === dataSource)
  const marketplaceName = marketplace ? marketplace.name : ''
  const mapDisabled = actionsDisabled || !r1Category

  const deleteDisabled =
    actionsDisabled ||
    dataSourceMappings.filter(({ dataProviderCategoryId }) =>
      selectedIds.includes(dataProviderCategoryId),
    ).length === 0

  const toggleActionModal = React.useCallback(() => setActionModal(!actionModal), [actionModal])

  const actionItems = [
    {
      id: 'setCategoryMappings',
      title: <Text>Map to R1 category</Text>,
    },
    {
      id: 'deleteCategoryMappings',
      title: <Text>Remove mapping from R1 category</Text>,
    },
  ]

  const handleAction = id => {
    if (id === actionItems[0].id) {
      setActionModalType('map')
      setModalTitle('Map to R1 category')
      toggleActionModal()
    } else {
      setActionModalType('delete')
      setModalTitle('Do you want to delete mappings from R1 category?')
      toggleActionModal()
    }
  }

  const handleSelectAllCategories = () => {
    if (selectAllCategories) {
      toggleSelectAllCategories(false)
      setItemsCount(0)
      setDataSourceCategory(null)
      setComputedCategories([])
    } else {
      toggleSelectAllCategories(true)
    }
    resetFilter()
  }

  const handleExport = React.useCallback(async () => {
    if (!dataSource) {
      setIsMarketplaceSelected(false)
      return
    }
    setIsLoading(true)
    const matchedDataSource = dataSources.find(ds => ds.id === dataSource)
    if (!matchedDataSource || !matchedDataSource.dataSourceId) {
      NotificationSystem.addNotification({
        level: 'error',
        message: 'Data source did not found',
      })
      return
    }
    const categoryTree = categoryTrees.find(
      ct => ct.dataSourceId === matchedDataSource.dataSourceId,
    )
    if (!categoryTree || !categoryTree.id) {
      NotificationSystem.addNotification({
        level: 'error',
        message: 'Category tree did not found',
      })
      return
    }

    // $FlowFixMe[incompatible-call]
    const downloadLinkToken = await api.getDowloadLinkToken({ categoryTreeId: categoryTree.id })

    window.open(`/uisvc/private-file-storage/download/api/v1/files/${downloadLinkToken}`, '_blank')

    setIsLoading(false)
  }, [categoryTrees, dataSource, dataSources])

  const mapModalActionButtons = React.useMemo(() => {
    return [
      {
        align: 'right',
        title: 'Cancel',
        color: 'secondary',
        onClick: toggleActionModal,
      },
      {
        align: 'right',
        title: 'Confirm',
        color: 'primary',
        disabled: mapDisabled,
        onClick: handleMapCategories,
      },
    ]
  }, [handleMapCategories, mapDisabled, toggleActionModal])

  const deleteModalActionButtons = React.useMemo(() => {
    return [
      {
        align: 'right',
        title: 'Cancel',
        color: 'secondary',
        onClick: toggleActionModal,
      },
      {
        align: 'right',
        title: 'Delete',
        color: 'danger',
        disabled: deleteDisabled,
        onClick: handleDeleteCategories,
      },
    ]
  }, [deleteDisabled, handleDeleteCategories, toggleActionModal])

  const getModalActionButtons = React.useCallback((): BottomActionButton[] => {
    if (actionModalType === 'map') {
      return mapModalActionButtons
    }
    return deleteModalActionButtons
  }, [actionModalType, deleteModalActionButtons, mapModalActionButtons])

  const renderMapModalContent = () => {
    const existedMappings = dataSourceMappings.filter(({ dataProviderCategoryId }) =>
      selectedIds.includes(dataProviderCategoryId),
    )

    let attentionText0 = ''
    let attentionText1 = `There will be affected the ${existedMappings.length} source categories.`

    if (existedMappings.length) {
      const r1CategoryObj = r1Categories.find(({ id }) => id === r1Category) || {}
      const r1CategoryName = r1CategoryObj.name
      attentionText0 = `Are you sure that you want to remap selected ${marketplaceName} categories to ${r1CategoryName}?`
    }

    if (existedMappings.length === 1) {
      const { dataProviderCategoryId } = existedMappings[0]
      const dataSourceCategoryObj =
        dataSourceCategories.find(({ id }) => id === dataProviderCategoryId) || {}
      const dataSourceCategoryName = dataSourceCategoryObj.name
      attentionText1 = `There will be affected "${dataSourceCategoryName}" source category.`
    }

    return (
      <Flex column>
        <FormField>
          <FormField.Label>{`${marketplaceName} category will be mapped to:`}</FormField.Label>
          {/*  */}
          {/* $FlowFixMe[incompatible-type] */}
          <TreeSelect
            data-test-id="r1_category_select"
            placeholder="Select a category"
            options={r1Categories}
            value={r1Category}
            onChange={onR1CategoryChange}
          />
        </FormField>
        {!mapDisabled && !!existedMappings.length && (
          <Flex column mt="M">
            <Text color="red">{attentionText0}</Text>
            <Text color="red">{attentionText1}</Text>
          </Flex>
        )}
      </Flex>
    )
  }

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowCategoryMappingView]}>
      <Main fullScreen>
        {isLoading && <Loader data-test-id="data-source-categories-loader" />}
        <ContentHeader>
          <ContentHeader.Title />
          <ContentHeader.ActionButtons>
            <Button onClick={handleExport}>Export category tree</Button>
          </ContentHeader.ActionButtons>
        </ContentHeader>
        <Content spaceBetween="M">
          <Flex spaceBetween="M" align="flex-end">
            <Flex maxWidth={200}>
              <FormField>
                <Select
                  data-test-id="marketplace_select"
                  width={200}
                  placeholder="Select marketplace"
                  options={dataSources}
                  value={dataSource}
                  error={!isMarketplaceSelected}
                  onChange={onDataSourceChange}
                />
                <FormField.Error>
                  {isMarketplaceSelected ? '' : 'Select marketplace first'}
                </FormField.Error>
              </FormField>
            </Flex>
            <Flex minWidth={60}>
              <FormField>
                <FormField.Label>Select all</FormField.Label>
                <CheckboxContainer>
                  <Checkbox
                    data-test-id="select_all"
                    checked={selectAllCategories}
                    onChange={handleSelectAllCategories}
                  />
                </CheckboxContainer>
                <FormField.Error />
              </FormField>
            </Flex>
            <Flex maxWidth={520}>
              <FormField>
                <FormField.Label>{`Select categories to ${
                  selectAllCategories ? 'EXCLUDE' : 'INCLUDE'
                }:`}</FormField.Label>
                <TreeSelect
                  multiple
                  data-test-id="marketplace_category_select"
                  width={520}
                  placeholder="Select categories"
                  options={dataSourceCategories}
                  value={dataSourceCategory}
                  onChange={onDataSourceCategoryChange}
                />
                <FormField.Error />
              </FormField>
            </Flex>
            <FormField>
              <Select
                clearable
                data-test-id="status_select"
                width={200}
                placeholder="Select status"
                options={statusOptions}
                value={status}
                onChange={onStatusChange}
              />
              <FormField.Error />
            </FormField>
          </Flex>
          <CategoryMappingTable
            itemsCount={itemsCount}
            actionItems={actionItems}
            isAllSelected={isAllSelected}
            setIsAllSelected={setIsAllSelected}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            marketplace={marketplaceName}
            status={status}
            setStatus={onStatusChange}
            dataSourceCategories={computedCategories}
            r1Categories={r1Categories}
            mappings={dataSourceMappings}
            pagination={{ page, pageSize }}
            onChangePagination={onChangePagination}
            onSelectActionItem={handleAction}
          />
        </Content>
      </Main>
      {actionModal && (
        <Modal
          isControlled
          size={actionModalType === 'map' ? 'M' : 'XS'}
          show={actionModal}
          title={modalTitle}
          actionButtons={getModalActionButtons()}
          onEscapeKeyDown={toggleActionModal}
        >
          {actionModalType === 'map' ? renderMapModalContent() : null}
        </Modal>
      )}
    </PageAccessControl>
  )
}
