import * as React from 'react'

import { DataProvider } from '../DataProvider'
import type { UserCreationFormControllerProps } from '../../types/UserCreationForm'
import { UserCreationFormController as Controller } from './UserCreationFormController'

export const UserCreationFormController = (props: UserCreationFormControllerProps) => {
  return (
    <DataProvider httpClient={props.httpClient} onError={props.onError}>
      {providerProps =>
        props.copyDefaultSettingsFromUserId ? (
          <Controller
            copyDefaultSettingsFromUserId={props.copyDefaultSettingsFromUserId}
            onFormCanceled={props.onFormCanceled}
            onFormSubmitted={props.onFormSubmitted}
            {...providerProps}
          >
            {formProps => props.children(formProps)}
          </Controller>
        ) : (
          <Controller
            onFormCanceled={props.onFormCanceled}
            onFormSubmitted={props.onFormSubmitted}
            {...providerProps}
          >
            {formProps => props.children(formProps)}
          </Controller>
        )
      }
    </DataProvider>
  )
}
