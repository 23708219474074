import { NotificationSystem } from '@r1/ui-kit'

import type { FormManagerClientValidateHandler } from '../types'

const uiValidators = {
  name: (name: string) => {
    if (name.length > 250) {
      return 'Need to be less than 251 symbols'
    }
    return null
  },

  notNullable: (name: string, value: string | string[]) => {
    if (!value || !value.length) {
      return `${name} need to be filled`
    }
    return null
  },
}

export const validate: FormManagerClientValidateHandler = (
  { name, facilityId, identifierValues, partCategoryId },
  { invokedBySubmissionButton },
) => {
  const errors = {
    name: uiValidators.notNullable('Name', name) || uiValidators.name(name),
    facilityId: uiValidators.notNullable('Warehouse', facilityId),
    identifierValues: uiValidators.notNullable('UPCs', identifierValues),
    partCategoryId: uiValidators.notNullable('Categories', partCategoryId),
    requiredCount: null,
  }
  let hasError = false
  Object.values(errors).forEach(error => {
    if (error !== null) {
      if (invokedBySubmissionButton)
        NotificationSystem.addNotification({ title: String(error), level: 'error' })
      hasError = true
    }
  })

  return hasError ? errors : null
}
