import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { compose } from 'recompose'
import { pathOr, propOr } from 'ramda'
import { Tooltip, withTheme, Flex, Box, Text } from '@r1/ui-kit'

import { Icon } from '../../../components/Icon'
import { selectors } from '../../../modules/products'
import { selectors as productTemplatesSelectors } from '../../../modules/productTemplates'
import { CategoryTextPath } from '../../../components/categories/TextPath'
import { VenueProfileIcon } from './VenueProfileIcon'

const Delimiter = styled('div')`
  width: 1px;
  height: 26px;
  background-color: ${({ theme }) => theme.palette.grey[300]};
`

const Status = styled('div')`
  padding: 4px 8px 5px 8px;
  text-transform: uppercase;
  border-radius: 2px;
  color: ${({ theme }) => theme.palette.grey[800]};
  border: solid 1px ${({ theme }) => theme.palette.grey[900]};
  max-height: 24px;
  font-size: 14px;
`

const mapStateToProps = (state, { productId }) => {
  const productCard = selectors.getItem(state, { id: productId })
  return {
    productCard,
    productTemplate: productTemplatesSelectors.getItem(state, {
      id: productCard && productCard.productTemplateId,
    }),
  }
}

const enhance = compose(withTheme, connect(mapStateToProps))

const mapTestedIconInfo = {
  FullyFunctional: {
    name: 'testedFullFunctional',
    text: 'Tested - Full functional',
  },
  PartsForRepair: {
    name: 'testedNotWorking',
    text: 'Tested - Parts For Repair',
  },
  AssessmentRequired: {
    name: 'testedNotWorking',
    text: 'Tested - Assessment Required',
  },
  Recycle: {
    name: 'testedNotWorking',
    text: 'Tested - Recycle',
  },
  Tested: {
    name: 'tested',
    text: 'Tested',
  },
}

const mapGradedIconInfo = {
  Graded: {
    name: 'graded',
    text: 'Graded',
  },
  GradeA: {
    name: 'grade_A',
    text: 'Grade A',
  },
  GradeB: {
    name: 'grade_B',
    text: 'Grade B',
  },
  GradeC: {
    name: 'grade_C',
    text: 'Grade C',
  },
  GradeD: {
    name: 'grade_D',
    text: 'Grade D',
  },
  BrandNew: {
    name: 'gradeNew',
    text: 'Brand new',
  },
}

const mapPackagedIconInfo = theme => ({
  Original: {
    name: 'packaged',
    color: theme.primaryColor[600],
    text: 'Original packaging',
  },
  NotOriginal: {
    name: 'packaged',
    color: theme.palette.yellow[800],
    text: 'Not original packaging',
  },
  NoPackaging: {
    name: 'noPackaged',
    color: theme.palette.white,
    text: 'No packaging',
  },
})

const HeaderContent = ({ productCard, categoryId, theme }) => {
  const internalNote = propOr('', 'internalNote', productCard)
  const checkedInOn = pathOr('', ['productState', 'checkedInOn'], productCard)
  const testedOn = pathOr('', ['productState', 'testedOn'], productCard)
  const technicalFunctionality = pathOr('', ['productState', 'technicalFunctionality'], productCard)
  const gradedOn = pathOr('', ['productState', 'gradedOn'], productCard)
  const physicalCondition = pathOr('', ['productState', 'physicalCondition'], productCard)
  const repairedOn = pathOr('', ['productState', 'repairedOn'], productCard)
  const packagedOn = pathOr('', ['productState', 'packagedOn'], productCard)
  const packaging = pathOr('', ['productState', 'packaging'], productCard)
  const venueProfileTypes = pathOr([], ['productState', 'venueProfileTypes'], productCard)
  const status = pathOr('', ['productState', 'status'], productCard)

  const showProviders = venueProfileTypes.length > 0
  const testedIconInfo = mapTestedIconInfo[technicalFunctionality] || {}
  const gradedIconInfo = mapGradedIconInfo[physicalCondition] || {}
  const packagedIconInfo = mapPackagedIconInfo(theme)[packaging] || {}

  return (
    <Flex column spaceBetween="M">
      <Flex>
        <Text>{internalNote}</Text>
      </Flex>
      <Flex spaceBetween="L">
        <Flex spaceBetween="S">
          <Tooltip place="bottom" text={`Checked in ${checkedInOn}`} disabled={!checkedInOn}>
            <Icon
              name="checkedIn"
              color={checkedInOn ? theme.primaryColor[600] : theme.palette.grey[300]}
              size="24px"
            />
          </Tooltip>
          <Tooltip place="bottom" text={`${testedIconInfo.text} ${testedOn}`} disabled={!testedOn}>
            <Icon
              name={testedIconInfo.name || 'tested'}
              color={testedOn ? theme.primaryColor[600] : theme.palette.grey[300]}
              size="24px"
            />
          </Tooltip>
          <Tooltip place="bottom" text={`${gradedIconInfo.text} ${gradedOn}`} disabled={!gradedOn}>
            <Icon
              name={gradedIconInfo.name || 'notGraded'}
              color={gradedOn ? theme.primaryColor[600] : theme.palette.grey[300]}
              size="24px"
            />
          </Tooltip>
          <Tooltip
            place="bottom"
            text={`Product was repaired ${repairedOn}`}
            disabled={!repairedOn}
          >
            <Icon
              name="repaired"
              color={repairedOn ? theme.primaryColor[600] : theme.palette.grey[300]}
              size="24px"
            />
          </Tooltip>
          <Tooltip
            place="bottom"
            text={`${packagedIconInfo.text} ${packagedOn}`}
            disabled={!packagedOn}
          >
            <Icon
              name={packagedIconInfo.name || 'packaged'}
              color={packagedIconInfo.color || theme.palette.grey[300]}
              size="24px"
            />
          </Tooltip>
        </Flex>
        <Box>
          <Delimiter />
        </Box>
        {status && <Status>{status}</Status>}
        {showProviders && (
          <Flex justify="center" align="center">
            {venueProfileTypes.length > 1 ? (
              <Icon name="someMarketplaces" size="60px" />
            ) : (
              <VenueProfileIcon venueProfileType={venueProfileTypes[0]} />
            )}
          </Flex>
        )}
      </Flex>
      <Flex>
        <CategoryTextPath categoryId={categoryId} />
      </Flex>
    </Flex>
  )
}

export default enhance(HeaderContent)
