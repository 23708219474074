import { createCarriersApi } from '@r1-webui/shipping-carriers-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = '/uisvc/carrier-management'

export const carriersApi = createCarriersApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}`,
})
