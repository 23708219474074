// @flow

import * as React from 'react'

import type { CategoryAttributesService } from '../../../services/categoryAttributesService'

type Categories = {| id: string, parentId?: string, name: string |}[]
type ServiceData = {|
  categoryService: $AnyObject,
  httpClient: $AnyObject,
  categoryAttributesService: CategoryAttributesService,
  allCategories: Categories,
  setAllCategories: Categories => void,
|}

export const ServiceContext: React.Context<ServiceData> = React.createContext({
  categoryService: {},
  httpClient: {},
  categoryAttributesService: {},
  allCategories: [],
  setAllCategories: () => {},
})
