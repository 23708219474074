// @flow

import React, { useState, useCallback, useContext, useMemo } from 'react'
import styled from '@emotion/styled'
import { TreeSelect, FormField, Box, Checkbox, Flex, Text } from '@r1/ui-kit'
import { ServiceContext } from '../../../../../../provider'
import type { MarketplaceCategoriesTreeSelectProps } from '../../../../../../types/product.type'

const Title = styled('span')`
  color: ${({ theme }) => theme.palette.black};
  font-size: 14px;
  font-weight: normal;
`

export function MarketplaceCategoriesTreeSelect({
  placeholder,
  width,
  error,
  disabled,
  onChangeCategoryId,
  onChangeAutoUpdate,
  mapping,
}: MarketplaceCategoriesTreeSelectProps) {
  const { categoryTreeId, categoryId, categories: initCategories = [], autoUpdate, title } = mapping
  const [categories, setCategories] = useState(initCategories)
  const [isLoading, setIsLoading] = React.useState(false)
  const [fetched, setFetched] = React.useState(false)

  const {
    productService: { getAllMarketplaceCategories },
  } = useContext(ServiceContext)

  const fetchCategories = useCallback(async () => {
    if (fetched) return
    setIsLoading(true)
    setCategories([])
    const fetchedCategories = await getAllMarketplaceCategories(categoryTreeId)
    if (!fetchedCategories.length) {
      onChangeCategoryId(null)
    }
    setCategories(fetchedCategories)
    setFetched(true)
    setIsLoading(false)
  }, [categoryTreeId, fetched, getAllMarketplaceCategories, onChangeCategoryId])

  const onChange = useCallback(
    value => {
      if (undefined !== value) {
        onChangeCategoryId(value ? value.toString() : null)
      }
    },
    [onChangeCategoryId],
  )

  const existError = useMemo(() => {
    const exist = categories ? categories.filter(({ id }) => id === categoryId).length > 0 : false
    return categoryId && !exist ? 'Selected Category Mapping value does not exist' : null
  }, [categories, categoryId])

  return (
    <Flex minWidth={890} maxWidth={890} spaceBetween="XL">
      <Box minWidth={108} maxWidth={108} display="block">
        <Text align="center">
          <Checkbox disabled={disabled} checked={autoUpdate} onChange={onChangeAutoUpdate} />
        </Text>
      </Box>
      <Box minWidth={250} maxWidth={250} mt="S">
        <Title>{title}</Title>
      </Box>
      <Box>
        {categoryTreeId == null ? (
          <TreeSelect
            disabled={disabled}
            options={[]}
            width={486}
            placeholder="There are no categories to choose from"
          />
        ) : (
          <FormField>
            <TreeSelect
              ignoreBranchNodes
              width={width || undefined}
              options={categories}
              loading={isLoading}
              placeholder={placeholder || ''}
              multiple={false}
              error={!!error || !!existError || false}
              value={categoryId}
              disabled={disabled || false}
              onChange={(onChange: any => void)}
              onOpen={() => {
                fetchCategories()
              }}
            />
            <FormField.Error>{error || existError}</FormField.Error>
          </FormField>
        )}
      </Box>
    </Flex>
  )
}
