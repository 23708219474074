// @flow

import type { Export } from './types'

import type { ExportContext } from './withExport'

export { withExport } from './withExport'
export { createApi as createExportApi } from '../../api/Export'

export type { ExportContext }

export function onSuccess(exportResult: Export) {
  if (exportResult.fileTokens) {
    exportResult.fileTokens.forEach(({ token }) => {
      if (token) {
        window.open(`/uisvc/private-file-storage/download/api/v1/files/${token}`, '_blank')
      }
    })
  }
}
