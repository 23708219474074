import * as PIXI from 'pixi.js'

export default (block, transform) => {
  // eslint-disable-next-line new-cap
  const texture = new PIXI.Texture.from(block.src)
  const imageBlock = new PIXI.Sprite(texture)
  imageBlock.width = transform.size.width
  imageBlock.height = transform.size.height
  return imageBlock
}
