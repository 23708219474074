// @flow

import * as React from 'react'
import type { ProductAttributesProps } from '../../../types/public/ProductCardView'
import { Attributes, Header } from './Common'

export const ProductAttributes = ({ productAttributes }: ProductAttributesProps) => (
  <React.Fragment>
    <Header>Product</Header>
    <Attributes productAttributes={productAttributes} />
  </React.Fragment>
)
