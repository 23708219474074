import * as PIXI from 'pixi.js'
import valuesToString from './valuesToString'

export default (transform, values, fontSize, fitTextToRectangle = false, reversedColor = false) => {
  const stringValue = valuesToString(values)
  const commonOptions = {
    fill: reversedColor ? 'white' : 'black',
  }

  if (fitTextToRectangle) {
    const graphics = new PIXI.Graphics()
    const rect = graphics.drawRect(0, 0, transform.size.width, transform.size.height)
    const bounds = rect.getBounds()
    const text = new PIXI.Text(stringValue, commonOptions)
    rect.addChild(text)
    text.updateText()
    text.width = bounds.width
    text.scale.y = text.scale.x

    // next code fits text to rectangle vertically
    if (text.height > transform.size.height) {
      text.height = bounds.height
      text.scale.x = text.scale.y
      // middle align in block
      text.x = (transform.size.width - text.width) / 2
    }
    return graphics
  }

  const options = {
    ...commonOptions,
    breakWords: true,
    wordWrap: transform.size.height >= fontSize * 2,
    fontSize: Math.max(fontSize, 20),
    wordWrapWidth: transform.size.width,
  }
  const text = new PIXI.Text(stringValue, options)
  return text
}
