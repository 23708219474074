// @flow

import * as React from 'react'

import styled from '@emotion/styled'

import { Flex, Box, Drawer, RadioGroup, Radio, BottomButtonsBar } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

import type { FileFormat } from './types'

const SubHeader = styled('div')`
  marginBottom: 12px,
  color: #c7c7c7,
  fontSize: 14px,
`

const Container = styled('div')`
  width: 100%,
  height: 95%,
  marginTop: 20px,
  overflowX: hidden,
  overflowY: auto,
  overscrollBehaviorY: contain,
`

type Props = {
  isExportInProgress: boolean,
  doExport: () => void,
  isVisible: boolean,
  onClose: () => void,
  fileFormat: FileFormat,
  setFileFormat: FileFormat => void,
}

export function ExportSettingsView(props: Props) {
  const bottomActionButtons: BottomActionButton[] = [
    {
      title: 'Export',
      align: 'right',
      disabled: props.isExportInProgress,
      onClick: () => {
        props.doExport()
        props.onClose()
      },
    },
    {
      title: 'Cancel',
      align: 'right',
      color: 'secondary',
      onClick: props.onClose,
    },
  ]

  return (
    <Drawer.Form
      closeButton
      title="Grid Export"
      show={!!props.isVisible}
      placement="right"
      size={470}
      footer={<BottomButtonsBar bottomActionButtons={bottomActionButtons} />}
      onClose={props.onClose}
    >
      <Container>
        <Flex pl="L">
          <SubHeader>Format</SubHeader>
        </Flex>
        <RadioGroup name="fileFormat" value={props.fileFormat} onChange={props.setFileFormat}>
          <Flex column spaceBetween="S" mb="L" pl="L">
            <Box>
              <Radio value="XLSX" label="XLSX" />
            </Box>
            <Box>
              <Radio value="TXT" label="TXT" />
            </Box>
            <Box>
              <Radio value="CSV" label="CSV" />
            </Box>
          </Flex>
        </RadioGroup>
      </Container>
    </Drawer.Form>
  )
}
