// @flow

import * as React from 'react'
import { isEmpty } from 'ramda'

import { NotificationSystem, Link } from '@r1/ui-kit'
import type { UnauthorizedError } from '@r1/types/flow/libTypes'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Loader } from '../../../../components/Loader'

import { baseUrl } from '../../constants'
import type { EditProfileScreenProps } from '../../types/EditProfileScreen'
import { setApplications, getInitialData } from './helpers'
import { ProfileCard } from './cards/ProfileCard'
import { SharingCard } from './cards/SharingCard'
import { ApplicationsCard } from './cards/ApplicationsCard'
import type { Field } from './types'

type EditProfileScreenState = {
  applicationIds: Array<string>,
  profileId: string,
  profileTypeId: string,
  applications: Array<{ id: string, name: string }>,
  profileName: string | null,
  profileImage: Object | null,
  profileInfo: string | null,
  profileFields: Array<Field>,
  sharedCompanies: Array<Object>,
  sharedCompaniesLoading: { [string]: boolean },
  isProfileEditable: boolean,
  isLoading: boolean,
  httpClient: any,
  purpose: { id: string, name: string } | null,
  onError?: (error: UnauthorizedError) => void,
}

export class EditProfileScreen extends React.Component<
  EditProfileScreenProps,
  EditProfileScreenState,
> {
  state = {
    profileId: '',
    profileTypeId: '',
    applications: [],
    applicationIds: [],
    profileName: null,
    profileImage: null,
    profileInfo: null,
    profileFields: [],
    // eslint-disable-next-line react/no-unused-state
    sharedCompanyName: null,
    sharedCompanies: [],
    sharedCompaniesLoading: { all: false },
    isProfileEditable: false,
    isLoading: true,
    httpClient: null,
    purpose: null,
    onError: (error: UnauthorizedError) => {
      throw error
    },
  }

  static getDerivedStateFromProps(
    nextProps: EditProfileScreenProps,
    state: EditProfileScreenState,
  ) {
    const { profileId, httpClient, onError } = nextProps

    if (!state.httpClient || !state.onError || profileId !== state.profileId) {
      return { profileId, httpClient, onError }
    }

    return null
  }

  componentDidMount() {
    const { httpClient, onError, profileId } = this.state

    getInitialData({ httpClient, profileId, onError }).then(initialData =>
      this.setState({ ...initialData }),
    )
  }

  updateSharedCompanies = (sharedCompanies: Array<Object>) => {
    const { sharedCompaniesLoading } = this.state

    this.setState({
      sharedCompanies,
      sharedCompaniesLoading: { ...sharedCompaniesLoading, all: false },
    })
  }

  setSharedCompaniesLoading = (id: ?string | ?number, isLoading: boolean = false) => {
    const { sharedCompaniesLoading } = this.state

    if (id) {
      this.setState({
        sharedCompaniesLoading: { ...sharedCompaniesLoading, [id]: isLoading },
      })
    }
  }

  onCompanyShare = (event: Event, companyName: string) => {
    const {
      httpClient,
      profileId,
      onError,
    }: {
      httpClient: any,
      profileId: string,
      onError?: (error: UnauthorizedError) => void,
    } = this.state

    event.preventDefault()

    this.setSharedCompaniesLoading('all', true)

    return httpClient
      .post(`${baseUrl}/profile/${profileId}/sharing`, { companyName })
      .then(() => httpClient.get('/uisvc/espm/api/profile/shared'))
      .then(data => {
        this.updateSharedCompanies(data)
        NotificationSystem.addNotification({
          level: 'success',
          message: `Shared ${companyName}`,
        })
      })
      .catch(error => {
        const { message = '', $type } = error

        if ($type === 'Unauthorized' && onError) {
          onError(error)
        } else {
          NotificationSystem.addNotification({ level: 'error', message })
        }

        this.setSharedCompaniesLoading('all')
      })
  }

  onRemoveShared = (field: Field) => {
    const { httpClient, profileId }: { httpClient: any, profileId: string } = this.state

    if (field.id) {
      this.setSharedCompaniesLoading(field.id, true)

      httpClient
        .put(`${baseUrl}/profile/${profileId}/sharing/approve/cancel`, {
          profileId: field.id,
        })
        .then(() => {
          this.setSharedCompaniesLoading(field.id)
          NotificationSystem.addNotification({
            level: 'success',
            message: 'Cancelled sharing',
          })
        })
        .catch(() => {
          this.setSharedCompaniesLoading(field.id)
          NotificationSystem.addNotification({
            level: 'error',
            message: 'Error while trying to cancel sharing',
          })
        })
    }
  }

  setCardApplications = (applicationIds: Array<string>) => {
    const { httpClient, onError, profileId } = this.state

    return setApplications({ httpClient, profileId, onError, applicationIds }).then(data => {
      if (!data.error) {
        this.setState({ applicationIds })

        NotificationSystem.addNotification({
          level: 'success',
          message: 'Changes were successfully saved',
        })
      } else {
        const message = typeof data.error === 'string' ? data.error : 'Unhandled error'

        NotificationSystem.addNotification({
          level: 'error',
          message,
        })
      }

      return data
    })
  }

  setProfileEditable = (isProfileEditable?: boolean = true) => this.setState({ isProfileEditable })

  editCompleteHandler = (step: Object = {}) => {
    const { informationPopupMarkdown } = step

    if (informationPopupMarkdown && informationPopupMarkdown.indexOf('saved') > -1) {
      this.setProfileEditable(false)
    }
  }

  render() {
    const {
      profileId,
      profileName,
      profileInfo,
      profileImage,
      profileFields,
      profileTypeId,
      applications,
      applicationIds,
      isProfileEditable,
      sharedCompanies,
      sharedCompaniesLoading,
      isLoading,
      httpClient,
      purpose,
      onError,
    } = this.state

    const { isAllowedToEdit } = this.props

    const { id: purposeId = '', name: purposeName = '' } = purpose || {}

    return (
      <Main>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {profileName && !isEmpty(purposeName) && (
              <ContentHeader>
                <ContentHeader.Breadcrumbs>
                  <Link to="/r1/admin/profiles">External Service Integrations</Link>
                  <Link to={`/r1/admin/profiles/${purposeId}`}>{purposeName}</Link>
                </ContentHeader.Breadcrumbs>
              </ContentHeader>
            )}
            <Content spaceBetween="M">
              <ProfileCard
                isAllowedToEdit={isAllowedToEdit}
                httpClient={httpClient}
                name={profileName}
                id={profileTypeId}
                image={profileImage}
                info={profileInfo}
                entityId={profileId}
                fields={profileFields}
                editable={isProfileEditable}
                setEditable={this.setProfileEditable}
                onEditComplete={this.editCompleteHandler}
                onError={onError}
              />
              <ApplicationsCard
                isAllowedToEdit={isAllowedToEdit}
                applications={applications}
                applicationIds={applicationIds}
                renderActionsButtons={node => (
                  <ContentHeader.ActionButtons>{node}</ContentHeader.ActionButtons>
                )}
                setCardApplications={this.setCardApplications}
              />
              {sharedCompanies.length > 0 && (
                <SharingCard
                  isAllowedToEdit={isAllowedToEdit}
                  sharedCompanies={sharedCompanies}
                  companiesLoading={sharedCompaniesLoading}
                  onRemoveShared={this.onRemoveShared}
                  onCompanyShare={this.onCompanyShare}
                />
              )}
            </Content>
          </>
        )}
      </Main>
    )
  }
}
