// @flow

import * as React from 'react'

import type { ProfileType } from '../../types'
import { ProfilesTable } from './ProfilesTable'
import { SharingsTable } from './SharingsTable'

export type ProfilesCardProps = {
  purposeName: string | null,
  profiles?: Array<ProfileType>,
  sharedCompanies?: Array<ProfileType>,
  profilesLoading: { [string]: boolean },
  onAcceptSharing?: (profile?: Object) => void,
  onRejectSharing?: (profile?: Object) => void,
  getProfileUrl: (profileId: string, profileTypeId: string) => string,
}

export const ProfilesCard = ({
  purposeName,
  profiles,
  getProfileUrl,
  sharedCompanies,
  profilesLoading,
  onAcceptSharing,
  onRejectSharing,
}: ProfilesCardProps) => {
  return (
    <React.Fragment>
      <ProfilesTable purposeName={purposeName} profiles={profiles} getProfileUrl={getProfileUrl} />
      <SharingsTable
        purposeName={purposeName}
        sharedCompanies={sharedCompanies}
        profilesLoading={profilesLoading}
        getProfileUrl={getProfileUrl}
        onAcceptSharing={onAcceptSharing}
        onRejectSharing={onRejectSharing}
      />
    </React.Fragment>
  )
}
