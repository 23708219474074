import * as React from 'react'
import * as R from 'ramda'
import { useState, useMemo } from 'react'
import { Drawer, Collapse, Flex, BottomButtonsBar } from '@r1/ui-kit'
import {
  ContactInfo,
  RuleConstraints,
  ShippingOption,
} from '@r1-webui/shipping-returnshippingrules-v1'
import { FormManager } from '@r1/form-manager'
import { useShippingOptions, usePriorities, useRuleConstraints, useRule } from '../hooks'
import { DrawerType } from '../types'
import { DrawerLabel } from './components'
import {
  ShippingOptionsSection,
  ContactInfoSection,
  OverviewSection,
  RuleConstraintsSection,
} from './sections'
import { validate } from './validation'
import { INITIAL_VALUE, collapsePanelsIds } from './constants'

type Props = {
  ruleId: string
  drawerType: DrawerType | null
  onCloseDrawer: () => void
  onOpenDrawer: (v: { id: string; type: DrawerType }) => void
  onRefreshGridData: () => void
}

export const RmaShippingRulesDrawer = React.memo(
  ({ ruleId, drawerType, onCloseDrawer, onOpenDrawer, onRefreshGridData }: Props) => {
    const { rule, isRuleLoading, submit, isSubmitting } = useRule(
      ruleId,
      drawerType,
      onCloseDrawer,
      onOpenDrawer,
      onRefreshGridData,
    )
    const [activePanels, setActivePanels] = useState<string[]>(collapsePanelsIds)
    const { rulePriorities, arePrioritiesLoading } = usePriorities(drawerType)
    const { marketplaces, programs, areMarketplacesLoading, areProgramsLoading } =
      useRuleConstraints(drawerType)

    const {
      shippingProfiles,
      thirdPartyShippingProfiles,
      shippingCarriers,
      areShippingProfilesLoading,
      areThirdPartyShippingProfilesLoading,
      areShippingCarriersLoading,
    } = useShippingOptions(drawerType)

    const initialValue = useMemo(
      () => (rule ? R.merge(INITIAL_VALUE, rule) : INITIAL_VALUE),
      [rule],
    )

    return (
      <FormManager initialValues={initialValue} clientValidate={validate} onSubmit={submit}>
        {({ values, handleChange, push, remove, errors, getError, handleSubmit }) => {
          const nameError = getError(['name'])
          const descriptionError = getError(['description'])
          const priorityError = getError(['priority', 'ruleId'])
          const marketplaceProfilesIdsError = getError([
            'ruleConstraints',
            'marketplaceProfilesIds',
          ])
          const weightRangeError =
            getError(['ruleConstraints', 'weightRange', 'from']) ||
            getError(['ruleConstraints', 'weightRange', 'to'])
          const dimensionalWeightRangeError =
            getError(['ruleConstraints', 'dimensionalWeightRange', 'from']) ||
            getError(['ruleConstraints', 'dimensionalWeightRange', 'to'])
          const salePriceRangeError =
            getError(['ruleConstraints', 'salePriceRange', 'from']) ||
            getError(['ruleConstraints', 'salePriceRange', 'to'])
          const maximumShippingPricePercentError = getError([
            'ruleConstraints',
            'maximumShippingPricePercent',
          ])
          const maximumShippingPriceError = getError([
            'ruleConstraints',
            'maximumShippingPrice',
            'amount',
          ])
          const phoneNumberError = getError(['contactInformation', 'phoneNumber'])
          const contactNameError = getError(['contactInformation', 'contactName'])
          const companyNameError = getError(['contactInformation', 'companyName'])

          const getShippingOptionsError = (index: number) => {
            if (errors.shippingOptions && errors.shippingOptions[index]) {
              return (errors.shippingOptions[index] as ShippingOption)?.shippingProfileId
            }

            return null
          }

          const bottomActionButtons = [
            {
              title: 'Cancel',
              align: 'right' as const,
              color: 'secondary' as const,
              onClick: onCloseDrawer,
            },
            {
              title: 'Save',
              align: 'right' as const,
              disabled: isSubmitting,
              loading: isSubmitting,
              'data-test-id': 'drawer-footer__button-Save',
              onClick: handleSubmit,
            },
          ]

          return (
            <Drawer.Form
              closeButton
              loading={isRuleLoading}
              backdrop="closing"
              size={1056}
              show={!!drawerType}
              placement="right"
              title={
                drawerType === 'CREATE' || drawerType === 'DUPLICATE'
                  ? 'Create Rule'
                  : initialValue.name
              }
              label={
                <DrawerLabel
                  active={values.active}
                  // @ts-expect-error
                  onToggleStatus={(v: boolean) => handleChange(['active'], v)}
                />
              }
              footer={<BottomButtonsBar bottomActionButtons={bottomActionButtons} />}
              onClose={onCloseDrawer}
            >
              <Flex column minWidth={1}>
                <Collapse active={activePanels} onChange={setActivePanels}>
                  <Collapse.Panel id="Overview" title="Overview">
                    <OverviewSection
                      isLoading={isRuleLoading}
                      rulePriorities={rulePriorities}
                      arePrioritiesLoading={arePrioritiesLoading}
                      ruleId={drawerType === 'EDIT' ? ruleId : ''}
                      name={values.name}
                      description={values.description}
                      priority={values.priority}
                      nameError={nameError}
                      descriptionError={descriptionError}
                      priorityError={priorityError}
                      onChange={handleChange}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel id="Shipping Options" title="Shipping Options">
                    <ShippingOptionsSection
                      isLoading={isRuleLoading}
                      shippingCarriers={shippingCarriers}
                      shippingProfiles={shippingProfiles}
                      shippingOptions={values.shippingOptions}
                      thirdPartyShippingProfiles={thirdPartyShippingProfiles}
                      areShippingProfilesLoading={areShippingProfilesLoading}
                      areThirdPartyShippingProfilesLoading={areThirdPartyShippingProfilesLoading}
                      areShippingCarriersLoading={areShippingCarriersLoading}
                      getShippingOptionsError={getShippingOptionsError}
                      onChange={handleChange}
                      onAddShippingOption={(opt: ShippingOption) => push(['shippingOptions'], opt)}
                      onDeleteShippingOption={(index: number) => remove(['shippingOptions'], index)}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel id="Rule Constraints" title="Rule Constraints">
                    <RuleConstraintsSection
                      isLoading={isRuleLoading}
                      ruleConstraints={values.ruleConstraints}
                      marketplaces={marketplaces}
                      programs={programs}
                      areMarketplacesLoading={areMarketplacesLoading}
                      areProgramsLoading={areProgramsLoading}
                      marketplaceProfilesIdsError={marketplaceProfilesIdsError}
                      weightRangeError={weightRangeError}
                      dimensionalWeightRangeError={dimensionalWeightRangeError}
                      salePriceRangeError={salePriceRangeError}
                      maximumShippingPricePercentError={maximumShippingPricePercentError}
                      maximumShippingPriceError={maximumShippingPriceError}
                      // @ts-ignore
                      onChange={(v: RuleConstraints) => handleChange(['ruleConstraints'], v)}
                    />
                  </Collapse.Panel>
                  <Collapse.Panel id="Contact Information" title="Contact Information">
                    <ContactInfoSection
                      isLoading={isRuleLoading}
                      contactInformation={values.contactInformation}
                      phoneNumberError={phoneNumberError}
                      companyNameError={companyNameError}
                      contactNameError={contactNameError}
                      // @ts-ignore
                      onChange={(v: ContactInfo) => handleChange(['contactInformation'], v)}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Flex>
            </Drawer.Form>
          )
        }}
      </FormManager>
    )
  },
)
