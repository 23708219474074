import { parse } from 'date-fns'

export function convert12hTimeStringTo24h(time?: string | null) {
  const initialTime = time ?? '00:00'
  const isTwelveHourFormat = /\s+(am|pm)/gi.exec(initialTime)
  let result = initialTime

  if (isTwelveHourFormat) {
    const parsedDate = parse(initialTime, 'h:mm aa', new Date())
    const hours = String(parsedDate.getHours()).padStart(2, '0')
    const minutes = String(parsedDate.getMinutes()).padStart(2, '0')

    result = `${hours}:${minutes}`
  }

  return result
}
