// @flow

import * as React from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Modal, Input, Flex, Text } from '@r1/ui-kit'

type ContinueFunction = (value: string) => void

type ModalProps = {
  showModal: boolean,
  title: string,
  inputLabel: string,
  onContinue: ContinueFunction,
  onCancel?: Function,
  onClose: Function,
}

type ModalState = {
  inputValue: string,
  isSubmitting: boolean,
}

export class EnterModal extends React.Component<ModalProps, ModalState> {
  modalActionButtons: BottomActionButton[]

  constructor(props: ModalProps) {
    super(props)
    this.state = {
      inputValue: '',
      isSubmitting: false,
    }
    this.modalActionButtons = [
      {
        title: 'CANCEL',
        color: 'secondary',
        onClick: this.props.onCancel ? this.props.onCancel : this.props.onClose,
        align: 'right',
      },
      {
        title: 'CONTINUE',
        loading: this.state.isSubmitting,
        onClick: this.onContinue,
        align: 'right',
      },
    ]
  }

  onInputChange = (value: string) => {
    this.setState({ inputValue: value })
  }

  onContinue = async () => {
    const { onContinue, onClose } = this.props
    const { inputValue } = this.state
    this.setState({ isSubmitting: true })
    try {
      await onContinue(inputValue)
      onClose()
    } finally {
      this.setState({ isSubmitting: false })
    }
  }

  render() {
    const { showModal, title, inputLabel, onCancel, onClose } = this.props
    let cancelHandler = onCancel
    if (!cancelHandler) {
      cancelHandler = onClose
    }
    const { inputValue } = this.state

    return (
      <Modal
        isControlled
        show={showModal}
        title={title}
        actionButtons={this.modalActionButtons}
        onEscapeKeyDown={onClose}
      >
        <Flex minWidth={200} maxWidth={416} align="center">
          <Flex basis={0.4}>
            <Text type="data">{inputLabel}</Text>
          </Flex>
          <Flex minWidth={208} maxWidth={208}>
            <Flex minWidth={208} maxWidth={208}>
              <Input value={inputValue} onChange={this.onInputChange} />
            </Flex>
          </Flex>
        </Flex>
      </Modal>
    )
  }
}
