// @flow

import type { AttributeValueType, AttributeValueConstraint, ShapeAttributeValue } from '../types'

export const initialValuesConstraint = (
  valueType: AttributeValueType,
): AttributeValueConstraint | null => {
  switch (valueType) {
    case 'IntRange':
    case 'DecimalRange':
      return {
        valueType: 'NumberRangeConstraint',
        minAllowedValue: undefined,
        maxAllowedValue: undefined,
      }
    case 'Integer':
    case 'Decimal':
      return {
        valueType: 'NumberConstraint',
        minAllowedValue: undefined,
        maxAllowedValue: undefined,
        forbiddenValues: [],
      }
    case 'String':
      return {
        valueType: 'StringConstraint',
        minAllowedLength: undefined,
        maxAllowedLength: undefined,
        forbiddenSubstrings: [],
        forbiddenWords: [],
      }
    case 'DateRange':
      return {
        valueType: 'DateRangeConstraint',
        minAllowedDate: undefined,
        maxAllowedDate: undefined,
      }
    case 'Date':
      return { valueType: 'DateConstraint', minAllowedDate: undefined, maxAllowedDate: undefined }
    case 'IntEnumeration':
    case 'DecimalEnumeration':
    case 'StringEnumeration':
      return { valueType: 'EnumerationConstraint', allowedVariants: [] }
    default:
      return null
  }
}

export const initialDefaultValue = (valueType: AttributeValueType): ShapeAttributeValue => {
  switch (valueType) {
    case 'IntRange':
      return { valueType: 'IntRange', from: undefined, to: undefined }
    case 'DecimalRange':
      return { valueType: 'DecimalRange', from: undefined, to: undefined }
    case 'Integer':
      return { valueType: 'IntValue', value: undefined }
    case 'Decimal':
      return { valueType: 'DecimalValue', value: undefined }
    case 'String':
      return { valueType: 'StringValue', value: undefined }
    case 'DateRange':
      return { valueType: 'DateRange', from: undefined, to: undefined }
    case 'Date':
      return { valueType: 'DateValue', value: undefined }
    case 'Boolean':
      return { valueType: 'BoolValue', value: undefined }
    case 'IntEnumeration':
    case 'DecimalEnumeration':
    case 'StringEnumeration':
      return { valueType: 'EnumValue', value: undefined }
    default:
      throw new Error('Unknown attribute definition type')
  }
}
