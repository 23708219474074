// @flow

import * as React from 'react'
import { useCallback } from 'react'
import styled from '@emotion/styled'

import { Box, Button, Flex, Link } from '@r1/ui-kit'
import { Main, ContentHeader } from '@r1/wireframe-primary'
import { GenericIcon } from '@r1/core-blocks'

import type { ContextRouter as NotFoundProps } from 'react-router-dom'

import { browserHistory } from '../browserHistory'
import { LinkText, StyledContent } from '../../components/StatusCodesStyled'

import { redirect } from '../../utils/windowUtils'

const Text404 = styled('span')`
  font-family: Roboto, serif;
  font-style: normal;
  font-weight: normal;
  line-height: 34px;
  text-align: center;
  font-size: ${({ theme }) => theme.space.L};
  color: ${({ theme }) => theme.palette.grey[700]};
`

const R1_HOME_ROUTE = '/r1/home'

export const NotFound = ({ history }: NotFoundProps) => {
  const redirectToHomePage = useCallback(() => redirect(R1_HOME_ROUTE), [])
  const redirectToLastPage = useCallback(() => browserHistory.goBack(), [history])

  return (
    <Main>
      <ContentHeader.Title browserTabTitle="404 Not Found" />
      <StyledContent spaceBetween="XL" justify="center" align="center">
        <Box>
          <GenericIcon svgId="404_error" />
        </Box>
        <Flex column>
          <Text404>We couldn&apos;t find the page you&apos;re looking for.</Text404>
          <Text404>
            You can go back to
            <Link onClick={redirectToLastPage}>
              <LinkText> previous page </LinkText>
            </Link>
            or use side menu
          </Text404>
        </Flex>
        <Box>
          <Button onClick={redirectToHomePage}>Take me back to homepage</Button>
        </Box>
      </StyledContent>
    </Main>
  )
}
