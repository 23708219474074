// @flow

import React, { useState, useCallback } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Dropdown, Icon, Modal } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { ProductEditScreenProps } from '../../../types/public/ProductEditScreen'
import { ProductContainer } from '../ProductContainer'
import { ProductEdit } from '../children/ProductEdit'
import { SubmitForReview } from '../children/SubmitForReview'

const ActionContainer = styled('div')`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  margin: 0;
  padding: 0;
`

export const ProductEditScreen = (props: ProductEditScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const [isSubmitForReviewOpen, setIsSubmitForReviewOpen] = useState(false)
  const handleOpenSubmit = () => setIsSubmitForReviewOpen(true)
  const handleCloseSubmit = () => setIsSubmitForReviewOpen(false)

  const cancelModalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'No',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Yes',
          transparent: false,
          onClick: () => {
            props.onCancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [props],
  )

  return (
    <ProductContainer httpClient={props.httpClient}>
      <ProductEdit
        productId={props.productId}
        permissions={props.permissions}
        getProductDetailsUrl={props.getProductDetailsUrl}
        onSuccessfulSubmit={props.onSuccessfulSubmit}
        onChangeScreenType={props.onChangeScreenType}
      >
        {({
          handlers,
          isLoading,
          isRescrapingInProcess,
          primaryIdentifier,
          approvalType,
          reviewReasonsList,
        }) => {
          const rescrapeActionItem = {
            id: 'rescrape',
            title: '',
            disabled: isRescrapingInProcess,
            icon: (
              <ActionContainer onClick={handlers.handleRescrapeProductData}>
                Rescrape
              </ActionContainer>
            ),
          }

          const items = [rescrapeActionItem]

          if (approvalType !== 'ApprovalStateApproved') {
            items.push({
              id: 'submitForReview',
              title: '',
              icon: <ActionContainer onClick={handleOpenSubmit}>Submit for review</ActionContainer>,
            })

            if (props.permissions.allowProductApprove) {
              const modalActionButtons = ({ onClose }): BottomActionButton[] => {
                return [
                  {
                    title: 'No',
                    color: 'secondary',
                    onClick: () => onClose(),
                    align: 'right',
                  },
                  {
                    title: 'Yes',
                    onClick: () => {
                      handlers.handleApprove()
                      onClose()
                    },
                    align: 'right',
                  },
                ]
              }

              items.push({
                id: 'approve',
                title: '',
                icon: (
                  <Modal
                    isControlled={false}
                    title="Product approve"
                    actionButtons={modalActionButtons}
                    buttonElement={({ onOpen }) => (
                      <ActionContainer onClick={onOpen}>Approve</ActionContainer>
                    )}
                  >
                    Are you sure you want to approve this product?
                  </Modal>
                ),
              })
            }
          }

          return (
            <>
              <Title enableBetaLabel>{`Product Details / ${primaryIdentifier}`}</Title>
              <ActionButtons>
                <Modal
                  isControlled={false}
                  title="You have unsaved data"
                  actionButtons={cancelModalActionButtons}
                  buttonElement={({ onOpen }) => (
                    <Button color="secondary" onClick={onOpen}>
                      Cancel
                    </Button>
                  )}
                >
                  Are you sure you want to leave without saving?
                </Modal>

                <Button loading={isLoading} disabled={isLoading} onClick={handlers.handleSubmit}>
                  Save
                </Button>
                {items.length > 0 && (
                  <Dropdown.Menu
                    data-test-id="product-edit__dropdown-details"
                    items={items}
                    placement="bottom-end"
                    size="M"
                    mode="click"
                    zIndex={21}
                  >
                    <Button
                      transparent
                      color="secondary"
                      shape="square"
                      data-test-id="product-edit__dropdown-details-button"
                    >
                      <Icon type="dots/horizontal" />
                    </Button>
                  </Dropdown.Menu>
                )}
              </ActionButtons>

              <SubmitForReview
                show={isSubmitForReviewOpen}
                reviewReasonsList={reviewReasonsList}
                handleSubmitReview={handlers.handleSubmitReview}
                onClose={handleCloseSubmit}
              />
            </>
          )
        }}
      </ProductEdit>
    </ProductContainer>
  )
}
