// @flow

import React, { memo } from 'react'
import { DatePicker, FormField } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { DateAttributeChildrenProps } from '../../../../../../types/attribute.type'

const InputWrapper = styled('div')`
  label {
    width: 255px;
  }
`

export const Date = memo<DateAttributeChildrenProps>(
  ({
    error,
    title,
    handleChange,
    optionIndex,
    disabled,
    value,
    valueConstraint,
  }: DateAttributeChildrenProps) => {
    const min =
      valueConstraint && valueConstraint.minAllowedDate ? valueConstraint.minAllowedDate : undefined
    const max =
      valueConstraint && valueConstraint.maxAllowedDate ? valueConstraint.maxAllowedDate : undefined
    return (
      <FormField>
        <FormField.Label>{title}</FormField.Label>
        <InputWrapper>
          <DatePicker
            error={!!error}
            disabled={disabled}
            placeholder="YYYY-MM-DD"
            value={value}
            min={min}
            max={max}
            onChange={handleChange(['Attributes', optionIndex, 'formValue'])}
            onRemove={handleChange(['Attributes', optionIndex, 'formValue'])}
          />
        </InputWrapper>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
