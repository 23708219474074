/* eslint-disable default-case */
// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { is } from 'ramda'
import { Format } from '@r1/ui-kit'
import { NotSpecified } from '../View/NotSpecified'
import type { UnitAttribute } from '../../../types/public/unitAttributes'

export function mapKeyToLabel(key: string): string {
  const schema = {
    primaryProductIdentifier: 'Product Identifier',
    productName: 'Title',
    categoryPath: 'Category',
    brand: 'Brand',
    model: 'Model',
    manufacturer: 'Manufacturer',
    country: 'Country of Origin',
    taxCode: 'Tax Code',
    retailPrice: 'Retail Price',
    shortDescription: 'Short Description',
    longDescription: 'Full Description',
    supportingUrl: 'Supporting URL',
    productWeight: 'Product Weight',
    packageWeight: 'Shipping Weight',
    productDimensions: 'Product Dimensions',
    packageDimensions: 'Shipping Dimensions',
    partNumber: 'Part Number',
  }

  return schema[key]
}

// copy style from ui-kit for IS-794
// because we can't use Link on external resources
const Link = styled('a')`
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-decoration: none;
  word-break: break-word;

  ${({ theme }) => `
  color: ${theme.primaryColor[500]};

  &:hover {
    color: ${theme.primaryColor[800]};
    text-decoration: underline;
  }

  &:visited {
    color: ${theme.palette.purple[600]};
  }

  &:active {
    color: ${theme.primaryColor[800]};
    text-decoration: underline;
  };
`};
`

export function extractValue(field: any, key: string): React$Node | string {
  const isArray = is(Array, field)
  const isObject = is(Object, field)
  const isString = is(String, field)

  if (isArray) {
    return field.join(' / ')
  } else if (isString) {
    switch (key) {
      case 'shortDescription':
      case 'longDescription':
        return <span dangerouslySetInnerHTML={{ __html: field }} />
      case 'supportingUrl':
        return (
          <Link target="_blank" href={/(http(s?)):\/\//gi.test(field) ? field : `//${field}`}>
            {field}
          </Link>
        )
      default:
        return field
    }
  } else if (isObject) {
    switch (key) {
      case 'primaryProductIdentifier':
        return <Link href={field.link}>{field.name}</Link>
      case 'retailPrice': {
        const { amount, currency } = field
        return !amount ? <NotSpecified /> : `${amount} ${currency}`
      }
      case 'packageDimensions':
      case 'productDimensions': {
        const { height, lengthField, width, measure } = field
        return !height && !lengthField && !width ? (
          <NotSpecified />
        ) : (
          `${height}x${lengthField}x${width}${measure}`
        )
      }
      case 'productWeight':
      case 'packageWeight': {
        const { amount, measure } = field
        return !amount ? <NotSpecified /> : `${amount} ${measure}`
      }
      default:
        return Object.keys(field).reduce((acc, val) => `${acc} ${field[val]}`, '')
    }
  }

  return <NotSpecified />
}

/**
 * @description Function that refines object or union of objects which should have "value" property.
 * @param {Object} obj - object or union of objects which should have "value" property that is equal to null | T.
 * @return {Object} - refined object that have "value" property that is equal to T.
 * @example
 * type Obj = { value: null | string };
 * declare var obj: Obj
 * const refinedObj = refineValueProperty(obj)
 * // now type of refinedObj is equal to { value: string }
 */
const refineValueProperty = <Obj: { value: any }>(
  obj: Obj,
): null | $ObjMapi<
  Obj,
  (<K: 'value', V>(key: K, value: V | null) => V) & (<K, V>(key: K, value: V) => V),
> => {
  if (obj.value !== null && typeof obj.value !== 'undefined') return obj
  return null
}

export const getTextOfAttribute = (attribute: UnitAttribute): React$Node => {
  const refinedAttribute = refineValueProperty(attribute)
  if (!refinedAttribute) return <NotSpecified />
  let text = ''
  switch (refinedAttribute.$type) {
    case 'string': {
      text = refinedAttribute.value
      break
    }
    case 'enum': {
      text = refinedAttribute.value
      break
    }
    case 'number': {
      text = `${refinedAttribute.value}`
      break
    }
    case 'boolean': {
      text = refinedAttribute.value ? 'Yes' : 'No'
      break
    }
    case 'date': {
      text = <Format.Date>{refinedAttribute.value}</Format.Date>
      break
    }

    case 'dateRange': {
      const { from, to } = refinedAttribute.value
        ? refinedAttribute.value
        : { from: undefined, to: undefined }
      if (from && to) {
        text = (
          <React.Fragment>
            <Format.Date>{from}</Format.Date>
            {' - '}
            <Format.Date>{to}</Format.Date>
          </React.Fragment>
        )
      } else if (from && !to) {
        text = (
          <React.Fragment>
            {'from '}
            <Format.Date>{from}</Format.Date>
          </React.Fragment>
        )
      } else if (!from && to) {
        text = (
          <React.Fragment>
            {'till '}
            <Format.Date>{to}</Format.Date>
          </React.Fragment>
        )
      } else {
        text = ' - '
      }
      break
    }
    case 'numberRange': {
      const { from, to } = refinedAttribute.value
        ? refinedAttribute.value
        : { from: undefined, to: undefined }
      if (from && to) {
        text = `${from} - ${to}`
      } else if (from && !to) {
        text = `from ${from}`
      } else if (!from && to) {
        text = `up to ${to}`
      } else {
        text = ' - '
      }
      break
    }
  }
  return (
    <React.Fragment>
      {text} {attribute.unitsOfMeasurement ? attribute.unitsOfMeasurement.name : ''}
    </React.Fragment>
  )
}
