import { connect } from 'react-redux'
import { undo, redo, saveDocumentTemplate, changeZoom } from '../../actions'
import { ToolBar } from './ToolBar'

const ZOOM_STEP = 0.2

const mapStateToProps = state => {
  return {
    state: state.labelManagement.documentTemplate,
  }
}

const mapDispatchToProps = (dispatch, { allowEdit }) => {
  return {
    buttons: [
      ...(allowEdit
        ? [
            {
              text: 'Save',
              handler: () => dispatch(saveDocumentTemplate()),
            },
          ]
        : []),
      {
        text: 'Undo',
        getEnabled: state => state.canUndo,
        handler: () => dispatch(undo()),
      },
      {
        text: 'Redo',
        getEnabled: state => state.canRedo,
        handler: () => dispatch(redo()),
      },
      {
        text: '+',
        handler: state => dispatch(changeZoom(state.currentState.zoom + ZOOM_STEP)),
      },
      {
        text: '-',
        handler: state => dispatch(changeZoom(state.currentState.zoom - ZOOM_STEP)),
      },
    ],
  }
}
export const MainToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBar)
