import { QuickFilter } from '@r1/grid'
import React, { useState, useCallback } from 'react'
import { useHistoryActions, Option } from './useHistoryActions'

type Props = {
  onChange: (actionIds: string[]) => void
}

export const HistoryActionFilter = (props: Props) => {
  const [actions] = useHistoryActions()
  const [filterValues, setFilterValues] = useState<Option[]>([])

  const onChange = useCallback(
    (values: Option[] | null) => {
      const safeValues = values || []
      setFilterValues(safeValues)
      props.onChange(safeValues.map(x => x.id))
    },
    [props],
  )

  return (
    <QuickFilter
      type="multiSelect"
      bodyMinWidth={300}
      value={filterValues}
      options={actions}
      entityName="Events"
      onChange={onChange}
    />
  )
}
