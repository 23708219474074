import * as React from 'react'
import { connect } from 'react-redux'

import { setDefaultPage } from './module'

class InitialPagePresenter extends React.Component {
  componentDidMount() {
    // @ts-ignore
    this.props.setDefaultPage()
  }

  render() {
    return null
  }
}

const InitialPage = connect(null, { setDefaultPage })(InitialPagePresenter)

export { InitialPage }
