import { useMemo } from 'react'
import { flatten } from 'ramda'

import { Warehouse, DayOfWeek } from '@r1-webui/facilitypickupscheduler-v1'

import { EventInput } from '@fullcalendar/react'

import { format } from 'date-fns'
import { Period } from '../types'
import { utcToLocal } from '../../../../../utils/dates/utcToLocal'

const dayOfWeekMap: Record<DayOfWeek, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

const getBusinessHoursForCalendarFeed = (warehouse: Warehouse | null): EventInput[] => {
  if (warehouse) {
    const businessHours = warehouse.schedule.regularSchedule
      .filter(workingSchedule => {
        // to hightlight Sunday and Saturday on calendar - mark them as non working days
        if (workingSchedule.dayOfWeek === 'Sunday' || workingSchedule.dayOfWeek === 'Saturday') {
          return false
        }
        return true
      })
      .map(workingSchedule => {
        return workingSchedule.periods.map(period => {
          const periodStart = utcToLocal(period.start)
          const periodEnd = utcToLocal(period.end)
          return {
            daysOfWeek: [dayOfWeekMap[workingSchedule.dayOfWeek]],
            startTime: format(periodStart, 'H:mm'),
            endTime: format(periodEnd, 'H:mm'),
          }
        })
      })

    return flatten(businessHours)
  }

  return []
}

const getWorkingHourseByDays = (warehouse: Warehouse | null): Record<number, Period> => {
  if (warehouse) {
    const businessHours = warehouse.schedule.regularSchedule.reduce(
      (acc: Record<number, Period>, workingSchedule) => {
        const period = workingSchedule.periods[0]
        acc[dayOfWeekMap[workingSchedule.dayOfWeek]] = {
          start: period.start,
          end: period.end,
        }
        return acc
      },
      {},
    )

    return businessHours
  }

  return {}
}

type UseBusinessHoursProps = {
  warehouse: Warehouse | null
}

type UseBusinessHoursState = {
  businessHoursForCalendar: EventInput[]
  businessHoursByDays: Record<number, Period>
}

export const useBusinessHours = ({ warehouse }: UseBusinessHoursProps): UseBusinessHoursState => {
  const businessHoursForCalendar = useMemo(() => {
    return getBusinessHoursForCalendarFeed(warehouse)
  }, [warehouse])

  const businessHoursByDays = useMemo(() => {
    return getWorkingHourseByDays(warehouse)
  }, [warehouse])

  return { businessHoursForCalendar, businessHoursByDays }
}
