import { createCategoriesApi, baseUri } from '@r1-webui/productcatalog-categories-public-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/company-catalog/${baseUri}`

export const categoriesApi = createCategoriesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
