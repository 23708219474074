// @flow

import invariant from 'invariant'
import { suppressThrownError } from '../../utils/errorHandlers'
import type { AttributeValue } from '../../../types/internal/api/products/productAttributes'
import type { AttributeDefinition } from '../../../types/internal/api/attributeDefinitions'
import type { UnitAttribute } from '../../../types/public/unitAttributes'
import type { AttributeType } from '../../../types/public/attributeDefinitionsCommon'
import type {
  ProductAttribute,
  DateRangeAttributeValue,
  NumberRangeAttributeValue,
} from '../../../types/public/productAttributes'

type AttributeInfo = ProductAttribute | UnitAttribute

export type Measurements = { [string]: {| id: string, name: string |} }

const findEnumValue = (
  enumId: string,
  enumArray: $ReadOnlyArray<{| +id: string, +value: string | number |}>,
): string => {
  const enumV = enumArray.find(({ id }) => enumId === id)
  return enumV ? `${enumV.value}` : ''
}

function mapUnitOfMeasurement(unitOfMeasurement) {
  return unitOfMeasurement ? { id: unitOfMeasurement.id, name: unitOfMeasurement.name } : undefined
}

type AttributeValueMap = {|
  Integer: number,
  Decimal: number,
  String: string,
  Date: string,
  DecimalEnumeration: string,
  IntEnumeration: string,
  StringEnumeration: string,
  Boolean: boolean,
  DateRange: DateRangeAttributeValue,
  IntRange: NumberRangeAttributeValue,
  DecimalRange: NumberRangeAttributeValue,
|}

const mapAttributeValue = <Type: AttributeType>(
  valueType: Type,
  attributeValue: void | AttributeValue,
): null | $ElementType<AttributeValueMap, Type> => {
  if (!attributeValue) return null
  let value = null
  /**
   * Hack to resolve flow errors.
   * @link https://github.com/facebook/flow/issues/7622
   */
  const type: string = valueType
  const message = 'Type of attribute value should correspond to type of attribute definition'
  switch (attributeValue.valueType) {
    case 'IntValue': {
      invariant(type === 'Integer', message)
      value = attributeValue.value
      break
    }
    case 'DecimalValue': {
      invariant(type === 'Decimal', message)
      value = attributeValue.value
      break
    }
    case 'DateValue': {
      invariant(type === 'Date', message)
      value = attributeValue.value
      break
    }
    case 'StringValue': {
      invariant(type === 'String', message)
      value = attributeValue.value
      break
    }
    case 'BoolValue': {
      invariant(type === 'Boolean', message)
      value = attributeValue.value
      break
    }
    case 'DateRange': {
      invariant(type === 'DateRange', message)
      value = { from: attributeValue.from, to: attributeValue.to }
      break
    }
    case 'IntRange': {
      invariant(type === 'IntRange', message)
      value = { from: attributeValue.from, to: attributeValue.to }
      break
    }
    case 'DecimalRange': {
      invariant(type === 'DecimalRange', message)
      value = { from: attributeValue.from, to: attributeValue.to }
      break
    }
    case 'EnumValue': {
      invariant(
        type === 'IntEnumeration' || type === 'StringEnumeration' || type === 'DecimalEnumeration',
        message,
      )
      value = attributeValue.value
      break
    }
    default:
      throw new Error('Unknown attribute value type')
  }
  // TODO: should add unit test to check that value corresponds to input attribute definition type
  return (value: any)
}

const unsafeFormatAttribute = (
  attributeValue: void | AttributeValue,
  attributeTitle: string,
  definition: AttributeDefinition,
): {| ...AttributeInfo, type: string |} => {
  const { id, type, valueType, enumValues, unitOfMeasurement } = definition

  switch (valueType) {
    case 'IntRange':
    case 'DecimalRange': {
      return {
        id,
        name: attributeTitle,
        type,
        $type: 'numberRange',
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'DateRange': {
      return {
        id,
        name: attributeTitle,
        type,
        $type: 'dateRange',
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'Date': {
      return {
        id,
        name: attributeTitle,
        $type: 'date',
        type,
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'Decimal':
    case 'Integer': {
      return {
        id,
        name: attributeTitle,
        $type: 'number',
        type,
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'String': {
      return {
        id,
        name: attributeTitle,
        $type: 'string',
        type,
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'Boolean': {
      return {
        id,
        name: attributeTitle,
        $type: 'boolean',
        type,
        value: mapAttributeValue(valueType, attributeValue),
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }

    case 'IntEnumeration':
    case 'StringEnumeration':
    case 'DecimalEnumeration': {
      const enums = enumValues ? enumValues.enumValues : []
      const enumId = mapAttributeValue(valueType, attributeValue)
      const enumValue = enumId && findEnumValue(enumId, enums)
      return {
        id,
        name: attributeTitle,
        $type: 'enum',
        type,
        value: enumValue,
        unitsOfMeasurement: mapUnitOfMeasurement(unitOfMeasurement),
      }
    }
    default:
      throw new Error('Unknown attribute definition type')
  }
}

// eslint-disable-next-line prettier/prettier
export const formatAttribute = suppressThrownError/*:: <typeof unsafeFormatAttribute, _> */(
  unsafeFormatAttribute,
  null,
)
