import React from 'react'
import { connect } from 'react-redux'

import { FormField, Flex } from '@r1/ui-kit'
import { BlockType, Ids, InputType, FontSizes } from '../../constants'
import { changeBlockProperty } from '../../actions'
import Container from './Container'
import Input from './Input'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState
  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    if (block.type === BlockType.REPEATER_BLOCK) {
      return {
        blockId: block.id,
        ...block,
      }
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onNameChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['name'], oldValue, newValue)),
  onSourceChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['source'], oldValue, newValue)),
  onSpaceBetweenChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['spaceBetween'], oldValue, newValue)),
  onFontSizeChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['fontSize'], oldValue, newValue)),
  onItemChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['item'], oldValue, newValue)),
  onOverflowChange: (blockId, oldValue, newValue) =>
    dispatch(changeBlockProperty(blockId, ['overflow'], oldValue, newValue)),
})

export const ListBlockPropertiesPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    blockId,
    name,
    source,
    spaceBetween,
    fontSize,
    item,
    overflow,
    onNameChange,
    onSourceChange,
    onSpaceBetweenChange,
    onFontSizeChange,
    onItemChange,
    onOverflowChange,
    ...props
  }) => {
    if (typeof blockId === 'undefined' || item === undefined) {
      return null
    }

    return (
      <Container {...props}>
        <Flex>
          <FormField.Label>Name</FormField.Label>
          <Input
            type={InputType.TEXT}
            data={name}
            onChange={newValue => onNameChange(blockId, name, newValue)}
          />
        </Flex>
        <Flex>
          <FormField.Label>Source</FormField.Label>
          <Input
            type={InputType.TEXT}
            data={source}
            onChange={newValue => onSourceChange(blockId, source, newValue)}
          />
        </Flex>
        <Flex label="">
          <FormField.Label>Space between</FormField.Label>
          <Input
            type={InputType.NUMBER}
            data={spaceBetween}
            onChange={newValue => onSpaceBetweenChange(blockId, spaceBetween, newValue)}
          />
        </Flex>
        <Flex>
          <FormField.Label>Font size</FormField.Label>
          <Input
            type={InputType.SELECT}
            data={fontSize}
            options={FontSizes.map(fs => ({
              id: fs,
              name: `${fs} pt`,
            }))}
            onChange={newValue => onFontSizeChange(blockId, fontSize, newValue)}
          />
        </Flex>

        <h3>Item settings</h3>
        <Flex>
          <FormField.Label>Height</FormField.Label>
          <Input
            type={InputType.NUMBER}
            data={item.height}
            onChange={newValue => onItemChange(blockId, item, { ...item, height: newValue })}
          />
        </Flex>
        <Flex>
          <FormField.Label>Overflow text</FormField.Label>
          <Input
            type={InputType.TEXT}
            data={item.overflowText}
            onChange={newValue => onItemChange(blockId, item, { ...item, overflowText: newValue })}
          />
        </Flex>

        <h3>Overflow settings</h3>
        <Flex>
          <FormField.Label>Height</FormField.Label>
          <Input
            type={InputType.NUMBER}
            data={overflow.height}
            onChange={newValue =>
              onOverflowChange(blockId, overflow, { ...overflow, height: newValue })
            }
          />
        </Flex>
        <Flex>
          <FormField.Label>Text</FormField.Label>
          <Input
            type={InputType.TEXT}
            data={overflow.text}
            onChange={newValue =>
              onOverflowChange(blockId, overflow, { ...overflow, text: newValue })
            }
          />
        </Flex>
      </Container>
    )
  },
)

/* ListBlockPanel.propTypes = {
  blockId: PropTypes.number,
  name: PropTypes.string,
  source: PropTypes.string,
  spaceBetween: PropTypes.number,
  fontSize: PropTypes.number,
  item: PropTypes.shape({
    height: PropTypes.number,
    overflowText: PropTypes.string,
  }),
  overflow: PropTypes.shape({
    height: PropTypes.number,
    text: PropTypes.string,
  }),
  onNameChange: PropTypes.func.isRequired,
  onSourceChange: PropTypes.func.isRequired,
  onSpaceBetweenChange: PropTypes.func.isRequired,
  onFontSizeChange: PropTypes.func.isRequired,
  onItemChange: PropTypes.func.isRequired,
  onOverflowChange: PropTypes.func.isRequired,
} */
