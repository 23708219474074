import { Facility, DailySchedule, Shift, ShippingCutOffTime } from '@r1-webui/facilities-v1'
import { getTimePickerValue } from './getTimePickerValue'

const mapPeriods = (value: DailySchedule | Shift | ShippingCutOffTime) => {
  const mappedPeriods = value.periods?.map(period => {
    return {
      ...period,
      from: getTimePickerValue(period.from),
      to: getTimePickerValue(period.to),
    }
  })
  return {
    ...value,
    periods: mappedPeriods,
  }
}

export const mapFacilityToForm = (facility: Facility): Facility => {
  const { schedule = [], shifts = [], shippingCutOffTimes = [] } = facility

  const mappedSchedule = schedule.map(mapPeriods)
  const mappedShifts = shifts.map(mapPeriods)
  const mappedCutOffTimes = shippingCutOffTimes.map(mapPeriods)

  return {
    ...facility,
    schedule: mappedSchedule as DailySchedule[],
    shifts: mappedShifts as Shift[],
    shippingCutOffTimes: mappedCutOffTimes as ShippingCutOffTime[],
  }
}
