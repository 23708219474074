// @flow

import {
  baseUri,
  createRulesApi,
  createCurrenciesApi,
  createAggregationMethodsApi,
} from '@r1-webui/productprices-companypriceconsolidationrules-v1'
import type { Api as RulesApi } from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/rules'
import type { Api as CurrenciesApi } from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/currencies'
import type { Api as AggregationMethodsApi } from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/aggregationMethods'
import { UI_API_PREFIXES } from '../../../api'

const API_PREFIX_COMPANY = `${UI_API_PREFIXES.PRICING_RULES}/${baseUri}`

export type CompanyPriceConsolidationRulesApi = {|
  ...RulesApi,
  ...CurrenciesApi,
  ...AggregationMethodsApi,
|}

export const createCompanyPriceConsolidationRulesApi = (
  httpClient: $AnyObject,
): CompanyPriceConsolidationRulesApi => {
  return {
    ...createRulesApi({ http: httpClient, urlPrefix: API_PREFIX_COMPANY }),
    ...createCurrenciesApi({ http: httpClient, urlPrefix: API_PREFIX_COMPANY }),
    ...createAggregationMethodsApi({ http: httpClient, urlPrefix: API_PREFIX_COMPANY }),
  }
}
