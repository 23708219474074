import { evolve } from 'ramda'

export function createReducer(initialState, reducers) {
  // eslint-disable-next-line default-param-last
  return (state = initialState, { type, ...payload }) => {
    const handler = reducers[type]

    if (type === 'reset') {
      return initialState
    }

    if (handler) {
      return handler(state, payload)
    }

    return state
  }
}

export function createReducerRamda(initialState, reducers) {
  // eslint-disable-next-line default-param-last
  return (state = initialState, { type, ...payload }) => {
    const reducer = reducers[type]

    if (type === 'reset') {
      return initialState
    }

    return reducer ? evolve(reducer(payload), state) : state
  }
}
