import * as React from 'react'
import * as R from 'ramda'
import { Control, useController } from 'react-hook-form'
import { Period as PeriodType } from '@r1-webui/facilities-v1'
import { Box, Button, Flex, FormField, Icon, Select, TimePicker } from '@r1/ui-kit'
import { getDayOfWeek } from '../../../utils/getDayOfWeek'
import { Value } from '../../Value'

export const Period = ({
  period,
  control,
  editable,
  indexPrefix,
  formPrefix,
  dataTestIdPrefix: dataTestId,
  periodStartLabel = 'Start day',
  periodFinishLabel = 'Finish Day',
  periodFromLabel = 'Start time',
  periodToLabel = 'Finish time',
  onAddPeriod,
  onRemovePeriod,
}: {
  period: PeriodType
  control: Control
  editable?: boolean
  indexPrefix: number
  formPrefix: string
  dataTestIdPrefix: string
  periodStartLabel?: string
  periodFinishLabel?: string
  periodFromLabel?: string
  periodToLabel?: string
  onAddPeriod: () => void
  onRemovePeriod: () => void
}) => {
  const {
    field: { ref: _ref, ...startDay },
  } = useController({
    name: `${formPrefix}.${indexPrefix}.startDay`,
    control,
    defaultValue: period.startDay,
  })
  const { field: from } = useController({
    name: `${formPrefix}.${indexPrefix}.from`,
    control,
    defaultValue: period.from,
  })
  const {
    field: { ref: _refFinish, ...finishDay },
    formState: { errors },
  } = useController({
    name: `${formPrefix}.${indexPrefix}.finishDay`,
    control,
    defaultValue: period.finishDay,
  })
  const { field: to } = useController({
    name: `${formPrefix}.${indexPrefix}.to`,
    control,
    defaultValue: period.to,
  })

  const dataTestIdPrefix = `${dataTestId}__period-${indexPrefix}`

  const periodFinishError = R.pathOr(
    null,
    [...formPrefix.split('.'), indexPrefix, 'finishDay', 'message'],
    errors,
  ) as string

  const periodError = R.pathOr(
    null,
    [...formPrefix.split('.'), indexPrefix, 'to', 'message'],
    errors,
  ) as string

  return (
    <Flex spaceBetween="M" align="center">
      <FormField>
        <FormField.Label>{periodStartLabel}</FormField.Label>
        {editable ? (
          <Select
            simpleValue
            {...startDay}
            options={getDayOfWeek()}
            data-test-id={`${dataTestIdPrefix}__start-day`}
          />
        ) : (
          <Value>{period.startDay}</Value>
        )}
      </FormField>
      <FormField>
        <FormField.Label>{periodFromLabel}</FormField.Label>
        {editable ? (
          <TimePicker format="HH:MM a" {...from} data-test-id={`${dataTestIdPrefix}__from`} />
        ) : (
          <Value>{period.from}</Value>
        )}
      </FormField>
      <FormField>
        <FormField.Label>{periodFinishLabel}</FormField.Label>
        {editable ? (
          <Select
            simpleValue
            {...finishDay}
            options={getDayOfWeek()}
            data-test-id={`${dataTestIdPrefix}__finish-day`}
          />
        ) : (
          <Value>{period.finishDay}</Value>
        )}
        <FormField.Error>{periodFinishError}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>{periodToLabel}</FormField.Label>
        {editable ? (
          <TimePicker format="HH:MM a" {...to} data-test-id={`${dataTestIdPrefix}__to`} />
        ) : (
          <Value>{period.to}</Value>
        )}
        <FormField.Error>{periodError}</FormField.Error>
      </FormField>
      {editable && (
        <Flex mt="XS" spaceBetween="XS">
          <Box>
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__add-period`}
              onClick={onRemovePeriod}
            >
              <Icon type="minus" size="S" />
            </Button>
          </Box>
          <Box>
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__remove-period`}
              onClick={onAddPeriod}
            >
              <Icon type="plus" size="S" />
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
