// @flow

import type { ClaimCreationState } from '../components/ClaimCreationForm/types'
import type { Order } from '../types/ClaimCreation'

export type Validator = (value: any, state: any) => boolean
export type Messenger = (value: any) => string

export type ValidationStep = [Validator, Messenger]

export type ValidationSchema = ValidationStep[]

/** Helpers */

const isMoney = value => {
  if (!value || typeof value !== 'object') {
    return false
  }

  const props = Object.keys(value)

  return props.includes('amount') && props.includes('currency')
}

/** Validators */

const isRequired = (message: string) => [
  (value: any) => (isMoney(value) ? !value.amount : !value),
  () => message || 'Field is required!',
]

const minLength = (count: number) => [
  (value: string) => value.length < count,
  () => `Must be equal or more than ${count} characters long`,
]
const maxLength = (count: number) => [
  (value: string) => value.length > count,
  () => `Can't be more than ${count} characters long`,
]

// eslint-disable-next-line no-unused-vars
const regExpMatch = (regExp: RegExp, message: string) => [
  (value: string) => !regExp.test(value),
  () => message,
]

const isOrderLineRequired = (message: string) => [
  orderLines => !orderLines.some(orderLine => orderLine.selected),
  () => message,
]

const hasNoAnyOrderLineWithFieldError = (fieldName: string) => {
  const getAnyOrderLineWithError = orderLines => {
    return orderLines.find(orderLine => orderLine.selected && orderLine[fieldName])
  }
  return [
    orderLines => !!getAnyOrderLineWithError(orderLines),
    orderLines => getAnyOrderLineWithError(orderLines)[fieldName] || '',
  ]
}

const isSupportedVenueProfile = (message: string) => [
  (order: Order, state: ClaimCreationState) =>
    !!state.rmaProfile && state.rmaProfile.venueTypeInfo.name === 'Backmarket',
  () => message,
]

/** Order */

export const orderSearchString: ValidationSchema = [
  isRequired('Please enter an identifier to search an order'),
  minLength(1),
  maxLength(50),
]

export const order: ValidationSchema = [
  isRequired('Please select an order to create a claim.'),
  isSupportedVenueProfile('Claim for selected marketplace cannot be created via Vendor Portal.'),
]

/** Claim */

export const expectedDecisionTypeId: ValidationSchema = [
  isRequired('Please select a claim decision.'),
]

export const orderLines: ValidationSchema = [
  isOrderLineRequired('Please select an order line to create a claim.'),
  hasNoAnyOrderLineWithFieldError('returnQuantityError'),
  hasNoAnyOrderLineWithFieldError('returnReasonIdError'),
  hasNoAnyOrderLineWithFieldError('refundAmountError'),
  hasNoAnyOrderLineWithFieldError('replacementsError'),
]
