// @flow

import { attributeCanHaveEnumValues } from '../../../AttributeDefinition/utils'

import type { AttributeDefinition } from '../../../../types/internal/api/attributeDefinitions'
import type {
  AttributeDefinitionUpdate,
  EnumValueArray,
} from '../../../../types/internal/api/attributeDefinitionManagement/definitions'
import type { FormManagerValues } from '../../../../types/public/AttributeEditFormController'

function createEnumValueArray({
  attributeValueType,
  enumValues,
}: {
  enumValues: { id: string, value: string }[],
  attributeValueType: string,
}): EnumValueArray {
  if (attributeValueType === 'IntEnumeration') {
    return {
      valueType: 'IntEnumArray',
      enumValues: enumValues.map(({ id, value }) =>
        /^tag-input_internal-id_/.test(id)
          ? { value: parseInt(value, 10) }
          : { id, value: parseInt(value, 10) },
      ),
    }
  }

  if (attributeValueType === 'StringEnumeration') {
    return {
      valueType: 'StringEnumArray',
      enumValues: enumValues.map(({ id, value }) =>
        /^tag-input_internal-id_/.test(id) ? { value } : { id, value },
      ),
    }
  }

  if (attributeValueType === 'DecimalEnumeration') {
    return {
      valueType: 'DecimalEnumArray',
      enumValues: enumValues.map(({ id, value }) =>
        /^tag-input_internal-id_/.test(id) ? { value } : { id, value },
      ),
    }
  }

  throw new Error(
    `[product-catalog] unexpected attribute value type: ${attributeValueType}, expected enum`,
  )
}

export function createAttributeDefinitionUpdate({
  attributeDefinition,
  formManagerValues,
}: {
  attributeDefinition: AttributeDefinition,
  formManagerValues: FormManagerValues,
}): AttributeDefinitionUpdate {
  const update: AttributeDefinitionUpdate =
    attributeDefinition.type === 'ProductAttributeDefinition'
      ? { name: formManagerValues.attributeName, type: 'ProductAttributeDefinitionUpdate' }
      : { name: formManagerValues.attributeName, type: 'UnitAttributeDefinitionUpdate' }

  if (attributeCanHaveEnumValues(attributeDefinition.valueType)) {
    update.enumValues = createEnumValueArray({
      attributeValueType: attributeDefinition.valueType,
      enumValues: formManagerValues.attributeEnumValues,
    })
  }

  if (formManagerValues.unitOfMeasurement) {
    update.unitOfMeasurementId = formManagerValues.unitOfMeasurement
  }

  return update
}
