// @flow

import * as React from 'react'
import styled from '@emotion/styled'

const StyledNotSpecified = styled('span')`
  color: ${({ theme }) => theme.palette.grey[600]};
`

export const NotSpecified = () => <StyledNotSpecified>Not specified</StyledNotSpecified>
