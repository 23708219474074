// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Box, Icon, Link, Text } from '@r1/ui-kit'
import { Table } from '@r1/grid'

import { gridTheme } from '../../../../constants'
import type { SharingsTableProps } from '../../types'

const sharedHeaders = [
  { id: 'name', name: 'Profile name' },
  { id: 'id', name: 'Provider' },
  { id: 'owningCompanyId', name: 'Owner' },
  { id: 'approvedOn', name: 'Status' },
  { id: 'actions', name: 'Actions' },
]

const SharingStatus = styled('span')`
  display: inline-block;
  vertical-align: top;
  color: ${({ isApproved, theme: { palette } }) =>
    isApproved ? palette.green[500] : palette.grey[500]};
  &:before {
    content: '${({ isApproved }) => (isApproved ? 'Accepted' : 'Pending')}';
  }
`

const ActionLink = styled(Link)`
  padding: 0 5px;
  color: ${({ type, theme: { palette } }) =>
    type === 'accept' ? palette.green[500] : palette.red[500]};
`

const ActionIcon = styled(Icon)`
  color: ${({ theme: { palette } }) => palette.blue[500]};
`

const tableSharedData = ({ data, profilesLoading, onAcceptSharing, onRejectSharing }) => ({
  displaySettings: {
    showCounter: true,
    columnsSettings: sharedHeaders.map(header => ({
      width: header.id === 'actions' || header.id === 'approvedOn' ? 150 : 180 + 130 / 3,
      header: {
        content: <div>{header.name}</div>,
      },
      cell: {
        $type: header.id === 'actions' || header.id === 'approvedOn' ? 'custom' : 'text',
        field: header.id,
        // eslint-disable-next-line consistent-return
        renderer: field => {
          if (header.id === 'actions') {
            return (
              <Box minWidth={1} maxWidth={1}>
                {field.approvedOn ? (
                  <Box>
                    <ActionIcon
                      cursor="pointer"
                      type="trash"
                      onClick={_ => {
                        if (!profilesLoading[field.id] && onRejectSharing) {
                          onRejectSharing(field)
                        }
                      }}
                    />
                  </Box>
                ) : (
                  <React.Fragment>
                    <ActionLink
                      type="accept"
                      onClick={_ =>
                        !profilesLoading[field.id] && onAcceptSharing && onAcceptSharing(field)
                      }
                    >
                      Accept
                    </ActionLink>
                    <ActionLink
                      type="reject"
                      onClick={_ =>
                        !profilesLoading[field.id] && onRejectSharing && onRejectSharing(field)
                      }
                    >
                      Reject
                    </ActionLink>
                  </React.Fragment>
                )}
              </Box>
            )
          }

          if (header.id === 'approvedOn') {
            return <SharingStatus isApproved={field.approvedOn} />
          }
        },
      },
    })),
  },
  height: data && data.length * gridTheme.rowHeight,
  data,
})

export const SharingsTable = ({
  purposeName,
  sharedCompanies,
  profilesLoading,
  onAcceptSharing,
  onRejectSharing,
}: SharingsTableProps) => {
  const sharedData = tableSharedData({
    data: sharedCompanies,
    profilesLoading,
    onAcceptSharing,
    onRejectSharing,
  })

  if (!sharedCompanies || sharedCompanies.length === 0) return null

  return (
    <React.Fragment>
      <Box pt="M">
        <Text weight="bold" type="paragraph">
          Shared {purposeName && purposeName.toLowerCase()} profiles (foreign)
        </Text>
      </Box>
      <Box maxWidth={1015}>
        {/* $FlowFixMe[incompatible-type] */}
        <Table {...sharedData} />
      </Box>
    </React.Fragment>
  )
}
