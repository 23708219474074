// @flow

import React, { memo, useCallback, useState, useMemo, useContext } from 'react'
import styled from '@emotion/styled'
import { Button, Flex, Input, Modal, Box, NotificationSystem, Text } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { join } from 'ramda'
import { ServiceContext } from '../../../../provider'
import { CategoriesTreeSelect } from '../../../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'
import type { CategoryFormManagerValues } from '../../../../types/category.type'

type ChangeParentProps = {
  onSuccessfulSubmit: () => void,
  category: CategoryFormManagerValues,
  version: string,
}

const TreeSelectContainer = styled('div')`
  height: 44px;
  & > div {
    position: absolute;
  }
`

export const ChangeParent = memo<ChangeParentProps>((props: ChangeParentProps) => {
  const { category, onSuccessfulSubmit, version } = props
  const { categoryService, httpClient } = useContext(ServiceContext)

  const [parentId, setParentId] = useState(category.parentId || null)

  const pathMemo = useMemo(() => join(' / ', category.parentPath || []), [category.parentPath])

  const onSaveParentCallback: () => any = useCallback(async () => {
    const { error } = await categoryService.updateCategory({
      category: { ...category, parentId, version },
      categoryId: category.id,
    })

    if (!error) {
      NotificationSystem.addNotification({
        level: 'success',
        title: `${category.name} category was updated successfully`,
      })

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit()
      }
    } else {
      NotificationSystem.addNotification({ level: 'error', title: error })
    }
  }, [category, categoryService, onSuccessfulSubmit, parentId, version])

  const onChangeCategoryId = useCallback(value => setParentId(value || null), [])

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Save',
          transparent: false,
          onClick: () => {
            onSaveParentCallback()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onSaveParentCallback],
  )

  return (
    <Modal
      isControlled={false}
      size="S"
      title="Change Parent Category"
      actionButtons={modalActionButtons}
      buttonElement={({ onOpen }) => (
        <Flex spaceBetween="M">
          <Input disabled value={pathMemo} onChange={() => {}} />
          <Box>
            <Button color="secondary" onClick={onOpen}>
              Change Category
            </Button>
          </Box>
        </Flex>
      )}
    >
      <Flex column minWidth={544} spaceBetween="S">
        <Box>
          <Text>
            Changing this category will delete and update all attributes for it. This step cannot be
            undone.
          </Text>
        </Box>
        <Box grow>
          <TreeSelectContainer>
            <CategoriesTreeSelect
              width={544}
              multiple={false}
              placeholder="Select parent category"
              hideCategoryIds={[Number(category.id)]}
              httpClient={httpClient}
              categoryId={parentId}
              onChangeCategoryId={onChangeCategoryId}
            />
          </TreeSelectContainer>
        </Box>
      </Flex>
    </Modal>
  )
})
