// @flow

import React from 'react'
import styled from '@emotion/styled'

export const Value = styled('span')`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.grey[800]};
`

const StyledNotSpecified = styled('span')`
  color: ${({ theme }) => theme.palette.grey[600]};
`

export const NotSpecified = () => <StyledNotSpecified>Not specified</StyledNotSpecified>
