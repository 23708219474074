// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { FormField, Button, Box, Flex } from '@r1/ui-kit'
import { HIDDEN_TEXT, EDIT_PROFILE_TEXT } from '../../../../constants'
import type { Field } from '../../types'

const TextWrapper = styled(Box)`
  padding-top: 10px;
  word-break: break-word;
  width: 100%;
`

export const Fields = ({
  fields,
  isAllowedToEdit,
  setEditable,
}: {
  fields: Array<Field>,
  isAllowedToEdit: boolean,
  setEditable: (editable?: boolean) => void,
}) => (
  <React.Fragment>
    {fields.map(field => (
      <Flex key={field.title}>
        <Box minWidth={336}>
          <FormField.Label data-test-id={field.title}>{field.title}</FormField.Label>
        </Box>
        <TextWrapper>{field.isHidden ? HIDDEN_TEXT : field.value}</TextWrapper>
      </Flex>
    ))}
    <Box py="M">
      <Button disabled={!isAllowedToEdit} onClick={() => setEditable()}>
        {EDIT_PROFILE_TEXT}
      </Button>
    </Box>
  </React.Fragment>
)
