import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { UpdateRuleModel } from '@r1-webui/legacy-parts-v1'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Link } from '@r1/ui-kit'

import { supplyLevelRulesService } from '../services'
import { SupplyLevelRuleForm } from '../components/SupplyLevelRuleForm'
import { ROUTES } from '../../../navigation'

const emptySupplyLevelRule = {
  name: '',
  facilityId: '',
  identifierValues: [],
  requiredCount: 1,
  partCategoryId: '',
}

export const AddSupplyLevelRule = () => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = useCallback(
    async (settings: UpdateRuleModel) => {
      try {
        setIsSubmitting(true)
        await supplyLevelRulesService.createRule(settings)
        navigate(ROUTES.SUPPLY_LEVEL_RULES)
      } finally {
        setIsSubmitting(false)
      }
    },
    [navigate],
  )

  const handleClose = useCallback(() => {
    navigate(ROUTES.SUPPLY_LEVEL_RULES)
  }, [navigate])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSupplyLevelRuleEdit]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Breadcrumbs>
            <Link to={ROUTES.SUPPLY_LEVEL_RULES}>Supply Level Rules</Link>
          </ContentHeader.Breadcrumbs>
          <ContentHeader.Title>Create New Supply Level Rule</ContentHeader.Title>
        </ContentHeader>

        <Content>
          <SupplyLevelRuleForm
            initialValue={emptySupplyLevelRule}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
