import * as React from 'react'
import { Country } from '@r1-webui/addressmanagement-v1'
import { countriesApi } from '../../../api/addresses/addresses'
import { showNotification, Notification } from '../../../utils/notifications/showNotification'

export const useCountries = () => {
  const [countries, setCountries] = React.useState<Array<Country> | null>(null)

  React.useEffect(() => {
    const countriesErrorMessage: Notification = {
      level: 'error',
      message: 'Failed to load countries',
    }

    countriesApi
      .getCountries({ acceptLanguage: 'en' })
      .then(({ body, status }) => {
        if (status !== 200) {
          showNotification(countriesErrorMessage)
        } else {
          setCountries(body.map(country => ({ id: country.code, ...country })))
        }
      })
      .catch(() => {
        showNotification(countriesErrorMessage)
      })
  }, [])

  return { countries }
}
