// @flow

import React from 'react'

import { Flex, Format } from '@r1/ui-kit'

import type { OrderInfoBlockProps } from '../../../types/ClaimCreation'
import { InfoBlockTitle, Property, Value } from '../StyledComponents'

export const OrderInfoBlock = (props: OrderInfoBlockProps) => {
  const { marketplaceOrderId, purchaseOrderId, createdOn, orderStatus, orderTotal } = props

  return (
    <Flex column mr="XL">
      <InfoBlockTitle>Order Info</InfoBlockTitle>
      <Flex>
        <Property>Marketplace ID:</Property>
        <Value>{marketplaceOrderId}</Value>
      </Flex>
      <Flex>
        <Property>Purchase ID:</Property>
        <Value>{purchaseOrderId}</Value>
      </Flex>
      <Flex>
        <Property>Created:</Property>
        <Value>{createdOn && <Format.Date>{createdOn}</Format.Date>}</Value>
      </Flex>
      <Flex>
        <Property>Status:</Property>
        <Value isExpired={orderStatus.status === 'Expired'}>
          {orderStatus.status}{' '}
          {orderStatus.status === 'Expired'
            ? `${orderStatus.daysAfterExpiring || 0} days ago`
            : null}
        </Value>
      </Flex>
      <Flex>
        <Property>Order Total:</Property>
        <Value>
          {orderTotal.currency} {orderTotal.amount}
        </Value>
      </Flex>
    </Flex>
  )
}
