// @flow

import { createUsersApi } from '@r1-webui/usermanagement-v1'
import type { Api } from '@r1-webui/usermanagement-v1/src/users'
import { httpClient } from '../../httpClient'

// eslint-disable-next-line import/no-unused-modules
export type UsersApi = Api

const API_PREFIX = `/uisvc/user-management`

export const usersApi = createUsersApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})
