import { createModelModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productApi } from '../../api/products'

const { actions, name, initialState, types, reducers, selectors } = createModelModule({
  name: '@products/classifications',
  params: ['productId'],
  fetchItemFunc: ({ productId }) => productApi.getClassifications({ id: productId }),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { selectors, actions }
