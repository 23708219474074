// @flow

import type { ProductImageInfo as ProductsApiImageInfo } from '@r1-webui/productcatalog-products-v1/src/types'
import type { ImageInfo as ProductManagementApiImageInfo } from '@r1-webui/productcatalog-productmanagement-v1/src/types'

type Images = {
  primary?: ProductsApiImageInfo,
  additional: ProductsApiImageInfo[],
}

const getImages = ({
  primary,
  additional,
}: Images): { ...ProductManagementApiImageInfo, uploaded: boolean }[] =>
  (primary
    ? [{ url: primary.image.url, isListing: primary.isListing, isPrimary: true, uploaded: true }]
    : []
  ).concat(
    additional.map(({ isListing, image: { url } }) => ({
      url,
      isListing,
      isPrimary: false,
      uploaded: true,
    })),
  )

const setImages = (values: Object) => {
  return values.map(({ url, isListing, isPrimary }) => ({ url, isListing, isPrimary }))
}

export const schema = {
  Images: {
    value: {
      errorValue: 'url',
      getPath: ['images'],
      getTransform: getImages,
      setPath: ['images'],
      setTransform: setImages,
    },
  },
}
