// @flow

import * as React from 'react'

import { getClaimType } from '../../../utils'
import type {
  FormActions,
  ViewProps,
  OrderSearchInfo,
  ClaimCreationInfo,
  SummaryInfo,
  Navigation,
} from '../types'
import { defaultState } from './defaultState'
import { createApiController } from './apiController'
import { createNavigationController } from './navigationController'
import { createValidationController } from './validationController'
import {
  createClaimDetailsHandlerController,
  createClaimInfoHandlerContoller,
  createFormActionHandlerController,
  createOrderSelectionHandlerController,
} from './handlerControllers'
import { WithController } from './types'

export function withController(View: React.ComponentType<ViewProps>) {
  class ViewWithController extends WithController {
    state = defaultState

    api = createApiController(this.props.httpClient)

    navigation = createNavigationController(this)

    validation = createValidationController(this)

    orderSelectionHandler = createOrderSelectionHandlerController(this)

    claimInfoHandler = createClaimInfoHandlerContoller(this)

    claimDetailsHandler = createClaimDetailsHandlerController(this)

    formActionHandler = createFormActionHandlerController(this)

    hasUnsavedChanges = () => {
      const { claimCreation } = this.state
      const excludedFields = ['claimTotal']

      return Object.keys(claimCreation).some(fieldName => {
        if (excludedFields.includes(fieldName)) return false
        return Boolean(claimCreation[fieldName])
      })
    }

    getOrderSearchInfo = (): OrderSearchInfo => {
      const { claimCreation, errors } = this.state

      return {
        selectedOrder: {
          value: claimCreation.order,
          onChange: this.orderSelectionHandler.handleOrderSelection,
          error: errors.order,
        },
        orderSearchString: {
          value: claimCreation.orderSearchString,
          onChange: this.orderSelectionHandler.handleOrderSearchStringChange,
          error: errors.orderSearchString,
        },
        orderSearchAction: {
          dispatch: this.orderSelectionHandler.handleOrderSearch,
          isExecuting: claimCreation.isOrderSearchExecuting,
        },
        foundOrders: claimCreation.orders,
        orderStatuses: claimCreation.orderStatuses,
        rmaProfiles: claimCreation.rmaProfiles,
        shippingAddresses: claimCreation.shippingAddresses,
      }
    }

    getClaimCreationInfo = (): ClaimCreationInfo => {
      const { claimCreation, errors } = this.state
      const {
        order,
        expectedDecisionTypeId,
        claimType,
        claimDescription,
        orderLines,
        currentOrderLine,
        isOrderLineNotesModalVisible,
        isOrderLineReplacementsModalVisible,
        orderStatus,
        rmaProfile,
        shippingAddress,
        returnReasons,
        expectedDecisionTypes,
        claimTotal,
      } = claimCreation

      let availableExpectedDecisionTypes
      if (!orderStatus || ['Shipped', 'Expired'].includes(orderStatus.status)) {
        availableExpectedDecisionTypes = expectedDecisionTypes
      } else {
        // For non-shipped orders available claim types are limited.
        availableExpectedDecisionTypes = expectedDecisionTypes.filter(edt =>
          ['RefundOnly', 'ReplacementOnly'].includes(getClaimType(edt.id)),
        )
      }

      const expectedDecisionType = expectedDecisionTypes.find(
        edt => edt.id === expectedDecisionTypeId,
      )
      const expectedDecisionTypeTitle = expectedDecisionType ? expectedDecisionType.title : ''

      return {
        expectedDecisionTypeTitle,
        claimType,
        selectedExpectedDecisionType: {
          value: expectedDecisionTypeId,
          onChange: this.claimInfoHandler.handleExpectedDecisionTypeSelection,
          error: errors.expectedDecisionTypeId,
          availableOptions: availableExpectedDecisionTypes,
        },
        claimDescription: {
          value: claimDescription,
          onChange: this.claimInfoHandler.handleClaimDescriptionChange,
          error: errors.claimDescription,
        },
        selectedOrderLines: {
          values: orderLines,
          onChange: this.claimDetailsHandler.handleOrderLineSelection,
          error: errors.orderLines,
        },
        order,
        currentOrderLine,
        isOrderLineNotesModalVisible,
        isOrderLineReplacementsModalVisible,
        orderStatus,
        rmaProfile,
        shippingAddress,
        returnReasons,
        claimTotal,
        onOrderLineQuantityChange: this.claimDetailsHandler.handleOrderLineQuantityChange,
        onOrderLineReasonChange: this.claimDetailsHandler.handleOrderLineReasonChange,
        onOrderLineNotesModalShow: this.claimDetailsHandler.handleOrderLineNotesModalShow,
        onOrderLineNotesChange: this.claimDetailsHandler.handleOrderLineNotesChange,
        onOrderLineAmountChange: this.claimDetailsHandler.handleOrderLineAmountChange,
        onOrderLineReplacementsModalShow:
          this.claimDetailsHandler.handleOrderLineReplacementsModalShow,
        onOrderLineReplacementsChange: this.claimDetailsHandler.handleOrderLineReplacementsChange,
      }
    }

    getSummaryInfo = (): SummaryInfo => {
      const {
        order,
        orderLines,
        orderStatus,
        rmaProfile,
        shippingAddress,
        returnReasons,
        claimTotal,
        claimDescription,
        expectedDecisionTypes,
        expectedDecisionTypeId,
        claimType,
      } = this.state.claimCreation

      const expectedDecisionType = expectedDecisionTypes.find(
        edt => edt.id === expectedDecisionTypeId,
      )
      const expectedDecisionTypeTitle = expectedDecisionType ? expectedDecisionType.title : ''

      const claimLines = []
      orderLines
        .filter(orderLine => orderLine.selected)
        .forEach(orderLine => {
          const returnReason = returnReasons.find(r => r.id === orderLine.returnReasonId)
          if (!returnReason) {
            return
          }

          const claimLine = {
            lineId: orderLine.lineId,
            price: orderLine.lineInfo.singleItemPrice.total,
            productInfo: orderLine.productInfo,
            returnQuantity: orderLine.returnQuantity,
            returnReason: returnReason.title,
            refundAmount: orderLine.refundAmount,
            note: orderLine.notes.message,
            images: orderLine.notes.images,
            replacements: orderLine.replacements,
          }
          claimLines.push(claimLine)
        })

      return {
        expectedDecisionTypeTitle,
        claimType,
        claimDescription,
        order,
        orderStatus,
        rmaProfile,
        shippingAddress,
        returnReasons,
        claimTotal,
        claimLines,
      }
    }

    getFormActionsProps = (): FormActions => {
      const { currentStep, steps } = this.state.navigation
      const currentStepIdx = this.navigation.getStepIdxById(currentStep)
      const stepsCount = steps.length - 1

      return {
        prevStep: {
          visible: currentStepIdx > 0,
          disabled:
            this.state.claimCreation.isClaimCreationExecuting ||
            this.state.claimCreation.isOrderSearchExecuting,
          dispatch: this.navigation.handlePrevStep,
        },
        nextStep: {
          visible: currentStepIdx < stepsCount,
          disabled:
            this.state.claimCreation.isClaimCreationExecuting ||
            this.state.claimCreation.isOrderSearchExecuting,
          dispatch: this.navigation.handleNextStep,
        },
        cancelForm: {
          visible: true,
          disabled:
            this.state.claimCreation.isClaimCreationExecuting ||
            this.state.claimCreation.isOrderSearchExecuting,
          dispatch: this.formActionHandler.handleCancelForm,
        },
        submitForm: {
          visible: currentStepIdx === stepsCount,
          isExecuting: this.state.claimCreation.isClaimCreationExecuting,
          dispatch: this.formActionHandler.handleSubmitForm,
        },
      }
    }

    getStepInfo = (): Navigation => this.state.navigation

    render() {
      return (
        <View
          httpClient={this.props.httpClient}
          formActions={this.getFormActionsProps()}
          orderSearchInfo={this.getOrderSearchInfo()}
          claimCreationInfo={this.getClaimCreationInfo()}
          summaryInfo={this.getSummaryInfo()}
          stepInfo={this.getStepInfo()}
          hasUnsavedChanges={this.hasUnsavedChanges()}
          claimType={this.props.claimType}
        />
      )
    }
  }

  return ViewWithController
}
