import React, { useState, useEffect, useCallback } from 'react'

import type { FmsiPreset } from '@r1-webui/usermanagement-v1'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import {
  Button,
  DualList,
  Flex,
  Label,
  Modal,
  NotificationSystem,
  Placeholder,
  Text,
} from '@r1/ui-kit'
import { ContentHeader } from '@r1/wireframe-primary'
import { handleServerError, useAccessControl } from '@r1/core-blocks'
import styled from '@emotion/styled'
import { DualListContainer } from '../../../components/common'
import { fmsiPresetsApi } from '../../../api/fmsiPresets'

type Props = {
  userId: string
  onCancelForm: () => void
}

type State = {
  readonly allPresets: FmsiPreset[]
  readonly selectedIds: string[]
}

const FMSILabelContainer = styled(Flex)`
  [class*='Label'] {
    font-size: 0.9rem;
  }
`

const initialState: State = {
  allPresets: [],
  selectedIds: [],
}

type PresetsListProps = State & {
  isEditMode: boolean
  onChange: (ids: string[]) => void
}

const PresetsList = (props: PresetsListProps) => {
  if (props.isEditMode) {
    return (
      <DualListContainer>
        <DualList
          options={props.allPresets}
          value={props.selectedIds}
          data-test-id="um-fmsi-presets-editable-list"
          // @ts-expect-error
          onChange={props.onChange}
        />
      </DualListContainer>
    )
  }

  const presetIdToName = new Map(props.allPresets.map(preset => [preset.id, preset.name]))
  const names = props.selectedIds.map(id => {
    if (!presetIdToName.has(id)) {
      throw new Error(`User's selected program with id "${id}" doesn't exist`)
    }
    return presetIdToName.get(id)
  })

  return (
    <FMSILabelContainer spaceBetween="S" data-test-id="um-fmsi-presets-readonly-list">
      {names.length > 0 ? (
        names.map(name => (
          <Label key={name} type="default">
            {name}
          </Label>
        ))
      ) : (
        <Text type="paragraph">No FMSI presets selected</Text>
      )}
    </FMSILabelContainer>
  )
}

const { addNotification } = NotificationSystem

export const FmsiPresetsTab = ({ userId, onCancelForm }: Props) => {
  const [state, setState] = useState(initialState)
  const [persistedState, setPersistedState] = useState(state)
  const [isLoading, setIsLoading] = useState(true)
  const [isEditMode, setEditMode] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [{ allowUserEdit }] = useAccessControl()

  useEffect(() => {
    const loadState = async () => {
      const [allPresetsResponse, userPresetsResponse] = await Promise.all([
        fmsiPresetsApi.getAllPresets(),
        fmsiPresetsApi.getUserPresets({ userId }),
      ])

      if (allPresetsResponse.status === 200 && userPresetsResponse.status === 200) {
        const actualState = {
          allPresets: allPresetsResponse.body,
          selectedIds: userPresetsResponse.body,
        }

        setState(actualState)
        setPersistedState(actualState)
        setIsLoading(false)
      } else {
        if (allPresetsResponse.status !== 200) {
          handleServerError(allPresetsResponse)
        }

        if (userPresetsResponse.status !== 200) {
          handleServerError(userPresetsResponse)
        }

        addNotification({
          level: 'error',
          title: "Try again to change user's FMSI presets later",
        })
      }
    }

    loadState()
  }, [userId])

  const onSelectPresets = useCallback(
    (ids: string[]) => {
      setState({ ...state, selectedIds: ids })
    },
    [state],
  )

  const onSave = useCallback(async () => {
    setIsSaving(true)

    const response = await fmsiPresetsApi.setUserPresets(
      { userId },
      { fmsiPresetIds: state.selectedIds },
    )

    if (response.status === 200) {
      setPersistedState(state)
      setEditMode(false)

      addNotification({ level: 'success', title: 'Presets are saved' })
    } else {
      handleServerError(response)
    }

    setIsSaving(false)
  }, [userId, state])

  const onDiscard = useCallback(() => {
    setState(persistedState)
    setEditMode(false)
  }, [persistedState])

  const onCancelEditing = useCallback(
    (showConfirmationModal: () => void) => {
      if (state !== persistedState) {
        showConfirmationModal()
      } else {
        onDiscard()
      }
    },
    [state, persistedState, onDiscard],
  )

  const discardModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'NO',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'YES',
          onClick: () => {
            onDiscard()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onDiscard],
  )

  return (
    <Flex column minWidth={1100} data-test-id="um-fmsi-presets-active">
      <Flex justify="space-between" pt="XL" pb="XXL">
        {isLoading ? (
          <Placeholder type="form" height={5} />
        ) : (
          <PresetsList {...state} isEditMode={isEditMode} onChange={onSelectPresets} />
        )}
      </Flex>

      <ContentHeader.ActionButtons>
        {!isEditMode && (
          <Button color="secondary" onClick={onCancelForm}>
            Close
          </Button>
        )}

        {!isEditMode && allowUserEdit && (
          <Button color="primary" onClick={() => setEditMode(true)}>
            Edit
          </Button>
        )}

        {isEditMode && (
          <Modal
            isControlled={false}
            title="You have unsaved data"
            actionButtons={discardModalActionButtons}
            buttonElement={({ onOpen }) => (
              <Button disabled={isSaving} color="secondary" onClick={() => onCancelEditing(onOpen)}>
                Cancel
              </Button>
            )}
          >
            Are you sure you want to discard unsaved data?
          </Modal>
        )}

        {isEditMode && (
          <Button color="primary" disabled={isSaving} onClick={onSave}>
            Save
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </Flex>
  )
}
