// @flow

type Notification = {|
  level: 'success' | 'error' | 'warning' | 'info',
  title: string,
  message?: string,
|}

type OnNotify = Notification => void

type HttpClientError = {
  response: {
    status: number,
    statusText: string,
    data: {
      noticeTypeCode?: string,
      badProperties?: string,
      defaultFormat?: string,
    },
    noticeTypeCode?: string,
    badProperties?: string,
    defaultFormat?: string,
  },
}

export type Options = {|
  onError?: (error: any) => void,
  onNotify: OnNotify,
|}

export const createSuccessHandler =
  (onNotify: OnNotify) =>
  (notifyTitle: string) =>
  <ResponseData>(data: ResponseData) => {
    onNotify({
      level: 'success',
      title: notifyTitle,
    })
    return data
  }

export const createErrorHandler =
  ({ onError, onNotify }: Options) =>
  (err: HttpClientError) => {
    if (err) {
      const { status, statusText, defaultFormat, data } = err.response
      const notifyData = {
        level: 'error',
        title:
          defaultFormat || (data && data.defaultFormat) || statusText || 'Unhandled server error',
      }

      if (status === 401 && onError) {
        onError({ $type: 'Unauthorized', message: 'Unauthorized' })
      }

      onNotify(notifyData)
    }
  }
