import { ChartProps } from 'react-chartjs-2'
import Chart from 'chart.js/auto'
import { PieData } from '../types'
import { formatNumber } from '../helper'

export const getPieChartSettings = (rawData: PieData[]) => {
  const config: ChartProps<'pie', number[], string> = {
    type: 'pie',
    data: {
      labels: rawData.map(x => String(x.name)),
      datasets: [
        {
          label: '',
          data: rawData.map(x => Number(x.value)),
          backgroundColor: rawData.map(x => x.color),
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          fullSize: true,
          display: true,
          position: 'right',
          labels: {
            boxWidth: 14,
            font: {
              family: 'Roboto',
              size: 12,
            },
            generateLabels: (chart: Chart) => {
              const labels = chart.data.labels as string[]
              const data = chart.data.datasets[0].data as number[]
              const backgroundColors = chart.data.datasets[0].backgroundColor as string[]
              return data.map((value: number, i: number) => {
                const label = labels[i]
                return {
                  text: `${label} ${formatNumber(value)}%`,
                  fillStyle: backgroundColors[i],
                }
              })
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
    },
  }
  return config
}
