// @flow

import React, { memo, useMemo } from 'react'
import { FormField, Link, Flex, HR } from '@r1/ui-kit'
import { useToggle } from '../../../../../utils/hooks'
import type { ProductPricesProps } from '../../../types/public/ProductCardView'
import { Header, Value, Text, CollapseWrapper } from './Common'

export const ProductPrices = memo<ProductPricesProps>(({ prices, header }: ProductPricesProps) => {
  const defaultShowCount = 5
  const pricesLength = prices.length

  const { state: isShowAll, toggle: setShowAll } = useToggle(false)

  const pricesMemo = useMemo(
    () => (isShowAll ? prices : prices.slice(0, defaultShowCount)),
    [prices, isShowAll],
  )

  return (
    <>
      <Header>{header}</Header>
      <>
        {pricesLength === 0 && <Text>There are no prices yet</Text>}
        {pricesMemo.map(({ rule: { name, id }, value: { currency, amount } }, index) => (
          <Flex key={id} spaceBetween="S">
            <Flex column spaceBetween="XXS">
              <FormField.Label>{name}</FormField.Label>
              <Value>
                {currency} {amount}
              </Value>
            </Flex>
            {index !== pricesMemo.length - 1 ? <HR /> : null}
          </Flex>
        ))}
        {defaultShowCount < pricesLength && (
          <CollapseWrapper>
            <Link onClick={() => setShowAll()}>
              {isShowAll ? 'Hide' : <>Show all ({pricesLength})</>}
            </Link>
          </CollapseWrapper>
        )}
      </>
    </>
  )
})
