import React from 'react'
import { Link, Text } from '@r1/ui-kit'
import { Customer } from '@r1-webui/gowholesale-offermanagement-v1'

export const CustomerLink = ({
  customer,
  onClick,
}: {
  customer: Customer
  onClick: () => void
}) => {
  return (
    <Link onClick={onClick}>
      <Text>{customer.company || customer.email}</Text>
    </Link>
  )
}
