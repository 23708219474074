import { createModelModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateApi } from '../../api/productTemplate/index.ts'

const { actions, name, initialState, types, reducers, selectors } = createModelModule({
  name: '@productTemplates',
  params: ['id'],
  // eslint-disable-next-line no-return-await
  fetchItemFunc: async ({ id }, _api) =>
    // eslint-disable-next-line no-return-await
    await productTemplateApi.getProductTemplate({ id }).then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { selectors, actions }
