// @flow

import * as React from 'react'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Flex, Placeholder, Modal, Icon, Link } from '@r1/ui-kit'
import { SubmissionButton } from '@r1/form-manager'

import { ROUTES } from '../../../../../navigation'
import {
  AttributeEditFormController,
  AttributeEditFormView,
} from '../../../components/AttributeEditForm'
import { testIds } from './testIds'

export const FORM_ID = 'attributeEditForm'

type EditAttributeFormProps = {|
  httpClient: $AnyObject,
  onSubmit: () => void,
  onSuccessfulDelete: () => void,
  onCancel: () => void,
  attributeId: string,
|}

export function EditAttributeForm({
  httpClient,
  onCancel,
  attributeId,
  onSubmit,
  onSuccessfulDelete,
}: EditAttributeFormProps) {
  return (
    <Main>
      <ContentHeader>
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.ATTRIBUTES_DEFINITIONS}>Attributes</Link>
        </ContentHeader.Breadcrumbs>
        <ContentHeader.ActionButtons>
          <Button data-test-id={testIds.cancelButton} color="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </ContentHeader.ActionButtons>
      </ContentHeader>
      <Content>
        <Flex column maxWidth={540}>
          <AttributeEditFormController
            httpClient={httpClient}
            attributeId={attributeId}
            onSuccessfulDelete={onSuccessfulDelete}
          >
            {props => {
              if (props.screenType === 'default') {
                const deleteAttributeDefinitionModalActionButtons = ({
                  onClose,
                }): BottomActionButton[] => {
                  return [
                    {
                      title: 'No',
                      color: 'secondary',
                      onClick: () => onClose(),
                      align: 'right',
                    },
                    {
                      title: 'Yes',
                      color: 'danger',
                      transparent: false,
                      onClick: () => {
                        props.handlers.onDelete()
                        onClose()
                      },
                      align: 'right',
                    },
                  ]
                }

                return (
                  <>
                    <ContentHeader.Title>{props.attributeInfo.attributeName}</ContentHeader.Title>
                    <AttributeEditFormView
                      formId={FORM_ID}
                      dataSources={props.dataSources}
                      attributeInfo={props.attributeInfo}
                      onSubmit={props.handlers.onSubmit}
                      onEnumValuesChange={props.handlers.onEnumValuesChange}
                    />
                    <ContentHeader.ActionButtons>
                      <SubmissionButton onSuccessfulSubmit={onSubmit}>
                        {({ handleSubmit, isSubmitting }) => {
                          const deleteAttributeModalActionButtons = ({
                            onClose,
                          }): BottomActionButton[] => {
                            return [
                              {
                                title: 'Cancel',
                                color: 'secondary',
                                onClick: () => onClose(),
                                align: 'right',
                              },
                              {
                                title: 'Delete',
                                color: 'danger',
                                onClick: () => {
                                  handleSubmit()
                                  onClose()
                                },
                                align: 'right',
                              },
                            ]
                          }

                          return props.wasDeletedEnumValues ? (
                            <Modal
                              isControlled={false}
                              title="Delete"
                              actionButtons={deleteAttributeModalActionButtons}
                              buttonElement={({ onOpen }) => (
                                <Button
                                  data-test-id={testIds.submitButton}
                                  loading={isSubmitting}
                                  onClick={onOpen}
                                >
                                  Save
                                </Button>
                              )}
                            >
                              Deleting of available attribute values will affect external
                              dependencies. Are you sure?
                            </Modal>
                          ) : (
                            <Button
                              data-test-id={testIds.submitButton}
                              loading={isSubmitting}
                              onClick={handleSubmit}
                            >
                              Save
                            </Button>
                          )
                        }}
                      </SubmissionButton>
                      <Modal
                        isControlled={false}
                        title="Delete"
                        actionButtons={deleteAttributeDefinitionModalActionButtons}
                        buttonElement={({ onOpen }) => (
                          <Button transparent color="secondary" shape="square" onClick={onOpen}>
                            <Icon type="trash" />
                          </Button>
                        )}
                      >
                        Deleting of attribute definition will affect external dependencies. Are you
                        sure?
                      </Modal>
                    </ContentHeader.ActionButtons>
                  </>
                )
              }
              return <Placeholder type="form" />
            }}
          </AttributeEditFormController>
        </Flex>
      </Content>
    </Main>
  )
}
