import React from 'react'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { Input as BaseInput, Select, Checkbox, ControlGroup } from '@r1/ui-kit'

import { InputType } from '../../constants'

const getLens = propPath => {
  const path = R.split('.', propPath)
  return R.lensPath(path)
}

const getValue = (data, propPath) => {
  if (propPath) {
    return R.view(getLens(propPath), data)
  }

  return data
}

const setValue = (data, propPath, value) => {
  if (propPath) {
    return R.set(getLens(propPath), value, R.clone(data))
  }

  return value
}

const Input = ({ type, data, options, propPath, onChange, ...props }) => {
  const handleChange = value => {
    onChange(setValue(data, propPath, value))
  }

  switch (type) {
    case InputType.NUMBER:
      return (
        <BaseInput
          type="number"
          value={getValue(data, propPath)}
          onChange={v => handleChange(Number.parseFloat(v))}
          {...props}
        />
      )
    case InputType.NUMBERGROUP:
      return (
        <ControlGroup.NumericInput
          value={getValue(data, propPath)}
          onChange={v => handleChange(Number.parseFloat(v))}
          {...props}
        />
      )
    case InputType.TEXT:
      return (
        <BaseInput
          type="text"
          value={getValue(data, propPath)}
          onChange={handleChange}
          {...props}
        />
      )
    case InputType.SELECT:
      return (
        <Select
          options={options}
          value={getValue(data, propPath)}
          clearable={false}
          onChange={handleChange}
          {...props}
        />
      )
    case InputType.CHECKBOX:
      return <Checkbox checked={getValue(data, propPath)} onChange={handleChange} {...props} />
    default:
      return null
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.any,
  propPath: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Input
