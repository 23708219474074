// @flow

import React, { useCallback, useEffect, useState, useMemo, memo } from 'react'
import { Link, Tree, Flex } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { CategoryTreeProps } from '../../../types/public/CategoryTree'

import { categoryService } from '../../../services'
import { categoryApi } from '../../../api'

const CategoryLink = styled(Link)`
  font-size: 14px;
`

export const Text = styled('span')`
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 16px;
  line-height: 24px;
`

const Node = memo(({ categoryUrl, category, searchString }) => {
  if (searchString) {
    const regexp = new RegExp(`(.*)(${searchString})(.*)`, 'i')
    const match = category.name.match(regexp)

    if (match) {
      return (
        <CategoryLink href={categoryUrl(category.id)}>
          {match[1]}
          <b>{match[2]}</b>
          {match[3]}
        </CategoryLink>
      )
    }
  }
  return <CategoryLink href={categoryUrl(category.id)}>{category.name}</CategoryLink>
})

const mapCategories = (categories, categoryUrl, searchString = '') =>
  categories.map(category => ({
    ...category,
    componentRenderer: () => <Node {...{ categoryUrl, category, searchString }} />,
  }))

const addParent = (nodes, idNode) => {
  const add = []
  const node = nodes.find(({ id }) => idNode === id)

  if (node && node.parentId) {
    add.push(...addParent(nodes, node.parentId))
    add.push(...nodes.filter(({ id }) => node.parentId === id))
  }
  return add
}

const filterNodes = (nodes, filtered, searchString) => {
  const filteredNodes = nodes
    .filter(({ name }) => name.match(new RegExp(searchString, 'i')))
    .reduce((acc, node) => [...acc, node, ...addParent(nodes, node.id)], [])
  return filteredNodes
}

export const CategoryTree = (props: CategoryTreeProps) => {
  const { categoryUrl, httpClient, searchString = '' } = props
  const { getAllCategories } = categoryService(categoryApi(httpClient))

  const [loading, setLoading] = useState(false)
  const [categoriesList, setCategoriesList] = useState([])
  const [filteredNodes, setFilteredNodes] = useState([])

  const [expandedAllNodes, setExpandedAllNodes] = useState([])
  const [expandedFilteredNodes, setExpandedFilteredNodes] = useState([])

  const fetchData = useCallback(async () => {
    setLoading(true)
    const categories = await getAllCategories()
    setLoading(false)
    setCategoriesList(mapCategories(categories, categoryUrl))
  }, [categoryUrl, getAllCategories])

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (searchString.length > 0) {
      const filtered = [...new Set(filterNodes(categoriesList, [], searchString))]
      setExpandedFilteredNodes(filtered.map(({ id }) => id))
      setFilteredNodes(mapCategories(filtered, categoryUrl, searchString))
    }
  }, [categoriesList, categoryUrl, searchString])

  const options = useMemo(
    () => (searchString.length > 0 ? filteredNodes : categoriesList),
    [categoriesList, filteredNodes, searchString.length],
  )

  const expandedNodes = useMemo(
    () => (searchString.length > 0 ? expandedFilteredNodes : expandedAllNodes),
    [expandedAllNodes, expandedFilteredNodes, searchString.length],
  )

  const onExpandNode = useMemo(
    () => (searchString.length > 0 ? setExpandedFilteredNodes : setExpandedAllNodes),
    [searchString.length],
  )

  return options.length === 0 && !loading ? (
    <Flex my="M">
      <Text>There are no categories</Text>
    </Flex>
  ) : (
    <Tree
      loading={loading}
      options={options}
      selectedNode={null}
      expandedNodes={expandedNodes}
      onSelectNode={() => {}}
      onExpandNode={onExpandNode}
    />
  )
}
