// @flow

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'

import { Table } from '@r1/grid'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Box, Button, Flex, Link, Icon, Tooltip, Modal, NotificationSystem } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

import { attributeTypeMapper, getAttributeDefinitionType } from './attributeInfoMapper'
import { createApi } from './api'

const AttributesCounter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.space.XXS};
`

const getColumnsSettings = deleteAttribute => [
  {
    width: 290,
    header: {
      content: 'Name',
    },
    cell: {
      $type: 'custom',
      renderer: ({ id, name }) => <Link to={`attribute-definitions/${id}`}>{name}</Link>,
    },
  },
  {
    width: 475,
    header: {
      content: 'Type',
    },
    cell: {
      $type: 'custom',
      renderer: ({ valueType }) => attributeTypeMapper(valueType),
    },
  },
  {
    width: 125,
    header: {
      content: 'Unique',
    },
    cell: {
      $type: 'custom',
      renderer: ({ productUniqueness }) =>
        productUniqueness ? String(productUniqueness).toUpperCase() : 'FALSE',
    },
  },
  {
    width: 125,
    header: {
      content: 'Refers to',
    },
    cell: {
      $type: 'custom',
      renderer: ({ type }) => getAttributeDefinitionType(type),
    },
  },
  {
    width: 100,
    header: {
      content: 'Actions',
    },
    cell: {
      $type: 'custom',
      renderer: ({ id, version }) => {
        const modalActionButtons = ({ onClose }): BottomActionButton[] => {
          return [
            {
              title: 'No',
              color: 'secondary',
              onClick: () => onClose(),
              align: 'right',
            },
            {
              title: 'Yes',
              color: 'danger',
              onClick: () => {
                deleteAttribute(id, version)
                onClose()
              },
              align: 'right',
            },
          ]
        }

        return (
          <Flex>
            <Modal
              isControlled={false}
              title="Delete"
              actionButtons={modalActionButtons}
              buttonElement={({ onOpen }) => (
                <Tooltip text="Delete attribute definition" placement="top">
                  <Button transparent size="S" color="secondary" shape="square" onClick={onOpen}>
                    <Icon type="trash" />
                  </Button>
                </Tooltip>
              )}
            >
              Deleting of attribute definition will affect external dependencies. Are you sure?
            </Modal>
          </Flex>
        )
      },
    },
  },
]

type AttributesTableProps = {| httpClient: $AnyObject, onAddClick: () => void |}

export const AttributesTable = (props: AttributesTableProps) => {
  const { httpClient, onAddClick } = props

  const api = useMemo(() => createApi(httpClient), [httpClient])

  const [isLoading, setIsLoading] = useState(false)
  const [attributes, setAttributes] = useState([])
  const [{ allowAttributeDefinitionCreate }] = useAccessControl()

  const fetchData = async () => {
    setIsLoading(true)
    const response = await api.getAllAttributeDefinitions()
    setAttributes(response.$type === 'Success' ? response.payload : [])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [api])

  const deleteAttribute = useCallback(async (id, version) => {
    const response = await api.deleteAttributeDefinition(id, version)
    switch (response.$type) {
      case 'Success':
        NotificationSystem.addNotification({
          level: 'success',
          title: `Attribute definition was deleted successfully`,
        })
        fetchData()
        break
      case 'Conflict':
        NotificationSystem.addNotification({
          level: 'error',
          title: response.message,
        })
        break

      default:
        NotificationSystem.addNotification({
          level: 'error',
          title: `Attribute definition not deleted`,
        })
        break
    }
  }, [])

  return (
    <Flex column spaceBetween="M" minWidth={1}>
      <Box>
        <Box grow>
          <Flex spaceBetween="XS" align="center">
            <Flex align="center">Attributes</Flex>
            <AttributesCounter>{attributes.length}</AttributesCounter>
          </Flex>
        </Box>
        <Box alignSelf="flex-end">
          {allowAttributeDefinitionCreate && (
            <Button onClick={onAddClick}>
              <Icon type="plus" color="inverted" />
              Add new
            </Button>
          )}
        </Box>
      </Box>
      <Table
        displaySettings={{
          showCounter: false,
          columnsSettings: getColumnsSettings(deleteAttribute),
        }}
        loading={isLoading}
        data={attributes}
      />
    </Flex>
  )
}
