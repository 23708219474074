// @flow

import React from 'react'
import { Flex } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'

export const MemberLogo = ({ iconName }: { iconName: string }) => (
  <Flex align="center" justify="center">
    <GenericIcon svgId={iconName} />
  </Flex>
)
