import React, { useCallback } from 'react'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Button, Modal } from '@r1/ui-kit'

type Props = {
  submitting: boolean
  hasUnsavedChanges: boolean
  cancel: () => void
  updateRole: () => void
}

export const ActionButtonsEditMode: React.FC<Props> = ({
  submitting,
  hasUnsavedChanges,
  cancel,
  updateRole,
}) => {
  const modalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Discard',
          onClick: () => {
            cancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [cancel],
  )

  return (
    <>
      <Modal
        isControlled={false}
        title="You have unsaved data"
        actionButtons={modalActionButtons}
        buttonElement={({ onOpen }) => (
          <Button
            color="secondary"
            disabled={submitting}
            onClick={() => {
              hasUnsavedChanges ? onOpen() : cancel()
            }}
          >
            Cancel
          </Button>
        )}
      >
        Are you sure you want to discard unsaved data?
      </Modal>

      <Button color="primary" loading={submitting} disabled={submitting} onClick={updateRole}>
        Save
      </Button>
    </>
  )
}
