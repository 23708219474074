import * as React from 'react'

import { MasterProgramRule, SubStampValue } from '@r1-webui/masterprograms-v1'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import {
  Box,
  Button,
  Flex,
  FormField,
  Icon,
  Select,
  SortableContainer,
  SortableElement,
  SortableHandle,
  Text,
} from '@r1/ui-kit'
import styled from '@emotion/styled'
import { SubStampFmsiFilters } from '../Filters'
import { Value } from '../../Value'
import { FilterBorderContainer } from './FilterBorderContainer'
import { SubStampsAdditional } from './SubStampsAdditional'

const Sortable = styled.div`
  cursor: grab;
`

type Props = {
  editable: boolean
  subStampIndex: number
  subStampValues: SubStampValue[]
}

export const SubStampValues = ({ editable, subStampIndex, subStampValues }: Props) => {
  const { control } = useFormContext<MasterProgramRule>()

  const { fields, replace, swap, append, remove } = useFieldArray({
    name: `subStamps.${subStampIndex}.values`,
    keyName: 'key',
    control,
  })

  const {
    formState: { errors },
  } = useController({
    name: 'subStamps',
    control,
  })

  const onAddValue = React.useCallback(() => {
    const emptySubStamp = {
      id: '',
      filterGroups: [],
    }
    append(emptySubStamp)
  }, [append])

  const onRemoveValue = React.useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  const onUpdateSubStampValueId = React.useCallback(
    (index: number, id: string) => {
      const newValue = fields.map((x, arrayIndex) => {
        if (index === arrayIndex) {
          return {
            ...x,
            id,
            filterGroups: [],
          }
        }
        return x
      })
      replace(newValue)
    },
    [fields, replace],
  )

  const onSortEnd = React.useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      swap(oldIndex, newIndex)
    },
    [swap],
  )

  return (
    <Flex column>
      <SortableContainer isSortableHandleUsed items={fields} onSortEnd={onSortEnd}>
        <Flex column basis={1}>
          {fields.map((field, index) => {
            const error = errors.subStamps && errors.subStamps[index]?.id?.message
            return (
              <SortableElement key={field.key} id={field.id} disabled={!editable}>
                <Flex basis={1} mt="L">
                  <FilterBorderContainer pr="S" mr="M">
                    <Text type="paragraph">{index + 1}</Text>
                    {editable && (
                      <SortableHandle>
                        <Sortable>
                          <Icon type="drag" />
                        </Sortable>
                      </SortableHandle>
                    )}
                  </FilterBorderContainer>
                  <Flex column basis={1}>
                    {editable ? (
                      <FormField>
                        <FormField.Label>Value</FormField.Label>
                        <Select
                          data-test-id={`sub-stamp-${subStampIndex}-value-${index}__select`}
                          options={subStampValues}
                          error={!!error}
                          value={field.id}
                          onChange={value => onUpdateSubStampValueId(index, value as string)}
                        />
                        <FormField.Error>{error}</FormField.Error>
                      </FormField>
                    ) : (
                      <FormField>
                        <FormField.Label>Value</FormField.Label>
                        <Value data-test-id={`sub-stamp-${index}-value-${subStampIndex}`}>
                          {subStampValues.find(x => x.id === field.id)?.name}
                        </Value>
                        <FormField.Error>{error}</FormField.Error>
                      </FormField>
                    )}
                    <SubStampFmsiFilters
                      editable={editable}
                      subStampIndex={subStampIndex}
                      subStampValueIndex={index}
                    />
                    <SubStampsAdditional
                      fieldIdx={index}
                      editable={editable}
                      subStampIndex={subStampIndex}
                      subStampValues={subStampValues}
                    />
                  </Flex>
                </Flex>
                {editable && (
                  <Flex justify="flex-end">
                    <Box mt="XS" minWidth={180} maxWidth={180}>
                      <Button
                        block
                        data-test-id={`sub-stamp-${subStampIndex}-value-${index}__remove`}
                        color="danger"
                        onClick={onRemoveValue(index)}
                      >
                        <Icon type="minus" /> Remove Value
                      </Button>
                    </Box>
                  </Flex>
                )}
              </SortableElement>
            )
          })}
        </Flex>
      </SortableContainer>
      {editable && fields.length < subStampValues.length && (
        <Box ml="XL" mt="XS" minWidth={180} maxWidth={180}>
          <Button block data-test-id={`sub-stamp-${subStampIndex}-value__add`} onClick={onAddValue}>
            <Icon type="plus" /> Add Value
          </Button>
        </Box>
      )}
    </Flex>
  )
}
