import styled from '@emotion/styled'
import { Box, Button, Flex, FormField, H1, Icon, Input, Modal } from '@r1/ui-kit'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import React, { useCallback, useMemo, useState } from 'react'

const Title = styled(H1)`
  margin-bottom: 0 0 15px 0;
`

export type SubStampModalProps = {
  show: boolean
  modalTitle: string
  subStampName?: string
  onSave: (name: string) => Promise<boolean>
  onCancel: () => void
}

export const SubStampModal = (props: SubStampModalProps) => {
  const [subStampName, setSubStampName] = useState<string>(props.subStampName ?? '')
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const onCancel = useCallback(() => {
    props.onCancel()
    setSubStampName('')
  }, [props])

  const onSave = useCallback(async () => {
    setIsSaving(true)
    const success = await props.onSave(subStampName)
    if (success) {
      onCancel()
    }
    setIsSaving(false)
  }, [onCancel, props, subStampName])

  const modalActionButtons: ControlledActionButtons = useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        align: 'right',
        onClick: onCancel,
      },
      {
        title: 'Save',
        transparent: false,
        color: 'primary',
        onClick: onSave,
        align: 'right',
        disabled: !subStampName || isSaving,
        loading: isSaving,
      },
    ],
    [onCancel, onSave, subStampName, isSaving],
  )

  const onEscapeKeyDown = () => {
    props.onCancel()
  }

  const onNameChange = (value: string) => {
    setSubStampName(value.trim())
  }

  return (
    <Flex>
      <Box>
        <Modal
          isControlled
          show={props.show}
          actionButtons={modalActionButtons}
          onEscapeKeyDown={onEscapeKeyDown}
        >
          <Flex column>
            <Box>
              <Title>{props.modalTitle}</Title>
            </Box>
            <FormField>
              <FormField.Label>Substamp name</FormField.Label>
              <Input
                value={subStampName}
                data-test-id="sub-stamp__name__input"
                onChange={onNameChange}
              />
            </FormField>
          </Flex>
        </Modal>
      </Box>
    </Flex>
  )
}

export type EditSubSampModalProps = {
  subStampName: string
  onUpdate: (name: string) => Promise<boolean>
}

export const EditSubStampModal = (props: EditSubSampModalProps) => {
  const [show, setShow] = useState<boolean>(false)

  const onCancel = () => {
    setShow(false)
  }

  return (
    <React.Fragment>
      <Box>
        <Button
          shape="square"
          data-test-id="sub-stamp__name__input"
          color="secondary"
          onClick={() => setShow(!show)}
        >
          <Icon type="pencil" />
        </Button>
      </Box>
      <SubStampModal
        show={show}
        modalTitle="Edit substamp"
        subStampName={props.subStampName}
        onSave={props.onUpdate}
        onCancel={onCancel}
      />
    </React.Fragment>
  )
}

export type CreateSubSampModalProps = {
  onAdd: (name: string) => Promise<boolean>
}

export const CreateSubStampModal = (props: CreateSubSampModalProps) => {
  const [show, setShow] = useState<boolean>(false)

  const onCancel = () => {
    setShow(false)
  }
  return (
    <React.Fragment>
      <Box mt="L" alignSelf="flex-end">
        <Button data-test-id="sub-stamp__add" color="light" onClick={() => setShow(!show)}>
          <Icon type="plus" /> Add Substamp
        </Button>
      </Box>
      <SubStampModal
        show={show}
        modalTitle="Add substamp"
        onSave={props.onAdd}
        onCancel={onCancel}
      />
    </React.Fragment>
  )
}
