// @flow

import React, { memo } from 'react'
import { Icon, Image as Img, Button, Box, Flex } from '@r1/ui-kit'

import { Absolute, Error, Relative, MainImage, RemoveButtonContainer, Container } from './styles'

type Props = {
  error: string | null,
  disabled: boolean,
  src: string,
  isListing: boolean,
  isMain: boolean,
  onToggleListing: () => void,
  onRemove: () => void,
  onTogglePrimary: () => void,
}

const RemoveButton = ({ onClick }) => {
  return (
    <RemoveButtonContainer>
      <Icon type="clear" color="white" onClick={onClick} />
    </RemoveButtonContainer>
  )
}

export const Image = memo<Props>(
  ({
    src,
    onRemove,
    onToggleListing,
    isListing,
    onTogglePrimary,
    isMain,
    disabled,
    error,
  }: Props) => {
    return (
      <Container className={`${isMain ? 'main' : ''} ${error ? 'error' : ''}`}>
        <Relative>
          {!disabled && (
            <Absolute right="-10px" top="-10px">
              <RemoveButton onClick={onRemove} />
            </Absolute>
          )}

          <Img src={src} width={101} height={101} />

          <Flex justify="center" pt="XL">
            {isListing ? 'Listing' : 'Reference'}
          </Flex>
        </Relative>
        {!disabled && (
          <Box pt="XS" pb="S">
            {isListing ? (
              <Button size="S" color="secondary" disabled={isMain} onClick={onToggleListing}>
                Remove from listing
              </Button>
            ) : (
              <Button size="S" onClick={onToggleListing}>
                Set as listing
              </Button>
            )}
          </Box>
        )}

        {isMain ? (
          <MainImage>Main Image</MainImage>
        ) : (
          !disabled && (
            <Button transparent size="S" onClick={onTogglePrimary}>
              Set as main image
            </Button>
          )
        )}
        {error && (
          <Absolute left="0" bottom="-1px">
            <Error title={error}>{error}</Error>
          </Absolute>
        )}
      </Container>
    )
  },
)
