// @flow

import type {
  AttributeBinding,
  BindingValue,
  ChildBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'
import type { AttributeValue } from '@r1-webui/productcatalog-categorymanagement-v1/src/types'

import type {
  ChildBindingValue,
  AttributeValueConstraint,
  ShapeAttributeValue,
  FormValues,
} from '../types'

import { isRangeValue } from './isRangeValue'

/**
 *
 * inside form child bindings store as pairs
 *
 * (attribute value to link, linked attribute id)
 *
 * linked attribute id is non-unique in each pair
 *
 * *******
 *
 * api need another structure where
 *
 * (linked attribute id, array of attribute values that we need to link)
 *
 * linked attribute id is unique in each pair
 *
 */

function groupChildBindingsByAttributeId(formChildBindings: ChildBindingValue[]): ChildBinding[] {
  const childBindingsMap = formChildBindings.reduce((map, { values, attributeDefinitionId }) => {
    const res = { ...map }
    res[attributeDefinitionId] = map[attributeDefinitionId] || []
    res[attributeDefinitionId].push(values)
    return res
  }, ({}: { [string]: BindingValue[] }))

  return Object.entries(childBindingsMap).map(([attributeDefinitionId, values]) => ({
    attributeDefinitionId,
    values: (values: any),
  }))
}

function getDefaultValueFromFormField(rawDefaultValue: ShapeAttributeValue): AttributeValue | null {
  if (!rawDefaultValue.valueType) {
    return null
  }

  if (isRangeValue(rawDefaultValue)) {
    if (rawDefaultValue.to == null && rawDefaultValue.from == null) {
      return null
    }

    return rawDefaultValue
  }

  if (
    typeof rawDefaultValue.value === 'undefined' ||
    rawDefaultValue.value === '' ||
    rawDefaultValue.value === null ||
    rawDefaultValue.value === undefined ||
    rawDefaultValue.value === 'null'
  ) {
    return null
  }

  if (rawDefaultValue.valueType === 'BoolValue') {
    // eslint-disable-next-line no-param-reassign
    rawDefaultValue.value = rawDefaultValue.value === 'true'
  }

  return rawDefaultValue
}

const constraintIsEmpty = (valueConstraint: AttributeValueConstraint): boolean => {
  switch (valueConstraint.valueType) {
    case 'NumberRangeConstraint':
      return !valueConstraint.minAllowedValue && !valueConstraint.maxAllowedValue
    case 'NumberConstraint':
      return (
        !valueConstraint.minAllowedValue &&
        !valueConstraint.maxAllowedValue &&
        (!valueConstraint.forbiddenValues || valueConstraint.forbiddenValues.length === 0)
      )
    case 'StringConstraint':
      return (
        !valueConstraint.minAllowedLength &&
        !valueConstraint.maxAllowedLength &&
        (!valueConstraint.forbiddenSubstrings ||
          valueConstraint.forbiddenSubstrings.length === 0) &&
        (!valueConstraint.forbiddenWords || valueConstraint.forbiddenWords.length === 0)
      )
    case 'DateRangeConstraint':
    case 'DateConstraint':
      return !valueConstraint.minAllowedDate && !valueConstraint.maxAllowedDate
    case 'EnumerationConstraint':
      return !valueConstraint.allowedVariants || valueConstraint.allowedVariants.length === 0
    default:
      return true
  }
}

export function mapFormValuesToAttributeBinding({
  formValues,
  initialBinding: { defaultValue: _, valueConstraint: oldConstraint, ...initialBinding },
}: {
  formValues: FormValues,
  initialBinding: AttributeBinding,
}): AttributeBinding {
  const {
    hasConstraint,
    childBindings,
    displayName,
    defaultValue: rawDefaultValue,
    valueConstraint,
    ...bindingFields
  } = formValues
  const defaultValue = getDefaultValueFromFormField(rawDefaultValue)

  return {
    ...initialBinding,
    ...bindingFields,
    displayName: displayName || undefined,

    // $FlowFixMe[exponential-spread]
    ...(defaultValue ? { defaultValue } : {}),
    ...(hasConstraint && valueConstraint && !constraintIsEmpty(valueConstraint)
      ? { valueConstraint }
      : {}),
    childBindings: groupChildBindingsByAttributeId(childBindings),
  }
}

export const mapAttributeBindingToFlat = (childBindings: ChildBinding[]): ChildBindingValue[] =>
  childBindings.reduce(
    (acc, { attributeDefinitionId, values }) => [
      ...acc,
      ...values.reduce((a, v) => [...a, { attributeDefinitionId, values: v }], []),
    ],
    [],
  )
