import { DayOfTheWeek } from '@r1-webui/facilities-v1'

export const DAYS_OF_WEEK: DayOfTheWeek[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const getDayOfWeek = () => DAYS_OF_WEEK.map(day => ({ id: day, name: day }))
