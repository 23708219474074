import { Flex, Text } from '@r1/ui-kit'
import React from 'react'

import { ProductHistoryItem } from '@r1-webui/productmanagement-v1'
import { HistoryActionFilter } from './HistoryActionFilter'

type Predicate = (history: ProductHistoryItem) => boolean

type Props = {
  onFilterChange: (filterId: string, predicate: Predicate | null) => void
}

export const FilterSection = (props: Props) => {
  const handleHistoryAction = (actionIds: string[]) => {
    const filterId = 'actionFilter'
    if (actionIds.length > 0) {
      const predicate = (history: ProductHistoryItem) => {
        return actionIds.includes(history.action)
      }
      props.onFilterChange(filterId, predicate)
    } else {
      props.onFilterChange(filterId, null)
    }
  }
  return (
    <Flex mb="L" spaceBetween="XS" align="center">
      <Text>Filters: </Text>
      <HistoryActionFilter onChange={handleHistoryAction} />
    </Flex>
  )
}
