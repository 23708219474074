import { ValueType, BlockType } from '../constants'
import { getNextBlockId, getNextValueId } from '../services/blocksFactory'

function convertValueFromApi(values) {
  return values.map(v => {
    const convertedValue = {
      id: getNextValueId(),
      ...v,
    }

    if (v.type === ValueType.PROPERTY && !v.required) {
      convertedValue.required = false
    }

    if (v.trueValues) {
      convertedValue.trueValues = convertValueFromApi(v.trueValues)
    }

    if (v.falseValues) {
      convertedValue.falseValues = convertValueFromApi(v.falseValues)
    }

    return convertedValue
  })
}

function convertBlockFromApi(block, images) {
  const convertedBlock = {
    id: getNextBlockId(),
    ...block,
    rotation: block.rotation || 0,
  }

  if (block.type === BlockType.REPEATER_BLOCK) {
    return { ...convertedBlock, itemTemplate: convertBlockFromApi(block.itemTemplate, images) }
  }

  if (block.type === BlockType.IMAGE_BLOCK) {
    const image = images.find(({ id }) => id === block.imageId)

    convertedBlock.src =
      image !== undefined ? `data:image/${image.extension};base64,${image.base64}` : ''
  }

  if (block.values) {
    convertedBlock.values = convertValueFromApi(block.values)
  }

  return convertedBlock
}

export const convertDocumentTemplateFromApi = (
  documentTemplate,
  templateModels,
  pageSettings,
  images,
) => ({
  ...documentTemplate,
  blocks: documentTemplate.blocks.map(block => convertBlockFromApi(block, images)),
  templateModel: templateModels.find(template => template.id === documentTemplate.templateModelId),
  pageSetting: pageSettings.find(setting => setting.id === documentTemplate.pageSettingId),
})
