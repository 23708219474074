// @flow

import * as React from 'react'

import { handleServerError } from '@r1/core-blocks'

import { createApi } from '../../api/api'
// import { createApi } from '../../api/mock'
import { convertFileToBase64, removeBase64ImageType } from '../../utils'
import type { ViewProps, WithControllerState, WithControllerProps } from './types'
import { defaultState } from './defaultState'

export function withController(
  View: React.ComponentType<ViewProps>,
): React.ComponentType<WithControllerProps> {
  class ViewWithController extends React.Component<WithControllerProps, WithControllerState> {
    state = defaultState

    // eslint-disable-next-line react/no-unused-class-component-methods
    api = createApi(this.props.httpClient)

    componentDidUpdate(prevProps) {
      if (
        this.props.orderLine &&
        (!prevProps.orderLine || this.props.orderLine.lineId !== prevProps.orderLine.lineId)
      ) {
        if (!this.props.orderLine.notes) {
          this.setState({
            lineId: this.props.orderLine.lineId,
            message: '',
            images: [],
            isMessageLengthError: false,
          })
        } else {
          this.setState({
            lineId: this.props.orderLine.lineId,
            message: this.props.orderLine.notes.message || '',
            images: this.props.orderLine.notes.images || [],
            isMessageLengthError: false,
          })
        }
      }
    }

    inputHandler = value => {
      this.setState({ message: value, isMessageLengthError: false })
    }

    uploaderHandler = uploaderPayload => {
      this.setState({ isUploading: true })
      this.saveImage(uploaderPayload.file)
    }

    saveImage = async file => {
      const readerResponse = await convertFileToBase64(file)
      if (readerResponse.status !== 200) {
        handleServerError(new Error(readerResponse))
        return
      }

      const base64Image = readerResponse.body
      const imageData = removeBase64ImageType(base64Image)
      const fileType = file.type

      this.setState(({ images }) => {
        images.push({ imageData, base64Image, fileType })
        return { images, isUploading: false }
      })
    }

    deleteImageHandler = index => {
      this.setState(({ images }) => {
        images.splice(index, 1)
        return { images }
      })
    }

    closeHandler = () => this.props.onOrderLineNotesModalShow(null)

    noteValidate = () => {
      const { message } = this.state

      const isMessageLengthError = typeof message === 'string' && message.length > 300

      this.setState({ isMessageLengthError })
      return !isMessageLengthError
    }

    saveHandler = () => {
      if (!this.noteValidate()) return

      const { lineId, message, images } = this.state

      if (typeof message === 'string' || images.length > 0) {
        this.props.onOrderLineNotesChange(lineId, {
          message,
          images,
        })
      }
      this.closeHandler()
    }

    render() {
      const { message, images, isMessageLengthError, isUploading } = this.state
      const { inputHandler, uploaderHandler, deleteImageHandler, closeHandler, saveHandler } = this
      const { orderLine, isVisible } = this.props
      const title = (orderLine && orderLine.productInfo.title) || ''

      return (
        <View
          show={isVisible}
          title={title}
          message={message}
          images={images}
          isUploading={isUploading}
          isMessageLengthError={isMessageLengthError}
          inputHandler={inputHandler}
          uploaderHandler={uploaderHandler}
          deleteImageHandler={deleteImageHandler}
          saveHandler={saveHandler}
          closeHandler={closeHandler}
        />
      )
    }
  }

  return ViewWithController
}
