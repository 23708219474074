import { createProgramsApi } from '@r1-webui/usermanagement-v1'
import { httpClient } from '../../../../httpClient'
import { API_PREFIX } from '../userManagement'

const apiOptions = {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
}

export const programsApi = createProgramsApi(apiOptions)
