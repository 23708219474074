// @flow

import type {
  AttributeBinding,
  ChildBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'

type Options = {
  attributeDefinitionId: string,
  bindings: AttributeBinding[],
}

/**
 *
 * ONLY FOR INHERITED BINDINGS
 *
 * child bindings in inherited category doesn't contain child bindings from child category
 *
 */

export function getChildBindingsFromChildCategory({
  attributeDefinitionId,
  bindings,
}: Options): ChildBinding[] {
  const childBindings = []
  bindings.forEach(binding => {
    if (
      binding.parentBinding &&
      binding.parentBinding.attributeDefinitionId === attributeDefinitionId
    ) {
      childBindings.push({
        attributeDefinitionId: binding.attributeDefinition.id,
        values: binding.parentBinding.values,
      })
    }
  })

  return childBindings
}
