// @flow

import React, { memo, useCallback, useContext, useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  H4,
  H3,
  FormField,
  StructuredList,
  Tag,
  Card,
  Flex,
  Box,
  Placeholder,
  HR,
} from '@r1/ui-kit'
import { ServiceContext } from '../../provider'
import { toObject } from '../../../../utils/toObject'
import { OutliersMethod } from './children/OutliersMethod'

const Title = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[900]};
`

type PricingRuleViewProps = {|
  pricingRuleId: string,
  children: (props: { name: string }) => React$Node,
|}

const columnsSettings = [
  { header: 'Data Source', cell: { $type: 'text', field: 'dataSource' } },
  { header: 'Data Provider', cell: { $type: 'text', field: 'dataProviderName' } },
  { header: 'Date Period', cell: { $type: 'text', field: 'datePeriod' } },
  {
    header: 'Aggregation Method',
    cell: { $type: 'text', field: 'priceAggregationName' },
  },
  { header: 'Price Type', cell: { $type: 'text', field: 'priceTypeName' } },
]

const SourceGroupList = memo(({ group, index, dataProviders }) => {
  const listData = useMemo(
    () =>
      group.sources.map(
        ({ dataProvider, datePeriod, datePeriodType, priceAggregationMethod, priceType }) => ({
          dataSource: dataProviders[dataProvider.id]
            ? dataProviders[dataProvider.id].dataSource.title
            : dataProvider.name,
          dataProviderName: dataProvider.name,
          datePeriod: `${datePeriod} ${datePeriodType.name}`,
          priceAggregationName: priceAggregationMethod.name,
          priceTypeName: priceType.name,
        }),
      ),
    [group, dataProviders],
  )

  return (
    <Card key={index} background="dark">
      <Flex column my="M" mx="M" spaceBetween="M">
        <H4>Group #{index + 1}</H4>
        <Box>
          <Flex spaceBetween="XXS">
            <FormField.Label>Aggregation Method</FormField.Label>
            {group.sourcesAggregationMethod.name}
          </Flex>
        </Box>
        <StructuredList
          key={index}
          background="white"
          data={listData}
          columnsSettings={columnsSettings}
        />
      </Flex>
    </Card>
  )
})

export const PricingRuleView = memo<PricingRuleViewProps>((props: PricingRuleViewProps) => {
  const { pricingRuleId, children } = props
  const {
    priceService: { getPricingRuleDetail, getAllDataProviders },
  } = useContext(ServiceContext)

  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState()
  const [dataProviders, setDataProviders] = useState({})

  const fetchData = useCallback(async () => {
    try {
      setInfo(await getPricingRuleDetail(pricingRuleId))
      setDataProviders(toObject(await getAllDataProviders(), 'id'))
    } catch (error) {
      setInfo()
      setDataProviders({})
    }
  }, [getPricingRuleDetail, getAllDataProviders, pricingRuleId])

  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [fetchData])

  if (loading) {
    return <Placeholder type="form" />
  }

  // check for incorrect data type
  if (!info || Array.isArray(info)) {
    return null
  }

  return (
    <React.Fragment>
      <Title>Main Settings</Title>
      <StructuredList
        data={[
          ['Pricing Rule Name', info.name],
          ['Currency', info.currency],
          ['Description', info.description],
          ['Outliers Methods', info.outlierMethods],
        ]}
        columnsSettings={[
          { header: null, cell: { $type: 'text', field: 0 } },
          {
            header: null,
            cell: {
              $type: 'custom',
              renderer: rowData => {
                const value = rowData[1]

                if (Array.isArray(value)) {
                  return value.map(({ id, name }) => <Tag key={id} text={name} />)
                }

                return value
              },
            },
          },
        ]}
      />

      {info.outlierMethods.map(({ id, ...methodInfo }) => (
        <OutliersMethod key={id} {...methodInfo} />
      ))}

      <Title>Prices</Title>
      <Flex column spaceBetween="S">
        <Flex>
          <FormField.Label>Resulting Aggregation Method</FormField.Label>
          {info.sourceGroupsAggregationMethod.name}
        </Flex>
        <HR />
      </Flex>
      {info.sourceGroups.map((sourceGroup, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <SourceGroupList key={i} group={sourceGroup} index={i} dataProviders={dataProviders} />
      ))}

      {children({ name: info.name || '' })}
    </React.Fragment>
  )
})
