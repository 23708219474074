import { handleServerError } from '@r1/core-blocks'
import { showNotification } from '../../../utils/notifications/showNotification'
import { masterProgramRulesApi } from './masterProgramRulesApi'

export async function importFmsiFilterGroup(fmsiFilterId: string) {
  const response = await masterProgramRulesApi.getFilterGroupFromFmsiFilter({ fmsiFilterId })

  if (response.status !== 200) {
    handleServerError(response)
    return null
  }

  showNotification({
    level: 'success',
    message: 'Filter is found and imported',
  })

  return response.body
}
