// @flow

import styled from '@emotion/styled'

export { OrderInfoWrapper } from '../StyledComponents'

export { InfoBlockTitle } from '../StyledComponents'

export { Property } from '../StyledComponents'

export const Value = styled('span')`
  color: ${({ theme, isExpired }) => (isExpired ? theme.palette.red[500] : 'inherit')};
`
