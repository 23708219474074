// @flow

import { generateTestIds } from '../../../../../components/utils/generateTestIds'

export const testIds = generateTestIds({
  scope: ['family'],
  components: {
    nameInput: 'name-input',
    description: 'description-editor',
    identifierTagInput: 'identifier-tagInput',
    categoryTreeSelect: 'category-treeSelect',
    attributeDefinitionSelect: 'attribute-definition-select',
    attributeActionSelect: 'attribute-action-select',
    attributeValuesSelect: 'attribute-values-select',
  },
})
