import { Deposit, DepositFilter } from '@r1-webui/gowholesale-depositmanagement-v1'
import { handleServerError, useUserSettings } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'
import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import { depositManagementApi } from '../../api'
import { DEFAULT_FILTER, DEFAULT_PAGINATION } from './const'
import { DepositsGridControllerPayload, DepositsGridPagination } from './types'

export const DepositsGridController: FC<{
  children: (controllerPayload: DepositsGridControllerPayload) => ReactElement
}> = ({ children }) => {
  const [data, setData] = useState<Deposit[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)
  const [isFetchingData, setIsFetchingData] = useState<boolean>(true)
  const [isProcessingDeposits, setIsProcessingDeposits] = useState<boolean>(false)
  const [pagination, setPagination] = useState<DepositsGridPagination>(DEFAULT_PAGINATION)
  const [filter, setFilter] = useState<DepositFilter>(DEFAULT_FILTER)

  const currentUserFullName = useUserSettings().currentUser.userInfo?.fullName ?? 'unknown'

  const fetchData = useCallback(async () => {
    setIsFetchingData(true)

    const response = await depositManagementApi.searchDeposits({
      page: pagination.page - 1,
      pageSize: pagination.pageSize,
      filter,
    })

    if (response.status === 200) {
      setData(response.body.deposits)
      setTotalCount(response.body.totalCount)
    } else {
      handleServerError(response)
      setData([])
      setTotalCount(0)
    }

    setIsFetchingData(false)
  }, [filter, pagination])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onCloseDeposit = useCallback(
    async (deposit: Deposit) => {
      setIsProcessingDeposits(true)

      try {
        const response = await depositManagementApi.closeDeposit(
          {
            depositId: deposit.entityId,
            depositType: deposit.type,
          },
          {
            closedBy: currentUserFullName,
          },
        )
        if (response.status !== 200) {
          handleServerError(response)
        }

        await fetchData()

        if (response.status === 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Success',
            message: 'Deposit has successfully closed',
          })
        }
      } finally {
        setIsProcessingDeposits(false)
      }
    },
    [fetchData, currentUserFullName],
  )

  const onChangeFilter = (filterChange: Partial<DepositFilter>) => {
    setPagination(DEFAULT_PAGINATION)
    setFilter(currentFilter => ({ ...currentFilter, ...filterChange }))
  }

  return children({
    data,
    totalCount,
    isFetchingData,
    isProcessingDeposits,
    pagination,
    filter,
    onCloseDeposit,
    onChangePagination: setPagination,
    onChangeFilter,
  })
}
