// @flow

import React, { useCallback, useState } from 'react'
import { FormField, Select } from '@r1/ui-kit'
import type { ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'

type Props = {
  options: ReturnReason[],
  defaultValue: string,
  onReturnReasonChange: (val: string) => void,
}

export const ReturnReasonEditor = (props: Props) => {
  const { options, defaultValue, onReturnReasonChange } = props
  const [value, setValue] = useState(defaultValue)

  const onChange = useCallback(
    (val: string) => {
      setValue(val)
      onReturnReasonChange(val)
    },
    [onReturnReasonChange],
  )

  return (
    <FormField>
      <FormField.Label>Return Reason:</FormField.Label>
      <Select
        data-test-id="return-reason"
        options={options}
        value={value}
        valueKey="id"
        labelKey="title"
        placeholder="Select Reason"
        clearable={false}
        onChange={onChange}
      />
    </FormField>
  )
}
