// @flow

import type {
  DimensionMeasure,
  WeightMeasure,
  IsocountryCode,
  Notice,
} from '@r1-webui/productcatalog-products-v1/src/types'
import type { WebUiApi as ReferenceApi } from '../api/reference/types'
import { replaceTemplateValues } from '../../../utils'

/**
 * map body with array of enum ( where structure = {code:string, name: notice} )
 */

const mapEnumValuesFromResponse = <T>(
  body: Array<{| code: T, name: Notice |}>,
): Array<{| id: T, name: string |}> =>
  body.map(({ code, name }) => ({ id: code, name: replaceTemplateValues(name) }))

const mapEnumReasonValuesFromResponse = (
  body: Array<{| id: string, title: Notice |}>,
): Array<{| id: string, title: string |}> =>
  body.map(({ id, title }) => ({ id, title: replaceTemplateValues(title) }))

export const referenceService = (api: ReferenceApi) => {
  const searchManufacturers = async (queryParameters: {|
    searchTerm?: string,
    limit: number,
    offset: number,
  |}) =>
    api
      .searchManufacturers(queryParameters)
      .then(response => (response.status === 200 ? response.body.manufacturers : []))

  const searchModels = async (queryParameters: {|
    manufacturerId: string,
    searchTerm?: string,
    limit: number,
    offset: number,
  |}) =>
    api
      .searchModels(queryParameters)
      .then(response => (response.status === 200 ? response.body.models : []))

  const getCountries = async () =>
    api
      .getAllCountries()
      .then(response => (response.status === 200 ? response.body : []))
      .then(body => mapEnumValuesFromResponse<IsocountryCode>(body))

  const getProductIdentifierTypes = async () => api.getProductIdentifierTypes().catch(() => [])

  const getAllDimensionMeasures = async () =>
    api
      .getAllDimensionMeasures()
      .then(response => (response.status === 200 ? response.body : []))
      .then(body => mapEnumValuesFromResponse<DimensionMeasure>(body))

  const getAllWeightMeasures = async () =>
    api
      .getAllWeightMeasures()
      .then(response => (response.status === 200 ? response.body : []))
      .then(body => mapEnumValuesFromResponse<WeightMeasure>(body))

  const getLockReasons = async () =>
    api
      .getLockReasons()
      .then(response => (response.status === 200 ? response.body : []))
      .then(body => mapEnumReasonValuesFromResponse(body))

  const getAllReviewReasons = async (): Promise<{ id: string, name: string }[]> =>
    api
      .getReviewReasons()
      .then(response => (response.status === 200 ? response.body : []))
      .then(body => body.map(({ id, title }) => ({ id, name: replaceTemplateValues(title) })))

  const getUsers = async (userPrincipalIds: string[]) =>
    api
      .getUsers({ userPrincipalIds })
      .then(response => (response.status === 200 ? response.body : []))

  return {
    searchManufacturers,
    searchModels,
    getCountries,
    getProductIdentifierTypes,
    getAllDimensionMeasures,
    getAllWeightMeasures,
    getLockReasons,
    getAllReviewReasons,
    getUsers,
  }
}
