// @flow

import { browserHistory } from '../routes/browserHistory'

type Query = {| key: string, value: string |}

const getValueByKey = (key: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(key)
}

const setQueries = (queries: Query[]): void => {
  const { pathname, search } = window.location

  const searchParams = new URLSearchParams(search)

  queries.forEach(query => searchParams.set(query.key, query.value))

  browserHistory.push({
    pathname,
    search: `?${searchParams.toString()}`,
  })
}

const deleteValueByKey = (key: string): void => {
  const { pathname, search } = window.location

  const searchParams = new URLSearchParams(search)
  searchParams.delete(key)

  browserHistory.push({
    pathname,
    search: `?${searchParams.toString()}`,
  })
}

export const queryParametersUtils = { getValueByKey, setQueries, deleteValueByKey }
