// @flow

import React, { useState } from 'react'
import { Flex, Button, Drawer, Format, HR } from '@r1/ui-kit'

import type { ProductItem, ClaimInfo } from '../../types/common'
import { Return } from './Return'
import {
  Refund,
  Title,
  Table,
  TableHeader,
  ProductPrice,
  ProductInfoTitle,
  ProductInfoDescription,
  ProductInfoDescriptionQty,
  Footer,
  TotalAmountLabel,
  TotalAmountValue,
  ActionButtons,
} from './StyledComponents'

type Props = {|
  api: any,
  rmaProfileId: null | string,
  products: ProductItem[],
  claim: ClaimInfo,
  isVisible: boolean,
  isSubmitting: boolean,
  withReturn: boolean,
  onClose: () => void,
  onSubmit: (value?: null | string) => Promise<void>,
  onWithReturnChange: () => void,
|}

export const RefundSidebar = ({
  api,
  rmaProfileId,
  claim,
  products,
  isVisible,
  isSubmitting,
  withReturn,
  onClose,
  onSubmit,
  onWithReturnChange,
}: Props) => {
  const [warehouse, onWarehouseChange] = useState(null)

  return (
    <Drawer.Form
      closeButton
      backdrop="closing"
      size={794}
      placement="right"
      show={isVisible}
      onClose={onClose}
    >
      <Refund column>
        <Title>Approve Refund</Title>

        <TableHeader justify="space-between">
          <span>Product Details</span>
          <span>Price</span>
        </TableHeader>

        <Table column>
          {products.map(({ id, title, model, price, quantity, returnReason }) => (
            <Flex key={id} justify="space-between" mb="XXXL" spaceBetween="S">
              <Flex column spaceBetween="XXS">
                <ProductInfoTitle>
                  {title} {model && `(${model})`}
                </ProductInfoTitle>

                <ProductInfoDescription spaceBetween="M">
                  <ProductInfoDescriptionQty>Qty: {quantity}</ProductInfoDescriptionQty>

                  <Flex>Return Reason: {returnReason}</Flex>
                </ProductInfoDescription>
              </Flex>

              <ProductPrice>
                <Format.Money value={price} />
              </ProductPrice>
            </Flex>
          ))}
        </Table>
      </Refund>

      <HR />

      <Flex column mr="XL" mb="L" mt="L" align="flex-end">
        <TotalAmountLabel>Total Amount:</TotalAmountLabel>

        <TotalAmountValue>
          <Format.Money value={claim.totalAmount} />
        </TotalAmountValue>
      </Flex>

      <Return
        api={api}
        rmaProfileId={rmaProfileId}
        withReturn={withReturn}
        warehouse={warehouse}
        onWithReturnChange={onWithReturnChange}
        onWarehouseChange={onWarehouseChange}
      />

      <Footer column align="flex-end">
        <ActionButtons spaceBetween="XXS" justify="flex-end">
          <Button transparent onClick={onClose}>
            Cancel
          </Button>
          <Button
            // Button disabled as result of task https://jira.trgdev.com/browse/R1-8651
            // Force/Manual refunds are not allowed for now for New Rma
            // This button was disabled as additional precaution measure and might be re-enabled later
            disabled
            loading={isSubmitting}
            onClick={() => {
              if (withReturn) {
                onSubmit(warehouse)
              }
            }}
          >
            Approve
          </Button>
        </ActionButtons>
      </Footer>
    </Drawer.Form>
  )
}
