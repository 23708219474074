import { Deposit, SortedColumn } from '@r1-webui/gowholesale-depositmanagement-v1'
import { useAccessControl } from '@r1/core-blocks'
import { DisplaySettings, Pagination, Table } from '@r1/grid'
import { Sorting } from '@r1/grid/contracts/ts/DisplaySettings'
import { Flex } from '@r1/ui-kit'
import React, { FC, useCallback, useMemo, useState } from 'react'
import { DialogModal } from '../../../../components/DialogModal/DialogModal'
import { DepositHistoryModal } from './components/DepositHistoryModal'
import { DepositsGridFilter } from './components/DepositsGridFilter'
import { PAGE_SIZES, sortingTypeUiFilterMap } from './const'
import { DepositAction, DepositDialogCase, DepositsGridControllerPayload } from './types'
import { getCloseDialogMessage, getGridDisplaySettings } from './utils'

export const DepositsGridView: FC<DepositsGridControllerPayload> = ({
  data,
  totalCount,
  isFetchingData,
  pagination,
  filter,
  onChangePagination,
  onCloseDeposit,
  onChangeFilter,
}) => {
  const [isDepositHistoryModalOpen, setIsDepositHistoryModalOpen] = useState<boolean>(false)

  const [depositForClose, setDepositForClose] = useState<Deposit | null>(null)
  const [depositForHistory, setDepositForHistory] = useState<Deposit | null>(null)

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogMessage, setDialogMessage] = useState<JSX.Element | null>(null)
  const [dialogCase, setDialogCase] = useState<DepositDialogCase | null>(null)

  const [{ allowGwsDepositManagementClose: allowToClose }] = useAccessControl()

  const requestCloseDeposit = useCallback((deposit: Deposit) => {
    const message = getCloseDialogMessage(deposit)
    setDepositForClose(deposit)
    setDialogMessage(message)
    setDialogCase(DepositDialogCase.CloseDeposit)
    setIsDialogOpen(true)
  }, [])

  const onDepositActionClick = useCallback(
    (deposit: Deposit, action: DepositAction) => {
      switch (action) {
        case DepositAction.Close: {
          requestCloseDeposit(deposit)
          break
        }
        case DepositAction.ShowHistory: {
          setDepositForHistory(deposit)
          setIsDepositHistoryModalOpen(true)
          break
        }
        default:
          break
      }
    },
    [requestCloseDeposit],
  )

  const onDialogCancel = () => {
    setIsDialogOpen(false)
    setDialogMessage(null)
    setDialogCase(null)
  }

  const onChangeSorting = (sorting: Sorting) => {
    if (sorting.type === 'none') {
      onChangeFilter({ ...filter, sorting: undefined })
      return
    }

    onChangeFilter({
      ...filter,
      sorting: {
        column: sorting.field as SortedColumn,
        sorting: sortingTypeUiFilterMap[sorting.type],
      },
    })
  }

  const onDialogConfirm = useMemo<(() => void) | null>(() => {
    switch (dialogCase) {
      case DepositDialogCase.CloseDeposit: {
        return () => {
          if (depositForClose) {
            onCloseDeposit(depositForClose)
          }
          setDepositForClose(null)
          onDialogCancel()
        }
      }
      default: {
        return null
      }
    }
  }, [depositForClose, dialogCase, onCloseDeposit])

  const onDepositHistoryModalClose = () => {
    setIsDepositHistoryModalOpen(false)
  }

  const displaySettings = useMemo<DisplaySettings<Deposit>>(
    () => getGridDisplaySettings(onDepositActionClick, allowToClose, filter),
    [onDepositActionClick, allowToClose, filter],
  )

  return (
    <>
      <Flex column spaceBetween="L">
        <DepositsGridFilter filter={filter} onChangeFilter={onChangeFilter} />

        <Pagination
          rowCount={totalCount}
          page={pagination.page}
          pageSize={pagination.pageSize}
          availablePageSizes={PAGE_SIZES}
          onChange={onChangePagination}
        >
          <Table
            resizable
            suppressVirtualization
            loading={isFetchingData}
            data={data}
            displaySettings={displaySettings}
            onChangeSorting={onChangeSorting}
          />
        </Pagination>
      </Flex>

      <DialogModal
        show={isDialogOpen}
        message={dialogMessage}
        onConfirm={onDialogConfirm}
        onClose={onDialogCancel}
      />

      {depositForHistory ? (
        <DepositHistoryModal
          isOpen={isDepositHistoryModalOpen}
          deposit={depositForHistory}
          onClose={onDepositHistoryModalClose}
        />
      ) : null}
    </>
  )
}
