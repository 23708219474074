// @flow

/* eslint-disable react/sort-comp */

import * as React from 'react'

import { Flex, Loader } from '@r1/ui-kit'

import { createApi } from '../../../api/api'
import type { ViewProps } from '../types'

import { defaultState } from './defaultState'

import type { WithControllerProps } from './types'
import { WithController } from './types'
import { createClaimController } from './controllers/claimController'
import { createDecisionItemsController } from './controllers/decisionItemsController'
import { createChatController } from './controllers/chatController'
import { createRefundController } from './controllers/refundController'
import { createInitializationController } from './controllers/initializationController'

export function withController(View: React.ComponentType<ViewProps>) {
  class ViewWithController extends WithController {
    state = defaultState

    api = createApi(this.props.httpClient)

    claim = createClaimController(this)

    decisionItems = createDecisionItemsController(this)

    chat = createChatController(this)

    refund = createRefundController(this)

    initialization = createInitializationController(this)

    componentDidMount() {
      this.initialization.load()
    }

    componentDidUpdate(prevProps: WithControllerProps) {
      if (this.props.claimId !== prevProps.claimId) {
        this.initialization.load()
      }
    }

    render() {
      const { onForbidden, onNotFound, onUnknownError } = this.props
      const {
        screen,
        data,
        sidebarIsVisible,
        refundIsSubmitting,
        withReturn,
        activeTabId,
        hasUnreadChatMessages,
      } = this.state

      if (screen === 'loading') {
        return (
          <Flex align="center" justify="center">
            <Loader />
          </Flex>
        )
      }
      if (screen === 'forbidden') return onForbidden()
      if (screen === 'notFound') return onNotFound()
      if (screen === 'unknownError') return onUnknownError()
      if (data === null) return onUnknownError()

      return (
        <View
          api={this.api}
          isLoading={screen === 'loading'}
          data={data}
          sidebarIsVisible={sidebarIsVisible}
          refundIsSubmitting={refundIsSubmitting}
          withReturn={withReturn}
          activeTabId={activeTabId}
          hasUnreadChatMessages={hasUnreadChatMessages}
          updateMessages={this.chat.updateMessages}
          setHasUnreadMessages={this.chat.setHasUnreadMessages}
          setExpectedDecisionTypeId={this.claim.setExpectedDecisionTypeId}
          createClaim={this.claim.createClaim}
          restartDecisionItem={this.decisionItems.restartDecisionItem}
          completeDecisionItem={this.decisionItems.completeDecisionItem}
          onForceRefund={this.refund.onForceRefund}
          onWithReturnChange={this.refund.onWithReturnChange}
        />
      )
    }
  }

  return ViewWithController
}
