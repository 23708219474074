// @flow

import React, { useCallback, useMemo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Flex, NotificationSystem, Tooltip, Icon, Modal, Button } from '@r1/ui-kit'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { variationsService } from '../../services'
import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'
import { variationsApi } from '../../api'

const ActionButton = ({ redirectToVariationsCreatePage }) => (
  <Button data-test-id="um-add-new-family-btn" onClick={redirectToVariationsCreatePage}>
    <Icon type="plus" color="inverted" />
    Add new
  </Button>
)

export const VariationsGrid = () => {
  const navigate = useNavigate()
  const grid = useRef(null)
  const { deleteVariation } = variationsService(variationsApi(httpClient))

  const initialStateQuery = useMemo(() => {
    const query = parseQuery()
    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: 100,
      },
    }
  }, [])

  const [{ allowFamilyEdit }] = useAccessControl()

  const redirectToVariationsCreatePage = useCallback(
    () => navigate(ROUTES.VARIATIONS_CREATE),
    [navigate],
  )

  const removeRow = useCallback(
    data => {
      deleteVariation(String(data.FamilyId))
        .then(() => {
          NotificationSystem.addNotification({
            level: 'success',
            title: `Variation was deleted successfully`,
          })
          if (grid.current) grid.current.refreshGrid()
        })
        .catch(error => {
          NotificationSystem.addNotification({
            level: 'error',
            title: `Variation not deleted. ${error}`,
          })
        })
    },
    [deleteVariation],
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowFamilyView]}>
      <Main>
        <ContentHeader />
        <Content>
          <ContentHeader.Title>Variations</ContentHeader.Title>

          <Flex column>
            <GridGenerator
              // $FlowFixMe[incompatible-type]
              ref={grid}
              gridId="FamilyGrid"
              gridEngineBaseUrl="/uisvc/integration-grids"
              initialStateQuery={initialStateQuery}
              httpClient={httpClient}
              aggregationCount={{ title: 'Total variations:', suppressAggregation: false }}
              toolbarActionButtons={
                allowFamilyEdit && (
                  <ActionButton redirectToVariationsCreatePage={redirectToVariationsCreatePage} />
                )
              }
              initialCustomColumnsSettings={[
                {
                  columnId: 'Actions',
                  cell: {
                    $type: 'custom',
                    renderer: data => {
                      const modalActionButtons = ({ onClose }): BottomActionButton[] => {
                        return [
                          {
                            title: 'No',
                            color: 'secondary',
                            onClick: () => onClose(),
                            align: 'right',
                          },
                          {
                            title: 'Yes',
                            onClick: () => {
                              removeRow(data)
                              onClose()
                            },
                            align: 'right',
                          },
                        ]
                      }
                      return (
                        <Flex>
                          <Modal
                            isControlled={false}
                            title="Delete"
                            actionButtons={modalActionButtons}
                            buttonElement={({ onOpen }) => (
                              <Tooltip text="Delete Variation" placement="top">
                                <Button
                                  transparent
                                  size="S"
                                  color="secondary"
                                  shape="square"
                                  onClick={onOpen}
                                >
                                  <Icon type="trash" />
                                </Button>
                              </Tooltip>
                            )}
                          >
                            Are you sure you want to delete variation?
                          </Modal>
                        </Flex>
                      )
                    },
                  },
                },
              ]}
              onChangeState={setQuery}
            />
          </Flex>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
