// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import { adjust, evolve, always } from 'ramda'
import { Flex, Text } from '@r1/ui-kit'
import { PriceBlock } from '../../PriceBlock'
import * as actions from './module'

const mapStateToProps = ({ pricing }) => ({
  priceBlock: pricing.priceBlock,
})

const enhance = compose(
  connect(mapStateToProps, actions),
  // $FlowFixMe[incompatible-use]
  withHandlers({
    onChangePrice:
      ({ onAddDirtyTab, changePriceBlockTemplate, priceBlock: { template } }) =>
      (
        index,
        value,
        // eslint-disable-next-line consistent-return
      ) => {
        const val = Number(value)

        const { currencyCode } = template[index]

        if (typeof val !== 'number' || Number.isNaN(val)) return null

        const newTemplate = adjust(index)(
          evolve({
            price: val
              ? () => ({
                  amount: value,
                  currency: currencyCode,
                })
              : always(null),
          }),
        )(template)
        changePriceBlockTemplate(newTemplate)
        onAddDirtyTab('pricing')
      },
  }),
)

type Props = {|
  priceBlock: any,
  id: string,
  loadPriceBlock: (id: string) => void,
  onChangePrice: (index: string, value: any) => void,
  disabled: boolean,
|}

class Pricing extends React.Component<Props> {
  componentDidMount() {
    const { id, loadPriceBlock } = this.props
    loadPriceBlock(id)
  }

  render() {
    const {
      priceBlock: { catalog, template },
      onChangePrice,
      disabled,
    } = this.props

    if (!catalog.length && !template.length)
      return <Text>There is no pricing for this category</Text>

    return (
      template.length && (
        <Flex column spaceBetween="L">
          <Flex column spaceBetween="XS">
            <Text type="paragraph">Custom prices</Text>

            <Flex flexWrap spaceBetween="M">
              {template.map((price, index) =>
                price.name ? (
                  <PriceBlock
                    key={price.name}
                    main
                    name={price.name}
                    price={price.price}
                    currency={price.currencyCode}
                    enabled={price.isEditable && !disabled}
                    onChange={({ target: { value } }) => onChangePrice(index, value)}
                  />
                ) : null,
              )}
            </Flex>
          </Flex>

          <Flex column spaceBetween="XS">
            <Text type="paragraph">Consolidated prices</Text>

            <Flex flexWrap spaceBetween="M">
              {catalog.map(price => (
                <PriceBlock key={price.rule.name} name={price.rule.name} price={price.value} />
              ))}
            </Flex>
          </Flex>
        </Flex>
      )
    )
  }
}

export const PricingEnhanced = enhance(Pricing)
