// @flow

/* const setImages = (values: Object) => {
  return values.map(({ url }) => ({ url }))
}

const getImages = (values: Object) => {
  return values.map(({ url }) => ({ url, uploaded: true }))
}

const setFilter = (filter: Object) => {
  switch (filter.type) {
    case 'ProductIdentifierValuesFilter':
      return filter.productIdentifierValues.length > 0 ? filter : null
    case 'CategoriesFilter':
      return filter.categoryIds.length > 0 ? filter : null
    case 'AttributeValuesFilter':
      return !filter.attributeDefinitionId
        ? null
        : filter.values.length > 0
        ? filter
        : { ...filter, values: null }
    case 'AggregationFilter':
      return { ...filter, filters: filter.filters.map(setFilter).filter(val => val !== null) }
  }
}

const getFilter = (values: Object) => {
  const filter = { ...values }

  //ибать копать, я ужасная функция, убейте меня :D

  const nestedAggregationFilterIndex = values.filters.findIndex(
    ({ type }) => type === 'AggregationFilter',
  )
  if (nestedAggregationFilterIndex !== -1) {
    const nestedAggregationFilter = values.filters[nestedAggregationFilterIndex]
    const cat = nestedAggregationFilter.filters.find(({ type }) => type === 'CategoriesFilter')
    if (cat === undefined) {
      filter.filters[nestedAggregationFilterIndex].filters = [
        ...filter.filters[nestedAggregationFilterIndex].filters,
        { type: 'CategoriesFilter', categoryIds: [] },
      ]
    }
    const attrIndex = nestedAggregationFilter.filters.findIndex(
      ({ type }) => type === 'AttributeValuesFilter',
    )
    if (attrIndex === -1) {
      filter.filters[nestedAggregationFilterIndex].filters = [
        ...filter.filters[nestedAggregationFilterIndex].filters,
        { type: 'AttributeValuesFilter', attributeDefinitionId: '', values: [] },
      ]
    } else {
      filter.filters[nestedAggregationFilterIndex].filters = filter.filters[
        nestedAggregationFilterIndex
      ].filters.map(f =>
        f.type === 'AttributeValuesFilter'
          ? { ...f, values: f.values === undefined ? [] : f.values }
          : f,
      )
    }
  }
  if (values.filters.length === 1) {
    filter.filters = [
      ...filter.filters,
      { type: 'ProductIdentifierValuesFilter', productIdentifierValues: [] },
    ]
  }
  return filter
} */

export const schema = {
  Name: {
    value: {
      getPath: ['name'],
      setPath: ['name'],
    },
  },
  Variations: {
    value: {
      getPath: ['variations'],
      setPath: ['variations'],
    },
  },
  ProductsFilter: {
    value: {
      getPath: ['productsFilter'],
      setPath: ['productsFilter'],
      // setTransform: setFilter,
      // getTransform: getFilter,
    },
  },
  /* Images: {
    value: {
      errorValue: 'url',
      getPath: ['images'],
      setPath: ['images'],
      setTransform: setImages,
      getTransform: getImages,
    },
  }, */
}
