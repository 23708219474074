import * as React from 'react'
import { useMemo } from 'react'
import { compose, withState, withHandlers, withProps } from 'recompose'
import { withTheme, Select, Textarea, Flex, Text, Modal } from '@r1/ui-kit'

const enhance = compose(
  withTheme,
  withState('reason', 'setReason', ''),
  withState('comment', 'changeComment', ''),
  withHandlers({
    onSubmitHandler:
      ({ onSubmit, reason, comment }) =>
      () =>
        onSubmit(reason, comment),
    onReasonChange:
      ({ setReason }) =>
      value =>
        setReason(value),
    onCommentChange:
      ({ changeComment }) =>
      value =>
        changeComment(value),
  }),
  withProps(({ reason, comment }) => ({
    commentValidation: reason === 'Other' ? comment.length >= 10 : true,
  })),
)

const ReviewModalInner = ({
  show,
  onCloseReviewDialog,
  theme,
  reason,
  comment,
  onReasonChange,
  onCommentChange,
  onSubmitHandler,
  reviewReasons,
  commentValidation,
}) => {
  const modalActionButtons = useMemo(
    () => [
      {
        title: 'CANCEL',
        color: 'secondary',
        onClick: onCloseReviewDialog,
        align: 'right',
        dataTestId: 'review-modal-cancel-button',
      },
      {
        title: 'SUBMIT',
        disabled: !commentValidation,
        onClick: onSubmitHandler,
        align: 'right',
        dataTestId: 'review-modal-submit-button',
      },
    ],
    [onCloseReviewDialog, commentValidation, onSubmitHandler],
  )

  return (
    <Modal
      isControlled
      show={show}
      title="Submit for review"
      size="S"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onCloseReviewDialog}
    >
      <Flex column minWidth={544} maxWidth={544}>
        <Flex column spaceBetween="M">
          <Text type="paragraph">Select reason for review</Text>
          <Select
            width="250px"
            value={reason}
            options={reviewReasons}
            data-test-id="review-modal-reason-select"
            placeholder="Choose reason"
            valueKey="key"
            labelKey="value"
            onChange={onReasonChange}
          />
          <Textarea
            data-test-id="review-modal-comment-textarea"
            value={comment}
            onChange={onCommentChange}
          />
          {!commentValidation && (
            <Text size="S" color={theme.palette.grey[500]}>
              Comment must be at least 10 characters length
            </Text>
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}

export const ReviewModal = enhance(ReviewModalInner)
