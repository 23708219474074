/* eslint-disable import/namespace */
import React from 'react'
import { Flex, Box, Placeholder } from '@r1/ui-kit'

export const FormLoadingPlaceholder = (props: { numberOfFields: number }) => {
  return (
    <Flex column minWidth={1} spaceBetween="S">
      <Box>
        <Placeholder type="form" height={props.numberOfFields} />
      </Box>
    </Flex>
  )
}
