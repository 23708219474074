// @flow

import * as React from 'react'
import { Box, Flex, Format, HR } from '@r1/ui-kit'
import type { Lib$Money as Money } from '@r1/types/flow/libTypes'

type Props = {|
  price: Money,
  tax: Money,
  shippingPrice: Money,
  shippingTax: Money,
  discount: Money,
  singleItemTotal: Money,
|}

export const PriceDetails = React.memo<Props>((p: Props) => {
  const { price, tax, shippingPrice, shippingTax, discount, singleItemTotal } = p

  return (
    <Flex column minWidth={200} spaceBetween="S">
      <Flex justify="space-between">
        <div>Price:</div>
        <Box>
          <Format.Money value={price} />
        </Box>
      </Flex>
      <Flex justify="space-between">
        <div>Tax:</div>
        <Box>
          <Format.Money value={tax} />
        </Box>
      </Flex>
      <Flex justify="space-between">
        <div>Shipping Price:</div>
        <Box>
          <Format.Money value={shippingPrice} />
        </Box>
      </Flex>
      <Flex justify="space-between">
        <div>Shipping Tax:</div>
        <Box>
          <Format.Money value={shippingTax} />
        </Box>
      </Flex>
      <Flex justify="space-between" mb="S">
        <div>Discount:</div>
        <Box>
          <div>-&nbsp;</div>
          <Format.Money value={discount} />
        </Box>
      </Flex>
      <HR />
      <Flex justify="space-between" mt="S">
        <div>Total:</div>
        <Box>
          <Format.Money value={singleItemTotal} />
        </Box>
      </Flex>
    </Flex>
  )
})
