// @flow

import * as React from 'react'
import { withAttributeDefinitionController } from '../../../components/AttributeDefinitions'
import { AttributeDefinitionsDefaultView } from './DefaultView'
import { AttributeDefinitionsPlaceholderView } from './PlaceholderView'
import { AttributeDefinitionsWrapperView } from './CommonView'

const getScreen = (screenType, props) => {
  // eslint-disable-next-line default-case
  switch (screenType) {
    case 'placeholder':
      return <AttributeDefinitionsPlaceholderView />
    case 'forbidden':
      return 'forbidden'
    case 'default':
      // @todo: remove this checking
      if (props.generalInfo) return <AttributeDefinitionsDefaultView {...props} />
      return null
  }
  return null
}

export const AttributeDefinitionsView = withAttributeDefinitionController(
  ({ screenType, ...props }) => {
    if (screenType === 'forbidden') return 'forbidden'
    return (
      <AttributeDefinitionsWrapperView>
        {getScreen(screenType, props)}
      </AttributeDefinitionsWrapperView>
    )
  },
)
