import uuid from 'uuid'
import { NotificationSystem } from '@r1/ui-kit'
import { createReducer, createTypes } from '../../../../../../redux/utils'
import { productTemplateConsigneeUpcApi } from '../../../../../../api/productTemplate/index.ts'
import { proceedError, showErrors } from '../../../../utils'

const initialState = {
  consigneeUPCs: null,
  isFetched: true,
  meta: {
    classifications: [],
    marketplaces: [],
    warehouses: [],
  },
}

const types = createTypes(['setFetching', 'setConsigneeUpc', 'initForm'], 'consigneeUPC')

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.setConsigneeUpc]: (state, payload) => ({ ...state, ...payload }),
  [types.initForm]: (state, { consigneeUPCs }) => ({ ...state, consigneeUPCs }),
})

export function showError({ title, message }) {
  return () => {
    NotificationSystem.addNotification({ level: 'error', title, message })
  }
}

export function initForm(consigneeUPCs) {
  return {
    type: types.initForm,
    consigneeUPCs,
  }
}

export function saveConsigneeUpc(id, consigneeUpc) {
  return async (_dispatch, _getState, _api) => {
    const res = await productTemplateConsigneeUpcApi.save(
      { id, consigneeUpc: consigneeUpc.consigneeUpc },
      consigneeUpc,
    )

    if (res.status !== 200) {
      showErrors(proceedError(res))
    } else {
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: `Consignee UPC #${consigneeUpc.consigneeUpc} successfully saved`,
      })
    }
  }
}

export function deleteConsigneeUpc(id, consigneeUpcId, index) {
  return async (dispatch, getState, _api) => {
    const currentUPCs = getState().consigneeUPC.consigneeUPCs

    if (!currentUPCs) return

    let consigneeUPCs
    if (currentUPCs.find(el => el.consigneeUpc === consigneeUpcId) && consigneeUpcId !== '') {
      await productTemplateConsigneeUpcApi.delete({ id, consigneeUpc: consigneeUpcId }, {})
      consigneeUPCs = currentUPCs.filter(upc => upc.consigneeUpc !== consigneeUpcId)
    } else {
      consigneeUPCs = currentUPCs.filter((el, idx) => idx !== index)
    }
    dispatch({
      type: types.setConsigneeUpc,
      consigneeUPCs,
    })

    NotificationSystem.addNotification({
      level: 'success',
      title: 'Success',
      message: `Consignee UPC #${consigneeUpcId} successfully deleted`,
    })
  }
}

export function addConsigneeUpc() {
  return async (dispatch, getState) => {
    const currentUPCs = getState().consigneeUPC.consigneeUPCs

    if (!currentUPCs) return

    const consigneeUPCs = currentUPCs.concat({
      key: uuid.v4(),
      consigneeUpc: '',
      classifications: [],
      excludedWarehouses: [],
      marketplaces: [],
      warehouses: [],
    })
    dispatch({
      type: types.setConsigneeUpc,
      consigneeUPCs,
    })
  }
}
