// @flow

import React, { useState, useEffect } from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Table } from '@r1/grid'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { QuarantineProductsChart, ConfirmationModal } from './components'
import { tableDisplaySettingsGetter } from './tableDisplaySettings'
import * as api from './api'

type ComparisonResult = {
  rule: {
    id: string,
    name: string,
  },
  comparisonResult: Array<{
    ruleComparisonSettingId: string,
    productsCount: number,
    status: string,
  }>,
}

const mapComparisonData = data =>
  data.map((result: ComparisonResult) => {
    const comparisonResultArray = result.comparisonResult
    const comparisonResultObject = {}

    comparisonResultArray.forEach(item => {
      comparisonResultObject[item.ruleComparisonSettingId] = {
        count: item.productsCount,
        status: item.status,
      }
    })

    return {
      rule: result.rule,
      comparisonResult: comparisonResultObject,
    }
  })

export const ProductTemplatePricesAnomaliesByRule = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSyncConfirmed, setIsSyncConfirmed] = useState(false)
  const [comparisonSettings, setComparisonSettings] = useState([])
  const [chartData, setChartData] = useState([])
  const [tableData, setTableData] = useState([])
  // for modal confirm logic
  const [ruleId, setRuleId] = useState('')
  const [settingId, setSettingId] = useState<?string>('')
  // for cell's animated loader
  const [loadingRuleId, setLoadingRuleId] = useState('')
  const [loadingSettingId, setLoadingSettingId] = useState<?string>('')

  useEffect(() => {
    const fetchInitialData = async () => {
      setIsLoading(true)

      setComparisonSettings((await api.getRuleComparisonSettings()) || [])

      const rawComparisonData = (await api.getConsolidatedPricesComparisonResult()) || []
      setTableData(mapComparisonData(rawComparisonData))

      setChartData((await api.getPricesStatistics()) || [])

      setIsLoading(false)
    }

    fetchInitialData()
  }, [])

  useEffect(() => {
    if (isSyncConfirmed) {
      const sync = async () => {
        await api.syncConsolidatedProductsPricesByRule({ ruleId, comparisonSettingId: settingId })

        // this will remove animated loader from cell
        setLoadingRuleId('')
        setLoadingSettingId('')
      }

      sync()
    }
  }, [settingId, isSyncConfirmed, ruleId])

  const onSyncClick = (_ruleId: string, _settingId?: string) => {
    setRuleId(_ruleId)
    // settingId might be undefined - it means that the whole row is syncing
    setSettingId(_settingId)

    setIsSyncConfirmed(false)
    setIsModalOpen(true)
  }

  const onModalConfirm = () => {
    setIsModalOpen(false)

    // this will force animated loader to appear in cell
    setLoadingRuleId(ruleId)
    setLoadingSettingId(settingId)

    setIsSyncConfirmed(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const displaySettings = tableDisplaySettingsGetter.pricesAnomaliesByRule({
    loadingSettingId,
    loadingRuleId,
    comparisonSettings,
    onSyncClick,
  })

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowPricesQuarantineView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title />
        </ContentHeader>
        <Content>
          <Table
            suppressVirtualization
            displaySettings={displaySettings}
            loading={isLoading}
            data={tableData}
          />
          <QuarantineProductsChart chartData={chartData} />
        </Content>

        <ConfirmationModal show={isModalOpen} onConfirm={onModalConfirm} onCancel={onModalCancel} />
      </Main>
    </PageAccessControl>
  )
}
