// @flow

import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Button, Icon } from '@r1/ui-kit'

import { ROUTES } from '../../../navigation'
import { httpClient } from '../../../httpClient'

const ActionButton = ({ redirectToCreatePage }: { redirectToCreatePage: () => void }) => (
  <Button onClick={redirectToCreatePage}>
    <Icon type="plus" color="inverted" />
    Add new
  </Button>
)

export const ViewSupplyLevelRulesGrid = () => {
  const navigate = useNavigate()

  const initialStateQuery = useMemo(() => {
    const query = parseQuery()
    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: 100,
      },
    }
  }, [])

  const redirectToCreatePage = useCallback(
    () => navigate(ROUTES.SUPPLY_LEVEL_RULE_CREATE),
    [navigate],
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSupplyLevelRuleView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Title>Supply Level Rules</ContentHeader.Title>
        <Content>
          <GridGenerator
            gridId="SupplyLevelRulesGrid"
            gridEngineBaseUrl="/uisvc/legacy-grids"
            initialStateQuery={initialStateQuery}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            httpClient={httpClient}
            aggregationCount={{ title: 'All Supply Level Rules' }}
            toolbarActionButtons={<ActionButton redirectToCreatePage={redirectToCreatePage} />}
            onChangeState={setQuery}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
