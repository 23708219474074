import * as React from 'react'
import { H4, Flex, Box, FormField, Input, Select, Button, Checkbox, HR } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

import type { MainUserInfoProps } from '../../types/MainUserInfo'

import { getDataTestId } from '../../utils/testUtils'
import { TextReadOnly, LabelReadOnly } from '../common'

const COLUMN_WIDTH = 408

const EditableView = (props: MainUserInfoProps) => {
  const [{ allowChangeUserType }] = useAccessControl()
  const {
    disabled,
    fullName,
    jobTitle,
    manager,
    userCompany,
    location,
    department,
    externalId,
    userType,
    isSalesRepresentative,
    reportLogin,
  } = props

  const userTypeDisabled = React.useMemo(() => {
    if (allowChangeUserType) {
      return disabled
    }
    return true
  }, [disabled, allowChangeUserType])

  return (
    <Flex column minWidth={COLUMN_WIDTH} maxWidth={COLUMN_WIDTH}>
      <H4>General Information</H4>
      <Box>
        <FormField disabled={disabled} error={!!fullName.error}>
          <FormField.Label>Full Name *</FormField.Label>
          <Input
            data-test-id="um-user-full-name-input"
            value={fullName.value || ''}
            onChange={fullName.onChange}
          />
          <FormField.Error>{fullName.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled} error={!!jobTitle.error}>
          <FormField.Label>Job Title</FormField.Label>
          <Input
            data-test-id="um-user-job-title-input"
            value={jobTitle.value || ''}
            onChange={jobTitle.onChange}
          />
          <FormField.Error>{jobTitle.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled} error={!!userCompany.error}>
          <FormField.Label>Company *</FormField.Label>
          <Select
            data-test-id="um-user-company-select"
            loading={userCompany.isLoading}
            value={userCompany.value || null}
            options={userCompany.availableOptions}
            clearable={false}
            onChange={userCompany.onChange}
          />
          <FormField.Error>{userCompany.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled}>
          <FormField.Label>Manager</FormField.Label>
          <Select
            data-test-id="um-user-manager-select"
            loading={manager.isLoading}
            value={manager.value || null}
            labelKey="fullName"
            options={manager.availableOptions}
            onChange={manager.onChange}
          />
          <FormField.Error>{manager.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled} error={!!department.error}>
          <FormField.Label>Department</FormField.Label>
          <Input
            data-test-id="um-user-department-input"
            value={department.value || ''}
            onChange={department.onChange}
          />
          <FormField.Error>{department.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled} error={!!location.error}>
          <FormField.Label>Location *</FormField.Label>
          <Select
            clearable
            editable
            data-test-id="um-location-select"
            loading={location.isLoading}
            value={location.value || null}
            options={location.availableOptions}
            onChange={location.onChange}
            onAddOption={name => {
              location.onAdd(name)
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onRemoveOption={(option: any) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              location.onDelete(option.id)
            }}
          />
          <FormField.Error>{location.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled} error={!!externalId.error}>
          <FormField.Label>External Id</FormField.Label>
          <Input
            data-test-id="um-user-externalId-input"
            value={externalId.value || ''}
            onChange={externalId.onChange}
          />
          <FormField.Error>{externalId.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={userTypeDisabled}>
          <FormField.Label>User Type *</FormField.Label>
          <Select
            data-test-id="um-user-userType-select"
            loading={userType.isLoading}
            value={userType.value || null}
            options={userType.availableOptions}
            clearable={false}
            onChange={userType.onChange}
          />
          <FormField.Error>{userType.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={disabled}>
          <FormField.Label>Report Login</FormField.Label>
          <Select
            data-test-id="um-user-report-login-select"
            loading={reportLogin.isLoading}
            value={reportLogin.value || null}
            labelKey="name"
            options={reportLogin.availableOptions}
            onChange={reportLogin.onChange}
          />
          <FormField.Error>{reportLogin.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField>
          <FormField.Label>Sales Representative</FormField.Label>
          <Box>
            <Checkbox
              data-test-id="um-user-salesRepresentative-checkbox"
              checked={isSalesRepresentative.value}
              onChange={isSalesRepresentative.onChange}
            />
          </Box>
        </FormField>
      </Box>
    </Flex>
  )
}

const ReadOnlyView = (props: MainUserInfoProps) => {
  const {
    fullName,
    jobTitle,
    manager,
    userCompany,
    location,
    department,
    externalId,
    userType,
    printExternalId,
    isSalesRepresentative,
    reportLogin,
  } = props

  const userCompanyInfo = userCompany.availableOptions.find(({ id }) => id === userCompany.value)
  const userCompanyName = userCompanyInfo ? userCompanyInfo.name : ''

  const managerInfo = manager.availableOptions.find(({ id }) => id === manager.value)
  const managerName = managerInfo ? managerInfo.name : ''

  const locationInfo = location.availableOptions.find(({ id }) => id === location.value)
  const locationName = locationInfo ? locationInfo.name : ''

  const userTypeValue = userType.availableOptions.find(({ id }) => id === userType.value)

  const reportLoginInfo = reportLogin.availableOptions.find(({ id }) => id === reportLogin.value)

  return (
    <Flex column minWidth={COLUMN_WIDTH} maxWidth={COLUMN_WIDTH}>
      <LabelReadOnly>General Information</LabelReadOnly>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Full Name</FormField.Label>
          <TextReadOnly data-test-id="full-name">{fullName.value}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Job Title</FormField.Label>
          <TextReadOnly data-test-id="job-title">{jobTitle.value}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Company</FormField.Label>
          <TextReadOnly data-test-id="company">{userCompanyName}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Manager</FormField.Label>
          <TextReadOnly data-test-id="manager">{managerName}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Department</FormField.Label>
          <TextReadOnly data-test-id="department">{department.value}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Location</FormField.Label>
          <TextReadOnly data-test-id="location">{locationName}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>External Id</FormField.Label>
          <TextReadOnly data-test-id="external-id">{externalId.value}</TextReadOnly>

          {printExternalId && printExternalId.visible && (
            <Box>
              <Button
                transparent
                data-test-id={getDataTestId('main-user-info', 'print-external-id-button')}
                onClick={printExternalId.dispatch}
              >
                Print label
              </Button>
            </Box>
          )}
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>User Type</FormField.Label>
          <TextReadOnly data-test-id="user-type">
            {userTypeValue ? userTypeValue.name : ''}
          </TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Report Login</FormField.Label>
          <TextReadOnly data-test-id="report-login">
            {reportLoginInfo ? reportLoginInfo.name : ''}
          </TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Sales Representative</FormField.Label>
          <Box minWidth={200}>
            <Checkbox
              disabled
              data-test-id="sales-representative"
              checked={isSalesRepresentative.value}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const MainUserInfo = (props: MainUserInfoProps) => {
  const View = props.interactionType === 'View' ? ReadOnlyView : EditableView

  return <View {...props} />
}
