import { connect } from 'react-redux'

import { Ids } from '../../constants'
import {
  addTextBlock,
  addBarcodeBlock,
  addQrCodeBlock,
  addRectangleBlock,
  addListBlock,
  removeBlock,
} from '../../actions'
import { ToolBar } from './ToolBar'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    return {
      state: block,
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    buttons: [
      {
        text: 'Text',
        handler: () => dispatch(addTextBlock()),
      },
      {
        text: 'Barcode',
        handler: () => dispatch(addBarcodeBlock()),
      },
      {
        text: 'QrCode',
        handler: () => dispatch(addQrCodeBlock()),
      },
      {
        text: 'Rectangle',
        handler: () => dispatch(addRectangleBlock()),
      },
      {
        text: 'List',
        handler: () => dispatch(addListBlock()),
      },
      {
        text: 'Remove',
        getEnabled: block => block,
        handler: block => dispatch(removeBlock(block)),
      },
    ],
  }
}

export const BlocksToolBar = connect(mapStateToProps, mapDispatchToProps)(ToolBar)
