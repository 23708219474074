// @flow

import * as React from 'react'
import { useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'

import { EditProfileScreen } from '../components/EditProfileScreen'
import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

export const EditProfile = () => {
  const { id } = useParams()
  const [{ allowManageProfilesEdit }] = useAccessControl()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowManageProfilesView]}>
      <EditProfileScreen
        isAllowedToEdit={allowManageProfilesEdit}
        httpClient={httpClient}
        profileId={id}
        onError={errorHandler}
      />
    </PageAccessControl>
  )
}
