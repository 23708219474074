import * as React from 'react'

import { MasterProgramRule, SubStamp, SubStampDescription } from '@r1-webui/masterprograms-v1'
import { useController, useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Button, Flex, FormField, Icon, Select, Text } from '@r1/ui-kit'
import { Value } from '../../Value'
import { useSubStampsApi } from './useSubStampsApi'
import { SubStampValues } from './SubStampValues'
import { FilterBorderContainer } from './FilterBorderContainer'

type Props = {
  editable: boolean
}

const getSubStampValueOptions = (subStampId: string, subStamps: SubStamp[]) => {
  return subStamps.find(x => x.id === subStampId)?.values ?? []
}

export const SubStamps = ({ editable }: Props) => {
  const { control } = useFormContext<MasterProgramRule>()

  const { fields, append, remove, replace } = useFieldArray({
    name: 'subStamps',
    keyName: 'key',
    control,
  })

  const {
    formState: { errors },
  } = useController({
    name: 'subStamps',
    control,
  })

  const { isLoading, subStamps } = useSubStampsApi(editable)

  const onAddFilter = React.useCallback(() => {
    const emptySubStamp: SubStampDescription = {
      id: '',
      values: [],
    }
    append(emptySubStamp)
  }, [append])

  const onUpdateSubStampId = React.useCallback(
    (index: number, id: string) => {
      const newValue = fields.map((x, arrayIndex) => {
        if (index === arrayIndex) {
          return {
            id,
            values: [],
          }
        }
        return x
      })
      replace(newValue)
    },
    [fields, replace],
  )

  const onRemoveFilter = React.useCallback(
    (index: number) => () => {
      remove(index)
    },
    [remove],
  )

  return (
    <Flex column>
      <Flex column basis={1}>
        {fields.map((field, index) => {
          const error = errors.subStamps && errors.subStamps[index]?.id?.message
          const number = index + 1
          return (
            <Flex key={field.key} basis={1} mt="L" data-test-id={`sub-stamp-block-${index}`}>
              <FilterBorderContainer pr="S" mr="M">
                <Text type="paragraph">{number}</Text>
              </FilterBorderContainer>
              <Flex column basis={1}>
                {editable ? (
                  <FormField>
                    <FormField.Label>Substamp</FormField.Label>
                    <Select
                      data-test-id={`sub-stamp-${index}__select`}
                      options={subStamps}
                      loading={isLoading}
                      error={!!error}
                      value={field.id}
                      onChange={value => onUpdateSubStampId(index, value as string)}
                    />
                    <FormField.Error>{error}</FormField.Error>
                  </FormField>
                ) : (
                  <FormField>
                    <FormField.Label>Substamp</FormField.Label>
                    <Value data-test-id={`sub-stamp-value-${index}`}>
                      {subStamps.find(x => x.id === field.id)?.name}
                    </Value>
                    <FormField.Error>{error}</FormField.Error>
                  </FormField>
                )}
                <SubStampValues
                  editable={editable}
                  subStampIndex={index}
                  subStampValues={getSubStampValueOptions(field.id, subStamps)}
                />
                {editable && (
                  <Flex justify="flex-end">
                    <Box mt="XS" minWidth={180} maxWidth={180}>
                      <Button
                        block
                        data-test-id={`sub-stamp-${index}__remove`}
                        color="danger"
                        onClick={onRemoveFilter(index)}
                      >
                        <Icon type="minus" /> Remove Substamp
                      </Button>
                    </Box>
                  </Flex>
                )}
              </Flex>
            </Flex>
          )
        })}
      </Flex>

      {editable && (
        <Box ml="S" mt="XS" minWidth={180} maxWidth={180}>
          <Button block data-test-id="sub-stamp__add" onClick={onAddFilter}>
            <Icon type="plus" /> Add Substamp
          </Button>
        </Box>
      )}
    </Flex>
  )
}
