// @flow

import React, { useContext, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { Flex, Box, H3, Link, Icon, Button, Text } from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import { useToggle } from '../../../../../../../../utils/hooks'
import { CategoriesTreeSelect } from '../../../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'
import { ProductSelect } from '../Common'

import type { PartsFormEditProps } from '../../../../types/product.type'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`
const DEFAULT_SHOW_COUNT = 5

export const FitsFor = (props: PartsFormEditProps) => {
  const { httpClient } = useContext(ServiceContext)
  const { values, push, remove, getProductDetailsUrl, getError, disabled } = props

  // Show all / Hide
  const { state: isShowAll, toggle: setShowAll } = useToggle(false)
  const fitsForLength = values.FitsFor.length

  const fitsForMemo = useMemo(
    () => (isShowAll ? values.FitsFor : values.FitsFor.slice(0, DEFAULT_SHOW_COUNT)),
    [values.FitsFor, fitsForLength, isShowAll],
  )
  //----

  const addFitsFor = useCallback(() => {
    if (!disabled) {
      push('FitsFor', {
        id: Math.random(),
        categoryId: null,
        productId: null,
        productOption: null,
      })
      if (!isShowAll) setShowAll()
    }
  }, [disabled, push, isShowAll])

  const removeFitsFor = useCallback(
    index => () => {
      if (!disabled) remove('FitsFor', index)
    },
    [disabled, remove],
  )

  return (
    <>
      <Box>
        <StyledH3>Fits For</StyledH3>
      </Box>

      {fitsForMemo.map((fitFor, index) => (
        <Flex
          key={`${fitFor.id}`}
          maxWidth={900}
          minWidth={900}
          justify="space-between"
          mt="S"
          align="flex-start"
        >
          <Box basis={255} mr="M">
            <CategoriesTreeSelect
              ignoreBranchNodes
              disabled={disabled} // Unsorted
              hideCategoryIds={['0']}
              width={255}
              multiple={false}
              placeholder="Select category"
              httpClient={httpClient}
              categoryId={fitFor.categoryId}
              onChangeCategoryId={value => {
                if (undefined !== value) {
                  props.handleChange(['FitsFor', index, 'categoryId'])(value)
                }
                props.handleChange(['FitsFor', index, 'productId'])(null)
                props.handleChange(['FitsFor', index, 'productOption'])(null)
              }}
            />
          </Box>
          <Box basis={430} mx="M">
            <ProductSelect
              {...props}
              key={`${fitFor.id}`}
              value={fitFor}
              error={getError(['FitsFor', index, 'productId'])}
              path={['FitsFor', index]}
              onChange={props.handleChange}
            />
          </Box>
          <Box maxWidth={20} minWidth={20} mt="S">
            {fitFor.productId && (
              <Link href={getProductDetailsUrl(fitFor.productId)} target="_blank">
                <Icon type="externalLink" color="primary" />
              </Link>
            )}
          </Box>
          {values.FitsFor.length > 1 && (
            <Button transparent color="secondary" onClick={removeFitsFor(index)}>
              <Icon type="minus" />
            </Button>
          )}
          <Button transparent color="secondary" onClick={addFitsFor}>
            <Icon type="plus" />
          </Button>
        </Flex>
      ))}

      {DEFAULT_SHOW_COUNT < fitsForLength && (
        <Link onClick={() => setShowAll()}>
          {isShowAll ? <Text>Hide</Text> : <Text>Show all ({fitsForLength})</Text>}
        </Link>
      )}
    </>
  )
}
