// @flow

import React from 'react'
import invariant from 'invariant'
import { FormField, Input, NumericInput, Box, Flex, DatePicker, Icon, Select } from '@r1/ui-kit'
import type { DefaultValueFieldProps } from './types'

const getLabelForDefaultFormat = (type: string, unitOfMeasurement: string | null) => {
  switch (type) {
    case 'DateRange':
      return (
        // baaaaaaaaaad, i know it...
        <Flex>
          <Box mr="XXL">Default Date From</Box>
          <Box ml="XXL">To</Box>
        </Flex>
      )
    case 'Date':
      return 'Default Date'
    default:
      return (
        <>
          Default Value
          {unitOfMeasurement && ` (${unitOfMeasurement})`}
        </>
      )
  }
}
const MAX_NUMBER = 2147483647

const message = 'Type of attribute value should correspond to type of attribute definition'
const messageConstraint =
  'Type of attribute constraint should correspond to type of attribute definition'

const DefaultValueInput = ({
  valueType,
  values,
  error,
  handleChange,
  enumValues,
}: DefaultValueFieldProps) => {
  switch (valueType) {
    case 'Boolean':
      invariant(values.defaultValue.valueType === 'BoolValue', message)
      return (
        <Select
          options={
            ([
              { id: 'null', name: 'Not specified' },
              { id: 'true', name: 'Yes' },
              { id: 'false', name: 'No' },
            ]: Array<$AnyObject>)
          }
          clearable={false}
          error={!!error}
          filterable={false}
          value={
            values.defaultValue.value || values.defaultValue.value === false
              ? values.defaultValue.value.toString()
              : 'null'
          }
          onChange={(handleChange: any)(['defaultValue', 'value'])}
        />
      )
    case 'IntEnumeration':
    case 'DecimalEnumeration':
    case 'StringEnumeration': {
      invariant(values.defaultValue.valueType === 'EnumValue', message)

      invariant(
        values.valueConstraint && values.valueConstraint.valueType === 'EnumerationConstraint',
        messageConstraint,
      )

      const allowedVariants =
        values.hasConstraint && values.valueConstraint ? values.valueConstraint.allowedVariants : []

      const filteredValue = []
      if (enumValues) {
        const enums = enumValues.enumValues
        if (allowedVariants.length === 0) {
          filteredValue.splice(0, 0, ...enums)
        } else {
          for (let i = 0; i < enums.length; i += 1) {
            if (allowedVariants.includes(enums[i].id)) {
              filteredValue.push(enums[i])
            }
          }
        }
      }

      return (
        <Select
          options={([{ id: 'null', value: 'Not specified' }, ...filteredValue]: Array<$AnyObject>)}
          labelKey="value"
          clearable={false}
          error={!!error}
          value={values.defaultValue.value || 'null'}
          onChange={(handleChange: any)(['defaultValue', 'value'])}
        />
      )
    }
    case 'IntRange':
    case 'DecimalRange': {
      invariant(
        values.defaultValue.valueType === 'IntRange' ||
          values.defaultValue.valueType === 'DecimalRange',
        message,
      )

      const minAllowedValue = -1 * MAX_NUMBER
      const maxAllowedValue = MAX_NUMBER

      return (
        <>
          <Box minWidth={188}>
            <NumericInput
              placeholder="Enter number"
              value={values.defaultValue.from != null ? parseFloat(values.defaultValue.from) : null}
              maxFractionDigits={values.defaultValue.valueType === 'DecimalRange' ? 100 : 0}
              min={minAllowedValue}
              max={maxAllowedValue}
              onChange={(handleChange: any)(['defaultValue', 'from'])}
            />
          </Box>
          <Icon type="minus" />
          <Box minWidth={188}>
            <NumericInput
              placeholder="Enter number"
              value={values.defaultValue.to != null ? parseFloat(values.defaultValue.to) : null}
              maxFractionDigits={values.defaultValue.valueType === 'DecimalRange' ? 100 : 0}
              min={minAllowedValue}
              max={maxAllowedValue}
              onChange={(handleChange: any)(['defaultValue', 'to'])}
            />
          </Box>
        </>
      )
    }
    case 'Date':
      invariant(values.defaultValue.valueType === 'DateValue', message)
      return (
        <DatePicker
          placeholder="YYYY-MM-DD"
          value={values.defaultValue.value}
          error={!!error}
          onChange={(handleChange: any)(['defaultValue', 'value'])}
          onRemove={(handleChange: any)(['defaultValue', 'value'])}
        />
      )
    case 'Integer':
    case 'Decimal': {
      invariant(
        values.defaultValue.valueType === 'DecimalValue' ||
          values.defaultValue.valueType === 'IntValue',
        message,
      )

      const minAllowedValue = -1 * MAX_NUMBER
      const maxAllowedValue = MAX_NUMBER

      return (
        <Box minWidth={188}>
          <NumericInput
            placeholder="Enter number"
            value={
              values.defaultValue.value !== undefined && values.defaultValue.value !== null
                ? parseFloat(values.defaultValue.value)
                : null
            }
            min={minAllowedValue}
            max={maxAllowedValue}
            error={!!error}
            maxFractionDigits={values.defaultValue.valueType === 'DecimalValue' ? 100 : 0}
            onChange={(handleChange: any)(['defaultValue', 'value'])}
          />
        </Box>
      )
    }
    case 'DateRange':
      invariant(values.defaultValue.valueType === 'DateRange', message)
      return (
        <DatePicker
          range
          placeholderFrom="YYYY-MM-DD"
          placeholderTo="YYYY-MM-DD"
          value={{ from: values.defaultValue.from || null, to: values.defaultValue.to || null }}
          error={!!error}
          onChange={val => {
            if (val === null) return // WTF datepicker?
            ;(handleChange: any)(['defaultValue', 'from'])(val.from)
            ;(handleChange: any)(['defaultValue', 'to'])(val.to)
          }}
          onRemove={() => {
            ;(handleChange: any)(['defaultValue', 'from'])(null)
            ;(handleChange: any)(['defaultValue', 'to'])(null)
          }}
        />
      )
    case 'String':
      invariant(values.defaultValue.valueType === 'StringValue', message)
      return (
        <Input
          value={values.defaultValue.value}
          error={!!error}
          onChange={value => (handleChange: any)(['defaultValue', 'value'])(value.trim())}
        />
      )
    default:
      return null
  }
}

export const DefaultValueField = (props: DefaultValueFieldProps) => (
  <FormField>
    <FormField.Label>
      {getLabelForDefaultFormat(props.valueType, props.unitOfMeasurement)}
    </FormField.Label>
    <DefaultValueInput {...props} />
    <FormField.Error>
      {props.error && (props.error.value || props.error.from || props.error.to || '')}
    </FormField.Error>
  </FormField>
)
