// @flow

import React from 'react'

import { Flex } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'

import type { MarketplaceInfoBlockProps } from '../../../types/ClaimCreation'
import { InfoBlockTitle } from '../StyledComponents'

export const MarketplaceInfoBlock = (props: MarketplaceInfoBlockProps) => {
  const { marketplaceName, marketplaceSvgId } = props

  return (
    <Flex column mr="XL">
      <InfoBlockTitle>Marketplace</InfoBlockTitle>
      <Flex>{marketplaceName}</Flex>
      {marketplaceSvgId && (
        <Flex column minWidth={40}>
          <GenericIcon height={33} svgId={marketplaceSvgId} />
        </Flex>
      )}
    </Flex>
  )
}
