// @flow

import * as React from 'react'
import {
  Flex,
  Image,
  FormField,
  ImageCarousel,
  Format,
  Icon,
  Tooltip,
  Text,
  Box,
  Button,
  NotificationSystem,
  Loader,
  useConfigurationProvider,
} from '@r1/ui-kit'
import type { Api as ClaimsApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claims'
import type {
  Image as ApiImage,
  Photo,
} from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { ProductItem } from '../../types/common'
import { UnitLinks } from '../UnitLinks'
import { ClaimLinePhotoModal } from '../ClaimLinePhotoModal'

import { PriceDetails } from '../PriceDetails'
import {
  Wrapper,
  MainImage,
  Images,
  ProductInfo,
  Thumbnail,
  ProductTitle,
  IconWrapper,
  PhotoWrapper,
} from './StyledComponents'

type Props = {|
  claimId: string,
  product: ProductItem,
  claimsApi: ClaimsApi,
|}

export const ClaimProduct = (props: Props) => {
  const { product: p, claimsApi, claimId } = props
  const { palette } = useConfigurationProvider()
  const [show, setShow] = React.useState(false)
  const [images, setImages] = React.useState<Photo[]>(p.customerImages)
  const [deletingImagesSet, setDeletingImagesSet] = React.useState<Set<string>>(new Set<string>())

  const onRemoveImageClick = React.useCallback(
    (id: string) => async (e: any) => {
      e.stopPropagation()
      setDeletingImagesSet(set => new Set(set.add(id)))
      const response = await claimsApi.deleteImage({
        claimId,
        claimItemId: p.id,
        imageId: id,
      })
      if (response.status === 200) {
        setImages(prevImages => {
          const index = prevImages.findIndex(im => im.id === id)
          return prevImages.slice(0, index).concat(prevImages.slice(index + 1))
        })
        NotificationSystem.addNotification({
          title: 'Image deleted',
          level: 'success',
        })
      } else {
        NotificationSystem.addNotification({
          title: 'Could not delete image',
          level: 'error',
        })
      }
      setDeletingImagesSet(set => {
        const newSet = new Set(set)
        newSet.delete(id)
        return newSet
      })
    },
    [claimsApi, claimId, p.id],
  )

  const onAddImages = React.useCallback(
    async (imgs: ApiImage[]) => {
      const response = await claimsApi.addImages({ claimId, claimItemId: p.id }, { images: imgs })
      if (response.status === 200) {
        setImages(prevImages => {
          return prevImages.concat(response.body.photos)
        })
        NotificationSystem.addNotification({
          title: 'Image(s) uploaded',
          level: 'success',
        })
      } else {
        NotificationSystem.addNotification({
          title: 'Could not add images',
          level: 'error',
        })
      }
    },
    [claimsApi, claimId, p.id],
  )

  const onAddPhotosButtonClick = React.useCallback(() => setShow(true), [])

  return (
    <Wrapper py="XL">
      <MainImage>
        <Image thumbnail width={158} height={158} borderRadius={4} src={p.productImageUrl} />
      </MainImage>

      <ProductInfo column>
        <ProductTitle>
          {p.title} {p.model && `(${p.model})`}
        </ProductTitle>

        <Flex justify="space-between" mt="S">
          <FormField.Label>Product ID:</FormField.Label>
          <Flex column>
            {p.units.map(unit => {
              return (
                <Box key={unit.unitId}>
                  <UnitLinks
                    unitId={unit.unitId}
                    productId={unit.productId}
                    newUnitId={unit.newUnitId}
                    returnedUnitId={unit.returnedUnitId}
                    returnedProductId={unit.returnedProductId}
                    newProductId={unit.newProductId}
                  />
                </Box>
              )
            })}
          </Flex>
        </Flex>

        {p.replacementUnits.length > 0 && (
          <Flex justify="space-between" mt="S">
            <FormField.Label>Replacement ID:</FormField.Label>
            <Flex column>
              {p.replacementUnits.map(replacementUnit => {
                return (
                  <Box key={replacementUnit.unitId}>
                    <UnitLinks
                      unitId={replacementUnit.unitId}
                      productId={replacementUnit.productId}
                    />
                  </Box>
                )
              })}
            </Flex>
          </Flex>
        )}

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Product Price:</FormField.Label>
          <Flex align="center">
            <Format.Money value={p.singleItemTotal} />
            <Tooltip
              text={
                <PriceDetails
                  price={p.price}
                  tax={p.tax}
                  shippingPrice={p.shippingPrice}
                  shippingTax={p.shippingTax}
                  discount={p.discount}
                  singleItemTotal={p.singleItemTotal}
                />
              }
            >
              <Icon type="info" />
            </Tooltip>
          </Flex>
        </Flex>
        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Product Quantity:</FormField.Label>
          <Text>{p.quantity}</Text>
        </Flex>
        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Product Total:</FormField.Label>
          <Format.Money value={p.total} />
        </Flex>
        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Return Reason:</FormField.Label>
          <Text>{p.returnReason}</Text>
        </Flex>
        <Flex mt="S">
          <FormField>
            <FormField.Label>
              <Text color={palette.grey[900]}>Customer Notes:</Text>
            </FormField.Label>
            <Text data-test-id="customer-note">{p.customerNote}</Text>
          </FormField>
        </Flex>
        {images.length > 0 && (
          <Images mt="XXS">
            <ImageCarousel.Modal
              images={images}
              triggerElement={({ open }) =>
                images.map(({ id, url }) => (
                  <Thumbnail key={id} onClick={() => open(id)}>
                    <PhotoWrapper key={id} align="center" justify="center">
                      {deletingImagesSet.has(id) ? (
                        <Loader />
                      ) : (
                        <Image thumbnail width={65} height={65} borderRadius={4} src={url} />
                      )}
                      <IconWrapper
                        color={palette.white}
                        type="clear"
                        onClick={onRemoveImageClick(id)}
                      />
                    </PhotoWrapper>
                  </Thumbnail>
                ))
              }
            />
          </Images>
        )}
        <Box alignSelf="center">
          <Button onClick={onAddPhotosButtonClick}>Add photos</Button>
        </Box>
        <ClaimLinePhotoModal show={show} setShow={setShow} addImages={onAddImages} />
      </ProductInfo>
    </Wrapper>
  )
}
