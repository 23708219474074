// @flow

import React from 'react'
import styled from '@emotion/styled'
import { FormManager } from '@r1/form-manager'
import { Drawer, Flex, Box, Button, FormField, Text, Input, Switch, HR } from '@r1/ui-kit'

import { Constraint } from '../../Constraint'
import { clientValidate } from '../../../../utils/validation'
import { getAttributeValueTypeText, getAttributeBindingType } from '../module/attributeViewMapper'
import { DefaultValueField } from './DefaultValueField'
import { ValueConstraintField } from './ValueConstraintField'
import { ChildAttributesField } from './ChildAttributesField'
import {
  initialValuesConstraint,
  initialDefaultValue,
  mapAttributeBindingToFlat,
  valueIsEmpty,
  mapFormValuesToAttributeBinding,
  transformValueTypes,
  transformConstraint,
  transformEnumValues,
} from './utils'
import { schema } from './scheme'

import type { SetupAttributeDrawerProps, FormValues, FormManagerSubmit } from './types'

const FlexWrapper = styled(Flex)`
  height: 100%;
`

const Footer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
  margin-top: auto;
  padding: ${({ theme }) => `${theme.space.M} ${theme.space.XXXL};`};
`

function canHaveChildAttributes(attributeDefinition) {
  const isUnit = attributeDefinition.type === 'UnitAttributeDefinition'
  const isBoolean = attributeDefinition.valueType === 'Boolean'
  const isEnumWithNonEmptyValues =
    (attributeDefinition.valueType === 'IntEnumeration' ||
      attributeDefinition.valueType === 'DecimalEnumeration' ||
      attributeDefinition.valueType === 'StringEnumeration') &&
    attributeDefinition.enumValues &&
    attributeDefinition.enumValues.enumValues.length !== 0
  const hasNoParent = !attributeDefinition.meta.binding.parentBinding

  return isUnit && (isBoolean || isEnumWithNonEmptyValues) && hasNoParent
}

export const SetupAttributeDrawer = (props: SetupAttributeDrawerProps) => {
  const {
    show,
    onClose,
    availableChildAttributes,
    attributeDefinition,
    onSetupBinding,
    isSubmitting,
  } = props

  const { binding, inherited, childBindingsFromChildCategory } = attributeDefinition.meta
  const { childBindings, displayName, required, valueConstraint, defaultValue } = binding

  const initialValues: FormValues = {
    displayName,
    valueConstraint: valueConstraint
      ? transformConstraint(valueConstraint)
      : initialValuesConstraint(attributeDefinition.valueType),
    defaultValue: defaultValue
      ? transformValueTypes(defaultValue)
      : initialDefaultValue(attributeDefinition.valueType),
    required,
    childBindings: childBindings
      ? mapAttributeBindingToFlat(inherited ? childBindingsFromChildCategory : childBindings)
      : [],
    hasConstraint: !!valueConstraint,
  }

  const enumValues = attributeDefinition.enumValues
    ? transformEnumValues(attributeDefinition.enumValues)
    : undefined

  const onSubmit: FormManagerSubmit = React.useCallback(
    formValues => {
      // $FlowFixMe[incompatible-call]
      // $FlowFixMe[prop-missing]
      const newBinding = mapFormValuesToAttributeBinding({ formValues, initialBinding: binding })
      const childrenUpdated = formValues.childBindings !== initialValues.childBindings

      return onSetupBinding({ binding: newBinding, childrenUpdated })
    },
    [binding, initialValues.childBindings, onSetupBinding],
  )

  const clientValidateCallback = (values: FormValues) =>
    clientValidate(schema, {
      ...values,
      valueConstraint: values.valueConstraint
        ? transformConstraint(values.valueConstraint)
        : values.valueConstraint,
      defaultValue: values.defaultValue
        ? transformValueTypes(values.defaultValue)
        : values.defaultValue,
    })

  return (
    <FormManager
      formId="setupAttributes"
      initialValues={initialValues}
      clientValidate={clientValidateCallback}
      onSubmit={onSubmit}
    >
      {({ values, errors, push, remove, handleSubmit, handleChange, handleBlur }) => (
        <Drawer.Form
          closeButton
          show={show}
          placement="right"
          title={`Attribute Settings: ${displayName || attributeDefinition.name}`}
          size={760}
          backdrop="closing"
          footer={
            <Footer>
              <Button transparent color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button loading={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </Footer>
          }
          onClose={onClose}
        >
          <FlexWrapper column justify="space-between">
            {/* Flex with Box and Flex again only for padding for all except footer */}
            <Box mx="XXL" mt="XXL" mb="XXL">
              <Flex column minWidth={624}>
                <Flex justify="space-between">
                  <FormField.Label>Type</FormField.Label>
                  <Text data-test-id="type">
                    {getAttributeValueTypeText(attributeDefinition.valueType)}
                  </Text>
                </Flex>
                <HR />
                <Flex justify="space-between">
                  <FormField.Label>Inherited</FormField.Label>
                  <Text data-test-id="inherited">{inherited ? 'Yes' : 'No'}</Text>
                </Flex>
                <HR />
                <Flex justify="space-between">
                  <FormField.Label>Binding</FormField.Label>
                  <Text data-test-id="binding">
                    {getAttributeBindingType(attributeDefinition.type)}
                  </Text>
                </Flex>
                <HR />
                <Flex justify="space-between">
                  <FormField.Label>Original Title</FormField.Label>
                  <Text data-test-id="original-title">{attributeDefinition.name}</Text>
                </Flex>
                <HR />
              </Flex>
            </Box>

            <Box mx="XXL" mb="XXL">
              <Flex column minWidth={624}>
                {!inherited && (
                  <Box my="M">
                    <FormField>
                      <FormField.Label>Display Title</FormField.Label>
                      <Input
                        data-test-id="display-title"
                        placeholder="Enter display title"
                        value={String(values.displayName || '')}
                        error={!!errors.displayName}
                        onChange={value => handleChange('displayName')(value.trim())}
                        onBlur={handleBlur('displayName')}
                      />
                      <FormField.Error>{errors.displayName}</FormField.Error>
                    </FormField>
                  </Box>
                )}
                {/* Attribute default values only for Product Attributes */}
                {/* Required prop only for Product Attributes */}
                {!inherited && attributeDefinition.type === 'ProductAttributeDefinition' && (
                  <>
                    {(!values.required || typeof values.required === 'undefined') && (
                      // Attribute default values Not Applicable if Attribute is Mandatory
                      <Box>
                        <DefaultValueField
                          valueType={attributeDefinition.valueType}
                          values={values}
                          error={errors.defaultValue}
                          handleChange={handleChange}
                          enumValues={enumValues}
                          unitOfMeasurement={
                            attributeDefinition.meta.translatedUnitOfMeasutementName
                          }
                        />
                      </Box>
                    )}
                    {valueIsEmpty(values.defaultValue) && (
                      // Mandatory / Optional attribute parameter Not Applicable if default value is set for Attribute
                      <React.Fragment>
                        <Flex align="center" justify="space-between">
                          <Flex column>
                            <FormField.Label>Required</FormField.Label>
                            <FormField.Description>
                              Make this attribute required
                            </FormField.Description>
                          </Flex>
                          <Switch
                            data-test-id="required"
                            checked={values.required}
                            onChange={handleChange('required')}
                          />
                        </Flex>
                        <HR />
                      </React.Fragment>
                    )}
                  </>
                )}

                {!inherited && values.valueConstraint && (
                  <ValueConstraintField
                    valueType={attributeDefinition.valueType}
                    hasConstraint={values.hasConstraint}
                    valueConstraint={values.valueConstraint}
                    defaultValue={values.defaultValue}
                    enumValues={enumValues}
                    error={errors.valueConstraint}
                    handleChange={handleChange}
                  />
                )}

                {inherited && binding.valueConstraint && (
                  <Constraint
                    inherited
                    value={binding.valueConstraint}
                    enumValues={attributeDefinition.enumValues}
                  />
                )}
                {/* Only unit Attribute with type Enumeration or Boolean may have Child Attribute */}
                {canHaveChildAttributes(attributeDefinition) && (
                  <ChildAttributesField
                    valueType={attributeDefinition.valueType}
                    values={values}
                    handleChange={handleChange}
                    enumValues={enumValues}
                    availableChildAttributes={availableChildAttributes}
                    push={push}
                    remove={remove}
                    inheritedChildAttributes={
                      inherited ? attributeDefinition.meta.childAttributes : null
                    }
                  />
                )}
              </Flex>
            </Box>
          </FlexWrapper>
        </Drawer.Form>
      )}
    </FormManager>
  )
}
