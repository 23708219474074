import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { propOr } from 'ramda'
import { Link, Flex, Box, FormField, Text } from '@r1/ui-kit'
import { selectors } from '../../../modules/products'

const mapStateToProps = (state, { productId }) => ({
  productCard: selectors.getItem(state, { id: productId }),
})

const enhance = compose(connect(mapStateToProps))

const ProductRelationsComponent = ({ productCard }) => {
  const productRelations = propOr([], 'unitRelations', productCard)

  return (
    <Flex maxWidth={1011} spaceBetween="S">
      {productRelations.length === 0
        ? 'There are no product relations yet'
        : productRelations.map(({ relationType, relatedUnitsInfo }) => (
            <Flex key={relationType} spaceBetween="XXS">
              <FormField.Label data-test-id={relationType}>{relationType}</FormField.Label>
              <Flex column>
                {relatedUnitsInfo.map((relationInfo, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Box key={index}>
                    <Box minWidth={150} maxWidth={150}>
                      <Link href={`/r1/product/${relationInfo.unitIdentity.unitId}`}>
                        {relationInfo.unitIdentity.trgId}
                      </Link>
                    </Box>
                    <Box minWidth={400} maxWidth={400}>
                      <Link href={`/r1v2/product-catalog/products/${relationInfo.productId}`}>
                        {relationInfo.title}
                      </Link>
                    </Box>
                    <Box minWidth={150} maxWidth={300}>
                      <Text>{relationInfo.categoriesPath.map(({ name }) => name).join('/')}</Text>
                    </Box>
                  </Box>
                ))}
              </Flex>
            </Flex>
          ))}
    </Flex>
  )
}

export const ProductRelations = enhance(ProductRelationsComponent)
