import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateCategoriesApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, selectors, actions } = createCollectionModule({
  name: '@products/categories',
  keyField: 'id',
  fetchAllFunc: (_, _api) =>
    productTemplateCategoriesApi.getProductCategories().then(({ body }) => body),
  restoreFunc: (_, categoryId, _api) =>
    productTemplateCategoriesApi
      .getProductCategoryParents({ id: categoryId })
      .then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}
export { selectors, actions }
