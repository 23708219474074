// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { Link } from '@r1/ui-kit'

import type { UnitAttributesProps } from '../../../types/public/ProductCardView'
import { Text, Header } from './Common'

const UnitLink = styled('div')`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    &::after {
      content: '';
      margin: 0 ${theme.space.XS};
      border-right: 1px solid ${theme.palette.black[300]};
      width: 1px;
      height: 14px;
    }
    &:last-child::after {
      display: none;
    }
  `}
`

const Container = styled('div')`
  display: flex;
  align-items: center;
`
// $FlowFixMe[prop-missing]
export const UnitAttributes = ({ unitAttributes, onClickUnit }: UnitAttributesProps) => (
  <React.Fragment>
    <Header>Unit</Header>
    <Container>
      {unitAttributes.length === 0 && <Text>There are no assigned attributes yet</Text>}
      {unitAttributes.map(attribute => (
        <UnitLink key={attribute.id}>
          <Link onClick={() => onClickUnit(attribute.id)}>{attribute.name}</Link>
        </UnitLink>
      ))}
    </Container>
  </React.Fragment>
)
