export const infoFieldLabels = new Map([
  ['consigned', 'Consigned/Owned'],
  ['discontinued', 'Discontinued'],
  ['directShip', 'Direct Ship'],
  ['serviceOnly', 'Service Only'],
  ['supplyChain', 'Supply Chain'],
  ['serviceSupplyChain', 'Service Supply Chain'],
  ['reCommerce', 'ReCommerce'],
  ['serviceReCommerce', 'Service ReCommerce'],
  ['physicalLocation', 'Physical Location(s)'],
  ['description', 'Description'],
  ['programQtyTypeSupplyChain', 'Program QTY type Supply Chain'],
  ['programQtyTypeReCommerce', 'Program QTY type ReCommerce'],
  ['checkIn', 'Check-In'],
  ['testing', 'Testing'],
  ['gca', 'GCA'],
  ['packaging', 'Packaging'],
  ['listableLocation', 'Listable Location'],
])
