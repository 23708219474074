// @flow

import type { AttributeDefinition } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'

export function spreadAttributes({
  categoryAttributeIds,
  allAttributes,
}: {
  categoryAttributeIds: Array<string>,
  allAttributes: Array<AttributeDefinition>,
}): {
  boundAttributes: Array<AttributeDefinition>,
  availableAttributes: Array<AttributeDefinition>,
} {
  const boundAttributes = []
  const availableAttributes = []

  allAttributes.forEach(attribute => {
    if (categoryAttributeIds.includes(attribute.id)) {
      boundAttributes.push(attribute)
    } else {
      availableAttributes.push(attribute)
    }
  })

  return { boundAttributes, availableAttributes }
}
