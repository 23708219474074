// @flow

import {
  createRuleManagementApi,
  baseUri,
} from '@r1-webui/productprices-companypriceconsolidationrulemanagement-v1'
import type { Api as CompanyPriceConsolidationRuleManagementApi } from '@r1-webui/productprices-companypriceconsolidationrulemanagement-v1/src/ruleManagement'

import { UI_API_PREFIXES } from '../../../api'

export const API_PREFIX_MANAGEMENT = `${UI_API_PREFIXES.PRICING_RULES_MANAGEMENT}/${baseUri}`

export const createCompanyPriceConsolidationRuleManagement = (
  httpClient: $AnyObject,
): CompanyPriceConsolidationRuleManagementApi =>
  createRuleManagementApi({
    http: httpClient,
    urlPrefix: `${API_PREFIX_MANAGEMENT}`,
  })
