import { BadProperty } from '@r1-webui/facilities-v1'
import { Notification } from '../../../utils/notifications/showNotification'
import { getBadPropertiesErrors } from './getBadPropertiesErrors'

export function getErrorNotification(
  status: number | null,
  {
    facilityId = '',
    badProperties = [],
  }: { facilityId?: string; badProperties?: Array<BadProperty> } = {},
): Notification {
  let message = 'Unhandled error'

  switch (status) {
    case 401:
      message = 'Authorization failed'
      break
    case 403:
      message = `Additional permissions required to view selected facility`
      break
    case 404:
      message = `Facility with id ${facilityId} not found`
      break
    case 400:
      message = getBadPropertiesErrors(badProperties).reduce(
        (result, item) => `${result}${item.fieldName}: ${item.message}\n`,
        '',
      )
      break
    default:
      break
  }

  return {
    level: 'error',
    message,
  }
}
