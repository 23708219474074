// @flow

import type { Order, OrderLine, SelectItem } from '../../../../types/ClaimCreation'

export const getOrderLines = (
  order: Order,
  returnReasons: SelectItem[],
  claimType: ?string,
): OrderLine[] => {
  if (!order) {
    return []
  }

  const orderLines = order.lines.map(line => ({
    lineId: line.lineId,
    lineInfo: line.lineInfo,
    productInfo: line.productInfo,
    selected: false,
    returnQuantity:
      claimType && ['RefundOnly', 'ReplacementOnly'].includes(claimType)
        ? 0
        : line.lineInfo.quantity,
    returnQuantityError: '',
    returnReasonId: returnReasons[0].id,
    returnReasonIdError: '',
    refundAmount: line.lineInfo.lineTotal,
    refundAmountError: '',
    notes: {
      message: '',
      images: [],
    },
    replacements: [],
    replacementsError: '',
    units: line.units,
  }))

  return orderLines
}
