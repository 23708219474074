// @flow

import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { H2, H3, Placeholder, FormField, Format, Drawer, Flex, Box, HR } from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import type { ProductsSourceDataProps } from '../../../../types/product.type'
import { BasicInfo } from './children/BasicInfo'
import { Dimensions } from './children/Dimensions'
import { Images } from './children/Images'
import { Value } from './Common'

const TitleH2 = styled(H2)`
  color: ${({ theme }) => theme.palette.grey[900]};
  width: 540px;
`
const TitleH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[900]};
`

export const ProductsSourceData = memo<ProductsSourceDataProps>(
  (props: ProductsSourceDataProps) => {
    const { isShow, title, productIdentifierValue, sourceProvider, lastPrice, onClose } = props
    const {
      productService: { fetchProductSourceData },
    } = useContext(ServiceContext)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState()

    const fetchData = useCallback(async () => {
      const { sourceData } = await fetchProductSourceData(sourceProvider, productIdentifierValue)
      setData(sourceData)
    }, [fetchProductSourceData, productIdentifierValue, sourceProvider])

    useEffect(() => {
      setLoading(true)
      fetchData().then(() => {
        setLoading(false)
      })
    }, [fetchData, productIdentifierValue, sourceProvider])

    return (
      <Drawer.Form
        closeButton
        show={isShow}
        placement="right"
        size={889}
        backdrop="closing"
        onClose={onClose}
      >
        <Flex column p="XL">
          <Box>
            <TitleH3>{title}</TitleH3>
          </Box>
          {loading ? (
            <Box>
              <Placeholder type="form" />
            </Box>
          ) : (
            data && (
              <>
                {data && data.title !== null && (
                  <Box>
                    <TitleH2>{data.title}</TitleH2>
                  </Box>
                )}
                <Flex column spaceBetween="S">
                  <Flex spaceBetween="XXS">
                    <FormField.Label>Price</FormField.Label>
                    <Value>
                      {lastPrice.price.currency} {Number(lastPrice.price.amount).toFixed(2)}
                    </Value>
                  </Flex>
                  <HR />
                </Flex>
                <Flex spaceBetween="XXS">
                  <FormField.Label>Received on</FormField.Label>
                  <Value>
                    <Format.Date>{lastPrice.receivedOn}</Format.Date>
                  </Value>
                </Flex>
                <Box mt="L">
                  <TitleH3>Basic Information</TitleH3>
                </Box>
                <BasicInfo fields={data} />
                <Box mt="L">
                  <TitleH3>Dimensions</TitleH3>
                </Box>
                <Dimensions fields={data} />
                {data.images.length > 0 && (
                  <>
                    <Box mt="L">
                      <TitleH3>Images</TitleH3>
                    </Box>
                    <Images images={data.images} />
                  </>
                )}
              </>
            )
          )}
        </Flex>
      </Drawer.Form>
    )
  },
)
