import React from 'react'
import { OfferStatus } from '@r1-webui/gowholesale-offermanagement-v1'
import { LabelType } from '@r1/ui-kit/contracts/ts/Label'
import { Label } from '@r1/ui-kit'
import { offerStatusNameMap } from '../const'

const getLabelType = ({
  status,
  isCustomerCounterOfferLast,
}: {
  status: OfferStatus
  isCustomerCounterOfferLast: boolean
}): LabelType => {
  if (status === 'CounterOffer' && isCustomerCounterOfferLast) {
    return 'success'
  }

  return 'default'
}

export const OfferStatusLabel = React.memo(
  ({
    status,
    isCustomerCounterOfferLast = false,
  }: {
    status: OfferStatus
    isCustomerCounterOfferLast?: boolean
  }) => {
    const statusName = offerStatusNameMap[status]

    const labelType = getLabelType({ status, isCustomerCounterOfferLast })

    return <Label type={labelType}>{statusName}</Label>
  },
)
