import * as React from 'react'
import { Facility, DailySchedule as DailyScheduleType } from '@r1-webui/facilities-v1'
import { Box, Button, Flex, HR, Icon } from '@r1/ui-kit'
import { Control, useController } from 'react-hook-form'
import { DailySchedule } from './DailySchedule'

export const Schedule = ({
  editable,
  schedule = [],
  control,
}: {
  editable?: boolean
  schedule?: Facility['schedule']
  control: Control
}) => {
  const { field: scheduleField } = useController({
    name: 'schedule',
    control,
    defaultValue: schedule,
  })
  const scheduleValue = scheduleField.value as DailyScheduleType[]

  const onAddDay = React.useCallback(() => {
    const emptyDay = {
      day: '',
      periods: [],
    }
    const updatedShifts = [...scheduleValue, emptyDay]
    scheduleField.onChange(updatedShifts)
  }, [scheduleField, scheduleValue])
  const onRemoveDay = React.useCallback(
    (index: number) => () => {
      const updatedShifts = scheduleValue.filter((_, idx) => idx !== index)
      scheduleField.onChange(updatedShifts)
    },
    [scheduleField, scheduleValue],
  )

  return (
    <Flex column>
      {scheduleValue.map((day, index) => {
        const isLastShift = index === scheduleValue.length - 1
        return (
          <React.Fragment key={`${day.day}-${index + 1}`}>
            <DailySchedule
              editable={editable}
              dailySchedule={day}
              control={control}
              indexPrefix={index}
              onRemoveDay={onRemoveDay(index)}
            />
            {!isLastShift && <HR />}
          </React.Fragment>
        )
      })}
      {editable && (
        <Box mt="XS">
          <Button data-test-id="facility__schedule__add" onClick={onAddDay}>
            <Icon type="plus" /> Add schedule day
          </Button>
        </Box>
      )}
    </Flex>
  )
}
