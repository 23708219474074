import styled from '@emotion/styled'

import { Flex, H3, H5 } from '@r1/ui-kit'

export const Refund = styled(Flex)`
  padding: 32px 32px 0;
`

export const Title = styled(H3)`
  margin-bottom: 18px;
`

export const TableHeader = styled(Flex)`
  ${({ theme }) => `
    text-transform: uppercase;
    color: ${theme.palette.grey[600]};
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
  `}
`

export const Table = styled(Flex)`
  max-height: calc(100% - 278px);
  overflow: auto;
`

export const ProductPrice = styled('span')`
  min-width: 120px;
  text-align: right;
`

export const ProductInfoTitle = styled(H5)`
  margin: 0;
`

export const ProductInfoDescription = styled(Flex)`
  font-size: 12px;
  line-height: 16px;
`

export const ProductInfoDescriptionQty = styled('span')`
  min-width: 52px;
`

export const Footer = styled(Flex)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

export const TotalAmountLabel = styled(H5)`
  margin: 0;
`

export const TotalAmountValue = styled(H3)`
  margin: 0;
`

export const ActionButtons = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    border-top: 1px solid ${theme.palette.grey[300]};
    background: ${theme.palette.grey[50]};
    padding: 18px 32px;
  `}
`
