import * as React from 'react'
import { compose, withState, withHandlers, mapProps } from 'recompose'
import { Textarea, Button, withTheme, Flex, Icon } from '@r1/ui-kit'

const enhance = compose(
  withTheme,
  withState('text', 'setText', null),
  mapProps(({ text, value, ...props }) => ({
    value: text === null ? value : text,
    ...props,
  })),
  withHandlers({
    onChangeHandler:
      ({ setText }) =>
      value =>
        setText(value),
    onSaveHandler:
      ({ onSave, value }) =>
      () =>
        onSave(value),
  }),
)

const IdentifiersTextComponent = ({ value, onSaveHandler, onChangeHandler, theme }) => (
  <Flex>
    <Textarea value={value} onChange={onChangeHandler} />
    <Button transparent onClick={onSaveHandler}>
      <span>&nbsp;&nbsp;SAVE&nbsp;&nbsp;</span>
      <Icon type="checkmark" size="S" color={theme.primaryColor[600]} />
    </Button>
  </Flex>
)

export const IdentifiersText = enhance(IdentifiersTextComponent)
