// @flow

import { Button, Flex, FormField, Modal, NotificationSystem, Textarea } from '@r1/ui-kit'
import React, { useCallback, useState } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

type Props = {
  handleClaimAction: (performedOn: string, reason: string) => Promise<number>,
  canPerformAction: boolean,
  successText: string,
  failText: string,
  title: string,
  description: string,
}

export const ClaimModalAction = (props: Props) => {
  const { handleClaimAction, canPerformAction, successText, failText, title, description } = props

  const [actionReason, setActionReason] = useState('')
  const [isActionError, setActionError] = useState(true)
  const [actionInProgress, setActionInProgress] = useState(false)

  const onReasonChange = useCallback((reason: string) => {
    setActionError(!reason)
    setActionReason(reason)
  }, [])

  const onCancelClaim = useCallback(
    (onClose: () => void) => async () => {
      setActionInProgress(true)
      const responseStatus = await handleClaimAction(new Date().toISOString(), actionReason)
      setActionInProgress(false)
      switch (responseStatus) {
        case 200:
          NotificationSystem.addNotification({
            level: 'success',
            message: successText,
          })
          setTimeout(() => window.location.reload(true), 1000)
          break
        default:
          NotificationSystem.addNotification({
            level: 'error',
            message: failText,
          })
          setTimeout(() => window.location.reload(true), 1000)
          break
      }
      onClose()
    },
    [actionReason, handleClaimAction, successText, failText],
  )

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        {
          title: 'Proceed',
          onClick: onCancelClaim(onClose),
          loading: actionInProgress,
          disabled: actionInProgress,
          align: 'right',
        },
      ]
    },
    [actionInProgress, onCancelClaim],
  )

  return (
    <Modal
      isControlled={false}
      title={title}
      description={description}
      actionButtons={modalActionButtons}
      buttonElement={({ onOpen }) => (
        <Button
          loading={actionInProgress}
          disabled={actionInProgress || !canPerformAction}
          onClick={onOpen}
        >
          {title}
        </Button>
      )}
    >
      <Flex column>
        <FormField>
          <Textarea
            data-test-id="action-reason"
            error={isActionError}
            value={actionReason}
            onChange={onReasonChange}
          />
          <FormField.Error>{isActionError ? 'This cannot be empty' : ''}</FormField.Error>
        </FormField>
      </Flex>
    </Modal>
  )
}
