import * as React from 'react'
import { compose, withStateHandlers, withPropsOnChange, withHandlers, withProps } from 'recompose'
import { path, mergeAll, identity, map, pathOr, concat, assoc, chain } from 'ramda'

import { NotificationSystem } from '@r1/ui-kit'

import Form from './components/Form'
import * as cards from './cards'
import { createValidationRules, createRuntimeInputConstraint } from './validation-helpers'

const mappingData = {
  False: false,
  True: true,
}

const BASE_URL = '/api/wizard'

const serverToClient = value => {
  const newValue = mappingData[value]

  return newValue === undefined ? value : newValue
}

const enhance = compose(
  withStateHandlers(
    ({ wizard, baseUrlPrefix, entityId }) => ({
      entityId,
      wizardId: wizard,
      wizardUrl: `${baseUrlPrefix || ''}${BASE_URL}`,

      stepId: null,
      wizardData: null,
      fields: [],
      isLoading: false,

      initialState: {},
      validationRules: {},
      runtimeInputConstraint: {},
    }),
    {
      updateState: () => identity,
      updateWizardData:
        ({ wizardId, wizardUrl }) =>
        response => {
          if (!response) return null

          const stepId = path(['initialData', 'stepId'])(response)
          const inputsData = compose(
            mergeAll,
            map(({ inputId, data }) => ({ [inputId]: serverToClient(data) })),
            path(['initialData', 'inputsData']),
          )(response)

          const inputFields = map(
            chain(
              assoc('requestDataUrl'),
              node => `${wizardUrl}/${wizardId}/step/${stepId}/field/${node.id}/values`,
            ),
          )(response.inputSpecs)

          return {
            wizardData: response,
            stepId,
            fields: concat(inputFields, response.buttonSpecs),
            initialState: inputsData,
            validationRules: createValidationRules(response.inputSpecs),
            runtimeInputConstraint: createRuntimeInputConstraint(response.inputSpecs),
          }
        },
    },
  ),
  withPropsOnChange(
    ['wizardId', 'wizardStateId', 'entityId'],
    async ({
      httpClient,
      wizardId,
      wizardUrl,
      wizardStateId,
      entityId,
      updateWizardData,
      onError,
    }) => {
      if (!wizardId) return

      const search = entityId ? `?entityId=${entityId}` : ''
      const url = wizardStateId
        ? `${wizardUrl}/resume/${wizardStateId}`
        : `${wizardUrl}/${wizardId}/step/initial${search}`

      try {
        const data = await httpClient.get(url)

        updateWizardData(data)
      } catch (error) {
        if (onError) {
          onError(error)
        } else {
          throw error
        }
      }
    },
  ),
  withHandlers({
    onNext:
      ({
        httpClient,
        stepId,
        wizardId,
        wizardUrl,
        wizardData,
        onNextStep,
        updateWizardData,
        onError,
      }) =>
      async (form, action, actionData) => {
        try {
          const response = await httpClient.post(`${wizardUrl}/${wizardId}/step/next`, {
            wizardState: path(['wizardState'])(wizardData),
            currentStepData: {
              stepId,
              inputsData: Object.keys(form).map(key => ({
                inputId: key,
                data: form[key],
              })),
            },
            action,
            actionData,
          })

          if (response.informationPopupMarkdown) {
            NotificationSystem.addNotification({
              autoDismiss: 10,
              level: 'success',
              title: 'Step result',
              message: response.informationPopupMarkdown,
            })
          }

          updateWizardData(response)

          if (onNextStep) {
            onNextStep(response)
          }
        } catch (error) {
          if (onError) {
            onError(error)
          } else {
            throw error
          }
        }
      },
    onBack:
      ({
        httpClient,
        wizardData,
        wizardUrl,
        wizardId,
        updateWizardData,
        onPreviousStep,
        onError,
      }) =>
      async () => {
        try {
          const response = await httpClient.post(`${wizardUrl}/${wizardId}/step/prev`, {
            wizardState: path(['wizardState'])(wizardData),
          })

          updateWizardData(response)

          if (onPreviousStep) {
            onPreviousStep(response)
          }
        } catch (error) {
          if (onError) {
            onError(error)
          } else {
            throw error
          }
        }
      },
  }),
  withHandlers({
    onSubmit:
      ({ onBack, onNext, updateState }) =>
      ({ updateErrors, actionData }) =>
      async ({ form }) => {
        try {
          updateState({ isLoading: true })
          const { action, ...formData } = form

          action === 'back' ? await onBack() : await onNext(formData, action, actionData)
        } catch (error) {
          const { body: { items, message } = {}, response: { status } = {} } = error

          if (items) {
            const errors = items
              .filter(field => field.property in form)
              .reduce(
                (acc, err) => ({
                  ...acc,
                  [err.property]: [err.errorMarkdown],
                }),
                {},
              )
            updateErrors(errors)
          }

          if (status === 422 && message) {
            NotificationSystem.addNotification({
              autoDismiss: 0,
              level: 'warning',
              message,
            })
          }
        } finally {
          updateState({ isLoading: false })
        }
      },
  }),
  withProps(({ httpClient, wizardData }) => ({
    httpClient,
    title: path(['title'], wizardData),
    button: {
      back: {
        title: path(['navigateBackButton', 'title'], wizardData),
        visible: path(['navigateBackButton', 'visible'], wizardData),
      },
      next: pathOr([], ['navigateForwardButtons'], wizardData),
    },
    // eslint-disable-next-line import/namespace
    Screen: cards[path(['type'], wizardData)] || cards.NotFound,
  })),
)

const Wizard = ({ stepId, initialState, validationRules, onSubmit, ...props }) => (
  <Form
    key={stepId}
    {...props}
    validateSingle
    validateOnChange
    rules={validationRules}
    initialState={initialState}
    onSubmit={onSubmit}
  />
)

const EnhancedWizard = enhance(Wizard)

export { EnhancedWizard }
