// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { ROUTES } from '../../../../../navigation'
import { httpClient } from '../../../../../httpClient'
import { AttributesTable } from './AttributesTable'

export const AttributeDefinitionsList = () => {
  const navigate = useNavigate()

  const handleAddClick = () => {
    navigate(ROUTES.ATTRIBUTE_DEFINITION_CREATE)
  }

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowAttributeDefinitionView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Title enableBetaLabel>Attributes</ContentHeader.Title>
        <Content>
          <AttributesTable httpClient={httpClient} onAddClick={handleAddClick} />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
