/* eslint-disable react/sort-comp */
// @flow

import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import {
  Flex,
  Input,
  DualList,
  Text,
  FormField,
  Button,
  Checkbox,
  Link,
  Box,
  withTheme,
  Modal,
  NotificationSystem,
} from '@r1/ui-kit'
import type { ComputedTheme } from '@r1/ui-kit/build/types/theming'

import { ContentHeader, Main, Content } from '@r1/wireframe-primary'
import styled from '@emotion/styled'
import { PageAccessControl, PERMISSIONS, withAccessControl } from '@r1/core-blocks'
import type { AllowedPermissions } from '@r1/core-blocks/build/types/useAccessControl'

import { getMatchedText } from '../../../utils/renderUtils'

import { FMSIExportPresetsService } from './services'

import type {
  IFMSIExportPresetsService,
  ColumnDefinition,
  ExportPreset,
  ColumnDefinitionBase,
} from './IFMSIExportPresetsService'

const DualListWrapper: typeof Box = styled(Box)`
  height: 513px;
`

const OptionWrapper: typeof Flex = styled(Flex)`
  height: 44px;
  padding-left: 20px;
  padding-right: 20px;
`

const TextWrapper: typeof Flex = styled(Flex)`
  flex: 1 1 auto;
`

type Props = {|
  params: any,
  navigate: (route: string) => void,
  allowedPermissions: AllowedPermissions,
  theme: ComputedTheme,
|}

type State = {|
  columnDefinitions: Array<ColumnDefinition>,
  presetName: string,
  useInInvoices: boolean,
  selectedColumns: string[],

  activeColumnId: string,
  aliasInputValue: string,
  isAliasModalVisible: boolean,
|}

class FMSIExportPresetsInternal extends React.Component<Props, State> {
  FMSIExportPresetsService: IFMSIExportPresetsService

  dualListSearchKeys = ['name', 'alias']

  aliasModalActionButtons: BottomActionButton[]

  constructor(props: Props) {
    super(props)

    this.FMSIExportPresetsService = new FMSIExportPresetsService()

    this.state = {
      columnDefinitions: [],
      presetName: '',
      useInInvoices: false,
      selectedColumns: [],

      activeColumnId: '',
      aliasInputValue: '',
      isAliasModalVisible: false,
    }

    this.aliasModalActionButtons = [
      {
        align: 'right',
        onClick: this.handleCloseAliasModal,
        color: 'secondary',
        title: 'Cancel',
      },
      {
        align: 'right',
        onClick: this.handleApplyAlias,
        color: 'primary',
        title: 'Apply',
      },
    ]
  }

  async componentDidMount() {
    const {
      params: { id },
    } = this.props

    let preset = FMSIExportPresetsService.defaultPreset

    const availableColumns = await this.FMSIExportPresetsService.getColumns()

    if (id && id !== 'new') {
      const currentPreset = await this.FMSIExportPresetsService.getPreset(id)

      if (currentPreset !== null) preset = currentPreset
    }

    this.setState(() => {
      return {
        columnDefinitions: FMSIExportPresetsInternal.addAliasFieldToColumnsDefinitions(
          availableColumns,
          preset,
        ),
        presetName: preset.name,
        useInInvoices: preset.useInInvoices,
        selectedColumns: preset.selectedColumns.map(selectedColumn => selectedColumn.id),
      }
    })
  }

  static addAliasFieldToColumnsDefinitions = (
    columnDefinitions: ColumnDefinitionBase[],
    currentPreset: ExportPreset | null,
  ): Array<ColumnDefinition> =>
    columnDefinitions.map(columnDefinition => {
      if (currentPreset == null) return { ...columnDefinition, alias: '' }
      const currentPresetSelectedColumn = currentPreset.selectedColumns.find(
        selectedColumn => columnDefinition.id === selectedColumn.id,
      )

      if (
        currentPresetSelectedColumn &&
        currentPresetSelectedColumn.name !== columnDefinition.name
      ) {
        return {
          ...columnDefinition,
          alias: currentPresetSelectedColumn.name,
        }
      }

      return { ...columnDefinition, alias: '' }
    })

  // State handlers
  changePresetName = presetName => this.setState({ presetName })

  changeUseInInvoices = useInInvoices => this.setState({ useInInvoices })

  // $FlowFixMe[incompatible-call] incompatible type for dual list
  handleSelectColumns = selectedColumns => this.setState({ selectedColumns })

  handleOpenAliasModal = (dataItem: ColumnDefinition) => {
    this.setState(() => {
      const currentAlias = (dataItem.alias !== '' && dataItem.alias) || dataItem.name

      return {
        activeColumnId: dataItem.id,
        aliasInputValue: currentAlias,
        isAliasModalVisible: true,
      }
    })
  }

  handleCloseAliasModal = () => {
    this.setState(() => ({ activeColumnId: '', aliasInputValue: '', isAliasModalVisible: false }))
  }

  handleChangeAlias = value => {
    this.setState(() => ({ aliasInputValue: value }))
  }

  handleApplyAlias = () => {
    const { activeColumnId, aliasInputValue } = this.state
    this.setState(
      prev => ({
        columnDefinitions: prev.columnDefinitions.map(columnDefinition =>
          columnDefinition.id === activeColumnId
            ? { ...columnDefinition, alias: aliasInputValue }
            : columnDefinition,
        ),
      }),
      this.handleCloseAliasModal,
    )
  }

  // Custom render for dual list
  selectedItemRenderer = ({
    index,
    dataItem,
    searchString,
  }: {
    index: number,
    dataItem: ColumnDefinition,
    searchString?: string,
  }) => {
    if (searchString) {
      const matchedText = getMatchedText(dataItem.name, searchString, 'ig')
      return this.renderItem(index, dataItem, matchedText)
    }
    return this.renderItem(index, dataItem)
  }

  renderItem = (index, dataItem: ColumnDefinition, matchedText) => {
    const { theme } = this.props
    let itemForRender = dataItem.name

    if (matchedText) {
      // eslint-disable-next-line react/no-array-index-key
      itemForRender = matchedText.map((node, idx) => React.cloneElement(node, { key: idx }))
    }
    return (
      <OptionWrapper align="center" basis={1}>
        <Flex basis={30}>{index + 1}</Flex>
        <TextWrapper spaceBetween="XS">
          <Text>{itemForRender}</Text>
          {dataItem.alias !== dataItem.name && (
            <Text overflow="ellipsis" color={theme.palette.grey[500]}>
              {dataItem.alias}
            </Text>
          )}
        </TextWrapper>
        <Button transparent onClick={() => this.handleOpenAliasModal(dataItem)}>
          {dataItem.alias !== dataItem.name ? 'Edit alias' : 'Add alias'}
        </Button>
      </OptionWrapper>
    )
  }

  // Submit
  handleButtonSubmit = async () => {
    const {
      navigate,
      params: { id },
    } = this.props

    if (id === 'new') {
      const newPresetId = await this.FMSIExportPresetsService.createExportPreset(
        this.getChosenColumnDefinitions(),
      )

      if (newPresetId) {
        NotificationSystem.addNotification({
          level: 'success',
          title: 'Success',
          message: 'Preset was successfully created',
        })
        navigate(`/r1/admin/fmsi/export/${newPresetId}`)
      }
    } else {
      const acknowledge = await this.FMSIExportPresetsService.updateExportPreset(
        id || '',
        this.getChosenColumnDefinitions(),
      )

      if (acknowledge) {
        NotificationSystem.addNotification({
          level: 'success',
          title: 'Success',
          message: 'Preset was successfully updated',
        })
      }
    }
  }

  // Mappings for submit
  getDefinitionById = columnDefinitionId =>
    this.state.columnDefinitions.find(
      columnDefinition => columnDefinition.id === columnDefinitionId,
    )

  getChosenColumnDefinitions = () => {
    const { selectedColumns, presetName, useInInvoices } = this.state

    return {
      name: presetName,
      useInInvoices,
      selectedColumns: selectedColumns
        .map(this.getDefinitionById)
        .filter(Boolean)
        .map(selectedColumn => {
          const { alias, ...rest } = selectedColumn
          if (alias !== '' && alias !== selectedColumn.name) {
            rest.name = alias
            return rest
          }

          return rest
        }),
    }
  }

  handleButtonCancel = () => window.history.back()

  render() {
    const {
      selectedColumns,
      presetName,
      useInInvoices,
      columnDefinitions,
      isAliasModalVisible,
      aliasInputValue,
    } = this.state

    const {
      allowedPermissions: [{ allowFMSIExportPresetsEdit }],
    } = this.props

    return (
      <PageAccessControl permissions={[PERMISSIONS.allowFMSIExportPresetsView]}>
        <Main>
          <ContentHeader>
            <ContentHeader.Breadcrumbs>
              <Link to="/Modules/Admin/FmsiExportPresets.aspx">FMSI Export Presets</Link>
            </ContentHeader.Breadcrumbs>
            <ContentHeader.Title>FMSI Export presets settings</ContentHeader.Title>
            <ContentHeader.ActionButtons>
              <Button color="secondary" onClick={this.handleButtonCancel}>
                Cancel
              </Button>
              <Button
                disabled={
                  !allowFMSIExportPresetsEdit || selectedColumns.length === 0 || presetName === ''
                }
                onClick={this.handleButtonSubmit}
              >
                Save
              </Button>
            </ContentHeader.ActionButtons>
          </ContentHeader>
          <Content>
            <Flex column align="flex-start" maxWidth={400} mb="L">
              <FormField>
                <FormField.Label>Export preset name</FormField.Label>
                <Input
                  data-test-id="preset-name"
                  value={presetName}
                  onChange={this.changePresetName}
                />
              </FormField>
              <Checkbox
                data-test-id="use-in-invoices"
                checked={useInInvoices}
                label="Use in invoices"
                onChange={this.changeUseInInvoices}
              />
            </Flex>
            <DualListWrapper maxWidth={950}>
              <DualList
                valueKey="id"
                labelKey="name"
                searchKeys={this.dualListSearchKeys}
                value={selectedColumns}
                options={columnDefinitions}
                // $FlowFixMe[incompatible-indexer] flow can not resolve DualList's dataItem prop
                selectedItemRenderer={this.selectedItemRenderer}
                onChange={this.handleSelectColumns}
              />
            </DualListWrapper>
            <Modal
              isControlled
              show={isAliasModalVisible}
              title="Edit column alias"
              actionButtons={this.aliasModalActionButtons}
              onEscapeKeyDown={this.handleCloseAliasModal}
            >
              <Input value={aliasInputValue} onChange={this.handleChangeAlias} />
            </Modal>
          </Content>
        </Main>
      </PageAccessControl>
    )
  }
}

const FMSIExportPresetsPresenter = withAccessControl<Props>(
  withTheme<Props, mixed>(FMSIExportPresetsInternal),
)

export const FMSIExportPresets = (props: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  return <FMSIExportPresetsPresenter {...props} navigate={navigate} params={params} />
}
