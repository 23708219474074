import { useCallback } from 'react'

type SearchParam = string | null

type GetParam = () => SearchParam
type SetParam = (value: SearchParam) => void
type UseURLSearchParamState = {
  get: GetParam
  set: SetParam
}

export const useURLSearchParamState = (paramName: string): UseURLSearchParamState => {
  const get = useCallback(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const value = searchParams.get(paramName)
    if (value === null) return null
    return decodeURIComponent(value)
  }, [paramName])

  const set = useCallback(
    (value: SearchParam) => {
      const newLocation = new URL(window.location.href)
      const searchParams = new URLSearchParams(window.location.search)
      if (value === null) {
        searchParams.delete(paramName)
      } else {
        searchParams.set(paramName, encodeURIComponent(value))
      }

      newLocation.search = searchParams.toString()
      window.history.replaceState({}, window.document.title, newLocation.toString())
    },
    [paramName],
  )

  return { get, set }
}
