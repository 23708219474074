import * as React from 'react'
import { Select } from '@r1/ui-kit'

export const ClassificationValues = ({
  classificationName,
  value,
  onChange,
  options,
  disabled,
  dataTestId,
}) => (
  <Select
    multiple
    width="500px"
    tagMode="none"
    disabled={disabled}
    placeholder={`${classificationName} (0)`}
    renderValue={values => `${classificationName} (${values.length})`}
    value={value}
    options={options}
    data-test-id={dataTestId}
    onChange={onChange}
  />
)
