/* eslint-disable default-param-last */
/* eslint-disable no-plusplus, no-extra-parens, no-shadow */
import * as React from 'react'
import styled from '@emotion/styled'
import { mapProps, withReducer, lifecycle, withPropsOnChange } from 'recompose'
import {
  view,
  lensPath,
  set,
  compose,
  apply,
  map,
  cond,
  is,
  lensIndex,
  equals,
  lensProp,
  T,
  curry,
  __,
} from 'ramda'
import { Select, Input, Button, DatePicker, Checkbox, FormField, Flex, Box } from '@r1/ui-kit'

function createReducer(reducers) {
  return (state, { type, ...payload }) => {
    const handler = reducers[type]

    return handler ? handler(state, payload) : state
  }
}

const updateIn = curry((path, val, obj) =>
  compose(
    set(__, val, obj),
    apply(compose),
    map(
      cond([
        [is(Number), lensIndex],
        [T, lensProp],
      ]),
    ),
  )(path),
)

const isNumeric = obj => !Array.isArray(obj) && obj - parseFloat(obj) + 1 >= 0

const CheckboxLabel = styled(Flex)`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-weight: 300;
  & > *:first-child {
    margin-right: 10px;
  }
`

function renderElement(
  node,
  lists,
  dispatch,
  path,
  index,
  moreOne,
  sortScheme,
  onAddDirtyTab,
  disabled,
) {
  let element

  const addValue = (lense, value) => {
    dispatch({
      type: 'ADD',
      value,
      path: lense,
      sortScheme,
    })
  }

  const deleteValue = lense => {
    dispatch({
      type: 'DELETE',
      path: lense,
      sortScheme,
    })
  }

  switch (node.ValueType) {
    case 'RangeOption':
    case 'Option': {
      const autocompleteProps = {}
      const changeValue = lense => value => {
        onAddDirtyTab()
        dispatch({
          type: 'OPTION',
          id: value,
          path: lense,
          sortScheme,
        })
      }

      if (lists[node.ClassificationId]) {
        const options = lists[node.ClassificationId]
        const rawValue = options.find(elem => elem.id === node.Value)

        autocompleteProps.options = options
        autocompleteProps.value = rawValue ? rawValue.id : rawValue
        autocompleteProps.onChange = changeValue(path)
      } else {
        autocompleteProps.errors = ['Range is not specified in the template']
        autocompleteProps.disabled = true
      }

      element = (
        <Select
          key={index}
          simpleValue
          {...autocompleteProps}
          placeholder={`Choose ${node.ClassificationName}`}
          disabled={disabled}
          valueKey="id"
          labelKey="value"
        />
      )
      break
    }

    case 'Decimal':
    case 'Integer': {
      const changeValue = lense => value => {
        const getFilteredValue = (value, prevValue) => {
          if (!value) return null
          return /^\d+$/.test(value) ? value : prevValue
        }

        dispatch({
          type: 'SIMPLE',
          value: getFilteredValue(value, node.Value),
          path: lense,
          sortScheme,
        })
      }
      element = (
        <Input
          key={node.ClassificationName}
          name={node.ClassificationName}
          label={node.ClassificationName}
          value={node.Value}
          disabled={disabled}
          onChange={changeValue(path)}
        />
      )
      break
    }
    case 'Text': {
      const changeValue = lense => value => {
        dispatch({
          type: 'SIMPLE',
          value,
          path: lense,
          sortScheme,
        })
      }

      element = (
        <Input
          key={node.ClassificationName}
          name={node.ClassificationName}
          label={node.ClassificationName}
          value={node.Value}
          disabled={disabled}
          onChange={changeValue(path)}
        />
      )
      break
    }
    case 'Date': {
      const changeValue = lense => value => {
        onAddDirtyTab()
        dispatch({
          type: 'SIMPLE',
          value,
          path: lense,
          sortScheme,
        })
      }

      if (typeof node.Value === 'string') {
        // eslint-disable-next-line no-param-reassign
        node.Value += '.000Z'
      }

      const parseISOString = s => {
        const b = s.split(/\D+/)
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]))
      }

      element = (
        <DatePicker
          key={node.ClassificationName}
          name={node.ClassificationName}
          value={node.Value ? parseISOString(node.Value) : node.Value}
          disabled={disabled}
          onChange={changeValue(path)}
        />
      )
      break
    }

    case 'MultipleOption': {
      const changeValue = (option, lense) => checked => {
        dispatch({
          type: 'MULTIPLE',
          id: option.id,
          checked,
          path: lense,
          sortScheme,
        })
      }

      const value = node.Value
      element = (
        <div>
          {lists[node.ClassificationId].map(option => (
            <CheckboxLabel key={option.id} align="center" m="XS">
              <Checkbox
                id={option.id}
                name={node.ClassificationName}
                checked={value.includes(option.id)}
                disabled={disabled}
                onChange={changeValue(option, path)}
              />
              <label htmlFor={option.id}>{option.value}</label>
            </CheckboxLabel>
          ))}
        </div>
      )
      break
    }
    case 'Range': {
      const changeValue = (lense, value) => {
        dispatch({
          type: 'SIMPLE',
          value,
          path: lense,
          sortScheme,
        })
      }

      const [from, to] = node.Value || []

      element = (
        <div>
          <Flex spaceBetween="M">
            <Input
              required
              name={node.ClassificationName}
              disabled={disabled}
              placeholder="From"
              label="From"
              value={from}
              onChange={value => {
                if (isNumeric(value) || !value) changeValue(path, [value, to])
              }}
            />
            <Input
              required
              name={node.ClassificationName}
              disabled={disabled}
              placeholder="To"
              label="To"
              value={to}
              errors={Number(from) > Number(to) && ['must be greater FROM']}
              onChange={value => (isNumeric(value) || !value) && changeValue(path, [from, value])}
            />
          </Flex>
        </div>
      )
      break
    }
    default:
      element = <div>None</div>
  }
  return (
    <Flex key={node.ClassificationName + path.toString()} align="flex-start">
      <Box minWidth={320} mt="S">
        <FormField.Label data-test-id={node.ClassificationName}>
          {node.ClassificationName}
        </FormField.Label>
      </Box>
      <div style={{ flexGrow: 1 }}>{element}</div>
      <div style={{ alignSelf: 'flex-start', display: 'flex' }}>
        {node.AllowMoreThenOnce && (
          <Button color="primary" onClick={() => addValue(path, node)}>
            Add
          </Button>
        )}{' '}
        {node.AllowMoreThenOnce && (
          <Button
            color="primary"
            disabled={moreOne[node.ClassificationId] === 1}
            onClick={() => deleteValue(path)}
          >
            Delete
          </Button>
        )}
      </div>
    </Flex>
  )
}

function traverse(
  scheme = [],
  level = 0,
  lists,
  currentValue = null,
  onAddDirtyTab,
  disabled,
  dispatch,
  path = [],
) {
  const newScheme = scheme.map((el, i) => ({ ...el, index: i }))

  const sortScheme = newScheme.sort((a, b) => {
    if (a.ClassificationId > b.ClassificationId) {
      return 1
    }
    if (a.ClassificationId < b.ClassificationId) {
      return -1
    }
    return 0
  })

  const moreOne = sortScheme.reduce(
    (acc, part) => ({
      ...acc,
      [part.ClassificationId]: ++acc[part.ClassificationId] || 1,
    }),
    {},
  )

  let elements = []

  sortScheme.forEach(node => {
    const isDeep =
      level === 0 ||
      (currentValue === node.ParentValue && node.ParentValue !== null) ||
      // || node.ParentValue === null && currentValue !== null && currentValue !== []
      (node.ParentValue === null && typeof currentValue === 'string')

    elements = [
      ...elements,
      isDeep
        ? renderElement(
            node,
            lists,
            dispatch,
            path.concat(node.index),
            node.index,
            moreOne,
            sortScheme,
            onAddDirtyTab,
            disabled,
          )
        : null,
      ...(node.Children.length > 0
        ? traverse(
            node.Children,
            level + 1,
            lists,
            node.Value,
            onAddDirtyTab,
            disabled,
            dispatch,
            path.concat([node.index, 'Children']),
          )
        : []),
    ]
  })

  return elements
}

const reducer = createReducer({
  LOAD: (state, { dictionaries, scheme }) => ({ ...state, dictionaries, scheme }),
  MULTIPLE: (state, { id, path }) => {
    const { scheme } = state
    const valueLens = lensPath(path.concat('Value'))
    const currentValue = new Set(view(valueLens, scheme))

    if (currentValue.has(id)) {
      currentValue.delete(id)
    } else {
      currentValue.add(id)
    }

    return { ...state, scheme: updateIn(path.concat('Value'), [...currentValue], scheme) }
  },
  OPTION: (state, { id, path }) => {
    const { scheme } = state

    return { ...state, scheme: updateIn(path.concat('Value'), id, scheme) }
  },
  SIMPLE: (state, { value, path }) => {
    const { scheme } = state

    return { ...state, scheme: updateIn(path.concat('Value'), value, scheme) }
  },
  ADD: (state, { value, path }) => {
    const { scheme } = state

    const valueLens = path.slice(0, path.length - 1)

    const newList = [
      ...view(lensPath(valueLens), scheme),
      {
        ...value,
        MapId: null,
        Value: null,
        Children:
          value.Children.length > 0
            ? value.Children.map(el => ({ ...el, MapId: null, Value: null }))
            : [],
      },
    ]

    return {
      ...state,
      scheme: valueLens.length === 0 ? newList : updateIn(valueLens, newList, scheme),
    }
  },
  DELETE: (state, { path }) => {
    const { scheme } = state
    const valueLens = path.slice(0, path.length - 1)

    const newList = [...view(lensPath(valueLens), scheme)].filter(
      (_, i) => i !== path[path.length - 1],
    )

    return {
      ...state,
      scheme: valueLens.length === 0 ? newList : updateIn(valueLens, newList, scheme),
    }
  },
})

const enhance = compose(
  withPropsOnChange([], () => ({
    getSubmitValue(scheme) {
      function travel(scheme, level = 0, parent = null) {
        return scheme
          .map(node => {
            const isInclude =
              level === 0 ||
              (node.ParentValue === parent.Value &&
                node.ParentValue !== null &&
                !parent.AllowMoreThenOnce) ||
              (node.ParentValue !== null && parent.AllowMoreThenOnce) ||
              (node.ParentValue === null && typeof parent.Value === 'string')

            return (
              isInclude && {
                ...node,
                ...(node.Children.length > 0 && {
                  Children: travel(node.Children, level + 1, node),
                }),
              }
            )
          })
          .filter(node => (node.AllowMoreThenOnce === true && node.Value === null ? false : node))
      }
      return travel(scheme)
    },
  })),
  withReducer('state', 'dispatch', reducer, { dictionaries: {}, scheme: [] }),
  lifecycle({
    componentWillMount() {
      this.props.dispatch({
        type: 'LOAD',
        dictionaries: this.props.dictionaries,
        scheme: this.props.scheme,
      })
    },
    componentWillReceiveProps({ dispatch, dictionaries, scheme, meta }) {
      if (equals(this.props.meta, meta)) {
        return false
      }

      dispatch({
        type: 'LOAD',
        dictionaries,
        scheme,
      })
      return undefined
    },
  }),
  mapProps(({ state: { dictionaries, scheme }, ...other }) => ({
    ...other,
    dictionaries,
    scheme,
  })),
)

const FormGeneratorComponent = ({
  scheme,
  dictionaries,
  dispatch,
  onAddDirtyTab,
  disabled,
  onChange,
  getSubmitValue,
}) => {
  if (onChange) onChange(getSubmitValue(scheme))
  return (
    <Flex column spaceBetween="M" pt="S" pb="M" minWidth={1011} maxWidth={1011}>
      {traverse(scheme, 0, dictionaries, null, onAddDirtyTab, disabled, dispatch)}
    </Flex>
  )
}

export const FormGenerator = enhance(FormGeneratorComponent)
