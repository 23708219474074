// @flow

import React from 'react'

import { Flex } from '@r1/ui-kit'

import type { StepsProps } from '../types'
import { OrderInfo } from '../../OrderInfo'
import { ClaimInfo } from '../../ClaimInfo'
import { ClaimLine } from '../../ClaimLine'
import { OrderInfoWrapper, ClaimInfoWrapper, ClaimLinesWrapper, Property } from './StyledComponents'

export const SummaryStep = (props: StepsProps) => {
  const {
    expectedDecisionTypeTitle,
    claimType,
    claimDescription,
    order,
    orderStatus,
    rmaProfile,
    shippingAddress,
    returnReasons,
    claimTotal,
    claimLines,
  } = props.summaryInfo

  return (
    <>
      <OrderInfoWrapper>
        <OrderInfo
          order={order}
          rmaProfile={rmaProfile}
          orderStatus={orderStatus}
          shippingAddress={shippingAddress}
        />
        <ClaimInfo claimTotal={claimTotal} />
      </OrderInfoWrapper>

      <ClaimInfoWrapper column mb="XL">
        <Flex>
          <Property>Decision:</Property>
          {expectedDecisionTypeTitle}
        </Flex>
        <Flex>
          <Property>Description:</Property>
          {claimDescription || 'N/A'}
        </Flex>
      </ClaimInfoWrapper>

      <ClaimLinesWrapper>
        {claimLines.map(claimLine => (
          <ClaimLine
            key={claimLine.lineId}
            claimType={claimType}
            order={order}
            claimLine={claimLine}
            orderStatus={orderStatus}
            rmaProfile={rmaProfile}
            returnReasons={returnReasons}
          />
        ))}
      </ClaimLinesWrapper>
    </>
  )
}
