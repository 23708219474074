import * as React from 'react'
import { withHandlers, compose } from 'recompose'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Button, Flex, Link } from '@r1/ui-kit'
import { PageAccessControl, PERMISSIONS, withAccessControl } from '@r1/core-blocks'
import ConditionMappingsList from './ConditionMappingsList'
import * as actions from './module'

const mapStateToProps = ({ conditionMappings }) => ({
  profile: conditionMappings.profile,
  conditionMappings: conditionMappings.conditionMappings,
  availableConditions: conditionMappings.availableConditions,
  marketplaceConditions: conditionMappings.marketplaceConditions,
  isCommittingInProgress: conditionMappings.isCommittingInProgress,
})

const enhance = compose(
  connect(mapStateToProps, actions),
  withHandlers({
    onDeleteRow:
      ({ deleteConditionMappings }) =>
      index => {
        deleteConditionMappings(index)
      },
    onChange:
      ({ changeConditionMappingsField }) =>
      ({ columnData, index, type, newValue }) => {
        switch (type) {
          case 'UPC': {
            const upc = newValue.split('\n')
            changeConditionMappingsField({
              columnData: { ...columnData },
              name: 'UPC',
              index,
              value: upc,
            })
            break
          }
          case 'marketplaceConditionType': {
            const condition = newValue ? newValue.name : null
            changeConditionMappingsField({
              columnData: { ...columnData },
              name: 'marketplaceConditionType',
              index,
              value: condition,
            })
            break
          }
          case 'condition': {
            const condition = newValue ? newValue.name : null
            changeConditionMappingsField({
              columnData: { ...columnData },
              name: 'condition',
              index,
              value: condition,
            })
            break
          }
          case 'physicalCondition': {
            const condition = newValue ? newValue.name : null
            changeConditionMappingsField({
              columnData: { ...columnData },
              name: 'physicalCondition',
              index,
              value: condition,
            })
            break
          }
          case 'technicalFunctionality': {
            const condition = newValue ? newValue.name : null
            changeConditionMappingsField({
              columnData: { ...columnData },
              name: 'technicalFunctionality',
              index,
              value: condition,
            })
            break
          }
          default:
            break
        }
      },
  }),
)

const ConditionMappingsGrid = ({
  profile,
  commitChanges,
  loadConditionMappings,
  addConditionMappings,
  conditionMappings,
  availableConditions,
  marketplaceConditions,
  onChange,
  isCommittingInProgress,
  onDeleteRow,
  allowedPermissions: [{ allowSalesFlowRulesEdit }],
}) => {
  const { id } = useParams()

  React.useEffect(() => {
    loadConditionMappings(id)
  }, [])

  const onSubmit = React.useCallback(
    event => {
      event.preventDefault()
      commitChanges({ id, conditionMappings })
    },
    [id, conditionMappings, commitChanges],
  )

  const onAddRow = React.useCallback(() => {
    addConditionMappings(id)
  }, [id, addConditionMappings])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSalesFlowRulesView]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Title>Edit Profile Mappings - {profile.name}</ContentHeader.Title>
          <ContentHeader.Breadcrumbs>
            <Link href="/r1/admin/metadata/marketplaceConditionMappings">Condition Mappings</Link>
          </ContentHeader.Breadcrumbs>
        </ContentHeader>
        <Content spaceBetween="S">
          <Flex spaceBetween="S">
            <Button
              style={{ overflow: 'visible' }}
              disabled={!allowSalesFlowRulesEdit || isCommittingInProgress}
              onClick={onSubmit}
            >
              Update Condition Mappings
            </Button>
            <Button
              disabled={!allowSalesFlowRulesEdit}
              style={{ overflow: 'visible' }}
              onClick={onAddRow}
            >
              +
            </Button>
          </Flex>
          <ConditionMappingsList
            conditionMappings={conditionMappings}
            marketplaceConditions={marketplaceConditions}
            availableConditions={availableConditions}
            onChange={onChange}
            onDeleteRow={onDeleteRow}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}

export default enhance(withAccessControl(ConditionMappingsGrid))
