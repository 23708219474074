import * as React from 'react'
import styled from '@emotion/styled'

const MatchedText = styled('span')`
  color: ${props => props.theme.palette.blue[600]};
`
const PlainText = styled('span')`
  color: ${props => props.theme.palette.grey[900]};
`

export const getMatchedText = (text, searchString, regexModifiers) => {
  const re = new RegExp(searchString, regexModifiers)

  let matchArray
  const matchedText = []
  let first = 0
  let last = 0

  // eslint-disable-next-line no-cond-assign
  while ((matchArray = re.exec(text)) != null) {
    last = matchArray.index

    matchedText.push(<PlainText>{text.substring(first, last)}</PlainText>)

    matchedText.push(<MatchedText>{matchArray[0]}</MatchedText>)
    first = re.lastIndex
  }

  matchedText.push(<PlainText>{text.substring(first, text.length)}</PlainText>)

  return matchedText
}
