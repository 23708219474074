// @flow

import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import * as yup from 'yup'
import { FormManager } from '@r1/form-manager'
import {
  Flex,
  FormField,
  H2,
  Box,
  Input,
  Editor,
  Placeholder,
  NotificationSystem,
} from '@r1/ui-kit'

import { ServiceContext } from '../../provider'
import { clientValidate } from '../../../../utils/validation'
import { replaceTemplateValues } from '../../../../../../utils'
import { CategoryAttributesGrid } from '../../../../components/Category/CategoryAttributesGrid'
import type {
  CategoryFormManagerValuesState,
  CategoryEditProps,
  /* TCategoryFormManagerSubmit, */
} from '../../types/category.type'
import { ChangeParent } from './children/ChangeParent'
import { PartsCategories } from './children/PartsCategories'

const schema = yup.object({
  name: yup
    .string()
    .min(2, () => `Text should be 2 symbols at least`)
    .max(100)
    .required(),
  parentId: yup.string().nullable(),
  description: yup.string().max(1000),
})

const CategoryEdit = ({
  onSuccessfulSubmit,
  onSuccessfulDelete,
  categoryId,
  children,
  getAttributeDetailsUrl,
}: CategoryEditProps) => {
  const { categoryService } = useContext(ServiceContext)
  const [version, setVersion] = useState('0')
  const [isFetch, setIsFetch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [charCount, setCharCount] = useState(0)
  // @TODO: -- Will remove when refresh callback prop will be available inside CategoryAttributeGrid
  const [isShowAttributes, setIsShowAttributes] = useState(true)
  const [restictions, setRestictions] = useState({})

  useEffect(() => {
    if (!isShowAttributes) {
      setIsShowAttributes(true)
    }
  }, [isShowAttributes])
  // @TODO: --

  const [categoryFormValues, setCategoryFormValues]: CategoryFormManagerValuesState = useState({
    id: '',
    version: '',
    name: '',
    parentId: null,
    hasSubcategories: false,
    description: '',
    lastModifiedDate: '',
    fitments: [],
  })

  const onUpdate = useCallback(latestVersion => {
    NotificationSystem.addNotification({
      level: 'success',
      title: `Category was updated successfully`,
    })
    setVersion(latestVersion)
    /**
     * @todo rm when refresh rdy
     */
    setIsShowAttributes(false)
  }, [])

  const fetchData = useCallback(async () => {
    const [{ error, data }, fitments, categoryRestictions] = await Promise.all([
      categoryService.getCategoryInfo({ categoryId }),
      categoryService.getDirectFitments({ categoryId }),
      categoryService.getCategoryRestrictions({ categoryId }),
    ])

    if (!error) {
      setVersion(data.version)
      setCategoryFormValues({ ...data, fitments })
      const mappedRestrictions = {}
      Object.keys(categoryRestictions).forEach(key => {
        if (categoryRestictions[key].type === 'InvalidAction')
          mappedRestrictions[key] = replaceTemplateValues(categoryRestictions[key].error)
      })
      setRestictions(mappedRestrictions)
    } else {
      NotificationSystem.addNotification({ level: 'error', title: error })
    }
  }, [categoryId, categoryService])

  const clientValidateCallback = useCallback(value => clientValidate(schema, value), [])

  const refreshAttributesCallback = useCallback(() => {
    // @TODO remove it soon
    setIsShowAttributes(false)
    setIsFetch(true)
    fetchData().then(() => setIsFetch(false))
  }, [fetchData])

  useEffect(() => {
    setIsFetch(true)
    fetchData().then(() => setIsFetch(false))
  }, [categoryId, fetchData])

  const onSubmitCallback = useCallback(
    async category => {
      setIsLoading(true)
      const { error } = await categoryService.updateCategory({
        category: {
          ...category,
          version,
        },
        categoryId,
      })
      setIsLoading(false)

      if (!error) {
        NotificationSystem.addNotification({
          level: 'success',
          title: `${category.name} category was updated successfully`,
        })

        if (onSuccessfulSubmit) {
          onSuccessfulSubmit()
        }
      } else {
        NotificationSystem.addNotification({ level: 'error', title: error })
      }
    },
    [categoryId, categoryService, onSuccessfulSubmit, version],
  )

  const onDelete = useCallback(() => {
    if (!categoryId) return
    setIsLoading(true)
    categoryService.deleteCategory(categoryId, version).then(result => {
      setIsLoading(false)
      if (result.status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          title: `Category was deleted successfully`,
        })
        if (onSuccessfulDelete) {
          onSuccessfulDelete()
        }
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: `Category not deleted`,
        })
      }
    })
  }, [categoryId, version, onSuccessfulDelete, categoryService])

  return (
    <Flex column>
      <Box>
        <H2>General Information</H2>
      </Box>
      {isFetch ? (
        <Placeholder type="form" height={3} />
      ) : (
        <FormManager
          initialValues={categoryFormValues}
          clientValidate={clientValidateCallback}
          onSubmit={onSubmitCallback}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
            return (
              <Flex column maxWidth={825}>
                <FormField>
                  <FormField.Label>Title *</FormField.Label>
                  <div style={{ width: '350px' }}>
                    <Input
                      placeholder="Enter title"
                      error={!!errors.name}
                      value={String(values.name)}
                      onChange={value => handleChange('name', value)}
                      onBlur={_ => {
                        const { name } = values
                        const trimName = name.replace(/\s+/gi, ' ').trim()

                        handleBlur('name')()
                        if (trimName !== name) {
                          handleChange('name', trimName)
                        }
                      }}
                    />
                  </div>
                  <FormField.Error>{errors.name}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label>Parent Category</FormField.Label>
                  <ChangeParent
                    category={categoryFormValues}
                    version={version}
                    onSuccessfulSubmit={refreshAttributesCallback}
                  />
                  <FormField.Error>{errors.parentId}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label charCounter={{ max: 1000, current: charCount }}>
                    Description
                  </FormField.Label>
                  <Editor
                    placeholder="Enter description"
                    error={!!errors.description}
                    value={String(values.description || '')}
                    onChange={(value, count) => {
                      setCharCount(count)
                      handleChange('description', value || '')
                    }}
                    onBlur={handleBlur('description')}
                  />
                  <FormField.Error>{errors.description}</FormField.Error>
                </FormField>

                {!values.hasSubcategories && (
                  <PartsCategories
                    {...{
                      values,
                      handleChange,
                    }}
                  />
                )}
                {children({
                  isLoading,
                  handlers: { handleSubmit, handleDelete: onDelete },
                  category: categoryFormValues,
                  restictions,
                })}
              </Flex>
            )
          }}
        </FormManager>
      )}
      <Box mt="XXL" mb="XL">
        <H2>Attributes</H2>
      </Box>
      <Box>
        {isShowAttributes && (
          <CategoryAttributesGrid
            mode="edit"
            version={version}
            categoryId={categoryId}
            getAttributeDetailsUrl={getAttributeDetailsUrl}
            onUpdate={onUpdate}
          />
        )}
      </Box>
    </Flex>
  )
}

export const MemoCategoryEdit = memo<CategoryEditProps>(CategoryEdit)
