import { MasterProgramRule } from '@r1-webui/masterprograms-v1'
import { MasterProgramModel } from '../types'
import { getInfoFieldsValue } from './getInfoFieldsValue'

export const processMasterProgramRuleFromApi = (
  masterProgram: MasterProgramRule,
): MasterProgramModel => {
  const bodyWithProcessedFields = {
    ...masterProgram,
    infoFields: getInfoFieldsValue(masterProgram.infoFields),
    // filterGroups must be mapped as object[] because react-hook-form does'nt work will plain array
    // https://github.com/react-hook-form/react-hook-form/issues/7622
    filterGroups: masterProgram.filterGroups.map(x => ({ filterId: x })),
    subStamps:
      masterProgram.subStamps?.map(substamp => ({
        ...substamp,
        values:
          substamp.values?.map(value => ({
            ...value,
            filterGroups: value.filterGroups.map(x => ({ filterId: x })),
          })) ?? [],
      })) ?? [],
  }

  return bodyWithProcessedFields
}

export const processMasterProgramRuleToApi = (
  masterProgram: MasterProgramModel,
): MasterProgramRule => {
  const bodyWithProcessedFields = {
    ...masterProgram,
    infoFields: getInfoFieldsValue(masterProgram.infoFields),
    filterGroups: masterProgram.filterGroups.map(x => x.filterId),
    subStamps:
      masterProgram.subStamps?.map(substamp => ({
        ...substamp,
        values:
          substamp.values?.map(value => ({
            ...value,
            filterGroups: value.filterGroups.map(x => x.filterId),
          })) ?? [],
      })) ?? [],
  }

  return bodyWithProcessedFields
}
