import * as R from 'ramda'

function updateObject(object, props, newValues) {
  return {
    ...object,
    ...newValues,
  }
}

function updateItemInArray(array, itemIds, newValues) {
  return array.map(item => {
    const itemIdToUpdate = itemIds.find(itemId => itemId === item.id)

    if (typeof itemIdToUpdate !== 'undefined') {
      return newValues[itemIdToUpdate]
    }

    return item
  })
}

function updateObjectOrArray(state, propsOrIds, newValues) {
  if (Array.isArray(state)) {
    return updateItemInArray(state, propsOrIds, newValues)
  }

  return updateObject(state, propsOrIds, newValues)
}

function getChildStateByPropOrId(state, propOrId) {
  if (Array.isArray(state)) {
    return state.find(item => item.id === propOrId)
  }

  return state[propOrId]
}

export const update = (state, propsIdsPath, updateCallback) => {
  if (propsIdsPath.length === 0) {
    return updateCallback(state)
  }

  const [currentPropsOrIds, ...otherPropsOrIds] = propsIdsPath
  const propsOrIds = Array.isArray(currentPropsOrIds) ? currentPropsOrIds : [currentPropsOrIds]
  const updatedChildren = {}

  propsOrIds.forEach(propOrId => {
    const childState = getChildStateByPropOrId(state, propOrId)
    updatedChildren[propOrId] = update(childState, otherPropsOrIds, updateCallback)
  })

  return updateObjectOrArray(state, propsOrIds, updatedChildren)
}

export const removeFromArray = (state, propsIdsPath) => {
  const splitted = R.splitAt(-1, propsIdsPath)
  const otherPropsOrIds = splitted[0]
  const itemId = splitted[1][0]

  return update(state, otherPropsOrIds, array => array.filter(item => item.id !== itemId))
}

function compareItems(a, b) {
  if (a.sortOrder) {
    return a.sortOrder - b.sortOrder
  }

  return a.id - b.id
}

export const addToArray = (state, propsIdsPath, data) => {
  return update(state, propsIdsPath, array => [...array, data].sort(compareItems))
}
