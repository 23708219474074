import React from 'react'
import { connect } from 'react-redux'
import { FormField, ControlGroup, Flex } from '@r1/ui-kit'
import { Ids, InputType } from '../../constants'
import { changeBlockTransform } from '../../actions'
import { getTransformFromBlock } from '../../utils/transform'
import Container from './Container'
import Input from './Input'

const rotations = [0, 90, 180, 270]

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    return {
      blockId: block.id,
      transform: getTransformFromBlock(block),
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onChange: (blockId, oldTransform, newTransform) =>
    dispatch(changeBlockTransform(blockId, oldTransform, newTransform)),
})

export const TransformPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ blockId, transform, onChange, ...props }) => {
  if (!transform) {
    return null
  }

  const handleChange = newTransform => {
    onChange(blockId, transform, newTransform)
  }

  return (
    <Container {...props}>
      <Flex spaceBetween="XXS">
        <FormField.Label>Position</FormField.Label>
        <ControlGroup>
          <ControlGroup.Label>X</ControlGroup.Label>
          <Input
            data-test-id="position-x"
            type={InputType.NUMBERGROUP}
            data={transform}
            propPath="position.x"
            onChange={handleChange}
          />
          <ControlGroup.Label>Y</ControlGroup.Label>
          <Input
            data-test-id="position-y"
            type={InputType.NUMBERGROUP}
            data={transform}
            propPath="position.y"
            onChange={handleChange}
          />
        </ControlGroup>
      </Flex>

      <Flex spaceBetween="XXS">
        <FormField.Label>Rotation</FormField.Label>
        <Input
          data-test-id="rotation"
          type={InputType.SELECT}
          data={transform}
          propPath="rotation"
          options={rotations.map(r => ({
            id: r,
            name: r,
          }))}
          onChange={handleChange}
        />
      </Flex>

      <Flex spaceBetween="XXS">
        <FormField.Label>Size</FormField.Label>
        <ControlGroup>
          <ControlGroup.Label>X</ControlGroup.Label>
          <Input
            data-test-id="size-x"
            type={InputType.NUMBERGROUP}
            data={transform}
            propPath="size.width"
            onChange={handleChange}
          />
          <ControlGroup.Label>Y</ControlGroup.Label>
          <Input
            data-test-id="size-y"
            type={InputType.NUMBERGROUP}
            data={transform}
            propPath="size.height"
            onChange={handleChange}
          />
        </ControlGroup>
      </Flex>
    </Container>
  )
})

/* TransformPanel.propTypes = {
  blockId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  transform: PropTypes.object,
  onChange: PropTypes.func.isRequired,
} */

// export default TransformPanel
