// @flow

import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Box, FormField, Switch, Select, Flex, Icon, Link, Button, Tooltip } from '@r1/ui-kit'

import { ChildAttributes } from '../CategoriesAttributeDrawer/ChildAttributes'
import { Section } from '../../../Section'
import type { ChildAttributesFieldProps } from './types'

const Underlined = styled('div')`
  ${({ theme }) => `&:not(:last-child) {border-bottom: 1px solid ${theme.palette.grey[300]};}`}
`

const InfoIcon = () => (
  <Tooltip text="Not editable child attributes bindings are inherited and could be customized only in parent category. ">
    <Icon type="info" />
  </Tooltip>
)

export const ChildAttributesField = ({
  values,
  valueType,
  availableChildAttributes,
  enumValues,
  handleChange,
  push,
  remove,
  inheritedChildAttributes,
}: ChildAttributesFieldProps) => {
  const options = useMemo(() => {
    if (valueType === 'Boolean') {
      return [
        { id: true, value: 'Yes' },
        { id: false, value: 'No' },
      ]
    }

    return enumValues ? enumValues.enumValues : []
  })

  const addChild = () => {
    push('childBindings', {
      values: {
        value: options.length ? options[0].id : null,
        valueType: valueType === 'Boolean' ? 'BoolValue' : 'EnumValue',
      },
      attributeDefinitionId: '1',
    })
  }

  const removeChild = childIndex => remove('childBindings', childIndex)

  const availableChildOptions = useMemo(() =>
    availableChildAttributes.map(attr => ({
      id: attr.id,
      name: attr.meta.binding.displayName || attr.name,
    })),
  )

  const hasInheritedChildBindings =
    inheritedChildAttributes && Object.keys(inheritedChildAttributes).length !== 0

  return (
    <Section
      title={SectionTitle => (
        <Flex align="center" justify="space-between" mb="M" mt="M">
          <Flex column>
            <SectionTitle icon={hasInheritedChildBindings && <InfoIcon />}>
              Child Attributes
            </SectionTitle>
            <FormField.Description>
              Create an associated child attribute that displays for a specific parent value
            </FormField.Description>
          </Flex>
          {!hasInheritedChildBindings && (
            <Switch
              checked={values.childBindings.length !== 0}
              onChange={value => {
                if (value) {
                  addChild()
                } else {
                  handleChange('childBindings', [])
                }
              }}
            />
          )}
        </Flex>
      )}
    >
      {inheritedChildAttributes && (
        <ChildAttributes as="content" childAttributes={inheritedChildAttributes} />
      )}

      <Flex column>
        {values.childBindings.map((child, childIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <Underlined key={childIndex}>
            <Flex mb="M" mt="L">
              <Box minWidth={250} maxWidth={250} mr="L">
                <FormField>
                  <FormField.Label>Linked value</FormField.Label>
                  <Select
                    data-test-id="linked-value"
                    // $FlowFixMe[incompatible-type]
                    options={options}
                    clearable={false}
                    labelKey="value"
                    filterable={false}
                    value={child.values.value.toString()}
                    onChange={handleChange(['childBindings', childIndex, 'values', 'value'])}
                  />
                </FormField>
              </Box>
              <Box minWidth={302} maxWidth={302} mr="L">
                <FormField>
                  <FormField.Label>Assigned attributes</FormField.Label>
                  <Select
                    data-test-id="assigned-attributes"
                    options={availableChildOptions}
                    clearable={false}
                    filterable={false}
                    value={child.attributeDefinitionId}
                    onChange={handleChange(['childBindings', childIndex, 'attributeDefinitionId'])}
                  />
                </FormField>
              </Box>
              <Box alignSelf="center">
                <Button transparent color="secondary" onClick={() => removeChild(childIndex)}>
                  <Icon type="clear" />
                </Button>
              </Box>
            </Flex>
          </Underlined>
        ))}
      </Flex>

      {(hasInheritedChildBindings || values.childBindings.length !== 0) && (
        <Box pb="M">
          <Link onClick={addChild}>+ Add Child Attribute</Link>
        </Box>
      )}
    </Section>
  )
}
