// @flow

import type {
  AttributeBinding,
  InheritedAttributeBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'

type RelationItem = {|
  parentId: ?string,
  children: string[],
|}

type Relations = { [id: string]: RelationItem }

export function generateAttributeRelations(
  attributeBindings: Array<InheritedAttributeBinding | AttributeBinding>,
): Relations {
  const relations: Relations = {}

  attributeBindings.forEach(({ childBindings, parentBinding, attributeDefinition }) => {
    const attributeDefinitionId = attributeDefinition.id
    relations[attributeDefinitionId] = relations[attributeDefinitionId] || {
      parentId: null,
      children: [],
    }

    if (parentBinding) {
      relations[attributeDefinitionId].parentId = parentBinding.attributeDefinitionId
      relations[parentBinding.attributeDefinitionId] = relations[
        parentBinding.attributeDefinitionId
      ] || {
        parentId: null,
        children: [],
      }

      /**
       *
       * potentially slow
       *
       */

      if (
        !relations[parentBinding.attributeDefinitionId].children.includes(
          parentBinding.attributeDefinitionId,
        )
      ) {
        relations[parentBinding.attributeDefinitionId].children.push(attributeDefinitionId)
      }
    }

    if (childBindings) {
      childBindings.forEach(childBinding => {
        /**
         *
         * potentially slow
         *
         */

        if (
          !relations[attributeDefinitionId].children.includes(childBinding.attributeDefinitionId)
        ) {
          relations[attributeDefinitionId].children.push(childBinding.attributeDefinitionId)
        }
      })
    }
  })

  return relations
}
