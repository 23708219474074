// @flow

import * as React from 'react'
import { useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'

import { httpClient } from '../../../../httpClient'
import { AttributeDefinitionsView } from './Views/View'
import { EditAttributeView } from './Edit'

export const AttributeDetails = () => {
  const [{ allowAttributeDefinitionEdit }] = useAccessControl()
  const { attributeId } = useParams()

  if (!attributeId) return null

  return allowAttributeDefinitionEdit ? (
    <EditAttributeView attributeId={attributeId} />
  ) : (
    <PageAccessControl permissions={[PERMISSIONS.allowAttributeDefinitionView]}>
      <AttributeDefinitionsView httpClient={httpClient} attributeId={attributeId} />
    </PageAccessControl>
  )
}
