// @flow

import * as React from 'react'
import { Box, FormField, HR, Flex } from '@r1/ui-kit'
import { Text } from '../Text'
import { DateRange } from '../../../../utils/utils'
import type { DateRangeConstraint as DateRangeConstraintValue } from '../types'

type Props = {| value: DateRangeConstraintValue |}

export const DateRangeConstraint = ({ value }: Props) =>
  (value.minAllowedDate || value.maxAllowedDate) && (
    <Box>
      <HR />
      <Flex>
        <FormField.Label>Allowed Dates</FormField.Label>
        <Text>
          <DateRange from={value.minAllowedDate} to={value.maxAllowedDate} />
        </Text>
      </Flex>
    </Box>
  )
