// @flow

import * as React from 'react'

/**
 * @todo move api factories to separate dir
 */
import { createAttributeDefinitionsApi } from '../../AttributeDefinitions'
import { createattributeDefinitionManagementApi } from '../../AttributeCreationForm'
import { isSuccessResponse, processResponse } from '../../utils/api'

import type { AttributeDefinition } from '../../../types/internal/api/attributeDefinitions'
import type {
  DataSources,
  FormManagerSubmit,
  AttributeEditFormControllerProps as Props,
  AttributeInfo,
} from '../../../types/public/AttributeEditFormController'
import type { UnitOfMeasurement } from '../../../types/public/attributeCreationFormCommon'
import { showNotification } from '../../../../../utils'
import { createAttributeInfo, createAttributeDefinitionUpdate } from './utils'

type State = {|
  screenType: 'default' | 'placeholder',
  dataSources: DataSources,
  attributeInfo: AttributeInfo,
  attributeDefinition: AttributeDefinition | null,
  // unitOfMeasurementType: string | null,
  wasDeletedEnumValues: boolean,
|}

function getAllowedUnitsOfMeasurement({
  attributeDefinition,
  allUnitsOfMeasurement,
}): Array<UnitOfMeasurement> {
  if (!attributeDefinition.unitOfMeasurement) {
    return []
  }

  // $FlowFixMe[prop-missing]
  return allUnitsOfMeasurement.filter(
    unit => unit.typeId === attributeDefinition.unitOfMeasurement.type.id,
  )
}

const emptyAttributeInfo = {
  attributeName: '',
  attributeType: 'String',
  attributeBindingType: 'Product',
  unitOfMeasurement: null,
  unitOfMeasurementType: null,
  attributeEnumValues: [],
  productUniqueness: false,
}

export class AttributeEditFormController extends React.Component<Props, State> {
  state = {
    screenType: 'placeholder',
    dataSources: {
      unitOfMeasurements: [],
    },
    // unitOfMeasurementType: null,
    attributeInfo: emptyAttributeInfo,
    attributeDefinition: null,
    wasDeletedEnumValues: false,
  }

  api = {
    ...createAttributeDefinitionsApi(this.props.httpClient),
    ...createattributeDefinitionManagementApi(this.props.httpClient),
  }

  componentDidMount() {
    this.fetchData()
  }

  // eslint-disable-next-line consistent-return
  onSubmit: FormManagerSubmit = async (formManagerValues, { createSubmitError }) => {
    const { attributeDefinition } = this.state

    if (attributeDefinition) {
      const update = createAttributeDefinitionUpdate({
        attributeDefinition,
        formManagerValues,
      })
      const response = await this.api
        .updateAttributeDefinition({
          attributeDefinitionId: this.props.attributeId,
          version: attributeDefinition.version,
          attributeDefinition: update,
        })
        .then(processResponse)

      if (!isSuccessResponse(response)) {
        if (response.$type === 'Validation') {
          const errors: any = response.fields.reduce(
            (map, { fieldId, message }) => ({ ...map, [fieldId]: message }),
            {},
          )
          return createSubmitError(errors)
        }

        if (response.$type === 'VersionConflictError') {
          showNotification({
            title: 'Reload page please',
            level: 'error',
          })
        }
      } else {
        showNotification({
          title: 'Successfully updated',
          level: 'success',
        })
      }
    } else {
      /* eslint-disable no-console */
      console.warn('[product-catalog] you probably trying to update entity that did not load')
      /* eslint-enable no-console */
    }
  }

  onEnumValuesChange = (enumValues: { id: string, value: string }[]) => {
    const { attributeDefinition } = this.state
    const removedIds = []
    const newIds = enumValues.map(({ id }) => id).filter(v => v)
    if (
      attributeDefinition &&
      attributeDefinition.enumValues !== undefined &&
      attributeDefinition.enumValues.enumValues.length > 0
    ) {
      attributeDefinition.enumValues.enumValues.forEach(({ id }) => {
        if (!newIds.includes(id)) {
          removedIds.push(id)
        }
      })

      if (removedIds.length > 0) this.setState({ wasDeletedEnumValues: true })
      else this.setState({ wasDeletedEnumValues: false })
    }
  }

  onDelete = () => {
    const { attributeDefinition } = this.state

    if (attributeDefinition) {
      this.api
        .deleteAttributeDefinition({
          attributeDefinitionId: this.props.attributeId,
          version: attributeDefinition.version,
        })
        .then(processResponse)
        .then(response => {
          if (!isSuccessResponse(response)) {
            if (response.$type === 'VersionConflictError') {
              showNotification({
                title: 'Reload page please',
                level: 'error',
              })
            }
          } else {
            showNotification({
              title: `Attribute definition was deleted successfully`,
              level: 'success',
            })
            if (this.props.onSuccessfulDelete) this.props.onSuccessfulDelete()
          }
        })
    }
  }

  async fetchData() {
    const [allUnitsOfMeasurementResponse, attributeDefinitionResponse] = await Promise.all([
      this.api.getAllUnitsOfMeasurement().then(processResponse),

      // $FlowFixMe[incompatible-call]
      this.api
        .getAttributeDefinition({ attributeDefinitionId: this.props.attributeId })
        .then(processResponse),
    ])

    if (
      isSuccessResponse(allUnitsOfMeasurementResponse) &&
      isSuccessResponse(attributeDefinitionResponse)
    ) {
      const attributeDefinition = attributeDefinitionResponse.payload
      const allUnitsOfMeasurement = allUnitsOfMeasurementResponse.payload

      this.setState({
        dataSources: {
          unitOfMeasurements: getAllowedUnitsOfMeasurement({
            attributeDefinition,
            allUnitsOfMeasurement,
          }),
        },
        attributeDefinition,
        attributeInfo: createAttributeInfo(attributeDefinition),
        screenType: 'default',
      })
    } else {
      this.setState({ screenType: 'default' })
    }
  }

  render() {
    const { children } = this.props
    const { screenType, dataSources, attributeInfo, wasDeletedEnumValues } = this.state

    switch (screenType) {
      case 'default':
        return children({
          screenType: 'default',
          dataSources,
          handlers: {
            onSubmit: this.onSubmit,
            onEnumValuesChange: this.onEnumValuesChange,
            onDelete: this.onDelete,
          },
          attributeInfo,
          wasDeletedEnumValues,
        })

      default:
        return children({ screenType: 'placeholder' })
    }
  }
}
