// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Table } from '@r1/grid'
import { H2, H4, Flex, Box, Label, Button } from '@r1/ui-kit'

import type { ConsolidationStepsSubViewProps } from './types'

const TABLE_MAX_HEIGHT = 500
const SPACE_BETWEEN_SUBSTEPS = 16
const TABLE_ROW_HEIGHT = 44

const TableContainer = styled('div')`
  width: 100%;
  max-height: ${TABLE_MAX_HEIGHT}px;
  overflow-y: auto;
  overflow-x: hidden;
`

const Collapse = styled(Flex)`
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  height: auto;
  transform-origin: top;
  max-height: ${({ visible, maxHeight }) => (visible ? `${maxHeight}px` : 0)};
`

const SubStepName = styled(Flex)`
  font-weight: 500;
  font-size: 14px;
  height: 44px;
`

const getTableProps = columns => ({
  displaySettings: {
    showCounter: false,
    columnsSettings: columns.map((column, idx) => {
      let width = ''
      if (columns.length === 1) {
        width = '500'
      } else if (idx === 0) {
        width = '170'
      }

      return {
        width,
        header: {
          content: <div style={{ margin: '5px' }}>{column}</div>,
        },
        cell: {
          $type: 'text',
          field: column,
        },
      }
    }),
  },
})

export const ConsolidationStepsSubView = ({ steps }: ConsolidationStepsSubViewProps) => {
  const [active, setActive] = React.useState([])

  React.useEffect(() => {
    setActive([])
  }, [steps])

  if (!steps.length) return <H2>Nothing to show</H2>

  const handleCollapse = sectionIdx => () => {
    if (active.includes(Number(sectionIdx))) {
      const newActiveState = active.filter(a => a !== sectionIdx)
      setActive([...newActiveState])
    } else {
      setActive([...active, Number(sectionIdx)])
    }
  }

  return (
    <Flex column minWidth={1000}>
      {steps.map(({ name, text, subSteps }, index) => {
        const visible = active.includes(index)
        const subStepsAvailable = subSteps !== null

        const TitleNode = (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={`consolidation-step-${index}`} maxWidth={1080} align="center" basis={1}>
            <Box basis={0.3}>
              <H4>
                {index + 1}. {name}
              </H4>
            </Box>
            <Box basis={0.3}>
              <Label type="info" colorMode="bright">
                {text}
              </Label>
            </Box>
            <Box basis={0.2}>
              {subStepsAvailable && (
                <Button color="secondary" size="S" onClick={handleCollapse(index)}>
                  {visible ? 'Hide details' : 'See details'}
                </Button>
              )}
            </Box>
          </Flex>
        )

        if (!subStepsAvailable) {
          return TitleNode
        }

        let maxHeight = subSteps.length * SPACE_BETWEEN_SUBSTEPS

        subSteps.forEach(({ data }) => {
          const tableHeight = (data.length + 1) * TABLE_ROW_HEIGHT
          maxHeight += tableHeight > TABLE_MAX_HEIGHT ? TABLE_MAX_HEIGHT : tableHeight
        })

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={`consolidation-step-${index}`} column basis={1}>
            <Box>{TitleNode}</Box>
            <Collapse
              column
              visible={active.includes(index)}
              maxWidth={1080}
              maxHeight={maxHeight}
              spaceBetween="M"
              basis={1}
            >
              {subSteps.map(({ name: subStepName, columns, data }, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={`consolidation-substep-${idx}`} spaceBetween="S">
                  <SubStepName basis={0.3} align="center" justify="flex-end">
                    {subStepName}
                  </SubStepName>
                  <Box basis={0.7}>
                    <TableContainer>
                      {/* $FlowFixMe[incompatible-type] */}
                      <Table suppressVirtualization {...getTableProps(columns)} data={data} />
                    </TableContainer>
                  </Box>
                </Flex>
              ))}
            </Collapse>
          </Flex>
        )
      })}
    </Flex>
  )
}
