// @flow

import type { AttributeBinding } from '@r1-webui/productcatalog-categorymanagement-v1/src/types'
import type {
  GetCategoryInfoOptions,
  GetCategoryPathOptions,
} from '../types/internal/api/categories'
import { processResponse } from '../components/utils/webUi'
import { Normalization } from '../normalization'
import type { CategoryFormManagerValues } from '../containers/CategoryContainer/types/category.type'
import type { Lib$Id } from '../types'
import type { WebUiApi as CategoryApi } from '../api/category/types'

type UpdateCategory = {|
  category: CategoryFormManagerValues,
  categoryId: Lib$Id,
  attributeBindings?: AttributeBinding[],
|}

type CreateCategory = CategoryFormManagerValues

export const categoryService = (api: CategoryApi) => {
  const createCategory = async ({ name, description, parentId, fitments }: CreateCategory) =>
    Normalization(
      await api.addCategory({
        category: { name, description, parentId: parentId || undefined },
        attributeBindings: [],
        fitments: fitments.map(id => ({ categoryId: id })),
      }),
    )

  const updateCategory = async ({
    category: { name, description, parentId, version, fitments },
    categoryId,
  }: UpdateCategory) =>
    Normalization(
      await api.updateCategory(
        { categoryId },
        { version },
        {
          category: {
            name,
            description,
            parentId: parentId || undefined,
          },
          fitments: fitments.map(id => ({ categoryId: id })),
        },
      ),
    )

  const fetchCategoryPath = async ({ categoryId }: GetCategoryPathOptions) =>
    Normalization(await api.getCategoryPath({ categoryId }))

  const fetchCategory = async ({ categoryId }: GetCategoryInfoOptions) =>
    Normalization(await api.getCategoryInfo({ categoryId }))

  const getCategoryInfo = async ({ categoryId }: GetCategoryInfoOptions) => {
    const { error: fetchCategoryError, data: category } = await fetchCategory({ categoryId })

    if (fetchCategoryError) {
      return { error: fetchCategoryError, data: null }
    }

    const {
      description,
      categoryInfo: { name, lastModifiedDate, parentId, version, id, hasSubcategories },
    } = category

    let parentPath = []

    if (parentId) {
      const {
        error: fetchCategoryPathError,
        data: { path },
      } = await fetchCategoryPath({
        categoryId: parentId,
      })

      if (fetchCategoryPathError) {
        return { error: fetchCategoryPathError, data: null }
      }

      parentPath = path
    }

    return {
      error: null,
      data: {
        name,
        description,
        lastModifiedDate,
        parentPath,
        parentId,
        version,
        id,
        hasSubcategories,
      },
    }
  }

  const getAttributeBindings = async ({ categoryId }: GetCategoryInfoOptions) => {
    const [directAttributeBindings, inheritedAttributeBindings] = await Promise.all([
      api
        .getDirectAttributeBindings({ categoryId })
        .then(processResponse)
        .then(response => (response.status === 200 ? response.body : [])),
      api
        .getInheritedAttributeBindings({ categoryId })
        .then(processResponse)
        .then(response => (response.status === 200 ? response.body : [])),
    ])
    return [...directAttributeBindings, ...inheritedAttributeBindings]
  }

  const getAllCategories = async () =>
    api.getAllCategories().then(response => (response.status === 200 ? response.body : []))

  const getDirectFitments = async ({ categoryId }: GetCategoryInfoOptions) =>
    api
      .getDirectFitments({ categoryId })
      .then(response =>
        response.status === 200 ? response.body.map<string>(({ categoryId: id }) => id) : [],
      )

  const deleteCategory = async (categoryId: string, version: string) =>
    api.deleteCategory({ categoryId }, { version }).then(processResponse)

  const getCategoryRestrictions = async ({ categoryId }: GetCategoryInfoOptions) =>
    api
      .getCategoryRestrictions({ categoryId })
      .then(response => (response.status === 200 ? response.body : []))

  return {
    getCategoryInfo,
    fetchCategoryPath,
    createCategory,
    updateCategory,
    getAttributeBindings,
    getAllCategories,
    getDirectFitments,
    deleteCategory,
    getCategoryRestrictions,
  }
}
