// @flow

import React from 'react'
import styled from '@emotion/styled'
import { H5, Flex, Container, Row, Col, Slider, FormField, Box, ControlGroup, HR } from '@r1/ui-kit'
import { DeviationLineCharts } from '../DeviationLineCharts'

const GreyH5 = styled(H5)`
  color: ${({ theme }) => theme.palette.grey[900]};
`

const SliderContainer = styled('div')`
  margin-top: 15px;
`

type OutliersMethodProps = {|
  name: string,
  lowLevelPrice: number,
  lowDeviationPercent: number,
  highLevelPrice: number,
  highDeviationPercent: number,
|}

export const OutliersMethod = (props: OutliersMethodProps) => {
  const { name, lowLevelPrice, lowDeviationPercent, highLevelPrice, highDeviationPercent } = props

  return (
    <Flex my="XL">
      <Container>
        <Row>
          <Col md={6}>
            <Flex column align="center">
              <Box maxWidth={260}>
                <GreyH5>
                  {name === 'DISCARD_ENTIRE_PRICE_RULE' &&
                    'Do not calculate price if deviation level is higher than, %'}

                  {name === 'DISCARD_INDIVIDUAL_PRICES' &&
                    'Exclude prices from calculation with deviation from mean higher than, %'}
                </GreyH5>
              </Box>

              <DeviationLineCharts
                lowLevel={lowLevelPrice}
                lowDeviation={lowDeviationPercent}
                hiLevel={highLevelPrice}
                hiDeviation={highDeviationPercent}
              />
            </Flex>
          </Col>

          <Col md={6}>
            <Flex column spaceBetween="L" pt="XXL">
              <Flex spaceBetween="XXS">
                <FormField.Label>Price</FormField.Label>
                <b>Maximum Deviation</b>
              </Flex>
              <HR />
              <Flex spaceBetween="L">
                <Box>
                  <ControlGroup disabled>
                    <ControlGroup.Label>$</ControlGroup.Label>
                    <ControlGroup.NumericInput
                      value={lowLevelPrice}
                      width={90}
                      onChange={() => {}}
                    />
                  </ControlGroup>
                </Box>
                <Box>
                  <Flex column spaceBetween="L" minWidth={295}>
                    <SliderContainer>
                      <Slider
                        disabled
                        value={lowDeviationPercent}
                        renderLabel={value => `${value} % `}
                      />
                    </SliderContainer>
                  </Flex>
                </Box>
              </Flex>
              <Flex spaceBetween="L">
                <Box>
                  <ControlGroup disabled>
                    <ControlGroup.Label>$</ControlGroup.Label>
                    <ControlGroup.NumericInput
                      value={highLevelPrice}
                      width={90}
                      onChange={() => {}}
                    />
                  </ControlGroup>
                </Box>
                <Box>
                  <Flex column spaceBetween="L" minWidth={295}>
                    <SliderContainer>
                      <Slider
                        disabled
                        value={highDeviationPercent}
                        renderLabel={value => `${value} % `}
                      />
                    </SliderContainer>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Container>
    </Flex>
  )
}
