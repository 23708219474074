import * as React from 'react'
import styled from '@emotion/styled'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { pick } from 'ramda'
import { NotificationSystem, Button, Flex, Collapse } from '@r1/ui-kit'
import { selectors, actions } from '../../modules/products'
import {
  MainInfo,
  ImportedProductDetails,
  ReceivingVendorInfo,
  Classifications,
  ProductRelations,
} from './blocks'

const Container = styled('form')`
  padding-bottom: 60px;
`

const Footer = styled('div')`
  position: sticky;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
`

const mapStateToProps = (state, { id }) => {
  const product = selectors.getItem(state, { id })
  return {
    initialValues: product && {
      ...pick(['slp', 'serialNumber', 'internalNote', 'vendorInfo'], product),
      upcInfo: pick(['templateIdentifierValue', 'productTemplateId'], product),
    },
  }
}

const enhance = compose(
  connect(mapStateToProps, { saveProductCard: actions.saveModel }),
  reduxForm({
    form: 'productCardForm',
    onSubmitFail: ({ _error: message }) =>
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Error',
        message,
      }),
    onSubmitSuccess: () =>
      NotificationSystem.addNotification({
        level: 'success',
        title: 'Success',
        message: 'Product was successfully updated',
      }),
  }),
)

class ProductCardForm extends React.Component {
  state = {
    activeSections: [
      'mainInfo',
      'classifications',
      'importedProductDetails',
      'receivingVendorInfo',
      'productRelations',
    ],
  }

  saveProductCard = formData => {
    const { saveProductCard, id } = this.props
    const classifications = this.classificationBlock.getClassifications()
    return saveProductCard(
      {
        classifications,
        ...formData,
      },
      id,
    )
  }

  setClassificationBlock = classificationBlock => {
    this.classificationBlock = classificationBlock
  }

  onCancel = () => {
    window.history.back()
  }

  onActiveSectionsChange = activeSections => {
    this.setState(state => ({ ...state, activeSections }))
  }

  render() {
    const { id, handleSubmit, submitting, isEditAllowed } = this.props

    return (
      <Container>
        <Collapse active={this.state.activeSections} onChange={this.onActiveSectionsChange}>
          <Collapse.Panel title="Main info" id="mainInfo">
            <MainInfo productId={id} />
          </Collapse.Panel>
          <Collapse.Panel title="Classifications" id="classifications">
            <Classifications ref={this.setClassificationBlock} productId={id} />
          </Collapse.Panel>
          <Collapse.Panel title="Imported product details" id="importedProductDetails">
            <ImportedProductDetails productId={id} />
          </Collapse.Panel>
          <Collapse.Panel title="Receiving & vendor info" id="receivingVendorInfo">
            <ReceivingVendorInfo productId={id} />
          </Collapse.Panel>
          <Collapse.Panel title="Product Relations" id="productRelations">
            <ProductRelations productId={id} />
          </Collapse.Panel>
        </Collapse>
        <Footer>
          <Flex spaceBetween="M" justify="center">
            <Button
              loading={submitting}
              disabled={!isEditAllowed}
              onClick={handleSubmit(this.saveProductCard)}
            >
              SAVE
            </Button>
            <Button transparent onClick={this.onCancel}>
              CANCEL
            </Button>
          </Flex>
        </Footer>
      </Container>
    )
  }
}

export default enhance(ProductCardForm)
