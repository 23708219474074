import { createCollectionModule, mapReducers } from '../../utils'
import { createReducer } from '../../../redux/utils'
import { productTemplateDataSourceCategoriesApi } from '../../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, selectors, actions } = createCollectionModule({
  name: '@productCatalog/dataSources/categories',
  keyField: 'id',
  params: ['dataSourceId'],
  fetchAllFunc: ({ dataSourceId }, _api) =>
    productTemplateDataSourceCategoriesApi
      .getProductCategories({ id: dataSourceId })
      .then(({ body }) => body),
  restoreFunc: async ({ dataSourceId }, categoryId, _api) =>
    productTemplateDataSourceCategoriesApi
      .getProductCategoryParents({
        id: dataSourceId,
        categoryId,
      })
      .then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { selectors, actions }
