import * as React from 'react'
import { GenericIcon } from '@r1/core-blocks'
import { Icon } from '../../../components/Icon'

const venueProfileGenericIconMap = {
  providerIconWalmartMarketplace: '5c5ae0fc79169146d0466b39',
  providerIconDirectLiquidation: '5c5ae0fc79169146d0466b29',
  providerIconAmazon: '5c5ae0fc79169146d0466b23',
  providerIconBestBuy: '5c5ae0fc79169146d0466b26',
  providerIconEbay: '5c5ae0fc79169146d0466b2a',
  providerIconGroupon: '5c5ae0fc79169146d0466b2d',
  providerIconHomeDepot: '5c6423dd7916912fe42c8bb9',
  providerIconJet: '5c5ae0fc79169146d0466b2e',
  providerIconNewegg: '5c5ae0fc79169146d0466b2f',
  providerIconR1: 'providerIconR1',
  providerIconTheStore: 'providerIconTheStore',
  providerIconVipOutlet: '5c5ae0fc79169146d0466b37',
  providerIconWalmartCanada: '5c5bee8679169146d0466b3b',
  providerIconWalmartDsv: '5c5ae0fc79169146d0466b38',
  providerIconWish: '5c5ae0fc79169146d0466b3a',
  providerIconRiskified: '5c5ae0fc79169146d0466b31',
  providerIconSignifyd: '5c5ae0fc79169146d0466b32',
  providerIconAmazonPay: '5c5ae0fc79169146d0466b24',
  providerIconAuthorizeNet: '5c5ae0fc79169146d0466b25',
  providerIconBillcom: '5c5ae0fc79169146d0466b27',
  providerIconMerchantOne: '5c6ab8677916912cbcf912bd',
  providerIconPayPal: '5c5ae0fc79169146d0466b30',
  providerIconStripe: '5c5ae0fc79169146d0466b33',
  providerIconCanadaPost: '5c5ae0fc79169146d0466b28',
  providerIconFedex: '5c5ae0fc79169146d0466b2b',
  providerIconFreightQuote: '5c5ae0fc79169146d0466b2c',
  providerIconUPS: '5c5ae0fc79169146d0466b34',
  providerIconUSPS: '5c5ae0fc79169146d0466b36',
  providerIconAvalara: '5c6423dd7916912fe42c8bb8',
  providerIconBackmarket: '5c5bee8679169146d0466b3c',
}

export const VenueProfileIcon = ({ venueProfileType }) => {
  const venueProfileGenericIconId = venueProfileGenericIconMap[`providerIcon${venueProfileType}`]
  if (venueProfileGenericIconId) {
    return <GenericIcon svgId={venueProfileGenericIconId} width={60} />
  }

  return <Icon name="notFound" />
}
