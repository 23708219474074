import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GenericIcon } from '@r1/core-blocks'
import { Flex, Text, useConfigurationProvider } from '@r1/ui-kit'
import { ROUTES } from '../../../../navigation'
import { DEFAULT_ICON_ID, existedIconIds } from '../reports.const'
import { CardTitle } from './CardTitle'
import { ArrowIcon } from './ArrowIcon'
import { CardWithBackground } from './CardWithBackground'

type Props = {
  id: string
  name: string
  reportsCount: number
  description: string
}

export const ReportGroupCard = React.memo<Props>(({ id, name, reportsCount, description }) => {
  const navigate = useNavigate()
  const { palette } = useConfigurationProvider()

  const background = (
    <GenericIcon
      svgId={existedIconIds.includes(id) ? id : DEFAULT_ICON_ID}
      width={140}
      height={140}
    />
  )

  const onClick = () => {
    navigate(ROUTES.REPORTS_GROUP.replace(':reportGroupId', id))
  }

  return (
    <CardWithBackground background={background} onClick={onClick}>
      <Flex column spaceBetween="XS">
        <CardTitle>{name}</CardTitle>
        <Text weight="medium" color={palette.grey[700]}>
          {reportsCount} reports
        </Text>
        <Text color={palette.grey[600]}>{description}</Text>
      </Flex>

      <Flex flexWrap={false} align="center" spaceBetween="XXS">
        <Text weight="medium" color={palette.blue[500]}>
          Explore reports
        </Text>
        <ArrowIcon color={palette.blue[500]} />
      </Flex>
    </CardWithBackground>
  )
})
