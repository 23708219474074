import styled from '@emotion/styled'
import { Flex, Box } from '@r1/ui-kit'
import React, { Children } from 'react'

const CardBox = styled(Box)`
  padding-top: calc(${({ theme }) => theme.space.M} / 2);
  width: 33.333333%;
  max-width: 33.333333%;
`

export function CardsLayout({ children }: React.PropsWithChildren) {
  return (
    <Flex flexWrap spaceBetween="M" basis={1} pb="XXL">
      {Children.map(children, child => (
        <CardBox grow>{child}</CardBox>
      ))}
    </Flex>
  )
}
