// @flow

import * as React from 'react'
import { Loader, Format, Modal, Text, Flex } from '@r1/ui-kit'
import { Pagination, Table, calculateTextLinesCount } from '@r1/grid'
import { compareAsc, compareDesc, format } from 'date-fns'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { TitleCounter, LoaderContainer } from './StyledComponents'

// $FlowFixMe[cannot-resolve-module]
import { FilterSection } from './Filter/FilterSection.tsx'

type HistoryRecord = {|
  executedByUserName: string,
  executedOn: string,
  text: string,
  action: string,
|}

type ProductHistoryProps = {|
  show: boolean,
  title: string,
  onClose: () => void,
  fetchHistory: () => HistoryRecord[],
  dataTestId: string,
|}

type Filter = {|
  [key: string]: (historyRecord: HistoryRecord) => boolean,
|}

const INITIAL_ROW_HEIGHT = 'short'

const clearTags = (text: string) => text.replace(/<[^>]+>/g, '')

const getTableSettings = rowHeight => ({
  columnsSettings: [
    {
      header: { content: 'User' },
      cell: {
        $type: 'text',
        field: 'executedByUserName',
      },
      width: 200,
    },
    {
      header: {
        content: 'Description',
      },
      cell: {
        $type: 'custom',
        renderer: ({ text }) => (
          <Text
            maxLines={calculateTextLinesCount(rowHeight)}
            tooltipTextPlacement="top"
            overflow="ellipsis"
          >
            {clearTags(text)}
          </Text>
        ),
      },
      width: 520,
    },
    {
      header: {
        content: 'Date',
        sorting: {
          field: 'executedOn',
          type: 'asc',
        },
      },
      cell: {
        $type: 'custom',
        renderer: ({ executedOn }) => (
          <Text>{format(new Date(executedOn), 'MM/dd/yyyy, hh:mm a')}</Text>
        ),
      },
      width: 168,
    },
  ],
  rowSettings: {
    rowHeight,
  },
})

const compareByDate = (productHistoryItemA, productHistoryItemB, orderDirection) => {
  if (orderDirection === 'asc')
    return compareAsc(
      new Date(productHistoryItemA.executedOn),
      new Date(productHistoryItemB.executedOn),
    )
  return compareDesc(
    new Date(productHistoryItemA.executedOn),
    new Date(productHistoryItemB.executedOn),
  )
}

const getPartialData = (query, data) => {
  const startIndex = (query.page - 1) * query.pageSize
  const endIndex = query.pageSize + startIndex

  return data.slice(startIndex, endIndex)
}

const initialState = {
  loading: true,
  data: [],
  partialData: [],
  totalCount: 0,
  query: {
    page: 1,
    pageSize: 10,
  },
}

export const ProductHistoryModal = ({
  show,
  onClose,
  fetchHistory,
  title,
  dataTestId,
}: ProductHistoryProps) => {
  const [state, setState] = React.useState(initialState)
  const [initialData, setInitialData] = React.useState<HistoryRecord[]>([])
  const [displaySettings, setDisplaySettings] = React.useState(getTableSettings(INITIAL_ROW_HEIGHT))
  const hasItems = Boolean(state.partialData.length)

  const [filters, setFilters] = React.useState<Filter>({})

  const handleFilterChange = (filterId, predicate) => {
    const predicatesFilters = filters
    if (predicate == null) {
      delete predicatesFilters[filterId]
    } else {
      predicatesFilters[filterId] = predicate
    }
    const data = initialData.filter(historyRecord =>
      // eslint-disable-next-line flowtype/no-weak-types
      Object.values(predicatesFilters).every((predicatesFilter: any) =>
        predicatesFilter(historyRecord),
      ),
    )
    setFilters(predicatesFilters)
    setState({
      ...initialState,
      data,
      totalCount: data.length,
      partialData: getPartialData(initialState.query, data),
      loading: false,
    })
  }

  const modalActionButtons: BottomActionButton[] = React.useMemo(
    () => [
      {
        title: 'Close',
        onClick: onClose,
        align: 'right',
      },
    ],
    [onClose],
  )

  const fetchData = React.useCallback(async () => {
    setState(initialState)
    const data = await fetchHistory()

    setInitialData(data)
    setState(prevState => {
      const partialData = getPartialData(prevState.query, data)

      return {
        ...prevState,
        data,
        partialData,
        totalCount: data.length,
        loading: false,
      }
    })
  }, [fetchHistory])

  React.useEffect(() => {
    if (show) {
      fetchData()
    }
  }, [fetchData, show])

  const onChangeSorting = React.useCallback(
    ({ field, type }) => {
      setState(prevState => ({
        ...prevState,
        partialData: prevState.partialData.slice().sort((i1, i2) => compareByDate(i1, i2, type)),
      }))
      const columnsSettings = displaySettings.columnsSettings.map(x => {
        if (x.header.sorting && x.header.sorting.field === 'executedOn') {
          return {
            ...x,
            header: {
              ...x.header,
              sorting: {
                type,
                field,
              },
            },
          }
        }
        return x
      })

      setDisplaySettings({ ...displaySettings, columnsSettings })
    },
    [displaySettings],
  )

  const onChangePagination = React.useCallback(
    ({ page, pageSize }) => {
      if (state.query.page !== page || state.query.pageSize !== pageSize) {
        const partialData = getPartialData({ page, pageSize }, state.data)
        setState(prevState => ({
          ...prevState,
          query: { ...prevState.query, page, pageSize },
          partialData,
        }))
      }
    },
    [state.data, state.query],
  )

  const onChangeRowHeight = React.useCallback(newRowHeight => {
    setDisplaySettings(getTableSettings(newRowHeight))
  }, [])

  return (
    <Modal
      isControlled
      title={title}
      size="L"
      show={show}
      actionButtons={modalActionButtons}
      data-test-id={dataTestId}
      onEscapeKeyDown={onClose}
    >
      <FilterSection onFilterChange={handleFilterChange} />
      <Flex mb="L" spaceBetween="XS" align="center">
        <Text>All records</Text>
        <TitleCounter align="center" px="XXS">
          {state.loading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Text type="data">
              <Format.Number minFractionDigits={0}>{state.totalCount}</Format.Number>
            </Text>
          )}
        </TitleCounter>
      </Flex>

      <Pagination
        loading={state.loading}
        page={state.query.page}
        pageSize={state.query.pageSize}
        rowCount={state.totalCount}
        rowHeight={displaySettings.rowSettings.rowHeight}
        hasItems={hasItems}
        onChange={onChangePagination}
        onChangeRowHeight={onChangeRowHeight}
      >
        <Table
          resizable
          height={400}
          loading={state.loading}
          data={state.partialData}
          suppressVirtualization={false}
          displaySettings={displaySettings}
          onChangeSorting={onChangeSorting}
        />
      </Pagination>
    </Modal>
  )
}
