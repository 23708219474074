/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Tooltip, Icon, Flex, FormField, Box, Input, Button, Select } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { getInputProps } from '@rjsf/utils'

const Asterisk = styled('span')`
  &:before {
    content: '*';
    color: ${({ theme: { palette } }) => palette.red[800]};
  }
`

const Section = styled('div')`
  ${({ simple, theme }) =>
    !simple &&
    `
    margin-left: 2px;
    padding-left: 12px;
    border-left: 2px solid ${theme.palette.grey[300]};
`}
`

export const FieldTemplate = ({ label, required, description, errors, children }) => (
  <Flex column basis={1} mb="M">
    <FormField>
      <FormField.Label>
        <Flex>
          {description && description.props.description && (
            <Tooltip text={description.props.description}>
              <Icon type="internal/notification/info" size="XS" cursor="pointer" />
            </Tooltip>
          )}
          <Box ml="XS" mr="XXXS">
            {label}
          </Box>
          {required ? <Asterisk /> : null}
        </Flex>
      </FormField.Label>

      {children}
      {errors.props.errors && (
        <FormField.Description>{errors.props.errors.join('; ')}</FormField.Description>
      )}
    </FormField>
  </Flex>
)

export const BaseInputTemplate = ({
  id,
  schema,
  options,
  value,
  type,
  placeholder,
  disabled,
  readonly,
  autofocus,
  onChange,
  onBlur,
  onFocus,
  rawErrors,
  hideError,
  required,
  ...rest
}) => {
  const handleChange = val => {
    onChange(val === '' ? undefined : val)
  }
  const handleBlur = ({ target: { value: val } }) => onBlur(id, val)
  const handleFocus = ({ target: { value: val } }) => onFocus(id, val)

  const inputProps = { ...rest, ...getInputProps(schema, type, options) }
  const hasError = required && rawErrors && rawErrors.length > 0 && !hideError

  return (
    <Input
      value={value ?? ''}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readonly}
      error={hasError}
      autoFocus={autofocus}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      {...inputProps}
    />
  )
}

export const ArrayFieldTemplate = ({ items, canAdd, onAddClick }) => {
  const renderItem = ({ key, index, hasRemove, onDropIndexClick, schema, children }) => {
    if (schema.enum) {
      return (
        <Flex key={key} align="center" mb="S" spaceBetween="XS">
          <Select
            {...children.props}
            simpleValue
            value={children.props.formData ?? null}
            options={schema.enum.map(opt => ({ id: opt, name: opt }))}
            onChange={val => {
              children.props.onChange(val, children.props.errorSchema, children.props.idSchema.$id)
            }}
          />
          {hasRemove && (
            <Button color="secondary" size="M" onClick={onDropIndexClick(index)}>
              Remove
            </Button>
          )}
        </Flex>
      )
    }

    return (
      <Flex key={key} column>
        <Box>{children}</Box>
        {hasRemove && (
          <div style={{ margin: '-8px 0 12px' }}>
            <Button color="secondary" size="S" onClick={onDropIndexClick(index)}>
              Remove
            </Button>
          </div>
        )}
      </Flex>
    )
  }

  return (
    <Section>
      {items.map(renderItem)}
      {canAdd && (
        <div>
          <Button color="light" size="M" onClick={onAddClick}>
            Add
          </Button>
        </div>
      )}
    </Section>
  )
}

export const ObjectFieldTemplate = ({ title, properties }) => (
  <Section simple={!title}>{properties.map(element => element.content)}</Section>
)
