// @flow

import {
  createFamilyManagementApi,
  baseUri as familyManagementBaseUri,
} from '@r1-webui/productfamilies-familymanagement-v1'

import {
  createFamiliesApi,
  baseUri as familiesBaseUri,
} from '@r1-webui/productfamilies-families-public-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const variationsApi = (httpClient: any): WebUiApi => {
  const {
    getFamily,
    createFamily,
    deleteFamily,
    updateFamily,
    generateFamilyHistoryReceivingToken,
    getProducts,
    calculateProducts,
  } = createFamilyManagementApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${familyManagementBaseUri}`,
  })

  const { getAllFamilies } = createFamiliesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${familiesBaseUri}`,
  })

  return {
    getFamily,
    createFamily,
    deleteFamily,
    updateFamily,
    generateFamilyHistoryReceivingToken,
    getProducts,
    calculateProducts,
    getAllFamilies,
  }
}
