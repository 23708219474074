// @flow

import * as React from 'react'
import { Flex, NotificationSystem, Select } from '@r1/ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { compareAsc, compareDesc } from 'date-fns'
import { handleServerError } from '@r1/core-blocks'
import type { ClaimMessage, CurrentUser } from '../../types/ClaimMessage'
import type { VendorPortalApi } from '../../api/api'
import { MessageLog } from './MessageLog'
import { MessageForm } from './MessageForm'
import { AddMessageButton } from './AddMessageButton'

declare type Props = {|
  claimId: string,
  messageLog: ClaimMessage[],
  setMessageLog: (messages: ClaimMessage[]) => void,
  isLoading: boolean,
  api: VendorPortalApi,
|}

declare type SortingOptions = {|
  direction: 'asc' | 'desc',
  text: string,
|}

const sortingOptions: SortingOptions[] = [
  { direction: 'asc', text: 'Ascending' },
  { direction: 'desc', text: 'Descending' },
]

declare type UserOptions = {|
  userType: 'all' | 'user' | 'system',
  text: string,
|}

const showByUserOptions: UserOptions[] = [
  { userType: 'all', text: 'All' },
  { userType: 'user', text: 'User' },
  { userType: 'system', text: 'System' },
]

export const ClaimMessages = (props: Props) => {
  const { claimId, messageLog, setMessageLog, isLoading, api } = props
  const { users: usersApi, messages: messagesApi } = api

  const [showMessageForm, setShowMessageForm] = useState(false)
  const [sorting, setSorting] = useState(sortingOptions[0].direction)
  const [showByUserOption, setShowByUserOption] = useState(showByUserOptions[0].userType)
  const [currentUser, setCurrentUser] = useState<CurrentUser>({ id: '', name: '' })

  useEffect(() => {
    async function fetchUser() {
      const response = await usersApi.getCurrentUser()

      if (response.status !== 200) {
        handleServerError(response)
        return
      }
      const { id, info } = response.body ?? {}
      const name = info.fullName
      setCurrentUser({ id, name })
    }

    fetchUser()
  }, [usersApi])

  const updateMessage = useCallback(
    async (id: string, text: string) => {
      const resp = await messagesApi.updateMessage(
        { claimId, messageId: id },
        {
          text,
          isPrivate: false,
          userId: currentUser.id,
          userName: currentUser.name,
        },
      )
      if (resp.status === 200) {
        const changedMessage = messageLog.find(msg => msg.id === id)
        if (changedMessage) changedMessage.text = text
        setMessageLog(messageLog)
        NotificationSystem.addNotification({ title: 'Message saved', level: 'success' })
      } else {
        NotificationSystem.addNotification({
          title: 'Cannot save message. Please, try again later',
          level: 'error',
        })
      }
    },
    [messagesApi, claimId, currentUser.id, currentUser.name, messageLog, setMessageLog],
  )

  const addMessage = useCallback(
    async (text: string) => {
      const currentDate = new Date()
      const payload = {
        text,
        messageOn: currentDate.toISOString(),
        isPrivate: false,
        userId: currentUser.id,
        userName: currentUser.name,
      }
      const resp = await messagesApi.createMessage({ claimId }, payload)
      if (resp.status === 200) {
        setMessageLog([
          ...messageLog,
          {
            claimId,
            id: resp.body.messageId,
            createdOn: currentDate.toISOString(),
            ...payload,
          },
        ])
        NotificationSystem.addNotification({ title: 'Message added', level: 'success' })
      } else {
        NotificationSystem.addNotification({
          title: 'Cannot add message. Please, try again later',
          level: 'error',
        })
      }
    },
    [currentUser.id, currentUser.name, messagesApi, claimId, setMessageLog, messageLog],
  )

  const onAddMessageButtonClick = () => {
    setShowMessageForm(true)
  }

  const filteredSortedData = useMemo(() => {
    let data = messageLog
    if (sorting)
      data.sort((msg1, msg2) => {
        if (sorting === 'asc') return compareAsc(new Date(msg1.messageOn), new Date(msg2.messageOn))
        return compareDesc(new Date(msg1.messageOn), new Date(msg2.messageOn))
      })
    switch (showByUserOption) {
      case 'user':
        data = messageLog.filter(msg => !!msg.userId)
        break
      case 'system':
        data = messageLog.filter(msg => !msg.userId)
        break
      default:
        break
    }
    return data
  }, [showByUserOption, messageLog, sorting])

  return (
    <Flex column align="stretch" spaceBetween="M">
      <Flex justify="space-between">
        <Flex align="center" spaceBetween="M">
          <div>Sort by date:</div>
          <Select
            width={160}
            clearable={false}
            options={sortingOptions}
            value={sorting}
            valueKey="direction"
            labelKey="text"
            onChange={direction => {
              setSorting(direction)
            }}
          />
        </Flex>
        <Flex align="center" spaceBetween="M">
          <div>Filter by user:</div>
          <Select
            simpleValue
            clearable={false}
            width={100}
            value={showByUserOption}
            options={showByUserOptions}
            valueKey="userType"
            labelKey="text"
            onChange={userType => {
              setShowByUserOption(userType)
            }}
          />
        </Flex>
      </Flex>
      {sorting === 'desc' &&
        ((showMessageForm && (
          <MessageForm
            onCancel={() => setShowMessageForm(false)}
            onSaveMessage={async text => {
              await addMessage(text)
              setShowMessageForm(false)
            }}
          />
        )) || <AddMessageButton onClick={onAddMessageButtonClick} />)}
      <MessageLog
        currentUser={currentUser}
        isLoading={isLoading}
        messages={filteredSortedData}
        onUpdateMessage={updateMessage}
      />
      {sorting === 'asc' &&
        ((showMessageForm && (
          <MessageForm
            onCancel={() => setShowMessageForm(false)}
            onSaveMessage={async text => {
              await addMessage(text)
              setShowMessageForm(false)
            }}
          />
        )) || <AddMessageButton onClick={onAddMessageButtonClick} />)}
    </Flex>
  )
}
