/* eslint-disable no-param-reassign */
// @flow

import type { EnumValueArray } from '../types'

/**
 * all transform enumValues should be here
 * Now:
 * 1) transform all decimal value for removing excess zero
 * 2) sorting array (string in alphabetical order, number asc)
 */

export const transformEnumValues = (enumValues: EnumValueArray): EnumValueArray => {
  if (enumValues.valueType === 'DecimalEnumArray' && enumValues.enumValues) {
    enumValues.enumValues = enumValues.enumValues
      .sort((a, b) => (a.value < b.value ? -1 : 1))
      .map(val => ({
        ...val,
        value: parseFloat(val.value).toString(),
      }))
  }

  if (enumValues.valueType === 'IntEnumArray' && enumValues.enumValues) {
    enumValues.enumValues = enumValues.enumValues.sort((a, b) => (a.value < b.value ? -1 : 1))
  }

  if (enumValues.valueType === 'StringEnumArray' && enumValues.enumValues) {
    enumValues.enumValues = enumValues.enumValues.sort((a, b) => a.value.localeCompare(b.value))
  }

  return enumValues
}
