import React from 'react'
import { format } from 'date-fns'
import { Text } from '@r1/ui-kit'
import { DepositEvent } from '@r1-webui/gowholesale-depositmanagement-v1'
import { DisplaySettings } from '@r1/grid'
import { DATE_FORMAT, depositHistoryGridMeta } from './const'
import { DepositHistoryGridColumn } from './types'

const getDepositEventMessage = ({ eventType, createdBy }: DepositEvent): string => {
  switch (eventType.type) {
    case 'DepositCreated':
      return `The deposit created at $${eventType.price.amount}.`
    case 'DepositCaptured':
      return 'The deposit is captured.'
    case 'DepositClosedAfterFailing':
      return `The deposit is closed by ${eventType.closedBy}. The penalty removed from the customer.`
    case 'DepositClosed':
      if (createdBy === 'R1') {
        return `The deposit is closed and automatically refunded.`
      }
      return `The deposit is closed and refunded by ${eventType.closedBy}.`
    case 'DepositFailedCapture':
      return 'Failed to capture the deposit. Penalty is assigned to the customer. The customer can’t make purchases until he pays the penalty.'
    default:
      return 'Unknown event'
  }
}

const getGridCellRenderer = (
  column: DepositHistoryGridColumn,
): ((dataItem: DepositEvent, rowIndex: number) => React.ReactNode) => {
  switch (column) {
    case DepositHistoryGridColumn.CompanyName:
      return depositEvent => <Text>{depositEvent.createdBy}</Text>
    case DepositHistoryGridColumn.HistoryEvent:
      return depositEvent => <Text>{getDepositEventMessage(depositEvent)}</Text>
    case DepositHistoryGridColumn.Date:
      return deposit => <Text>{format(new Date(deposit.createdOn), DATE_FORMAT)}</Text>
    default:
      return () => null
  }
}

export const getGridDisplaySettings = (): DisplaySettings<DepositEvent> => {
  return {
    showCounter: false,
    columnsSettings: [
      ...Array.from(depositHistoryGridMeta).map(([column, settings]) => {
        return {
          header: { content: <span>{settings.header}</span> },
          cell: {
            $type: 'custom' as const,
            renderer: getGridCellRenderer(column),
          },
          width: settings.width,
        }
      }),
    ],
  }
}
