// @flow

import * as React from 'react'
import { ContentHeader, Main, Content, SiteMap } from '@r1/wireframe-primary'

export const SiteMapScreen = () => {
  return (
    <Main>
      <ContentHeader>
        <ContentHeader.Title>R1 Site Map</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <SiteMap />
      </Content>
    </Main>
  )
}
