// @flow

import type { ExportApi } from '../../components/Export/types'
import type { Options } from '../errorHandler'
import { createErrorHandler } from '../errorHandler'

const baseURL = '/uisvc/tcr'

export function createApi(
  httpClient: { [string]: Function },
  { onError, onNotify }: Options,
): ExportApi {
  const ordersPrefix = `${baseURL}/api/v1/purchase-orders-grid/exports`
  const eventsPrefix = `${baseURL}/api/v1/financial-events-grid/exports`

  const errorHandler = createErrorHandler({ onError, onNotify })

  return {
    startPurchaseOrderExport(data) {
      return httpClient.post(ordersPrefix, data).catch(errorHandler)
    },

    getPurchaseOrderExport(id) {
      return httpClient.get(`${ordersPrefix}/${id}`).catch(errorHandler)
    },

    getPurchaseOrderExportStatus(id) {
      return httpClient.get(`${ordersPrefix}/${id}/status`).catch(errorHandler)
    },

    startFinancialEventsExport(data) {
      return httpClient.post(eventsPrefix, data).catch(errorHandler)
    },

    getFinancialEventsExport(id) {
      return httpClient.get(`${eventsPrefix}/${id}`).catch(errorHandler)
    },

    getFinancialEventsExportStatus(id) {
      return httpClient.get(`${eventsPrefix}/${id}/status`).catch(errorHandler)
    },
  }
}
