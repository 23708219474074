import * as React from 'react'
import styled from '@emotion/styled'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Drawer, Flex, Box, Placeholder, BottomButtonsBar, Icon } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { MasterProgramRule } from '@r1-webui/masterprograms-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { MasterProgramForm } from '../MasterProgramForm'
import { updateMasterProgramRule } from '../../api/updateMasterProgramRule'
import { createMasterProgramRule } from '../../api/createMasterProgramRule'
import { useMasterProgramRule } from '../../hooks/useMasterProgramRule'
import { schema } from '../../schemas/MasterProgramForm.schema'
import { getEmptyMasterProgram } from '../../utils/getEmptyMasterProgram'
import { MasterProgramModel } from '../../types'
import { processMasterProgramRuleToApi } from '../../utils/processMasterProgramRule'
import { masterProgramSettings } from './constants'

const DrawerContainer = styled(Flex)`
  [data-test-id='condition-select__container'] {
    display: none;
  }
`

const ActionButton = styled(Box)`
  cursor: pointer;
  align-items: center;
`

const ActionsBar = ({
  masterProgramId,
  openRuleHistory,
}: {
  masterProgramId?: string | null
  openRuleHistory: () => void
}) => {
  if (!masterProgramId) {
    return null
  }

  return (
    <ActionButton>
      <Icon type="history" onClick={openRuleHistory} />
    </ActionButton>
  )
}

export const MasterProgramDrawer = React.memo(
  ({
    isOpen,
    masterProgramId,
    onOpenHistoryDrawer,
    onClose,
    onSubmit,
  }: {
    isOpen: boolean
    masterProgramId?: string | null
    onOpenHistoryDrawer: (ruleId: string) => void
    onClose: () => void
    onSubmit: () => void
  }) => {
    const [{ allowMasterProgramEdit }] = useAccessControl()
    const [loading, setLoading] = React.useState(false)

    const form = useForm<MasterProgramModel>({
      mode: 'onTouched',
      resolver: zodResolver(schema),
      defaultValues: getEmptyMasterProgram(),
    })

    const handleClose = React.useCallback(() => {
      onClose()
      form.reset(getEmptyMasterProgram())
    }, [onClose, form])

    const { masterProgram, loading: isLoadingMasterProgram } = useMasterProgramRule({
      masterProgramId,
      onError: handleClose,
    })

    const disabled = React.useMemo(() => masterProgram?.deleted ?? false, [masterProgram])

    React.useEffect(() => {
      if (masterProgram && masterProgramSettings.filters.length) {
        form.reset(masterProgram)
      }
    }, [masterProgram, form])

    const isEditMode = Boolean(masterProgramId)
    const title = isEditMode ? masterProgram?.name : 'Create Master Program Rule'

    const putMasterProgramRuleChanges = (processedData: MasterProgramRule): Promise<boolean> => {
      if (masterProgramId) {
        return updateMasterProgramRule(masterProgramId, processedData)
      }
      return createMasterProgramRule(processedData)
    }

    const handleSubmit = async () => {
      await form.handleSubmit(async data => {
        setLoading(true)
        const processedData = processMasterProgramRuleToApi(data)
        const isSucceeded = await putMasterProgramRuleChanges(processedData)
        setLoading(false)

        if (isSucceeded) {
          handleClose()
          onSubmit()
        }
      })()
    }

    const openHistoryDrawer = React.useCallback(() => {
      if (masterProgramId) {
        onOpenHistoryDrawer(masterProgramId)
      }
    }, [onOpenHistoryDrawer, masterProgramId])

    const bottomActionButtons: ControlledActionButtons = [
      {
        title: 'Cancel',
        align: 'right',
        color: 'secondary',
        onClick: handleClose,
      },
      {
        title: isEditMode ? 'Save' : 'Create',
        align: 'right',
        loading,
        disabled: disabled || !allowMasterProgramEdit || loading,
        onClick: handleSubmit,
      },
    ]

    return (
      <Drawer.Form
        closeButton
        show={isOpen}
        backdrop="closing"
        size={1056}
        placement="right"
        title={title}
        loading={isLoadingMasterProgram}
        actionsBar={
          <ActionsBar masterProgramId={masterProgramId} openRuleHistory={openHistoryDrawer} />
        }
        footer={<BottomButtonsBar bottomActionButtons={bottomActionButtons} />}
        onClose={handleClose}
      >
        {isLoadingMasterProgram ? (
          <Placeholder type="form" height={10} />
        ) : (
          <DrawerContainer column minWidth={1}>
            <FormProvider {...form}>
              <MasterProgramForm masterProgram={masterProgram} disabled={disabled} />
            </FormProvider>
          </DrawerContainer>
        )}
      </Drawer.Form>
    )
  },
)
