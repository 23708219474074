import { Report, ReportSection } from '@r1-webui/reports-v1'
import React from 'react'
import { parse } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { defaultReportSection } from './reports.const'

type ReportSectionView = {
  section: ReportSection
  reports: Report[]
}

export function useReportSectionViews({
  reports,
  sections,
}: {
  reports: Report[]
  sections: ReportSection[]
}) {
  const reportSectionViews: ReportSectionView[] = React.useMemo(() => {
    const sectionIdViewMap: Record<string, ReportSectionView> = {}

    sectionIdViewMap[defaultReportSection.id] = { section: defaultReportSection, reports: [] }
    sections.forEach(section => {
      sectionIdViewMap[section.id] = { section, reports: [] }
    })

    reports.forEach(report => {
      const sectionReports = sectionIdViewMap[report.sectionId || defaultReportSection.id]
      sectionReports?.reports.push(report)
    })

    return Object.values(sectionIdViewMap)
  }, [reports, sections])

  return reportSectionViews
}

// dateString example: 7/17/2023 17:45:25
export function formatLastRefreshDate(dateString: string): string {
  try {
    const date = parse(dateString, 'M/d/yyyy H:m:s', new Date())
    return `${formatInTimeZone(date, 'EST', 'MM/dd/yyyy hh:mm a')}`
  } catch {
    return ''
  }
}
