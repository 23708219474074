// @flow

import React, { useMemo } from 'react'
import { Modal, Text } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

type Props = {
  show: boolean,
  message: string,
  completing: boolean,
  onConfirm: () => void,
  onCancel: () => void,
}

export const ConfirmationModal = ({ show, message, completing, onConfirm, onCancel }: Props) => {
  const modalActionButtons: BottomActionButton[] = useMemo(() => {
    const buttons = [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: onCancel,
        align: 'right',
        disabled: completing,
      },
    ]
    buttons.push({
      title: 'Confirm',
      color: 'primary',
      onClick: onConfirm,
      loading: completing,
      align: 'right',
    })
    return buttons
  }, [message, completing, onCancel, onConfirm])

  return (
    <Modal
      isControlled
      show={show}
      title="Confirmation"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onCancel}
    >
      <Text type="paragraph">{message}</Text>
    </Modal>
  )
}
