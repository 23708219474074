// @flow

import {
  createClassificationsApi,
  createMarketplaceConditionsApi,
  createProfileConditionMappingsApi,
} from '@r1-webui/profileconditionmappings-v1'
import { httpClient } from '../../../../httpClient'

const API_PREFIX = `/uisvc/profile-condition-mappings`

export const classificationsApi = createClassificationsApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const marketplaceConditionsApi = createMarketplaceConditionsApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const profileConditionMappingsApi = createProfileConditionMappingsApi({
  http: httpClient,
  urlPrefix: API_PREFIX,
})
