/* eslint-disable flowtype/no-types-missing-file-annotation */

import * as React from 'react'
import styled from '@emotion/styled'

import { Table } from '@r1/grid'

import { MoneyCell } from '../Cells/MoneyCell'
import { TextCell } from '../Cells/TextCell'

const columnsSettings = [
  {
    width: 150,
    header: {
      content: 'Marketplace profile',
    },
    cell: {
      $type: 'text',
      field: 'venueProfileName',
    },
  },
  {
    width: 100,
    header: {
      content: 'PO #',
    },
    cell: TextCell('purchaseOrderId'),
  },
  {
    width: 100,
    header: {
      content: 'Financial event type',
    },
    cell: {
      $type: 'text',
      field: 'type',
    },
  },
  {
    width: 535,
    header: {
      content: 'Line title',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <span>{dataItem.purchaseOrderLine && dataItem.purchaseOrderLine.title}</span>
      ),
    },
  },
  {
    width: 100,

    header: {
      content: 'Quantity',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <span>{dataItem.purchaseOrderLine && dataItem.purchaseOrderLine.quantity}</span>
      ),
    },
  },
  {
    width: 110,

    header: {
      content: 'Principal amount',
    },
    cell: MoneyCell('principal'),
  },
  {
    width: 110,

    header: {
      content: 'Sales tax amount',
    },
    cell: MoneyCell('tax'),
  },
  {
    width: 110,

    header: {
      content: 'Sales tax withheld',
    },
    cell: MoneyCell('taxWithheld'),
  },
  {
    width: 110,

    header: {
      content: 'Shipping cost',
    },
    cell: MoneyCell('shippingCost'),
  },
  {
    width: 110,

    header: {
      content: 'Shipping tax amount',
    },
    cell: MoneyCell('shippingTax'),
  },
  {
    width: 110,

    header: {
      content: 'Shipping tax withheld',
    },
    cell: MoneyCell('shippingTaxWithheld'),
  },
  {
    width: 110,

    header: {
      content: 'Totals',
    },
    cell: MoneyCell('total'),
  },
]

const tableProps = {
  displaySettings: {
    showCounter: false,
    columnsSettings,
  },
}
const Container = styled('div')`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  width: 100%;
  min-height: 400px;
`
export function FinancialEventsGrid(props) {
  return (
    <Container>
      <Table {...tableProps} {...props} />
    </Container>
  )
}
