import { SubStamp } from '@r1-webui/masterprograms-v1'
import { handleServerError } from '@r1/core-blocks'
import { showNotification } from '../../../utils/notifications/showNotification'
import { subStampsApi } from '../api/MasterProgram'

export const createSubStamp = async (name: string): Promise<string | null> => {
  const response = await subStampsApi.createSubStamp({ name })

  if (response.status !== 200) {
    handleServerError(response)
    return null
  }

  showNotification({
    level: 'success',
    message: `Substamp ${name} successfully created`,
  })

  return response.body.subStampId
}

export const deleteSubStamp = async (substampId: string): Promise<boolean> => {
  const response = await subStampsApi.deleteSubStamp({ substampId })

  if (response.status !== 200) {
    handleServerError(response)
    return false
  }

  showNotification({
    level: 'success',
    message: `Substamp successfully deleted `,
  })

  return true
}

export const getActiveSubStamps = async (): Promise<SubStamp[]> => {
  const response = await subStampsApi.getActiveSubStamps()

  if (response.status !== 200) {
    handleServerError(response)
    return []
  }

  return response.body
}

export const updateSubStamp = async (substampId: string, name: string): Promise<boolean> => {
  const response = await subStampsApi.updateSubStamp({ substampId }, { name })

  if (response.status !== 200) {
    handleServerError(response)
    return false
  }

  showNotification({
    level: 'success',
    message: `Substamp successfully updated`,
  })

  return true
}

export const addSubStampValue = async (
  substampId: string,
  subStampName: string,
  value: string,
): Promise<string | null> => {
  const response = await subStampsApi.createSubStampValue({ substampId }, { name: value })

  if (response.status !== 200) {
    handleServerError(response)
    return null
  }

  showNotification({
    level: 'success',
    message: `Value ${value} successfully added to Substamp ${subStampName}`,
  })

  return response.body.subStampValueId
}

export const updateSubStampValue = async (
  substampId: string,
  valueId: string,
  value: string,
): Promise<boolean> => {
  const response = await subStampsApi.updateSubStampValue(
    { substampId, subStampValueId: valueId },
    { name: value },
  )

  if (response.status !== 200) {
    handleServerError(response)
    return false
  }

  showNotification({
    level: 'success',
    message: `Substamp value successfully updated`,
  })

  return true
}

export const removeSubStampValue = async (
  substampId: string,
  valueId: string,
): Promise<boolean> => {
  const response = await subStampsApi.deleteSubStampValue({ substampId, subStampValueId: valueId })

  if (response.status !== 200) {
    handleServerError(response)
    return false
  }

  showNotification({
    level: 'success',
    message: `Substamp value successfully removed`,
  })

  return true
}
