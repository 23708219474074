// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { FormField, Flex, HR } from '@r1/ui-kit'
import type { ProductIdentifiersProps } from '../../../types/public/ProductCardView'
import { Value } from './Common'

const Primary = styled('span')`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
  padding-left: ${({ theme }) => theme.space.XS};
`

const Wrapper = styled('div')`
  ${({ primary, theme }) => css`
    background-color: ${primary ? theme.palette.grey[50] : 'transparent'};
  `}
`

export const ProductIdentifiers = ({
  productIdentifiers,
  primaryProductIdentifier,
}: ProductIdentifiersProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <React.Fragment>
    {productIdentifiers.map(({ name, value }, index) => (
      <Wrapper key={`${name}${value}`} primary={value === primaryProductIdentifier}>
        <Flex column spaceBetween="S">
          <Flex spaceBetween="XXS">
            <FormField.Label>{name}</FormField.Label>
            <Value>
              {value}
              {value === primaryProductIdentifier && <Primary>Primary Identifier</Primary>}
            </Value>
          </Flex>
          {index !== productIdentifiers.length ? <HR /> : null}
        </Flex>
      </Wrapper>
    ))}
  </React.Fragment>
)
