import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Collapse, Flex, Icon, Tooltip } from '@r1/ui-kit'
import styled from '@emotion/styled'
import RJSForm from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import * as templates from './templates'
import * as actions from './module'
import { enrichUiSchemaWithSelects } from './utils'

const mapStateToProps = ({ categoryMapping, walmartAttributes }) => ({
  walmartCategoryId: categoryMapping.walmartCategoryId,
  schema: walmartAttributes.schema,
  data: walmartAttributes.data,
  errors: walmartAttributes.errors,
  initialData: walmartAttributes.initialData,
  initialWalmartCategoryId: walmartAttributes.initialWalmartCategoryId,
})

const enhance = compose(connect(mapStateToProps, { ...actions }))

const DEFAULT_UI_SCHEMA = {
  'ui:submitButtonOptions': {
    norender: true,
  },
}

const VALID_FORM_TEXT = 'Form does not contain validation errors'
const PANEL_ID = 'walmart-attributes'

const Form = styled(RJSForm)`
  & #root {
    border: none;
    padding: 0;
  }
`

const WalmartAttributesWrapper = ({
  id,
  disabled,
  walmartCategoryId,
  schema,
  fetchSchemaAndData,
  data,
  changeData,
  errors,
  setErrors,
}) => {
  const formRef = useRef()
  const [activePanel, setActivePanel] = useState(null)

  const checkErrors = useCallback(async () => {
    if (formRef && formRef.current) {
      setTimeout(() => {
        formRef.current.validateForm()
        // this is a hack to wait for the form to be rendered then check for errors
        setErrors(formRef.current.state.errors)
      }, 100)
    }
  }, [setErrors])

  useEffect(() => {
    if (walmartCategoryId) {
      fetchSchemaAndData(id, walmartCategoryId)
        .then(() => {
          setActivePanel(PANEL_ID)
          // this is a hack to wait for the form to be rendered then check for errors
          return Promise.resolve(() => setTimeout(() => {}, 100))
        })
        .then(() => {
          checkErrors()
        })
    }
  }, [checkErrors, fetchSchemaAndData, id, walmartCategoryId])

  useEffect(() => {
    checkErrors()
  }, [checkErrors, data, schema, activePanel])

  const handleChangeData = value => {
    changeData(value.formData)
  }

  if (!walmartCategoryId || !schema || !data) {
    return null
  }

  const uiSchema = {
    ...DEFAULT_UI_SCHEMA,
    ...enrichUiSchemaWithSelects(schema),
  }

  const tooltipText = errors.length ? errors.map(err => err.stack).join('\n') : VALID_FORM_TEXT

  return (
    <Collapse accordion active={activePanel} onChange={setActivePanel}>
      <Collapse.Panel
        id={PANEL_ID}
        title={
          <Flex align="center" spaceBetween="XS">
            <div>Walmart Attributes</div>
            <Tooltip text={tooltipText}>
              <Icon
                type="internal/notification/success"
                color={errors.length ? '#A9B0B8' : '#265ECA'}
                cursor="pointer"
              />
            </Tooltip>
          </Flex>
        }
      >
        <Form
          ref={formRef}
          noHtml5Validate
          liveValidate
          uiSchema={uiSchema}
          disabled={disabled}
          formData={data}
          schema={schema}
          showErrorList={false}
          validator={validator}
          templates={templates}
          experimental_defaultFormStateBehavior={{
            arrayMinItems: {
              populate: 'requiredOnly',
            },
          }}
          onChange={handleChangeData}
        />
      </Collapse.Panel>
    </Collapse>
  )
}

export default enhance(WalmartAttributesWrapper)
