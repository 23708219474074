// @flow

import { set } from 'lodash'
import type { ObjectSchema } from 'yup/lib/object'

export const clientValidate = <T>(schema: ObjectSchema<T>, values: mixed) => {
  try {
    schema.validateSync(values, { abortEarly: false, strict: true })

    return null
  } catch (e) {
    // Set works with path with dots for creating nested object
    return e.inner.reduce((errors, error) => set(errors, error.path, error.message), {})
  }
}
