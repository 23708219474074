import React from 'react'
import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

const CardBgContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -16px;
  transition: bottom 0.25s ease-out;
`

const CardFlex = styled(Flex)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 24px;
  width: 100%;
  height: 300px;
  background-color: ${({ theme }) => theme.palette.white};
  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    & > ${CardBgContainer} {
      bottom: 12px;
    }
  }
`

type Props = {
  background: React.ReactNode
  onClick(): void
}

export function CardWithBackground({
  background,
  onClick,
  children,
}: React.PropsWithChildren<Props>) {
  return (
    <CardFlex column justify="space-between" onClick={onClick}>
      {children}
      <CardBgContainer>{background}</CardBgContainer>
    </CardFlex>
  )
}
