// @flow

import { isErrorResponse } from '../../utils/api'
import { showNotification } from '../../../../../utils'

export function processResponse<T>(response: T, permissionErrorHandler: () => void = () => {}): T {
  if (isErrorResponse(response)) {
    if (response.message && typeof response.message === 'string') {
      showNotification({ title: response.message, level: 'error' })
    }
    // eslint-disable-next-line default-case
    switch (response.$type) {
      case 'PermissionDenied':
        permissionErrorHandler()
        break
    }
  }

  return response
}

const mapFieldNames = {
  name: 'attributeName',
  dataType: 'attributeType',
  enumValues: 'attributeEnumValues',
  unitOfMeasurementId: 'unitOfMeasurement',
}

type BadProperty = {
  noticeTypeCode: string,
  property: string,
  defaultFormat: string,
}
type ErrorData = { [fieldId: string]: string }

const variableRegEx = /\{([^}]+)\}/g
const bracesRegex = /[{}]/g
const getBadPropertyMessage = (notice): string =>
  notice.defaultFormat.replace(
    variableRegEx,
    currentMatch => notice[currentMatch.replace(bracesRegex, '')] || currentMatch,
  )

export const getBadPropertiesError = (badProperties: $ReadOnlyArray<BadProperty>): ErrorData[] => {
  const errorData = badProperties.map(badProperty => {
    const { property } = badProperty
    return {
      fieldId: mapFieldNames[property.slice(property.lastIndexOf('.') + 1)] || '',
      message: getBadPropertyMessage(badProperty),
    }
  })

  return errorData
}

export function mapSubmitError(data: Object, errors?: ErrorData = {}) {
  const { noticeTypeCode = '.', defaultFormat = '' } = data.error ? data.error : data
  const noticeCode = noticeTypeCode.split('.')[2]
  const res = { ...errors }
  // eslint-disable-next-line default-case
  switch (noticeCode) {
    case 'EnumValuesAreNotUnique':
    case 'AttributeEnumValueNotFound':
    case 'AttributeEnumValueIsInUse':
    case 'EnumValueNotCorrespondToAttributeDataType':
    case 'AtLeastOneEnumValueMustBeSpecifiedForEnumerationDataType':
      res.fieldId = 'attributeEnumValues'
      res.message = defaultFormat
      break
    case 'AttributeDataTypeNotSupportEnumValues':
      res.fieldId = 'attributeType'
      res.message = defaultFormat
      break
    case 'UnitOfMeasurementNotFound':
    case 'UnitOfMeasurementNotCorrespondToAttributeDataType':
    case 'UnitOfMeasurementNotSupportedByAttributeDataType':
      res.fieldId = 'unitOfMeasurement'
      res.message = defaultFormat
      break
  }
  return res
}
