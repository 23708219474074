import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'

const { initialState, types, reducers, name, actions, selectors } = createCollectionModule({
  name: '@warehouses',
  keyField: 'id',
  // eslint-disable-next-line no-return-await
  fetchAllFunc: async (_, api) => await api.client.get('/ui/api/warehouses?set=1'),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { actions, selectors }
