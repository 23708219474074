// @flow

import React, { useCallback } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Modal, Icon, Tooltip } from '@r1/ui-kit'
import type { CategoryEditScreenProps } from '../../../types/public/CategoryEditScreen'
import { CategoryContainer } from '../CategoryContainer'
import { CategoryEdit } from '../children/CategoryEdit'

export const CategoryEditScreen = (props: CategoryEditScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const cancelModalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'No',
          color: 'secondary',
          transparent: false,
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Yes',
          transparent: false,
          onClick: () => {
            props.onCancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [props],
  )

  return (
    <CategoryContainer httpClient={props.httpClient}>
      <CategoryEdit
        categoryId={props.categoryId}
        getAttributeDetailsUrl={props.getAttributeDetailsUrl}
        onSuccessfulSubmit={props.onSuccessfulSubmit}
        onSuccessfulDelete={props.onCancel}
      >
        {({ handlers, category: { name = '' }, restictions, isLoading }) => {
          const deleteCategoryActionButtons = ({ onClose }): BottomActionButton[] => {
            return [
              {
                title: 'No',
                color: 'secondary',
                onClick: () => onClose(),
                align: 'right',
              },
              {
                title: 'Yes',
                onClick: () => {
                  handlers.handleDelete()
                  onClose()
                },
                align: 'right',
              },
            ]
          }
          return (
            <>
              <Title>{`Category Details / ${name}`}</Title>
              <ActionButtons>
                <Modal
                  isControlled={false}
                  title="You have unsaved data"
                  actionButtons={cancelModalActionButtons}
                  buttonElement={({ onOpen }) => (
                    <Button color="secondary" onClick={onOpen}>
                      Cancel
                    </Button>
                  )}
                >
                  Are you sure you want to leave without saving?
                </Modal>
                <Button loading={isLoading} onClick={handlers.handleSubmit}>
                  Save
                </Button>
                {props.permissions.allowCategoryDelete && restictions.canBeDeleted ? (
                  <Tooltip text={restictions.canBeDeleted}>
                    <Button disabled transparent shape="square">
                      <Icon type="trash" />
                    </Button>
                  </Tooltip>
                ) : (
                  <Modal
                    isControlled={false}
                    title="Delete category"
                    actionButtons={deleteCategoryActionButtons}
                    buttonElement={({ onOpen }) => (
                      <Button transparent color="secondary" shape="square" onClick={onOpen}>
                        <Icon type="trash" />
                      </Button>
                    )}
                  >
                    Are you sure you want to delete this category?
                  </Modal>
                )}
              </ActionButtons>
            </>
          )
        }}
      </CategoryEdit>
    </CategoryContainer>
  )
}
