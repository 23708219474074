// @flow

import { handleServerError } from '@r1/core-blocks'

import { toInternalData } from '../../../../mappers/mapper'
import type { WithController } from '../types'

export class InitializationController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  processErrorResp = (resp: any) => {
    if (resp.status === 404) {
      this.component.setState({ screen: 'notFound' })
    } else if (resp.status === 403) {
      this.component.setState({ screen: 'forbidden' })
    } else {
      this.component.setState({ screen: 'unknownError' })
      handleServerError(resp)
    }
  }

  load = async () => {
    const { claimId } = this.component.props
    const { api } = this.component

    this.component.setState({ screen: 'loading' })

    const claimResp = await api.claims.getClaim({ claimId })
    if (claimResp.status !== 200) {
      this.processErrorResp(claimResp)
      return
    }
    const claim = claimResp.body
    const { rmaProfileId, purchaseOrderId } = claim

    const orderResp = await api.orders.getOrder({ rmaProfileId, purchaseOrderId })
    if (orderResp.status !== 200 && orderResp.status !== 404) {
      this.processErrorResp(orderResp)
      return
    }
    /* TODO remove */
    const order =
      orderResp.status !== 404
        ? orderResp.body
        : {
            rmaProfileId: 'N/A',
            purchaseOrderId: 'N/A',
            externalId: 'N/A',
            createdOn: new Date().toISOString(),
            info: {
              status: 'Created',
            },
            customer: {
              fullName: '',
              email: 'N/A',
            },
            billingInfo: {
              fullName: 'N/A',
              addressId: 'N/A',
            },
            shippingInfo: {
              addressId: 'N/A',
            },
            lines: [],
            orderTotal: { amount: '0.00', currency: 'USD' },
            legacyInfo: [],
          }
    const { shippingInfo } = order

    const [
      rmaProfile,
      returnReasons,
      expectedDecisions,
      availableDecisions,
      shippingAddress,
      claimHistoryByCustomer,
      facilities,
    ] = await Promise.all([
      this.fetchRmaProfileInfo(rmaProfileId),
      this.fetchReturnReasons(rmaProfileId),
      this.fetchExpectedDecisionVariants(rmaProfileId),
      this.fetchAvailableDecisions(rmaProfileId),
      this.fetchAddress(shippingInfo.addressId),
      this.fetchClaimHistoryByCustomer(claim.customerEmail),
      this.fetchFacilities(rmaProfileId),
    ])

    this.component.setState({
      screen: 'loaded',
      sidebarIsVisible: false,
      refundIsSubmitting: false,
      data: toInternalData({
        claim,
        order,
        rmaProfile,
        returnReasons,
        expectedDecisions,
        availableDecisions,
        shippingAddress,
        claimHistory: claimHistoryByCustomer,
        facilities,
      }),
      activeTabId: window.location.hash === '#replacements' ? 'tabReplacements' : 'tabReturns',
    })
  }

  fetchRmaProfileInfo = async (rmaProfileId: string) => {
    const response = await this.component.api.rmaProfiles.getProfileInfo({ rmaProfileId })

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body
  }

  fetchReturnReasons = async (rmaProfileId: string) => {
    const response = await this.component.api.rmaProfiles.getReturnReasons({ rmaProfileId })

    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }

  fetchExpectedDecisionVariants = async (rmaProfileId: string) => {
    const response = await this.component.api.rmaProfiles.getExpectedDecisionVariants({
      rmaProfileId,
    })
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }

  fetchAvailableDecisions = async (rmaProfileId: string) => {
    const response = await this.component.api.rmaProfiles.getAvailableDecisions({ rmaProfileId })
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }

  fetchAddress = async (addressId: string) => {
    const response = await this.component.api.address.getAddress(
      { addressId },
      { acceptLanguage: 'en' },
    )
    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body
  }

  fetchClaimHistoryByCustomer = async (customerEmail: string) => {
    const response = await this.component.api.claimHistory.getClaimHistoryByCustomer({
      customerEmail,
    })
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }

  fetchFacilities = async (rmaProfileId: string) => {
    const response = await this.component.api.rmaProfiles.getFacilities({ rmaProfileId })
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }
}

export const createInitializationController = (component: WithController) =>
  new InitializationController(component)
