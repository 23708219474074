// @flow

import * as React from 'react'
import { Flex } from '@r1/ui-kit'

type Props = {
  texts: string[],
}

export const NotificationTooltip = ({ texts }: Props) => (
  <Flex column minWidth={200} spaceBetween="S">
    {texts.map(t => (
      <div key={t}>{t}</div>
    ))}
  </Flex>
)
