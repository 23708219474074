// @flow

import React, { useMemo, type ComponentType } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text } from '@r1/ui-kit'
import type { EnhancedFlexProps as FlexProps } from '@r1/ui-kit/build/types/Flex'

const Wrapper: ComponentType<{| ...FlexProps, disabled: boolean |}> = styled(Flex)`
  ${({ theme, disabled }) => `
    borderRadius: 2px;
    border: 1px solid ${disabled ? theme.palette.grey[400] : theme.palette.grey[600]};
    color: ${disabled ? theme.palette.grey[500] : 'black'};
  `}
`

const PriceInput: ComponentType<{|
  type: string,
  width: number,
  value: string,
  disabled: boolean,
  onChange?: (event: any) => void,
|}> = styled('input')`
  ${({ theme, disabled, width }) => `
    border: none !important;
    outline: none;
    
    text-align: right;
    padding: 0;
    
    font-size: 15px;
    background-color: transparent;
    color: ${disabled ? theme.palette.grey[500] : 'black'};
    
    height: 100%;
    width: ${width}px;
   `}
`

type Props = {|
  name: string,
  price: {| amount?: string | number, currency: string |} | null,
  main?: boolean,
  enabled?: boolean,
  currency?: string,
  onChange?: (event: any) => void,
|}

const MIN_INPUT_WIDTH: number = 27
const SYMBOL_WIDTH: number = 8.75

export const PriceBlock = ({
  name = '',
  price = null,
  main,
  currency,
  onChange,
  enabled,
}: Props) => {
  const getInputWidth = (): number => {
    if (!price || !price.amount) return MIN_INPUT_WIDTH
    const valueStr = String(price.amount)
    return valueStr.length * SYMBOL_WIDTH < MIN_INPUT_WIDTH
      ? MIN_INPUT_WIDTH
      : valueStr.length * SYMBOL_WIDTH
  }

  const inputWidth = useMemo(getInputWidth, [price])

  return (
    <Wrapper p="XS" mr="S" mb="S" spaceBetween="S" align="center" disabled={!enabled}>
      <Text>{name}</Text>

      {main ? (
        // custom prices
        <Flex spaceBetween="XXS">
          <Box maxWidth={inputWidth}>
            <PriceInput
              type="text"
              width={inputWidth}
              value={String((price && price.amount) || '')}
              disabled={!enabled}
              onChange={onChange}
            />
          </Box>

          {currency === 'USD' ? <Text>$</Text> : <Text>{currency || ''}</Text>}
        </Flex>
      ) : (
        // consolidated prices
        price && (
          <Flex spaceBetween="XXS">
            <Text>{price.amount || ''}</Text>
            <Text>{price.currency === 'USD' ? '$' : price.currency}</Text>
          </Flex>
        )
      )}
    </Wrapper>
  )
}
