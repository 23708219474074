// @flow

import { createPricesApi, baseUri as pricesBaseUri } from '@r1-webui/productprices-prices-v1'
import { createProductsApi, baseUri as productsBaseUri } from '@r1-webui/productcatalog-products-v1'
import {
  createSourceDataApi,
  createSourcePricesHistoryApi,
  createProductsSourceDataApi,
  baseUri as sourceDataBaseUri,
} from '@r1-webui/sourcedataprovider-v1'
import { createProfileTypesApi } from '@r1-webui/marketplaceprofilemanagement-provider-v1'
import {
  createProductManagementApi,
  baseUri as productManagementBaseUri,
} from '@r1-webui/productcatalog-productmanagement-v1'
import {
  createCategoryTreeApi,
  baseUri as dataSourceCategoriesBaseUri,
} from '@r1-webui/globalcatalog-datasourcecategories-v1'
import {
  createListingCategoryTreesApi,
  baseUri as listingCategoryTreesBaseUri,
} from '@r1-webui/marketplacesettingsmanagement-listingcategorytrees-v1'

import {
  createAttributeDefinitionsApi,
  baseUri as attributeBaseUri,
} from '@r1-webui/productcatalog-attributedefinitions-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const productApi = (httpClient: any): WebUiApi => {
  const { getPrices } = createPricesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.PRICING_RULES}/${pricesBaseUri}`,
  })

  const {
    updateProduct,
    lockProduct,
    unlockProduct,
    rescrapeProductData,
    updateProductApprovalState,
    createPartProduct,
  } = createProductManagementApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG_MANAGEMENT}/${productManagementBaseUri}`,
  })

  const { getAllProfileTypes } = createProfileTypesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.MARKETPLACE_PROFILE_MANAGEMENT}`,
  })

  const { getCategoryTrees } = createListingCategoryTreesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.MARKETPLACE_SETTINGS_MANAGEMENT}/${listingCategoryTreesBaseUri}`,
  })

  const { getSourceDataInfo } = createSourceDataApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.PRODUCT_SOURCE_DATA}/${sourceDataBaseUri}`,
  })

  const { searchSourcePriceHistory } = createSourcePricesHistoryApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.PRODUCT_SOURCE_DATA}/${sourceDataBaseUri}`,
  })

  const { searchProductSourceData } = createProductsSourceDataApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.PRODUCT_SOURCE_DATA}/${sourceDataBaseUri}`,
  })

  const {
    findAllAttributeDefinitions,
    findAttributeDefinitionNamesByIds,
    findAttributeDefinitions,
    generateAttributeDefinitionHistoryReceivingToken,
    getAttributeDefinition,
  } = createAttributeDefinitionsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${attributeBaseUri}`,
  })

  const {
    getProductInfo,
    generateProductHistoryReceivingToken,
    getScrappedProductImages,
    searchProductNames,
    searchProductPartNumbers,
  } = createProductsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const {
    getAllCategories,
    getAllCategoryTrees,
    getCategoryPath,
    getCategoryPathsBulk,
    getCategoryTree,
    downloadTree,
  } = createCategoryTreeApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.DATA_SOURCE_INFO}/${dataSourceCategoriesBaseUri}`,
  })

  return {
    updateProduct,
    lockProduct,
    rescrapeProductData,
    updateProductApprovalState,
    unlockProduct,
    getPrices,
    getProductInfo,
    generateProductHistoryReceivingToken,
    getCategoryTrees,
    getScrappedProductImages,
    searchProductNames,
    searchProductPartNumbers,
    getAllCategories,
    getAllCategoryTrees,
    getCategoryPath,
    getCategoryPathsBulk,
    getCategoryTree,
    downloadTree,
    getAllProfileTypes,
    findAllAttributeDefinitions,
    findAttributeDefinitionNamesByIds,
    findAttributeDefinitions,
    generateAttributeDefinitionHistoryReceivingToken,
    getAttributeDefinition,
    getSourceDataInfo,
    searchProductSourceData,
    searchSourcePriceHistory,
    createPartProduct,
  }
}
