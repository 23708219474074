import React from 'react'
import { Modal } from '@r1/ui-kit'
import { Deposit } from '@r1-webui/gowholesale-depositmanagement-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { DepositHistoryGrid } from '../../DepositHistoryGrid/DepositHistoryGrid'

type DepositHistoryModalProps = {
  isOpen: boolean
  deposit: Deposit
  onClose: () => void
}

export const DepositHistoryModal = ({ isOpen, deposit, onClose }: DepositHistoryModalProps) => {
  const modalActionButtons: ControlledActionButtons = React.useMemo(
    () => [
      {
        title: 'Close',
        onClick: onClose,
        align: 'right',
      },
    ],
    [onClose],
  )

  return (
    <Modal
      isControlled
      size="L"
      show={isOpen}
      title="Deposit History"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      <DepositHistoryGrid deposit={deposit} />
    </Modal>
  )
}
