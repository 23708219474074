// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Flex, Text, useConfigurationProvider, Box, Dropdown, Icon, Format } from '@r1/ui-kit'
import type { EnhancedFlexProps as FlexProps } from '@r1/ui-kit/build/types/Flex'
import type { EnhancedBoxProps as BoxProps } from '@r1/ui-kit/build/types/Box'
import type { Lib$Optional } from '@r1/types/flow/libTypes'

type BaseProps = {|
  count: number,
  withFilterFlush?: Lib$Optional<boolean, true>,
  isSelected?: Lib$Optional<boolean, false>,
  actionItems?: any,
  onSelectItem?: (id: string) => void | Promise<void>,
|}

type PropsFiltered = {|
  ...BaseProps,
  isFiltered: boolean,
  onFilterFlush: () => void | Promise<void>,
|}

type PropsNotFiltered = {|
  ...BaseProps,
  isFiltered?: Lib$Optional<boolean, false>,
|}

type Props = PropsFiltered | PropsNotFiltered

const CounterFlex: React$ComponentType<FlexProps> = styled(Flex)`
  ${({ theme }) => `
    height: 24px;
    background-color: ${theme.palette.grey[200]};
    border-radius: 1px;
    color: ${theme.palette.grey[800]};
  `}
`

const ActionsFlex = styled(Flex)`
  cursor: pointer;
`

const MainLabelTextWrapperBox: React$ComponentType<{|
  ...BoxProps,
  isFilterFlushActive: boolean,
  onClick: () => void | Promise<void>,
|}> = styled(Box)`
  ${({ isFilterFlushActive }) => `
    cursor: ${isFilterFlushActive ? 'pointer' : 'default'};
  `}
`

export const CounterSection = ({
  count,
  withFilterFlush = true,
  isSelected = false,
  isFiltered = false,
  onFilterFlush = null,
  actionItems = [],
  onSelectItem = null,
}: Props) => {
  const { palette } = useConfigurationProvider()

  const renderTitle = () => {
    if (isSelected) return <Text color={palette.grey[900]}>&nbsp;/&nbsp;Selected</Text>
    if (isFiltered) return <Text color={palette.grey[900]}>&nbsp;/&nbsp;Search results</Text>
    return null
  }

  const isFilterFlushActive = React.useMemo(
    () => withFilterFlush && (isFiltered || isSelected),
    [withFilterFlush, isFiltered, isSelected],
  )

  const isDropdownVisible = React.useMemo(
    () => isSelected && onSelectItem && actionItems.length > 0,
    [isSelected, onSelectItem, actionItems],
  )

  return (
    <Flex align="center" spaceBetween="M">
      <Flex align="center">
        <MainLabelTextWrapperBox
          isFilterFlushActive={isFilterFlushActive}
          onClick={isFilterFlushActive ? onFilterFlush : () => {}}
        >
          <Text weight="medium" color={isFilterFlushActive ? palette.blue[500] : palette.grey[900]}>
            All Records
          </Text>
        </MainLabelTextWrapperBox>

        {renderTitle()}

        <CounterFlex ml="XS" align="center" justify="center" px="XXS" minWidth={24}>
          <Text type="paragraph">
            <Format.Number minFractionDigits={0}>{count}</Format.Number>
          </Text>
        </CounterFlex>
      </Flex>

      {isDropdownVisible && (
        <Dropdown.Menu
          mode="click"
          placement="bottom-start"
          size="S"
          zIndex={31}
          items={actionItems}
          // $FlowFixMe[incompatible-type] promise in callback
          onSelectItem={onSelectItem}
        >
          <ActionsFlex align="center" data-test-id="dropdown_actions">
            <Text color={palette.grey[700]}>Actions</Text>
            <Icon type="chevron/down" color={palette.grey[700]} />
          </ActionsFlex>
        </Dropdown.Menu>
      )}
    </Flex>
  )
}
