// @flow

import React, { memo, useCallback, useContext, useState } from 'react'
import * as yup from 'yup'
import { FormManager } from '@r1/form-manager'
import { Flex, FormField, Input, Editor, NotificationSystem } from '@r1/ui-kit'
import { ServiceContext } from '../../provider'
import { CategoriesTreeSelect } from '../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'
import { clientValidate } from '../../../../utils/validation'
import { PartsCategories } from '../CategoryEdit/children/PartsCategories'
import type {
  CategoryCreateProps,
  CategoryFormManagerSubmit,
  CategoryFormManagerValuesState,
} from '../../types/category.type'

const schema = yup.object({
  name: yup
    .string()
    .min(2, () => `Text should be 2 symbols at least`)
    .max(100)
    .required('Field is required'),
  parentId: yup.string().nullable(),
  description: yup.string().max(1000),
})

export const CategoryCreate = memo<CategoryCreateProps>((props: CategoryCreateProps) => {
  const { onSuccessfulSubmit, children } = props
  const { categoryService, httpClient } = useContext(ServiceContext)

  const [isLoading, setIsLoading] = useState(false)
  const [charCount, setCharCount] = useState(0)

  const [categoryFormValues]: CategoryFormManagerValuesState = useState({
    id: '',
    version: '',
    name: '',
    parentId: null,
    description: '',
    lastModifiedDate: '',
    fitments: [],
  })

  const onSubmit = async ({ ...category }) => {
    const { error, data: categoryId } = await categoryService.createCategory(category)

    if (!error) {
      NotificationSystem.addNotification({
        level: 'success',
        title: `${category.name} category  was created successfully`,
      })

      if (onSuccessfulSubmit) {
        onSuccessfulSubmit(categoryId)
      }
    } else {
      NotificationSystem.addNotification({ level: 'error', title: error })
    }
  }

  const onSubmitCallback = useCallback<CategoryFormManagerSubmit>(category => {
    setIsLoading(true)
    return onSubmit(category).then(() => setIsLoading(false))
  }, [])

  const clientValidateCallback = useCallback(value => clientValidate(schema, value), [])

  return (
    <Flex column minWidth={890} maxWidth={890}>
      <FormManager
        initialValues={categoryFormValues}
        clientValidate={clientValidateCallback}
        onSubmit={onSubmitCallback}
      >
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => {
          return (
            <>
              <Flex column maxWidth={825}>
                <FormField>
                  <FormField.Label>Title *</FormField.Label>
                  <div style={{ width: '350px' }}>
                    <Input
                      data-test-id="title"
                      placeholder="Enter title"
                      error={!!errors.name}
                      value={String(values.name)}
                      onChange={value => handleChange('name', value)}
                      onBlur={_ => {
                        const { name } = values
                        const trimName = name.replace(/\s+/gi, ' ').trim()

                        handleBlur('name')()
                        if (trimName !== name) {
                          handleChange('name', trimName)
                        }
                      }}
                    />
                  </div>
                  <FormField.Error>{errors.name}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label>Parent Category</FormField.Label>
                  <CategoriesTreeSelect
                    multiple={false}
                    placeholder="Select parent category"
                    httpClient={httpClient}
                    categoryId={values.parentId}
                    onChangeCategoryId={value => handleChange('parentId', value || null)}
                  />
                  <FormField.Error>{errors.parentId}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label charCounter={{ max: 1000, current: charCount }}>
                    Description
                  </FormField.Label>
                  <Editor
                    data-test-id="description"
                    placeholder="Enter description"
                    error={!!errors.description}
                    value={String(values.description)}
                    onChange={(value, count) => {
                      setCharCount(count)
                      handleChange('description', value || '')
                    }}
                    onBlur={handleBlur('description')}
                  />
                  <FormField.Error>{errors.description}</FormField.Error>
                </FormField>
                <PartsCategories
                  {...{
                    values,
                    handleChange,
                  }}
                />
              </Flex>
              {children({
                isLoading,
                handlers: { handleSubmit },
                category: values,
              })}
            </>
          )
        }}
      </FormManager>
    </Flex>
  )
})
