// @flow

import * as React from 'react'

import { useAccessControl } from '@r1/core-blocks'

import { ProductView } from './ProductView'
import { ProductEdit } from './ProductEdit'

export const ProductDetails = () => {
  const [{ allowProductEdit }] = useAccessControl()
  return allowProductEdit ? <ProductEdit /> : <ProductView />
}
