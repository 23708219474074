import { InventoryType } from '@r1-webui/masterprograms-v1'
import { MasterProgramModel } from '../types'
import { getInfoFieldsValue } from './getInfoFieldsValue'

export const getEmptyMasterProgram = (): MasterProgramModel => {
  return {
    id: '',
    name: '',
    inventoryType: 'AsIs' as InventoryType,
    isActive: true,
    parentProgramName: '',
    clientProgramName: '',
    filterGroups: [],
    infoFields: getInfoFieldsValue([]),
    subStamps: [],
    deleted: false,
  }
}
