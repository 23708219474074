import * as React from 'react'
import { useMemo } from 'react'
import { GridGenerator, setQuery, parseQuery, CustomColumnSettings } from '@r1/grid-generator'
import { Link, Text, Button, Dropdown, Icon, Flex } from '@r1/ui-kit'
import { httpClient } from '../../../../httpClient'
import { DrawerType } from './types'

const GRID_ID = 'RmaRules'
const GRID_BASE_URL = '/uisvc/shipping-rule-processor-grid'
const AGGREGATION_COUNT = { title: 'All Records' }

export type PrimitiveValue = string | number | boolean
export type DataItem = {
  [columnId: string]: PrimitiveValue
}

type Props = {
  onOpenDrawer: (v: { id: string; type: DrawerType }) => void
  tableRef: React.RefObject<GridGenerator>
}

export const RmaShippingRulesGrid = ({ onOpenDrawer, tableRef }: Props) => {
  const initialStateQuery = useMemo(parseQuery, [])

  const idColumn: CustomColumnSettings<DataItem> = useMemo(
    () => ({
      cell: {
        $type: 'custom',
        renderer: (item: DataItem) => (
          <Link
            to=""
            onClick={() => onOpenDrawer({ id: item.id ? String(item.id) : '', type: 'EDIT' })}
          >
            <Text overflow="ellipsis">{item.name}</Text>
          </Link>
        ),
      },
      columnId: 'id',
    }),
    [onOpenDrawer],
  )

  const actionsColumn: CustomColumnSettings<DataItem> = useMemo(
    () => ({
      cell: {
        $type: 'custom',
        renderer: (item: DataItem) => (
          <Dropdown.Menu
            items={[{ id: 'Duplicate rule', title: 'Duplicate rule' }]}
            mode="click"
            placement="bottom-end"
            onSelectItem={(itemId: string) => {
              if (itemId === 'Duplicate rule')
                onOpenDrawer({ id: String(item.id), type: 'DUPLICATE' })
            }}
          >
            <Flex justify="center">
              <Button transparent shape="square" size="S">
                <Icon type="dots" />
              </Button>
            </Flex>
          </Dropdown.Menu>
        ),
      },
      columnId: 'Actions',
      width: 100,
    }),
    [onOpenDrawer],
  )

  const initialCustomColumnsSettings = useMemo(
    () => [idColumn, actionsColumn],
    [idColumn, actionsColumn],
  )

  return (
    <GridGenerator
      ref={tableRef}
      gridId={GRID_ID}
      gridEngineBaseUrl={GRID_BASE_URL}
      initialStateQuery={initialStateQuery}
      aggregationCount={AGGREGATION_COUNT}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      httpClient={httpClient}
      initialCustomColumnsSettings={initialCustomColumnsSettings}
      toolbarActionButtons={
        <Button onClick={() => onOpenDrawer({ id: '', type: 'CREATE' })}>Create</Button>
      }
      onChangeState={setQuery}
    />
  )
}
