import { NotificationSystem } from '@r1/ui-kit'

import { ClientValidateHandler } from '@r1/form-manager'
import { Priority, ShippingOptions } from '@r1-webui/shipping-returnshippingrules-v1'
import { FormValues } from '../types'

const MAX_INT = 999999999

type RangeError = {
  from: string | null
  to: string | null
}

type FormErrors = {
  name: string | null
  description: string | null
  priority: {
    ruleId: string | null
  }
  ruleConstraints: {
    marketplaceProfilesIds: string | null
    weightRange: RangeError
    dimensionalWeightRange: RangeError
    salePriceRange: RangeError
    maximumShippingPrice: { amount: string | null }
    maximumShippingPricePercent: string | null
  }
  shippingOptions: {
    shippingProfileId: string | null
  }[]
  contactInformation: {
    companyName: string | null
    contactName: string | null
    phoneNumber: string | null
  }
}

type FormManagerClientValidateHandler = ClientValidateHandler<FormValues, FormErrors>

export const uiValidators = {
  name: (name: string) => {
    if (!name) {
      return 'Name cannot be empty'
    } else if (name.length > 250) {
      return 'String is too long'
    }
    return null
  },

  description: (description?: string) => {
    if (description && description.length > 2000) {
      return 'String is too long'
    }
    return null
  },

  priority: (priority: Priority | { type: null }) => {
    if (priority.type === 'ExecuteAfterRuleId' && !priority.ruleId) {
      return {
        ruleId: 'Cannot be empty',
      }
    }
    if (!priority.type) {
      return {
        ruleId: 'Cannot be empty',
      }
    }

    return {
      ruleId: null,
    }
  },

  range: (from?: number, to?: number) => {
    if (from && to && from > to) {
      return {
        from: 'Invalid range',
        to: 'Invalid range',
      }
    }

    if (from && from > MAX_INT) {
      return {
        from: 'Number is too big',
        to: 'Number is too big',
      }
    }

    if (to && to > MAX_INT) {
      return {
        from: 'Number is too big',
        to: 'Number is too big',
      }
    }

    return {
      from: null,
      to: null,
    }
  },

  maximumShippingPrice: (value?: number) => {
    if (value && value > MAX_INT) {
      return {
        amount: 'Number is too big',
      }
    }
    return { amount: null }
  },

  maximumShippingPricePercent: (percent?: number) => {
    if (percent && (percent < 0 || percent > 100)) {
      return 'Invalid percent'
    }
    return null
  },

  marketplaceProfilesIds: (marketplaces: Array<string>) => {
    if (marketplaces.length > 0) {
      return null
    }
    return 'Cannnot be empty'
  },

  shippingOptions: (shippingOptions: ShippingOptions) => {
    return shippingOptions.map(opt => {
      if (opt.shippingProfileId) {
        return {
          shippingProfileId: null,
        }
      }

      return {
        shippingProfileId: 'Cannot be empty',
      }
    })
  },

  companyName: (val: string) => {
    if (val.length > 0) {
      return null
    }
    return 'Cannnot be empty'
  },
  contactName: (val: string) => {
    if (val.length > 0) {
      return null
    }
    return 'Cannnot be empty'
  },
  phoneNumber: (val: string) => {
    const num = val.replace(/\D/g, '')
    if (num.length > 15 || num.length < 10) {
      return 'Invalid phone number'
    }
    return null
  },
}

export const validate: FormManagerClientValidateHandler = (values, params): FormErrors | null => {
  const {
    name,
    description,
    priority,
    ruleConstraints: {
      marketplaceProfilesIds,
      weightRange,
      dimensionalWeightRange,
      salePriceRange,
      maximumShippingPrice,
      maximumShippingPricePercent,
    },
    shippingOptions,
    contactInformation: { companyName, contactName, phoneNumber },
  } = values
  const { invokedBySubmissionButton } = params

  const errors = {
    name: uiValidators.name(name),
    description: uiValidators.description(description),
    priority: uiValidators.priority(priority),
    ruleConstraints: {
      marketplaceProfilesIds: uiValidators.marketplaceProfilesIds(marketplaceProfilesIds),
      weightRange: uiValidators.range(weightRange?.from, weightRange?.to),
      dimensionalWeightRange: uiValidators.range(
        dimensionalWeightRange?.from,
        dimensionalWeightRange?.to,
      ),
      salePriceRange: uiValidators.range(salePriceRange?.from, salePriceRange?.to),
      maximumShippingPrice: uiValidators.maximumShippingPrice(maximumShippingPrice?.amount),
      maximumShippingPricePercent: uiValidators.maximumShippingPricePercent(
        maximumShippingPricePercent,
      ),
    },
    shippingOptions: uiValidators.shippingOptions(shippingOptions),
    contactInformation: {
      companyName: uiValidators.companyName(companyName),
      contactName: uiValidators.contactName(contactName),
      phoneNumber: uiValidators.phoneNumber(phoneNumber),
    },
  }

  const hasErrors =
    errors.name ||
    errors.description ||
    errors.priority.ruleId ||
    errors.ruleConstraints.marketplaceProfilesIds ||
    errors.ruleConstraints.weightRange.from ||
    errors.ruleConstraints.dimensionalWeightRange.from ||
    errors.ruleConstraints.salePriceRange.from ||
    errors.ruleConstraints.maximumShippingPricePercent ||
    errors.ruleConstraints.maximumShippingPrice.amount ||
    errors.shippingOptions.some(({ shippingProfileId }) => shippingProfileId) ||
    errors.contactInformation.companyName ||
    errors.contactInformation.contactName ||
    errors.contactInformation.phoneNumber

  if (hasErrors && invokedBySubmissionButton) {
    NotificationSystem.addNotification({
      title: 'Some fields are invalid',
      level: 'error',
      autoDismiss: false,
    })

    return errors
  }

  return null
}
