// @flow

import * as React from 'react'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { CompanyPricingRulesScreen } from '../../components/CompanyPricingRules'

import { httpClient } from '../../../../httpClient'

export const PricingRules = () => {
  const [{ allowCompanyPriceCreate }] = useAccessControl()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowCompanyPriceView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Title>Company Prices</ContentHeader.Title>
        <Content>
          <CompanyPricingRulesScreen
            httpClient={httpClient}
            allowCompanyPriceCreate={allowCompanyPriceCreate}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
