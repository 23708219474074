import {
  DepositFilter,
  DepositStatus,
  DepositType,
  SortedColumn,
  SortType,
} from '@r1-webui/gowholesale-depositmanagement-v1'
import { SortingType } from '@r1/grid/contracts/ts/DisplaySettings'
import { GridColumnSettings } from '../../../offer-management/types'
import { DepositsGridColumn, DepositsGridPagination } from './types'

export const MIN_PAGE_SIZE = 25

export const PAGE_SIZES: number[] = [MIN_PAGE_SIZE, 50, 100]

export const DEFAULT_PAGINATION: DepositsGridPagination = {
  page: 1,
  pageSize: MIN_PAGE_SIZE,
}

export const DEFAULT_FILTER: DepositFilter = {
  depositStatuses: ['Held', 'Unpaid'],
}

export const FILTER_STATUSES: { id: DepositStatus; name: string }[] = [
  { id: 'Captured', name: 'Captured' },
  { id: 'Closed', name: 'Closed' },
  { id: 'Held', name: 'Held' },
  { id: 'Unpaid', name: 'Unpaid' },
]

export const depositTypeNameMap: Record<DepositType, string> = {
  OnePercent: '1% Offer Deposit',
  TenPercent: '10% Offer Deposit',
  WireTransfer: 'Wire Transfer Deposit',
}

export const FILTER_TYPES: { id: DepositType; name: string }[] = [
  { id: 'OnePercent', name: depositTypeNameMap.OnePercent },
  { id: 'TenPercent', name: depositTypeNameMap.TenPercent },
  { id: 'WireTransfer', name: depositTypeNameMap.WireTransfer },
]

export const sortingTypeFilterUiMap: Record<SortType, SortingType> = {
  Ascending: 'asc',
  Descending: 'desc',
}

export const sortingTypeUiFilterMap: Record<SortingType, SortType> = {
  asc: 'Ascending',
  desc: 'Descending',
  none: 'Ascending',
}

export const depositsGridSortedColumns = [
  DepositsGridColumn.DepositId,
  DepositsGridColumn.OfferCreationDate,
  DepositsGridColumn.DepositType,
  DepositsGridColumn.DepositAmount,
  DepositsGridColumn.Status,
  DepositsGridColumn.OfferId,
  DepositsGridColumn.WireTransferOrderId,
]

export const sortedColumnsGridColumnsMap: Record<SortedColumn, DepositsGridColumn> = {
  Id: DepositsGridColumn.DepositId,
  CreationDate: DepositsGridColumn.OfferCreationDate,
  DepositType: DepositsGridColumn.DepositType,
  DepositAmount: DepositsGridColumn.DepositAmount,
  Status: DepositsGridColumn.Status,
  OfferId: DepositsGridColumn.OfferId,
  MarketplaceOrderId: DepositsGridColumn.WireTransferOrderId,
}

export const depositsGridMeta = new Map<DepositsGridColumn, GridColumnSettings>([
  [DepositsGridColumn.DepositId, { header: 'Deposit ID', width: 150 }],
  [DepositsGridColumn.OfferCreationDate, { header: 'Created On', width: 270 }],
  [DepositsGridColumn.CompanyName, { header: 'Company Name' }],
  [DepositsGridColumn.DepositType, { header: 'Deposit Type', width: 200 }],
  [DepositsGridColumn.DepositAmount, { header: 'Deposit Amount', width: 180 }],
  [DepositsGridColumn.Status, { header: 'Status', width: 130 }],
  [DepositsGridColumn.OfferId, { header: 'Offer ID', width: 130 }],
  [DepositsGridColumn.WireTransferOrderId, { header: 'Wire Transfer Order ID', width: 180 }],
  [DepositsGridColumn.Actions, { header: 'Actions', width: 100 }],
])
