export type Permission =
  | 'B2B Order:Edit'
  | 'catalog:create'
  | 'SecondaryCatalogTemplate:View'
  | 'ProductTemplate:View'
  | 'ProductTemplate:Unlock'
  | 'File:Import'
  | 'CatalogImportSFTPSettings:View'
  | 'CatalogImportSFTPSettings:Edit'
  | 'CatalogImportSFTPSettings:Create'
  | 'CatalogImportSFTPSettings:Delete'

export type PermissionsMap = {
  [p in Permission]: boolean
}

export const PERMISSIONS: PermissionsMap = {
  'B2B Order:Edit': false,
  'catalog:create': false,
  'SecondaryCatalogTemplate:View': false,
  'ProductTemplate:View': false,
  'ProductTemplate:Unlock': false,
  'File:Import': false,
  'CatalogImportSFTPSettings:View': false,
  'CatalogImportSFTPSettings:Edit': false,
  'CatalogImportSFTPSettings:Create': false,
  'CatalogImportSFTPSettings:Delete': false,
}

export type LegacyPermissions = { [key: string]: Array<string> }
