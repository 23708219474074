// @flow

import { baseUri } from '@r1-webui/productcatalog-attributedefinitions-v1'
import type { CommonError, UnauthorizedError } from '@r1/types/flow/libTypes'
import type { Api } from '../../types/internal/api/attributeDefinitions'

import { UI_API_PREFIXES } from '../../api'
import { successResponseInterceptor } from '../utils/api'

export const API_PREFIX = `${UI_API_PREFIXES.CATALOG}/${baseUri}`

const mapToCommonErrors = (err): CommonError | UnauthorizedError => {
  if (err.response) {
    const { status } = err.response

    if (status === 401) return { $type: 'Unauthorized', message: 'Unauthorized' }
  }
  return {
    $type: 'Common',
    payload: err,
  }
}

const mapStatus = (status: number, obj: Object) => async err => {
  if (err.response) {
    if (err.response.status === status) {
      const message = err.response.data.defaultFormat

      return {
        $type: obj.$type,
        message,
      }
    }
  }
  return null
}

const getNotSupportedOrNull = mapStatus(422, {
  $type: 'NotSupported',
})

const getForbiddenOrNull = mapStatus(403, {
  $type: 'Forbidden',
})

export function createApi(httpClient: any): Api {
  return {
    findAllAttributeDefinitions: () =>
      httpClient
        .get(`${API_PREFIX}/attribute-definitions`)
        .then(successResponseInterceptor)
        .catch(async err => {
          const forbidden = await getForbiddenOrNull(err)
          if (forbidden) return forbidden
          return mapToCommonErrors(err)
        }),
    getAttributeDefinition: ({ attributeDefinitionId }) =>
      httpClient
        .get(`${API_PREFIX}/attribute-definitions/${attributeDefinitionId}`)
        .then(successResponseInterceptor)
        .catch(async err => {
          const forbidden = await getForbiddenOrNull(err)
          if (forbidden) return forbidden
          return mapToCommonErrors(err)
        }),
    getAllUnitsOfMeasurement: () =>
      httpClient
        .get(`${API_PREFIX}/units-of-measurement`)
        .then(successResponseInterceptor)
        .catch(mapToCommonErrors),
    getUnitOfMeasurementTypes: () =>
      httpClient
        .get(`${API_PREFIX}/units-of-measurement/types`)
        .then(successResponseInterceptor)
        .catch(async err => {
          const notSupported = await getNotSupportedOrNull(err)
          if (notSupported) return notSupported
          return mapToCommonErrors(err)
        }),
    findAttributeDefinitions: ({ attributeDefinitionIds }) =>
      httpClient
        .post(`${API_PREFIX}/attribute-definitions/search/`, {
          attributeDefinitionIds,
        })
        .then(successResponseInterceptor)
        .catch(async err => {
          const forbidden = await getForbiddenOrNull(err)
          if (forbidden) return forbidden
          return mapToCommonErrors(err)
        }),
  }
}
