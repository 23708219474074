// @flow

import * as React from 'react'

import { Flex, Format } from '@r1/ui-kit'

import type { ClaimType } from '../../types/common'
import type { ClaimLineProps, ClaimLine as ClaimLineType } from '../../types/ClaimCreation'
import { ReplacementItem } from '../ReplacementItem'

import { Property, Img, ItemInfo, PhotoWrapper, Photo } from './StyledComponents'

const renderProductInfo = (claimType: ClaimType, claimLine: ClaimLineType) => {
  const result = []

  result.push(
    <Flex mb="M">
      <Property>Product:</Property>
      {claimLine.productInfo.title}
    </Flex>,
  )

  switch (claimType) {
    case 'RefundOnly':
      result.push(
        <Flex mb="M">
          <Property>Refund Amount:</Property>
          <Format.Money value={claimLine.refundAmount} />
        </Flex>,
        <Flex mb="M">
          <Property>Refund Reason:</Property>
          {claimLine.returnReason}
        </Flex>,
      )
      break

    default:
      result.push(
        <Flex mb="M">
          <Property>Price:</Property>
          <Format.Money value={claimLine.price} />
        </Flex>,
        <Flex mb="M">
          <Property>Return Quantity:</Property>
          {claimLine.returnQuantity}
        </Flex>,
        <Flex mb="M">
          <Property>Return Reason:</Property>
          {claimLine.returnReason}
        </Flex>,
      )
      break
  }

  return result
}

export const ClaimLine = (props: ClaimLineProps) => {
  const { claimType, claimLine } = props

  return (
    <Flex mb="XL" pb="XL" justify="space-between">
      <ItemInfo justify="space-between">
        <Flex justify="flex-start">
          <Flex align="center" justify="center" minWidth={160} maxWidth={160} mr="XL">
            <Img src={claimLine.productInfo.imageUrls[0]} />
          </Flex>
          <Flex column>{renderProductInfo(claimType, claimLine)}</Flex>
        </Flex>
      </ItemInfo>
      <Flex column pl="XL" ml="XL" minWidth={475} maxWidth={475}>
        <Flex mb="M">
          <Property>Notes:</Property>
          {claimLine.note || 'N/A'}
        </Flex>
        <Flex mb="M" column={!!claimLine.images.length}>
          <Property>Images:</Property>
          {!claimLine.images.length ? (
            'N/A'
          ) : (
            <PhotoWrapper>
              {claimLine.images.map(image => (
                <Photo key={image.imageId} src={image.base64Image} />
              ))}
            </PhotoWrapper>
          )}
        </Flex>
        {['ReplacementAfterReturn', 'ReplacementOnly'].includes(claimType) && (
          <Flex mb="M" column={!!claimLine.replacements.length}>
            <Property>Replacements:</Property>
            {!claimLine.replacements.length ? (
              'N/A'
            ) : (
              <Flex flexWrap spaceBetween="M" mt="XS">
                {claimLine.replacements.map(replacement => (
                  <Flex key={replacement.productId}>
                    <ReplacementItem replacement={replacement} />
                  </Flex>
                ))}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
