// @flow

import * as React from 'react'
import { Box, Flex, FormField, Tag, HR } from '@r1/ui-kit'
import type { AttributeDefinitionViewProps } from './types'
import { attributeTypeMapper, uniquenessMapper } from './attributeInfoMappers'

export const TagViewList = (props: {| list: string[] |}) => (
  <Flex flexWrap>
    {props.list.map(val => (
      <Box key={val} mt="XXS" mr="XXS">
        <Tag key={val} viewMode text={val} maxSize="M" />
      </Box>
    ))}
  </Flex>
)

const LABEL_WIDTH = 215

export function AttributeDefinitionView(props: AttributeDefinitionViewProps) {
  return (
    <>
      <Flex column spaceBetween="S">
        <HR />
        <Flex maxWidth={LABEL_WIDTH}>
          <FormField.Label>Name</FormField.Label>
          {props.generalInfo.name}
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex maxWidth={LABEL_WIDTH}>
          <FormField.Label>Refers To</FormField.Label>
          {props.generalInfo.bindingType}
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex maxWidth={LABEL_WIDTH}>
          <FormField.Label>Refers To</FormField.Label>
          {props.generalInfo.bindingType}
        </Flex>
      </Flex>
      <Flex column spaceBetween="S">
        <HR />
        <Flex maxWidth={LABEL_WIDTH}>
          <FormField.Label>Type</FormField.Label>
          {attributeTypeMapper(props.generalInfo.type)}
        </Flex>
      </Flex>
      {props.enumerationInfo &&
        props.enumerationInfo.enumValues &&
        props.enumerationInfo.enumValues.length > 0 && (
          <Flex column spaceBetween="S">
            <HR />
            <Flex maxWidth={LABEL_WIDTH}>
              <FormField.Label>Available values</FormField.Label>
              <TagViewList list={props.enumerationInfo.enumValues} />
            </Flex>
          </Flex>
        )}
      {props.unitOfMeasurementInfo && props.unitOfMeasurementInfo.type && (
        <Flex column spaceBetween="S">
          <HR />
          <Flex maxWidth={LABEL_WIDTH}>
            <FormField.Label>Unit of Measurement type</FormField.Label>
            {props.unitOfMeasurementInfo.type}
          </Flex>
        </Flex>
      )}
      {props.unitOfMeasurementInfo && props.unitOfMeasurementInfo.name && (
        <Flex column spaceBetween="S">
          <HR />
          <Flex maxWidth={LABEL_WIDTH}>
            <FormField.Label>Unit of Measurement</FormField.Label>
            {props.unitOfMeasurementInfo.name}
          </Flex>
        </Flex>
      )}
      {props.unitRelatedInfo &&
        props.unitRelatedInfo.manufacturers &&
        props.unitRelatedInfo.manufacturers.length > 0 && (
          <Flex column spaceBetween="S" minWidth={LABEL_WIDTH} maxWidth={LABEL_WIDTH}>
            <HR />
            <Flex spaceBetween="XXS">
              <FormField.Label>Manufacturers</FormField.Label>
              <TagViewList list={props.unitRelatedInfo.manufacturers} />
            </Flex>
          </Flex>
        )}
      {props.unitRelatedInfo && props.unitRelatedInfo.uniqueness && (
        <Flex column spaceBetween="S" minWidth={LABEL_WIDTH} maxWidth={LABEL_WIDTH}>
          <HR />
          <Flex spaceBetween="XXS">
            <FormField.Label>Uniqueness</FormField.Label>
            {uniquenessMapper(props.unitRelatedInfo.uniqueness)}
          </Flex>
        </Flex>
      )}
    </>
  )
}
