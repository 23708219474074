import { handleServerError } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'
import { PriorityChangeInfo } from '@r1-webui/discountsrulesmanagement-v1'
import { discountsRulesApi } from '../../../../api/discountsRulesManagement'
import { DiscountRule } from '../../types'

import { discountRuleMapper } from './discountsRuleMapper'

const getDiscountsRules = async () =>
  discountsRulesApi.getDiscountRules().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }
    return response.body.map(discountRuleMapper.toState).sort((a, b) => a.priority - b.priority)
  })

const changeRulePriority = (priorityChangeInfo: PriorityChangeInfo) =>
  discountsRulesApi.changeRulePriority(priorityChangeInfo).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      throw response
    } else {
      NotificationSystem.addNotification({
        title: 'Discounts settings priorities was successfully updated',
        level: 'success',
      })
      return response.body.map(discountRuleMapper.toState).sort((a, b) => a.priority - b.priority)
    }
  })

const deleteDiscountRule = (ruleId: string, version: string) =>
  discountsRulesApi.deleteDiscountRule({ ruleId }, { version }).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      throw response
    }
    NotificationSystem.addNotification({
      title: 'Discounts settings was successfully deleted',
      level: 'success',
    })
  })

const getDiscountRule = (ruleId: string) =>
  discountsRulesApi.getDiscountRule({ ruleId }).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      throw response
    } else return discountRuleMapper.toState(response.body)
  })

const updateDiscountRule = (ruleId: string, version: string, body: DiscountRule) =>
  discountsRulesApi
    .updateDiscountRule({ ruleId }, { version }, discountRuleMapper.fromState(body))
    .then(response => {
      if (response.status === 422) {
        NotificationSystem.addNotification({
          title: response.body.errorMessage,
          level: 'error',
        })
        throw response
      } else if (response.status !== 200) {
        handleServerError(response)
        throw response
      } else {
        NotificationSystem.addNotification({
          title: 'Discount rule was successfully updated',
          level: 'success',
        })

        return response.body.newVersion
      }
    })

const createDiscountRule = (body: DiscountRule) =>
  discountsRulesApi.createDiscountRule(discountRuleMapper.fromState(body)).then(response => {
    if (response.status === 422) {
      NotificationSystem.addNotification({
        title: response.body.errorMessage,
        level: 'error',
      })
      throw response
    } else if (response.status !== 200) {
      handleServerError(response)
      throw response
    }
    NotificationSystem.addNotification({
      title: 'Discount rule was successfully created',
      level: 'success',
    })
  })

export const discountsRulesService = {
  getDiscountsRules,
  changeRulePriority,
  deleteDiscountRule,
  getDiscountRule,
  updateDiscountRule,
  createDiscountRule,
}
