// @flow

import type { AttributeBinding } from '@r1-webui/productcatalog-categorymanagement-v1/src/types'

import type { AttributeDefinitionType } from '../types'

export function createAttributeBinding({
  attributeDefinitionId,
  type,
}: {
  attributeDefinitionId: string,
  type: AttributeDefinitionType,
}): AttributeBinding {
  return type === 'ProductAttributeDefinition'
    ? {
        attributeDefinitionId,
        type: 'ProductAttributeBinding',
      }
    : {
        attributeDefinitionId,
        type: 'UnitAttributeBinding',
      }
}
