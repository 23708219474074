// @flow

import { NotificationSystem } from '@r1/ui-kit'
import { createOrderProductPickUpSettingsApi } from '@r1-webui/orderproductpickupsettings-v1'
import { handleServerError } from '@r1/core-blocks'
import { replaceTemplateValues } from '../../../utils'
import { httpClient } from '../../../httpClient'
import { handleError } from '../../../modules/utils'

import type { PickUpSetting } from './types'

const PROFILE_URL = '/uisvc/espm/api/purpose/B2CSellingVenueProfile/profile'
const API_PREFIX = `/uisvc/order-product-pickup-settings`

const orderProductPickUpSettingsApi = createOrderProductPickUpSettingsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const api = {
  getProfiles: async () => {
    try {
      return await httpClient.get(PROFILE_URL)
    } catch (error) {
      handleError(error)
      return []
    }
  },

  getPickupSettings: async () => {
    const res = await orderProductPickUpSettingsApi.getOrderProductPickUpSettings()

    if (res.status !== 200) {
      handleServerError(res)
      return []
    }

    return res.body
  },

  putPickupSettings: async (pickUpSettings: PickUpSetting[]) => {
    const res = await orderProductPickUpSettingsApi.updateOrderProductPickUpSettings(pickUpSettings)

    if (res.status === 409) {
      NotificationSystem.addNotification({
        level: 'error',
        message: replaceTemplateValues(res.body),
      })
      return
    }

    if (res.status !== 200) {
      handleServerError(res)
      return
    }

    NotificationSystem.addNotification({
      level: 'success',
      message: 'Settings successfully updated',
    })
  },
}
