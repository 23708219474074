// @flow

import { useRef, useCallback } from 'react'

export const useDebounce = (delay: number) => {
  const timerHandler = useRef(null)

  const debounce = useCallback(
    (callback: any) => {
      clearTimeout(timerHandler.current)
      timerHandler.current = setTimeout(() => {
        if (typeof callback === 'function') {
          callback()
        }
      }, delay)
    },
    [timerHandler.current],
  )
  // test
  return debounce
}
