/* eslint-disable no-underscore-dangle */
// @flow

import React, { useReducer, useEffect } from 'react'

import { Button, Icon, Box } from '@r1/ui-kit'
import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Loader } from '../../../components/Loader'
import { PickUpSetting } from './components/PickUpSettings'
import { api } from './api'

import { actionTypes, type State, type ReducerAction } from './types'

const initialState: State = {
  profiles: [],
  pickUpSettings: [],
  isFetching: false,
  isUpdating: false,
}

function reducer(state: State, action: ReducerAction) {
  switch (action.type) {
    case actionTypes.SET_PROFILES:
      return { ...state, profiles: action.profiles }
    case actionTypes.SET_PICKUP_SETTINGS:
      return { ...state, pickUpSettings: action.pickUpSettings }
    case actionTypes.SET_IS_FETCHING:
      return { ...state, isFetching: action.isFetching }
    case actionTypes.SET_IS_UPDATING:
      return { ...state, isUpdating: action.isUpdating }
    default:
      return initialState
  }
}

export const OrderProductPickUpSettings = () => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [{ allowShippingSettingsEdit }] = useAccessControl()

  const fetchData = async () => {
    dispatch({ type: actionTypes.SET_IS_FETCHING, isFetching: true })

    const _profiles = await api.getProfiles()
    const _pickUpSettings = await api.getPickupSettings()

    dispatch({
      type: actionTypes.SET_PROFILES,
      profiles: _profiles,
    })

    dispatch({
      type: actionTypes.SET_PICKUP_SETTINGS,
      pickUpSettings: _pickUpSettings,
    })

    dispatch({ type: actionTypes.SET_IS_FETCHING, isFetching: false })
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onAddSetting = () => {
    const newSetting = {
      $type: 'OrderProductPickUpSettings',
      profileIds: [],
      pickUpStrategy: -1,
    }

    const newPickupSettings = [...state.pickUpSettings, newSetting]

    dispatch({ type: actionTypes.SET_PICKUP_SETTINGS, pickUpSettings: newPickupSettings })
  }

  const onDeleteSetting = (settingIndex: number) => () => {
    const newPickUpSettings = state.pickUpSettings.filter((_, index) => index !== settingIndex)

    dispatch({ type: actionTypes.SET_PICKUP_SETTINGS, pickUpSettings: newPickUpSettings })
  }

  const onSettingProfilesChange = (settingIndex: number) => (values: string[]) => {
    const newPickUpSettings = [...state.pickUpSettings]
    const newSetting = newPickUpSettings[settingIndex]

    newSetting.profileIds = values

    dispatch({ type: actionTypes.SET_PICKUP_SETTINGS, pickUpSettings: newPickUpSettings })
  }

  const onSettingPickUpStrategyChange = (settingIndex: number) => (value: number) => {
    const newPickUpSettings = [...state.pickUpSettings]
    const newSetting = newPickUpSettings[settingIndex]

    newSetting.pickUpStrategy = value

    dispatch({ type: actionTypes.SET_PICKUP_SETTINGS, pickUpSettings: newPickUpSettings })
  }

  const updateSettings = async () => {
    dispatch({ type: actionTypes.SET_IS_UPDATING, isUpdating: true })

    await api.putPickupSettings(state.pickUpSettings)

    dispatch({ type: actionTypes.SET_IS_UPDATING, isUpdating: false })
  }

  const renderContent = () => (
    <Content spaceBetween="M" justify="center">
      {state.pickUpSettings.length ? (
        <>
          {state.pickUpSettings.map((setting, settingIndex) => (
            <PickUpSetting
              // eslint-disable-next-line react/no-array-index-key
              key={settingIndex}
              profiles={state.profiles}
              settingData={setting}
              onAddSetting={onAddSetting}
              onDeleteSetting={onDeleteSetting(settingIndex)}
              onPickUpStrategyChange={onSettingPickUpStrategyChange(settingIndex)}
              onProfilesChange={onSettingProfilesChange(settingIndex)}
            />
          ))}

          <Box>
            <Button
              disabled={!allowShippingSettingsEdit || state.isUpdating}
              onClick={updateSettings}
            >
              Update Settings
            </Button>
          </Box>
        </>
      ) : (
        <Box>
          <Button disabled={!allowShippingSettingsEdit} onClick={onAddSetting}>
            <Icon type="plus" color="inverted" />
            Add setting
          </Button>
        </Box>
      )}
    </Content>
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowShippingSettingsView]}>
      <Main>
        {state.isFetching ? (
          <Loader />
        ) : (
          <>
            <ContentHeader>
              <ContentHeader.Title />
            </ContentHeader>
            {renderContent()}
          </>
        )}
      </Main>
    </PageAccessControl>
  )
}
