import * as React from 'react'
import ConditionMappingsItem from './ConditionMappingsItem'

const ConditionMappingsList = ({
  conditionMappings,
  availableConditions,
  marketplaceConditions,
  onChange,
  onDeleteRow,
}) => (
  <div>
    {conditionMappings.map((item, index) => (
      <ConditionMappingsItem
        key={`item-${item.generatedId}`}
        index={index}
        availableConditions={availableConditions}
        marketplaceConditions={marketplaceConditions}
        UPC={item.UPC}
        marketplaceConditionType={item.marketplaceConditionType}
        technicalFunctionality={item.technicalFunctionality}
        physicalCondition={item.physicalCondition}
        condition={item.condition}
        onChange={onChange}
        onDeleteRow={onDeleteRow}
      />
    ))}
  </div>
)

export default ConditionMappingsList
