// @flow

import React, { useContext, useEffect, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { Box, Flex, H3, Placeholder } from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import type { AttributeFormEditProps } from '../../../../types/product.type'

import { initialDefaultValue } from '../../../../../../utils/AttributeDefinitions'
import { Enumeration, Boolean, DateRange, Date, NumberRange, Decimal, String } from './children'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const Text = styled('span')`
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 16px;
  line-height: 24px;
`

const ContainerDefaultValue = styled('span')`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
  padding-left: ${({ theme }) => theme.space.XXXL};
`

const AttributeDefaultValue = ({ children, defaultValue }) => (
  <Box>
    {children}
    {defaultValue && defaultValue.value && (
      <Box mt="XXL">
        <ContainerDefaultValue>{defaultValue.value}</ContainerDefaultValue>
      </Box>
    )}
  </Box>
)

const mapValueType = {
  Integer: 'IntValue',
  String: 'StringValue',
  Decimal: 'DecimalValue',
  Boolean: 'BoolValue',
  Date: 'DateValue',
  DecimalEnumeration: 'EnumValue',
  IntEnumeration: 'EnumValue',
  StringEnumeration: 'EnumValue',
  IntRange: 'IntRange',
  DecimalRange: 'DecimalRange',
  DateRange: 'DateRange',
}

const getFormValue = (attributesInitValue, definition) => {
  const initValue = attributesInitValue.find(({ definitionId }) => definitionId === definition.id)
  if (initValue && initValue.formValue !== null) return initValue.formValue // from service GetProductInfo

  const { valueType, ...initialValue } = initialDefaultValue(definition.valueType)
  return 'value' in initialValue && initialValue.value !== null
    ? initialValue.value
    : { ...initialValue }
}

const getAttributeTitle = (title, bindings, definition) => {
  return ` 
    ${bindings.displayName || title} 
    ${definition.unitOfMeasurement ? ` (${definition.unitOfMeasurement.name.defaultFormat}) ` : ``}
    ${bindings && bindings.required ? `*` : ``}
  `
}

const sortEnumValues = enumValues => {
  const res = { ...enumValues }
  if (enumValues.valueType === 'DecimalEnumArray' && enumValues.enumValues) {
    res.enumValues = enumValues.enumValues.sort((a, b) => (a.value < b.value ? -1 : 1))
  }

  if (enumValues.valueType === 'IntEnumArray' && enumValues.enumValues) {
    res.enumValues = enumValues.enumValues.sort((a, b) => (a.value < b.value ? -1 : 1))
  }

  if (enumValues.valueType === 'StringEnumArray' && enumValues.enumValues) {
    res.enumValues = enumValues.enumValues.sort((a, b) => a.value.localeCompare(b.value))
  }

  return res
}

export const Attributes = ({
  values,
  handleChange,
  handleBlur,
  disabled,
  getError,
  setAttributeValueType,
  setIsLoadingProduct,
  attributesInitValue,
}: AttributeFormEditProps) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [attributes, setAttributes] = useState([])

  const {
    productService: { findAttributeDefinitions },
    categoryService: { getAttributeBindings },
  } = useContext(ServiceContext)

  const fetchAttributes = useCallback(async () => {
    if (!values.CategoryId) {
      handleChange(['Attributes'], [])
      setAttributes([])
      return
    }
    setIsLoadingProduct(true)
    setIsLoading(true)

    const attributeBindings = await getAttributeBindings({ categoryId: values.CategoryId })

    const attributeDefinitions =
      attributeBindings.length > 0
        ? await findAttributeDefinitions(
            attributeBindings.map(({ attributeDefinition }) => attributeDefinition.id),
          )
        : []

    const newAttrValues = [] // for FormManager
    const attrWithData = [] // for state - attribute with bindings and definitions

    attributeBindings.forEach(binding => {
      const definition = attributeDefinitions.find(
        ({ id }) => id === binding.attributeDefinition.id,
      )
      if (!definition || definition.type !== 'ProductAttributeDefinition') return

      const attributeValue = {
        definitionId: definition.id,
        title: definition.name,
        formValue: getFormValue(attributesInitValue, definition),
        children: [],
      }
      newAttrValues.push(attributeValue)
      attrWithData.push({ ...attributeValue, bindings: binding, definition })
    })

    // $FlowFixMe[incompatible-call]
    handleChange(['Attributes'], newAttrValues)
    setAttributes(attrWithData)
    setAttributeValueType(
      attrWithData.map(({ definitionId, definition }) => ({
        definitionId,
        valueType: mapValueType[definition.valueType],
      })),
    )

    setIsLoading(false)
    setIsLoadingProduct(false)
    // we don't need to reload each time values.Attributes change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    findAttributeDefinitions,
    getAttributeBindings,
    handleChange,
    setAttributeValueType,
    setIsLoadingProduct,
    // values.Attributes,
    values.CategoryId,
  ])

  useEffect(() => {
    fetchAttributes()
  }, [fetchAttributes, values.CategoryId])

  return (
    <>
      <Box>
        <StyledH3>Attributes</StyledH3>
      </Box>
      <Box>
        {isLoading ? (
          <Placeholder type="form" height={2} />
        ) : (
          <Flex column minWidth={890} maxWidth={890}>
            {attributes.map((attribute, optionIndex) => {
              if (attribute.definition.type !== 'ProductAttributeDefinition') return null

              return (
                // eslint-disable-next-line react/no-array-index-key
                <Flex key={optionIndex} spaceBetween="XL">
                  {(() => {
                    switch (attribute.definition.valueType) {
                      case 'StringEnumeration':
                      case 'DecimalEnumeration':
                      case 'IntEnumeration':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <Enumeration
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              handleChange={handleChange}
                              optionIndex={optionIndex}
                              value={values.Attributes[optionIndex].formValue}
                              enumValues={sortEnumValues(attribute.definition.enumValues)}
                              valueConstraint={attribute.bindings.valueConstraint}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'Boolean':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <Boolean
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              optionIndex={optionIndex}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'DateRange':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <DateRange
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              optionIndex={optionIndex}
                              valueConstraint={attribute.bindings.valueConstraint}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'Date':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <Date
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              optionIndex={optionIndex}
                              valueConstraint={attribute.bindings.valueConstraint}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'DecimalRange':
                      case 'IntRange':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <NumberRange
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              optionIndex={optionIndex}
                              valueType={attribute.definition.valueType}
                              valueConstraint={attribute.bindings.valueConstraint}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'Decimal':
                      case 'Integer':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <Decimal
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              optionIndex={optionIndex}
                              valueType={attribute.definition.valueType}
                              valueConstraint={attribute.bindings.valueConstraint}
                            />
                          </AttributeDefaultValue>
                        )
                      case 'String':
                        return (
                          <AttributeDefaultValue defaultValue={attribute.bindings.defaultValue}>
                            <String
                              error={getError(['Attributes', optionIndex, 'formValue'])}
                              disabled={disabled}
                              title={getAttributeTitle(
                                attribute.title,
                                attribute.bindings,
                                attribute.definition,
                              )}
                              value={values.Attributes[optionIndex].formValue}
                              handleChange={handleChange}
                              optionIndex={optionIndex}
                            />
                          </AttributeDefaultValue>
                        )
                      default:
                        return null
                    }
                  })()}
                </Flex>
              )
            })}
            {attributes.length === 0 && <Text>There are no binding attribute</Text>}
          </Flex>
        )}
      </Box>
    </>
  )
}
