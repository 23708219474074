/* eslint-disable import/no-unused-modules */
// @flow

import * as R from 'ramda'
import type {
  Attribute,
  ProductImageInfo,
  ProductIdentifierInfo,
} from '@r1-webui/productcatalog-products-v1/src/types'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import { isSuccessResponse, processResponse } from '../../utils/api'
import type {
  ProductDimensionsInfo,
  ProductIdentifiersInfo,
  AttributesInfo,
  ProductGeneralInfo,
} from '../../../types/public/ProductCardController'
import type { ProductImage } from '../../../types/public/productCardCommon'
import { replaceTemplateValues } from '../../../../../utils'

import type { CategoryApi } from '../../Category/api'
import type { Api as ProductApi } from '../../../types/internal/api/products'
import type { Api as AttributeApi } from '../../../types/internal/api/attributeDefinitions'
import { formatAttribute } from './attributesMapper'

type SummaryApi = {|
  ...CategoryApi,
  ...ProductApi,
  ...AttributeApi,
|}

type Callback = () => void

type ProductImagesInfo = {|
  productImages: ProductImage[],
  primaryProductImage?: Lib$Id,
|}

type ProductIdentifiers = {|
  primary: ProductIdentifierInfo,
  additional: ProductIdentifierInfo[],
|}

type MappedProps = {|
  generalInfo: ProductGeneralInfo,
  dimensionsInfo: ProductDimensionsInfo,
  identifiersInfo: ProductIdentifiersInfo,
  attributesInfo: AttributesInfo,
  imagesInfo: ProductImagesInfo,
|}

type ProductImages = {|
  primary?: ProductImageInfo,
  additional: ProductImageInfo[],
|}

export function getImages(images: ProductImages) {
  const allImages = images.additional

  if (images.primary) {
    allImages.unshift(images.primary)
  }

  const imagesInfo: ProductImagesInfo = {
    productImages: allImages.map((i, index) => ({ id: String(index), url: i.image.url })),
    primaryProductImage: String(0),
  }

  return imagesInfo
}

export function getIdentifiers(
  identifiers: ProductIdentifiers = {
    additional: [],
    primary: { value: '', type: { id: '', name: { noticeTypeCode: '', defaultFormat: '' } } },
  },
) {
  const allIdentifiers = [identifiers.primary].concat(identifiers.additional)
  const identifiersInfo: ProductIdentifiersInfo = {
    productIdentifiers: allIdentifiers.map(identifier => ({
      id: identifier.type.id,
      name: replaceTemplateValues(identifier.type.name),
      value: identifier.value,
    })),
    primaryProductIdentifier: identifiers.primary.value,
  }

  return identifiersInfo
}

export function getDimensions(fieldsMap: { [string]: any }) {
  const dimensionsInfo: ProductDimensionsInfo = {
    packageDimensions: fieldsMap.PackageDimensionsField,
    productDimensions: fieldsMap.ProductDimensionsField,
    packageWeight: fieldsMap.PackageWeightField,
    productWeight: fieldsMap.ProductWeightField,
  }

  return dimensionsInfo
}

export function getCategory(
  getCategoryPath: $PropertyType<CategoryApi, 'getCategoryPath'>,
  categoryId: string,
  permissionErrorHandler: Callback,
) {
  return getCategoryPath({ categoryId })
    .then(response => processResponse(response, permissionErrorHandler))
    .then(categoryResponse => {
      let categoryPath = []

      if (isSuccessResponse(categoryResponse)) {
        categoryPath = categoryResponse.payload
      }

      return categoryPath
    })
}

const flatChildren: (Array<Attribute>) => Array<Attribute> = R.chain(attr => [
  attr,
  ...flatChildren(attr.children),
])

export function getAttributes(
  attributes: Array<Attribute>,
  findAttributeDefinitions: $PropertyType<AttributeApi, 'findAttributeDefinitions'>,
): Promise<AttributesInfo> {
  if (attributes.length === 0)
    // eslint-disable-next-line no-promise-executor-return
    return new Promise(resolve => resolve({ productAttributes: [], unitAttributes: [] }))

  const flatAttributes = flatChildren(attributes)
  const attributeDefinitionIds = flatAttributes.map(({ definitionId }) => definitionId)

  return findAttributeDefinitions({
    attributeDefinitionIds,
  })
    .then(processResponse)
    .then(attributeDefinitionsResponse => {
      if (isSuccessResponse(attributeDefinitionsResponse)) {
        const attributeDefinitions = attributeDefinitionsResponse.payload || []

        const attributesMap = flatAttributes.reduce(
          (acc, { definitionId, value, title }) => ({ ...acc, [definitionId]: { value, title } }),
          {},
        )

        if (attributeDefinitions.length > 0) {
          const { ProductAttributeDefinition = [], UnitAttributeDefinition = [] } =
            attributeDefinitions.reduce((acc, attributeDefinition) => {
              const formattedAttribute = formatAttribute(
                attributesMap[attributeDefinition.id].value,
                attributesMap[attributeDefinition.id].title,
                attributeDefinition,
              )

              if (!formattedAttribute) return acc

              const existingAttributes = acc[formattedAttribute.type] || []
              acc[formattedAttribute.type] = [...existingAttributes, formattedAttribute]

              return acc
            }, {})

          return {
            productAttributes: ProductAttributeDefinition,
            unitAttributes: UnitAttributeDefinition,
          }
        }
      }

      return { productAttributes: [], unitAttributes: [] }
    })
}

export async function getGeneralInfo(
  fieldsMap: { [string]: any },
  categoryId: ?string,
  getCategoryPath: $PropertyType<SummaryApi, 'getCategoryPath'>,
  permissionErrorHandler: Callback,
): Promise<ProductGeneralInfo> {
  const categoryPath = categoryId
    ? await getCategory(getCategoryPath, categoryId, permissionErrorHandler)
    : []

  const model = fieldsMap.ModelField
  const manufacturer = fieldsMap.ManufacturerField

  const generalInfo: ProductGeneralInfo = {
    productName: fieldsMap.TitleField,
    categoryPath,
    brand: fieldsMap.BrandField,
    model: model.name,
    manufacturer: manufacturer.name,
    country:
      fieldsMap.CountryOfOriginField &&
      fieldsMap.CountryOfOriginField.name &&
      replaceTemplateValues(fieldsMap.CountryOfOriginField.name),
    taxCode: fieldsMap.TaxCodeField,
    retailPrice: fieldsMap.RetailPriceField,
    shortDescription: fieldsMap.ShortDescriptionField,
    longDescription: fieldsMap.LongDescriptionField,
    supportingUrl: fieldsMap.SupportingUrlField,
    partNumber: fieldsMap.PartNumberField,
  }

  return generalInfo
}

export const createPropsProvider = (api: SummaryApi, permissionErrorHandler: Callback) => ({
  getMappedProps: async (
    images: ProductImages,
    identifiers: ProductIdentifiers,
    attributes: Attribute[],
    fieldsMap: { [string]: any },
    categoryId?: string,
  ): Promise<MappedProps> => {
    const imagesInfo = getImages(images)
    const dimensionsInfo = getDimensions(fieldsMap)
    const identifiersInfo = getIdentifiers(identifiers)

    const [attributesInfo, generalInfo] = await Promise.all([
      getAttributes(attributes, api.findAttributeDefinitions),
      getGeneralInfo(fieldsMap, categoryId, api.getCategoryPath, permissionErrorHandler),
    ])

    return {
      imagesInfo,
      dimensionsInfo,
      attributesInfo,
      identifiersInfo,
      generalInfo,
    }
  },
})
