// @flow

import { mapSubmitError, getBadPropertiesError } from '../helpers'

const schema = {
  '401': {
    $type: 'Unauthorized',
    message: 'Unauthorized',
  },
  '403': {
    $type: 'Forbidden',
    message: 'Forbidden',
  },
  '409': {
    $type: 'Conflict',
    message: '',
  },
  '422': {
    $type: 'Validation',
    fields: [],
  },
  '400': {
    $type: 'Validation',
    fields: [],
  },
  default: {
    $type: 'Common',
    message: 'Common',
  },
}

export function errorHandler(
  error: Object,
  options: Object = {},
): { $type: string, message: string, fields?: Object } {
  const { response } = error
  const data = response.data || {}
  const errorSchema = Object.keys(options).reduce((acc, key) => {
    if (acc[key]) {
      acc[key] = options[key]
    }
    return acc
  }, schema)
  const notice = errorSchema[response.status] || errorSchema.default

  if (notice.$type === 'Validation') {
    notice.fields = Object.prototype.hasOwnProperty.call(data, 'badProperties')
      ? // RAML validation, which should be parse
        getBadPropertiesError(data.badProperties)
      : [mapSubmitError(data)]
  } else if (notice.$type === 'Conflict') {
    notice.message = data.error.defaultFormat
  }
  return notice
}
