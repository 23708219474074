import * as React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@r1/ui-kit'

const RemovableContainer = styled('div')`
  position: relative;
  box-sizing: border-box;
  padding-top: 4px;
`

const IconButton = styled('button')`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  outline: 0;
  background-color: transparent;
  border: none;
  opacity: 0.7;
  font-size: 16px;

  &:hover {
    opacity: 1;
  }

  ${({ theme, placement }) => {
    let position

    switch (placement) {
      case 'border':
        position = 'translateX(75%);'
        break

      case 'outside':
        position = 'translateX(150%);'
        break

      default:
        position = 'translateX(0);'
    }

    return `
      transform: ${position};
      transition: opacity ${theme.animationDuration} ${theme.animationTimingFunction};
    `
  }};
`

const renderIconButton = props => {
  const { iconPlacement, onRemove } = props

  return (
    <IconButton placement={iconPlacement} onClick={onRemove}>
      <Icon type="clear" />
    </IconButton>
  )
}

export const Removable = props => {
  const { children, disabled } = props

  return (
    <RemovableContainer>
      {!disabled && renderIconButton(props)}
      {children}
    </RemovableContainer>
  )
}
