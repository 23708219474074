import { SubStamp } from '@r1-webui/masterprograms-v1'
import { handleServerError } from '@r1/core-blocks'
import { useEffect, useState } from 'react'
import { subStampsApi } from '../../../api/MasterProgram'

export const useSubStampsApi = (active: boolean) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [subStamps, setSubStamps] = useState<SubStamp[]>([])

  useEffect(() => {
    const fetchSubStamps = async () => {
      setIsLoading(true)
      const response = active
        ? await subStampsApi.getActiveSubStamps()
        : await subStampsApi.getAllSubStamps()
      setIsLoading(false)
      if (response.status !== 200) {
        handleServerError(response)
        return
      }

      setSubStamps(response.body)
    }
    fetchSubStamps()
  }, [])

  return {
    isLoading,
    subStamps,
  }
}
