import { handleServerError } from '@r1/core-blocks'

import { categoriesApi } from '../../../api/productCatalogCategoriesApi'

const fetchCategories = () =>
  categoriesApi.getAllCategories().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  })

export const productCatalogService = { fetchCategories }
