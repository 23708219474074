import * as React from 'react'
import { useMemo } from 'react'
import { compose, withState, withHandlers, withProps } from 'recompose'
import {
  append,
  filter,
  propEq,
  find,
  concat,
  over,
  lensProp,
  complement,
  set,
  view,
  without,
} from 'ramda'
import { arrayMove } from 'react-sortable-hoc'
import { Modal, Flex, Text } from '@r1/ui-kit'
import { ListingImagesEnhanced as ListingImages } from './ListingImages'

const referenceLens = lensProp('reference')
const listingLens = lensProp('listing')
const uploadedLens = lensProp('uploaded')
const removedLens = lensProp('removed')

const enhance = compose(
  withProps(({ value }) => ({
    reference: value.reference || [],
    listing: value.listing || [],
    uploaded: value.uploaded || [],
    removed: value.removed || [],
  })),
  withState('showModal', 'setShowModal', false),
  withState('zoomImage', 'setZoomImage', undefined),
  withHandlers({
    addImage2Reference:
      ({ onChange, value }) =>
      async item => {
        const pipe = compose(over(referenceLens, append(item)), over(uploadedLens, append(item)))

        onChange(pipe(value))
      },
    delReference:
      ({ onChange, value }) =>
      id => {
        const is = propEq('id', id)
        const item = filter(is, view(referenceLens, value))
        const wasSavedOnServer = image => image.url && image.id === id
        const itemToDelete = filter(wasSavedOnServer, view(referenceLens, value))
        const pipe = compose(
          over(referenceLens, without(item)),
          over(uploadedLens, without(item)),
          over(removedLens, concat(itemToDelete)),
        )

        onChange(pipe(value))
      },
    moveToReference:
      ({ onChange, value }) =>
      id =>
        onChange(
          over(
            referenceLens,
            append(find(propEq('id', id), view(listingLens, value))),
            over(listingLens, filter(complement(propEq('id', id))), value),
          ),
        ),
    moveToListing:
      ({ onChange, value }) =>
      id => {
        onChange(
          over(
            listingLens,
            append(find(propEq('id', id), view(referenceLens, value))),
            over(referenceLens, filter(complement(propEq('id', id))), value),
          ),
        )
      },
    onSortEndListing:
      ({ onChange, value }) =>
      ({ oldIndex, newIndex }) =>
        onChange(set(listingLens, arrayMove(value.listing, oldIndex, newIndex), value)),
    onSortEndReference:
      ({ onChange, value }) =>
      ({ oldIndex, newIndex }) =>
        onChange(set(referenceLens, arrayMove(value.reference, oldIndex, newIndex), value)),
    zoom:
      ({ setShowModal, setZoomImage, reference, listing }) =>
      id => {
        const { src, url } = find(propEq('id', id), concat(listing, reference))

        setShowModal(true)
        setZoomImage(src || url)
      },
    closeModal:
      ({ setShowModal }) =>
      _ =>
        setShowModal(false),
  }),
)

const LISTING_IMAGE_SIZE = [164, 164]
const REFERENCE_IMAGE_SIZE = [128, 128]

const Images = ({
  listing,
  reference,
  delReference,
  moveToListing,
  moveToReference,
  addImage2Reference,
  onSortEndListing,
  onSortEndReference,
  closeModal,
  showModal,
  zoomImage,
  zoom,
  disabled,
}) => {
  const referenceImageButtons = [{ icon: 'internal/zoom', func: zoom }]

  const listingImageButtons = [{ icon: 'internal/zoom', func: zoom }]

  if (!disabled) {
    referenceImageButtons.push(
      { icon: 'trash', func: delReference },
      { name: 'List it', func: moveToListing },
    )

    listingImageButtons.push({ name: 'Remove', func: moveToReference })
  }

  const modalActionButtons = useMemo(
    () => [
      {
        title: 'Close',
        onClick: closeModal,
        align: 'right',
      },
    ],
    [closeModal],
  )

  return (
    <Flex column spaceBetween="S">
      <Text>Listing images (main images which is listing everywhere)</Text>
      <ListingImages
        list={listing}
        size={LISTING_IMAGE_SIZE}
        actives={[0]}
        buttons={listingImageButtons}
        disabled={disabled}
        onSortEnd={onSortEndListing}
      />
      <Text>Reference images (source of all images, for listing choose and press «List it»)</Text>
      <ListingImages
        list={reference}
        size={REFERENCE_IMAGE_SIZE}
        buttons={referenceImageButtons}
        disabled={disabled}
        onUpload={addImage2Reference}
        onSortEnd={onSortEndReference}
      />
      <Modal
        isControlled
        show={showModal}
        actionButtons={modalActionButtons}
        onEscapeKeyDown={closeModal}
      >
        <img src={zoomImage} style={{ maxWidth: '80vw', maxHeight: '80vh' }} alt={zoomImage} />
      </Modal>
    </Flex>
  )
}

export const ImagesEnhanced = enhance(Images)
