// @flow

import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

export const Img = styled('img')`
  max-width: 160px;
  max-height: 153px;
  border-radius: ${({ theme }) => theme.space.XXS};

  @media (max-width: 1140px) {
    max-width: 134px;
  }
`

export const ItemInfo = styled(Flex)`
  width: 100%;
`

export { Property } from '../StyledComponents'

export const PhotoWrapper = styled(Flex)`
  display: flow-root;
  margin-top: ${({ theme }) => theme.space.XS};
`

export const Photo = styled('img')`
  max-height: 70px;
`
