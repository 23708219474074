// @flow

import * as React from 'react'

type Categories = {| id: string, parentId?: string, name: string |}[]
type ServiceData = {|
  productService: $AnyObject,
  categoryService: $AnyObject,
  priceService: $AnyObject,
  imageService: $AnyObject,
  referenceService: $AnyObject,
  variationService: $AnyObject,
  manufacturerManagementService: $AnyObject,
  httpClient: $AnyObject,
  allCategories: Categories,
  setAllCategories: Categories => void,
|}

export const ServiceContext: React.Context<ServiceData> = React.createContext({
  productService: {},
  categoryService: {},
  priceService: {},
  imageService: {},
  referenceService: {},
  variationService: {},
  manufacturerManagementService: {},
  httpClient: {},
  allCategories: [],
  setAllCategories: () => {},
})
