// @flow

import type { AttributeValueConstraint } from '../types'

/**
 * Transform Constraint for filling initialValues with keys that weren't in response
 * and for parse Decimal string to float
 */

export const transformConstraint = (value: AttributeValueConstraint): AttributeValueConstraint => {
  switch (value.valueType) {
    case 'NumberRangeConstraint':
      return {
        valueType: 'NumberRangeConstraint',
        minAllowedValue:
          // $FlowFixMe[incompatible-return]
          value.minAllowedValue != null ? parseFloat(value.minAllowedValue) : undefined,
        maxAllowedValue:
          // $FlowFixMe[incompatible-return]
          value.maxAllowedValue != null ? parseFloat(value.maxAllowedValue) : undefined,
      }
    case 'DateRangeConstraint':
      return {
        valueType: 'DateRangeConstraint',
        minAllowedDate: value.minAllowedDate || undefined,
        maxAllowedDate: value.maxAllowedDate || undefined,
      }
    case 'DateConstraint':
      return {
        valueType: 'DateConstraint',
        minAllowedDate: value.minAllowedDate || undefined,
        maxAllowedDate: value.maxAllowedDate || undefined,
      }
    case 'EnumerationConstraint':
      return {
        valueType: 'EnumerationConstraint',
        allowedVariants: value.allowedVariants || [],
      }
    case 'NumberConstraint':
      return {
        valueType: 'NumberConstraint',
        minAllowedValue:
          // $FlowFixMe[incompatible-return]
          value.minAllowedValue != null ? parseFloat(value.minAllowedValue) : undefined,
        maxAllowedValue:
          // $FlowFixMe[incompatible-return]
          value.maxAllowedValue != null ? parseFloat(value.maxAllowedValue) : undefined,
        forbiddenValues: value.forbiddenValues || [],
      }
    case 'StringConstraint':
      return {
        valueType: 'StringConstraint',
        minAllowedLength: value.minAllowedLength || undefined,
        maxAllowedLength: value.maxAllowedLength || undefined,
        forbiddenSubstrings: value.forbiddenSubstrings || [],
        forbiddenWords: value.forbiddenWords || [],
      }

    default:
      return value
  }
}
