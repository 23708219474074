// @flow

import React from 'react'

import { Flex } from '@r1/ui-kit'

import type { CustomerInfoBlockProps } from '../../../types/ClaimCreation'
import { InfoBlockTitle, Property, Value } from '../StyledComponents'

export const CustomerInfoBlock = (props: CustomerInfoBlockProps) => {
  const { fullName, email, phone, shippingAddress } = props

  return (
    <Flex column mr="XL">
      <InfoBlockTitle>Customer</InfoBlockTitle>
      <Flex>
        <Property>Full Name:</Property>
        <Value>{fullName}</Value>
      </Flex>
      <Flex>
        <Property>Email:</Property>
        <Value>{email}</Value>
      </Flex>
      <Flex>
        <Property>Phone:</Property>
        <Value>{phone}</Value>
      </Flex>
      <Flex>
        <Property>Shipping Address:</Property>
        <Value>{shippingAddress}</Value>
      </Flex>
    </Flex>
  )
}
