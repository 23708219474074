// @flow

import * as React from 'react'
import { useMemo, memo, useState } from 'react'
import {
  createAttributeDefinitionsApi,
  baseUri as attributeDefinitionsBaseUri,
} from '@r1-webui/productcatalog-attributedefinitions-v1'
import {
  createCategoriesApi,
  baseUri as categoriesBaseUri,
} from '@r1-webui/productcatalog-categories-v1'
import {
  createCategoryManagementApi,
  baseUri as categoryManagementBaseUri,
} from '@r1-webui/productcatalog-categorymanagement-v1'

import { categoryApi, UI_API_PREFIXES } from '../../api'
import { categoryService } from '../../services'
import { processResponse } from '../../components/utils/webUi'
import { categoryAttributesService } from '../../services/categoryAttributesService'
import { ServiceContext } from './provider'

type TCategoryContainerProps = {
  children?: React.Node,
  httpClient: any,
}

export const CategoryContainer = memo<TCategoryContainerProps>((props: TCategoryContainerProps) => {
  const { httpClient, children } = props
  const [allCategories, setAllCategories] = useState([])

  const apiMemo = useMemo(
    () => ({
      categoryService: categoryService(categoryApi(httpClient)),

      categoryAttributesService: categoryAttributesService({
        api: {
          attributeDefinitions: createAttributeDefinitionsApi({
            http: httpClient,
            urlPrefix: `${UI_API_PREFIXES.CATALOG}/${attributeDefinitionsBaseUri}`,
          }),
          categories: createCategoriesApi({
            http: httpClient,
            urlPrefix: `${UI_API_PREFIXES.CATALOG}/${categoriesBaseUri}`,
          }),
          categoryManagement: createCategoryManagementApi({
            http: httpClient,
            urlPrefix: `${UI_API_PREFIXES.CATALOG_MANAGEMENT}/${categoryManagementBaseUri}`,
          }),
        },
        responseInterceptor: processResponse,
      }),
      allCategories,
      setAllCategories,
      httpClient,
    }),
    [httpClient],
  )

  return <ServiceContext.Provider value={apiMemo}>{children}</ServiceContext.Provider>
})
