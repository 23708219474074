import React, { FC, useMemo, useState } from 'react'
import {
  DepositFilter,
  DepositStatus,
  DepositType,
} from '@r1-webui/gowholesale-depositmanagement-v1'
import { Button, DatePicker, Flex, FormField, Input, Select, TagInput } from '@r1/ui-kit'
import { DepositsGridControllerPayload } from '../types'
import { FILTER_STATUSES, FILTER_TYPES } from '../const'

type Props = Pick<DepositsGridControllerPayload, 'filter' | 'onChangeFilter'>

export const DepositsGridFilter: FC<Props> = ({ filter: initialFilter, onChangeFilter }) => {
  const [filter, setFilter] = useState<typeof initialFilter>(initialFilter)

  const onIdsFilterChange = (ids: string[]) => {
    setFilter(currentFilter => ({ ...currentFilter, ids }))
  }

  const onStatusesChange = (depositStatuses: DepositStatus[]) => {
    setFilter(currentFilter => ({ ...currentFilter, depositStatuses }))
  }

  const onTypesChange = (depositTypes: DepositType[]) => {
    setFilter(currentFilter => ({ ...currentFilter, depositTypes }))
  }

  const onCreationDateChange = (dateType: 'from' | 'to') => (value: string | null) => {
    setFilter(currentFilter => ({
      ...currentFilter,
      creationDate: { ...currentFilter.creationDate, [dateType]: value },
    }))
  }

  const onCreationDateRemove = (dateType: 'from' | 'to') => () => {
    setFilter(currentFilter => ({
      ...currentFilter,
      creationDate: { ...currentFilter.creationDate, [dateType]: null },
    }))
  }

  const onCustomerCompanyChange = (value: string) => {
    setFilter(currentFilter => ({ ...currentFilter, customerCompany: value }))
  }

  const processFilterBeforeSubmit = (): DepositFilter => {
    return {
      ...filter,
      customerCompany: filter.customerCompany?.trim() || undefined,
    }
  }

  const submitFilter = () => {
    const newFilter = processFilterBeforeSubmit()
    setFilter(newFilter)
    onChangeFilter(newFilter)
  }

  const depositIds = useMemo<string[]>(() => (filter.ids ?? []).map(String), [filter.ids])

  return (
    <Flex column spaceBetween="XS">
      <Flex spaceBetween="XL">
        <Flex column basis={0.4}>
          <FormField>
            <FormField.Label>Deposit IDs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={2}
              tagLimit={5}
              placeholder="Enter values"
              value={depositIds}
              accept={/^\d+$/}
              delimiter={['\n', ',']}
              onChange={onIdsFilterChange}
            />
          </FormField>

          <FormField>
            <FormField.Label>Statuses</FormField.Label>
            <Select
              multiple
              placeholder="Choose Statuses"
              options={FILTER_STATUSES}
              value={filter.depositStatuses ?? []}
              onChange={onStatusesChange}
            />
          </FormField>

          <FormField>
            <FormField.Label>Company Name</FormField.Label>
            <Input
              placeholder="Enter value"
              value={filter.customerCompany || ''}
              onChange={onCustomerCompanyChange}
            />
          </FormField>
        </Flex>

        <Flex column>
          <FormField>
            <FormField.Label>Types</FormField.Label>
            <Select
              multiple
              placeholder="Choose Types"
              options={FILTER_TYPES}
              value={filter.depositTypes ?? []}
              onChange={onTypesChange}
            />
          </FormField>

          <FormField>
            <FormField.Label>Creation Date</FormField.Label>
            <Flex spaceBetween="XL">
              <Flex align="center" spaceBetween="M">
                <span>From:</span>
                <DatePicker
                  value={filter.creationDate?.from}
                  onChange={onCreationDateChange('from')}
                  onRemove={onCreationDateRemove('from')}
                />
              </Flex>

              <Flex align="center" spaceBetween="M">
                <span>To:</span>
                <DatePicker
                  value={filter.creationDate?.to}
                  onChange={onCreationDateChange('to')}
                  onRemove={onCreationDateRemove('to')}
                />
              </Flex>
            </Flex>
          </FormField>
        </Flex>
      </Flex>

      <Flex justify="flex-start">
        <Button onClick={submitFilter}>Search</Button>
      </Flex>
    </Flex>
  )
}
