export const Action = {
  CLEAR_TEMPLATE_MODELS: 'CLEAR_TEMPLATE_MODELS',
  LOAD_TEMPLATE_MODELS: 'LOAD_TEMPLATE_MODELS',
  LOAD_PAGE_SETTINGS: 'LOAD_PAGE_SETTINGS',
  CREATE_DOCUMENT_TEMPLATE: 'CREATE_DOCUMENT_TEMPLATE',
  LOAD_DOCUMENT_TEMPLATE: 'LOAD_DOCUMENT_TEMPLATE',
  SAVE_DOCUMENT_TEMPLATE: 'SAVE_DOCUMENT_TEMPLATE',
  CLOSE_CREATE_DOCUMENT_TEMPLATE_PANEL: 'CLOSE_CREATE_DOCUMENT_TEMPLATE_PANEL',

  CHANGE_NAME: 'CHANGE_NAME',
  CHANGE_TEMPLATE_MODEL: 'CHANGE_TEMPLATE_MODEL',
  CHANGE_PAGE_SETTING: 'CHANGE_PAGE_SETTING',
  CHANGE_ZOOM: 'CHANGE_ZOOM',
  ADD_BLOCK: 'ADD_BLOCK',
  REMOVE_BLOCK: 'REMOVE_BLOCK',
  SELECT_BLOCK: 'SELECT_BLOCK',
  CHANGE_BLOCK_TRANSFORM: 'CHANGE_BLOCK_TRANSFORM',
  CHANGE_BLOCK_PROPERTY: 'CHANGE_BLOCK_PROPERTY',

  ADD_BLOCK_VALUE: 'ADD_BLOCK_VALUE',
  REMOVE_BLOCK_VALUE: 'REMOVE_BLOCK_VALUE',
  CHANGE_BLOCK_VALUE: 'CHANGE_BLOCK_VALUE',

  UNDO: 'UNDO',
  REDO: 'REDO',
}
/*  TextBlock
      | BarcodeBlock
      | QrCodeBlock
      | ContainerBlock
      | ImageBlock
      | LineBlock
      | RectangleBlock
      | RepeaterBlock
*/
export const BlockType = {
  TEXT_BLOCK: 'TextBlock',
  BARCODE_BLOCK: 'BarcodeBlock',
  QR_CODE_BLOCK: 'QrCodeBlock',
  RECTANGLE_BLOCK: 'RectangleBlock',
  REPEATER_BLOCK: 'RepeaterBlock',
  IMAGE_BLOCK: 'ImageBlock',
}

export const FontSizes = [6, 7, 8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 24, 26, 28, 36]

export const BarcodeFormat = {
  CODE_128_AUTO: 'Code128Auto',
  TEMPLATE_IDENTIFIER: 'TemplateIdentifier',
}

export const RepeaterDirection = {
  HORISONTAL: 'Horizontal',
  VERTICAL: 'Vertical',
}

export const ValueType = {
  CONSTANT: 'ConstantValue',
  PROPERTY: 'PropertyValue',
  CONDITIONAL: 'ConditionalValue',
}

export const ConditionType = {
  NOT_EMPTY: 'NotEmptyCondition',
}

export const Ids = {
  DRAG_CONTAINER_ID: -5000,
}

export const Handles = {
  TOP_HANDLE: 'TOP_HANDLE',
  BOTTOM_HANDLE: 'BOTTOM_HANDLE',
  LEFT_HANDLE: 'LEFT_HANDLE',
  RIGHT_HANDLE: 'RIGHT_HANDLE',
}

export const InputType = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  NUMBERGROUP: 'NUMBERGROUP',
  SELECT: 'SELECT',
  CHECKBOX: 'CHECKBOX',
}
