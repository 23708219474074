import styled from '@emotion/styled'

export const Username = styled('span')`
  color: ${({ theme }) => theme.palette.blue[600]};
`

export const DateElem = styled('span')`
  font-size: ${({ theme }) => theme.space.S};
  color: ${({ theme }) => theme.palette.grey[600]};
`

export const NoMessagesText = styled('div')`
  color: ${({ theme }) => theme.palette.grey[600]};
`
