// @flow

import React, { memo } from 'react'
import styled from '@emotion/styled'
import { FormField, Flex, HR } from '@r1/ui-kit'
import { Value, NotSpecified } from '../../Common'

import type { ProductFieldsProps } from '../../../../../../types/product.type'

const NA = styled('span')`
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const parseDimension = dimension => (dimension != null ? parseFloat(dimension) : <NA>N/A</NA>)

function mapKeyToLabel(key: string): string {
  const schema = {
    productWeight: 'Product Weight',
    packageWeight: 'Shipping Weight',
    productDimensions: 'Product Dimensions',
    packageDimensions: 'Shipping Dimensions',
  }

  return schema[key]
}

function extractValue(field: any, key) {
  switch (key) {
    case 'packageDimensions':
    case 'productDimensions': {
      const { height, length, width, measure } = field

      return !height && !length && !width ? (
        <NotSpecified />
      ) : (
        <>
          {parseDimension(height)} × {parseDimension(length)} × {parseDimension(width)}{' '}
          {measure || ''}
        </>
      )
    }
    case 'productWeight':
    case 'packageWeight': {
      const { amount, measure } = field
      return !amount ? (
        <NotSpecified />
      ) : (
        <>
          {parseDimension(amount)} {measure || ''}
        </>
      )
    }
    default:
      return ''
  }
}

export const Dimensions = memo<ProductFieldsProps>((props: ProductFieldsProps) => {
  const { fields } = props

  const mappedFields = Object.keys(fields).reduce((acc, key) => {
    const field = fields[key]
    const label = mapKeyToLabel(key)
    if (label && field && field.length !== 0) acc.push({ key, label, field })
    return acc
  }, [])

  return mappedFields.map(({ key, label, field }, index) => {
    return (
      <Flex key={key}>
        {index !== 0 ? <HR /> : null}
        <Flex spaceBetween="XXS">
          <FormField.Label>{label}</FormField.Label>
          <Value>{extractValue(field, key)}</Value>
        </Flex>
      </Flex>
    )
  })
})
