// @flow

import React, { memo, useContext, useCallback, useEffect, useState, useMemo } from 'react'
import { createCategoriesApi, baseUri } from '@r1-webui/productcatalog-categories-public-v1'
import { TreeSelect } from '@r1/ui-kit'
import { ServiceContext } from '../../../containers/ProductContainer/provider'

import type { CategoriesTreeSelectProps as Props } from './types'

export const CategoriesTreeSelect = memo<Props>((props: Props) => {
  const { allCategories, setAllCategories } = useContext(ServiceContext)

  const { hideCategoryIds } = props

  const { getAllCategories } = createCategoriesApi({
    http: props.httpClient,
    urlPrefix: `/uisvc/company-catalog/${baseUri}`,
  })

  const [categoriesList, setCategoriesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (allCategories.length > 0) {
      // if context has this array, take it
      setCategoriesList(allCategories)
    } else {
      // if context doesn't have this array, get it and push in context
      setIsLoading(true)
      const categories = await getAllCategories()

      setIsLoading(false)
      setCategoriesList(categories.status === 200 ? categories.body : [])
      setAllCategories(categories.status === 200 ? categories.body : [])
    }
    // on old pages we doesnt have context, and without it it reload recursive
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const memoCategories = useMemo(
    () =>
      hideCategoryIds && hideCategoryIds.length > 0
        ? categoriesList.filter(category => hideCategoryIds.indexOf(category.id) === -1)
        : categoriesList,
    [categoriesList, hideCategoryIds],
  )

  return props.multiple === false ? (
    <TreeSelect
      data-test-id="parent-category"
      multiple={false}
      options={memoCategories}
      loading={isLoading}
      placeholder={props.placeholder || ''}
      width={props.width || undefined}
      error={props.error || false}
      ignoreBranchNodes={props.ignoreBranchNodes || false}
      value={props.categoryId}
      disabled={props.disabled || false}
      onChange={(props: any).onChangeCategoryId}
    />
  ) : (
    <TreeSelect
      multiple
      data-test-id="parent-category"
      options={memoCategories}
      loading={isLoading}
      placeholder={props.placeholder || ''}
      width={props.width || undefined}
      error={props.error || false}
      value={props.categoriesIds}
      disabled={props.disabled || false}
      onChange={(props: any).onChangeCategoriesIds}
    />
  )
})
