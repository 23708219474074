// @flow

import * as yup from 'yup'
import type { ObjectSchema } from 'yup/lib/object'

export const valueConstraintSchema: ObjectSchema<any> = yup
  .object()
  .shape({
    valueType: yup.string(),
    minAllowedValue: yup.number().nullable(),
    maxAllowedValue: yup
      .number()
      .nullable()
      .when(
        'minAllowedValue',
        ({
          is: val => val != null,
          then: yup
            .number()
            .moreThan(
              yup.ref('minAllowedValue'),
              '"from" value can\'t be greater or equal than "to" value',
            )
            .nullable(),
          otherwise: yup.number().nullable(),
          // because flow think it is WhenOptionsBuilderFunction, but it is WhenOptionsBuilderObject
        }: any),
      ),

    minAllowedLength: yup.number().nullable(),
    maxAllowedLength: yup
      .number()
      .nullable()
      .when(
        'minAllowedLength',
        ({
          is: val => val != null,
          then: yup
            .number()
            .moreThan(
              yup.ref('minAllowedLength'),
              '"from" value can\'t be greater or equal than "to" value',
            )
            .nullable(),
          otherwise: yup.number().nullable(),
        }: any),
      ),

    minAllowedDate: yup.string().nullable(),
    maxAllowedDate: yup
      .string()
      .when('minAllowedDate', (minAllowedDate, schema) =>
        schema.test(
          'dateminmax',
          '"from" value can\'t be greater or equal than "to" value',
          val => {
            return !val || !minAllowedDate || new Date(val) > new Date(minAllowedDate)
          },
        ),
      )
      .nullable(),
  })
  .nullable()
