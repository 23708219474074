// @flow

import React from 'react'

import { Loader } from '@r1/ui-kit'

import { orderId } from '../../utils'
import type { StepsProps } from '../ClaimCreationForm/types'
import { OrderSelectionItem } from './OrderSelectionItem'
import { EmptyData, OrderList } from './StyledComponents'

export const OrderSelectionList = (props: StepsProps) => {
  const {
    orderSearchAction,
    foundOrders,
    orderStatuses,
    rmaProfiles,
    shippingAddresses,
    selectedOrder,
  } = props.orderSearchInfo

  if (orderSearchAction.isExecuting) {
    return <Loader />
  }

  if (!foundOrders || !foundOrders.length) {
    return (
      <OrderList>
        <EmptyData>No orders found</EmptyData>
      </OrderList>
    )
  }

  const isSelected = order => {
    return (
      selectedOrder.value != null &&
      order.rmaProfileId === selectedOrder.value.rmaProfileId &&
      order.purchaseOrderId === selectedOrder.value.purchaseOrderId
    )
  }

  const orderListItems = foundOrders.map(order => {
    const orderStatusInfo = orderStatuses.get(orderId(order))
    if (!orderStatusInfo) {
      return null
    }

    const rmaProfileInfo = rmaProfiles.get(order.rmaProfileId)
    if (!rmaProfileInfo) {
      return null
    }

    const shippingAddressInfo = shippingAddresses.get(order.shippingInfo.addressId)
    if (!shippingAddressInfo) {
      return null
    }

    const { orderStatus } = orderStatusInfo
    const { rmaProfile } = rmaProfileInfo
    const { shippingAddress } = shippingAddressInfo

    return (
      <OrderSelectionItem
        key={orderId(order)}
        order={order}
        orderStatus={orderStatus}
        rmaProfile={rmaProfile}
        shippingAddress={shippingAddress}
        isSelected={isSelected(order)}
        // Allow create claim for any order, not only shipped (except canceled).
        // List of available expected decisions will be additionally
        // filtered according to order state.
        isClaimCanBeCreated={orderStatus.status !== 'Cancelled'}
        claimCannotBeCreatedMessage="Claim cannot be created for cancelled order."
        onSelection={selectedOrder.onChange}
      />
    )
  })

  return <OrderList>{orderListItems}</OrderList>
}
