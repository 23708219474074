// @flow

import * as React from 'react'

import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Link, NotificationSystem } from '@r1/ui-kit'

import type { Field } from '../EditProfileScreen/types'
import { Loader } from '../../../../components/Loader'
import type { ProfileListScreenProps } from '../../types/ProfileListScreen'
import {
  getInitialData,
  approveProfileSharing,
  cancelProfileSharing,
  getSharedCompanies,
} from './helpers'
import { ProfilesCard } from './cards/ProfilesCard'
import { ProfileTemplatesCard } from './cards/ProfileTemplatesCard'
import type { ProfileListScreenState } from './types'

class ProfileListScreen extends React.Component<ProfileListScreenProps, ProfileListScreenState> {
  state = {
    purposeId: null,
    // eslint-disable-next-line react/no-unused-state
    purposeName: null,
    profiles: [],
    purposes: [],
    sharedCompanies: [],
    purposeInfo: {},
    profilesLoading: { all: false },
    // eslint-disable-next-line react/no-unused-state
    currentProfile: null,
    // showModal: false,
    isLoading: false,
    httpClient: null,
    onError: () => {},
  }

  static getDerivedStateFromProps(
    nextProps: ProfileListScreenProps,
    state: ProfileListScreenState,
  ) {
    const { purposeId, httpClient, onError } = nextProps

    if (!state.purposeId || !state.httpClient || state.onError) {
      return { purposeId, httpClient, onError }
    }

    return null
  }

  componentDidMount() {
    this.setInitialData()
  }

  componentDidUpdate(prevProps: ProfileListScreenProps) {
    const { purposeId } = this.props
    const hasNewId = purposeId !== prevProps.purposeId

    if (hasNewId) {
      this.setInitialData()
    }
  }

  setInitialData = () => {
    const { purposeId, httpClient, onError } = this.state

    this.setState({ isLoading: true }, () =>
      getInitialData({ purposeId, httpClient, onError }).then(initialData =>
        this.setState({ ...initialData, isLoading: false }),
      ),
    )
  }

  setProfilesLoading = ({
    id,
    isLoading = false,
    cb = () => {},
  }: {
    id: ?string | ?number,
    isLoading?: boolean,
    cb?: any,
  }) => {
    const { profilesLoading } = this.state

    if (id) {
      this.setState(
        {
          profilesLoading: { ...profilesLoading, [id]: isLoading },
        },
        cb,
      )
    }
  }

  updateSharedCompanies = () => {
    const { httpClient, onError } = this.state

    getSharedCompanies(httpClient, onError).then(sharedCompanies =>
      this.setState({ sharedCompanies }),
    )
  }

  onAcceptSharing = ({ id }: Field = {}) => {
    const { httpClient, onError } = this.state

    this.setProfilesLoading({
      id,
      isLoading: true,
      cb: () => {
        approveProfileSharing({ profileId: id, httpClient, onError })
          .then(() => this.updateSharedCompanies())
          .then(() => {
            NotificationSystem.addNotification({
              level: 'success',
              message: 'Shared profile',
            })
            this.setProfilesLoading({ id })
          })
          .catch(() => {
            NotificationSystem.addNotification({ level: 'error', message: 'Shared profile' })
            this.setProfilesLoading({ id })
          })
      },
    })
  }

  onRejectSharing = ({ id }: Field = {}) => {
    const { httpClient, onError } = this.state

    this.setProfilesLoading({
      id,
      isLoading: true,
      cb: () => {
        cancelProfileSharing({ profileId: id, httpClient, onError })
          .then(() => this.updateSharedCompanies())
          .then(() => {
            NotificationSystem.addNotification({
              level: 'success',
              message: 'Cancelled sharing',
            })
            this.setProfilesLoading({ id })
          })
          .catch(() => {
            NotificationSystem.addNotification({
              level: 'success',
              message: 'Error while trying to cancel sharing',
            })
            this.setProfilesLoading({ id })
          })
      },
    })
  }

  render() {
    const { getProfileUrl, getProfileCreationUrl } = this.props
    const {
      profiles,
      purposes,
      purposeId,
      purposeInfo,
      // currentProfile,
      profilesLoading,
      sharedCompanies,
      // showModal,
      isLoading,
    } = this.state
    const { groupName, name } = purposeInfo

    return (
      <Main>
        <ContentHeader>
          <ContentHeader.Breadcrumbs>
            <Link to="/r1/admin/profiles">External Service Integrations</Link>
          </ContentHeader.Breadcrumbs>
          <ContentHeader.Title>{name}</ContentHeader.Title>
        </ContentHeader>
        {isLoading ? (
          <Loader />
        ) : (
          <Content>
            <ProfileTemplatesCard
              purposeId={purposeId}
              purposeName={groupName}
              items={purposes}
              getProfileCreationUrl={getProfileCreationUrl}
            />
            <ProfilesCard
              profiles={profiles}
              sharedCompanies={sharedCompanies}
              purposeName={groupName}
              profilesLoading={profilesLoading}
              getProfileUrl={getProfileUrl}
              onAcceptSharing={this.onAcceptSharing}
              onRejectSharing={this.onRejectSharing}
            />
          </Content>
        )}
      </Main>
    )
  }
}

export { ProfileListScreen }
