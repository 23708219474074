// @flow

const uriPrefix = `webclientprint3:${document.location.origin}/uisvc/printing/api/wcpp`

// $FlowFixMe[missing-annot]
export const printJobIds = ({ jobIds }) => {
  const url = `${uriPrefix}/print?ids=${jobIds.join(',')}`

  const iframe = document.createElement('iframe')
  iframe.src = url
  iframe.style.display = 'none'

  if (document.body) document.body.appendChild(iframe)
}
