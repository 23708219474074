import * as React from 'react'
import { legacyEspm, ProfileType } from '../../../api/legacyEspm'

export const useShippingProfiles = () => {
  const [profiles, setProfiles] = React.useState<ProfileType[]>([])

  const getProfiles = React.useCallback(async () => {
    const profilesData = await legacyEspm.getTypesByPurpose('ShippingProfile')
    setProfiles(profilesData)
  }, [])

  React.useEffect(() => {
    getProfiles()
  }, [])

  return { profiles }
}
