import { emailRegexp } from '../../../regExps'

export type Validator = (value: string) => boolean
export type Messenger = (value: string) => string

export type ValidationStep = {
  validator: Validator
  messenger: Messenger
}

export type ValidationSchema = ValidationStep[]

/** Helpers */

const isRequired: ValidationStep = {
  validator: (value: string) => !value.length,
  messenger: () => 'Field is required!',
}

function minLength(count: number): ValidationStep {
  return {
    validator: (value: string) => value.length < count,
    messenger: () => `Must be more than ${count} characters long`,
  }
}

function maxLength(count: number): ValidationStep {
  return {
    validator: (value: string) => value.length > count,
    messenger: () => `Can't be more than ${count} characters long`,
  }
}

function regExpMatch(regExp: RegExp, message: string): ValidationStep {
  return {
    validator: (value: string) => !regExp.test(value),
    messenger: () => message,
  }
}

/** User */

const login: ValidationSchema = [
  isRequired,
  minLength(3),
  maxLength(100),
  regExpMatch(/^[a-z_0-9]{3,100}$/, 'May consist of a-z, _, 0-9'),
]

const password: ValidationSchema = [
  isRequired,
  minLength(8),
  maxLength(50),
  regExpMatch(/[A-Z]+/, 'At least one uppercase character'),
  regExpMatch(/[a-z]+/, 'At least one lowercase character'),
  regExpMatch(/[0-9]+/, 'At least one digit'),
  regExpMatch(
    /^[\w!@#$%^&*()_+|\-=\\{}:;"'<>,./?~]+$/,
    'Only English letters, digits(0-9) and symbols !@#$%^&*()_+|-={}:;"\'<>,./?~',
  ),
]

export type SchemaName =
  | 'login'
  | 'password'
  | 'fullName'
  | 'jobTitle'
  | 'department'
  | 'email'
  | 'phone'
  | 'phoneExtension'
  | 'roleName'
  | 'roleDescription'
  | 'userCompanyId'
  | 'locationId'
  | 'timeZoneId'

export const schemas: Record<SchemaName, ValidationSchema> = {
  /** User Info */
  login,
  password,
  fullName: [isRequired, maxLength(100)],
  jobTitle: [maxLength(100)],
  department: [maxLength(100)],
  email: [regExpMatch(emailRegexp, 'Invalid value')],
  phone: [maxLength(15), regExpMatch(/^\d*$/, 'Should contain only numbers')],
  phoneExtension: [maxLength(6), regExpMatch(/^\d*$/, 'Should contain only numbers')],
  userCompanyId: [isRequired],
  locationId: [isRequired],
  timeZoneId: [isRequired],

  /** Create role */
  roleName: [isRequired, maxLength(150)],
  roleDescription: [maxLength(500)],
}
