import React, { useCallback, useEffect, useState } from 'react'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Placeholder, Link } from '@r1/ui-kit'
import { useNavigate, useParams } from 'react-router-dom'
import { discountsRulesService } from '../services'
import { DiscountRule } from '../types'
import { DiscountsRuleForm } from '../components/DiscountsRuleForm'
import { ROUTES } from '../../../navigation'

export const EditDiscountsRule = () => {
  const { discountId } = useParams<{ discountId: string }>()
  const navigate = useNavigate()
  const [discountRule, setDiscountRule] = useState<DiscountRule | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const fetchDiscountRule = useCallback(async () => {
    setIsLoading(true)
    try {
      if (!discountId) {
        return
      }
      const rule = await discountsRulesService.getDiscountRule(discountId)
      setDiscountRule(rule)
      setIsLoading(false)
    } catch (error) {
      if ((error as Record<string, unknown>).status === 404) navigate(ROUTES.NOT_FOUND)
    }
  }, [navigate, discountId])

  const handleSubmit = useCallback(
    async (settings: DiscountRule) => {
      if (discountRule === null) return
      try {
        setIsSubmitting(true)
        const newVersion = await discountsRulesService.updateDiscountRule(
          discountRule.id,
          discountRule.version,
          settings,
        )
        setDiscountRule({ ...settings, version: newVersion })
      } finally {
        setIsSubmitting(false)
      }
    },
    [discountRule],
  )

  const handleClose = useCallback(() => {
    navigate(ROUTES.DISCOUNTS)
  }, [navigate])

  useEffect(() => {
    fetchDiscountRule()
  }, [fetchDiscountRule])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSalesFlowRulesEdit]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.DISCOUNTS}>Volume Discount Rules</Link>
        </ContentHeader.Breadcrumbs>

        <Content>
          <ContentHeader.Title>{`Edit Discount Rule ${
            discountRule ? `"${discountRule.name}"` : ''
          } `}</ContentHeader.Title>

          {discountRule && (
            <DiscountsRuleForm
              initialDiscountRule={discountRule}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
              onClose={handleClose}
            />
          )}
          {isLoading && <Placeholder type="form" height={5} />}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
