// @flow

import * as yup from 'yup'
import type { ObjectSchema } from 'yup/lib/object'

const getNumberMinMaxScheme = (min, max) =>
  yup
    .number()
    .concat(min ? yup.number().min(min) : yup.number())
    .concat(max ? yup.number().max(max) : yup.number())

const getStringMinMaxScheme = (min, max) =>
  yup
    .string()
    .concat(min ? yup.string().min(min) : yup.string())
    .concat(max ? yup.string().max(max) : yup.string())

const getDateMinMaxScheme = (min, max) =>
  yup
    .string()
    .test(
      'minmax',
      'Default value is out of constraint',
      val =>
        (!val ||
          !min ||
          new Date(val).setHours(0, 0, 0, 0) >= new Date(min).setHours(0, 0, 0, 0)) &&
        (!val || !max || new Date(val).setHours(0, 0, 0, 0) <= new Date(max).setHours(0, 0, 0, 0)),
    )

export const defaultValueSchema: ObjectSchema<any> = yup
  .object()
  .when(
    ['hasConstraint', 'valueConstraint'],
    (
      hasConstraint,
      valueConstraint: any,
      schema: ObjectSchema<any>,
      value: any,
    ): ObjectSchema<any> => {
      if (!hasConstraint) return schema
      switch (valueConstraint.valueType) {
        case 'EnumerationConstraint':
          // we've already filtered allowedVariants in select
          return schema
        case 'NumberConstraint':
          return schema.shape({
            value: getNumberMinMaxScheme(
              valueConstraint.minAllowedValue,
              valueConstraint.maxAllowedValue,
            )
              .concat(yup.number().notOneOf(valueConstraint.forbiddenValues).label('Default value'))
              .nullable(),
          })
        case 'NumberRangeConstraint':
          return schema.shape({
            from: getNumberMinMaxScheme(
              valueConstraint.minAllowedValue,
              valueConstraint.maxAllowedValue,
            )
              .label('"from"')
              .nullable(),
            to: getNumberMinMaxScheme(
              valueConstraint.minAllowedValue,
              valueConstraint.maxAllowedValue,
            )
              .concat(
                value.value.from != null
                  ? yup
                      .number()
                      .moreThan(
                        yup.ref('from'),
                        '"from" value can\'t be greater or equal than "to" value',
                      )
                  : yup.number(),
              )
              .label('"to"')
              .nullable(),
          })
        case 'StringConstraint':
          return schema.shape({
            value: getStringMinMaxScheme(
              valueConstraint.minAllowedLength,
              valueConstraint.maxAllowedLength,
            )
              .concat(
                yup
                  .string()
                  .test('forbiddenWords', 'Default Value cannot contain forbidden words', val => {
                    // checking that Default Value doesnt contain forbidden words
                    const words = val ? val.split(/[\\ ]+/) : []
                    const int = words.filter(v => valueConstraint.forbiddenWords.includes(v))
                    return int.length === 0
                  }),
              )
              .label('Default value')
              .nullable(),
          })
        case 'DateConstraint':
          return schema.shape({
            value: getDateMinMaxScheme(
              valueConstraint.minAllowedDate,
              valueConstraint.maxAllowedDate,
            )
              .label('Default value')
              .nullable(),
          })

        case 'DateRangeConstraint':
          return schema.shape({
            from: getDateMinMaxScheme(
              valueConstraint.minAllowedDate,
              valueConstraint.maxAllowedDate,
            )
              .label('"from"')
              .nullable(),
            to: getDateMinMaxScheme(valueConstraint.minAllowedDate, valueConstraint.maxAllowedDate)
              .label('"to"')
              .nullable(),
          })
        default:
          return schema
      }
    },
  )
