// @flow

import type { AttributeValue } from '@r1-webui/productcatalog-categorymanagement-v1/src/types'

const dateFormat = (date: string): string => date.replace('+00:00', '.000')

/**
 * All transform Attribute Value for next work with it inside SetupAttributeDrawer
 * Now:
 * 1) transorm Date for correct work with timezone
 * 2) transform Decimal (String to Float) for correct yup validation
 */

export const transformValueTypes = (value: AttributeValue): AttributeValue => {
  switch (value.valueType) {
    case 'DateRange':
      return {
        valueType: 'DateRange',
        from: value.from != null ? dateFormat(value.from) : undefined,
        to: value.to != null ? dateFormat(value.to) : undefined,
      }
    case 'DateValue':
      return { valueType: 'DateValue', value: dateFormat(value.value) }
    case 'DecimalValue':
      // $FlowFixMe[incompatible-return]
      return { valueType: 'DecimalValue', value: parseFloat(value.value) }
    case 'IntRange':
      return {
        valueType: 'IntRange',
        from: value.from != null ? value.from : undefined,
        to: value.to != null ? value.to : undefined,
      }
    case 'DecimalRange':
      return {
        valueType: 'DecimalRange',

        // $FlowFixMe[incompatible-return]
        from: value.from != null ? parseFloat(value.from) : undefined,

        // $FlowFixMe[incompatible-return]
        to: value.to != null ? parseFloat(value.to) : undefined,
      }
    case 'StringValue':
      return value
    case 'IntValue':
      return value
    case 'BoolValue':
      return value
    case 'EnumValue':
      return value
    default:
      return value
  }
}
