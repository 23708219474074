// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { useMemo, useCallback, useRef } from 'react'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { GridGenerator, setQuery, parseQuery } from '@r1/grid-generator'
import type { CustomColumnSettings, DataItem } from '@r1/grid-generator/build/types/GridGenerator'
import { Flex, Button, Icon, NotificationSystem } from '@r1/ui-kit'

import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { ROUTES } from '../../../../navigation'
import { httpClient } from '../../../../httpClient'
import type { BusinessClaimType } from '../../types/common'
import { Notification } from '../../components/NotificationTooltip'

const ActionButton = ({ title, action, icon = '', color = 'inverted' }) => (
  <Button onClick={action}>
    {icon ? <Icon type={icon} color={color} /> : null}
    {title}
  </Button>
)

type Props = {
  claimType: BusinessClaimType,
}

export const ViewClaimsGrid = ({ claimType }: Props) => {
  const navigate = useNavigate()

  const redirectToClaimCreatePage = useCallback(
    () => navigate(claimType === 'B2C' ? ROUTES.CLAIM_CREATE_B2C : ROUTES.CLAIM_CREATE_B2B),
    [navigate, claimType],
  )
  const initialStateQuery = useMemo(() => {
    const query = parseQuery()
    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: 25,
      },
      sorting: {
        column: {
          settingsId: 'NotificationsId',
          schemaId: 'Notifications',
        },
        direction: 'Desc',
      },
    }
  }, [])
  const gridRef = useRef<GridGenerator | null>(null)
  const [{ allowNewRmaVendorPortalCreate }] = useAccessControl()
  const renderToolbarActionButtons = useCallback(() => {
    const actions = []
    if (allowNewRmaVendorPortalCreate) {
      actions.push(<ActionButton title="Create" action={redirectToClaimCreatePage} icon="plus" />)
    }
    return actions
  }, [allowNewRmaVendorPortalCreate, redirectToClaimCreatePage])

  const notificationsColumn: CustomColumnSettings<DataItem> = useMemo(
    () => ({
      cell: {
        $type: 'custom',
        renderer: (item: DataItem) => {
          let notifyFlags = parseInt(item.Notifications, 10)
          // eslint-disable-next-line no-restricted-globals
          if (isNaN(notifyFlags)) {
            NotificationSystem.addNotification({ title: 'Rendering grid error', level: 'error' })
            notifyFlags = 0
          }
          return <Notification notificationFlagSet={notifyFlags} />
        },
      },
      columnId: 'NotificationsId',
    }),
    [],
  )

  const initialCustomColumnsSettings = useMemo(() => [notificationsColumn], [notificationsColumn])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowNewRmaVendorPortalView]}>
      <Main fullScreen>
        <ContentHeader />
        <Content>
          <ContentHeader.Title>{`${claimType} Marketplace RMA Claims`}</ContentHeader.Title>
          <Flex column mt="XXL" mb="M">
            <GridGenerator
              ref={gridRef}
              exportable
              gridId={claimType === 'B2C' ? 'vpaggregator' : 'vpaggregator_b2b'}
              gridEngineBaseUrl="/uisvc/rma-claim-grid"
              initialStateQuery={initialStateQuery}
              httpClient={httpClient}
              initialCustomColumnsSettings={initialCustomColumnsSettings}
              aggregationCount={{ title: 'All Claims' }}
              toolbarActionButtons={renderToolbarActionButtons()}
              onChangeState={setQuery}
            />
          </Flex>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
