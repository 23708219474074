import styled from '@emotion/styled'
import { Box, Button, Flex, FormField, H1, Icon, Input, Modal } from '@r1/ui-kit'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import React, { useCallback, useMemo, useState } from 'react'

const Title = styled(H1)`
  margin-bottom: 0 0 15px 0;
`

export type SubStampValueModalProps = {
  show: boolean
  modalTitle: string
  subStampId: string
  value?: string
  onSaveValue: (name: string) => Promise<boolean>
  onCancel: () => void
}

export const SubStampValueModal = (props: SubStampValueModalProps) => {
  const [subStampValueName, setSubStampValueName] = useState<string>(props.value ?? '')
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const onCancel = useCallback(() => {
    props.onCancel()
    setSubStampValueName('')
  }, [props])

  const onSave = useCallback(async () => {
    setIsSaving(true)
    const success = await props.onSaveValue(subStampValueName)
    if (success) {
      onCancel()
    }
    setIsSaving(false)
  }, [onCancel, props, subStampValueName])

  const modalActionButtons: ControlledActionButtons = useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        align: 'right',
        onClick: () => {
          props.onCancel()
          setSubStampValueName('')
        },
      },
      {
        title: 'Save',
        transparent: false,
        color: 'primary',
        onClick: onSave,
        align: 'right',
        disabled: !subStampValueName || isSaving,
        loading: isSaving,
      },
    ],
    [onSave, subStampValueName, props, isSaving],
  )

  const onEscapeKeyDown = () => {
    props.onCancel()
  }

  const onValueChange = (value: string) => {
    setSubStampValueName(value.trim())
  }

  return (
    <Flex>
      <Box>
        <Modal
          isControlled
          show={props.show}
          actionButtons={modalActionButtons}
          onEscapeKeyDown={onEscapeKeyDown}
        >
          <Flex column>
            <Box>
              <Title>{props.modalTitle}</Title>
            </Box>
            <FormField>
              <FormField.Label>Substamp value</FormField.Label>
              <Input
                value={subStampValueName}
                data-test-id="sub-stamp__value__input"
                onChange={onValueChange}
              />
            </FormField>
          </Flex>
        </Modal>
      </Box>
    </Flex>
  )
}

export type EditSubSampValueModalProps = {
  subStampId: string
  value: string
  onUpdateValue: (name: string) => Promise<boolean>
}

export const EditSubStampValueModal = (props: EditSubSampValueModalProps) => {
  const [show, setShow] = useState<boolean>(false)

  const onCancel = () => {
    setShow(false)
  }

  return (
    <React.Fragment>
      <Box>
        <Button
          shape="square"
          data-test-id="sub-stamp__value__input"
          color="secondary"
          onClick={() => setShow(!show)}
        >
          <Icon type="pencil" />
        </Button>
      </Box>
      <SubStampValueModal
        show={show}
        modalTitle="Edit substamp value"
        subStampId={props.subStampId}
        value={props.value}
        onSaveValue={props.onUpdateValue}
        onCancel={onCancel}
      />
    </React.Fragment>
  )
}

export type CreateSubSampValueModalProps = {
  subStampId: string
  onAddValue: (name: string) => Promise<boolean>
}

export const CreateSubStampValueModal = (props: CreateSubSampValueModalProps) => {
  const [show, setShow] = useState<boolean>(false)

  const onCancel = () => {
    setShow(false)
  }
  return (
    <React.Fragment>
      <Box mt="L" alignSelf="flex-end">
        <Button
          data-test-id={`sub-stamp__${props.subStampId}__value__add`}
          color="light"
          onClick={() => setShow(!show)}
        >
          <Icon type="plus" /> Add Value
        </Button>
      </Box>
      <SubStampValueModal
        show={show}
        modalTitle="Add substamp value"
        subStampId={props.subStampId}
        onSaveValue={props.onAddValue}
        onCancel={onCancel}
      />
    </React.Fragment>
  )
}
