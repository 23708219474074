import * as React from 'react'
import { Icon } from '@r1/ui-kit'

export const Label = ({ label, trackingNumber, index }) => {
  return (
    <a
      data-test-id={`label-${index}`}
      href={`data:image/png;base64, ${label}`}
      download={`${trackingNumber}.jpg`}
    >
      <Icon type="internal/upload-note" />
    </a>
  )
}
