/* eslint-disable require-atomic-updates */
import { equals } from 'ramda'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { getArrayDiff } from '../../../../../utils/dataUtils'
import { actions as imagesActions } from '../../../../../modules/productTemplates/images'
import {
  productTemplateImagesApi,
  productTemplateStorageApi,
} from '../../../../../api/productTemplate/index.ts'
import { proceedError, showErrors } from '../../../utils'

const IMAGE_TYPE = {
  Reference: 'Reference',
  Listing: 'Listing',
}

const initialState = {
  images: null,
  isFetched: true,
  initialImages: {},
}

const types = createTypes(['setFetching', 'loadImages', 'setImages', 'uploadImage'], 'images')

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.loadImages]: (state, { images }) => ({
    ...state,
    images,
    initialImages: images,
    isFetched: true,
  }),
  [types.setImages]: (state, { images }) => ({ ...state, images }),
})

const sortByOrder = (a, b) => a.order - b.order

export function loadImages(productTemplateID) {
  return async dispatch => {
    if (productTemplateID === 'new') return
    dispatch({ type: types.setFetching })

    const response = await dispatch(
      imagesActions.fetchAll({ id: productTemplateID }, { force: true }),
    )
    const data = response || []
    const images = {
      reference: data.filter(({ type }) => type === 'Reference').sort(sortByOrder),
      listing: data.filter(({ type }) => type === 'Listing').sort(sortByOrder),
      uploaded: [],
      removed: [],
    }

    dispatch({
      type: types.loadImages,
      images,
    })
  }
}

export function changeImages(images) {
  return async dispatch => {
    dispatch({
      type: types.setImages,
      images,
    })
  }
}

function deleteImage({ id }, productTemplateID, _api) {
  return productTemplateImagesApi.delete({ id: productTemplateID, imageId: id })
}

export async function addImage({ id, url, src }, productTemplateID, type, _api) {
  let imageUrl = url

  if (!url) {
    imageUrl = await productTemplateStorageApi
      .uploadImage({
        base64: src.replace(/data:image\/\w*;base64,/, ''),
      })
      .then(({ body }) => body)
  }

  const createdId = await productTemplateImagesApi
    .add(
      { id: productTemplateID },
      {
        url: imageUrl,
        imageType: type,
      },
    )
    .then(({ body }) => body)

  return {
    guid: id,
    id: createdId,
    url: imageUrl,
  }
}

// eslint-disable-next-line consistent-return
async function setImageType({ id }, productTemplateID, imageType, _api) {
  if (!id) {
    return Promise.resolve()
  }
  await productTemplateImagesApi.changeType({ id: productTemplateID, imageId: id }, { imageType })
}

async function removeImages(images, productTemplateID, api) {
  return images.removed.forEach(image => {
    if (image.url) {
      deleteImage(image, productTemplateID, api)
    }
  })
}

async function addImages(images, productTemplateID, api) {
  const { uploaded } = images
  const { reference } = images
  const { listing } = images

  const requests = uploaded.map(image => {
    let type = null

    if (listing.includes(image)) {
      type = IMAGE_TYPE.Listing
    }

    if (reference.includes(image)) {
      type = IMAGE_TYPE.Reference
    }

    if (type === null) {
      return null
    }

    return addImage(image, productTemplateID, type, api)
  })

  if (!requests.length) {
    return Promise.resolve([])
  }

  return Promise.all(requests.filter(Boolean))
}

async function updateImages({ type, oldImages, newImages, productTemplateID }, api) {
  const { added } = getArrayDiff(oldImages, newImages, equals)

  await Promise.all(added.map(image => setImageType(image, productTemplateID, type, api))).catch(
    () => {
      showErrors(['Failed to set images types'])
    },
  )

  if (!newImages.length) return

  await productTemplateImagesApi
    .reorder(
      { id: productTemplateID, imageType: type },
      newImages.map(item => item.id),
    )
    .then(({ body, status }) => {
      if (status !== 200) {
        const errorData = body || { errors: ['Failed to update images'] }
        // eslint-disable-next-line no-throw-literal
        throw {
          response: { status, data: errorData },
        }
      }

      return body
    })
    .catch(err => {
      showErrors(proceedError(err))
    })
}

export function submitImages(productTemplateID) {
  return async (dispatch, getState, api) => {
    const { initialImages, images } = getState().images

    if (!images) return

    const addedImages = await addImages(images, productTemplateID, api).catch(() => {
      showErrors(['Failed to upload images'])

      return []
    })

    // TODO hotfix for RONE-11078, please, refactor
    images.listing = images.listing.map(image => {
      if (images.uploaded.includes(image)) {
        return addedImages.find(addedImage => image.id === addedImage.guid)
      }

      return image
    })

    images.reference = images.reference.map(image => {
      if (images.uploaded.includes(image)) {
        return addedImages.find(addedImage => image.id === addedImage.guid)
      }

      return image
    })

    images.uploaded = []

    await removeImages(images, productTemplateID, api)

    images.removed = []

    await updateImages(
      {
        type: IMAGE_TYPE.Listing,
        oldImages: initialImages.listing,
        newImages: images.listing,
        productTemplateID,
      },
      api,
    )

    await updateImages(
      {
        type: IMAGE_TYPE.Reference,
        oldImages: initialImages.reference,
        newImages: images.reference,
        productTemplateID,
      },
      api,
    )

    // TODO hotfix for RONE-11078, please, refactor
    initialImages.listing = [...images.listing]
    initialImages.reference = [...images.reference]

    dispatch({
      type: types.setImages,
      images: { ...images },
    })
  }
}
