// @flow

import type { Filter, Variations } from '@r1-webui/productfamilies-familymanagement-v1/src/types'
import { deserialize, serialize } from '../serialize'
import { variationsProcessResponse } from '../../components/utils/webUi'
import type { WebUiApi } from '../../api/variations/types'
import type { VariationFormValues } from '../../containers/VariationContainer/types/variation.type'
import { fieldsSchema } from './schemas'

type CreateVariation = VariationFormValues
type UpdateVariation = VariationFormValues

const handleErrorResponse = resp => {
  if (resp.status !== 200) throw new Error((resp.body && resp.body.defaultFormat) || 'error')
}

export const variationsService = (api: WebUiApi) => {
  const getAllVariations = async () =>
    api.getAllFamilies().then(response => (response.status === 200 ? response.body : []))

  const createVariation = async (variation: CreateVariation) => {
    const body = deserialize(variation, fieldsSchema)

    return api.createFamily(body).then(res => variationsProcessResponse(res, { body }))
  }

  const updateVariation = async (variationId: string, variation: UpdateVariation) => {
    const body = deserialize(variation, fieldsSchema)

    return api
      .updateFamily({ familyId: variationId }, body)
      .then(res => variationsProcessResponse(res, { body }))
  }

  const getVariation = async (variationId: string) =>
    api
      .getFamily({ familyId: variationId })
      .then(response => (response.status === 200 ? serialize(response.body, fieldsSchema) : null))

  const calculateProducts = async ({
    filter,
    variations,
  }: {
    filter: Filter,
    variations: Variations,
  }) =>
    api
      .calculateProducts({ filter, variations })
      .then(response => (response.status === 200 ? response.body.familyProducts : []))

  const getProducts = async (variationId: string) =>
    api
      .getProducts({ familyId: variationId })
      .then(response => (response.status === 200 ? response.body.familyProducts : []))

  const deleteVariation = async (variationId: string) =>
    api.deleteFamily({ familyId: variationId }).then(handleErrorResponse)

  return {
    getAllVariations,
    getVariation,
    createVariation,
    updateVariation,
    calculateProducts,
    getProducts,
    deleteVariation,
  }
}
