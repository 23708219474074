// @flow

import * as React from 'react'

import { Flex, Box, Loader, NotificationSystem } from '@r1/ui-kit'

import createApi from '../../api'

import { ListItem } from './ListItem'

type ProfileConfiguratorProps = {|
  httpClient: Object,
  onError?: (error: any) => void,
|}

const AVALARA_PURPOSE_ID = 'TaxCommitmentProfile'
const MARKETPLACE_PURPOSE_ID = 'B2CSellingVenueProfile'
const CONTAINER_WIDTH = 880
const LOADER_FALLBACK_WIDTH = 800
const LOADER_FALLBACK_HEIGHT = 300

const loaderFallbackStyles = {
  position: 'relative',
  width: '100%',
  height: `${LOADER_FALLBACK_HEIGHT}px`,
}

type Props = ProfileConfiguratorProps

export type Profile = {|
  id: string,
  name: string,
|}

type Mappings = { [venueProfileId: string]: string }

type TaxCommitmentMapping = {|
  venueProfileId: string,
  taxCommitmentSystemProfileId: string | null,
|}

type State = {|
  marketplaceProfiles: Profile[],
  avalaraProfiles: Profile[],
  mappings: Mappings,
  isFetching: boolean,
  isSubmitting: string[],
  areAllSubmitting: boolean,
  noDataFound: boolean,
|}

type Notification = {|
  level: 'success' | 'error' | 'warning' | 'info',
  title: string,
  message?: string,
|}

const handleNotification = ({ level, title, message }: Notification) => {
  NotificationSystem.addNotification({
    level,
    title,
    message,
  })
}

const normalizeMappings = (mappings: TaxCommitmentMapping[]): Mappings =>
  mappings.reduce(
    (acc, { venueProfileId, taxCommitmentSystemProfileId }: TaxCommitmentMapping) => ({
      ...acc,
      [venueProfileId]: taxCommitmentSystemProfileId,
    }),
    {},
  )

export class ProfileConfigurator extends React.Component<Props, State> {
  state = {
    marketplaceProfiles: [],
    avalaraProfiles: [],
    mappings: {},
    isFetching: true,
    isSubmitting: [],
    areAllSubmitting: false,
    noDataFound: false,
  }

  api = createApi(this.props.httpClient, {
    onError: this.props.onError,
    onNotify: handleNotification,
  })

  async componentDidMount() {
    const [marketplaceProfiles, avalaraProfiles, mappings] = await Promise.all([
      this.api.fetchVenueProfiles(MARKETPLACE_PURPOSE_ID),
      this.api.fetchVenueProfiles(AVALARA_PURPOSE_ID),
      this.api.fetchProfileMappings(),
    ])

    if (
      !Array.isArray(marketplaceProfiles) ||
      !Array.isArray(avalaraProfiles) ||
      !Array.isArray(mappings)
    ) {
      this.setState({
        noDataFound: true,
        isFetching: false,
      })

      return
    }

    const extractProfileOption = ({ id, name }: Profile) => ({ id, name })

    this.setState({
      marketplaceProfiles: marketplaceProfiles.map(extractProfileOption),
      avalaraProfiles: avalaraProfiles.map(extractProfileOption),
      mappings: normalizeMappings(mappings),
      isFetching: false,
    })
  }

  onSelect = (venueProfileId: string) => (avalaraProfileId: string | null) => {
    this.setState(state => ({
      ...state,
      mappings: { ...state.mappings, [venueProfileId]: avalaraProfileId },
    }))
  }

  setProfile = (venueProfileId: string, avalaraProfileId: string | null = null) => {
    return this.api.setMappedTaxCommitmentSystemProfile(venueProfileId, avalaraProfileId)
  }

  onSubmit = (venueProfileId: string) => async (avalaraProfileId: string | null) => {
    this.setState(state => ({ ...state, isSubmitting: state.isSubmitting.concat(venueProfileId) }))

    await this.setProfile(venueProfileId, avalaraProfileId)

    this.setState(state => ({
      ...state,
      isSubmitting: state.isSubmitting.filter(id => id !== venueProfileId),
    }))
  }

  renderFallback() {
    return (
      <Flex justify="center" minWidth={LOADER_FALLBACK_WIDTH}>
        <Box>
          <div style={loaderFallbackStyles}>
            {this.state.noDataFound ? 'No data found' : <Loader />}
          </div>
        </Box>
      </Flex>
    )
  }

  renderList(): React.Node[] {
    const { marketplaceProfiles, avalaraProfiles, mappings, areAllSubmitting, isSubmitting } =
      this.state

    return marketplaceProfiles.map(profile => (
      <ListItem
        key={profile.id}
        label={profile.name}
        options={avalaraProfiles}
        value={mappings[profile.id]}
        disabled={areAllSubmitting}
        loading={isSubmitting.includes(profile.id)}
        onChange={this.onSelect(profile.id)}
        onSubmit={this.onSubmit(profile.id)}
      />
    ))
  }

  render() {
    const { noDataFound, isFetching } = this.state

    return (
      <Flex column minWidth={CONTAINER_WIDTH} mt="M" mb="M" spaceBetween="M">
        {isFetching || noDataFound ? this.renderFallback() : this.renderList()}
      </Flex>
    )
  }
}
