import { BadProperty } from '@r1-webui/facilities-v1'
import type { Notice } from '@r1/types/typescript'
import { replaceTemplateValues } from '../../../utils/responseUtils'

export function getBadPropertiesErrors(badProperties: Array<BadProperty>) {
  const errorData = badProperties.map(badProperty => {
    let propertyName = badProperty.property || ''
    const normalizedBadProperty = { ...badProperty }
    if (propertyName) {
      propertyName = propertyName.slice(propertyName.lastIndexOf('.') + 1)
      normalizedBadProperty.property = propertyName
    }

    return {
      fieldName: propertyName,
      message: replaceTemplateValues(normalizedBadProperty as Notice),
    }
  })

  return errorData
}
