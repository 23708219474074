import * as React from 'react'
import { Drawer } from '@r1/ui-kit'
import { MasterProgramHistory } from './MasterProgramHistory'

export const MasterProgramHistoryDrawer = ({
  ruleId,
  onClose,
  onOpenRule,
}: {
  ruleId: string
  onClose: () => void
  onOpenRule: (ruleId: string) => void
}) => {
  const onSelectHistoryEvent = React.useCallback(
    (masterProgramRuleId: string) => () => {
      onOpenRule(masterProgramRuleId)
    },
    [onOpenRule],
  )

  return (
    <Drawer.Form
      closeButton
      show
      size={800}
      backdrop="closing"
      placement="right"
      title="Master Program History"
      onClose={onClose}
    >
      <MasterProgramHistory masterProgramHistoryId={ruleId} onSelectEvent={onSelectHistoryEvent} />
    </Drawer.Form>
  )
}
