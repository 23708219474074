import * as React from 'react'
import { Flex, Box, Button, Modal, Input, FormField, Link, Text } from '@r1/ui-kit'
import { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { MasterProgramRuleShort, SubStampShort } from '@r1-webui/masterprograms-v1'

import { checkMasterProgramForProduct } from '../../../services/masterProgramService'
import { isNumber } from '../../../validation'

export const MasterProgramRuleTesterModal = () => {
  const [trgProductId, setProductId] = React.useState<number | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [masterProgramResponse, setMasterProgramResponse] = React.useState<
    MasterProgramRuleShort[] | null
  >(null)
  const [substamps, setSubStamps] = React.useState<SubStampShort[]>([])

  const modalActionButtons: UncontrolledActionButtons = ({ onClose }) => {
    return [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: onClose,
        align: 'right',
      },
      {
        title: 'Check',
        transparent: false,
        color: 'primary',
        loading,
        disabled: !trgProductId || loading,
        onClick: async () => {
          if (trgProductId) {
            setLoading(true)
            const response = await checkMasterProgramForProduct(trgProductId)

            if (response !== null) {
              setMasterProgramResponse(response.rules)
              setSubStamps(response.subStamps)
            }

            setLoading(false)
          }
        },
        align: 'right',
      },
    ]
  }

  const renderFoundRules = (
    rules: MasterProgramRuleShort[],
    foundSubStamp: SubStampShort[],
  ): React.ReactElement => {
    if (rules.length === 0) {
      return (
        <Box data-test-id="master-program__rule-tester__no-rules">No suitable rules were found</Box>
      )
    }

    if (rules.length === 1) {
      const rule = rules[0]

      return (
        <Box>
          <Flex column>
            <Link
              to={`master-programs?ruleId=${rule.id}`}
              data-test-id={`master-program__rule-tester__rule-${rule.id}`}
            >
              {rule.name}
            </Link>
            {foundSubStamp.length === 0 && <Text>Without substamp</Text>}
            {foundSubStamp.map(substamp => (
              <Text key={substamp.id}>
                Substamp: {substamp.name} : {substamp.valuename}
              </Text>
            ))}
          </Flex>
        </Box>
      )
    }

    return (
      <Box>
        <Flex column spaceBetween="XS">
          {rules.map(rule => (
            <Link
              key={rule.id}
              to={`master-programs?ruleId=${rule.id}`}
              data-test-id={`master-program__rule-tester__rule-${rule.id}`}
            >
              {rule.name}
            </Link>
          ))}
        </Flex>
      </Box>
    )
  }

  const handleInputChange = (value: string) => {
    if (isNumber(value)) {
      const numberValue = Number(value)
      if (numberValue >= Number.MAX_SAFE_INTEGER) {
        setProductId(Number.MAX_SAFE_INTEGER)
      } else {
        setProductId(Number(value))
      }
    }
    if (!value) {
      setProductId(null)
    }
  }

  return (
    <Flex>
      <Box>
        <Modal
          isControlled={false}
          actionButtons={modalActionButtons}
          // eslint-disable-next-line react/no-unstable-nested-components
          buttonElement={({ onOpen }) => <Button onClick={onOpen}>Rule Tester</Button>}
        >
          <Flex>
            <FormField>
              <FormField.Label>Trg Id</FormField.Label>
              <Flex column spaceBetween="L" minWidth={1}>
                <Box>
                  <Input
                    value={trgProductId?.toString() ?? ''}
                    data-test-id="master-program__rule-tester__input"
                    onChange={handleInputChange}
                  />
                </Box>
                {masterProgramResponse && renderFoundRules(masterProgramResponse, substamps)}
              </Flex>
            </FormField>
          </Flex>
        </Modal>
      </Box>
    </Flex>
  )
}
