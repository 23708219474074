// @flow

import { cond } from 'ramda'

/* eslint import/namespace: ['error', { allowComputed: true }] */
import * as schemas from './schemas'

type ValidationResult = string

export const validate = (fieldName: string, value: any, state: any): ValidationResult => {
  const schema = schemas[fieldName]

  if (!schema) return ''

  return cond(schema)(value, state) || ''
}
