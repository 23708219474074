import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { equals } from 'ramda'
import * as actions from './module'
import { ImagesEnhanced as Images } from './Images'

const mapStateToProps = ({ images }) => ({
  images: images.images,
})

const checkForRecentlyUploaded = images => images.filter(img => !img.$type).length

const enhance = compose(connect(mapStateToProps, actions))

class ImagesForm extends React.PureComponent {
  componentDidMount() {
    const { id, loadImages } = this.props

    loadImages(id)
  }

  componentDidUpdate(prevProps) {
    const { images, onAddDirtyTab, id, loadImages } = this.props

    if (prevProps.images && !equals(prevProps.images, images)) {
      onAddDirtyTab('images')
    }

    if (
      images &&
      ((images.reference && checkForRecentlyUploaded(images.reference)) ||
        (images.listing && checkForRecentlyUploaded(images.listing))) &&
      images.uploaded?.length !== prevProps.images?.uploaded?.length &&
      images.removed?.length !== prevProps.images?.removed?.length
    ) {
      /**
       * Please check this condition carefully
       * -------
       * After:
       * images.uploaded?.length !== prevProps.images?.uploaded?.length &&
       * images.removed?.length !== prevProps.images?.removed?.length
       * ---------
       * Before:
       * !images.uploaded.length && !images.removed.length
       * This condition caused infinite loop and ntwork requests never ending
       */
      loadImages(id)
    }
  }

  render() {
    const { images, changeImages, ...props } = this.props

    return <Images value={images || {}} onChange={changeImages} {...props} />
  }
}

export const ImagesFormEnhanced = enhance(ImagesForm)
