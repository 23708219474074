// @flow

const setValue = (pathArray, obj, value, transform) => {
  return pathArray.reduce((acc, path, index) => {
    if (index === pathArray.length - 1) {
      return { ...acc, [path]: transform ? transform(value) : value }
    }

    return { ...acc, [path]: acc[path] || {} }
  }, obj)
}

export const deserialize = <O: Object, S: Object>(initialObject: O, initialSchema: S): any => {
  let deserializedObject = {}

  const mapObjectToSchema = (object: O, schema: S) => {
    Object.keys(object).forEach(key => {
      if (schema[key]) {
        if (Object.prototype.hasOwnProperty.call(schema[key], 'value')) {
          if (schema[key].value.setPath) {
            deserializedObject = setValue(
              schema[key].value.setPath,
              deserializedObject,
              object[key],
              schema[key].value.setTransform,
            )
          }
        } else {
          mapObjectToSchema(object[key], schema[key])
        }
      }
    })
  }

  mapObjectToSchema(initialObject, initialSchema)

  return deserializedObject
}
