// @flow

import React, { useCallback } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Modal, Icon } from '@r1/ui-kit'
import type { VariationEditScreenProps } from '../../../types/public/VariationEditScreen'
import { VariationContainer } from '../VariationContainer'
import { VariationEdit } from '../children/VariationEdit'

export const VariationEditScreen = (props: VariationEditScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const DeleteModal = React.memo(({ handleDelete }) => {
    const deleteModalActionButtons = useCallback(
      ({ onClose }): BottomActionButton[] => {
        return [
          {
            title: 'No',
            color: 'secondary',
            onClick: () => onClose(),
            align: 'right',
          },
          {
            title: 'Yes',
            transparent: false,
            onClick: () => {
              handleDelete()
              onClose()
            },
            align: 'right',
          },
        ]
      },
      [handleDelete],
    )

    return (
      <Modal
        isControlled={false}
        title="Delete"
        actionButtons={deleteModalActionButtons}
        buttonElement={({ onOpen }) => (
          <Button transparent color="secondary" shape="square" onClick={onOpen}>
            <Icon type="trash" />
          </Button>
        )}
      >
        Are you sure you want to delete variation?
      </Modal>
    )
  })

  return (
    <VariationContainer httpClient={props.httpClient}>
      <VariationEdit
        variationId={props.variationId}
        onSuccessfulSubmit={props.onSuccessfulSubmit}
        onCancel={props.onCancel}
      >
        {({ handlers, variationName = '', isLoading }) => {
          const modalActionButtons = ({ onClose }): BottomActionButton[] => {
            return [
              {
                title: 'No',
                color: 'secondary',
                onClick: () => onClose(),
                align: 'right',
              },
              {
                title: 'Yes',
                onClick: () => {
                  props.onCancel()
                  onClose()
                },
                align: 'right',
              },
            ]
          }

          return (
            <>
              <Title>{variationName}</Title>
              <ActionButtons>
                <Modal
                  isControlled={false}
                  title="You have unsaved data"
                  actionButtons={modalActionButtons}
                  buttonElement={({ onOpen }) => (
                    <Button color="secondary" onClick={onOpen}>
                      Cancel
                    </Button>
                  )}
                >
                  Are you sure you want to leave without saving?
                </Modal>

                <Button loading={isLoading} disabled={isLoading} onClick={handlers.handleSubmit}>
                  Save
                </Button>

                {handlers.handleDelete && <DeleteModal handleDelete={handlers.handleDelete} />}
              </ActionButtons>
            </>
          )
        }}
      </VariationEdit>
    </VariationContainer>
  )
}
