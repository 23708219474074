import {
  Address,
  Facility,
  DailySchedule,
  Shift,
  ShippingCutOffTime,
} from '@r1-webui/facilities-v1'
import { format } from 'date-fns'
import { h12Format } from '../../../utils/dates/formats'
import { mergeDateWithTime } from '../../../utils/dates/mergeDateWithTime'
import { convert12hTimeStringTo24h } from '../../../utils/dates/convert12hTimeStringTo24h'

const currentDate = new Date().toISOString()

const mapPeriods = (value: DailySchedule | Shift | ShippingCutOffTime) => {
  const mappedPeriods = value.periods?.map(period => {
    const fromTime = convert12hTimeStringTo24h(period.from)
    const toTime = convert12hTimeStringTo24h(period.to)
    const from = format(mergeDateWithTime(currentDate, fromTime), h12Format)
    const to = format(mergeDateWithTime(currentDate, toTime), h12Format)

    return { ...period, from, to }
  })
  return {
    ...value,
    periods: mappedPeriods,
  }
}

export const mapFacilityToApi = (facility: Facility) => {
  const { schedule = [], shifts = [], shippingCutOffTimes = [] } = facility

  const addresses = Object.entries(facility.addresses).map(([addressType, address]) => {
    const result = Object.assign(address, { addressType } as {
      addressType: Address['addressType']
    })

    return result
  })

  const mappedSchedule = schedule.map(mapPeriods)
  const mappedShifts = shifts.map(mapPeriods)
  const mappedCutOffTimes = shippingCutOffTimes.map(mapPeriods)

  return {
    ...facility,
    addresses,
    schedule: mappedSchedule as DailySchedule[],
    shifts: mappedShifts as Shift[],
    shippingCutOffTimes: mappedCutOffTimes as ShippingCutOffTime[],
  }
}
