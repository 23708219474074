import * as React from 'react'
import styled from '@emotion/styled'

const ActionsContainer = styled('div')`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 0 2px;
  align-items: center;
  margin-left: -16px;
`
export const ActionsCell = props => <ActionsContainer>{props.children}</ActionsContainer>
