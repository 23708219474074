// @flow

import { createProductsApi } from '@r1-webui/productmanagement-v1'
import { handleServerError } from '@r1/core-blocks'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/product-management`

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const productApiClient = createProductsApi({ http: httpClient, urlPrefix: API_PREFIX })

// eslint-disable-next-line import/no-unused-modules
export const productApi = {
  getProduct: async (params: $AnyObject) => {
    const res = await productApiClient.getProduct(params)
    if (res.status !== 200) {
      handleServerError(res.body)
      return null
    }
    return res.body
  },
  saveProduct: async (params: $AnyObject, body: $AnyObject) => {
    const res = await productApiClient.saveProduct(params, body)
    if (res.status !== 200) {
      handleServerError(res.body)
    }
  },
  changeProductTemplate: async (params: $AnyObject, body: $AnyObject) => {
    const res = await productApiClient.changeProductTemplate(params, body)
    if (res.status !== 200) {
      handleServerError(res.body)
      return null
    }
    return res.body
  },
  getClassifications: async (params: $AnyObject) => {
    const res = await productApiClient.getClassifications(params)
    if (res.status !== 200) {
      handleServerError(res.body)
      return null
    }
    return res.body
  },
  getHistory: async (params: $AnyObject) => {
    const res = await productApi.getHistory(params)
    // $FlowFixMe
    if (res.status !== 200) {
      // $FlowFixMe
      handleServerError(res.body)
      return []
    }
    // $FlowFixMe
    return res.body
  },
}
