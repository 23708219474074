import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import debounce from 'debounce'
import { Select } from '@r1/ui-kit'
import * as actions from '../../modules/manufacturers'

const mapStateToProps = ({ manufacturers }) => ({
  data: manufacturers.data,
  isFetched: manufacturers.isFetched,
})

const enhance = compose(connect(mapStateToProps, actions))

function isValidNewOption({ label }) {
  return label && label.length > 3
}

class ManufacturerComponent extends React.Component {
  componentDidMount() {
    const { restoreManufacturer, value } = this.props
    restoreManufacturer(value)
  }

  componentDidUpdate(prevProps) {
    const { value, restoreManufacturer } = this.props

    if (prevProps.value !== value) {
      restoreManufacturer(value)
    }
  }

  async onAddManufacturer(name) {
    const { addManufacturer, onAddManufacturer } = this.props
    const id = await addManufacturer(name)
    if (id) {
      onAddManufacturer(id)
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  onAddManufacturer = this.onAddManufacturer.bind(this)

  async onRemoveManufacturer({ id, name }, closeSelectMenu) {
    closeSelectMenu()
    const { removeManufacturer, onRemoveManufacturer } = this.props
    if (await removeManufacturer({ id, name })) {
      onRemoveManufacturer(id)
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  onRemoveManufacturer = this.onRemoveManufacturer.bind(this)

  onInputChange = debounce(this.onInputChange.bind(this), 300, false)

  // eslint-disable-next-line no-dupe-class-members
  onInputChange(term) {
    const id = this.props.value
    this.props.searchManufacturers({ id, term })
  }

  onDropdownOpen = this.handleDropdownOpen.bind(this)

  handleDropdownOpen(id, data) {
    if (!data) return
    const { name } = data.find(manufacturer => manufacturer.id === id) || {}
    this.props.searchManufacturers({ id, term: name })
  }

  render() {
    const { value, isFetched, data, onChange, validationFailed, disabled } = this.props

    return (
      <Select
        editable
        value={value}
        isLoading={!isFetched}
        options={data}
        disabled={disabled}
        placeholder="Enter manufacturer"
        noResultsText="Type to search"
        error={validationFailed}
        isValidNewOption={isValidNewOption}
        zIndex={10}
        onInputChange={this.onInputChange}
        onChange={onChange}
        onOpen={() => this.onDropdownOpen(value, data)}
        onRemoveOption={this.onRemoveManufacturer}
        onAddOption={this.onAddManufacturer}
      />
    )
  }
}

export const ManufacturerSelect = enhance(ManufacturerComponent)
