// @flow

import React from 'react'
import { Box, Flex, FormField, Select, Text } from '@r1/ui-kit'
import { ColoredText } from '../../ColoredText'
import type { AttributesFilterProps } from '../../../../../../types/variation.type'
import { testIds } from '../../../testIds'

export const AttributesFilter = (props: AttributesFilterProps) => {
  const { values, handleChange, disabled, getError, path, attributeDefinitions } = props

  return (
    <>
      <Flex>
        <ColoredText>
          <Text type="paragraph">
            Sell with the following <b>variations</b>:
          </Text>
        </ColoredText>
      </Flex>
      <Flex justify="space-between" mt="S">
        {/* <Box basis={180} mr="M" mt={'S'}>
          <ColoredText>Attributes</ColoredText>
        </Box>
        <Box basis={180} mr="M" mt={'S'}>
          <ColoredText>In list</ColoredText>
        </Box> */}
        <Box shrink grow mr="S">
          <FormField>
            <Select
              multiple
              data-test-id={`${testIds.attributeDefinitionSelect}`}
              disabled={disabled}
              error={!!getError(path)}
              value={values}
              options={attributeDefinitions}
              onChange={handleChange(path)}
            />
            <FormField.Error>{getError(path)}</FormField.Error>
          </FormField>
        </Box>
      </Flex>
    </>
  )
}
