import { cond } from 'ramda'
import { schemas, ValidationSchema } from './schemas'

type ValidationResult = string

export function validate(fieldName: string, value: string): ValidationResult {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const schema: ValidationSchema = schemas[fieldName]
  if (!schema) return ''

  const mappedSchema = schema.map(({ validator, messenger }) => [validator, messenger])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return cond(mappedSchema)(value) || ''
}
