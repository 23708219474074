// @flow

export const schema = {
  MarketplaceCategoryMappingsValues: {
    value: {
      errorValue: 'autoUpdate',
      getPath: [],
      setPath: ['marketplaceCategoryMappings'],
      setTransform: (value: any) =>
        value.map(({ categoryTreeId, categoryId, autoUpdate }) => ({
          categoryTreeId,
          categoryId,
          autoUpdate,
        })),
    },
  },
}
