// @flow

import { uniq } from 'ramda'

import {
  spreadAttributes,
  extendBoundAttributeDefinitions,
  transformBinding,
  createAttributeBinding,
  updateBindingsArray,
  handleServiceErrors,
} from './utils'
import type { CategoryAttributesService, CreateServiceOptions } from './contract'

export function categoryAttributesService({
  api,
  responseInterceptor,
}: CreateServiceOptions): CategoryAttributesService {
  async function findAttributesInfoForCategory({ categoryId }) {
    const [attributeBindingsResponse, inheritedBindingsResponse, attributeDefinitionsResponse] =
      await Promise.all([
        api.categories.getDirectAttributeBindings({ categoryId }).then(responseInterceptor),
        api.categories.getInheritedAttributeBindings({ categoryId }).then(responseInterceptor),
        api.attributeDefinitions.findAllAttributeDefinitions().then(responseInterceptor),
      ])

    const attributeBindings =
      attributeBindingsResponse.status === 200 ? attributeBindingsResponse.body : []

    const inheritedAttributeBindings =
      inheritedBindingsResponse.status === 200 ? inheritedBindingsResponse.body : []

    const attributeDefinitions =
      attributeDefinitionsResponse.status === 200 ? attributeDefinitionsResponse.body : []

    const attributeDefinitionIds = uniq(
      [...attributeBindings, ...inheritedAttributeBindings].map(
        ({ attributeDefinition }) => attributeDefinition.id,
      ),
    )

    const attributes = spreadAttributes({
      categoryAttributeIds: attributeDefinitionIds,
      allAttributes: attributeDefinitions,
    })

    const extendedBoundAttributes = extendBoundAttributeDefinitions({
      attributeBindings,
      inheritedAttributeBindings,
      attributeDefinitions: attributes.boundAttributes,
    })

    return {
      availableAttributes: attributes.availableAttributes,
      boundAttributes: extendedBoundAttributes,
      bindings: attributeBindings,
      inheritedBindings: inheritedAttributeBindings,
    }
  }

  function updateCategoryAttributesBase({
    categoryId,
    bindings,
    boundAttributes,
    attributeDefinitionsToAdd,
    availableAttributes,
    version,
  }) {
    const boundAttributesTypes = boundAttributes.reduce(
      (map, { id, type }) => ({ ...map, [id]: type }),
      {},
    )
    const existingBindings = bindings.map(binding =>
      transformBinding({ binding, type: boundAttributesTypes[binding.attributeDefinition.id] }),
    )
    const availableAttributesTypes = availableAttributes.reduce(
      (map, { id, type }) => ({ ...map, [id]: type }),
      {},
    )
    const newBindings = attributeDefinitionsToAdd.map(attributeDefinitionId =>
      createAttributeBinding({
        attributeDefinitionId,
        type: availableAttributesTypes[attributeDefinitionId],
      }),
    )

    return api.categoryManagement
      .updateCategory(
        { categoryId },
        { version },
        { attributeBindings: existingBindings.concat(newBindings) },
      )
      .then(handleServiceErrors)
      .then(responseInterceptor)
  }

  function updateBinding({ bindings, bindingToUpdate, needToUpdateChildBindings, ...rest }) {
    const bindingToUpdateIndex = bindings.findIndex(
      ({ attributeDefinition: { id } }) => id === bindingToUpdate.attributeDefinition.id,
    )
    const isInherited = /inherit/i.test(bindingToUpdate.type)

    if (!isInherited && bindingToUpdateIndex === -1) {
      throw new Error(`[product-catalog] update binding that doesn't exist ;(`)
    }

    const newBindings = needToUpdateChildBindings
      ? updateBindingsArray({
          bindings,
          newChildBindings: bindingToUpdate.childBindings,
          attributeDefinitionId: bindingToUpdate.attributeDefinition.id,
        })
      : [...bindings]

    if (!isInherited) {
      for (let i = 0; i < newBindings.length; i += 1) {
        if (newBindings[i].attributeDefinition.id === bindingToUpdate.attributeDefinition.id) {
          newBindings[i] = bindingToUpdate
          break
        }
      }
    }

    return updateCategoryAttributesBase({
      ...rest,
      bindings: newBindings,
      attributeDefinitionsToAdd: [],
      availableAttributes: [],
    })
  }

  function deleteBinding({ deletedAttributeDefinitionId, bindings, ...rest }) {
    return updateCategoryAttributesBase({
      ...rest,
      bindings: bindings.filter(
        ({ attributeDefinition }) => attributeDefinition.id !== deletedAttributeDefinitionId,
      ),
      attributeDefinitionsToAdd: [],
      availableAttributes: [],
    })
  }

  const findAllAttributeDefinitions = async () =>
    api.attributeDefinitions
      .findAllAttributeDefinitions()
      .then(response => (response.status === 200 ? response.body : []))

  return {
    findAttributesInfoForCategory,
    bindAttributesToCategory: updateCategoryAttributesBase,
    updateBinding,
    deleteBinding,
    findAllAttributeDefinitions,
  }
}
