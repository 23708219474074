// @flow

import * as React from 'react'
import { Button } from '@r1/ui-kit'

type Props = {
  onDrop: (base64: string[]) => void,
}

export class Uploader extends React.Component<Props> {
  input: ?HTMLInputElement

  openUploader = () => this.input && this.input.click()

  onUpload = () => {
    const { onDrop } = this.props
    if (this.input) {
      const files = [...this.input.files].map(
        file =>
          new Promise(resolve => {
            const reader: FileReader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () =>
              resolve(
                typeof reader.result === 'string'
                  ? reader.result.replace(/data:image\/\w*;base64,/, '')
                  : '',
              )
          }),
      )
      Promise.all(files).then(loadedFiles => {
        if (loadedFiles.length > 0) {
          return onDrop(loadedFiles)
        }
        return undefined
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            this.openUploader()
          }}
        >
          UPLOAD PHOTO
        </Button>
        <input
          // eslint-disable-next-line no-return-assign
          ref={input => (this.input = input)}
          hidden
          multiple
          type="file"
          accept="image/*"
          onChange={this.onUpload}
        />
      </React.Fragment>
    )
  }
}
