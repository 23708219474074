// @flow

import React, { memo } from 'react'
import { Box, FormField, Input } from '@r1/ui-kit'
import type { StringAttributeChildrenProps } from '../../../../../../types/attribute.type'

export const String = memo<StringAttributeChildrenProps>(
  ({ error, title, handleChange, optionIndex, disabled, value }: StringAttributeChildrenProps) => {
    return (
      <FormField>
        <FormField.Label>{title}</FormField.Label>
        <Box minWidth={541} maxWidth={541}>
          <Input
            error={!!error}
            disabled={disabled}
            placeholder=""
            value={value}
            onChange={v => handleChange(['Attributes', optionIndex, 'formValue'])(v.trim())}
          />
        </Box>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
