// @flow

import React, { useState, useEffect } from 'react'
import { HR, Checkbox, Select } from '@r1/ui-kit'

import { Refund, TableHeader } from './StyledComponents'

type Props = {|
  api: any,
  rmaProfileId: null | string,
  onWithReturnChange: () => void,
  withReturn: boolean,
  onWarehouseChange: (null | string) => void,
  warehouse: string | null,
|}

export const Return = ({
  api,
  rmaProfileId,
  onWithReturnChange,
  withReturn,
  onWarehouseChange,
  warehouse,
}: Props) => {
  const [options, setOptions] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.rmaProfiles.getAvailableWarehouses({ rmaProfileId })
      if (response && response.status === 200) setOptions(response.body)
    }
    fetchData()
  }, [api.rmaProfiles, rmaProfileId])

  return (
    <>
      <HR />

      <Refund column>
        <Checkbox checked={withReturn} label="With return" onChange={onWithReturnChange} />

        {withReturn && (
          <>
            <TableHeader>
              <span>Warehouse to return</span>
            </TableHeader>
            <Select
              options={options}
              value={warehouse}
              labelKey="title"
              width={300}
              onChange={onWarehouseChange}
            />
          </>
        )}
      </Refund>
    </>
  )
}
