// @flow

import { ROOT } from './routes'

const regRouterLink = new RegExp(`^${ROOT}/`)
export const isRouterLink = (link: string) => regRouterLink.test(link)

export const redirect = (url: string) => {
  window.location.href = url
}

export const redirectToLogin = () => {
  const { pathname, search, hash } = window.location
  const path = pathname + search + hash
  let queryString = ''

  if (path !== '/') {
    queryString = `?path=${encodeURIComponent(path)}`
  }

  if (pathname !== '/login') {
    redirect(`/login${queryString}`)
  }
}

export const openNewWindow = (url: string) => {
  window.open(url, '_blank')
}
