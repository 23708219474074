/* eslint-disable */
import * as React from 'react'
import Icon from '../Icon'

export default props => (
  <Icon originalWidth={18} originalHeight={18} {...props}>
    <g>
      <path d="M17.28 17.28L13.68 17.28 13.68 18 17.64 18C17.856 18 18 17.856 18 17.64L18 13.68 17.28 13.68 17.28 17.28zM17.64 0L13.68 0 13.68.72 17.28.72 17.28 4.32 18 4.32 18 .36C18 .144 17.856 0 17.64 0zM.72.72L4.32.72 4.32 0 .36 0C.144 0 0 .144 0 .36L0 4.32.72 4.32.72.72zM.72 13.68L0 13.68 0 17.64C0 17.856.144 18 .36 18L4.32 18 4.32 17.28.72 17.28.72 13.68z"></path>
      <rect width=".72" height="13.739" x="2.16" y="2.269"></rect>
      <rect width=".72" height="9.445" x="3.6" y="2.269"></rect>
      <polygon points="5.04 2.269 5.04 11.715 5.76 11.715 6.48 11.715 6.48 2.269 5.76 2.269"></polygon>
      <rect width=".72" height="9.445" x="7.2" y="2.269"></rect>
      <polygon points="10.08 11.715 10.08 2.269 9.36 2.269 8.64 2.269 8.64 11.715 9.36 11.715"></polygon>
      <rect width=".72" height="9.445" x="10.8" y="2.269"></rect>
      <rect width=".72" height="9.445" x="12.24" y="2.269"></rect>
      <rect width=".72" height="9.445" x="13.68" y="2.269"></rect>
      <rect width=".72" height="13.739" x="15.12" y="2.269"></rect>
      <path d="M6.12,12.88 L4.68,12.88 C4.464,12.88 4.32,13.024 4.32,13.24 L4.32,14.68 C4.32,14.896 4.464,15.04 4.68,15.04 L6.12,15.04 C6.336,15.04 6.48,14.896 6.48,14.68 L6.48,13.24 C6.48,13.024 6.336,12.88 6.12,12.88 Z M5.76,14.32 L5.04,14.32 L5.04,13.6 L5.76,13.6 L5.76,14.32 Z"></path>
      <rect width=".72" height="2.16" x="7.56" y="12.88"></rect>
      <path d="M10.8 12.88L9.36 12.88 9.36 13.6 10.44 13.6 10.44 14.32 9.36 14.32 9.36 15.04 10.8 15.04C11.016 15.04 11.16 14.896 11.16 14.68L11.16 13.24C11.16 13.024 11.016 12.88 10.8 12.88zM13.68 15.04L13.68 14.32 13.68 13.6 13.68 13.24 12.96 13.24 12.96 13.6 12.6 13.6 12.6 12.88 11.88 12.88 11.88 13.96C11.88 14.176 12.024 14.32 12.24 14.32L12.96 14.32 12.96 15.04 13.68 15.04z"></path>
    </g>
  </Icon>
)
