// @flow

import * as React from 'react'
import { Button, Flex, Textarea } from '@r1/ui-kit'

type Props = {
  submit: (text: string, date: string) => Promise<void>,
}

export const NewMessageForm = (props: Props) => {
  const { submit } = props
  const [text, setText] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const onClick = React.useCallback(async () => {
    setLoading(true)
    await submit(text, new Date().toISOString())
    setText('')
    setLoading(false)
  }, [submit, text])

  return (
    <Flex spaceBetween="M" p="S">
      <Textarea
        value={text}
        rows={2}
        placeholder="Enter a reply here..."
        disabled={loading}
        onChange={setText}
      />
      <Button loading={loading} disabled={text.length === 0} onClick={onClick}>
        Send
      </Button>
    </Flex>
  )
}
