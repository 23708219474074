import { handleServerError } from '@r1/core-blocks'
import { legacyPrograms } from '../../../api/legacyPrograms'
import { mapProgramsToTree, ProgramTreeItem } from './helpers'

export const getPrograms = async (): Promise<ProgramTreeItem[]> => {
  const res = await legacyPrograms.getAllPrograms()
  if (res.status !== 200) {
    handleServerError(res)
    return []
  }
  return mapProgramsToTree(res.body)
}
