/* eslint-disable flowtype/no-types-missing-file-annotation, import/no-unused-modules */

import * as React from 'react'

import { TagInput, Flex, Box, FormField, Select, Input, Button, Checkbox } from '@r1/ui-kit'

import { withExport, onSuccess } from '../Export'
import { filterNullable } from '../PurchaseOrdersScreen/utils'
import { handleNotification } from '../../api'

const financialEventsPurchaseOrderUnassignedFilter = 'FinancialEventsPurchaseOrderUnassignedFilter'

const typeOptions = [
  { id: 'Sale', name: 'Sale' },
  { id: 'Refund', name: 'Refund' },
]

const enhance = withExport(({ api }) => ({
  start(options) {
    return api.startFinancialEventsExport(options)
  },
  get(id) {
    return api.getFinancialEventsExport(id)
  },
  getStatus(id) {
    return api.getFinancialEventsExportStatus(id)
  },
  onNotify: handleNotification,
  onSuccess,
}))

export class FinancialEventsFilterInternal extends React.Component {
  state = {
    purchaseOrderFilter: this.props.purchaseOrderFilter || null,
    venueProfileIds: null,
    financialEventType: this.props.financialEventType,
    lineTitle: null,
  }

  onEventTypeChange = financialEventType => this.setState({ financialEventType })

  onPurchaseOrderFilterChange = purchaseOrderIds =>
    this.setState({
      purchaseOrderFilter: purchaseOrderIds.length
        ? {
            type: 'FinancialEventsPurchaseOrderIdsFilter',
            purchaseOrderIds,
          }
        : null,
    })

  onVenueProfileIdsChange = venueProfileIds => this.setState({ venueProfileIds })

  onLineTitleChange = lineTitle => this.setState({ lineTitle })

  onDestinationSubdivisionFilterEmptyChange = empty => {
    if (empty) {
      this.setState({
        purchaseOrderFilter: {
          type: financialEventsPurchaseOrderUnassignedFilter,
        },
      })
    } else {
      this.setState({
        purchaseOrderFilter: null,
      })
    }
  }

  getSearchFilter = () => ({
    purchaseOrderFilter: this.state.purchaseOrderFilter,
    venueProfileIds: this.state.venueProfileIds,
    financialEventType: this.state.financialEventType,
    title: this.state.lineTitle,
  })

  onSubmit = () => {
    const searchFilter = this.getSearchFilter()
    if (this.props.onSubmit) this.props.onSubmit(searchFilter)
  }

  handleExport = () => {
    this.props.startExport(filterNullable(this.getSearchFilter()))
  }

  render() {
    const { purchaseOrderFilter, venueProfileIds, financialEventType, lineTitle } = this.state

    return (
      <Flex column basis={1}>
        <Flex spaceBetween="S">
          <Flex minWidth={464} maxWidth={464}>
            <FormField>
              <FormField.Label>MPPO ID</FormField.Label>
              <TagInput
                data-test-id="mmpo-id"
                $type="simpleTagValue"
                delimiter={['\n', ',']}
                value={(purchaseOrderFilter && purchaseOrderFilter.purchaseOrderIds) || []}
                onChange={this.onPurchaseOrderFilterChange}
              />
            </FormField>
          </Flex>
          <Flex minWidth={260}>
            <FormField>
              <FormField.Label>Events with unknown MPPOs</FormField.Label>
              <Checkbox
                data-test-id="mmpo-events"
                checked={
                  !!(
                    purchaseOrderFilter &&
                    purchaseOrderFilter.type === financialEventsPurchaseOrderUnassignedFilter
                  )
                }
                label="Show events with unknown MPPOs"
                onChange={this.onDestinationSubdivisionFilterEmptyChange}
              />
            </FormField>
          </Flex>
        </Flex>
        <Flex spaceBetween="S" mt="S">
          <Flex>
            <FormField>
              <FormField.Label>Marketplace profile</FormField.Label>
              <Select
                multiple
                data-test-id="marketplace-profile"
                width={200}
                filterable={false}
                placeholder="Select venue"
                value={venueProfileIds}
                options={this.props.venueProfiles}
                onChange={this.onVenueProfileIdsChange}
              />
            </FormField>
          </Flex>
          <Flex>
            <FormField>
              <FormField.Label>Type</FormField.Label>
              <Select
                data-test-id="type"
                width={180}
                filterable={false}
                placeholder="Select type"
                value={financialEventType}
                options={typeOptions}
                onChange={this.onEventTypeChange}
              />
            </FormField>
          </Flex>
          <Flex>
            <FormField>
              <FormField.Label>Line title</FormField.Label>
              <Input
                data-test-id="line-title"
                placeholder="Enter line title"
                value={lineTitle}
                width="200px"
                onChange={this.onLineTitleChange}
              />
            </FormField>
          </Flex>
        </Flex>
        <Flex justify="flex-end" spaceBetween="S">
          <Box>
            <Button
              loading={this.props.exportInProgress}
              color="secondary"
              onClick={this.handleExport}
            >
              Export
            </Button>
          </Box>
          <Box>
            <Button onClick={this.onSubmit}>Search</Button>
          </Box>
        </Flex>
      </Flex>
    )
  }
}

export const FinancialEventsFilter = enhance(FinancialEventsFilterInternal)
