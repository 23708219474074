// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { compose, withHandlers, withProps, defaultProps } from 'recompose'
import { Flex, Icon, Loader } from '@r1/ui-kit'

import type { HOC } from 'recompose'

const Container = styled(Flex)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`

const ImageWrapper = styled('div')`
  width: ${({ tileSize }) => `${tileSize}px`};
  height: ${({ tileSize }) => `${tileSize}px`};
  position: relative;
  display: flex;
  user-select: none;
  justify-content: space-around;
`

const RemoveIcon = styled(Icon)`
  position: absolute;
  color: ${({ theme }) => theme.palette.blue[600]};
  right: 6px;
  top: 0px;
`

const ImageElement = styled('img')`
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
`

type Image = {
  id: number,
  url: string,
  previewUrl: string,
}

type Props = {
  disabled: boolean,
  images: Image[],
  value: number[],
  loadingImages: number[],
  tileSize: number,
  onClick: (id: number) => void,
  onRemoveClick: (event: SyntheticEvent<any>, value: number[], id: number) => void,
}

const enhance: HOC<any, Props> = compose(
  defaultProps({
    images: [],
    value: [],
    loadingImages: [],
    onClick: () => {},
    onChange: null,
    disabled: false,
    tileSize: 100,
  }),
  withProps(({ value, images }) => ({
    images: images.filter(image => value.includes(image.id)),
  })),
  // $FlowFixMe[incompatible-use]
  withHandlers({
    onRemoveClick:
      ({ onChange }) =>
      (event, value, id) => {
        event.stopPropagation()
        if (onChange) onChange(value, { type: 'remove', id })
      },
  }),
)

const ListComponent = ({
  disabled,
  images,
  value,
  loadingImages,
  tileSize,
  onClick,
  onRemoveClick,
}: Props) => (
  <Container flexWrap spaceBetween="S" disabled={disabled}>
    {images.map((image, index) => {
      const { id, previewUrl, url } = image
      return (
        // eslint-disable-next-line react/no-array-index-key
        <ImageWrapper key={`image-${index}`} tileSize={tileSize} onClick={() => onClick(id)}>
          <RemoveIcon
            type="close"
            cursor="pointer"
            onClick={event => onRemoveClick(event, value, id)}
          />
          {loadingImages.includes(id) ? <Loader /> : <ImageElement src={previewUrl || url} />}
        </ImageWrapper>
      )
    })}
  </Container>
)

export const List = enhance(ListComponent)
