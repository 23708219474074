// @flow

import React, { useContext, useState, useCallback } from 'react'
import { Select, FormField } from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import { useDebounce } from '../../../../../../../../utils/hooks'

type ProductSelectProps = {
  value: {
    id: number | string,
    productOption?: [{| id: string, name: string |}],
    categoryId: string | null,
    productId: string | null,
  },
  disabled: boolean,
  onChange: (path: Array<string | number>) => (value: any) => void,
  path: Array<string | number>,
  error: string | null,
}

export const ProductSelect = React.memo<ProductSelectProps>(
  ({ value, disabled, onChange, path, error }: ProductSelectProps) => {
    const {
      productService: { searchProductNames },
    } = useContext(ServiceContext)

    const debounce = useDebounce(300)

    const [productList, setProductList] = useState(value.productOption ? value.productOption : null)
    const [loading, setLoading] = useState(false)

    const findProducts = useCallback(
      (searchTerm?: string = '') => {
        setLoading(true)
        searchProductNames({
          categoryId: value.categoryId,
          searchTerm: searchTerm || '',
          limit: 100,
        }).then(products => {
          setLoading(false)
          setProductList(products)
        })
      },
      [value.categoryId, searchProductNames],
    )

    const handleChange = useCallback(
      (productId: string) => {
        onChange([...path, 'productId'])(productId)
        onChange([...path, 'productOption'])(productList)
      },
      [productList, onChange, path],
    )

    return (
      <FormField>
        <Select
          error={!!error}
          width={430}
          loading={loading}
          value={value.productId}
          disabled={value.categoryId === null || disabled}
          placeholder="Choose product"
          noResultsText="Type to search"
          options={productList}
          onInputChange={term => debounce(() => term && findProducts(term))}
          onChange={handleChange}
        />
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
