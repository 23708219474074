// @flow

import React, { useCallback } from 'react'
import { FormManager } from '@r1/form-manager'
import { Checkbox, FormField, Input, Select, TagInput, Box, Flex } from '@r1/ui-kit'

import { clientValidate } from '../../../utils/validation'
import { schema } from '../schema'
import {
  attributeCanHaveEnumValues,
  attributeCanHaveUnitOfMeasurement,
} from '../../AttributeDefinition/utils'
import type { AttributeEditFormViewProps as Props } from '../../../types/public/AttributeEditFormView'
import type { FormManagerValues } from '../../../types/public/AttributeEditFormController'
import { testIds } from './testIds'

function initialValues(props: Props): FormManagerValues {
  return {
    attributeName: props.attributeInfo.attributeName,
    attributeEnumValues: props.attributeInfo.attributeEnumValues,
    unitOfMeasurement: props.attributeInfo.unitOfMeasurement,
  }
}

const SELECT_WIDTH = 255

const getRegexp = type => {
  switch (type) {
    case 'IntEnumeration':
      return /^\d+?$/
    case 'DecimalEnumeration':
      return /^\d+(\.\d)?$/
    default:
      return undefined
  }
}

const attributeTypeMapping: Object[] = [
  { id: 'Integer', name: 'Integer number' },
  { id: 'Decimal', name: 'Decimal number' },
  { id: 'String', name: 'Free text' },
  { id: 'IntEnumeration', name: 'Integer number list' },
  { id: 'DecimalEnumeration', name: 'Decimal number list' },
  { id: 'StringEnumeration', name: 'Text List' },
  { id: 'Boolean', name: 'Boolean' },
  { id: 'Date', name: 'Date' },
  { id: 'DateRange', name: 'Date range' },
  { id: 'IntRange', name: 'Integer number range' },
  { id: 'DecimalRange', name: 'Decimal number range' },
]

const attributeBindingTypes: Object[] = [
  { id: 'Product', name: 'Product' },
  { id: 'Unit', name: 'Unit' },
]

const createReadonlySelectOptionsStub = value => (value ? [{ id: value, name: value }] : [])

const trimMultiSpaces = str => str.trim().replace(/ +(?= )/g, '')

export function AttributeEditFormView(props: Props) {
  const { onSubmit, dataSources, formId, attributeInfo, onEnumValuesChange } = props
  const { unitOfMeasurements } = dataSources

  const onEnumChange = useCallback(
    handleChange => values => {
      const newValues = []
      // check and don't add duplicate and extra spaces
      values.forEach(value => {
        if (
          newValues.find(
            enumValue =>
              enumValue.value.toLowerCase() === trimMultiSpaces(value.value.toLowerCase()),
          ) === undefined
        )
          newValues.push({ id: value.id, value: trimMultiSpaces(value.value) })
      })

      handleChange('attributeEnumValues')(newValues)
      onEnumValuesChange(newValues)
    },
    [onEnumValuesChange],
  )
  return (
    <FormManager
      formId={formId}
      initialValues={initialValues(props)}
      clientValidate={value => clientValidate(schema, value)}
      onSubmit={onSubmit}
    >
      {({ values, getError, handleChange, handleBlur }) => {
        return (
          <Flex column spaceBetween="XXS">
            <FormField>
              <FormField.Label>Name</FormField.Label>
              <Input
                data-test-id={testIds.nameInput}
                placeholder="Enter attribute name"
                value={String(values.attributeName)}
                error={!!getError('attributeName')}
                onChange={value => handleChange('attributeName', value)}
                onBlur={handleBlur('attributeName')}
              />
              <FormField.Error>{getError('attributeName')}</FormField.Error>
            </FormField>

            <FormField disabled>
              <FormField.Label>Type</FormField.Label>
              <Select
                data-test-id={testIds.typeSelect}
                width={SELECT_WIDTH}
                clearable={false}
                placeholder="Choose attribute type"
                value={attributeInfo.attributeType}
                options={attributeTypeMapping}
              />
              <FormField.Error />
            </FormField>

            {attributeCanHaveEnumValues(attributeInfo.attributeType) && (
              <FormField>
                <FormField.Label>Available Values</FormField.Label>
                <TagInput
                  editable
                  $type="complexTagValue"
                  data-test-id={testIds.valuesInput}
                  value={values.attributeEnumValues
                    .map(item => ({ ...item, color: 'default', icon: null }))
                    .sort((a, b) => (a.value > b.value ? 1 : -1))}
                  accept={getRegexp(attributeInfo.attributeType)}
                  placeholder="Enter values"
                  error={!!getError('attributeEnumValues')}
                  onChange={onEnumChange(handleChange)}
                />
                <FormField.Error>{getError('attributeEnumValues')}</FormField.Error>
              </FormField>
            )}

            {attributeInfo.unitOfMeasurementType && (
              <FormField disabled>
                <FormField.Label>Unit of Measurement Type</FormField.Label>
                <Select
                  data-test-id={testIds.unitOfMeasurementTypeSelect}
                  width={SELECT_WIDTH}
                  clearable={false}
                  placeholder="Select measurement type"
                  value={attributeInfo.unitOfMeasurementType}
                  // $FlowFixMe[incompatible-type]
                  options={createReadonlySelectOptionsStub(attributeInfo.unitOfMeasurementType)}
                />
                <FormField.Error />
              </FormField>
            )}

            {attributeInfo.unitOfMeasurementType &&
              attributeCanHaveUnitOfMeasurement(attributeInfo.attributeType) && (
                <FormField>
                  <FormField.Label>Unit of Measurement</FormField.Label>
                  <Select
                    data-test-id={testIds.unitOfMeasurementSelect}
                    width={SELECT_WIDTH}
                    clearable={false}
                    placeholder="Select unit of measurement"
                    value={values.unitOfMeasurement}
                    error={!!getError('unitOfMeasurement')}
                    options={unitOfMeasurements}
                    onChange={handleChange('unitOfMeasurement')}
                  />
                  <FormField.Error>{getError('unitOfMeasurement')}</FormField.Error>
                </FormField>
              )}

            <FormField disabled>
              <FormField.Label>Refers To</FormField.Label>
              <Select
                data-test-id={testIds.bindingTypeSelect}
                width={SELECT_WIDTH}
                clearable={false}
                placeholder="Select product or unit"
                value={attributeInfo.attributeBindingType}
                options={attributeBindingTypes}
              />
              <FormField.Error />
            </FormField>
            <Box>
              <Checkbox disabled label="Unique" checked={attributeInfo.productUniqueness} />
            </Box>
          </Flex>
        )
      }}
    </FormManager>
  )
}
