// @flow

import { evolve, mergeLeft } from 'ramda'

import { orderId } from '../../../../utils'

import type { Order } from '../../../../types/ClaimCreation'
import type { WithController } from '../types'

import { getOrderLines } from './utils'

export class OrderSelectionHandlerController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  handleOrderSearchStringChange = (orderSearchString: string) => {
    this.component.validation.resetError('orderSearchString')

    this.component.setState(
      evolve({
        claimCreation: mergeLeft({ orderSearchString }),
      }),
    )
  }

  handleOrderSearch = async () => {
    this.component.validation.resetError('orderSearchString')
    this.component.validation.resetError('order')

    const identifier = this.component.state.claimCreation.orderSearchString
    if (
      this.component.validation.validateStepPart(
        'orderSearchString',
        identifier,
        this.component.state.claimCreation,
      )
    ) {
      return
    }

    this.component.setState(
      evolve({
        claimCreation: mergeLeft({
          isOrderSearchExecuting: true,
          orders: [],
          order: null,
          expectedDecisionTypeId: '',
          claimType: null,
        }),
      }),
    )

    try {
      // Orders
      const orderType = this.component.props.claimType
      const orders = await this.component.api.searchOrdersByIdentifier(orderType, identifier)
      if (!orders.length) {
        return
      }
      const orderStatuses = this.component.api.getOrderStatuses(orders)

      // RmaProfiles
      const rmaProfileIds = orders.map(o => o.rmaProfileId)
      const uniqueRmaProfileIds = rmaProfileIds.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
      const rmaProfiles = await this.component.api.fetchProfiles(uniqueRmaProfileIds)
      if (!rmaProfiles.size) {
        return
      }

      // ShippingAddresses
      const shippingAddressIds = orders.map(o => o.shippingInfo.addressId)
      const uniqueShippingAddressIds = shippingAddressIds.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
      const shippingAddresses = await this.component.api.fetchShippingAddresses(
        uniqueShippingAddressIds,
      )
      if (!shippingAddresses.size) {
        return
      }

      this.component.setState(
        evolve({
          claimCreation: mergeLeft({
            orders,
            orderStatuses,
            rmaProfiles,
            shippingAddresses,
          }),
        }),
        () => {
          this.selectFirstShippedOrder()
        },
      )
    } finally {
      this.component.setState(
        evolve({
          claimCreation: mergeLeft({
            isOrderSearchExecuting: false,
          }),
        }),
      )
    }
  }

  selectFirstShippedOrder = () => {
    const { claimCreation } = this.component.state

    const shippedOrders = []

    claimCreation.orders.forEach(order => {
      const orderStatusInfo = claimCreation.orderStatuses.get(orderId(order))
      const orderStatus = orderStatusInfo ? orderStatusInfo.orderStatus : null
      if (orderStatus && orderStatus.status === 'Shipped') {
        shippedOrders.push(order)
      }
    })

    const firstOrder = shippedOrders.length === 1 ? shippedOrders[0] : null
    if (!firstOrder) {
      return
    }

    this.handleOrderSelection(firstOrder)
  }

  handleOrderSelection = (selectedOrder: Order) => {
    this.component.validation.resetError('order')

    this.component.setState(
      state => {
        const order = state.claimCreation.order === selectedOrder ? null : selectedOrder
        if (!order) {
          return evolve(
            {
              claimCreation: mergeLeft({
                order,
                orderLines: [],
                orderStatus: null,
                rmaProfile: null,
                shippingAddress: null,
                returnReasons: [],
                expectedDecisionTypes: [],
                claimTotal: {
                  amount: {
                    currency: 'USD',
                    amount: 0,
                  },
                  quantity: 0,
                },
              }),
            },
            state,
          )
        }

        const orderStatusInfo = state.claimCreation.orderStatuses.get(orderId(order))
        if (!orderStatusInfo) {
          return null
        }

        const rmaProfileInfo = state.claimCreation.rmaProfiles.get(order.rmaProfileId)
        if (!rmaProfileInfo) {
          return null
        }

        const shippingAddressInfo = state.claimCreation.shippingAddresses.get(
          order.shippingInfo.addressId,
        )
        if (!shippingAddressInfo) {
          return null
        }

        const { orderStatus } = orderStatusInfo
        const { rmaProfile, returnReasons, expectedDecisionTypes } = rmaProfileInfo
        const { shippingAddress } = shippingAddressInfo

        const orderLines = getOrderLines(order, returnReasons)

        return evolve(
          {
            claimCreation: mergeLeft({
              order,
              orderLines,
              orderStatus,
              rmaProfile,
              shippingAddress,
              returnReasons,
              expectedDecisionTypes,
            }),
          },
          state,
        )
      },
      () => {
        this.selectSingleOrderLine()
      },
    )
  }

  selectSingleOrderLine = () => {
    const { orderLines } = this.component.state.claimCreation

    if (orderLines.length === 1) {
      this.component.claimDetailsHandler.handleOrderLineSelection(orderLines[0].lineId, true)
    }
  }
}

export const createOrderSelectionHandlerController = (component: WithController) =>
  new OrderSelectionHandlerController(component)
