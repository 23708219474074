// @flow

import type { MapControllerStateToViewProps } from './types'

const getUnitOfMeasurement = state => {
  return state.allUnitsOfMeasurement.find(
    u =>
      state.attributeDefinition.unitOfMeasurement &&
      u.id === state.attributeDefinition.unitOfMeasurement.id,
  )
}

const getUnitOfMeasurementType = (state, unitOfMeasurement) => {
  if (unitOfMeasurement == null) return null
  return state.availableUnitOfMeasurementTypes.find(t => t.id === unitOfMeasurement.typeId)
}

const getEnumerationInfo = state => {
  if (
    state.attributeDefinition.enumValues == null ||
    state.attributeDefinition.enumValues.enumValues == null
  )
    return null
  let enumValues = [] // because of Flow, https://github.com/facebook/flow/issues/2264
  for (let i = 0; i < state.attributeDefinition.enumValues.enumValues.length; i += 1) {
    enumValues = [...enumValues, String(state.attributeDefinition.enumValues.enumValues[i].value)]
  }
  return {
    enumValues,
  }
}

const getUnitOfMeasurementInfo = (unitOfMeasurement, unitOfMeasurementType) => {
  if (unitOfMeasurement == null || unitOfMeasurementType == null) return null
  return {
    type: unitOfMeasurementType.name,
    name: unitOfMeasurement.name,
  }
}

const getUnitRelatedInfo = state => {
  if (state.attributeDefinition.type === 'ProductAttributeDefinition') return null
  return {
    uniqueness: state.attributeDefinition.uniqueness,
    manufacturers: state.attributeDefinition.manufacturers.map(m => m.name),
  }
}

const getAttributeDefinitionType = (type): 'Product' | 'Unit' => {
  // Flow can't do this with constants
  switch (type) {
    case 'ProductAttributeDefinition':
      return 'Product'
    case 'UnitAttributeDefinition':
      return 'Unit'
    default:
  }
  return type
}

const getGeneralInfo = state => {
  return {
    name: state.attributeDefinition.name,
    bindingType: getAttributeDefinitionType(state.attributeDefinition.type),
    type: state.attributeDefinition.valueType,
  }
}

export const mapControllerStateToViewProps: MapControllerStateToViewProps = state => {
  const unitOfMeasurement = getUnitOfMeasurement(state)
  const unitOfMeasurementType = getUnitOfMeasurementType(state, unitOfMeasurement)
  return {
    screenType: 'default',
    generalInfo: getGeneralInfo(state),
    enumerationInfo: getEnumerationInfo(state),
    unitOfMeasurementInfo: getUnitOfMeasurementInfo(unitOfMeasurement, unitOfMeasurementType),
    unitRelatedInfo: getUnitRelatedInfo(state),
  }
}
