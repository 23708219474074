// @flow

import * as React from 'react'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { PurposeListScreen } from '../components/PurposeListScreen'

import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

export const PurposeList = () => (
  <PageAccessControl permissions={[PERMISSIONS.allowManageProfilesView]}>
    <PurposeListScreen
      httpClient={httpClient}
      getPurposeUrl={id => `/r1/admin/profiles/${id}`}
      getProfileCreationUrl={(purposeId, profileTypeId) =>
        `/r1/admin/profiles/${purposeId || ''}/${profileTypeId}/new`
      }
      onError={errorHandler}
    />
  </PageAccessControl>
)
