import * as React from 'react'
import { Collapse } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { RuleFmsiFilters } from '../MasterProgramFields/Filters'
import { InfoFields } from '../MasterProgramFields/InfoFileds'
import { MainSettings } from '../MasterProgramFields/MainSettings'
import { defaultMasterProgramActivePanels } from '../../constants'
import { SubStamps } from '../MasterProgramFields/SubStamps'
import { MasterProgramModel } from '../../types'

export const MasterProgramForm = ({
  masterProgram,
  disabled,
}: {
  masterProgram: MasterProgramModel | null
  disabled?: boolean
}) => {
  const [{ allowMasterProgramEdit }] = useAccessControl()
  const [activePanels, setActivePanels] = React.useState<Array<string>>(
    defaultMasterProgramActivePanels,
  )

  const isEditable = !disabled && allowMasterProgramEdit

  return (
    <Collapse active={activePanels} onChange={setActivePanels}>
      <Collapse.Panel id="mainSettings" title="Main Settings">
        <MainSettings
          editable={isEditable}
          name={masterProgram?.name}
          clientProgramName={masterProgram?.clientProgramName}
          inventoryType={masterProgram?.inventoryType}
          isActive={masterProgram?.isActive}
          parentProgramName={masterProgram?.parentProgramName}
        />
      </Collapse.Panel>
      <Collapse.Panel id="infoFields" title="Info Fields">
        <InfoFields editable={isEditable} />
      </Collapse.Panel>
      <Collapse.Panel id="filters" title="Filters">
        <RuleFmsiFilters editable={isEditable} />
      </Collapse.Panel>
      <Collapse.Panel id="subStamps" title="Substamps">
        <SubStamps editable={isEditable} />
      </Collapse.Panel>
    </Collapse>
  )
}
