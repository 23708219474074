// @flow

import { compose, groupBy, path, flatten } from 'ramda'

import { baseUrl } from '../../constants'
import { getData } from '../../utils'

const getPurposes = (httpClient: any, onError: any) =>
  getData({
    httpClient,
    onError,
    defaultValue: [],
    options: {
      method: 'GET',
      url: `${baseUrl}/purpose`,
    },
  }).then(({ value }) => value)

const getProfileType = ({
  httpClient,
  profileId,
  onError,
}: {
  httpClient: any,
  profileId: string,
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'GET',
      url: `${baseUrl}/purpose/${profileId}/type`,
    },
  }).then(({ value }) => value)

const getProfileTypes = ({
  httpClient,
  purposeIds,
  onError,
}: {
  httpClient: any,
  purposeIds: Array<string>,
  onError: any,
}) => {
  const promises = purposeIds.map(profileId =>
    getProfileType({
      httpClient,
      profileId,
      onError,
    }),
  )
  return Promise.all(promises).then(types => types.reduce((acc, type) => [...acc, type], []))
}

export const getInitialData = (httpClient: any, onError: any) =>
  getPurposes(httpClient, onError).then(purposes => {
    const purposeIds = purposes.map(({ id }) => id)
    return getProfileTypes({ httpClient, purposeIds, onError }).then(profileTypesData => {
      const profileTypes = compose(
        groupBy<$AnyObject>(path(['purpose', 'id'])),
        flatten,
      )(profileTypesData)

      return { purposes, profileTypes }
    })
  })
