import * as React from 'react'
import { compose, mapProps, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { Flex } from '@r1/ui-kit'
import { selectors, actions } from '../../../../modules/products/images'
import { List, Uploader, withPreview } from './Images'

const mapStateToProps = (state, { productId }) => ({
  images: selectors.getCollectionItems(state, { productId }),
})

const enhance = compose(
  connect(mapStateToProps, {
    fetchImages: actions.fetchAll,
    uploadImage: actions.uploadImage,
    removeImage: actions.removeImage,
  }),
  mapProps(({ images, ...props }) => ({
    images: images.map(({ id, url }) => ({ id, url, previewUrl: url })),
    ...props,
  })),
  withPreview,
  withHandlers({
    toggleImagePreview:
      ({ onTogglePreview, images }) =>
      imageId =>
        onTogglePreview(images, imageId),
  }),
)

class ProductImagesComponent extends React.Component {
  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    this.fetchData()
  }

  async handleChangeImages(value, { type, id: imageId }) {
    const { fetchImages, removeImage, productId } = this.props
    if (type === 'remove') {
      await removeImage(productId, imageId)
    }
    fetchImages({ productId }, { force: true })
  }

  onChangeImages = this.handleChangeImages.bind(this)

  async handleDropImages(images) {
    const { fetchImages, uploadImage, productId } = this.props
    await Promise.all(images.map(base64 => uploadImage(productId, base64)))
    fetchImages({ productId }, { force: true })
  }

  onDropImages = this.handleDropImages.bind(this)

  fetchData() {
    const { fetchImages, productId } = this.props
    fetchImages({ productId })
  }

  render() {
    const { images, toggleImagePreview } = this.props
    const value = images.map(({ id }) => id)

    return (
      <Flex column spaceBetween="M">
        <Flex>
          <Uploader onDrop={this.onDropImages} />
        </Flex>
        <List
          images={images}
          value={value}
          onChange={this.onChangeImages}
          onClick={toggleImagePreview}
        />
      </Flex>
    )
  }
}

export const ProductImages = enhance(ProductImagesComponent)
