// @flow

import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Editor,
  Flex,
  FormField,
  H3,
  Input,
  NumericInput,
  Select,
  Text,
  Format,
} from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import { useDebounce } from '../../../../../../../../utils/hooks/useDebounce'
import { makeCancelable } from '../../../../../../utils/makeCancelablePromise'
import { CategoriesTreeSelect } from '../../../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'
import type { BasicInfoFormEditProps } from '../../../../types/product.type'
import { testIds } from '../../testIds'
import { stripHtml } from '../../../../../../../../routes/ProductTemplate/utils'
import { ModalConfirm } from './ModalConfirm'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const Data = styled('span')`
  color: ${({ theme }) => theme.palette.grey[600]};
`

const MAX_NUMBER = 2147483647
const MAX_ALLOWED_VALUE = MAX_NUMBER

type Options = { id: string, name: string }[]

export const BasicInfo = ({
  productId,
  values,
  errors,
  handleChange,
  handleBlur,
  disabled,
}: BasicInfoFormEditProps) => {
  const {
    httpClient,
    referenceService: { searchManufacturers, getCountries, searchModels },
    variationService: { getAllVariations },
    manufacturerManagementService: {
      createManufacturer,
      deleteManufacturer,
      createModel,
      deleteModel,
    },
  } = useContext(ServiceContext)

  const debounce = useDebounce(600)
  let modelPromise = null
  let manufacturerPromise = null

  const [charLongDescriptionCount, setCharLongDescriptionCount] = useState(0)
  const [charShortDescriptionCount, setCharShortDescriptionCount] = useState(0)
  const [manufacturersList, setManufacturersList] = useState<Options>([])
  const [modelsList, setModelsList] = useState<Options>([])
  const [isLoadManufacturers, setIsLoadManufacturers] = useState(false)
  const [isLoadModels, setIsLoadModels] = useState(false)

  const [variationsList, setVariationsList] = useState(null)
  const [isLoadVariations, setIsLoadVariations] = useState(true)

  const [countriesList, setCountriesList] = useState(null)
  const [isLoadCountries, setIsLoadCountries] = useState(false)

  const [modalConfirmType, setModalConfirmType] = useState<null | 'model' | 'manufacturer'>(null)
  const [modalConfirmOpt, setModalConfirmOpt] = useState(null)

  const shortDescriptionFieldMetaData = useMemo(
    () => ({
      max: 1000,
      current: charShortDescriptionCount,
    }),
    [charShortDescriptionCount],
  )

  const longDescriptionFieldMetaData = useMemo(
    () => ({
      max: 4000,
      current: charLongDescriptionCount,
    }),
    [charLongDescriptionCount],
  )

  const openModalConfirm = useCallback(
    (type: 'model' | 'manufacturer') =>
      ({ id, name }) => {
        setModalConfirmOpt({ id, name })
        setModalConfirmType(type)
      },
    [],
  )

  const closeModalConfirm = useCallback(() => setModalConfirmType(null), [])

  const findManufacturers = useCallback(
    (searchTerm?: string = '') => {
      setIsLoadManufacturers(true)
      if (manufacturerPromise !== null) manufacturerPromise.cancel()
      const cancelablePromise = makeCancelable(
        searchManufacturers({
          searchTerm: searchTerm || '',
          limit: 50,
          offset: 0,
        }),
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
      manufacturerPromise = cancelablePromise
      manufacturerPromise.promise
        .then(manufacturers => {
          setIsLoadManufacturers(false)
          setManufacturersList(manufacturers)
        })
        .catch(console.log)
    },
    [searchManufacturers],
  )

  const findModels = useCallback(
    (searchTerm = '') => {
      if (values.ManufacturerField.id) {
        setIsLoadModels(true)
        if (modelPromise !== null) modelPromise.cancel()
        const cancelablePromise = makeCancelable(
          searchModels({
            manufacturerId: values.ManufacturerField.id,
            searchTerm: searchTerm || '',
            limit: 50,
            offset: 0,
          }),
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        modelPromise = cancelablePromise
        modelPromise.promise.then(models => {
          setIsLoadModels(false)
          setModelsList(models)
        })
      }
    },
    [searchModels, values.ManufacturerField.id],
  )

  const fetchData = useCallback(async () => {
    setIsLoadVariations(true)
    setIsLoadCountries(true)

    getCountries()
      .then(setCountriesList)
      .then(() => setIsLoadCountries(false))

    getAllVariations()
      .then(setVariationsList)
      .then(() => setIsLoadVariations(false))
  }, [getAllVariations, getCountries])

  const addManufacturer = useCallback(
    (name: string) => {
      createManufacturer(name).then((id: string) => {
        setManufacturersList([{ id, name }, ...manufacturersList])
        handleChange(['ManufacturerField', 'id'])(id)
        handleChange(['ModelField', 'name'])(null)
      })
    },
    [manufacturersList, createManufacturer, handleChange],
  )

  const debounceAddManufacturer = val => debounce(() => addManufacturer(val))

  const removeManufacturer = useCallback(
    (id: string) => {
      deleteManufacturer(id, productId).then(() => {
        setManufacturersList(
          manufacturersList.filter(({ id: manufacturerId }) => id !== manufacturerId),
        )
        if (values.ManufacturerField && values.ManufacturerField.id === id) {
          handleChange(['ManufacturerField', 'id'])(null)
          handleChange(['ModelField', 'id'])(null)
        }
      })
    },
    [manufacturersList, deleteManufacturer, productId, handleChange, values.ManufacturerField],
  )

  const addModel = useCallback(
    (name: string) => {
      if (values.ManufacturerField && values.ManufacturerField.id) {
        createModel({ manufacturerId: values.ManufacturerField.id, modelName: name }).then(
          (id: string) => {
            setModelsList([...modelsList, { id, name }])
            handleChange(['ModelField', 'id'])(id)
          },
        )
      }
    },
    [modelsList, createModel, handleChange],
  )

  const removeModel = useCallback(
    (modelId: string) => {
      deleteModel(modelId, productId).then(() => {
        setModelsList(modelsList.filter(({ id }) => modelId !== id))
        if (values.ModelField && values.ModelField.id === modelId) {
          handleChange(['ModelField', 'id'])(null)
        }
      })
    },
    [modelsList, deleteModel, productId, handleChange, values.ModelField],
  )

  const onDelete = useCallback(() => {
    if (modalConfirmOpt !== null) {
      if (modalConfirmType === 'manufacturer') removeManufacturer(modalConfirmOpt.id)
      else if (modalConfirmType === 'model') removeModel(modalConfirmOpt.id)
    }
    setModalConfirmType(null)
  }, [modalConfirmOpt, modalConfirmType, removeManufacturer, removeModel])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (values.ManufacturerField.name) {
      findManufacturers(values.ManufacturerField.name)
    }
  }, [findManufacturers, values.ManufacturerField.name])

  useEffect(() => {
    if (values.ModelField.name) findModels(values.ModelField.name)
  }, [findModels, values.ModelField.name])

  useEffect(() => {
    if (values.LongDescriptionField) {
      setCharLongDescriptionCount(stripHtml(values.LongDescriptionField).length)
    }

    if (values.ShortDescriptionField) {
      setCharShortDescriptionCount(stripHtml(values.ShortDescriptionField).length)
    }
  }, [])

  return (
    <>
      <Box>
        <StyledH3>General Information</StyledH3>
      </Box>
      <Box>
        <Flex column minWidth={890} maxWidth={890}>
          <FormField>
            <FormField.Label>Title</FormField.Label>
            <Input
              placeholder="Enter title"
              error={!!errors.TitleField}
              value={values.TitleField}
              disabled={disabled}
              // $FlowFixMe[prop-missing]
              onChange={value => handleChange('TitleField')(value.trim())}
            />
            <FormField.Error>{errors.TitleField}</FormField.Error>
          </FormField>

          <FormField>
            <FormField.Label>Category</FormField.Label>
            <CategoriesTreeSelect
              ignoreBranchNodes
              width={890}
              multiple={false}
              placeholder="Select category"
              error={!!errors.CategoryId}
              httpClient={httpClient}
              categoryId={values.CategoryId}
              disabled={disabled}
              onChangeCategoryId={value => {
                if (undefined !== value) {
                  // $FlowFixMe[prop-missing]
                  handleChange('CategoryId')(value)
                }
              }}
            />
            <FormField.Error>{errors.CategoryId}</FormField.Error>
          </FormField>

          <Flex spaceBetween="XL">
            <Box minWidth={255} maxWidth={255} mt="XXS">
              <FormField>
                <FormField.Label>Manufacturer</FormField.Label>
                <Box minWidth={255} maxWidth={255}>
                  <Select
                    editable
                    data-test-id={testIds.manufacturerSelect}
                    simpleValue={false}
                    value={(values.ManufacturerField && values.ManufacturerField.id) || null}
                    loading={isLoadManufacturers}
                    options={manufacturersList}
                    disabled={disabled}
                    error={!!(errors.ManufacturerField && errors.ManufacturerField.id)}
                    placeholder="Enter manufacturer"
                    noResultsText="Type to search"
                    onInputChange={term => debounce(() => term && findManufacturers(term))}
                    onChange={value => {
                      handleChange(['ManufacturerField', 'id'])(value ? value.id : null)
                      handleChange(['ModelField', 'id'])(null)
                    }}
                    onAddOption={debounceAddManufacturer}
                    onRemoveOption={openModalConfirm('manufacturer')}
                  />
                </Box>
                <FormField.Error>
                  {errors.ManufacturerField && errors.ManufacturerField.id}
                </FormField.Error>
              </FormField>
            </Box>
            <Box minWidth={255} maxWidth={255} mt="XXS">
              <FormField>
                <FormField.Label>Model</FormField.Label>
                <Box minWidth={255} maxWidth={255}>
                  <Select
                    editable
                    data-test-id={testIds.modelSelect}
                    simpleValue={false}
                    value={(values.ModelField && values.ModelField.id) || null}
                    loading={isLoadModels}
                    disabled={
                      disabled || !(values.ManufacturerField && values.ManufacturerField.id)
                    }
                    error={!!(errors.ModelField && errors.ModelField.name)}
                    placeholder="Choose model"
                    noResultsText="Type to search"
                    options={modelsList}
                    onInputChange={term => debounce(() => term && findModels(term))}
                    onChange={value => {
                      handleChange(['ModelField', 'id'])(value ? value.id : null)
                      handleChange(['ModelField', 'name'])(value ? value.name : null)
                    }}
                    onAddOption={addModel}
                    onRemoveOption={openModalConfirm('model')}
                  />
                </Box>
                <FormField.Error>
                  {errors.ModelField && errors.ModelField.name ? errors.ModelField.name : null}
                </FormField.Error>
              </FormField>
            </Box>
          </Flex>

          <FormField disabled={disabled}>
            <FormField.Label>Country of Origin</FormField.Label>
            <Box minWidth={255} maxWidth={255}>
              <Select
                loading={isLoadCountries}
                clearable={false}
                filterable={false}
                value={(values.CountryOfOriginField && values.CountryOfOriginField.code) || null}
                options={countriesList}
                onChange={(handleChange: any)(['CountryOfOriginField', 'code'])}
              />
            </Box>
            <FormField.Error>
              {errors.CountryOfOriginField && errors.CountryOfOriginField.code
                ? errors.CountryOfOriginField.code
                : null}
            </FormField.Error>
          </FormField>

          <FormField disabled={disabled} error={!!errors.TaxCodeField}>
            <FormField.Label>Tax Code</FormField.Label>
            <Box minWidth={255} maxWidth={255}>
              <Input
                placeholder="Tax code"
                value={values.TaxCodeField}
                // $FlowFixMe[incompatible-call]
                onChange={value => handleChange('TaxCodeField', value)}
              />
            </Box>
            <FormField.Error>{errors.TaxCodeField}</FormField.Error>
          </FormField>

          <FormField disabled={disabled} error={!!errors.RetailPriceField}>
            <FormField.Label>{`Retail Price (${
              values.RetailPriceField && values.RetailPriceField.currency
            })`}</FormField.Label>
            <Flex spaceBetween="XL">
              <Box minWidth={255} maxWidth={255}>
                <NumericInput
                  placeholder="Retail Price"
                  value={values.RetailPriceField.amount}
                  maxFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onChange={value => handleChange(['RetailPriceField', 'amount'])(value)}
                />
              </Box>
              <Box alignSelf="center">
                <Data>
                  {values.RetailPriceField.lastUpdateDate != null && (
                    <>
                      <Text type="data">{`Last updated on `}</Text>
                      <Format.Date>{values.RetailPriceField.lastUpdateDate}</Format.Date>
                    </>
                  )}
                </Data>
              </Box>
            </Flex>
            <FormField.Error>{errors.RetailPriceField}</FormField.Error>
          </FormField>

          <FormField disabled={disabled}>
            <FormField.Label charCounter={shortDescriptionFieldMetaData}>
              Short Description
            </FormField.Label>
            <Editor
              disabled={disabled}
              error={!!errors.ShortDescriptionField}
              placeholder="Enter short description"
              value={String(values.ShortDescriptionField || '')}
              onChange={(value, count) => {
                setCharShortDescriptionCount(count)

                // $FlowFixMe[incompatible-call]
                // $FlowFixMe[prop-missing]
                handleChange('ShortDescriptionField', value || '')
              }}
              onBlur={handleBlur('ShortDescriptionField')}
            />
            <FormField.Error>{errors.ShortDescriptionField}</FormField.Error>
          </FormField>

          <FormField disabled={disabled}>
            <FormField.Label charCounter={longDescriptionFieldMetaData}>
              Full Description
            </FormField.Label>
            <Editor
              placeholder="Enter full description"
              error={!!errors.LongDescriptionField}
              value={String(values.LongDescriptionField || '')}
              disabled={disabled}
              onChange={(value, count) => {
                setCharLongDescriptionCount(count)

                // $FlowFixMe[incompatible-call]
                // $FlowFixMe[prop-missing]
                handleChange('LongDescriptionField', value || '')
              }}
              onBlur={handleBlur('LongDescriptionField')}
            />
            <FormField.Error>{errors.LongDescriptionField}</FormField.Error>
          </FormField>

          <FormField disabled={disabled} error={!!errors.SupportingUrlField}>
            <FormField.Label>Supporting URL</FormField.Label>
            <Input
              placeholder="Supporting URL"
              value={values.SupportingUrlField}
              // $FlowFixMe[incompatible-call]
              onChange={value => handleChange('SupportingUrlField', value)}
            />
            <FormField.Error>{errors.SupportingUrlField}</FormField.Error>
          </FormField>

          <FormField disabled={disabled} error={!!errors.VariationId}>
            <FormField.Label>Variations</FormField.Label>
            <Box minWidth={890} maxWidth={890}>
              <Select
                clearable
                filterable
                width={890}
                loading={isLoadVariations}
                value={values.VariationId}
                options={variationsList}
                onChange={handleChange('VariationId')}
              />
            </Box>
            <FormField.Error>{errors.VariationId}</FormField.Error>
          </FormField>

          <FormField disabled={disabled} error={!!errors.PartNumber}>
            <FormField.Label>Part Number</FormField.Label>
            <Input
              placeholder="Enter part number"
              value={values.PartNumber}
              onChange={value => handleChange('PartNumber')(value.trim())}
            />
            <FormField.Error>{errors.PartNumber}</FormField.Error>
          </FormField>

          {modalConfirmType !== null && modalConfirmOpt !== null && (
            <ModalConfirm
              isOpen
              text={`${modalConfirmType} "${modalConfirmOpt.name || ''}"`}
              onClose={closeModalConfirm}
              onDelete={onDelete}
            />
          )}
        </Flex>
      </Box>
    </>
  )
}
