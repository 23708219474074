// @flow

import * as React from 'react'
import { FormField, Flex, HR } from '@r1/ui-kit'
import { mapKeyToLabel, extractValue } from '../helpers'
import type { ProductGeneralInfoProps } from '../../../types/public/ProductCardView'
import { Value } from './Common'
import { NotSpecified } from './NotSpecified'

export const ProductGeneralInfo = (props: ProductGeneralInfoProps) => {
  const { renderedFields = [], ...restProps } = props

  // $FlowFixMe[invalid-computed-prop]
  const fields = renderedFields.reduce((acc, id) => ({ ...acc, [id]: restProps[id] }), {})

  // $FlowFixMe[missing-annot]
  return Object.keys(fields).map((key, index) => {
    const field = fields[key]
    const label = mapKeyToLabel(key)

    return (
      <Flex key={key} column spaceBetween="S">
        <Flex spaceBetween="XXS">
          <FormField.Label>{label}</FormField.Label>
          <Value>{extractValue(field, key) || <NotSpecified />}</Value>
          {index !== Object.keys(fields).length - 1 ? <HR /> : null}
        </Flex>
      </Flex>
    )
  })
}
