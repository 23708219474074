// @flow

import * as React from 'react'
import { FormField, Flex, HR } from '@r1/ui-kit'
import { mapKeyToLabel, extractValue } from '../helpers'
import type { ProductDimensionsProps } from '../../../types/public/ProductCardView'
import { Value } from './Common'

export const ProductDimensions = (props: ProductDimensionsProps) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <React.Fragment>
    {Object.keys(props).map((key, index) => {
      const field = props[key]
      const label = mapKeyToLabel(key)

      return (
        <Flex key={key} column spaceBetween="S">
          <Flex spaceBetween="XXS">
            <FormField.Label>{label}</FormField.Label>
            <Value>{extractValue(field, key)}</Value>
          </Flex>
          {index !== Object.keys(props).length - 1 ? <HR /> : null}
        </Flex>
      )
    })}
  </React.Fragment>
)
