import * as React from 'react'
import { Control, useController } from 'react-hook-form'
import { DayPeriod } from '@r1-webui/facilities-v1'
import { Box, Button, Flex, FormField, Icon, TimePicker } from '@r1/ui-kit'
import { Value } from '../../Value'

export const DailyPeriod = ({
  period,
  control,
  editable,
  indexPrefix,
  formPrefix,
  dataTestIdPrefix: dataTestId,
  onAddPeriod,
  onRemovePeriod,
}: {
  period: DayPeriod
  control: Control
  editable?: boolean
  indexPrefix: number
  formPrefix: string
  dataTestIdPrefix: string
  onAddPeriod: () => void
  onRemovePeriod: () => void
}) => {
  const { field: from } = useController({
    name: `${formPrefix}.${indexPrefix}.from`,
    control,
    defaultValue: period.from,
  })
  const { field: to } = useController({
    name: `${formPrefix}.${indexPrefix}.to`,
    control,
    defaultValue: period.to,
  })

  const dataTestIdPrefix = `${dataTestId}__period-${indexPrefix}`

  return (
    <Flex spaceBetween="M" align="center">
      <FormField>
        <FormField.Label>Start time</FormField.Label>
        {editable ? (
          <TimePicker format="HH:MM a" {...from} data-test-id={`${dataTestIdPrefix}__from`} />
        ) : (
          <Value>{period.from}</Value>
        )}
      </FormField>
      <FormField>
        <FormField.Label>Finish time</FormField.Label>
        {editable ? (
          <TimePicker format="HH:MM a" {...to} data-test-id={`${dataTestIdPrefix}__to`} />
        ) : (
          <Value>{period.to}</Value>
        )}
      </FormField>
      {editable && (
        <Flex mt="XS" spaceBetween="XS">
          <Box>
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__add-period`}
              onClick={onRemovePeriod}
            >
              <Icon type="minus" size="S" />
            </Button>
          </Box>
          <Box>
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__remove-period`}
              onClick={onAddPeriod}
            >
              <Icon type="plus" size="S" />
            </Button>
          </Box>
        </Flex>
      )}
    </Flex>
  )
}
