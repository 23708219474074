// @flow

import React from 'react'
import type { CancelClaim } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimState'
import { ClaimModalAction } from './ClaimModalAction'

type Props = {
  claimId: string,
  handleCancelClaim: CancelClaim,
  canCancelClaim: boolean,
}

export const CancelClaimAction = (props: Props) => {
  const { claimId, handleCancelClaim, canCancelClaim } = props
  return (
    <ClaimModalAction
      handleClaimAction={async (performedOn, reason) => {
        const resp = await handleCancelClaim({ claimId }, { cancelledOn: performedOn, reason })
        return resp.status
      }}
      canPerformAction={canCancelClaim}
      successText="Claim was successfully canceled"
      failText="Cannot cancel claim. Please, try again later"
      title="Cancel Claim"
      description="Please enter reason for claim cancellation:"
    />
  )
}
