// @flow

import { createPricingRulesApi, baseUri } from '@r1-webui/priceconsolidator-rulemanagement-v1'
import {
  createDataProvidersApi,
  baseUri as dataProvidersBaseUri,
} from '@r1-webui/globalcatalog-dataproviderinfo-v1'
import {
  createDataSourcesApi,
  baseUri as dataSourcesBaseUri,
} from '@r1-webui/globalcatalog-datasourceinfo-v1'

import { UI_API_PREFIXES } from '../constants'

import type { WebUiApi } from './types'

export const priceApi = (httpClient: any): {| ...WebUiApi |} => {
  const { getPricingRule, createPricingRule, getPricingRuleInfos, updatePricingRule } =
    createPricingRulesApi({
      http: httpClient,
      urlPrefix: `${UI_API_PREFIXES.PRICING_CONSOLIDATION}/${baseUri}`,
    })

  const { getAllDataProviders } = createDataProvidersApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.DATA_SOURCE_INFO}/${dataProvidersBaseUri}`,
  })

  const { getAllDataSources } = createDataSourcesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.DATA_SOURCE_INFO}/${dataSourcesBaseUri}`,
  })

  return {
    getPricingRule,
    createPricingRule,
    getPricingRuleInfos,
    updatePricingRule,
    getAllDataProviders,
    getAllDataSources,
  }
}
