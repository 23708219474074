import * as React from 'react'
import * as R from 'ramda'
import { Control, useController } from 'react-hook-form'
import { DailySchedule as DailyScheduleType, DayPeriod } from '@r1-webui/facilities-v1'
import { Box, Button, Flex, FormField, Icon, Select, Text } from '@r1/ui-kit'
import { getDayOfWeek } from '../../../utils/getDayOfWeek'
import { Value } from '../../Value'
import { DailyPeriod } from './DailyPeriod'

export const DailySchedule = ({
  dailySchedule,
  control,
  editable,
  indexPrefix,
  onRemoveDay,
}: {
  dailySchedule: DailyScheduleType
  control: Control
  editable?: boolean
  indexPrefix: number
  onRemoveDay: () => void
}) => {
  const formPrefix = 'schedule'
  const {
    field: { ref: _ref, ...dayField },
    formState: { errors },
  } = useController({
    name: `${formPrefix}.${indexPrefix}.day`,
    control,
    defaultValue: dailySchedule.day,
  })
  const { field: periodsField } = useController({
    name: `${formPrefix}.${indexPrefix}.periods`,
    control,
    defaultValue: dailySchedule.periods,
  })

  const periodsValue: DayPeriod[] = React.useMemo(() => {
    return periodsField.value as DayPeriod[]
  }, [periodsField.value])
  const isPeriodsEmpty = React.useMemo(() => periodsValue.length === 0, [periodsValue.length])

  const onAddPeriod = React.useCallback(() => {
    const newPeriod = {
      from: '09:00 AM',
      to: '09:00 PM',
    }
    const updatedPeriods = [...periodsValue, newPeriod]
    periodsField.onChange(updatedPeriods)
  }, [periodsField, periodsValue])

  const onRemovePeriod = React.useCallback(
    (index: number) => () => {
      const updatedPeriods = periodsValue.filter((_, idx) => idx !== index)
      periodsField.onChange(updatedPeriods)
    },
    [periodsField, periodsValue],
  )

  const dataTestIdPrefix = `facility__schedule-${indexPrefix}`

  return (
    <Flex spaceBetween="XL" align={isPeriodsEmpty ? 'center' : 'flex-start'}>
      <Flex spaceBetween="XS" basis={300} align="center">
        <FormField>
          <FormField.Label>Schedule day</FormField.Label>
          {editable ? (
            <Select
              simpleValue
              {...dayField}
              options={getDayOfWeek()}
              data-test-id={`${dataTestIdPrefix}__day`}
            />
          ) : (
            <Value>{dailySchedule.day}</Value>
          )}
          <FormField.Error>
            {R.pathOr(null, [formPrefix, indexPrefix, 'day', 'message'], errors)}
          </FormField.Error>
        </FormField>
        {editable && (
          <Box mt="XS">
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__remove`}
              onClick={onRemoveDay}
            >
              <Icon type="clear" size="S" />
            </Button>
          </Box>
        )}
      </Flex>
      <Flex column justify="center">
        {editable && isPeriodsEmpty && (
          <Flex align="center" mt="XS">
            <Box>
              <Text>No periods were set for the day. Tap + to add.</Text>{' '}
            </Box>
            <Box ml="XS">
              <Button
                transparent
                shape="square"
                color="secondary"
                data-test-id={`${dataTestIdPrefix}__add-period`}
                onClick={onAddPeriod}
              >
                <Icon type="plus" size="S" />
              </Button>
            </Box>
          </Flex>
        )}
        {periodsValue.map((period, index) => (
          <DailyPeriod
            key={`${period.from}-${period.to}-${index + 1}`}
            period={period}
            control={control}
            editable={editable}
            indexPrefix={index}
            formPrefix={`${formPrefix}.${indexPrefix}.periods`}
            dataTestIdPrefix={dataTestIdPrefix}
            onAddPeriod={onAddPeriod}
            onRemovePeriod={onRemovePeriod(index)}
          />
        ))}
      </Flex>
    </Flex>
  )
}
