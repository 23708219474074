// @flow

import * as React from 'react'
import { Flex, Link } from '@r1/ui-kit'

type Props = {|
  unitId?: string,
  productId?: string,
  newUnitId?: string,
  returnedUnitId?: string,
  newProductId?: string,
  returnedProductId?: string,
|}

function renderLink(unitId, productId) {
  return <Link href={`/r1/product/${productId}`}>TRG-{unitId}</Link>
}

export const UnitLinks = React.memo<Props>(
  ({ unitId, productId, newUnitId, returnedUnitId, newProductId, returnedProductId }: Props) => {
    if (
      newUnitId &&
      newProductId &&
      returnedProductId &&
      returnedUnitId &&
      returnedUnitId !== newUnitId
    )
      return (
        <Flex align="center">
          {renderLink(returnedUnitId, returnedProductId)}
          <div>&nbsp;(original) /&nbsp;</div>
          {renderLink(newUnitId, newProductId)}
        </Flex>
      )

    if (returnedProductId && returnedUnitId) return renderLink(returnedUnitId, returnedProductId)

    if (newProductId && newUnitId) return renderLink(newUnitId, newProductId)

    if (unitId && productId) return renderLink(unitId, productId)

    if (unitId) return `TRG-${unitId}`

    return 'N/A'
  },
)
