// @flow

import React, { useState, useCallback } from 'react'
import { Button, Icon, Tooltip } from '@r1/ui-kit'
import type { DecisionItem } from '../../../types/common'
import { ConfirmationModal } from '../../ConfirmationModal'

type Props = {|
  claimId: string,
  decisionItem: DecisionItem,
  completeDecisionItem: (claimId: string, decisionItemId: string) => Promise<void>,
|}

export const CompleteRefund = (props: Props) => {
  const { claimId, decisionItem, completeDecisionItem } = props

  const [completing, setCompleting] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [completingDecisionItemId, setCompletingDecisionItemId] = useState('')

  const openDialog = useCallback((decisionItemId: string) => {
    return () => {
      setCompletingDecisionItemId(decisionItemId)
      setIsDialogOpen(true)
    }
  }, [])

  const closeDialog = useCallback(() => {
    setCompletingDecisionItemId('')
    setIsDialogOpen(false)
  }, [])

  const onCompleteDecisionItemClick = useCallback(async () => {
    setCompleting(true)
    await completeDecisionItem(claimId, completingDecisionItemId)
    setCompleting(false)
    closeDialog()
  }, [claimId, completingDecisionItemId, completeDecisionItem, closeDialog])

  return (
    <>
      <Tooltip text="Complete step">
        <Button
          transparent
          loading={completing}
          size="S"
          shape="square"
          color="secondary"
          onClick={openDialog(decisionItem.id)}
        >
          <Icon size="XS" type="checkmark" />
        </Button>
      </Tooltip>
      <ConfirmationModal
        show={isDialogOpen}
        message="Do you really want to complete this claim step?"
        completing={completing}
        onConfirm={onCompleteDecisionItemClick}
        onCancel={closeDialog}
      />
    </>
  )
}
