import { useCallback, useEffect, useMemo, useState } from 'react'
import { equals } from 'ramda'

import { useNavigate, useParams } from 'react-router-dom'
import { PERMISSIONS } from '@r1/core-blocks'
import { EntityPermissions } from '@r1-webui/usermanagement-v1'
import { ROUTES } from '../../../../../../navigation'
import * as rolesService from '../services/rolesService'

import { ActionId, EntityId, PageMode, Role, SelectedEntityActions } from '../types'

const NAME_MAX_LETTERS_COUNT = 150
const DESCRIPTION_MAX_LETTERS_COUNT = 500

function getMaxLettersCountError(lettersCount: number) {
  return `Can't be more than ${lettersCount} characters long`
}

const DEFAULT_ROLE_DATA: Role = {
  name: '',
  description: '',
  entityTypeActions: {},
}

export function useRoleController() {
  /** Navigation */
  const navigate = useNavigate()
  const params = useParams()
  const { roleId } = params

  const [pageMode, setPageMode] = useState<PageMode>(PageMode.View)
  useEffect(() => {
    if (roleId === 'new') {
      setPageMode(PageMode.Creating)
    } else {
      setPageMode(PageMode.View)
    }
  }, [roleId])

  const openCreatedRolePage = useCallback(
    (newRoleId: string) => {
      navigate(`${ROUTES.ROLES}/${newRoleId}`)
    },
    [navigate],
  )

  const navigateToRolesGrid = useCallback(() => {
    navigate(ROUTES.ROLES)
  }, [navigate])

  /** Permissions */

  const pagePermissions = useMemo(() => {
    if (pageMode === PageMode.Creating) {
      return [PERMISSIONS.allowRoleCreate]
    }
    return [PERMISSIONS.allowRoleView]
  }, [pageMode])

  /** Form data */

  const [originalRoleData, setOriginalRoleData] = useState<Role>(DEFAULT_ROLE_DATA)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [description, setDescription] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [allEntityActionsInfo, setAllEntityActionsInfo] = useState<EntityPermissions[]>([])
  const [selectedEntityActions, setSelectedEntityActions] = useState<SelectedEntityActions>({})

  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const switchToEditMode = useCallback(() => {
    setPageMode(PageMode.Editing)
  }, [])

  const switchToViewMode = useCallback(
    (withReset = true) => {
      if (withReset) {
        setName(originalRoleData.name)
        setDescription(originalRoleData.description)
        setSelectedEntityActions(originalRoleData.entityTypeActions)
      }
      setPageMode(PageMode.View)
    },
    [originalRoleData],
  )

  const fetchRoleData = useCallback(async () => {
    if (roleId === undefined) return

    const roleData = await rolesService.fetchRole(roleId)
    setOriginalRoleData(roleData)
    setName(roleData.name)
    setDescription(roleData.description)
    setSelectedEntityActions(roleData.entityTypeActions)
  }, [roleId])

  const fetchAllEntityActionsInfo = useCallback(async () => {
    setAllEntityActionsInfo(await rolesService.fetchAllEntityActionsInfo())
  }, [])

  useEffect(() => {
    async function fetchAllEntityTypeActions() {
      setIsLoading(true)
      const initialDataRequests = [fetchAllEntityActionsInfo()]
      if (roleId !== 'new') {
        initialDataRequests.push(fetchRoleData())
      }
      await Promise.all(initialDataRequests)
      setIsLoading(false)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchAllEntityTypeActions()
  }, [])

  const changeName = useCallback((value: string) => {
    if (value.length === 0) {
      setNameError('Field is required')
    } else if (value.length > NAME_MAX_LETTERS_COUNT) {
      setNameError(getMaxLettersCountError(NAME_MAX_LETTERS_COUNT))
    } else {
      setNameError('')
    }
    setName(value)
  }, [])

  const changeDescription = useCallback((value: string) => {
    if (value.length > DESCRIPTION_MAX_LETTERS_COUNT) {
      setDescriptionError(getMaxLettersCountError(DESCRIPTION_MAX_LETTERS_COUNT))
    } else {
      setDescriptionError('')
    }
    setDescription(value)
  }, [])

  const changeEntityActions = useCallback(
    (entityId: EntityId, actionIds: ActionId[]) => {
      const newValue = {
        ...selectedEntityActions,
      }

      if (actionIds.length === 0) {
        delete newValue[entityId]
      } else {
        newValue[entityId] = actionIds
      }
      setSelectedEntityActions(newValue)
    },
    [selectedEntityActions],
  )

  const hasUnsavedChanges = useMemo(() => {
    return (
      name !== originalRoleData.name ||
      description !== originalRoleData.description ||
      !equals(selectedEntityActions, originalRoleData.entityTypeActions)
    )
  }, [
    description,
    name,
    originalRoleData.description,
    originalRoleData.entityTypeActions,
    originalRoleData.name,
    selectedEntityActions,
  ])

  const areThereErrors = useMemo(() => {
    return nameError || descriptionError
  }, [nameError, descriptionError])

  /** Actions */

  const createRole = useCallback(async () => {
    if (areThereErrors) return
    setIsSubmitting(true)
    const newRoleId = await rolesService.createRole({
      name,
      description,
      entityTypeActions: selectedEntityActions,
    })
    setIsSubmitting(false)
    if (newRoleId !== null) {
      openCreatedRolePage(newRoleId)
    }
  }, [areThereErrors, name, description, selectedEntityActions, openCreatedRolePage])

  const updateRole = useCallback(async () => {
    if (areThereErrors || roleId === undefined) return

    setIsSubmitting(true)
    const success = await rolesService.updateRole(roleId, {
      name,
      description,
      entityTypeActions: selectedEntityActions,
    })
    if (success) {
      switchToViewMode(false)
    }
    setIsSubmitting(false)
  }, [areThereErrors, description, name, roleId, selectedEntityActions, switchToViewMode])

  const deleteRole = useCallback(async () => {
    if (roleId === undefined) return

    setIsSubmitting(true)
    const success = await rolesService.deleteRole(roleId)
    if (success) {
      navigateToRolesGrid()
    }
    setIsSubmitting(false)
  }, [navigateToRolesGrid, roleId])

  /** History */
  const [isHistoryOpen, setIsHistoryOpen] = useState(false)
  const openHistory = useCallback(() => {
    setIsHistoryOpen(true)
  }, [])
  const closeHistory = useCallback(() => {
    setIsHistoryOpen(false)
  }, [])

  return {
    roleId,
    name: {
      value: name,
      onChange: changeName,
      error: nameError,
    },

    description: {
      value: description,
      onChange: changeDescription,
      error: descriptionError,
    },

    allEntityTypeActionsInfo: allEntityActionsInfo,
    rolePermissions: {
      value: selectedEntityActions,
      onChange: changeEntityActions,
    },

    hasUnsavedChanges,

    pageMode,
    switchToEditMode,
    switchToViewMode,
    openRolesGrid: navigateToRolesGrid,
    pagePermissions,

    isLoading,
    isSubmitting,

    createRole,
    updateRole,
    deleteRole,

    isHistoryOpen,
    openHistory,
    closeHistory,
  }
}
