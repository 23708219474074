// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { useCallback, useMemo, useState } from 'react'
import { GridGenerator } from '@r1/grid-generator'
import { Loader } from '@r1/ui-kit'
import { Content, ContentHeader, Main, useBackgroundTasksProvider } from '@r1/wireframe-primary'
import { useNavigate } from 'react-router-dom'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { errorHandler } from '../../../../utils/errorHandler'
import { httpClient } from '../../../../httpClient'
import { useStateQuery } from '../hooks/useStateQuery'
import { ProductHistoryModal } from '../../../../components/ProductHistoryModal'
// $FlowFixMe[cannot-resolve-module]
import { historyApi } from '../../../../api/productHistory/history.ts'
import { useDefaultStateQuery } from './hooks'

const GRID_ID = 'InventoryUnits'
const GRID_BASE_URL = '/uisvc/legacy-grids'
const AGGREGATION_COUNT = { title: 'All Records' }

const SymbolIcon = styled('span')`
  font-size: 32px;
`

const infinityIcon = <SymbolIcon>&#8734;</SymbolIcon>

const initialCustomColumnsSettings = [
  {
    cell: {
      $type: 'custom',
      renderer: (data: $AnyObject) => {
        if (data.InfinityQuantity === true) {
          return infinityIcon
        }
        return data.QtyAvailable
      },
    },
    columnId: 'Quantity',
    horizontalAlign: 'right',
  },
]

const bulkActions = { actions: [] }

const fullscreenSettings = { toolbarTopPosition: 140 }

export const InventoryGrid = () => {
  const navigate = useNavigate()
  const { initialStateQuery, onChangeState } = useStateQuery()
  const { defaultStateQuery, loading } = useDefaultStateQuery(initialStateQuery)
  const { showBackgroundTasks } = useBackgroundTasksProvider()
  const [showHistoryModal, setHistoryModal] = useState(false)
  const [id, setId] = useState('')

  const closeHistoryModal = useCallback(() => setHistoryModal(false), [setHistoryModal])
  const openHistoryModal = useCallback(
    rowData => {
      setId(String(rowData.ProductId))
      setHistoryModal(true)
    },
    [setHistoryModal, setId],
  )

  const redirectToUnitPage = useCallback(
    productId => navigate(`/r1/product/${String(productId)}`),
    [navigate],
  )

  const rowActions = useMemo(
    () => [
      {
        title: 'View unit details',
        handler: rowData => redirectToUnitPage(rowData.ProductId),
      },
      {
        title: 'View history',
        handler: openHistoryModal,
      },
    ],
    [redirectToUnitPage, openHistoryModal],
  )

  const fetchData = useCallback(async () => {
    const data = await historyApi.getProductHistory(id)
    return data
  }, [id])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowUnitView]}>
      <Main fullScreen>
        <ContentHeader />

        <ContentHeader.Title>Inventory Units</ContentHeader.Title>

        <Content column>
          {loading ? (
            <Loader />
          ) : (
            <>
              <GridGenerator
                exportable
                fullscreen
                fullscreenSettings={fullscreenSettings}
                bulkActions={bulkActions}
                gridId={GRID_ID}
                apiRoutes={{
                  engine: `${GRID_BASE_URL}/v2/Grids/engine`,
                  viewSettingsStorage: `${GRID_BASE_URL}/v2/Grids/view`,
                  queryStorage: `${GRID_BASE_URL}/v2/Grids/querystorage`,
                }}
                initialStateQuery={defaultStateQuery}
                aggregationCount={AGGREGATION_COUNT}
                httpClient={httpClient}
                initialCustomColumnsSettings={initialCustomColumnsSettings}
                rowActions={rowActions}
                onError={errorHandler}
                onChangeState={onChangeState}
                onOpenBackgroundTasks={showBackgroundTasks}
              />
              <ProductHistoryModal
                title="Product History"
                fetchHistory={fetchData}
                show={showHistoryModal}
                dataTestId="modal-product-history"
                onClose={closeHistoryModal}
              />
            </>
          )}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
