import * as React from 'react'
import { Flex, Box, Switch } from '@r1/ui-kit'
import { ShippingOption } from '@r1-webui/shipping-returnshippingrules-v1'
import { ChangeValueChildrenHandler } from '@r1/form-manager'
import { FormValues } from '../../types'

export const ShippingServicesLabel = ({
  option,
  index,
  onChange,
}: {
  option: ShippingOption
  index: number
  onChange: ChangeValueChildrenHandler<FormValues>
}) => (
  <Flex align="center">
    {option.shippingServices?.type === 'ExcludeShippingServices'
      ? 'Exclude Shipping Services'
      : 'Include Shipping Services'}
    <Box ml="XS">
      <Switch
        checked={option.shippingServices?.type === 'IncludeShippingServices'}
        onChange={include =>
          /** @ts-ignore */
          onChange(['shippingOptions', index], {
            ...option,
            shippingServices: {
              type: include ? 'IncludeShippingServices' : 'ExcludeShippingServices',
              shippingServices: option.shippingServices?.shippingServices ?? [],
            },
          })
        }
      />
    </Box>
  </Flex>
)
