import styled from '@emotion/styled'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  box-sizing: border-box;
  padding: 5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  border-color: ${({ theme }) => theme.palette.grey[500]};
  list-style: none;
`

export default Container
