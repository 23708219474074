import { ResponseSuperType } from '../api/contracts'

const THUMBOR_URL = '/uisvc/thumbor/unsafe/smart'

const removeProtocol = (url: string) => {
  return url.replace(/(^\w+:|^)\/\//, '')
}

const getThumborUrl = (url: string) => {
  const urlWithoutProtocol = removeProtocol(url)
  return `${THUMBOR_URL}/${urlWithoutProtocol}`
}

export function imageToBase64(url: string): Promise<ResponseSuperType<string>> {
  return new Promise<ResponseSuperType<string>>(resolve => {
    const xhr = new XMLHttpRequest()
    const reader = new FileReader()

    xhr.onload = () => {
      reader.onloadend = () => {
        resolve({
          $type: 'Success',
          // @ts-expect-error
          payload: reader.result,
        })
      }

      reader.readAsDataURL(xhr.response)
    }

    xhr.onerror = () => {
      resolve({
        $type: 'Common',
        payload: new Error('Image loading Error'),
      })
    }

    reader.onerror = () => {
      resolve({
        $type: 'Common',
        payload: new Error('File reading Error'),
      })
    }

    xhr.open('GET', getThumborUrl(url))
    xhr.responseType = 'blob'
    xhr.send()
  })
}
