// @flow

import * as React from 'react'

import { ContentHeader } from '@r1/wireframe-primary'
import { Collapse, Container, Row, Col, Flex, Tab, Tabs, Icon } from '@r1/ui-kit'

import { ClaimProduct } from '../ClaimProduct'
import { ClaimCard } from '../ClaimCard'
import { RefundSidebar } from '../RefundSidebar'
import { ReturnGrid } from '../ReturnGrid'
import { ReplacementGrid } from '../ReplacementGrid'
import { ClaimHistory } from '../ClaimHistory'
import { ClaimMessages } from '../MessageLog/ClaimMessages'
import { ClaimChat } from '../ClaimChat'

import { CancelClaimAction } from './actions/CancelClaimAction'
import { CloseClaimAction } from './actions/CloseClaimAction'
import type { ViewProps } from './types'

export const View = (props: ViewProps) => {
  const {
    api,
    isLoading,
    data,
    sidebarIsVisible,
    refundIsSubmitting,
    withReturn,
    activeTabId,
    hasUnreadChatMessages,
    onForceRefund,
    onWithReturnChange,
    updateMessages,
    setHasUnreadMessages,
    createClaim,
    restartDecisionItem,
    completeDecisionItem,
    setExpectedDecisionTypeId,
  } = props

  const {
    claim,
    customer,
    marketplace,
    order,
    products,
    facilities,
    rmaProfileId,
    expectedDecisionTypes,
    returnReasons,
  } = data
  const hasReplacements = ['ReplacementAfterReturn', 'ReplacementOnly'].includes(claim.claimType)

  const getTabs = () => {
    const result = []
    result.push(
      <Tab key={1} id="tabReturns" title="Returns">
        <ReturnGrid api={api} claimId={claim.id} />
      </Tab>,
    )
    if (hasReplacements) {
      result.push(
        <Tab key={2} id="tabReplacements" title="Replacements">
          <ReplacementGrid api={api} claimId={claim.id} />
        </Tab>,
      )
    }
    return result
  }

  const getMainTabs = () => {
    const result = []
    result.push(
      <Tab key={1} id="tabClaimDetails" title="Claim Details">
        <Flex column>
          {products.map(p => (
            <ClaimProduct key={p.id} claimId={claim.id} product={p} claimsApi={api.claims} />
          ))}
          <Flex mb="XL">
            <Tabs active={activeTabId}>{getTabs()}</Tabs>
          </Flex>
          <Collapse>
            <Collapse.Panel id="Claim messages" title="Claim Messages">
              <ClaimMessages
                api={api}
                claimId={claim.id}
                isLoading={isLoading}
                messageLog={claim.messages}
                setMessageLog={updateMessages}
              />
            </Collapse.Panel>
          </Collapse>
        </Flex>
      </Tab>,
    )
    if (marketplace.isChatSupported) {
      result.push(
        <Tab
          key={2}
          id="tabUserChat"
          title={
            <Flex spaceBetween="S">
              <div>User Chat</div>
              {hasUnreadChatMessages && (
                <Icon type="internal/notification/info" color="#5390FD" size="XS" />
              )}
            </Flex>
          }
        >
          <ClaimChat
            chatApi={api.chat}
            customer={customer}
            claim={claim}
            order={order}
            expectedDecisionTypes={expectedDecisionTypes}
            returnReasons={returnReasons}
            setHasUnreadMessages={setHasUnreadMessages}
            createClaim={createClaim}
          />
        </Tab>,
      )
    }
    result.push(
      <Tab key={3} id="tabOrderHistory" title="Order History">
        <ClaimHistory purchaseOrderId={order.purchaseOrderId} rmaProfileId={rmaProfileId} />
      </Tab>,
    )
    result.push(
      <Tab key={4} id="tabUserHistory" title="User History">
        <ClaimHistory showDatePicker customerEmail={customer.email} />
      </Tab>,
    )
    return result
  }

  return (
    <Flex mb="XL">
      <ContentHeader.ActionButtons>
        <CancelClaimAction
          claimId={claim.id}
          handleCancelClaim={api.claimState.cancelClaim}
          canCancelClaim={claim.status !== 'Closed' && claim.status !== 'Canceled'}
        />
        <CloseClaimAction
          claimId={claim.id}
          handleCloseClaim={api.claimState.closeClaim}
          canCloseClaim={claim.status !== 'Closed' && claim.status !== 'Canceled'}
        />
      </ContentHeader.ActionButtons>
      <div>
        <RefundSidebar
          api={api}
          rmaProfileId={rmaProfileId}
          claim={claim}
          products={products}
          isVisible={sidebarIsVisible}
          isSubmitting={refundIsSubmitting}
          withReturn={withReturn}
          onClose={() => {}}
          onSubmit={onForceRefund}
          onWithReturnChange={onWithReturnChange}
        />
      </div>
      <Container>
        <Row>
          <Col md={7}>
            <Tabs forceRender>{getMainTabs()}</Tabs>
          </Col>
          <Col md={5}>
            <ClaimCard
              claim={claim}
              order={order}
              marketplace={marketplace}
              customer={customer}
              facilities={facilities}
              restartDecisionItem={restartDecisionItem}
              completeDecisionItem={completeDecisionItem}
              expectedDecisionTypes={expectedDecisionTypes}
              setExpectedDecisionTypeId={setExpectedDecisionTypeId}
            />
          </Col>
        </Row>
      </Container>
    </Flex>
  )
}
