// @flow

import * as React from 'react'

import { Button, Flex, Input, Icon } from '@r1/ui-kit'

import type { StepsProps } from '../types'
import { OrderSelectionList } from '../../OrderSelectionList'
import {
  OrderSearchForm,
  OrderSelectionListWrapper,
  InputField,
  ErrorMessage,
} from './StyledComponents'

export const OrderSelectionStep = (props: StepsProps) => {
  const { orderSearchString, orderSearchAction, selectedOrder } = props.orderSearchInfo

  const onSubmit = event => {
    event.preventDefault()
    orderSearchAction.dispatch()
  }

  return (
    <Flex column>
      <Flex mb="L">
        <OrderSearchForm onSubmit={onSubmit}>
          {' '}
          <InputField>
            <Input
              value={orderSearchString.value}
              error={!!orderSearchString.error}
              placeholder="Marketplace ID or Purchase ID"
              disabled={orderSearchAction.isExecuting}
              onChange={orderSearchString.onChange}
            />
            <ErrorMessage>{orderSearchString.error}</ErrorMessage>
          </InputField>
          <Button type="submit" color="primary" disabled={orderSearchAction.isExecuting}>
            <Icon type="search" color="inverted" />
            Search
          </Button>
        </OrderSearchForm>
      </Flex>
      <OrderSelectionListWrapper error={selectedOrder.error}>
        <OrderSelectionList {...props} />
      </OrderSelectionListWrapper>
      <ErrorMessage>{selectedOrder.error}</ErrorMessage>
    </Flex>
  )
}
