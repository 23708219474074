import { mapToLazyEnum } from './mapToLazyEnum'

const PROFILE_TYPES = [
  'Pending',
  'Rtv',
  'RtvApproved',
  'NonRtv',
  'RtvRejected',
  'RtvDestroy',
  'RtvLiquidate',
  'Research',
  'ReturnToStock',
  'RtvVendorInspect',
]

export const rtvTypes = mapToLazyEnum(PROFILE_TYPES)
