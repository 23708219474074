// @flow

import { evolve, mergeLeft } from 'ramda'
import { getClaimType } from '../../../../utils'
import type { WithController } from '../types'
import { getOrderLines } from './utils'

export class ClaimInfoHandlerController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  handleExpectedDecisionTypeSelection = (value: string) => {
    this.component.validation.resetError('expectedDecisionTypeId')

    this.component.setState(state => {
      const { order, returnReasons } = state.claimCreation
      if (!order) {
        return null
      }

      const claimType = value ? getClaimType(value) : null

      // Reset order lines.
      const orderLines = getOrderLines(order, returnReasons, claimType)
      // Reset claim total.
      const claimTotal = {
        amount: {
          currency: 'USD',
          amount: 0,
        },
        quantity: 0,
      }

      return evolve(
        {
          claimCreation: mergeLeft({
            expectedDecisionTypeId: value,
            claimType,
            orderLines,
            claimTotal,
          }),
        },
        state,
      )
    })
  }

  handleClaimDescriptionChange = (value: string) => {
    this.component.setState(
      evolve({
        claimCreation: mergeLeft({
          claimDescription: value,
        }),
      }),
    )
  }
}

export const createClaimInfoHandlerContoller = (component: WithController) =>
  new ClaimInfoHandlerController(component)
