// @flow

import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { ClaimScreen } from '../../components/ClaimScreen'
import { ROUTES } from '../../../../navigation'
import { httpClient } from '../../../../httpClient'
import type { BusinessClaimType } from '../../types/common'

type Props = {
  claimType: BusinessClaimType,
}

export const ViewClaim = ({ claimType }: Props) => {
  const navigate = useNavigate()
  const { claimId } = useParams()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowNewRmaVendorPortalView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={claimType === 'B2C' ? ROUTES.CLAIMS_B2C : ROUTES.CLAIMS_B2B}>
            {`${claimType} RMA Claims`}
          </Link>
        </ContentHeader.Breadcrumbs>
        <ContentHeader.Title>{`Claim ID ${claimId}`}</ContentHeader.Title>
        <Content>
          <ClaimScreen
            claimId={claimId}
            httpClient={httpClient}
            ActionButtons={ContentHeader.ActionButtons}
            businessClaimType={claimType}
            onForbidden={() => {
              navigate(ROUTES.NO_PERMISSION)
              return null
            }}
            onNotFound={() => {
              navigate(ROUTES.NOT_FOUND)
              return null
            }}
            onUnknownError={() => null}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
