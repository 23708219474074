// @flow

import * as React from 'react'
import { handleServerError } from '@r1/core-blocks'
import uuid from 'uuid'
import type { Image as ApiImage } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import { convertFileToBase64, removeBase64ImageType } from '../../utils'
import { View } from './View'
import type { ModalImage, UploaderPayload } from './types'

type Props = {
  show: boolean,
  setShow: boolean => void,
  addImages: (ApiImage[]) => Promise<void>,
}

export const ClaimLinePhotoModal = (props: Props) => {
  const { show, setShow, addImages } = props

  const [images, setImages] = React.useState<ModalImage[]>([])
  const [isUploading, setIsUploading] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const uploaderHandler = React.useCallback(async (uploaderPayload: UploaderPayload) => {
    const { file } = uploaderPayload
    setIsUploading(true)
    const readerResponse = await convertFileToBase64(file)
    if (readerResponse.status !== 200) {
      setIsUploading(false)
      handleServerError(new Error(readerResponse))
      return
    }

    const base64Image = readerResponse.body
    const imageData = removeBase64ImageType(base64Image)
    const fileType = file.type
    const newTempId = uuid.v4()
    const newImage = { id: newTempId, imageData, base64Image, fileType }
    setImages(existingImages => existingImages.concat(newImage))

    setIsUploading(false)
  }, [])

  const deleteImageHandler = React.useCallback((index: number) => {
    setImages(prevImages => {
      return prevImages.slice(0, index).concat(prevImages.slice(index + 1))
    })
  }, [])

  const closeHandler = React.useCallback(() => {
    setImages([])
    setShow(false)
  }, [setShow])

  const saveHandler = React.useCallback(async () => {
    setIsSaving(true)
    await addImages(images.map(i => ({ base64Data: i.imageData, format: i.fileType })))
    setIsSaving(false)
    closeHandler()
    setImages([])
  }, [addImages, images, closeHandler, setImages])

  return (
    <View
      show={show}
      isUploading={isUploading}
      isSaving={isSaving}
      images={images}
      deleteImageHandler={deleteImageHandler}
      uploaderHandler={uploaderHandler}
      closeHandler={closeHandler}
      saveHandler={saveHandler}
    />
  )
}
