// @flow

import React, { memo } from 'react'
import { FormField, Select, Box } from '@r1/ui-kit'
import type { BooleanAttributeChildrenProps } from '../../../../../../types/attribute.type'

export const Boolean = memo<BooleanAttributeChildrenProps>(
  ({ error, title, handleChange, optionIndex, disabled, value }: BooleanAttributeChildrenProps) => {
    return (
      <FormField disabled={disabled} error={!!error}>
        <FormField.Label>{title}</FormField.Label>
        <Box minWidth={255} maxWidth={255}>
          <Select
            clearable
            options={
              ([
                { id: 'true', name: 'Yes' },
                { id: 'false', name: 'No' },
              ]: Array<$AnyObject>)
            }
            filterable={false}
            value={value !== undefined && value !== null ? value.toString() : null}
            onChange={handleChange(['Attributes', optionIndex, 'formValue'])}
          />
        </Box>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
