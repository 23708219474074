// @flow

import styled from '@emotion/styled'

const Container = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
`

const Toolbox = styled('div')`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  text-transform: upperCase;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 11px 16px;
  font-weight: 300;
  justify-content: center;

  > span {
    font-size: 14px;
    letter-spacing: 5.3px;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  }
`

const Close = styled('div')`
  border: 1px solid white;
  text-transform: upperCase;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 4px 8px;
  outline: none;
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
  cursor: pointer;
`

export { Close, Toolbox, Container }
