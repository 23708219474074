// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { ContentHeader } from '@r1/wireframe-primary'
import { compose, withHandlers } from 'recompose'

import { Box, NotificationSystem, HR } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'
import type { UnauthorizedError } from '@r1/types/flow/libTypes'

import { Wizard } from '../../../../wizard'
import type { Field } from '../../types'
import { Fields } from './Fields'

export type ProfileCardProps = {
  id: string | null,
  info: string | null,
  image: any,
  name: string,
  fields: Array<Field>,
  entityId: string | null,
  onEditComplete: (step: Object) => void,
  onWizardError: (error: UnauthorizedError) => void,
  setEditable: (editable?: boolean) => void,
  httpClient: any,
  // this prop sets editable state of the card
  editable: boolean,
  // this prop is controlled by permissions service
  isAllowedToEdit: boolean,
}

const enhance = compose(
  // $FlowFixMe[incompatible-use]
  // $FlowFixMe[missing-annot]
  withHandlers({
    onWizardError:
      ({ onError, setEditable }) =>
      error => {
        const { message, $type } = error

        setEditable(false)

        if ($type === 'Unauthorized' && onError) {
          onError(error)
        } else {
          NotificationSystem.addNotification({ level: 'error', message })
        }
      },
  }),
)

const IconWrapper = styled('div')`
  padding-right: 10px;
`
const TextWrapper = styled(Box)`
  max-width: 80%;
`

const InfoWrap = styled(Box)`
  align-items: center;
`

const ProfileCardComponent = ({
  id,
  info,
  image,
  name,
  fields,
  editable,
  entityId,
  setEditable,
  onEditComplete,
  onWizardError,
  httpClient,
  isAllowedToEdit,
}: ProfileCardProps) => (
  <React.Fragment>
    {!editable && <ContentHeader.Title>{name}</ContentHeader.Title>}
    <InfoWrap pb="M">
      <IconWrapper>{image && <GenericIcon width={110} svgId={image.value} />}</IconWrapper>
      <TextWrapper>{info}</TextWrapper>
    </InfoWrap>
    {fields.length > 0 &&
      (editable ? (
        <Wizard
          httpClient={httpClient}
          wizard={`edit_${id || ''}`}
          baseUrlPrefix="/uisvc/espm"
          entityId={entityId}
          onNextStep={onEditComplete}
          onError={onWizardError}
        />
      ) : (
        <Fields isAllowedToEdit={isAllowedToEdit} fields={fields} setEditable={setEditable} />
      ))}
    <HR />
  </React.Fragment>
)

export const ProfileCard = enhance(ProfileCardComponent)
