// @flow

import type { WithControllerState } from './types'

export const defaultState: WithControllerState = {
  navigation: {
    currentStep: 'orderSelection',
    steps: [
      {
        id: 'orderSelection',
        complete: false,
        error: false,
      },
      {
        id: 'claimInfo',
        complete: false,
        error: false,
      },
      {
        id: 'claimCreation',
        complete: false,
        error: false,
      },
      {
        id: 'summary',
        complete: false,
        error: false,
      },
    ],
  },
  claimCreation: {
    orderSearchString: '',
    isOrderSearchExecuting: false,
    orders: [],
    orderStatuses: new Map(),
    rmaProfiles: new Map(),
    shippingAddresses: new Map(),
    order: null,
    expectedDecisionTypeId: '',
    claimType: null,
    claimDescription: '',
    orderLines: [],
    currentOrderLine: null,
    isOrderLineNotesModalVisible: false,
    isOrderLineReplacementsModalVisible: false,
    orderStatus: null,
    rmaProfile: null,
    returnReasons: [],
    expectedDecisionTypes: [],
    shippingAddress: null,
    claimTotal: {
      amount: {
        amount: '0.0',
        currency: 'USD',
      },
      quantity: 0,
    },
    isClaimCreationExecuting: false,
  },
  errors: {
    order: '',
    orderLines: '',
  },
}
