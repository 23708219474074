// @flow

import * as React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

type ChartData = {
  date: Date,
  quarantinedPricesCount: number,
  syncedPricesCount: number,
}[]

export const QuarantineProductsChart = ({ chartData }: { chartData: ChartData }) => {
  if (!chartData || chartData.length === 0) {
    return null
  }

  const preparedData = chartData.map(({ date, quarantinedPricesCount, syncedPricesCount }) => ({
    date: new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    }),
    Quarantined: quarantinedPricesCount,
    Approved: syncedPricesCount,
  }))

  return (
    <BarChart
      width={1300}
      height={300}
      data={preparedData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 25,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Quarantined" stackId="a" fill="#85b3ec" />
      <Bar dataKey="Approved" stackId="a" fill="#fed07a" />
    </BarChart>
  )
}
