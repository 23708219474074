// @flow

import * as React from 'react'
import { useMemo } from 'react'

import { Content, Main, ContentHeader, useBackgroundTasksProvider } from '@r1/wireframe-primary'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Flex } from '@r1/ui-kit'

import { httpClient } from '../../../../httpClient'

export const ViewProductsGrid = () => {
  const { showBackgroundTasks } = useBackgroundTasksProvider()

  const initialStateQuery = useMemo(() => {
    const query = parseQuery()
    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: 100,
      },
    }
  }, [])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowProductView]}>
      <Main fullScreen>
        <ContentHeader />
        <ContentHeader.Title enableBetaLabel>Products</ContentHeader.Title>
        <Content>
          <Flex column>
            <GridGenerator
              exportable
              gridId="ProductGrid"
              gridEngineBaseUrl="/uisvc/integration-grids"
              initialStateQuery={initialStateQuery}
              httpClient={httpClient}
              aggregationCount={{ title: 'All Products' }}
              onChangeState={setQuery}
              onOpenBackgroundTasks={showBackgroundTasks}
            />
          </Flex>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
