import * as React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { Field, reduxForm, FieldArray } from 'redux-form'
import { Checkbox, FormField, Flex, Text } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { CategoriesSelect } from '../../../../../components/categories/Select'
import { WALMART_VALUE_NAME } from './constants'
import * as actions from './module'

const Label = styled(FormField.Label)`
  min-width: 320px;
`

const mapStateToProps = ({ categoryMapping }) => ({
  initialValues: categoryMapping.data ? { rows: categoryMapping.data } : null,
})

const enhance = compose(
  connect(mapStateToProps, { ...actions }),
  reduxForm({
    form: 'categoryMappingForm',
    onSubmit: ({ rows }, dispatch, { id, handleSubmit }) =>
      handleSubmit({ productTemplateId: id, rows }),
  }),
  withPropsOnChange(['dirty'], ({ dirty, onAddDirtyTab }) => {
    if (dirty) onAddDirtyTab('category mapping')
  }),
)

class CategoryMapping extends React.Component {
  componentDidMount() {
    const { id, loadData } = this.props
    loadData(id)
  }

  renderRow = ({ input: { value, onChange }, disabled }) => {
    const { changeWalmartCategoryId } = this.props

    return (
      <Flex align="center">
        <Label>{value.name}</Label>
        <Flex grow={3} basis={0.85} align="center" spaceBetween="S">
          <Checkbox
            disabled={disabled}
            checked={value.enableAutoUpdate}
            onChange={() => onChange({ ...value, enableAutoUpdate: !value.enableAutoUpdate })}
          />
          <Flex column basis={1}>
            <CategoriesSelect
              ignoreBranchNodes
              disabled={disabled}
              value={value.categoryId}
              dataSourceId={value.dataSourceId}
              dataSourceName={value.name}
              onChange={categoryId => {
                if (value.name === WALMART_VALUE_NAME) {
                  changeWalmartCategoryId(categoryId, value.dataSourceId)
                }
                onChange({ ...value, categoryId })
              }}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }

  renderRows = ({ fields, disabled }) => {
    if (!fields.length) return <Text>There is no mapping for this category</Text>
    return (
      <Flex column spaceBetween="S">
        {fields.map(field => (
          <Field key={field} name={field} component={this.renderRow} disabled={disabled} />
        ))}
      </Flex>
    )
  }

  render() {
    const { handleSubmit, disabled } = this.props

    return (
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Flex column spaceBetween="M">
          <FieldArray name="rows" component={this.renderRows} disabled={disabled} />
        </Flex>
      </form>
    )
  }
}

export const CategoryMappingEnhanced = enhance(CategoryMapping)
