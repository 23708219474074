import {
  categoryMappingReducers,
  consigneeUPCReducers,
  imagesReducers,
  mainInfoReducers,
  otherSettingsReducers,
  pricingReducers,
  walmartAttributesReducers,
} from './components'
import { reducer } from './module'

export const reducers = {
  productTemplate: reducer,
  ...categoryMappingReducers,
  ...consigneeUPCReducers,
  ...imagesReducers,
  ...mainInfoReducers,
  ...otherSettingsReducers,
  ...pricingReducers,
  ...walmartAttributesReducers,
}
