// @flow

import React, { memo, useState, useCallback, useEffect, useMemo } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Flex, Modal, RadioGroup, Box, Radio, Textarea } from '@r1/ui-kit'

type LockModalProps = {
  isOpen: boolean,
  reasons: { id: string, title: string }[],
  onClose: () => void,
  onLockSubmit: (lockReasonId: string, lockReasonDescription?: string) => void,
}

const OTHER_ID = 'f7490752287344fdbe1156090ae0ad34'

export const LockModal = memo<LockModalProps>(
  ({ isOpen, reasons, onLockSubmit, onClose }: LockModalProps) => {
    const [isLockDisabled, setIsLockDisabled] = useState(false)
    const [selected, setSelected] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [reason, setReason] = useState('')

    const onSelectedChange = useCallback(id => setSelected(id.toString()), [])
    const onChangeReason = useCallback(
      text => (text.length < 200 ? setReason(text) : undefined),
      [],
    )

    useEffect(() => {
      setIsLockDisabled(selected === OTHER_ID && reason.length === 0)
    }, [reason.length, selected])

    useEffect(() => {
      if (reasons.length > 0) {
        setSelected(reasons[0].id)
      }
    }, [reasons])

    useEffect(() => {
      if (isOpen) {
        setIsLoading(false)
      }
    }, [isOpen])

    const modalActionButtons: BottomActionButton[] = useMemo(
      () => [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        {
          title: 'Lock',
          loading: isLoading,
          disabled: isLockDisabled,
          onClick: () => {
            setIsLoading(true)
            onLockSubmit(selected, reason || undefined)
            onClose()
          },
          align: 'right',
        },
      ],
      [onClose, setIsLoading, onLockSubmit, isLoading, isLockDisabled, selected, reason],
    )

    return (
      <Modal
        isControlled
        show={isOpen}
        size="S"
        title="Lock product template"
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onClose}
      >
        <Flex column minWidth={544} maxWidth={544}>
          Are you sure you want to lock product template? Select reason for lock:
          <Flex column mb="XL" mt="S">
            <RadioGroup value={selected} onChange={onSelectedChange}>
              <Flex column>
                {reasons.map(({ id, title }) => (
                  <Box key={id}>
                    <Radio value={id} label={title} />
                  </Box>
                ))}
              </Flex>
            </RadioGroup>
          </Flex>
          {selected === OTHER_ID && (
            <Flex>
              <Textarea rows={3} value={reason} onChange={onChangeReason} />
            </Flex>
          )}
        </Flex>
      </Modal>
    )
  },
)
