import * as React from 'react'
import { Flex } from '@r1/ui-kit'
import { compose, withHandlers, mapProps } from 'recompose'
import { transformAndCheckId } from '../../../../utils/dataUtils'

import { IdentifiersText } from './IdentifiersText'
import { IdentifiersRows } from './IdentifiersRows'

function idsToText(ids) {
  const isValidIds = Array.isArray(ids)

  return isValidIds ? ids.map(({ value }) => value)?.join('\n') : ''
}

function idsToRows(ids) {
  const isValidIds = Array.isArray(ids)

  return isValidIds ? ids.map(({ type, value, attributes }) => ({ type, value, attributes })) : []
}

const enhance = compose(
  withHandlers({
    transformTextToRows:
      ({ onChange, identifiers, onError }) =>
      textValues => {
        const identifiersArray = textValues ? textValues.split('\n') : []
        const transformedAndCheckedIds = identifiersArray.map(id => {
          const existRow = identifiers.find(row => row.value === id)
          const existType = existRow && existRow.type
          return transformAndCheckId(id, existType, onError)
        })
        onChange(transformedAndCheckedIds)
      },
    onEditHandler:
      ({ toggleTextState }) =>
      () =>
        toggleTextState(true),
  }),
  mapProps(({ identifiers, ...props }) => ({
    identifiersText: identifiers ? idsToText(identifiers) : '',
    identifiersRows: identifiers ? idsToRows(identifiers) : [],
    ...props,
  })),
)

const IdentifiersComponent = ({
  identifiersText,
  identifiersRows,
  isTextState,
  transformTextToRows,
  onEditHandler,
  disabled,
}) => (
  <Flex mt="S">
    {isTextState ? (
      <IdentifiersText value={identifiersText} onSave={transformTextToRows} />
    ) : (
      <IdentifiersRows data={identifiersRows} disabled={disabled} onEdit={onEditHandler} />
    )}
  </Flex>
)

export const Identifiers = enhance(IdentifiersComponent)
