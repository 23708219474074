import { always, evolve, keys, values } from 'ramda'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { actions as priceActions } from '../../../../../modules/productTemplates/price'
import { actions as manifestActions } from '../../../../../modules/productTemplates/price/manifest'
import { showErrors, proceedError } from '../../../utils'

const initialState = {
  priceBlock: {
    template: [],
    catalog: [],
    history: {},
  },
  isFetched: true,
}

const types = createTypes(['setFetching', 'loadPriceBlock', 'changePriceBlock'], 'pricing')

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.loadPriceBlock]: (state, { catalog = [], template = [], history = {} }) =>
    evolve({
      priceBlock: {
        catalog: always(catalog),
        template: always(template),
        history: always(history),
      },
    })(state),
  [types.changePriceBlock]: (state, { priceBlockTemplate }) =>
    evolve({
      priceBlock: { template: always(priceBlockTemplate) },
    })(state),
})

export function loadPriceBlock(templateId) {
  return async (dispatch, getState, api) => {
    if (templateId === 'new') return

    let templatePrices = {}
    let manifestPrices = {}
    try {
      templatePrices = await dispatch(priceActions.fetchItem({ id: templateId }))
      manifestPrices = await dispatch(manifestActions.fetchItem({ id: templateId }))
    } catch (err) {
      showErrors(proceedError(err))
    }

    let template = Array.prototype.concat(
      values(templatePrices)
        .filter(node => typeof node !== 'string')
        .map(({ readonly, ...rest }) => ({ price: {}, isEditable: !readonly, ...rest })),
      values(manifestPrices).map(node => ({ ...node, isEditable: false })),
    )

    template = template.map(node => {
      const newObj = {}
      keys(node).map(key => {
        newObj[key.charAt(0).toLowerCase() + key.slice(1)] = node[key]
        return key
      })

      return newObj
    })

    let catalog = []

    if (templateId) {
      catalog = await api.client.get(
        `/uisvc/product-prices/ubxb/api/v1/products/${templateId}/prices`,
      )
    }
    dispatch({
      type: types.loadPriceBlock,
      catalog,
      template,
    })
  }
}

export function changePriceBlockTemplate(priceBlockTemplate) {
  return {
    type: types.changePriceBlock,
    priceBlockTemplate,
  }
}
