import React, { useRef, useEffect, useMemo } from 'react'
import * as PIXI from 'pixi.js'
import { connect } from 'react-redux'
import { selectBlock, changeBlockTransform } from '../../actions'
import { Scene } from './Scene'

const mapStateToProps = state => {
  return {
    documentTemplate: state.labelManagement.documentTemplate.currentState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onBlockSelectedHandler: id => dispatch(selectBlock(id)),
    onBlockTransformChangedHandler: (id, oldTransform, newTransform) =>
      dispatch(changeBlockTransform(id, oldTransform, newTransform)),
  }
}
// let app: PIXI.Application = null
// let scene: Scene = null

export const DocumentTemplate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  ({
    width,
    height,
    background: backgroundColor,
    onBlockSelectedHandler,
    onBlockTransformChangedHandler,
    documentTemplate,
  }) => {
    const containerRef = useRef(null)

    const [app, scene] = useMemo(() => {
      const a = new PIXI.Application({ width, height, backgroundColor })
      const sc = new Scene(width, height, a.stage, {
        onBlockTransformChangedHandler,
        onBlockSelectedHandler,
      })
      return [a, sc]
    }, [width, height, backgroundColor])

    useEffect(() => {
      if (app !== null && scene !== null) {
        if (containerRef !== null && containerRef.current !== null) {
          containerRef.current.appendChild(app.view)
        }

        scene.clearStage()
        scene.setZoom(documentTemplate.zoom)
        scene.addPage(documentTemplate.pageSetting)
        scene.addBlocks(documentTemplate.blocks, documentTemplate.selectedBlockId)
      }
    }, [app, scene, documentTemplate])

    return <div ref={containerRef} />
  },
)

/*
DocumentTemplate.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  background: PropTypes.number,
  documentTemplate: PropTypes.shape({
    pageSetting: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    blocks: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.shape([]),
      }),
    ),
    selectedBlockId: PropTypes.number,
    zoom: PropTypes.number,
  }).isRequired,
  // onBlockSelectedHandler: PropTypes.func.isRequired,
  // onBlockTransformChangedHandler: PropTypes.func.isRequired,
}
*/
