// @flow

export const schema = {
  CategoryId: {
    value: {
      getPath: [],
      setPath: ['id'],
    },
  },
}
