// @flow

import * as React from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Flex, Box, Input, Icon, Button } from '@r1/ui-kit'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { CategoryTree } from '../../components/Category/CategoryTree'

import { ROUTES } from '../../../../navigation'
import { httpClient } from '../../../../httpClient'

const getCategoryUrl = categoryId => generatePath(ROUTES.CATEGORY_DETAILS, { categoryId })

export const ViewCategoriesGrid = () => {
  const navigate = useNavigate()
  const [searchString, setSearchString] = React.useState('')
  const [{ allowCategoryCreate }] = useAccessControl()
  const redirectToCategoryCreatePage = React.useCallback(
    () => navigate(ROUTES.CATEGORY_CREATE),
    [navigate],
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowCategoryView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Title>Categories</ContentHeader.Title>
        <Content>
          <Box alignSelf="flex-end" mb="M">
            <Flex spaceBetween="M" align="flex-end">
              <Box>
                <Box maxWidth={320} minWidth={320}>
                  <Input
                    value={searchString}
                    after={<Icon type="search" />}
                    placeholder="Search"
                    onChange={setSearchString}
                  />
                </Box>
              </Box>
              <Box>
                {allowCategoryCreate && (
                  <Button onClick={redirectToCategoryCreatePage}>
                    <Icon type="plus" color="inverted" />
                    Add new
                  </Button>
                )}
              </Box>
            </Flex>
          </Box>
          <Box>
            <CategoryTree
              httpClient={httpClient}
              categoryUrl={getCategoryUrl}
              searchString={searchString}
            />
          </Box>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
