export const subtractPoints = (a, b) => {
  return {
    x: a.x - b.x,
    y: a.y - b.y,
  }
}

export const magnitude = (a, b = { x: 0, y: 0 }) => {
  const sub = subtractPoints(a, b)
  return Math.sqrt(sub.x * sub.x + sub.y * sub.y)
}
