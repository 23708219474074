// @flow

import * as React from 'react'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import { Button, Flex, FormField, Icon, Modal, Select, Textarea, Tooltip } from '@r1/ui-kit'
import { useCallback, useState } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import type { ExpectedDecision, ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'
import type { ClaimInfo, OrderInfo } from '../../types/common'
import type { NewClaimItem } from '../../types/ClaimScreen'
import { getClaimType, RETURN_REASON_NOT_FUNCTIONING } from '../../utils'
import { ClaimLine } from './ClaimLine'

type Props = {
  claim: ClaimInfo,
  order: OrderInfo,
  chatMessageId: Lib$Id,
  availableDecisionTypes: ExpectedDecision[],
  availableReturnReasons: ReturnReason[],
  createClaim: (
    chatMessageId: Lib$Id,
    decisionTypeId: Lib$Id,
    description: string,
    claimItems: NewClaimItem[],
  ) => Promise<string | null>,
}

export const ClaimCreationModal = (props: Props) => {
  const {
    claim,
    order,
    chatMessageId,
    availableDecisionTypes,
    availableReturnReasons,
    createClaim,
  } = props

  const decisionTypesWithoutReplacement = availableDecisionTypes.filter(
    type => !type.title.toUpperCase().includes('replacement'.toUpperCase()),
  )
  const [currentDecisionTypeId, setCurrentDecisionTypeId] = useState(
    decisionTypesWithoutReplacement[0].id,
  )
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const initialClaimItems = claim.claimItems.map((item): NewClaimItem => {
    const orderLine = order.orderLines.find(line => line.lineId === item.orderLineId)
    if (orderLine) {
      return {
        claimItemId: item.id,
        orderLineId: item.orderLineId,
        quantity: parseInt(item.quantity || 1, 10),
        refund: {
          currency: orderLine.lineInfo.singleItemPrice.total.currency,
          amount: orderLine.lineInfo.singleItemPrice.total.amount,
        },
        returnReasonId: item.returnReasonId ?? RETURN_REASON_NOT_FUNCTIONING,
      }
    }
    return {
      claimItemId: item.id,
      orderLineId: item.orderLineId,
      quantity: parseInt(item.quantity || 1, 10),
      refund: {
        currency: 'USD',
        amount: '0.0',
      },
      returnReasonId: availableReturnReasons[0].id,
    }
  })

  const [claimItems, setClaimItems] = useState(initialClaimItems)

  const updateClaimItems = useCallback(
    claimItemData => {
      const newArray = claimItems.filter(item => item.claimItemId !== claimItemData.claimItemId)
      newArray.push(claimItemData)
      setClaimItems(newArray)
    },
    [claimItems],
  )

  const createClaimAndClose = useCallback(
    (onClose: () => void) => async () => {
      setLoading(true)
      try {
        const claimId = await createClaim(
          chatMessageId,
          currentDecisionTypeId,
          description,
          claimItems,
        )
        if (claimId) {
          onClose()
        }
      } finally {
        setLoading(false)
      }
    },
    [createClaim, chatMessageId, currentDecisionTypeId, description, claimItems],
  )

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          disabled: loading,
          align: 'right',
        },
        {
          title: 'Proceed',
          onClick: createClaimAndClose(onClose),
          loading,
          disabled: loading,
          align: 'right',
        },
      ]
    },
    [loading, createClaimAndClose],
  )

  return (
    <Modal
      size="L"
      isControlled={false}
      actionButtons={modalActionButtons}
      title="New Claim"
      description="Please enter parameters for a new claim:"
      buttonElement={({ onOpen }) => (
        <Tooltip text="Create Claim">
          <Button transparent shape="square" size="S" color="secondary" onClick={onOpen}>
            <Icon type="plus" />
          </Button>
        </Tooltip>
      )}
    >
      <Flex column spaceBetween="XXL">
        <Flex spaceBetween="S">
          <FormField>
            <FormField.Label>Decision:</FormField.Label>
            <Select
              data-test-id="decision"
              options={decisionTypesWithoutReplacement}
              value={currentDecisionTypeId}
              valueKey="id"
              labelKey="title"
              onChange={value => {
                setCurrentDecisionTypeId(value)
              }}
            />
          </FormField>
          <FormField>
            <FormField.Label>Description:</FormField.Label>
            <Textarea
              rows={3}
              value={description || ''}
              onChange={value => {
                setDescription(value)
              }}
            />
          </FormField>
        </Flex>
        {claim.claimItems.map(claimItem => {
          const orderLine = order.orderLines.find(line => line.lineId === claimItem.orderLineId)
          const decisionType = getClaimType(currentDecisionTypeId)
          if (orderLine && decisionType) {
            return (
              <ClaimLine
                key={claimItem.id}
                claimItem={claimItem}
                orderLine={orderLine}
                decisionType={decisionType}
                availableReturnReasons={availableReturnReasons}
                setChangeClaimItemData={updateClaimItems}
              />
            )
          }
          return <div key={claimItem.id} />
        })}
      </Flex>
    </Modal>
  )
}
