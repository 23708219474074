import * as React from 'react'
import { Flex, Box } from '@r1/ui-kit'
import styled from '@emotion/styled'

const Container = styled(Flex)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.grey[800]};
`

const Label = styled('span')`
  font-weight: 300;
`

const TopContent = ({ manufacturer, model }) => {
  return (
    <Container spaceBetween="XXXL">
      <Box>
        <Label>Manufacturer:&nbsp;</Label> {manufacturer}
      </Box>
      <Box>
        <Label>Model:&nbsp;</Label> {model}
      </Box>
    </Container>
  )
}

export default TopContent
