// @flow

import * as React from 'react'
import { Flex, FormField, HR } from '@r1/ui-kit'
import { Text } from '../Text'
import { DateRange } from '../../../../utils/utils'
import type { DateConstraint as DateConstraintValue } from '../types'

type Props = {| value: DateConstraintValue |}

export const DateConstraint = ({ value }: Props) =>
  (value.minAllowedDate || value.maxAllowedDate) && (
    <Flex column spaceBetween="S">
      <HR />
      <Flex spaceBetween="XXS">
        <FormField.Label>Allowed Dates</FormField.Label>
        <Text>
          <DateRange from={value.minAllowedDate} to={value.maxAllowedDate} />
        </Text>
      </Flex>
    </Flex>
  )
