import * as React from 'react'
import { FormField, Select, Textarea } from '@r1/ui-kit'
import { Value } from '../../Value'
import { infoFieldLabels } from './helpers/infoFieldsLabels'
import { getInfoFieldsOptions } from './helpers/infoFieldsOptions'

export const InfoField = ({
  editable,
  fieldId,
  value,
  onUpdateField,
}: {
  editable?: boolean
  fieldId: string
  value: string
  onUpdateField: (value: string) => void
}) => {
  const fieldLabel = infoFieldLabels.get(fieldId)
  const dataTestId = `master-program__${fieldId}`

  if (!editable) {
    return (
      <FormField>
        <FormField.Label>{fieldLabel}</FormField.Label>
        <Value>{value}</Value>
      </FormField>
    )
  }

  if (['physicalLocation', 'description'].includes(fieldId)) {
    return (
      <FormField>
        <FormField.Label>{fieldLabel}</FormField.Label>
        <Textarea value={value} data-test-id={dataTestId} onChange={onUpdateField} />
      </FormField>
    )
  }

  return (
    <FormField>
      <FormField.Label>{fieldLabel}</FormField.Label>
      <Select
        simpleValue
        valueKey="id"
        labelKey="value"
        value={value}
        options={getInfoFieldsOptions(fieldId)}
        data-test-id={dataTestId}
        onChange={onUpdateField}
      />
    </FormField>
  )
}
