import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { PageAccessControl, PERMISSIONS, useAccessControl, useUserSettings } from '@r1/core-blocks'
import { useAccountInfo } from '@r1/wireframe-primary'
import { getNotificationsAutologinUrl } from '../../utils'

const HEADER_HEIGHT = 56

const NotificationsContainer = styled('div')`
  position: relative;
  overflow: hidden;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: 100%;
`

const NotificationsView = styled('iframe')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`

export const GoWholesaleNotificationsScreen = () => {
  const {
    currentUser: { userInfo },
  } = useUserSettings()
  const account = useAccountInfo()
  const [allowedPermissions] = useAccessControl()

  const iframeUrl = useMemo(() => {
    const userName = account?.login || null
    const userEmail = userInfo?.contactInfo?.email || null
    const notificationsUrl = window._env_?.GWS_NOTIFICATIONS_URL
    const notificationsApiKey = window._env_?.GWS_NOTIFICATIONS_API_KEY

    if (
      typeof notificationsUrl === 'string' &&
      typeof notificationsApiKey === 'string' &&
      userName &&
      notificationsUrl
    ) {
      return getNotificationsAutologinUrl({
        baseUrl: notificationsUrl,
        apiKey: notificationsApiKey,
        userName,
        userEmail,
        allowedPermissions,
      })
    }

    return null
  }, [account, userInfo, allowedPermissions])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowGwsNotificationsView]}>
      {iframeUrl ? (
        <NotificationsContainer>
          <NotificationsView src={iframeUrl}>
            Your browser does not support iframes
          </NotificationsView>
        </NotificationsContainer>
      ) : null}
    </PageAccessControl>
  )
}
