// @flow

import type { ShapeAttributeValue } from '../types'

export function isRangeValue(rawDefaultValue: ShapeAttributeValue): %checks {
  return (
    rawDefaultValue.valueType === 'DateRange' ||
    rawDefaultValue.valueType === 'IntRange' ||
    rawDefaultValue.valueType === 'DecimalRange'
  )
}
