// @flow

import { NotificationSystem } from '@r1/ui-kit'

import type { WithController } from '../types'

export class DecisionItemsController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  restartDecisionItem = async (claimId: string, decisionItemId: string) => {
    const resp = await this.component.api.decisionItems.restartDecisionItem({
      claimId,
      decisionItemId,
    })
    if (resp.status === 200) {
      NotificationSystem.addNotification({
        title: 'Step was successfuly restarted.',
        level: 'success',
      })
      setTimeout(() => window.location.reload(true), 1000)
    } else {
      NotificationSystem.addNotification({
        title: 'Cannot restart step. Please, try again later.',
        level: 'error',
      })
    }
  }

  completeDecisionItem = async (claimId: string, decisionItemId: string) => {
    const resp = await this.component.api.decisionItems.completeDecisionItem({
      claimId,
      decisionItemId,
    })
    if (resp.status === 200) {
      NotificationSystem.addNotification({
        title: 'Step was successfuly completed.',
        level: 'success',
      })
      setTimeout(() => window.location.reload(true), 1000)
    } else {
      NotificationSystem.addNotification({
        title: 'Cannot complete step. Please, try again later.',
        level: 'error',
      })
    }
  }
}

export const createDecisionItemsController = (component: WithController) =>
  new DecisionItemsController(component)
