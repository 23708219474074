// @flow

import { Flex } from '@r1/ui-kit'
import React from 'react'
import { InformationalText, SystemMessageContainer } from '../StyledComponents'

type Props = {
  text: string,
}

const SystemMessage = (props: Props) => {
  const { text } = props

  return (
    <SystemMessageContainer>
      <Flex column spaceBetween="S">
        <Flex justify="center" align="center" spaceBetween="XS">
          {/* It is safe to show system messages */}
          <InformationalText dangerouslySetInnerHTML={{ __html: text }} />
        </Flex>
      </Flex>
    </SystemMessageContainer>
  )
}

export default SystemMessage
