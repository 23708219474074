import React, { useEffect, useState, useMemo } from 'react'
import { Flex } from '@r1/ui-kit'
import { ProductsStatistic } from '@r1-webui/masterprograms-v1'
import { handleServerError } from '@r1/core-blocks'
import { HorizonatalBarChart, PieChart } from '../../../../../components/charts'
import { formatNumber } from '../../../../../components/charts/helper'

import { masterProgramRulesApi } from '../../../api/MasterProgram'
import { Data, PieData } from '../../../../../components/charts/types'

const defaultStatistc: ProductsStatistic = {
  all: 0,
  assigned: 0,
  conflict: 0,
  notCalculated: 0,
  notFound: 0,
}

const getHorizontalBarData = (productStatistic: ProductsStatistic): Data[] => {
  return [
    { name: 'Assigned', value: productStatistic.assigned },
    { name: 'Conflict', value: productStatistic.conflict },
    { name: 'Not Assigned', value: productStatistic.notFound },
  ]
}

const getPieBarData = (productStatistic: ProductsStatistic): PieData[] => {
  return [
    {
      name: 'Assigned',
      value: (productStatistic.assigned / productStatistic.all) * 100,
      color: '#39568C',
    },
    {
      name: 'Conflict',
      value: (productStatistic.conflict / productStatistic.all) * 100,
      color: '#20A387',
    },
    {
      name: 'Not Assigned',
      value: (productStatistic.notFound / productStatistic.all) * 100,
      color: '#95D840',
    },
  ]
}

export const MasterProgramStatisticChart = () => {
  const [statistic, setStatistic] = useState<ProductsStatistic>(defaultStatistc)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const getStatistic = async () => {
      setLoading(true)
      const result = await masterProgramRulesApi.getProductsStatistic()
      if (result.status === 200) {
        setStatistic(result.body)
      } else {
        handleServerError(result)
      }
      setLoading(false)
    }
    getStatistic()
  }, [])

  const horizontalBarData = useMemo(() => getHorizontalBarData(statistic), [statistic])
  const pieBarData = useMemo(() => getPieBarData(statistic), [statistic])

  return (
    <Flex justify="space-between" mb="L">
      <HorizonatalBarChart
        title={`Products (${formatNumber(statistic.all)})`}
        data={horizontalBarData}
        loading={loading}
      />
      <PieChart data={pieBarData} loading={loading} />
    </Flex>
  )
}
