// @flow

import React, { useState } from 'react'
import { Table } from '@r1/grid'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'
import { Button, Textarea, Text, Box, Flex } from '@r1/ui-kit'
import { tableDisplaySettingsGetter } from './tableDisplaySettings'
import * as api from './api'

const mapPrices = (source: {
  rule?: { name: string },
  isFirst: boolean,
  productId: string,
  actualPrice?: {
    value: { currency: string, amount: string },
    updatedOn: string,
  },
  assumedPrice?: {
    value: { amount: string, currency: string },
    updatedOn: string,
  },
}) => ({
  name: source.rule ? source.rule.name : '',
  productId: source.productId,
  actualAmount: source.actualPrice ? source.actualPrice.value.amount : '',
  actualCurrency: source.actualPrice ? source.actualPrice.value.currency : '',
  actualUpdatedOn: source.actualPrice ? source.actualPrice.updatedOn : '',
  assumedAmount: source.assumedPrice ? source.assumedPrice.value.amount : '',
  assumedCurrency: source.assumedPrice ? source.assumedPrice.value.currency : '',
  assumedUpdatedOn: source.assumedPrice ? source.assumedPrice.updatedOn : '',
  isFirst: source.isFirst,
})

const mapData = data =>
  data
    .filter(x => x.type === 'ConsolidatedProductPricesResultSuccess')
    .reduce((prev, curr) => {
      const currCopy = { ...curr }
      if (currCopy.consolidatedProductPrices.length > 0)
        currCopy.consolidatedProductPrices[0].isFirst = true

      return prev.concat(currCopy.consolidatedProductPrices)
    }, [])
    .sort((x, y) => x.productId - y.productId)
    .map(mapPrices)

export const ProductTemplatePricesScreen = () => {
  const [templateIdsString, setTemplateIdsString] = useState('')
  const [prices, setPrices] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const findPrices = async () => {
    setIsLoading(true)

    setPrices(mapData((await api.getConsolidatedProductsPrices(templateIdsString)) || []))

    setIsLoading(false)
  }

  const syncPricesByProduct = async (templateIds: string) => {
    await api.syncConsolidatedProductsPrices(templateIds)
    await findPrices()
  }

  const syncNewPrices = async () => {
    await api.syncConsolidatedProductsPrices(templateIdsString)
    await findPrices()
  }

  const displaySettings = tableDisplaySettingsGetter.pricesScreen(syncPricesByProduct)

  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Template Sync</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <Flex align="center">
          <Text>Template ids:</Text>
          <Box ml="M">
            <Textarea rows={1} value={templateIdsString} onChange={setTemplateIdsString} />
          </Box>

          <Box ml="M">
            <Button
              onClick={() => {
                findPrices()
              }}
            >
              Find
            </Button>
          </Box>

          <Box ml="M">
            <Button
              loading={isLoading}
              onClick={() => {
                syncNewPrices()
              }}
            >
              Sync prices
            </Button>
          </Box>
        </Flex>

        <Box mt="XL">
          <Table
            suppressVirtualization
            displaySettings={displaySettings}
            loading={isLoading}
            data={prices}
          />
        </Box>
      </Content>
    </Main>
  )
}
