import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { UserCreationFormController } from '../../components'

import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'
import { CreateUserForm } from './CreateUserForm'

export const CreateUser = () => {
  const params = useParams<{ userId?: string }>()
  const navigate = useNavigate()

  const getFormProps = () => ({
    copyDefaultSettingsFromUserId: params.userId || '',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    httpClient,
    onError: () => undefined,
    onFormSubmitted: () => {
      navigate(ROUTES.USERS)
    },
    onFormCanceled: () => {
      navigate(ROUTES.USERS)
    },
  })

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowUserCreate]}>
      <UserCreationFormController {...getFormProps()}>
        {formProps => <CreateUserForm {...formProps} />}
      </UserCreationFormController>
    </PageAccessControl>
  )
}
