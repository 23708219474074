// @flow

import styled from '@emotion/styled'

import { Flex } from '@r1/ui-kit'

import { OrderInfoWrapper } from '../StyledComponents'

export const SelectableOrderInfo = styled(OrderInfoWrapper)`
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.space.M};

  &:hover {
    background-color: ${({ theme }) => theme.palette.blue[100]};
  }
`

export const IconWrapper = styled('div')`
  position: relative;
  top: -1rem;
  left: -1rem;
  width: 3rem;
  height: 3rem;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  border-radius: 3rem;
  box-shadow: rgb(19 41 63 / 20%) 0px 1px 2px 0px;
`
export const EmptyData = styled('div')`
  width: 100%;
  text-align: center;
  padding: ${({ theme }) => theme.space.S};
`
export const OrderList = styled(Flex)`
  flex-direction: column;
`
