// @flow

import React, { useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Flex, Link } from '@r1/ui-kit'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { ROUTES } from '../../../navigation'

import {
  MainToolBar,
  BlocksToolBar,
  DocumentTemplatePanel,
  TransformPanel,
  ListBlockPropertiesPanel,
  TextBlockPanel,
  BarcodeBlockPanel,
  RectangleBlockPanel,
  ValueEditorPanel,
  DocumentTemplate,
} from '../components'
import { loadDocumentTemplate, loadPageSettings, loadTemplateModels, clearData } from '../actions'

const useMapStateToProps = state => {
  const templateModelId = state.labelManagement.documentTemplate.currentState.templateModel.id
  const popUpVisible = state.labelManagement.createDocumentTemplateVisible

  const { name } = state.labelManagement.documentTemplate.currentState

  return {
    showCreateDocumentTemplatePopup: popUpVisible || !templateModelId,
    name,
  }
}

export const EditLabel = connect(useMapStateToProps)(({ dispatch, name = '' }) => {
  const [{ allowLabelEdit }] = useAccessControl()
  const { templateId } = useParams()

  const fetchData = useCallback(async () => {
    dispatch(clearData())
    dispatch(loadTemplateModels())
    dispatch(loadPageSettings())

    if (templateId) {
      dispatch(loadDocumentTemplate(templateId))
    }
  }, [dispatch, templateId])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowLabelView]}>
      <Main fullScreen>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.LABEL_MANAGEMENT}>Label Appearance Design</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <ContentHeader.Title>Edit label {name}</ContentHeader.Title>
          <Flex column spaceBetween="S">
            <MainToolBar allowEdit={allowLabelEdit} />
            <Flex spaceBetween="S">
              <BlocksToolBar column />
              <DocumentTemplate width={800} height={800} background={0xeeeeee} />
              <Flex column spaceBetween="S">
                <DocumentTemplatePanel />
                <TransformPanel />
                <ListBlockPropertiesPanel />
                <TextBlockPanel />
                <BarcodeBlockPanel />
                <RectangleBlockPanel />
                <ValueEditorPanel />
              </Flex>
            </Flex>
          </Flex>
        </Content>
      </Main>
    </PageAccessControl>
  )
})
