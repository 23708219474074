import {
  FilterSettings,
  RuleBuilderSettings,
} from '@r1/ui-kit/contracts/ts/RuleBuilder/RuleBuilder'
import { getMasterProgramEnums } from '../../utils/getMasterProgramEnums'
import { getMasterProgramTree } from '../../utils/getMasterProgramTree'

const settings: FilterSettings[] = [
  {
    operators: ['HasAnyOf'],
    filterId: 'programIds',
    filterName: 'Programs',
    valueType: 'EnumArray',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'All',
    filterName: 'All search options',
    valueType: 'Boolean',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'isOnSortingPallet',
    filterName: 'Is On Sorting Pallet',
    valueType: 'Boolean',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'InOrder',
    filterName: 'In order',
    valueType: 'Boolean',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'OrderClosed',
    filterName: 'Order closed',
    valueType: 'Boolean',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'excludeErcMoved',
    filterName: 'Is excluded erc Moved',
    valueType: 'Boolean',
  },
  {
    operators: ['IsYes', 'IsNo'],
    filterId: 'checkedInByPallet',
    filterName: 'Сhecked In By Pallet',
    valueType: 'Boolean',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'palletSortingCategories',
    filterName: 'Pallet Sorting Categories',
    valueType: 'EnumArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'excludedPalletSortingCategories',
    filterName: 'Excluded Pallet Sorting Categories',
    valueType: 'EnumArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'rtvTypes',
    filterName: 'RTV Types',
    valueType: 'EnumArray',
  },
  {
    operators: ['IsIn'],
    filterId: 'categoryList',
    filterName: 'Categories',
    valueType: 'Tree',
  },
  {
    operators: ['IsIn'],
    filterId: 'excludedCategoryList',
    filterName: 'Excluded Categories',
    valueType: 'Tree',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'upc',
    filterName: 'UPC',
    valueType: 'StringArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'excludedUpc',
    filterName: 'Excluded UPC',
    valueType: 'StringArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'manufacturer',
    filterName: 'Manufacturer',
    valueType: 'StringArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'excludedManufacturer',
    filterName: 'Excluded Manufacturer',
    valueType: 'StringArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'excludedKeyWords',
    filterName: 'Excluded key words',
    valueType: 'StringArray',
  },
  {
    operators: ['HasAnyOf'],
    filterId: 'includedKeyWords',
    filterName: 'Included key words',
    valueType: 'StringArray',
  },
]

export const masterProgramSettings: RuleBuilderSettings = {
  ruleConditions: ['All'],
  filters: settings,
  onLoadEnum: getMasterProgramEnums,
  onLoadTree: getMasterProgramTree,
}
