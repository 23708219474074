// @flow

export const getQueryStringValue = (key: string) => {
  return decodeURIComponent(
    window.location.search.replace(
      new RegExp(
        // eslint-disable-next-line no-useless-escape
        `^(?:.*[&\\?]${encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&')}(?:\\=([^&]*))?)?.*$`,
        'i',
      ),
      '$1',
    ),
  )
}

export const redirect = (url: string) => {
  window.location.href = url
}
