import React from 'react'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Link, Text, Tooltip } from '@r1/ui-kit'

type OfferAskPriceLinkProps = {
  offer: Offer
}

export const OfferAskPriceLink = React.memo<OfferAskPriceLinkProps>(({ offer }) =>
  offer.askPrice ? (
    <Tooltip text={<Text weight="medium">{offer.askedBy}</Text>}>
      <Link target="_blank">${offer.askPrice?.amount}</Link>
    </Tooltip>
  ) : (
    <div>-</div>
  ),
)
