import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Flex } from '@r1/ui-kit'
import { MasterProgramModel } from '../../../types'
import { InfoField } from './InfoField'

export const InfoFields = ({ editable }: { editable?: boolean }) => {
  const { control } = useFormContext<MasterProgramModel>()

  const { fields, update } = useFieldArray({
    name: 'infoFields',
    control,
  })

  const handleUpdateField = React.useCallback(
    (index: number, fieldId: string) => (value: string) => {
      update(index, { key: fieldId, value })
    },
    [update],
  )

  return (
    <Flex column spaceBetween="M">
      {fields.map(({ key: fieldId, value }, index) => (
        <InfoField
          key={fieldId}
          fieldId={fieldId}
          editable={editable}
          value={value}
          onUpdateField={handleUpdateField(index, fieldId)}
        />
      ))}
    </Flex>
  )
}
