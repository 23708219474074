import React, { useCallback } from 'react'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Link, Modal } from '@r1/ui-kit'

import type { UserCreationFormProps } from '../../types/UserCreationForm'

import { ROUTES } from '../../../../navigation'
import { AddUserManually } from './Tabs'

export const CreateUserForm = (props: UserCreationFormProps) => {
  const modalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'NO',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'YES',
          onClick: () => {
            props.actions.cancelForm.dispatch()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [props.actions.cancelForm],
  )
  return (
    <Main>
      <ContentHeader>
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.USERS}>Users</Link>
        </ContentHeader.Breadcrumbs>
        <ContentHeader.Title>Add new user</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <Modal
          isControlled={false}
          title="You have unsaved data"
          buttonElement={({ onOpen }) => <AddUserManually {...props} cancelCreation={onOpen} />}
          actionButtons={modalActionButtons}
        >
          Are you sure you want to cancel user creation?
        </Modal>
      </Content>
    </Main>
  )
}
