import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { pathOr, propOr } from 'ramda'
import { Field } from 'redux-form'
import { Link, Flex, Text } from '@r1/ui-kit'
import { selectors } from '../../../modules/products'
import { renderInput, renderDatePicker } from '../fieldRenderer'
import { Label } from '../components'

const mapStateToProps = (state, { productId }) => ({
  productCard: selectors.getItem(state, { id: productId }),
})

const enhance = compose(connect(mapStateToProps))

const ReceivingVendorInfoComponent = ({ productCard }) => {
  const receivingId = pathOr('', ['receivingInfo', 'receivingId'], productCard)
  const vendorBol = pathOr('None', ['receivingInfo', 'vendorBol'], productCard)
  const transportationBol = pathOr('None', ['receivingInfo', 'transportationBol'], productCard)
  const palletId = pathOr('', ['receivingInfo', 'palletId'], productCard)
  const companyProductId = propOr(null, 'companyProductId', productCard)
  const receivingPalletsQueryString = companyProductId ? `?trg=${companyProductId}` : ''

  return (
    <Flex column maxWidth={1011} spaceBetween="S">
      <Flex align="center">
        <Label>RCV</Label>
        {receivingId ? (
          <Link href={`/Modules/Transportation/Receiving.aspx?srchRCVBLs=${receivingId}`}>
            {receivingId}
          </Link>
        ) : (
          <Text>None</Text>
        )}
      </Flex>
      <Flex align="center">
        <Label>Vendor BOL</Label>
        <Text>{vendorBol}</Text>
      </Flex>
      <Flex align="center">
        <Label>Transp. BOL</Label>
        <Text>{transportationBol}</Text>
      </Flex>
      <Flex align="center">
        <Label>Receiving pallet</Label>
        {palletId ? (
          <Link
            href={`/Modules/Transportation/ReceivingPallets.aspx${receivingPalletsQueryString}`}
          >
            {palletId}
          </Link>
        ) : (
          <Text>None</Text>
        )}
      </Flex>
      <Field label="Store number" name="vendorInfo.storeNumber" component={renderInput} />
      <Field label="RC claim number" name="vendorInfo.rcClaimNumber" component={renderInput} />
      <Field label="Claim date" name="vendorInfo.claimDate" component={renderDatePicker} />
    </Flex>
  )
}

export const ReceivingVendorInfo = enhance(ReceivingVendorInfoComponent)
