import { equals } from 'ramda'
import { compose, mapProps, withHandlers, withStateHandlers } from 'recompose'
import { transformAndCheckId } from '../../../../../../utils/dataUtils'

const SELECT_ALL_KEY = -1

export const enhance = compose(
  mapProps(({ upc, meta, ...rest }) => {
    const isAllIncludeWarehouses = upc.warehouses.includes(SELECT_ALL_KEY)
    const isAllExcludeWarehouses = upc.excludedWarehouses.includes(SELECT_ALL_KEY)

    const allWarehouseKeys = meta.warehouses.map(el => el.id)
    if (isAllIncludeWarehouses) {
      return {
        includedWarehouses: allWarehouseKeys.concat([SELECT_ALL_KEY]), // hack to set SelectAll as checked
        excludedWarehouses: upc.excludedWarehouses,
        upc,
        meta,
        ...rest,
      }
    }
    if (isAllExcludeWarehouses) {
      return {
        includedWarehouses: upc.warehouses,
        excludedWarehouses: allWarehouseKeys.concat([SELECT_ALL_KEY]),
        upc,
        meta,
        ...rest,
      }
    }

    return {
      includedWarehouses: upc.warehouses,
      excludedWarehouses: upc.excludedWarehouses,
      upc,
      meta,
      ...rest,
    }
  }),
  withStateHandlers(
    ({ upc, meta, includedWarehouses, excludedWarehouses }) => ({
      consigneeUpc: upc.consigneeUpc,
      selectedMarketplaces: upc.marketplaces,
      marketplacesMeta: meta.marketplaces,
      selectedWarehouses: includedWarehouses,
      selectedExWarehouses: excludedWarehouses,
      allWarehouses: meta.warehouses,
      availableIncludeWarehouses: meta.warehouses,
      availableExcludeWarehouses: meta.warehouses,
      classifications: upc.classifications,
      classificationsMeta: meta.classifications,
      blockAddition: upc.classifications.length === meta.classifications.length,
      show: false,
    }),
    {
      onChangeClassifications: () => classifications => ({ classifications }),
      onChangeConsigneeUPC: () => value => ({ consigneeUpc: value }),
      onChangeMarketplaces: () => value => ({ selectedMarketplaces: value }),
      onChangeIncludeWarehouses: () => selectedWarehouses => {
        return {
          selectedWarehouses,
        }
      },
      onChangeExcludeWarehouses: () => selectedWarehouses => {
        return {
          selectedExWarehouses: selectedWarehouses,
        }
      },
      onToggleShow: () => value => ({ show: value }),
    },
  ),
  withHandlers({
    onSaveConsigneeUpc:
      ({
        onSaveConsigneeUpc,
        consigneeUpc,
        selectedMarketplaces,
        selectedWarehouses,
        selectedExWarehouses,
        classifications,
        onError,
        allWarehouses,
      }) =>
      () => {
        if (!consigneeUpc) {
          onError({ title: 'Error', message: 'Please fill "Consignee UPC" field' })
          return
        }
        transformAndCheckId(consigneeUpc, onError)
        const pureClassifications = classifications.map(({ classificationId, values }) => ({
          classificationId,
          values,
        }))
        const allWarehouseKeys = allWarehouses.map(el => el.id)

        const includeWarehouses = selectedWarehouses.filter(item => item !== SELECT_ALL_KEY)
        const excludeWarehouses = selectedExWarehouses.filter(item => item !== SELECT_ALL_KEY)
        const isAllIncludeWarehouses = equals(includeWarehouses, allWarehouseKeys)
        const isAllExcludeWarehouses = equals(excludeWarehouses, allWarehouseKeys)

        const marketplaces = selectedMarketplaces
        if (isAllIncludeWarehouses && isAllExcludeWarehouses) {
          onSaveConsigneeUpc({
            consigneeUpc,
            marketplaces,
            warehouses: [],
            excludedWarehouses: [],
            classifications: pureClassifications,
          })
          return
        }
        if (isAllIncludeWarehouses) {
          onSaveConsigneeUpc({
            consigneeUpc,
            marketplaces,
            warehouses: [SELECT_ALL_KEY],
            excludedWarehouses: selectedExWarehouses,
            classifications: pureClassifications,
          })
          return
        }
        if (isAllExcludeWarehouses) {
          onSaveConsigneeUpc({
            consigneeUpc,
            marketplaces,
            warehouses: selectedWarehouses,
            excludedWarehouses: [SELECT_ALL_KEY],
            classifications: pureClassifications,
          })
          return
        }
        onSaveConsigneeUpc({
          consigneeUpc,
          marketplaces,
          warehouses: selectedWarehouses,
          excludedWarehouses: selectedExWarehouses,
          classifications: pureClassifications,
        })
      },
  }),
)
