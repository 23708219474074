// @flow

import * as React from 'react'
import { Icon, Tooltip } from '@r1/ui-kit'
import { NotificationTooltip } from './NotificationTooltip'

type Props = {
  notificationFlagSet: number,
}

/**
 * @param statusCode {number} - where each bit is responsible for a specific message
 * 0b001 - has new Messages
 * 0b010 - requires recision
 * 0b100 - pending manual refund
 * e.g. code 5 quals 0b101 and says 'has new message, pending manual orders'
 * possible codes: from 0 to 7
 */

const newMessagesMask = 1
const needDesicionSetMask = 2
const needRefundApprovalMask = 4
function mapStatusCodeToMessages(statusCode: number) {
  const notificationTooltips = []
  // eslint-disable-next-line no-bitwise
  if ((statusCode & newMessagesMask) !== 0) {
    notificationTooltips.push('New message')
  }
  // eslint-disable-next-line no-bitwise
  if ((statusCode & needDesicionSetMask) !== 0) {
    notificationTooltips.push('Decision is not set')
  }
  // eslint-disable-next-line no-bitwise
  if ((statusCode & needRefundApprovalMask) !== 0) {
    notificationTooltips.push('Manual refund pending')
  }
  return notificationTooltips
}

export const Notification = ({ notificationFlagSet }: Props) => {
  return notificationFlagSet === 0 ? (
    <div>—</div>
  ) : (
    <Tooltip text={<NotificationTooltip texts={mapStatusCodeToMessages(notificationFlagSet)} />}>
      <Icon type="internal/notification/info" color="#5390FD" size="XS" />
    </Tooltip>
  )
}
