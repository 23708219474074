import React from 'react'
import { Flex, Text, Image, Icon, Box, Link } from '@r1/ui-kit'
import { GOWHOLESALE_URL_DEV } from '../../../../../../gowholesale/const'
import { OfferDrawerProps } from '../OfferDrawer.types'
import { OfferAction } from '../../../types'
import { OfferAskPriceLink } from '../../OfferAskPrice'
import { OfferUnitsLink } from '../../OfferUnitsLink'
import { OfferLastSale } from '../../OfferLastSale'
import { OFFER_DRAWER_IMAGE_SIZE } from '../const'
import { CustomerLink } from '../../CustomerLink'

export const OfferDrawerBody = ({
  offer,
  onActionClick,
}: Pick<OfferDrawerProps, 'offer' | 'onActionClick'>) => {
  return offer ? (
    <Flex column spaceBetween="L">
      <Text>Offer ID: {offer.id}</Text>

      <Flex justify="space-between">
        <Image
          width={OFFER_DRAWER_IMAGE_SIZE}
          height={OFFER_DRAWER_IMAGE_SIZE}
          src={`${window._env_?.GOWHOLESALE_URL || GOWHOLESALE_URL_DEV}/media/${
            offer.product.imageUrl
          }`}
        />

        <Icon
          type="history"
          cursor="pointer"
          onClick={() => onActionClick(offer, OfferAction.History)}
        />
      </Flex>

      <Box>
        <Link
          target="_blank"
          href={`${window._env_?.GOWHOLESALE_URL || GOWHOLESALE_URL_DEV}${offer.product.slug}`}
        >
          <Text>{offer.product.name}</Text>
        </Link>
      </Box>

      <Flex column spaceBetween="M">
        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Remaining Quantity</Text>
          </Box>

          <Text>
            {offer.statistic.targetStock.quantity.value}&nbsp;(
            {offer.statistic.targetStock.facilityName})
          </Text>
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Status</Text>
          </Box>

          <Text>{offer.status}</Text>
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">SKU</Text>
          </Box>

          <Text>{offer.masterSku}</Text>
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Company Name</Text>
          </Box>

          <CustomerLink
            customer={offer.customer}
            onClick={() => onActionClick(offer, OfferAction.ViewCustomer)}
          />
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Offer</Text>
          </Box>

          <Text>${offer.price.amount}</Text>
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Ask</Text>
          </Box>

          <Text>${offer.product.price.amount}</Text>
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Seller&apos;s Counter Offer</Text>
          </Box>

          <OfferAskPriceLink offer={offer} />
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Offer Units</Text>
          </Box>

          <OfferUnitsLink offer={offer} />
        </Flex>

        <Flex align="center">
          <Box minWidth={180}>
            <Text weight="medium">Last Sale</Text>
          </Box>

          <OfferLastSale offer={offer} />
        </Flex>
      </Flex>
    </Flex>
  ) : null
}
