// @flow

import { NotificationSystem } from '@r1/ui-kit'

import { createApi as PurchaseOrdersApi } from './PurchaseOrders'
import { createApi as ProfileConfiguratorApi } from './ProfileConfigurator'
import { createApi as ExportApi } from './Export'

type Notification = {|
  level: 'success' | 'error' | 'warning' | 'info',
  title: string,
  message?: string,
|}

type OnNotify = Notification => void

export type Options = {|
  onError?: (error: any) => void,
  onNotify: OnNotify,
|}

export const handleNotification = ({ level, title, message }: Notification) => {
  NotificationSystem.addNotification({
    level,
    title,
    message,
  })
}
const createApi = (httpClient: Object, { onError, onNotify }: Options) => {
  return {
    ...PurchaseOrdersApi(httpClient, { onError, onNotify }),
    ...ProfileConfiguratorApi(httpClient, { onError, onNotify }),
    ...ExportApi(httpClient, { onError, onNotify }),
  }
}

export default createApi
