import * as React from 'react'
import { assoc, assocPath, evolve, identity, memoizeWith, always, merge } from 'ramda'

import type { Location, ReportLogin } from '@r1-webui/usermanagement-v1'
import { NotificationSystem } from '@r1/ui-kit'
import { withAccountInfo, UserAccount } from '@r1/wireframe-primary'
import { cookie, handleServerError } from '@r1/core-blocks'
import type { Lib$Id } from '@r1/types/typescript'

import { formatDate } from '../../utils/formatDate'
import { usersApi } from '../../api/users'
import { createApi as createUserManagementApi } from '../../api/userManagement'
import { validate } from '../../validation'
import { imageToBase64 } from '../../utils/imageToBase64'
import { base64ImageExt, base64RemoveImageType } from '../../utils/base64/base64'

import { isErrorResponse, isSuccessResponse, extractPayload } from '../../api/helpers'
import type { ServerValidationError, ResponseSuperType } from '../../api/contracts'
import { DateFormats } from '../../types/api/userManagement/definitions'

// @ts-expect-error
import { printJobIds } from '../../../../utils'

import { showNotification } from '../../../../utils/notifications/showNotification'
import type {
  UserEditFormControllerProps,
  ActivationInfo,
  UserDisplayInfo,
  UserEditableInfo,
  UserEditFormActions,
  ImageEditorInfo,
  User,
  UserManagementApi,
  UserInfo,
  Manager,
  TimeZone,
  UserCompany,
} from './types'

type UserState = {
  id: Lib$Id
  fullName: string
  password: string
  confirmPassword: string
  changePasswordOnNextLogin: boolean
  jobTitle?: string
  department?: string
  manager?: Lib$Id
  userCompanyId?: Lib$Id
  locationId?: Lib$Id
  profileImageSrc?: string
  createdOn: string
  lastLoggedIn?: string
  disabled: boolean
  login: string
  email?: string
  phone?: string
  phoneExtension?: string
  dateFormat?: Lib$Id
  timeZoneId?: Lib$Id
  externalId?: string
  userType?: string
  isSalesRepresentative?: boolean
  reportLoginId?: Lib$Id
}

type FieldName = keyof UserState

type Errors = Partial<Record<FieldName, string>>

type State = {
  user: UserState
  managers: Manager[]
  userCompanies: UserCompany[]
  locations: Location[]
  reportLogins: ReportLogin[]
  dateFormats: string[]
  timeZones: TimeZone[]
  editMode: boolean
  isPlaceholderVisible: boolean
  isVisibleImageEditor: boolean
  hasUserInfoChanges: boolean
  hasPasswordChanges: boolean
  hasChangePasswordOnNextLoginChanges: boolean
  hasDisabledStatusChanges: boolean
  hasImageChanges: boolean
  fetching: {
    isLoadingUser: boolean
    isSavingUser: boolean
    isSwitchingUserDisabled: boolean
    isLoadingManagers: boolean
    isLoadingUserCompanies: boolean
    isLoadingLocations: boolean
    isLoadingReportLogins: boolean
    isLoadingDateFormats: boolean
    isLoadingTimeZones: boolean
    isSavingProfileImage: boolean
    isUpdatingPassword: boolean
  }
  errors: Errors
}

type HandleChangeField = (fieldName: FieldName) => (value: UserState[FieldName]) => void
type ValidateField = (fieldName: FieldName, value: UserState[FieldName]) => string

const mapToUserState = (user: User, profileImageSrc?: string): UserState => {
  const { authInfo, info, changePasswordOnNextLogin, ...restUserProperties } = user
  const { department, contactInfo, localizationSettings, ...restUserInfoProperties } = info

  return {
    ...restUserProperties,
    ...authInfo,
    ...contactInfo,
    ...restUserInfoProperties,
    password: '',
    confirmPassword: '',
    changePasswordOnNextLogin: changePasswordOnNextLogin || false,
    dateFormat: localizationSettings.dateFormat,
    timeZoneId: info.timeZoneId,
    department,
    profileImageSrc,
  }
}

export type EnhancedUserEditFormControllerProps = UserEditFormControllerProps & {
  account: UserAccount
}

export class UserEditFormControllerComponent extends React.Component<
  EnhancedUserEditFormControllerProps,
  State
> {
  // initial value for hasChangePasswordOnNextLoginChanges
  initChangePasswordOnNextLogin: boolean

  api: UserManagementApi

  // for save user flat state
  userFlatOrigin: UserState

  constructor(props: EnhancedUserEditFormControllerProps) {
    super(props)

    const { httpClient } = props

    this.initChangePasswordOnNextLogin = false

    this.state = {
      user: {
        id: '',
        fullName: '',
        password: '',
        confirmPassword: '',
        changePasswordOnNextLogin: false,
        jobTitle: '',
        department: '',
        phone: '',
        phoneExtension: '',
        login: '',
        email: '',
        createdOn: '',
        lastLoggedIn: '',
        disabled: true,
        dateFormat: '',
        timeZoneId: '',
        externalId: '',
        userType: 'FullTimeEmployee',
        isSalesRepresentative: false,
        reportLoginId: '',
      },
      managers: [],
      userCompanies: [],
      locations: [],
      reportLogins: [],
      dateFormats: [],
      timeZones: [],
      editMode: false,
      isPlaceholderVisible: true,
      isVisibleImageEditor: false,
      hasUserInfoChanges: false,
      hasPasswordChanges: false,
      hasChangePasswordOnNextLoginChanges: false,
      hasDisabledStatusChanges: false,
      hasImageChanges: false,
      fetching: {
        isLoadingUser: false,
        isSavingUser: false,
        isSwitchingUserDisabled: false,
        isLoadingManagers: false,
        isLoadingUserCompanies: false,
        isLoadingLocations: false,
        isLoadingReportLogins: false,
        isLoadingDateFormats: false,
        isLoadingTimeZones: false,
        isSavingProfileImage: false,
        isUpdatingPassword: false,
      },
      errors: {},
    }

    this.userFlatOrigin = this.state.user

    this.api = {
      ...createUserManagementApi(httpClient),
    }
  }

  /** Hooks */

  async componentDidMount() {
    await Promise.all([
      this.fetchUser(),
      this.fetchManagers(),
      this.fetchUserCompanies(),
      this.fetchLocations(),
      this.fetchDateFormats(),
      this.fetchTimeZones(),
      this.fetchReportLogins(),
    ])
    this.setState({ isPlaceholderVisible: false })
  }

  componentDidUpdate(prevProps: EnhancedUserEditFormControllerProps, _: State) {
    if (this.props.userId !== prevProps.userId) {
      this.fetchUser()
    }
  }

  /** Api calls */

  // eslint-disable-next-line react/sort-comp
  updateFetchingFlags = (flags: Record<string, boolean>) => {
    this.setState(
      evolve({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        fetching: fetching => ({ ...fetching, ...flags }),
      }),
    )
  }

  processValidationError = (error: ServerValidationError) => {
    const updatedErrors: Errors = {}

    if (error.data) {
      error.data.errors.forEach(({ fieldName, message }) => {
        // @ts-expect-error
        updatedErrors[fieldName] = message
      })
    }

    this.setState(
      evolve({
        errors: () => updatedErrors,
      }),
    )
  }

  processResponse = <TPayload>(
    response: ResponseSuperType<TPayload>,
  ): ResponseSuperType<TPayload> => {
    if (isErrorResponse(response)) {
      switch (response.$type) {
        case 'Validation':
          showNotification({ title: 'Form is invalid', level: 'error' })
          this.processValidationError(response)
          break
        case 'Unauthorized':
          if (this.props.onError) this.props.onError(response)
          break
        case 'Common':
          handleServerError(response)
          break
        default:
          break
      }
    }

    return response
  }

  fetchUser = async () => {
    const { userId } = this.props

    this.updateFetchingFlags({ isLoadingUser: true })
    const response: ResponseSuperType<User> = await this.api
      .getUser(userId)
      .then(this.processResponse)

    this.updateFetchingFlags({ isLoadingUser: false })

    const payload = extractPayload(response)
    if (payload) {
      const user = payload

      let profileImageSrc: string | undefined
      if (user.profileImageUrl) profileImageSrc = await this.fetchBase64Image(user.profileImageUrl)

      const userFlat = mapToUserState(user, profileImageSrc)

      // initial value for hasChangePasswordOnNextLoginChanges
      this.initChangePasswordOnNextLogin = userFlat.changePasswordOnNextLogin

      this.setState(assoc('user', userFlat))
      this.userFlatOrigin = { ...userFlat }
    }
  }

  fetchBase64Image = async (profileImageUrl: string): Promise<string | undefined> =>
    imageToBase64(profileImageUrl)
      .then(this.processResponse)
      .then(res => extractPayload(res))

  getUpdatedUserInfo = (): UserInfo => {
    const { user } = this.state
    return {
      fullName: user.fullName,
      jobTitle: user.jobTitle || undefined,
      department: user.department || undefined,
      userCompanyId: user.userCompanyId,
      locationId: user.locationId,
      timeZoneId: user.timeZoneId,
      manager: user.manager,
      externalId: user.externalId || undefined,
      reportLoginId: user.reportLoginId || undefined,
      userType: user.userType || 'FullTimeEmployee',
      isSalesRepresentative: user.isSalesRepresentative,
      contactInfo: {
        email: user.email || undefined,
        phone: user.phone || undefined,
        phoneExtension: user.phoneExtension || undefined,
      },
      localizationSettings: {
        dateFormat: user.dateFormat || '1',
      },
    }
  }

  saveUser = async () => {
    const {
      hasUserInfoChanges,
      hasPasswordChanges,
      hasChangePasswordOnNextLoginChanges,
      hasImageChanges,
      hasDisabledStatusChanges,
    } = this.state
    const { profileImageSrc } = this.state.user

    this.updateFetchingFlags({ isSavingUser: true })

    const updateImage = profileImageSrc ? this.saveProfileImage : this.deleteProfileImage

    const updateRequests = [
      hasUserInfoChanges ? this.saveUserInfo() : Promise.resolve(true),
      hasPasswordChanges ? this.savePassword() : Promise.resolve(true),
      hasChangePasswordOnNextLoginChanges && !hasPasswordChanges
        ? this.saveChangePasswordOnNextLogin()
        : Promise.resolve(true),
      hasImageChanges ? updateImage() : Promise.resolve(true),
      hasDisabledStatusChanges ? this.saveUserDisabledStatus() : Promise.resolve(true),
    ]

    // Waiting for all requests without prevent
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const reqStatuses = await Promise.all(updateRequests.map(req => req.catch(err => err)))

    this.updateFetchingFlags({ isSavingUser: false })

    if (reqStatuses.every(status => status)) {
      this.setState({
        hasPasswordChanges: false,
        hasImageChanges: false,
        hasDisabledStatusChanges: false,
        hasUserInfoChanges: false,
        hasChangePasswordOnNextLoginChanges: false,
      })

      // change initial value
      this.initChangePasswordOnNextLogin = this.state.user.changePasswordOnNextLogin

      this.toggleEditMode()
    }

    this.userFlatOrigin = { ...this.state.user }
  }

  saveUserInfo = async (): Promise<boolean> => {
    const { userId } = this.props
    const userInfo = this.getUpdatedUserInfo()
    const response = await this.api.saveUser(userId, userInfo).then(this.processResponse)
    this.updateFetchingFlags({ isSavingUser: false })
    if (isSuccessResponse(response)) {
      this.setState({ hasUserInfoChanges: false })
      showNotification({ title: 'User info was updated', level: 'success' })
      return true
    }
    return false
  }

  saveUserDisabledStatus = async (): Promise<boolean> => {
    const { userId } = this.props
    const { disabled } = this.state.user
    const originDisabledStatus = this.userFlatOrigin.disabled

    this.updateFetchingFlags({ isSwitchingUserDisabled: true })
    const response = await this.api.disableUser(userId, disabled).then(this.processResponse)
    this.updateFetchingFlags({ isSwitchingUserDisabled: false })

    this.setHasDisabledStatusChanges(false)

    if (isSuccessResponse(response)) {
      this.userFlatOrigin.disabled = disabled
      showNotification({
        title: `User has been ${disabled ? 'disabled' : 'activated'}`,
        level: 'success',
      })
      return true
    }

    this.setState(assocPath(['user', 'disabled'], originDisabledStatus))
    return false
  }

  saveProfileImage = async (): Promise<boolean> => {
    const { userId } = this.props
    const { profileImageSrc } = this.state.user
    const originImageSrc = this.userFlatOrigin.profileImageSrc

    if (!profileImageSrc) return true

    this.updateFetchingFlags({ isSavingProfileImage: true })

    const imageExt = base64ImageExt(profileImageSrc)
    const imageData = base64RemoveImageType(profileImageSrc)

    if (imageExt) {
      const response = await this.api
        .updateProfileImage(userId, { base64image: imageData, extension: imageExt })
        .then(this.processResponse)

      this.updateFetchingFlags({ isSavingProfileImage: false })

      this.setHasImageChanges(false)

      if (isSuccessResponse(response)) {
        this.userFlatOrigin.profileImageSrc = profileImageSrc
        showNotification({ title: 'User photo successfully changed', level: 'success' })
        return true
      }
      this.setProfileImageSrc(originImageSrc)
      return false
    }

    return false
  }

  deleteProfileImage = async (): Promise<boolean> => {
    const { userId } = this.props
    const originImageSrc = this.userFlatOrigin.profileImageSrc

    this.updateFetchingFlags({ isSavingProfileImage: true })

    const response = await this.api.deleteProfileImage(userId).then(this.processResponse)

    this.updateFetchingFlags({ isSavingProfileImage: false })

    this.setHasImageChanges(false)

    if (isSuccessResponse(response)) {
      this.userFlatOrigin.profileImageSrc = undefined
      showNotification({ title: 'User photo has been removed', level: 'success' })
      return true
    }
    this.setProfileImageSrc(originImageSrc)
    return false
  }

  savePassword = async (): Promise<boolean> => {
    const { password, confirmPassword } = this.state.errors
    if (password || confirmPassword) return false

    const { userId } = this.props
    const newPassword = this.state.user.password
    const { changePasswordOnNextLogin } = this.state.user

    this.updateFetchingFlags({ isUpdatingPassword: true })

    const response = await this.api
      .setPasswordToUser(userId, { newPassword, changePasswordOnNextLogin })
      .then(this.processResponse)

    this.updateFetchingFlags({ isUpdatingPassword: false })

    this.setHasImageChanges(false)

    if (isSuccessResponse(response)) {
      showNotification({ title: 'User password has changed', level: 'success' })
      return true
    }
    return false
  }

  saveChangePasswordOnNextLogin = async (): Promise<boolean> => {
    const { userId } = this.props
    const { changePasswordOnNextLogin } = this.state.user

    const response = await this.api
      .setChangePasswordOnNextLoginToUser(userId, changePasswordOnNextLogin)
      .then(this.processResponse)

    return isSuccessResponse(response)
  }

  resetUserInfoChanges = () => {
    const originInfo = { ...this.userFlatOrigin }
    this.setState(assoc('user', originInfo))
  }

  fetchData =
    <T>(
      request: () => Promise<ResponseSuperType<T>>,
      loadingPropertyName: string,
      dataPropertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseMapping: (response: T) => any = res => res,
    ) =>
    async () => {
      this.updateFetchingFlags({ [loadingPropertyName]: true })

      const response = await request().then(this.processResponse)

      this.updateFetchingFlags({ [loadingPropertyName]: false })

      const payload = extractPayload(response)
      if (payload) {
        this.setState(assoc(dataPropertyName, responseMapping(payload)))
      }
    }

  fetchManagers = this.fetchData(() => this.api.getManagers(), 'isLoadingManagers', 'managers')

  fetchTimeZones = async () => {
    this.updateFetchingFlags({ isLoadingTimeZones: true })

    const response = await usersApi.getTimeZones()
    if (response.status === 200) {
      this.setState(assoc('timeZones', response.body))
    } else {
      handleServerError(response)
    }

    this.updateFetchingFlags({ isLoadingTimeZones: false })
  }

  fetchUserCompanies = this.fetchData(
    () => this.api.getUserCompanies(),
    'isLoadingUserCompanies',
    'userCompanies',
  )

  fetchDateFormats = this.fetchData(
    () => this.api.getDateFormats(),
    'isLoadingDateFormats',
    'dateFormats',
    (response: DateFormats) => response.formats,
  )

  fetchLocations = async () => {
    this.updateFetchingFlags({ isLoadingLocations: true })

    const response = await usersApi.getLocations()
    if (response.status === 200) {
      this.setState(assoc('locations', response.body))
    } else {
      handleServerError(response)
    }

    this.updateFetchingFlags({ isLoadingLocations: false })
  }

  fetchReportLogins = async () => {
    this.updateFetchingFlags({ isLoadingReportLogins: true })

    const response = await usersApi.getReportLogins()
    if (response.status === 200) {
      this.setState(assoc('reportLogins', response.body))
    } else {
      handleServerError(response)
    }

    this.updateFetchingFlags({ isLoadingReportLogins: false })
  }

  /** Handlers */
  deleteLocation = async (locationId: Lib$Id) => {
    this.updateFetchingFlags({ isLoadingLocations: true })
    const response = await usersApi.deleteLocation({ locationId })
    if (response.status !== 200) {
      handleServerError(response)
    }
    await this.fetchLocations()
  }

  addLocation = async (name: Lib$Id) => {
    this.updateFetchingFlags({ isLoadingLocations: true })
    // if you dont wrap name, it will send text without double quotes ("..")
    const requestBody = JSON.stringify(name)
    const response = await usersApi.addLocation(requestBody)
    if (response.status !== 200) {
      handleServerError(response)
    }
    await this.fetchLocations()
  }

  toggleEditMode = () => {
    const { editMode } = this.state
    this.setState({
      editMode: !editMode,
    })
  }

  handleChangeField: HandleChangeField = memoizeWith(identity, fieldName => value => {
    const {
      errors,
      user: { password, confirmPassword },
    } = this.state
    const error = this.validateField(fieldName, value)

    switch (fieldName) {
      case 'password': {
        this.setState((prevState: State, _) => {
          const evolver = evolve({
            hasPasswordChanges: always(Boolean(value || confirmPassword)),
          })
          return evolver(prevState)
        })
        break
      }

      case 'confirmPassword': {
        this.setState((prevState: State, _) => {
          const evolver = evolve({
            hasPasswordChanges: always(Boolean(value || password)),
          })
          return evolver(prevState)
        })
        break
      }

      case 'changePasswordOnNextLogin': {
        this.setState({
          hasChangePasswordOnNextLoginChanges: value !== this.initChangePasswordOnNextLogin,
        })
        break
      }

      default: {
        this.setState((prevState: State, _) => {
          const evolver = evolve({
            hasUserInfoChanges: always(true),
          })
          return evolver(prevState)
        })
      }
    }

    let updatedErrors: Errors = { ...errors, [fieldName]: error }

    // Reset confirm password error. When first fill field 'Confirm password' and THEN 'Password'
    const isPasswordField = fieldName === 'password'
    if (isPasswordField && !error && confirmPassword && value === confirmPassword) {
      updatedErrors = {
        ...updatedErrors,
        confirmPassword: '',
      }
    }

    this.setState((prevState: State, _) => {
      const evolver = evolve({
        user: (user: UserState) => assoc(fieldName, value, user),
        errors: always(updatedErrors),
      })
      return evolver(prevState)
    })
  })

  handleExternalIdPrint = () => {
    const { userId, account } = this.props
    const accountId = account.id || 0

    let machineId: string | null
    machineId = cookie.getItem(`UserPrinterSettings${accountId}`) || null
    if (!machineId) machineId = localStorage.getItem(`ReconMain: MACHINE_ID_${accountId}`) || null

    if (!machineId) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Please set up printer settings',
      })

      return
    }

    this.api
      .printUserExternalId(userId, machineId)
      .then((res: ResponseSuperType<{ printingBatchId: string }>) => {
        if (res.$type === 'Success') {
          const jobId = res.payload.printingBatchId
          if (jobId) printJobIds({ jobIds: [jobId] })
          return
        }

        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error while printing label',
        })
      })
  }

  /** Validation */

  validateField: ValidateField = (fieldName, value) => {
    if (fieldName === 'password' && !value) {
      return ''
    }

    if (fieldName === 'confirmPassword') {
      const { password } = this.state.user
      return password !== value ? 'Password does not match the confirm password.' : ''
    }

    if ((fieldName === 'userCompanyId' || fieldName === 'locationId') && value === null) {
      return validate(fieldName, '')
    }

    if (value === null || typeof value === 'string') {
      return validate(fieldName, value || '')
    }

    return ''
  }

  /**
   * @returns {boolean} is valid
   */
  validateAuthForm = () => {
    const fieldNames: FieldName[] = ['password', 'confirmPassword', 'login']
    const newErrors: Errors = {}
    let valid = true
    fieldNames.forEach(fieldName => {
      const value = this.state.user[fieldName]
      const error = this.validateField(fieldName, value)
      newErrors[fieldName] = error
      if (error) valid = false
    })

    this.setState((prevState: Readonly<State>, _) =>
      evolve(
        {
          errors: (errors: Errors) => merge(errors, newErrors),
        },
        prevState,
      ),
    )

    return valid
  }

  validateUserInfo = () => {
    const fieldNames: FieldName[] = [
      'email',
      'fullName',
      'jobTitle',
      'manager',
      'department',
      'phone',
      'phoneExtension',
      'userCompanyId',
      'locationId',
      'timeZoneId',
      'reportLoginId',
    ]
    const newErrors: Errors = {}
    let valid = true
    fieldNames.forEach(fieldName => {
      const value = this.state.user[fieldName]
      const error = this.validateField(fieldName, value)
      newErrors[fieldName] = error
      if (error) valid = false
    })

    this.setState((prevState: Readonly<State>, _) =>
      evolve(
        {
          errors: (errors: Errors) => merge(errors, newErrors),
        },
        prevState,
      ),
    )

    return valid
  }

  handleSaveUser = () => {
    if (this.validateAuthForm() && this.validateUserInfo()) this.saveUser()
  }

  switchOffEditMode = () => {
    this.resetUserInfoChanges()
    this.toggleEditMode()
  }

  /** User activation */

  setHasDisabledStatusChanges = (has = true) => {
    this.setState({ hasDisabledStatusChanges: has })
  }

  handleToggleActive = (active: boolean) => {
    const disabled = !active
    const originStatus = this.userFlatOrigin.disabled

    this.setState(assocPath(['user', 'disabled'], disabled))
    this.setHasDisabledStatusChanges(disabled !== originStatus)
  }

  /** Image Editor */

  setProfileImageSrc = (base64Image?: string) => {
    this.setState(assocPath(['user', 'profileImageSrc'], base64Image))
  }

  setHasImageChanges = (has = true) => {
    this.setState({ hasImageChanges: has })
  }

  showImageEditor = () => {
    this.setState({ isVisibleImageEditor: true })
  }

  hideImageEditor = () => {
    this.setState({ isVisibleImageEditor: false })
  }

  handleCancelEditImage = () => {
    this.hideImageEditor()
    this.setProfileImageSrc(this.userFlatOrigin.profileImageSrc)
  }

  handleUploadImage = (base64Image: string) => {
    this.setProfileImageSrc(base64Image)
    this.showImageEditor()
  }

  handleChangeImage = (base64Image: string) => {
    this.setProfileImageSrc(base64Image)
    this.setHasImageChanges()
    this.hideImageEditor()
  }

  handleDeleteImage = () => {
    this.setProfileImageSrc(undefined)
    // If user has not image, then remove will not affect on flag.
    this.setHasImageChanges(Boolean(this.userFlatOrigin.profileImageSrc))
  }

  /** Collect output props */

  getUserEditableInfoProp = (): UserEditableInfo => {
    const {
      user,
      managers,
      userCompanies,
      locations,
      dateFormats,
      timeZones,
      fetching,
      errors,
      reportLogins,
    } = this.state

    return {
      email: {
        value: user.email || '',
        onChange: this.handleChangeField('email'),
        error: errors.email,
      },
      login: {
        value: user.login || '',
        onChange: this.handleChangeField('login'),
        error: errors.login || '',
      },
      password: {
        value: user.password,
        onChange: this.handleChangeField('password'),
        error: errors.password,
      },
      confirmPassword: {
        value: user.confirmPassword,
        onChange: this.handleChangeField('confirmPassword'),
        error: errors.confirmPassword,
      },
      changePasswordOnNextLogin: {
        value: user.changePasswordOnNextLogin,
        onChange: this.handleChangeField('changePasswordOnNextLogin'),
      },
      fullName: {
        value: user.fullName,
        onChange: this.handleChangeField('fullName'),
        error: errors.fullName,
      },
      jobTitle: {
        value: user.jobTitle,
        onChange: this.handleChangeField('jobTitle'),
        error: errors.jobTitle,
      },
      department: {
        value: user.department,
        onChange: this.handleChangeField('department'),
        error: errors.department,
      },
      phone: {
        value: user.phone,
        onChange: this.handleChangeField('phone'),
        error: errors.phone,
      },
      phoneExtension: {
        value: user.phoneExtension,
        onChange: this.handleChangeField('phoneExtension'),
        error: errors.phoneExtension,
      },
      manager: {
        isLoading: fetching.isLoadingManagers,
        value: user.manager,
        onChange: this.handleChangeField('manager'),
        error: errors.manager,
        availableOptions: managers,
      },
      userCompany: {
        isLoading: fetching.isLoadingUserCompanies,
        value: user.userCompanyId,
        onChange: this.handleChangeField('userCompanyId'),
        error: errors.userCompanyId,
        availableOptions: userCompanies,
      },
      location: {
        isLoading: fetching.isLoadingLocations,
        value: user.locationId,
        onChange: this.handleChangeField('locationId'),
        error: errors.locationId,
        availableOptions: locations,
        onAdd: this.addLocation,
        onDelete: this.deleteLocation,
      },
      reportLogin: {
        isLoading: fetching.isLoadingReportLogins,
        value: user.reportLoginId,
        onChange: this.handleChangeField('reportLoginId'),
        availableOptions: reportLogins,
      },
      dateFormat: {
        isLoading: fetching.isLoadingDateFormats,
        value: user.dateFormat,
        onChange: this.handleChangeField('dateFormat'),
        availableOptions: dateFormats.map(dateFormatString => ({
          id: dateFormatString,
          name: `${dateFormatString} (${formatDate(new Date(), dateFormatString)})`,
        })),
      },
      timeZone: {
        isLoading: fetching.isLoadingTimeZones,
        value: user.timeZoneId,
        error: errors.timeZoneId,
        onChange: this.handleChangeField('timeZoneId'),
        availableOptions: timeZones,
      },
      externalId: {
        value: user.externalId,
        onChange: this.handleChangeField('externalId'),
        error: errors.externalId,
      },
      userType: {
        value: user.userType,
        onChange: this.handleChangeField('userType'),
        error: errors.userType,
        isLoading: false,
        availableOptions: [
          { id: 'FullTimeEmployee', name: 'Full Time Employee' },
          { id: 'Operator', name: 'Operator' },
          { id: 'OperatorTemplate', name: 'Operator Template' },
        ],
      },
      isSalesRepresentative: {
        value: user.isSalesRepresentative || false,
        onChange: this.handleChangeField('isSalesRepresentative'),
      },
    }
  }

  hasUnsavedChanges = () => {
    const {
      hasUserInfoChanges,
      hasDisabledStatusChanges,
      hasImageChanges,
      hasPasswordChanges,
      hasChangePasswordOnNextLoginChanges,
    } = this.state
    return (
      hasUserInfoChanges ||
      hasDisabledStatusChanges ||
      hasImageChanges ||
      hasPasswordChanges ||
      hasChangePasswordOnNextLoginChanges
    )
  }

  getUserEditFormActions = (): UserEditFormActions => {
    const { fetching, editMode } = this.state
    return {
      switchOnEditMode: {
        visible: !editMode,
        dispatch: this.toggleEditMode,
      },
      switchOffEditMode: {
        visible: editMode,
        dispatch: this.switchOffEditMode,
      },
      saveUser: {
        visible: editMode,
        disabled: !this.hasUnsavedChanges(),
        isExecuting: fetching.isSavingUser,
        dispatch: this.handleSaveUser,
      },
      cancelForm: {
        visible: !editMode,
        dispatch: this.props.onCancelForm,
      },
      printExternalId: {
        visible: !editMode && !!this.state.user.externalId,
        dispatch: this.handleExternalIdPrint,
      },
    }
  }

  getUserImageEditorInfoProp = (): ImageEditorInfo => {
    const { user, isVisibleImageEditor } = this.state
    return {
      visible: isVisibleImageEditor,
      profileImageSrc: user.profileImageSrc,
      onCancel: this.handleCancelEditImage,
      onShow: this.showImageEditor,
      onUploadImage: this.handleUploadImage,
      onChangeImage: this.handleChangeImage,
      onDeleteImage: this.handleDeleteImage,
    }
  }

  getUserActivationInfoProp = (): ActivationInfo => {
    const { user, fetching } = this.state

    return {
      active: !user.disabled,
      disabled: fetching.isSwitchingUserDisabled,
      onToggle: this.handleToggleActive,
    }
  }

  getUserDisplayInfoProp = (): UserDisplayInfo => {
    const { createdOn, lastLoggedIn } = this.state.user
    return {
      originalFullName: this.userFlatOrigin ? this.userFlatOrigin.fullName : '',
      createdOn,
      lastLoggedIn,
    }
  }

  render() {
    const { userId, children } = this.props
    const { isPlaceholderVisible, editMode } = this.state

    if (isPlaceholderVisible) {
      return children({ placeholder: true })
    }
    return children({
      userId,
      placeholder: false,
      formEditMode: editMode,
      hasUnsavedChanges: this.hasUnsavedChanges(),
      formActions: this.getUserEditFormActions(),
      activationInfo: this.getUserActivationInfoProp(),
      displayInfo: this.getUserDisplayInfoProp(),
      editableInfo: this.getUserEditableInfoProp(),
      imageEditorInfo: this.getUserImageEditorInfoProp(),
    })
  }
}

export const UserEditFormController = withAccountInfo<UserEditFormControllerProps>(
  UserEditFormControllerComponent,
)
