/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import * as React from 'react'
import { connect } from 'react-redux'

const compose = (...fns) =>
  fns.reduce(
    (f, g) =>
      (...args) =>
        f(g(...args)),
  )

export function withModule({ selectors, actions }, valueKey = 'value') {
  const withState = WrappedComponent =>
    class extends React.Component {
      state = { term: '' }

      setTerm = term => this.setState({ term })

      render() {
        return (
          <WrappedComponent term={this.state.term} onChangeTerm={this.setTerm} {...this.props} />
        )
      }
    }

  const mapStateToProps = (state, props) => ({
    items: selectors.getCollectionItems(state, props),
    isFetching: selectors.isFetching(state, props),
  })

  const withLifecycle = WrappedComponent =>
    class extends React.Component {
      componentDidMount() {
        const { [valueKey]: value, restoreItems, fetchAll, ...props } = this.props

        if (restoreItems) {
          if (value) restoreItems(value, props)
        } else if (fetchAll) {
          fetchAll(props)
        }
      }

      componentDidUpdate(prevProps) {
        const { [valueKey]: value, restoreItems, ...props } = this.props

        if (restoreItems) {
          if (prevProps.value !== value) {
            if (value) restoreItems(value, props)
          }
        }
      }

      render() {
        return <WrappedComponent {...this.props} />
      }
    }

  return compose(withState, connect(mapStateToProps, actions), withLifecycle)
}
