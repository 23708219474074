// @flow

import * as React from 'react'
import { FormManagerProvider } from '@r1/form-manager'
import { useNavigate } from 'react-router-dom'
import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'
import { CreateAttributeForm } from './CreateAttributeForm'

const formManagerConfig = [{ formId: 'attributeCreationForm' }]

export const CreateAttribute = () => {
  const navigate = useNavigate()
  return (
    <FormManagerProvider config={formManagerConfig}>
      <CreateAttributeForm
        httpClient={httpClient}
        onSubmit={() => {
          navigate(ROUTES.ATTRIBUTES_DEFINITIONS)
        }}
        onCancel={() => {
          navigate(ROUTES.ATTRIBUTES_DEFINITIONS)
        }}
      />
    </FormManagerProvider>
  )
}
