// @flow

const mapToSuccessResponse = data => {
  return {
    $type: 'Success',
    payload: data,
  }
}

const mapToCommonErrors = err => {
  if (err.response) {
    const { status } = err.response

    if (status === 401) return { $type: 'Unauthorized', message: 'Unauthorized' }
    if (status === 409) return { $type: 'Conflict', message: err.response.data.error.defaultFormat }
  }
  return {
    $type: 'Common',
    payload: err,
  }
}

export function createApi(httpClient: $AnyObject) {
  return {
    getAllAttributeDefinitions: () =>
      httpClient
        .get('/uisvc/company-catalog/skms/api/v1/attribute-definitions')
        .then(mapToSuccessResponse)
        .catch(mapToCommonErrors),

    deleteAttributeDefinition: (id: string, version: string) =>
      httpClient
        .delete(
          `/uisvc/company-catalog-management/8ihs/api/v1/attribute-definitions/${id}?version=${version}`,
        )
        .then(mapToSuccessResponse)
        .catch(mapToCommonErrors),
  }
}
