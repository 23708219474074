// @flow

import React, { useCallback } from 'react'
import { Button, Modal } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import type { CategoryCreationScreenProps } from '../../../types/public/CategoryCreationScreen'
import { CategoryContainer } from '../CategoryContainer'
import { CategoryCreate } from '../children/CategoryCreate'

export const CategoryCreationScreen = (props: CategoryCreationScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'No',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Yes',
          transparent: false,
          onClick: () => {
            props.onCancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [props],
  )

  return (
    <CategoryContainer httpClient={props.httpClient}>
      <CategoryCreate onSuccessfulSubmit={props.onSuccessfulSubmit}>
        {({ handlers, isLoading }) => (
          <>
            <Title>New Category</Title>
            <ActionButtons>
              <Modal
                isControlled={false}
                title="You have unsaved data"
                actionButtons={modalActionButtons}
                buttonElement={({ onOpen }) => (
                  <Button color="secondary" onClick={onOpen}>
                    Cancel
                  </Button>
                )}
              >
                Are you sure you want to leave without saving?
              </Modal>
              <Button loading={isLoading} onClick={handlers.handleSubmit}>
                Create
              </Button>
            </ActionButtons>
          </>
        )}
      </CategoryCreate>
    </CategoryContainer>
  )
}
