// @flow

import * as React from 'react'
import { Flex } from '@r1/ui-kit'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import type {
  Attachment,
  ChatMessageType,
} from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { ExpectedDecision, ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'
import type { ClaimInfo, OrderInfo, CustomerInfo } from '../../types/common'
import type { NewClaimItem } from '../../types/ClaimScreen'

import ExpectedDecisionMessage from './chatMessages/ExpectedDecisionMessage'
import RegularMessage from './chatMessages/RegularMessage'
import SystemMessage from './chatMessages/SystemMessage'

type Props = {
  chatMessageId: string,
  unread: boolean,
  sender: string,
  messageType: ChatMessageType,
  recipient: string,
  text: string,
  sentOn: string,
  marketplaceId?: string,
  markAsRead: () => void | Promise<void>,
  getAttachment: (attachmentId: ?string) => Promise<?Attachment>,
  attachments: Attachment[],
  expectedDecisionChatMessageIds: string[],
  claim: ClaimInfo,
  order: OrderInfo,
  customer: CustomerInfo,
  availableDecisionTypes: ExpectedDecision[],
  availableReturnReasons: ReturnReason[],
  createClaim: (
    chatMessageId: Lib$Id,
    decisionTypeId: Lib$Id,
    description: string,
    claimItems: NewClaimItem[],
  ) => Promise<string | null>,
}

export const ChatMessage = (props: Props) => {
  const {
    chatMessageId,
    unread,
    sender,
    messageType,
    recipient,
    text,
    sentOn,
    marketplaceId,
    markAsRead,
    getAttachment,
    attachments,
    expectedDecisionChatMessageIds,
    claim,
    order,
    customer,
    availableDecisionTypes,
    availableReturnReasons,
    createClaim,
  } = props

  const isOwn = React.useMemo(() => sender === 'Merchant', [sender])

  return (
    <Flex justify={isOwn ? 'flex-end' : 'flex-start'}>
      {sender === 'System' && <SystemMessage text={text} />}
      {sender !== 'System' && messageType === 'ExpectedDecision' && (
        <ExpectedDecisionMessage
          chatMessageId={chatMessageId}
          text={text}
          claim={claim}
          order={order}
          expectedDecisionChatMessageIds={expectedDecisionChatMessageIds}
          availableDecisionTypes={availableDecisionTypes}
          availableReturnReasons={availableReturnReasons}
          createClaim={createClaim}
        />
      )}
      {sender !== 'System' && messageType !== 'ExpectedDecision' && (
        <RegularMessage
          isOwn={isOwn}
          unread={unread}
          sender={sender}
          recipient={recipient}
          customer={customer}
          text={text}
          sentOn={sentOn}
          marketplaceId={marketplaceId}
          markAsRead={markAsRead}
          getAttachment={getAttachment}
          attachments={attachments}
        />
      )}
    </Flex>
  )
}
