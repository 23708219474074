import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { PageAccessControl, PERMISSIONS, withAccessControl } from '@r1/core-blocks'
import { Main, Content } from '@r1/wireframe-primary'
import { useParams } from 'react-router-dom'
import { actions } from '../../modules/products'
import { actions as productTemplatesActions } from '../../modules/productTemplates'
import { actions as imagesActions } from '../../modules/productTemplates/images'
import ProductCardForm from './ProductCardForm'
import { Header } from './Header'

const enhance = compose(
  connect(
    null,
    {
      fetchProductCard: actions.fetchItem,
      fetchProductTemplate: productTemplatesActions.fetchItem,
      fetchImages: imagesActions.fetchAll,
    },
    null,
  ),
)

const ProductCardInner = ({
  fetchProductCard,
  fetchProductTemplate,
  fetchImages,
  allowedPermissions: [{ allowUnitEdit }],
}) => {
  const { id } = useParams()

  React.useEffect(() => {
    const fetchData = async () => {
      const productCardData = await fetchProductCard({ id })
      if (productCardData) {
        const { productTemplateId } = productCardData
        fetchProductTemplate({ id: productTemplateId })
        fetchImages({ id: productTemplateId })
      }
    }

    fetchData()
  }, [fetchImages, fetchProductCard, fetchProductTemplate, id])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowUnitView]}>
      <Main>
        <Content>
          <Header productId={id} />
          <ProductCardForm id={id} isEditAllowed={allowUnitEdit} />
        </Content>
      </Main>
    </PageAccessControl>
  )
}

export const ProductCard = enhance(withAccessControl(ProductCardInner))
