// @flow

import React, { useContext, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { Flex, Box, H3, Link, Icon, Button, Text } from '@r1/ui-kit'
import { ServiceContext } from '../../../../provider'
import { useToggle } from '../../../../../../../../utils/hooks'
import { CategoriesTreeSelect } from '../../../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'
import { ProductSelect } from '../Common'

import type { PartsFormEditProps } from '../../../../types/product.type'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`
const DEFAULT_SHOW_COUNT = 5

export const Parts = (props: PartsFormEditProps) => {
  const { httpClient } = useContext(ServiceContext)
  const { values, push, remove, getProductDetailsUrl, getError, disabled } = props

  // Show all / Hide
  const { state: isShowAll, toggle: setShowAll } = useToggle(false)
  const partsLength = values.Parts.length

  const partsMemo = useMemo(
    () => (isShowAll ? values.Parts : values.Parts.slice(0, DEFAULT_SHOW_COUNT)),
    [values.Parts, partsLength, isShowAll],
  )
  //----

  const addPart = useCallback(() => {
    if (!disabled) {
      push('Parts', {
        id: Math.random(),
        categoryId: null,
        productId: null,
        productOption: null,
      })
      if (!isShowAll) setShowAll()
    }
  }, [disabled, push])

  const removePart = useCallback(
    index => () => {
      if (!disabled) remove('Parts', index)
    },
    [remove, disabled, isShowAll],
  )

  return (
    <>
      <Box>
        <StyledH3>Parts</StyledH3>
      </Box>
      {partsMemo.map((part, index) => (
        <Flex
          key={`${part.id}`}
          maxWidth={900}
          minWidth={900}
          justify="space-between"
          mt="S"
          align="flex-start"
        >
          <Box basis={255} mr="M">
            <CategoriesTreeSelect
              ignoreBranchNodes
              disabled={disabled} // Unsorted
              hideCategoryIds={['0']}
              width={255}
              multiple={false}
              placeholder="Select category"
              httpClient={httpClient}
              categoryId={part.categoryId}
              onChangeCategoryId={value => {
                if (undefined !== value) {
                  props.handleChange(['Parts', index, 'categoryId'])(value)
                }
                props.handleChange(['Parts', index, 'productId'])(null)
                props.handleChange(['Parts', index, 'productOption'])(null)
              }}
            />
          </Box>
          <Box basis={430} mx="M">
            <ProductSelect
              {...props}
              key={`${part.id}`}
              value={part}
              error={getError(['Parts', index, 'productId'])}
              path={['Parts', index]}
              onChange={props.handleChange}
            />
          </Box>
          <Box maxWidth={20} minWidth={20} mt="S">
            {part.productId && (
              <Link href={getProductDetailsUrl(part.productId)} target="_blank">
                <Icon type="externalLink" color="primary" />
              </Link>
            )}
          </Box>
          {values.Parts.length > 1 && (
            <Button transparent color="secondary" onClick={removePart(index)}>
              <Icon type="minus" />
            </Button>
          )}
          <Button transparent color="secondary" onClick={addPart}>
            <Icon type="plus" />
          </Button>
        </Flex>
      ))}

      {DEFAULT_SHOW_COUNT < partsLength && (
        <Link onClick={() => setShowAll()}>
          {isShowAll ? <Text>Hide</Text> : <Text>Show all ({partsLength})</Text>}
        </Link>
      )}
    </>
  )
}
