// @flow

import styled from '@emotion/styled'

export const RegularMessageContainer = styled('div')`
  margin: 10px;
  position: relative;
  padding: 30px;
  justify-content: center;
  background-color: ${({ theme, sender }) => {
    switch (sender) {
      case 'Customer':
        return theme.palette.blue[200]
      case 'Admin':
        return theme.palette.yellow[200]
      default:
        return theme.palette.white
    }
  }};
  border-radius: 10px;

  &::after {
    content: '';
    position: absolute;

    width: 16px;
    height: 16px;
    left: ${({ sender }) => (sender === 'Merchant' ? 'calc(100% - 8px)' : '-8px')};
    top: 20px;

    background-color: ${({ theme, sender }) => {
      switch (sender) {
        case 'Customer':
          return theme.palette.blue[200]
        case 'Admin':
          return theme.palette.yellow[200]
        default:
          return theme.palette.white
      }
    }};

    transform: rotate(-45deg);
  }
`

export const ExpectedDecisionMessageContainer = styled('div')`
  margin: 10px;
  position: relative;
  padding: 30px;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.yellow[200]};
  border-radius: 10px;
  width: 100%;
`

export const SystemMessageContainer = styled('div')`
  margin: 10px;
  position: relative;
  padding: 30px;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.white[200]};
  border-radius: 10px;
  width: 100%;
`

export const MessageDate = styled('div')`
  margin-top: ${({ theme }) => theme.space.S};
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.space.S};
  color: ${({ theme }) => theme.palette.grey[600]};
`

export const MessageText = styled('div')`
  white-space: pre-wrap;
  max-width: 350px;
  font-size: ${({ theme }) => theme.space.M};
  overflow-wrap: break-word;
`

export const InformationalText = styled('div')`
  white-space: pre-wrap;
  font-size: ${({ theme }) => theme.space.M};
  overflow-wrap: break-word;
  text-align: center;
`
