import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateDictionariesApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, selectors, actions } = createCollectionModule({
  name: '@dictionaries/consigneeUpcClassifications',
  keyField: 'id',
  fetchAllFunc: (_, _api) =>
    productTemplateDictionariesApi.getConsigneeUpcClassifications().then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { selectors, actions }
