import { GridColumnSettings } from '../../../offer-management/types'
import { DepositHistoryGridColumn } from './types'

export const depositHistoryGridMeta = new Map<DepositHistoryGridColumn, GridColumnSettings>([
  [DepositHistoryGridColumn.CompanyName, { header: 'Company Name', width: 150 }],
  [DepositHistoryGridColumn.HistoryEvent, { header: 'Event' }],
  [DepositHistoryGridColumn.Date, { header: 'Created On', width: 270 }],
])

export const DATE_FORMAT = 'MM/dd/yyyy hh:mm a'
