import { handleServerError } from '@r1/core-blocks'
import { sortingIndexesApi } from '../../../api/sortingIndexes'

// problem with comma sign in values
// caching pairs of values with and without comma for last processing
export const sortingIndexesCache = new Map()

export const getSortingIndexes = async () => {
  const res = await sortingIndexesApi.getSortingIndexes()

  if (!Array.isArray(res)) {
    handleServerError(res)
    return []
  }

  const sortingIndexes: Array<{ id: string; name: string }> = []

  res.forEach(item => {
    const key = item.replace(/,/g, '')
    sortingIndexesCache.set(key, item)
    sortingIndexes.push({ id: key, name: item })
  })

  return sortingIndexes
}
