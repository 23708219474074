import styled from '@emotion/styled'

export const CSSGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 30px;
  ${({ width }) => `width: ${width};`}
`
export const Cell = styled('div')`
  ${({ span }) => (span ? `grid-column-start: span ${span};` : '')}
  ${({ end }) => (end ? `grid-column-end: ${end};` : '')}
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ height }) => (height ? `height: ${height};` : '')}
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
`
