// @flow

import { baseUri } from '@r1-webui/productcatalog-products-v1'

import { UI_API_PREFIXES } from '../../../api'
import { successResponseInterceptor } from '../../utils/api'
import type { Api, GetProductInfoOptions } from '../../../types/internal/api/products'
import { errorHandler } from './errorHandler'

export const API_PREFIX = `${UI_API_PREFIXES.CATALOG}/${baseUri}`

export const createApi = (httpClient: Object): Api => ({
  getProductInfo: (options: GetProductInfoOptions) =>
    httpClient
      .get(`${API_PREFIX}/products/${options.productId}/info`)
      .then(successResponseInterceptor)
      .catch(errorHandler),
})
