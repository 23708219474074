// @flow

import * as React from 'react'
import { useMemo, memo, useState } from 'react'
import {
  imageApi,
  priceApi,
  productApi,
  referenceApi,
  categoryApi,
  variationsApi,
  manufacturerManagementApi,
} from '../../api'
import {
  priceService,
  productService,
  referenceService,
  imageService,
  categoryService,
  variationsService,
  manufacturerManagementService,
} from '../../services'
import { ServiceContext } from './provider'

type ProductContainerProps = {
  children?: React.Node,
  httpClient: any,
}

export const ProductContainer = memo<ProductContainerProps>((props: ProductContainerProps) => {
  const { httpClient, children } = props
  const [allCategories, setAllCategories] = useState([])
  const apiMemo = useMemo(
    () => ({
      productService: productService(productApi(httpClient)),
      imageService: imageService(imageApi(httpClient)),
      categoryService: categoryService(categoryApi(httpClient)),
      priceService: priceService(priceApi(httpClient)),
      referenceService: referenceService(referenceApi(httpClient)),
      variationService: variationsService(variationsApi(httpClient)),
      manufacturerManagementService: manufacturerManagementService(
        manufacturerManagementApi(httpClient),
      ),
      httpClient,
      allCategories,
      setAllCategories,
    }),
    [allCategories, httpClient],
  )

  return <ServiceContext.Provider value={apiMemo}>{children}</ServiceContext.Provider>
})
