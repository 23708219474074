// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Flex, Box, Link, Text, Icon } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'
import { Table } from '@r1/grid'

import { gridTheme } from '../../../../constants'
import type { ProfileType, SharingType } from '../../types'

const mapSharings = ({ sharings = [] }: ProfileType) => {
  return sharings.reduce(
    (acc: { shared: number, pending: number }, sharing: SharingType) => {
      const isApproved = sharing.approvedOn && !sharing.approveCanceledOn
      const isPending = sharing.sharedOn && !sharing.approvedOn

      if (isApproved) {
        acc.shared += 1
      } else if (isPending) {
        acc.pending += 1
      }
      return acc
    },
    { shared: 0, pending: 0 },
  )
}

const headers = [
  { id: 'name', name: 'Profile name' },
  { id: 'type', name: 'Provider' },
  { id: 'sharings', name: 'Sharing status' },
  { id: 'actions', name: 'Actions' },
]

const SharingsText = styled('span')`
  color: ${({ theme: { palette } }) => palette.grey[500]};

  &:after {
    padding-right: 5px;
    content: ',';
  }

  &:before {
    color: ${({ theme: { palette } }) => palette.black};
  }

  &:last-child {
    &:after {
      content: '';
    }
  }
`

const SharedText = styled(SharingsText)`
  &:before {
    content: 'Shared ';
  }
`

const PendingText = styled(SharingsText)`
  &:before {
    content: 'Pending ';
  }
`

const ActionIcon = styled(Icon)`
  color: ${({ theme: { palette } }) => palette.blue[500]};
`

const tableData = ({ getProfileUrl, data }) => ({
  displaySettings: {
    showCounter: true,
    columnsSettings: headers.map(header => ({
      width: header.id === 'actions' ? 100 : 200 + 100 / 3,
      header: {
        content: <div>{header.name}</div>,
      },
      cell: {
        $type: ['name', 'actions', 'sharings', 'type'].indexOf(header.id) > -1 ? 'custom' : 'text',
        field: header.id,
        // eslint-disable-next-line consistent-return
        renderer: field => {
          if (!field) return null

          const { id, type = {} } = field
          const { name, image } = type

          if (header.id === 'name') {
            return <Link href={getProfileUrl(id, type.id)}>{field.name || id}</Link>
          }

          if (header.id === 'actions') {
            return (
              <Link href={getProfileUrl(id, type.id)}>
                <ActionIcon cursor="pointer" type="pencil" />
              </Link>
            )
          }

          if (header.id === 'type') {
            return (
              <React.Fragment>
                {image && (
                  <Box mr="S">
                    <GenericIcon width={110} height={40} svgId={image.value} />
                  </Box>
                )}
                <Text>{name}</Text>
              </React.Fragment>
            )
          }

          if (header.id === 'sharings') {
            const { shared, pending } = mapSharings(field)

            return (
              <React.Fragment>
                <SharedText>{`(${shared})`}</SharedText>
                <PendingText>{`(${pending})`}</PendingText>
              </React.Fragment>
            )
          }
        },
      },
    })),
  },
  height: data.length * gridTheme.rowHeight,
  data,
})

type ProfilesTableProps = {
  purposeName: string | null,
  profiles?: Array<ProfileType>,
  getProfileUrl: (profileId: string, profileTypeId: string) => string,
}

export const ProfilesTable = ({ profiles, purposeName, getProfileUrl }: ProfilesTableProps) => {
  if (!profiles || profiles.length === 0) return null

  const profilesData = tableData({
    getProfileUrl,
    data: profiles,
  })

  return (
    <Flex column mt="XL">
      <Box mb="M">
        <Text weight="bold" type="paragraph">
          Company {purposeName && purposeName.toLowerCase()} profiles
        </Text>
      </Box>
      <Box data-test-id="profiles-table-container" maxWidth={840}>
        {/* $FlowFixMe[incompatible-type] */}
        <Table {...profilesData} />
      </Box>
    </Flex>
  )
}
