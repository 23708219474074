import * as React from 'react'
import { compose, withHandlers } from 'recompose'
import { allPass } from 'ramda'
import withValidation from 'revalidation'

import { Flex, Box, Button } from '@r1/ui-kit'
import { ContentHeader } from '@r1/wireframe-primary'

import { extractNameAndValue } from '../utils'

const enhance = compose(
  withValidation,
  withHandlers({
    onButtonClick:
      ({ revalidation, onSubmit }) =>
      (value, actionData) => {
        const { onChange, updateErrors } = revalidation
        onChange('action', value, null, ({ form }) =>
          onSubmit({ updateErrors, actionData })({ form }),
        )
      },
    onChange:
      ({ revalidation: { onChange, UPDATE_FIELD }, runtimeInputConstraint }) =>
      (_, event) => {
        const { name, value } = extractNameAndValue(event)

        const constraints = runtimeInputConstraint[name]

        if (constraints && !allPass(constraints)(value)) return

        onChange(name, value, [UPDATE_FIELD])
      },
  }),
)

const Form = ({
  httpClient,
  wizardData,
  fields,
  title,
  button,
  Screen,
  isLoading,
  onButtonClick,
  onChange,
  wizardId,
  entityId,
  revalidation: { form, errors = {}, onSubmit: validate },
}) => (
  <>
    {title && <ContentHeader.Title>{`${title} profile`}</ContentHeader.Title>}
    <form name="wizard" onSubmit={event => event.preventDefault()}>
      <Flex column spaceBetween="M">
        {wizardData && (
          <Screen
            {...wizardData}
            httpClient={httpClient}
            wizardId={wizardId}
            fields={fields}
            fieldsData={form}
            errors={errors}
            isLoading={isLoading}
            isNewEntity={Boolean(entityId)}
            onFieldChange={onChange}
            onButtonClick={onButtonClick}
          />
        )}
        <Flex spaceBetween="XS">
          <Box>
            {button.back.visible && (
              <Button disabled={isLoading} onClick={() => onButtonClick('back')}>
                {button.back.title}
              </Button>
            )}
          </Box>
          {button.next.map(btn => (
            <Box key={btn.action + btn.title}>
              <Button
                disabled={isLoading}
                onClick={() =>
                  validate(({ valid }) => valid && onButtonClick(btn.action, btn.actionData))
                }
              >
                {btn.title}
              </Button>
            </Box>
          ))}
        </Flex>
      </Flex>
    </form>
  </>
)

export default enhance(Form)
