// @flow

import * as React from 'react'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import {
  Flex,
  Format,
  Button,
  Icon,
  Tooltip,
  Text,
  Box,
  FormField,
  useConfigurationProvider,
} from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'
import { useState, useCallback, useMemo } from 'react'
import type { ExpectedDecision, Facility } from '@r1-webui/rmavendorportal-v1/src/types'
import type { DecisionTypeData } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type {
  ClaimInfo,
  CustomerInfo,
  MarketplaceInfo,
  OrderInfo,
  DecisionItem,
} from '../../types/common'
import { ClaimFacilityModal } from '../ClaimFacilityModal'
import { ExpectedDecisionTypeModal } from '../ExpectedDecisionTypeModal'

import {
  Wrapper,
  ClaimAmountValue,
  ClaimAmountLabel,
  ClaimReviseLabel,
  ClaimInfoBlockTitle,
  StatusIndicator,
  DecisionStatusIndicator,
} from './StyledComponents'
import { OrderLink } from './OrderLink'
import { RestartProduceLabel } from './DecisionItemActions/RestartProduceLabel'
import { CompleteRefund } from './DecisionItemActions/CompleteRefund'

type Props = {|
  claim: ClaimInfo,
  order: OrderInfo,
  marketplace: MarketplaceInfo,
  customer: CustomerInfo,
  facilities: Facility[],
  expectedDecisionTypes: ExpectedDecision[],
  restartDecisionItem: (claimId: string, decisionItemId: string) => Promise<void>,
  completeDecisionItem: (claimdId: string, decisionItemId: string) => Promise<void>,
  setExpectedDecisionTypeId: (
    decisionTypeId: Lib$Id,
    decisionData: DecisionTypeData[],
  ) => Promise<void>,
|}

export const ClaimCard = ({
  claim,
  marketplace,
  customer,
  order,
  facilities,
  expectedDecisionTypes,
  restartDecisionItem,
  completeDecisionItem,
  setExpectedDecisionTypeId,
}: Props) => {
  const [showClaimFacilityModal, setShowClaimFacilityModal] = useState(false)
  const [customFacilityId, setCustomFacilityId] = useState(claim.customRmaFacilityId)

  const closeClaimFacilityModal = useCallback(
    () => setShowClaimFacilityModal(false),
    [setShowClaimFacilityModal],
  )

  const openClaimFacilityModal = useCallback(
    () => setShowClaimFacilityModal(true),
    [setShowClaimFacilityModal],
  )

  const resetCustomFacilityId = useCallback(id => setCustomFacilityId(id), [setCustomFacilityId])

  const isActiveClaim = useMemo(() => {
    return !['Closed', 'Canceled'].includes(claim.status)
  }, [claim])

  const isRestartProduceLabelVisible = (decisionItem: DecisionItem): boolean => {
    return decisionItem.name === 'Producing label'
  }

  const isCompleteRefundVisible = (decisionItem: DecisionItem): boolean => {
    return (
      (decisionItem.name === 'Full refund' ||
        decisionItem.name === 'Partial refund' ||
        decisionItem.name === 'Sending accessories') &&
      (decisionItem.processingPolicy === 'SemiAutomatic' ||
        decisionItem.processingPolicy === 'Manual') &&
      !decisionItem.managerActionResult
    )
  }

  const getRmaFacilityTitle = (facilityId: string): string => {
    const facility = facilities.find(f => f.id === facilityId)
    if (facility == null) {
      return facilityId
    }
    return facility.title
  }

  const { externalIssueInfo, feeAmount } = claim

  const { palette } = useConfigurationProvider()

  return (
    <Wrapper column>
      <Flex align="flex-start" justify="space-between" mb="M">
        <Flex>
          {marketplace.svgId ? (
            <GenericIcon height={42} width={150} svgId={marketplace.svgId} />
          ) : null}
        </Flex>
        <Flex column align="flex-end">
          <ClaimAmountValue>
            <Format.Money value={claim.totalAmount} />
          </ClaimAmountValue>
          <ClaimAmountLabel>Claim Total Amount</ClaimAmountLabel>
          <ClaimReviseLabel>{claim.refundReviseState}</ClaimReviseLabel>
        </Flex>
      </Flex>

      <Flex column mb="M" spaceBetween="XXS">
        <ClaimInfoBlockTitle mb="XXS">Claim information</ClaimInfoBlockTitle>

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Claim Status:</FormField.Label>
          <Flex align="center">
            <StatusIndicator status={claim.status} />
            <Text data-test-id="claim-status">{claim.status}</Text>
          </Flex>
        </Flex>

        {claim.status === 'Resolved' && (
          <Flex justify="space-between" mt="S" align="center">
            <FormField.Label>Refunded Amount:</FormField.Label>
            <Format.Money data-test-id="total-amount" value={claim.totalAmount} />
          </Flex>
        )}

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Claim Source:</FormField.Label>
          <Text data-test-id="source">{claim.source}</Text>
        </Flex>

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Requested Decision:</FormField.Label>
          {claim.requestedDecision ? (
            <Text data-test-id="requested-decision">{claim.requestedDecision}</Text>
          ) : (
            <ExpectedDecisionTypeModal
              claim={claim}
              order={order}
              availableDecisionTypes={expectedDecisionTypes}
              onDecisionTypeSelection={setExpectedDecisionTypeId}
            />
          )}
        </Flex>

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Claim Created:</FormField.Label>
          <Format.Date data-test-id="claim-created">{claim.createdOn}</Format.Date>
        </Flex>

        <Flex justify="space-between" align="center" mt="S">
          <FormField.Label>Claim Created By:</FormField.Label>
          <Text data-test-id="claim-created-by">{claim.createdByUserFullName || ''}</Text>
        </Flex>

        {marketplace.name === 'Backmarket' && externalIssueInfo && feeAmount && (
          <>
            <Flex mt="S" justify="space-between">
              <FormField.Label>TypeForm sent:</FormField.Label>
              <Flex>
                <Format.Date data-test-id="filled-on">
                  {externalIssueInfo.formFilledOn || ''}
                </Format.Date>
                &nbsp;
                <Format.Money data-test-id="fee-amount" value={feeAmount} />
              </Flex>
            </Flex>
            <Flex mt="S" justify="space-between">
              <FormField.Label>TypeForm status:</FormField.Label>
              <Text data-test-id="fill-status">{externalIssueInfo.formFillStatus || ''}</Text>
            </Flex>
          </>
        )}
        <FormField>
          <FormField.Label>
            <Text color={palette.grey[900]}>Claim Description:</Text>
          </FormField.Label>
          <Text data-test-id="claim-description">{claim.description}</Text>
        </FormField>
      </Flex>

      <Flex column mb="M" spaceBetween="S">
        <ClaimInfoBlockTitle mb="XXS">Claim Steps</ClaimInfoBlockTitle>

        {claim.decisionItems.map(decisionItem => {
          return (
            <Flex key={decisionItem.id} justify="space-between" align="center">
              <Text>{decisionItem.name}</Text>
              <Flex align="center" justify="space-around" spaceBetween="XS">
                <DecisionStatusIndicator status={decisionItem.status} />
                <Text>{decisionItem.status}</Text>
                {isActiveClaim && (
                  <>
                    {isRestartProduceLabelVisible(decisionItem) && (
                      <RestartProduceLabel
                        claimId={claim.id}
                        decisionItem={decisionItem}
                        restartDecisionItem={restartDecisionItem}
                      />
                    )}
                    {isCompleteRefundVisible(decisionItem) && (
                      <CompleteRefund
                        claimId={claim.id}
                        decisionItem={decisionItem}
                        completeDecisionItem={completeDecisionItem}
                      />
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          )
        })}
      </Flex>

      <Flex column mb="M" spaceBetween="S">
        <ClaimInfoBlockTitle mb="XXS">Customer information</ClaimInfoBlockTitle>

        <Flex justify="space-between" align="center">
          <Text>Customer:</Text>
          <Text>{customer.fullName}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Phone Number:</Text>
          <Text>{customer.phone}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Contact E-mail:</Text>
          <Text>{customer.email}</Text>
        </Flex>

        <Flex column spaceBetween="XS">
          <Text>Shipping Address:</Text>
          <Text>{customer.shippingAddress}</Text>
        </Flex>
      </Flex>

      <Flex column mb="M" spaceBetween="S">
        <ClaimInfoBlockTitle mb="XXS">Order information</ClaimInfoBlockTitle>

        <Flex justify="space-between" align="center">
          <Text>Marketplace Order ID:</Text>
          <Text>{order.marketplaceOrderId || ''}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Purchase Order ID:</Text>
          <Text>{order.purchaseOrderId}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Order ID:</Text>
          <Flex>
            <OrderLink
              legacyOrderId={order.legacyOrderId}
              legacyCompanyOrderId={order.legacyCompanyOrderId}
            />
          </Flex>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Order Created:</Text>
          <Text>
            <Format.Date>{order.createdOn}</Format.Date>
          </Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Order Total:</Text>
          <Box>
            <Text>
              <Format.Money value={order.totalAmount} />
            </Text>
          </Box>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Marketplace Profile:</Text>
          <Text>{marketplace.profile}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Marketplace Name:</Text>
          <Text>{marketplace.name}</Text>
        </Flex>
      </Flex>

      <Flex column mb="M" spaceBetween="S">
        <ClaimInfoBlockTitle mb="XXS">Facility information</ClaimInfoBlockTitle>

        <Flex justify="space-between" align="center">
          <Text>Default Facility:</Text>
          <Text>{getRmaFacilityTitle(claim.rmaFacilityId || '')}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Default Facility Origin:</Text>
          <Text>{claim.rmaFacilitySourceType || ''}</Text>
        </Flex>

        <Flex justify="space-between" align="center">
          <Text>Custom Facility:</Text>
          <Flex align="center" justify="space-around" spaceBetween="XS">
            <Text>{getRmaFacilityTitle(customFacilityId || '')}</Text>
            {!['Closed', 'Canceled'].includes(claim.status) && (
              <Tooltip text="Edit Facility">
                <Button
                  transparent
                  size="S"
                  shape="square"
                  color="secondary"
                  onClick={openClaimFacilityModal}
                >
                  <Icon size="XS" type="pencil" />
                </Button>
              </Tooltip>
            )}
          </Flex>

          <ClaimFacilityModal
            claimId={claim.id}
            title="Custom Return Facility"
            show={showClaimFacilityModal}
            customFacilityId={customFacilityId}
            setCustomFacilityId={resetCustomFacilityId}
            facilities={facilities}
            onClose={closeClaimFacilityModal}
          />
        </Flex>
      </Flex>
    </Wrapper>
  )
}
