// @flow

import * as React from 'react'
import { Flex, FormField, HR } from '@r1/ui-kit'
import { TagViewList } from '../../../AttributeDefinitions/AttributeDefinitionView/AttributeDefinitionView'
import type { EnumerationConstraint as EnumerationConstraintValue } from '../types'

type Props = {|
  value: EnumerationConstraintValue,
  enumValues: $ReadOnlyArray<{| +id: string, +value: string | number |}>,
|}

export const EnumerationConstraint = ({ value, enumValues }: Props) => {
  return (
    <Flex column spaceBetween="S">
      <HR />
      <Flex spaceBetween="XXS">
        <FormField.Label>Allowed The Following</FormField.Label>
        <TagViewList
          list={enumValues
            .filter(({ id }) => value.allowedVariants.includes(id))
            .reduce((acc, val) => [...acc, val.value.toString()], [])}
        />
      </Flex>
    </Flex>
  )
}
