// @flow

import { handleServerError } from '@r1/core-blocks'
import { usersApi } from '../../api/userManagement'
import { r1UserAccountApi } from '../../api/r1UserAccount/userAccount'

export const getUserAccount = async () => {
  const userAccount = await usersApi.getCurrentUser().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return undefined
    }
    return response.body
  })

  const legacyUserAccount = await r1UserAccountApi.getAccount().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return undefined
    }
    return response.body
  })

  if (!userAccount || !legacyUserAccount) {
    return undefined
  }

  return {
    id: userAccount.id,
    login: userAccount.authInfo.login,
    fullName: userAccount.info.fullName,
    email: userAccount.info.contactInfo.email,
  }
}
