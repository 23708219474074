// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, FormField, H3, Input } from '@r1/ui-kit'

import type { BasicInfoFormProps } from '../../../../types/variation.type'

import { testIds } from '../testIds'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

export const BasicInfo = (props: BasicInfoFormProps) => {
  const { values, getError, handleChange, disabled } = props

  return (
    <>
      <Box>
        <StyledH3>General Information</StyledH3>
      </Box>
      <Box>
        <Flex column minWidth={890} maxWidth={890}>
          <FormField disabled={disabled} error={!!getError('Name')}>
            <FormField.Label>Variation Name *</FormField.Label>
            <Input
              data-test-id={testIds.nameInput}
              placeholder="Enter Variation Name"
              value={values.Name}
              onChange={handleChange('Name')}
            />
            <FormField.Error>{getError('Name')}</FormField.Error>
          </FormField>

          {/* <FormField
            vertical
            label="Variation Description *"
            error={getError('Description')}
            meta={{
              max: 1000,
              current: charDescriptionCount,
            }}
          >
            <Editor
              data-test-id={testIds.description}
              placeholder="Enter Variation Description"
              error={!!getError('Description')}
              value={String(values.Description || '')}
              disabled={disabled}
              onChange={(value, count) => {
                setShortDescriptionCount(count)
                handleChange('Description', value || '')
              }}
              onBlur={handleBlur('Description')}
            />
          </FormField> */}
        </Flex>
      </Box>
    </>
  )
}
