import React from 'react'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { Flex, Link, Text, Tooltip } from '@r1/ui-kit'

type OfferUnitsLinkProps = {
  offer: Offer
}

export const OfferUnitsLink = React.memo<OfferUnitsLinkProps>(({ offer }) => {
  const TooltipText = offer.statistic.stocks.length ? (
    <Flex column>
      {offer.statistic.stocks.map(({ facilityName, quantity }) => (
        <Text key={facilityName}>
          {facilityName} : {quantity.value}
        </Text>
      ))}
      <Text weight="medium">Units requested in offer: {offer.quantity.value}</Text>
    </Flex>
  ) : (
    <Text weight="medium">Out of stocks</Text>
  )

  return (
    <Tooltip text={TooltipText}>
      <Link target="_blank">{offer.quantity.value}</Link>
    </Tooltip>
  )
})
