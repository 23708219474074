import * as React from 'react'
import { handleServerError } from '@r1/core-blocks'
import { MasterProgramRuleHistoryEvent } from '@r1-webui/masterprograms-v1'
import { masterProgramRulesApi } from '../api/masterProgramRulesApi'

export const useMasterProgramHistory = ({
  masterProgramId,
  onError,
}: {
  masterProgramId?: string | null
  onError?: () => void
}) => {
  const [masterProgramHistory, setMasterProgramHistory] = React.useState<
    MasterProgramRuleHistoryEvent[]
  >([])
  const [loading, setLoading] = React.useState<boolean>(false)

  const getMasterProgramHistory = React.useCallback(
    async (ruleId: string) => {
      setLoading(true)
      const handleError = typeof onError === 'function' ? onError : () => {}
      const response = await masterProgramRulesApi.getMasterProgramRuleHistory({ ruleId })
      setLoading(false)

      if (response.status !== 200) {
        handleServerError(response)
        handleError()
        return
      }
      setMasterProgramHistory(response.body)
    },
    [onError],
  )

  React.useEffect(() => {
    if (masterProgramId) {
      getMasterProgramHistory(masterProgramId)
    } else {
      setMasterProgramHistory([])
    }
  }, [getMasterProgramHistory, masterProgramId])

  return { masterProgramHistory, loading }
}
