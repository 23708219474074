const consignedOwnedOptions = ['Owned', 'Consigned', 'Multiple', 'N/A']

const yesNoOptions = ['Yes', 'No']

const yesNoWithNA = [...yesNoOptions, 'N/A']

const unitsPalletsOptions = ['Units', 'Pallets', 'N/A']

const mapToSelectOptions = (options: string[]) =>
  options.map(option => ({ id: option, value: option }))

export const getInfoFieldsOptions = (fieldId: string) => {
  switch (fieldId) {
    case 'consigned':
      return mapToSelectOptions(consignedOwnedOptions)
    case 'discontinued':
      return mapToSelectOptions(yesNoOptions)
    case 'directShip':
    case 'serviceOnly':
    case 'supplyChain':
    case 'serviceSupplyChain':
    case 'reCommerce':
    case 'serviceReCommerce':
    case 'checkIn':
    case 'testing':
    case 'gca':
    case 'packaging':
    case 'listableLocation':
      return mapToSelectOptions(yesNoWithNA)
    case 'programQtyTypeSupplyChain':
    case 'programQtyTypeReCommerce':
      return mapToSelectOptions(unitsPalletsOptions)
    default:
      return []
  }
}
