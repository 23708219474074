import { createRulesApi } from '@r1-webui/shipping-returnshippingrules-v1'

import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/shipping-rule-processor`

export const rmaShippingRulesApi = createRulesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}`,
})
