import React, { useMemo } from 'react'

import { Chart } from 'react-chartjs-2'
import { Box, Card, Flex, H2, Icon } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { QuaterCardWrapper } from '../styles'
import { PieData } from '../types'
import { getPieChartSettings } from './settings'

const Container = styled(Flex)`
  height: 100%;
`

type PieChartProps = {
  title?: React.ReactNode
  data: PieData[]
  loading: boolean
}

export const PieChart = ({ data, loading, title }: PieChartProps) => {
  const settings = useMemo(() => {
    return getPieChartSettings(data)
  }, [data])
  return (
    <QuaterCardWrapper column>
      <Card>
        <Container column mr="XS" ml="XS" justify="center">
          <Flex justify="space-between" align="center">
            <Flex align="center">
              {title && <H2>{title}</H2>}
              {loading && (
                <Box ml="S">
                  <Icon type="loaders/circle" />
                </Box>
              )}
            </Flex>
          </Flex>
          <Flex>{!loading && <Chart {...settings} type="pie" />}</Flex>
        </Container>
      </Card>
    </QuaterCardWrapper>
  )
}
