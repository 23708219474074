// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { withTheme, Link, Tooltip, Icon } from '@r1/ui-kit'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Table } from '@r1/grid'

import * as actions from './module'

const TableWrapper = styled('div')`
  width: 420px;
`

const ActionIcon = styled(Icon)`
  color: ${({ theme }) => theme.primaryColor[600]};
`

const getColumnsSettings = onClick => [
  {
    width: 280,
    header: {
      content: <div>Marketplaces</div>,
    },
    cell: {
      $type: 'custom',
      renderer: ({ id, name }) => (
        <Link href={`/r1/admin/metadata/editConditionMappings/${id}`}>{name}</Link>
      ),
    },
  },
  {
    width: 100,
    header: {
      content: <div>Actions</div>,
    },
    cell: {
      $type: 'custom',
      renderer: ({ id }) => (
        <Tooltip text="Edit">
          <ActionIcon type="pencil" size="S" cursor="pointer" onClick={() => onClick(id)} />
        </Tooltip>
      ),
    },
  },
]

const getTableProps = onClick => ({
  displaySettings: {
    showCounter: true,
    columnsSettings: getColumnsSettings(onClick),
  },
})

const mapStateToProps = ({ conditionMappingProfiles }) => ({
  profiles: conditionMappingProfiles.profiles,
})

const enhance = compose(
  connect(mapStateToProps, actions),
  withTheme,
  mapProps(({ profiles, ...props }) => ({
    profiles,
    ...props,
  })),
)

const ConditionMappingsComponent = ({ profiles, loadProfiles }) => {
  const navigate = useNavigate()

  const redirectToEditPage = profileId => {
    navigate(`/r1/admin/metadata/editConditionMappings/${profileId}`)
  }

  React.useEffect(() => {
    loadProfiles()
  }, [loadProfiles])

  const tableProps = getTableProps(id => redirectToEditPage(id))

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSalesFlowRulesView]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Title>Condition Mappings</ContentHeader.Title>
        </ContentHeader>
        <Content>
          <TableWrapper>
            {tableProps && <Table {...tableProps} suppressVirtualization data={profiles} />}
          </TableWrapper>
        </Content>
      </Main>
    </PageAccessControl>
  )
}

export const ConditionMappings = enhance(ConditionMappingsComponent)
