// @flow

import { createProductImagesApi } from '@r1-webui/productmanagement-v1'
import { handleServerError } from '@r1/core-blocks'
import { httpClient } from '../../../httpClient'

const API_PREFIX = `/uisvc/product-management`

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const productImagesApiClient = createProductImagesApi({ http: httpClient, urlPrefix: API_PREFIX })

// eslint-disable-next-line import/no-unused-modules
export const productImagesApi = {
  getImages: async (params: $AnyObject) => {
    const res = await productImagesApiClient.getImages(params)
    if (res.status !== 200) {
      handleServerError(res.body)
      return []
    }
    return res.body
  },
  add: async (params: $AnyObject, body: $AnyObject) => {
    const res = await productImagesApiClient.add(params, body)
    if (res.status !== 200) {
      handleServerError(res.body)
    }
  },
  delete: async (params: $AnyObject) => {
    const res = await productImagesApiClient.delete(params)
    if (res.status !== 200) {
      handleServerError(res.body)
    }
  },
}
