/* eslint-disable flowtype/no-types-missing-file-annotation */

export const MARKETPLACE_PURPOSE_ID = 'B2CSellingVenueProfile'

export const PurchaseOrderComparator = (value1, value2) =>
  value1.purchaseOrderId === value2.purchaseOrderId &&
  value1.venueProfileId === value2.venueProfileId

export const filterNullable = filterObj =>
  Object(filterObj) !== filterObj
    ? filterObj
    : Object.keys(filterObj).reduce(
        (acc, key) =>
          filterObj[key] == null || (Array.isArray(filterObj[key]) && filterObj[key].length === 0)
            ? acc
            : {
                ...acc,
                [key]: filterObj[key],
              },
        {},
      )

export const sortSubdivisions = (sub1, sub2) => {
  if (sub1 == null || sub2 == null) return 0
  const countryCompare = String(sub1.countryCode).localeCompare(sub2.countryCode, undefined, {
    sensitivity: 'base',
  })
  if (countryCompare === 0) {
    return String(sub1.countrySubdivisionCode).localeCompare(sub2.countrySubdivisionCode)
  }
  return countryCompare
}

export const AUTOSUBMITTED_PROFILES_OPTION_ID = 'AUTOSUBMITTED_PROFILES_OPTION_ID'
