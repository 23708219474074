import { Action } from '../constants'

const initialState = []

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case Action.LOAD_PAGE_SETTINGS:
      return action.pageSettings

    default:
      return state
  }
}
