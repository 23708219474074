// @flow

import {
  createManufacturersApi,
  createModelsApi,
  baseUri,
} from '@r1-webui/productcatalog-manufacturermanagement-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const manufacturerManagementApi = (httpClient: any): WebUiApi => {
  const { createManufacturer, deleteManufacturer } = createManufacturersApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${baseUri}`,
  })

  const { createModel, deleteModel } = createModelsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${baseUri}`,
  })

  return {
    createManufacturer,
    deleteManufacturer,
    createModel,
    deleteModel,
  }
}
