// @flow

import * as React from 'react'
import { useMemo, memo } from 'react'
import { variationsApi, imageApi, attributesApi } from '../../api'
import { variationsService, imageService, attributesService } from '../../services'
import { ServiceContext } from './provider'

type VariationContainerProps = {
  children?: React.Node,
  httpClient: any,
}

export const VariationContainer = memo<VariationContainerProps>(props => {
  const { httpClient, children } = props

  const apiMemo = useMemo(
    () => ({
      variationsService: variationsService(variationsApi(httpClient)),
      imageService: imageService(imageApi(httpClient)),
      attributesService: attributesService(attributesApi(httpClient)),
      httpClient,
    }),
    [httpClient],
  )

  return <ServiceContext.Provider value={apiMemo}>{children}</ServiceContext.Provider>
})
