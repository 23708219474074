// @flow

import React, { useContext, useState, useCallback, useEffect } from 'react'
import { Box, Flex, H3, Placeholder } from '@r1/ui-kit'

import { ServiceContext } from '../../../../provider'
import type { SettingsFormProps } from '../../../../types/variation.type'
import { ProductIdentifierValuesFilter } from './children/Filter/ProductIdentifierValuesFilter'
import { AttributesFilter } from './children/Filter/AttributesFilter'
import { ProductsGrid } from './children/ProductsGrid'
import { ColoredText } from './ColoredText'

const FilterStyle = {
  backgroundColor: '#EDEEF0',
  marginBottom: '16px',
}

export const Settings = (props: SettingsFormProps) => {
  const { values, variationId, ...nextprops } = props

  const [attributesLoading, setAttributesLoading] = useState(true)
  const [attributeDefinitions, setAttributeDefinitions] = useState([])
  const {
    attributesService: { findAllAttributeDefinitions },
  } = useContext(ServiceContext)

  const fetchData = useCallback(async () => {
    try {
      const attributes = await findAllAttributeDefinitions()

      const textListAttributes = attributes.filter(({ valueType }) =>
        ['StringEnumeration', 'DecimalEnumeration', 'IntEnumeration'].includes(valueType),
      )

      setAttributeDefinitions(textListAttributes)
    } catch (error) {
      setAttributeDefinitions([])
    }
  }, [findAllAttributeDefinitions])

  useEffect(() => {
    setAttributesLoading(true)
    fetchData().then(() => {
      setAttributesLoading(false)
    })
  }, [fetchData])

  return (
    <>
      <Box>
        <ColoredText>
          <H3>Variation Settings</H3>
        </ColoredText>
      </Box>
      {attributesLoading ? (
        <Placeholder type="form" />
      ) : (
        <>
          <div style={FilterStyle}>
            <Flex column pt="L" pb="L" pr="XL" pl="XL">
              <ProductIdentifierValuesFilter
                {...nextprops}
                values={values.ProductsFilter}
                path={['ProductsFilter', 'productIdentifierValues']}
              />
              <AttributesFilter
                {...nextprops}
                attributeDefinitions={attributeDefinitions}
                values={values.Variations.attributeDefinitionIds}
                path={['Variations', 'attributeDefinitionIds']}
              />
            </Flex>
          </div>
          <ProductsGrid
            variationId={variationId}
            productIdentifierValues={values.ProductsFilter.productIdentifierValues}
            attributeDefinitionIds={values.Variations.attributeDefinitionIds}
            attributeDefinitions={attributeDefinitions}
          />
        </>
      )}
    </>
  )
}
