import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

export const QuaterCardWrapper = styled(Flex)`
  flex-shrink: 1;
  flex-grow: 1;
  margin-right: 1%;
  width: 25%;

  &:last-child {
    margin-right: 0;
  }

  & > div {
    border: none;
    min-width: 140px;
    width: 100%;
    padding: 0 16px 13px 16px;
    box-shadow: none;
  }
`
