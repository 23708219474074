import React, { useCallback, useState } from 'react'
import { assocPath } from 'ramda'
import {
  Modal,
  Box,
  Flex,
  FormField,
  RadioButtonGroup,
  DatePicker,
  Button,
  Textarea,
  Icon,
  Select,
} from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { add, format, getDay, getHours, getMinutes, isSameDay, parse } from 'date-fns'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { createReservation } from '../../../../services/reservationService'
import { Period } from '../../types'
import { useExceptionDates } from '../../hooks/useExceptionDates'
import { utcToLocal } from '../../../../../../utils/dates/utcToLocal'
import { mergeDateWithTime } from '../../../../../../utils/dates/mergeDateWithTime'
import { ISOFormat } from '../../../../../../utils/dates/formats'

export type ReservationEvent = {
  period: Period
  pickupDate: string
  notes: string | null
  id: string
}

const intervals = ['30min', '1hour'] as const

type Interval = (typeof intervals)[number]

type ReservationState = {
  period: {
    start: string | null
    end: string | null
  }
  interval: Interval
  pickupDate: string | null
  notes: string
}

type ModalProps = {
  warehouseLocationId: string | undefined
  warehouseWorkingHours: Record<number, Period>
  warehouseExceptionDates: string[]
  onAdd: (reservation: ReservationEvent) => void
}

const createDefaultReservation = (): ReservationState => {
  return {
    period: {
      start: '12:00 PM',
      end: '12:30 PM',
    },
    interval: '30min',
    pickupDate: null,
    notes: '',
  }
}

const generateTimeIntervals = (warehouseWorkingHours: Period): string[] => {
  const result = []

  const startDate = utcToLocal(warehouseWorkingHours.start)
  const endDate = utcToLocal(warehouseWorkingHours.end)

  const startHour = getHours(startDate)
  const startMinutes = getMinutes(startDate)

  result.push(format(parse(`${startHour}:${startMinutes}`, 'HH:mm', new Date()), 'h:mm a'))

  let currentHour = startHour + 1

  while (currentHour <= getHours(endDate) - 1) {
    result.push(format(parse(String(currentHour), 'HH', new Date()), 'h:mm a'))
    result.push(format(parse(`${currentHour}:30`, 'HH:mm', new Date()), 'h:mm a'))
    currentHour += 1
  }

  return result
}

export const CreateReservationModal = (props: ModalProps) => {
  const [reservation, setReservation] = useState<ReservationState>(createDefaultReservation())
  const [show, setShow] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const [{ allowPickupScheduleEdit }] = useAccessControl()

  const exceptionDates = useExceptionDates(props.warehouseExceptionDates)

  const updateReservation = (path: string[]) => (value: string | Date | null) => {
    setReservation(prev => {
      return {
        ...assocPath(path, value, prev),
      }
    })
  }

  const onModalClose = useCallback(() => {
    setReservation(createDefaultReservation())
    setShow(false)
  }, [])

  const intervalOptions = React.useMemo(() => {
    if (reservation.pickupDate) {
      const date = new Date(reservation.pickupDate)

      if (exceptionDates.some(exceptionDate => isSameDay(exceptionDate, date))) return []

      const day = getDay(date)
      const period = props.warehouseWorkingHours[day]
      const intervalTimes = (period && generateTimeIntervals(period)) || []

      return intervalTimes.map(x => ({ id: x, name: x }))
    }
    return []
  }, [exceptionDates, props.warehouseWorkingHours, reservation.pickupDate])

  const modalActionButtons: ControlledActionButtons = [
    {
      title: 'Close',
      onClick: onModalClose,
      align: 'left',
      transparent: true,
    },
    {
      title: 'Save',
      onClick: async () => {
        if (reservation.pickupDate && props.warehouseLocationId) {
          setLoading(true)
          const start = format(
            mergeDateWithTime(reservation.pickupDate, reservation.period.start),
            ISOFormat,
          )
          const end = format(
            mergeDateWithTime(reservation.pickupDate, reservation.period.end),
            ISOFormat,
          )
          const body = {
            notes: reservation.notes,
            pickupDate: reservation.pickupDate,
            period: { start, end },
          }

          const id = await createReservation(props.warehouseLocationId, body)
          if (id) {
            const reserve = { ...body, id }
            props.onAdd(reserve)
          }
          setLoading(false)
        }
        onModalClose()
      },
      align: 'right',
      disabled: !(reservation.pickupDate && intervalOptions.length > 0),
      loading,
    },
  ]

  const onPeriodStartChange = (value: string) => {
    let end = mergeDateWithTime(reservation.pickupDate, value)

    switch (reservation.interval) {
      case '1hour':
        end = add(end, { hours: 1 })
        break
      default:
        end = add(end, { minutes: 30 })
    }

    updateReservation(['period', 'start'])(value)
    updateReservation(['period', 'end'])(format(end, 'h:mm a'))
  }

  const onIntervalChange = (value: Interval) => {
    let end = mergeDateWithTime(reservation.pickupDate, reservation.period.start)

    switch (value) {
      case '1hour':
        end = add(end, { hours: 1 })
        break
      default:
        end = add(end, { minutes: 30 })
    }
    updateReservation(['period', 'end'])(format(end, 'h:mm a'))
    updateReservation(['interval'])(value)
  }

  return (
    <>
      <Button
        disabled={!allowPickupScheduleEdit || !props.warehouseLocationId}
        data-test-id="shipping__schedule-pickup__create-reservation-button"
        onClick={() => setShow(true)}
      >
        <Icon type="plus" />
        Reserve
      </Button>
      <Modal
        isControlled
        show={show}
        size="M"
        title="Create reservation"
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onModalClose}
      >
        <Flex column>
          <FormField>
            <FormField.Label>Pickup Date</FormField.Label>
            <DatePicker
              value={reservation.pickupDate}
              data-test-id="shipping__create-reserve-modal__pickup-date-date-picker"
              onChange={updateReservation(['pickupDate'])}
              onRemove={() => updateReservation(['pickupDate'])(null)}
            />
          </FormField>
          <FormField>
            <FormField.Label>Pickup Period</FormField.Label>
            <Flex column spaceBetween="M">
              <Box>
                <Select
                  value={reservation.period.start}
                  options={intervalOptions}
                  data-test-id="shipping__create-reserve-modal__pickup-period-select"
                  onChange={onPeriodStartChange}
                />
              </Box>
              <Box>
                <RadioButtonGroup
                  options={[
                    { id: '30min', name: '30 min' },
                    { id: '1hour', name: '1 hour' },
                  ]}
                  value={reservation.interval}
                  data-test-id="shipping__create-reserve-modal__reservation-interval-radio-button-group"
                  onChange={onIntervalChange}
                />
              </Box>
            </Flex>
          </FormField>
          <FormField>
            <FormField.Label>Notes</FormField.Label>
            <Textarea
              value={reservation.notes}
              data-test-id="shipping__create-reserve-modal__notes-textarea"
              onChange={updateReservation(['notes'])}
            />
          </FormField>
        </Flex>
      </Modal>
    </>
  )
}
