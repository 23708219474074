// @flow

import { useCallback, useState } from 'react'

export const useToggle = <S: boolean>(
  initialState: S,
): ({| state: S, toggle: (val: ?S) => void |}) => {
  const [state, setState] = useState(initialState)

  const toggle = useCallback(newState => {
    setState(prevState => {
      if (typeof newState === 'boolean' && newState !== prevState) {
        return newState
      }
      return !prevState
    })
  }, [])
  return { state, toggle }
}
