import { all } from 'ramda'
import { NotificationSystem } from '@r1/ui-kit'

export const handleError = error => {
  let response
  if (typeof error === 'object' && error !== null) {
    response = error.response || {}
  } else {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: 'Unhandled error',
    })
    return
  }

  let message = ''
  if (response.status === 400) {
    const { badProperties = [] } = response.data || response
    if (badProperties.length) {
      response.badProperties.forEach(errObj => {
        message += `${errObj.name}: ${errObj.message}\n`
      })
    } else if (response.data && response.data.innerException) {
      message = response.data.innerException.message
    }
  } else if (response.data && response.data.message) {
    message = response.data.message || response.message || response.Message
  }

  NotificationSystem.addNotification({
    level: 'error',
    title: 'Error',
    message: message || `Unhandled error (status ${response.status})`,
  })
}

function normalizeArray(arr, keyField) {
  const itemMap = {}
  const itemOrder = []

  if (Array.isArray(arr)) {
    arr.forEach(item => {
      const key = item[keyField] || Math.random()
      itemMap[key] = item
      itemOrder.push(key)
    })
  }

  return [itemMap, itemOrder]
}

function addToSet(source, items) {
  const newItems = items.filter(item => !source.includes(item))
  return [...source, ...newItems]
}

function idxOr(alt, accessor) {
  try {
    return accessor() || alt
  } catch (error) {
    return alt
  }
}

const cache = new Map()

function fromMapOr(map, func, key, ...keys) {
  if (map.has(key)) {
    const value = map.get(key)
    if (keys.length) {
      return fromMapOr(value, func, ...keys)
    }
    return value
  }

  if (keys.length) {
    const value = new Map()
    map.set(key, value)
    return fromMapOr(value, func, ...keys)
  }

  const value = func()

  map.set(key, value)

  return value
}

function getItemWithCache(getResult, ...args) {
  return fromMapOr(cache, getResult, ...args)
}

export function createModelModule({ name, params = [], fetchItemFunc }) {
  const paramNames = params.sort()

  const createKey = (obj = {}) =>
    paramNames.reduce((result, paramName) => `${result}/${paramName}/${obj[paramName]}`, 'root')

  const types = {
    startQuery: `type::${name}/startQuery`,
    setItem: `type::${name}/setItem`,
  }

  const getModel = (state, obj) => idxOr({}, _ => state[name].modelByKey[createKey(obj)])

  const selectors = {
    getItem: (state, obj = {}) => {
      const model = getModel(state, obj)
      return idxOr(null, _ => model.item)
    },
    isFetching: (state, obj) => {
      const model = getModel(state, obj)
      return !!idxOr(false, _ => model.pendingQueries.length)
    },
  }

  const updateModel =
    updater =>
    (state, { key, ...payload }) => ({
      ...state,
      modelByKey: {
        ...state.modelByKey,
        [key]: updater(
          idxOr({}, _ => state.modelByKey[key]),
          payload,
        ),
      },
    })

  const reducers = {
    startQuery: updateModel((model, { query }) => ({
      ...model,
      pendingQueries: [...(model.pendingQueries || []), query],
    })),

    setItem: updateModel((model, { item, query }) => ({
      ...model,
      item,
      pendingQueries: model.pendingQueries.filter(q => q !== query),
    })),
  }

  const actions = {
    fetchItem:
      (obj, { force = false } = {}) =>
      async (dispatch, getState, extraArgument) => {
        const key = createKey(obj)
        const query = 'fetchItem'
        const model = idxOr({}, _ => getState()[name].modelByKey[key])

        if (!force && model.item) {
          return model.item
        }

        if (!force && model.pendingQueries && model.pendingQueries.includes(query)) return undefined

        dispatch({ type: types.startQuery, key, query })

        let item
        try {
          item = await fetchItemFunc(obj, extraArgument)
        } catch (error) {
          handleError(error)
        }

        dispatch({
          type: types.setItem,
          key,
          item,
          query,
        })

        return item
      },
  }

  const initialState = {
    modelByKey: {},
  }

  return {
    initialState,
    selectors,
    reducers,
    actions,
    types,
    name,
  }
}

export function createCollectionModule({
  name,
  params = [],
  fetchAllFunc,
  restoreFunc,
  searchFunc,
  keyField,
}) {
  const paramNames = params.sort()
  const createKey = (obj = {}) =>
    paramNames.reduce((result, paramName) => `${result}/${paramName}/${obj[paramName]}`, 'root')

  const types = {
    startQuery: `type::${name}/startQuery`,
    setAllItems: `type::${name}/setAllItems`,
    setRestoredItems: `type::${name}/setRestoredItems`,
    setSearchedItems: `type::${name}/setSearchedItems`,
  }

  const getCollection = (state, obj) => idxOr({}, _ => state[name].collectionByKey[createKey(obj)])

  const selectors = {
    getCollectionItems: (state, { term = '', ...obj } = {}) => {
      const collection = getCollection(state, obj)
      if (!collection.itemMap || !collection.itemOrderByTerm || !collection.itemOrderByTerm[term])
        return []
      return getItemWithCache(
        () => collection.itemOrderByTerm[term].map(key => collection.itemMap[key]),
        collection,
        term,
      )
    },
    isAllCollectionFetched: (state, obj) => {
      const collection = getCollection(state, obj)
      return collection.isAllFetched
    },
    isFetching: (state, obj) => {
      const collection = getCollection(state, obj)
      return !!idxOr(false, _ => collection.pendingQueries.length)
    },
    getItem: (state, { value, ...obj }) => {
      const collection = getCollection(state, obj)
      return idxOr(null, _ => collection.itemMap[value])
    },
  }

  const updateCollection =
    updater =>
    (state, { key, ...payload }) => ({
      ...state,
      collectionByKey: {
        ...state.collectionByKey,
        [key]: updater(
          idxOr({}, _ => state.collectionByKey[key]),
          payload,
        ),
      },
    })

  const updateCollectionWithItems = updater =>
    updateCollection((collection, { items, query, ...payload }) => ({
      ...updater(collection, normalizeArray(items, keyField), payload),
      pendingQueries: collection.pendingQueries.filter(q => q !== query),
    }))

  const reducers = {
    startQuery: updateCollection((collection, { query }) => ({
      ...collection,
      pendingQueries: [...(collection.pendingQueries || []), query],
    })),

    setAllItems: updateCollectionWithItems((collection, [itemMap, itemOrder]) => ({
      ...collection,
      isAllFetched: true,
      itemMap,
      itemOrderByTerm: {
        ...collection.itemOrderByTerm,
        '': itemOrder,
      },
    })),

    setRestoredItems: updateCollectionWithItems((collection, [itemMap, itemOrder]) => ({
      itemMap: { ...collection.itemMap, ...itemMap },
      itemOrderByTerm: {
        ...collection.itemOrderByTerm,
        '': addToSet(
          idxOr([], _ => collection.itemOrderByTerm['']),
          itemOrder,
        ),
      },
    })),

    setSearchedItems: updateCollection((collection, [itemMap, itemOrder], { term }) => ({
      itemMap: { ...collection.itemMap, ...itemMap },
      itemOrderByTerm: {
        ...collection.itemOrderByTerm,
        '': addToSet(
          idxOr([], _ => collection.itemOrderByTerm['']),
          itemOrder,
        ),
        [term]: addToSet(
          idxOr([], _ => collection.itemOrderByTerm[term]),
          itemOrder,
        ),
      },
    })),
  }

  const actions = {
    ...(fetchAllFunc && {
      fetchAll:
        (obj, { force = false } = {}) =>
        async (dispatch, getState, extraArgument) => {
          const key = createKey(obj)
          const query = 'fetchAll'
          const collection = idxOr({}, _ => getState()[name].collectionByKey[key])

          if (!force && collection.isAllFetched) {
            return selectors.getCollectionItems(getState(), obj)
          }

          if (!force && collection.pendingQueries && collection.pendingQueries.includes(query))
            return undefined

          dispatch({ type: types.startQuery, key, query })

          let items = []
          try {
            items = await fetchAllFunc(obj, extraArgument)
          } catch (error) {
            handleError(error)
          } finally {
            dispatch({
              type: types.setAllItems,
              key,
              items,
              query,
            })
          }

          return items
        },
    }),

    ...(restoreFunc && {
      restoreItems: (itemValues, obj) => async (dispatch, getState, extraArgument) => {
        const key = createKey(obj)
        const query = `restoreItems/${JSON.stringify(itemValues)}`
        const collection = idxOr({}, _ => getState()[name].collectionByKey[key])

        if (
          (collection.pendingQueries && collection.pendingQueries.includes(query)) ||
          collection.isAllFetched ||
          (collection.itemMap && all(itemValue => !!collection.itemMap[itemValue], itemValues))
        )
          return

        dispatch({ type: types.startQuery, key, query })

        let items = []
        try {
          items = await restoreFunc(obj, itemValues, extraArgument)
        } catch (error) {
          handleError(error)
        } finally {
          dispatch({
            type: types.setRestoredItems,
            key,
            items,
            query,
          })
        }
      },
    }),

    ...(searchFunc && {
      searchItems: (term, obj) => async (dispatch, getState, extraArgument) => {
        const key = createKey(obj)
        const query = `searchItems/${term}`
        const collection = idxOr({}, _ => getState()[name].collectionByKey[key])

        if (
          (collection.pendingQueries && collection.pendingQueries.includes(query)) ||
          (collection.itemOrderByTerm && !!collection.itemOrderByTerm[term])
        )
          return

        dispatch({ type: types.startQuery, key, query })

        const items = await searchFunc(obj, term, extraArgument)

        dispatch({
          type: types.setSearchedItems,
          key,
          term,
          items,
          query,
        })
      },
    }),
  }

  const initialState = {
    collectionByKey: {},
  }

  return {
    initialState,
    selectors,
    reducers,
    actions,
    types,
    name,
  }
}

export function mapReducers(types, reducers) {
  const obj = {}
  Object.keys(types).forEach(key => {
    obj[types[key]] = reducers[key]
  })
  return obj
}
