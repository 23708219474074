import * as React from 'react'

import type {
  UserAssignRolesActions,
  UserAssignRolesControllerProps,
} from '../../types/UserAssignRoles'

import type { Role } from '../../types/api/userManagement/definitions'

import type { DataProviderChildProps } from '../DataProvider/types'
import type { MultiselectField } from '../../types/common'

type Props = DataProviderChildProps & Omit<UserAssignRolesControllerProps, 'onError' | 'httpClient'>

type State = {
  roles: Role[]
  editMode: boolean
  selectedRoleIds: string[]
  isPlaceholderVisible: boolean
  hasUnsavedChanges: boolean
}

export class UserAssignRolesController extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      // eslint-disable-next-line react/no-unused-state
      roles: [],
      editMode: false,
      selectedRoleIds: [],
      isPlaceholderVisible: true,
      hasUnsavedChanges: false,
    }
  }

  rolesOriginIds: string[] = []

  async componentDidMount() {
    const { userId } = this.props
    const { fetchRoles, fetchUserRoles } = this.props.userManagement.methods
    const [userRoles] = await Promise.all([fetchUserRoles(userId), fetchRoles()])
    if (userRoles) {
      this.rolesOriginIds = userRoles.map(role => role.id)
      this.setState({ selectedRoleIds: this.rolesOriginIds })
    }
    this.setState({ isPlaceholderVisible: false })
  }

  async componentDidUpdate(prevProps: Props) {
    if (this.props.userId !== prevProps.userId) {
      const { userId } = this.props
      const { fetchRoles, fetchUserRoles } = this.props.userManagement.methods
      const [userRoles] = await Promise.all([fetchUserRoles(userId), fetchRoles()])
      if (userRoles) {
        this.rolesOriginIds = userRoles.map(role => role.id)
        this.setState({ selectedRoleIds: this.rolesOriginIds })
      }
      this.setState({ isPlaceholderVisible: false })
    }
  }

  onSubmit = () => {
    const { userId } = this.props
    const { setUserRoles } = this.props.userManagement.methods
    const { selectedRoleIds } = this.state
    setUserRoles(userId, selectedRoleIds).then(data => {
      if (!data) {
        this.rolesOriginIds = selectedRoleIds
        this.setState({ editMode: false, hasUnsavedChanges: false })
      }
    })
  }

  switchOffEditMode = () =>
    this.setState({
      editMode: false,
      selectedRoleIds: this.rolesOriginIds,
    })

  switchOnEditMode = () => this.setState({ editMode: true })

  resetForm = () => this.setState({ selectedRoleIds: this.rolesOriginIds })

  onCancel = () => {
    this.resetForm()
    if (this.props.onCancelForm) this.props.onCancelForm()
  }

  getRolesProps = (): MultiselectField<Role> => {
    const { roles } = this.props.userManagement.data
    const { selectedRoleIds } = this.state
    return {
      availableOptions: roles,
      selected: selectedRoleIds,
      onChange: value => this.setState({ selectedRoleIds: value, hasUnsavedChanges: true }),
    }
  }

  isSubmitting = () =>
    this.props.requestsFlags.isFetchingRoles || this.props.requestsFlags.isUpdatingUser

  getFormActionsProps = (): UserAssignRolesActions => {
    const { editMode } = this.state

    return {
      switchOnEditMode: {
        visible: !editMode,
        dispatch: this.switchOnEditMode,
      },
      switchOffEditMode: {
        visible: editMode,
        dispatch: this.switchOffEditMode,
      },
      saveRoles: {
        visible: editMode,
        disabled: this.isSubmitting(),
        isExecuting: this.isSubmitting(),
        dispatch: this.onSubmit,
      },
      cancelForm: {
        visible: !editMode,
        dispatch: this.onCancel,
      },
    }
  }

  render() {
    const { editMode, isPlaceholderVisible, hasUnsavedChanges } = this.state
    const { children } = this.props
    return isPlaceholderVisible
      ? children({ placeholder: true })
      : children({
          hasUnsavedChanges,
          placeholder: false,
          formEditMode: editMode,
          roles: this.getRolesProps(),
          formActions: this.getFormActionsProps(),
        })
  }
}
