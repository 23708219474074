import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ContentHeader } from '@r1/wireframe-primary'

import {
  Button,
  Input,
  Flex,
  FormField,
  H5,
  HR,
  Select,
  TagInput,
  Placeholder,
  TreeSelect,
  Icon,
} from '@r1/ui-kit'
import {
  ChangeValueChildrenHandler,
  FormManager,
  InsertChildrenHandler,
  RemoveChildrenHandler,
} from '@r1/form-manager'

import { marketplaceProfileService, productCatalogService } from '../services'
import { Category, MarketplaceProfile, DiscountRule, FormManagerSubmit } from '../types'
import { DiscountStep } from './DiscountStep'
import { validate } from './validation'

type Props = {
  initialDiscountRule: DiscountRule
  isSubmitting: boolean
  onSubmit: FormManagerSubmit
  onClose: () => void
}

const IconButton = styled(Button)`
  padding: 0 16px;
  &:focus {
    box-shadow: none;
  }
`

const MAX_STEPS = 10

const getBetweenValue = (from: number, to: number) => from + Math.floor((to - from) / 2)

export const DiscountsRuleForm = (props: Props) => {
  const { initialDiscountRule, isSubmitting, onSubmit, onClose } = props

  const [marketplaceProfiles, setMarketplaceProfiles] = useState<MarketplaceProfile[]>([])
  const [allCategories, setAllCategories] = useState<Category[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const [profiles, categories] = await Promise.all([
      marketplaceProfileService.fetchMarketplaceProfiles(),
      productCatalogService.fetchCategories(),
    ])
    setMarketplaceProfiles(profiles)
    setAllCategories(categories)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderDiscountSteps = useCallback(
    (
      values: DiscountRule,
      remove: RemoveChildrenHandler<DiscountRule>,
      insert: InsertChildrenHandler<DiscountRule>,
      handleChange: ChangeValueChildrenHandler<DiscountRule>,
    ) => {
      return values.steps.map((step, idx, steps) => {
        const isLast = idx === steps.length - 1

        let minValue = null
        let maxValue = null
        const newStep = {
          id: idx + 1,
          count: Math.min(step.count + 10, 100000),
          percent: Math.min(step.percent + 10, 99),
        }
        if (isLast) {
          if (step.count === 100000 || step.percent === 99) {
            newStep.id = idx
            newStep.count = getBetweenValue(step.count, steps[idx - 1].count)
            newStep.percent = getBetweenValue(step.percent, steps[idx - 1].percent)
          }
        } else {
          newStep.count = getBetweenValue(step.count, steps[idx + 1].count)
          newStep.percent = getBetweenValue(step.percent, steps[idx + 1].percent)
        }

        if (idx !== 0) {
          const prevStep = steps[idx - 1]
          minValue = {
            count: prevStep.count + 1,
            percent: prevStep.percent + 1,
          }
        }

        if (idx !== steps.length - 1) {
          const nextStep = steps[idx + 1]
          maxValue = {
            count: nextStep.count - 1,
            percent: nextStep.percent - 1,
          }
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={idx}>
            <DiscountStep
              value={step}
              minValue={minValue}
              maxValue={maxValue}
              // @ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
              onChange={handleChange(['steps', idx])}
            />

            <Flex align="center">
              <IconButton
                transparent
                disabled={idx === 0 && steps.length === 1}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
                onClick={() => remove(['steps'], idx)}
              >
                <Icon type="minus" />
              </IconButton>
              <IconButton
                transparent
                disabled={steps.length === MAX_STEPS}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call
                onClick={() => insert(['steps'], newStep.id, newStep)}
              >
                <Icon type="plus" />
              </IconButton>
            </Flex>
          </Flex>
        )
      })
    },
    [],
  )

  return isLoading ? (
    <Placeholder type="form" height={5} />
  ) : (
    <FormManager initialValues={initialDiscountRule} clientValidate={validate} onSubmit={onSubmit}>
      {({ values, dirty, handleChange, handleBlur, remove, insert, handleSubmit, getError }) => {
        const nameError = getError('name')
        const categoriesError = getError('categories')
        const includeUPCError = getError('includeUPC')
        const excludeUPCError = getError('excludeUPC')
        const marketplacesError = getError('marketplaces')

        return (
          <>
            <ContentHeader.ActionButtons>
              <Button color="secondary" onClick={onClose}>
                Close
              </Button>
              <Button
                disabled={isSubmitting || !dirty}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </ContentHeader.ActionButtons>

            <Flex column minWidth={1} my="L">
              <Flex column pt="L" spaceBetween="S">
                <FormField>
                  <FormField.Label>Volume Discounting Rule Name</FormField.Label>
                  <Input
                    data-test-id="volume-discounting-rule-name"
                    value={values.name}
                    error={!!nameError}
                    // @ts-ignore
                    onChange={name => handleChange(['name'], name)}
                    onBlur={handleBlur(['name'])}
                  />
                  <FormField.Error>{nameError}</FormField.Error>
                </FormField>
              </Flex>

              <HR />

              <Flex column pt="L">
                <H5>For all product units with the following attributes:</H5>
                <FormField>
                  <FormField.Label>Category</FormField.Label>
                  <TreeSelect
                    multiple
                    data-test-id="category"
                    placeholder="Choose Categories"
                    ignoreBranchNodes={false}
                    options={allCategories}
                    value={values.categories}
                    error={!!categoriesError}
                    // @ts-ignore
                    onChange={handleChange(['categories'])}
                    onBlur={handleBlur(['categories'])}
                  />
                  <FormField.Error>{categoriesError || ''}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label>UPCs (Include)</FormField.Label>
                  <TagInput
                    data-test-id="UPCs-include"
                    $type="simpleTagValue"
                    value={values.includeUPC}
                    error={!!includeUPCError}
                    delimiter={['\n', ' ', ',', ':']}
                    // @ts-ignore
                    onChange={handleChange(['includeUPC'])}
                    onBlur={handleBlur(['includeUPC'])}
                  />
                  <FormField.Error>{includeUPCError}</FormField.Error>
                </FormField>
                <FormField>
                  <FormField.Label>UPCs (Exclude)</FormField.Label>
                  <TagInput
                    data-test-id="UPCs-exclude"
                    $type="simpleTagValue"
                    value={values.excludeUPC}
                    error={!!excludeUPCError}
                    delimiter={['\n', ' ', ',', ':']}
                    // @ts-ignore
                    onChange={handleChange(['excludeUPC'])}
                    onBlur={handleBlur(['excludeUPC'])}
                  />
                  <FormField.Error>{excludeUPCError || ''}</FormField.Error>
                </FormField>
              </Flex>
              <HR />

              <Flex column pt="L">
                <H5>Apply the Following Discount Rule</H5>

                {renderDiscountSteps(values, remove, insert, handleChange)}
              </Flex>

              <HR />

              <Flex column pt="L">
                <H5>When selling on the following marketplaces: </H5>

                <FormField>
                  <Select
                    multiple
                    data-test-id="marketplace"
                    placeholder="Choose Marketplace"
                    options={marketplaceProfiles}
                    error={!!marketplacesError}
                    value={values.marketplaces}
                    // @ts-ignore
                    onChange={handleChange(['marketplaces'])}
                    onBlur={handleBlur(['marketplaces'])}
                  />
                  <FormField.Error>{marketplacesError}</FormField.Error>
                </FormField>
              </Flex>
            </Flex>
          </>
        )
      }}
    </FormManager>
  )
}
