// @flow

import * as React from 'react'

import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'

import { httpClient } from '../../../httpClient'

import { ProfileConfigurator as ProfileConfiguratorForm } from '../components'

const ProfileConfigurator = () => (
  <Main>
    <ContentHeader>
      <ContentHeader.Breadcrumbs>
        <Link to="/r1/admin/profiles">External Service Integrations</Link>
      </ContentHeader.Breadcrumbs>
      <ContentHeader.Title>Profile configurator</ContentHeader.Title>
    </ContentHeader>
    <Content>
      <ProfileConfiguratorForm httpClient={httpClient} onError={() => {}} />
    </Content>
  </Main>
)

export default ProfileConfigurator
