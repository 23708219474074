import * as React from 'react'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Flex, H2, Placeholder, Link } from '@r1/ui-kit'

import { PageAccessControl } from '@r1/core-blocks'
import { ROUTES } from '../../../../../navigation'
import { PageMode } from './types'

import { useRoleController } from './controller'
import { BaseRoleInfo } from './components/BaseRoleInfo'
import { RolePermissions } from './components/RolePermissions'
import { ActionButtonsViewMode } from './components/ActionButtons/ActionButtonsViewMode'
import { ActionButtonsEditMode } from './components/ActionButtons/ActionButtonsEditMode'
import { ActionButtonsCreateMode } from './components/ActionButtons/ActionButtonsCreateMode'
import { RoleHistory } from './components/RoleHistory'

export const RolePage = () => {
  const controller = useRoleController()

  const pageTitle = React.useMemo(() => {
    if (controller.pageMode === PageMode.Creating) {
      return 'Creating a new role'
    }
    return controller.name.value
  }, [controller.pageMode, controller.name.value])

  return (
    <PageAccessControl permissions={controller.pagePermissions}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.ROLES}>Roles</Link>
        </ContentHeader.Breadcrumbs>
        <ContentHeader.Title>{pageTitle}</ContentHeader.Title>
        <Content>
          <Flex column pb="XXL" spaceBetween="M">
            <Flex column maxWidth={640}>
              <H2>General Information</H2>
              <BaseRoleInfo
                editable={controller.pageMode !== PageMode.View}
                loading={controller.isLoading}
                disabled={controller.isSubmitting}
                name={controller.name}
                description={controller.description}
              />
            </Flex>

            <Flex column>
              <H2>Permissions</H2>
              <RolePermissions
                editable={controller.pageMode !== PageMode.View}
                loading={controller.isLoading}
                disabled={controller.isSubmitting}
                allEntityActionsInfo={controller.allEntityTypeActionsInfo}
                value={controller.rolePermissions.value}
                onChange={controller.rolePermissions.onChange}
              />
            </Flex>
          </Flex>
          <ContentHeader.ActionButtons>
            {/* TODO don't show action buttons during loading */}
            {controller.isLoading && <Placeholder type="rectangle" height={44} />}
            {controller.pageMode === PageMode.View && (
              <ActionButtonsViewMode
                close={controller.openRolesGrid}
                switchToEditMode={controller.switchToEditMode}
                openHistory={controller.openHistory}
                deleteRole={controller.deleteRole}
              />
            )}
            {controller.pageMode === PageMode.Editing && (
              <ActionButtonsEditMode
                submitting={controller.isSubmitting}
                cancel={controller.switchToViewMode}
                hasUnsavedChanges={controller.hasUnsavedChanges}
                updateRole={controller.updateRole}
              />
            )}
            {controller.pageMode === PageMode.Creating && (
              <ActionButtonsCreateMode
                submitting={controller.isSubmitting}
                cancel={controller.openRolesGrid}
                hasUnsavedChanges={controller.hasUnsavedChanges}
                createRole={controller.createRole}
              />
            )}
          </ContentHeader.ActionButtons>
          <RoleHistory
            open={controller.isHistoryOpen}
            roleId={controller.roleId}
            roleName={controller.name.value}
            onClose={controller.closeHistory}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
