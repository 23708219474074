import axios from 'axios'

const baseUrl = 'https://api.powerbi.com/v1.0/myorg'

export const powerBiApi = {
  getEmbedUrl: async ({
    accessToken,
    workspaceId,
    reportId,
  }: {
    accessToken: string
    workspaceId: string
    reportId: string
  }): Promise<{ embedUrl: string } | null> => {
    return axios
      .get(`${baseUrl}/groups/${workspaceId}/reports/${reportId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(({ data }: { data: Record<string, unknown> }) => ({
        embedUrl: data.embedUrl as string,
      }))
      .catch(() => {
        return null
      })
  },
  refreshUserPermissions(accessToken: string): void {
    axios
      .post(
        `${baseUrl}/RefreshUserPermissions`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      )
      .then((response: Record<string, unknown>) => {
        if (response.ok) {
          console.log('User permissions refreshed successfully.')
        } else if (response.status === 429) {
          // Too many requests in one hour will cause the API to fail
          console.error('Permissions refresh will be available in up to an hour.')
        } else {
          console.error(response)
        }
      })
      .catch(error => {
        console.error(`Failure in making API call. ${error}`)
      })
  },
}
