// @flow

import * as React from 'react'
import { useMemo, memo } from 'react'
import { priceApi } from '../../api'
import { priceService } from '../../services'
import { ServiceContext } from './provider'

type PriceContainerProps = {
  children?: React.Node,
  httpClient: any,
}

export const PriceContainer = memo<PriceContainerProps>((props: PriceContainerProps) => {
  const { httpClient, children } = props
  const apiMemo = useMemo(
    () => ({
      priceService: priceService(priceApi(httpClient)),
      httpClient,
    }),
    [httpClient],
  )

  return <ServiceContext.Provider value={apiMemo}>{children}</ServiceContext.Provider>
})
