// @flow

import * as React from 'react'
import LoadingBar from 'react-redux-loading-bar'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { NotificationSystem, Modal, Text } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

import { Loader } from '../../components/Loader'
import * as actions from './module'

const Container = styled('div')`
  display: flex;
  flex: auto;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[800]};
  letter-spacing: 0.15px;
`

class AppContainerPresenter extends React.Component<
  {
    dialog: { text: string },
    closeDialog: boolean => void,
    showOverlay: boolean,
    children: React$Node,
  },
  {},
> {
  /**
   *
   * 1. renderDialog read dialog store from redux store
   * 2. There are two handlers showDialog and closeDialog in the App/module.js.
   * They are injected below in this file via compose from react-redux
   * 3. showDialog also uses in setDialogDispatch which creates dispatchDialog in the closure
   * 4. confirm handler in the dialogs.js uses dispatchDialog
   * 5. confirm uses in the src/modules/manufacturers/manufacturers.js and src/modules/products/models.js
   */
  renderDialog() {
    const { dialog, closeDialog } = this.props

    if (!dialog) return null

    const bottomActionButtons: BottomActionButton[] = [
      {
        align: 'right',
        onClick: () => closeDialog(false),
        title: 'Cancel',
        color: 'secondary',
      },
      { align: 'right', onClick: () => closeDialog(true), title: 'Confirm' },
    ]
    return (
      <Modal
        show
        isControlled
        title="Delete"
        actionButtons={bottomActionButtons}
        onEscapeKeyDown={() => closeDialog(false)}
      >
        <Text>{dialog.text}</Text>
      </Modal>
    )
  }

  render() {
    const { children, showOverlay } = this.props
    return (
      <Container>
        <LoadingBar style={{ backgroundColor: '#f07510', zIndex: 1001 }} />
        {showOverlay && <Loader />}
        <NotificationSystem />
        {this.renderDialog()}
        {children}
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  dialog: state.app.dialog,
  showOverlay: !!state.loadingBar,
})

const enhance = compose(connect(mapStateToProps, actions))

export const AppContainer = enhance(AppContainerPresenter)
