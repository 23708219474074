// @flow

import type { ClaimMessage } from '../../../../types/ClaimMessage'
import type { WithController } from '../types'

export class ChatController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  updateMessages = (messages: ClaimMessage[]) => {
    this.component.setState(prevState => {
      if (prevState.data)
        return {
          ...prevState,
          data: {
            ...prevState.data,
            claim: {
              ...prevState.data.claim,
              messages,
            },
          },
        }
      return prevState
    })
  }

  setHasUnreadMessages = (flag: boolean) => {
    this.component.setState({ hasUnreadChatMessages: flag })
  }
}

export const createChatController = (component: WithController) => new ChatController(component)
