import { Action } from '../constants'

const initialState = true

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case Action.CLOSE_CREATE_DOCUMENT_TEMPLATE_PANEL:
      return false

    default:
      return state
  }
}
