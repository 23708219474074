import {
  createFacilityPickupSchedulerManagmentApi,
  baseUri,
} from '@r1-webui/facilitypickupscheduler-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = '/uisvc/legacy-browserui'

export const schedulePickupApi = createFacilityPickupSchedulerManagmentApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}/${baseUri}`,
})
