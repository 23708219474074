// @flow

import * as React from 'react'
import { useMemo } from 'react'
import { GridGenerator, setQuery, parseQuery } from '@r1/grid-generator'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'

import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

type PropsTypes = {}

const DecisionRulesGrid = (_: PropsTypes) => {
  const initialStateQuery = useMemo(parseQuery, [])
  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Decision History Grid</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <GridGenerator
          gridId="ProductDecision"
          gridEngineBaseUrl="/uisvc/legacy-grids"
          initialStateQuery={initialStateQuery}
          aggregationCount={{ title: '', suppressAggregation: true }}
          httpClient={httpClient}
          onError={errorHandler}
          onChangeState={setQuery}
        />
      </Content>
    </Main>
  )
}

export { DecisionRulesGrid }
