export function formatMoney(money: number): string {
  let options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  if (money - Math.trunc(money) !== 0) {
    options = {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }
  }
  return new Intl.NumberFormat('en-US', options).format(money)
}

export function formatNumber(count: number): string {
  let options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }
  if (count - Math.trunc(count) !== 0) {
    options = {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    }
  }
  return new Intl.NumberFormat('en-US', options).format(count)
}
