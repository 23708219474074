// @flow

import uuid from 'uuid'

import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'

import { browserHistory } from '../../../browserHistory'

import { createReducer, createTypes } from '../../../../redux/utils'
import apiRoutes from '../resources/apiRoutes'
import {
  classificationsApi,
  marketplaceConditionsApi,
  profileConditionMappingsApi,
} from '../api/profileConditionMappingsApi'

const initialState = {
  profile: {
    id: '',
    name: '',
  },
  isCommittingInProgress: false,
  conditionMappings: [],
  availableConditions: {
    conditions: [],
    technicalFunctionalities: [],
    physicalConditions: [],
  },
  marketplaceConditions: [],
}

const types = createTypes(
  [
    'loadConditionMappings',
    'commitChangesStart',
    'commitChangesEnd',
    'addConditionMappings',
    'deleteConditionMappings',
    'changeConditionMappingsField',
  ],
  'conditionMappings',
)

type ConditionMapping = {|
  $type: string,
  profileId: string,
  generatedId: string,
  UPC: string[],
  condition: string | null,
  marketplaceConditionType: number | null,
  physicalCondition: string | null,
  technicalFunctionality: string | null,
|}

class ConditionMappings {
  constructor(profileId: string) {
    this.$type = 'ProfileConditionMappings'
    this.profileId = profileId
    this.generatedId = uuid.v4()
    this.UPC = []
    this.condition = null
    this.marketplaceConditionType = null
    this.physicalCondition = null
    this.technicalFunctionality = null
  }

  $type: string

  profileId: string

  generatedId: string

  UPC: string[]

  condition: string | null

  marketplaceConditionType: number | null

  physicalCondition: string | null

  technicalFunctionality: string | null
}

const getUniqMappingKeys = ({ physicalCondition, technicalFunctionality, condition, UPC }) => {
  const keys = []
  if (UPC && UPC.length > 0) {
    for (const upcKey of UPC) {
      keys.push(
        `${physicalCondition || ''}-${technicalFunctionality || ''}-${condition || ''}-${upcKey}`,
      )
    }
  } else {
    keys.push(`${physicalCondition || ''}-${technicalFunctionality || ''}-${condition || ''}`)
  }
  return keys
}

const findDuplicateMappings = (mappings: ConditionMapping[]) => {
  const duplicates = []
  const set = {}
  mappings.forEach(m => {
    const keys = getUniqMappingKeys(m)
    for (const key of keys) {
      const mappingExists = set[key]
      if (mappingExists) {
        duplicates.push(m)
      } else {
        set[key] = true
      }
    }
  })
  return duplicates
}

export const reducer = createReducer(initialState, {
  [types.commitChangesStart]: state => ({
    ...state,
    isCommittingInProgress: true,
  }),
  [types.commitChangesEnd]: state => ({
    ...state,
    isCommittingInProgress: false,
  }),
  [types.loadConditionMappings]: (
    state,
    { profile, conditionMappings, availableConditions, marketplaceConditions },
  ) => ({ ...state, profile, conditionMappings, availableConditions, marketplaceConditions }),
  [types.addConditionMappings]: (state, { profileId }) => ({
    ...state,
    conditionMappings: [new ConditionMappings(profileId), ...state.conditionMappings],
  }),
  [types.deleteConditionMappings]: (state, { index }) => {
    const conditionMappings = [...state.conditionMappings]
    conditionMappings.splice(index, 1)
    return { ...state, conditionMappings }
  },
  [types.changeConditionMappingsField]: (state, { index, name, value }) => {
    const conditionMappings = [...state.conditionMappings]
    conditionMappings[index][name] = value
    return {
      ...state,
      conditionMappings,
    }
  },
})

export const addConditionMappings: Function = profileId => async dispatch => {
  dispatch({
    type: types.addConditionMappings,
    profileId,
  })
}

export const deleteConditionMappings: Function = index => async dispatch => {
  dispatch({
    type: types.deleteConditionMappings,
    index,
  })
}

export const commitChanges =
  ({ id, conditionMappings }: { id: number, conditionMappings: any }): Function =>
  async dispatch => {
    const duplicates = findDuplicateMappings(conditionMappings)
    if (duplicates.length > 0) {
      const message =
        'Profile mappings contain duplicate values by fields:' +
        ' Condition, Physical Condition, Technical Functionality or UPCs'

      NotificationSystem.addNotification({
        level: 'error',
        title: 'Duplicates are not allowed',
        message,
      })
    } else {
      dispatch({
        type: types.commitChangesStart,
      })

      try {
        await profileConditionMappingsApi.updateProfileConditionMappings(
          { profileId: id },
          conditionMappings,
        )
        dispatch({
          type: types.commitChangesEnd,
        })
      } catch (error) {
        handleServerError(error)
      }

      browserHistory.push('/r1/admin/metadata/marketplaceConditionMappings')
    }
  }

export const changeConditionMappingsField =
  ({
    columnData,
    index,
    name,
    value,
  }: {
    columnData: any,
    index: number,
    name: string,
    value: string,
  }): Function =>
  dispatch => {
    dispatch({
      type: types.changeConditionMappingsField,
      name,
      value,
      index,
      columnData,
    })
  }

const sortConditionMappings = (a, b) => {
  if (a.marketplaceCondition > b.marketplaceCondition) {
    return 1
  } else if (a.marketplaceCondition < b.marketplaceCondition) {
    return -1
  }
  const aCondition = a.condition || ''
  const bCondition = b.condition || ''
  if (aCondition > bCondition) {
    return 1
  } else if (aCondition < bCondition) {
    return -1
  }
  return 0
}

export function loadConditionMappings(id: number): Function {
  return async (dispatch, getState, api) => {
    const profileUrl = apiRoutes.getProfile({ profileId: id })
    const profile = await api.client.get(profileUrl)
    const conditionMappings = await profileConditionMappingsApi.getProfileConditionMappings({
      profileId: id,
    })
    const availableConditions = await classificationsApi.getMainClassificationValues()
    const marketplaceConditions = await marketplaceConditionsApi.getMarketplaceConditions()
    const viewModelConditionMappings = conditionMappings.body
      .map(p => ({
        ...p,
        generatedId: uuid.v4(),
      }))
      .sort(sortConditionMappings)
    dispatch({
      profile,
      type: types.loadConditionMappings,
      conditionMappings: viewModelConditionMappings,
      availableConditions: availableConditions.body,
      marketplaceConditions: marketplaceConditions.body,
    })
  }
}
