/* eslint-disable consistent-return */
import * as React from 'react'
import { assoc, evolve } from 'ramda'

import type { Lib$ServerResponse } from '@r1/types/typescript'

import type { Location, ReportLogin } from '@r1-webui/usermanagement-v1'
import { handleServerError } from '@r1/core-blocks'

import { isErrorResponse, extractPayload } from '../../api/helpers'
import { createApi as createUserManagementApi } from '../../api/userManagement'
import { usersApi } from '../../api/users'

import { base64ImageExt, base64RemoveImageType } from '../../utils/base64/base64'
import type { ResponseSuperType, User } from '../../api/contracts'
import { DateFormats } from '../../types/api/userManagement/definitions'
import { showNotification } from '../../../../utils/notifications/showNotification'
import type {
  UserManagementApi,
  CreateUserBody,
  Manager,
  TimeZone,
  UserCompany,
  Role,
  RequestsFlags,
  Error,
  DataProviderChildProps,
} from './types'

type State = {
  managers: Manager[]
  userCompanies: UserCompany[]
  locations: Location[]
  dateFormats: string[]
  timeZones: TimeZone[]
  roles: Role[]
  reportLogins: ReportLogin[]
  requestsFlags: RequestsFlags
}

export type Props = {
  httpClient: Record<string, unknown>
  onError?: (error: Error) => void
  children: (props: DataProviderChildProps) => React.ReactNode
}

export class DataProvider extends React.Component<Props, State> {
  api: UserManagementApi

  constructor(props: Props) {
    super(props)

    const { httpClient } = props

    this.state = {
      managers: [],
      userCompanies: [],
      locations: [],
      dateFormats: [],
      timeZones: [],
      roles: [],
      reportLogins: [],

      requestsFlags: {
        isFetchingManagers: false,
        isFetchingUserCompanies: false,
        isFetchingLocations: false,
        isFetchingTimeZones: false,
        isFetchingReportLogins: false,
        isFetchingRoles: false,
        isCreatingUser: false,

        isUpdatingProfileImage: false,
        isUpdatingUser: false,

        isFetchingDateFormats: false,
      },
    }

    this.api = createUserManagementApi(httpClient)
  }

  /** State updaters */

  updateRequestFlags = (flags: Record<string, boolean>) => {
    this.setState(
      evolve({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        requestsFlags: prevFlags => ({ ...prevFlags, ...flags }),
      }),
    )
  }

  /** Api calls */

  processResponse = <T>(response: ResponseSuperType<T>): ResponseSuperType<T> => {
    if (isErrorResponse(response)) {
      switch (response.$type) {
        case 'Validation':
          showNotification({ title: response.message, level: 'error' })
          return response
        case 'Unauthorized':
          if (this.props.onError) this.props.onError(response)
          break
        case 'Common':
          handleServerError(response)
          break
        default:
          break
      }
    }

    return response
  }

  fetchUser = async (userId: string): Promise<void | User> => {
    this.updateRequestFlags({ isFetchingUser: true })
    const response = await this.api.getUser(userId).then(this.processResponse)
    this.updateRequestFlags({ isFetchingUser: false })

    return extractPayload(response)
  }

  fetchData =
    <T>(
      request: () => Lib$ServerResponse<T>,
      loadingPropertyName: string,
      dataPropertyName: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      responseMapping: (response: T) => any = response => response,
    ) =>
    async () => {
      this.updateRequestFlags({ [loadingPropertyName]: true })

      const response = await request().then(this.processResponse)

      this.updateRequestFlags({ [loadingPropertyName]: false })

      const payload = extractPayload<T>(response)
      if (payload) {
        this.setState(assoc(dataPropertyName, responseMapping(payload)))
      }
    }

  fetchManagers = this.fetchData(() => this.api.getManagers(), 'isFetchingManagers', 'managers')

  fetchTimeZones = async () => {
    this.updateRequestFlags({ isLoadingTimeZones: true })

    const response = await usersApi.getTimeZones()
    if (response.status === 200) {
      this.setState(assoc('timeZones', response.body))
    } else {
      handleServerError(response)
    }

    this.updateRequestFlags({ isLoadingTimeZones: false })
  }

  fetchReportLogins = async () => {
    this.updateRequestFlags({ isFetchingReportLogins: true })

    const response = await usersApi.getReportLogins()
    if (response.status === 200) {
      this.setState(assoc('reportLogins', response.body))
    } else {
      handleServerError(response)
    }

    this.updateRequestFlags({ isFetchingReportLogins: false })
  }

  fetchUserCompanies = this.fetchData(
    () => this.api.getUserCompanies(),
    'isFetchingUserCompanies',
    'userCompanies',
  )

  fetchLocations = async () => {
    this.updateRequestFlags({ isFetchingLocations: true })

    const response = await usersApi.getLocations()
    if (response.status === 200) {
      this.setState(assoc('locations', response.body))
    } else {
      handleServerError(response)
    }

    this.updateRequestFlags({ isFetchingLocations: false })
  }

  deleteLocation = async (locationId: string) => {
    this.updateRequestFlags({ isFetchingLocations: true })
    const response = await usersApi.deleteLocation({ locationId })
    if (response.status !== 200) {
      handleServerError(response)
    }
    await this.fetchLocations()
  }

  addLocation = async (name: string) => {
    this.updateRequestFlags({ isFetchingLocations: true })
    // if you dont wrap name, it will send text without double quotes ("..")
    const requestBody = JSON.stringify(name)
    const response = await usersApi.addLocation(requestBody)
    if (response.status !== 200) {
      handleServerError(response)
    }
    await this.fetchLocations()
  }

  fetchDateFormats = this.fetchData(
    () => this.api.getDateFormats(),
    'isFetchingDateFormats',
    'dateFormats',
    (response: DateFormats) => response.formats,
  )

  fetchRoles = this.fetchData(() => this.api.getRoles(), 'isFetchingRoles', 'roles')

  fetchUserRoles = async (userId: string): Promise<void | Role[]> => {
    this.updateRequestFlags({ isFetchingRole: true })
    const response = await this.api.getUserRoles(userId).then(this.processResponse)
    this.updateRequestFlags({ isFetchingRole: false })

    return extractPayload(response)
  }

  setUserRoles = async (userId: string, roles: string[]) => {
    this.updateRequestFlags({ isUpdatingUser: true })
    const response = await this.api.setUserRoles(userId, roles).then(this.processResponse)
    this.updateRequestFlags({ isUpdatingUser: false })

    if (response.$type === 'Success') {
      showNotification({ title: ' User roles have been updated', level: 'success' })
      return
    }

    if (response.$type === 'Validation' || response.$type === 'Unhandled') {
      return response
    }
  }

  createUser = async (userData: CreateUserBody, profileImageSrc?: string) => {
    this.updateRequestFlags({ isCreatingUser: true })
    const response = await this.api.createUser(userData).then(this.processResponse)
    this.updateRequestFlags({ isCreatingUser: false })

    const payload = extractPayload(response)
    if (payload) {
      const userId = payload

      if (profileImageSrc && typeof userId === 'string')
        await this.updateProfileImage(userId, profileImageSrc)

      showNotification({ title: 'User was created', level: 'success' })
      return payload
    }

    if (response && response.$type === 'Validation') {
      return response
    }
  }

  updateProfileImage = async (userId: string, base64Image: string) => {
    this.updateRequestFlags({ isUpdatingProfileImage: true })

    const imageExt = base64ImageExt(base64Image)
    const imageData = base64RemoveImageType(base64Image)

    if (imageExt) {
      const response = await this.api
        .updateProfileImage(userId, {
          base64image: imageData,
          extension: imageExt,
        })
        .then(this.processResponse)

      this.updateRequestFlags({ isUpdatingProfileImage: false })

      return extractPayload(response)
    }

    this.updateRequestFlags({ isUpdatingProfileImage: false })
  }

  render() {
    const {
      managers,
      timeZones,
      userCompanies,
      locations,
      roles,
      requestsFlags,
      dateFormats,
      reportLogins,
    } = this.state

    return this.props.children({
      userManagement: {
        data: {
          managers,
          userCompanies,
          locations,
          timeZones,
          roles,
          dateFormats,
          reportLogins,
        },
        methods: {
          fetchUser: this.fetchUser,
          fetchManagers: this.fetchManagers,
          fetchTimeZones: this.fetchTimeZones,
          fetchReportLogins: this.fetchReportLogins,
          fetchUserCompanies: this.fetchUserCompanies,
          fetchLocations: this.fetchLocations,
          fetchDateFormats: this.fetchDateFormats,
          fetchRoles: this.fetchRoles,
          createUser: this.createUser,
          fetchUserRoles: this.fetchUserRoles,
          setUserRoles: this.setUserRoles,
          addLocation: this.addLocation,
          deleteLocation: this.deleteLocation,
        },
      },
      requestsFlags,
    })
  }
}
