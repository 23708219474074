import { getCategories } from '../services/categoriesService'

const treeCache = new Map()

export function getMasterProgramTree(filterId: string): Promise<
  Array<{
    id: string
    name: string
    parentId: string | null
  }>
> {
  if (!treeCache.get(filterId)) {
    const categories = getCategories()
    switch (filterId) {
      case 'categoryList':
      case 'excludedCategoryList':
        treeCache.set('categoryList', categories)
        treeCache.set('excludedCategoryList', categories)
        break
      default:
        // eslint-disable-next-line no-promise-executor-return
        treeCache.set(filterId, new Promise(resolve => resolve([])))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return treeCache.get(filterId)
}
