// @flow

type BadProperty = {
  noticeTypeCode: string,
  property: string,
  defaultFormat: string,
}

type ErrorData = { [fieldId: string]: string }

const mapFieldNames = {
  name: 'attributeName',
  dataType: 'attributeType',
  enumValues: 'attributeEnumValues',
  unitOfMeasurementId: 'unitOfMeasurement',
}
const variableRegEx = /\{([^}]+)\}/g
const bracesRegex = /[{}]/g
const getBadPropertyMessage = (notice): string =>
  notice.defaultFormat.replace(
    variableRegEx,
    currentMatch => notice[currentMatch.replace(bracesRegex, '')] || currentMatch,
  )

export const parseBadPropertiesNotice = (
  badProperties: $ReadOnlyArray<BadProperty>,
): ErrorData[] => {
  const errorData = badProperties.reduce((errors, badProperty) => {
    const { property } = badProperty
    const id = property.slice(property.lastIndexOf('.') + 1)
    const fieldId = mapFieldNames[id] || id
    const message = getBadPropertyMessage(badProperty)

    if (fieldId) {
      errors.push({ [fieldId]: message.replace(new RegExp(property, 'g'), '') })
    }

    return errors
  }, [])

  return errorData
}
