export const base64ImageExt = (encoded: string) => {
  const regExp = /^data:[a-zA-Z0-9]+\/([a-zA-Z0-9-.+]+).*,.*/
  const mime = encoded.match(regExp)
  if (mime && mime.length) {
    return mime[1]
  }
  return null
}

export const base64RemoveImageType = (encoded: string) => {
  const regExp = /^data:image\/(.+);base64,/
  return encoded.replace(regExp, '')
}
