import * as React from 'react'
import { H4, Flex, Box, FormField, Select, HR } from '@r1/ui-kit'

import type { UserLocalizationSettingsProps } from '../../types/UserLocalizationSettings'

import { formatDate } from '../../utils/formatDate'
import { TextReadOnly, LabelReadOnly } from '../common'

const EditableView = (props: UserLocalizationSettingsProps) => {
  const { disabled, dateFormat, timeZone } = props

  return (
    <Flex column minWidth={408} maxWidth={408} spaceBetween="XS">
      <H4>Localization Settings</H4>
      <Box>
        <FormField disabled={disabled}>
          <FormField.Label>Time Zone *</FormField.Label>
          <Select
            data-test-id="um-user-timezone-select"
            loading={timeZone.isLoading}
            value={timeZone.value || null}
            error={!!timeZone.error}
            labelKey="name"
            clearable={false}
            options={timeZone.availableOptions}
            onChange={timeZone.onChange}
          />
          <FormField.Error>{timeZone.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField>
          <FormField.Label>Date Format</FormField.Label>
          <Select
            data-test-id="um-user-dateformat-select"
            disabled={disabled}
            loading={dateFormat.isLoading}
            value={dateFormat.value || null}
            labelKey="name"
            options={dateFormat.availableOptions}
            onChange={dateFormat.onChange}
          />
        </FormField>
      </Box>
    </Flex>
  )
}

const ReadOnlyView = (props: UserLocalizationSettingsProps) => {
  const { dateFormat, timeZone } = props

  const dateFormatInfo = dateFormat.availableOptions.find(({ id }) => id === dateFormat.value)
  const dateFormatValue = dateFormatInfo
    ? `${dateFormatInfo.id} (${formatDate(new Date(), dateFormatInfo.id)})`
    : ''

  const timeZoneInfo = timeZone.availableOptions.find(({ id }) => id === timeZone.value)
  const timeZoneName = timeZoneInfo ? timeZoneInfo.name : ''

  return (
    <Flex column minWidth={408} maxWidth={408} spaceBetween="XS">
      <LabelReadOnly>Localization Settings</LabelReadOnly>
      <Flex column>
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Time Zone</FormField.Label>
          <TextReadOnly data-test-id="time-zone">{timeZoneName}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column>
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Date format</FormField.Label>
          <TextReadOnly data-test-id="date-format">{dateFormatValue}</TextReadOnly>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const UserLocalizationSettings = (props: UserLocalizationSettingsProps) => {
  const View = props.editable ? EditableView : ReadOnlyView

  return <View {...props} />
}
