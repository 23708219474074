// @flow

import { evolve, mergeLeft } from 'ramda'

import { NotificationSystem } from '@r1/ui-kit'

import type { WithController } from '../types'

export class FormActionHandlerController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  handleSubmitForm = async () => {
    const { order, orderLines, expectedDecisionTypeId, claimDescription } =
      this.component.state.claimCreation

    if (!order || !orderLines.filter(l => l.selected).length) {
      return
    }

    let claimId = null

    this.setFetchStatus({
      isClaimCreationExecuting: true,
    })
    try {
      claimId = await this.component.api.createClaim(
        order,
        orderLines,
        expectedDecisionTypeId,
        claimDescription,
      )
    } finally {
      this.setFetchStatus({
        isClaimCreationExecuting: false,
      })
    }

    if (claimId) {
      this.component.props.onFormSubmitted(claimId)
    }
  }

  handleCancelForm = async () => {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Claim Creation',
      message: 'Claim creation was canceled',
    })
    this.component.props.onFormCanceled()
  }

  setFetchStatus = (status: { isClaimCreationExecuting: boolean }) => {
    this.component.setState(
      evolve({
        claimCreation: mergeLeft(status),
      }),
    )
  }
}

export const createFormActionHandlerController = (component: WithController) =>
  new FormActionHandlerController(component)
