// @flow

import * as React from 'react'

import { Flex, Box } from '@r1/ui-kit'

const NotFound = () => (
  <Flex column>
    <Box>Type not found</Box>
  </Flex>
)

export { NotFound }
