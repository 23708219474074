import { mapToLazyEnum } from './mapToLazyEnum'

const PROFILE_TYPES = [
  'WalmartMarketplace',
  'WalmartDSV',
  'WalmartCanada',
  'HomeDepot',
  'WalmartInStore',
]

export const consigneeUpcVenueProfileTypes = mapToLazyEnum(PROFILE_TYPES)
