// @flow

import * as React from 'react'
import { FormField, Flex, HR } from '@r1/ui-kit'
import { Text } from '../Text'
import { TagViewList } from '../../../AttributeDefinitions/AttributeDefinitionView/AttributeDefinitionView'
import { NumberRange } from '../../../../utils/utils'
import type { NumberConstraint as NumberConstraintValue } from '../types'

type Props = {| value: NumberConstraintValue |}

export const NumberConstraint = ({ value }: Props) => (
  <>
    {(value.minAllowedValue || value.maxAllowedValue) && (
      <Flex column spaceBetween="S">
        <HR />
        <Flex spaceBetween="XXS">
          <FormField.Label>Allowed Numbers</FormField.Label>
          <Text>
            <NumberRange from={value.minAllowedValue} to={value.maxAllowedValue} />
          </Text>
        </Flex>
      </Flex>
    )}
    {value.forbiddenValues && (
      <Flex column spaceBetween="S">
        <HR />
        <Flex spaceBetween="XXS">
          <FormField.Label>Forbidden The Following</FormField.Label>
          <TagViewList list={value.forbiddenValues} />
        </Flex>
      </Flex>
    )}
  </>
)
