// @flow

import {
  createCategoryManagementApi,
  baseUri as categoryBaseUri,
} from '@r1-webui/productcatalog-categorymanagement-v1'

import {
  createCategoriesApi,
  baseUri as categoriesBaseUri,
} from '@r1-webui/productcatalog-categories-v1'

import {
  createCategoriesApi as createCategoriesPublicApi,
  baseUri as categoriesPublicBaseUri,
} from '@r1-webui/productcatalog-categories-public-v1'

import { UI_API_PREFIXES } from '../constants'

import type { WebUiApi } from './types'

export const categoryApi = (httpClient: any): {| ...WebUiApi |} => {
  const { addCategory, deleteCategory, getCategoryRestrictions, updateCategory } =
    createCategoryManagementApi({
      http: httpClient,
      urlPrefix: `${UI_API_PREFIXES.CATALOG_MANAGEMENT}/${categoryBaseUri}`,
    })

  const {
    generateCategoryHistoryReceivingToken,
    getCategoryInfo,
    getCategoryPath,
    getChildrenCategories,
    getDirectAttributeBindings,
    getInheritedAttributeBindings,
    getRootCategories,
    searchCategoriesByIds,
    searchCategoriesByName,
    getDirectFitments,
  } = createCategoriesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${categoriesBaseUri}`,
  })

  const { getAllCategories } = createCategoriesPublicApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${categoriesPublicBaseUri}`,
  })

  return {
    addCategory,
    updateCategory,
    deleteCategory,
    getCategoryRestrictions,
    generateCategoryHistoryReceivingToken,
    getCategoryInfo,
    getCategoryPath,
    getChildrenCategories,
    getDirectAttributeBindings,
    getInheritedAttributeBindings,
    getRootCategories,
    searchCategoriesByIds,
    searchCategoriesByName,
    getDirectFitments,
    getAllCategories,
  }
}
