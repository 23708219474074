// @flow

import * as React from 'react'
import { useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { ProfileListScreen } from '../components/ProfileListScreen'

import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

export const ProfileList = () => {
  const { purposeId } = useParams()
  return (
    <PageAccessControl permissions={[PERMISSIONS.allowManageProfilesView]}>
      <ProfileListScreen
        purposeId={purposeId}
        httpClient={httpClient}
        getProfileUrl={(profileId, profileTypeId) =>
          `/r1/admin/profile/${profileId}?profileTypeId=${profileTypeId || ''}`
        }
        getProfileCreationUrl={(id, profileTypeId) =>
          `/r1/admin/profiles/${id || ''}/${profileTypeId}/new`
        }
        onError={errorHandler}
      />
    </PageAccessControl>
  )
}
