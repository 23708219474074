// @flow

import * as React from 'react'
import { Format } from '@r1/ui-kit'

import type { AttributeDefinition } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'
import type { AttributeValue } from '@r1-webui/productcatalog-categories-v1/src/types'
import { NumberRange, DateRange } from '../../utils/utils'

type Props = {
  value: AttributeValue,
  definition: { ...AttributeDefinition },
}

type EnumValueProps = {|
  id: string,
  attribute: AttributeDefinition,
|}

function EnumValue({ id, attribute }: EnumValueProps) {
  const { enumValues } = attribute

  if (!enumValues) {
    return null
  }

  if (enumValues.valueType === 'DecimalEnumArray' || enumValues.valueType === 'IntEnumArray') {
    /**
     *
     * wtf union errors again %)
     *
     */
    const targetValue = (enumValues: any).enumValues.find(value => value.id === id)

    return targetValue ? <Format.Number>{parseFloat(targetValue.value)}</Format.Number> : null
  }

  const targetValue = enumValues.enumValues.find(value => value.id === id)

  return targetValue ? targetValue.value : null
}

export function FormatAttributeValue(props: Props) {
  switch (props.value.valueType) {
    case 'EnumValue':
      // $FlowFixMe[incompatible-type]
      return <EnumValue id={props.value.value} attribute={props.definition} />
    case 'IntRange':
      return <NumberRange {...props.value} />
    case 'DecimalRange':
      return <NumberRange {...props.value} />
    case 'DateValue':
      return <Format.Date>{props.value.value}</Format.Date>
    case 'BoolValue':
      return props.value.value ? 'Yes' : 'No'
    case 'IntValue':
    case 'DecimalValue':
      return <Format.Number>{Number(props.value.value)}</Format.Number>
    case 'DateRange':
      return <DateRange {...props.value} />
    default:
      return props.value.value
  }
}
