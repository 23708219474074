import * as React from 'react'
import { NotificationSystem, Text } from '@r1/ui-kit'
import { createReducer, createTypes } from '../../redux/utils'
import { confirm } from '../../redux/dialogs'
import { handleError } from '../utils'
import { productTemplateManufacturersApi } from '../../api/productTemplate/index.ts'

const initialState = {
  isFetched: true,
  data: null,
}

const types = createTypes(['setFetching', 'setData', 'addItem', 'removeItem'], 'manufacturers')

export default {
  manufacturers: createReducer(initialState, {
    [types.setFetching]: state => ({ ...state, isFetched: false }),
    [types.setData]: (state, payload) => ({ ...state, ...payload, isFetched: true }),
    [types.addItem]: (state, { id, name }) => ({ ...state, data: [{ id, name }, ...state.data] }),
    [types.removeItem]: (state, { id }) => ({
      ...state,
      data: state.data.filter(item => item.id !== id),
    }),
  }),
}

function fetchManufacturer(_api, id) {
  return productTemplateManufacturersApi.getManufacturer({ id }).then(({ body }) => body)
}

export function restoreManufacturer(id) {
  return async (dispatch, getState, api) => {
    if (!id || getState().manufacturers.data) return
    dispatch({ type: types.setFetching })
    const manufacturer = await fetchManufacturer(api, id)
    dispatch({ type: types.setData, data: [manufacturer] })
  }
}

export function searchManufacturers({ id, term }) {
  return async (dispatch, getState, api) => {
    let data = []

    if (id) {
      const manufactureresData = getState().manufacturers.data
      let currentManufacturer =
        manufactureresData && manufactureresData.find(manufacturer => manufacturer.id === id)

      if (!currentManufacturer) {
        dispatch({ type: types.setFetching })
        currentManufacturer = await fetchManufacturer(api, id)
      }

      data = [currentManufacturer]
    }

    if (!term || term.length < 2) return dispatch({ type: types.setData, data })

    dispatch({ type: types.setFetching })

    const result = await productTemplateManufacturersApi
      .searchManufacturers({ term })
      .then(({ body }) => body)

    dispatch({ type: types.setData, data: [...result] })
    return undefined
  }
}

export function addManufacturer(name) {
  return async (dispatch, _getState, _api) => {
    let id = null
    try {
      id = await productTemplateManufacturersApi
        .createManufacturer({ name })
        .then(({ body }) => body)

      dispatch({ type: types.addItem, id, name })
      NotificationSystem.addNotification({
        level: 'success',
        title: `Manufacturer ${name} added successfully`,
      })
    } catch (error) {
      handleError(error)
    }
    return id
  }
}

export function removeManufacturer({ id, name }) {
  return async (dispatch, _getState, _api) => {
    if (
      !(await confirm(
        <Text>
          Are you sure you want to delete manufacturer <b>{name}</b>?
        </Text>,
      ))
    )
      return false
    try {
      await productTemplateManufacturersApi.deleteManufacturer({ id })
    } catch (error) {
      handleError(error)
      return false
    }
    dispatch({ type: types.removeItem, id })
    NotificationSystem.addNotification({
      level: 'success',
      title: `Manufacturer ${name} deleted successfully`,
    })
    return true
  }
}
