// @flow

import type { NavigateFunction } from 'react-router-dom'

const shouldUseBrowserHistory = (nextPathName: string) => {
  const isSpaPath = (pathName: string) => /^\/r1(v2)?\//.test(pathName)
  return isSpaPath(window.location.pathname) && isSpaPath(nextPathName)
}

export const redirect = (nextPathName: string, navigate?: NavigateFunction) => {
  if (navigate && shouldUseBrowserHistory(nextPathName)) {
    navigate(nextPathName)
  } else {
    window.location.href = nextPathName
  }
}
