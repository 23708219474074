// @flow

import * as React from 'react'

import { withTheme, Link, Text, Flex } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'

import { ProfileTemplatesCard } from '../ProfileListScreen'

const AdditionalLink = ({ id }) => {
  if (id === 'TaxCommitmentProfile')
    return (
      <div>
        <Link to="/r1/admin/profiles/tax/mapping">Manage bindings to Marketplace profiles</Link>
      </div>
    )

  return null
}

type PurposeComponentProps = {|
  id: string,
  name: string,
  icon: string,
  text: string,
  url: string,
  getProfileCreationUrl: (purposeId: string, profileTypeId: string) => string,
  profileTypesData: any,
|}

const PurposeComponent = ({
  name,
  text,
  icon,
  profileTypesData = [],
  id,
  url,
  getProfileCreationUrl,
  theme,
}) => (
  <Flex spaceBetween="L">
    <div style={{ color: theme.primaryColor[600] }}>
      <GenericIcon width={60} svgId={icon} />
    </div>
    <Flex column spaceBetween="M">
      <Text type="paragraph" color={theme.primaryColor[600]}>
        <Link to={url}>{name}</Link>
      </Text>
      <Text weight="light">{text}</Text>
      <AdditionalLink id={id} />
      <Flex column spaceBetween="S">
        <Text weight="bold">Choose the {name.toLowerCase()} to proceed.</Text>
        <ProfileTemplatesCard
          purposeId={id}
          items={profileTypesData}
          getProfileCreationUrl={getProfileCreationUrl}
        />
      </Flex>
    </Flex>
  </Flex>
)

export const PurposeCard = withTheme<PurposeComponentProps, mixed>(PurposeComponent)
