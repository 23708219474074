import * as PIXI from 'pixi.js'

export default (transform, getBlock, spaceBetween = 0) => {
  // does not support horizontal repeat, change axis from y to x for it
  const container = new PIXI.Container()
  let tempOffset = 0

  while (true) {
    const itemDisplayObject = getBlock()

    itemDisplayObject.y += tempOffset

    const bounds = itemDisplayObject.getBounds()
    // const height = spaceBetween < 0 ? transform.size.height - bounds.height : transform.size.height
    tempOffset += bounds.height
    // debugger
    if (
      Math.abs(tempOffset) > transform.size.height ||
      Math.abs(itemDisplayObject.y) > transform.size.height
    ) {
      break
    }
    tempOffset += spaceBetween
    container.addChild(itemDisplayObject)
  }

  return container
}
