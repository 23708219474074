/* eslint-disable default-case */
// @flow

import * as R from 'ramda'
import {
  fieldsSchema,
  identifiersSchema,
  partsSchema,
  attributesSchema,
  categorySchema,
  marketplaceCategorySchema,
  imagesSchema,
  variationsSchema,
} from '../../services/productService/schemas'
import { fieldsSchema as variationsFieldsSchema } from '../../services/variationsService/schemas'
import { serializeErrors } from '../../services/serialize/serializeErrors'
import { toObject } from '../../utils/toObject'
import { replaceTemplateValues, showNotification } from '../../../../utils'

function isErrorResponse(response: mixed): %checks {
  return (
    typeof response === 'object' &&
    response !== null &&
    !Array.isArray(response) &&
    response.status !== 200
  )
}

function bodyHasProperties(body: mixed, key: string): %checks {
  return (
    body !== null && typeof body === 'object' && Object.prototype.hasOwnProperty.call(body, key)
  )
}

const defaultPermissionErrorHandler = () =>
  showNotification({ title: 'Permissions Denied', level: 'error' })

export function imageProcessResponse(response: any, images: any) {
  switch (response.status) {
    case 200: {
      const { body } = response
      const errorFields = { Images: [] }

      body.forEach(({ type, error }, index) => {
        if (type !== 'UploadImageSuccessResult') {
          const findImage = images[index]
          if (findImage) {
            errorFields.Images[findImage.index] = { url: replaceTemplateValues(error) }
          }
        }
      })

      return { ...response, errorFields: errorFields.Images.length > 0 ? errorFields : undefined }
    }
    case 401:
      showNotification({ title: 'Unauthorized', level: 'error' })
      break
    case 400: {
      const { body } = response

      if (bodyHasProperties(body, 'badProperties') && Array.isArray(body.badProperties)) {
        const { badProperties } = body

        const errorFields = { Images: [] }

        badProperties.forEach((notice: any) => {
          const pathArray = notice.property.replace(/\[|\]/g, '').split('.')
          const findImage = images[Number(pathArray[1])]

          if (findImage) {
            errorFields.Images[findImage.index] = { url: replaceTemplateValues(notice) }
          }
        })
        return { ...response, errorFields }
      }
      break
    }
    default:
  }
  return response
}

export function variationsProcessResponse<T, D>(
  response: T,
  request: D,
  permissionErrorHandler: () => void = defaultPermissionErrorHandler,
): T {
  if (isErrorResponse(response)) {
    switch (response.status) {
      case 403:
        permissionErrorHandler()
        break
      case 401:
        showNotification({ title: 'Unauthorized', level: 'error' })
        break
      case 400: {
        const { body } = response

        if (bodyHasProperties(body, 'badProperties') && Array.isArray(body.badProperties)) {
          const { badProperties } = body

          let errorFields = {
            ProductsFilter: { productIdentifierValues: '' },
            Variations: { attributeDefinitionIds: '' },
          }

          badProperties.forEach((notice: any) => {
            const pathArray = notice.property.replace(/\[|\]/g, '').split('.')
            const field = pathArray[pathArray.length - 1]
            const block = pathArray[1]
            switch (block) {
              case 'productsFilter':
                errorFields.ProductsFilter = {
                  productIdentifierValues: replaceTemplateValues(notice),
                }
                break
              case 'variations':
                errorFields.Variations = {
                  attributeDefinitionIds: replaceTemplateValues(notice),
                }
                break
              default:
                errorFields = {
                  ...errorFields,
                  ...serializeErrors({ [field]: { error: notice } }, variationsFieldsSchema),
                }
                // @TODO: Remove after Anton M will clean images
                if (!pathArray[2]) {
                  errorFields = {
                    ...errorFields,
                    Images: replaceTemplateValues(notice),
                  }
                }
                break
            }
          })

          return { ...response, errorFields }
        }
        break
      }
      case 422: {
        const { body } = response
        const errorFields = {}

        if (
          bodyHasProperties(body, 'productsFilter') &&
          body.productsFilter &&
          body.productsFilter.code === 'ProductAlreadyInOtherFamily'
        ) {
          errorFields.ProductsFilter = {
            productIdentifierValues: 'Product already bounded in other variation',
          }
        }
        return { ...response, errorFields }
      }
    }
  }

  return response
}

export function processResponse<T, D>(
  response: T,
  request: D,
  permissionErrorHandler: () => void = defaultPermissionErrorHandler,
): T {
  if (isErrorResponse(response)) {
    switch (response.status) {
      case 403:
        permissionErrorHandler()
        break
      case 401:
        showNotification({ title: 'Unauthorized', level: 'error' })
        break
      case 400: {
        const { body } = response

        if (bodyHasProperties(body, 'badProperties') && Array.isArray(body.badProperties)) {
          const { badProperties } = body

          let errorFields = {}

          badProperties.forEach((notice: any) => {
            const pathArray = notice.property.replace(/\[|\]/g, '').split('.')
            const block = pathArray[1]

            switch (block) {
              case 'fields':
                {
                  const field = R.path(pathArray.slice(0, -1), request)

                  if (field)
                    errorFields = {
                      ...errorFields,
                      ...serializeErrors({ [field.type]: { error: notice } }, fieldsSchema),
                    }
                }
                break
              case 'category':
                errorFields = {
                  ...errorFields,
                  ...serializeErrors({ CategoryId: { error: notice } }, categorySchema),
                }

                break
              case 'attributes':
                errorFields = {
                  ...errorFields,
                  ...serializeErrors(
                    [
                      {
                        error: notice,
                        index: Number(pathArray[2]),
                      },
                    ],
                    attributesSchema,
                  ),
                }

                break
              case 'identifiers':
                errorFields = {
                  ...errorFields,
                  ...serializeErrors(
                    [
                      {
                        error: notice,
                        index: Number(pathArray[2]),
                      },
                    ],
                    identifiersSchema,
                  ),
                }

                break
              case 'parts':
                errorFields = {
                  ...errorFields,
                  ...serializeErrors(
                    [
                      {
                        error: notice,
                        index: Number(pathArray[2]),
                      },
                    ],
                    partsSchema,
                  ),
                }

                break
              case 'marketplaceCategoryMappings':
                errorFields = {
                  ...errorFields,
                  ...serializeErrors(
                    [
                      {
                        error: notice,
                        index: Number(pathArray[2]),
                      },
                    ],
                    marketplaceCategorySchema,
                  ),
                }

                break
              case 'images':
                // @TODO: Remove after Anton M will clean images
                if (!pathArray[2]) {
                  errorFields = {
                    ...errorFields,
                    Images: replaceTemplateValues(notice),
                  }
                } else {
                  errorFields = {
                    ...errorFields,
                    ...serializeErrors(
                      [
                        {
                          error: notice,
                          index: Number(pathArray[2]),
                        },
                      ],
                      imagesSchema,
                    ),
                  }
                }

                break
              default:
                break
            }
          })

          return { ...response, errorFields }
        }
        break
      }
      case 422: {
        const { body } = response

        let errorFields = {}

        if (bodyHasProperties(body, 'fields') && Array.isArray(body.fields)) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(toObject(body.fields, ['field', 'type']), fieldsSchema),
          }
        }

        if (bodyHasProperties(body, 'category')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors({ CategoryId: body.category }, categorySchema),
          }
        }

        if (bodyHasProperties(body, 'identifiers')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(body.identifiers, identifiersSchema),
          }
        }

        if (bodyHasProperties(body, 'parts')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(body.parts, partsSchema),
          }
        }

        if (bodyHasProperties(body, 'attributes')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(body.attributes, attributesSchema),
          }
        }

        if (bodyHasProperties(body, 'images')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(body.images, imagesSchema),
          }
        }

        if (bodyHasProperties(body, 'marketplaceCategoryMappings')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors(body.marketplaceCategoryMappings, marketplaceCategorySchema),
          }
        }

        if (bodyHasProperties(body, 'family')) {
          errorFields = {
            ...errorFields,
            ...serializeErrors({ VariationId: body.family }, variationsSchema),
          }
        }

        return { ...response, errorFields }
      }
    }
  }

  return response
}
