import * as React from 'react'
import { path } from 'ramda'
import { NotificationSystem, Text } from '@r1/ui-kit'
import { createReducer, createTypes } from '../../redux/utils'
import { confirm } from '../../redux/dialogs'
import { handleError } from '../utils'
import { productTemplateModelsApi } from '../../api/productTemplate/index.ts'

const initialState = {
  isInitial: false,
  isFetched: true,
  data: [],
}

const types = createTypes(
  ['setFetching', 'setInitialData', 'setData', 'addItem', 'removeItem'],
  'productModels',
)

export default {
  productModels: createReducer(initialState, {
    [types.setFetching]: state => ({ ...state, isFetched: false }),
    [types.setInitialData]: (state, { data }) => ({
      ...state,
      data,
      isInitial: true,
      isFetched: true,
    }),
    [types.setData]: (state, { data }) => ({
      ...state,
      data: data || [],
      isInitial: false,
      isFetched: true,
    }),
    [types.addItem]: (state, { id, name }) => ({
      ...state,
      data: [{ id, name }, ...(state.data || [])],
    }),
    [types.removeItem]: (state, { id }) => ({
      ...state,
      data: state.data.filter(item => item.id !== id),
    }),
  }),
}

export function restoreProductModel({ productModelId }) {
  return async (dispatch, getState, _api) => {
    const modelData = path(['productModels', 'data'], getState())
    if (!productModelId || (Array.isArray(modelData) && modelData.length > 0)) return

    dispatch({ type: types.setFetching })

    let model = {}
    try {
      model = await productTemplateModelsApi
        .getProductModel({ id: productModelId })
        .then(({ body }) => body)
    } catch (error) {
      handleError(error)
    } finally {
      dispatch({ type: types.setInitialData, data: [model] })
    }
  }
}

export function fetchProductModels({ manufacturerId }) {
  return async (dispatch, _getState, _api) => {
    if (!manufacturerId) return

    dispatch({ type: types.setFetching })
    let data = []
    try {
      data = await productTemplateModelsApi
        .getProductModelsByManufacturer({ manufacturerId })
        .then(({ body }) => body)
    } catch (error) {
      handleError(error)
    } finally {
      dispatch({ type: types.setData, data })
    }
  }
}

export function addProductModel({ name, manufacturerId, categoryId }) {
  return async (dispatch, _getState, _api) => {
    let id = null
    try {
      id = await productTemplateModelsApi
        .createProductModel({
          name,
          manufacturerId,
          categoryId,
        })
        .then(({ body }) => body)

      dispatch({ type: types.addItem, id, name })

      NotificationSystem.addNotification({
        level: 'success',
        title: `Model ${name} added successfully`,
      })
    } catch (error) {
      handleError(error)
    }
    return id
  }
}

export function removeProductModel({ id, name }) {
  return async (dispatch, _getState, _api) => {
    if (
      !(await confirm(
        <Text>
          Are you sure you want to delete model <b>{name}</b>?
        </Text>,
      ))
    )
      return
    await productTemplateModelsApi.deleteProductModel({ id })
    dispatch({ type: types.removeItem, id })

    NotificationSystem.addNotification({
      level: 'success',
      title: `Model ${name} deleted successfully`,
    })
  }
}
