import styled from '@emotion/styled'
import { css } from '@emotion/css'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 162px;
  margin: 15px 10px;
  position: relative;

  ${({ theme }) => css`
    border-radius: ${theme.space.XXS};
    padding: ${theme.space.M} 0 ${theme.space.S} 0;
    border: 1px solid ${theme.palette.grey[300]};
    background: ${theme.palette.white};

    userselect: none;
    cursor: grab;

    &:active: {
      cursor: grabbing;
    }

    &.main {
      border-color: ${theme.palette.blue[500]};
    }

    &.error {
      border-color: ${theme.palette.red[800]};
    }
  `};
`

const RemoveButtonContainer = styled('button')`
  cursor: pointer;
  border-radius: 50%;
  opacity: 0.7;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.space.XL};
    height: ${theme.space.XL};
    background-color: ${theme.palette.grey[900]};
  `};
`

const MainImage = styled('div')`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.palette.grey[700]};
    height: ${theme.space.XL};
  `};
`

const Relative = styled('div')`
  position: relative;
`

const Error = styled('div')`
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  width: 180px;

  ${({ theme }) => css`
    color: ${theme.palette.red[800]};
  `};
`

const Absolute = styled('div')`
  position: absolute;
  top: ${props => props.top || 'initial'};
  left: ${props => props.left || 'initial'};
  right: ${props => props.right || 'initial'};
  bottom: ${props => props.bottom || 'initial'};
`

export { Absolute, Error, Relative, MainImage, RemoveButtonContainer, Container }
