import { MasterProgramRule } from '@r1-webui/masterprograms-v1'
import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import { showNotification } from '../../../utils/notifications/showNotification'
import {
  replaceTemplateValues,
  replaceTemplateValuesBadProperties,
} from '../../../utils/responseUtils'
import { masterProgramRulesApi } from './masterProgramRulesApi'

export async function createMasterProgramRule(data: MasterProgramRule): Promise<boolean> {
  const response = await masterProgramRulesApi.createMasterProgramRule(data)

  if (response.status === 400) {
    NotificationSystem.addNotification({
      level: 'error',
      message: replaceTemplateValuesBadProperties(response.body.badProperties),
      autoDismiss: false,
    })
    return false
  }

  if (response.status === 422) {
    NotificationSystem.addNotification({
      level: 'error',
      message: response.body.errorText,
      autoDismiss: false,
    })
    return false
  }

  if (response.status === 409) {
    NotificationSystem.addNotification({
      level: 'error',
      message: replaceTemplateValues(response.body),
      autoDismiss: false,
    })
    return false
  } else if (response.status !== 200) {
    handleServerError(response)
    return false
  }

  showNotification({
    level: 'success',
    message: `Successfully created Master Program rule with id ${response.body.ruleId}`,
  })
  return true
}
