// @flow

import * as React from 'react'
import { Flex, FormField, NumericInput, Text } from '@r1/ui-kit'
import { useCallback, useState } from 'react'

type Props = {
  onRefundChange: (val: string) => void,
  defaultValue: string,
  maxValue: string,
  currency: string,
}

export const RefundEditor = (props: Props) => {
  const { onRefundChange, defaultValue, maxValue, currency } = props
  const [value, setValue] = useState(defaultValue)

  const onChange = useCallback(
    (val: string | null) => {
      setValue(val)
      onRefundChange(val ?? '')
    },
    [onRefundChange],
  )

  return (
    <FormField>
      <FormField.Label>Refund:</FormField.Label>
      <Flex justify="space-between" align="center">
        <Flex minWidth={40}>
          <Text>{currency}</Text>
        </Flex>
        <NumericInput
          data-test-id="refund"
          value={value}
          min={0}
          max={parseFloat(maxValue)}
          maxFractionDigits={2}
          minFractionDigits={2}
          onChange={onChange}
        />
      </Flex>
    </FormField>
  )
}
