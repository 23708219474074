import { Action } from '../constants'

export const undo = () => {
  return {
    type: Action.UNDO,
  }
}

export const redo = () => {
  return {
    type: Action.REDO,
  }
}
