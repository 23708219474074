// @flow

import * as React from 'react'

import type { Price } from '@r1-webui/productprices-prices-v1/src/types'
import { createApi as createCategoryApi } from '../../Category/api'
import { createApi as createProductApi } from '../api/product'
import { createAttributeDefinitionsApi } from '../../AttributeDefinitions'
import type {
  ProductGeneralInfo,
  ProductDimensionsInfo,
  ProductIdentifiersInfo,
  ProductImagesInfo,
  AttributesInfo,
  ProductCardControllerProps,
} from '../../../types/public/ProductCardController'

import { productApi } from '../../../api/product/productApi'
import { priceService, productService } from '../../../services'
import { priceApi } from '../../../api'
import { createPropsProvider } from './propsProvider'
import { defaultState } from './defaultState'

type ScreenType = 'default' | 'placeholder' | 'forbidden' | 'error'
type State = {|
  screenType: ScreenType,
  generalInfo: ProductGeneralInfo,
  dimensionsInfo: ProductDimensionsInfo,
  identifiersInfo: ProductIdentifiersInfo,
  attributesInfo: AttributesInfo,
  imagesInfo: ProductImagesInfo,
  companyPrices: Price[],
  aggregatedPrices: Price[],
  marketplaceMappingsInfo: any,
  disabledEdit: null | 'locked' | 'putOnHoldOn',
|}

// See: https://trg-jira.atlassian.net/wiki/spaces/AR/pages/1129810126/R1.CompanyProductPricesService+version+1
const COMPANY_PRICE_ID = '627ab30bd60f49ee9a6bf1f47110c05b'
const AGGREGATED_PRICE_ID = 'c31c51dd94fd43d0beb94212913d8c3b'

export class ProductCardController extends React.Component<ProductCardControllerProps, State> {
  state = defaultState

  productApiService = productService(productApi(this.props.httpClient))

  priceApiService = priceService(priceApi(this.props.httpClient))

  api = {
    ...createProductApi(this.props.httpClient),
    ...createAttributeDefinitionsApi(this.props.httpClient),
    ...createCategoryApi(this.props.httpClient),
  }

  componentDidMount() {
    this.fetchInfo()
  }

  componentDidUpdate({ productId }: ProductCardControllerProps) {
    if (productId !== this.props.productId) this.fetchInfo()
  }

  errorHandler = (screenType: ScreenType = 'forbidden') => this.setState({ screenType })

  fetchInfo = async () => {
    const { fetchProduct } = this.productApiService
    const { getAllDataSources } = this.priceApiService
    const { productId } = this.props

    const allDataSources = await getAllDataSources()
    // test
    fetchProduct(productId)
      .then(({ prices, product, mapping: marketplaceMappingsInfo, categoryTrees }) => {
        const {
          categoryId,
          attributes,
          images,
          identifiers,
          serializedFields,
          lockInfo,
          putOnHoldOn,
          partNumber,
        } = product

        const disabledEdit =
          // eslint-disable-next-line no-nested-ternary
          lockInfo != null ? 'locked' : putOnHoldOn != null ? 'putOnHoldOn' : null

        const propsProvider = createPropsProvider(this.api, this.errorHandler)
        return Promise.all([
          prices,
          marketplaceMappingsInfo
            .map(marketplace => {
              let obj = {}
              const dataSources = allDataSources.find(({ id }) => marketplace.dataSourceId === id)

              const isMarketplace = categoryTrees.find(
                ({ categoryTreeId: marketplaceTreeId }) =>
                  marketplaceTreeId === marketplace.categoryTreeId,
              )

              if (dataSources) {
                obj = {
                  isMarketplace: !!isMarketplace,
                  marketplace: dataSources.title,
                  categoryPath: marketplace.categories.map(item => item.name).reverse(),
                }
              }

              return obj
            })
            .sort((a, b) => a.marketplace.localeCompare(b.marketplace)),
          disabledEdit,
          propsProvider.getMappedProps(
            images,
            identifiers,
            attributes,
            { ...serializedFields, PartNumberField: partNumber },
            categoryId || '',
          ),
        ])
      })
      .then(([prices, marketplaceMappingsInfo, disabledEdit, mappedProps]) => {
        const companyPrices: Array<Price> = []
        const aggregatedPrices: Array<Price> = []
        prices.forEach(price => {
          // eslint-disable-next-line default-case
          switch (price.rule.type.id) {
            case COMPANY_PRICE_ID:
              companyPrices.push(price)
              break
            case AGGREGATED_PRICE_ID:
              aggregatedPrices.push(price)
              break
          }
        })
        this.setState({
          ...mappedProps,
          disabledEdit,
          companyPrices,
          aggregatedPrices,
          marketplaceMappingsInfo,
          screenType: 'default',
        })
      })
      .catch(() => this.errorHandler('error'))
  }

  render() {
    const { children } = this.props
    const {
      screenType,
      generalInfo,
      imagesInfo,
      identifiersInfo,
      dimensionsInfo,
      attributesInfo,
      companyPrices,
      aggregatedPrices,
      marketplaceMappingsInfo,
      disabledEdit,
    } = this.state

    switch (screenType) {
      case 'default':
        return children({
          screenType: 'default',
          generalInfo,
          imagesInfo,
          identifiersInfo,
          dimensionsInfo,
          attributesInfo,
          companyPrices,
          aggregatedPrices,
          marketplaceMappingsInfo,
          disabledEdit,
        })
      case 'forbidden':
        return children({ screenType: 'forbidden' })
      case 'error':
        return children({ screenType: 'error' })
      default:
        return children({ screenType: 'placeholder' })
    }
  }
}
