import React from 'react'
import styled from '@emotion/styled'
import { Flex, Loader } from '@r1/ui-kit'

const ReportsLoaderContainer = styled(Flex)`
  width: 100%;
  height: 100%;
`

export function ReportsLoader() {
  return (
    <ReportsLoaderContainer justify="center" align="center">
      <Loader />
    </ReportsLoaderContainer>
  )
}
