import React from 'react'
import { Flex, Link } from '@r1/ui-kit'
import { Content, ContentHeader, Main } from '@r1/wireframe-primary'
import { useAccessControl } from '@r1/core-blocks'
import { ROUTES } from '../../../../navigation'
import { GWS_STATIC_PAGE_EDITOR_URL } from '../../const'

export const GoWholesaleRootScreen = () => {
  const [allowPermissions] = useAccessControl()

  return (
    <Main>
      <ContentHeader>
        <ContentHeader.Title>goWholesale</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <Flex column spaceBetween="M">
          {allowPermissions.allowGwsHomePageEditorView ? (
            <Link href={ROUTES.GOWHOLESALE_HOME_PAGE_EDITOR}>Homepage Editor</Link>
          ) : null}

          {allowPermissions.allowGwsNotificationsView ? (
            <Link href={ROUTES.GOWHOLESALE_NOTIFICATIONS}>Notifications</Link>
          ) : null}

          <Link href={GWS_STATIC_PAGE_EDITOR_URL} target="_blank">
            Privacy Policy and Terms of Use Editor
          </Link>
        </Flex>
      </Content>
    </Main>
  )
}
