import { useState, useEffect } from 'react'
import { NotificationSystem } from '@r1/ui-kit'
import { historyApi } from '../../../api/productHistory'

export type Option = { id: string; name: string }

export const useHistoryActions = () => {
  const [actions, setActions] = useState<Option[]>([])

  const fetchActions = async () => {
    try {
      const response = await historyApi.getAvailableHistoryActions()
      const result = response.map(x => ({ id: String(x.id), name: x.name }))
      setActions(result)
    } catch (error) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Error',
        message: 'Unhandled error',
      })
    }
  }

  useEffect(() => {
    fetchActions()
  }, [])

  return [actions]
}
