import * as React from 'react'

import type { Lib$Optional } from '@r1/types/typescript'
import { Input, Flex, Box, FormField, Textarea, Placeholder, HR } from '@r1/ui-kit'

import { TextReadOnly } from '../../../../../components/common'

import { Field } from '../../types'

type BaseProps = {
  name: Field<string>
  description: Field<string>
}

type ReadOnlyViewProps = BaseProps

type EditableViewProps = BaseProps & {
  disabled: boolean
}

type BaseRoleInfoProps = EditableViewProps & {
  editable?: Lib$Optional<boolean, false>
  loading: boolean
}

const ReadOnlyView: React.FC<ReadOnlyViewProps> = ({ name, description }) => (
  <Flex column>
    <Flex column>
      <HR />
      <Flex spaceBetween="S" mt="S" mb="S">
        <FormField.Label>Name</FormField.Label>
        <TextReadOnly>{name.value}</TextReadOnly>
      </Flex>
      <HR />
    </Flex>
    <Flex spaceBetween="S" mt="S">
      <FormField.Label>Description</FormField.Label>
      <TextReadOnly>{description.value}</TextReadOnly>
    </Flex>
  </Flex>
)

const EditableView: React.FC<EditableViewProps> = ({ name, description, disabled = false }) => (
  <Flex column>
    <Box>
      <FormField>
        <FormField.Label>Name</FormField.Label>
        <Input
          disabled={disabled}
          value={name.value}
          error={Boolean(name.error)}
          onChange={name.onChange}
        />
        <FormField.Error>{name.error}</FormField.Error>
      </FormField>
    </Box>
    <Box>
      <FormField>
        <FormField.Label>Description</FormField.Label>
        <Textarea
          rows={2}
          disabled={disabled}
          value={description.value}
          error={Boolean(description.error)}
          onChange={description.onChange}
        />
        <FormField.Error>{description.error}</FormField.Error>
      </FormField>
    </Box>
  </Flex>
)

export const BaseRoleInfo: React.FC<BaseRoleInfoProps> = ({
  name,
  description,
  disabled,
  editable = false,
  loading,
}) => {
  if (loading) {
    return <Placeholder type="form" height={2} />
  }
  if (editable) {
    return <EditableView name={name} description={description} disabled={disabled} />
  }
  return <ReadOnlyView name={name} description={description} />
}
