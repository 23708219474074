// @flow

import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { FormManager } from '@r1/form-manager'
import {
  Flex,
  NotificationSystem,
  Placeholder,
  H2,
  Container,
  Row,
  Col,
  PageNavigation,
} from '@r1/ui-kit'
import { ServiceContext } from '../../provider'
import { clientValidate } from '../../../../utils/validation'
import type {
  ProductEditProps,
  ScreenType,
  SubmitForReviewFormValue,
} from '../../types/product.type'
import {
  BasicInfo,
  Dimensions,
  Identifiers,
  Images,
  Attributes,
  MarketplaceMapping,
  FooterPanel,
  HeaderBlock,
  LockModal,
  Parts,
  FitsFor,
} from './children'
import { schema } from './schema'

const RIN_ID = '83cfc7ab81fe4ac298d88768dbef82d7'

const getLinks = () => {
  const links = [
    { to: 'overview', name: 'Overview', offset: -150 },
    { to: 'general', name: 'General Information', offset: -150 },
    { to: 'dimension', name: 'Dimensions', offset: -150 },
    { to: 'parts', name: 'Parts', offset: -150 },
    { to: 'fitsFor', name: 'Fits For', offset: -150 },
    { to: 'attributes', name: 'Attributes', offset: -150 },
    { to: 'identifiers', name: 'Product Identifiers', offset: -150 },
    { to: 'categoryMapping', name: 'Category Mapping', offset: -150 },
    { to: 'images', name: 'Images', offset: -150 },
  ]
  return links
}

const LinkContainer = styled('div')`
  position: fixed;
`

export const ProductEdit = memo<ProductEditProps>((props: ProductEditProps) => {
  const {
    onSuccessfulSubmit,
    productId,
    onChangeScreenType,
    children,
    permissions,
    getProductDetailsUrl,
  } = props

  const {
    productService: {
      fetchProduct,
      updateProduct,
      rescrapeProductData,
      lockProduct,
      unlockProduct,
      updateProductApprovalState,
    },
    imageService: { prepareAndUploadImages },
    priceService: { getAllDataSources },
    referenceService: {
      getAllDimensionMeasures,
      getAllWeightMeasures,
      getProductIdentifierTypes,
      getLockReasons,
      getAllReviewReasons,
      getUsers,
    },
  } = useContext(ServiceContext)

  const [screenType, setScreenType] = useState('default')
  const [isLoading, setIsLoading] = useState(false)
  const [isRescrapingInProcess, setRescrapingInProcess] = useState(false)
  const [isLock, setIsLock] = useState(false)
  const [footerInfo, setFooterInfo] = useState({ type: '' })
  const [primaryIdentifier, setPrimaryIdentifier] = useState('')
  const [approvalType, setApprovalType] = useState('')
  const [identifiersList, setIdentifiersList] = useState(null)
  const [dimensionMeasuresList, setDimensionMeasuresList] = useState([])
  const [weightMeasuresList, setWeightMeasuresList] = useState([])
  const [reasonsList, setReasonsList] = useState([])
  const [version, setVersion] = useState(null)
  // eslint-disable-next-line
  const [pricesIdentifiers, setPricesIdentifiers] = useState([])
  const [reviewReasonsList, setReviewReasonsList] = useState([])

  const [attributeValueType, setAttributeValueType] = useState([])
  const [attributesInitValue, setAttributesInitValue] = useState([])

  const [disabled, setDisabled] = useState(false)
  const [showLockModal, setIsShowLockModal] = useState(false)

  const changeScreenType = useCallback(
    (screen: ScreenType) => {
      onChangeScreenType(screen)
      setScreenType(screen)
    },
    [onChangeScreenType],
  )

  const [productFormValues, setProductFormValues] = useState({
    PackageDimensionsField: { lengthField: 0, width: 0, height: 0, measure: 'Inch' },
    PackageWeightField: { amount: 0, measure: 'Lb' },
    ProductDimensionsField: { lengthField: 0, width: 0, height: 0, measure: 'Inch' },
    ProductWeightField: { amount: 0, measure: 'Lb' },
    TitleField: '',
    RetailPriceField: { amount: 0, currency: '', lastUpdateDate: null },
    LongDescriptionField: '',
    ShortDescriptionField: '',
    TaxCodeField: '',
    SupportingUrlField: '',
    CountryOfOriginField: { code: '' },
    ManufacturerField: { name: '', id: '' },
    ModelField: { name: '', id: '' },
    Identifiers: [],
    Attributes: [],
    Images: [],
    Parts: [],
    FitsFor: [],
    CategoryId: null,
    MarketplaceCategoryMappingsValues: [],
    VariationId: null,
    PartNumber: '',
  })

  const fetchData = useCallback(async () => {
    changeScreenType('placeholder')
    try {
      const [
        dimensionMeasures,
        reviewReasons,
        weightMeasures,
        allDataSources,
        identifierTypes,
        { product, mapping, categoryAllTrees, categoryTrees },
        reasons,
      ] = await Promise.all([
        getAllDimensionMeasures(),
        getAllReviewReasons(),
        getAllWeightMeasures(),
        getAllDataSources(),
        getProductIdentifierTypes(),
        fetchProduct(productId),
        getLockReasons(),
      ])

      const {
        serializedFields,
        version: versionData,
        serializedImages,
        serializedIdentifiers,
        serializedAttributes,
        serializedCategory,
        serializedVariation,
        serializedParts,
        serializedFitsFor,
        lockInfo,
        putOnHoldOn,
        approvalState,
      } = product

      // if product are locked, all fields should be disabled
      setDisabled(lockInfo || putOnHoldOn)
      setIsLock(!!lockInfo)

      if (lockInfo) {
        const lockDataInfo = {
          type: 'lock',
          date: lockInfo.lockedOn,
          user: '',
          description: '',
        }

        const users = await getUsers([lockInfo.lockedBy])

        if (users && users.length > 0) {
          lockDataInfo.user = users[0].name
        }

        if (lockInfo.lockReason) {
          const reason = reasons.find(({ id }) => id === lockInfo.lockReason.id)

          if (reason) {
            lockDataInfo.description = `${reason.title} ${lockInfo.lockReasonDescription || ''}`
          }
        }

        setFooterInfo(lockDataInfo)
      }

      setPrimaryIdentifier(
        serializedIdentifiers.Identifiers.find(identifier => identifier.isPrimary).value,
      )
      setPricesIdentifiers(serializedIdentifiers.Identifiers.map(({ value }) => value))
      setApprovalType(approvalState.type)

      if (versionData) {
        setVersion(versionData.currentVersion)
      }

      setReasonsList(reasons)
      setDimensionMeasuresList(dimensionMeasures)
      setWeightMeasuresList(weightMeasures)
      setReviewReasonsList(reviewReasons)

      if (identifierTypes.body && identifierTypes.body.length > 0) {
        setIdentifiersList(
          identifierTypes.body
            .map(({ id, name: { defaultFormat: name } }) => ({ id, name }))
            /** TODO remove this hardcode in next sprint */
            .filter(({ id }) => id !== RIN_ID),
        )
      }

      setAttributesInitValue(serializedAttributes.Attributes)

      setProductFormValues({
        ...serializedVariation,
        ...serializedFields,
        ...serializedCategory,
        ...serializedIdentifiers,
        ...serializedAttributes,
        ...serializedParts,
        ...serializedFitsFor,
        ...serializedImages,
        MarketplaceCategoryMappingsValues: categoryAllTrees
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ dataSourceId, id: categoryTreeId }, index) => {
            const { title } = allDataSources.find(({ id }) => dataSourceId === id)
            const mp = mapping.find(
              ({ categoryTreeId: mappingTreeId }) => mappingTreeId === categoryTreeId,
            )

            const isMarketplace = categoryTrees.find(
              ({ categoryTreeId: marketplaceTreeId }) => marketplaceTreeId === categoryTreeId,
            )

            return {
              index,
              title,
              categoryTreeId,
              isMarketplace: !!isMarketplace,
              autoUpdate: mp ? mp.autoUpdate : true,
              categoryId: mp ? mp.categoryId : null,
              categories: mp ? mp.categories : [],
            }
          }),
      })
      changeScreenType('default')
    } catch (error) {
      changeScreenType(error.message)
      if (error.message === 'notFound')
        NotificationSystem.addNotification({
          level: 'error',
          title: `Product not found`,
        })
    }
  }, [
    changeScreenType,
    fetchProduct,
    getAllDataSources,
    getAllDimensionMeasures,
    getAllReviewReasons,
    getAllWeightMeasures,
    getLockReasons,
    getProductIdentifierTypes,
    getUsers,
    productId,
  ])

  const onUnlockSubmit = useCallback(() => {
    setIsLoading(true)
    unlockProduct(productId).then(() => {
      setDisabled(false)
      setIsLock(false)
      setIsLoading(false)
      setFooterInfo({ type: '' })
    })
  }, [productId, unlockProduct])

  const onLockSubmit = useCallback(
    (lockReasonId: string, lockReasonDescription?: string) => {
      lockProduct(productId, lockReasonId, lockReasonDescription).then(() => {
        setDisabled(true)
        setIsLock(true)
        setIsShowLockModal(false)
        setFooterInfo({ type: 'lock' })
      })
    },
    [lockProduct, productId],
  )

  const onSubmitCallback = useCallback(
    // eslint-disable-next-line consistent-return
    async (product, { createSubmitError }) => {
      setIsLoading(true)

      const {
        status: imagesStatus,
        errorFields: imageErrorFields,
        images,
      } = await prepareAndUploadImages(product.Images)

      if (imagesStatus !== 200) {
        return createSubmitError(imageErrorFields)
      }

      const { status, body, errorFields } = await updateProduct({
        productId,
        version,
        product: {
          ...product,
          Images: images,
          Attributes: product.Attributes.map(val => {
            const valueType = attributeValueType.find(
              ({ definitionId }) => definitionId === val.definitionId,
            )
            return {
              ...val,
              valueType: valueType ? valueType.valueType : null,
            }
          }),
        },
      })
      setIsLoading(false)
      if (status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          title: `Product «${primaryIdentifier}» has been saved`,
        })
        setVersion(body.currentVersion)

        if (onSuccessfulSubmit) {
          onSuccessfulSubmit()
        }
      } else {
        const errorText = Object.entries(errorFields).reduce((acc, [key, val]) => {
          switch (typeof val) {
            case 'string':
              return `${acc}\n${key.replace('Field', '').replace(/(.)([A-Z])/g, '$1 $2')}: ${val}`
            case 'object': {
              if (Array.isArray(val)) {
                // usual we have array with the same elements, so we can take text from first element, this will be enough
                const error = val.filter(v => v)
                const errorFirst = error && error[0] ? Object.values(error[0])[0] : null
                return typeof errorFirst === 'string'
                  ? `${acc}\n${key.replace(/(.)([A-Z])/g, '$1 $2')}: ${errorFirst}`
                  : acc
              }
              return acc
            }
            default:
              return acc
          }
        }, '')
        NotificationSystem.addNotification({
          autoDismiss: false,
          level: 'error',
          title: `Product «${primaryIdentifier}» not saved`,
          message: errorText,
        })
        if (errorFields) {
          return createSubmitError(errorFields)
        }
      }
    },
    [
      attributeValueType,
      onSuccessfulSubmit,
      prepareAndUploadImages,
      primaryIdentifier,
      productId,
      updateProduct,
      version,
    ],
  )

  const handleLock = useCallback(() => setIsShowLockModal(true), [])
  const handleUnlock = useCallback(() => onUnlockSubmit(), [onUnlockSubmit])
  const lockSwitch = useCallback(
    () => (isLock ? handleUnlock() : handleLock()),
    [handleLock, handleUnlock, isLock],
  )

  const clientValidateCallback = useCallback(value => clientValidate(schema, value), [])

  const handleUpdateProductApprovalState = useCallback(
    body => {
      updateProductApprovalState(productId, body).then(result => {
        if (result.status === 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: `Product «${primaryIdentifier}» has been updated`,
          })
          if (body.type === 'ApprovalStateApproved') {
            setDisabled(true)
          }
        } else {
          let error = ''
          if (Object.keys(result.errorFields).length > 0) {
            // 422
            // need to be replaced with ProductForm validation
            error = result.body.fields.reduce(
              (acc, val) =>
                `${acc}${val.field.type.replace('Field', '').replace(/(.)([A-Z])/g, '$1 $2')} - ${
                  val.error.defaultFormat
                }.\n`,
              '',
            )
          }

          NotificationSystem.addNotification({
            autoDismiss: false,
            level: 'error',
            title: `Product «${primaryIdentifier}» not updated`,
            message: error,
          })
        }
      })
    },
    [primaryIdentifier, productId, updateProductApprovalState],
  )

  const handleApprove = useCallback(
    () => handleUpdateProductApprovalState({ type: 'ApprovalStateApproved' }),
    [handleUpdateProductApprovalState],
  )

  const handleRescrapeProductData = useCallback(() => {
    setRescrapingInProcess(true)

    rescrapeProductData(productId)
      .then(res => {
        if (res.status === 404) {
          NotificationSystem.addNotification({ title: 'Not found', level: 'error' })
        }
        if (res.status === 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Rescraping is in process. Please refresh the page',
          })
        }

        setRescrapingInProcess(false)
      })
      .catch(() => {
        setRescrapingInProcess(false)
      })
  }, [productId, rescrapeProductData])

  const handleSubmitReview = useCallback(
    async ({ reviewReasonId, comment }: SubmitForReviewFormValue) =>
      handleUpdateProductApprovalState({
        type: 'ApprovalStateSubmittedForReview',
        reviewReasonId,
        comment: comment || null,
      }),
    [handleUpdateProductApprovalState],
  )

  useEffect(() => {
    fetchData()
  }, [fetchData, productId])

  switch (screenType) {
    case 'placeholder':
      return <Placeholder type="form" height={5} />
    case 'error':
      return <H2>Sorry, something went wrong</H2>
    case 'default':
      return (
        <Flex column>
          <FormManager
            initialValues={productFormValues}
            clientValidate={clientValidateCallback}
            onSubmit={onSubmitCallback}
          >
            {({
              values,
              errors,
              getError,
              push,
              remove,
              handleChange,
              handleBlur,
              handleSubmit,
              insert,
            }) => {
              const commonProps = {
                productId,
                values,
                remove,
                push,
                insert,
                errors,
                getError,
                handleChange,
                handleBlur,
                disabled,
              }
              return (
                <>
                  <Container>
                    <Row>
                      <Col md={10}>
                        <PageNavigation.Element id="overview">
                          <HeaderBlock
                            images={productFormValues.Images.map(({ url }, index) => ({
                              url,
                              id: index.toString(),
                            }))}
                            identifier={primaryIdentifier}
                            title={productFormValues.TitleField}
                            isLock={isLock}
                            allowProductUnlock={permissions.allowProductUnlock}
                            lockSwitch={lockSwitch}
                          />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="general">
                          <BasicInfo {...commonProps} />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="dimension">
                          <Dimensions
                            {...commonProps}
                            {...{
                              dimensionMeasuresList,
                              weightMeasuresList,
                            }}
                          />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="parts">
                          <Parts {...commonProps} getProductDetailsUrl={getProductDetailsUrl} />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="fitsFor">
                          <FitsFor {...commonProps} getProductDetailsUrl={getProductDetailsUrl} />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="attributes">
                          <Attributes
                            {...commonProps}
                            {...{
                              setAttributeValueType,
                              setIsLoadingProduct: setIsLoading,
                              attributesInitValue,
                            }}
                          />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="identifiers">
                          <Identifiers {...commonProps} identifiersList={identifiersList} />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="categoryMapping">
                          <MarketplaceMapping {...commonProps} />
                        </PageNavigation.Element>
                        <PageNavigation.Element id="images">
                          <Images {...commonProps} />
                        </PageNavigation.Element>
                        {/* {permissions.allowProductSourceDataView && (
                          <PageNavigation.Element id="externalData">
                            <ScrappedData identifiers={pricesIdentifiers} />
                          </PageNavigation.Element>
                        )} */}
                        <LockModal
                          reasons={reasonsList}
                          isOpen={showLockModal}
                          onClose={() => setIsShowLockModal(false)}
                          onLockSubmit={onLockSubmit}
                        />
                        <FooterPanel {...footerInfo} />
                      </Col>
                      <Col md={2}>
                        <LinkContainer>
                          <PageNavigation links={getLinks()} />
                        </LinkContainer>
                      </Col>
                    </Row>
                  </Container>

                  {children({
                    isLoading,
                    isRescrapingInProcess,
                    primaryIdentifier,
                    approvalType,
                    reviewReasonsList,
                    handlers: {
                      handleSubmit,
                      handleApprove,
                      handleSubmitReview,
                      handleRescrapeProductData,
                    },
                  })}
                </>
              )
            }}
          </FormManager>
        </Flex>
      )
    default:
      return null
  }
})
