// @flow

import * as React from 'react'
import { Flex } from '@r1/ui-kit'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import type { ExpectedDecision, ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'
import type { ClaimInfo, OrderInfo } from '../../../types/common'
import type { NewClaimItem } from '../../../types/ClaimScreen'

import { LinkifiedText } from '../../LinkifiedText'
import { ClaimCreationModal } from '../../ClaimCreationModal'
import { ExpectedDecisionMessageContainer, InformationalText } from '../StyledComponents'

type Props = {
  chatMessageId: string,
  text: string,
  expectedDecisionChatMessageIds: string[],
  claim: ClaimInfo,
  order: OrderInfo,
  availableDecisionTypes: ExpectedDecision[],
  availableReturnReasons: ReturnReason[],
  createClaim: (
    chatMessageId: Lib$Id,
    decisionTypeId: Lib$Id,
    description: string,
    claimItems: NewClaimItem[],
  ) => Promise<string | null>,
}

const ExpectedDecisionMessage = (props: Props) => {
  const {
    chatMessageId,
    text,
    expectedDecisionChatMessageIds,
    claim,
    order,
    availableDecisionTypes,
    availableReturnReasons,
    createClaim,
  } = props

  const isFirstExpectedDecision = React.useMemo(
    () =>
      expectedDecisionChatMessageIds?.length > 0
        ? expectedDecisionChatMessageIds[0] === chatMessageId
        : false,
    [chatMessageId, expectedDecisionChatMessageIds],
  )

  return (
    <ExpectedDecisionMessageContainer>
      <Flex column spaceBetween="S">
        <Flex justify="center" align="center" spaceBetween="XS">
          <InformationalText>
            <LinkifiedText text={text} />
          </InformationalText>
          {!isFirstExpectedDecision && (
            <ClaimCreationModal
              chatMessageId={chatMessageId}
              claim={claim}
              order={order}
              availableDecisionTypes={availableDecisionTypes}
              availableReturnReasons={availableReturnReasons}
              createClaim={createClaim}
            />
          )}
        </Flex>
      </Flex>
    </ExpectedDecisionMessageContainer>
  )
}

export default ExpectedDecisionMessage
