import { Action } from '../constants'
import { createDocumentTemplateManagerService } from '../services/documentTemplateManagerService'

const api = createDocumentTemplateManagerService()

export const loadPageSettings = () => {
  return async dispatch => {
    const pageSettings = await api.getPageSettings()

    dispatch({
      type: Action.LOAD_PAGE_SETTINGS,
      pageSettings,
    })
  }
}
