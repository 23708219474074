// @flow

import React, { useCallback } from 'react'
import { FormManager } from '@r1/form-manager'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Textarea, Select, FormField, Modal, Flex } from '@r1/ui-kit'
import type { SubmitForReviewProps } from '../../types/product.type'

const OTHER_REVIEW_REASON_ID = '6'

export const SubmitForReview = ({
  reviewReasonsList,
  handleSubmitReview,
  onClose,
  show,
}: SubmitForReviewProps) => {
  const onSubmit = useCallback(
    (value, error) => {
      onClose()
      return handleSubmitReview(value, error)
    },
    [handleSubmitReview, onClose],
  )

  return (
    reviewReasonsList.length > 0 && (
      <Flex column minWidth={550} maxWidth={550}>
        <FormManager
          initialValues={{
            reviewReasonId: reviewReasonsList[0].id,
            comment: '',
          }}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, handleSubmit }) => {
            const modalActionButtons: BottomActionButton[] = [
              {
                title: 'Cancel',
                color: 'secondary',
                onClick: onClose,
                align: 'right',
              },
              // button is locked when reason = other and comment is empty
              {
                title: 'Submit',
                disabled:
                  values.reviewReasonId === OTHER_REVIEW_REASON_ID && values.comment.length === 0,
                onClick: handleSubmit,
                align: 'right',
              },
            ]

            return (
              <Modal
                isControlled
                title="Product Submit"
                show={show}
                actionButtons={modalActionButtons}
                onEscapeKeyDown={onClose}
              >
                <FormField>
                  <FormField.Label>Select reason for review</FormField.Label>
                  <Select
                    clearable={false}
                    filterable={false}
                    value={values.reviewReasonId}
                    options={reviewReasonsList}
                    onChange={handleChange(['reviewReasonId'])}
                  />
                </FormField>
                <FormField>
                  <FormField.Label>{`Comment ${
                    values.reviewReasonId === OTHER_REVIEW_REASON_ID ? `(required)` : ``
                  }`}</FormField.Label>
                  <Textarea value={values.comment} onChange={handleChange(['comment'])} />
                </FormField>
              </Modal>
            )
          }}
        </FormManager>
      </Flex>
    )
  )
}
