// @flow

import React, { memo, useCallback, useContext, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import {
  Collapse,
  Link,
  H3,
  Placeholder,
  FormField,
  Container,
  Row,
  Col,
  Flex,
  HR,
} from '@r1/ui-kit'
import { ServiceContext } from '../../provider'
import type { ScrappedDataProps } from '../../types/product.type'
import { SourcePriceHistory } from './children/SourcePriceHistory'
import { ProductsSourceData } from './children/ProductsSourceData'

const Wrapper = styled('div')`
  width: 540px;
`

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

export const Text = styled('span')`
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 16px;
  line-height: 24px;
`

const IdentifierInfo = memo(
  ({
    identifier,
    identifierType,
    lastPrice,
    hasScrappedProductInfo,
    last,
    onPriceClick,
    onIdentifierClick,
  }) => (
    <Row>
      <Col md={1}> </Col>
      <Col>
        <Flex column spaceBetween="S">
          <Flex maxWidth={0.5} minWidth={0.5} spaceBetween="XXS">
            <FormField.Label>
              {hasScrappedProductInfo ? (
                <Link onClick={onIdentifierClick}>
                  {identifierType} {identifier}
                </Link>
              ) : (
                `${identifierType} ${identifier}`
              )}
            </FormField.Label>
            <Link onClick={onPriceClick}>{`${lastPrice.price.currency} ${Number(
              lastPrice.price.amount,
            ).toFixed(2)}`}</Link>
          </Flex>
          {!last ? <HR /> : null}
        </Flex>
      </Col>
    </Row>
  ),
)

export const ScrappedData = (props: ScrappedDataProps) => {
  const { identifiers } = props
  const [loading, setLoading] = useState(false)
  const [sourceDataInfo, setSourceDataInfo] = useState({})
  const [priceHistory, setPriceHistory] = useState()
  const [openedIdentifier, setOpenedIdentifier] = useState()

  const {
    productService: { fetchSourceDataInfo },
  } = useContext(ServiceContext)

  const fetchData = useCallback(async () => {
    const sourceData = identifiers.length === 0 ? [] : await fetchSourceDataInfo(identifiers)
    const dataInfo = sourceData.reduce((acc, val) => {
      if (acc[val.sourceProvider]) acc[val.sourceProvider].push(val)
      else acc[val.sourceProvider] = [val]
      return acc
    }, {})

    setSourceDataInfo(dataInfo)
  }, [identifiers, fetchSourceDataInfo])

  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [identifiers, fetchData])

  const onPriceClick = useCallback(
    ({ productIdentifierType, productIdentifierValue, sourceProvider }) =>
      () => {
        setPriceHistory({ productIdentifierType, productIdentifierValue, sourceProvider })
      },
    [],
  )

  const onIdentifierClick = useCallback(
    ({ productIdentifierType, productIdentifierValue, sourceProvider, lastPrice }) =>
      () => {
        setOpenedIdentifier({
          productIdentifierType,
          productIdentifierValue,
          sourceProvider,
          lastPrice,
        })
      },
    [],
  )

  return (
    <Wrapper>
      <StyledH3>External Data</StyledH3>
      {loading ? (
        <Placeholder type="form" />
      ) : (
        <>
          {Object.keys(sourceDataInfo).length > 0 ? (
            <Container>
              <Collapse>
                {Object.keys(sourceDataInfo).map(key => (
                  <Collapse.Panel key={key} id={key} title={key}>
                    {sourceDataInfo[key].map((info, index) => (
                      <IdentifierInfo
                        key={`${info.identifier} ${info.identifierType} `}
                        {...info}
                        last={index === sourceDataInfo[key].length - 1}
                        onPriceClick={onPriceClick({
                          productIdentifierType: info.identifierType,
                          productIdentifierValue: info.identifier,
                          sourceProvider: key,
                        })}
                        onIdentifierClick={onIdentifierClick({
                          productIdentifierType: info.identifierType,
                          productIdentifierValue: info.identifier,
                          sourceProvider: key,
                          lastPrice: info.lastPrice,
                        })}
                      />
                    ))}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Container>
          ) : (
            <Text>There is no external data yet</Text>
          )}

          {priceHistory != null && (
            <SourcePriceHistory
              isShow
              title={`Price History for 
                ${priceHistory.productIdentifierType} ${priceHistory.productIdentifierValue}`}
              productIdentifierValue={priceHistory.productIdentifierValue}
              sourceProvider={priceHistory.sourceProvider}
              onClose={() => setPriceHistory()}
            />
          )}

          {openedIdentifier != null && (
            <ProductsSourceData
              isShow
              title={`
                ${openedIdentifier.productIdentifierType} 
                ${openedIdentifier.productIdentifierValue} on ${openedIdentifier.sourceProvider}
              `}
              productIdentifierValue={openedIdentifier.productIdentifierValue}
              sourceProvider={openedIdentifier.sourceProvider}
              lastPrice={openedIdentifier.lastPrice}
              onClose={() => setOpenedIdentifier()}
            />
          )}
        </>
      )}
    </Wrapper>
  )
}
