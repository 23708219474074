import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Link } from '@r1/ui-kit'

import { discountsRulesService } from '../services'
import { DiscountsRuleForm } from '../components/DiscountsRuleForm'
import { ROUTES } from '../../../navigation'
import { DiscountRule } from '../types/DiscountRule'

const emptyDiscountRule = {
  id: '',
  name: '',
  categories: [],
  includeUPC: [],
  excludeUPC: [],
  steps: [{ count: 10, percent: 10 }],
  marketplaces: [],
  updatedOn: '',
  priority: 1,
  version: '1',
}

export const AddDiscountsRule = () => {
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = useCallback(
    async (settings: DiscountRule) => {
      try {
        setIsSubmitting(true)
        await discountsRulesService.createDiscountRule(settings)
        navigate(ROUTES.DISCOUNTS)
      } finally {
        setIsSubmitting(false)
      }
    },
    [navigate],
  )

  const handleClose = useCallback(() => {
    navigate(ROUTES.DISCOUNTS)
  }, [navigate])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSalesSettingsEdit]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Breadcrumbs>
            <Link to={ROUTES.DISCOUNTS}>Volume Discount Rules</Link>
          </ContentHeader.Breadcrumbs>
          <ContentHeader.Title>Create New Discount Rule</ContentHeader.Title>
        </ContentHeader>
        <Content>
          <DiscountsRuleForm
            initialDiscountRule={emptyDiscountRule}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            onClose={handleClose}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
