import { Facility } from '@r1-webui/facilities-v1'
import { facilitiesApi } from '../../../api/facilities/facilities'
import { showNotification } from '../../../utils/notifications/showNotification'
import { getErrorNotification } from '../utils/getErrorNotification'
import { mapFacilityToApi } from '../utils/mapFacilityToApi'

export async function createFacilityLocation(data: Facility): Promise<boolean> {
  const mappedFacility = mapFacilityToApi(data)

  try {
    const result = await facilitiesApi.createFacility(mappedFacility)

    if (result.status === 400) {
      const notification = getErrorNotification(result.status, {
        badProperties: result.body.badProperties,
      })
      showNotification(notification)
      return false
    } else if (result.status !== 200) {
      const notification = getErrorNotification(result.status)
      showNotification(notification)
      return false
    }

    showNotification({
      level: 'success',
      message: `Successfully created Facility location with id ${result.body.facilityId}`,
    })
    return true
  } catch (exception) {
    const notification = getErrorNotification(null)
    showNotification(notification)
    return false
  }
}
