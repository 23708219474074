import * as React from 'react'
import { CommonClassifications } from '../../../../../../components/classifications/Classifications'
import { ClassificationValues } from '../../../../../../components/classifications/ClassificationValues'

export default class Classifications extends React.Component {
  renderClassificationValues = ({
    classificationId,
    classificationName,
    disabled,
    classificationValueIds,
    onChangeClassificationValueIds,
  }) => {
    const classification = this.props.classificationsMeta.find(({ id }) => id === classificationId)
    const options = classification && classification.values
    const testId = classificationName?.toLowerCase()?.replace(/\s+/, '-')
    const dataTestId = `consignee-upc-classification-${testId}`

    return classification ? (
      <ClassificationValues
        classificationName={classificationName}
        value={classificationValueIds}
        disabled={disabled}
        options={options}
        dataTestId={dataTestId}
        onChange={onChangeClassificationValueIds}
      />
    ) : null
  }

  render() {
    const { disabled, classifications, classificationsMeta } = this.props
    const value = classifications.map(({ classificationId, values }) => ({
      classificationId,
      valueIds: values,
    }))
    const onChange = values =>
      this.props.onChange(
        values.map(({ classificationId, valueIds }) => ({ classificationId, values: valueIds })),
      )

    return (
      <CommonClassifications
        disabled={disabled}
        classificationData={classificationsMeta}
        value={value}
        renderClassificationValues={this.renderClassificationValues}
        onChange={onChange}
      />
    )
  }
}
