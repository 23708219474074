import * as React from 'react'
import { connect } from 'react-redux'
import { Flex } from '@r1/ui-kit'
import { selectors, actions } from '../../../modules/products/classifications'
import { ClassificationInput } from '../../../components/classifications/ClassificationInput'

function normalizeClassifications(items) {
  return items.map(({ children, serializedValue, ...item }) => ({
    ...item,
    value: JSON.parse(serializedValue),
    children: normalizeClassifications(children),
  }))
}

function parseClassifications(items) {
  return items.map(({ children, value, ...item }) => ({
    ...item,
    serializedValue: JSON.stringify(value),
    children: parseClassifications(children),
  }))
}

const mapStateToProps = (state, { productId }) => ({
  classificationItems: selectors.getItem(state, { productId }),
})

const enhance = connect(
  mapStateToProps,
  {
    fetchClassifications: actions.fetchItem,
  },
  null,
  { forwardRef: true },
)

function normalizeClassificationItems(classificationItems) {
  if (!classificationItems) {
    return {
      dictionaries: null,
      schema: null,
      classifications: null,
    }
  }

  return {
    dictionaries: classificationItems.dictionaries,
    classifications: normalizeClassifications(classificationItems.classifications),
    schema: normalizeClassifications(classificationItems.schema),
  }
}

class ClassificationsComponent extends React.Component {
  classificationInput = null

  componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate() {
    this.fetchData()
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  getClassifications = () => parseClassifications(this.classificationInput.getClassifications())

  setClassificationInput = classificationInput => {
    this.classificationInput = classificationInput
  }

  fetchData() {
    const { productId, fetchClassifications } = this.props
    fetchClassifications({ productId })
  }

  render() {
    const { dictionaries, schema, classifications } = normalizeClassificationItems(
      this.props.classificationItems,
    )

    return (
      <Flex column>
        <ClassificationInput
          ref={this.setClassificationInput}
          dictionaries={dictionaries}
          schema={schema}
          classifications={classifications}
        />
      </Flex>
    )
  }
}

export const Classifications = enhance(ClassificationsComponent)
