import React from 'react'
import { Link } from '@r1/ui-kit'
import { Report, ReportSection } from '@r1-webui/reports-v1'
import { useParams } from 'react-router-dom'
import { Main, ContentHeader, Content } from '@r1/wireframe-primary'
import { PERMISSIONS, PageAccessControl, handleServerError } from '@r1/core-blocks'
import { ROUTES } from '../../../../navigation'
import { ReportGroupPage, ReportsLoader } from '../components'
import { reportGroupsApi } from '../reports.api'

export function ReportGroupScreen() {
  const { reportGroupId } = useParams()

  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [groupName, setGroupName] = React.useState<string>('')
  const [reports, setReports] = React.useState<Report[]>([])
  const [reportSections, setReportSections] = React.useState<ReportSection[]>([])

  React.useEffect(() => {
    const fetchData = async () => {
      if (!reportGroupId) {
        return
      }

      setIsLoading(true)

      const reportsResponse = await reportGroupsApi.getReportsByGroupType({
        reportGroupId,
      })

      if (reportsResponse.status === 200) {
        setGroupName(reportsResponse.body.reportGroupName)
        setReports(reportsResponse.body.reports)
        setReportSections(reportsResponse.body.reportSections)
      } else {
        handleServerError(reportsResponse)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [reportGroupId])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowReportDashboardsView]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Breadcrumbs>
            <Link to={ROUTES.REPORTS_DASHBOARDS}>Reports</Link>
          </ContentHeader.Breadcrumbs>
          <ContentHeader.Title>{groupName}</ContentHeader.Title>
        </ContentHeader>
        <Content>
          {isLoading ? (
            <ReportsLoader />
          ) : (
            <ReportGroupPage reports={reports} sections={reportSections} />
          )}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
