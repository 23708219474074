// @flow

import { evolve, mergeLeft } from 'ramda'

import type { ClaimType } from '../../../../types/common'
import type { OrderLine, ClaimTotal, LineNote, Replacement } from '../../../../types/ClaimCreation'
import type { WithController } from '../types'

export class ClaimDetailsHandlerController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  handleOrderLineSelection = (lineId: string, isSelected: boolean) => {
    this.component.validation.resetError('orderLines')
    this.component.validation.resetOrderLineFieldErrors(lineId)

    this.component.setState(
      state => {
        const { orderLines: originOrderLines, claimType } = state.claimCreation
        if (!claimType) {
          return null
        }

        const orderLines = this.selectOrderLine(originOrderLines, lineId, isSelected)
        const claimTotal = this.calcClaimTotal(orderLines, claimType)

        return evolve(
          {
            claimCreation: mergeLeft({
              orderLines,
              claimTotal,
            }),
          },
          state,
        )
      },
      () => {
        if (isSelected) {
          // Force validation of previous value on line selection.
          switch (this.component.state.claimCreation.claimType) {
            case 'RefundOnly':
              this.handleOrderLineAmountChange(lineId, undefined)
              this.handleOrderLineReasonChange(lineId, undefined)
              break

            case 'ReplacementOnly':
              this.handleOrderLineReasonChange(lineId, undefined)
              this.handleOrderLineReplacementsChange(lineId, undefined)
              break

            case 'ReplacementAfterReturn':
              this.handleOrderLineQuantityChange(lineId, undefined)
              this.handleOrderLineReasonChange(lineId, undefined)
              this.handleOrderLineReplacementsChange(lineId, undefined)
              break

            default:
              this.handleOrderLineQuantityChange(lineId, undefined)
              this.handleOrderLineReasonChange(lineId, undefined)
              break
          }
        }
      },
    )
  }

  selectOrderLine = (orderLines: OrderLine[], lineId: string, isSelected: boolean): OrderLine[] => {
    const orderLine = orderLines.find(ol => ol.lineId === lineId)
    if (!orderLine) {
      return orderLines
    }

    const { rmaProfile } = this.component.state.claimCreation
    if (!rmaProfile) {
      return orderLines
    }

    if (rmaProfile.claimCreationPolicy === 'SingleLine') {
      // Before selection new line we should unselect previous.
      if (isSelected) {
        const selectedLine = orderLines.find(ol => ol.selected)
        if (selectedLine) {
          selectedLine.selected = false
        }
      }
    }
    orderLine.selected = isSelected

    return orderLines
  }

  calcClaimTotal = (orderLines: OrderLine[], claimType: ClaimType): ClaimTotal => {
    let amount = 0.0
    let currency = 'USD'
    let quantity = 0

    switch (claimType) {
      case 'RefundAfterReturn':
        orderLines
          .filter(orderLine => orderLine.selected)
          .forEach(orderLine => {
            amount +=
              Number(orderLine.lineInfo.singleItemPrice.total.amount) *
              Number(orderLine.returnQuantity)
            currency = orderLine.lineInfo.singleItemPrice.total.currency
            quantity += Number(orderLine.returnQuantity)
          })
        break

      case 'RefundOnly':
        orderLines
          .filter(orderLine => orderLine.selected)
          .forEach(orderLine => {
            amount += Number(orderLine.refundAmount.amount)
            currency = orderLine.lineInfo.lineTotal.currency
          })
        break

      case 'ReturnOnly':
        orderLines
          .filter(orderLine => orderLine.selected)
          .forEach(orderLine => {
            quantity += Number(orderLine.returnQuantity)
          })
        break

      case 'ReplacementOnly':
        // Do nothing
        break

      case 'ReplacementAfterReturn':
        orderLines
          .filter(orderLine => orderLine.selected)
          .forEach(orderLine => {
            quantity += Number(orderLine.returnQuantity)
          })
        break

      default:
        throw new Error(`Unsupported claim type ${claimType}.`)
    }

    return { amount: { amount: amount.toFixed(2), currency }, quantity }
  }

  handleOrderLineQuantityChange = (lineId: string, quantity: ?string) => {
    this.component.validation.resetError('orderLines')

    this.component.setState(state => {
      const { orderLines, claimType } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }
      if (!claimType) {
        return null
      }

      if (quantity != null) {
        orderLine.returnQuantity = this.normalizeReturnQuantity(quantity)
      }
      orderLine.returnQuantityError = this.component.validation.validateReturnQuantity(
        orderLine.returnQuantity,
        orderLine.lineInfo.quantity,
      )
      const claimTotal = this.calcClaimTotal(orderLines, claimType)

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
            claimTotal,
          }),
        },
        state,
      )
    })
  }

  handleOrderLineAmountChange = (lineId: string, amount: ?string) => {
    this.component.validation.resetError('orderLines')

    this.component.setState(state => {
      const { orderLines, claimType } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }
      if (!claimType) {
        return null
      }

      if (amount != null) {
        const refundAmountValue = this.normalizeRefundAmount(amount)
        orderLine.refundAmount = {
          amount: refundAmountValue,
          currency: orderLine.refundAmount.currency,
        }
      }

      orderLine.refundAmountError = this.component.validation.validateRefundAmount(
        orderLine.refundAmount,
        orderLine.lineInfo.lineTotal,
      )
      const claimTotal = this.calcClaimTotal(orderLines, claimType)

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
            claimTotal,
          }),
        },
        state,
      )
    })
  }

  handleOrderLineReasonChange = (lineId: string, reasonId: ?string) => {
    this.component.validation.resetError('orderLines')

    this.component.setState(state => {
      const { orderLines } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }

      if (reasonId != null) {
        orderLine.returnReasonId = reasonId
      }
      orderLine.returnReasonIdError = this.component.validation.validateReturnReason(
        orderLine.returnReasonId,
        state.claimCreation.claimType,
      )

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
          }),
        },
        state,
      )
    })
  }

  handleOrderLineNotesModalShow = (orderLine: ?OrderLine) => {
    this.component.setState(
      evolve({
        claimCreation: mergeLeft({
          currentOrderLine: orderLine,
          isOrderLineNotesModalVisible: !!orderLine,
        }),
      }),
    )
  }

  handleOrderLineNotesChange = (lineId: string, note: LineNote) => {
    this.component.setState(state => {
      const { orderLines } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }

      orderLine.notes = {
        message: note.message,
        images: note.images,
      }

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
          }),
        },
        state,
      )
    })
  }

  handleOrderLineReplacementsModalShow = (orderLine: ?OrderLine) => {
    this.component.validation.resetError('orderLines')

    this.component.setState(
      evolve({
        claimCreation: mergeLeft({
          currentOrderLine: orderLine,
          isOrderLineReplacementsModalVisible: !!orderLine,
        }),
      }),
    )
  }

  handleOrderLineReplacementsChange = (lineId: string, replacements?: Replacement[]) => {
    this.component.validation.resetError('orderLines')

    this.component.setState(state => {
      const { orderLines } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }

      if (replacements != null) {
        orderLine.replacements = replacements
      }
      orderLine.replacementsError = this.component.validation.validateReplacements(
        orderLine,
        orderLines,
      )

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
          }),
        },
        state,
      )
    })
  }

  normalizeReturnQuantity = (value: ?string): number => {
    return value ? Number(value.replace(/\D/gi, '')) : 0
  }

  normalizeRefundAmount = (value: ?string): string => {
    return value ? value.replace(/[^0-9.]/gi, '').replace(/^0/gi, '') : ''
  }
}

export const createClaimDetailsHandlerController = (component: WithController) =>
  new ClaimDetailsHandlerController(component)
