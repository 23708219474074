// @flow

export const toObject = <I: Object, A: I[], K: void | $Keys<I>>(
  array: A,
  key: K,
): { [string]: I } =>
  array.reduce((acc, item) => {
    const id = item[key || 'id']
    acc[id] = item
    return acc
  }, {})
