/* eslint-disable react/no-unused-state */
// @flow

import * as React from 'react'
import { createApi as attributeDefinitionsApi } from '../../AttributeDefinitions/api'
import { createApi as attributeDefinitionManagementApi } from '../api/attributeDefinitionManagement'
import { isSuccessResponse } from '../../utils/api'
import { processResponse } from '../helpers'
import type {
  NewAttributeDefinition,
  EnumValueArray,
} from '../../../types/internal/api/attributeDefinitionManagement/definitions'
import { attributeCanHaveUnitOfMeasurement } from '../../AttributeDefinition/utils'
import type { AttributeCreationFormControllerProps } from '../../../types/public/AttributeCreationFormController'
import type {
  DataSources,
  UnitOfMeasurement,
  FormManagerSubmit,
} from '../../../types/public/attributeCreationFormCommon'
import { showNotification } from '../../../../../utils'

type State = {|
  screenType: 'default' | 'placeholder',
  dataSources: DataSources,
  unitOfMeasurementType: string | null,
  allUnitOfMeasurements: { [id: string]: UnitOfMeasurement[] },
|}

const getEnumValues = (attributeType, attributeEnumValues): EnumValueArray | null => {
  switch (attributeType) {
    case 'IntEnumeration':
      return {
        valueType: 'IntEnumArray',
        enumValues: attributeEnumValues.map(({ id, value }) =>
          /^tag-input_internal-id_/.test(id)
            ? { value: parseInt(value, 10) }
            : { id, value: parseInt(value, 10) },
        ),
      }
    case 'DecimalEnumeration':
      return {
        valueType: 'DecimalEnumArray',
        enumValues: attributeEnumValues.map(({ id, value }) =>
          /^tag-input_internal-id_/.test(id) ? { value } : { id, value },
        ),
      }
    case 'StringEnumeration':
      return {
        valueType: 'StringEnumArray',
        enumValues: attributeEnumValues.map(({ id, value }) =>
          /^tag-input_internal-id_/.test(id) ? { value } : { id, value },
        ),
      }
    default:
      return null
  }
}

// dont send unitOfMeasurementId if attribute cannot have it
const getUnitOfMeasurementId = (
  attributeType,
  unitOfMeasurement,
): {| unitOfMeasurementId?: string |} => {
  if (attributeCanHaveUnitOfMeasurement(attributeType) && unitOfMeasurement != null) {
    return { unitOfMeasurementId: unitOfMeasurement }
  }
  return { unitOfMeasurementId: undefined }
}

export class AttributeCreationFormController extends React.Component<
  AttributeCreationFormControllerProps,
  State,
> {
  state = {
    screenType: 'placeholder',
    dataSources: {
      unitOfMeasurements: [],
      unitOfMeasurementTypes: [],
    },
    allUnitOfMeasurements: {},
    unitOfMeasurementType: null,
  }

  api = {
    ...attributeDefinitionsApi(this.props.httpClient),
    ...attributeDefinitionManagementApi(this.props.httpClient),
  }

  componentDidMount() {
    this.fetchDataSources()
  }

  fetchDataSources = () => {
    const { getAllUnitsOfMeasurement, getUnitOfMeasurementTypes } = this.api

    return Promise.all([
      getAllUnitsOfMeasurement().then(processResponse),
      getUnitOfMeasurementTypes().then(processResponse),
    ]).then(([allUnitsOfMeasurementResponse, unitOfMeasurementTypesResponse]) => {
      if (
        isSuccessResponse(allUnitsOfMeasurementResponse) &&
        isSuccessResponse(unitOfMeasurementTypesResponse)
      ) {
        const unitOfMeasurementTypes = unitOfMeasurementTypesResponse.payload.find(
          /* extract unitOfMeasurementTypes that correspond to 'Decimal' attribute type
           * because the same unitOfMeasurementTypes are used for 'DecimalRange' and 'DecimalEnumeration' */
          ({ attributeType }) => attributeType === 'Decimal',
        )

        const unitOfMeasurements = allUnitsOfMeasurementResponse.payload.reduce(
          (acc, { id, name, typeId }) => {
            if (acc[typeId]) acc[typeId].push({ id, name })
            else acc[typeId] = [{ id, name }]
            return acc
          },
          {},
        )

        const dataSources: DataSources = {
          unitOfMeasurements: [],
          unitOfMeasurementTypes: unitOfMeasurementTypes
            ? unitOfMeasurementTypes.unitOfMeasurementTypes
            : [],
        }

        this.setState({
          dataSources,
          screenType: 'default',
          allUnitOfMeasurements: unitOfMeasurements,
        })
      } else {
        this.setState({ screenType: 'default' })
      }
    })
  }

  onSubmit: FormManagerSubmit = async (
    { attributeName, attributeType, attributeEnumValues, attributeBindingType, unitOfMeasurement },
    { createSubmitError },
  ) => {
    if (!attributeBindingType || !attributeType) return
    const options: NewAttributeDefinition =
      attributeBindingType === 'Product'
        ? {
            type: 'NewProductAttributeDefinition',
            name: attributeName,
            dataType: attributeType,
            ...getUnitOfMeasurementId(attributeType, unitOfMeasurement),
            enumValues: getEnumValues(attributeType, attributeEnumValues) || undefined,
          }
        : {
            type: 'NewUnitAttributeDefinition',
            name: attributeName,
            dataType: attributeType,
            ...getUnitOfMeasurementId(attributeType, unitOfMeasurement),
            enumValues: getEnumValues(attributeType, attributeEnumValues) || undefined,
          }

    const { createAttributeDefinition } = this.api

    const response = await createAttributeDefinition(options).then(processResponse)

    if (!isSuccessResponse(response)) {
      if (response.$type === 'Validation') {
        const errors: any = response.fields.reduce(
          (map, { fieldId, message }) => ({ ...map, [fieldId]: message }),
          {},
        )
        // eslint-disable-next-line consistent-return
        return createSubmitError(errors)
      }
    } else {
      showNotification({
        title: `${attributeName} attribute was created successfully`,
        level: 'success',
      })
    }
  }

  onChangeUnitOfMeasurementType = (unitOfMeasurementType: string | null) => {
    this.setState(state => ({
      unitOfMeasurementType,
      dataSources: {
        ...state.dataSources,
        unitOfMeasurements: unitOfMeasurementType
          ? state.allUnitOfMeasurements[unitOfMeasurementType]
          : [],
      },
    }))
  }

  render() {
    const { onSubmit, onChangeUnitOfMeasurementType } = this
    const { children } = this.props
    const { screenType, dataSources } = this.state

    switch (screenType) {
      case 'default':
        return children({
          screenType: 'default',
          dataSources,
          handlers: { onSubmit, onChangeUnitOfMeasurementType },
        })
      default:
        return children({ screenType: 'placeholder' })
    }
  }
}
