// @flow

import { find, propEq, is } from 'ramda'

import { baseUrl } from '../../constants'
import { getData } from '../../utils'

const mapFields = (sources, definitions) => {
  let fields = []

  if (sources && definitions) {
    fields = definitions.map(definition => {
      const description = find(propEq('key', definition.key))(sources) || {}

      return { ...definition, ...description }
    })
  }

  return fields
}

export const getProfile = ({
  httpClient,
  profileId,
  onError,
}: {
  httpClient: any,
  profileId: string,
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'GET',
      url: `${baseUrl}/profile/${profileId}`,
    },
    transformData: data => {
      const {
        name,
        applicationIds,
        type: { id, informationMarkdown = '', image = {}, fieldDefinitions = [], purpose },
      } = is(Object, data) ? data : {}

      return {
        applicationIds,
        profileTypeId: id,
        profileName: name,
        profileInfo: informationMarkdown,
        profileImage: image,
        fieldDefinitions,
        purpose,
      }
    },
  }).then(({ value }) => value)

export const getApplications = (httpClient: any, onError: any) =>
  getData({
    httpClient,
    onError,
    defaultValue: [],
    options: {
      method: 'GET',
      url: '/uisvc/espm/applications',
    },
  }).then(({ value }) => value)

export const getSharedCompanies = (httpClient: any, onError: any) =>
  getData({
    httpClient,
    onError,
    defaultValue: [],
    options: {
      method: 'GET',
      url: '/uisvc/espm/api/profile/shared',
    },
  }).then(({ value }) => value)

export const getFields = ({
  httpClient,
  profileId,
  onError,
}: {
  httpClient: any,
  profileId: string,
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'GET',
      url: `${baseUrl}/profile/${profileId}/fields`,
    },
  }).then(({ value }) => value)

export const setApplications = ({
  httpClient,
  onError,
  profileId,
  applicationIds,
}: {
  httpClient: any,
  onError: any,
  profileId: string,
  applicationIds: Array<string>,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'PUT',
      url: `${baseUrl}/profile/${profileId}/applications`,
      data: { applicationIds },
    },
  })

export const getInitialData = ({
  httpClient,
  onError,
  profileId,
}: {
  httpClient: any,
  onError: any,
  profileId: string,
}) =>
  Promise.all([
    getProfile({ httpClient, profileId, onError }),
    getApplications(httpClient),
    getFields({ httpClient, profileId, onError }),
    getSharedCompanies(httpClient, onError),
  ]).then(([profile, applications, fields, sharedCompanies]) => {
    const { fieldDefinitions, ...restProfileData } = profile

    const result = {
      ...restProfileData,
      profileFields: mapFields(fields, fieldDefinitions),
      applications,
      sharedCompanies,
      applicationsLoading: false,
      isLoading: false,
    }

    return result
  })
