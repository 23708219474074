import React from 'react'
import { Deposit, DepositFilter, SortedColumn } from '@r1-webui/gowholesale-depositmanagement-v1'
import { DisplaySettings } from '@r1/grid'
import { FreezeType, Sorting } from '@r1/grid/contracts/ts/DisplaySettings'
import { format } from 'date-fns'
import { Button, Dropdown, Flex, Icon, Link, Text } from '@r1/ui-kit'
import { ROUTES } from '../../../../navigation/index'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../../../offer-management/const'
import { YMDh12Format } from '../../../../utils/dates/formats'
import {
  depositsGridMeta,
  depositsGridSortedColumns,
  depositTypeNameMap,
  sortedColumnsGridColumnsMap,
  sortingTypeFilterUiMap,
} from './const'
import { DepositAction, DepositsGridColumn } from './types'

const getGridCellRenderer = (
  column: DepositsGridColumn,
  onActionClick: (deposit: Deposit, action: DepositAction) => void,
  allowToClose: boolean,
): ((dataItem: Deposit, rowIndex: number) => React.ReactNode) => {
  switch (column) {
    case DepositsGridColumn.DepositId:
      return deposit => <Text>{deposit.id}</Text>
    case DepositsGridColumn.OfferCreationDate:
      return deposit => <Text>{format(new Date(deposit.createdOn), YMDh12Format)}</Text>
    case DepositsGridColumn.CompanyName:
      return deposit => <Text>{deposit.customer.company || ''}</Text>
    case DepositsGridColumn.DepositType:
      return deposit => <Text>{depositTypeNameMap[deposit.type]}</Text>
    case DepositsGridColumn.DepositAmount:
      return deposit => <Text>${deposit.amount.amount}</Text>
    case DepositsGridColumn.Status:
      return deposit => <Text>{deposit.status}</Text>
    case DepositsGridColumn.OfferId:
      return deposit => {
        if (deposit.type === 'TenPercent' || deposit.type === 'OnePercent') {
          const searchParams = new URLSearchParams()
          searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, deposit.entityId)

          return (
            <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
              {deposit.entityId}
            </Link>
          )
        }
        return null
      }
    case DepositsGridColumn.WireTransferOrderId:
      return deposit => {
        if (deposit.type === 'WireTransfer') {
          return <Text>{deposit.entityId}</Text>
        }
        return null
      }
    case DepositsGridColumn.Actions:
      return deposit => {
        const withActions = deposit.status === 'Held' || deposit.status === 'Unpaid'

        return (
          <Flex basis={1} align="center" justify="space-between">
            <Icon
              type="history"
              cursor="pointer"
              onClick={() => onActionClick(deposit, DepositAction.ShowHistory)}
            />

            {withActions ? (
              <Dropdown.Menu
                items={[
                  {
                    id: DepositAction.Close,
                    title: 'Close',
                    disabled: !allowToClose,
                  },
                ]}
                mode="click"
                placement="bottom-end"
                onSelectItem={action => {
                  onActionClick(deposit, action)
                }}
              >
                <Flex justify="center">
                  <Button transparent shape="square" size="S">
                    <Icon type="dots" />
                  </Button>
                </Flex>
              </Dropdown.Menu>
            ) : null}
          </Flex>
        )
      }
    default:
      return () => null
  }
}

const getColumnFreezeByColumn = (column: DepositsGridColumn): FreezeType | undefined => {
  switch (column) {
    case DepositsGridColumn.DepositId:
      return 'left'
    case DepositsGridColumn.Actions:
      return 'right'
    default:
      return undefined
  }
}

const getSortingFieldByColumn = (column: DepositsGridColumn): SortedColumn | 'unknown' => {
  switch (column) {
    case DepositsGridColumn.DepositId:
      return 'Id'
    case DepositsGridColumn.OfferCreationDate:
      return 'CreationDate'
    case DepositsGridColumn.Status:
      return 'Status'
    case DepositsGridColumn.DepositType:
      return 'DepositType'
    case DepositsGridColumn.DepositAmount:
      return 'DepositAmount'
    case DepositsGridColumn.OfferId:
      return 'OfferId'
    case DepositsGridColumn.WireTransferOrderId:
      return 'MarketplaceOrderId'
    default:
      return 'unknown'
  }
}

const getColumnSortingByColumn = (
  column: DepositsGridColumn,
  filter: DepositFilter,
): Sorting | undefined => {
  if (!depositsGridSortedColumns.includes(column)) {
    return undefined
  }

  if (filter.sorting && sortedColumnsGridColumnsMap[filter.sorting.column] === column) {
    return {
      field: filter.sorting.column,
      type: sortingTypeFilterUiMap[filter.sorting.sorting],
    }
  }

  return {
    field: getSortingFieldByColumn(column),
    type: 'none',
  }
}

export const getGridDisplaySettings = (
  onActionClick: (deposit: Deposit, action: DepositAction) => void,
  allowToClose: boolean,
  filter: DepositFilter,
): DisplaySettings<Deposit> => {
  return {
    showCounter: false,
    columnsSettings: [
      ...Array.from(depositsGridMeta).map(([column, settings]) => {
        const freeze = getColumnFreezeByColumn(column)
        const sorting = getColumnSortingByColumn(column, filter)

        return {
          freeze,
          header: { content: <span>{settings.header}</span>, sorting },
          cell: {
            $type: 'custom' as const,
            renderer: getGridCellRenderer(column, onActionClick, allowToClose),
          },
          width: settings.width,
        }
      }),
    ],
  }
}

export const getCloseDialogMessage = (deposit: Deposit): JSX.Element => {
  return (
    <>
      <Text type="paragraph">{`Are you sure you want to close the deposit ${deposit.id}?`}</Text>
      <Text type="paragraph">The deposit will be refunded to the customer.</Text>
    </>
  )
}
