/* eslint-disable react/no-unused-class-component-methods */
// @flow

/* eslint-disable react/no-unused-prop-types */

import * as React from 'react'

import type { HttpClient, BusinessClaimType } from '../../../types/common'
import type { Navigation, ClaimCreationState, Errors } from '../types'

import type { ApiController } from './apiController'
import type { NavigationController } from './navigationController'
import type { ValidationController } from './validationController'
import type { OrderSelectionHandlerController } from './handlerControllers/orderSelectionHandlerContoller'
import type { ClaimInfoHandlerController } from './handlerControllers/claimInfoHandlerContoller'
import type { ClaimDetailsHandlerController } from './handlerControllers/claimDetailsHandlerContoller'
import type { FormActionHandlerController } from './handlerControllers/formActionHandlerController'

export type WithControllerProps = {|
  httpClient: HttpClient,
  onError: () => void,
  onFormCanceled: () => void,
  onFormSubmitted: (claimId: string) => void,
  claimType: BusinessClaimType,
|}

export type WithControllerState = {|
  navigation: Navigation,
  claimCreation: ClaimCreationState,
  errors: Errors,
|}

export class WithController extends React.Component<WithControllerProps, WithControllerState> {
  api: ApiController

  navigation: NavigationController

  validation: ValidationController

  orderSelectionHandler: OrderSelectionHandlerController

  claimInfoHandler: ClaimInfoHandlerController

  claimDetailsHandler: ClaimDetailsHandlerController

  formActionHandler: FormActionHandlerController
}
