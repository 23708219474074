import * as React from 'react'
import { Control, useController } from 'react-hook-form'
import { Box, Button, Flex, HR, Icon } from '@r1/ui-kit'
import { Facility, ShippingCutOffTime as ShippingCutOffTimeType } from '@r1-webui/facilities-v1'
import { useShippingProfiles } from '../../../hooks/useShippingProfiles'
import { ShippingCutOffTime } from './ShippingCutOffTime'

export const ShippingCutOffTimes = ({
  editable,
  shippingCutOffTimes = [],
  control,
}: {
  editable?: boolean
  shippingCutOffTimes?: Facility['shippingCutOffTimes']
  control: Control
}) => {
  const { profiles } = useShippingProfiles()
  const { field: cutOffTimeField } = useController({
    name: 'shippingCutOffTimes',
    control,
    defaultValue: shippingCutOffTimes,
  })
  const shippinhCutOffTimesValue: ShippingCutOffTimeType[] =
    cutOffTimeField.value as ShippingCutOffTimeType[]

  const onAddShift = React.useCallback(() => {
    const emptyShift = {
      name: '',
      periods: [],
    }
    const updatedShifts = [...shippinhCutOffTimesValue, emptyShift]
    cutOffTimeField.onChange(updatedShifts)
  }, [cutOffTimeField, shippinhCutOffTimesValue])
  const onRemoveShift = React.useCallback(
    (index: number) => () => {
      const updatedShifts = shippinhCutOffTimesValue.filter((_, idx) => idx !== index)
      cutOffTimeField.onChange(updatedShifts)
    },
    [cutOffTimeField, shippinhCutOffTimesValue],
  )

  return (
    <Flex column>
      {shippinhCutOffTimesValue.map((shippingCutOffTime, index) => {
        const isLastShift = index === shippinhCutOffTimesValue.length - 1
        return (
          <React.Fragment key={`${shippingCutOffTime.carrierId}-${index + 1}`}>
            <ShippingCutOffTime
              profiles={profiles}
              editable={editable}
              shippingCutOffTime={shippingCutOffTime}
              control={control}
              indexPrefix={index}
              onRemoveShift={onRemoveShift(index)}
            />
            {!isLastShift && <HR />}
          </React.Fragment>
        )
      })}
      {editable && (
        <Box mt="XS">
          <Button data-test-id="facility__cut-off__add" onClick={onAddShift}>
            <Icon type="plus" /> Add cut-off time
          </Button>
        </Box>
      )}
    </Flex>
  )
}
