// @flow

import * as React from 'react'
import linkify from 'linkify-it'
import { Link } from '@r1/ui-kit'

type Props = {
  text: string,
}

// Code inspired from: https://github.com/tasti/react-linkify/blob/master/src/components/Linkify.jsx

export const LinkifiedText = React.memo<Props>(({ text }) => {
  const linkifyInstance = linkify()
  const matches = linkifyInstance.match(text)

  let lastIndex = 0
  const elements = []

  if (matches) {
    matches.forEach(match => {
      // Push preceding text if there is any
      if (match.index > lastIndex) {
        elements.push(<div>{text.substring(lastIndex, match.index)}</div>)
      }

      elements.push(<Link>{text.substring(match.index, match.lastIndex)}</Link>)
      lastIndex = match.lastIndex
    })
    // Push remaining text if there is any
    if (text.length > lastIndex) {
      elements.push(text.substring(lastIndex, text.length))
    }
    return elements
  }
  return text
})
