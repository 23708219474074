/* eslint-disable */
import * as React from 'react'
import Icon from '../Icon'

export default props => (
  <Icon originalWidth={15} originalHeight={16} {...props}>
    <path d="M7.13962712,15.0576271 L2.97352542,15.0576271 C1.84132203,15.0576271 0.922677966,14.1355932 0.922677966,13.0067797 L0.922677966,2.96610169 C0.922677966,1.83389831 1.84471186,0.915254237 2.97352542,0.915254237 L7.20742373,0.915254237 C7.46166102,0.915254237 7.66505085,0.711864407 7.66505085,0.457627119 C7.66505085,0.203389831 7.46166102,0 7.20742373,0 L2.97352542,0 C1.33623729,0 0.00742372881,1.33220339 0.00742372881,2.96610169 L0.00742372881,13.0067797 C0.00742372881,14.6440678 1.33962712,15.9728814 2.97352542,15.9728814 L7.13962712,15.9728814 C7.39386441,15.9728814 7.59725424,15.7694915 7.59725424,15.5152542 C7.59725424,15.2610169 7.39047458,15.0576271 7.13962712,15.0576271 Z"></path>
    <path d="M14.6786102,7.66440678 L11.7701356,4.7559322 C11.5904746,4.57627119 11.302339,4.57627119 11.122678,4.7559322 C10.9430169,4.93559322 10.9430169,5.22372881 11.122678,5.40338983 L13.2514915,7.53220339 L3.96674576,7.53220339 C3.71250847,7.53220339 3.50911864,7.73559322 3.50911864,7.98983051 C3.50911864,8.2440678 3.71250847,8.44745763 3.96674576,8.44745763 L13.2514915,8.44745763 L11.122678,10.5762712 C10.9430169,10.7559322 10.9430169,11.0440678 11.122678,11.2237288 C11.2108136,11.3118644 11.3294576,11.359322 11.4447119,11.359322 C11.5599661,11.359322 11.6786102,11.3152542 11.7667458,11.2237288 L14.6752203,8.31525424 C14.8582712,8.13220339 14.8582712,7.84067797 14.6786102,7.66440678 Z"></path>
  </Icon>
)
