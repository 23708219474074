// @flow

import React from 'react'
import type { CloseClaim } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimState'
import { ClaimModalAction } from './ClaimModalAction'

type Props = {
  claimId: string,
  handleCloseClaim: CloseClaim,
  canCloseClaim: boolean,
}

export const CloseClaimAction = (props: Props) => {
  const { claimId, handleCloseClaim, canCloseClaim } = props
  return (
    <ClaimModalAction
      handleClaimAction={async (performedOn, reason) => {
        const resp = await handleCloseClaim({ claimId }, { closedOn: performedOn, reason })
        return resp.status
      }}
      canPerformAction={canCloseClaim}
      successText="Claim was successfully closed"
      failText="Cannot close claim. Please, try again later"
      title="Close Claim"
      description="Please enter reason for claim closing:"
    />
  )
}
