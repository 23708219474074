import {
  createFacilityReservationsSchedulerManagmentApi,
  baseUri,
} from '@r1-webui/facilityreservationscheduler-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = '/uisvc/legacy-browserui'

export const reservationApi = createFacilityReservationsSchedulerManagmentApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}/${baseUri}`,
})
