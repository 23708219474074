import styled from '@emotion/styled'

type Props = {
  active: boolean
  selectable: boolean
}

export const ActionTagStyled = styled.div<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.25rem;
  min-width: 4rem;
  padding: 0 0.5rem;
  border-radius: 1.125rem;
  margin: 0.25rem;

  transition: background-color 0.2s linear;

  border: 1px solid;
  border-color: ${({ theme, active }) => (active ? 'transparent' : theme.palette.grey[300])};
  color: ${({ theme, active }) => (active ? theme.palette.blue[600] : 'inherit')};
  background-color: ${({ theme, active }) => (active ? theme.primaryColor[100] : 'inherit')};

  &:hover {
    cursor: ${({ selectable }) => (selectable ? 'pointer' : 'inherit')};
  }
`
