// @flow

import * as React from 'react'
import { PurchaseOrdersScreen } from '../components'
import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

export const PurchaseOrders = () => (
  <PurchaseOrdersScreen httpClient={httpClient} onError={errorHandler} />
)
