// @flow

import React, { useContext, useState, useCallback, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { Flex, Box, H3, Text, Label, Link } from '@r1/ui-kit'
import { Table } from '@r1/grid'

import type { EnumValueArray } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'
import type { AttributeValue } from '@r1-webui/productfamilies-familymanagement-v1/src/types'
import { ServiceContext } from '../../../../../../provider'
import { toObject } from '../../../../../../../../utils/toObject'

import type { ProductsGridProps } from '../../../../../../types/variation.type'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const getAttributeValue = (attributeValue: AttributeValue, enumValues: EnumValueArray): string => {
  switch (enumValues.valueType) {
    case 'IntEnumArray': {
      const enumValueslist = enumValues.enumValues.find(
        ({ id: enumId }) => enumId === attributeValue.value,
      )
      return enumValueslist ? String(enumValueslist.value) : ''
    }
    case 'StringEnumArray': {
      const enumValueslist = enumValues.enumValues.find(
        ({ id: enumId }) => enumId === attributeValue.value,
      )
      return enumValueslist ? String(enumValueslist.value) : ''
    }
    case 'DecimalEnumArray': {
      const enumValueslist = enumValues.enumValues.find(
        ({ id: enumId }) => enumId === attributeValue.value,
      )
      return enumValueslist ? String(enumValueslist.value) : ''
    }

    default:
      return ''
  }
}

const Counter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.space.XXS};
  margin-left: 10px;
`

const getTableSettings = ({ attributeDefinitionIds, attributesList }) => ({
  displaySettings: {
    columnsSettings: [
      {
        width: 150,
        header: {
          content: 'Primary Identifier',
        },
        cell: {
          $type: 'custom',
          renderer: ({ primaryIdentifier, id }) => (
            <Link href={`/r1/producttemplate/${id}`} target="_blank">
              {primaryIdentifier}
            </Link>
          ),
        },
      },
      {
        width: 300,
        header: {
          content: 'Title',
        },
        cell: {
          $type: 'custom',
          renderer: ({ title }) => <Text overflow="ellipsis-delimiter">{title}</Text>,
        },
      },
    ].concat(
      attributeDefinitionIds.map(id => ({
        header: {
          content: attributesList[id] ? attributesList[id].name : id,
        },
        cell: {
          $type: 'custom',
          renderer: data => {
            const attribute = data[id]
            if (!attribute) return ' - '
            switch (attribute.status) {
              case 'Valid': {
                if (!attribute.attributeValue) return ''
                return attributesList[id].enumValues
                  ? getAttributeValue(attribute.attributeValue, attributesList[id].enumValues)
                  : ''
              }
              case 'ValueNotSelected':
                return <Label type="warning">Value Not Selected</Label>
              case 'AttributeDefinitionNotBound':
                return <Label type="error">Attribute Not Bound</Label>
              default:
                return ' - '
            }
          },
        },
      })),
    ),
  },
})

const mapProducts = products =>
  products.map(({ productInfo: { id, title, identifiers }, attributes }) =>
    attributes.reduce((acc, attr) => ({ ...acc, [attr.attributeDefinitionId]: attr }), {
      id,
      title,
      primaryIdentifier: identifiers.primary.value,
    }),
  )
export const ProductsGrid = (props: ProductsGridProps) => {
  const { attributeDefinitions, productIdentifierValues, attributeDefinitionIds, variationId } =
    props

  const [firstIsLoad, setFirstIsLoad] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productList, setProductList] = useState([])

  const {
    variationsService: { calculateProducts, getProducts },
  } = useContext(ServiceContext)

  const attributesList = useMemo(() => toObject(attributeDefinitions, 'id'), [attributeDefinitions])

  const count = useMemo(() => productList.length, [productList])

  const fetchData = useCallback(async () => {
    try {
      if (!firstIsLoad && variationId) {
        setProductList(mapProducts(await getProducts(variationId)))
        setFirstIsLoad(true)
      } else if (productIdentifierValues.length === 0 || attributeDefinitionIds.length === 0) {
        setProductList([])
      } else {
        const products = await calculateProducts({
          filter: { type: 'Filter', productIdentifierValues },
          variations: { attributeDefinitionIds },
        })
        setProductList(mapProducts(products))
      }
    } catch (error) {
      setProductList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationId, productIdentifierValues, attributeDefinitionIds, getProducts, calculateProducts])

  useEffect(() => {
    setIsLoading(true)
    fetchData().then(() => {
      setIsLoading(false)
    })
  }, [fetchData])

  return (
    <>
      <Box>
        <StyledH3>Products in variation</StyledH3>
      </Box>
      <Box mb="M">
        <Flex align="center">
          Products
          <Counter>{count}</Counter>
        </Flex>
      </Box>
      <Box>
        <Table
          height={500}
          loading={isLoading}
          {...getTableSettings({ attributeDefinitionIds, attributesList })}
          data={productList}
        />
      </Box>
    </>
  )
}
