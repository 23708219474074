// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { compose, withStateHandlers } from 'recompose'

import { withTheme, Box, Button, Icon, Input, Text, H1 } from '@r1/ui-kit'
import { Table } from '@r1/grid'

import { gridTheme } from '../../../constants'
import type { Field } from '../types'

type SharingCardProps = {
  // columnWidth: number,
  theme: Object,
  sharedCompanies: Array<Object>,
  sharedCompanyName: string,
  companiesLoading: { [string]: boolean },
  onCompanyNameChange: string => void,
  onRemoveShared: Field => void,
  onCompanyShare: (event: Event, companyName: string) => Promise<any>,
  isAllowedToEdit: boolean,
}

const headers = [
  { id: 'name', name: 'Sharing for' },
  { id: 'owningCompanyId', name: 'Sharing by' },
  { id: 'approvedOn', name: 'Status' },
  { id: 'actions', name: 'Actions' },
]

const SharingStatus = styled('span')`
  display: inline-block;
  vertical-align: top;
  color: ${({ isApproved, theme: { palette } }) =>
    isApproved ? palette.green[500] : palette.grey[500]};
  &:before {
    content: '${({ isApproved }) => (isApproved ? 'Accepted' : 'Pending')}';
  }
`

const tableData = (sharedCompanies, companiesLoading, onRemoveShared, theme) => ({
  displaySettings: {
    freeze: false,
    showCounter: true,
    columnsSettings: headers.map(header => ({
      width: header.id === 'actions' || header.id === 'approvedOn' ? 150 : 200 + 130 / 3,
      header: {
        content: <div>{header.name}</div>,
      },
      cell: {
        $type: header.id === 'actions' || header.id === 'approvedOn' ? 'custom' : 'text',
        field: header.id,
        // eslint-disable-next-line consistent-return
        renderer: field => {
          if (header.id === 'actions') {
            return (
              <Box>
                <Icon
                  cursor="pointer"
                  type="trash"
                  color={theme.palette.blue[500]}
                  onClick={_ => {
                    if (!companiesLoading[field.id]) {
                      onRemoveShared(field)
                    }
                  }}
                />
              </Box>
            )
          }

          if (header.id === 'approvedOn') {
            return <SharingStatus isApproved={field.approvedOn} />
          }
        },
      },
    })),
  },
  height: sharedCompanies && sharedCompanies.length * gridTheme.rowHeight,
  data: sharedCompanies,
})

const defaultState = {
  sharedCompanyName: '',
}

const enhance = compose(
  // $FlowFixMe[missing-annot]
  withTheme,
  withStateHandlers(() => defaultState, {
    onCompanyNameChange: state => (sharedCompanyName: string) => ({ ...state, sharedCompanyName }),
  }),
)

const SharingCardComponent = ({
  theme,
  sharedCompanies,
  sharedCompanyName,
  companiesLoading,
  onCompanyNameChange,
  onCompanyShare,
  onRemoveShared,
  isAllowedToEdit,
}: SharingCardProps) => {
  const ShareButton = styled(Button)`
    margin-left: 10px;
  `
  const data = tableData(sharedCompanies, companiesLoading, onRemoveShared, theme)

  return (
    <React.Fragment>
      <Box pt="M">
        <H1>Sharing profile settings</H1>
      </Box>
      <Box>
        <Text>Enter company name to be shared with</Text>
      </Box>
      <Box pb="M">
        <Input
          value={sharedCompanyName}
          placeholder="Enter company name"
          onChange={onCompanyNameChange}
        />
        <ShareButton
          disabled={!isAllowedToEdit || companiesLoading.all}
          onClick={event => onCompanyShare(event, sharedCompanyName)}
        >
          SHARE
        </ShareButton>
      </Box>
      <Box maxWidth={1015}>
        {/* $FlowFixMe[prop-missing] */}
        {/* $FlowFixMe[incompatible-type] */}
        <Table {...data} />
      </Box>
    </React.Fragment>
  )
}

export const SharingCard = enhance(SharingCardComponent)
