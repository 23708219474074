import { handleServerError } from '@r1/core-blocks'
import { legacyEspm, Profile } from '../../../api/legacyEspm'

const MARKETPLACE_PURPOSE_ID = 'B2CSellingVenueProfile'
const SHIPPING_PURPOSE_ID = 'ShippingProfile'
const THIRD_PARTY_PURPOSE_ID = 'ThirdPartyShippingProfile'

export const getMarketplaces = async (): Promise<Profile[]> => {
  const res = await legacyEspm.getProfilesByPurpose(MARKETPLACE_PURPOSE_ID)

  if (!Array.isArray(res)) {
    handleServerError(res)
    return []
  }

  return res
}

export const getShippingProfiles = async (): Promise<Profile[]> => {
  const res = await legacyEspm.getProfilesByPurpose(SHIPPING_PURPOSE_ID)

  if (!Array.isArray(res)) {
    handleServerError(res)
    return []
  }

  return res
}

export const getThirdPartyShippingProfiles = async (): Promise<Profile[]> => {
  const res = await legacyEspm.getProfilesByPurpose(THIRD_PARTY_PURPOSE_ID)
  if (!Array.isArray(res)) {
    handleServerError(res)
    return []
  }

  return res
}

export type { Profile } from '../../../api/legacyEspm'
