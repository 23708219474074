// @flow

import * as React from 'react'

type ServiceData = {|
  priceService: $AnyObject,
  httpClient: $AnyObject,
|}

export const ServiceContext: React.Context<ServiceData> = React.createContext({
  priceService: {},
  httpClient: {},
})
