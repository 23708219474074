// @flow

import * as React from 'react'
import { Modal, Flex, Box, Textarea, Uploader, FormField, Loader } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

import { Photo, PhotoWrapper, IconWrapper } from './StyledComponents'

import type { ViewProps } from './types'

export const View = (props: ViewProps) => {
  const {
    show,
    title,
    message,
    images,
    isUploading,
    isMessageLengthError,
    inputHandler,
    uploaderHandler,
    deleteImageHandler,
    closeHandler,
    saveHandler,
  } = props

  const bottomActionButton: BottomActionButton[] = React.useMemo(() => {
    return [
      {
        align: 'right',
        onClick: closeHandler,
        color: 'secondary',
        title: 'Cancel',
      },
      {
        align: 'right',
        onClick: saveHandler,
        color: 'primary',
        title: 'Save',
      },
    ]
  }, [closeHandler, saveHandler])

  return (
    <Modal
      isControlled
      size="M"
      show={show}
      title="Add Notes and Photos"
      description={title}
      actionButtons={bottomActionButton}
      onEscapeKeyDown={closeHandler}
    >
      <Box mb="XS">
        <FormField>
          <FormField.Label>Product Notes:</FormField.Label>
          <Textarea
            data-test-id="product-note"
            placeholder="Add short description here..."
            rows={2}
            value={message}
            error={isMessageLengthError}
            onChange={inputHandler}
          />
          <FormField.Error>
            {isMessageLengthError ? 'Note must contain no more than 300 characters' : ''}
          </FormField.Error>
        </FormField>
      </Box>
      {isUploading ? <Loader /> : <Uploader onChange={uploaderHandler} />}
      {images.length > 0 && (
        <Flex flexWrap spaceBetween="L">
          {images.map((image, index) => (
            <PhotoWrapper key={image.imageId} align="center" justify="center">
              <Photo src={image.base64Image} />
              <IconWrapper type="clear" onClick={() => deleteImageHandler(index)} />
            </PhotoWrapper>
          ))}
        </Flex>
      )}
    </Modal>
  )
}
