import * as React from 'react'
import { Control, useController } from 'react-hook-form'
import { Flex, Button, DatePicker, FormField, Link, StructuredList, Alert } from '@r1/ui-kit'
import { Facility } from '@r1-webui/facilities-v1'
import { StructuredListColumnSettings } from '@r1/ui-kit/contracts/ts/StructuredList'
import { formatInTimeZone } from 'date-fns-tz'
import { sanitizeTimeString } from '../../../../../utils/dates/sanitizeTimeString'

const formatDate = (date: string) => formatInTimeZone(new Date(date), 'GMT', 'MMMM dd, yyyy')

export const NonWorkingDays = ({
  editable,
  nonWorkingDays = [],
  control,
}: {
  editable?: boolean
  nonWorkingDays?: Facility['nonWorkingDays']
  control: Control
}) => {
  const { field: nonWorkingDaysField } = useController({
    name: 'nonWorkingDays',
    control,
    defaultValue: nonWorkingDays,
  })
  const nonWorkingDaysValue = nonWorkingDaysField.value as string[]

  const [date, setDate] = React.useState<string | null>(null)
  const listData = React.useMemo(() => {
    return nonWorkingDaysValue.map(item => {
      const formattedDate = formatDate(item)

      return { date: formattedDate, raw: item }
    })
  }, [nonWorkingDaysValue])

  const sanitizedDate = sanitizeTimeString(date, '+00:00')
  const canAddDate = sanitizedDate && !nonWorkingDaysValue.some(day => day === sanitizedDate)
  const showWarning = date && !canAddDate

  const onDateInputClear = () => setDate(null)

  const handleAddDate = () => {
    if (canAddDate) {
      const updatedNonWorkingDays = [...nonWorkingDaysValue, sanitizedDate]

      nonWorkingDaysField.onChange(updatedNonWorkingDays)
      setDate(null)
    }
  }

  const handleRemoveDate = (datestring: string) => () => {
    const updatedNonWorkingDays = listData.reduce<Array<string>>((updatedDays, day) => {
      if (datestring !== day.date) {
        updatedDays.push(day.raw)
      }
      return updatedDays
    }, [])
    nonWorkingDaysField.onChange(updatedNonWorkingDays)
  }

  const columnsSettings: StructuredListColumnSettings<{ date: string }>[] = [
    { header: 'List of non-working days', cell: { $type: 'text', field: 'date' } },
    {
      header: '',
      cell: {
        $type: 'custom',
        renderer: (item: { date: string }) => (
          <Link onClick={handleRemoveDate(item.date)}>delete</Link>
        ),
      },
    },
  ]

  return (
    <React.Fragment>
      {editable && (
        <FormField>
          <FormField.Label>Add exception date</FormField.Label>
          <Flex spaceBetween="XXS" pb="S">
            <DatePicker
              data-test-id="exception-date"
              value={date}
              onChange={setDate}
              onRemove={onDateInputClear}
            />
            <Button disabled={!canAddDate} onClick={handleAddDate}>
              Add
            </Button>
          </Flex>
        </FormField>
      )}
      {showWarning ? (
        <Flex pb="S">
          <Alert level="warning">This date has already been added</Alert>
        </Flex>
      ) : null}
      {nonWorkingDaysValue.length !== 0 && (
        <StructuredList columnsSettings={columnsSettings} data={listData} />
      )}
    </React.Fragment>
  )
}
