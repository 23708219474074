/* eslint-disable no-nested-ternary */
// @flow

import React from 'react'
import { Box, FormField, Icon, NumericInput } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { NumberRangeAttributeChildrenProps } from '../../../../../../types/attribute.type'

const Divider = styled('div')`
  ${({ theme }) => `color: ${theme.palette.grey[500]};`};
  margin: 0 14px;
  font-size: 14px;
`

const MAX_NUMBER = 2147483647

const minAllowedValue = -1 * MAX_NUMBER
const maxAllowedValue = MAX_NUMBER

export const NumberRange = ({
  error,
  title,
  handleChange,
  optionIndex,
  disabled,
  value,
  valueType,
  // handleBlur for correct error display
  handleBlur,
}: NumberRangeAttributeChildrenProps) => {
  return (
    <FormField error={!!error} disabled={disabled}>
      <FormField.Label>{title}</FormField.Label>
      <Box minWidth={255}>
        <NumericInput
          placeholder="Enter number"
          value={
            value !== null && value.from != null
              ? valueType === 'DecimalRange'
                ? parseFloat(value.from)
                : parseInt(value.from, 10)
              : null
          }
          maxFractionDigits={valueType === 'DecimalRange' ? 100 : 0}
          min={minAllowedValue}
          max={maxAllowedValue}
          onChange={handleChange(['Attributes', optionIndex, 'formValue', 'from'])}
          onBlur={handleBlur(['Attributes', optionIndex, 'formValue'])}
        />
      </Box>
      <Divider>
        <Icon type="minus" />
      </Divider>
      <Box minWidth={255}>
        <NumericInput
          placeholder="Enter number"
          value={
            value !== null && value.to != null
              ? valueType === 'DecimalRange'
                ? parseFloat(value.to)
                : parseInt(value.to, 10)
              : null
          }
          min={minAllowedValue}
          max={maxAllowedValue}
          maxFractionDigits={valueType === 'DecimalRange' ? 100 : 0}
          onChange={handleChange(['Attributes', optionIndex, 'formValue', 'to'])}
          onBlur={handleBlur(['Attributes', optionIndex, 'formValue'])}
        />
      </Box>
      <FormField.Error>{error}</FormField.Error>
    </FormField>
  )
}
