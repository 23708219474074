// @flow

import styled from '@emotion/styled'
import { Box, Flex } from '@r1/ui-kit'

export const Wrapper = styled(Flex)`
  ${({ theme }) => `
    width: 100%;
    border-radius: 4px;
    background: ${theme.palette.grey[100]};
  `}
`

export const ClaimAmountValue = styled('span')`
  ${({ theme }) => `
    font-size: 24px;
    line-height: 30px;
    color: ${theme.palette.blue[500]};
  `}
`

export const ClaimReviseLabel = styled('div')`
  font-weight: bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.yellow[500]};
`

export const ClaimAmountLabel = styled(Box)`
  font-size: 11px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.grey[700]};
`

export const ClaimInfoBlockTitle = styled(Flex)`
  ${({ theme }) => `
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${theme.palette.grey[700]};
    background-color: ${theme.palette.grey[300]};
    padding: ${theme.space.XXS};
  `}
`

// DEBT(telichkin): Replace this component by StatusIndicator from UI-Kit
// when new version of StatusIndicator will be merged into UI-Kit
const getStatusIndicatorColor = (status, theme) => {
  if (status === 'Closed') return theme.palette.grey[400]
  if (status === 'Created') return theme.palette.yellow[600]
  if (status === 'Error') return theme.palette.red[600]
  if (status === 'In progress') return theme.palette.blue[500]
  if (status === 'Canceled') return theme.palette.grey[400]
  if (status === 'Resolved') return theme.palette.green[600]
  return theme.palette.grey[400]
}

export const StatusIndicator = styled('span')`
  display: inline-block;
  width: 12px;
  height: 12px;
  min-height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ theme, status }) => getStatusIndicatorColor(status, theme)};
`

const getDecisionStatusIndicator = (status, theme) => {
  if (status === 'Pending') return theme.palette.grey[400]
  if (status === 'Failed') return theme.palette.red[600]
  if (status === 'In progress') return theme.palette.blue[500]
  if (status === 'Succeeded') return theme.palette.green[600]
  return theme.palette.grey[400]
}

export const DecisionStatusIndicator = styled('span')`
  display: inline-block;
  width: 12px;
  height: 12px;
  min-height: 12px;
  margin-right: 8px;
  border-radius: 50%;
  background-color: ${({ theme, status }) => getDecisionStatusIndicator(status, theme)};
`

export const Nowrap = styled('span')`
  white-space: nowrap;
`
