// @flow

const schema = {
  '401': {
    $type: 'Unauthorized',
    message: 'Unauthorized',
  },
  '403': {
    $type: 'Forbidden',
    message: 'Forbidden',
  },
  '404': {
    $type: 'ProductNotFound',
    message: 'ProductNotFound',
  },
  default: {
    $type: 'Common',
  },
}

export function errorHandler(error: Object, options: Object = {}) {
  const { response } = error
  const errorSchema = Object.keys(options).reduce((acc, key) => {
    if (acc[key]) {
      acc[key] = options[key]
    }
    return acc
  }, schema)
  const notice = errorSchema[response.status] || errorSchema.default

  return { ...notice, payload: { response } }
}
