import React from 'react'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'

type OfferLastSaleProps = {
  offer: Offer
}

export const OfferLastSale = React.memo<OfferLastSaleProps>(({ offer }) => {
  const { lastQuantity, lastPrice } = offer.statistic
  return lastQuantity && lastPrice ? (
    <div>{`${lastQuantity.value} $${lastPrice.amount}`}</div>
  ) : (
    <div>-</div>
  )
})
