import * as React from 'react'
import { Link, NotificationSystem } from '@r1/ui-kit'
import { useEffect, useState, useCallback } from 'react'
import { Rule } from '@r1-webui/shipping-returnshippingrules-v1'
import { createRule, getRule, updateRule } from '../../../services/shippingRulesService'
import { INITIAL_VALUE } from '../Drawer/constants'
import { DrawerType, FormValues } from '../types'

export const useRule = (
  ruleId: string,
  type: DrawerType | null,
  onCloseDrawer: () => void,
  onOpenDrawer: (v: { id: string; type: DrawerType }) => void,
  onRefreshGridData: () => void,
) => {
  const [rule, setRule] = useState<FormValues | null>(null)
  const [isRuleLoading, setLoading] = useState<boolean>(false)
  const [isSubmitting, setSubmitting] = useState<boolean>(false)

  useEffect(() => {
    const fetchRule = async () => {
      setRule(null)
      setLoading(true)

      const res = await getRule(ruleId)

      const formValues: FormValues | null = res ? { ...res } : null

      if (formValues && type === 'DUPLICATE') {
        formValues.priority.type = null
        formValues.name += ' (Copy)'
      }
      setLoading(false)
      setRule(formValues)
    }

    if (type === 'EDIT' || type === 'DUPLICATE') {
      fetchRule()
    } else {
      setRule(INITIAL_VALUE)
    }
  }, [ruleId, type])

  const submit = useCallback(
    async (updatedRule: FormValues) => {
      setSubmitting(true)

      if (type === 'CREATE' || type === 'DUPLICATE') {
        const res = await createRule(updatedRule as Rule)
        setSubmitting(false)

        if (res) {
          onCloseDrawer()
          onRefreshGridData()

          NotificationSystem.addNotification({
            level: 'success',
            message: `New rule ${updatedRule.name} was created`,
            children: (
              <Link onClick={() => onOpenDrawer({ id: res?.id ?? '', type: 'EDIT' })}>
                <span style={{ color: 'white' }}>View rule</span>
              </Link>
            ),
            autoDismiss: false,
          })
        }
        return
      }

      const res = await updateRule(updatedRule as Rule, ruleId)

      if (res?.version && Number.isInteger(res.version)) {
        setRule({ ...updatedRule, version: res.version })
      }

      setSubmitting(false)

      if (res) {
        NotificationSystem.addNotification({
          level: 'success',
          message: `Rule ${updatedRule.name} was updated`,
          autoDismiss: false,
        })
      }
    },
    [onCloseDrawer, onOpenDrawer, onRefreshGridData, ruleId, type],
  )

  return { rule, isRuleLoading, submit, isSubmitting }
}
