// @flow

import type { WithControllerState } from './types'

export const defaultState: WithControllerState = {
  lineId: '',
  replacements: [],
  productId: '',
  isLoading: false,
}
