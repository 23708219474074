// @flow

import type { AttributeType, AttributeTypeMapping, Uniqueness, UniquenessMapping } from './types'

const attributeTypeMapping: AttributeTypeMapping = {
  Boolean: 'Boolean',
  Date: 'Date',
  DateRange: 'Date range',
  Decimal: 'Decimal number',
  DecimalEnumeration: 'Enumeration of decimal numbers',
  DecimalRange: 'Decimal number range',
  IntEnumeration: 'Enumeration of integer numbers',
  IntRange: 'Range of integer numbers',
  Integer: 'Integer number',
  String: 'Free text',
  StringEnumeration: 'Enumeration of free text',
}

export const attributeTypeMapper = (type: AttributeType) => {
  return attributeTypeMapping[type] ? attributeTypeMapping[type] : type
}

const uniquenessMapping: UniquenessMapping = {
  NotUnique: 'Not unique',
  Unique: 'Unique',
  UniquePerManufacturer: 'Unique within manufacturer',
}

export const uniquenessMapper = (uniqueness: Uniqueness) => {
  return uniquenessMapping[uniqueness] ? uniquenessMapping[uniqueness] : uniqueness
}
