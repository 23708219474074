// @flow

import * as React from 'react'
import { Modal, Flex } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

import type { Replacement } from '../../types/ClaimCreation'
import { ReplacementItem } from '../ReplacementItem'

import type { ViewProps } from './types'
import { ReplacementList, EmptyList } from './StyledComponents'
import { ReplacementsPicker } from './ReplacementsPicker'

const FORM_WIDTH = 992
const FORM_HEIGHT = 500
const FORM_PADDING = 32

export const View = (props: ViewProps) => {
  const {
    show,
    title,
    replacements,
    productId,
    addReplacementHandler,
    deleteReplacementHandler,
    closeHandler,
    saveHandler,
  } = props

  const onReplacementChanged = (replacement: Replacement, isChecked: boolean) => {
    if (isChecked) {
      addReplacementHandler(replacement)
    } else {
      deleteReplacementHandler(replacement)
    }
  }

  const bottomActionButton: BottomActionButton[] = React.useMemo(
    function generateBottomActionButton() {
      return [
        {
          align: 'right',
          onClick: closeHandler,
          color: 'secondary',
          title: 'Cancel',
        },
        {
          align: 'right',
          onClick: saveHandler,
          color: 'primary',
          title: 'Save',
        },
      ]
    },
    [closeHandler, saveHandler],
  )

  return (
    <Modal
      isControlled
      size="L"
      show={show}
      title={`Pick Replacements: ${title}`}
      actionButtons={bottomActionButton}
      onEscapeKeyDown={closeHandler}
    >
      <Flex column>
        <ReplacementsPicker
          width={FORM_WIDTH - FORM_PADDING * 2}
          height={FORM_HEIGHT}
          replacements={replacements}
          productId={productId}
          onReplacementChanged={onReplacementChanged}
        />
        <ReplacementList mt="M" mb="M">
          <Flex mr="S">Replacements:</Flex>
          <Flex>
            {replacements.length > 0 ? (
              <Flex flexWrap spaceBetween="M">
                {replacements.map(replacement => (
                  <Flex key={replacement.productId}>
                    <ReplacementItem
                      editMode
                      replacement={replacement}
                      deleteHandler={deleteReplacementHandler}
                    />
                  </Flex>
                ))}
              </Flex>
            ) : (
              <EmptyList>N/A</EmptyList>
            )}
          </Flex>
        </ReplacementList>
      </Flex>
    </Modal>
  )
}
