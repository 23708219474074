import { Period, DayOfTheWeek, Shift } from '@r1-webui/facilities-v1'
import { getHours } from 'date-fns'
import { utcToLocal } from '../../../utils/dates/utcToLocal'
import { DAYS_OF_WEEK } from './getDayOfWeek'

const isCurrentOrNextDay = (startDay: DayOfTheWeek, finishDay: DayOfTheWeek) => {
  const startDayIndex = DAYS_OF_WEEK.indexOf(startDay)
  const isCurrentDay = finishDay === DAYS_OF_WEEK[startDayIndex]
  const isNextDay =
    finishDay === DAYS_OF_WEEK[startDayIndex + 1] ||
    (startDayIndex === DAYS_OF_WEEK.length - 1 && finishDay === DAYS_OF_WEEK[0])

  return { isCurrentDay, isNextDay }
}

// function returns true if period is more than 24 hours
// it returns two booleans for validating day-of-week or time field
export const getIsOverWholeDay = (period: Period) => {
  const { isCurrentDay, isNextDay } = isCurrentOrNextDay(period.startDay, period.finishDay)
  const fromDate = utcToLocal(period.from)
  const toDate = utcToLocal(period.to)
  const isInNextDay = isNextDay && getHours(fromDate) > getHours(toDate)

  if (isCurrentDay || isInNextDay) return { isOverByDay: false, isOverByTime: false }

  if (!isCurrentDay && !isNextDay) return { isOverByDay: true, isOverByTime: false }

  return { isOverByDay: false, isOverByTime: true }
}

export const validateShifts = (
  shifts: Shift[],
): Record<string, { type: string; message: string }> => {
  const shiftsErrors = shifts.reduce(
    (acc: Record<string, { type: string; message: string }>, { periods }, idx) => {
      const result = acc
      periods.forEach((period, index) => {
        const { isOverByDay, isOverByTime } = getIsOverWholeDay(period)
        if (isOverByDay || isOverByTime) {
          const key = `shifts.${idx}.periods.${index}.${isOverByDay ? 'finishDay' : 'to'}`
          result[key] = {
            type: 'custom',
            message: 'Choose period within 23:59',
          }
        }
      })
      return result
    },
    {},
  )
  return shiftsErrors
}
