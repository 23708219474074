/* eslint-disable no-underscore-dangle */
import { SubmissionError } from 'redux-form'
import { omit } from 'ramda'
import { NotificationSystem } from '@r1/ui-kit'
import { createModelModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productApi } from '../../api/products'

const {
  actions: moduleActions,
  name,
  initialState,
  types,
  reducers,
  selectors,
} = createModelModule({
  name: '@products',
  params: ['id'],
  fetchItemFunc: async ({ id }, api) => {
    const [product, unitRelations] = await Promise.all([
      productApi.getProduct({ id }),
      api.client.get(`/uisvc/fmsi-export/nogr/api/v1/parts/unit-relations/${id}`),
    ])
    return { ...product, unitRelations }
  },
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

const handleSubmissionError = error => {
  const submissionError = {}
  if (error.status === 432) {
    const { fieldErrors = [], message } = error.errorData || error.data
    submissionError._error = message
    fieldErrors.forEach(errObj => {
      const errMsg = errObj.errors.join('; ')
      submissionError[errObj.name] = errMsg
    })
    throw new SubmissionError(submissionError)
  }
  if (error.status === 400) {
    const { badProperties = [], message } = error.errorData || error.data || {}
    submissionError._error = message // change to: error.message
    badProperties.forEach(errObj => {
      submissionError[errObj.name] = errObj.message
    })
    throw new SubmissionError(submissionError)
  }

  const { message } = error.data || {}
  submissionError._error = message
  throw new SubmissionError(submissionError)
}

const saveModel =
  ({ slp, serialNumber, internalNote, classifications, vendorInfo }, productId) =>
  () =>
    productApi
      .saveProduct(
        {
          id: productId,
        },
        {
          slp,
          serialNumber,
          internalNote,
          classifications,
          vendorInfo: omit(['$type'], vendorInfo),
        },
      )
      .catch(({ response: error }) => {
        if (error.status === 432) {
          const { fieldErrors = [] } = error.errorData || error.data
          fieldErrors.forEach(errObj => {
            if (errObj.name.startsWith('Classifications')) {
              const title = `Classifications: ${errObj.name.substr('Classifications'.length + 1)}`
              errObj.errors.forEach(message =>
                NotificationSystem.addNotification({ level: 'error', title, message }),
              )
            }
          })
        }
        handleSubmissionError(error)
      })

const saveUpc = (id, value) => () => productApi.changeProductTemplate({ id }, JSON.stringify(value))

const actions = { saveModel, saveUpc, ...moduleActions }

export { selectors, actions }
