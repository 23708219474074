// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Loader as UiKitLoader } from '@r1/ui-kit'

const LoaderOverlay = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  z-index: 999999;
`

const Loader = () => (
  <LoaderOverlay>
    <UiKitLoader />
  </LoaderOverlay>
)

export { Loader }
