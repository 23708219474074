import React from 'react'
import { Helmet } from 'react-helmet'
import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { Content, Main } from '@r1/wireframe-primary'
import { GOWHOLESALE_URL_DEV } from '../../const'
import { getPageEditorPermission } from '../../utils'
import { GwsPageEditorPermission } from '../../gws-page-editor'

/**
 * <gws-page-editor> is web component
 * script page_editor.js provides registration of it
 * */
export const GoWholesaleHomePageEditorScreen = () => {
  const gwsPageEditorSrcUrl = window._env_?.GWS_PAGE_EDITOR_URL
  const gwsPageEditorBaseUrl = window._env_?.GOWHOLESALE_URL

  const [allowedPermissions] = useAccessControl()

  const permission = React.useMemo<GwsPageEditorPermission | null>(
    () => getPageEditorPermission(allowedPermissions),
    [allowedPermissions],
  )

  return typeof gwsPageEditorSrcUrl === 'string' ? (
    <>
      <Helmet>
        <link rel="stylesheet" href={`${gwsPageEditorSrcUrl}/page_editor.css`} />
        <script src={`${gwsPageEditorSrcUrl}/page_editor.js`} type="module" />
      </Helmet>
      <PageAccessControl permissions={[PERMISSIONS.allowGwsHomePageEditorView]}>
        {permission !== null && (
          <Main fullScreen>
            <Content>
              <gws-page-editor
                page="home"
                permission={permission}
                baseURL={
                  typeof gwsPageEditorBaseUrl === 'string'
                    ? gwsPageEditorBaseUrl
                    : GOWHOLESALE_URL_DEV
                }
              />
            </Content>
          </Main>
        )}
      </PageAccessControl>
    </>
  ) : null
}
