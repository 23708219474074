// @flow

import * as React from 'react'

import { createApi } from '../../api/api'
import type { Replacement } from '../../types/ClaimCreation'
import type { ViewProps, WithControllerState, WithControllerProps } from './types'
import { defaultState } from './defaultState'

export function withController(
  View: React.ComponentType<ViewProps>,
): React.ComponentType<WithControllerProps> {
  class ViewWithController extends React.Component<WithControllerProps, WithControllerState> {
    state = defaultState

    // eslint-disable-next-line react/no-unused-class-component-methods
    api = createApi(this.props.httpClient)

    componentDidUpdate(prevProps) {
      const { orderLine } = this.props

      if (orderLine && (!prevProps.orderLine || orderLine.lineId !== prevProps.orderLine.lineId)) {
        if (!orderLine.notes) {
          this.setState({
            lineId: orderLine.lineId,
            replacements: [],
            productId: orderLine.units.length > 0 ? orderLine.units[0].id : '',
          })
        } else {
          this.setState({
            lineId: orderLine.lineId,
            replacements: orderLine.replacements || [],
            productId: orderLine.units.length > 0 ? orderLine.units[0].id : '',
          })
        }
      }
    }

    addReplacementHandler = (replacement: Replacement) => {
      this.setState(({ replacements: prevReplacements }) => {
        const replacements = [...prevReplacements, replacement]
        return { replacements }
      })
    }

    deleteReplacementHandler = (replacement: Replacement) => {
      this.setState(({ replacements: prevReplacements }) => {
        const replacements = [...prevReplacements]

        const index = replacements.findIndex(r => r.productId === replacement.productId)
        if (index === -1) {
          return null
        }

        replacements.splice(index, 1)
        return { replacements }
      })
    }

    closeHandler = () => this.props.onOrderLineReplacementsModalShow(null)

    saveHandler = () => {
      const { lineId, replacements } = this.state

      this.props.onOrderLineReplacementsChange(lineId, replacements)
      this.closeHandler()
    }

    render() {
      const { replacements, productId } = this.state
      const { orderLine, isVisible } = this.props
      const title = (orderLine && orderLine.productInfo.title) || ''

      return (
        <View
          show={isVisible}
          title={title}
          replacements={replacements}
          productId={productId}
          addReplacementHandler={this.addReplacementHandler}
          deleteReplacementHandler={this.deleteReplacementHandler}
          saveHandler={this.saveHandler}
          closeHandler={this.closeHandler}
        />
      )
    }
  }

  return ViewWithController
}
