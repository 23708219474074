import * as React from 'react'
import { useState, useCallback, useRef } from 'react'
import { Flex } from '@r1/ui-kit'
import { Content, ContentHeader, Main } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { GridGenerator } from '@r1/grid-generator/typings/GridGenerator'
import { RmaShippingRulesGrid } from './RmaShippingRulesGrid'
import { RmaShippingRulesDrawer } from './Drawer/RmaShippingRulesDrawer'
import { DrawerType } from './types'

export type PrimitiveValue = string | number | boolean
export type DataItem = {
  [columnId: string]: PrimitiveValue
}

export const RmaShippingRules = () => {
  const [drawerType, setDrawerType] = useState<null | DrawerType>(null)
  const [ruleId, viewRuleId] = useState<string>('')
  const tableRef = useRef<GridGenerator>(null)

  const refreshGridData = useCallback(() => {
    if (tableRef.current) tableRef.current.refreshGrid()
  }, [tableRef])

  const onOpenDrawer = useCallback(({ id, type }: { id: string; type: DrawerType }) => {
    viewRuleId(id)
    setDrawerType(type)
  }, [])

  const onCloseDrawer = useCallback(() => {
    setDrawerType(null)
    viewRuleId('')
  }, [])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowRMAShippingRulesView]}>
      <Main>
        <ContentHeader />

        <ContentHeader.Title description="Calculate the best shipping option for the RMA product">
          RMA Shipping Auto Processing Rules
        </ContentHeader.Title>

        <Content>
          <Flex column>
            <RmaShippingRulesGrid tableRef={tableRef} onOpenDrawer={onOpenDrawer} />
          </Flex>

          <RmaShippingRulesDrawer
            ruleId={ruleId}
            drawerType={drawerType}
            onRefreshGridData={refreshGridData}
            onOpenDrawer={onOpenDrawer}
            onCloseDrawer={onCloseDrawer}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
