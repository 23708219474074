import * as React from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Checkbox, FormField, Input, Select, Flex } from '@r1/ui-kit'
import { InventoryType, MasterProgramRule } from '@r1-webui/masterprograms-v1'
import { Value } from '../../Value'

const inventoryTypeOptions: Array<{ id: InventoryType; value: InventoryType }> = [
  {
    id: 'AsIs',
    value: 'AsIs',
  },
  { id: 'Finished', value: 'Finished' },
  { id: 'SaaS', value: 'SaaS' },
]

export const MainSettings = ({
  editable,
  name = '',
  clientProgramName = '',
  inventoryType = inventoryTypeOptions[0].id,
  isActive = true,
  parentProgramName = '',
}: Partial<MasterProgramRule> & {
  editable?: boolean
}) => {
  const { control } = useFormContext<MasterProgramRule>()

  const {
    field: nameField,
    formState: { errors },
  } = useController({
    name: 'name',
    control,
    defaultValue: name,
  })
  const { field: clientProgramNameField } = useController({
    name: 'clientProgramName',
    control,
    defaultValue: clientProgramName,
  })
  const {
    field: { ref: _ref, ...inventoryTypeField },
  } = useController({
    name: 'inventoryType',
    control,
    defaultValue: inventoryType,
  })
  const { field: isActiveField } = useController({
    name: 'isActive',
    control,
    defaultValue: isActive,
  })
  const { field: parentProgramNameField } = useController({
    name: 'parentProgramName',
    control,
    defaultValue: parentProgramName,
  })

  return (
    <React.Fragment>
      <FormField>
        <FormField.Label>Name</FormField.Label>
        {editable ? (
          <Input {...nameField} data-test-id="master-program__name" />
        ) : (
          <Value>{name}</Value>
        )}
        <FormField.Error>{errors.name?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Client program name</FormField.Label>
        {editable ? (
          <Input
            {...clientProgramNameField}
            value={clientProgramNameField?.value ?? ''}
            data-test-id="master-program__client-name"
          />
        ) : (
          <Value>{clientProgramName}</Value>
        )}
        <FormField.Error>{errors.clientProgramName?.message}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Inventory type</FormField.Label>
        {editable ? (
          <Select
            simpleValue
            valueKey="id"
            labelKey="value"
            options={inventoryTypeOptions}
            {...inventoryTypeField}
            data-test-id="master-program__inventory-type"
          />
        ) : (
          <Value>{inventoryType}</Value>
        )}
        <FormField.Error />
      </FormField>
      <FormField>
        <FormField.Label>Is active</FormField.Label>
        <Flex mb="M">
          <Checkbox
            {...isActiveField}
            checked={isActiveField.value}
            disabled={!editable}
            data-test-id="master-program__is-active"
          />
        </Flex>
      </FormField>
      <FormField>
        <FormField.Label>Parent program</FormField.Label>
        {editable ? (
          <Input {...parentProgramNameField} data-test-id="master-program__parent-name" />
        ) : (
          <Value>{parentProgramName}</Value>
        )}
        <FormField.Error>{errors.parentProgramName?.message}</FormField.Error>
      </FormField>
    </React.Fragment>
  )
}
