// @flow

import { createErrorHandler } from '../errorHandler'
import type { Options } from '../errorHandler'

const baseURL = '/uisvc/tcr/api/v1/financial-events-grid'

export const createApi = (httpClient: Object, { onError, onNotify }: Options): any => {
  const { post } = httpClient
  const errorHandler = createErrorHandler({ onError, onNotify })

  return {
    getFinancialEvents: obj =>
      post(`${baseURL}/financial-events/query`, obj)
        .then(({ data }) => data)
        .catch(errorHandler),
  }
}
