// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import { useNavigate, generatePath } from 'react-router-dom'

import { H3 as UIKitH3, ImageCarousel, Container, Row, Col, PageNavigation } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import type { LoadedProductCardProps } from '../../../types/public/ProductCardController'
import {
  ProductGeneralInfo,
  ProductDimensions,
  ProductIdentifiers,
  ProductAttributes,
  UnitAttributes,
  ProductPrices,
  MarketplaceMappings,
} from '../../../components/ProductCard'

import { ROUTES } from '../../../../../navigation'

import { ScrappedData } from '../../../containers/ProductContainer'

const CarouselWrapper = styled('div')`
  ${({ theme }) => `
    padding: ${theme.space.L};
  `}
`

const LinkContainer = styled('div')`
  position: fixed;
`

const H1 = styled('h1')`
  color: ${({ theme }) => theme.palette.grey[900]};
`

const H3 = styled(UIKitH3)`
  ${({ theme }) => css`
    margin-top: ${theme.space.XXXL};
    margin-bottom: ${theme.space.XL};
    color: ${theme.palette.grey[800]};
  `}
`

const getLinks = permissions => {
  const links = [
    { to: 'overview', name: 'Overview', offset: -150 },
    { to: 'general', name: 'General Information', offset: -150 },
    { to: 'dimension', name: 'Dimensions', offset: -150 },
    { to: 'attributes', name: 'Attributes', offset: -150 },
    { to: 'identifiers', name: 'Product Identifiers', offset: -150 },
    { to: 'prices', name: 'Prices', offset: -150 },
    { to: 'categoryMapping', name: 'Category Mapping', offset: -150 },
  ]
  if (permissions.allowProductSourceDataView)
    links.push({ to: 'externalData', name: 'External Data', offset: -150 })
  return links
}

const ProductId = styled('div')`
  font-size: 14px;

  ${({ theme }) => `
    color: ${theme.palette.grey[700]};
  `}
`

export const ProductCard = (props: LoadedProductCardProps) => {
  const {
    generalInfo,
    dimensionsInfo,
    identifiersInfo,
    attributesInfo,
    imagesInfo,
    companyPrices,
    aggregatedPrices,
    marketplaceMappingsInfo,
  } = props
  const navigate = useNavigate()

  const { productImages, primaryProductImage } = imagesInfo
  const [{ allowProductSourceDataView }] = useAccessControl()
  function handleUnitAttributeClick(attributeId) {
    navigate(generatePath(ROUTES.ATTRIBUTE_DEFINITION_DETAILS, { attributeId }))
  }

  function getPrimaryInfo() {
    const { productIdentifiers, primaryProductIdentifier } = identifiersInfo
    const primary = productIdentifiers.find(({ value }) => value === primaryProductIdentifier)

    return primary ? `(${primary.name}) - ${primary.value}` : ''
  }

  return (
    <Container>
      <Row>
        <Col md={10}>
          <PageNavigation.Element id="overview">
            <div style={{ display: 'flex' }}>
              <CarouselWrapper>
                <ImageCarousel zoomable images={productImages} defaultImage={primaryProductImage} />
              </CarouselWrapper>
              <div>
                <ProductId>Primary Identifier {getPrimaryInfo()}</ProductId>
                <H1>{generalInfo.productName}</H1>
              </div>
            </div>
          </PageNavigation.Element>
          <PageNavigation.Element id="general">
            <H3>General Information</H3>
            <ProductGeneralInfo
              primaryProductIdentifier={{
                name: '',
                link: '',
              }}
              {...generalInfo}
              renderedFields={[
                'productName',
                'categoryPath',
                'brand',
                'model',
                'manufacturer',
                'country',
                'taxCode',
                'retailPrice',
                'shortDescription',
                'longDescription',
                'supportingUrl',
                'partNumber',
              ]}
            />
          </PageNavigation.Element>
          <PageNavigation.Element id="dimension">
            <H3>Dimensions</H3>
            <ProductDimensions {...dimensionsInfo} />
          </PageNavigation.Element>
          <PageNavigation.Element id="attributes">
            <H3>Attributes</H3>
            <ProductAttributes productAttributes={attributesInfo.productAttributes} />
            <UnitAttributes
              unitAttributes={attributesInfo.unitAttributes}
              onClickUnit={handleUnitAttributeClick}
            />
          </PageNavigation.Element>
          <PageNavigation.Element id="identifiers">
            <H3>Product Identifiers</H3>
            <ProductIdentifiers {...identifiersInfo} />
          </PageNavigation.Element>
          <PageNavigation.Element id="prices">
            <H3>Prices</H3>
            <ProductPrices prices={companyPrices} header="COMPANY PRICES" />
            <ProductPrices prices={aggregatedPrices} header="AGGREGATED PRICES" />
          </PageNavigation.Element>
          <PageNavigation.Element id="categoryMapping">
            <H3>Category Mapping</H3>
            <MarketplaceMappings mapping={marketplaceMappingsInfo} />
          </PageNavigation.Element>
          {allowProductSourceDataView && (
            <PageNavigation.Element id="externalData">
              <ScrappedData
                identifiers={identifiersInfo.productIdentifiers.map(({ value }) => value)}
              />
            </PageNavigation.Element>
          )}
        </Col>
        <Col md={2}>
          <LinkContainer>
            <PageNavigation links={getLinks({ allowProductSourceDataView })} />
          </LinkContainer>
        </Col>
      </Row>
    </Container>
  )
}
