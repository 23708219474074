// @flow

import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from '@emotion/styled'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'
import { VariationEditScreen } from '../../containers/VariationContainer'

import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'

const EmptySpace = styled('div')`
  height: 200px;
`

export const VariationEdit = () => {
  const { variationId } = useParams()
  const navigate = useNavigate()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowFamilyEdit]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.VARIATIONS}>Variations</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <VariationEditScreen
            variationId={variationId || ''}
            httpClient={httpClient}
            renderTitle={ContentHeader.Title}
            renderActionsButtons={ContentHeader.ActionButtons}
            onSuccessfulSubmit={() => navigate(ROUTES.VARIATIONS)}
            onCancel={() => navigate(ROUTES.VARIATIONS)}
          />
        </Content>
        <EmptySpace />
      </Main>
    </PageAccessControl>
  )
}
