/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { handleServerError } from '@r1/core-blocks'
import {
  baseUri,
  createTemplateHistoryApi,
  createDictionariesApi,
  createProductsTemplatesApi,
  createTemplateAdditionalSettingsApi,
  createProductTemplateConsigneeUpcApi,
  createLockApi,
  createStorageApi,
  createProductTemplateManifestPriceApi,
} from '@r1-webui/producttemplatemanagement-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/legacy-browserui/${baseUri}`

export const productTemplateApi = createProductsTemplatesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateAdditionalSettingsApi = createTemplateAdditionalSettingsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateConsigneeUpcApi = createProductTemplateConsigneeUpcApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateDictionariesApi = createDictionariesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateHistoryApi = createTemplateHistoryApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const getProductTemplateHistory = async (productTemplateId: string) => {
  const res = await productTemplateHistoryApi.getHistory({ id: Number(productTemplateId) })

  if (res.status !== 200) {
    handleServerError(res.body)
    return []
  }
  return res.body
}

export const productTemplateLockApi = createLockApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateStorageApi = createStorageApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const productTemplateManifestPriceApi = createProductTemplateManifestPriceApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
