// @flow

import type { Lib$Money } from '@r1/types/flow/libTypes'
import type { ApiData, InternalData } from '../types/common'

import { getClaimType } from '../utils'

// `...Money` functions use common practice to work with money as integers
// which is prevent the accumulation of errors.

// IMPORTANT(telichkin): `sumMoney` works incorrectly with different currencies.
const sumMoney = (m1: Lib$Money, m2: Lib$Money): Lib$Money => {
  return {
    amount: ((Number(m1.amount) * 100 + Number(m2.amount) * 100) / 100).toFixed(2),
    currency: m1.currency,
  }
}

const mulMoney = (m1: Lib$Money, n: number): Lib$Money => {
  return {
    amount: ((Number(m1.amount) * 100 * n) / 100).toFixed(2),
    currency: m1.currency,
  }
}

export const toInternalData = (data: ApiData): InternalData => {
  const {
    claim,
    order,
    rmaProfile,
    returnReasons,
    shippingAddress,
    expectedDecisions,
    availableDecisions,
    claimHistory,
    facilities,
  } = data

  const getStatus = () => {
    if (claim.state === 'Created') return 'Created'
    if (claim.state === 'DecisionExecuting') return 'In progress'
    if (claim.state === 'DecisionExecuted') return 'In progress'
    if (claim.state === 'Closed') return 'Closed'
    if (claim.state === 'Canceled') return 'Canceled'
    return 'Error'
  }

  const getRmaFacilitySourceType = () => {
    if (claim.rmaFacilitySourceType === 'Order') return 'Order'
    if (claim.rmaFacilitySourceType === 'RmaProfile') return 'RmaProfile'
    return ''
  }

  const getRefundReviseState = () => {
    if (claim.refundReviseState === 'Pending') return 'Will be revised'
    if (claim.refundReviseState === 'Performed') return 'Revised'
    return ''
  }

  const getSourceType = () => {
    if (claim.sourceType === 'Marketplace') return 'Marketplace'
    if (claim.sourceType === 'CustomerPortal') return 'Customer Portal'
    if (claim.sourceType === 'VendorPortal') return 'Vendor Portal'
    return 'Unknown'
  }

  const products = []
  const claimExpectedDecision = claim.expectedDecisionTypeId ?? ''
  const claimType = getClaimType(claimExpectedDecision)
  let claimTotal = { amount: '0', currency: 'USD' }

  // eslint-disable-next-line consistent-return
  claim.items.forEach(item => {
    const line = order.lines.find(({ lineId }) => item.orderLineId === lineId)
    if (!line) {
      return null
    }

    let itemQuantity = 0

    switch (claimType) {
      case 'RefundAfterReturn':
        {
          itemQuantity = (item && item.quantity) || 0
          const itemPrice = mulMoney(line.lineInfo.singleItemPrice.total, itemQuantity)
          claimTotal = sumMoney(claimTotal, itemPrice)
        }
        break

      case 'RefundOnly':
        // Item quantity is zero.
        item.refundAmounts.forEach(refundAmount => {
          claimTotal = sumMoney(claimTotal, refundAmount.amount)
        })
        break

      case 'ReturnOnly':
        itemQuantity = (item && item.quantity) || 0
        // Claim total is zero.
        break

      case 'ReplacementOnly':
        // Do nothing
        // Item quantity is zero.
        // Claim total is zero.
        break

      case 'ReplacementAfterReturn':
        itemQuantity = (item && item.quantity) || 0
        // Claim total is zero.
        break

      default:
        // Do nothing
        // Item quantity is zero.
        // Claim total is zero.
        break
    }
    const returnReason = returnReasons.find(e => e.id === item.returnReasonId)

    products.push({
      id: (item && item.id) || '',
      title: line.productInfo.title || '',
      model: line.productInfo.modelName || '',
      productImageUrl: line.productInfo.imageUrls[0],
      quantity: itemQuantity,
      price: line.lineInfo.singleItemPrice.price,
      tax: line.lineInfo.singleItemPrice.tax,
      shippingPrice: line.lineInfo.singleItemPrice.shippingPrice,
      shippingTax: line.lineInfo.singleItemPrice.shippingTax,
      discount: line.lineInfo.singleItemPrice.discount,
      singleItemTotal: line.lineInfo.singleItemPrice.total,
      total: mulMoney(line.lineInfo.singleItemPrice.total, itemQuantity),
      returnReason: (returnReason && returnReason.title) || '',
      customerNote: (item && item.customerNote) || '',
      customerImages: (item && item.photos) || [],
      units: item.unitInfos || [],
      replacementUnits: item.replacementUnitInfos || [],
    })
  })

  const decisionItems = claim.decisionItems.map(d => {
    return {
      id: d.id,
      name: d.name,
      status: d.status,
      processingPolicy: d.processingPolicy,
      managerActionResult: d.managerActionResult,
    }
  })

  const expectedDecision = expectedDecisions.find(e => e.id === claim.expectedDecisionTypeId)
  const requestedDecision = (expectedDecision && expectedDecision.title) || ''

  const decision = availableDecisions.find(item => item.action === 'FullRefund') || null

  const address = [
    shippingAddress?.addressLine1 ?? '',
    shippingAddress?.addressLine2 ?? '',
    shippingAddress?.addressLine3 ?? '',
    shippingAddress?.cityName ?? '',
    shippingAddress?.subdivisionCode ?? '',
    shippingAddress?.postalCode ?? '',
    shippingAddress?.countryCode ?? '',
  ]

  return {
    rmaProfileId: claim.rmaProfileId,
    products,
    decision,
    claim: {
      id: claim.id,
      rmaProfileId: claim.rmaProfileId,
      status: getStatus(),
      source: getSourceType(),
      claimType,
      expectedDecisionTypeId: claim.expectedDecisionTypeId,
      requestedDecision,
      createdOn: claim.createdOn,
      description: claim.description || '',
      totalAmount: claimTotal,
      decisionItems,
      claimItems: claim.items,
      messages: claim.messages,
      refundReviseState: getRefundReviseState(),
      refundReviseOn: claim.refundReviseOn,
      customRmaFacilityId: claim.customRmaFacilityId,
      rmaFacilitySourceType: getRmaFacilitySourceType(),
      rmaFacilityId: claim.rmaFacilityId,
      createdByUserFullName: claim.createdByUserFullName,
      externalIssueInfo: claim.externalIssueInfo,
      feeAmount: claim.feeAmount,
    },
    marketplace: {
      name: rmaProfile?.sellerName ?? '',
      profile: rmaProfile?.venueTypeInfo.name ?? '',
      svgId: rmaProfile?.venueTypeInfo.svgId ?? '',
      isChatSupported: rmaProfile?.isUserChatSupported ?? false,
    },
    order: {
      rmaProfileId: order.rmaProfileId,
      purchaseOrderId: order.purchaseOrderId,
      createdOn: order.createdOn,
      totalAmount: order.orderTotal,
      marketplaceOrderId: order.externalId,
      legacyOrderId: order.legacyInfo.length ? order.legacyInfo[0].orderId : undefined,
      legacyCompanyOrderId: order.legacyInfo.length
        ? order.legacyInfo[0].companyOrderId
        : undefined,
      shippingAddressId: order.shippingInfo.addressId,
      orderLines: order.lines,
    },
    customer: {
      shippingAddress: address.filter(Boolean).join(', '),
      fullName: order.customer.fullName || '',
      phone: order.shippingInfo.phone || '',
      email: claim.customerContactEmail || '',
    },
    claimHistory,
    facilities,
    expectedDecisionTypes: expectedDecisions,
    returnReasons,
  }
}
