import React from 'react'
import { Main, ContentHeader, Content } from '@r1/wireframe-primary'
import { PERMISSIONS, PageAccessControl, handleServerError } from '@r1/core-blocks'
import { ReportGroup } from '@r1-webui/reports-v1'
import { ReportGroupsPage, ReportsLoader } from '../components'
import { reportGroupsApi } from '../reports.api'

export function ReportGroupsScreen() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [reportGroups, setReportGroups] = React.useState<ReportGroup[]>([])

  React.useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setIsLoading(true)

      const groupsResponse = await reportGroupsApi.getReportGroups()

      if (groupsResponse.status === 200) {
        setReportGroups(groupsResponse.body)
      } else {
        handleServerError(groupsResponse)
      }

      setIsLoading(false)
    }

    fetchData()
  }, [])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowReportDashboardsView]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Title>Reports</ContentHeader.Title>
        </ContentHeader>
        <Content>
          {isLoading ? <ReportsLoader /> : <ReportGroupsPage reportGroups={reportGroups} />}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
