// @flow

import * as React from 'react'
import { Box, Button, Flex, FormField, Textarea } from '@r1/ui-kit'
import { useCallback, useMemo, useState } from 'react'

type Props = {|
  onSaveMessage: (text: string) => Promise<void>,
  onCancel: () => void | Promise<void>,
  initText?: string,
|}

export const MessageForm = (props: Props) => {
  const { onSaveMessage, initText, onCancel } = props
  const [text, setText] = useState(initText || '')
  const [actionExecuting, setActionExecuting] = useState(false)

  const error = useMemo(() => !text || text.length === 0, [text])

  const onSaveClick = useCallback(async () => {
    setActionExecuting(true)
    await onSaveMessage(text)
    setActionExecuting(false)
  }, [onSaveMessage, text])

  return (
    <Flex column spaceBetween="M" mb="M" mt="M">
      <FormField>
        <Textarea
          data-test-id="message"
          error={error}
          rows={2}
          placeholder="Enter message here..."
          value={text}
          onChange={setText}
        />
        <FormField.Error>{error ? 'This cannot be empty' : ''}</FormField.Error>
      </FormField>
      <Box alignSelf="flex-end">
        <Flex spaceBetween="M">
          {!actionExecuting && (
            <Button color="secondary" onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button disabled={error} loading={actionExecuting} onClick={onSaveClick}>
            Save
          </Button>
        </Flex>
      </Box>
    </Flex>
  )
}
