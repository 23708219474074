// @flow

import * as React from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Button, Placeholder, Flex, Link } from '@r1/ui-kit'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { ProductCardController } from '../../../components/ProductCard'
import { ProductContainer } from '../../../containers/ProductContainer'

import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'
import { ProductCard } from './ProductCard'

export const ProductView = () => {
  const { productId } = useParams()
  const navigate = useNavigate()

  return productId ? (
    <PageAccessControl permissions={[PERMISSIONS.allowProductView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.PRODUCTS}>Products</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <ContentHeader.ActionButtons>
            <Flex spaceBetween="M">
              <Button color="secondary" onClick={() => navigate(ROUTES.PRODUCTS)}>
                Close
              </Button>
            </Flex>

            {/* <HistoryOpenBtn /> */}
          </ContentHeader.ActionButtons>
          <ProductCardController productId={productId} httpClient={httpClient}>
            {productCardProps => {
              if (productCardProps.screenType === 'default') {
                return (
                  <React.Fragment>
                    <ContentHeader.Title enableBetaLabel>
                      {`Product Details / ${productCardProps.identifiersInfo.primaryProductIdentifier}`}
                    </ContentHeader.Title>
                    <ProductContainer httpClient={httpClient}>
                      <ProductCard {...productCardProps} />
                    </ProductContainer>
                  </React.Fragment>
                )
              } else if (productCardProps.screenType === 'forbidden') {
                return 'Product Not Found'
              } else if (productCardProps.screenType === 'error') {
                return <Navigate to="/not-found" />
              }
              return <Placeholder type="form" />
            }}
          </ProductCardController>
        </Content>
      </Main>
    </PageAccessControl>
  ) : null
}
