export const mapToLazyEnum = (
  values: string[],
): Promise<
  Array<{
    id: string
    name: string
  }>
> => {
  return new Promise(resolve => {
    resolve(
      values.map(value => ({
        id: value,
        name: value,
      })),
    )
  })
}
