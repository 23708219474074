import * as React from 'react'
import { Flex, Box, Dropdown, Icon } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'

const MENU_ITEMS = [
  { id: 'Active', title: 'Active' },
  { id: 'Inactive', title: 'Inactive' },
]

export const DrawerLabel = ({
  active,
  onToggleStatus,
}: {
  active: boolean
  onToggleStatus: (isActive: boolean) => void
}) => {
  return (
    <Flex align="center" spaceBetween="XS">
      <Box mr="S">RMA Shipping Rule</Box>
      <Dropdown.Select
        selectedItemId={active ? 'Active' : 'Inactive'}
        items={MENU_ITEMS}
        zIndex={100}
        onSelectItem={id => onToggleStatus(id === 'Active')}
      >
        <Flex align="center" spaceBetween="XS">
          <Box>
            {active ? (
              <GenericIcon svgId="griddemostatus-active" />
            ) : (
              <GenericIcon svgId="griddemostatus-inactive" />
            )}
          </Box>
          <Box>{active ? 'Active' : 'Inactive'}</Box>
          <Box>
            <Icon type="chevron/down" />
          </Box>
        </Flex>
      </Dropdown.Select>
    </Flex>
  )
}
