import { BlockType, ConditionType, RepeaterDirection, ValueType } from '../constants'

const DEFAULT_POSITION = { x: 0, y: 0 }

let nextBlockId = 0
let nextValueId = 0

export const getNextBlockId = () => {
  nextBlockId += 1
  return nextBlockId
}
export const getNextValueId = () => {
  nextValueId += 1
  return nextValueId
}

export const createConstantValue = value => ({
  type: ValueType.CONSTANT,
  id: getNextValueId(),
  value,
})

export const createPropertyValue = fullName => ({
  type: ValueType.PROPERTY,
  id: getNextValueId(),
  fullName,
  required: true,
})

export const getDefaultBlockValue = type => {
  switch (type) {
    case ValueType.CONSTANT:
      return {
        value: '',
      }

    case ValueType.PROPERTY:
      return {
        fullName: '',
        required: true,
      }

    case ValueType.CONDITIONAL:
      return {
        condition: {
          type: ConditionType.NOT_EMPTY,
          parameter: '',
        },
        trueValues: [createConstantValue('')],
      }
    default:
      return null
  }
}

export const createTextBlock = () => {
  return {
    type: BlockType.TEXT_BLOCK,
    id: getNextBlockId(),
    position: DEFAULT_POSITION,
    rotation: 0,
    size: { width: 1, height: 0.5 },
    fontSize: 12,
    values: [createConstantValue('Text')],
  }
}

export const createBarcodeBlock = () => {
  return {
    type: BlockType.BARCODE_BLOCK,
    id: getNextBlockId(),
    position: DEFAULT_POSITION,
    rotation: 0,
    size: { width: 1, height: 0.3 },
    format: 'Code128Auto',
    values: [createConstantValue('Barcode')],
  }
}

export const createQrCodeBlock = () => {
  return {
    type: BlockType.QR_CODE_BLOCK,
    id: getNextBlockId(),
    position: DEFAULT_POSITION,
    rotation: 0,
    size: { width: 1, height: 1 },
    values: [createConstantValue('QrCode')],
  }
}

export const createRectangleBlock = () => {
  return {
    type: BlockType.RECTANGLE_BLOCK,
    id: getNextBlockId(),
    position: DEFAULT_POSITION,
    rotation: 0,
    size: { width: 1.5, height: 1 },
    lineThickness: 0.05,
  }
}

export const createListBlock = () => {
  return {
    type: BlockType.REPEATER_BLOCK,
    id: getNextBlockId(),
    position: DEFAULT_POSITION,
    rotation: 0,
    size: { width: 1, height: 2 },
    direction: RepeaterDirection.VERTICAL,
    name: 'List',
    source: '',
    spaceBetween: 0.1,
    fontSize: 12,
    item: {
      height: 0.5,
      overflowText: '...',
    },
    overflow: {
      height: 0.1,
      text: '...',
    },
    values: [createPropertyValue('List.Item')],
  }
}
