import React, { useCallback } from 'react'

import { HistoryModal } from '@r1/core-blocks'

import { httpClient } from '../../../../../../../httpClient'
import * as roleHistoryService from '../../services/roleHistoryService'

import { RoleId } from '../../types'

type Props = {
  open: boolean
  onClose: () => void
  roleId?: RoleId
  roleName: string
}

export const RoleHistory: React.FC<Props> = ({ open, onClose, roleId = '', roleName }) => {
  const getHistoryReceivingToken = useCallback(async () => {
    if (roleId !== '') {
      return roleHistoryService.getRoleHistoryReceivingToken(roleId)
    }
    return ''
  }, [roleId])

  if (!open) {
    return null
  }

  return (
    <HistoryModal
      isControlled
      title={`Role ${roleName} History`}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      httpClient={httpClient}
      getHistoryReceivingToken={getHistoryReceivingToken}
      show={open}
      onClose={onClose}
    />
  )
}
