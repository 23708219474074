// @flow

import type { UpdateCategoryResponse } from '@r1-webui/productcatalog-categorymanagement-v1/src/categoryManagement'
import { replaceTemplateValues, showNotification } from '../../../../../utils'

export function handleServiceErrors(response: UpdateCategoryResponse): UpdateCategoryResponse {
  switch (response.status) {
    case 404:
      showNotification({ title: 'Category not found', level: 'error' })
      break
    case 409:
    case 500:
      showNotification({ title: replaceTemplateValues(response.body), level: 'error' })
      break
    case 422:
      if (response.body.type === 'AttributeBindingUpdateSubsystemError')
        showNotification({
          title: replaceTemplateValues(response.body.subsystemName),
          message: replaceTemplateValues(response.body.error),
          level: 'error',
        })
      else showNotification({ title: replaceTemplateValues(response.body.error), level: 'error' })
      break
    case 400:
      showNotification({
        title: 'Bad Request',
        message: replaceTemplateValues(response.body.badProperties[0]),
        level: 'error',
      })
      break
    default:
      break
  }
  return response
}
