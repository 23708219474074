// @flow

import * as React from 'react'
import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { Title } from './Title'

const Container = styled('section')`
  ${({ theme }) => css`
    margin-bottom: ${theme.space.L};

    & > *:first-child {
      margin-bottom: ${theme.space.XS};
    }
  `}
`

type Props = {
  title: React.Node | ((typeof Title) => React.Node),
  titleIcon?: ?React.Node,
  children: React.Node,
}

export function Section({ title, titleIcon, children, ...rest }: Props) {
  return (
    <Container {...rest}>
      {typeof title === 'function' ? title(Title) : <Title icon={titleIcon}>{title}</Title>}

      {children}
    </Container>
  )
}
