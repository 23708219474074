import React, { useCallback, useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { Table, DisplaySettings } from '@r1/grid'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Flex, Icon, Format, Modal, Link } from '@r1/ui-kit'
import { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { discountsRulesService, marketplaceProfileService } from '../services'
import { DiscountRule } from '../types/DiscountRule'
import { ProfileState } from '../types/Marketplace'
import { ROUTES } from '../../../navigation'

const AttributesCounter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.space.XXS};
`

type getDisplaySettingsFunction = (
  marketplaceProfiles: ProfileState,
  handleDeleteRule: (id: string, version: string) => () => void,
) => DisplaySettings<DiscountRule>

const getDisplaySettings: getDisplaySettingsFunction = (marketplaceProfiles, handleDeleteRule) => ({
  columnsSettings: [
    {
      header: { content: 'Rule Name' },
      cell: {
        $type: 'custom',
        renderer: ({ name, id }) => <Link href={`${ROUTES.DISCOUNTS}/${id}`}>{name}</Link>,
      },
    },
    {
      header: { content: 'Marketplace' },
      cell: {
        $type: 'custom',
        renderer: ({ marketplaces }) =>
          marketplaces.map(marketplace => marketplaceProfiles[marketplace]).join(', '),
      },
    },
    {
      header: { content: 'Updated on' },
      cell: {
        $type: 'custom',
        renderer: ({ updatedOn }) => <Format.DateTime>{updatedOn}</Format.DateTime>,
      },
    },
    {
      header: { content: 'Action' },
      cell: {
        $type: 'custom',
        renderer: val => {
          const modalActionButtons: UncontrolledActionButtons = ({ onClose }) => {
            return [
              {
                title: 'Cancel',
                color: 'secondary',
                onClick: onClose,
                align: 'right',
              },
              {
                title: 'Delete',
                transparent: false,
                color: 'danger',
                onClick: () => {
                  handleDeleteRule(val.id, val.version)
                  onClose()
                },
                align: 'right',
              },
            ]
          }

          return (
            <Modal
              title="Warning"
              isControlled={false}
              actionButtons={modalActionButtons}
              buttonElement={({ onOpen }) => <Icon type="trash" onClick={onOpen} />}
            >
              Are you sure you want to delete rule?
            </Modal>
          )
        },
      },
    },
  ],
})

export const ViewDiscountsGrid = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(false)
  const [discountRules, setDiscountRules] = useState<DiscountRule[]>([])
  const [marketplaceProfiles, setMarketplaceProfiles] = useState<ProfileState>({})

  const fetchData = useCallback(() => {
    discountsRulesService.getDiscountsRules().then(setDiscountRules)
    marketplaceProfileService
      .fetchMarketplaceProfiles()
      .then(profiles =>
        setMarketplaceProfiles(
          profiles.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {}),
        ),
      )
  }, [])
  useEffect(() => fetchData(), [fetchData])

  const handleRowsOrderChange = useCallback(
    async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const { id: ruleId, version: ruleVersion } = discountRules[oldIndex]
      setLoading(true)
      try {
        const newRules = await discountsRulesService.changeRulePriority({
          ruleId,
          ruleVersion,
          priority: newIndex,
        })
        setDiscountRules(newRules)
      } finally {
        setLoading(false)
      }
    },
    [discountRules],
  )

  const handleDeleteRule = useCallback(
    (deletedId: string, version: string) => async () => {
      setLoading(true)
      try {
        await discountsRulesService.deleteDiscountRule(deletedId, version)
        setDiscountRules(discountRules.filter(({ id }) => id !== deletedId))
      } finally {
        setLoading(false)
      }
    },
    [discountRules],
  )

  const redirectToRuleCreatePage = useCallback(() => navigate(ROUTES.DISCOUNT_CREATE), [navigate])

  const displaySettings = useMemo(
    () => getDisplaySettings(marketplaceProfiles, handleDeleteRule),
    [marketplaceProfiles, handleDeleteRule],
  )

  return (
    <Main fullScreen>
      <ContentHeader />
      <Content>
        <ContentHeader.Title>Volume Discount Rules</ContentHeader.Title>
        <Flex column spaceBetween="M" minWidth={1}>
          <Box>
            <Box grow>
              <Flex spaceBetween="XS" align="center">
                <Flex align="center">All Volume Discount Rules</Flex>
                <AttributesCounter>{discountRules.length}</AttributesCounter>
              </Flex>
            </Box>
            <Box alignSelf="flex-end">
              <Button onClick={redirectToRuleCreatePage}>
                <Icon type="plus" color="inverted" />
                Add new
              </Button>
            </Box>
          </Box>
          <Table
            reorderableRows
            suppressVirtualization
            loading={loading}
            data={discountRules}
            displaySettings={displaySettings}
            onRowsOrderChange={handleRowsOrderChange}
          />
        </Flex>
      </Content>
    </Main>
  )
}
