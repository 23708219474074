/* eslint-disable no-nested-ternary */
import * as React from 'react'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { formValueSelector } from 'redux-form'
import { withTheme, Flex, Text } from '@r1/ui-kit'
import { CategoryTextPath } from '../../../components/categories/TextPath'
import { CardHeader } from '../../../components/CardHeader'
import { stripHtml } from '../utils'
import { historyApi } from '../../../api/productHistory/history.ts'
import { PriceBlock } from './PriceBlock'

const selector = formValueSelector('mainInfoForm')

const mapStateToProps = ({ mainInfo, images, pricing, ...state }) => ({
  productData: mainInfo.model,
  priceBlock: pricing.priceBlock,
  categoryId: selector(state, 'productInfo.categoryId'),
  title: selector(state, 'productInfo.title'),
  shortDescription: selector(state, 'productInfo.shortDescription'),
  images: images.images,
})

const enhance = compose(
  withTheme,
  connect(mapStateToProps),
  mapProps(({ productData, priceBlock, shortDescription, images, ...props }) => {
    const { identifier = {} } = productData || {}
    const { listing = [], reference = [] } = images || {}
    let imageSrc = ''
    if (listing.length > 0) {
      imageSrc = listing[0].url
    } else if (reference.length > 0) {
      imageSrc = reference[0].url
    }

    return {
      shortDescription: shortDescription && stripHtml(shortDescription),
      productTemplateNumber: identifier.value,
      imageSrc,
      scrappedPrices: priceBlock ? priceBlock.catalog : [],
      ...props,
    }
  }),
)

const ProductCardInner = ({
  theme,
  title,
  shortDescription,
  productTemplateNumber,
  categoryId,
  imageSrc,
  scrappedPrices,
  id,
}) => {
  const fetchData = React.useCallback(async () => {
    const data = await historyApi.getProductTemplateHistory(id)
    return data
  }, [id])

  return (
    <CardHeader
      title={title}
      topContent={
        <Text color={theme.palette.grey[500]}>Product template #{productTemplateNumber}</Text>
      }
      imageUrl={imageSrc}
      historyDialogTitle="Product template history"
      dataTestId="modal-template-history"
      fetchData={fetchData}
    >
      <Text type="paragraph">{shortDescription}</Text>
      <Flex flexWrap spaceBetween="S" my="S">
        {scrappedPrices.map(price => (
          <PriceBlock key={price.rule.name} name={price.rule.name} price={price.value} />
        ))}
      </Flex>
      <CategoryTextPath categoryId={categoryId} />
    </CardHeader>
  )
}

export const ProductCard = enhance(ProductCardInner)
