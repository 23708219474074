// @flow

import * as React from 'react'
import { Button, Flex, Format, Icon, Text, Tooltip } from '@r1/ui-kit'
import type { Attachment } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import { RegularMessageContainer, MessageDate, MessageText } from '../StyledComponents'
import { LinkifiedText } from '../../LinkifiedText'
import type { CustomerInfo } from '../../../types/common'

type Props = {
  isOwn: boolean,
  unread: boolean,
  sender: string,
  recipient: string,
  customer: CustomerInfo,
  text: string,
  sentOn: string,
  marketplaceId?: string,
  markAsRead: () => void | Promise<void>,
  getAttachment: (attachmentId: ?string) => Promise<?Attachment>,
  attachments: Attachment[],
}

const RegularMessage = (props: Props) => {
  const {
    isOwn,
    unread,
    sender,
    recipient,
    customer,
    text,
    sentOn,
    marketplaceId,
    markAsRead,
    getAttachment,
    attachments,
  } = props

  const [downloadingMap, setDownloadingMap] = React.useState({})

  const senderName = React.useMemo(() => {
    switch (sender) {
      case 'Admin':
        return 'Marketplace'
      case 'Customer':
        return customer?.fullName
      default:
        return sender
    }
  }, [sender, customer])

  const setDownloading = (attachmentId, isDownloading) => {
    setDownloadingMap(prevLoadingMap => {
      const newLoadingMap = { ...prevLoadingMap }
      newLoadingMap[attachmentId] = isDownloading
      return newLoadingMap
    })
  }

  const downloadAttachment = React.useCallback(
    attachmentId => () => {
      if (!attachmentId) {
        return
      }
      setDownloading(attachmentId, true)
      getAttachment(attachmentId)
        .then(attachment => {
          if (attachment) {
            window.open(attachment.url, '__blank')
          }
        })
        .finally(() => {
          setDownloading(attachmentId, false)
        })
    },
    [getAttachment],
  )

  const isAttachmentDownloading = React.useCallback(
    attachmentId => (attachmentId ? downloadingMap[attachmentId] : false),
    [downloadingMap],
  )

  const isUnread = React.useMemo(() => !isOwn && unread, [isOwn, unread])

  return (
    <RegularMessageContainer sender={sender}>
      <Flex column align={isOwn ? 'flex-end' : 'flex-start'} spaceBetween="S">
        <Flex column>
          <Flex spaceBetween="S" align="center" mb={!isOwn ? 'L' : 'XXS'}>
            {!isOwn && (
              <Text weight="bold" align={isOwn ? 'left' : 'right'} type="paragraph">
                {senderName}
              </Text>
            )}
            {recipient !== 'All' && (
              <Text weight="light" align={isOwn ? 'left' : 'right'} type="data">
                {`(to: ${recipient})`}
              </Text>
            )}
            {isUnread && <Icon type="internal/notification/info" color="#5390FD" size="XS" />}
          </Flex>
          <MessageText>
            <LinkifiedText text={text} />
          </MessageText>
          {attachments.length > 0 && (
            <Flex align="flex-start" mt="L" spaceBetween="XS">
              {attachments.map((attachment, i) => (
                <Button
                  key={attachment.id}
                  color="light"
                  loading={isAttachmentDownloading(attachment.externalId)}
                  onClick={downloadAttachment(attachment.externalId)}
                >{`Attachment ${i + 1}`}</Button>
              ))}
            </Flex>
          )}
        </Flex>
        <Flex spaceBetween="M" align="center">
          {marketplaceId ? (
            <MessageDate>
              <Format.DateTime>{sentOn}</Format.DateTime>
            </MessageDate>
          ) : (
            <Text type="caption-medium">Sending...</Text>
          )}
          {isUnread && (
            <Tooltip text="Mark as read">
              <Button transparent color="secondary" shape="circle" size="S" onClick={markAsRead}>
                <Icon type="checkmark" size="XS" />
              </Button>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    </RegularMessageContainer>
  )
}

export default RegularMessage
