import { useEffect, useState } from 'react'

import { getLegacyPermissions } from './getLegacyPermissions'
import { PERMISSIONS, PermissionsMap } from './permissions'

export const useLegacyPermissions = () => {
  const [permissions, setPermissions] = useState<PermissionsMap>(PERMISSIONS)

  useEffect(() => {
    const newPermissions = { ...PERMISSIONS }

    const permissionsR1 = getLegacyPermissions()
    const parsedPermissionsR1 = Object.entries(permissionsR1).reduce<string[]>(
      (acc, [permission, actions]) => {
        const updatedPermission = actions.map(action => `${permission}:${action}`)
        acc.push(...updatedPermission)
        return acc
      },
      [],
    )

    parsedPermissionsR1.forEach(permission => {
      newPermissions[permission as keyof PermissionsMap] = true
    })

    setPermissions(newPermissions)

    return () => {
      setPermissions(PERMISSIONS)
    }
  }, [])

  return permissions
}
