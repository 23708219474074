// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'
import { ClaimCreationForm } from '../../components/ClaimCreationForm'
import type { BusinessClaimType } from '../../types/common'

type Props = {
  claimType: BusinessClaimType,
}

export const CreateClaim = ({ claimType }: Props) => {
  const navigate = useNavigate()

  const path = claimType === 'B2C' ? ROUTES.CLAIMS_B2C : ROUTES.CLAIMS_B2B
  const getFormProps = () => ({
    httpClient,
    onError: () => undefined,
    onFormSubmitted: (claimId: string) => {
      navigate(`${path}/${claimId}`)
    },
    onFormCanceled: () => {
      navigate(path)
    },
    claimType,
  })

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowNewRmaVendorPortalCreate]}>
      <ClaimCreationForm {...getFormProps()} />
    </PageAccessControl>
  )
}
