// @flow

const getTransform = (value: any) =>
  value.length === 0
    ? [{ categoryId: null, productId: null }]
    : value.map(({ category, product }) => ({
        id: Math.random(),
        categoryId: category.id,
        productId: product.id,
        productOption: [
          {
            id: product.id,
            name: `${product.title.value || ''} (${product.identifiers.primary.value})`,
          },
        ],
      }))

const setTransform = (value: any) =>
  value.filter(({ productId }) => productId).map(({ productId }) => ({ productId }))

const setTransformPartNumber = (value: string) => (value === '' ? { value: null } : { value })

export const schema = {
  Parts: {
    value: {
      errorValue: 'productId',
      getPath: ['parts'],
      setPath: ['parts'],
      setTransform,
      getTransform,
    },
  },
  PartNumber: {
    value: {
      getPath: ['partNumber'],
      setPath: ['partNumber'],
      setTransform: setTransformPartNumber,
    },
  },
}
