// @flow

import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { Pagination, Table } from '@r1/grid'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Flex, Input, Button, Icon, Link } from '@r1/ui-kit'
import { getQueryStringValue } from '../../utils/windowUtils'
import { ConfirmationModal } from './components'
import { tableDisplaySettingsGetter } from './tableDisplaySettings'
import * as api from './api'

const MIN_PAGE_SIZE = 10

const TableHeader = styled('div')`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const ItemsCount = styled('div')`
  background: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProductTemplatePricesAnomaliesByProduct = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(1)
  const [inputProductId, setInputProductId] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSyncConfirmed, setIsSyncConfirmed] = useState(false)
  const [productId, setProductId] = useState('')
  const [pricesCount, setPricesCount] = useState(0)

  const fetchData = useCallback(async () => {
    setIsLoading(true)

    const response = await api.getConsolidatedProductsPricesByRule({
      ruleId: getQueryStringValue('ruleId'),
      comparisonSettingId: getQueryStringValue('settingId'),
      page,
      pageSize,
    })

    if (response && response.results) setData(response.results)
    if (response && response.pricesCount) setPricesCount(response.pricesCount)

    setIsLoading(false)
  }, [page, pageSize])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    if (isSyncConfirmed) {
      const sync = async () => {
        await api.syncConsolidatedProductPriceByRule({
          ruleId: getQueryStringValue('ruleId'),
          productId,
        })

        await fetchData()
      }

      sync()
    }
  }, [fetchData, isSyncConfirmed, productId])

  const onSyncClick = (_productId: string) => {
    setProductId(_productId)
    setIsSyncConfirmed(false)
    setIsModalOpen(true)
  }

  const onChangePagination = newPagination => {
    setPage(newPagination.page)
    setPageSize(newPagination.pageSize)
  }

  const onModalConfirm = () => {
    setIsModalOpen(false)
    setIsSyncConfirmed(true)
  }

  const onModalCancel = () => {
    setIsModalOpen(false)
  }

  const onChangeProductId = _inputProductId => {
    setInputProductId(_inputProductId)
  }

  const onApplyFilterClick = async () => {
    const identifier = inputProductId.trim()
    setInputProductId(identifier)

    if (identifier === '') {
      fetchData()
      return
    }

    const ruleId = getQueryStringValue('ruleId')
    const comparisonSettingId = getQueryStringValue('settingId')

    const response = await api.searchProductsByIdentifier(
      { identifier, ruleId },
      comparisonSettingId ? { comparisonSettingId } : {},
    )

    if (response && Array.isArray(response)) {
      setData(response)
      setPricesCount(response.length)
    }
  }

  const onClearFilter = () => {
    setInputProductId('')
    fetchData()
  }

  const displaySettings = tableDisplaySettingsGetter.pricesAnomaliesByProduct(onSyncClick)

  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Price Difference</ContentHeader.Title>
        <ContentHeader.Breadcrumbs>
          <Link to="/r1/product-prices-anomalies-rule">Prices Quarantine</Link>
        </ContentHeader.Breadcrumbs>
      </ContentHeader>

      <Content>
        <Flex align="center" justify="space-between">
          <Flex spaceBetween="S">
            <TableHeader>All Differences</TableHeader>
            <ItemsCount>{pricesCount}</ItemsCount>
          </Flex>

          <Flex maxWidth={400} minWidth={400} spaceBetween="S" mb="M">
            <Input
              placeholder="Product Identifier"
              value={inputProductId}
              after={<Icon type="search" />}
              onChange={onChangeProductId}
            />
            <Button
              onClick={() => {
                onApplyFilterClick()
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                onClearFilter()
              }}
            >
              Clear
            </Button>
          </Flex>
        </Flex>
        {pricesCount >= MIN_PAGE_SIZE ? (
          <Pagination
            rowCount={pricesCount}
            page={page}
            pageSize={pageSize}
            onChange={onChangePagination}
          >
            <Table displaySettings={displaySettings} loading={isLoading} data={data} />
          </Pagination>
        ) : (
          <Table
            suppressVirtualization
            displaySettings={displaySettings}
            loading={isLoading}
            data={data}
          />
        )}
      </Content>

      <ConfirmationModal show={isModalOpen} onConfirm={onModalConfirm} onCancel={onModalCancel} />
    </Main>
  )
}
