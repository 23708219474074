import { Deposit, DepositFilter } from '@r1-webui/gowholesale-depositmanagement-v1'

export type DepositsGridPagination = { page: number; pageSize: number }

export type DepositsGridControllerPayload = {
  data: Deposit[]
  totalCount: number
  pagination: DepositsGridPagination
  isFetchingData: boolean
  isProcessingDeposits: boolean
  filter: DepositFilter
  onCloseDeposit: (deposit: Deposit) => void
  onChangePagination: (pagination: DepositsGridPagination) => void
  onChangeFilter: (filterChange: Partial<DepositFilter>) => void
}

export enum DepositAction {
  Close,
  ShowHistory,
}

export enum DepositDialogCase {
  CloseDeposit,
}

export enum DepositsGridColumn {
  DepositId,
  OfferCreationDate,
  CompanyName,
  DepositType,
  DepositAmount,
  Status,
  OfferId,
  WireTransferOrderId,
  RestockingFeeOrderId,
  Actions,
}
