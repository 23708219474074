// @flow

// import * as React from 'react'
import { evolve, mergeLeft } from 'ramda'

import type { StepId, Step } from '../types'
import type { WithController } from './types'

// eslint-disable-next-line import/no-unused-modules
export class NavigationController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  getStepIdxById = (stepId: StepId) => {
    const { steps } = this.component.state.navigation
    return steps.findIndex(({ id }) => id === stepId)
  }

  getStepDataById = (stepId: StepId): Step<StepId> => {
    const { steps } = this.component.state.navigation
    return steps.filter(({ id }) => id === stepId)[0]
  }

  changeStep = (updater: 1 | -1) => {
    const { currentStep, steps } = this.component.state.navigation
    const currentStepIdx = this.getStepIdxById(currentStep)
    const stepsCount = steps.length - 1

    if (updater > 0 && currentStepIdx === stepsCount) return
    if (updater < 0 && currentStepIdx === 0) return

    const newStepId = steps[currentStepIdx + updater].id

    this.component.setState(
      evolve({
        navigation: mergeLeft({
          currentStep: newStepId,
        }),
      }),
    )
  }

  handleNextStep = async () => {
    const hasCurrentStepError = this.component.validation.validateCurrentStep()
    if (hasCurrentStepError) {
      return
    }

    this.changeStep(1)
  }

  handlePrevStep = async () => {
    const { currentStep } = this.component.state.navigation

    switch (currentStep) {
      case 'claimInfo':
        this.component.validation.resetError('expectedDecisionTypeId')
        break
      case 'claimCreation':
        this.component.validation.resetError('orderLines')
        break
      default: // Do nothing.
    }

    this.changeStep(-1)
  }
}

export const createNavigationController = (component: WithController) =>
  new NavigationController(component)
