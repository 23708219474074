// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Flex, Icon, Box, Text, Link } from '@r1/ui-kit'
import { formatDateToString } from '../../utils/dataUtils'
import { AnimatedLoader } from './components'

const CustomIcon = styled(Icon)`
  ${({ theme, isNew }) => `
    fill: ${isNew ? theme.palette.blue[500] : theme.palette.grey[500]};
    color: ${isNew ? theme.palette.blue[500] : theme.palette.grey[500]};
    cursor: ${isNew ? 'pointer' : 'default'};
  `}
`

const ApplyPriceAction = styled('div')`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.blue[500]};
`

const formatValue = ({ amount, currency }: { amount: string, currency: string }) => {
  const [int, dec] = amount.split('.')
  return `${currency} ${int}.${dec ? dec.slice(0, 2) : '00'}`
}

type Value = {
  amount: string,
  currency: string,
}

type Price = {
  value: Value,
}

type ComparisonResult = {
  status: string,
  count: number,
}

type ComparisonResultObject = {
  [key: string]: ComparisonResult,
}

type Rule = {
  id: string,
  name: string,
}

type ComparisonSettings = { id: string, name: string }[]

export const tableDisplaySettingsGetter = {
  pricesScreen: (syncById: (value: string) => Promise<void>) => {
    return {
      showCounter: false,
      columnsSettings: [
        {
          width: 140,
          header: {
            content: 'Product Id',
          },
          cell: {
            $type: 'custom',
            renderer: (price: { productId: string, isFirst: boolean }) =>
              price.isFirst ? (
                <Flex>
                  <Icon
                    type="refresh"
                    onClick={() => {
                      syncById(price.productId)
                    }}
                  />
                  <Box ml="XS">
                    <Text weight="bold">{price.productId}</Text>
                  </Box>
                </Flex>
              ) : (
                ''
              ),
          },
        },
        {
          width: 300,
          header: {
            content: 'Rule Name',
          },
          cell: {
            $type: 'text',
            field: 'name',
          },
        },
        {
          width: 200,
          header: {
            content: 'Actual Price',
          },
          cell: {
            $type: 'custom',
            renderer: ({
              actualAmount,
              actualCurrency,
            }: {
              actualAmount: string,
              actualCurrency: string,
            }) => {
              let price = ''
              if (actualAmount && actualCurrency) {
                price = formatValue({ amount: `${actualAmount}`, currency: actualCurrency })
              }

              return <div>{price}</div>
            },
          },
        },
        {
          width: 250,
          header: {
            content: 'Actual Price Updated On',
          },
          cell: {
            $type: 'custom',
            renderer: ({ actualUpdatedOn }: { actualUpdatedOn: string }) => {
              if (!actualUpdatedOn) return 'null'

              const date = new Date(actualUpdatedOn)

              return formatDateToString(date)
            },
          },
        },
        {
          width: 200,
          header: {
            content: 'Assumed Amount',
          },
          cell: {
            $type: 'custom',
            renderer: ({
              assumedAmount,
              assumedCurrency,
            }: {
              assumedAmount: string,
              assumedCurrency: string,
            }) => {
              let price = ''
              if (assumedAmount && assumedCurrency) {
                price = formatValue({ amount: `${assumedAmount}`, currency: assumedCurrency })
              }

              return <div>{price}</div>
            },
          },
        },
        {
          width: 250,
          header: {
            content: 'Assumed Price Updated On',
          },
          cell: {
            $type: 'custom',
            renderer: ({ assumedUpdatedOn }: { assumedUpdatedOn: string }) => {
              if (!assumedUpdatedOn) return 'null'

              const date = new Date(assumedUpdatedOn)

              return formatDateToString(date)
            },
          },
        },
        {
          header: {
            content: 'Found Issues',
          },
          cell: {
            $type: 'custom',
            renderer: ({
              actualAmount,
              assumedAmount,
              actualCurrency,
              assumedCurrency,
            }: {
              actualAmount: string,
              assumedAmount: string,
              actualCurrency: string,
              assumedCurrency: string,
            }) => {
              const problems = []

              if (actualAmount !== assumedAmount) {
                problems.push(`(${actualAmount || 'null'} != ${assumedAmount || 'null'})`)
              }

              if (actualCurrency !== assumedCurrency) {
                problems.push(` (${actualCurrency || 'null'} != ${assumedCurrency || 'null'})`)
              }

              return <div style={{ color: 'red' }}>{problems.join(' AND ')}</div>
            },
          },
        },
      ],
    }
  },

  pricesAnomaliesByRule: ({
    comparisonSettings,
    onSyncClick,
    loadingRuleId,
    loadingSettingId,
  }: {
    comparisonSettings: ComparisonSettings,
    onSyncClick: (ruleId: string, settingId?: string) => void,
    loadingRuleId: string,
    loadingSettingId: ?string,
  }) => {
    const baseSettings = [
      {
        width: 400,
        header: {
          content: 'Rule Name',
        },
        cell: {
          $type: 'custom',
          renderer: ({
            comparisonResult,
            rule,
          }: {
            comparisonResult: ComparisonResultObject,
            rule: Rule,
          }) => {
            const isNew = Object.values(comparisonResult).some((x: any) => x.status === 'New')
            let totalCount = 0

            // https://github.com/facebook/flow/issues/2221
            // $FlowFixMe[incompatible-use]
            Object.values(comparisonResult).forEach(({ count }) => {
              totalCount += count
            })

            const link = `/r1/product-prices-anomalies-product?ruleId=${rule.id}`
            const onIconClick = isNew ? () => onSyncClick(rule.id) : () => {}
            const isSyncing = loadingRuleId === rule.id && loadingSettingId === undefined

            return (
              <Flex>
                <Box mr="XS">
                  {isSyncing ? (
                    <AnimatedLoader />
                  ) : (
                    <CustomIcon
                      cursor={isNew ? 'pointer' : 'default'}
                      isNew={isNew}
                      type="checkmark"
                      onClick={onIconClick}
                    />
                  )}
                </Box>

                <Flex align="center">
                  <Text weight="bold">{rule.name}&nbsp;•&nbsp;</Text>
                  {totalCount === 0 ? (
                    <Box>{totalCount}</Box>
                  ) : (
                    <Link href={link} target="_blank">
                      {totalCount}
                    </Link>
                  )}
                </Flex>
              </Flex>
            )
          },
        },
      },
    ]

    if (comparisonSettings.length)
      comparisonSettings.forEach(setting => {
        baseSettings.push({
          horizontalAlign: 'right',
          header: {
            horizontalAlign: 'right',
            content: <Text>{setting.name}</Text>,
          },
          cell: {
            $type: 'custom',
            renderer: ({
              comparisonResult,
              rule,
            }: {
              comparisonResult: ComparisonResultObject,
              rule: Rule,
            }) => {
              const value = comparisonResult[setting.id] || { count: '0', status: 'empty' }

              const link = rule
                ? `/r1/product-prices-anomalies-product?ruleId=${
                    rule.id
                  }&settingId=${encodeURIComponent(setting.id)}`
                : ''

              const isNew = value.status === 'New'
              const onIconClick = isNew ? () => onSyncClick(rule.id, setting.id) : () => {}

              const isSyncing = loadingRuleId === rule.id && loadingSettingId === setting.id
              const isValueEmpty = value.count === 0 || value.count === '0'

              return (
                <Flex>
                  <Box mr="XS">
                    {isValueEmpty ? (
                      <Flex align="center">{value.count}</Flex>
                    ) : (
                      <Link href={link} target="_blank">
                        {value.count}
                      </Link>
                    )}
                  </Box>
                  <Box>
                    {isSyncing ? (
                      <AnimatedLoader />
                    ) : (
                      <CustomIcon
                        cursor={isNew ? 'pointer' : 'default'}
                        isNew={isNew}
                        type="checkmark"
                        onClick={onIconClick}
                      />
                    )}
                  </Box>
                </Flex>
              )
            },
          },
        })
      })

    return {
      showCounter: false,
      columnsSettings: baseSettings,
    }
  },

  pricesAnomaliesByProduct: (onSyncClick: (productId: string) => void) => ({
    showCounter: true,
    columnsSettings: [
      {
        width: 150,
        header: {
          content: 'Product ID',
        },
        cell: {
          $type: 'custom',
          renderer: ({ productId }: { productId: string }) => {
            return (
              <Flex>
                <Link href={`/r1/producttemplate/${productId}`} target="_blank">
                  {productId}
                </Link>
              </Flex>
            )
          },
        },
      },
      {
        width: 200,
        header: {
          content: 'Identifier',
        },
        cell: {
          $type: 'text',
          field: 'identifier',
        },
      },
      {
        width: 200,
        header: {
          content: 'Actual Price',
        },
        cell: {
          $type: 'custom',
          renderer: ({ actualPrice }: { actualPrice: Price }) => {
            let price = ''
            if (actualPrice) {
              const {
                value: { amount, currency },
              } = actualPrice
              price = formatValue({ amount: `${amount}`, currency })
            }

            return <div>{price}</div>
          },
        },
      },
      {
        width: 200,
        header: {
          content: 'Assumed Price',
        },
        cell: {
          $type: 'custom',
          renderer: ({ assumedPrice }: { assumedPrice: Price }) => {
            let price = ''
            if (assumedPrice) {
              const {
                value: { amount, currency },
              } = assumedPrice
              price = formatValue({ amount: `${amount}`, currency })
            }

            return <div>{price}</div>
          },
        },
      },
      {
        width: 200,
        header: {
          content: 'Difference',
        },
        cell: {
          $type: 'custom',
          renderer: ({
            actualPrice,
            assumedPrice,
          }: {
            actualPrice: Price,
            assumedPrice: Price,
          }) => {
            if (actualPrice && assumedPrice) {
              const {
                value: { amount: actualAmount, currency },
              } = actualPrice
              const {
                value: { amount: assumedAmount },
              } = assumedPrice

              const difference = Math.abs(Number(actualAmount) - Number(assumedAmount))

              return formatValue({ amount: `${difference}`, currency })
            }

            return null
          },
        },
      },
      {
        header: {
          content: 'Ratio',
        },
        cell: {
          $type: 'custom',
          renderer: ({
            actualPrice,
            assumedPrice,
          }: {
            actualPrice?: Price,
            assumedPrice?: Price,
          }) => {
            if (actualPrice && assumedPrice) {
              const actualAmount = Number(actualPrice.value.amount)
              const assumedAmount = Number(assumedPrice.value.amount)
              const ratio =
                actualAmount > assumedAmount
                  ? actualAmount / assumedAmount
                  : assumedAmount / actualAmount

              const [int, dec] = `${ratio}`.split('.')
              return (
                <div>
                  {int}.{dec ? dec.slice(0, 2) : '00'}
                </div>
              )
            }
            return null
          },
        },
      },
      {
        horizontalAlign: 'right',
        width: 200,
        header: {
          content: 'Actions',
          horizontalAlign: 'right',
        },
        cell: {
          $type: 'custom',
          renderer: ({ productId }: { productId: string }) => {
            const onClick = () => {
              onSyncClick(productId)
            }
            return <ApplyPriceAction onClick={onClick}>Apply assumed price</ApplyPriceAction>
          },
        },
      },
    ],
  }),
}
