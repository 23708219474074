import * as React from 'react'
import { Tooltip, withTheme, Link, Text, Flex } from '@r1/ui-kit'
import { Icon } from '../../../../components/Icon'

const MovedLink = ({ productId, children }) => (
  <Link to={`/r1/product/${productId}`}>{children}</Link>
)

const ProductLink = ({ movingInfo }) => {
  if (!movingInfo) return null
  return (
    <Text>
      Moved {movingInfo.movingDirection.toLowerCase()}&nbsp;
      <MovedLink productId={movingInfo.productId}>#{movingInfo.productId}</MovedLink>
    </Text>
  )
}

const MovedIconView = ({ movingInfo, theme }) => {
  if (!movingInfo) return null
  return (
    <MovedLink productId={movingInfo.productId}>
      <Icon
        rotate={movingInfo.movingDirection === 'To' ? 0 : 180}
        name="moved"
        color={theme.primaryColor[600]}
      />
    </MovedLink>
  )
}

const MovedIcon = withTheme(MovedIconView)

export const ProductField = ({ companyProductId, movingInfo }) => (
  <Tooltip place="right" text={<ProductLink movingInfo={movingInfo} />} disabled={!movingInfo}>
    <Flex align="center">
      <Text>{companyProductId}</Text> <MovedIcon movingInfo={movingInfo} />
    </Flex>
  </Tooltip>
)
