// @flow

import { createErrorHandler, createSuccessHandler } from '../errorHandler'
import type { Options } from '../errorHandler'

export const ESPM_API_PREFIX = '/uisvc/espm/api'
export const TCP_API_PREFIX = '/uisvc/tcr/profile-configurator/api/v1'

type VenueTaxCommitmentSystemMapping = {|
  venueProfileId: string,
  taxCommitmentSystemProfileId: string | null,
|}

type VenueProfile = {|
  id: string,
  name: string,
|}

export const createApi = (httpClient: Object, { onError, onNotify }: Options) => {
  // eslint-disable-next-line no-unused-vars
  const { get, put, delete: deleteMethod } = httpClient

  const successHandler = createSuccessHandler(onNotify)
  const errorHandler = createErrorHandler({ onError, onNotify })

  return {
    fetchProfileMappings: (): Promise<VenueTaxCommitmentSystemMapping[]> =>
      get(`${TCP_API_PREFIX}/venue-profiles/tax-commitment-system-profile-mappings`).catch(
        errorHandler,
      ),

    fetchVenueProfiles: (purposeId: string): Promise<VenueProfile[]> =>
      get(`${ESPM_API_PREFIX}/purpose/${purposeId}/profile`).catch(errorHandler),

    setMappedTaxCommitmentSystemProfile: (
      venueProfileId: string,
      taxCommitmentSystemProfileId: string | null,
    ): Promise<void> => {
      if (taxCommitmentSystemProfileId === null) {
        return deleteMethod(
          `${TCP_API_PREFIX}/venue-profiles/${venueProfileId}/mapped-tax-commitment-system-profile`,
        )
          .then(
            successHandler('Tax commitment system profile mapping has been successfully removed'),
          )
          .catch(errorHandler)
      }

      return put(
        `${TCP_API_PREFIX}/venue-profiles/${venueProfileId}/mapped-tax-commitment-system-profile`,
        { taxCommitmentSystemProfileId },
      )
        .then(successHandler('Tax commitment system profile mapping has been successfully changed'))
        .catch(errorHandler)
    },
  }
}
