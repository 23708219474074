// @flow

import React from 'react'
import { Button } from '@r1/ui-kit'
import type { PricingRuleViewScreenProps } from '../../../types/public/PricingRuleViewScreen'
import { PriceContainer } from '../PriceContainer'
import { PricingRuleView } from '../children/PricingRuleView'

export const PricingRuleViewScreen = (props: PricingRuleViewScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons
  return (
    <PriceContainer httpClient={props.httpClient}>
      <PricingRuleView pricingRuleId={props.pricingRuleId}>
        {({ name }) => (
          <>
            <Title>{name}</Title>
            <ActionButtons>
              <Button color="secondary" onClick={props.onClose}>
                Close
              </Button>
            </ActionButtons>
          </>
        )}
      </PricingRuleView>
    </PriceContainer>
  )
}
