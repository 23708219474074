// @flow

export const defaultState = {
  screenType: 'placeholder',
  generalInfo: {
    productName: '',
    categoryPath: [],
    brand: '',
    model: '',
    manufacturer: '',
    country: '',
    taxCode: '',
    retailPrice: {
      amount: '0',
      currency: 'USD',
    },
    shortDescription: '',
    longDescription: '',
    supportingUrl: '',
    partNumber: '',
  },
  dimensionsInfo: {
    packageDimensions: {
      length: '0',
      width: '0',
      height: '0',
      measure: 'Cm',
    },
    productDimensions: {
      length: '0',
      width: '0',
      height: '0',
      measure: 'Cm',
    },
    packageWeight: {
      amount: '0',
      measure: 'Kg',
    },
    productWeight: {
      amount: '0',
      measure: 'Kg',
    },
  },
  identifiersInfo: {
    productIdentifiers: [],
    primaryProductIdentifier: '0',
  },
  attributesInfo: {
    productAttributes: [],
    unitAttributes: [],
  },
  imagesInfo: {
    productImages: [],
    primaryProductImage: '',
  },
  companyPrices: [],
  aggregatedPrices: [],
  marketplaceMappingsInfo: [],
  disabledEdit: null,
}
