import { combineReducers } from 'redux'

import templateModels from './templateModels'
import pageSettings from './pageSettings'
import documentTemplate from './documentTemplate'
import history from './history'
import createDocumentTemplateVisible from './createDocumentTemplateVisible'

const documentGeneratorApp = combineReducers({
  templateModels,
  pageSettings,
  documentTemplate: history(documentTemplate),
  createDocumentTemplateVisible,
})

export default documentGeneratorApp
