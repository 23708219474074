import * as React from 'react'
import styled from '@emotion/styled'

import { EntityAction, EntityPermissions } from '@r1-webui/usermanagement-v1'
import { Flex, Placeholder, FormField, HR, Box } from '@r1/ui-kit'

import { useCallback, useMemo } from 'react'
import { ActionId, EntityId, SelectedEntityActions } from '../../types'
import { ActionTag } from './components/ActionTag'

type EntityActionsProps = {
  editable: boolean
  disabled: boolean
  allEntityActionsInfo: EntityPermissions[]
  value: SelectedEntityActions
  onChange: (entityId: EntityId, actionIds: ActionId[]) => void
}

type RolePermissionsProps = EntityActionsProps & {
  loading: boolean
}

type ReadOnlyActionsViewProps = {
  actions: EntityAction[]
}
const ReadOnlyActionsView: React.FC<ReadOnlyActionsViewProps> = ({ actions }) => (
  <Flex flexWrap>
    {actions.map(action => (
      <ActionTag key={`tag-${action.id}`} active tipText={action.description}>
        {action.name}
      </ActionTag>
    ))}
  </Flex>
)

type EditableActionsViewProps = {
  disabled: boolean
  availableActions: EntityAction[]
  value: ActionId[]
  onChange: (value: ActionId[]) => void
}

const widthColl = 0.7

const EditableActionsView: React.FC<EditableActionsViewProps> = ({
  disabled = false,
  availableActions,
  value,
  onChange,
}) => {
  const isActionSelected = useCallback(
    (actionId: ActionId) => {
      return value.includes(actionId)
    },
    [value],
  )

  const getHandleActionSelect = useCallback(
    (actionId: ActionId) => {
      return () => {
        const actionIdIndex = value.indexOf(actionId)
        const newValue = [...value]
        if (actionIdIndex === -1) {
          newValue.push(actionId)
        } else {
          newValue.splice(actionIdIndex, 1)
        }

        onChange(newValue)
      }
    },
    [onChange, value],
  )

  return (
    <Flex flexWrap>
      {availableActions.map(action => (
        <ActionTag
          key={`tag-${action.id}`}
          tipText={action.description}
          active={isActionSelected(action.id)}
          onClick={disabled ? undefined : getHandleActionSelect(action.id)}
        >
          {action.name}
        </ActionTag>
      ))}
    </Flex>
  )
}

const EntityActions: React.FC<EntityActionsProps> = ({
  allEntityActionsInfo,
  value,
  onChange,
  disabled,
  editable,
}) => {
  const getOnChangeHandler = useCallback(
    (entityId: EntityId) => {
      return (selectedActions: ActionId[] | null) => {
        onChange(entityId, selectedActions || [])
      }
    },
    [onChange],
  )

  const readOnlyActionsInfo = useMemo(() => {
    const selectedEntitiesIds = Object.keys(value)
    return allEntityActionsInfo.reduce<EntityPermissions[]>((acc, { entityType, actions }) => {
      const { id: entityId } = entityType
      if (selectedEntitiesIds.includes(entityId)) {
        const entity = {
          entityType,
          actions: actions.filter(({ id }) => value[entityId].includes(id)),
        }
        acc.push(entity)
      }
      return acc
    }, [])
  }, [allEntityActionsInfo, value])

  if (editable) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {allEntityActionsInfo.map(({ entityType, actions }) => (
          <Flex key={entityType.id} column>
            <Flex align="center" justify="space-between" mt="XXS" mb="XXS">
              <FormField.Label>{entityType.name}</FormField.Label>
              <Box maxWidth={widthColl} minWidth={widthColl}>
                <EditableActionsView
                  disabled={disabled}
                  value={value[entityType.id] || []}
                  availableActions={actions}
                  onChange={getOnChangeHandler(entityType.id)}
                />
              </Box>
            </Flex>
            <HR />
          </Flex>
        ))}
      </>
    )
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {readOnlyActionsInfo.map(({ entityType, actions }) => (
        <Flex key={entityType.id} column>
          <Flex align="center">
            <FormField.Label>{entityType.name}</FormField.Label>
            <Box maxWidth={widthColl} minWidth={widthColl}>
              <ReadOnlyActionsView actions={actions} />
            </Box>
          </Flex>
          <HR />
        </Flex>
      ))}
    </>
  )
}

const Container = styled(Flex)`
  flex-grow: 1;
`

export const RolePermissions = ({
  editable,
  loading,
  allEntityActionsInfo,
  value,
  onChange,
  disabled,
}: RolePermissionsProps) => {
  return (
    <Container column>
      <Flex column>
        <HR />
        <Flex spaceBetween="S" mt="S" mb="S" align="center">
          <FormField.Label>Entity</FormField.Label>
          <Box minWidth={widthColl}>Allowed actions</Box>
        </Flex>
        <HR />
      </Flex>
      {loading ? (
        <Flex justify="center">
          <Placeholder type="form" height={10} />
        </Flex>
      ) : (
        <EntityActions
          editable={editable}
          disabled={disabled}
          allEntityActionsInfo={allEntityActionsInfo}
          value={value}
          onChange={onChange}
        />
      )}
    </Container>
  )
}
