// @flow

import React from 'react'
import { Link, Icon, Button, Dropdown, Box } from '@r1/ui-kit'
import styled from '@emotion/styled'
import type { DisplaySettings } from '@r1/grid/build/types/displaySettings'
import type { RuleInfo } from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/types'
import type { DeleteRule as DeleteRuleApi } from '@r1-webui/productprices-companypriceconsolidationrulemanagement-v1/src/ruleManagement'
import { DeleteRule } from './View/DeleteRule'
import type { HistoryModalData } from './CompanyPricingRulesScreen'

const ActionContainer = styled(Box)`
  cursor: pointer;
`

export const getTableSettings: (
  onRuleClick: (ruleId: string) => void,
  onHistoryModalOpen: (HistoryModalData) => void,
  deleteRule: DeleteRuleApi,
  fetchRulesInfo: () => Promise<void>,
) => DisplaySettings<RuleInfo> = (onRuleClick, onHistoryModalOpen, deleteRule, fetchRulesInfo) => ({
  columnsSettings: [
    {
      width: 822,
      header: {
        content: 'Title',
      },
      cell: {
        $type: 'custom',
        renderer: ({ id, name }) => <Link onClick={() => onRuleClick(id)}>{name}</Link>,
      },
    },
    {
      header: {
        content: 'Currency',
      },
      cell: {
        $type: 'custom',
        renderer: ({ currencyCode }) => currencyCode,
      },
    },
    {
      header: {
        content: 'Actions',
      },
      cell: {
        $type: 'custom',
        renderer: rule => (
          <Dropdown.Menu
            items={[
              {
                id: '0',
                title: (
                  <ActionContainer
                    grow="1"
                    onClick={() => {
                      onHistoryModalOpen({ id: rule.id, name: rule.name })
                    }}
                  >
                    History
                  </ActionContainer>
                ),
              },
              {
                id: '1',
                title: (
                  <DeleteRule
                    ruleInfo={rule}
                    deleteRuleApiCall={deleteRule}
                    onDeleteSuccess={fetchRulesInfo}
                  >
                    {({ onOpen }) => (
                      <ActionContainer grow="1" onClick={onOpen}>
                        Delete
                      </ActionContainer>
                    )}
                  </DeleteRule>
                ),
              },
            ]}
            mode="click"
            placement="bottom-end"
            size="M"
            subMenuPlacement="right"
            width={200}
          >
            <Button transparent shape="square" color="secondary">
              <Icon type="dots" />
            </Button>
          </Dropdown.Menu>
        ),
      },
    },
  ],
})
