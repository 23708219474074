// @flow

import { httpClient } from '../../httpClient'
import { handleError } from '../../modules/utils'

export const loadConsolidationSteps = () =>
  httpClient
    .get('/uisvc/consolidator/raw-price-calculations/get-latest2')
    .catch(err => handleError(err))

export const sync = (productIdentifier: string, priceRuleId: string) =>
  httpClient
    .post('/uisvc/consolidator/raw-price-calculations/add-to-queue', {
      productIdentifier,
      priceRuleId,
    })
    .catch(err => handleError(err))

export const loadPricingRulesInfos = () =>
  httpClient
    .get('/uisvc/catalog-data-consolidator/2s90/api/v1/pricing-rules/infos')
    .catch(err => handleError(err))
