import * as React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import { Content, ContentHeader } from '@r1/wireframe-primary'
import { withTheme, Button, Input, Text, FormField } from '@r1/ui-kit'
import { useNavigate } from 'react-router-dom'
import * as appActions from '../../App/module'
import { transformAndCheckId } from '../../../utils/dataUtils'

const Divider = styled('div')`
  width: 100%;
  margin: 16px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};
`

const enhance = compose(
  connect(() => ({}), appActions),
  withTheme,
  withState('inputValue', 'setInputValue', ''),
  withHandlers({
    onChangeInput:
      ({ setInputValue }) =>
      value =>
        setInputValue(value),
  }),
)

const ProductTemplateCreatingFormInner = ({
  theme,
  inputValue,
  onCreate,
  onChangeInput,
  onCancel,
}) => {
  const navigate = useNavigate()

  const onCreateHandler = React.useCallback(() => {
    onCreate(navigate, transformAndCheckId(inputValue, false))
  }, [inputValue, navigate, onCreate])

  return (
    <>
      <ContentHeader />
      <ContentHeader.Title>Creating new product template</ContentHeader.Title>
      <ContentHeader.ActionButtons>
        <Button onClick={onCreateHandler}>CREATE</Button>
        <Button transparent onClick={onCancel}>
          CANCEL
        </Button>
      </ContentHeader.ActionButtons>
      <Content>
        <Text type="paragraph" color={theme.palette.grey[600]}>
          Think of a product as a data record for an item that you sell. It helps you track
          information about your item, and contains the information needed to create a listing on
          different marketplaces, including the title, description, pictures, and other options.
          Also product template has multiple attributes and catalog information that helps manage
          business processes and apply different business flow rules.
        </Text>
        <Divider />
        <FormField>
          <FormField.Label>Primary identifier</FormField.Label>
          <Input
            data-test-id="primary-identifier"
            placeholder="UPC, ASIN, ISBN, EAN"
            value={inputValue}
            onChange={onChangeInput}
          />
        </FormField>
      </Content>
    </>
  )
}

export const ProductTemplateCreatingForm = enhance(ProductTemplateCreatingFormInner)
