// @flow

import * as React from 'react'
import { ContentHeader } from '@r1/wireframe-primary'
import { AttributeDefinitionView } from '../../../components/AttributeDefinitions'

export const AttributeDefinitionsDefaultView = (
  props: React.ElementProps<typeof AttributeDefinitionView>,
) => {
  return (
    <>
      <ContentHeader.Title>{props.generalInfo.name}</ContentHeader.Title>
      <AttributeDefinitionView {...props} />
    </>
  )
}
