// @flow

import React, { useState, useCallback } from 'react'
import { Button, Icon, Tooltip } from '@r1/ui-kit'
import type { DecisionItem } from '../../../types/common'
import { ConfirmationModal } from '../../ConfirmationModal'

type Props = {|
  claimId: string,
  decisionItem: DecisionItem,
  restartDecisionItem: (claimId: string, decisionItemId: string) => Promise<void>,
|}

export const RestartProduceLabel = (props: Props) => {
  const { claimId, decisionItem, restartDecisionItem } = props

  const [restarting, setRestarting] = useState(false)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [restartingDecisionItemId, setRestartingDecisionItemId] = useState('')

  const openDialog = useCallback((decisionItemId: string) => {
    return () => {
      setRestartingDecisionItemId(decisionItemId)
      setIsDialogOpen(true)
    }
  }, [])

  const closeDialog = useCallback(() => {
    setRestartingDecisionItemId('')
    setIsDialogOpen(false)
  }, [])

  const onRestartDecisionItemClick = useCallback(async () => {
    setRestarting(true)
    await restartDecisionItem(claimId, restartingDecisionItemId)
    setRestarting(false)
    closeDialog()
  }, [claimId, restartingDecisionItemId, restartDecisionItem, closeDialog])

  return (
    <>
      <Tooltip text="Restart step">
        <Button
          transparent
          loading={restarting}
          size="S"
          shape="square"
          color="secondary"
          onClick={openDialog(decisionItem.id)}
        >
          <Icon size="XS" type="refresh" />
        </Button>
      </Tooltip>
      <ConfirmationModal
        show={isDialogOpen}
        message="Do you really want to restart this claim step?"
        completing={restarting}
        onConfirm={onRestartDecisionItemClick}
        onCancel={closeDialog}
      />
    </>
  )
}
