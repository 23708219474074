import * as React from 'react'
import styled from '@emotion/styled'
import {
  Text,
  FormField,
  Textarea,
  Checkbox,
  Input,
  withTheme,
  Flex,
  Icon,
  Editor,
  useConfigurationProvider,
} from '@r1/ui-kit'
import { stripHtml } from '../utils'

const HistoryIcon = styled('span')`
  width: 30px;
  margin: 16px 0 0 4px;
`

const Label = ({ label, help }) => {
  const { palette } = useConfigurationProvider()
  return (
    <Flex align="center" spaceBetween="S">
      <Text color={palette.grey[900]}>{label}</Text>
      {help && <Icon type="help" size="S" />}
    </Flex>
  )
}

export function createFieldRenderer({ Control }) {
  return withTheme(
    ({
      input,
      vertical,
      label,
      counter,
      help,
      meta: { touched, error },
      handleChange,
      historyType,
      onHistoryIconClick,
      theme,
      ...props
    }) => {
      const metaData = React.useMemo(
        () => ({
          max: 1000,
          current: ['productInfo.longDescription', 'productInfo.shortDescription'].includes(
            input.name,
          )
            ? stripHtml(input.value).length
            : input.value.length,
        }),
        [input.name, input.value],
      )

      return (
        <Flex maxWidth={900}>
          <FormField>
            <FormField.Label charCounter={counter ? metaData : null}>
              <Label label={label} help={help} />
            </FormField.Label>
            <Flex column basis={1} align="flex-start" justify="center">
              <Control
                {...props}
                value={input.value}
                error={!!touched && !!error}
                onChange={value => {
                  input.onChange(value === '' ? null : value)
                  if (handleChange) handleChange(value === '' ? null : value)
                }}
              />
              {touched && <Text color={theme.palette.red[500]}>{error}</Text>}
            </Flex>
          </FormField>
          <HistoryIcon>
            {historyType && (
              <Icon
                type="history"
                color={theme.primaryColor[500]}
                onClick={() => onHistoryIconClick(historyType)}
              />
            )}
          </HistoryIcon>
        </Flex>
      )
    },
  )
}

export const renderEditor = ({ label, value, onChange, disabled }) => (
  <FormField>
    <FormField.Label>
      <Label label={label} />
    </FormField.Label>
    <Flex column basis={1} align="flex-start" justify="center" maxWidth={900}>
      <Editor data-test-id={label} disabled={disabled} value={value || ''} onChange={onChange} />
    </Flex>
  </FormField>
)

export const renderInput = createFieldRenderer({ Control: Input })
export const renderTextarea = createFieldRenderer({
  Control: ({ ...props }) => <Textarea {...props} />,
})

// eslint-disable-next-line no-unused-vars
/* eslint-disable max-len */
export const renderCheckbox = createFieldRenderer({
  Control: ({ onChange, value, onVerifyProduct, error, ...props }) => (
    <Checkbox
      checked={value || false}
      onChange={val => {
        if (onVerifyProduct) onVerifyProduct(val)
        onChange(val)
      }}
      {...props}
    />
  ),
})
