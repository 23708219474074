import { Action } from '../constants'
import { createDocumentTemplateManagerService } from '../services/documentTemplateManagerService'

const api = createDocumentTemplateManagerService()

export const loadTemplateModels = () => {
  return async dispatch => {
    const templateModels = await api.getTemplateModels()

    dispatch({
      type: Action.LOAD_TEMPLATE_MODELS,
      templateModels,
    })
  }
}
