// @flow

import * as React from 'react'
import { Format } from '@r1/ui-kit'

const dateFormat = (date: string): string => date.replace('+00:00', '.000')

export function NumberRange({ from, to }: { from?: number | string, to?: number | string }) {
  if (typeof to !== 'undefined' && typeof from !== 'undefined') {
    return (
      <span>
        <Format.Number>{Number(from)}</Format.Number> &mdash;{' '}
        <Format.Number>{Number(to)}</Format.Number>
      </span>
    )
  }

  if (typeof to !== 'undefined') {
    return (
      <>
        Up to &nbsp;<Format.Number>{Number(to)}</Format.Number>
      </>
    )
  }

  if (typeof from !== 'undefined') {
    return (
      <>
        From &nbsp;<Format.Number>{Number(from)}</Format.Number>
      </>
    )
  }

  return null
}

export function DateRange({ from, to }: { from?: string, to?: string }) {
  if (typeof to !== 'undefined' && typeof from !== 'undefined') {
    return (
      <span>
        <Format.Date>{dateFormat(from)}</Format.Date> &mdash;{' '}
        <Format.Date>{dateFormat(to)}</Format.Date>
      </span>
    )
  }

  if (typeof to !== 'undefined') {
    return (
      <>
        Till &nbsp;<Format.Date>{dateFormat(to)}</Format.Date>
      </>
    )
  }

  if (typeof from !== 'undefined') {
    return (
      <>
        From &nbsp;<Format.Date>{dateFormat(from)}</Format.Date>
      </>
    )
  }

  return null
}
