import { utcToZonedTime, toDate } from 'date-fns-tz'
import { format } from 'date-fns'
import { convert12hTimeStringTo24h } from './convert12hTimeStringTo24h'
import { YMDFormat } from './formats'

export function mergeDateWithTime(date?: string | null, time?: string | null) {
  let mergedDateWithTime = new Date()

  if (typeof date === 'string') {
    const pureDate = toDate(date)
    const onlyDate = format(pureDate, YMDFormat)
    const onlyTime = convert12hTimeStringTo24h(time)
    mergedDateWithTime = utcToZonedTime(`${onlyDate}T${onlyTime}+00:00`, 'UTC')
  }

  return mergedDateWithTime
}
