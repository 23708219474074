import React, { useState } from 'react'
import { Modal, Text, Flex, Box, Link, FormField, Button, Input } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

import { PickupDescription as ReservationPickupDescription } from '@r1-webui/facilityreservationscheduler-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'

import { deleteReservation, bindReservation } from '../../../../services/reservationService'

import { Reservation } from '../../types'

export type DetailsReservationModalProps = Reservation & {
  onClose: () => void
  onDelete: (reservationId: string) => void
  onBind: (reservationId: string, pickupDescription: ReservationPickupDescription) => void
}

const ReserveModalBody = ({
  reservationId,
  notes,
  createdBy,
  onBind,
  onClose,
}: DetailsReservationModalProps) => {
  const [orderInput, setOrderInput] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [{ allowPickupScheduleEdit }] = useAccessControl()

  const handleBindClick = async () => {
    if (orderInput) {
      setLoading(true)
      const pickupDescription = await bindReservation(reservationId, orderInput)
      setLoading(false)
      if (pickupDescription) {
        onBind(reservationId, pickupDescription)
        onClose()
      }
    }
  }

  return (
    <Flex column spaceBetween="L">
      <Box>
        <Text type="paragraph">
          Reserved by{' '}
          <Link to={`/r1v2/admin/user-management/users/${createdBy.id}`}>{createdBy.name}</Link>
        </Text>
      </Box>
      <Box>
        <FormField>
          <FormField.Label>Notes</FormField.Label>
          <Text data-test-id="notes">{notes}</Text>
        </FormField>
      </Box>
      <Box>
        <FormField>
          <FormField.Label>Bind with order</FormField.Label>
          <Flex spaceBetween="M">
            <Box>
              <Input
                data-test-id="bind-with-order"
                value={orderInput || ''}
                onChange={setOrderInput}
              />
            </Box>
            <Box>
              <Button
                disabled={!allowPickupScheduleEdit && (!orderInput || loading)}
                loading={loading}
                onClick={handleBindClick}
              >
                Bind
              </Button>
            </Box>
          </Flex>
          <FormField.Description>
            After binding reservation will be turned into pickup
          </FormField.Description>
        </FormField>
      </Box>
    </Flex>
  )
}

export const DetailsReservationModal = (props: DetailsReservationModalProps) => {
  const [{ allowPickupScheduleEdit }] = useAccessControl()
  const [loading, setLoading] = useState<boolean>(false)

  const modalActionButtons: ControlledActionButtons = React.useMemo(() => {
    const buttons = [
      {
        title: 'Close',
        onClick: props.onClose,
        align: 'left' as const,
        transparent: true,
      },
      {
        title: 'Delete',
        disabled: !allowPickupScheduleEdit || loading,
        onClick: async () => {
          setLoading(true)
          const success = await deleteReservation(props.reservationId)
          setLoading(false)
          if (success) {
            props.onClose()
            props.onDelete(props.reservationId)
          }
        },
        align: 'right' as const,
        // @ts-ignore
        color: 'danger' as const,
        loading,
      },
    ]

    return buttons
  }, [allowPickupScheduleEdit, loading, props])

  return (
    <Modal
      isControlled
      show
      size="M"
      title="Reservation Details"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={props.onClose}
    >
      <ReserveModalBody {...props} />
    </Modal>
  )
}
