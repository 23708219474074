import { ValueType } from '../../../constants'

const valuesToString = values => {
  return values
    ? values
        .map(v => {
          switch (v.type) {
            case ValueType.CONSTANT:
              return v.value
            case ValueType.PROPERTY:
              return `{${v.fullName}}`
            case ValueType.CONDITIONAL:
              return valuesToString(v.trueValues)
            default:
              return ''
          }
        })
        .join('')
    : ''
}

export default valuesToString
