import { MasterProgramRule } from '@r1-webui/masterprograms-v1'

export const infoFieldsIds = [
  'consigned',
  'directShip',
  'serviceOnly',
  'supplyChain',
  'serviceSupplyChain',
  'programQtyTypeSupplyChain',
  'reCommerce',
  'serviceReCommerce',
  'programQtyTypeReCommerce',
  'discontinued',
  'checkIn',
  'testing',
  'gca',
  'packaging',
  'listableLocation',
  'physicalLocation',
  'description',
]

export const getInfoFieldsValue = (
  infoFields: MasterProgramRule['infoFields'] = [],
): MasterProgramRule['infoFields'] => {
  const infoFieldsCache = new Map(infoFields.map(field => [field.key, field]))
  return infoFieldsIds.map(id => infoFieldsCache.get(id) || { key: id, value: '' })
}
