import { isEmpty } from 'ramda'
import { SelectWidget } from './widgets'

export const removeEmptyValues = data => {
  const newData = {}
  Object.entries(data).forEach(([key, value]) => {
    if (!value) {
      return
    }

    if (Array.isArray(value) && (!value.length || (value.length === 1 && value[0] === undefined))) {
      return
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      const newValue = removeEmptyValues(value)
      if (!isEmpty(newValue)) {
        newData[key] = newValue
      }
      return
    }

    newData[key] = value
  })

  return newData
}

export const enrichUiSchemaWithSelects = schema => {
  const newUISchema = {}
  Object.keys(schema.properties).forEach(prop => {
    // it means that property will be rendered as select
    if (schema.properties[prop].enum) {
      newUISchema[prop] = {
        // check data
        'ui:widget': SelectWidget,
      }
    }
    // recursively go through children if parent has 'properties' key
    if (schema.properties[prop].properties) {
      newUISchema[prop] = {
        ...enrichUiSchemaWithSelects(schema.properties[prop]),
      }
    }
    // recursively go through children if parent has 'items' key
    if (schema.properties[prop]?.items?.properties) {
      newUISchema[prop] = {
        items: {
          ...enrichUiSchemaWithSelects(schema.properties[prop].items),
        },
      }
    }
  })

  return newUISchema
}
