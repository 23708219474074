import { handleServerError } from '@r1/core-blocks'
import { profilesApi } from '../../../api/marketplaceProfileManagementProvider'

const fetchMarketplaceProfiles = () =>
  profilesApi.getAccessibleProfiles().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body.profiles.map(({ id, name }) => ({ id, name }))
  })

export const marketplaceProfileService = { fetchMarketplaceProfiles }
