import * as React from 'react'
import styled from '@emotion/styled'
import { Icon } from '@r1/ui-kit'

const RemoveIcon = styled('div')`
  z-index: 10;
  position: absolute;
  transform: translate(150%, 0);
  right: 0;
  display: flex;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.grey[500]};
  opacity: 0;
  transition: 0.5s ease;
  &:hover + * {
    box-shadow: 0px 0px 10px 0px rgba(111, 111, 111, 0.52);
    transition: 0.5s ease;
  }
`

const Container = styled('div')`
  display: inline-flex;
  align-items: center;
  position: relative;
  &:hover ${RemoveIcon} {
    opacity: 1;
  }
`

export const RemovableBlock = ({ onRemove, children, disabled }) =>
  disabled ? (
    children
  ) : (
    <Container>
      <RemoveIcon onClick={onRemove}>
        <Icon type="trash" />
      </RemoveIcon>
      {children}
    </Container>
  )
