import * as React from 'react'
import { Select } from '@r1/ui-kit'
import { withModule } from '../utils'
import * as module from '../../modules/dictionaries/countries'

const enhance = withModule(module)

const CountryComponent = ({ error, value, isFetching, items, onChange, disabled }) => (
  <Select
    simpleValue
    openOnFocus
    disabled={disabled}
    error={error}
    valueKey="id"
    labelKey="name"
    placeholder="Choose country"
    value={value}
    isLoading={isFetching}
    options={items}
    onChange={onChange}
  />
)

export const CountrySelect = enhance(CountryComponent)
