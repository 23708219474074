import * as React from 'react'
import styled from '@emotion/styled'
import { H4, Flex, FormField, Image, HR } from '@r1/ui-kit'

import type { GeneralInfoSummaryProps } from '../../types/UserCreationForm'

const ProfileImageContainer = styled('div')`
  & img {
    object-fit: scale-down;
  }
`

function buildPhoneNumber(phone?: string, phoneExtension?: string) {
  if (!phone) return ''

  const phoneExtensionFormatted = phoneExtension ? ` x ${phoneExtension}` : ''

  return phone + phoneExtensionFormatted
}

const SummaryField = ({ label, children }: React.PropsWithChildren<{ label: string }>) => {
  return (
    <Flex column spaceBetween="S">
      <Flex spaceBetween="XXS">
        <FormField.Label>{label}</FormField.Label>
        {children}
      </Flex>
      <HR />
    </Flex>
  )
}

export const GeneralInfoSummary = (props: GeneralInfoSummaryProps) => {
  const {
    login,
    fullName,
    jobTitle,
    dateFormat,
    timeZone,
    department,
    manager,
    externalId,
    userType,
    email,
    phone,
    phoneExtension,
    active,
    profileImageSrc,
    userCompany,
    location,
    reportLoginId,
    isSalesRepresentative,
  } = props

  return (
    <Flex column spaceBetween="M">
      <H4>General Information</H4>

      <ProfileImageContainer>
        <Image src={profileImageSrc} width={200} height={200} />
      </ProfileImageContainer>

      <div>
        <SummaryField label="Status">{active ? 'Active' : 'Disabled'}</SummaryField>

        <SummaryField label="Login">{login}</SummaryField>

        <SummaryField label="Full Name">{fullName}</SummaryField>

        <SummaryField label="Job Title">{jobTitle}</SummaryField>

        <SummaryField label="Company">{userCompany}</SummaryField>

        <SummaryField label="Manager">{manager}</SummaryField>

        <SummaryField label="Department">{department}</SummaryField>

        <SummaryField label="Location">{location}</SummaryField>

        <SummaryField label="External Id">{externalId}</SummaryField>

        <SummaryField label="User Type">{userType}</SummaryField>

        <SummaryField label="Report Login">{reportLoginId}</SummaryField>

        <SummaryField label="Sales Representative">{isSalesRepresentative}</SummaryField>

        <SummaryField label="Date format">{dateFormat}</SummaryField>

        <SummaryField label="Time Zone">{timeZone}</SummaryField>

        <SummaryField label="Email">{email}</SummaryField>

        <SummaryField label="Phone">{buildPhoneNumber(phone, phoneExtension)}</SummaryField>
      </div>
    </Flex>
  )
}
