import * as React from 'react'
import { Flex, FormField, Input } from '@r1/ui-kit'
import { ContactInfo } from '@r1-webui/shipping-returnshippingrules-v1'
import { FormLoadingPlaceholder } from '../../../../../../components/FormLoadingPlaceholder'

type Props = {
  isLoading: boolean
  contactInformation: ContactInfo
  phoneNumberError: string | null
  companyNameError: string | null
  contactNameError: string | null
  onChange: (v: ContactInfo) => void
}

export const ContactInfoSection = React.memo(
  ({
    isLoading,
    contactInformation,
    phoneNumberError,
    companyNameError,
    contactNameError,
    onChange,
  }: Props) => {
    if (isLoading) {
      return <FormLoadingPlaceholder numberOfFields={5} />
    }
    return (
      <>
        <FormField>
          <FormField.Label>Company</FormField.Label>
          <Flex maxWidth={415} minWidth={415}>
            <Input
              value={contactInformation.companyName}
              error={!!companyNameError}
              onChange={companyName => onChange({ ...contactInformation, companyName })}
            />
          </Flex>
          <FormField.Error>{companyNameError}</FormField.Error>
        </FormField>

        <FormField>
          <FormField.Label>Contact Name</FormField.Label>
          <Flex maxWidth={415} minWidth={415}>
            <Input
              value={contactInformation.contactName}
              error={!!contactNameError}
              onChange={contactName => onChange({ ...contactInformation, contactName })}
            />
          </Flex>
          <FormField.Error>{contactNameError}</FormField.Error>
        </FormField>

        <FormField>
          <FormField.Label>Phone Number</FormField.Label>
          <Flex maxWidth={415} minWidth={415}>
            <Input
              value={contactInformation.phoneNumber}
              error={!!phoneNumberError}
              onChange={phoneNumber => onChange({ ...contactInformation, phoneNumber })}
            />
          </Flex>
          <FormField.Error>{phoneNumberError}</FormField.Error>
        </FormField>
      </>
    )
  },
)
