// @flow

import React, { memo, useMemo } from 'react'
import styled from '@emotion/styled'
import { Image, Box, Flex, Link, Text } from '@r1/ui-kit'
import { useToggle } from '../../../../../../../../../../utils/hooks'

import type { ImagesProps } from '../../../../../../types/product.type'

const ImageContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.grey[500]};
  box-sizing: border-box;
  border-radius: 2px;
`

export const Images = memo<ImagesProps>((props: ImagesProps) => {
  const { images } = props

  const defaultShowCount = 10
  const imagesLength = images.length

  const { state: isShowAll, toggle: setShowAll } = useToggle(false)

  const imagesMemo = useMemo(
    () => (isShowAll ? images : images.slice(0, defaultShowCount)),
    [images, isShowAll],
  )

  return (
    <>
      <Flex flexWrap spaceBetween="L" my="M">
        {imagesMemo.map(image => (
          <Box key={image.url}>
            <ImageContainer>
              <Image src={image.url} width={130} height={130} />
            </ImageContainer>
          </Box>
        ))}
      </Flex>

      {defaultShowCount < imagesLength && (
        <Link onClick={() => setShowAll()}>
          {isShowAll ? <Text>Hide</Text> : <Text>Show all ({imagesLength})</Text>}
        </Link>
      )}
    </>
  )
})
