import * as React from 'react'
import styled from '@emotion/styled'

import { Text } from '@r1/ui-kit'

const TextContainer = styled('div')`
  max-width: 100%;
`
export const TextCell = field => ({
  $type: 'custom',
  renderer: dataItem => (
    <TextContainer>
      <Text overflow="ellipsis">{dataItem[field]}</Text>
    </TextContainer>
  ),
})
