import { useNavigate, useLocation, Location } from 'react-router-dom'

export type RoutingTabsChildrenProps = {
  activeTabId: string
  onChangeTab: (tabId: string) => void
}

type Props = {
  tabIds: string[]
  children: (props: RoutingTabsChildrenProps) => JSX.Element
}

const TAB_PARAM_KEY = 'tab'

const getTabId = (location: Location, tabIds: string[]): string => {
  const params = new URLSearchParams(location.search)
  const tabId = params.get(TAB_PARAM_KEY)
  return tabId && tabIds.includes(tabId) ? tabId : tabIds[0]
}

const updateQueryString = (urlSearchParams: URLSearchParams, updater: Record<string, string>) => {
  Object.entries(updater).forEach(([key, value]) => urlSearchParams.set(key, String(value)))
  return urlSearchParams
}

export const RoutingTabs = ({ tabIds, children }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  return children({
    activeTabId: getTabId(location, tabIds),
    onChangeTab: (tabId: string) => {
      const params = updateQueryString(new URLSearchParams(location.search), {
        [TAB_PARAM_KEY]: tabId,
      })
      navigate({ search: `?${params.toString()}` })
    },
  })
}
