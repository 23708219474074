// @flow

export const toArray = <O: Object>(
  object: O,
  keyName: string,
  valueName: string,
): { [key: string]: $Keys<O> | $Values<O> }[] =>
  Object.entries(object).reduce(
    (acc, [key, value]) => [...acc, { [keyName]: key, [valueName]: value }],
    [],
  )
