/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react'
import type { BottomActionButton } from '@r1/ui-kit/contracts/ts/common/common'
import { Modal, Flex, Loader } from '@r1/ui-kit'
import { ImageCropper } from '@r1/drawing'
import styled from '@emotion/styled'

import type { UserImageEditorProps } from '../../types/UserImageEditor'

type CroppedImageGetter = () => string

const Wrapper = styled(Flex)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const ModalContentWrapper = styled(Flex)`
  min-width: 400px;
  min-height: 400px;
`

export class UserImageEditor extends React.Component<UserImageEditorProps> {
  base64Image: string

  constructor(props: UserImageEditorProps) {
    super(props)

    this.base64Image = ''
  }

  onChangeBoundary = (croppedImageGetter: CroppedImageGetter) => {
    this.base64Image = croppedImageGetter()
  }

  onSaveImage = () => this.props.onChangeImage(this.base64Image)

  render() {
    const { visible, onCancel, imageSrc } = this.props

    const modalActionButtons: BottomActionButton[] = [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: onCancel,
        align: 'right',
      },
      {
        title: 'Save',
        onClick: () => this.onSaveImage(),
        align: 'right',
      },
    ]

    return (
      <Modal
        isControlled
        show={visible}
        size="L"
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onCancel}
      >
        <ModalContentWrapper column justify="space-between">
          {imageSrc ? (
            <Wrapper>
              <ImageCropper
                square
                sourceImage={imageSrc}
                minWidth={500}
                onChangeBoundary={this.onChangeBoundary}
              />
            </Wrapper>
          ) : (
            <Wrapper>
              <Loader text="Prepare image..." />
            </Wrapper>
          )}
        </ModalContentWrapper>
      </Modal>
    )
  }
}
