import { Notice as DefaultNotice } from '@r1/types/typescript'

const variableRegEx = /{(\w+|\d+)}/gi

// any becayse Notice might have any type of value
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Notice = DefaultNotice & { [key: string]: any }

/**
 * parse server Notice with properties to string
 *
 * in notice "{locationType} location {locationName} for program {programName} already exists"
 * replaces properties locationType, locationName and programName to the corresponding propertes from Notice
 */
export const replaceTemplateValues = (notice: Notice): string =>
  notice.defaultFormat.replace(variableRegEx, (currentMatch, p1) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    notice[p1] ? String(notice[p1]) : currentMatch,
  )

export const replaceTemplateValuesBadProperties = (badProperties: Notice[]): string => {
  const result = badProperties.reduce((message, notice, index) => {
    const separator = index > 0 ? '\n' : ''
    const values = replaceTemplateValues(notice)

    return `${message}${separator}${values}`
  }, '')

  return result
}
