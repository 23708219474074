import type { SuccessResponse } from '@r1/types/typescript'
import type { ResponseSuperType } from '../contracts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isErrorResponse(response: ResponseSuperType<any>) {
  return response !== null && !Array.isArray(response) && response.$type !== 'Success'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSuccessResponse(response: ResponseSuperType<any>) {
  return (
    typeof response === 'object' &&
    response !== null &&
    !Array.isArray(response) &&
    response.$type === 'Success'
  )
}

export function extractPayload<Payload>(response: ResponseSuperType<Payload>): Payload | undefined {
  switch (response.$type) {
    case 'Success':
      return response.payload
    default:
  }
  return undefined
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSuccessResponses(response: ResponseSuperType<any>[]) {
  return response !== null && Array.isArray(response) && response.every(isSuccessResponse)
}

export function successResponseHandler<P>(data: P): SuccessResponse<P> {
  return {
    $type: 'Success',
    payload: data,
  }
}
