// @flow

import * as yup from 'yup'

export const schema = yup.object({
  TitleField: yup.string().nullable().max(250).label('Title'),
  /* RetailPriceField: yup.object().shape({
    amount: yup
      .string()
      .matches(/^\d+(\.\d{1,2})?$/)
      .label('Retail Price'),
  }), */
  /* LongDescriptionField: yup
    .string()
    .nullable()
    .max(4000)
    .label('Long Description'),
  ShortDescriptionField: yup
    .string()
    .nullable()
    .max(1000)
    .label('Short Description'), */
  TaxCodeField: yup.string().nullable().max(100).label('Tax Code'),
  SupportingUrlField: yup
    .string()
    .url('Supporting URL field must be a valid URL')
    .max(1000)
    .nullable()
    .label('Supporting URL'),
  /* CountryOfOriginField: yup.object().shape({
    code: yup
      .string()
      .nullable()
      .min(2)
      .max(3)
      .label('Country Of Origin'),
  }), */
})
