// @flow

const getTransform = (value: any) =>
  value.length === 0
    ? [{ categoryId: null, productId: null }]
    : value.map(({ category, product }) => ({
        id: Math.random(),
        categoryId: category.id,
        productId: product.id,
        productOption: [
          {
            id: product.id,
            name: `${product.title.value || ''} (${product.identifiers.primary.value})`,
          },
        ],
      }))

const setTransform = (value: any) =>
  value.filter(({ productId }) => productId).map(({ productId }) => productId)

export const schema = {
  FitsFor: {
    value: {
      errorValue: 'productId',
      getPath: ['fitsFor'],
      setPath: ['fitsFor'],
      defaultValue: [{ categoryId: null, productId: null }],
      setTransform,
      getTransform,
    },
  },
}
