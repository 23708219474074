import { useEffect, useMemo, useState } from 'react'
import { Query } from '@r1/grid-generator'
import { handleServerError } from '@r1/core-blocks'
import { showNotification } from '../../../../../utils/notifications/showNotification'
import { programsApi } from '../../../../user-management/api/programs'
import { usersApi } from '../../../../user-management/api/users'

type UseDefaultStateQuery = (initialStateQuery: Query) => {
  defaultStateQuery: Query
  loading: boolean
}

const showErrorRequestNotification = () =>
  showNotification({
    level: 'error',
    message: "Can't load user settings",
  })

const handleErrorWithNotification = (error: object) => {
  showErrorRequestNotification()
  handleServerError(error)
}

/** TODO Need move call usersApi.getCurrentUser() to UserStateProvider core-blocks 23.09.2021 ablagoy */
const loadUserSearchProgram = async (setDefaultSearchProgram: (val: string) => void) => {
  const userResponse = await usersApi.getCurrentUser()

  if (userResponse.status !== 200) {
    handleErrorWithNotification(userResponse)
    return
  }

  const userId = userResponse.body.id
  const settingsResponse = await programsApi.getUserProgramSettings({ userId })

  if (settingsResponse.status !== 200) {
    handleErrorWithNotification(settingsResponse)
    return
  }

  const defaultProgram = settingsResponse.body.defaultSearchProgram
  /** User dont have defaultSearchProgram */
  if (defaultProgram) setDefaultSearchProgram(defaultProgram)
}

export const useDefaultStateQuery: UseDefaultStateQuery = query => {
  const [loading, setLoading] = useState(true)
  const [defaultSearchProgram, setDefaultSearchProgram] = useState<string | null>(null)

  const defaultStateQuery = useMemo(() => {
    /** TODO 'All ***' values from client Default Search Program currently not be supported.
     * Grid now not contain 'All ***' values on warehouse filter so we show all list of data */
    const isAllFilterValue = defaultSearchProgram !== null && Number(defaultSearchProgram) < 0
    if (
      query.filterState ||
      query.queryId !== '' ||
      defaultSearchProgram === null ||
      isAllFilterValue
    ) {
      return query
    }

    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: query.pagination.pageSize || 25,
      },
      filterState: {
        filterLines: [
          {
            filterLineId: 1,
            filterObjectId: 'FilterByWareHouseId',
            operation: 'OneOf',
            values: [defaultSearchProgram],
          },
        ],
      },
    }
  }, [defaultSearchProgram, query])

  /** This code will be processed on start loading page and setup filter by warehouse (from user settings) */
  useEffect(() => {
    if (query.filterState || query.queryId !== '') {
      setLoading(false)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        await loadUserSearchProgram(setDefaultSearchProgram)
      } catch (e) {
        showErrorRequestNotification()
      } finally {
        setLoading(false)
      }
    })()
  }, [query, query.filterState, setLoading])

  return { defaultStateQuery, loading }
}
