export const remapPagination = {
  from: ({ limit, offset }) => ({
    page: (limit + offset) / limit,
    pageSize: limit,
  }),
  to: ({ page, pageSize }) => ({
    limit: pageSize,
    offset: (page - 1) * pageSize,
  }),
}
