/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Flex, Link, Tab, Tabs } from '@r1/ui-kit'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { ROUTES } from '../../../../navigation'
import { httpClient } from '../../../../httpClient'

import { UserEditFormController, UserAssignRolesController } from '../../components'
import { RoutingTabs } from '../../components/RoutingTabs'
import type { UserEditFormProps } from '../../types/UserEditForm'
import {
  BasicInfoPlaceholder,
  BasicInfoTab,
  RolesTab,
  RolesPlaceholder,
  FmsiPresetsTab,
  ProgramSettingsTab,
} from './Tabs'

const PAGE_TAB_IDS = ['basicInfo', 'programs', 'roles', 'fmsiPresets']

export const EditUser = () => {
  const [{ allowRoleView }] = useAccessControl()
  const params = useParams<{ userId?: string }>()
  const navigate = useNavigate()

  const getFormProps = () => ({
    userId: params.userId || '',
    onError: () => undefined,
    onCancelForm: () => navigate(ROUTES.USERS),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    httpClient,
  })

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowUserView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.USERS}>Users</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <RoutingTabs tabIds={PAGE_TAB_IDS}>
            {({
              activeTabId,
              onChangeTab,
            }: {
              activeTabId?: string
              onChangeTab?: (id: string) => void
            }) => (
              <Tabs
                active={activeTabId}
                renderHeader={(header: any, DefaultHeader) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                  const tabTitles: string = allowRoleView ? header.tabTitles : [header.tabTitles[0]]
                  return (
                    <ContentHeader.CardNavigation>
                      <Flex>
                        <DefaultHeader {...header} tabTitles={tabTitles} />
                      </Flex>
                    </ContentHeader.CardNavigation>
                  )
                }}
                onChange={onChangeTab}
              >
                <Tab
                  id={PAGE_TAB_IDS[0]}
                  title="General Information"
                  data-test-id="um-edit-user-tab-basic-info"
                >
                  <UserEditFormController {...getFormProps()}>
                    {(formProps: UserEditFormProps) => {
                      const isActiveTab = activeTabId === PAGE_TAB_IDS[0]
                      if (formProps.placeholder) {
                        return isActiveTab ? <BasicInfoPlaceholder /> : null
                      }
                      return <BasicInfoTab {...formProps} isActiveTab={isActiveTab} />
                    }}
                  </UserEditFormController>
                </Tab>

                <Tab id={PAGE_TAB_IDS[1]} title="Programs" data-test-id="um-edit-user-tab-programs">
                  <ProgramSettingsTab {...getFormProps()} />
                </Tab>

                <Tab id={PAGE_TAB_IDS[2]} title="Roles" data-test-id="um-edit-user-tab-roles">
                  <UserAssignRolesController {...getFormProps()}>
                    {formProps => {
                      const isActiveTab = activeTabId === PAGE_TAB_IDS[2]
                      if (formProps.placeholder) {
                        return isActiveTab ? <RolesPlaceholder /> : null
                      }
                      return <RolesTab {...formProps} isActiveTab={isActiveTab} />
                    }}
                  </UserAssignRolesController>
                </Tab>

                <Tab
                  id={PAGE_TAB_IDS[3]}
                  title="FMSI export Presets"
                  data-test-id="um-edit-user-tab-fmsiPresets"
                >
                  <FmsiPresetsTab {...getFormProps()} />
                </Tab>
              </Tabs>
            )}
          </RoutingTabs>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
