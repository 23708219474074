// @flow

import { useMemo, useCallback, useState } from 'react'
import { setQuery, parseQuery } from '@r1/grid-generator'
import type { Query } from '@r1/grid-generator/build/types/query'

export const useStateQuery = () => {
  const initialStateQuery = useMemo(parseQuery, [])
  const [isExpanded, setExpanded] = useState(false)
  const [lastGrouping, setLastGrouping] = useState([])

  const onChangeState = useCallback((query: Query) => {
    /* set query as query params to link */
    setQuery(query)
    /* save last applied grouping in order to use it for breadcrumb */
    if (Array.isArray(query.grouping) && query.grouping.length > 0) {
      setLastGrouping(query.grouping)
    }
    /* save expanded state to show correct breadcrumb */
    setExpanded(query.expanded)
  }, [])

  return {
    isExpanded,
    lastGrouping,
    initialStateQuery,
    onChangeState,
  }
}
