// @flow

import * as React from 'react'
import { Table, Pagination } from '@r1/grid'
import { Checkbox, StatusIndicator, Flex } from '@r1/ui-kit'
import type { FilterStatus } from '@r1-webui/datasourceinfo-categorymapping-v1/src/types'
import { CounterSection } from '../../../../components/GridComponents/CounterSection'
import { getParentName } from './dataUtils'

export type PaginationType = {
  page: number,
  pageSize: number,
}

type CategoryMappingTableProps = {
  itemsCount: number,
  isAllSelected: boolean,
  setIsAllSelected: (selected: boolean) => void,
  selectedIds: string[],
  setSelectedIds: (selected: string[]) => void,
  setStatus: (status: FilterStatus | null) => void,
  marketplace: string,
  dataSourceCategories: $ReadOnlyArray<$AnyObject>,
  r1Categories: $ReadOnlyArray<$AnyObject>,
  mappings: $ReadOnlyArray<$AnyObject>,
  actionItems: any,
  onSelectActionItem: (id: string) => void | Promise<void>,
  status: string | null,
  pagination: PaginationType,
  onChangePagination: (pagination: PaginationType) => void,
}

const headers = ['Marketplace', 'Status', 'Marketplace Category', 'R1 Category']

const getRender = header => {
  switch (header) {
    case headers[0]:
      return dataItem => <div>{dataItem.marketplace}</div>
    case headers[1]:
      return dataItem => (
        <StatusIndicator type={dataItem.mapped ? 'success' : 'error'}>
          {dataItem.mapped ? 'Mapped' : 'Not mapped'}
        </StatusIndicator>
      )
    case headers[2]:
      return dataItem => <div>{dataItem.dataSourceCategory}</div>
    case headers[3]:
      return dataItem => <div>{dataItem.r1Category}</div>
    default:
      return () => null
  }
}

const mapHeaderToWidth = {
  [headers[0]]: 150,
  [headers[1]]: 150,
  [headers[2]]: 750,
  [headers[3]]: 250,
}

export const MIN_PAGE_SIZE = 50

export const CategoryMappingTable = (props: CategoryMappingTableProps) => {
  const {
    itemsCount,
    marketplace,
    dataSourceCategories,
    r1Categories,
    mappings,
    isAllSelected,
    setIsAllSelected,
    selectedIds,
    setSelectedIds,
    actionItems,
    onSelectActionItem,
    status,
    setStatus,
    pagination,
    onChangePagination,
  } = props

  const [data, setData] = React.useState([])

  React.useEffect(() => {
    const computedData = dataSourceCategories.map(dsCategory => {
      const r1CategoryMapping =
        mappings.find(
          ({ dataProviderCategoryId }) =>
            dataProviderCategoryId === dsCategory.dataProviderCategoryId,
        ) || {}

      const r1Category =
        r1Categories.find(({ id }) => id === r1CategoryMapping.catalogCategoryId) || {}

      const r1CategoryName = r1Category.parentId
        ? `${getParentName(r1Categories, r1Category.parentId)} - ${r1Category.name}`
        : r1Category.name

      return {
        marketplace,
        dataSourceCategory: dsCategory.path.join(' - '),
        dataSourceCategoryId: dsCategory.dataProviderCategoryId,
        r1Category: r1CategoryName,
        mapped: !!r1CategoryName,
      }
    })

    setData(computedData)
  }, [dataSourceCategories, mappings, marketplace, r1Categories])

  const onSelectAll = () => {
    if (isAllSelected) {
      setIsAllSelected(false)
      setSelectedIds([])
    } else {
      setIsAllSelected(true)

      setSelectedIds(
        dataSourceCategories.map(({ dataProviderCategoryId }) => dataProviderCategoryId),
      )
    }
  }

  const onSelectId = (id: string, checked: boolean) => () => {
    if (checked) {
      setIsAllSelected(false)
      const newIds = [...selectedIds]
      newIds.splice(newIds.indexOf(id), 1)
      setSelectedIds(newIds)
    } else {
      const newIds = [...selectedIds, id]
      if (newIds.length === dataSourceCategories.length) {
        setIsAllSelected(true)
        setSelectedIds(newIds)
      } else {
        setSelectedIds(newIds)
      }
    }
  }

  const handleFlushFilter = () => {
    setStatus(null)
  }

  const displaySettings = {
    showCounter: false,
    columnsSettings: [
      {
        width: 50,
        header: {
          content: (
            <Checkbox
              data-test-id="select-all-checkbox"
              data-test-state={
                // eslint-disable-next-line no-nested-ternary
                !isAllSelected && !!selectedIds.length
                  ? 'ideterminate'
                  : isAllSelected
                  ? 'checked'
                  : 'unchecked'
              }
              indeterminate={!isAllSelected && !!selectedIds.length}
              checked={isAllSelected}
              onChange={onSelectAll}
            />
          ),
        },
        cell: {
          $type: 'custom',
          renderer: dataItem => {
            const checked = isAllSelected || selectedIds.includes(dataItem.dataSourceCategoryId)
            return (
              <Checkbox
                data-test-id={`select-category-${dataItem.dataSourceCategoryId}-checkbox`}
                data-test-state={checked ? 'checked' : 'uncheked'}
                checked={checked}
                onChange={onSelectId(dataItem.dataSourceCategoryId, checked)}
              />
            )
          },
        },
      },
      ...headers.map(header => ({
        width: mapHeaderToWidth[header],
        minWidth: 100,
        header: {
          content: <div>{header}</div>,
        },
        verticalAlign: 'middle',
        horizontalAlign: 'left',
        cell: {
          $type: 'custom',
          renderer: getRender(header),
        },
      })),
    ],
  }

  return (
    <Flex column basis={1}>
      <Flex mb="M">
        <CounterSection
          count={selectedIds.length || itemsCount}
          isSelected={!!selectedIds.length}
          isFiltered={!!status}
          actionItems={actionItems}
          onSelectItem={onSelectActionItem}
          onFilterFlush={handleFlushFilter}
        />
      </Flex>
      <Pagination
        rowCount={itemsCount}
        page={pagination.page}
        pageSize={pagination.pageSize}
        availablePageSizes={[50, 100, 500]}
        onChange={onChangePagination}
      >
        <Table resizable data={data} displaySettings={displaySettings} />
      </Pagination>
    </Flex>
  )
}
