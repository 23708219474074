// @flow

/**
 * attribute should have only parent
 * add attribute as child -> remove same attribute from it's previous parent
 */

import type {
  AttributeBinding,
  ChildBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'

/**
 *
 * childBindings field using only in view mode!!!
 *
 * if we add Attribute1 as child binding to Attribute2
 * we should remove childBindings field in Attribute1 (childBindings field using only in view mode!!!)
 * and add Attribute1 to parentBinding field in Attribute2 before sending
 *
 */

function replaceAttributesParentBindings({ bindings, newChildBindings, attributeDefinitionId }) {
  const newBindings = [...bindings]

  newChildBindings.forEach(childBinding => {
    const targetBindingIndex = newBindings.findIndex(
      ({ attributeDefinition: { id } }) => id === childBinding.attributeDefinitionId,
    )

    if (targetBindingIndex !== -1) {
      newBindings[targetBindingIndex] = {
        ...newBindings[targetBindingIndex],
        parentBinding: {
          ...childBinding,
          attributeDefinitionId,
        },
      }
    }
  })

  return newBindings
}

function removeAttributesParentBindings({ bindings, newChildBindings, attributeDefinitionId }) {
  const newBindings = [...bindings]
  const newChildBindingsAttributeIds = newChildBindings.map(
    binding => binding.attributeDefinitionId,
  )

  for (let i = 0; i < newBindings.length; i += 1) {
    if (
      newBindings[i].parentBinding &&
      newBindings[i].parentBinding.attributeDefinitionId === attributeDefinitionId &&
      !newChildBindingsAttributeIds.includes(newBindings[i].attributeDefinition.id)
    ) {
      const { parentBinding: _, ...bindingWithoutParent } = newBindings[i]
      newBindings[i] = bindingWithoutParent
    }
  }

  return newBindings
}

type UpdateOptions = {
  bindings: AttributeBinding[],
  newChildBindings: ChildBinding[],
  attributeDefinitionId: string,
}

export function updateBindingsArray(updateOptions: UpdateOptions): AttributeBinding[] {
  return replaceAttributesParentBindings({
    ...updateOptions,
    bindings: removeAttributesParentBindings(updateOptions),
  })
}
