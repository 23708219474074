// @flow

import * as React from 'react'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Col, Container, Flex, Link, Row } from '@r1/ui-kit'

import { ROUTES } from '../../../../../navigation'

export const AttributeDefinitionsWrapperView = (props: {| children: React$Node |}) => {
  return (
    <Main>
      <ContentHeader />
      <ContentHeader.Breadcrumbs>
        <Link to={ROUTES.ATTRIBUTES_DEFINITIONS}>Attributes</Link>
      </ContentHeader.Breadcrumbs>

      <Content>
        <Flex mt="XL">
          <Container>
            <Row>
              <Col md={7}>{props.children}</Col>
            </Row>
          </Container>
        </Flex>
      </Content>
    </Main>
  )
}
