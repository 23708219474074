// @flow

import * as React from 'react'
import type { DecisionTypeData } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import { Flex, Image, Text } from '@r1/ui-kit'
import type { Line } from '@r1-webui/rmavendorportal-orders-v1/src/types'
import type { ClaimType } from '../../types/common'
import { RefundEditor, QuantityEditor } from '../editors'
import { Nowrap } from '../ClaimCard/StyledComponents'

type Props = {
  claimLineData: DecisionTypeData,
  orderLine: Line,
  decisionType: ClaimType,
  onRefundChange: (claimItemId: string, value: string) => void,
  onQuantityChange: (claimItemId: string, value: number) => void,
}

export const ClaimLine = (props: Props) => {
  const { claimLineData, orderLine, decisionType, onRefundChange, onQuantityChange } = props

  return (
    <Flex justify="space-between">
      <Flex>
        <Flex align="center" justify="center" minWidth={160} maxWidth={160}>
          <Image src={orderLine.productInfo.imageUrls[0]} height={100} width={100} />
        </Flex>
        <Flex column>
          <Flex mb="M">
            <Nowrap>Product:</Nowrap>&nbsp;
            <Text>
              <div>{orderLine.productInfo.title}</div>
            </Text>
          </Flex>
          <Flex mb="M">
            <Nowrap>Price:</Nowrap>&nbsp;
            <Text>
              {orderLine.lineInfo.singleItemPrice.total.currency}{' '}
              {orderLine.lineInfo.singleItemPrice.total.amount}
            </Text>
          </Flex>
          <Flex mb="M">
            <Nowrap>Quantity:</Nowrap>&nbsp;
            <Text>{orderLine.lineInfo.quantity}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex minWidth={180} maxWidth={180}>
        {decisionType === 'RefundOnly' ? (
          <RefundEditor
            defaultValue={claimLineData.refund.amount}
            currency={claimLineData.refund.currency}
            maxValue={(
              parseFloat(orderLine.lineInfo.singleItemPrice.total.amount) *
              orderLine.lineInfo.quantity
            ).toFixed(2)}
            onRefundChange={value => onRefundChange(claimLineData.claimItemId, value)}
          />
        ) : (
          <QuantityEditor
            defaultValue={claimLineData.quantity}
            maxValue={orderLine.lineInfo.quantity}
            onQuantityChange={value => onQuantityChange(claimLineData.claimItemId, value)}
          />
        )}
      </Flex>
    </Flex>
  )
}
