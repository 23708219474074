// @flow

import * as React from 'react'

import { useAccessControl } from '@r1/core-blocks'

import { CategoryView } from './CategoryView'
import { CategoryEdit } from './CategoryEdit'

export const CategoryDetails = () => {
  const [{ allowCategoryEdit }] = useAccessControl()
  return allowCategoryEdit ? <CategoryEdit /> : <CategoryView />
}
