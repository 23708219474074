// @flow

import {
  createAttributeDefinitionsApi,
  baseUri as attributeBaseUri,
} from '@r1-webui/productcatalog-attributedefinitions-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const attributesApi = (httpClient: any): WebUiApi => {
  const {
    findAllAttributeDefinitions,
    findAttributeDefinitionNamesByIds,
    findAttributeDefinitions,
    generateAttributeDefinitionHistoryReceivingToken,
    getAttributeDefinition,
  } = createAttributeDefinitionsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${attributeBaseUri}`,
  })

  return {
    findAllAttributeDefinitions,
    findAttributeDefinitionNamesByIds,
    findAttributeDefinitions,
    generateAttributeDefinitionHistoryReceivingToken,
    getAttributeDefinition,
  }
}
