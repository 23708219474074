import { CompanyModelTypeEnum } from '../../../utils/useUserInfo'

export const getRMPMenuItemsByPermissions = (companyType: CompanyModelTypeEnum) => {
  const isVendor = companyType === CompanyModelTypeEnum.Vendor
  const rmpBaseUrl = window._env_?.RMP_LINK || ''
  const homePagePath = isVendor ? 'vendor-dashboard' : 'retailer-dashboard'
  return [
    {
      title: 'Home',
      url: `/returns-portal/${homePagePath}`,
      description: 'Home',
      iconSvgId: 'home',
      isStarred: false,
    },
    {
      title: 'Retailers',
      description: 'Retailers',
      iconSvgId: 'retailers',
      url: '/returns-portal/retailers',
      permissions: ['CmRetailer'],
    },
    {
      url: '/returns-portal/vendors',
      isStarred: false,
      title: 'Vendors',
      description: 'Create and manage vendor records that are used in the policy management',
      iconSvgId: 'vendors',
      permissions: ['CmVendors'],
    },
    {
      title: 'Policies',
      description: 'Policies',
      iconSvgId: 'policies',
      url: '/returns-portal/policies',
      permissions: ['CmPolicies'],
    },
    {
      title: 'RA management',
      description: 'RA management',
      iconSvgId: 'ra_management',
      url: '/returns-portal/ra-requests',
      permissions: ['RARequests'],
    },
    {
      title: 'Shipments',
      description: 'Shipments',
      iconSvgId: 'shipments',
      url: '/returns-portal/inbound-shipments',
      permissions: ['CmShipments'],
    },
    {
      title: 'Credit statements',
      description: 'Credit statements',
      iconSvgId: 'credit_management',
      url: '/returns-portal/credit-management',
      permissions: ['CmCreditReports'],
    },
    {
      title: 'Units',
      description: 'Units',
      iconSvgId: 'units',
      url: '/contracts/RTV-Units',
      permissions: ['RtvUnits'],
    },
    {
      title: 'Settings',
      description: 'Settings',
      iconSvgId: 'settings',
      url: '/returns-portal/settings',
      permissions: ['RMPSettings'],
    },
    {
      title: 'Reports',
      description: 'Reports',
      iconSvgId: 'reports',
      permissions: ['BIReportsinRMP'],
      url: '/returns-portal/reports-dashboards',
    },
  ].map((item, index) => ({
    ...item,
    // ids are required for wireframe to process menu items
    id: index.toString(),
    // RMP_LINK is set in gitlab and differs depending on environment
    url: `${rmpBaseUrl}${item.url}`,
  }))
}
