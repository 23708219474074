// @flow

import type { Arguments, Return } from '../../types'

/**
 * @description Higher order function that safely handles the error and returns defaultValue if the error has occurred.
 * @param {Function} fn - function that is thrown some error ("throw new Error").
 * @param {*} defaultValue - value that will be returned if the error has occurred.
 * @return {Function} - same function fn, but all errors are caught. Fn now returns defaultValue if the error has occurred.
 */
export const suppressThrownError =
  <Fn: (...any[]) => any, DefaultValue>(
    fn: Fn,
    defaultValue: DefaultValue,
  ): ((...args: Arguments<Fn>) => Return<Fn> | DefaultValue) =>
  (...args) => {
    try {
      return fn(...args)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return defaultValue
    }
  }
