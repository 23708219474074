import React from 'react'
import { connect } from 'react-redux'
import { FormField, Flex } from '@r1/ui-kit'
import { InputType, FontSizes, BlockType, Ids } from '../../constants'
import { changeBlockProperty } from '../../actions'
import Container from './Container'
import Input from './Input'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    if (block.type === BlockType.TEXT_BLOCK) {
      return {
        blockId: block.id,
        fontSize: block.fontSize,
        overflowText: block.overflowText,
      }
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onFontSizeChange: (blockId, oldFontSize, newFontSize) =>
    dispatch(changeBlockProperty(blockId, ['fontSize'], oldFontSize, newFontSize)),
  onOverflowTextChange: (blockId, oldFontSize, newFontSize) =>
    dispatch(changeBlockProperty(blockId, ['overflowText'], oldFontSize, newFontSize)),
})

export const TextBlockPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ blockId, fontSize, overflowText, onFontSizeChange, onOverflowTextChange, ...props }) => {
  if (typeof blockId === 'undefined') {
    return null
  }

  return (
    <Container {...props}>
      <Flex>
        <FormField.Label>Font size</FormField.Label>
        <Input
          type={InputType.SELECT}
          data={fontSize}
          options={FontSizes.map(fs => ({
            id: fs,
            name: `${fs} pt`,
          }))}
          onChange={newValue => onFontSizeChange(blockId, fontSize, newValue)}
        />
      </Flex>
      <Flex>
        <FormField.Label>Overflow text</FormField.Label>
        <Input
          type={InputType.TEXT}
          data={overflowText}
          onChange={newValue => onOverflowTextChange(blockId, overflowText, newValue)}
        />
      </Flex>
    </Container>
  )
})
/*
TextBlockPanel.propTypes = {
  blockId: PropTypes.number,
  fontSize: PropTypes.number,
  overflowText: PropTypes.string,
  onFontSizeChange: PropTypes.func.isRequired,
  onOverflowTextChange: PropTypes.func.isRequired,
} */
