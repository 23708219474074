import * as React from 'react'
import styled from '@emotion/styled'
import { SortableElement } from 'react-sortable-hoc'
import { compose, defaultProps, withState, withHandlers, withProps } from 'recompose'
import { has } from 'ramda'
import { withTheme, Button, Flex, Icon } from '@r1/ui-kit'

const Overlay = styled('div')`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: ${({ size }) => (size ? `${size[0]}px` : '170px')};
  height: ${({ size }) => (size ? `${size[0]}px` : '170px')};
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.68);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
    transition: opacity ease 0.2s;
  }
`

const Wrapper = styled('div')`
  position: relative;
  display: inline-block;
  width: ${({ size }) => (size ? `${size[0]}px` : '170px')};
  height: ${({ size }) => (size ? `${size[0]}px` : '170px')};
  margin: 16px 0 0 16px;
  user-select: none;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`

const Image = styled('img')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const Active = styled('div')`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: ${({ active, theme }) => (active ? `6px solid ${theme.palette.blue[500]}` : 'none')};
`

const IconWrap = styled('div')`
  color: ${({ theme }) => theme.palette.white};
  transition-duration: 0.15s;
  transition-property: opacity, color;
  transition-timing-function: ease;
  opacity: 0.8;
  &:hover {
    color: ${({ theme }) => theme.palette.red[300]};
    opacity: 1;
  }
`

const ImageSize = styled('div')`
  width: 100%;
  bottom: -20px;
  text-align: center;
  position: absolute;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.palette.grey[600]};
`

const enhance = compose(
  SortableElement,
  defaultProps({
    buttons: [],
  }),
  withTheme,
  withState('imgSizes', 'setImgSizes', {}),
  withHandlers({
    onLoadImg:
      ({ setImgSizes, url, width, height }) =>
      ({ target }) => {
        setImgSizes({
          width: url ? width : target.naturalWidth,
          height: url ? height : target.naturalHeight,
        })
      },
  }),
  withProps(({ url, src }) => ({
    imageSrc: url ? `/uisvc/thumbor/unsafe/164x164/smart/${encodeURIComponent(url)}` : src,
  })),
)

const ImageWrapper = ({ buttons, id, size, active, imgSizes, imageSrc, onLoadImg }) => (
  <Wrapper size={size}>
    <Image src={imageSrc} alt={id} onLoad={onLoadImg} />
    <Active active={active} />
    <Overlay size={size}>
      <Flex column spaceBetween="S">
        <Flex align="middle" justify="center">
          <Flex spaceBetween="XS">
            {buttons.filter(has('icon')).map(({ icon, func }) => (
              <IconWrap key={icon} onClick={() => func(id)}>
                <Icon type={icon} color="currentColor" size="S" />
              </IconWrap>
            ))}
          </Flex>
        </Flex>
        {buttons.filter(has('name')).map(({ name, func, red }) => (
          <Button key={name} color={red ? 'danger' : 'primary'} size="S" onClick={() => func(id)}>
            {name}
          </Button>
        ))}
      </Flex>
    </Overlay>
    <ImageSize>
      {imgSizes.width}px&nbsp;×&nbsp;{imgSizes.height}px
    </ImageSize>
  </Wrapper>
)

export const ImageWrapperEnhanced = enhance(ImageWrapper)
