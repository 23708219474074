import * as React from 'react'
import * as R from 'ramda'
import { Control, useController } from 'react-hook-form'
import {
  Period as PeriodType,
  ShippingCutOffTime as ShippingCutOffTimeType,
} from '@r1-webui/facilities-v1'
import { Box, Button, Flex, FormField, Icon, Select, Text } from '@r1/ui-kit'
import { ProfileType } from '../../../../../api/legacyEspm'
import { Value } from '../../Value'
import { Period } from '../Period'

export const ShippingCutOffTime = ({
  shippingCutOffTime,
  profiles,
  control,
  editable,
  indexPrefix,
  onRemoveShift,
}: {
  shippingCutOffTime: ShippingCutOffTimeType
  profiles: ProfileType[]
  control: Control
  editable?: boolean
  indexPrefix: number
  onRemoveShift: () => void
}) => {
  const formPrefix = 'shippingCutOffTimes'
  const {
    field: { ref: _ref, ...carrierId },
    formState: { errors },
  } = useController({
    name: `${formPrefix}.${indexPrefix}.carrierId`,
    control,
    defaultValue: shippingCutOffTime.carrierId,
  })
  const { field: periodsField } = useController({
    name: `${formPrefix}.${indexPrefix}.periods`,
    control,
    defaultValue: shippingCutOffTime.periods,
  })

  const periodsValue = periodsField.value as PeriodType[]

  const onAddPeriod = React.useCallback(() => {
    const newPeriod = {
      startDay: '',
      from: '09:00 AM',
      finishDay: '',
      to: '09:00 PM',
    }
    const updatedPeriods = [...periodsValue, newPeriod]
    periodsField.onChange(updatedPeriods)
  }, [periodsField, periodsValue])

  const onRemovePeriod = React.useCallback(
    (index: number) => () => {
      const updatedPeriods = periodsValue.filter((_, idx) => idx !== index)
      periodsField.onChange(updatedPeriods)
    },
    [periodsField, periodsValue],
  )

  const dataTestIdPrefix = `facility__cut-off-${indexPrefix}`

  return (
    <Flex column>
      <Flex spaceBetween="XS" align="center">
        <FormField>
          <FormField.Label>Carrier Name</FormField.Label>
          {editable ? (
            <Select
              simpleValue
              {...carrierId}
              options={profiles}
              data-test-id={`${dataTestIdPrefix}__carrier`}
            />
          ) : (
            <Value>{shippingCutOffTime.carrierId}</Value>
          )}
          <FormField.Error>
            {R.pathOr(null, [formPrefix, indexPrefix, 'carrierId', 'message'], errors)}
          </FormField.Error>
        </FormField>
        {editable && (
          <Box mt="XS">
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__remove`}
              onClick={onRemoveShift}
            >
              <Icon type="clear" size="S" />
            </Button>
          </Box>
        )}
      </Flex>
      <Flex column>
        {editable && periodsValue.length === 0 && (
          <Flex align="center" justify="flex-end">
            <Box>
              <Text>No periods were set for the cut-off time. Tap + to add.</Text>{' '}
            </Box>
            <Box ml="XS">
              <Button
                transparent
                shape="square"
                color="secondary"
                data-test-id={`${dataTestIdPrefix}__add-period`}
                onClick={onAddPeriod}
              >
                <Icon type="plus" size="S" />
              </Button>
            </Box>
          </Flex>
        )}
        {periodsValue.map((period, idx) => (
          <Period
            key={`${period.from}-${period.to}-${idx + 1}`}
            period={period}
            control={control}
            editable={editable}
            indexPrefix={idx}
            formPrefix={`${formPrefix}.${indexPrefix}.periods`}
            dataTestIdPrefix={dataTestIdPrefix}
            periodStartLabel="All Orders Created Before"
            periodFinishLabel="Must Ship Before"
            periodFromLabel="Time"
            periodToLabel="Time"
            onAddPeriod={onAddPeriod}
            onRemovePeriod={onRemovePeriod(idx)}
          />
        ))}
      </Flex>
    </Flex>
  )
}
