import { FormValues } from '../types'

export const collapsePanelsIds = [
  'Overview',
  'Shipping Options',
  'Rule Constraints',
  'Contact Information',
]

export const INITIAL_VALUE: FormValues = {
  name: '',
  active: true,
  description: '',
  priority: {
    type: null,
    ruleId: '',
  },
  shippingOptions: [
    {
      shippingProfileId: '',
      thirdPartyShippingProfileId: undefined,
      shippingServices: {
        type: 'ExcludeShippingServices',
        shippingServices: [],
      },
    },
  ],
  ruleConstraints: {
    marketplaceProfilesIds: [],
    programsIds: [],
    weightRange: {
      measure: 'Lb',
      from: undefined,
      to: undefined,
    },
    dimensionalWeightRange: {
      measure: 'Lb',
      from: undefined,
      to: undefined,
    },
    salePriceRange: {
      currency: 'USD',
      from: undefined,
      to: undefined,
    },
    maximumShippingPrice: {
      currency: 'USD',
      // @ts-expect-error
      amount: null,
    },
    maximumShippingPricePercent: undefined,
  },
  contactInformation: {
    companyName: '',
    contactName: '',
    phoneNumber: '',
  },
}
