// @flow

import React from 'react'
import { useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'

import { browserHistory } from '../../../routes/browserHistory'

import { CreateProfileScreen } from '../components/CreateProfileScreen'
import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

const CreateProfile = () => {
  const { profileTypeId, purposeId } = useParams()

  const onCreationComplete = (step = {}) => {
    const { informationPopupMarkdown } = step

    if (informationPopupMarkdown && informationPopupMarkdown.indexOf('saved') > -1) {
      browserHistory.goBack()
    }
  }

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowManageProfilesEdit]}>
      <CreateProfileScreen
        profileTypeId={profileTypeId}
        purposeId={purposeId}
        httpClient={httpClient}
        onCreationComplete={onCreationComplete}
        onError={errorHandler}
      />
    </PageAccessControl>
  )
}

export { CreateProfile }
