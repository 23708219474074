import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import {
  PickupReservation,
  PickupDescription as ReservationPickupDescription,
} from '@r1-webui/facilityreservationscheduler-v1'
import { addYears, formatISO, startOfDay, endOfDay } from 'date-fns'
import { ReservationPayload } from '../routes/SchedulePickup/types'
import { reservationApi } from '../../../api/schedulePickup'
import { replaceTemplateValues } from '../../../utils/responseUtils'
import { UpdatePickupTimeParams } from './schedulePickupService'

export const getReservations = async (
  warehouseLocationId: string,
): Promise<PickupReservation[]> => {
  const startOfToday = startOfDay(new Date())
  const endofToday = endOfDay(new Date())
  const startTime = formatISO(addYears(startOfToday, -1))
  const endTime = formatISO(addYears(endofToday, 1))

  const res = await reservationApi.getReservations({
    warehouseLocationId,
    startTime,
    endTime,
  })

  if (res.status !== 200) {
    handleServerError(res)
    return []
  }

  return res.body
}

export const createReservation = async (
  warehouseLocationId: string,
  reservation: ReservationPayload,
): Promise<string | null> => {
  const res = await reservationApi.createPickupReservation({
    warehouseLocationId,
    notes: reservation.notes || '',
    pickupDate: reservation.pickupDate,
    period: {
      start: reservation.period.start,
      end: reservation.period.end,
    },
  })

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to create reservation',
    })
    return null
  }

  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Reservation has been created',
  })

  return res.body
}

export const updateReservationTime = async (
  reservationId: string,
  args: UpdatePickupTimeParams,
): Promise<boolean> => {
  const res = await reservationApi.changeReservationTime(
    { reservationId },
    {
      pickupDate: args.pickupDate,
      period: {
        start: args.period.start,
        end: args.period.end,
      },
    },
  )

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to update reservation time',
    })
    return false
  }

  if (res.status !== 200) {
    handleServerError(res)
    return false
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Reservation time has been updated',
  })
  return true
}

export const deleteReservation = async (reservationId: string): Promise<boolean> => {
  const res = await reservationApi.deleteReservation({
    reservationId,
  })

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to delete reservation',
    })
    return false
  }

  if (res.status !== 200) {
    handleServerError(res)
    return false
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Reservation has been deleted',
  })
  return true
}

export const bindReservation = async (
  reservationId: string,
  orderId: string,
): Promise<ReservationPickupDescription | null> => {
  const res = await reservationApi.bindReservationWithOrder(
    {
      reservationId,
    },
    { orderId },
  )

  if (res.status === 403) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'You have no permission to link reservation with order',
    })
    return null
  }

  if (res.status === 422) {
    NotificationSystem.addNotification({
      level: 'error',
      title: replaceTemplateValues(res.body),
    })
    return null
  }

  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Order has been linked with reservation',
  })

  return res.body
}
