// @flow

import * as R from 'ramda'

export const toObject = <
  I: Object,
  A: $ReadOnlyArray<I>,
  K: void | $Keys<I> | Array<I>,
  V: void | $Keys<I>,
>(
  array: A,
  key: K,
  value: V,
): { [string]: I } =>
  array.reduce((acc, item) => {
    const id = Array.isArray(key) ? R.path(key, item) : item[key || 'id']
    acc[id] = value ? item[value] : item
    return acc
  }, {})
