// @flow

import type { AttributeType } from '../../../types/public/attributeDefinitionsCommon'

export function attributeCanHaveEnumValues(attributeType: AttributeType): %checks {
  return (
    attributeType === 'IntEnumeration' ||
    attributeType === 'DecimalEnumeration' ||
    attributeType === 'StringEnumeration'
  )
}
