// @flow

import type { WithControllerState } from './types'

export const defaultState: WithControllerState = {
  screen: 'loading',
  sidebarIsVisible: false,
  refundIsSubmitting: false,
  labelIsGenerated: false,
  withReturn: false,
  data: null,
  messageToSend: '',
  user: null,
  activeTabId: 'tabReturns',
  cancellationReason: '',
  cancellationReasonError: true,
  cancellationInProgress: false,
  hasUnreadChatMessages: false,
}
