// @flow

const getIdentifiers = (values: any) => {
  const identifiers = []
  const { primary, additional } = values

  identifiers.push({ value: primary.value, type: primary.type.id, isPrimary: true })

  /* for (const {
    value,
    type: { id: type },
  } of additional) {
    identifiers.push({ value, type, isPrimary: false })
  } */

  additional.forEach(({ value, type: { id: type } }) => {
    identifiers.push({ value, type, isPrimary: false })
  })

  return identifiers
}

const setIdentifiers = (values: any) => {
  return values.map(({ value, type: typeId, isPrimary }) => ({
    identifier: {
      value,
      typeId,
    },
    isPrimary,
  }))
}

export const schema = {
  Identifiers: {
    value: {
      errorValue: 'value',
      getPath: ['identifiers'],
      getTransform: getIdentifiers,
      setPath: ['identifiers'],
      setTransform: setIdentifiers,
    },
  },
}
