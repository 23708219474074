// @flow

import * as React from 'react'
import { Modal, Select, NotificationSystem, FormField, Flex } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { useState, useCallback, useMemo } from 'react'
import type { Facility } from '@r1-webui/rmavendorportal-v1/src/types'
import { createApi } from '../../api/api'
import { httpClient } from '../../../../httpClient'

declare type ClaimFacilityProps = {|
  claimId: string,
  show: boolean,
  title: string,
  onClose: () => void,
  setCustomFacilityId: (id: string) => void,
  customFacilityId: string,
  facilities: Facility[],
|}

export const ClaimFacilityModal = (props: ClaimFacilityProps) => {
  const { claimId, show, title, onClose, setCustomFacilityId, customFacilityId, facilities } = props

  const [selectedFacilityId, setSelectedFacilityId] = useState(customFacilityId)

  const [isSaveFacilityIdExecuting, setIsSaveFacilityIdExecuting] = useState(false)

  const api = useMemo(() => createApi(httpClient), [httpClient])

  const updateFacility = useCallback(async () => {
    setIsSaveFacilityIdExecuting(true)
    const resp = await api.claims.updateCustomRmaFacilityId(
      { claimId },
      { customRmaFacilityId: selectedFacilityId },
    )
    if (resp.status === 200) {
      NotificationSystem.addNotification({
        title: 'Facility was successfuly saved',
        level: 'success',
      })
      setCustomFacilityId(selectedFacilityId)
      setIsSaveFacilityIdExecuting(false)
      onClose()
    } else {
      setIsSaveFacilityIdExecuting(false)
      NotificationSystem.addNotification({
        title: 'Cannot set facility for claim. Please, try again later',
        level: 'error',
      })
    }
  }, [api.claims, claimId, selectedFacilityId, setCustomFacilityId, onClose])

  const modalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'Save',
        onClick: updateFacility,
        align: 'right',
        loading: isSaveFacilityIdExecuting,
      },
      {
        title: 'Close',
        onClick: onClose,
        align: 'right',
        disabled: isSaveFacilityIdExecuting,
      },
    ],
    [onClose, updateFacility, isSaveFacilityIdExecuting],
  )

  return (
    <Modal
      isControlled
      title={title}
      size="XS"
      show={show}
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      <Flex spaceBetween="XXS" justify="space-between" align="center">
        <FormField.Label>Facility</FormField.Label>
        <Select
          width={250}
          options={facilities}
          valueKey="id"
          labelKey="title"
          placeholder="Select facility"
          value={selectedFacilityId}
          onChange={id => setSelectedFacilityId(id)}
        />
      </Flex>
    </Modal>
  )
}
