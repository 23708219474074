import * as React from 'react'
import { propOr, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { compose, mapProps } from 'recompose'
import { ContentHeader } from '@r1/wireframe-primary'
import { CardHeader } from '../../../components/CardHeader'
import { selectors as productsSelectors } from '../../../modules/products'
import { selectors as productTemplatesSelectors } from '../../../modules/productTemplates'
import { selectors as imagesSelectors } from '../../../modules/productTemplates/images'
import { historyApi } from '../../../api/productHistory/history.ts'
import HeaderContent from './HeaderContent'
import TopContent from './TopContent'

const mapStateToProps = (state, { productId }) => {
  const product = productsSelectors.getItem(state, { id: productId })
  const productTemplateId = product && product.productTemplateId
  return {
    title: propOr('', 'title', product),
    productTemplate: productTemplatesSelectors.getItem(state, { id: productTemplateId }),
    images: imagesSelectors.getCollectionItems(state, { id: productTemplateId }),
  }
}

const enhance = compose(
  connect(mapStateToProps),
  mapProps(({ productId, title, productTemplate, images }) => ({
    manufacturer: pathOr('', ['productInfo', 'manufacturer', 'name'], productTemplate),
    model: pathOr('', ['productInfo', 'model', 'name'], productTemplate),
    categoryId: pathOr('', ['productInfo', 'categoryId'], productTemplate),
    title,
    productId,
    listingImages: images ? images.filter(image => image.type === 'Listing') : [],
    referenceImages: images ? images.filter(image => image.type === 'Reference') : [],
  })),
)

const Header = ({
  title,
  productId,
  categoryId,
  model,
  manufacturer,
  listingImages,
  referenceImages,
}) => {
  const imageUrl = listingImages.length > 0 ? listingImages[0] : referenceImages[0] || { url: '' }

  const fetchData = React.useCallback(async () => {
    const data = await historyApi.getProductHistory(productId)
    return data
  }, [productId])

  return (
    <CardHeader
      title={title}
      topContent={<TopContent model={model} manufacturer={manufacturer} />}
      imageUrl={imageUrl.url}
      historyDialogTitle="Product History"
      fetchData={fetchData}
      dataTestId="modal-product-history"
    >
      <ContentHeader.Title browserTabTitle={title} />
      <HeaderContent productId={productId} categoryId={categoryId} />
    </CardHeader>
  )
}

export default enhance(Header)
