// @flow

import * as React from 'react'
import { NumericInput, FormField } from '@r1/ui-kit'
import { useCallback, useState } from 'react'

type Props = {
  onQuantityChange: (val: number) => void,
  defaultValue: number,
  maxValue: number,
}

export const QuantityEditor = (props: Props) => {
  const { onQuantityChange, defaultValue, maxValue } = props
  const [value, setValue] = useState(defaultValue)

  const onChange = useCallback(
    (val: string | null) => {
      const newVal = parseInt(val, 10)
      setValue(newVal)
      onQuantityChange(newVal)
    },
    [onQuantityChange],
  )

  return (
    <FormField>
      <FormField.Label>Quantity:</FormField.Label>
      <NumericInput
        data-test-id="quantity"
        value={value}
        min={1}
        max={maxValue}
        maxFractionDigits={0}
        onChange={onChange}
      />
    </FormField>
  )
}
