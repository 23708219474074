// @flow

import * as React from 'react'
import { memo, useCallback, useState, useMemo } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Modal, NotificationSystem } from '@r1/ui-kit'
import type { DeleteRule as DeleteRuleType } from '@r1-webui/productprices-companypriceconsolidationrulemanagement-v1/src/ruleManagement'
import type { RuleInfo } from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/types'
import { processResponse } from '../../utils/webUi'

type DeleteRuleProps = {|
  ruleInfo: RuleInfo,
  deleteRuleApiCall: DeleteRuleType,
  onDeleteSuccess?: () => Promise<void>,
  children: (props: { onOpen: () => void }) => React$Node,
|}

export const DeleteRule = memo<DeleteRuleProps>((props: DeleteRuleProps) => {
  const {
    ruleInfo: { id: ruleId, name, version = '' },
    onDeleteSuccess,
    deleteRuleApiCall,
    children,
  } = props

  const [showError, setShowError] = useState(false)

  const onDeleteConfirmClick = useCallback(() => {
    deleteRuleApiCall({ ruleId }, { version })
      .then(processResponse)
      .then(response => {
        switch (response.status) {
          case 200:
            NotificationSystem.addNotification({
              level: 'success',
              title: `Company Price «${name}» has been deleted`,
            })
            if (onDeleteSuccess) onDeleteSuccess()
            break
          case 409:
            setShowError(true)
            break
          default:
            NotificationSystem.addNotification({ message: response.status.toString() })
        }
      })
  }, [deleteRuleApiCall, name, onDeleteSuccess, ruleId, version])

  const modalConfirmActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        {
          title: 'Delete',
          color: 'danger',
          transparent: false,
          onClick: () => {
            onDeleteConfirmClick()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onDeleteConfirmClick],
  )

  const onCloseModal = useCallback(() => setShowError(false), [])

  const deleteFailedModalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'Close',
        color: 'secondary',
        onClick: onCloseModal,
        align: 'right',
      },
    ],
    [onCloseModal],
  )

  return (
    <>
      <Modal
        isControlled={false}
        title={`Delete Company Price «${name}»?`}
        buttonElement={children}
        actionButtons={modalConfirmActionButtons}
      >
        After confirmed all information will be deleted
      </Modal>

      <Modal
        isControlled
        show={showError}
        title="Delete Failed"
        actionButtons={deleteFailedModalActionButtons}
        onEscapeKeyDown={onCloseModal}
      >
        Company Price that you want to delete has already use in the system. Before you can delete
        this company price, you must unlink it throughout the system.
      </Modal>
    </>
  )
})
