import { Program } from '@r1-webui/usermanagement-v1'
import { useEffect, useState } from 'react'
import { getMarketplaces, Profile } from '../../../services/espmService'
import { getPrograms } from '../../../services/programsService'
import { DrawerType } from '../types'

export const useRuleConstraints = (type: DrawerType | null) => {
  const [marketplaces, setMarketplaces] = useState<Profile[]>([])
  const [areMarketplacesLoading, setMarketplacesLoading] = useState<boolean>(false)
  const [programs, setPrograms] = useState<Program[]>([])
  const [areProgramsLoading, setProgramsLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchMarketPlaces = async () => {
      setMarketplacesLoading(true)
      setMarketplaces(await getMarketplaces())
      setMarketplacesLoading(false)
    }
    if (type) fetchMarketPlaces()
  }, [type])

  useEffect(() => {
    const fetchPrograms = async () => {
      setProgramsLoading(true)
      setPrograms(await getPrograms())
      setProgramsLoading(false)
    }
    if (type) fetchPrograms()
  }, [type])

  return { marketplaces, programs, areMarketplacesLoading, areProgramsLoading }
}
