import * as React from 'react'

import { H4, Flex, Box, FormField, Input, HR } from '@r1/ui-kit'

import type { ContactUserInfoProps } from '../../types/ContactUserInfo'

import { TextReadOnly, LabelReadOnly } from '../common'

const EditableView = (props: ContactUserInfoProps) => {
  const { disabled, phone, phoneExtension, email } = props

  return (
    <Flex column minWidth={408} maxWidth={408}>
      <H4>Contact Info</H4>
      <Box>
        <FormField>
          <FormField.Label>Email</FormField.Label>
          <Input
            data-test-id="um-user-email-input"
            value={email.value || ''}
            error={!!email.error}
            onChange={email.onChange}
          />
          <FormField.Error>{email.error}</FormField.Error>
        </FormField>
      </Box>
      <Flex>
        <Box minWidth={205} maxWidth={205}>
          <FormField>
            <FormField.Label>Phone</FormField.Label>
            <Input
              data-test-id="um-user-phone-input"
              disabled={disabled}
              value={phone.value || ''}
              error={!!phone.error}
              onChange={phone.onChange}
            />
            <FormField.Error>{phone.error}</FormField.Error>
          </FormField>
        </Box>
        <Box ml="XL" minWidth={85} maxWidth={85}>
          <FormField>
            <FormField.Label>Extension</FormField.Label>
            <Input
              data-test-id="um-user-phone-extension-input"
              disabled={disabled}
              value={phoneExtension.value || ''}
              error={!!phoneExtension.error}
              onChange={phoneExtension.onChange}
            />
            <FormField.Error>{phoneExtension.error}</FormField.Error>
          </FormField>
        </Box>
      </Flex>
    </Flex>
  )
}

const combinePhoneAndExtension = (phone?: string, extension?: string) => {
  if (!phone) return ''
  const extensionFormatted = extension ? ` x ${extension}` : ''
  return phone + extensionFormatted
}

const ReadOnlyView = (props: ContactUserInfoProps) => {
  const { phone, phoneExtension, email } = props
  const combinedPhone = combinePhoneAndExtension(phone.value, phoneExtension.value)

  return (
    <Flex column minWidth={408} maxWidth={408}>
      <LabelReadOnly>Contact Info</LabelReadOnly>
      <Flex column>
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Email</FormField.Label>
          <TextReadOnly data-test-id="email">{email.value}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column>
        <HR />
        <Flex align="center" justify="space-between">
          <FormField.Label>Phone</FormField.Label>
          <TextReadOnly data-test-id="phone">{combinedPhone}</TextReadOnly>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const ContactUserInfo = (props: ContactUserInfoProps) => {
  const View = props.editable ? EditableView : ReadOnlyView

  return <View {...props} />
}
