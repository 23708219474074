// @flow

import { Flex, Format } from '@r1/ui-kit'
import React from 'react'

import type { ClaimInfoProps } from '../../types/ClaimCreation'
import { ClaimTotal, InfoBlockTitle, Property, Value } from './StyledComponents'

export const ClaimInfo = (props: ClaimInfoProps) => {
  const { claimTotal } = props

  return (
    <ClaimTotal align="flex-end" ml="S">
      <Flex column>
        <InfoBlockTitle>Total Claim</InfoBlockTitle>
        <Flex column>
          <Property>Amount:</Property>
          <Value>
            <Format.Money value={claimTotal.amount} />
          </Value>
        </Flex>
        <Flex column mt="M">
          <Property>Quantity:</Property>
          <Value>{claimTotal.quantity}</Value>
        </Flex>
      </Flex>
    </ClaimTotal>
  )
}
