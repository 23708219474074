// @flow

import * as React from 'react'

import { Main, Content, ContentHeader } from '@r1/wireframe-primary'

import type { PurposeListScreenProps } from '../../types/PurposeListScreen'
import { Loader } from '../../../../components/Loader'
import { PurposeCard } from './PurposeCard'
import { getInitialData } from './helpers'

type PurposeListScreenState = {|
  purposes: Array<$AnyObject>,
  profileTypes: $AnyObject,
  isLoading: boolean,
|}

const HR = _ => <div style={{ borderBottom: 'dashed 2px #dedede', marginTop: '24px' }} />

export class PurposeListScreen extends React.Component<
  PurposeListScreenProps,
  PurposeListScreenState,
> {
  state = {
    purposes: [],
    profileTypes: {},
    isLoading: false,
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    getInitialData(this.props.httpClient, () => {}).then(({ purposes, profileTypes }) =>
      this.setState({ purposes, profileTypes, isLoading: false }),
    )
  }

  render() {
    const { getPurposeUrl, getProfileCreationUrl } = this.props
    const { purposes, profileTypes, isLoading } = this.state
    return (
      <Main>
        <ContentHeader>
          <ContentHeader.Title />
        </ContentHeader>
        {isLoading ? (
          <Loader />
        ) : (
          <Content spaceBetween="XL">
            {purposes.map(({ name, informationMarkdown, id, image: { value: icon } }) => (
              <div key={id}>
                <PurposeCard
                  id={id}
                  name={name}
                  icon={icon}
                  text={informationMarkdown}
                  url={getPurposeUrl(id)}
                  getProfileCreationUrl={getProfileCreationUrl}
                  profileTypesData={profileTypes[id]}
                />
                <HR />
              </div>
            ))}
          </Content>
        )}
      </Main>
    )
  }
}
