import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateDictionariesLegacyApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, selectors, actions } = createCollectionModule({
  name: '@dictionaries/countries',
  keyField: 'id',
  fetchAllFunc: (_, _api) =>
    productTemplateDictionariesLegacyApi.getAvailableCountries().then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { selectors, actions }
