// @flow

const attributeTypeMapping = {
  Boolean: 'Boolean',
  Date: 'Date',
  DateRange: 'Date range',
  Decimal: 'Decimal number',
  DecimalEnumeration: 'Enumeration of decimal numbers',
  DecimalRange: 'Decimal number range',
  IntEnumeration: 'Enumeration of integer numbers',
  IntRange: 'Range of integer numbers',
  Integer: 'Integer number',
  String: 'Free text',
  StringEnumeration: 'Enumeration of free text',
}

export const attributeTypeMapper = (
  type: $Keys<typeof attributeTypeMapping>,
): $Values<typeof attributeTypeMapping> => {
  return attributeTypeMapping[type] ? attributeTypeMapping[type] : type
}

export const getAttributeDefinitionType = (
  type: 'ProductAttributeDefinition' | 'UnitAttributeDefinition',
): 'Product' | 'Unit' => {
  // Flow can't do this with constants
  switch (type) {
    case 'ProductAttributeDefinition':
      return 'Product'
    case 'UnitAttributeDefinition':
      return 'Unit'
    default:
      // eslint-disable-next-line no-unused-expressions
      ;(type: empty)
  }
  return type
}
