// @flow

import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

export const Option = styled('div')`
  display: flex;
  margin-bottom: 10px;
  padding: ${({ theme }) => theme.space.S} 0;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  align-items: center;
  z-index: 1002;

  &:last-child {
    margin-bottom: 0;
  }
`

export const Sortable = styled('span')`
  display: inherit;
`

export const Priority = styled('span')`
  margin-right: ${({ theme }) => theme.space.S};
  color: ${({ theme }) => theme.palette.grey[500]};
`

export const DeleteBlock = styled('div')`
  margin: 0;
`

export const Footer = styled(Flex)`
  border-top: solid ${({ theme }) => theme.palette.grey[300]} 1px;
  background-color: ${({ theme }) => theme.palette.grey[100]};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`

export const RulesContainer = styled(Flex)`
  margin-bottom: 140px;
`
