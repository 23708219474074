import React from 'react'
import { DisplaySettings, Table } from '@r1/grid'
import { handleServerError } from '@r1/core-blocks'
import { DepositEvent } from '@r1-webui/gowholesale-depositmanagement-v1'
import { depositManagementApi } from '../../api'
import { DepositHistoryGridProps } from './types'
import { getGridDisplaySettings } from './utils'

export const DepositHistoryGrid = ({ deposit }: DepositHistoryGridProps) => {
  const [data, setData] = React.useState<DepositEvent[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const fetchData = React.useCallback(async () => {
    setIsLoading(true)

    const response = await depositManagementApi.getHistory({
      depositId: deposit.entityId,
      depositType: deposit.type,
    })

    if (response.status === 200) {
      setData(response.body)
    } else {
      handleServerError(response)
      setData([])
    }

    setIsLoading(false)
  }, [deposit.entityId, deposit.type])

  React.useEffect(() => {
    fetchData()
  }, [fetchData])

  const displaySettings = React.useMemo<DisplaySettings<DepositEvent>>(
    () => getGridDisplaySettings(),
    [],
  )

  return <Table loading={isLoading} data={data} displaySettings={displaySettings} />
}
