let dispatchDialog = null

export function setDialogDispatch(dialogDispatchFunc) {
  dispatchDialog = dialogDispatchFunc
}

export function confirm(text) {
  return new Promise(resolve => {
    dispatchDialog({
      type: 'confirm',
      text,
      resolve,
    })
  })
}
