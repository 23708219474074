import * as z from 'zod'

const isValid = (value: unknown) => Boolean(value)

const infoFieldSchema = z.object({
  key: z.string(),
  value: z.string(),
})

const filterSchema = z.object({
  filterId: z.string(),
})

const subStampValueSchema = z.object({
  id: z.string().min(1, { message: 'Select SubStamp value' }),
  filterGroups: z.array(filterSchema).nonempty({ message: 'Set up filters for substamp value' }),
  notRecalculateValueIds: z.array(z.string()).optional(),
})

const subStampSchema = z.object({
  id: z.string().min(1, { message: 'Select SubStamp' }),
  values: z.array(subStampValueSchema).nonempty({ message: 'Set up values for substamp' }),
})

export const schema = z.object({
  name: z.string().refine(isValid, 'Please fill rule name'),
  inventoryType: z.string(),
  parentProgramName: z.string().refine(isValid, 'Please fill parent program name'),
  clientProgramName: z.string().refine(isValid, 'Please fill client program name'),
  isActive: z.boolean(),
  infoFields: z.array(infoFieldSchema),
  filterGroups: z.array(filterSchema),
  subStamps: z.array(subStampSchema).optional(),
})
