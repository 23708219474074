// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Box, ControlGroup, Flex, FormField, H3 } from '@r1/ui-kit'
import type { DimensionsFormEditProps } from '../../../../types/product.type'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const MAX_NUMBER = 2147483647
const MAX_ALLOWED_VALUE = MAX_NUMBER

export const Dimensions = ({
  values,
  errors,
  handleChange,
  handleBlur,
  dimensionMeasuresList,
  weightMeasuresList,
  disabled,
}: DimensionsFormEditProps) => {
  return (
    <>
      <Box>
        <StyledH3>Dimensions</StyledH3>
      </Box>
      <Box>
        <Flex column>
          <Box>
            <FormField>
              <FormField.Label>Shipping Dimensions</FormField.Label>
              <ControlGroup disabled={disabled}>
                <ControlGroup.Label>L</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.PackageDimensionsField.lengthField}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['PackageDimensionsField', 'lengthField'])}
                  onChange={handleChange(['PackageDimensionsField', 'lengthField'])}
                />
                <ControlGroup.Label>W</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.PackageDimensionsField.width}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['PackageDimensionsField', 'width'])}
                  onChange={handleChange(['PackageDimensionsField', 'width'])}
                />
                <ControlGroup.Label>H</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.PackageDimensionsField.height}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['PackageDimensionsField', 'height'])}
                  onChange={handleChange(['PackageDimensionsField', 'height'])}
                />
                <ControlGroup.Dropdown
                  options={dimensionMeasuresList}
                  width={100}
                  value={values.PackageDimensionsField.measure}
                  onChange={(handleChange: any)(['PackageDimensionsField', 'measure'])}
                />
              </ControlGroup>
              <FormField.Error>{errors.PackageDimensionsField}</FormField.Error>
            </FormField>
          </Box>

          <Box>
            <FormField>
              <FormField.Label>Product Dimensions</FormField.Label>
              <ControlGroup disabled={disabled}>
                <ControlGroup.Label>L</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.ProductDimensionsField.lengthField}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['ProductDimensionsField', 'lengthField'])}
                  onChange={handleChange(['ProductDimensionsField', 'lengthField'])}
                />
                <ControlGroup.Label>W</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.ProductDimensionsField.width}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['ProductDimensionsField', 'width'])}
                  onChange={handleChange(['ProductDimensionsField', 'width'])}
                />
                <ControlGroup.Label>H</ControlGroup.Label>
                <ControlGroup.NumericInput
                  width={100}
                  value={values.ProductDimensionsField.height}
                  maxFractionDigits={2}
                  minFractionDigits={2}
                  min={0}
                  max={MAX_ALLOWED_VALUE}
                  onBlur={handleBlur(['ProductDimensionsField', 'height'])}
                  onChange={handleChange(['ProductDimensionsField', 'height'])}
                />
                <ControlGroup.Dropdown
                  options={dimensionMeasuresList}
                  width={100}
                  value={values.ProductDimensionsField.measure}
                  onChange={(handleChange: any)(['ProductDimensionsField', 'measure'])}
                />
              </ControlGroup>
              <FormField.Error>{errors.ProductDimensionsField}</FormField.Error>
            </FormField>
          </Box>

          <Box>
            <Flex spaceBetween="XL">
              <FormField>
                <FormField.Label>Shipping Weight</FormField.Label>
                <ControlGroup disabled={disabled}>
                  <ControlGroup.NumericInput
                    width={100}
                    value={values.PackageWeightField.amount}
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    min={0}
                    max={MAX_ALLOWED_VALUE}
                    onBlur={handleBlur(['PackageWeightField', 'amount'])}
                    onChange={handleChange(['PackageWeightField', 'amount'])}
                  />
                  <ControlGroup.Dropdown
                    options={weightMeasuresList}
                    width={100}
                    value={values.PackageWeightField.measure}
                    onChange={(handleChange: any)(['PackageWeightField', 'measure'])}
                  />
                </ControlGroup>
                <FormField.Error>{errors.PackageWeightField}</FormField.Error>
              </FormField>

              <FormField>
                <FormField.Label>Product Weight</FormField.Label>
                <ControlGroup disabled={disabled}>
                  <ControlGroup.NumericInput
                    width={100}
                    value={values.ProductWeightField.amount}
                    maxFractionDigits={2}
                    minFractionDigits={2}
                    min={0}
                    max={MAX_ALLOWED_VALUE}
                    onBlur={handleBlur(['ProductWeightField', 'amount'])}
                    onChange={handleChange(['ProductWeightField', 'amount'])}
                  />
                  <ControlGroup.Dropdown
                    options={weightMeasuresList}
                    width={100}
                    value={values.ProductWeightField.measure}
                    onChange={(handleChange: any)(['ProductWeightField', 'measure'])}
                  />
                </ControlGroup>
                <FormField.Error>{errors.ProductWeightField}</FormField.Error>
              </FormField>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  )
}
