// @flow

const dateFormat = (date: string): string => date.replace('+00:00', '.000')

const getAttributes = (attributes: any) => {
  return attributes.map(attribute => {
    const { value } = attribute
    const res = { ...attribute }

    if (value) {
      switch (value.valueType) {
        case 'EnumValue':
        case 'BoolValue':
        case 'DecimalValue':
        case 'IntValue':
        case 'StringValue':
          res.formValue = value.value
          break
        case 'DateValue':
          res.formValue = dateFormat(value.value)
          break
        case 'DateRange': {
          const { from, to } = value
          res.formValue = {
            from: from != null ? dateFormat(from) : undefined,
            to: to != null ? dateFormat(to) : undefined,
          }
          break
        }
        case 'DecimalRange':
        case 'IntRange': {
          const { from, to } = value
          res.formValue = { from, to }
          break
        }
        default:
          res.formValue = null
      }
    }

    return res
  })
}

const transformValue = (value, valueType) => {
  switch (valueType) {
    case 'DecimalValue':
      return { value: value.toString(), valueType }
    case 'DecimalRange':
      return {
        from: value.from != null ? value.from.toString() : undefined,
        to: value.to != null ? value.to.toString() : undefined,
        valueType,
      }
    case 'IntRange':
    case 'DateRange':
      return {
        from: value.from,
        to: value.to,
        valueType,
      }
    default:
      return { valueType, value }
  }
}

const valueIsEmpty = (value: any, valueType: string): boolean => {
  if (['IntRange', 'DecimalRange', 'DateRange'].includes(valueType))
    return value.from == null && value.to == null
  return (
    typeof value === 'undefined' || value == null || value === '' || value === 'null' // for select
  )
}

const setAttributes = (attributes: any) => {
  return attributes.map(attribute => {
    const { definitionId, formValue, valueType } = attribute

    return valueIsEmpty(formValue, valueType)
      ? { definitionId, value: null }
      : { definitionId, value: transformValue(formValue, valueType) }
  })
}

export const schema = {
  Attributes: {
    value: {
      errorValue: 'formValue',
      getPath: ['attributes'],
      setPath: ['attributes'],
      getTransform: getAttributes,
      setTransform: setAttributes,
    },
  },
}
