// @flow

import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Flex, H3, FormField, Icon } from '@r1/ui-kit'

export const Wrapper = styled(Flex)`
  ${({ theme }) => `
    border-bottom: 1px solid ${theme.palette.black[300]};
  `}

  &:last-child {
    border-bottom: none;
  }
`

export const MainImage = styled(Flex)`
  ${({ theme }) => css`
    max-width: 160px;
    max-height: 160px;
    margin-right: 30px;
    border-radius: 4px;
    border: 1px solid ${theme.palette.black[400]};
  `}
`
export const ProductTitle = styled(H3)`
  ${({ theme }) => css`
    margin-top: 0;
    margin-bottom: ${theme.space.XL};
  `}
`

export const ProductInfo = styled(Flex)`
  width: 100%;
`

export const Images = styled(Flex)`
  flex-wrap: wrap;

  & span {
    margin: 0 30px 0 0;
  }

  & span:nth-of-type(n + 6) {
    margin-top: 16px;
  }

  & span:nth-of-type(5n) {
    margin-right: 0;
  }
`

export const Thumbnail = styled('span')`
  & img {
    cursor: pointer;
  }
`

export const FormFieldVertical = styled(FormField)`
  ${({ theme }) => `
    color: ${theme.palette.grey[900]};
  `}
`
export const IconWrapper = styled(Icon)`
  position: absolute;
  top: 5px;
  left: 65px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.grey[500]};
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`

export const PhotoWrapper = styled(Flex)`
  position: relative;
  width: 80px;
  height: 80px;
  background-color: transparent;
`
