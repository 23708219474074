/* eslint-disable no-param-reassign */
import { subtractPoints, magnitude } from '../../utils/mathUtils'

const DRAG_TRESHOLD = 4

export const addDragSelectEvents = ({ displayObject, ...handlers }) => {
  const onPointerDown = event => {
    event.stopPropagation()

    displayObject.event = {
      data: event.data,
      startPointerPosition: event.data.getLocalPosition(displayObject.parent),
      pointerDown: true,
      elementId: displayObject.id,
    }
  }

  const onPointerUp = event => {
    event.stopPropagation()
    if (displayObject.event && displayObject.event.dragging) {
      const from = displayObject.event.startElementPosition
      const to = { x: displayObject.x, y: displayObject.y }

      if (typeof handlers.onDragEndHandler === 'function') {
        handlers.onDragEndHandler(displayObject.event.elementId, from, to)
      }
    }

    if (displayObject.event && typeof handlers.onSelectHandler === 'function') {
      handlers.onSelectHandler(displayObject.event.elementId)
    }

    displayObject.event = null
  }

  const onPointerMove = () => {
    if (!displayObject.event || !displayObject.event.pointerDown) {
      return
    }

    const pointerPosition = displayObject.event.data.getLocalPosition(displayObject.parent)
    const distance = subtractPoints(pointerPosition, displayObject.event.startPointerPosition)

    if (displayObject.event.pointerDown && !displayObject.event.dragging) {
      if (magnitude(distance) >= DRAG_TRESHOLD) {
        displayObject.event.dragging = true
        displayObject.event.startElementPosition = {
          x: displayObject.x,
          y: displayObject.y,
        }

        if (typeof handlers.onDragStartHandler === 'function') {
          handlers.onDragStartHandler(displayObject.event.elementId)
        }
      }
    }

    if (displayObject.event.dragging) {
      let position = {
        x: displayObject.event.startElementPosition.x + distance.x,
        y: displayObject.event.startElementPosition.y + distance.y,
      }

      if (typeof handlers.onDragHandler === 'function') {
        position = handlers.onDragHandler(displayObject.event.elementId, position)
      }

      displayObject.x = position.x
      displayObject.y = position.y
    }
  }

  displayObject
    .on('pointerdown', onPointerDown)
    .on('pointerup', onPointerUp)
    .on('pointerupoutside', onPointerUp)
    .on('pointermove', onPointerMove)
}
