/* eslint-disable react/no-unused-class-component-methods */
// @flow

/* eslint-disable react/no-unused-prop-types */

import * as React from 'react'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import type { ActionButtonsProps } from '@r1/wireframe-primary/build/types/ContentHeader'

import type { HttpClient, InternalData, BusinessClaimType } from '../../../types/common'

import type { VendorPortalApi } from '../../../api/api'
import { ClaimController } from './controllers/claimController'
import { DecisionItemsController } from './controllers/decisionItemsController'
import { ChatController } from './controllers/chatController'
import { RefundController } from './controllers/refundController'
import { InitializationController } from './controllers/initializationController'

type User = {|
  id: string,
  name: string,
|}

export type WithControllerProps = {|
  claimId: Lib$Id,
  httpClient: HttpClient,
  ActionButtons: React$ComponentType<ActionButtonsProps>,
  businessClaimType: BusinessClaimType,
  onForbidden: () => null,
  onNotFound: () => null,
  onUnknownError: () => null,
|}

export type WithControllerState = {|
  screen: 'loaded' | 'loading' | 'notFound' | 'forbidden' | 'unknownError',
  sidebarIsVisible: boolean,
  refundIsSubmitting: boolean,
  labelIsGenerated: boolean,
  withReturn: boolean,
  data: null | InternalData,
  messageToSend: string,
  user: User | null,
  activeTabId: 'tabReturns' | 'tabReplacements',
  cancellationReason: string,
  cancellationReasonError: boolean,
  cancellationInProgress: boolean,
  hasUnreadChatMessages: boolean,
|}

export class WithController extends React.Component<WithControllerProps, WithControllerState> {
  state: WithControllerState

  api: VendorPortalApi

  claimCreation: ClaimController

  decisionItems: DecisionItemsController

  chat: ChatController

  refund: RefundController

  initialization: InitializationController
}
