// @flow

import * as yup from 'yup'
import { defaultValueSchema } from './defaultValueSchema'
import { valueConstraintSchema } from './valueConstraintSchema'

export const schema = yup.object({
  displayName: yup.string().max(100, () => `Text should be maximum 100 symbols`),
  hasConstraint: yup.boolean(),
  required: yup.boolean(),
  valueConstraint: valueConstraintSchema,
  defaultValue: defaultValueSchema.nullable(),
})
