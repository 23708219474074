import * as React from 'react'
import { Chart as ChartJS } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import 'chart.js/auto'

import { Chart } from 'react-chartjs-2'
import { Box, Card, Flex, H2, Icon } from '@r1/ui-kit'
import { useMemo } from 'react'
import { QuaterCardWrapper } from '../styles'
import { Data } from '../types'
import { getChartSettings } from './settings'

ChartJS.register(ChartDataLabels)

type HorizonatalBarChartProps = {
  title: React.ReactNode
  data: Data[]
  loading: boolean
  height?: number
}

export const HorizonatalBarChart = ({
  data,
  loading,
  title,
  height = 150,
}: HorizonatalBarChartProps) => {
  const settings = useMemo(() => {
    return getChartSettings(data)
  }, [data])
  return (
    <QuaterCardWrapper column>
      <Card>
        <Flex column mt="XS" mr="XS" ml="XS">
          <Flex align="center">
            <H2>{title}</H2>
            {loading && (
              <Box ml="S">
                <Icon type="loaders/circle" />
              </Box>
            )}
          </Flex>
          <div style={{ height }}>{!loading && <Chart {...settings} type="bar" />}</div>
        </Flex>
      </Card>
    </QuaterCardWrapper>
  )
}
