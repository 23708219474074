import React from 'react'
import { Flex, Button } from '@r1/ui-kit'

export const ToolBar = ({ state, buttons, column }) => {
  return (
    <Flex column={column}>
      {buttons.map(b => (
        <Button
          key={b.text || '1'}
          disabled={b.getEnabled ? !b.getEnabled(state) : false}
          onClick={() => b.handler(state)}
        >
          {b.text}
        </Button>
      ))}
    </Flex>
  )
}

/* ToolBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      handler: PropTypes.func.isRequired,
    }),
  ),
  // eslint-disable-next-line react/boolean-prop-naming
  column: PropTypes.bool,
} */

// export default ToolBar
