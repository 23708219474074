// @flow

import * as React from 'react'

export const AnimatedLoader = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <animateTransform
      attributeName="transform"
      type="rotate"
      from="0 0 0"
      to="360 0 0"
      dur="1s"
      repeatCount="indefinite"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z"
      fill="#265ECA"
    />
    <path
      d="M12 2C10.4661 2 8.95281 2.35286 7.57711 3.03127C6.20142 3.70969 5.00023 4.69548 4.06647 5.91239C3.1327 7.12929 2.49139 8.54469 2.19215 10.0491C1.8929 11.5535 1.94375 13.1066 2.34074 14.5882C2.73774 16.0698 3.47025 17.4402 4.4816 18.5935C5.49296 19.7467 6.75605 20.6518 8.17317 21.2388C9.59028 21.8258 11.1234 22.0789 12.654 21.9786C14.1846 21.8783 15.6716 21.4272 17 20.6603L15.0027 17.2009C14.205 17.6615 13.312 17.9324 12.3928 17.9926C11.4736 18.0528 10.5529 17.9008 9.70181 17.5483C8.85077 17.1958 8.09222 16.6522 7.48486 15.9597C6.8775 15.2671 6.43759 14.4441 6.19918 13.5543C5.96076 12.6646 5.93023 11.7319 6.10994 10.8284C6.28965 9.92493 6.67478 9.07492 7.23555 8.34411C7.79632 7.6133 8.51769 7.02129 9.34386 6.61387C10.17 6.20645 11.0788 5.99454 12 5.99454V2Z"
      fill="#AACCFE"
    />
  </svg>
)
