import * as React from 'react'
import { Facility } from '@r1-webui/facilities-v1'
import { facilitiesApi } from '../../../api/facilities/facilities'
import { showNotification } from '../../../utils/notifications/showNotification'
import { getErrorNotification } from '../utils/getErrorNotification'
import { mapFacilityToForm } from '../utils/mapFacilityToForm'

export const useFacility = ({
  facilityId,
  onError,
}: {
  facilityId?: string | null
  onError?: () => void
}) => {
  const [facility, setFacility] = React.useState<Facility | null>(null)

  React.useEffect(() => {
    if (facilityId) {
      const hasCallback = typeof onError === 'function'

      facilitiesApi
        .getFacilityById({ facilityId })
        .then(({ body, status }) => {
          if (status !== 200) {
            const notification = getErrorNotification(status, { facilityId })

            showNotification(notification)

            if (hasCallback) {
              onError()
            }
          } else {
            setFacility(mapFacilityToForm(body.facility))
          }
        })
        .catch(() => {
          const notification = getErrorNotification(null, { facilityId })

          showNotification(notification)
        })
    } else {
      setFacility(null)
    }
  }, [facilityId, onError])

  return { facility }
}
