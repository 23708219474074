// @flow

import { NotificationSystem } from '@r1/ui-kit'
import {
  createConsolidatedPricesApi,
  baseUri as synchronizationBaseUri,
} from '@r1-webui/productprices-consolidatedpricessynchronization-v1'
import {
  createConsolidatedRuleComparisonSettingsApi,
  createConsolidatedPricesComparisonResultApi,
  baseUri as comparisonBaseUri,
} from '@r1-webui/productprices-consolidatedpricescomparison-v1'
import { httpClient as http } from '../../httpClient'
import { handleError } from '../../modules/utils'

const consolidatedPricesApi = createConsolidatedPricesApi({
  http,
  urlPrefix: `/uisvc/product-prices/${synchronizationBaseUri}`,
})
const consolidatedRuleComparisonSettingsApi = createConsolidatedRuleComparisonSettingsApi({
  http,
  urlPrefix: `/uisvc/product-prices/${comparisonBaseUri}`,
})
const consolidatedPricesComparisonResultApi = createConsolidatedPricesComparisonResultApi({
  http,
  urlPrefix: `/uisvc/product-prices/${comparisonBaseUri}`,
})

type Status = any // 200 | 400 | 500 | 401 | 403 | 705 | 404

const processResponse = ({ status, body }: { status: Status, body: any }) => {
  if (status === 200) return body
  handleError({ response: { status, body } })
  return undefined
}

const processSyncResponse = ({ status }: { status: Status, body: any }) => {
  if (status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      message: 'Synchronizing prices',
    })
  } else {
    NotificationSystem.addNotification({
      level: 'error',
      message: 'Synchronization failed',
    })
  }
}

// requests for sync

export const syncConsolidatedProductsPrices = async (templateIdsString: string) =>
  consolidatedPricesApi
    .syncConsolidatedProductsPrices({
      productIds: templateIdsString.trim().split(/[\s,]+/),
    })
    .then(processSyncResponse)

export const syncConsolidatedProductsPricesByRule = async (uriParameters: {|
  comparisonSettingId: ?string,
  ruleId: string,
|}) =>
  uriParameters.comparisonSettingId
    ? consolidatedPricesApi
        .syncConsolidatedProductsPricesByRuleSetting({
          ruleId: uriParameters.ruleId,
          comparisonSettingId: uriParameters.comparisonSettingId,
        })
        .then(processSyncResponse)
    : consolidatedPricesApi
        .syncAllConsolidatedProductsPricesByRule({ ruleId: uriParameters.ruleId })
        .then(processSyncResponse)

export const syncConsolidatedProductPriceByRule = async (uriParameters: {|
  productId: string,
  ruleId: string,
|}) =>
  consolidatedPricesApi.syncConsolidatedProductPriceByRule(uriParameters).then(processSyncResponse)

// requests for data

export const getRuleComparisonSettings = async () =>
  consolidatedRuleComparisonSettingsApi.getRuleComparisonSettings().then(processResponse)

export const getConsolidatedProductsPrices = async (templateIdsString: string) =>
  consolidatedPricesApi
    .getConsolidatedProductsPrices({
      productIds: templateIdsString.trim().split(/[\s,]+/),
    })
    .then(processResponse)

export const getConsolidatedPricesComparisonResult = async () =>
  consolidatedPricesComparisonResultApi
    .getConsolidatedPricesComparisonResult()
    .then(processResponse)

export const getConsolidatedProductsPricesByRule = async ({
  ruleId,
  comparisonSettingId,
  page,
  pageSize,
}: {|
  ruleId: string,
  comparisonSettingId: string,
  page: number,
  pageSize: number,
|}) => {
  const body = { limit: pageSize, offset: (page - 1) * pageSize }

  return comparisonSettingId
    ? consolidatedPricesApi
        .getConsolidatedProductsPricesByRuleSetting({ ruleId, comparisonSettingId }, body)
        .then(processResponse)
    : consolidatedPricesApi
        .getConsolidatedProductsPricesByRuleId({ ruleId }, body)
        .then(processResponse)
}

export const getPricesStatistics = () =>
  consolidatedPricesApi.getPricesStatistic().then(processResponse)

export const searchProductsByIdentifier = async (
  uriParameters: {|
    identifier: string,
    ruleId: string,
  |},
  body: {|
    comparisonSettingId?: string,
  |},
) =>
  consolidatedPricesApi
    .searchForProductByIdentifierAndRuleId(uriParameters, body)
    .then(processResponse)
