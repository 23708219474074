import { useState, useEffect } from 'react'
import { utcToLocal } from '../../../../../utils/dates/utcToLocal'

export const useExceptionDates = (warehouseExceptionDates: string[]) => {
  const [exceptionDates, setExceptionDates] = useState<Date[]>([])

  useEffect(() => {
    const newExceptionDates = warehouseExceptionDates.map(exceptionDate =>
      utcToLocal(exceptionDate),
    )
    setExceptionDates(newExceptionDates)
  }, [warehouseExceptionDates])

  return exceptionDates
}
