// @flow

import * as React from 'react'

import { longPolling, randomString } from './utils'

import type { Export, ExportConfig, FileFormat } from './types'

type State = {
  exports: Array<Export> | null,
  exportInProgress: boolean,
  error: boolean,
}

type ExportOptions<Filter> = { filter: Filter, fileFormat: FileFormat }

type Props<Filter> = {
  exportConfig: ExportConfig<Filter>,
  children: ({
    doExport: (ExportOptions<Filter>) => Promise<void>,
    exports: Array<Export> | null,
    exportInProgress: boolean,
    error: boolean,
  }) => React.Node,
}

const exportFinished = status => !status || status === 'Completed' || status === 'HasError'

export class Exporter<Filter: {}> extends React.Component<Props<Filter>, State> {
  static POLLING_INTERVAL = 500

  state = {
    exports: null,
    exportInProgress: false,
    error: false,
  }

  doExport: (options: ExportOptions<Filter>) => Promise<void> = async options => {
    this.setState({
      exports: null,
      exportInProgress: true,
      error: false,
    })
    try {
      const exportId = await this.props.exportConfig.start({ ...options, fileName: randomString() })
      if (exportId == null) {
        this.setState({ exportInProgress: false, error: true, exports: null })
        return
      }
      const exportStatus = await longPolling({
        asyncCall: () => this.props.exportConfig.getStatus(exportId),
        stopWhen: exportFinished,
        pollingInterval: Exporter.POLLING_INTERVAL,
      })

      if (exportStatus === 'HasError') {
        this.setState({ exportInProgress: false, error: true, exports: null })
        this.props.exportConfig.onNotify({
          level: 'error',
          title: 'Export error',
          message: 'An unexpected error occurred (status: HasError)',
        })
      }

      if (exportStatus === 'Completed') {
        const exports = await this.props.exportConfig.get(exportId)
        this.setState(
          {
            exportInProgress: false,
            error: false,
            exports,
          },
          () => {
            this.props.exportConfig.onSuccess(exports)
          },
        )
      }
    } catch (e) {
      this.setState({ exportInProgress: false, error: true, exports: null })
    }
  }

  render() {
    return this.props.children({
      exports: this.state.exports,
      exportInProgress: this.state.exportInProgress,
      error: this.state.error,
      doExport: this.doExport,
    })
  }
}
