// @flow

import React from 'react'

import { Flex, Icon, NotificationSystem } from '@r1/ui-kit'

import type { OrderSelectionItemProps } from '../../types/ClaimCreation'
import { MarketplaceInfoBlock, OrderInfoBlock, CustomerInfoBlock } from '../OrderInfo'
import { SelectableOrderInfo, IconWrapper } from './StyledComponents'

export const OrderSelectionItem = (props: OrderSelectionItemProps) => {
  const {
    shippingAddress,
    isSelected,
    isClaimCanBeCreated,
    claimCannotBeCreatedMessage,
    order,
    orderStatus,
    rmaProfile,
    onSelection,
  } = props
  const {
    createdOn,
    purchaseOrderId,
    externalId: marketplaceOrderId,
    orderTotal,
    shippingInfo,
  } = order
  const {
    sellerName: marketplaceName,
    venueTypeInfo: { svgId: marketplaceSvgId },
  } = rmaProfile

  const onOrderInfoClick = selectedOrder => {
    if (!isClaimCanBeCreated) {
      NotificationSystem.addNotification({
        level: 'warning',
        title: 'Claim Creation',
        message: claimCannotBeCreatedMessage,
      })
      return
    }

    onSelection(selectedOrder)
  }

  return (
    <SelectableOrderInfo onClick={() => onOrderInfoClick(order)}>
      <Flex column mr="XL">
        <IconWrapper>{isSelected && <Icon type="checkmark" size="L" />}</IconWrapper>
      </Flex>
      <MarketplaceInfoBlock marketplaceName={marketplaceName} marketplaceSvgId={marketplaceSvgId} />
      <OrderInfoBlock
        marketplaceOrderId={marketplaceOrderId}
        purchaseOrderId={purchaseOrderId}
        createdOn={createdOn}
        orderStatus={orderStatus}
        orderTotal={orderTotal}
      />
      <CustomerInfoBlock
        fullName={shippingInfo.fullName}
        email={shippingInfo.email}
        phone={shippingInfo.phone}
        shippingAddress={shippingAddress}
      />
    </SelectableOrderInfo>
  )
}
