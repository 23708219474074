// @flow

import * as React from 'react'
import type { ClaimItem } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'
import { Flex, Image, Text } from '@r1/ui-kit'
import { useState } from 'react'
import type { Line } from '@r1-webui/rmavendorportal-orders-v1/src/types'
import type { ClaimType } from '../../types/common'
import type { NewClaimItem } from '../../types/ClaimScreen'
import { RefundEditor, QuantityEditor, ReturnReasonEditor } from '../editors'
import { Nowrap } from './StyledComponents'

type Props = {
  claimItem: ClaimItem,
  orderLine: Line,
  decisionType: ClaimType,
  availableReturnReasons: ReturnReason[],
  setChangeClaimItemData: (claimItem: NewClaimItem) => void,
}

export const ClaimLine = (props: Props) => {
  const { claimItem, orderLine, decisionType, availableReturnReasons, setChangeClaimItemData } =
    props
  const claimItemQuantity = parseInt(orderLine.lineInfo.quantity || '1', 10)
  const [quantity, setQuantity] = useState(claimItemQuantity)
  const [refundAmount, setRefundAmount] = useState(orderLine.lineInfo.singleItemPrice.total.amount)
  const [returnReasonId, setReturnReasonId] = useState(availableReturnReasons[0].id)

  React.useEffect(() => {
    setChangeClaimItemData({
      claimItemId: claimItem.id,
      orderLineId: claimItem.orderLineId,
      quantity,
      refund: {
        amount: refundAmount,
        currency: orderLine.lineInfo.singleItemPrice.total.currency,
      },
      returnReasonId,
    })
    // Update data only on change of editors to avoid recursive data updates.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, refundAmount, returnReasonId])

  return (
    <Flex justify="space-between">
      <Flex>
        <Flex align="center" justify="center" minWidth={160} maxWidth={160}>
          <Image src={orderLine.productInfo.imageUrls[0]} height={100} width={100} />
        </Flex>
        <Flex column>
          <Flex mb="M">
            <Nowrap>Product:</Nowrap>&nbsp;
            <Text>{orderLine.productInfo.title || ''}</Text>
          </Flex>
          <Flex mb="M">
            <Nowrap>Price:</Nowrap>&nbsp;
            <Text>
              {orderLine.lineInfo.singleItemPrice.total.currency}{' '}
              {orderLine.lineInfo.singleItemPrice.total.amount}
            </Text>
          </Flex>
          <Flex mb="M">
            <Nowrap>Quantity:</Nowrap>&nbsp;
            <Text>{orderLine.lineInfo.quantity}</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex column minWidth={200} maxWidth={200}>
        <Flex>
          {decisionType === 'RefundOnly' ? (
            <RefundEditor
              defaultValue={orderLine.lineInfo.singleItemPrice.total.amount}
              maxValue={orderLine.lineInfo.singleItemPrice.total.amount}
              currency={orderLine.lineInfo.singleItemPrice.total.currency}
              onRefundChange={setRefundAmount}
            />
          ) : (
            <QuantityEditor
              defaultValue={parseInt(claimItemQuantity, 10)}
              maxValue={parseInt(claimItemQuantity, 10)}
              onQuantityChange={setQuantity}
            />
          )}
        </Flex>
        <Flex>
          <ReturnReasonEditor
            options={availableReturnReasons}
            defaultValue={returnReasonId}
            onReturnReasonChange={setReturnReasonId}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
