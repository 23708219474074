import { baseUri, createAddressApi, createCountriesApi } from '@r1-webui/addressmanagement-v1'

import { httpClient } from '../../httpClient'

const urlPrefix = `/uisvc/address-management/${baseUri}`

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const addressesApi = createAddressApi({ http: httpClient, urlPrefix })

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const countriesApi = createCountriesApi({ http: httpClient, urlPrefix })
