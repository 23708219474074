// @flow

import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Button, Flex, Box } from '@r1/ui-kit'
import * as warehousesModule from '../../../../../modules/warehouses'
import * as consigneeUpcClassificationsModule from '../../../../../modules/dictionaries/consigneeUpcClassifications'
import * as consigneeUpcMarketplacesModule from '../../../../../modules/dictionaries/consigneeUpcMarketplaces'
import * as consigneeUpcModule from '../../../../../modules/productTemplates/consigneeUpc'
import { ConsigneeUPCItem } from './components/ConsigneeUPCItem'
import * as actions from './module/module'
import type { ConsigneeUPCPublicProps, ConsigneeUPCProps, ConsigneeUPCType } from './types'

const mapStateToProps = state => ({
  consigneeUpcForm: state.consigneeUPC.consigneeUPCs,
  meta: {
    classifications: consigneeUpcClassificationsModule.selectors.getCollectionItems(state),
    marketplaces: consigneeUpcMarketplacesModule.selectors.getCollectionItems(state),
    warehouses: warehousesModule.selectors.getCollectionItems(state),
  },
})

const enhance = compose(
  connect(mapStateToProps, {
    fetchWarehouses: warehousesModule.actions.fetchAll,
    fetchConsigneeUpcClassifications: consigneeUpcClassificationsModule.actions.fetchAll,
    fetchConsigneeUpcMarketplaces: consigneeUpcMarketplacesModule.actions.fetchAll,
    fetchConsigneeUpcs: consigneeUpcModule.actions.fetchAll,
    ...actions,
  }),
)

const ConsigneeUPCComponent = ({
  meta,
  consigneeUpcForm,
  addConsigneeUpc,
  disabled,
  id,
  fetchWarehouses,
  fetchConsigneeUpcClassifications,
  fetchConsigneeUpcMarketplaces,
  fetchConsigneeUpcs,
  initForm,
  onAddDirtyTab,
  saveConsigneeUpc,
  deleteConsigneeUpc,
}: ConsigneeUPCProps) => {
  React.useEffect(() => {
    ;(async () => {
      fetchWarehouses()
      fetchConsigneeUpcClassifications()
      fetchConsigneeUpcMarketplaces()
      const consigneeUpcs = await fetchConsigneeUpcs({ id })
      initForm(consigneeUpcs)
    })()
  }, [])

  const onSaveConsigneeUpc = React.useCallback(
    (consigneeUpc: ConsigneeUPCType) => {
      saveConsigneeUpc(id, consigneeUpc)
      onAddDirtyTab('consignee upc')
    },
    [saveConsigneeUpc, onAddDirtyTab, id],
  )

  const onDeleteConsigneeUpc = React.useCallback(
    (consigneeUpcId: string, index: number) => {
      deleteConsigneeUpc(id, consigneeUpcId, index)
      onAddDirtyTab('consignee upc')
    },
    [id, deleteConsigneeUpc, onAddDirtyTab],
  )

  const check = React.useMemo(
    () =>
      consigneeUpcForm &&
      meta.classifications.length > 0 &&
      meta.marketplaces.length > 0 &&
      meta.warehouses.length > 0,
    [consigneeUpcForm, meta.classifications, meta.marketplaces, meta.warehouses],
  )

  return (
    <Flex column spaceBetween="L">
      <Box alignSelf="flex-start">
        <Button size="M" disabled={disabled} onClick={addConsigneeUpc}>
          Add consignee UPC
        </Button>
      </Box>

      {check &&
        consigneeUpcForm.map((upc, index) => (
          <ConsigneeUPCItem
            key={upc.consigneeUpc || upc.key}
            index={index}
            upc={upc}
            meta={meta}
            disabled={disabled}
            onSaveConsigneeUpc={onSaveConsigneeUpc}
            onDeleteConsigneeUpc={onDeleteConsigneeUpc}
          />
        ))}
    </Flex>
  )
}

export const ConsigneeUPC: React$ComponentType<ConsigneeUPCPublicProps> =
  enhance(ConsigneeUPCComponent)
