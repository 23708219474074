import styled from '@emotion/styled'

type EventDotProps = {
  color: string
}

export const EventDot = styled.div<EventDotProps>`
  margin: 0 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: ${props => `4px solid ${props.color}`};
  border: ${props => `calc(8px / 2) solid ${props.color}`};
  border-radius: 4px;
  border-radius: calc(8px / 2);
`
