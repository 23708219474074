// @flow

const packageScope = 'product-catalog'

type Options<Comps: { [string]: string }> = {
  components: Comps,
  scope: string[],
}

export function generateTestIds<Comps: { [string]: string }>({
  components,
  scope,
}: Options<Comps>): $ObjMap<Comps, () => string> {
  const result = {}

  Object.entries(components).forEach(([key, value]) => {
    result[key] = [packageScope, ...scope, value].join('__')
  })

  return result
}
