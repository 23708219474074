import { Action } from '../constants'
import { createDocumentTemplateManagerService } from '../services/documentTemplateManagerService'
import * as factory from '../services/blocksFactory'

const api = createDocumentTemplateManagerService()

export const clearData = () => {
  return async dispatch => {
    dispatch({
      type: Action.CLEAR_TEMPLATE_MODELS,
    })
  }
}

export const loadDocumentTemplate = id => {
  return async dispatch => {
    const template = await api.getDocumentTemplate(id)

    dispatch({
      type: Action.LOAD_DOCUMENT_TEMPLATE,
      template,
    })
  }
}

export const saveDocumentTemplate = () => {
  return async (dispatch, getState) => {
    const template = getState().labelManagement.documentTemplate.currentState

    if (template.id) {
      await api.updateDocumentTemplate(template)
    } else {
      const id = await api.createDocumentTemplate(template)
      if (typeof id !== 'string') {
        console.error(id)
      } else {
        dispatch({
          type: Action.CREATE_DOCUMENT_TEMPLATE,
          id,
        })
      }
    }
  }
}

const changeNameAction = name => ({
  type: Action.CHANGE_NAME,
  name,
})

export const changeName = (oldName, newName) => {
  return {
    ...changeNameAction(newName),
    undo: changeNameAction(oldName),
  }
}

export const changeTemplateModel = templateModel => {
  return {
    type: Action.CHANGE_TEMPLATE_MODEL,
    templateModel,
  }
}

const changePageSettingAction = pageSetting => ({
  type: Action.CHANGE_PAGE_SETTING,
  pageSetting,
})

export const changePageSetting = (oldPageSetting, newPageSetting) => {
  return {
    ...changePageSettingAction(newPageSetting),
    undo: changePageSettingAction(oldPageSetting),
  }
}

export const changeZoom = zoom => {
  return {
    type: Action.CHANGE_ZOOM,
    zoom,
  }
}

const addBlockAction = block => ({
  type: Action.ADD_BLOCK,
  block,
})

const removeBlockAction = id => ({
  type: Action.REMOVE_BLOCK,
  id,
})

export const addTextBlock = () => {
  const block = factory.createTextBlock()

  return {
    ...addBlockAction(block),
    undo: removeBlockAction(block.id),
  }
}

export const addBarcodeBlock = () => {
  const block = factory.createBarcodeBlock()

  return {
    ...addBlockAction(block),
    undo: removeBlockAction(block.id),
  }
}

export const addQrCodeBlock = () => {
  const block = factory.createQrCodeBlock()

  return {
    ...addBlockAction(block),
    undo: removeBlockAction(block.id),
  }
}

export const addRectangleBlock = () => {
  const block = factory.createRectangleBlock()

  return {
    ...addBlockAction(block),
    undo: removeBlockAction(block.id),
  }
}

export const addListBlock = () => {
  const block = factory.createListBlock()

  return {
    ...addBlockAction(block),
    undo: removeBlockAction(block.id),
  }
}

export const removeBlock = block => {
  return {
    ...removeBlockAction(block.id),
    undo: addBlockAction(block),
  }
}

export const selectBlock = id => {
  return {
    type: Action.SELECT_BLOCK,
    id,
  }
}

const changeTransformAction = (id, transform) => {
  return {
    type: Action.CHANGE_BLOCK_TRANSFORM,
    id,
    transform,
  }
}

export const changeBlockTransform = (id, oldTransform, newTransform) => {
  return {
    ...changeTransformAction(id, newTransform),
    undo: changeTransformAction(id, oldTransform),
  }
}

const changeBlockPropertyAction = (id, propsIdsPath, value) => {
  return {
    type: Action.CHANGE_BLOCK_PROPERTY,
    id,
    propsIdsPath,
    value,
  }
}

export const changeBlockProperty = (id, propsIdsPath, oldValue, newValue) => {
  return {
    ...changeBlockPropertyAction(id, propsIdsPath, newValue),
    undo: changeBlockPropertyAction(id, propsIdsPath, oldValue),
  }
}

const createBlockValueAction = (id, propsIdsPath, value) => {
  return {
    type: Action.ADD_BLOCK_VALUE,
    id,
    propsIdsPath,
    value: {
      type: value.type,
      ...factory.getDefaultBlockValue(value.type),
      ...value,
    },
  }
}

const removeBlockValueAction = (id, propsIdsPath, valueId) => {
  return {
    type: Action.REMOVE_BLOCK_VALUE,
    id,
    propsIdsPath,
    valueId,
  }
}

export const addConstantValue = (blockId, propsIdsPath) => {
  const value = factory.createConstantValue('')

  return {
    ...createBlockValueAction(blockId, propsIdsPath, value),
    undo: removeBlockValueAction(blockId, propsIdsPath, value.id),
  }
}

export const removeValue = (blockId, propsIdsPath, value) => {
  return {
    ...removeBlockValueAction(blockId, propsIdsPath, value.id),
    undo: createBlockValueAction(blockId, propsIdsPath, value),
  }
}

const changeBlockValueAction = (id, propsIdsPath, value) => {
  return {
    type: Action.CHANGE_BLOCK_VALUE,
    id,
    propsIdsPath,
    value: {
      ...factory.getDefaultBlockValue(value.type),
      ...value,
    },
  }
}

export const changeValue = (blockId, propsIdsPath, oldValue, newValue) => {
  return {
    ...changeBlockValueAction(blockId, propsIdsPath, newValue),
    undo: changeBlockValueAction(blockId, propsIdsPath, oldValue),
  }
}
