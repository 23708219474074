import styled from '@emotion/styled'
import { Box, Flex } from '@r1/ui-kit'

export const TitleCounter = styled(Flex)`
  height: 24px;
  border-radius: 2px;
  color: ${({ theme }) => theme.palette.grey[900]};
  background-color: ${({ theme }) => theme.palette.grey[300]};
`

export const LoaderContainer = styled(Box)`
  width: 14px;
  height: 14px;
`
