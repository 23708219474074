// @flow

import type {
  AttributeBinding,
  InheritedAttributeBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'
import type { AttributeDefinition } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'

import { toObject } from '../../../utils/toObject'
import { replaceTemplateValues } from '../../../../../utils'
import type { ExtendedAttributeDefinition, ChildAttributes, AttributeMeta } from '../types'
import { getChildBindingsFromChildCategory } from './getChildBindingsFromChildCategory'
import { generateAttributeRelations } from './generateAttributeRelations'

function findValuesByChildBinding(attribute, childBinding): Array<string | number | boolean> {
  const values = []
  const { enumValues } = attribute

  if (enumValues) {
    childBinding.values.forEach(bindingValue => {
      /**
       * wtf error because of union types %)
       */
      const attributeValue = (enumValues: any).enumValues.find(
        value => value.id === bindingValue.value,
      )
      if (attributeValue) {
        values.push(attributeValue.value)
      }
    })
  }

  if (attribute.valueType === 'Boolean') {
    childBinding.values.forEach(bindingValue => {
      values.push(bindingValue.value)
    })
  }

  return values
}

function getChildAttributes({
  attributeBindings,
  targetAttributeDefinition,
  attributeDefinitions,
}): ChildAttributes | null {
  const result: ChildAttributes = {}
  const attributeDefinitionsMap = toObject(attributeDefinitions, 'id')
  const bindingsMap: {
    [string]: AttributeBinding | InheritedAttributeBinding,
  } = attributeBindings.reduce((acc, item) => ({ ...acc, [item.attributeDefinition.id]: item }), {})

  attributeBindings.forEach(attributeBinding => {
    if (attributeBinding.attributeDefinition.id !== targetAttributeDefinition.id) {
      return
    }

    attributeBinding.childBindings.forEach(childBinding => {
      const binding = bindingsMap[childBinding.attributeDefinitionId]
      const childAttribute = attributeDefinitionsMap[binding.attributeDefinition.id]
      const attributeValues = findValuesByChildBinding(targetAttributeDefinition, childBinding)

      attributeValues.forEach(attributeValue => {
        result[attributeValue] = result[attributeValue] || []
        result[attributeValue].push(
          bindingsMap[childAttribute.id].displayName || childAttribute.name,
        )
      })
    })
  })

  return Object.keys(result).length > 0 ? result : null
}

const getTranslatedName = unitOfMeasurement =>
  unitOfMeasurement ? replaceTemplateValues(unitOfMeasurement.name) : null

export function extendBoundAttributeDefinitions({
  attributeBindings,
  attributeDefinitions,
  inheritedAttributeBindings,
}: {
  attributeBindings: AttributeBinding[],
  attributeDefinitions: AttributeDefinition[],
  inheritedAttributeBindings: InheritedAttributeBinding[],
}): ExtendedAttributeDefinition[] {
  const bindings = [...attributeBindings, ...inheritedAttributeBindings]

  const bindingMap = bindings.reduce(
    (acc, item) => ({ ...acc, [item.attributeDefinition.id]: item }),
    {},
  )

  const relations = generateAttributeRelations(bindings)

  return attributeDefinitions.map(attributeDefinition => {
    const attributeBinding = bindingMap[attributeDefinition.id]
    const inherited = /inherit/i.test(attributeBinding.type)
    const relation = relations[attributeDefinition.id]
    const { parentId, children } = relation
    const hasChildren = children.length > 0

    const meta: AttributeMeta = {
      childAttributes: getChildAttributes({
        attributeBindings: bindings,
        attributeDefinitions,
        targetAttributeDefinition: attributeDefinition,
      }),
      inherited,
      binding: attributeBinding,
      childBindingsFromChildCategory: inherited
        ? getChildBindingsFromChildCategory({
            attributeDefinitionId: attributeDefinition.id,
            bindings: attributeBindings,
          })
        : [],
      translatedUnitOfMeasutementName: getTranslatedName(attributeDefinition.unitOfMeasurement),
    }

    // union desctructing ;)
    return { ...(attributeDefinition: any), meta, hasChildren, parentId }
  })
}
