// @flow

import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { Flex, Icon } from '@r1/ui-kit'

export const Photo = styled('img')`
  max-width: 141px;
  max-height: 153px;
`

export const PhotoWrapper = styled(Flex)`
  ${({ theme }) => css`
    position: relative;
    width: 165px;
    height: 165px;
    background: ${theme.palette.white};
  `}
`

export const IconWrapper = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 17px;
`
