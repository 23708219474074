/**
 * This helper normalize date format pattern from backend in order to use it with date-fns@2
 * and format through date-fns
 * @see https://github.com/date-fns/date-fns/blob/master/CHANGELOG.md#changed-4
 * */

import { format } from 'date-fns'

const defaultFormat = 'yyyy-MM-dd'

/** Keys are known formats from backend. /uisvc/user-management/preference-values/date-formats */
const normalizedFormats: { [key: string]: string } = {
  'YYYY-MM-DD': 'yyyy-MM-dd',
  'MM/D/YY': 'MM/d/yy',
  'MM/D/YYYY': 'MM/d/yyyy',
  'D/MM/YY': 'd/MM/yy',
  'D/MM/YYYY': 'd/MM/yyyy',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDate = (date: number | Date, formatStr: string, options?: any) => {
  let normalizedFormat = normalizedFormats[formatStr]
  if (!normalizedFormat) {
    console.warn(
      `UM. Format date. Normalized format for ${formatStr} was not found. Will be used default format ${defaultFormat}.`,
    )
    normalizedFormat = defaultFormat
  }

  return format(date, normalizedFormat, options)
}
