/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { handleServerError } from '@r1/core-blocks'
import { createProductsApi } from '@r1-webui/productmanagement-v1'
import { httpClient } from '../../httpClient'
import { getProductTemplateHistory } from '../productTemplate'

const API_PREFIX = `/uisvc/product-management`

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const productApi = createProductsApi({ http: httpClient, urlPrefix: API_PREFIX }) // current API in js file 'cause of using

export const historyApi = {
  getProductHistory: async (id: number) => {
    const res = await productApi.getHistory({ id })
    if (res.status !== 200) {
      handleServerError(res.body)
      return []
    }
    return res.body
  },

  getAvailableHistoryActions: async (): Promise<{ id: number; name: string }[]> => {
    try {
      return await httpClient.get(`/ui/api/datasource/history-actions`)
    } catch (error) {
      handleServerError(error)
      return []
    }
  },

  getProductTemplateHistory,
}
