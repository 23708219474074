// @flow

import React, { memo, createRef, useEffect, useMemo } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Modal } from '@r1/ui-kit'

type LockModalProps = {
  isOpen: boolean,
  text: string,
  onClose: () => void,
  onDelete: () => void,
}

export const ModalConfirm = memo<LockModalProps>(
  ({ isOpen, text, onClose, onDelete }: LockModalProps) => {
    const input = createRef()
    // fix: we need to close select because z-index of the select is larger then z-index of the modal
    // see https://jira.trgdev.com/browse/R1-12151
    useEffect(() => {
      if (input.current) input.current.focus()
    }, [input])

    const modalActionButtons: BottomActionButton[] = useMemo(
      () => [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        {
          title: 'Delete',
          onClick: () => {
            onDelete()
            onClose()
          },
          align: 'right',
        },
      ],
      [onDelete, onClose],
    )

    return (
      <Modal
        isControlled
        title="Delete"
        show={isOpen}
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onClose}
      >
        {`Are you sure you want to delete ${text}?`}
      </Modal>
    )
  },
)
