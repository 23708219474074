import React from 'react'
import { Button, Flex } from '@r1/ui-kit'
import { OfferAction } from '../../../types'
import { getOfferCounterActionAbility, getOffersApprovalActionAbility } from '../../../utils'
import { OfferDrawerProps } from '../OfferDrawer.types'
import { FINISHED_OFFER_STATUSES } from '../../../const'

export const OfferDrawerActions = React.memo<
  Pick<OfferDrawerProps, 'offer' | 'allowEdit' | 'onActionClick' | 'onClose'>
>(({ offer, allowEdit, onActionClick, onClose }) => {
  const approvalAbility = offer ? getOffersApprovalActionAbility([offer]) : null

  const counterOfferAbility = offer ? getOfferCounterActionAbility([offer]) : null

  const withActions = offer ? allowEdit && !FINISHED_OFFER_STATUSES.includes(offer.status) : false

  return offer && approvalAbility && counterOfferAbility ? (
    <Flex align="center" justify="space-between">
      {withActions ? (
        <Flex align="center" spaceBetween="S">
          <Button
            disabled={!approvalAbility.canPerform}
            onClick={() => onActionClick(offer, OfferAction.Approve)}
          >
            Approve
          </Button>

          <Button
            color="light"
            disabled={!counterOfferAbility.canPerform}
            onClick={() => onActionClick(offer, OfferAction.Counter)}
          >
            Counter Offer
          </Button>
        </Flex>
      ) : (
        <div />
      )}

      <Button transparent color="secondary" onClick={onClose}>
        Cancel
      </Button>
    </Flex>
  ) : null
})
