// @flow

import type { Api as FMSIExportPresetsApi } from '@r1-webui/fmsiexportpresets/src/fmsiExportPresets'
import type { Api as PresetColumnsApi } from '@r1-webui/fmsiexportpresets/src/presetColumns'
import { handleServerError } from '@r1/core-blocks'

import { fmsiExportPresetApi, presetColumnsApi } from '../../../../api/fmsiExportPresets'
import { httpClient } from '../../../../httpClient'

import type {
  IFMSIExportPresetsService,
  ColumnDefinitionBase,
  ExportPreset,
} from '../IFMSIExportPresetsService'

export class FMSIExportPresetsService implements IFMSIExportPresetsService {
  fmsiExportPresetsAPi: FMSIExportPresetsApi

  presetColumnsApi: PresetColumnsApi

  constructor() {
    this.fmsiExportPresetsAPi = fmsiExportPresetApi(httpClient)
    this.presetColumnsApi = presetColumnsApi(httpClient)
  }

  static defaultPreset: ExportPreset = {
    name: '',
    useInInvoices: false,
    selectedColumns: [],
  }

  getColumns = async (): Promise<ColumnDefinitionBase[]> => {
    const response = await this.presetColumnsApi.getAvailableColumns()

    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body.map(columnDefinition => ({
      id: columnDefinition.columnName,
      name: columnDefinition.displayName,
    }))
  }

  getPreset = async (presetId: string): Promise<ExportPreset | null> => {
    const response = await this.fmsiExportPresetsAPi.getExportPreset({ id: presetId })

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    const { columns, displayName, useInInvoices } = response.body

    const selectedColumns = columns.map(column => ({
      id: column.columnName,
      name: column.displayName,
    }))
    return { selectedColumns, useInInvoices, name: displayName }
  }

  createExportPreset = async ({ name, useInInvoices, selectedColumns }: ExportPreset) => {
    const response = await this.fmsiExportPresetsAPi.createExportPreset({
      name,
      useInInvoices,
      columns: selectedColumns.map(selectedColumn => ({
        columnName: selectedColumn.id,
        displayName: selectedColumn.name,
      })),
    })

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body
  }

  updateExportPreset = async (presetId, { name, useInInvoices, selectedColumns }: ExportPreset) => {
    const response = await this.fmsiExportPresetsAPi.updateExportPreset(
      { id: presetId },
      {
        name,
        useInInvoices,
        columns: selectedColumns.map(selectedColumn => ({
          columnName: selectedColumn.id,
          displayName: selectedColumn.name,
        })),
      },
    )

    if (response.status !== 200) {
      handleServerError(response)
      return false
    }

    return true
  }
}
