import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'

import { httpClient } from '../../../httpClient'

import { FinancialEventsScreen } from '../components/FinancialEventsScreen'

export const FinancialEvents = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Financial Events</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <FinancialEventsScreen
          httpClient={httpClient}
          gridFilter={{
            financialEventType: params.get('financialEventType'),
            purchaseOrderId: params.get('purchaseOrderId'),
          }}
          onError={() => {}}
        />
      </Content>
    </Main>
  )
}
