// @flow

import type {
  UploadImageInfo,
  ImageInfo,
} from '@r1-webui/productcatalog-productmanagement-v1/src/types'
import { imageProcessResponse } from '../components/utils/webUi'

import type { WebUiApi as ImageApi } from '../api/image/types'

export type ExtendedImage = {
  ...ImageInfo,
  uploaded: boolean,
}

const parseDataImage = (imageData: string): UploadImageInfo => ({
  contentBase64: imageData.replace(/^data:image\/(.+);base64,/, ''),
})

export const imageService = (api: ImageApi) => {
  const uploadImages = async (images: { image: UploadImageInfo, index: number }[]) =>
    api
      .uploadImages(images.map(({ image }) => image))
      .then(res => imageProcessResponse(res, images))

  const prepareAndUploadImages = async (extendedImage: ExtendedImage[]) => {
    const images = [...extendedImage]
    const newImages = []

    images.forEach(
      ({ url, uploaded }, index) =>
        !uploaded && newImages.push({ index, image: parseDataImage(url) }),
    )

    if (newImages.length > 0) {
      const imagesResponses = await uploadImages(newImages)

      if (imagesResponses.status !== 200 || imagesResponses.errorFields) {
        imagesResponses.status = 400
        return imagesResponses
      }
      imagesResponses.body.forEach(({ type, url }, index) => {
        if (type === 'UploadImageSuccessResult') {
          const imageIndex = newImages[index].index
          images[imageIndex].uploaded = true
          images[imageIndex].url = url
        }
      })
    }

    return { status: 200, images }
  }

  return {
    uploadImages,
    prepareAndUploadImages,
  }
}
