// @flow

import React from 'react'
import { Box, Button, Col, Container, Icon, Row, Select } from '@r1/ui-kit'

import { type Profile, type PickUpSetting as PickUpSettingType } from '../types'

const pickupStrategyOptions: $ReadOnlyArray<$AnyObject> = [
  {
    id: 1,
    name: 'FIFO',
  },
  {
    id: 4,
    name: 'Nearest',
  },
]

type Props = {|
  onAddSetting: () => void,
  onDeleteSetting: () => void,
  profiles: Profile[],
  settingData: PickUpSettingType,
  onProfilesChange: (values: string[]) => void,
  onPickUpStrategyChange: (value: number) => void,
|}

export const PickUpSetting = ({
  onAddSetting,
  onDeleteSetting,
  profiles,
  settingData,
  onProfilesChange,
  onPickUpStrategyChange,
}: Props) => (
  <Box>
    <Container fluid>
      <Row>
        <Col>
          <Select
            multiple
            options={profiles}
            value={settingData.profileIds}
            placeholder="Select profiles"
            onChange={onProfilesChange}
          />
        </Col>

        <Col md={4}>
          <Select
            options={pickupStrategyOptions}
            value={settingData.pickUpStrategy}
            placeholder="Select pick up strategy"
            onChange={onPickUpStrategyChange}
          />
        </Col>

        <Col md={2}>
          <Box>
            <Button transparent size="S" color="secondary" onClick={onDeleteSetting}>
              <Icon type="minus" />
            </Button>
            <Button transparent size="S" color="secondary" onClick={onAddSetting}>
              <Icon type="plus" />
            </Button>
          </Box>
        </Col>
      </Row>
    </Container>
  </Box>
)
