/* eslint-disable no-nested-ternary */
// @flow

import React, { useCallback, useState, useMemo, useContext, useEffect } from 'react'
import styled from '@emotion/styled'
import { H2, Flex, Box, Placeholder, Text, Link } from '@r1/ui-kit'

import { ServiceContext } from '../../../../provider'
import type { PartsCategoriesProps } from '../../../../types/category.type'
import { useToggle } from '../../../../../../../../utils/hooks'
import { CategoriesTreeSelect } from '../../../../../../components/Category/CategoriesTreeSelect/CategoriesTreeSelect'

export const CollapseWrapper = styled('div')`
  margin: 12px 0;
  > a {
    font-size: 14px;
    margin: 24px 0;
    display: block;
  }
`

/** return path to the passed id - array of names */
const getAllPath = (categories, id) => {
  if (!categories[id]) return []
  return categories[id].parentId !== undefined
    ? [...getAllPath(categories, categories[id].parentId), categories[id].name]
    : [categories[id].name]
}

/** return all children ids recursively */
const findAllChild = (categoriesObject, categoriesArray, id) => {
  if (categoriesObject[id].hasChild) {
    return categoriesArray
      .filter(({ parentId }) => parentId === id)
      .reduce(
        (acc, val) => [...acc, ...findAllChild(categoriesObject, categoriesArray, val.id)],
        [],
      )
  }
  return [id]
}

/**
 * compare recursively
 * start from index = 0 and go deeper, if path up to the current index is the same
 * a and b = param from Array.sort
 */
const comparePath = (a, b, index = 0) => {
  if (a.path.length === 0 || b.path.length === 0) return 0
  if (a.path[index] === b.path[index]) {
    if (a.path.length === index + 1 && b.path.length === index + 1) return 0 // completely identical

    if (a.path.length === index + 1 || b.path.length === index + 1)
      // The current index is the last index of one category
      // It means that category with less path is sorted before another category
      return a.path.length < b.path.length ? -1 : 1

    return comparePath(a, b, index + 1)
  }
  return a.path[index] < b.path[index] ? -1 : 1
}

export const PartsCategories = (props: PartsCategoriesProps) => {
  const defaultShowCount = 5
  const { values, handleChange } = props
  const { httpClient, categoryService } = useContext(ServiceContext)

  const [categoriesList, setCategoriesList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { state: isShowAll, toggle: setShowAll } = useToggle(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const categories = await categoryService.getAllCategories()
    setIsLoading(false)
    setCategoriesList(categories)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const categories = useMemo(
    () =>
      categoriesList.reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            ...item,
            hasChild: categoriesList.filter(({ parentId }) => parentId === item.id).length > 0,
          },
        }),
        {},
      ),
    [categoriesList],
  )

  const fitmentsLength = values.fitments.length

  const fitments = useMemo(
    () =>
      values.fitments
        .map(categoryId => ({
          categoryId,
          path: getAllPath(categories, categoryId),
        }))
        .sort(comparePath),
    [values.fitments, categories],
  )

  const handlePartsChange = useCallback(
    val => {
      if (handleChange === undefined) return

      const newValues = val.reduce(
        (acc, id) => [...acc, ...findAllChild(categories, categoriesList, id)],
        [],
      )

      handleChange('fitments', newValues)
    },
    [categories],
  )

  const slicedFitments = useMemo(
    () => (isShowAll ? fitments : fitments.slice(0, defaultShowCount)),
    [fitments, isShowAll],
  )

  return (
    <Flex column>
      <Box>
        <Flex align="center">
          <H2>Parts Mapping</H2>
        </Flex>
      </Box>

      {isLoading ? (
        <Placeholder type="form" height={3} />
      ) : handleChange !== undefined ? (
        // on create and edit screen
        <CategoriesTreeSelect
          multiple
          width={825}
          placeholder="Select part categories"
          httpClient={httpClient}
          categoriesIds={values.fitments}
          onChangeCategoriesIds={handlePartsChange}
        />
      ) : (
        // on view screen
        <>
          {slicedFitments.map(({ categoryId, path }) => (
            <Box key={categoryId} my="S">
              {path.join(' / ')}
            </Box>
          ))}
          {values.fitments.length === 0 && <Text>There are no mapped fitments yet</Text>}
          {defaultShowCount < fitmentsLength && (
            <CollapseWrapper>
              <Link onClick={() => setShowAll()}>
                {isShowAll ? 'Hide' : <>Show all ({fitmentsLength})</>}
              </Link>
            </CollapseWrapper>
          )}
        </>
      )}
    </Flex>
  )
}
