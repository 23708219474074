import React, { useCallback } from 'react'

import { ContentHeader } from '@r1/wireframe-primary'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Modal, Button, Flex, Placeholder, Icon } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import type { InteractionType } from '../../../types/common'

import {
  ContactUserInfo,
  MainUserInfo,
  UserAuth,
  UserCard,
  UserImageEditor,
  UserLocalizationSettings,
  UserHistoryModal,
} from '../../../components'
import type { BaseUserEditFormProps } from '../../../types/UserEditForm'

import { httpClient } from '../../../../../httpClient'

const BasicInfoPlaceholder = () => {
  const [{ allowUserEdit }] = useAccessControl()

  return (
    <React.Fragment>
      <Flex column pt="M">
        <Flex justify="space-between" pt="XL">
          <Flex column spaceBetween="XL" minWidth={410}>
            <Placeholder type="form" height={4} />
            <Placeholder type="form" height={2} />
            <Placeholder type="form" height={1} />
          </Flex>
          <Flex column spaceBetween="XL" minWidth={410}>
            <Placeholder type="rectangle" height={300} />
            <Placeholder type="form" height={2} />
          </Flex>
        </Flex>
      </Flex>
      <ContentHeader.ActionButtons>
        <Button transparent disabled color="secondary">
          Close
        </Button>
        {allowUserEdit && (
          <Button disabled color="primary">
            Edit
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </React.Fragment>
  )
}

type Props = BaseUserEditFormProps & {
  isActiveTab: boolean
}

const BasicInfo = (props: Props) => {
  const {
    userId,
    editableInfo,
    displayInfo,
    activationInfo,
    imageEditorInfo,
    formActions,
    formEditMode,
    hasUnsavedChanges,
    isActiveTab,
  } = props

  const [{ allowUserEdit }] = useAccessControl()

  const isSubmitting = formActions.saveUser.isExecuting ? formActions.saveUser.isExecuting : false
  const interactionType: InteractionType = formEditMode ? 'Edit' : 'View'

  const userAuthProps = {
    disabled: isSubmitting,
    interactionType,
    login: editableInfo.login,
    password: editableInfo.password,
    confirmPassword: editableInfo.confirmPassword,
    changePasswordOnNextLogin: editableInfo.changePasswordOnNextLogin,
  }

  const mainUserInfoProps = {
    disabled: isSubmitting,
    interactionType,
    fullName: editableInfo.fullName,
    jobTitle: editableInfo.jobTitle,
    userCompany: editableInfo.userCompany,
    location: editableInfo.location,
    manager: editableInfo.manager,
    department: editableInfo.department,
    externalId: editableInfo.externalId,
    userType: editableInfo.userType,
    printExternalId: formActions.printExternalId,
    isSalesRepresentative: editableInfo.isSalesRepresentative,
    reportLogin: editableInfo.reportLogin,
  }

  const contactUserInfoProps = {
    disabled: isSubmitting,
    editable: formEditMode,
    phone: editableInfo.phone,
    phoneExtension: editableInfo.phoneExtension,
    email: editableInfo.email,
  }

  const { originalFullName } = props.displayInfo

  const userCardProps = {
    editable: formEditMode,
    profileImageSrc: imageEditorInfo.visible ? undefined : imageEditorInfo.profileImageSrc,
    activationInfo,
    disabled: isSubmitting,
    fullName: originalFullName,
    createdOn: displayInfo.createdOn,
    lastLoggedIn: displayInfo.lastLoggedIn,
    onUploadImage: imageEditorInfo.onUploadImage,
    onEditImage: imageEditorInfo.onShow,
    onDeleteImage: imageEditorInfo.onDeleteImage,
  }

  const userImageEditorProps = {
    visible: imageEditorInfo.visible,
    imageSrc: imageEditorInfo.profileImageSrc,
    onCancel: imageEditorInfo.onCancel,
    onChangeImage: imageEditorInfo.onChangeImage,
  }

  const userLocalizationSettingsProps = {
    editable: formEditMode,
    disabled: isSubmitting,
    dateFormat: editableInfo.dateFormat,
    timeZone: editableInfo.timeZone,
  }

  const HistoryOpenBtn = ({ onOpen }: { onOpen: () => void }) => {
    return (
      <Button transparent color="secondary" shape="square" onClick={onOpen}>
        <Icon type="history" />
      </Button>
    )
  }

  const deleteModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Delete',
          color: 'danger',
          onClick: () => {
            imageEditorInfo.onDeleteImage()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [imageEditorInfo],
  )

  const discardModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'NO',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'YES',
          onClick: () => {
            formActions.switchOffEditMode.dispatch()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [formActions],
  )

  return (
    <React.Fragment>
      <ContentHeader.Title>{props.displayInfo.originalFullName}</ContentHeader.Title>
      <Flex pt="M" justify="space-between">
        <Flex justify="space-between" pt="XL" pb="XXL">
          <Flex column spaceBetween="XL">
            <UserAuth {...userAuthProps} />
            <MainUserInfo {...mainUserInfoProps} />
            <ContactUserInfo {...contactUserInfoProps} />
            <UserLocalizationSettings {...userLocalizationSettingsProps} />
          </Flex>
          <UserImageEditor {...userImageEditorProps} />
        </Flex>
        <Flex column pt="M">
          <Flex justify="space-between" pt="XL" pb="XXL">
            <Modal
              isControlled={false}
              title="Warning"
              actionButtons={deleteModalActionButtons}
              buttonElement={({ onOpen }) => <UserCard {...userCardProps} onDeleteImage={onOpen} />}
            >
              Are you sure you want to delete the image?
            </Modal>
          </Flex>
        </Flex>
      </Flex>

      {isActiveTab && (
        <ContentHeader.ActionButtons>
          {formActions.cancelForm.visible && (
            <Button color="secondary" onClick={formActions.cancelForm.dispatch}>
              Close
            </Button>
          )}
          {formActions.switchOnEditMode.visible && allowUserEdit && (
            <Button color="primary" onClick={formActions.switchOnEditMode.dispatch}>
              Edit
            </Button>
          )}

          {formActions.switchOnEditMode.visible && (
            <UserHistoryModal
              isControlled={false}
              userId={userId}
              username={props.displayInfo.originalFullName}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              httpClient={httpClient}
              buttonElement={HistoryOpenBtn}
            />
          )}
          {formActions.switchOffEditMode.visible && (
            <Modal
              isControlled={false}
              title="You have unsaved data"
              actionButtons={discardModalActionButtons}
              buttonElement={({ onOpen }) => (
                <Button
                  transparent
                  color="secondary"
                  onClick={() => {
                    hasUnsavedChanges ? onOpen() : formActions.switchOffEditMode.dispatch()
                  }}
                >
                  Cancel
                </Button>
              )}
            >
              Are you sure you want to discard unsaved data?
            </Modal>
          )}
          {formActions.saveUser.visible && (
            <Button color="primary" loading={isSubmitting} onClick={formActions.saveUser.dispatch}>
              Save
            </Button>
          )}
        </ContentHeader.ActionButtons>
      )}
    </React.Fragment>
  )
}

export { BasicInfo, BasicInfoPlaceholder }
