import React from 'react'

export function ReportWarningIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29868 11.5589C0.741442 12.5298 1.43618 13.749 2.55067 13.749H13.4494C14.5639 13.749 15.2587 12.5298 14.7014 11.5589L9.25204 2.06121C8.6948 1.09027 7.30531 1.09027 6.74807 2.06121L1.29868 11.5589ZM7.27636 5.66632V8.54556C7.27636 8.94707 7.60203 9.27559 8.00006 9.27559C8.39809 9.27559 8.72375 8.94707 8.72375 8.54556V5.66632C8.72375 5.2648 8.39809 4.93629 8.00006 4.93629C7.60203 4.93629 7.27636 5.2648 7.27636 5.66632ZM8.72375 11.8335V10.3734H7.27636V11.8335H8.72375Z"
        fill="#FFBC33"
      />
    </svg>
  )
}
