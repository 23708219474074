import { Notice, replaceTemplateValues } from '../../../utils/responseUtils'
import type { ServerValidationError } from './contracts'

export const getBadPropertiesErrors = (
  badProperties: ReadonlyArray<Notice>,
): ServerValidationError => {
  const errorData = badProperties.map(badProperty => {
    let propertyName = (badProperty.property as string | undefined) || ''
    const normalizedBadProperty = { ...badProperty }
    if (propertyName) {
      propertyName = propertyName.slice(propertyName.lastIndexOf('.') + 1)
      normalizedBadProperty.property = propertyName
    }

    return {
      fieldName: propertyName,
      message: replaceTemplateValues(normalizedBadProperty),
    }
  })

  return {
    $type: 'Validation',
    message: 'Bad form properties',
    data: {
      errors: errorData,
    },
  }
}
