import { createSupplyLevelApi, baseUri } from '@r1-webui/legacy-parts-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/fmsi-export`

export const supplyLevelApi = createSupplyLevelApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `${API_PREFIX}/${baseUri}`,
})
