import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Flex, Button, Icon } from '@r1/ui-kit'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'

import { ROUTES } from '../../../../../navigation'
import { httpClient } from '../../../../../httpClient'

const ActionButton = ({
  redirectToFamilyCreatePage,
}: {
  redirectToFamilyCreatePage: () => void
}) => (
  <Button data-test-id="um-add-new-role-btn" onClick={redirectToFamilyCreatePage}>
    <Icon type="plus" color="inverted" />
    Add new
  </Button>
)

export const RolesGrid = () => {
  const navigate = useNavigate()
  const initialStateQuery = useMemo(parseQuery, [])
  const [{ allowRoleCreate }] = useAccessControl()
  const redirectToRoleCreatePage = useCallback(() => navigate(`${ROUTES.ROLES}/new`), [navigate])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowRoleView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Title>Roles</ContentHeader.Title>

        <Content>
          <Flex column>
            <GridGenerator
              gridId="roles"
              gridEngineBaseUrl="/uisvc/user-management-grid"
              initialStateQuery={initialStateQuery}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              httpClient={httpClient}
              aggregationCount={{ title: 'All Roles' }}
              toolbarActionButtons={
                allowRoleCreate && (
                  <ActionButton redirectToFamilyCreatePage={redirectToRoleCreatePage} />
                )
              }
              onChangeState={setQuery}
            />
          </Flex>
        </Content>
      </Main>
    </PageAccessControl>
  )
}
