import { createModelModule, mapReducers } from '../../utils'
import { createReducer } from '../../../redux/utils'
import { productTemplateManifestPriceApi } from '../../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, actions, selectors } = createModelModule({
  name: '@productTemplates/price/manifest',
  params: ['id'],
  fetchItemFunc: async ({ id }, _api) =>
    // eslint-disable-next-line no-return-await
    await productTemplateManifestPriceApi.getManifestPrices({ id }).then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { actions, selectors }
