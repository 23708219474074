// @flow

import { createFmsiExportPresetsApi, baseUri } from '@r1-webui/fmsiexportpresets'

const API_PREFIX = '/uisvc/fmsi-export'

export const fmsiExportPresetApi = (http: $AnyObject) =>
  createFmsiExportPresetsApi({
    http,
    urlPrefix: `${API_PREFIX}/${baseUri}`,
  })
