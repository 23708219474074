// @flow

import { handleServerError } from '@r1/core-blocks'

type TCategoryNormalization = {
  data: any,
  error: string | null,
}

export const Normalization = (response: any): Promise<TCategoryNormalization> =>
  Promise.resolve(response)
    .then(data => {
      switch (data.status) {
        case 200:
          return { error: null, data: data.body }
        case 409:
          throw new Error(data.body.defaultFormat)
        case 401:
          throw new Error('Unauthorized')
        case 404:
          throw new Error('Category not found')
        case 403:
          throw new Error("User doesn't have a permission to perform the operation")
        case 422:
          throw new Error(data.body.error.defaultFormat)
        case 400:
          throw new Error('Validation error')
        default:
          handleServerError(data)
          throw new Error('common')
      }
    })
    .catch(error => ({ error: error.message, data: null }))
