import * as React from 'react'
import { injectGlobal } from '@emotion/css'
import { theme } from '@r1/ui-kit'
import { reducer as formReducer } from 'redux-form'
import { loadingBarReducer } from 'react-redux-loading-bar'
import { BrowserRouter as Router } from 'react-router-dom'
import { browserHistory } from '../browserHistory'

import { reducers as moduleReducers } from '../../modules'

import * as masterLayout from '../MasterLayout'
import documentGeneratorApp from '../../containers/label-management/reducers'
import { AppContainer } from './App'
import { reducer } from './module'
import AppRoutes from './Routes'

// eslint-disable-next-line no-unused-expressions
injectGlobal`
  body {
    background: ${theme.palette.grey[100]}; 
  }
`

export const App = () => {
  const [state, setState] = React.useState({
    action: browserHistory.action,
    location: browserHistory.location,
  })

  React.useLayoutEffect(() => browserHistory.listen(setState), [])

  return (
    <AppContainer>
      <Router location={state.location} navigationType={state.action} navigator={browserHistory}>
        <AppRoutes />
      </Router>
    </AppContainer>
  )
}

export const reducers = {
  app: reducer,
  ...masterLayout.reducers,
  ...moduleReducers,

  form: formReducer,
  loadingBar: loadingBarReducer,
  labelManagement: documentGeneratorApp,
}
