import { httpClient } from '../../httpClient'

const API_PREFIX = '/uisvc/espm'

type Purpose = {
  id: 'ShippingProfile' | 'ThirdPartyShippingProfile'
}

type RelatedProfile = {
  profileTypeId: string
}

export type ProfileType = {
  id: string
  name: string
  purpose: Purpose
  relatedProfileType?: RelatedProfile
}

export type Profile = {
  id: string
  type: ProfileType
  name: string
}

export const legacyEspm = {
  getProfilesByPurpose: (purpose: string): Promise<Profile[]> =>
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    httpClient.get(`${API_PREFIX}/api/purpose/${purpose}/profile`),
  getTypesByPurpose: (purpose: string): Promise<ProfileType[]> =>
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    httpClient.get(`${API_PREFIX}/api/purpose/${purpose}/type`),
}
