// @flow

import * as React from 'react'
import { FormField, Icon, Tooltip, Flex } from '@r1/ui-kit'
import { Section } from '../../Section'
import { Text } from './Text'
import {
  NumberConstraint,
  StringConstraint,
  DateConstraint,
  NumberRangeConstraint,
  DateRangeConstraint,
  EnumerationConstraint,
} from './components'
import type { AttributeValueConstraint, EnumValueArray } from './types'

// eslint-disable-next-line consistent-return
const getCurrentConstraints = (value, enumValues) => {
  // eslint-disable-next-line default-case
  switch (value.valueType) {
    case 'NumberConstraint':
      return <NumberConstraint value={value} />
    case 'StringConstraint':
      return <StringConstraint value={value} />

    case 'DateConstraint':
      return <DateConstraint value={value} />

    case 'NumberRangeConstraint':
      return <NumberRangeConstraint value={value} />

    case 'DateRangeConstraint':
      return <DateRangeConstraint value={value} />

    case 'EnumerationConstraint':
      return (
        <EnumerationConstraint value={value} enumValues={enumValues ? enumValues.enumValues : []} />
      )
  }
}

type Props = {| value: AttributeValueConstraint, enumValues?: EnumValueArray, inherited?: boolean |}

const InfoIcon = () => (
  <Tooltip text="Not editable constraints are inherited and could be customized only in parent category. ">
    <Icon type="info" />
  </Tooltip>
)

export const Constraint = ({ value, enumValues, inherited }: Props) => {
  return (
    <Section title="Constraints" titleIcon={inherited ? <InfoIcon /> : null}>
      <Flex spaceBetween="XXS">
        <FormField.Label>Title</FormField.Label>
        <Text semibold>Values</Text>
      </Flex>

      {getCurrentConstraints(value, enumValues)}
    </Section>
  )
}
