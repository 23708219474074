import * as PIXI from 'pixi.js'

import { Handles } from '../../constants'
// import { Position, Size, Color, PageSetting, Block, Container } from '../../types'
import UnitsConverter from './UnitsConverter'
import DragContainer from './DragContainer'
import { createBlockDisplayObject } from './BlockDisplayObjectFactory'
import { addDragSelectEvents } from './events'

const PX_IN_INCH = 96.0
const PAGE_COLOR = 0xffffff
const PAGE_BORDER_COLOR = 0xaaaaaa
const PAGE_CORNER_RADIUS = 0.07
const LINE_WIDTH_PX = 2

const converter = new UnitsConverter(PX_IN_INCH)

const createRect = (position, size, color) => {
  const rect = new PIXI.Graphics()
  rect.lineStyle(LINE_WIDTH_PX, color)
  rect.drawRect(position.x, position.y, size.width, size.height)

  return rect
}

const createHandle = (type, size) => {
  const handleColor = 0x1661be
  const handleSize = { width: 8, height: 8 }

  const left = () => -handleSize.width / 2
  const center = () => size.width / 2 - handleSize.width / 2
  const right = () => size.width - handleSize.width / 2
  const top = () => -handleSize.height / 2
  const middle = () => size.height / 2 - handleSize.height / 2
  const bottom = () => size.height - handleSize.height / 2

  let handlePosition

  if (type === Handles.TOP_HANDLE) {
    handlePosition = { x: center(), y: top() }
  } else if (type === Handles.BOTTOM_HANDLE) {
    handlePosition = { x: center(), y: bottom() }
  } else if (type === Handles.LEFT_HANDLE) {
    handlePosition = { x: left(), y: middle() }
  } else if (type === Handles.RIGHT_HANDLE) {
    handlePosition = { x: right(), y: middle() }
  }

  return createRect(handlePosition, handleSize, handleColor)
}

// const createHandles = (container: Container, blockPosition: Position, blockSize: Size) => {
const createHandles = (container, blockPosition, blockSize) => {
  for (const type in Handles) {
    if (Object.prototype.hasOwnProperty.call(Handles, 'type')) {
      // if (Handles.hasOwnProperty(type)) {
      const handle = createHandle(type, blockPosition, blockSize)
      container.addChild(handle)
    }
  }
}
const createSelectionRect = (block, transform) => {
  const rectColor = 0x1661be
  const rectContainer = new PIXI.Container()
  const rect = createRect({ x: 0, y: 0 }, transform.size, rectColor)

  rectContainer.addChild(rect)
  createHandles(rectContainer, transform.size)

  return rectContainer
}

export class Scene {
  /* dragContainer: Container
  stage: any
  handlers: any */

  constructor(width, height, stage, handlers) {
    this.stage = stage
    this.handlers = handlers
    this.dragContainer = new DragContainer(width, height, handlers)
    this.stage.addChild(this.dragContainer.displayObject)
  }

  clearStage() {
    this.dragContainer.displayObject.removeChildren()
  }

  setZoom = zoom => {
    converter.setZoom(zoom)
  }

  addPage(pageSetting) {
    const radius = converter.fromModel(PAGE_CORNER_RADIUS)
    const size = converter.fromModelSize(pageSetting)
    this.dragContainer.setPageSize(size)
    const graphics = new PIXI.Graphics()
    graphics.lineStyle(LINE_WIDTH_PX, PAGE_BORDER_COLOR)
    graphics.beginFill(PAGE_COLOR)
    graphics.drawRoundedRect(0, 0, size.width, size.height, radius)
    graphics.endFill()

    this.dragContainer.displayObject.addChild(graphics)
  }

  addBlocks(blocks, selectedBlockId) {
    let selectionRect = {}
    blocks.forEach(block => {
      const displayObject = createBlockDisplayObject(block, converter)
      this.dragContainer.displayObject.addChild(displayObject)

      const transform = converter.fromModelTransform(block)
      if (block.id === selectedBlockId) {
        selectionRect = createSelectionRect(block, transform)
        displayObject.addChild(selectionRect)
      }

      addDragSelectEvents({
        displayObject,
        onSelectHandler: this.handlers.onBlockSelectedHandler,
        onDragStartHandler: () => {
          selectionRect.alfa = 0.5
        },
        onDragEndHandler: (id, from, to) => {
          selectionRect.alfa = 1
          const offsetTo = { x: to.x - transform.offset.x, y: to.y - transform.offset.y }
          this.handlers.onBlockTransformChangedHandler(
            id,
            {
              position: converter.toModelPoint(from),
            },
            {
              position: converter.toModelPoint(offsetTo),
            },
          )
        },
      })
    })
  }
}
