import React, { useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
// $FlowFixMe
import { Flex, Uploader, SortableContainer, SortableElement, Label, H3, Box } from '@r1/ui-kit'
import { fileToBase64 } from '../../../../../../../../utils'

import { Image } from './children/Image'
import { Photo } from './children/Photo'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const Hint = styled('div')`
  ${({ theme }) => css`
    font-size: 16px;
    color: ${theme.palette.grey[700]};
    margin-bottom: ${theme.space.L};
  `};
`

const MAX_IMAGES_COUNT = 10
const MAX_IMAGE_SIZE = 1.2e7

export const Images = ({ values, handleChange, disabled, getError }) => {
  const toggleListing = useCallback(
    (index, image) => handleChange(['Images', index], { ...image, isListing: !image.isListing }),
    [handleChange],
  )

  const togglePrimary = useCallback(
    (index, sortedImage) => {
      const images = sortedImage || [...values.Images]
      const primaryIndex = images.findIndex(({ isPrimary }) => isPrimary)

      if (primaryIndex !== -1 && primaryIndex !== index) {
        images[primaryIndex].isPrimary = false
      }

      images[index].isPrimary = true
      images[index].isListing = true
      images.splice(0, 0, images.splice(index, 1)[0])

      handleChange(['Images'])(images)
    },
    [handleChange, values.Images],
  )

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      const images = [...values.Images]

      images.splice(newIndex, 0, images.splice(oldIndex, 1)[0])
      togglePrimary(0, images)
    },
    [togglePrimary, values.Images],
  )

  const onRemoveImage = useCallback(
    index => {
      if (values.Images.length > 1) {
        const images = [...values.Images]

        images.splice(index, 1)
        togglePrimary(0, images)
      }
    },
    [togglePrimary, values.Images],
  )

  const handleUploaderChange = useCallback(
    ({ files }) => {
      Promise.all(files.map(file => fileToBase64(file))).then(base64images => {
        const images = [
          ...values.Images,
          ...base64images.map(({ payload }) => ({
            isPrimary: false,
            isListing: false,
            url: payload,
            uploaded: false,
          })),
        ]

        togglePrimary(0, images)
      })
    },
    [togglePrimary, values.Images],
  )

  const onPhotoCreate = useCallback(
    photo => {
      const images = [
        ...values.Images,
        {
          isPrimary: false,
          isListing: false,
          url: photo,
          uploaded: false,
        },
      ]
      togglePrimary(0, images)
    },
    [togglePrimary, values.Images],
  )

  const canUpload = useMemo(() => values.Images.length < MAX_IMAGES_COUNT, [values.Images.length])
  const sortableItems = values.Images.map(image => ({ ...image, id: image.url.substr(0, 50) }))

  return (
    <Flex column minWidth={890} maxWidth={890}>
      <StyledH3>Images</StyledH3>
      {!disabled && (
        <Hint>Select images which are must be in Marketplace listings (Max 10 Images)</Hint>
      )}
      {getError('Images') && !Array.isArray(getError('Images')) && (
        <Hint>
          <Label type="error">{getError('Images')}</Label>
        </Hint>
      )}
      {!disabled && canUpload && (
        <Uploader
          multiple
          maxSize={MAX_IMAGE_SIZE}
          height={115}
          accept="image/*"
          onChange={handleUploaderChange}
        />
      )}
      {!disabled && (
        <Box minWidth={255} maxWidth={255}>
          <Photo onPhotoCreate={onPhotoCreate} />
        </Box>
      )}
      <SortableContainer distance={2} items={sortableItems} onSortEnd={onSortEnd}>
        <Flex flexWrap spaceBetween="L">
          {sortableItems.map((image, index) => (
            <SortableElement key={image.id} id={image.id} index={index} disabled={disabled}>
              <Image
                error={getError(['Images', index, 'url'])}
                src={image.url}
                disabled={disabled}
                isMain={image.isPrimary}
                isListing={image.isListing}
                onToggleListing={() => toggleListing(index, image)}
                onTogglePrimary={() => togglePrimary(index)}
                onRemove={() => onRemoveImage(index)}
              />
            </SortableElement>
          ))}
        </Flex>
      </SortableContainer>
    </Flex>
  )
}
