import * as PIXI from 'pixi.js'

export default (transform, lineThickness) => {
  const halfThickness = lineThickness / 2
  const graphics = new PIXI.Graphics()
  graphics.lineStyle(lineThickness)

  graphics.drawRect(
    halfThickness,
    halfThickness,
    transform.size.width - lineThickness,
    transform.size.height - lineThickness,
  )

  return graphics
}
