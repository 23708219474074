import { Context } from 'chartjs-plugin-datalabels'
import 'chart.js/auto'

import { ChartProps } from 'react-chartjs-2'
import { formatMoney } from '../helper'
import { Data } from '../types'

export const getChartSettings = (rawData: Data[]) => {
  const config: ChartProps<'bar', string[], string> = {
    type: 'bar',
    data: {
      labels: rawData.map(x => String(x.name)),
      datasets: [
        {
          label: '',
          data: rawData.map(x => String(x.value)),
          backgroundColor: ['#DAE7F8', '#DAE7F8'],
          borderColor: '#DAE7F8',
          borderWidth: 1,
        },
      ],
    },
    options: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          display: false,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },

        y: {
          display: false,
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter(_: unknown, context: Context) {
            if (!context.chart.data.labels) return ''
            return `${context.chart.data.labels[context.dataIndex]} (${formatMoney(
              Number(context.chart.data.datasets[0].data[context.dataIndex]),
            )})`
          },
          color: '#16293F',
          align: 'right',
          anchor: 'start',
          offset: 0,
          padding: 8,
          clip: false,
          font: {
            size: 14,
            family: 'Roboto',
          },
        },
      },
      indexAxis: 'y' as const,
    },
  }
  return config
}
