import { CarrierShippingService } from '@r1-webui/shipping-carriers-v1'
import { handleServerError } from '@r1/core-blocks'
import { carriersApi } from '../../../api/shippingCarriers'

export const getAllCarriersShippingServices = async (): Promise<CarrierShippingService[]> => {
  const res = await carriersApi.getAllCarriersShippingServices()
  if (res.status !== 200) {
    handleServerError(res)
    return []
  }

  return res.body
}
