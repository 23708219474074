// @flow

const toString = val => (val !== null ? String(val) : null)

const getDefaultDimensions = () => ({ lengthField: null, width: null, height: null, measure: 'Cm' })

const getTransformDimensions = ({ length, width, height, measure }: any) => ({
  lengthField: parseFloat(length),
  width: parseFloat(width),
  height: parseFloat(height),
  measure,
})

const setTransformDimensions = ({ lengthField, width, height, measure }: any) =>
  lengthField == null && width == null && height == null
    ? null
    : { length: toString(lengthField), width: toString(width), height: toString(height), measure }

const getDefaultWeight = () => ({ amount: null, measure: 'Kg' })

const getTransformWeight = ({ amount, measure }: any) => ({
  amount: parseFloat(amount),
  measure,
})

const setTransformWeight = ({ amount, measure }: any) =>
  amount == null ? null : { amount: toString(amount), measure }

export const schema = {
  ProductDimensionsField: {
    value: {
      getPath: ['ProductDimensionsField', 'value'],
      setPath: ['ProductDimensionsField'],
      defaultValue: getDefaultDimensions(),
      getTransform: getTransformDimensions,
      setTransform: setTransformDimensions,
    },
  },
  PackageDimensionsField: {
    value: {
      getPath: ['PackageDimensionsField', 'value'],
      setPath: ['PackageDimensionsField'],
      defaultValue: getDefaultDimensions(),
      getTransform: getTransformDimensions,
      setTransform: setTransformDimensions,
    },
  },
  PackageWeightField: {
    value: {
      getPath: ['PackageWeightField', 'value'],
      setPath: ['PackageWeightField'],
      defaultValue: getDefaultWeight(),
      setTransform: setTransformWeight,
      getTransform: getTransformWeight,
    },
  },
  ProductWeightField: {
    value: {
      getPath: ['ProductWeightField', 'value'],
      setPath: ['ProductWeightField'],
      defaultValue: getDefaultWeight(),
      setTransform: setTransformWeight,
      getTransform: getTransformWeight,
    },
  },
  TitleField: {
    value: {
      getPath: ['TitleField', 'value'],
      setPath: ['TitleField'],
    },
  },
  RetailPriceField: {
    value: {
      getPath: ['RetailPriceField', 'value'],
      setPath: ['RetailPriceField'],
      defaultValue: { amount: null, currency: 'USD', lastUpdateDate: null },
      getTransform: ({ amount, currency, lastUpdateDate }: any) => ({
        amount: parseFloat(amount),
        currency,
        lastUpdateDate,
      }),
      setTransform: ({ amount, currency }: any) =>
        amount == null ? null : { amount: toString(amount), currency },
    },
  },
  LongDescriptionField: {
    value: {
      getPath: ['LongDescriptionField', 'value'],
      setPath: ['LongDescriptionField'],
      setTransform: (val: string) => (val != null && val.length > 0 ? val : null),
    },
  },
  ShortDescriptionField: {
    value: {
      getPath: ['ShortDescriptionField', 'value'],
      setPath: ['ShortDescriptionField'],
      setTransform: (val: string) => (val != null && val.length > 0 ? val : null),
    },
  },
  TaxCodeField: {
    value: {
      getPath: ['TaxCodeField', 'value'],
      setPath: ['TaxCodeField'],
    },
  },
  SupportingUrlField: {
    value: {
      getPath: ['SupportingUrlField', 'value'],
      setPath: ['SupportingUrlField'],
    },
  },
  CountryOfOriginField: {
    code: {
      value: {
        getPath: ['CountryOfOriginField', 'value', 'code'],
        setPath: ['CountryOfOriginField'],
      },
    },
    name: {
      value: {
        getPath: ['CountryOfOriginField', 'value', 'name'],
      },
    },
  },
  BrandField: {
    value: {
      getPath: ['BrandField', 'value'],
      // setPath: ['BrandField'],
    },
  },
  ModelField: {
    name: {
      value: {
        getPath: ['ModelField', 'value', 'name'],
      },
    },
    id: {
      value: {
        getPath: ['ModelField', 'value', 'id'],
        setPath: ['ModelField'],
      },
    },
  },
  ManufacturerField: {
    name: {
      value: {
        getPath: ['ManufacturerField', 'value', 'name'],
      },
    },
    id: {
      value: {
        getPath: ['ManufacturerField', 'value', 'id'],
        setPath: ['ManufacturerField'],
      },
    },
  },
}
