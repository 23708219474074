// @flow

import * as React from 'react'
import { useCallback, useState } from 'react'

import { Box, Select, FormField, HR, H1, Button } from '@r1/ui-kit'

type Props = {
  isAllowedToEdit: boolean,
  applicationIds: Array<string>,
  applications: Array<{ id: string, name: string }>,
  renderActionsButtons: (node: React$Node) => React$Node,
  setCardApplications: (applicationIds: Array<string>) => Promise<any>,
}

export const ApplicationsCard = ({
  applicationIds,
  applications,
  setCardApplications,
  renderActionsButtons,
  isAllowedToEdit,
}: Props) => {
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedValue, selectValue] = useState(applicationIds || [])

  const onSave = useCallback(async () => {
    setLoading(true)

    const response = await setCardApplications(selectedValue)

    setLoading(false)
    setError(!!(response && response.error))
  }, [selectedValue, setCardApplications])

  const actionButton = (
    <Button disabled={!isAllowedToEdit || loading} loading={loading} onClick={onSave}>
      Save
    </Button>
  )
  return (
    <React.Fragment>
      <Box pt="M">
        <H1>Available for applications</H1>
      </Box>
      <Box pb="S">
        <FormField disabled={!isAllowedToEdit || loading} error={error}>
          <FormField.Label>Applications</FormField.Label>
          <Select
            multiple
            value={selectedValue}
            options={applications}
            loading={loading}
            onChange={selectValue}
          />
        </FormField>
      </Box>

      {renderActionsButtons(actionButton)}

      <HR />
    </React.Fragment>
  )
}
