import { Deposit } from '@r1-webui/gowholesale-depositmanagement-v1'

export type DepositHistoryGridProps = {
  deposit: Deposit
}

export enum DepositHistoryGridColumn {
  CompanyName,
  HistoryEvent,
  Date,
}
