import { NotificationSystem } from '@r1/ui-kit'
import { SubmissionError } from 'redux-form'
import { redirectToLogin } from '../../navigation'

function parseMessage(error) {
  if (error.message) return error.message
  if (error.data) {
    if (error.data.message) return error.data.message
    if (error.data.Message) return error.data.Message
    if (error.innerException && error.innerException.message) return error.innerException.message
    if (error.data.badProperties && error.data.badProperties.length > 0) {
      return error.data.badProperties.reduce((finalMessage, badProperty, index) => {
        if (index === 0) return badProperty.message
        return `${finalMessage} \n ${badProperty.message}`
      }, error.data.badProperties[0].message)
    }
  }
  return JSON.stringify(error)
}

function parseException(errorResponse) {
  const { statusText } = errorResponse
  const message = parseMessage(errorResponse)
  if (
    !statusText ||
    (errorResponse.data &&
      errorResponse.data.badProperties &&
      errorResponse.data.badProperties.length > 0)
  ) {
    return { statusText: 'Error', message }
  }
  return { statusText, message }
}

export function createErrorMiddleware() {
  return () => next => action => {
    let result

    try {
      result = next(action)
    } catch (error) {
      const message = error.message || error.Message || JSON.stringify(error)
      NotificationSystem.addNotification({ level: 'error', title: 'Error', message })
      throw error
    }

    if (!result || !result.then) return action

    return result.catch(errorResponse => {
      if (errorResponse instanceof SubmissionError) {
        throw errorResponse
      }

      if (!errorResponse || !errorResponse.status) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error',
          message: 'Unhandled error',
        })
        return
      }

      if (errorResponse.status === 401) {
        redirectToLogin()
        return
      }

      const { message, statusText } = parseException(errorResponse)
      NotificationSystem.addNotification({
        level: 'error',
        title: statusText,
        message,
      })
      throw errorResponse
    })
  }
}
