import * as React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mapProps, compose } from 'recompose'

const IconContainer = styled('svg')`
  color: ${({ theme, color, fill }) => color || fill || theme.palette.blue[600]};
  fill: currentColor;
  vertical-align: middle;
  cursor: pointer;
  flex-shrink: 0;
`

const enhance = compose(
  mapProps(({ size, rotate, originalWidth, originalHeight, ...props }) => ({
    style: {
      ...(size && { fontSize: size }),
      ...(rotate && { transform: `rotate(${rotate}deg)` }),
    },
    viewBox: `0 0 ${originalWidth} ${originalHeight}`,
    dimensions: {
      ...(originalWidth > originalHeight
        ? { width: '1em', height: `${1 / (originalWidth / originalHeight)}em` }
        : { width: `${1 / (originalHeight / originalWidth)}em`, height: '1em' }),
    },
    ...props,
  })),
)

const Icon = ({ style, viewBox, dimensions, children, ...props }) => (
  <IconContainer {...props} {...dimensions} style={style} viewBox={viewBox}>
    {children}
  </IconContainer>
)

Icon.propTypes = {
  size: PropTypes.string,
  rotate: PropTypes.number,
  originalWidth: PropTypes.number,
  originalHeight: PropTypes.number,
}

export default enhance(Icon)
