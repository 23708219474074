/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { successResponseHandler } from '../helpers'
import { createErrorHandler } from '../createErrorHandler'
import { createErrorHandler as createUserManagementErrorHandler } from '../userManagementErrorHandler'

import type { UserManagementApi } from '../contracts'

export const API_PREFIX = '/uisvc/user-management'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createApi(httpClient: Record<string, any>): UserManagementApi {
  const { get, post, put, delete: deleteMethod } = httpClient
  const errorHandler = createErrorHandler()
  const userManagementErrorHandler = createErrorHandler(createUserManagementErrorHandler)
  return {
    /** Users */

    createUser: userData =>
      post(`${API_PREFIX}/users`, userData)
        .then(successResponseHandler)
        .catch(userManagementErrorHandler),

    getUser: userId =>
      get(`${API_PREFIX}/users/${userId}`).then(successResponseHandler).catch(errorHandler),

    saveUser: (userId, userInfo) =>
      put(`${API_PREFIX}/users/${userId}`, userInfo)
        .then(successResponseHandler)
        .catch(userManagementErrorHandler),

    disableUser: (userId, disabled) =>
      put(`${API_PREFIX}/users/${userId}/disabled`, { disabled })
        .then(successResponseHandler)
        .catch(errorHandler),

    updateProfileImage: (userId, imageInfo) =>
      put(`${API_PREFIX}/users/${userId}/profile-image`, imageInfo)
        .then(successResponseHandler)
        .catch(errorHandler),

    deleteProfileImage: userId =>
      deleteMethod(`${API_PREFIX}/users/${userId}/profile-image`)
        .then(successResponseHandler)
        .catch(errorHandler),

    setPasswordToUser: (userId, passwordOptions) =>
      put(`${API_PREFIX}/users/${userId}/password`, passwordOptions)
        .then(successResponseHandler)
        .catch(errorHandler),

    setChangePasswordOnNextLoginToUser: (userId, value) =>
      put(`${API_PREFIX}/users/${userId}/password/changePasswordOnNextLogin`, {
        value,
      })
        .then(successResponseHandler)
        .catch(errorHandler),

    getManagers: () =>
      get(`${API_PREFIX}/users/managers`).then(successResponseHandler).catch(errorHandler),

    getUserCompanies: () =>
      get(`${API_PREFIX}/users/companies`).then(successResponseHandler).catch(errorHandler),

    getUserRoles: userId =>
      get(`${API_PREFIX}/users/${userId}/roles`)
        .then(successResponseHandler)
        .catch(userManagementErrorHandler),

    setUserRoles: (userId, roleIds) =>
      put(`${API_PREFIX}/users/${userId}/roles`, { roles: roleIds })
        .then(successResponseHandler)
        .catch(userManagementErrorHandler),

    printUserExternalId: (userId, machineId) =>
      post(`${API_PREFIX}/users/${userId}/external-id/print`, { machineId })
        .then(successResponseHandler)
        .catch(userManagementErrorHandler),

    /** Roles */

    getRoles: () => get(`${API_PREFIX}/roles`).then(successResponseHandler).catch(errorHandler),

    /** History */

    getUserHistoryReceivingToken: userId =>
      get(`${API_PREFIX}/history/users/${userId}/token`)
        .then(successResponseHandler)
        .catch(errorHandler),

    /** Date formats */

    getDateFormats: () =>
      get(`${API_PREFIX}/preference-values/date-formats`)
        .then(successResponseHandler)
        .catch(errorHandler),
  }
}
