// @flow

import * as React from 'react'

import { Select, Flex, Box, FormField, Button } from '@r1/ui-kit'

import type { Profile } from './ProfileConfigurator'

const WIDTH = 800
const PLACEHOLDER = 'Choose profile'
const LABEL_KEY = 'name'
const VALUE_KEY = 'id'

type ListProps = {
  label: string,
  value: string | null,
  options: Profile[],
  disabled: boolean,
  loading: boolean,
  onChange: (avalaraProfileId: string | null) => void,
  onSubmit: (avalaraProfileId: string | null) => Promise<void>,
}

export const ListItem = ({
  label,
  options,
  value,
  disabled,
  loading,
  onChange,
  onSubmit,
}: ListProps) => (
  <Flex spaceBetween="M" align="center">
    <Box maxWidth={WIDTH} minWidth={WIDTH}>
      <Flex spaceBetween="XXS">
        <FormField.Label>{label}</FormField.Label>
        <Select
          simpleValue
          disabled={disabled || loading}
          value={value}
          options={options}
          placeholder={PLACEHOLDER}
          valueKey={VALUE_KEY}
          labelKey={LABEL_KEY}
          onChange={onChange}
        />
      </Flex>
    </Box>
    <Box>
      <Button
        disabled={disabled}
        loading={loading}
        color="secondary"
        type="submit"
        onClick={() => {
          onSubmit(value || null)
        }}
      >
        Submit
      </Button>
    </Box>
  </Flex>
)
