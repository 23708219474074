// @flow

import styled from '@emotion/styled'

export const Text = styled('span')`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-weight: ${props => (props.semibold ? 500 : 400)};
  line-height: 24px;
  font-size: 16px;
  word-break: break-word;
`
