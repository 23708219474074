import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Content, ContentHeader, Main } from '@r1/wireframe-primary'
import React, { FC } from 'react'
import { DepositsGridController } from '../components/DepositsGrid/DepositsGridController'
import { DepositsGridView } from '../components/DepositsGrid/DepositsGridView'

export const DepositManagementDepositsGridScreen: FC = () => (
  <PageAccessControl permissions={[PERMISSIONS.allowGwsDepositManagementView]}>
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>goWholesale Deposit Management</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <DepositsGridController>
          {controllerPayload => <DepositsGridView {...controllerPayload} />}
        </DepositsGridController>
      </Content>
    </Main>
  </PageAccessControl>
)
