// @flow

import * as React from 'react'
import { Link } from '@r1/ui-kit'

type Props = {|
  legacyOrderId?: string,
  legacyCompanyOrderId?: string,
|}

export const OrderLink = React.memo<Props>(({ legacyOrderId, legacyCompanyOrderId }: Props) => {
  if (legacyOrderId && legacyCompanyOrderId) {
    return (
      <Link href={`/Modules/B2B/OrderDetails.aspx?id=${legacyOrderId}`}>
        {legacyCompanyOrderId}
      </Link>
    )
  }
  return 'N/A'
})
