// @flow

import * as React from 'react'
import { H5 } from '@r1/ui-kit'
import styled from '@emotion/styled'

type Props = {
  children: React.Node,
  icon?: ?React.Node,
}

const Text = styled(H5)`
  color: ${({ theme }) => theme.palette.grey[800]};
  margin: 0;
  margin-right: ${({ theme }) => theme.space.XS};
`

const Container = styled('div')`
  display: flex;
  align-items: center;
`

export function Title({ children, icon }: Props) {
  return (
    <Container>
      <Text>{children}</Text>

      {icon}
    </Container>
  )
}
