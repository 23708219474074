import * as React from 'react'

import { ContentHeader } from '@r1/wireframe-primary'
import { Box, Button, Flex, Stepper, DualList } from '@r1/ui-kit'

import {
  ContactUserInfo,
  GeneralInfoSummary,
  MainUserInfo,
  ProgramsSettings,
  RolesSummary,
  UserAuth,
  UserCard,
  UserImageEditor,
  UserLocalizationSettings,
} from '../../../components'

import type { MultiselectField, SelectField, InteractionType, Role } from '../../../types/common'
import type {
  GeneralInfoSummaryProps,
  UserCreationFormProps,
} from '../../../types/UserCreationForm'
import type { UserCardProps } from '../../../types/UserCard'
import type { UserImageEditorProps } from '../../../types/UserImageEditor'
import { DualListContainer } from '../../../components/common'

const extractValue = (field: SelectField): string => {
  const { value, availableOptions } = field
  if (!value) return ''
  const selectedValue = availableOptions.find(({ id }) => id === value)
  return selectedValue ? selectedValue.name : ''
}

const getFullSelectedData = (fieldData: MultiselectField<Role>) => {
  const { selected, availableOptions } = fieldData

  return availableOptions.reduce((acc: Role[], role) => {
    if (selected.includes(role.id)) {
      acc.push(role)
    }
    return acc
  }, [])
}

export const AddUserManually = (
  props: UserCreationFormProps & {
    cancelCreation: () => void
  },
) => {
  const interactionType: InteractionType = 'Create'
  const {
    generalInfo,
    programsSettingsInfo,
    rolesInfo,
    imageEditorInfo,
    stepInfo,
    actions,
    isSubmitting,
    hasUnsavedChanges,
  } = props
  const userAuthProps = {
    interactionType,
    disabled: isSubmitting,
    login: generalInfo.login,
    password: generalInfo.password,
    confirmPassword: generalInfo.confirmPassword,
    changePasswordOnNextLogin: generalInfo.changePasswordOnNextLogin,
  }

  const mainUserInfoProps = {
    interactionType,
    disabled: isSubmitting,
    fullName: generalInfo.fullName,
    jobTitle: generalInfo.jobTitle,
    userCompany: generalInfo.userCompany,
    location: generalInfo.location,
    manager: generalInfo.manager,
    department: generalInfo.department,
    externalId: generalInfo.externalId,
    userType: generalInfo.userType,
    isSalesRepresentative: generalInfo.isSalesRepresentative,
    reportLogin: generalInfo.reportLogin,
  }

  const contactUserInfoProps = {
    editable: true,
    disabled: isSubmitting,
    phone: generalInfo.phone,
    phoneExtension: generalInfo.phoneExtension,
    email: generalInfo.email,
  }

  const generalInfoSummaryProps: GeneralInfoSummaryProps = {
    login: generalInfo.login.value,
    fullName: generalInfo.fullName.value,
    jobTitle: generalInfo.jobTitle.value,
    manager: extractValue(generalInfo.manager),
    userType: extractValue(generalInfo.userType),
    externalId: generalInfo.externalId.value,
    dateFormat: extractValue(generalInfo.dateFormat),
    department: generalInfo.department.value,
    email: generalInfo.email.value,
    phone: generalInfo.phone.value,
    phoneExtension: generalInfo.phoneExtension.value,
    active: generalInfo.activation.value,
    profileImageSrc: imageEditorInfo.profileImageSrc,
    userCompany: extractValue(generalInfo.userCompany),
    location: extractValue(generalInfo.location),
    reportLoginId: extractValue(generalInfo.reportLogin),
    isSalesRepresentative: `${generalInfo.isSalesRepresentative.value}`,
  }

  const userCardProps: UserCardProps = {
    editable: true,
    activationInfo: {
      disabled: false,
      active: generalInfo.activation.value,
      onToggle: generalInfo.activation.onChange,
    },
    disabled: isSubmitting,
    profileImageSrc: imageEditorInfo.visible ? undefined : imageEditorInfo.profileImageSrc,
    onEditImage: imageEditorInfo.onShow,
    onDeleteImage: imageEditorInfo.onDeleteImage,
    onUploadImage: imageEditorInfo.onUploadImage,
  }

  const userImageEditorProps: UserImageEditorProps = {
    visible: imageEditorInfo.visible,
    imageSrc: imageEditorInfo.profileImageSrc,
    onChangeImage: imageEditorInfo.onChangeImage,
    onCancel: imageEditorInfo.onCancel,
  }

  const userLocalizationSettingsProps = {
    editable: true,
    disabled: isSubmitting,
    dateFormat: generalInfo.dateFormat,
    timeZone: generalInfo.timeZone,
  }

  const { steps, currentStep } = stepInfo
  const currentStepIdx = steps.findIndex(step => step.id === currentStep)
  const stepsProps = steps.map((step, idx) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...stepProps } = step
    return {
      ...stepProps,
      complete: idx < currentStepIdx,
    }
  })

  return (
    <>
      <Flex column pt="M">
        <Box mb="XXL">
          <Stepper currentStep={currentStepIdx}>
            <Stepper.Step {...stepsProps[0]} title="General Information" />
            <Stepper.Step {...stepsProps[1]} title="Programs" />
            <Stepper.Step {...stepsProps[2]} title="Roles" />
            <Stepper.Step {...stepsProps[3]} title="Summary" />
          </Stepper>
        </Box>

        {currentStepIdx === 0 && (
          <Flex column>
            <Flex justify="space-between" pt="XL">
              <Flex column spaceBetween="XL" pb="XL">
                <UserAuth {...userAuthProps} />
                <MainUserInfo {...mainUserInfoProps} />
                <ContactUserInfo {...contactUserInfoProps} />
                <UserLocalizationSettings {...userLocalizationSettingsProps} />
              </Flex>
              <Box alignSelf="flex-start">
                <UserCard {...userCardProps} />
              </Box>
              <UserImageEditor {...userImageEditorProps} />
            </Flex>
          </Flex>
        )}

        {currentStepIdx === 1 && (
          <Flex column spaceBetween="L" pt="XXL" pb="XXL" maxWidth={0.5}>
            <ProgramsSettings
              isEditing
              userSettings={programsSettingsInfo.value}
              onChange={programsSettingsInfo.onChange}
            />
          </Flex>
        )}

        {currentStepIdx === 2 && (
          <Flex column spaceBetween="L" pt="XXL" pb="XXL" maxWidth={0.5}>
            <DualListContainer>
              <DualList
                options={rolesInfo.roles.availableOptions}
                value={rolesInfo.roles.selected}
                orderable={false}
                data-test-id="um-create-user-assign-roles"
                // @ts-expect-error
                onChange={rolesInfo.roles.onChange}
              />
            </DualListContainer>
          </Flex>
        )}

        {currentStepIdx === 3 && (
          <Flex column spaceBetween="L" pt="XXL" pb="XXL" maxWidth={0.5}>
            <GeneralInfoSummary {...generalInfoSummaryProps} />
            <ProgramsSettings isEditing={false} userSettings={programsSettingsInfo.value} />
            <RolesSummary roles={getFullSelectedData(rolesInfo.roles)} />
          </Flex>
        )}
      </Flex>
      <ContentHeader.ActionButtons>
        {actions.cancelForm.allowed && (
          <Button
            transparent
            data-test-id="um-create-user-cancel-btn"
            color="secondary"
            onClick={hasUnsavedChanges ? props.cancelCreation : actions.cancelForm.dispatch}
          >
            Cancel
          </Button>
        )}
        <Button
          data-test-id="um-create-user-prevstep-btn"
          color="secondary"
          onClick={actions.prevStep.dispatch}
        >
          Previous
        </Button>

        {actions.nextStep.allowed && (
          <Button
            data-test-id="um-create-user-nextstep-btn"
            color="primary"
            onClick={actions.nextStep.dispatch}
          >
            Next
          </Button>
        )}

        {actions.submitForm.allowed && (
          <Button
            data-test-id="um-create-user-save-btn"
            color="primary"
            loading={isSubmitting}
            onClick={actions.submitForm.dispatch}
          >
            Save
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </>
  )
}
