// @flow

const getValue = (obj, path, transform, defaultValue) => {
  let value = obj
  path.forEach(el => {
    value = value ? value[el] : null
  })

  if (value) {
    return transform ? transform(value) : value
  }

  return defaultValue != null ? defaultValue : null
}

export const serialize = <O: Object, S: Object>(object: O, schema: S): any => {
  return Object.keys(schema).reduce(
    (acc, key) =>
      key === 'value'
        ? getValue(object, schema[key].getPath, schema[key].getTransform, schema[key].defaultValue)
        : { ...acc, [key]: serialize(object, schema[key]) },
    {},
  )
}
