// @flow

// import * as React from 'react'
import { evolve, update, mergeLeft } from 'ramda'

import type { Lib$Money } from '@r1/types/flow/libTypes'

import { validate } from '../../../validation'
import type { ClaimType } from '../../../types/common'
import type { OrderLine } from '../../../types/ClaimCreation'
import type {
  ValidateField,
  EditableStepId,
  FieldName,
  Errors,
  StepToFieldMap,
  FieldToStepMap,
} from '../types'
import type { WithController } from './types'

const stepToFieldMap: StepToFieldMap = {
  orderSelection: ['order'],
  claimInfo: ['expectedDecisionTypeId', 'claimDescription'],
  claimCreation: ['orderLines'],
}

const fieldToStepMap: FieldToStepMap = Object.keys(stepToFieldMap).reduce((acc, stepId) => {
  const fields = stepToFieldMap[stepId]
  fields.forEach(field => {
    acc[field] = stepId
  })
  return acc
}, {})

// eslint-disable-next-line import/no-unused-modules
export class ValidationController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  resetError = (fieldName: FieldName) => {
    const stepId = fieldToStepMap[fieldName]
    const stepIdx = this.component.navigation.getStepIdxById(stepId)
    const stepData = this.component.navigation.getStepDataById(stepId)

    const newErrors: Errors = {}
    newErrors[fieldName] = ''

    this.component.setState(state => {
      const hasError = Object.keys(newErrors).some((key: FieldName) => {
        if (key === fieldName) return false
        return !!state.errors[key]
      })

      return evolve(
        {
          navigation: {
            steps: update(stepIdx, { ...stepData, error: hasError }),
          },
          errors: mergeLeft(newErrors),
        },
        state,
      )
    })
  }

  resetOrderLineFieldErrors = (lineId: string) => {
    this.component.setState(state => {
      const { orderLines } = state.claimCreation
      const orderLine = orderLines.find(ol => ol.lineId === lineId)
      if (!orderLine) {
        return null
      }

      orderLine.returnQuantityError = ''
      orderLine.returnReasonIdError = ''
      orderLine.refundAmountError = ''
      orderLine.replacementsError = ''

      return evolve(
        {
          claimCreation: mergeLeft({
            orderLines,
          }),
        },
        state,
      )
    })
  }

  validateField: ValidateField = (fieldName: FieldName, value: any, state: any) => {
    return validate(fieldName, value, state)
  }

  validateReturnQuantity = (value: number, quantityLimit: number) => {
    const error =
      !value || value < 1 || quantityLimit < value
        ? 'Return quantity must be between 1 and order quantity'
        : ''

    return error
  }

  validateReturnReason = (value: string, claimType: ?ClaimType) => {
    const error = !value
      ? `${claimType === 'RefundOnly' ? 'Refund' : 'Return'} reason is required`
      : ''
    return error
  }

  validateRefundAmount = (value: Lib$Money, productTotal: Lib$Money) => {
    const error =
      !value.amount ||
      Number.isNaN(value.amount) ||
      parseFloat(value.amount) <= 0 ||
      Number(productTotal.amount) < parseFloat(value.amount)
        ? 'Refund amount must be not greater than product total'
        : ''

    return error
  }

  validateReplacements = (currentOrderLine: OrderLine, orderLines: OrderLine[]) => {
    const { replacements, lineId } = currentOrderLine
    const lineIndex = orderLines.indexOf(currentOrderLine)

    let error = ''

    if (!replacements.length) {
      error = `At least one replacement should be specified for the order line #${
        orderLines.indexOf(currentOrderLine) + 1
      }`
    } else {
      replacements.forEach(replacement => {
        const replacementId = replacement.productId
        orderLines.forEach((orderLine, index) => {
          if (orderLine.lineId === lineId) return
          if (!orderLine.selected) return

          const orderLineReplacementIds = orderLine.replacements.map(r => r.productId)
          if (orderLineReplacementIds.includes(replacementId)) {
            error = `The replacement ${replacement.trgId || 'TRG-UNKNOWN'} in the order line #${
              lineIndex + 1
            } has a duplicate replacement in the order line #${index + 1}.`
          }
        })
      })
    }

    return error
  }

  validateStepById = (stepId: EditableStepId): boolean => {
    const stepFields = stepToFieldMap[stepId]

    let hasStepErrors = false
    const newErrors: Errors = {}

    stepFields.forEach(fieldName => {
      const value = this.component.state.claimCreation[fieldName]
      const error = this.validateField(fieldName, value, this.component.state.claimCreation)

      newErrors[fieldName] = error
      hasStepErrors = hasStepErrors || !!error
    })

    this.component.setState(
      evolve({
        errors: mergeLeft(newErrors),
      }),
    )

    return hasStepErrors
  }

  validateStepPart = (fieldName: FieldName, valueOrValidationFn: any, state: any): boolean => {
    const error =
      typeof valueOrValidationFn === 'function'
        ? valueOrValidationFn()
        : this.validateField(fieldName, valueOrValidationFn, state)

    const newErrors: Errors = {}
    newErrors[fieldName] = error

    this.component.setState(
      evolve({
        errors: mergeLeft(newErrors),
      }),
    )

    return !!error
  }

  /**
   * @returns {boolean} failed validation
   */
  validateCurrentStep = (): boolean => {
    const { currentStep } = this.component.state.navigation
    const editableSteps: EditableStepId[] = ['orderSelection', 'claimInfo', 'claimCreation']

    const editableStep = editableSteps.find(s => s === currentStep)
    if (editableStep) {
      return this.validateStepById(editableStep)
    }

    return false
  }
}

export const createValidationController = (component: WithController) =>
  new ValidationController(component)
