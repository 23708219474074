// @flow

export const UI_API_PREFIXES = {
  CATALOG: '/uisvc/company-catalog',
  CATALOG_MANAGEMENT: '/uisvc/company-catalog-management',
  ADDRESS_MANAGEMENT: '/uisvc/address-management',
  PRICING_CONSOLIDATION: '/uisvc/catalog-data-consolidator',
  PRICING_RULES: '/uisvc/product-prices',
  PRICING_RULES_MANAGEMENT: '/uisvc/price-consolidation-rule-management',
  MARKETPLACE_PROFILE_MANAGEMENT: '/uisvc/marketplace-profile-management',
  DATA_SOURCE_INFO: '/uisvc/data-source-info',
  MARKETPLACE_SETTINGS_MANAGEMENT: '/uisvc/marketplace-settings-management',
  PRODUCT_SOURCE_DATA: '/uisvc/product-source-data',
  USER_PRINCIPAL: '/uisvc/user-principal-meta-data',
}
