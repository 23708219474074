export const errorHandler = (error = {}) => {
  const { $type = '' } = error
  const { pathname, search, hash } = window.location
  const path = pathname + search + hash
  let queryString = ''

  if (path !== '/') {
    queryString = `?path=${encodeURIComponent(path)}`
  }

  if ($type === 'Unauthorized') {
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('accessToken')

    if (pathname !== '/login') {
      window.location.href = `/login${queryString}`
    }
  }
}
