/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable react/no-access-state-in-setstate */

import * as React from 'react'

import { Pagination } from '@r1/grid'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'
import { Box, Button, Checkbox, Flex, Modal, Text } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'

import createApi, { handleNotification } from '../../api'
import { profilesApi } from '../../api/api.ts'

import { remapPagination } from '../utils'
import { PurchaseOrdersFilter } from './PurchaseOrdersFilter'
import { PurchaseOrdersGrid } from './PurchaseOrdersGrid'
import {
  AUTOSUBMITTED_PROFILES_OPTION_ID,
  filterNullable,
  MARKETPLACE_PURPOSE_ID,
  PurchaseOrderComparator,
  sortSubdivisions,
} from './utils'
import { TaxCommitmentGrid } from './TaxCommitmentGrid'
import { TotalsBar } from './TotalsBar'

const availablePageSizes = [200, 500, 1000, 2000, 5000, 10000, 25000]

const countries = [
  {
    queryCode: 'USA',
    code: 'US',
    name: 'United States',
  },
  {
    queryCode: 'CAN',
    code: 'CA',
    name: 'Canada',
  },
]

export class PurchaseOrdersScreen extends React.Component {
  /* eslint-disable react/no-unused-state */
  state = {
    purchaseOrders: [],
    totalOrdersPrincipal: [],
    totalOrdersTax: [],
    totalOrdersTaxWithheld: [],
    totalOrdersShippingCost: [],
    totalOrdersShippingTax: [],
    totalOrdersShippingTaxWithheld: [],
    totalOrdersTotal: [],
    totalsData: [],
    venueProfiles: [],
    autosubmittedVenueProfileIds: [],
    venueProfileNamesMapById: {},
    subdivisions: [],
    chosen: [],
    filter: {},
    offset: 0,
    limit: 1000,
    totalCount: 0,
    isLoading: false,
    isSubmitting: false,
    openedPurchaseOrderModalId: {},
  }

  api = createApi(this.props.httpClient, {
    onError: this.props.onError || (() => {}),
    onNotify: handleNotification,
  })

  async componentDidMount() {
    await Promise.all([
      this.loadPurchaseOrderData(),
      this.loadVenueProfiles(),
      this.loadSubdivisions(),
    ])
  }

  closeTaxCommitmentGridModal = () => this.setState({ openedPurchaseOrderModalId: {} })

  loadPurchaseOrderData = async ({ filter, offset, limit } = {}) => {
    this.setState({ isLoading: true })

    const PurchaseOrdersData = await this.api.getPurchaseOrders({
      filter: filterNullable(filter || this.state.filter),
      offset: offset != null ? offset : this.state.offset,
      limit: limit != null ? limit : this.state.limit,
    })

    if (PurchaseOrdersData == null) {
      this.setState({
        isLoading: false,
      })
      return null
    }
    const totals = {
      totalOrdersPrincipal: PurchaseOrdersData.totalOrdersPrincipal,
      totalOrdersTax: PurchaseOrdersData.totalOrdersTax,
      totalOrdersTaxWithheld: PurchaseOrdersData.totalOrdersTaxWithheld,
      totalOrdersShippingCost: PurchaseOrdersData.totalOrdersShippingCost,
      totalOrdersShippingTax: PurchaseOrdersData.totalOrdersShippingTax,
      totalOrdersShippingTaxWithheld: PurchaseOrdersData.totalOrdersShippingTaxWithheld,
      totalOrdersTotal: PurchaseOrdersData.totalOrdersTotal,
    }
    const totalsData = []
    const entries = Object.entries(totals)
    for (let i = 0; i < entries.length; i += 1) {
      for (let j = 0; j < entries[i].length; j += 1) {
        totalsData[j] = { ...(totalsData[j] || {}), [entries[i][0]]: entries[i][1][j] }
      }
    }

    this.setState({
      ...totals,
      purchaseOrders: PurchaseOrdersData.purchaseOrders,
      totalCount: PurchaseOrdersData.totalCount,
      totalsData,
      isLoading: false,
      chosen: [],
    })
    return PurchaseOrdersData
  }

  loadVenueProfiles = async () => {
    const venueProfilesResponse = await this.api.fetchVenueProfiles(MARKETPLACE_PURPOSE_ID)

    if (venueProfilesResponse === null) {
      this.setState({
        isLoading: false,
      })
    } else {
      const autoSubmitInfo = await this.loadVenueProfilesAutoSubmitInfo()
      const allAutosubmittedVenueProfileIds = autoSubmitInfo
        .filter(info => info.autoSubmitted)
        .map(info => info.venueProfileId)

      const venueProfiles = [
        { id: AUTOSUBMITTED_PROFILES_OPTION_ID, name: 'Autosubmitted' },
        ...venueProfilesResponse.map(profile => {
          const isAutosubmitted = allAutosubmittedVenueProfileIds.includes(profile.id)
          return {
            ...profile,
            autoSubmitted: isAutosubmitted,
            parentId: isAutosubmitted ? AUTOSUBMITTED_PROFILES_OPTION_ID : null,
          }
        }),
      ]

      this.setState({
        venueProfiles,
        autosubmittedVenueProfileIds: venueProfiles
          .filter(profile => profile.autoSubmitted)
          .map(profile => profile.id),
        venueProfileNamesMapById: venueProfiles.reduce(
          (prevProfile, curProfile) => ({
            ...prevProfile,
            [curProfile.id]: curProfile.name,
          }),
          {},
        ),
      })
    }
  }

  loadVenueProfilesAutoSubmitInfo = async () => {
    const response = await profilesApi.getProfiles()

    if (response.status === 200) {
      return response.body
    }

    handleServerError(response)
    return []
  }

  loadSubdivisions = async () => {
    const subdivisionsWithoutCountries = await Promise.all(
      countries.map(({ queryCode }) => this.api.getSubdivisions({ code: queryCode })),
    )

    if (subdivisionsWithoutCountries.some(sub => sub == null)) {
      this.setState({
        isLoading: false,
      })
      return this.state.subdivisions
    }

    const subdivisions = [].concat(
      ...subdivisionsWithoutCountries.map((country, i) => {
        return country.map(sub => ({
          countryCode: countries[i].code,
          countrySubdivisionCode: sub.code,
          countryName: countries[i].name,
          countrySubdivisionName: sub.name,
        }))
      }),
    )

    this.setState({ subdivisions: subdivisions.sort(sortSubdivisions) })
    return null
  }

  showTaxCommitmentGridModal =
    ({ purchaseOrderId, venueProfileId }) =>
    () =>
      this.setState({ openedPurchaseOrderModalId: { purchaseOrderId, venueProfileId } })

  submitPurchaseOrdersAll = async () => {
    if (this.state.chosen.length === 0) return
    await this.api.submitPurchaseOrders({
      purchaseOrderSubmissionData: this.state.chosen,
    })
    this.setState({ chosen: [] })
  }

  submitPurchaseOrder = async orderUniqueId => {
    this.setState({ isSubmitting: true })
    await this.api.submitPurchaseOrders({
      purchaseOrderSubmissionData: [orderUniqueId],
    })
    this.setState({ isSubmitting: false })
  }

  voidTaxCommitmentDocument = taxCommitmentDocumentId => async params => {
    await this.api.voidTaxCommitmentDocument(taxCommitmentDocumentId, params)
  }

  openR1Orders = async ({ venueProfileId, purchaseOrderId }) => {
    const linkId = await this.api.getIdToR1Orders({
      MarketplacePo: [String(purchaseOrderId)],
      VenueProfileIdTable: [String(venueProfileId)],
    })
    window.open(
      `/Modules/Orders/SearchOrders.aspx?filter=${window.encodeURIComponent(linkId)}`,
      '_blank',
    )
  }

  onSelect = id => isSelected =>
    this.setState(({ chosen }: { chosen: Array<Object> }) => {
      if (isSelected) {
        return {
          chosen: chosen.some(value =>
            PurchaseOrderComparator(value.purchaseOrderUniqueId, id.purchaseOrderUniqueId),
          )
            ? chosen
            : [...chosen, id],
        }
      }
      return {
        chosen: chosen.filter(
          chosenId =>
            !PurchaseOrderComparator(chosenId.purchaseOrderUniqueId, id.purchaseOrderUniqueId),
        ),
      }
    })

  onSubmit = async filter => {
    await this.loadPurchaseOrderData({ filter })
    this.setState({ filter })
  }

  onChangePagination = async ({ page, pageSize }) => {
    this.setState({ isLoading: true })
    const pagination = remapPagination.to({ page, pageSize })
    const PurchaseOrdersData = await this.loadPurchaseOrderData(pagination)
    if (PurchaseOrdersData != null) {
      this.setState({
        ...pagination,
        purchaseOrders: PurchaseOrdersData.purchaseOrders,
        totalCount: PurchaseOrdersData.totalCount,
      })
    }
    this.setState({ isLoading: false })
  }

  onSelectPageClick = checkAll => {
    if (checkAll === false) {
      this.setState({ chosen: [] })
    } else {
      this.setState(state => ({
        chosen: state.purchaseOrders
          .filter(order => order.canBeSubmitted)
          .map(order => ({
            purchaseOrderUniqueId: {
              purchaseOrderId: order.purchaseOrderId,
              venueProfileId: order.venueProfileId,
            },
            taxCommitmentDocumentIds: order.taxCommitmentDocuments
              .filter(doc => !doc.submittedOn)
              .map(doc => doc.taxCommitmentDocumentId),
          })),
      }))
    }
  }

  // Hack because of virtualization don't listen height changes in windowScroller LEG-1224
  triggerVirtualizationToGetNewTopOffset = () => this.forceUpdate()

  render() {
    const {
      purchaseOrders,
      venueProfiles,
      autosubmittedVenueProfileIds,
      venueProfileNamesMapById,
      subdivisions,
      chosen,
      offset,
      limit,
      isLoading,
      isSubmitting,
      openedPurchaseOrderModalId,
      totalsData,
      totalCount,
    } = this.state
    const newPurchaseOrderArray = purchaseOrders.map(order => ({
      ...order,
      venueProfileName: venueProfileNamesMapById[order.venueProfileId],
      onSelect: this.onSelect,
      isChosen: chosen.some(value => PurchaseOrderComparator(value.purchaseOrderUniqueId, order)),
      submitPurchaseOrder: this.submitPurchaseOrder,
      openR1Orders: this.openR1Orders,
      isSubmitting,
      showTaxCommitmentGridModal: this.showTaxCommitmentGridModal(order),
      destinationSubdivisionFull:
        order.destinationSubdivision && subdivisions.length > 0
          ? subdivisions.find(
              sub =>
                order.destinationSubdivision.countryCode === sub.countryCode &&
                order.destinationSubdivision.countrySubdivisionCode === sub.countrySubdivisionCode,
            )
          : [],
      isTaxCommitmentGridModalOpened: PurchaseOrderComparator(openedPurchaseOrderModalId, order),
    }))

    const openedOrderArray = newPurchaseOrderArray.filter(
      order => order.isTaxCommitmentGridModalOpened,
    )

    const canBeSubmittedCount = purchaseOrders.filter(order => order.canBeSubmitted).length

    const actionButtons = [
      {
        align: 'right',
        title: 'Close',
        color: 'primary',
        onClick: this.closeTaxCommitmentGridModal,
      },
    ]

    return (
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title>Tax Commitment Documents</ContentHeader.Title>
        </ContentHeader>
        <Content spaceBetween="M">
          <Box>
            <PurchaseOrdersFilter
              api={this.api}
              subdivisions={subdivisions}
              venueProfiles={venueProfiles}
              autosubmittedVenueProfileIds={autosubmittedVenueProfileIds}
              triggerVirtualizationToGetNewTopOffset={this.triggerVirtualizationToGetNewTopOffset}
              onChange={this.onSubmit}
            />
          </Box>
          <Box>
            <TotalsBar data={totalsData} loading={isLoading} />
          </Box>
          <Box>
            <Flex spaceBetween="S" align="center">
              <Box>
                <Checkbox
                  disabled={isLoading || canBeSubmittedCount === 0}
                  checked={
                    canBeSubmittedCount === chosen.length && !isLoading && canBeSubmittedCount !== 0
                  }
                  label="Select page"
                  onChange={this.onSelectPageClick}
                />
              </Box>
              <Button
                color="secondary"
                disabled={isLoading || chosen.length === 0}
                loading={isSubmitting}
                onClick={this.submitPurchaseOrdersAll}
              >
                Submit selected{chosen.length > 0 ? ` (${chosen.length})` : ''}
              </Button>
              <Box>
                <Text>
                  Orders found: <span>{totalCount}</span>
                </Text>
              </Box>
            </Flex>
          </Box>
          <Pagination
            {...remapPagination.from({ limit, offset })}
            availablePageSizes={availablePageSizes}
            rowCount={totalCount}
            onChange={this.onChangePagination}
          >
            <PurchaseOrdersGrid data={newPurchaseOrderArray} loading={isLoading} />
          </Pagination>
          <Modal
            isControlled
            size="L"
            show={!!openedOrderArray.length}
            actionButtons={actionButtons}
            onEscapeKeyDown={this.closeTaxCommitmentGridModal}
          >
            {openedOrderArray.length && (
              <TaxCommitmentGrid
                data={openedOrderArray[0].taxCommitmentDocuments}
                voidTaxCommitmentDocument={this.voidTaxCommitmentDocument}
              />
            )}
          </Modal>
        </Content>
      </Main>
    )
  }
}
