import React from 'react'
import styled from '@emotion/styled'
import { Drawer, Flex } from '@r1/ui-kit'
import { OfferDrawerProps } from './OfferDrawer.types'
import { OfferDrawerBody } from './components/OfferDrawerBody'
import { OfferDrawerActions } from './components/OfferDrawerActions'

const BodyFlex = styled(Flex)`
  height: 100%;
`

export const OfferDrawer = React.memo<OfferDrawerProps>(
  ({ isOpen, offer, allowEdit, onActionClick, onClose }) => {
    return (
      <Drawer backdrop="closing" size={460} placement="right" show={isOpen} onClose={onClose}>
        <BodyFlex column px="XL" py="L" justify="space-between">
          <OfferDrawerBody offer={offer} onActionClick={onActionClick} />
          <OfferDrawerActions
            offer={offer}
            allowEdit={allowEdit}
            onClose={onClose}
            onActionClick={onActionClick}
          />
        </BodyFlex>
      </Drawer>
    )
  },
)
