// @flow

import React, { memo, useCallback, useContext, useState } from 'react'
import { FormManager } from '@r1/form-manager'
import { Flex, H2, NotificationSystem, Placeholder } from '@r1/ui-kit'
import { ServiceContext } from '../../provider'

import { clientValidate } from '../../../../utils/validation'
import type { VariationCreateProps } from '../../types/variation.type'
import { schema } from './schema'

import { BasicInfo } from './children/BasicInfo'
// import { Images } from './children/Images'
import { Settings } from './children/Settings'

export const VariationCreate = memo<VariationCreateProps>(props => {
  const { onSuccessfulSubmit, children } = props

  const {
    variationsService,
    // imageService: { prepareAndUploadImages },
  } = useContext(ServiceContext)

  const [isLoading, setIsLoading] = useState(false)
  const [disabled] = useState(false)
  const [screenType] = useState('default')
  const [variationFormValues] = useState({
    Name: '',
    // Description: null,
    ProductsFilter: {
      type: 'Filter',
      productIdentifierValues: [],
    },
    Variations: {
      attributeDefinitionIds: [],
    },
  })

  const onSubmitCallback = useCallback(
    // eslint-disable-next-line consistent-return
    async (variation, { createSubmitError }) => {
      setIsLoading(true)

      // const {
      //   status: imagesStatus,
      //   errorFields: imageErrorFields,
      //   images,
      // } = await prepareAndUploadImages(variation.Images)

      // if (imagesStatus !== 200) {
      //   setIsLoading(false)
      //   return createSubmitError(imageErrorFields)
      // }

      // variation.Images = images

      const { errorFields, body, status } = await variationsService.createVariation(variation)

      setIsLoading(false)

      if (status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          title: `${variation.Name} product variation has been created`,
        })

        if (onSuccessfulSubmit) {
          onSuccessfulSubmit(body)
        }
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: `Variation not saved`,
        })

        if (errorFields) {
          return createSubmitError(errorFields)
        }
      }
    },
    [variationsService, onSuccessfulSubmit],
  )

  const clientValidateCallback = useCallback(value => clientValidate(schema, value), [])

  switch (screenType) {
    case 'placeholder':
      return <Placeholder type="form" height={5} />
    case 'error':
      return <H2>Sorry, something went wrong</H2>
    case 'default':
      return (
        <Flex column>
          <FormManager
            initialValues={variationFormValues}
            clientValidate={clientValidateCallback}
            onSubmit={onSubmitCallback}
          >
            {({ values, getError, push, remove, handleChange, handleBlur, handleSubmit }) => (
              <>
                <BasicInfo
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                  }}
                />
                {/* <Images
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                  }}
                /> */}
                <Settings
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                  }}
                />
                {children({
                  isLoading,
                  handlers: { handleSubmit },
                })}
              </>
            )}
          </FormManager>
        </Flex>
      )
    default:
      return null
  }
})
