// @flow

import React, { memo } from 'react'
import { Box, FormField, Select } from '@r1/ui-kit'
import type { EnumerationAttributeChildrenProps } from '../../../../../../types/attribute.type'

export const Enumeration = memo<EnumerationAttributeChildrenProps>(
  ({
    error,
    title,
    handleChange,
    optionIndex,
    disabled,
    value,
    enumValues,
    valueConstraint,
  }: EnumerationAttributeChildrenProps) => {
    const allowedVariants = valueConstraint ? valueConstraint.allowedVariants : []

    const filteredValue = []
    if (enumValues) {
      const enums = enumValues.enumValues
      if (allowedVariants.length === 0) {
        filteredValue.splice(0, 0, ...enums)
      } else {
        for (let i = 0; i < enums.length; i += 1) {
          if (allowedVariants.includes(enums[i].id)) {
            filteredValue.push(enums[i])
          }
        }
      }
    }

    return (
      <FormField>
        <FormField.Label>{title}</FormField.Label>
        <Box minWidth={255} maxWidth={255}>
          <Select
            clearable
            filterable
            error={!!error}
            disabled={disabled}
            value={value}
            valueKey="id"
            labelKey="value"
            options={(filteredValue: Array<$AnyObject>)}
            onChange={handleChange(['Attributes', optionIndex, 'formValue'])}
          />
        </Box>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
