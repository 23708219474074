// @flow

import React from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Select, Button, Input, Flex, Box, Modal, H3, Format } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'

import * as api from './api'
import { AllCalculationsGrid } from './AllCalculationsGrid'
import { ConsolidationStepsSubView } from './ConsolidationStepsSubView'

import type { ConsolidationStepsScreenState } from './types'

const DEFAULT_CONSOLIDATION_STEP = {
  steps: [],
  ruleName: '',
  identifier: '',
  createdAt: '',
}

export class ConsolidationStepsScreen extends React.Component<null, ConsolidationStepsScreenState> {
  interval: IntervalID

  prevSelected: any

  defaultColor: any

  modalActionButtons: BottomActionButton[]

  constructor(props: null) {
    super(props)

    this.state = {
      loading: false,
      inputIdentifier: '',
      rule: '',
      syncing: false,
      selectedIndex: 0,
      consolidationSteps: [],
      modalState: false,
      rules: [],
    }

    this.modalActionButtons = [
      {
        title: 'Close',
        color: 'primary',
        onClick: this.onToggleModal,
        align: 'right',
      },
    ]
  }

  async componentDidMount() {
    Promise.all([this.loadPricingRulesInfos(), this.loadConsolidationSteps()])

    this.interval = setInterval(async () => {
      await this.loadConsolidationSteps()
    }, 300000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  loadPricingRulesInfos = async () => {
    try {
      this.setState({ loading: true })
      await api.loadPricingRulesInfos().then(rules => {
        if (rules) {
          this.setState({ rules })
        }
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  loadConsolidationSteps = async () => {
    try {
      this.setState({ loading: true })
      await api.loadConsolidationSteps().then(consolidationSteps => {
        if (consolidationSteps) {
          this.setState({ consolidationSteps })
        }
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  sync = async () => {
    try {
      this.setState({ syncing: true })
      await api.sync(this.state.inputIdentifier, this.state.rule)
    } finally {
      this.setState({ syncing: false })
    }
  }

  onChange = (field: string, value: any) => this.setState({ [field]: value })

  onToggleModal = () => this.setState(prev => ({ modalState: !prev.modalState }))

  loadSteps = (index: number, e: SyntheticEvent<HTMLDivElement>) => {
    const rowEl = e.currentTarget.closest('.grid-row-wrapper')
    if (this.prevSelected) this.prevSelected.style.backgroundColor = this.defaultColor

    // $FlowFixMe[incompatible-use]
    // $FlowFixMe[prop-missing]
    this.defaultColor = rowEl.style.backgroundColor
    this.prevSelected = rowEl

    // $FlowFixMe[incompatible-use]
    // $FlowFixMe[prop-missing]
    rowEl.style.backgroundColor = '#DAE7F8'
    this.setState({
      selectedIndex: index,
    })
    this.onToggleModal()
  }

  render() {
    const current =
      this.state.consolidationSteps[this.state.selectedIndex] || DEFAULT_CONSOLIDATION_STEP
    const { steps = [], ruleName = '', createdAt = '', identifier = '' } = current

    const { inputIdentifier, rule, rules, syncing, loading, consolidationSteps, modalState } =
      this.state

    return (
      <PageAccessControl permissions={[PERMISSIONS.allowConsolidationStepsView]}>
        <Main>
          <ContentHeader>
            <ContentHeader.Title />
          </ContentHeader>

          <Content>
            <Flex maxWidth={900} minWidth={900} spaceBetween="M">
              <Box maxWidth={200} minWidth={200}>
                <Input
                  placeholder="Identifier"
                  value={inputIdentifier}
                  onChange={val => this.onChange('inputIdentifier', val)}
                />
              </Box>
              <Select
                width={350}
                options={rules.map(({ priceRuleId, name }) => ({ key: priceRuleId, value: name }))}
                valueKey="key"
                labelKey="value"
                value={rule}
                name="rule"
                onChange={val => this.onChange('rule', val)}
              />
              <Button
                disabled={syncing}
                loading={syncing}
                onClick={() => {
                  this.sync()
                }}
              >
                Start calculation
              </Button>
              <Button disabled={syncing} loading={syncing} onClick={this.onToggleModal}>
                See all calculations
              </Button>
            </Flex>
            {steps.length > 0 && (
              <H3>
                {ruleName} for {identifier} calculation (started at&nbsp;
                <Format.DateTime>{createdAt}</Format.DateTime>)
              </H3>
            )}
            <ConsolidationStepsSubView steps={steps} />
          </Content>
          <Modal
            isControlled
            size="L"
            show={modalState}
            actionButtons={this.modalActionButtons}
            onEscapeKeyDown={this.onToggleModal}
          >
            <AllCalculationsGrid
              rules={rules}
              data={consolidationSteps}
              loadSteps={this.loadSteps}
              loading={loading}
            />
          </Modal>
        </Main>
      </PageAccessControl>
    )
  }
}
