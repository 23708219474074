import React, { useState } from 'react'
import { format } from 'date-fns'
import styled from '@emotion/styled'
import { useAccessControl } from '@r1/core-blocks'
import { Flex, Text, Button, Tooltip, Icon, useConfigurationProvider } from '@r1/ui-kit'

const getReasonValue = (reasons, key) => reasons.find(reason => reason.key === key)?.value ?? null

export function FooterPanel(props) {
  const [isRescrapingInProcess, setIsRescrapingInProcess] = useState(false)

  const { palette } = useConfigurationProvider()

  const [
    {
      allowProductTemplateApprove,
      allowProductTemplateSubmitForReview,
      allowProductTemplateUpdateWithoutValidation,
    },
  ] = useAccessControl()

  const showActionButtons = props.lockStatus === '' && !props.templateWasMerged
  const showApproveButton = showActionButtons && allowProductTemplateApprove
  const showSubmitButton = showActionButtons && allowProductTemplateSubmitForReview
  const showSaveAllButton = showActionButtons && allowProductTemplateUpdateWithoutValidation

  const { sentToReviewOn, sentToReviewByUserName, reason, comment } = props.reviewInfo ?? {}
  const lockReason = props?.lockStatus?.toLowerCase?.()

  const Container = styled(Flex)`
    position: sticky;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: ${() => palette.grey[100]};
    z-index: 10;
  `

  const Info = styled(Flex)`
    background-color: ${({ templateWasMerged }) =>
      templateWasMerged ? palette.red[50] : palette.yellow[100]};
    height: 32px;
  `

  const FOOTER_ACTION_BUTTON_PANEL_HEIGHT = '54px'
  const MainPanel = styled(Flex)`
    height: ${FOOTER_ACTION_BUTTON_PANEL_HEIGHT};
  `
  const onProductDataRescrape = async () => {
    setIsRescrapingInProcess(true)
    await props.onProductDataRescrape()
    setIsRescrapingInProcess(false)
  }

  const drawReviewInfo = () => {
    if (!props.reviewInfo && !props.lockStatus && !props.templateWasMerged) {
      return null
    }

    let reviewInfoNode = null
    let lockStatusNode = null
    let mergedStatusNode = null

    if (props.reviewInfo) {
      const reviewSubmitDate = sentToReviewOn
        ? `${format(new Date(sentToReviewOn), 'MM.dd.yyyy')} ${format(
            new Date(sentToReviewOn),
            'LT',
          )}`
        : null

      reviewInfoNode = (
        <Flex spaceBetween="S" align="center" justify="center">
          <Text>Submitted for review.</Text>
          <Flex>
            <Text>Reason:&nbsp;</Text>
            <Text weight="bold">
              {props.reviewReasons ? getReasonValue(props.reviewReasons, reason) : reason}
            </Text>
            ,
          </Flex>
          <Flex>
            <Text>by:&nbsp;</Text>
            <Text weight="bold">{sentToReviewByUserName}</Text>,
          </Flex>
          {reviewSubmitDate ? <Text weight="bold">{reviewSubmitDate}</Text> : null}
          {comment ? (
            <Tooltip text={comment}>
              <Icon type="info" size="S" />
            </Tooltip>
          ) : null}
        </Flex>
      )
    }

    if (lockReason) {
      lockStatusNode = (
        <Flex spaceBetween="S" justify="center">
          <Text>Product template is locked</Text>
          <Text>-</Text>
          <Text weight="bold">{lockReason}</Text>
        </Flex>
      )
    }

    if (props.templateWasMerged) {
      mergedStatusNode = (
        <Flex spaceBetween="S" justify="center">
          <Text weight="bold" color={palette.red[600]}>
            This template was merged and now it&apos;s blocked
          </Text>
        </Flex>
      )
    }

    return (
      <Info column justify="center">
        {props.templateWasMerged && mergedStatusNode}
        {!props.templateWasMerged && props.lockStatus && lockStatusNode}
        {!props.templateWasMerged && !props.lockStatus && props.reviewInfo && reviewInfoNode}
      </Info>
    )
  }

  return (
    <Container column justify="center" data-test-id="content-footer-panel">
      {drawReviewInfo()}
      <MainPanel align="center" justify="center" spaceBetween="M">
        {showSaveAllButton && (
          <Button type="submit" onClick={props.onSubmit}>
            SAVE ALL
          </Button>
        )}

        <Button transparent onClick={props.onCancel}>
          CANCEL
        </Button>
        {showActionButtons && (
          <Button transparent disabled={isRescrapingInProcess} onClick={onProductDataRescrape}>
            RESCRAPE
          </Button>
        )}
        {showSubmitButton && (
          <Button transparent onClick={props.onReview}>
            SUBMIT FOR REVIEW
          </Button>
        )}
        {showApproveButton && (
          <Button transparent onClick={props.onApprove}>
            SAVE APPROVE AND CONTINUE
          </Button>
        )}
      </MainPanel>
    </Container>
  )
}
