// @flow

import { generateTestIds } from '../../../../components/utils/generateTestIds'

export const testIds = generateTestIds({
  scope: ['product_edit'],
  components: {
    manufacturerSelect: 'manufacturer-Select',
    modelSelect: 'model-Select',
  },
})
