import { withErrorHandlers } from '@r1/http-client'

let handleUnauthorizedError: () => void = () => {}

export function addUnauthorizedErrorHandler(errorHandler: () => void): void {
  handleUnauthorizedError = errorHandler
}

/**
 * @description - Call if not valid refreshToken
 */
export const errorMiddleware = withErrorHandlers({
  '401': error => {
    if (typeof handleUnauthorizedError === 'function') {
      handleUnauthorizedError()
    }
    return Promise.reject(error)
  },
})
