// @flow

import React, { useCallback } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Button, Modal } from '@r1/ui-kit'
import type { VariationCreationScreenProps } from '../../../types/public/VariationCreationScreen'
import { VariationContainer } from '../VariationContainer'
import { VariationCreate } from '../children/VariationCreate'

export const VariationCreateScreen = (props: VariationCreationScreenProps) => {
  const Title = props.renderTitle
  const ActionButtons = props.renderActionsButtons

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'No',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Yes',
          onClick: () => {
            props.onCancel()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [props],
  )

  return (
    <VariationContainer httpClient={props.httpClient}>
      <VariationCreate onSuccessfulSubmit={props.onSuccessfulSubmit}>
        {({ handlers, isLoading }) => (
          <>
            <Title>New Variation</Title>
            <ActionButtons>
              <Modal
                isControlled={false}
                title="You have unsaved data"
                actionButtons={modalActionButtons}
                buttonElement={({ onOpen }) => (
                  <Button color="secondary" onClick={onOpen}>
                    Cancel
                  </Button>
                )}
              >
                Are you sure you want to leave without saving?
              </Modal>
              <Button loading={isLoading} disabled={isLoading} onClick={handlers.handleSubmit}>
                Create
              </Button>
            </ActionButtons>
          </>
        )}
      </VariationCreate>
    </VariationContainer>
  )
}
