// @flow

import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Image } from '@r1/ui-kit'
import { useDebounce } from '../../../../../../../../../../../../utils/hooks/useDebounce'

import { Container, ConnectError } from './styles'

type CameraProps = {
  active: boolean,
  onPhotoMake: (image: string) => void,
}

export const Camera = ({ onPhotoMake, active }: CameraProps) => {
  const videoRef = useRef<HTMLVideoElement | Object>(null)
  const tempImgRef = useRef<Object>(null)
  const [isUserMedia, setIsUserMedia] = useState(false)

  const [isStartAnimation, setIsStartAnimation] = useState(false)

  const debounce = useDebounce(300)

  const sourceSelected = useCallback(videoSource => {
    if (!navigator.getUserMedia || !videoSource) {
      return
    }

    const constraints = {
      video: {
        optional: [{ sourceId: videoSource }],
      },
      audio: false,
    }

    navigator.getUserMedia(
      constraints,
      stream => {
        setIsUserMedia(true)
        videoRef.current.srcObject = stream
      },
      () => {
        setIsUserMedia(false)
      },
    )
  }, [])

  const initUserMedia = useCallback(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .enumerateDevices()
        .then(devices =>
          sourceSelected(
            devices.find(device => device.kind === 'video' || device.kind === 'videoinput'),
          ),
        )
        .catch(error => new Error(error))
    } else {
      // $FlowFixMe[prop-missing]
      MediaStreamTrack.getSources(sources =>
        sourceSelected(sources.find(source => source.kind === 'video')),
      )
    }
  }, [sourceSelected])

  const stopUserMedia = useCallback(() => {
    if (videoRef.current && videoRef.current.srcObject) {
      videoRef.current.srcObject.getTracks().forEach(track => track.stop())
      setIsUserMedia(false)
    }
  }, [])

  const onContainerClick = useCallback(() => {
    if (!videoRef.current || !isUserMedia) {
      return
    }

    const video = videoRef.current
    const canvas = document.createElement('canvas')
    const aspectRatio = video.videoWidth / video.videoHeight

    canvas.width = video.clientWidth
    canvas.height = video.clientWidth / aspectRatio
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)

    const data = canvas.toDataURL('image/jpeg')

    if (onPhotoMake) {
      onPhotoMake(data)
    }

    tempImgRef.current = data
    debounce(() => {
      setIsStartAnimation(true)
    })
  }, [debounce, isUserMedia, onPhotoMake])

  useEffect(
    () => (active ? initUserMedia() : stopUserMedia()),
    [active, initUserMedia, stopUserMedia],
  )

  return (
    <Container onClick={onContainerClick}>
      <div
        className={`shot-effect ${isStartAnimation ? 'animation' : ''}`}
        onAnimationEnd={() => setIsStartAnimation(false)}
      >
        <Image src={tempImgRef.current} width={160} height={160} />
      </div>
      {!isUserMedia && <ConnectError>Camera not connected. Try again.</ConnectError>}
      <video ref={videoRef} autoPlay muted width="100%" height="100%" />
    </Container>
  )
}
