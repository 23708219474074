import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateImagesApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, actions, selectors } = createCollectionModule({
  name: '@productTemplates/images',
  keyField: 'id',
  params: ['id'],
  fetchAllFunc: async ({ id }, _api) =>
    // eslint-disable-next-line no-return-await
    await productTemplateImagesApi.getImages({ id }).then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { actions, selectors }
