import * as PIXI from 'pixi.js'
import { BlockType } from '../../constants'
import barcode from './displayObjects/barcode'
import qrCode from './displayObjects/qrCode'
import rectangle from './displayObjects/rectangle'
import repeater from './displayObjects/repeater'
import text from './displayObjects/text'
import image from './displayObjects/image'

const createVisibleDisplayObject = (block, converter) => {
  const transform = converter.fromModelTransform(block)

  switch (block.type) {
    case BlockType.TEXT_BLOCK: {
      const fontSize = converter.fromModelFontSize(block.fontSize)
      return text(transform, block.values, fontSize, block.fitTextToRectangle, block.reversedColor)
    }

    case BlockType.BARCODE_BLOCK:
      return barcode(transform, block.format)

    case BlockType.QR_CODE_BLOCK:
      return qrCode(transform)

    case BlockType.RECTANGLE_BLOCK:
      // hack for narrow line in totem lable ??? i don't know why and how, this is from expirience
      return rectangle(transform, converter.fromModel(block.lineThickness) + 0.01)

    case BlockType.REPEATER_BLOCK: {
      // eslint-disable-next-line no-use-before-define
      const getRepeatedBlock = () => createBlockDisplayObject(block.itemTemplate, converter)
      const spaceBetween = converter.fromModel(block.spaceBetween)
      return repeater(transform, getRepeatedBlock, spaceBetween)
    }

    case BlockType.IMAGE_BLOCK:
      return image(block, transform)

    default:
      // eslint-disable-next-line no-console
      console.error('Invalid block type', block.type, block)
      return null
  }
}

export const createBlockDisplayObject = (block, converter) => {
  const container = new PIXI.Container()

  container.id = block.id
  container.interactive = true
  container.buttonMode = true

  const transform = converter.fromModelTransform(block)

  container.x = transform.position.x + transform.offset.x
  container.y = transform.position.y + transform.offset.y
  container.rotation = transform.rotation

  container.hitArea = new PIXI.Rectangle(0, 0, transform.size.width, transform.size.height)

  const displayObject = createVisibleDisplayObject(block, converter)

  if (displayObject) {
    container.addChild(displayObject)
  }

  return container
}
