import * as React from 'react'
import styled from '@emotion/styled'
import { Button, withTheme, Flex, Text, Icon } from '@r1/ui-kit'

const IdentifierRow = styled(Flex)`
  width: 250px;
  height: 32px;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  &:nth-of-type(2n) {
    background-color: transparent;
  }
`

function renderIdentifierAttributes(type, attributes) {
  if (!attributes) return null
  if (type === 'ASIN') {
    const asinTypeAttributes = attributes.filter(a => a.$type === 'AsinTypeAttribute')
    return <Text>({asinTypeAttributes.length > 0 ? asinTypeAttributes[0].value : 'Unknown'})</Text>
  }
  return null
}

function renderIdentifiersRows(rows) {
  return rows.map(({ type, value, attributes }) => (
    <IdentifierRow key={type + value} align="center">
      <Flex mx="S" basis={0.2}>
        <Text weight="medium">{type}</Text>
      </Flex>
      <Flex basis={0.47}>
        <Text weight="bold">{value}</Text>
      </Flex>
      <Flex basis={0.33}>{renderIdentifierAttributes(type, attributes)}</Flex>
    </IdentifierRow>
  ))
}

const IdentifiersRowsComponent = ({ theme, data, onEdit, disabled }) => (
  <div>
    <Flex column mb={data.length > 0 ? 'M' : 0}>
      {renderIdentifiersRows(data)}
    </Flex>
    {!disabled && (
      <Button transparent onClick={onEdit}>
        <Flex align="center">
          <Icon type="pencil" color={theme.palette.blue[600]} />
          <span>&nbsp;MANAGE IDENTIFIERS</span>
        </Flex>
      </Button>
    )}
  </div>
)

export const IdentifiersRows = withTheme(IdentifiersRowsComponent)
export { renderIdentifierAttributes }
