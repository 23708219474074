import { ReportSection } from '@r1-webui/reports-v1'

export const defaultReportSection: ReportSection = {
  id: 'DEFAULT_REPORT_SECTION_ID',
  name: '',
}

export const existedIconIds = [
  'Legacy',
  '66f70416-4780-409c-b501-d6483f77d930',
  '9e08fd8c-1431-416d-af52-8beb0fc5a274',
]

export const DEFAULT_ICON_ID = 'defaultReportsWorkspace'
