import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { Select } from '@r1/ui-kit'
import * as actions from '../../modules/products/models'

const mapStateToProps = ({ productModels }) => ({
  data: productModels.data,
  isFetched: productModels.isFetched,
})

const enhance = compose(connect(mapStateToProps, actions))

class ProductModelComponent extends React.Component {
  componentDidMount() {
    const { restoreProductModel, value } = this.props
    restoreProductModel({ productModelId: value })
  }

  componentDidUpdate(prevProps) {
    const { value, restoreProductModel } = this.props

    if (value !== prevProps.value) {
      restoreProductModel({ productModelId: value })
    }
  }

  async onAddProductModel(name) {
    const { addProductModel, onAddProductModel, manufacturerId, categoryId } = this.props
    const id = await addProductModel({ name, manufacturerId, categoryId })
    if (id) {
      onAddProductModel(id)
    }
  }

  // eslint-disable-next-line no-dupe-class-members
  onAddProductModel = this.onAddProductModel.bind(this)

  async onRemoveProductModel({ id, name }, closeSelectMenu) {
    closeSelectMenu()
    const { removeProductModel, onRemoveProductModel } = this.props
    await removeProductModel({ id, name })
    onRemoveProductModel(id)
  }

  // eslint-disable-next-line no-dupe-class-members
  onRemoveProductModel = this.onRemoveProductModel.bind(this)

  render() {
    const {
      value,
      isFetched,
      data,
      onChange,
      manufacturerId,
      fetchProductModels,
      validationFailed,
      disabled,
    } = this.props

    return (
      <Select
        editable
        value={value}
        isLoading={!isFetched}
        options={data}
        disabled={disabled}
        placeholder="Choose model"
        noResultsText="Type to search"
        error={validationFailed}
        zIndex={10}
        onChange={onChange}
        onOpen={() => fetchProductModels({ manufacturerId })}
        onRemoveOption={this.onRemoveProductModel}
        onAddOption={this.onAddProductModel}
      />
    )
  }
}

export const ProductModelSelect = enhance(ProductModelComponent)
