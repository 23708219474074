// @flow

import React, { useEffect, useCallback, useState, useMemo } from 'react'
import { Table } from '@r1/grid'
import { Link } from '@r1/ui-kit'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { createDocumentTemplateManagerService } from '../services/documentTemplateManagerService'
import { ROUTES } from '../../../navigation'

const displaySettings = {
  columnsSettings: [
    {
      header: { content: 'Name' },
      cell: {
        $type: 'custom',
        renderer: val => <Link href={`${ROUTES.LABEL_MANAGEMENT}/${val.id}`}>{val.name}</Link>,
      },
    },
    {
      header: { content: 'Page Setting' },
      cell: { $type: 'text', field: 'pageSettingName' },
    },
  ],
}

export const ViewLabelsGrid = () => {
  const api = useMemo(() => createDocumentTemplateManagerService(), [])
  const [documentTemplates, setDocumentTemplates] = useState([])
  // const [pageSettings, setPageSettings] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    const [templates, settings] = await Promise.all([
      api.getDocumentTemplates(),
      api.getPageSettings(),
    ])
    setLoading(false)
    const mappedSettings = settings.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {})
    setDocumentTemplates(
      templates.map(template => ({
        ...template,
        pageSettingName: mappedSettings[template.pageSettingId],
      })),
    )
    // setPageSettings(settings)
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowLabelView]}>
      <Main>
        <ContentHeader />
        <Content>
          <ContentHeader.Title>Label Appearance Design</ContentHeader.Title>
          <Table loading={loading} data={documentTemplates} displaySettings={displaySettings} />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
