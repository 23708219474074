import { evolve, merge, intersection, symmetricDifference, path } from 'ramda'
import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { updateVersion, changeCategoryId } from '../../../module'
import { actions as identifiersActions } from '../../../../../modules/productTemplates/identifiers'
import { actions as templatesActions } from '../../../../../modules/productTemplates/productTemplates'
import {
  productTemplateApi,
  productTemplateIdentifiersApi,
} from '../../../../../api/productTemplate/index.ts'

const weightMeasures = ['Kg', 'Lb', 'Oz']
const dimensionMeasures = ['Cm', 'Inch']

const initialState = {
  model: null,
  initialIds: [],
  idsTextState: false,
  isFetched: true,
}

const types = createTypes(
  ['setFetching', 'loadModel', 'updateIds', 'toggleIdsTextState', 'stripHtml'],
  'mainInfo',
)

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.toggleIdsTextState]: (state, { idsTextState }) => ({ ...state, idsTextState }),
  [types.loadModel]: (state, payload) => ({ ...state, ...payload, isFetched: true }),
  [types.updateIds]: (state, { identifiers }) => ({ ...state, initialIds: identifiers }),
  [types.stripHtml]: (state, payload) => ({ ...state, ...payload }),
})

export function toggleIdsTextState(idsTextState) {
  return async dispatch => {
    dispatch({
      type: types.toggleIdsTextState,
      idsTextState,
    })
  }
}

export function loadModel(productTemplateId) {
  return async (dispatch, getState) => {
    if (getState().mainInfo.model) return

    dispatch({ type: types.setFetching })

    const [productTemplate, identifiers] = await Promise.all([
      await dispatch(templatesActions.fetchItem({ id: productTemplateId })),
      await dispatch(identifiersActions.fetchItem({ id: productTemplateId })),
    ])

    if (!productTemplate) return

    const model = merge(
      evolve(
        {
          packaging: packaging => ({
            packageDimension: {
              ...packaging.packageDimension,
              measure: path(['packageDimension', 'measure'], packaging) || dimensionMeasures[1],
            },
            packageWeight: {
              ...packaging.packageWeight,
              measure: path(['packageWeight', 'measure'], packaging) || weightMeasures[1],
            },
            productDimension: {
              ...packaging.productDimension,
              measure: path(['productDimension', 'measure'], packaging) || dimensionMeasures[1],
            },
            productWeight: {
              ...packaging.productWeight,
              measure: path(['productWeight', 'measure'], packaging) || weightMeasures[1],
            },
          }),
        },
        productTemplate,
      ),
      {
        identifiers,
      },
    )

    dispatch(updateVersion(productTemplate.version))

    dispatch({
      type: types.updateIds,
      identifiers,
    })
    dispatch({
      type: types.loadModel,
      model,
    })
    dispatch(changeCategoryId(model?.productInfo?.categoryId))
  }
}

async function updateIdentifiers({ newIdentifiers, productTemplateId }, _api) {
  const oldIdentifiersResponse = await productTemplateIdentifiersApi.getAdditionalIdentifiers({
    id: productTemplateId,
  })
  const oldIdentifiers = oldIdentifiersResponse.body.map(({ type, value }) => ({ type, value }))
  const notModified = intersection(newIdentifiers, oldIdentifiers)
  const removed = symmetricDifference(notModified, oldIdentifiers)
  const added = symmetricDifference(notModified, newIdentifiers)

  const addPromises = added.map(item =>
    productTemplateIdentifiersApi.addAdditionalIdentifier({ id: productTemplateId }, item),
  )
  const removePromises = removed.map(item =>
    productTemplateIdentifiersApi.removeAdditionalIdentifier(
      { id: productTemplateId },
      { data: item },
    ),
  )
  const responses = await Promise.all([...removePromises, ...addPromises])
  let success = true
  responses.forEach(response => {
    if (response.status !== 200) {
      handleServerError(response)
      success = false
    }
  })

  if (success) {
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Success',
      message: 'Additional identifiers successfully saved',
    })
  }

  return success
}

export function submitIdentifiers(productTemplateId, identifiers, onIdentifiersFetched) {
  return async (dispatch, getState, api) => {
    const identifiersUpdated = await updateIdentifiers(
      {
        newIdentifiers: identifiers,
        productTemplateId,
      },
      api,
    )

    if (!identifiersUpdated) {
      return
    }

    const newIdentifiers = await dispatch(identifiersActions.fetchItem({ id: productTemplateId }))

    dispatch({
      type: types.updateIds,
      identifiers: newIdentifiers,
    })
    dispatch({
      type: types.toggleIdsTextState,
      idsTextState: false,
    })
    onIdentifiersFetched(newIdentifiers)
  }
}

export function verifyProduct(productTemplateId, isVerified) {
  return async (dispatch, getState, _api) => {
    const { version } = getState().productTemplate
    const newVersion = await productTemplateApi
      .setPhysicallyVerified({ id: productTemplateId }, { version, value: isVerified })
      .then(({ body }) => body)
    dispatch(updateVersion(newVersion))

    NotificationSystem.addNotification({
      level: 'success',
      title: 'Success',
      message: `Product ${productTemplateId} now has change it verified status.`,
    })
  }
}
