// @flow

import * as React from 'react'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link, Modal } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'

import { ROUTES } from '../../../../navigation'
import type { ViewProps } from './types'
import { Steps } from './Steps'

export const View = (props: ViewProps) => {
  const generateActionButtons = React.useCallback(
    function generateActionButtons({ onClose }): BottomActionButton[] {
      return [
        {
          title: 'No',
          align: 'right',
          onClick: onClose,
          color: 'secondary',
        },
        {
          title: 'Yes',
          align: 'right',
          onClick: props.formActions.cancelForm.dispatch,
          color: 'danger',
        },
      ]
    },
    [props.formActions.cancelForm],
  )

  return (
    <Main>
      <ContentHeader />
      <ContentHeader.Breadcrumbs>
        <Link to={props.claimType === 'B2C' ? ROUTES.CLAIMS_B2C : ROUTES.CLAIMS_B2B}>
          {`${props.claimType} RMA Claims`}
        </Link>
      </ContentHeader.Breadcrumbs>
      <Content>
        <ContentHeader.Title>Create claim</ContentHeader.Title>
        <Modal
          isControlled={false}
          title="You have unsaved data"
          actionButtons={generateActionButtons}
          buttonElement={({ onOpen }) => <Steps {...props} cancelCreation={onOpen} />}
        >
          Are you sure you want to cancel claim creation?
        </Modal>
      </Content>
    </Main>
  )
}
