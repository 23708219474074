import { getSortingIndexes } from '../services/sortingIndexesService'
import { warehousesApi } from '../../../api/warehouses'
import { consigneeUpcVenueProfileTypes } from './consigneeUpcVenueProfileTypes'
import { rtvTypes } from './rtvTypes'

const enumCache = new Map()

export function getMasterProgramEnums(filterId: string): Promise<
  Array<{
    id: string
    name: string
  }>
> {
  if (!enumCache.get(filterId)) {
    switch (filterId) {
      case 'programIds':
        enumCache.set(filterId, warehousesApi.getWarehouses())
        break
      case 'consigneeUpcVenueProfileTypeId':
        enumCache.set(filterId, consigneeUpcVenueProfileTypes)
        break
      case 'rtvTypes':
        enumCache.set(filterId, rtvTypes)
        break
      case 'palletSortingCategories':
      case 'excludedPalletSortingCategories':
        enumCache.set(filterId, getSortingIndexes())
        break
      default:
        // eslint-disable-next-line no-promise-executor-return
        enumCache.set(filterId, new Promise(resolve => resolve([])))
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return enumCache.get(filterId)
}
