// @flow

import { baseUri } from '@r1-webui/productcatalog-attributedefinitionmanagement-v1'

import { UI_API_PREFIXES } from '../../../api'
import { mapToSuccessResponse } from '../../utils/api'
import type {
  Api,
  UpdateAttributeDefinitionOptions,
  DeleteAttributeDefinitionOptions,
} from '../../../types/internal/api/attributeDefinitionManagement'
import type { NewAttributeDefinition } from '../../../types/internal/api/attributeDefinitionManagement/definitions'
import type { Lib$Id, Lib$ServerResponse } from '../../../types'
import type { ForbiddenError, ValidationError } from '../../../types/internal/api/serverErrors'
import { errorHandler } from './errorHandler'

export const API_PREFIX = `${UI_API_PREFIXES.CATALOG_MANAGEMENT}/${baseUri}`

export const createApi = (httpClient: Object): Api => ({
  createAttributeDefinition: (
    values: NewAttributeDefinition,
  ): Lib$ServerResponse<Lib$Id, ForbiddenError | ValidationError> =>
    httpClient
      .post(`${API_PREFIX}/attribute-definitions`, values)
      .then(mapToSuccessResponse)
      .catch(errorHandler),
  updateAttributeDefinition: (options: UpdateAttributeDefinitionOptions) =>
    httpClient
      .put(
        `${API_PREFIX}/attribute-definitions/${options.attributeDefinitionId}?version=${options.version}`,
        options.attributeDefinition,
      )
      .then(mapToSuccessResponse)
      .catch(errorHandler),
  deleteAttributeDefinition: (options: DeleteAttributeDefinitionOptions) =>
    httpClient
      .delete(
        `${API_PREFIX}/attribute-definitions/${options.attributeDefinitionId}?version=${options.version}`,
      )
      .then(mapToSuccessResponse)
      .catch(errorHandler),
})
