import { createReducer, createTypes } from '../../../redux/utils'
import { handleError } from '../../utils'

const initialState = {
  data: [],
  isFetched: true,
}

const types = createTypes(['setFetching', 'setData'], 'productCatalogDataSources')

export default {
  productCatalogDataSources: createReducer(initialState, {
    [types.setFetching]: state => ({ ...state, isFetched: false }),
    [types.setData]: (state, payload) => ({ ...state, ...payload, isFetched: true }),
  }),
}

export function loadDataSource() {
  return async (dispatch, getState, api) => {
    if (getState().productCatalogDataSources.data.length) return

    dispatch({ type: types.setFetching })

    let data = []

    try {
      data = await api.client.get('/uisvc/data-source-info/ldrv/api/v1/data-sources')
    } catch (error) {
      handleError(error)
    } finally {
      dispatch({
        type: types.setData,
        data,
      })
    }
  }
}
