import React from 'react'
import { connect } from 'react-redux'
import { Button, FormField, Flex, Text } from '@r1/ui-kit'

import { InputType, ValueType, ConditionType, Ids } from '../../constants'
import { addConstantValue, removeValue, changeValue } from '../../actions'
import Container from './Container'
import Input from './Input'
import { Removable } from './Removable'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    if (block.values) {
      return {
        blockId: block.id,
        propsIdsPath: ['values'],
        values: block.values,
      }
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onAdd: (blockId, propsIdsPath) => dispatch(addConstantValue(blockId, propsIdsPath)),
  onRemove: (blockId, propsIdsPath, value) => dispatch(removeValue(blockId, propsIdsPath, value)),
  onChange: (blockId, propsIdsPath, oldValue, newValue) =>
    dispatch(changeValue(blockId, propsIdsPath, oldValue, newValue)),
})

const valueTypes = [
  {
    id: ValueType.CONSTANT,
    name: 'Constant',
  },
  {
    id: ValueType.PROPERTY,
    name: 'Property',
  },
  {
    id: ValueType.CONDITIONAL,
    name: 'Conditional',
  },
]

const conditionTypes = [
  {
    id: ConditionType.NOT_EMPTY,
    name: 'Not empty',
  },
]

const renderConstantValueEditor = (parentValueIds, value, onChange) => (
  <Flex spaceBetween="XXS">
    <FormField.Label>Value</FormField.Label>
    <Input
      type={InputType.TEXT}
      data={value}
      propPath="value"
      onChange={newValue => onChange(parentValueIds, value, newValue)}
    />
  </Flex>
)

const renderPropertyValueEditor = (parentValueIds, value, onChange) => (
  <React.Fragment>
    <Flex spaceBetween="XXS">
      <FormField.Label>Property</FormField.Label>
      <Input
        type={InputType.TEXT}
        data={value}
        propPath="fullName"
        onChange={newValue => onChange(parentValueIds, value, newValue)}
      />
    </Flex>
    <Flex spaceBetween="XXS">
      <FormField.Label>Format</FormField.Label>
      <Input
        type={InputType.TEXT}
        data={value}
        propPath="format"
        onChange={newValue => onChange(parentValueIds, value, newValue)}
      />
    </Flex>
    <Flex spaceBetween="XXS">
      <FormField.Label>Required</FormField.Label>
      <Input
        type={InputType.CHECKBOX}
        data={value}
        propPath="required"
        onChange={newValue => onChange(parentValueIds, value, newValue)}
      />
    </Flex>
  </React.Fragment>
)

const renderValueEditor = (parentValueIds, value, handlers, props) => (
  <Removable key={value.id} onRemove={() => handlers.onRemove(parentValueIds, value)}>
    <Container {...props}>
      <Flex spaceBetween="XXS">
        <FormField.Label>Type</FormField.Label>
        <Input
          type={InputType.SELECT}
          data={value}
          options={valueTypes}
          propPath="type"
          onChange={newValue => handlers.onChange(parentValueIds, value, newValue)}
        />
      </Flex>

      {value.type === ValueType.CONSTANT &&
        renderConstantValueEditor(parentValueIds, value, handlers.onChange)}

      {value.type === ValueType.PROPERTY &&
        renderPropertyValueEditor(parentValueIds, value, handlers.onChange)}

      {value.type === ValueType.CONDITIONAL &&
        // eslint-disable-next-line no-use-before-define
        renderConditionalValueEditor(parentValueIds, value, handlers, props)}
    </Container>
  </Removable>
)

const renderValueEditorPanel = (parentValueIds, values, handlers, props) => {
  return (
    <Container {...props}>
      {values.map(value => renderValueEditor(parentValueIds, value, handlers, props))}
      <Button onClick={() => handlers.onAdd(parentValueIds)}>Add</Button>
    </Container>
  )
}
const renderConditionalValueEditor = (parentValueIds, value, handlers, props) => (
  <React.Fragment>
    <Flex spaceBetween="XXS">
      <FormField.Label>Condition</FormField.Label>
      <Input
        type={InputType.SELECT}
        data={value}
        options={conditionTypes}
        propPath="condition.type"
        onChange={newValue => handlers.onChange(parentValueIds, value, newValue)}
      />
    </Flex>
    <Flex spaceBetween="XXS">
      <FormField.Label>Parameter</FormField.Label>
      <Input
        type={InputType.TEXT}
        data={value}
        propPath="condition.parameter"
        onChange={newValue => handlers.onChange(parentValueIds, value, newValue)}
      />
    </Flex>
    <Flex column mt="M" spaceBetween="XXS">
      <Text>True values</Text>

      {renderValueEditorPanel(
        [...parentValueIds, value.id, 'trueValues'],
        value.trueValues,
        handlers,
        props,
      )}
    </Flex>
    <Flex column mt="M" spaceBetween="XXS">
      <Text>False values</Text>

      {renderValueEditorPanel(
        [...parentValueIds, value.id, 'falseValues'],
        value.falseValues,
        handlers,
        props,
      )}
    </Flex>
  </React.Fragment>
)

export const ValueEditorPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ blockId, propsIdsPath = [], values, onAdd, onRemove, onChange, ...props }) => {
  if (!values) {
    return null
  }

  const handleAdd = parentValueIds => {
    onAdd(blockId, parentValueIds)
  }

  const handleChange = (parentValueIds, oldValue, newValue) => {
    onChange(blockId, parentValueIds, oldValue, newValue)
  }

  const handleRemove = (parentValueIds, value) => {
    onRemove(blockId, parentValueIds, value)
  }

  const handlers = {
    onAdd: handleAdd,
    onRemove: handleRemove,
    onChange: handleChange,
  }

  return renderValueEditorPanel(propsIdsPath, values, handlers, props)
})
/*
ValueEditorPanel.propTypes = {
  blockId: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  propsIdsPath: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  values: PropTypes.array,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
} */

// export default ValueEditorPanel
