// @flow

import { NotificationSystem } from '@r1/ui-kit'

type UnauthorizedError = {|
  $type: 'Unauthorized',
|}

export const getData = ({
  httpClient,
  onError,
  options,
  defaultValue = {},
  transformData = data => data,
}: {
  httpClient: any,
  onError?: (error: UnauthorizedError) => void,
  options: Object,
  defaultValue?: any,
  transformData?: Object => Object,
}): Promise<{ value: any, error: any }> => {
  const result = {
    value: defaultValue,
    error: null,
  }

  if (!httpClient) return Promise.resolve(result)

  return httpClient(options)
    .then(data => {
      result.value = transformData(data)
      return result
    })
    .catch(error => {
      const { message, $type } = error

      if ($type === 'Unauthorized' && onError) {
        onError({ $type })
      } else {
        NotificationSystem.addNotification({ level: 'error', message })
      }

      result.error = error
      return result
    })
}

export function hash() {
  return Math.random().toString(16).slice(2, 10)
}
