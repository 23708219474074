import { handleServerError } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'

import { UpdateRuleModel, Rule, Notice, BadProperty } from '@r1-webui/legacy-parts-v1'
import { supplyLevelApi } from '../../../api/legacyParts'
import { replaceTemplateValues } from '../../../utils/responseUtils'

const mapRuleFromApi = (rule: Rule): UpdateRuleModel => ({
  name: rule.name,
  facilityId: rule.facility.id,
  identifierValues: rule.identifierValues,
  requiredCount: rule.requiredCount,
  partCategoryId: rule.partCategoryPath[rule.partCategoryPath.length - 1].id,
})

const handle400error = (badProperties: BadProperty[]) =>
  badProperties.forEach(error =>
    NotificationSystem.addNotification({
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      title: `${error.property}: ${replaceTemplateValues(error as Notice)}`,
      level: 'error',
    }),
  )

const createRule = (body: UpdateRuleModel) =>
  supplyLevelApi.createRule(body).then(response => {
    if (response.status === 422) {
      NotificationSystem.addNotification({
        title: replaceTemplateValues(response.body.error),
        level: 'error',
      })
      throw response
    } else if (response.status === 400) {
      handle400error(response.body.badProperties)
      throw response
    } else
      NotificationSystem.addNotification({
        title: 'Supply Level Rule was successfully created',
        level: 'success',
      })
  })

const getPartCategoryTree = () =>
  supplyLevelApi.getPartCategoryTree().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  })

const getRule = (ruleId: string) =>
  supplyLevelApi.getRule({ ruleId }).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      throw response
    }
    return mapRuleFromApi(response.body)
  })

const updateRule = (ruleId: string, body: UpdateRuleModel) =>
  supplyLevelApi.updateRule({ ruleId }, body).then(response => {
    if (response.status === 422) {
      NotificationSystem.addNotification({
        title: replaceTemplateValues(response.body.error),
        level: 'error',
      })
      throw response
    } else if (response.status === 400) {
      handle400error(response.body.badProperties)
      throw response
    } else if (response.status !== 200) {
      handleServerError(response)
      throw response
    } else {
      NotificationSystem.addNotification({
        title: 'Supply Level Rule was successfully updated',
        level: 'success',
      })

      return response.body
    }
  })

const deleteRule = (ruleId: string) =>
  supplyLevelApi.deleteRule({ ruleId }).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      throw response
    }
    NotificationSystem.addNotification({
      title: 'Supply Level Rule was successfully deleted',
      level: 'success',
    })
  })

const getAllFacilities = () =>
  supplyLevelApi.getAllFacilities().then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }
    return response.body.sort((a, b) => a.name.localeCompare(b.name))
  })

export const supplyLevelRulesService = {
  createRule,
  getPartCategoryTree,
  getRule,
  updateRule,
  deleteRule,
  getAllFacilities,
}
