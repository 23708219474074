// @flow

import React, { useCallback, useState } from 'react'
import { Button, Drawer } from '@r1/ui-kit'
import { Camera } from './children/Camera'

import { Toolbox, Close, Container } from './styles'

type PhotoProps = {
  onPhotoCreate?: (image: string) => void,
}

export const Photo = ({ onPhotoCreate }: PhotoProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [counter, setCounter] = useState(0)

  const onPhotoMake = useCallback(
    image => {
      setCounter(counter + 1)
      if (onPhotoCreate) {
        onPhotoCreate(image)
      }
    },
    [counter, onPhotoCreate],
  )

  const onClose = useCallback(() => {
    setCounter(0)
    setIsOpen(false)
  }, [])

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>Take Photo</Button>
      <Drawer.Form
        closeButton={false}
        show={isOpen}
        size={704}
        placement="bottom"
        backdrop="closing"
        onClose={onClose}
      >
        <Close onClick={onClose}>Close</Close>
        <Container>
          <Camera active={isOpen} onPhotoMake={onPhotoMake} />
          <Toolbox>
            <span>
              Click anywhere to make shot. New photos: <b>{counter}</b>
            </span>
          </Toolbox>
        </Container>
      </Drawer.Form>
    </>
  )
}
