// @flow

import type { Price } from '@r1-webui/productprices-prices-v1/src/types'
import type {
  MarketplaceCategoryMapping,
  ProductInfo,
} from '@r1-webui/productcatalog-products-v1/src/types'
import type { ProductIdentifierValue } from '@r1-webui/sourcedataprovider-v1/src/types'
import type {
  ImageInfo,
  ApprovalState,
} from '@r1-webui/productcatalog-productmanagement-v1/src/types'
import type {
  CategoryTree,
  Category,
} from '@r1-webui/globalcatalog-datasourcecategories-v1/src/types'
import type { ListingCategoryTree } from '@r1-webui/marketplacesettingsmanagement-listingcategorytrees-v1/src/types'
import type { ProductFormValues } from '../../containers/ProductContainer/types/product.type'
import { serialize, deserialize } from '../serialize'
import type { WebUiApi as ProductApi } from '../../api/product/types'
import { toArray } from '../../utils/toArray'
import { toObject } from '../../utils/toObject'
import { processResponse } from '../../components/utils/webUi'
import {
  fieldsSchema,
  categorySchema,
  variationsSchema,
  marketplaceCategorySchema,
  identifiersSchema,
  attributesSchema,
  imagesSchema,
  partsSchema,
  fitsForSchema,
} from './schemas'

type UpdateProduct = {|
  product: ProductFormValues,
  version: string,
  productId: string,
|}

type Product = {|
  product: {
    ...ProductInfo,
    serializedFields: ProductFormValues,
    serializedIdentifiers: ProductFormValues,
    serializedImages: ImageInfo[],
    partNumber: string,
  },
  prices: Price[],
  mapping: {
    ...MarketplaceCategoryMapping,
    autoUpdate: boolean,
    dataSourceId: string | null,
    categories: Category[],
  }[],
  categoryTrees: ListingCategoryTree[],
  categoryAllTrees: CategoryTree[],
|}

const errors: { [string]: string } = { '404': 'notFound', '403': 'forbidden' }

const handleErrorResponse = resp => {
  if (resp.status !== 200) throw new Error(errors[resp.status.toString()] || 'error')
  return resp
}

export const productService = (api: ProductApi) => {
  const updateProduct = async ({ product, productId, version }: UpdateProduct) => {
    const body = {
      fields: toArray(deserialize(product, fieldsSchema), 'type', 'value'),
      category: product.CategoryId ? deserialize(product, categorySchema) : null,
      family: deserialize(product, variationsSchema),
      ...deserialize(product, marketplaceCategorySchema),
      attributes:
        product.Attributes.length > 0 ? deserialize(product, attributesSchema).attributes : null,
      ...deserialize(product, identifiersSchema),
      ...deserialize(product, imagesSchema),
      ...deserialize(product, partsSchema),
      ...deserialize(product, fitsForSchema), // ????
    }

    return api
      .updateProduct({ productId }, { version }, body)
      .then(res => processResponse(res, { body }))
  }

  const rescrapeProductData = async (productId: string) =>
    api.rescrapeProductData({ productId }).then(processResponse)

  const fetchSourcePriceHistory = async (sourceProvider: string, productIdentifierValue: string) =>
    api.searchSourcePriceHistory({ sourceProvider, productIdentifierValue }).then(processResponse)

  const fetchProductInfo = async (productId: string) =>
    // $FlowFixMe[incompatible-call]
    // $FlowFixMe[missing-annot]
    api.getProductInfo({ productId }).then(processResponse)

  const fetchProductPrices = async (productId: string) =>
    api.getPrices({ productId }).then(processResponse)

  const fetchCategoryPathsBulk: any = async categoryTreeCategories =>
    api.getCategoryPathsBulk({ categoryTreeCategories }).then(processResponse)

  const getAllMarketplaceCategories = async (categoryTreeId: string) =>
    api
      .getAllCategories({ categoryTreeId })
      .then(processResponse)
      .then(response => (response.status === 200 ? response.body : []))

  const findAttributeDefinitions = async (attributeDefinitionIds: string[]) =>
    api
      .findAttributeDefinitions({ attributeDefinitionIds })
      .then(processResponse)
      .then(response => (response.status === 200 ? response.body : []))

  // const fetchCategoryTrees = async () => api.getCategoryTrees().then(processResponse)
  const fetchAllCategoryTrees = async () => api.getAllCategoryTrees().then(processResponse)

  const lockProduct = async (
    productId: string,
    lockReasonId: string,
    lockReasonDescription?: string,
  ) => api.lockProduct({ productId }, { lockReasonId, lockReasonDescription })

  const unlockProduct = async (productId: string) => api.unlockProduct({ productId })

  const fetchProduct = async (productId: string): Promise<Product> => {
    return Promise.all([
      fetchProductInfo(productId).then(handleErrorResponse),
      fetchProductPrices(productId),
      // fetchCategoryTrees(),
      fetchAllCategoryTrees(),
    ])
      .then(([product, prices, allTrees]) => {
        const { marketplaceCategoryMappings } = product.body
        let categoryAllTrees = []
        const categoryTrees = []

        let categoryPaths = null

        if (marketplaceCategoryMappings.length > 0) {
          categoryPaths = fetchCategoryPathsBulk(
            marketplaceCategoryMappings.filter(marketplace => marketplace.categoryId),
          )
        }

        if (allTrees.status === 200) {
          categoryAllTrees = allTrees.body
        }

        return Promise.all([
          categoryPaths,
          // attributeDefinitions,
          product,
          prices,
          categoryTrees,
          categoryAllTrees,
        ])
      })
      .then(([categoryPaths, product, prices, categoryTrees, categoryAllTrees]) => {
        const mapping = []
        const { marketplaceCategoryMappings } = product.body

        if (categoryPaths && categoryPaths.status === 200) {
          const { body: paths } = categoryPaths

          marketplaceCategoryMappings.forEach(({ categoryTreeId, categoryId, autoUpdate }) => {
            const path = paths.find(categoryPath => categoryPath.categoryTreeId === categoryTreeId)
            const dataSource = categoryAllTrees.find(({ id }) => categoryTreeId === id)

            const marketplace = {
              categories: [],
              dataSourceId: dataSource ? dataSource.dataSourceId : null,
              categoryTreeId,
              categoryId,
              autoUpdate,
            }

            if (path) {
              if (path.type === 'CategoryTreeCategorySuccess') {
                // marketplace.categoryPath = path.categoryPath.map(item => item.name).reverse()
                marketplace.categories = path.categoryPath
              }
              mapping.push(marketplace)
            }
          })
        }

        const serializedFields: ProductFormValues = serialize(
          toObject(product.body.fields, 'type'),
          fieldsSchema,
        )
        const serializedImages = serialize(product.body, imagesSchema)
        const serializedParts = serialize(product.body, partsSchema)
        const serializedFitsFor = serialize(product.body, fitsForSchema)
        const serializedIdentifiers: ProductFormValues = serialize(product.body, identifiersSchema)

        return {
          product: {
            ...product.body,
            serializedFields,
            serializedIdentifiers,
            serializedAttributes: serialize(product.body, attributesSchema),
            serializedCategory: serialize(product.body.categoryId, categorySchema),
            serializedVariation: serialize(product.body.familyId, variationsSchema),
            serializedImages,
            serializedParts,
            serializedFitsFor,
          },
          prices: prices.status === 200 ? prices.body : [],
          mapping,
          categoryTrees,
          categoryAllTrees,
        }
      })
  }

  const fetchSourceDataInfo = async (identifiers: ProductIdentifierValue[]) =>
    api
      .getSourceDataInfo({ identifiers })
      .then(processResponse)
      .then(response => (response.status === 200 ? response.body : []))

  const fetchProductSourceData = async (sourceProvider: string, productIdentifierValue: string) =>
    api
      .searchProductSourceData({ sourceProvider, productIdentifierValue })
      .then(processResponse)
      .then(response => (response.status === 200 ? response.body : []))

  const updateProductApprovalState = async (productId: string, body: ApprovalState) =>
    api.updateProductApprovalState({ productId }, body).then(processResponse)

  const searchProductNames = async ({
    categoryId,
    searchTerm,
    limit,
  }: {|
    categoryId: string,
    searchTerm: string,
    limit: number,
  |}) =>
    api
      .searchProductNames({ categoryId, searchTerm, limit })
      .then(processResponse)
      .then(response =>
        response.status === 200
          ? response.body.reduce(
              (acc, { id, title, identifiers }) => [
                ...acc,
                {
                  id,
                  name: `${title.value || ''} (${identifiers.primary.value})`,
                },
              ],
              [],
            )
          : [],
      )

  return {
    lockProduct,
    unlockProduct,
    updateProduct,
    rescrapeProductData,
    fetchProductInfo,
    fetchProductPrices,
    fetchProduct,
    fetchSourcePriceHistory,
    getAllMarketplaceCategories,
    findAttributeDefinitions,
    fetchSourceDataInfo,
    fetchProductSourceData,
    updateProductApprovalState,
    searchProductNames,
  }
}
