/* eslint-disable flowtype/no-types-missing-file-annotation */
/* eslint-disable no-console */

import * as React from 'react'
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  FormField,
  H2,
  HR,
  Select,
  TagInput,
  TreeSelect,
} from '@r1/ui-kit'

import { handleNotification } from '../../api'
import { withExport, onSuccess } from '../Export'
import { AUTOSUBMITTED_PROFILES_OPTION_ID, filterNullable } from './utils'
import { Cell, CSSGrid } from './styles'

const remapTripleBoolean = (enabled, disabled, empty = null) => ({
  from: value => {
    switch (value) {
      case true:
        return enabled
      case false:
        return disabled
      default:
        return empty
    }
  },
  to: value => {
    switch (value) {
      case enabled:
        return true
      case disabled:
        return false
      default:
        return empty
    }
  },
})

const emptySubdivision = 'UndefinedSubdivisionFilter'
const notEmptySubdivision = 'SubdivisionListFilter'
const remapSubdivisions = {
  from: subdivisions =>
    subdivisions.map(division => ({
      id: `${division.countryCode}-${division.countrySubdivisionCode}-${division.countryName}-${division.countrySubdivisionName}`,
      name: `${division.countryName}-${division.countrySubdivisionName}`,
    })),
  to: ids => {
    return ids.map(id => {
      let idBug = id // TODO DSP-592: because select return full object when element is removed
      if (Object(id) === id) {
        idBug = id.id
      }
      const divisionArray = idBug.split('-')
      return {
        countryCode: divisionArray[0],
        countrySubdivisionCode: divisionArray[1],
        countryName: divisionArray[2],
        countrySubdivisionName: divisionArray[3],
      }
    })
  },
}

const yesNoRemap = remapTripleBoolean('YES', 'NO')
const yesNoOptions = [
  { id: 'YES', name: 'YES' },
  { id: 'NO', name: 'NO' },
]

const enhance = withExport(({ api }) => ({
  start(options) {
    return api.startPurchaseOrderExport(options)
  },
  get(id) {
    return api.getPurchaseOrderExport(id)
  },
  getStatus(id) {
    return api.getPurchaseOrderExportStatus(id)
  },
  onNotify: handleNotification,
  onSuccess,
}))

const replaceTimeStringToTheMax = (stringOfDate: string | null): string => {
  if (stringOfDate != null) {
    const dateEndPosition = stringOfDate.indexOf('T')
    if (dateEndPosition !== -1) {
      return `${stringOfDate.substring(0, dateEndPosition)}T23:59:59.999`
    }
    return stringOfDate
  }
  return stringOfDate
}

class PurchaseOrdersFilterInternal extends React.Component<{}> {
  state = {
    purchaseOrderIds: [],
    venueProfileIds: [],
    taxCommitmentSystemTransactionIds: [],
    destinationSubdivisionFilter: null,
    createdOnMin: null,
    createdOnMax: null,
    closedOnMin: null,
    closedOnMax: null,
    closed: null,
    locked: null,
    submitted: null,
    reconciled: null,
    anyTaxWithheld: null,
  }

  onPurchaseOrderIdsChange = purchaseOrderIds => {
    this.props.triggerVirtualizationToGetNewTopOffset()
    this.setState({ purchaseOrderIds })
  }

  onVenueProfileIdsChange = venueProfileIds => {
    this.props.triggerVirtualizationToGetNewTopOffset()

    const { autosubmittedVenueProfileIds } = this.props
    const autosubmittedOptionIndex = venueProfileIds.indexOf(AUTOSUBMITTED_PROFILES_OPTION_ID)

    if (autosubmittedOptionIndex === -1) {
      this.setState({ venueProfileIds })
    } else {
      const newVenueProfileIds = [...venueProfileIds]
      newVenueProfileIds.splice(autosubmittedOptionIndex, 1, ...autosubmittedVenueProfileIds)
      this.setState({ venueProfileIds: newVenueProfileIds })
    }
  }

  onCreatedOnMinChange = createdOnMin => this.setState({ createdOnMin })

  onCreatedOnMaxChange = createdOnMax =>
    this.setState({ createdOnMax: replaceTimeStringToTheMax(createdOnMax) })

  onClosedOnMinChange = closedOnMin => this.setState({ closedOnMin })

  onClosedOnMaxChange = closedOnMax =>
    this.setState({ closedOnMax: replaceTimeStringToTheMax(closedOnMax) })

  onClosedChange = id => this.setState({ closed: yesNoRemap.to(id) })

  onLockedChange = id => this.setState({ locked: yesNoRemap.to(id) })

  onSubmittedChange = id =>
    this.setState({
      submitted: yesNoRemap.to(id),
    })

  onReconciledChange = id => this.setState({ reconciled: yesNoRemap.to(id) })

  onAnyTaxWithheldChange = id => this.setState({ anyTaxWithheld: yesNoRemap.to(id) })

  onTaxCommitmentSystemTransactionIdsChange = taxCommitmentSystemTransactionIds =>
    this.setState({ taxCommitmentSystemTransactionIds })

  onDestinationSubdivisionFilterChange = destinationSubdivisionFilter => {
    this.props.triggerVirtualizationToGetNewTopOffset()
    const subdivisions = remapSubdivisions.to(destinationSubdivisionFilter)

    if (subdivisions.length > 0) {
      this.setState({
        destinationSubdivisionFilter: {
          type: notEmptySubdivision,
          subdivisions,
        },
      })
    } else {
      this.setState({
        destinationSubdivisionFilter: null,
      })
    }
  }

  onDestinationSubdivisionFilterEmptyChange = empty => {
    this.props.triggerVirtualizationToGetNewTopOffset()
    if (empty) {
      this.setState({
        destinationSubdivisionFilter: {
          type: emptySubdivision,
        },
      })
    } else {
      this.setState({
        destinationSubdivisionFilter: null,
      })
    }
  }

  onSubmit = () => {
    if (
      this.state.destinationSubdivisionFilter &&
      this.state.destinationSubdivisionFilter.type === notEmptySubdivision
    ) {
      const newFilter = {
        ...this.state,
        destinationSubdivisionFilter: {
          ...this.state.destinationSubdivisionFilter,
          subdivisions: this.state.destinationSubdivisionFilter.subdivisions.map(sub => ({
            countryCode: sub.countryCode,
            countrySubdivisionCode: sub.countrySubdivisionCode,
          })),
        },
      }
      this.props.onChange(newFilter)
    } else {
      this.props.onChange(this.state)
    }
  }

  handleExport = () => {
    this.props.startExport(filterNullable(this.state))
  }

  render() {
    const {
      purchaseOrderIds,
      venueProfileIds,
      createdOnMin,
      createdOnMax,
      closedOnMin,
      closedOnMax,
      closed,
      locked,
      submitted,
      reconciled,
      anyTaxWithheld,
      taxCommitmentSystemTransactionIds,
      destinationSubdivisionFilter,
    } = this.state

    const { venueProfiles, subdivisions } = this.props

    return (
      <Flex column>
        <CSSGrid width="1110px">
          <Cell span={6}>
            <FormField>
              <FormField.Label>Avalara transaction IDs</FormField.Label>
              <TagInput
                data-test-id="avalara-transaction-ids"
                $type="simpleTagValue"
                width={506}
                delimiter={['\n', ',']}
                value={taxCommitmentSystemTransactionIds}
                onChange={this.onTaxCommitmentSystemTransactionIdsChange}
              />
            </FormField>
          </Cell>
          <Cell span={4} />
          <Cell span={6}>
            <FormField>
              <FormField.Label>Submitted to Avalara</FormField.Label>
              <Select
                data-test-id="submitted-to-avalara"
                filterable={false}
                placeholder="Select value"
                value={yesNoRemap.from(submitted)}
                options={yesNoOptions}
                onChange={this.onSubmittedChange}
              />
            </FormField>
          </Cell>
          <Cell span={4} />
          <Cell span={6}>
            <FormField>
              <FormField.Label>Tax filled with state</FormField.Label>
              <Select
                simpleValue
                data-test-id="tax-filled"
                filterable={false}
                placeholder="Select value"
                value={yesNoRemap.from(locked)}
                options={yesNoOptions}
                onChange={this.onLockedChange}
              />
            </FormField>
          </Cell>
        </CSSGrid>
        <H2>Marketplace Purchase Orders</H2>
        <CSSGrid width="1110px">
          <Cell span={4}>
            <FormField>
              <FormField.Label>Order IDs</FormField.Label>
              <TagInput
                data-test-id="order-ids"
                $type="simpleTagValue"
                width={506}
                delimiter={['\n', ',']}
                value={purchaseOrderIds}
                onChange={this.onPurchaseOrderIdsChange}
              />
            </FormField>
          </Cell>
          <Cell span={3}>
            <FormField>
              <FormField.Label>Created from</FormField.Label>
              <DatePicker
                data-test-id="created-from"
                value={createdOnMin}
                onChange={this.onCreatedOnMinChange}
                onRemove={this.onCreatedOnMinChange}
              />
            </FormField>
          </Cell>
          <Cell span={3}>
            <FormField>
              <FormField.Label>to</FormField.Label>
              <DatePicker
                data-test-id="created-to"
                value={createdOnMax}
                onChange={this.onCreatedOnMaxChange}
                onRemove={this.onCreatedOnMaxChange}
              />
            </FormField>
          </Cell>
          <Cell span={4}>
            <FormField>
              <FormField.Label>Order Closed</FormField.Label>
              <Select
                data-test-id="order-closed"
                filterable={false}
                placeholder="Select value"
                value={yesNoRemap.from(closed)}
                options={yesNoOptions}
                onChange={this.onClosedChange}
              />
            </FormField>
          </Cell>
          <Cell span={3}>
            <FormField>
              <FormField.Label>Closed date from</FormField.Label>
              <DatePicker
                data-test-id="closed-date-from"
                value={closedOnMin}
                onChange={this.onClosedOnMinChange}
                onRemove={this.onClosedOnMinChange}
              />
            </FormField>
          </Cell>
          <Cell span={3}>
            <FormField>
              <FormField.Label>to</FormField.Label>
              <DatePicker
                data-test-id="closed-date-to"
                value={closedOnMax}
                onChange={this.onClosedOnMaxChange}
                onRemove={this.onClosedOnMaxChange}
              />
            </FormField>
          </Cell>
          <Cell span={10}>
            <FormField>
              <FormField.Label>Destination Address Country & State</FormField.Label>
              <Select
                multiple
                data-test-id="destination-address"
                simpleValue={false}
                placeholder="Select value"
                value={remapSubdivisions.from(
                  (destinationSubdivisionFilter && destinationSubdivisionFilter.subdivisions) || [],
                )}
                options={remapSubdivisions.from(subdivisions)}
                onChange={this.onDestinationSubdivisionFilterChange}
              />
            </FormField>
          </Cell>
          <Cell span={10}>
            <Checkbox
              checked={
                !!(
                  destinationSubdivisionFilter &&
                  destinationSubdivisionFilter.type === emptySubdivision
                )
              }
              label="Destination address not identified"
              onChange={this.onDestinationSubdivisionFilterEmptyChange}
            />
          </Cell>
          <Cell span={10}>
            <FormField>
              <FormField.Label>Marketplace profile</FormField.Label>
              <TreeSelect
                multiple
                data-test-id="marketplace-profile"
                value={venueProfileIds}
                options={venueProfiles}
                valueKey="id"
                labelKey="name"
                parentKey="parentId"
                onChange={this.onVenueProfileIdsChange}
              />
            </FormField>
          </Cell>
          <Cell span={3}>
            <FormField>
              <FormField.Label>Tax Withheld by Marketplace</FormField.Label>
              <Select
                data-test-id="tax-withheld-marketplace"
                filterable={false}
                placeholder="Select value"
                value={yesNoRemap.from(anyTaxWithheld)}
                options={yesNoOptions}
                onChange={this.onAnyTaxWithheldChange}
              />
            </FormField>
          </Cell>
          <Cell span={2}>
            <FormField>
              <FormField.Label>Reconciled</FormField.Label>
              <Select
                data-test-id="reconciled"
                filterable={false}
                placeholder="Select value"
                value={yesNoRemap.from(reconciled)}
                options={yesNoOptions}
                onChange={this.onReconciledChange}
              />
            </FormField>
          </Cell>
          <Cell span={10} margin="-0.5em 0 0 0">
            <HR />
          </Cell>
          <Cell span={2} end={9}>
            <Button
              block
              loading={this.props.exportInProgress}
              color="secondary"
              onClick={this.handleExport}
            >
              Export
            </Button>
          </Cell>
          <Cell span={2} end={11}>
            <Button block onClick={this.onSubmit}>
              Search
            </Button>
          </Cell>
        </CSSGrid>
      </Flex>
    )
  }
}

export const PurchaseOrdersFilter = enhance(PurchaseOrdersFilterInternal)
