// @flow

import React from 'react'
import { Link, Icon } from '@r1/ui-kit'

import type { Replacement } from '../../types/ClaimCreation'
import { ReplacementItemWrapper } from './StyledComponents'

type Props = {
  editMode?: boolean,
  replacement: Replacement,
  deleteHandler: (replacement: Replacement) => void,
}

export const ReplacementItem = ({ editMode, replacement, deleteHandler }: Props) => {
  return (
    <ReplacementItemWrapper editMode={editMode}>
      <Link to={`/r1/product/${replacement.productId}`} target="_blank">
        {replacement.trgId}
        <br />
        {replacement.title}
      </Link>
      {editMode && (
        <Icon type="clear" cursor="pointer" onClick={() => deleteHandler(replacement)} />
      )}
    </ReplacementItemWrapper>
  )
}
