// @flow

import * as React from 'react'
import { useMemo } from 'react'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'

import { httpClient } from '../../../../../httpClient'

export const GlobalPricingRules = () => {
  const initialStateQuery = useMemo(() => {
    const query = parseQuery()
    return {
      ...query,
      pagination: {
        ...query.pagination,
        pageSize: 100,
      },
    }
  }, [])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowCompanyPriceView]}>
      <Main fullScreen>
        <ContentHeader />
        <ContentHeader.Title>Aggregated Prices</ContentHeader.Title>
        <Content>
          <GridGenerator
            gridId="AggregatedPricingRulesGrid"
            gridEngineBaseUrl="/uisvc/product-prices"
            initialStateQuery={initialStateQuery}
            httpClient={httpClient}
            aggregationCount={{ title: '', suppressAggregation: true }}
            onChangeState={setQuery}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
