import React from 'react'
import styled from '@emotion/styled'
import { Box, Flex, Text, useConfigurationProvider } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'
import { formatLastRefreshDate } from '../reports.utils'
import { CardTitle } from './CardTitle'
import { CardWithBackground } from './CardWithBackground'
import { ArrowIcon } from './ArrowIcon'
import { ReportWarningIcon } from './ReportWarningIcon'

type Props = {
  name: string
  reportUrl: string
  version?: string
  description?: string
  lastRefreshDate?: string
  lastRefreshSuccessful?: boolean
}

const ReportVersionBox = styled(Box)`
  background-color: rgba(22, 41, 63, 0.08);
  border-radius: 4px;
  padding: 2px 4px;
`

export const ReportCard = React.memo<Props>(
  ({ name, reportUrl, version, description, lastRefreshDate, lastRefreshSuccessful }) => {
    const { palette } = useConfigurationProvider()

    const background = <GenericIcon svgId="reportPage" width={140} height={140} />

    const onClick = () => window.open(reportUrl, '_self')

    return (
      <CardWithBackground background={background} onClick={onClick}>
        <Flex column justify="space-between" basis={1}>
          <Flex column spaceBetween="XS">
            <Flex justify="space-between">
              <CardTitle>{name}</CardTitle>
              <ArrowIcon color={palette.grey[400]} rotationDegree={-45} />
            </Flex>

            <Text maxLines={5} overflow="ellipsis">
              {description}
            </Text>
          </Flex>

          <Flex column>
            {lastRefreshDate ? (
              <>
                <Text color={palette.grey[700]}>Last refresh date:</Text>
                <Flex spaceBetween="XXS" align="center">
                  <Text color={palette.grey[700]}>{formatLastRefreshDate(lastRefreshDate)}</Text>
                  {!lastRefreshSuccessful && <ReportWarningIcon />}
                </Flex>
              </>
            ) : null}

            {version ? (
              <Flex spaceBetween="XS" align="center">
                <Text color={palette.grey[700]}>Version</Text>
                <ReportVersionBox alignSelf="flex-start">
                  <Text color={palette.grey[700]}>{version}</Text>
                </ReportVersionBox>
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      </CardWithBackground>
    )
  },
)
