// @flow

const attributeTypeMapping = {
  Boolean: 'Boolean',
  Date: 'Date',
  DateRange: 'Date Range',
  Decimal: 'Decimal Number',
  DecimalEnumeration: 'Decimal Number List',
  DecimalRange: 'Decimal Number Range',
  IntEnumeration: 'Integer Number List',
  IntRange: 'Integer Number Range',
  Integer: 'Integer Number',
  String: 'Text',
  StringEnumeration: 'Text List',
}

export const getAttributeValueTypeText = (
  type: $Keys<typeof attributeTypeMapping>,
): $Values<typeof attributeTypeMapping> => {
  return attributeTypeMapping[type] ? attributeTypeMapping[type] : type
}

export const getAttributeBindingType = (
  type: 'ProductAttributeDefinition' | 'UnitAttributeDefinition',
): 'Product' | 'Unit' => {
  switch (type) {
    case 'ProductAttributeDefinition':
      return 'Product'
    case 'UnitAttributeDefinition':
      return 'Unit'
    default:
      return (type: empty)
  }
}
