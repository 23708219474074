// @flow

import * as React from 'react'
import { Flex, Loader } from '@r1/ui-kit'
import { compareAsc, isEqual } from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import type { ClaimMessage, CurrentUser } from '../../types/ClaimMessage'
import { MessageLogItem } from './MessageLogItem'
import { MessageForm } from './MessageForm'
import { NoMessagesText } from './StyledComponents'

type Props = {
  currentUser: CurrentUser,
  isLoading: boolean,
  messages: ClaimMessage[],
  onUpdateMessage: (messageId: string, text: string) => Promise<void>,
}

export const MessageLog = ({ currentUser, isLoading, messages, onUpdateMessage }: Props) => {
  const [editableMessage, setEditableMessage] = useState<string | null>(null)

  const lastMessageDate = useMemo(
    () =>
      messages
        .map(msg => new Date(msg.messageOn))
        .reduce((date1, date2) => {
          if (compareAsc(date1, date2) === 1) return date1
          return date2
        }, new Date()),
    [messages],
  )

  const saveMessage = useCallback(
    function saveMessage(messageId) {
      return async text => {
        await onUpdateMessage(messageId, text)
        setEditableMessage(null)
      }
    },
    [onUpdateMessage],
  )

  const setEditable = useCallback(function setEditable(id) {
    return () => {
      setEditableMessage(id)
    }
  }, [])

  const renderMessages =
    messages === undefined || messages.length === 0 ? (
      <Flex m="M" justify="center" align="center">
        <NoMessagesText>No messages</NoMessagesText>
      </Flex>
    ) : (
      <Flex column spaceBetween="S">
        {messages.map(
          msg =>
            (editableMessage === msg.id && (
              <MessageForm
                key={msg.id}
                initText={msg.text}
                onCancel={setEditable(msg.id)}
                onSaveMessage={saveMessage(msg.id)}
              />
            )) || (
              <MessageLogItem
                key={msg.id}
                message={msg}
                canEdit={
                  msg.userId === currentUser.id && isEqual(new Date(msg.messageOn), lastMessageDate)
                }
                onEditClick={setEditable(msg.id)}
              />
            ),
        )}
      </Flex>
    )

  return <div>{isLoading ? <Loader /> : renderMessages}</div>
}
