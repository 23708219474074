import * as React from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { Main } from '@r1/wireframe-primary'
import { rescrapeProductData } from './module'
import { ProductTemplate, ProductTemplateCreatingForm } from './components'

import * as actions from './module'

const mapStateToProps = ({ app, productTemplate, mainInfo }) => ({
  isFetching: app.isFetching,
  isLoading: productTemplate.isLoading,
  lockStatus: productTemplate.lockStatus,
  productData: mainInfo.model,
  reviewInfo: productTemplate.reviewInfo,
  reviewReasonOptions: productTemplate.reviewReasonOptions,
})

const enhance = connect(mapStateToProps, actions)

function onCancel() {
  window.history.back()
}

const ProductTemplateScreenInner = ({
  isLoading,
  isFetching,
  toggleLoading,
  loadReview,
  loadReviewReasons,
  checkForLocking,
  submitAll,
  productData,
  createNewTemplate,
  reviewInfo,
  reviewReasonOptions,
  lockStatus,
}) => {
  const templateAttributesFormRef = React.useRef()
  const params = useParams()

  React.useEffect(() => {
    toggleLoading(true)
    loadReviewReasons()
    checkForLocking(params.id)
    if (params.id !== 'new') {
      loadReview(params.id)
    }
  }, [])

  React.useEffect(() => {
    if (isLoading && !isFetching) {
      toggleLoading(false)
    }
  }, [isLoading, isFetching, toggleLoading])

  const saveAttributeValues = () => {
    if (templateAttributesFormRef && templateAttributesFormRef.current) {
      return templateAttributesFormRef.current.saveValues(params.id)
    }

    return undefined
  }

  const onApprove = () => {
    submitAll({
      productTemplateId: params.id,
      approve: true,
      saveAttributeValues,
    })
  }

  const onSubmitForm = () => {
    submitAll({
      productTemplateId: params.id,
      saveAttributeValues,
    })
  }

  const onSubmitReview = (reason, comment) => {
    submitAll({
      productTemplateId: params.id,
      review: { reason, comment },
      saveAttributeValues,
    })
  }

  const handleProductDataRescrape = () => {
    return rescrapeProductData(params.id)
  }

  const { id } = productData || {}
  const isNew = params.id === 'new'
  const templateWasMerged = !!id && !!params.id && Number(params.id) !== id

  return (
    <Main>
      {isNew ? (
        <ProductTemplateCreatingForm onCreate={createNewTemplate} onCancel={onCancel} />
      ) : (
        <ProductTemplate
          id={params.id}
          templateAttributesFormRef={templateAttributesFormRef}
          templateWasMerged={templateWasMerged}
          reviewReasons={reviewReasonOptions}
          lockStatus={lockStatus}
          reviewInfo={reviewInfo}
          onProductDataRescrape={handleProductDataRescrape}
          onApprove={onApprove}
          onSubmitReview={onSubmitReview}
          onSubmitForm={onSubmitForm}
          onCancel={onCancel}
        />
      )}
    </Main>
  )
}

export const ProductTemplateScreen = enhance(ProductTemplateScreenInner)
