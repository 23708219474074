import { Action } from '../constants'

const initialState = {
  actions: [],
  currentActionIndex: -1,
  canUndo: false,
  canRedo: false,
}

export default reducerFunction => {
  // eslint-disable-next-line default-param-last
  return (state = initialState, action) => {
    switch (action.type) {
      case Action.UNDO: {
        if (state.actions.length === 0) {
          console.error("Can't undo because actions is empty")
          return state
        }

        if (state.currentActionIndex < 0) {
          console.error("Can't undo because actions are ended")
          return state
        }

        const undoAction = state.actions[state.currentActionIndex].undo

        if (!undoAction) {
          console.error(`Can't find undo in ${action.type} action`)
          return state
        }

        return {
          currentState: reducerFunction(state.currentState, undoAction),
          actions: state.actions,
          currentActionIndex: state.currentActionIndex - 1,
          canRedo: true,
          canUndo: state.currentActionIndex > 0,
        }
      }
      case Action.REDO: {
        if (state.actions.length === 0) {
          console.error("Can't redo because actions is empty")
          return state
        }

        if (state.currentActionIndex + 1 >= state.actions.length) {
          console.error("Can't redo because actions are ended")
          return state
        }

        const currentaction = state.actions[state.currentActionIndex + 1]

        return {
          currentState: reducerFunction(state.currentState, currentaction),
          actions: state.actions,
          currentActionIndex: state.currentActionIndex + 1,
          canRedo: state.currentActionIndex + 2 < state.actions.length,
          canUndo: true,
        }
      }
      default: {
        const newState = reducerFunction(state.currentState, action)

        if (action.undo) {
          return {
            currentState: newState,
            actions: [...state.actions.slice(0, state.currentActionIndex + 1), action],
            currentActionIndex: state.currentActionIndex + 1,
            canUndo: true,
            canRedo: false,
          }
        }
        return { ...state, currentState: newState }
      }
    }
  }
}
