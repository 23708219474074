// @flow

import type { CommonError, SuccessResponse, UnauthorizedError } from '@r1/types/flow/libTypes'
import { replaceTemplateValues, showNotification } from '../../../../utils'

export function isResponse(response: mixed): %checks {
  return (
    typeof response === 'object' && response !== null && !Array.isArray(response) && response.$type
  )
}

export function isErrorResponse(response: mixed): %checks {
  return (
    typeof response === 'object' &&
    response !== null &&
    !Array.isArray(response) &&
    response.$type !== 'Success'
  )
}

export function isSuccessResponse(response: mixed): %checks {
  return (
    typeof response === 'object' &&
    response !== null &&
    !Array.isArray(response) &&
    response.$type === 'Success'
  )
}

const mapStatus = (status: number, obj: Object) => async (err: any) => {
  if (err.response) {
    if (err.response.status === status) {
      const message = err.response.data.defaultFormat

      return {
        $type: obj.$type,
        message,
      }
    }
  }
  return null
}

export const mapToCommonErrors = (err: any): CommonError | UnauthorizedError => {
  if (err.response) {
    const { status } = err.response

    if (status === 401) return { $type: 'Unauthorized', message: 'Unauthorized' }
  }
  return {
    $type: 'Common',
    payload: err,
  }
}

export const mapToSuccessResponse = <P>(data: P): SuccessResponse<P> => {
  return {
    $type: 'Success',
    payload: data,
  }
}

const getForbiddenOrNull = mapStatus(403, {
  $type: 'Forbidden',
})

export const mapForbiddenError = async (err: any) => {
  const forbidden = await getForbiddenOrNull(err)
  if (forbidden) return forbidden
  throw err
}

const defaultPermissionErrorHandler = () =>
  showNotification({ title: 'Permissions Denied', level: 'error' })

export function processResponse<T>(
  response: T,
  permissionErrorHandler: () => void = defaultPermissionErrorHandler,
): T {
  if (isErrorResponse(response)) {
    if (response.message && typeof response.message === 'string') {
      showNotification({ title: response.message, level: 'error' })
    }
    // eslint-disable-next-line default-case
    switch (response.$type) {
      case 'Forbidden':
        permissionErrorHandler()
        break
    }
  }

  return response
}

function replaceNameNotices(data: any): any {
  if (typeof data !== 'object' || data === null) {
    return data
  }

  if (Array.isArray(data)) {
    data.forEach(replaceNameNotices)
    return data
  }

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'name' && typeof value === 'object' && value !== null) {
      // eslint-disable-next-line no-param-reassign
      data.name = replaceTemplateValues(data.name)
    } else if (typeof value === 'object' && value !== null) {
      replaceNameNotices(value)
    }
  })

  return data
}

export async function successResponseInterceptor<I: {}, R: {} | Array<I>>(response: {
  data: R,
}): Promise<SuccessResponse<R>> {
  const translatedResponse = replaceNameNotices(response)
  return mapToSuccessResponse(translatedResponse)
}
