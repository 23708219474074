export const getTransformFromBlock = block => {
  return {
    position: {
      x: block.position.x,
      y: block.position.y,
    },
    rotation: block.rotation,
    size: {
      width: block.size.width,
      height: block.size.height,
    },
  }
}
