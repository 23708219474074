import React, { memo } from 'react'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { Report } from './Report'
import * as config from './config'
import { getReportParamsFromUrlAndSaveToLocalStorage } from './utils/getReportParamsFromUrlAndSaveToLocalStorage'

function generateMsalConfig() {
  const msalConfig = {
    auth: {
      clientId: (window?._env_?.POWER_BI_CLIENT_ID as string) || '',
      authority: config.authorityUrl,
      redirectUri: `${window.location.origin}/r1/power-bi-report`,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
  }
  return msalConfig
}

const msalInstanceProvider = new PublicClientApplication(generateMsalConfig())

export const PowerBiReport = memo(() => {
  const { reportId, workspaceId } = getReportParamsFromUrlAndSaveToLocalStorage()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowReportDashboardsView]}>
      <MsalProvider instance={msalInstanceProvider}>
        {reportId && workspaceId && <Report reportId={reportId} workspaceId={workspaceId} />}
      </MsalProvider>
    </PageAccessControl>
  )
})
