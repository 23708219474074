// @flow

import * as React from 'react'
import { Modal, Flex, Uploader, Loader } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { ClearButton } from '../ClearButton'

import type { ModalImage, UploaderPayload } from './types'

type Props = {
  show: boolean,
  isUploading: boolean,
  isSaving: boolean,
  images: ModalImage[],
  deleteImageHandler: (index: number) => void | ((index: number) => Promise<void>),
  uploaderHandler: (uploaderPayload: UploaderPayload) => Promise<void>,
  closeHandler: () => void,
  saveHandler: () => Promise<void>,
}

export const View = (props: Props) => {
  const {
    show,
    isUploading,
    isSaving,
    images,
    deleteImageHandler,
    uploaderHandler,
    closeHandler,
    saveHandler,
  } = props

  const uploaderHandlerSync = React.useCallback(
    (uploaderPayload: UploaderPayload) => {
      uploaderHandler(uploaderPayload)
    },
    [uploaderHandler],
  )

  const bottomActionButtons: BottomActionButton[] = React.useMemo(
    function generateBottomActionButton() {
      return [
        {
          align: 'right',
          onClick: closeHandler,
          color: 'secondary',
          title: 'Cancel',
          loading: isSaving,
        },
        {
          align: 'right',
          onClick: saveHandler,
          color: 'primary',
          title: 'Save',
          loading: isSaving,
        },
      ]
    },
    [closeHandler, saveHandler, isSaving],
  )

  const onRemove = React.useCallback(
    (index: number) => () => {
      deleteImageHandler(index)
    },
    [deleteImageHandler],
  )

  return (
    <Modal
      isControlled
      size="M"
      show={show}
      title="Add Photos"
      actionButtons={bottomActionButtons}
      onEscapeKeyDown={closeHandler}
    >
      {isUploading ? <Loader /> : <Uploader onChange={uploaderHandlerSync} />}
      {images.length > 0 && (
        <Flex flexWrap spaceBetween="L">
          {images.map((image, index) => (
            <ClearButton key={image.id} icon="clear" onRemove={onRemove(index)}>
              <img width="100" height="100" src={image.base64Image} />
            </ClearButton>
          ))}
        </Flex>
      )}
    </Modal>
  )
}
