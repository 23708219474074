// @flow

import type { WithControllerState } from './types'

export const defaultState: WithControllerState = {
  lineId: '',
  message: '',
  images: [],
  isMessageLengthError: false,
  isUploading: false,
}
