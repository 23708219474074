// @flow

import React, { memo } from 'react'
import { Box, FormField, NumericInput } from '@r1/ui-kit'
import type { DecimalAttributeChildrenProps } from '../../../../../../types/attribute.type'

const MAX_NUMBER = 2147483647

const minAllowedValue = -1 * MAX_NUMBER
const maxAllowedValue = MAX_NUMBER

export const Decimal = memo<DecimalAttributeChildrenProps>(
  ({
    error,
    title,
    handleChange,
    optionIndex,
    disabled,
    value,
    valueType,
  }: DecimalAttributeChildrenProps) => {
    return (
      <FormField>
        <FormField.Label>{title}</FormField.Label>
        <Box minWidth={255} maxWidth={255}>
          <NumericInput
            error={!!error}
            disabled={disabled}
            placeholder="Enter number"
            value={
              // eslint-disable-next-line no-nested-ternary
              value != null
                ? valueType === 'Decimal'
                  ? parseFloat(value)
                  : parseInt(value, 10)
                : null
            }
            maxFractionDigits={valueType === 'Decimal' ? 100 : 0}
            min={minAllowedValue}
            max={maxAllowedValue}
            onChange={handleChange(['Attributes', optionIndex, 'formValue'])}
          />
        </Box>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    )
  },
)
