import * as R from 'ramda'

import { Program } from '@r1-webui/programprovider-v1'
import { BadProperty, Notice, Rule } from '@r1-webui/shipping-returnshippingrules-v1'
import { NotificationSystem } from '@r1/ui-kit'
import { replaceTemplateValues } from '../../../utils/responseUtils'

export type ProgramTreeItem = {
  id: string
  name: string
  parentId?: string | null
}

export const mapProgramsToTree = (programs: Program[]): ProgramTreeItem[] => {
  const groups: string[] = []

  programs.forEach(({ groupName }) => {
    if (!groupName || groups.includes(groupName)) return
    groups.push(groupName)
  })

  return programs
    .map(program =>
      program.groupName
        ? {
            id: program.id,
            name: program.name,
            parentId: program.groupName,
          }
        : {
            id: program.id,
            name: program.name,
          },
    )
    .concat(
      groups.map(groupName => ({
        id: groupName,
        name: groupName,
      })),
    )
    .sort((a, b) => {
      if (!a.parentId && b.parentId) {
        return -1
      }
      if (a.parentId && !b.parentId) {
        return 1
      }
      if (!a.parentId && !b.parentId) {
        return a.name.localeCompare(b.name)
      }
      if (a.parentId && b.parentId) {
        return a.parentId.localeCompare(b.parentId)
      }
      return -1
    })
}

export const replacePhoneNumber = (rule: Rule): Rule => {
  return {
    ...rule,
    contactInformation: {
      ...rule.contactInformation,
      phoneNumber: rule.contactInformation.phoneNumber.replace(/\D/g, ''),
    },
  }
}

export const removeEmptyFields = (rule: Rule) => {
  const copy: Rule = R.clone(rule)
  if (copy.description === '') {
    delete copy.description
  }

  copy.shippingOptions = copy.shippingOptions
    .filter(option => option.shippingProfileId)
    .map(option => {
      const optionCopy = R.clone(option)
      if (!optionCopy.thirdPartyShippingProfileId) {
        delete optionCopy.thirdPartyShippingProfileId
      }

      if (
        optionCopy.shippingServices &&
        optionCopy.shippingServices.shippingServices.length === 0
      ) {
        delete optionCopy.shippingServices
      }
      return optionCopy
    })

  if (
    copy.ruleConstraints.weightRange &&
    !copy.ruleConstraints.weightRange.from &&
    !copy.ruleConstraints.weightRange.to
  ) {
    const { ruleConstraints } = copy
    delete ruleConstraints.weightRange
    copy.ruleConstraints = ruleConstraints
  }

  if (
    copy.ruleConstraints.dimensionalWeightRange &&
    !copy.ruleConstraints.dimensionalWeightRange.from &&
    !copy.ruleConstraints.dimensionalWeightRange.to
  ) {
    const { ruleConstraints } = copy
    delete ruleConstraints.dimensionalWeightRange
    copy.ruleConstraints = ruleConstraints
  }

  if (
    copy.ruleConstraints.salePriceRange &&
    !copy.ruleConstraints.salePriceRange.from &&
    !copy.ruleConstraints.salePriceRange.to
  ) {
    const { ruleConstraints } = copy
    delete ruleConstraints.salePriceRange
    copy.ruleConstraints = ruleConstraints
  }

  if (
    copy.ruleConstraints.maximumShippingPrice &&
    !copy.ruleConstraints.maximumShippingPrice.amount
  ) {
    const { ruleConstraints } = copy
    delete ruleConstraints.maximumShippingPrice
    copy.ruleConstraints = ruleConstraints
  }

  return copy
}

export const prepareRule = R.compose(replacePhoneNumber, removeEmptyFields)

export const handle400error = (badProperties: BadProperty[]) =>
  badProperties.forEach(error =>
    NotificationSystem.addNotification({
      title: `${error.property}: ${replaceTemplateValues(error as Notice)}`,
      level: 'error',
      autoDismiss: false,
    }),
  )
