// @flow

import React, { memo, useMemo, useCallback } from 'react'
import { Box, FormField, HR, Flex } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { Text, Value } from './Common'

const Header = styled('span')`
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 14px;
  font-weight: bold;
  font-variant: small-caps;
`

type MarketplaceMappingsProps = {
  mapping: { marketplace: string, categoryPath: string[], isMarketplace: boolean }[],
}

const Marketplace = ({ mapping }: MarketplaceMappingsProps) => {
  const getMapping = useCallback(
    ({ marketplace, categoryPath }, index) => (
      <Flex key={marketplace}>
        <FormField.Label>{marketplace}</FormField.Label>
        <Value data-test-id={marketplace}>{categoryPath.join(' / ')}</Value>
        {index !== mapping.length - 1 ? <HR /> : null}
      </Flex>
    ),
    [mapping.length],
  )

  const supported = useMemo(() => mapping.filter(({ isMarketplace }) => isMarketplace), [mapping])
  const notSupported = useMemo(
    () => mapping.filter(({ isMarketplace }) => !isMarketplace),
    [mapping],
  )

  return (
    <>
      {mapping.length === 0 && <Text>There are no category mappings yet</Text>}
      {supported.length > 0 && (
        <>
          <Box mb="M" mt="L">
            <Header>SUPPORTED MARKETPLACES</Header>
          </Box>
          {supported.map(getMapping)}
        </>
      )}
      {notSupported.length > 0 && (
        <>
          <Box mb="M" mt="L">
            <Header>NOT SUPPORTED MARKETPLACES</Header>
          </Box>
          {notSupported.map(getMapping)}
        </>
      )}
    </>
  )
}

export const MarketplaceMappings = memo<MarketplaceMappingsProps>(Marketplace)
