import React, { useCallback, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { UpdateRuleModel } from '@r1-webui/legacy-parts-v1'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Placeholder, Link } from '@r1/ui-kit'
import { supplyLevelRulesService } from '../services'
import { RuleFormValues } from '../types'
import { SupplyLevelRuleForm } from '../components/SupplyLevelRuleForm'
import { ROUTES } from '../../../navigation'

export const EditSupplyLevelRule = () => {
  const navigate = useNavigate()
  const { supplyLevelRuleId = '' } = useParams<{ supplyLevelRuleId: string }>()
  const [supplyLevelRule, setSupplyLevelRule] = useState<RuleFormValues | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const handleSubmit = useCallback(
    async (settings: UpdateRuleModel) => {
      try {
        setIsSubmitting(true)
        await supplyLevelRulesService.updateRule(supplyLevelRuleId, settings)
        navigate(ROUTES.SUPPLY_LEVEL_RULES)
      } finally {
        setIsSubmitting(false)
      }
    },
    [navigate, supplyLevelRuleId],
  )

  const handleDeleteRule = useCallback(async () => {
    setIsSubmitting(true)
    try {
      await supplyLevelRulesService.deleteRule(supplyLevelRuleId)
      navigate(ROUTES.SUPPLY_LEVEL_RULES)
    } finally {
      setIsSubmitting(false)
    }
  }, [navigate, supplyLevelRuleId])

  const fetchRule = useCallback(async () => {
    setIsLoading(true)
    try {
      const rule = await supplyLevelRulesService.getRule(supplyLevelRuleId)
      setSupplyLevelRule(rule)
      setIsLoading(false)
    } catch (error) {
      if ((error as Record<string, unknown>).status === 404) navigate(ROUTES.NOT_FOUND)
      else navigate(ROUTES.SUPPLY_LEVEL_RULES)
    }
  }, [navigate, supplyLevelRuleId])

  useEffect(() => {
    fetchRule()
  }, [fetchRule])

  const handleClose = useCallback(() => {
    navigate(ROUTES.SUPPLY_LEVEL_RULES)
  }, [navigate])

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowSupplyLevelRuleEdit]}>
      <Main>
        <ContentHeader>
          <ContentHeader.Breadcrumbs>
            <Link to={ROUTES.SUPPLY_LEVEL_RULES}>Supply Level Rules</Link>
          </ContentHeader.Breadcrumbs>
          <ContentHeader.Title>{`Edit Supply Level Rule ${
            supplyLevelRule ? `"${supplyLevelRule.name}"` : ''
          } `}</ContentHeader.Title>
        </ContentHeader>

        <Content>
          {isLoading && <Placeholder type="form" height={5} />}

          {supplyLevelRule !== null && (
            <SupplyLevelRuleForm
              initialValue={supplyLevelRule}
              isSubmitting={isSubmitting}
              onSubmit={handleSubmit}
              onClose={handleClose}
              onDelete={handleDeleteRule}
            />
          )}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
