export const getParentName = (tree, id) => {
  const currentEl = tree.find(t => t.id === id) || {}
  return currentEl.parentId
    ? `${getParentName(tree, currentEl.parentId)} - ${currentEl.name}`
    : `${currentEl.name}`
}

const getChilds = (tree, id) => {
  const currentChilds = tree.filter(({ parentId }) => parentId === id)
  const childsOfChilds = []
  currentChilds.forEach(child => {
    const childs = getChilds(tree, child.id)
    childs.length > 0 ? childsOfChilds.push(...childs) : childsOfChilds.push(child)
  })
  return childsOfChilds
}

export const computeCategories = (tree, values) => {
  const allChilds = []
  values.forEach(value => {
    const childs = getChilds(tree, value)
    childs.length > 0
      ? allChilds.push(...childs)
      : allChilds.push(tree.find(({ id }) => id === value))
  })

  return allChilds.filter(Boolean).map(child => {
    const fullPath = child.parentId
      ? `${getParentName(tree, child.parentId)} - ${child.name}`
      : child.name
    return { ...child, fullPath }
  })
}
