// @flow

import type { AttributeDefinition } from '../../../types/internal/api/attributeDefinitions'
import type { BindingType } from '../../../types/public/attributeDefinitionsCommon'

export function getAttributeBindingType(definition: AttributeDefinition): BindingType {
  switch (definition.type) {
    case 'ProductAttributeDefinition':
      return 'Product'
    case 'UnitAttributeDefinition':
      return 'Unit'
    default:
      throw new Error(`[product-catalog] Unexpected attribute definition type: ${definition.type}`)
  }
}
