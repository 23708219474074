// @flow

import * as React from 'react'

import { Flex, Loader } from '@r1/ui-kit'

import type { Replacement } from '../../types/ClaimCreation'
import type { ReplacementsPickerProps } from './types'

const DOM_SEARCH_BUTTON_ID = 'InventorySearchForm_btnSearch'
const DOM_PRODUCT_CHECKBOX_SELECTOR = '[name="ProductID"]'

export const ReplacementsPicker = (props: ReplacementsPickerProps) => {
  const { width, height, productId, replacements, onReplacementChanged } = props

  const [isLoading, setIsLoading] = React.useState(true)
  const [loaderText, setLoaderText] = React.useState('')
  const iframeEl = React.useRef(null)

  const replacementsMap = React.useMemo(() => {
    const object = Object.assign({}, ...replacements.map(x => ({ [x.productId]: x })))
    const map = new Map(Object.entries(object))
    return map
  }, [replacements])

  // $FlowIgnore[cannot-resolve-name] There is no HTMLDocument definition in Flow.
  const getDocument = (): HTMLDocument => {
    return iframeEl.current ? iframeEl.current.contentDocument : new HTMLDocument()
  }

  const getReplacement = (checkboxEl: HTMLInputElement): Replacement => {
    const replacement = {
      unitId: '',
      productId: checkboxEl.value,
      trgId: '',
      upc: '',
      title: '',
    }

    const td1 = checkboxEl.closest('td')
    if (!td1) return replacement

    const td2 = td1.nextElementSibling
    if (!td2) return replacement

    const a = td2.querySelector('a')
    if (a && a.innerText) {
      replacement.trgId = a.innerText
      replacement.unitId = a.innerText.replace('TRG-', '')
    }

    const td3 = td2.nextElementSibling
    if (!td3) return replacement
    replacement.upc = td3.innerText || ''

    const td4 = td3.nextElementSibling
    if (!td4) return replacement
    replacement.title = td4.innerText || ''

    return replacement
  }

  const onChecked = (e: MouseEvent) => {
    const checkbox = ((e.target: any): HTMLInputElement)
    const replacement = getReplacement(checkbox)

    if (replacement) {
      onReplacementChanged(replacement, checkbox.checked)
    }
  }

  const hideActionPanel = () => {
    const document = getDocument()
    const form = document.forms[0]
    if (form) {
      const elements = form.getElementsByClassName('fixed-bottom')
      const panel = elements[0]
      panel.style.display = 'none'
    } else {
      console.warn('Form to pick replacements was not found on the page.')
    }
  }

  const setHandlerForSearchButton = () => {
    const document = getDocument()
    const button = document.getElementById(DOM_SEARCH_BUTTON_ID)
    button.onclick = () => {
      setIsLoading(true)
    }
  }

  const setValueAndHandlerForCheckboxes = () => {
    const document = getDocument()
    const checkboxes = [...document.querySelectorAll(DOM_PRODUCT_CHECKBOX_SELECTOR)]
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      const c = checkbox
      c.checked = replacementsMap.has(c.value)
      c.onclick = onChecked
    })
  }

  const setValueForCheckboxes = () => {
    const document = getDocument()
    const checkboxes = [...document.querySelectorAll(DOM_PRODUCT_CHECKBOX_SELECTOR)]
    checkboxes.forEach((checkbox: HTMLInputElement) => {
      const c = checkbox
      c.checked = replacementsMap.has(c.value)
    })
  }

  const adjustIframe = () => {
    hideActionPanel()
    setValueAndHandlerForCheckboxes()
    setHandlerForSearchButton()
  }

  React.useEffect(() => {
    setValueForCheckboxes()
  }, [replacements])

  const onLoad = () => {
    setIsLoading(false)
    setLoaderText('Searching...')
    window.setImmediate(adjustIframe) // Wait the end of page rendering.
  }

  return (
    <Flex column>
      {isLoading && (
        <div style={{ height: `${height}px` }}>
          <Loader text={loaderText} />
        </div>
      )}
      <div style={{ display: isLoading ? 'none' : 'block' }}>
        <iframe
          ref={iframeEl}
          src={
            productId
              ? `/Modules/Shipping/ProductSearch.aspx?view=0000010&cpid=${productId}&RMA=1`
              : null
          }
          width={width}
          height={height}
          onLoad={onLoad}
        />
      </div>
    </Flex>
  )
}
