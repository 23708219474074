import { Rule, RulePriority } from '@r1-webui/shipping-returnshippingrules-v1'
import { handleServerError } from '@r1/core-blocks'
import { NotificationSystem } from '@r1/ui-kit'
import { rmaShippingRulesApi } from '../../../api/rmaShippingRules'
import { replaceTemplateValues } from '../../../utils/responseUtils'
import { prepareRule, handle400error } from './helpers'

export const getRule = async (ruleId: string): Promise<Rule | null> => {
  const res = await rmaShippingRulesApi.getRule({ ruleId })
  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  return res.body
}

export const createRule = async (
  rule: Rule,
): Promise<{
  id: string
} | null> => {
  const res = await rmaShippingRulesApi.createRule(prepareRule(rule))

  if (res.status === 400) {
    handle400error(res.body.badProperties)
    return null
  }

  if (res.status === 403 || res.status === 409 || res.status === 422) {
    NotificationSystem.addNotification({
      level: 'error',
      message: replaceTemplateValues(res.body),
      autoDismiss: false,
    })
    return null
  }

  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  return res.body
}

export const updateRule = async (
  rule: Rule,
  ruleId: string,
): Promise<{ version: number } | null> => {
  const res = await rmaShippingRulesApi.updateRule({ ruleId }, prepareRule(rule))

  if (res.status === 400) {
    handle400error(res.body.badProperties)
    return null
  }

  if (res.status === 403 || res.status === 404 || res.status === 409 || res.status === 422) {
    NotificationSystem.addNotification({
      level: 'error',
      message: replaceTemplateValues(res.body),
      autoDismiss: false,
    })
    return null
  }

  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  return res.body
}

export const getPriorities = async (): Promise<RulePriority[]> => {
  const res = await rmaShippingRulesApi.getPrioritiesByRules()
  if (res.status !== 200) {
    handleServerError(res)
    return []
  }

  return res.body
}
