// @flow

import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import {
  Box,
  Flex,
  H3,
  Input,
  // Radio,
  Select,
  RadioGroup,
  Icon,
  Button,
  Link,
  FormField,
  Text,
} from '@r1/ui-kit'
import type { CommonProductFormEditProps } from '../../../../types/product.type'

const StyledH3 = styled(H3)`
  color: ${({ theme }) => theme.palette.grey[800]};
`

const StyledLabel = styled('div')`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const RIN_ID = '83cfc7ab81fe4ac298d88768dbef82d7'

export const Identifiers = ({
  values,
  identifiersList,
  handleChange,
  push,
  remove,
  getError,
  disabled,
}: CommonProductFormEditProps) => {
  const addIdentifierCallback = useCallback(() => {
    if (identifiersList && identifiersList.length > 0) {
      push('Identifiers', {
        type: identifiersList[0].id,
        value: '',
        isPrimary: false,
      })
    }
  }, [identifiersList, push])

  /* const onRadioChangeCallback = useCallback(
    optionIndex => {
      values.Identifiers.forEach((item, index) => (item.isPrimary = index === optionIndex))
      handleChange(['Identifiers'])(values.Identifiers)
    },
    [handleChange, values.Identifiers],
  ) */

  return (
    <>
      <Box>
        <StyledH3>Product Identifiers</StyledH3>
      </Box>
      <Box>
        <Flex column minWidth={890} maxWidth={890}>
          <Flex spaceBetween="XL">
            <Box minWidth={255} maxWidth={255} mt="XXS">
              <StyledLabel>Type</StyledLabel>
            </Box>
            <Box minWidth={255} maxWidth={255} mt="XXS">
              <StyledLabel>Identifier</StyledLabel>
            </Box>
            {/* <Box minWidth={255} maxWidth={255} mt={'XXS'}>
              <StyledLabel>
                Primary
              </StyledLabel>
            </Box> */}
          </Flex>
          <RadioGroup>
            {values.Identifiers.map((identifier, optionIndex) => (
              <FormField
                // eslint-disable-next-line react/no-array-index-key
                key={optionIndex}
              >
                <Flex spaceBetween="XL">
                  <Box minWidth={255} maxWidth={255}>
                    {identifier.type && identifier.type === RIN_ID ? (
                      <Flex align="center" mx="L">
                        <StyledLabel>RIN</StyledLabel>
                      </Flex>
                    ) : (
                      <Select
                        disabled={identifier.isPrimary || disabled}
                        clearable={false}
                        filterable={false}
                        value={identifier.type}
                        valueKey="id"
                        labelKey="name"
                        options={identifiersList}
                        onChange={(handleChange: any)(['Identifiers', optionIndex, 'type'])}
                      />
                    )}
                  </Box>

                  <Box minWidth={255} maxWidth={255}>
                    <Input
                      disabled={
                        identifier.isPrimary ||
                        /** TODO remove this hardcode in next sprint */
                        (identifier.type && identifier.type === RIN_ID) ||
                        disabled
                      }
                      error={!!getError(['Identifiers', optionIndex, 'value'])}
                      placeholder="Enter identifier"
                      value={identifier.value}
                      onChange={value =>
                        (handleChange: any)(['Identifiers', optionIndex, 'value'])(value.trim())
                      }
                    />
                  </Box>

                  <Box>
                    {identifier.isPrimary ? (
                      <Flex align="center">
                        <StyledLabel>Primary identifier</StyledLabel>
                      </Flex>
                    ) : (
                      <Button
                        transparent
                        disabled={disabled}
                        color="secondary"
                        onClick={() => remove('Identifiers', optionIndex)}
                      >
                        <Icon type="clear" />
                      </Button>
                    )}
                  </Box>
                </Flex>
                <FormField.Error>{getError(['Identifiers', optionIndex, 'value'])}</FormField.Error>
              </FormField>
            ))}
          </RadioGroup>
          {!disabled && (
            <Box pb="S">
              <Link onClick={addIdentifierCallback}>
                <Text weight="medium">+ Add Identifier</Text>
              </Link>
            </Box>
          )}
        </Flex>
      </Box>
    </>
  )
}
