// @flow

import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'

import type { WithController } from '../types'

export class RefundController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  onGenerateShippingLabel = async (value: null | string) => {
    const { claimId } = this.component.props

    if (value) {
      await this.component.api.claims
        .createShippingLabelRequest({ claimId }, { warehouseId: value })
        .then(resp =>
          resp.status === 200
            ? this.component.setState({ labelIsGenerated: true })
            : handleServerError(resp),
        )
    }
  }

  onWithReturnChange = () =>
    this.component.setState(({ withReturn }) => ({
      withReturn: !withReturn,
    }))

  onForceRefund = async (value?: null | string) => {
    if (!this.component.state.data) return
    const { claim, decision } = this.component.state.data

    // We need notify user if it tries to make something else than Full Refund

    if (!decision || decision.action !== 'FullRefund') {
      NotificationSystem.addNotification({
        title: 'Only Full Refund is supported',
        level: 'error',
      })
      return
    }

    if (this.component.state.withReturn && value) {
      this.component.setState({ refundIsSubmitting: true })
      await this.onGenerateShippingLabel(value)

      if (!this.component.state.labelIsGenerated) {
        NotificationSystem.addNotification({
          title: 'Something went wrong, try again',
          level: 'error',
        })

        return
      }
    }

    this.component.setState({ refundIsSubmitting: true })

    this.component.api.claims.createForcedRefundRequest({ claimId: claim.id }).then(resp => {
      this.component.setState({ refundIsSubmitting: false, sidebarIsVisible: false })
      if (resp.status === 200) {
        NotificationSystem.addNotification({
          title: 'Refund request was successfully created.',
          level: 'success',
        })
      } else {
        handleServerError(resp)
      }
    })
  }
}

export const createRefundController = (component: WithController) => new RefundController(component)
