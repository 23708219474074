// @flow

import * as React from 'react'

import { Flex, FormField, Select, Textarea } from '@r1/ui-kit'

import type { StepsProps } from '../types'
import { OrderInfo } from '../../OrderInfo'
import { ClaimHistory } from '../../ClaimHistory'
import { OrderInfoWrapper, TitleLabel } from './StyledComponents'

export const ClaimInfoStep = (props: StepsProps) => {
  const { claimCreationInfo } = props
  const {
    order,
    selectedExpectedDecisionType,
    claimDescription,
    orderStatus,
    rmaProfile,
    shippingAddress,
  } = claimCreationInfo

  const { purchaseOrderId, rmaProfileId } = order || { purchaseOrderId: '', rmaProfileId: '' }

  return (
    <>
      <OrderInfoWrapper>
        <OrderInfo
          order={order}
          rmaProfile={rmaProfile}
          orderStatus={orderStatus}
          shippingAddress={shippingAddress}
        />
      </OrderInfoWrapper>

      <Flex spaceBetween="XXL">
        <FormField>
          <FormField.Label>Decision:</FormField.Label>
          <Select
            data-test-id="decision"
            options={selectedExpectedDecisionType.availableOptions}
            value={selectedExpectedDecisionType.value}
            error={!!selectedExpectedDecisionType.error}
            valueKey="id"
            labelKey="title"
            placeholder="Select Decision"
            onChange={selectedExpectedDecisionType.onChange}
          />
          <FormField.Error>{selectedExpectedDecisionType.error}</FormField.Error>
        </FormField>

        <FormField>
          <FormField.Label>Description:</FormField.Label>
          <Textarea
            rows={3}
            value={claimDescription.value || ''}
            error={!!claimDescription.error}
            onChange={claimDescription.onChange}
          />
          <FormField.Error>{claimDescription.error}</FormField.Error>
        </FormField>
      </Flex>

      <TitleLabel>Previous claims for this order:</TitleLabel>
      <ClaimHistory purchaseOrderId={purchaseOrderId} rmaProfileId={rmaProfileId} />
    </>
  )
}
