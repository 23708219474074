/* eslint-disable flowtype/no-types-missing-file-annotation */

import * as React from 'react'
import styled from '@emotion/styled'

import { Pagination } from '@r1/grid'
import { Box, Flex, Text } from '@r1/ui-kit'

import { createApi as createFinancialApi } from '../../api/FinancialEvents'
import createApi, { handleNotification } from '../../api'
import { filterNullable } from '../PurchaseOrdersScreen/utils'
import { remapPagination } from '../utils'

import { FinancialEventsFilter } from './FinancialEventsFilterInternal'
import { FinancialEventsGrid } from './FinancialEventsGrid'

const Container = styled('div')`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
`
const MARKETPLACE_PURPOSE_ID = 'B2CSellingVenueProfile'

const availablePageSizes = [200, 500, 1000, 2000, 5000, 10000, 25000]

export class FinancialEventsScreen extends React.Component {
  constructor(props) {
    super(props)

    this.financialApi = createFinancialApi(this.props.httpClient, {
      onError: this.props.onError || (() => {}),
      onNotify: handleNotification,
    })
    this.api = createApi(this.props.httpClient, {
      onError: this.props.onError || (() => {}),
      onNotify: handleNotification,
    })

    this.state = {
      financialEvents: [],
      venueProfiles: [],
      filter: {
        purchaseOrderFilter:
          (props.gridFilter &&
            props.gridFilter.purchaseOrderId && {
              type: 'FinancialEventsPurchaseOrderIdsFilter',
              purchaseOrderIds: [props.gridFilter.purchaseOrderId],
            }) ||
          null,
        venueProfileIds: [],
        financialEventType: (props.gridFilter && props.gridFilter.financialEventType) || null,
        title: null,
      },
      offset: 0,
      limit: 1000,
      totalCount: 0,
      loading: true,
    }
  }

  async componentDidMount(): void {
    const [financialEventsQueriedData, venueProfiles] = await Promise.all([
      this.fetchFinancialEvents({ filter: this.state.filter }),
      this.fetchVenueProfiles(),
    ])
    if (financialEventsQueriedData == null) {
      this.setState({ loading: false })
    } else {
      this.setState({
        financialEvents: financialEventsQueriedData.financialEvents,
        totalCount: financialEventsQueriedData.totalCount,
        venueProfiles,
        loading: false,
      })
    }
  }

  fetchVenueProfiles = () => {
    return this.api.fetchVenueProfiles(MARKETPLACE_PURPOSE_ID)
  }

  fetchFinancialEvents = ({ filter, offset, limit } = {}) => {
    return this.financialApi.getFinancialEvents({
      filter: filterNullable(filter) || filterNullable(this.state.filter),
      offset: offset != null ? offset : this.state.offset,
      limit: limit != null ? limit : this.state.limit,
    })
  }

  onSubmit = async filter => {
    this.setState({ loading: true })
    const financialEventsQueriedData = await this.fetchFinancialEvents({
      filter: filterNullable(filter),
    })
    if (financialEventsQueriedData == null) {
      this.setState({ loading: false })
    } else {
      this.setState({
        filter: filterNullable(filter),
        financialEvents: financialEventsQueriedData.financialEvents,
        totalCount: financialEventsQueriedData.totalCount,
        loading: false,
      })
    }
  }

  onChangePagination = async ({ page, pageSize }) => {
    this.setState({ loading: true })
    const pagination = remapPagination.to({ page, pageSize })
    const financialEventsQueriedData = await this.fetchFinancialEvents(pagination)
    this.setState({
      ...pagination,
      financialEvents: financialEventsQueriedData.financialEvents,
      totalCount: financialEventsQueriedData.totalCount,
    })
    this.setState({ loading: false })
  }

  render() {
    const { financialEvents, venueProfiles, loading, filter, totalCount, limit, offset } =
      this.state

    const gridData = financialEvents.map(event => {
      if (venueProfiles == null) {
        return {
          ...event,
          venueProfileName: '',
        }
      }
      const marketPlaceProfile = venueProfiles.find(({ id }) => event.venueProfileId === id)
      return {
        ...event,
        venueProfileName: (marketPlaceProfile && marketPlaceProfile.name) || '',
      }
    })

    return (
      <Container>
        <FinancialEventsFilter
          {...filter}
          api={this.api}
          venueProfiles={venueProfiles}
          onSubmit={this.onSubmit}
        />
        <Flex spaceBetween="S" mb="M" align="center">
          <Box>
            <Text>
              Records found: <span>{totalCount}</span>
            </Text>
          </Box>
        </Flex>
        <Pagination
          {...remapPagination.from({ limit, offset })}
          loading={loading}
          rowCount={totalCount}
          availablePageSizes={availablePageSizes}
          onChange={this.onChangePagination}
        >
          <FinancialEventsGrid data={gridData} loading={loading} />
        </Pagination>
      </Container>
    )
  }
}
