import React from 'react'
import { connect } from 'react-redux'
import { FormField, Flex } from '@r1/ui-kit'

import { InputType, BarcodeFormat, BlockType, Ids } from '../../constants'
import { changeBlockProperty } from '../../actions'
import Container from './Container'
import Input from './Input'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    if (block.type === BlockType.BARCODE_BLOCK) {
      return {
        blockId: block.id,
        format: block.format,
      }
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onChange: (blockId, oldFormat, newFormat) =>
    dispatch(changeBlockProperty(blockId, ['format'], oldFormat, newFormat)),
})

export const BarcodeBlockPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ blockId, format, onChange, ...props }) => {
  if (!format) {
    return null
  }

  const handleChange = newFormat => {
    onChange(blockId, format, newFormat)
  }

  return (
    <Container {...props}>
      <Flex>
        <FormField.Label>Format</FormField.Label>
        <Input
          type={InputType.SELECT}
          data={format}
          options={Object.keys(BarcodeFormat).map(key => ({
            id: BarcodeFormat[key],
            name: BarcodeFormat[key],
          }))}
          onChange={handleChange}
        />
      </Flex>
    </Container>
  )
})
/*
BarcodeBlockPanel.propTypes = {
  blockId: PropTypes.number,
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
} */
