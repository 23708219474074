// @flow

import * as React from 'react'
import { Button, Flex, NotificationSystem } from '@r1/ui-kit'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import type { Api as ClaimChatApi } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/claimChat'
import type {
  ChatMessage as ChatMessageApi,
  Attachment,
} from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { ExpectedDecision, ReturnReason } from '@r1-webui/rmavendorportal-v1/src/types'

import type { CustomerInfo, ClaimInfo, OrderInfo } from '../../types/common'
import type { NewClaimItem } from '../../types/ClaimScreen'
import { ChatMessage } from './ChatMessage'
import { NewMessageForm } from './NewMessageForm'

type Props = {
  chatApi: ClaimChatApi,
  customer: CustomerInfo,
  claim: ClaimInfo,
  order: OrderInfo,
  expectedDecisionTypes: ExpectedDecision[],
  returnReasons: ReturnReason[],
  setHasUnreadMessages: (flag: boolean) => void,
  createClaim: (
    chatMessageId: Lib$Id,
    decisionTypeId: Lib$Id,
    description: string,
    claimItems: NewClaimItem[],
  ) => Promise<string | null>,
}

type MessageToAttachmentsMap = {
  messageId: string,
  attachments: Attachment[],
}

export const ClaimChat = (props: Props) => {
  const {
    chatApi,
    customer,
    claim,
    order,
    expectedDecisionTypes,
    returnReasons,
    setHasUnreadMessages,
    createClaim,
  } = props

  const [messages, setMessages] = React.useState<ChatMessageApi[]>([])
  const [attachmentsMap, setAttachmentsMap] = React.useState<MessageToAttachmentsMap[]>([])

  const hasUnreadMessages = React.useMemo(() => messages.some(item => item.unread), [messages])

  React.useEffect(
    () => setHasUnreadMessages(hasUnreadMessages),
    [hasUnreadMessages, setHasUnreadMessages],
  )

  const fetchTimer = 5000

  React.useEffect(() => {
    async function fetchChat() {
      const response = await chatApi.getChat({ claimId: claim.id })
      if (response.status !== 200) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error',
          message: 'There are connection troubles with chat system, please refresh page',
        })
        return
      }

      setMessages(response.body)
    }
    fetchChat()
    const timer = setInterval(fetchChat, fetchTimer)
    return () => clearInterval(timer)
  }, [chatApi, claim, setHasUnreadMessages])

  const markAsRead = React.useCallback(
    (messageId: string) => async () => {
      const i = messages.findIndex(msg => msg.id === messageId)
      const newMessages = [...messages]
      if (newMessages[i].unread) {
        newMessages[i].unread = false
        const response = await chatApi.markChatMessageAsRead({ claimId: claim.id, messageId })
        if (response.status !== 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Error',
            message: 'Cannot fetch chat updates',
          })
        }
        setMessages(newMessages)
      }
    },
    [chatApi, claim, messages],
  )

  const markAllAsRead = React.useCallback(() => {
    messages.forEach(message => {
      if (message.unread) {
        markAsRead(message.id)()
      }
    })
  }, [markAsRead, messages])

  const submitMessage = React.useCallback(
    async (text: string, date: string) => {
      const response = await chatApi.createChatMessage(
        { claimId: claim.id },
        {
          messageOn: date,
          messageType: 'Regular',
          recipient: 'All',
          text,
        },
      )
      if (response.status !== 200) {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Error',
          message: 'There are connection troubles with chat system, please try again later',
        })
        return
      }

      setMessages(prevMessages => {
        const newMessages = [...prevMessages]
        newMessages.push({
          id: response.body,
          messageOn: date,
          messageType: 'Regular',
          recipient: 'All',
          unread: false,
          text,
          sender: 'Merchant',
          attachments: [],
        })
        return newMessages
      })
    },
    [chatApi, claim],
  )

  const getAttachment = React.useCallback(
    (messageId: string) =>
      async (attachmentExternalId: ?string): Promise<?Attachment> => {
        let attachments = []

        const messageAttachments = attachmentsMap.find(m => m.messageId === messageId)
        if (messageAttachments) {
          attachments = messageAttachments.attachments
        } else {
          const response = await chatApi.getChatMessageAttachments({ claimId: claim.id, messageId })
          if (response.status !== 200) {
            NotificationSystem.addNotification({
              level: 'error',
              title: 'Error',
              message: 'There are connection troubles with chat system, please try again later',
            })
            return undefined
          }

          attachments = response.body
          setAttachmentsMap(prevAttachments => {
            const newAttachments = [...prevAttachments]
            newAttachments.push({
              messageId,
              attachments,
            })
            return newAttachments
          })
        }

        const attachment = attachments.find(a => a.externalId === attachmentExternalId)
        return attachment
      },
    [chatApi, claim, attachmentsMap],
  )

  const expectedDecisionChatMessageIds = React.useMemo(
    () => messages.filter(m => m.messageType === 'ExpectedDecision').map(m => m.id),
    [messages],
  )

  return (
    <Flex column>
      {messages.map((msg: ChatMessageApi) => (
        <ChatMessage
          key={msg.id}
          chatMessageId={msg.id}
          messageType={msg.messageType}
          sender={msg.sender}
          recipient={msg.recipient}
          text={msg.text}
          unread={msg.unread}
          sentOn={msg.messageOn}
          markAsRead={markAsRead(msg.id)}
          getAttachment={getAttachment(msg.id)}
          marketplaceId={msg.externalId}
          attachments={msg.attachments}
          expectedDecisionChatMessageIds={expectedDecisionChatMessageIds}
          claim={claim}
          order={order}
          customer={customer}
          availableDecisionTypes={expectedDecisionTypes}
          availableReturnReasons={returnReasons}
          createClaim={createClaim}
        />
      ))}
      <Flex justify="space-around" mt="M" mb="M">
        <Button disabled={!hasUnreadMessages} onClick={markAllAsRead}>
          Mark all as read
        </Button>
      </Flex>
      <NewMessageForm submit={submitMessage} />
    </Flex>
  )
}
