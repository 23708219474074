import * as React from 'react'
import { useMemo } from 'react'
import { Button, Flex, Icon, Link } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { useBackgroundTasksProvider } from '@r1/wireframe-primary'
import { httpClient } from '../../../../httpClient'
import { getGridBulkActions } from '../../utils/getGridBulkActions'
import { SubStampsDrawer } from './components/SubStamps/SubStampsDrawer'
import { MasterProgramRuleTesterModal } from './components/MasterProgramRuleTesterModal'

const API_ROUTES = {
  engine: '/uisvc/legacy-grids/v2/Grids/engine',
  queryStorage: '/uisvc/legacy-grids/v2/Grids/querystorage',
  viewSettingsStorage: '/uisvc/legacy-grids/v2/Grids/view',
}

const AGGREGATION_COUNT = {
  title: 'All master programs',
  suppressAggregation: false,
}

type MasterProgramGridProps = {
  gridRef: React.RefObject<GridGenerator>
  onCreateMasterProgram: () => void
  onEditMasterProgram: (ruleId: string) => void
  onOpenHistoryDrawer: (ruleId: string) => void
  onDeleteMasterProgram: (ruleId: string) => Promise<void>
}

const ActionButton = ({ onAddRule }: { onAddRule: () => void }) => {
  const [{ allowMasterProgramEdit }] = useAccessControl()

  return allowMasterProgramEdit ? (
    <Button onClick={onAddRule}>
      <Icon type="plus" color="inverted" />
      Add rule
    </Button>
  ) : null
}

export const MasterProgramGrid = React.memo(
  ({
    gridRef,
    onCreateMasterProgram,
    onEditMasterProgram,
    onOpenHistoryDrawer,
    onDeleteMasterProgram,
  }: MasterProgramGridProps) => {
    const initialStateQuery = useMemo(parseQuery, [])
    const { showBackgroundTasks } = useBackgroundTasksProvider()

    const toolbarActionButtons = React.useMemo(
      () => (
        <Flex spaceBetween="M">
          <ActionButton onAddRule={onCreateMasterProgram} />
          <MasterProgramRuleTesterModal />
          <SubStampsDrawer />
        </Flex>
      ),
      [onCreateMasterProgram],
    )

    const initialCustomColumnsSettings = React.useMemo(
      () => [
        {
          columnId: 'Name',
          cell: {
            $type: 'custom',
            renderer: ({ Id, Name }: Record<string, string>) => (
              <Link
                onClick={() => {
                  onEditMasterProgram(Id)
                }}
              >
                {Name}
              </Link>
            ),
          },
        },
      ],
      [onEditMasterProgram],
    )

    const deleteMasterProgramWithReload = React.useCallback(
      (ruleId: string) => {
        onDeleteMasterProgram(ruleId).then(() => gridRef.current?.refreshGrid())
      },
      [onDeleteMasterProgram, gridRef],
    )

    const rowActions = React.useMemo(
      () => ({
        requiredColumns: ['Id'],
        actionsRenderer: ({ Id }: { Id: string }) => [
          {
            title: 'Open Rule History',
            handler: () => onOpenHistoryDrawer(Id),
          },
          {
            title: 'Delete',
            handler: () => deleteMasterProgramWithReload(Id),
          },
        ],
      }),
      [onOpenHistoryDrawer, deleteMasterProgramWithReload],
    )

    const bulkActions = getGridBulkActions(() => {
      if (gridRef.current) {
        gridRef.current.clearSelection()
      }
    })

    return (
      <GridGenerator
        ref={gridRef}
        fullscreen
        fullscreenMode="container"
        gridId="MasterPrograms"
        apiRoutes={API_ROUTES}
        initialStateQuery={initialStateQuery}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        httpClient={httpClient}
        initialCustomColumnsSettings={initialCustomColumnsSettings}
        toolbarActionButtons={toolbarActionButtons}
        aggregationCount={AGGREGATION_COUNT}
        rowActions={rowActions}
        bulkActions={bulkActions}
        onChangeState={setQuery}
        onOpenBackgroundTasks={showBackgroundTasks}
      />
    )
  },
)
