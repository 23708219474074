// @flow

import * as React from 'react'
import { concat } from 'ramda'
import { Flex, FormField, HR } from '@r1/ui-kit'
import { NumberRange } from '../../../../utils/utils'
import { Text } from '../Text'
import { TagViewList } from '../../../AttributeDefinitions/AttributeDefinitionView/AttributeDefinitionView'
import type { StringConstraint as StringConstraintValue } from '../types'

// eslint-disable-next-line consistent-return
const getForbidden = (forbiddenSubstrings, forbiddenWords) => {
  if (forbiddenSubstrings && forbiddenWords) return concat(forbiddenSubstrings, forbiddenWords)
  if (forbiddenSubstrings || forbiddenWords) return forbiddenSubstrings || forbiddenWords
}

type Props = {| value: StringConstraintValue |}

export const StringConstraint = ({ value }: Props) => {
  return (
    <>
      {(value.minAllowedLength || value.maxAllowedLength) && (
        <Flex column spaceBetween="S">
          <HR />
          <Flex spaceBetween="XXS">
            <FormField.Label>Allowed String Length</FormField.Label>
            <Text>
              <NumberRange from={value.minAllowedLength} to={value.maxAllowedLength} /> Symbols
            </Text>
          </Flex>
        </Flex>
      )}
      {(value.forbiddenSubstrings || value.forbiddenWords) && (
        <Flex column spaceBetween="S">
          <HR />
          <Flex spaceBetween="XXS">
            <FormField.Label>Forbidden The Following</FormField.Label>
            <TagViewList
              list={getForbidden(value.forbiddenSubstrings, value.forbiddenWords) || []}
            />
          </Flex>
        </Flex>
      )}
    </>
  )
}
