// @flow

import type { ClaimType } from '../types/common'

export const RETURN_REASON_NOT_FUNCTIONING = 'e4d220bc96fb4c02bb8406faf8039de3'

const EXPECTED_DECISION_TYPE_REFUND_AFTER_RETURN = '5993009353ce429ca98f8eec0f863ca5'
const EXPECTED_DECISION_TYPE_REFUND_ONLY = 'cfbb00a846674be1bc0db04e5d815e4c'
const EXPECTED_DECISION_TYPE_RETURN_ONLY = '4f7271dd99544e04b4d98fbe9a1cce23'
const EXPECTED_DECISION_TYPE_REPLACEMENT_AFTER_RETURN = '2a27bcf0708740588f55805d8ec945a2'
const EXPECTED_DECISION_TYPE_REPLACEMENT_ONLY = 'cd7c3883f22f4b84ba99244209f512a4'

export const removeBase64ImageType = (encoded: string) => {
  const regExp = /^data:image\/(.+);base64,/
  return encoded.replace(regExp, '')
}

type ReaderResponse = {
  status: 200 | 500,
  body: string,
}

export function convertFileToBase64(file: File): Promise<ReaderResponse> {
  return new Promise<ReaderResponse>(resolve => {
    const reader = new FileReader()

    reader.onloadend = () => {
      resolve({
        status: 200,
        body: String(reader.result),
      })
    }

    reader.onerror = () => {
      resolve({
        status: 500,
        body: 'File reading Error',
      })
    }

    reader.readAsDataURL(file)
  })
}

export function orderId(order: { rmaProfileId: string, purchaseOrderId: string }): string {
  return `${order.rmaProfileId}|${order.purchaseOrderId}`
}

export function getClaimType(expectedDecisionTypeId: string): ClaimType {
  switch (expectedDecisionTypeId) {
    case EXPECTED_DECISION_TYPE_REFUND_AFTER_RETURN:
      return 'RefundAfterReturn'
    case EXPECTED_DECISION_TYPE_REFUND_ONLY:
      return 'RefundOnly'
    case EXPECTED_DECISION_TYPE_RETURN_ONLY:
      return 'ReturnOnly'
    case EXPECTED_DECISION_TYPE_REPLACEMENT_AFTER_RETURN:
      return 'ReplacementAfterReturn'
    case EXPECTED_DECISION_TYPE_REPLACEMENT_ONLY:
      return 'ReplacementOnly'
    default:
      return 'RefundAfterReturn'
  }
}

export function getClaimStateText(claimState?: string): string {
  switch (claimState) {
    case 'Created':
      return 'Created'
    case 'DecisionExecuting':
      return 'In progress'
    case 'DecisionExecuted':
      return 'In progress'
    case 'Closed':
      return 'Closed'
    case 'Canceled':
      return 'Canceled'
    case 'Error':
      return 'Error'
    default:
      return 'Unhandled status'
  }
}
