/* eslint-disable no-return-await */
import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { storageUpload } from '../storage/images'
import { productImagesApi } from '../../api/products'

const {
  initialState,
  types,
  reducers,
  name,
  selectors,
  actions: moduleActions,
} = createCollectionModule({
  name: '@products/images',
  keyField: 'id',
  params: ['productId'],
  fetchAllFunc: ({ productId }) => productImagesApi.getImages({ id: productId }),
})

const uploadImage = (productId, base64) => async dispatch => {
  const url = await dispatch(storageUpload(base64))
  return await productImagesApi.add({ id: productId }, { url })
}

const removeImage = (productId, imageId) => () =>
  productImagesApi.delete({ id: productId, imageId })

const actions = { uploadImage, removeImage, ...moduleActions }

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}
export { selectors, actions }
