import { useState, useEffect } from 'react'

import { Warehouse } from '@r1-webui/facilitypickupscheduler-v1'

import { getWarehouseLocations } from '../../../services/schedulePickupService'

type UseWarehouseHookState = {
  isWarehousesloading: boolean
  warehouses: Warehouse[]
}

export const useWarehouses = (): UseWarehouseHookState => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([])
  const [isWarehousesloading, setWarehousesLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchWarehouses = async () => {
      setWarehousesLoading(true)
      const warehouseLocations = await getWarehouseLocations()
      setWarehouses(warehouseLocations)
      setWarehousesLoading(false)
    }
    fetchWarehouses()
  }, [])

  return { isWarehousesloading, warehouses }
}
