import * as React from 'react'
import styled from '@emotion/styled'
import { withTheme, Modal, Flex, Icon } from '@r1/ui-kit'

const CurrentImage = styled('img')`
  max-height 100%;
  max-width 100%;
  object-fit contain;
  margin 10px;
`

const PreviewImage = styled('img')`
  width: 40px;
  height: 40px;
  border: 1px solid #000;
  margin-right: 5px;
  user-select: none;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  cursor: pointer;
`

const ExtendedFlex = styled(Flex)`
  height: ${({ height }) => height || 'unset'};
  vertical-align: ${({ valign }) => valign || 'unset'};
`

const getImageIndexById = (images, id) => {
  return images.findIndex(image => image.id === id)
}

const getNextArrayIndex = (array, currentIndex) => {
  return currentIndex === array.length - 1 ? 0 : currentIndex + 1
}

const getPreviousArrayIndex = (array, currentIndex) => {
  return currentIndex === 0 ? array.length - 1 : currentIndex - 1
}

export const withPreview = WrappedComponent => {
  class Component extends React.Component {
    modalActionButtons

    constructor(props) {
      super(props)
      this.state = {
        isDialogVisible: false,
        images: [],
      }
      this.modalActionButtons = [
        {
          title: 'Cancel',
          onClick: this.onModalClose,
          align: 'right',
        },
      ]
    }

    onTogglePreview = (images, id) => {
      const currentIndex = id ? getImageIndexById(images, id) : 0
      this.setState({
        isDialogVisible: true,
        images,
        currentIndex,
      })
    }

    onNextButton = () => {
      this.setState(prevState => ({
        currentIndex: getNextArrayIndex(prevState.images, prevState.currentIndex),
      }))
    }

    onImageClick = id => {
      this.setState(prevState => ({ currentIndex: getImageIndexById(prevState.images, id) }))
    }

    onPrevButton = () => {
      this.setState(prevState => ({
        currentIndex: getPreviousArrayIndex(prevState.images, prevState.currentIndex),
      }))
    }

    onModalClose = () => {
      this.setState({ isDialogVisible: false })
    }

    renderPreviewImages = (images, currentIndex) =>
      images.map((image, index) => (
        <PreviewImage
          key={image.id}
          selected={index === currentIndex}
          src={image.url}
          onClick={() => this.onImageClick(image.id)}
        />
      ))

    render() {
      const { isDialogVisible, images, currentIndex } = this.state
      const { theme } = this.props
      const currentImage = images[currentIndex]

      return (
        <React.Fragment>
          <Modal
            isControlled
            show={isDialogVisible}
            actionButtons={this.modalActionButtons}
            onEscapeKeyDown={this.onModalClose}
          >
            <ExtendedFlex align="space-around" height="70vh">
              <Flex column justify="center">
                <Icon
                  type="chevron/left"
                  color={theme.palette.blue}
                  size="M"
                  cursor="pointer"
                  onClick={this.onPrevButton}
                />
              </Flex>
              <Flex column>
                <ExtendedFlex height="95%" align="space-around">
                  {currentImage && <CurrentImage key={currentImage.id} src={currentImage.url} />}
                </ExtendedFlex>
                <Flex align="space-around">
                  <Flex>{this.renderPreviewImages(images, currentIndex)}</Flex>
                </Flex>
              </Flex>
              <Flex column justify="center">
                <Icon
                  type="chevron/right"
                  color={theme.palette.blue}
                  size="M"
                  cursor="pointer"
                  onClick={this.onNextButton}
                />
              </Flex>
            </ExtendedFlex>
          </Modal>
          <WrappedComponent {...this.props} onTogglePreview={this.onTogglePreview} />
        </React.Fragment>
      )
    }
  }

  return withTheme(Component)
}
