import * as React from 'react'
import { compose, withState, withHandlers } from 'recompose'
import styled from '@emotion/styled'
import { Content } from '@r1/wireframe-primary'
import { withTheme, Collapse } from '@r1/ui-kit'
import { FooterPanel } from './FooterPanel'

import { ReviewModal } from './ReviewModal'
import { ProductCard } from './ProductCard'
import { CategoryMapping } from './tabs/CategoryMapping'
import { ConsigneeUPC } from './tabs/ConsigneeUPC'
import { ImagesForm } from './tabs/Images'
import { MainInfo } from './tabs/MainInfo'
import { OtherSettings } from './tabs/OtherSettings'
import { Pricing } from './tabs/Pricing'
import { TemplateAttributesForm } from './tabs/TemplateAttributesForm'
import { WalmartAttributes } from './tabs/WalmartAttributes'

const TemplateContent = styled('div')`
  position: relative;
`

const updateStickyElement = new Event('stickyForceUpdate')

const enhance = compose(
  withTheme,
  withState('activeTab', 'setActiveTab', 0),
  withState('dialog', 'toggleDialogVisibility', false),
  withState('dirtyTabs', 'setDirtyTabs', []),
  withHandlers({
    onChangeTab:
      ({ setActiveTab }) =>
      value => {
        setActiveTab(value)
        setTimeout(() => window.dispatchEvent(updateStickyElement), 10)
      },
    onReviewButtonClick:
      ({ dialog, toggleDialogVisibility }) =>
      () =>
        toggleDialogVisibility(!dialog),
    onCloseReviewDialog:
      ({ toggleDialogVisibility }) =>
      () =>
        toggleDialogVisibility(false),
    onAddDirtyTab:
      ({ dirtyTabs, setDirtyTabs }) =>
      tabName => {
        const newTabs =
          dirtyTabs.indexOf(tabName) === -1 ? Array.prototype.concat(dirtyTabs, tabName) : dirtyTabs
        setDirtyTabs(newTabs)
      },
    onSubmitReview:
      ({ onSubmitReview, toggleDialogVisibility }) =>
      (reason, comment) => {
        onSubmitReview(reason, comment)
        toggleDialogVisibility(false)
      },
  }),
)

const ProductTemplateInner = ({
  id,
  dialog,
  onAddDirtyTab,
  onSubmitForm,
  onCancel, // dirtyTabs, TODO: fix dirtyTabs behavior
  onApprove,
  onReviewButtonClick,
  onCloseReviewDialog,
  onSubmitReview,
  reviewInfo,
  reviewReasons,
  lockStatus,
  templateWasMerged,
  templateAttributesFormRef,
  onProductDataRescrape,
}) => {
  const disabled = !!lockStatus || templateWasMerged

  const [activeSections, setActiveSections] = React.useState([
    'mainInfo',
    'images',
    'categoryMapping',
    'pricing',
    'attributes',
    'consigneeUpc',
    'otherSettings',
  ])

  return (
    <Content spaceBetween="M">
      <ReviewModal
        show={dialog}
        reviewReasons={reviewReasons}
        onSubmit={onSubmitReview}
        onCloseReviewDialog={onCloseReviewDialog}
      />
      <ProductCard id={id} />
      <TemplateContent data-test-id="content-template-content">
        <Collapse active={activeSections} onChange={setActiveSections}>
          <Collapse.Panel title="Main info" id="mainInfo">
            <MainInfo id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>

          <Collapse.Panel title="Images" id="images">
            <ImagesForm id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>

          <Collapse.Panel title="Category mapping" id="categoryMapping">
            <CategoryMapping id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>

          <WalmartAttributes id={id} disabled={disabled} />

          <Collapse.Panel title="Pricing" id="pricing">
            <Pricing id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>

          <Collapse.Panel title="Attributes" id="attributes">
            <TemplateAttributesForm
              ref={templateAttributesFormRef}
              id={id}
              disabled={disabled}
              onAddDirtyTab={onAddDirtyTab}
            />
          </Collapse.Panel>

          <Collapse.Panel title="Consignee upc" id="consigneeUpc">
            <ConsigneeUPC id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>

          <Collapse.Panel title="Other settings" id="otherSettings">
            <OtherSettings id={id} disabled={disabled} onAddDirtyTab={onAddDirtyTab} />
          </Collapse.Panel>
        </Collapse>
      </TemplateContent>
      <FooterPanel
        id={id}
        lockStatus={lockStatus}
        reviewInfo={reviewInfo}
        reviewReasons={reviewReasons}
        templateWasMerged={templateWasMerged}
        onProductDataRescrape={onProductDataRescrape}
        onSubmit={onSubmitForm}
        onCancel={onCancel}
        onReview={onReviewButtonClick}
        onApprove={onApprove}
      />
    </Content>
  )
}

export const ProductTemplate = enhance(ProductTemplateInner)
