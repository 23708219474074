import {
  createDocumentTemplatesApi,
  createPageSettingsApi,
  createTemplateModelsApi,
  createImagesApi,
  baseUri,
} from '@r1-webui/documenttemplatemanager-v1'
import { NotificationSystem } from '@r1/ui-kit'

import { httpClient } from '../../../httpClient'

import { convertDocumentTemplateFromApi } from '../utils/convertFromApi'
import { convertDocumentTemplateToApi } from '../utils/convertToApi'
import { replaceTemplateValues } from '../../../utils/responseUtils.ts'

const processResponse = response => {
  if (response.status !== 200) {
    switch (response.status) {
      case 403:
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Permissions Denied',
        })
        break
      case 409:
      case 422:
        NotificationSystem.addNotification({
          level: 'error',
          title: replaceTemplateValues(response.body),
        })
        break
      case 500:
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Server error',
        })
        break
      default:
        break
    }
  }
  return response
}

const baseParam = {
  http: httpClient,
  urlPrefix: `/uisvc/document-generator/${baseUri}`,
}
export const createDocumentTemplateManagerService = () => {
  const api = {
    ...createDocumentTemplatesApi(baseParam),
    ...createPageSettingsApi(baseParam),
    ...createTemplateModelsApi(baseParam),
    ...createImagesApi(baseParam),
  }

  const getDocumentTemplates = () =>
    api.getDocumentTemplates().then(response => (response.status === 200 ? response.body : []))

  const getPageSettings = () =>
    api.getPageSettings().then(response => (response.status === 200 ? response.body : []))

  const getTemplateModels = () =>
    api.getTemplateModels().then(response => (response.status === 200 ? response.body : []))

  const getDocumentTemplate = async id => {
    const [documentTemplate, templateModels, pageSettings, images] = await Promise.all([
      api.getDocumentTemplate({ id }),
      api.getTemplateModels(),
      api.getPageSettings(),
      api.getImages(),
    ])
    if (documentTemplate.status === 200)
      return convertDocumentTemplateFromApi(
        documentTemplate.body,
        templateModels.body,
        pageSettings.body,
        images.body,
      )
    return null
  }

  const createDocumentTemplate = body => api.createDocumentTemplate(body)

  const updateDocumentTemplate = ({ id, name, templateModelId, pageSettingId, blocks }) =>
    api
      .updateDocumentTemplate(
        { id },
        convertDocumentTemplateToApi({ name, templateModelId, pageSettingId, blocks }),
      )
      .then(processResponse)

  return {
    getDocumentTemplates,
    getPageSettings,
    getDocumentTemplate,
    getTemplateModels,
    createDocumentTemplate,
    updateDocumentTemplate,
  }
}
