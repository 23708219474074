import * as React from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Box, Button, Flex, FormField, Icon, Input, Link, Text } from '@r1/ui-kit'
import { importFmsiFilterGroup } from '../../../api/importFmsiFilterGroup'
import { FmsiFilter, MasterProgramModel } from '../../../types'
import { FilterBorderContainer } from '../SubStamps/FilterBorderContainer'

type DataTestIdsProviders = {
  getInputDataTestId(): string
  getImportButtonDataTestId(): string
  getRemoveButtonDataTestId(filterIndex: number): string
}

type FmsiFiltersProps = {
  editable?: boolean
  fields: { filterId: string; id: string }[]
  onAdd: (filter: FmsiFilter) => void
  onRemove: (index: number) => void
  dataTestIdsProviders: DataTestIdsProviders
}

const FmsiFilters = ({
  editable,
  fields,
  onAdd,
  onRemove,
  dataTestIdsProviders,
}: FmsiFiltersProps) => {
  const [fmsiFilterId, setFmsiFilterId] = React.useState('')

  const onRemoveFilter = React.useCallback(
    (index: number) => () => {
      onRemove(index)
    },
    [onRemove],
  )

  const importFmsiFilter = React.useCallback(async () => {
    await importFmsiFilterGroup(fmsiFilterId)
    onAdd({ filterId: fmsiFilterId })
    setFmsiFilterId('')
  }, [onAdd, fmsiFilterId])

  return (
    <Flex column>
      {editable && (
        <FormField>
          <FormField.Label>FMSI Filter Id</FormField.Label>
          <Flex>
            <Input
              data-test-id={dataTestIdsProviders.getInputDataTestId()}
              value={fmsiFilterId}
              onChange={setFmsiFilterId}
            />
            <Box ml="S" minWidth={180} maxWidth={180}>
              <Button
                block
                data-test-id={dataTestIdsProviders.getImportButtonDataTestId()}
                disabled={!fmsiFilterId}
                onClick={importFmsiFilter}
              >
                Import FMSI Filter
              </Button>
            </Box>
          </Flex>
        </FormField>
      )}
      {fields.map((field, index) => {
        return (
          <Flex key={`master-program-filter-group-${field.id}`} basis={1} mt="L">
            <FilterBorderContainer centered pr="S" mr="M">
              <Text type="paragraph">{index + 1}</Text>
            </FilterBorderContainer>
            <Flex justify="space-between" basis={1} align="center">
              <Box grow>
                <Link
                  target="_blank"
                  href={`/Modules/Inventory/ProductSearch.aspx?filter=${field.filterId}&pagesize=5`}
                >
                  Open FMSI filter #{field.filterId}
                </Link>
              </Box>
              {editable && (
                <Box minWidth={180} maxWidth={180}>
                  <Button
                    block
                    data-test-id={dataTestIdsProviders.getRemoveButtonDataTestId(index)}
                    color="danger"
                    onClick={onRemoveFilter(index)}
                  >
                    <Icon type="minus" /> Remove Filter
                  </Button>
                </Box>
              )}
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

type RuleFiltersProps = {
  editable?: boolean
}

export const RuleFmsiFilters = ({ editable }: RuleFiltersProps) => {
  const { control } = useFormContext<MasterProgramModel>()

  const { fields, append, remove } = useFieldArray({
    name: 'filterGroups',
    control,
  })

  const dataTestIdsProviders: DataTestIdsProviders = {
    getInputDataTestId: () => `master-program-filter__input`,
    getImportButtonDataTestId: () => `master-program-filter__add`,
    getRemoveButtonDataTestId: filterIndex => `master-program-filter-${filterIndex}__remove`,
  }
  return (
    <FmsiFilters
      editable={editable}
      fields={fields}
      dataTestIdsProviders={dataTestIdsProviders}
      onAdd={append}
      onRemove={remove}
    />
  )
}

type SubStampFmsiFiltersProps = {
  editable?: boolean
  subStampIndex: number
  subStampValueIndex: number
}

export const SubStampFmsiFilters = ({
  editable,
  subStampIndex,
  subStampValueIndex,
}: SubStampFmsiFiltersProps) => {
  const { control } = useFormContext<MasterProgramModel>()

  const { fields, append, remove } = useFieldArray({
    name: `subStamps.${subStampIndex}.values.${subStampValueIndex}.filterGroups`,
    control,
  })

  const dataTestIdsProviders: DataTestIdsProviders = {
    getInputDataTestId: () =>
      `sub-stamp-${subStampIndex}-value-${subStampValueIndex}-filter__input`,
    getImportButtonDataTestId: () =>
      `sub-stamp-${subStampIndex}-value-${subStampValueIndex}-filter__add`,
    getRemoveButtonDataTestId: filterIndex =>
      `sub-stamp-${subStampIndex}-value-${subStampValueIndex}-filter-${filterIndex}__remove`,
  }

  return (
    <FmsiFilters
      editable={editable}
      fields={fields}
      dataTestIdsProviders={dataTestIdsProviders}
      onAdd={append}
      onRemove={remove}
    />
  )
}
