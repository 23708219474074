import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { pathOr } from 'ramda'
import { Text, Flex } from '@r1/ui-kit'
import { Label } from '../components'
import { selectors } from '../../../modules/products'

const mapStateToProps = (state, { productId }) => ({
  productCard: selectors.getItem(state, { id: productId }),
})

const enhance = compose(connect(mapStateToProps))

const ImportedProductDetailsComponent = ({ productCard }) => {
  const model = pathOr('None', ['manifestData', 'model'], productCard)
  const templateIdentifierValue = pathOr(
    'None',
    ['manifestData', 'templateIdentifierValue'],
    productCard,
  )
  const description = pathOr('None', ['manifestData', 'description'], productCard)
  const slp = pathOr('None', ['manifestData', 'slp'], productCard)
  const amount = pathOr('None', ['manifestData', 'retailPrice', 'amount'], productCard)
  const currency = pathOr('', ['manifestData', 'retailPrice', 'currency'], productCard)
  const bl = pathOr('None', ['manifestData', 'bl'], productCard)
  const uploadedOn = pathOr('None', ['manifestData', 'uploadedOn'], productCard)
  const fileName = pathOr('None', ['manifestData', 'fileName'], productCard)

  return (
    <Flex column maxWidth={1011} spaceBetween="S">
      <Flex align="center">
        <Label>Model</Label>
        <Text>{model}</Text>
      </Flex>
      <Flex align="center">
        <Label>Template identifier</Label>
        <Text>{templateIdentifierValue}</Text>
      </Flex>
      <Flex align="center">
        <Label>Description</Label>
        <Text>{description}</Text>
      </Flex>
      <Flex align="center">
        <Label>SLP</Label>
        <Text>{slp}</Text>
      </Flex>
      <Flex align="center">
        <Label>Retail price</Label>
        <Text>{`${amount} ${currency}`}</Text>
      </Flex>
      <Flex align="center">
        <Label>BL</Label>
        <Text>{bl}</Text>
      </Flex>
      <Flex align="center">
        <Label>Date manifest imported</Label>
        <Text>{uploadedOn}</Text>
      </Flex>
      <Flex align="center">
        <Label>Manifest file name</Label>
        <Text>{fileName}</Text>
      </Flex>
    </Flex>
  )
}

export const ImportedProductDetails = enhance(ImportedProductDetailsComponent)
