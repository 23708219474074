// @flow

import * as React from 'react'
import { debounce } from 'lodash'
import styled from '@emotion/styled'
import { Table } from '@r1/grid'
import { Box, Flex, Input, Icon, Button } from '@r1/ui-kit'
import { handleServerError, HistoryModal } from '@r1/core-blocks'
import type {
  CurrencyCode,
  AggregationMethod,
  RuleInfo,
  RuleOption,
} from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/types'
import type { PricingRuleInfo } from '@r1-webui/priceconsolidator-rulemanagement-v1/src/types'

import { replaceTemplateValues } from '../../../../utils'

import type { Lib$Id } from '../../types'
import type { CompanyPricingRulesScreenProps } from '../../types/public/CompanyPricingRulesScreen'

import {
  createCompanyPriceConsolidationRulesApi,
  createCompanyPriceConsolidationRuleManagement,
  createPriceConsolidatorRuleManagementApi,
} from './api'
import { PricingRulesDrawer } from './PricingRulesDrawer'
import { getTableSettings } from './tableSettings'

export type HistoryModalData = { id: string, name: string }

export type Rule = {|
  id: Lib$Id | null,
  name: string,
  currencyCode: CurrencyCode,
  aggregationMethodId: Lib$Id,
  options: Array<RuleOption>,
|}

export type State = {
  rules: {| data: Array<RuleInfo>, loading: boolean |},
  currencies: { data: Array<{| id: CurrencyCode, name: CurrencyCode |}>, loading: boolean },
  aggregationMethods: { data: Array<AggregationMethod>, loading: boolean },
  info: { data: Array<PricingRuleInfo>, loading: boolean },
  filteredRules: Array<RuleInfo>,
  currentRule: {| data: Rule | null, version: Lib$Id, loading: boolean |},
  searchValue: string,
  showDrawer: boolean,
  historyModal: HistoryModalData | null,
}

const ControlsWrapper = styled('div')`
  display: flex;
  margin-left: auto;
`

const PricingRulesCounter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.space.XXS};
`

export class CompanyPricingRulesScreen extends React.Component<
  CompanyPricingRulesScreenProps,
  State,
> {
  state = {
    rules: {
      data: [],
      loading: true,
    },
    info: {
      data: [],
      loading: true,
    },
    currencies: {
      data: [],
      loading: true,
    },
    aggregationMethods: {
      data: [],
      loading: true,
    },
    currentRule: {
      data: null,
      version: '',
      loading: false,
    },
    filteredRules: [],
    searchValue: '',
    showDrawer: false,
    historyModal: null,
  }

  api = {
    ...createCompanyPriceConsolidationRulesApi(this.props.httpClient),
    ...createCompanyPriceConsolidationRuleManagement(this.props.httpClient),
    ...createPriceConsolidatorRuleManagementApi(this.props.httpClient),
  }

  debouncedSearch = debounce(
    (searchValue: string, rules: { data: Array<RuleInfo>, loading: boolean }) => {
      let filteredRules = rules.data
      if (searchValue.length > 0) {
        filteredRules = rules.data.filter(({ name, currencyCode }) => {
          const search = new RegExp(searchValue, 'gi')
          const matchedName = !!name.match(search)
          const matchedCurrency =
            searchValue.length === 3 && !matchedName && !!currencyCode.match(search)

          return matchedName || matchedCurrency
        })
      }
      this.setState({ filteredRules })
    },
    500,
  )

  componentDidMount() {
    this.fetchRulesInfo()
  }

  componentWillUnmount() {
    this.debouncedSearch.cancel()
  }

  fetchHistoryToken = async (id: string): Promise<string> => {
    const response = await this.api.generateRuleHistoryReceivingToken({ ruleId: id })

    if (response.status !== 200) {
      handleServerError(response)
      return ''
    }
    return response.body.historyReceivingToken
  }

  fetchRulesInfo = () => {
    const { getAllRules, getPricingRuleInfos, getAvailableCurrencies, getAggregationMethods } =
      this.api

    return Promise.all([
      getAllRules(),
      getPricingRuleInfos(),
      getAvailableCurrencies(),
      getAggregationMethods(),
    ]).then(
      ([rulesResponse, rulesInfoResponse, currenciesResponse, aggregationMethodsResponse]) => {
        let resultingState: State = {}
        const errors = []

        if (rulesResponse.status === 200) {
          resultingState = {
            ...resultingState,
            rules: { data: rulesResponse.body, loading: false },
            filteredRules: rulesResponse.body,
          }
        } else {
          errors.push(rulesResponse)
        }

        if (rulesInfoResponse.status === 200) {
          resultingState = {
            ...resultingState,
            info: { data: rulesInfoResponse.body, loading: false },
          }
        } else {
          errors.push(rulesInfoResponse)
        }

        if (currenciesResponse.status === 200) {
          resultingState = {
            ...resultingState,
            currencies: {
              data: currenciesResponse.body.map(currency => ({ id: currency, name: currency })),
              loading: false,
            },
          }
        } else {
          errors.push(currenciesResponse)
        }

        if (aggregationMethodsResponse.status === 200) {
          const aggregationMethods = aggregationMethodsResponse.body.map(({ id, name }) => ({
            id,
            name: replaceTemplateValues(name),
          }))
          resultingState = {
            ...resultingState,
            aggregationMethods: {
              data: aggregationMethods,
              loading: false,
            },
          }
        } else {
          errors.push(aggregationMethodsResponse)
        }

        this.setState(prevState => ({ ...prevState, ...resultingState }))
        errors.forEach(error => handleServerError(error))
      },
    )
  }

  updateRule = async ({ id, ...rule }: Rule, ruleId: Lib$Id) => {
    const { currentRule } = this.state

    const updateResponse = await this.api.updateRule(
      { ruleId },
      {
        version: currentRule.version,
      },
      rule,
    )

    this.setState(prevState => {
      const result = {
        currentRule: {
          ...prevState.currentRule,
          loading: false,
        },
        rules: {
          ...prevState.rules,
          loading: false,
        },
        filteredRules: prevState.filteredRules,
      }

      if (updateResponse.status === 200) {
        const { latestVersion: version } = updateResponse.body

        const updatedRules = prevState.rules.data.map(item => {
          if (item.id === ruleId)
            return {
              ...item,
              version,
              name: rule.name,
              currencyCode: rule.currencyCode,
            }

          return item
        })

        result.currentRule.data = { ...rule, id: ruleId }
        result.currentRule.version = version

        result.rules.data = updatedRules
        result.filteredRules = updatedRules
      }

      return result
    })

    return updateResponse
  }

  createRule = async ({ id, ...rule }: Rule) => {
    const createResponse = await this.api.createRule(rule)

    this.setState(prevState => {
      const result = {
        rules: { ...prevState.rules, loading: false },
        filteredRules: prevState.filteredRules,
      }

      if (createResponse.status === 200) {
        const { ruleId, version } = createResponse.body

        const updatedRules = [
          ...prevState.rules.data,
          {
            id: ruleId,
            version,
            name: rule.name,
            currencyCode: rule.currencyCode,
          },
        ]

        result.rules.data = updatedRules
        result.filteredRules = updatedRules
      }

      return result
    })

    return createResponse
  }

  onSearch = (searchValue: string) => {
    const { rules } = this.state

    this.setState({ searchValue })
    this.debouncedSearch(searchValue, rules)
  }

  onRuleClick = (ruleId: Lib$Id) => {
    const { getRule } = this.api
    const { currentRule } = this.state

    if (!currentRule.loading) {
      this.setState(prevState => ({
        currentRule: { ...prevState.currentRule, loading: true },
      }))

      getRule({ ruleId }).then(pricingRuleResponse => {
        this.setState(prevState => {
          const result = {}
          if (pricingRuleResponse.status === 200) {
            const {
              rule: {
                aggregationMethod: { id: aggregationMethodId },
                ...rest
              },
              version,
            } = pricingRuleResponse.body

            result.currentRule = { data: { ...rest, aggregationMethodId }, version, loading: false }
            result.showDrawer = true
          } else {
            handleServerError(pricingRuleResponse)
            result.currentRule = { ...prevState.currentRule, loading: false }
          }
          return result
        })
      })
    }
  }

  onDrawerClose = () =>
    this.setState({ showDrawer: false, currentRule: { data: null, version: '', loading: false } })

  onHistoryModalOpen = ({ id, name }: HistoryModalData) =>
    this.setState({ historyModal: { id, name } })

  onHistoryModalClose = () => this.setState({ historyModal: null })

  onSaveDrawerChanges = (rule: Rule) => {
    const { currentRule } = this.state
    const ruleId = currentRule.data && currentRule.data.id

    this.setState(prevState => {
      if (ruleId) {
        return {
          rules: { ...prevState.rules, loading: true },
          currentRule: { ...prevState.currentRule, loading: true },
        }
      }
      return {
        rules: { ...prevState.rules, loading: true },
      }
    })

    if (ruleId) {
      return this.updateRule(rule, ruleId)
    }
    return this.createRule(rule)
  }

  render() {
    const {
      rules,
      info,
      currencies,
      aggregationMethods,
      searchValue,
      currentRule,
      filteredRules,
      showDrawer,
      historyModal,
    } = this.state
    const { allowCompanyPriceCreate = true } = this.props
    const rulesCount = rules.data.length

    const displaySettings = getTableSettings(
      this.onRuleClick,
      this.onHistoryModalOpen,
      this.api.deleteRule,
      this.fetchRulesInfo,
    )

    return (
      <React.Fragment>
        <PricingRulesDrawer
          show={showDrawer}
          rule={currentRule}
          info={info.data}
          currencies={currencies.data}
          aggregationMethods={aggregationMethods.data}
          onClose={this.onDrawerClose}
          onSaveChanges={this.onSaveDrawerChanges}
        />
        <Flex column spaceBetween="M" minWidth={1}>
          <Box>
            <Box grow>
              <Flex spaceBetween="XS" align="center">
                <Flex align="center">All Prices</Flex>
                <PricingRulesCounter>{rulesCount}</PricingRulesCounter>
              </Flex>
              <ControlsWrapper>
                <Box minWidth={360} maxWidth={360} pr="S">
                  <Input
                    placeholder="Search by Price Name"
                    after={<Icon type="search" />}
                    value={searchValue}
                    onChange={this.onSearch}
                  />
                </Box>
                <Box>
                  {allowCompanyPriceCreate && (
                    <Button
                      onClick={() =>
                        this.setState({
                          showDrawer: true,
                          currentRule: { data: null, version: '', loading: false },
                        })
                      }
                    >
                      + Add New Price
                    </Button>
                  )}
                </Box>
              </ControlsWrapper>
            </Box>
          </Box>
          <Table
            displaySettings={displaySettings}
            loading={rules.loading}
            data={filteredRules}
            height={400}
          />
          {historyModal && (
            <HistoryModal
              isControlled
              show
              title={`History ${historyModal.name}`}
              httpClient={this.props.httpClient}
              getHistoryReceivingToken={() => this.fetchHistoryToken(historyModal.id)}
              onClose={this.onHistoryModalClose}
            />
          )}
        </Flex>
      </React.Fragment>
    )
  }
}
