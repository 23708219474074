// @flow

import * as React from 'react'
import { handleServerError } from '@r1/core-blocks'
import { Drawer, Flex, Box, Input, Select, FormField } from '@r1/ui-kit'
import type {
  UpdateRuleResponse,
  CreateRuleResponse,
} from '@r1-webui/productprices-companypriceconsolidationrulemanagement-v1/src/ruleManagement'
import type {
  CurrencyCode,
  AggregationMethod,
  AggregationMethodId,
} from '@r1-webui/productprices-companypriceconsolidationrules-v1/src/types'
import type { PricingRuleInfo } from '@r1-webui/priceconsolidator-rulemanagement-v1/src/types'

import { parseBadPropertiesNotice } from '../utils/parseBadPropertiesNotice'
import { RulesForm } from './View/RulesForm'
import { drawerWidth, drawerContentWidth } from './constants/common'
import type { State as CompanyPricingRulesScreenState, Rule } from './CompanyPricingRulesScreen'

type Props = {|
  currencies: Array<{| id: CurrencyCode, name: CurrencyCode |}>,
  aggregationMethods: Array<AggregationMethod>,
  show: $ElementType<CompanyPricingRulesScreenState, 'showDrawer'>,
  rule: $ElementType<CompanyPricingRulesScreenState, 'currentRule'>,
  info: Array<PricingRuleInfo>,
  onClose: () => void,
  onSaveChanges: Rule => Promise<UpdateRuleResponse | CreateRuleResponse>,
|}

type State = {
  ruleName: string,
  priceRuleId: string,
  currencyCode: CurrencyCode,
  aggregationMethodId: AggregationMethodId,
  errors: Object,
}

const initialState: State = {
  ruleName: '',
  priceRuleId: '0',
  currencyCode: '',
  aggregationMethodId: '',
  errors: {},
}

export class PricingRulesDrawer extends React.Component<Props, State> {
  state = { ...initialState }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const { show, rule } = nextProps
    const ruleId = rule.data?.id ?? null

    if (!show) {
      return initialState
    }

    if (ruleId && ruleId !== prevState.priceRuleId) {
      const ruleName = rule.data?.name ?? ''
      const currencyCode = rule.data?.currencyCode ?? ''
      const aggregationMethodId = rule.data?.aggregationMethodId ?? ''

      return {
        currencyCode,
        aggregationMethodId,
        priceRuleId: ruleId,
        ruleName,
      }
    }

    return null
  }

  onNameChange = (ruleName: string) => this.setState({ ruleName })

  onCurrencyChange = (currencyCode: Object) => this.setState({ currencyCode })

  onAggregationMethodChange = (aggregationMethodId: string) =>
    this.setState({
      aggregationMethodId,
    })

  onSubmit = ({ options }: { options: Object }) => {
    const { onSaveChanges, onClose, rule } = this.props
    const { ruleName, currencyCode, aggregationMethodId } = this.state

    onSaveChanges({
      id: rule.data?.id ?? '',
      name: ruleName,
      options,
      currencyCode,
      aggregationMethodId,
    }).then(saveRuleResponse => {
      if (saveRuleResponse.status !== 200) {
        if (saveRuleResponse.body?.badProperties) {
          // $FlowFixMe[incompatible-call]
          const parsedErrors = parseBadPropertiesNotice(saveRuleResponse.body.badProperties)
          const errors = Object.assign({}, ...parsedErrors)
          this.setState({ errors })
        } else {
          handleServerError(saveRuleResponse)
        }
      } else {
        onClose()
      }
    })
  }

  render() {
    const { show, rule, currencies, aggregationMethods, info, onClose } = this.props
    const { currencyCode, ruleName, aggregationMethodId, errors } = this.state

    const options = rule.data?.options ?? []

    const initialValues = show ? { options } : {}
    const initialRule = { globalRuleId: '', percentage: '0', priority: 1 }
    const formProps = {
      rule,
      info,
      errors,
      initialRule,
      onClose,
    }

    return (
      <Drawer.Form
        closeButton
        show={show}
        title="Price Rule"
        placement="right"
        size={drawerWidth}
        backdrop="closing"
        onClose={onClose}
      >
        <Flex column minWidth={drawerContentWidth} maxWidth={drawerContentWidth}>
          <FormField>
            <FormField.Label>Price name</FormField.Label>
            <Input value={ruleName} onChange={this.onNameChange} />
            <FormField.Error>{errors.attributeName}</FormField.Error>
          </FormField>

          <FormField>
            <FormField.Label>Currency</FormField.Label>
            <Select
              clearable={false}
              filterable={false}
              value={currencyCode}
              options={currencies}
              onChange={this.onCurrencyChange}
            />
            <FormField.Error>{errors.currencyCode}</FormField.Error>
          </FormField>

          <FormField>
            <FormField.Label>Aggregation method</FormField.Label>
            <Select
              clearable={false}
              filterable={false}
              value={aggregationMethodId}
              options={aggregationMethods}
              onChange={this.onAggregationMethodChange}
            />
            <FormField.Error>{errors.aggregationMethodId}</FormField.Error>
          </FormField>

          <Box grow>
            <RulesForm {...formProps} initialValues={initialValues} onSubmit={this.onSubmit} />
          </Box>
        </Flex>
      </Drawer.Form>
    )
  }
}
