import styled from '@emotion/styled'
import { SubStampValue } from '@r1-webui/masterprograms-v1'
import { BottomButtonsBar, Box, Button, Drawer, Flex, Icon, Placeholder, Text } from '@r1/ui-kit'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import React, { useState, useCallback, useEffect, useMemo } from 'react'
import {
  createSubStamp,
  deleteSubStamp,
  getActiveSubStamps,
  updateSubStamp,
} from '../../../../services/subStampService'
import { CreateSubStampModal, EditSubStampModal } from './SubStampModal'
import { SubStampValues } from './SubStampValues'
import { SubStampModel } from './types'

const FilterBorderContainer = styled(Box)`
  border-right: 6px solid #c7ccd1;
`

export const SubStampsDrawer = () => {
  const [show, setShow] = useState(false)
  const [subStamps, setSubStamps] = useState<SubStampModel[]>([])
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const fetchSubStamps = useCallback(async () => {
    setIsFetching(true)
    const response = await getActiveSubStamps()
    setSubStamps(response)
    setIsFetching(false)
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchSubStamps()
  }, [fetchSubStamps])

  const onClose = () => {
    setShow(false)
  }

  const modalActionButtons: ControlledActionButtons = useMemo(
    () => [
      {
        title: 'Close',
        color: 'secondary',
        onClick: onClose,
        align: 'right',
      },
    ],
    [],
  )

  const requestUpdateSubStamp = async (id: string, subStampName: string) => {
    const success = await updateSubStamp(id, subStampName)

    if (success) {
      setSubStamps(
        subStamps.map(subStamp => {
          if (subStamp.id === id) {
            return { id, name: subStampName, values: subStamp.values }
          }

          return subStamp
        }),
      )
    }

    return success
  }

  const requestCreateSubStamp = async (subStampName: string) => {
    const newId = await createSubStamp(subStampName)

    if (newId) {
      setSubStamps(subStamps.concat([{ id: newId, name: subStampName, values: [] }]))
      return true
    }
    return false
  }

  const onSave = async (id: string | null, subStampName: string) => {
    let success = false
    if (id) {
      success = await requestUpdateSubStamp(id, subStampName)
    } else {
      success = await requestCreateSubStamp(subStampName)
    }

    return success
  }

  const onAdd = async (subStampName: string) => {
    const success = await onSave(null, subStampName)
    return success
  }

  const onChange = (id: string) => async (name: string) => {
    const success = await onSave(id, name)
    return success
  }

  const onRemove = (subStampId: string) => async () => {
    const isSucceed = await deleteSubStamp(subStampId)

    if (isSucceed) {
      setSubStamps(subStamps.filter(x => x.id !== subStampId))
    }
  }

  const onChangeValues = (subStampId: string, values: SubStampValue[]) => {
    setSubStamps(
      subStamps.map(subStamp => {
        if (subStamp.id === subStampId) {
          return { ...subStamp, values }
        }

        return subStamp
      }),
    )
  }

  return (
    <React.Fragment>
      <Button onClick={() => setShow(!show)}>Substamps</Button>
      <Drawer.Form
        closeButton
        show={show}
        backdrop="closing"
        size={600}
        placement="right"
        title="Substamps"
        loading={isFetching}
        footer={<BottomButtonsBar bottomActionButtons={modalActionButtons} />}
        onClose={onClose}
      >
        {isFetching ? (
          <Placeholder type="form" height={10} />
        ) : (
          <Flex column minWidth={1}>
            {subStamps.map((x, index) => (
              <Flex key={x.id} mt="L">
                <FilterBorderContainer pr="S" mr="M">
                  <Text type="paragraph">{index + 1}</Text>
                </FilterBorderContainer>
                <Flex column basis={1}>
                  <Flex column minWidth={1}>
                    <Flex justify="space-between" minWidth={1}>
                      <Box alignSelf="center">
                        <Text>{x.name}</Text>
                      </Box>
                      <Flex>
                        <EditSubStampModal subStampName={x.name} onUpdate={onChange(x.id)} />
                        <Button color="secondary" shape="square" onClick={onRemove(x.id)}>
                          <Icon type="trash" />
                        </Button>
                      </Flex>
                    </Flex>
                    <SubStampValues subStamp={x} onChange={onChangeValues} />
                  </Flex>
                </Flex>
              </Flex>
            ))}
            <CreateSubStampModal onAdd={onAdd} />
          </Flex>
        )}
      </Drawer.Form>
    </React.Fragment>
  )
}
