import debounce from 'debounce'
import React, { useCallback, useEffect, useState } from 'react'
import { Select } from '@r1/ui-kit'
import { Family } from '@r1-webui/families-v1'
import { handleServerError } from '@r1/core-blocks'
import { familiesApi } from '../../api/families'

type Props = {
  value: string | null
  disabled: boolean
  onChange: (value: string) => void
}

export const FamilySelect = React.memo<Props>(({ value, disabled, onChange }: Props) => {
  const [families, setFamilies] = useState<Family[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [initialFamilyFetched, setInitialFamilyFetched] = useState<boolean>(false)

  useEffect(() => {
    if (!value && !searchTerm && families.length) {
      setFamilies([])
      return
    }

    if (value && !families.length && !initialFamilyFetched) {
      const fetchInitialFamily = async () => {
        const response = await familiesApi.getFamily({ id: value })
        if (response.status === 200) {
          setFamilies([response.body])
          setInitialFamilyFetched(true)
        } else {
          handleServerError(response)
        }
      }
      fetchInitialFamily()
    }
  }, [value, families, searchTerm, initialFamilyFetched])

  const onInputChange = useCallback(async (term: string) => {
    setSearchTerm(term)
    if (term) {
      setIsLoading(true)
      const response = await familiesApi.searchFamilies({ searchTerm: term, limit: 20 })
      if (response.status === 200) {
        setFamilies(response.body)
      } else {
        handleServerError(response)
      }
      setIsLoading(false)
    }
  }, [])

  const onInputChangeDebounced = debounce(onInputChange, 300)

  return (
    <Select
      data-test-id="product-catalog__product-edit__family-select"
      clearable={false}
      loading={isLoading}
      disabled={disabled}
      zIndex={10}
      placeholder="Choose family"
      noResultsText="Type to search"
      value={value}
      options={families}
      onInputChange={onInputChangeDebounced}
      onChange={onChange}
    />
  )
})
