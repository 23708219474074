import * as React from 'react'
import {
  H4,
  Flex,
  Box,
  FormField,
  Input,
  Dropdown,
  Checkbox,
  StatusIndicator,
  Text,
  HR,
} from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

import styled from '@emotion/styled'
import type { UserAuthProps } from '../../types/UserAuth'

import { TextReadOnly, LabelReadOnly } from '../common'

const EditableView = (props: UserAuthProps) => {
  const [{ allowChangePassword }] = useAccessControl()

  const { disabled, login, password, confirmPassword, changePasswordOnNextLogin } = props

  const loginDisabled = props.interactionType !== 'Create'

  const passwordDisabled = React.useMemo(() => {
    if (props.interactionType === 'Create') {
      return false
    } else if (allowChangePassword) {
      return disabled
    }
    return true
  }, [props.interactionType, disabled, allowChangePassword])

  const Item = styled('div')`
    display: flex;
    background-color: ${({ theme }) => theme.palette.white};
    box-sizing: border-box;
    height: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    &:first-child {
      font-weight: 500;
      height: 36px;
    }
  `

  const TextContainer = styled('div')`
    box-sizing: border-box;
    color: ${({ theme }) => theme.palette.black};
    cursor: default;
  `

  const ItemsContainer = styled('div')`
    margin-top: -10px;
    position: relative;
    padding: 24px;
    margin-left: 35px;
    width: 228px;
    height: 148px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.white};
    box-shadow: 0px 2px 8px rgba(19, 41, 63, 0.2);
    border-radius: 2px;

    &::after {
      content: '';
      position: absolute;

      width: 16px;
      height: 16px;
      left: -8px;
      top: 20px;

      background-color: ${({ theme }) => theme.palette.white};
      box-shadow: -3px -3px 3px -1px rgba(19, 41, 63, 0.1);

      transform: rotate(-45deg);
    }
  `

  const items = [
    { id: 0, name: 'Password must have:' },
    { id: 1, name: '8 or more characters' },
    { id: 2, name: 'Upper and lowercase letters' },
    { id: 3, name: 'At least 1 number' },
  ]

  const renderOneLevelItems = () =>
    items.map(({ id, name }) => (
      <Item key={id}>
        <TextContainer>{name}</TextContainer>
      </Item>
    ))

  return (
    <Flex column minWidth={408} maxWidth={408}>
      <H4>Authentication</H4>
      <Box>
        <FormField error={!!login.error} disabled={loginDisabled}>
          <FormField.Label>Login *</FormField.Label>
          <Input
            data-test-id="um-user-login-input"
            value={login.value || ''}
            onChange={login.onChange}
          />
          <FormField.Error>{login.error}</FormField.Error>
        </FormField>
      </Box>

      <Box>
        <FormField disabled={passwordDisabled} error={!!password.error}>
          <FormField.Label>Password *</FormField.Label>
          <Dropdown
            data-test-id="password"
            zIndex={10}
            placement="right-start"
            contentRenderer={() => <ItemsContainer>{renderOneLevelItems()}</ItemsContainer>}
          >
            {({ onMouseEnter, onMouseLeave, innerRef }) => (
              <Input
                ref={innerRef as unknown as React.Ref<HTMLInputElement>}
                data-test-id="um-user-password-input"
                type="password"
                autoComplete="new-password"
                value={password.value || ''}
                onChange={password.onChange}
                onFocus={onMouseEnter}
                onBlur={onMouseLeave}
              />
            )}
          </Dropdown>
          <FormField.Error>{password.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <FormField disabled={passwordDisabled} error={!!confirmPassword.error}>
          <FormField.Label>Confirm password *</FormField.Label>
          <Dropdown
            data-test-id="confirm-password"
            zIndex={10}
            placement="right-start"
            contentRenderer={() => <ItemsContainer>{renderOneLevelItems()}</ItemsContainer>}
          >
            {({ onMouseEnter, onMouseLeave, innerRef }) => (
              <Input
                ref={innerRef as unknown as React.Ref<HTMLInputElement>}
                data-test-id="um-user-confirm-password-input"
                type="password"
                autoComplete="new-password"
                value={confirmPassword.value || ''}
                onChange={confirmPassword.onChange}
                onFocus={onMouseEnter}
                onBlur={onMouseLeave}
              />
            )}
          </Dropdown>
          <FormField.Error>{confirmPassword.error}</FormField.Error>
        </FormField>
      </Box>
      <Box>
        <Checkbox
          label="Change password at next login"
          checked={changePasswordOnNextLogin.value}
          onChange={changePasswordOnNextLogin.onChange}
        />
      </Box>
    </Flex>
  )
}

const ReadOnlyView = (props: UserAuthProps) => {
  const { login, changePasswordOnNextLogin } = props

  return (
    <Flex column minWidth={408} maxWidth={408}>
      <LabelReadOnly>Authentication</LabelReadOnly>
      <Flex column spaceBetween="XXS">
        <HR />
        <Flex justify="space-between" align="center">
          <FormField.Label>Login</FormField.Label>
          <TextReadOnly data-test-id="login">{login.value}</TextReadOnly>
        </Flex>
      </Flex>
      <Flex column spaceBetween="XXS" mb="L">
        <HR />
        <Flex justify="space-between" align="center">
          <FormField.Label>Password</FormField.Label>
          <TextReadOnly data-test-id="password">********</TextReadOnly>
        </Flex>
      </Flex>
      {changePasswordOnNextLogin.value ? (
        <Box mt="S">
          <StatusIndicator type="success">
            <Text>User should change password at next login</Text>
          </StatusIndicator>
        </Box>
      ) : (
        ''
      )}
    </Flex>
  )
}

export const UserAuth = (props: UserAuthProps) => {
  const View = props.interactionType === 'View' ? ReadOnlyView : EditableView

  return <View {...props} />
}
