import { propOr, sortBy, prop, compose, toLower } from 'ramda'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { loadDataSource } from '../../../../../modules/productCatalog/dataSources'
import { actions as mappingActions } from '../../../../../modules/productTemplates/categoryMapping'
import { proceedError, showErrors } from '../../../utils'
import { productTemplateCategoryMappingsApi } from '../../../../../api/productTemplate/index.ts'
import { WALMART_VALUE_NAME } from './constants'

const initialState = {
  data: null,
  isFetched: true,
  walmartCategoryId: null,
  walmartDataSourceId: null,
}

const types = createTypes(['setFetching', 'loadData', 'setWalmartCategory'], 'categoryMapping')

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.loadData]: (state, { namedRows }) => ({ ...state, data: namedRows, isFetched: true }),
  [types.setWalmartCategory]: (state, { walmartCategoryId, walmartDataSourceId }) => ({
    ...state,
    walmartCategoryId,
    walmartDataSourceId,
  }),
})

const sortByName = sortBy(compose(toLower(), prop('name')))

const toMap = (list, idKey) =>
  Array.isArray(list) ? list.reduce((map, item) => ({ ...map, [item[idKey]]: item }), {}) : []

function getNamedRows({ categoryMapping, dataSources }) {
  const emptyRow = {
    $type: 'ProductCategoryMapping',
    enableAutoUpdate: true,
  }
  const categoryMappingMap = toMap(categoryMapping, 'dataSourceId')

  return dataSources.map(dataSource => ({
    ...(categoryMappingMap[dataSource.id] || emptyRow),
    name: propOr('', 'title', dataSource),
    dataSourceId: dataSource.id,
  }))
}

export function loadData(productTemplateId) {
  return async (dispatch, getState) => {
    if (getState().categoryMapping.data) return
    dispatch({ type: types.setFetching })
    await dispatch(loadDataSource())

    const dataSources = getState().productCatalogDataSources.data
    const data = await dispatch(mappingActions.fetchItem({ id: productTemplateId }))
    const namedRows = sortByName(
      getNamedRows({
        categoryMapping: data,
        dataSources,
      }),
    )

    dispatch({
      type: types.setWalmartCategory,
      walmartCategoryId:
        namedRows.find(({ name }) => name === WALMART_VALUE_NAME)?.categoryId ?? null,
      walmartDataSourceId:
        namedRows.find(({ name }) => name === WALMART_VALUE_NAME)?.dataSourceId ?? null,
    })

    dispatch({
      type: types.loadData,
      namedRows,
    })
  }
}

export function handleSubmit({ productTemplateId, rows }) {
  return async (_dispatch, _getState, _api) => {
    try {
      await productTemplateCategoryMappingsApi.saveCategoryMapping({ id: productTemplateId }, rows)
    } catch (error) {
      showErrors(proceedError(error))
    }
  }
}

export function changeWalmartCategoryId(walmartCategoryId, walmartDataSourceId) {
  return async dispatch => {
    dispatch({ type: types.setWalmartCategory, walmartCategoryId, walmartDataSourceId })
  }
}
