import * as React from 'react'
import { Flex, H4 } from '@r1/ui-kit'
import type { RolesSummaryProps } from '../../types/UserCreationForm'

export const RolesSummary = (props: RolesSummaryProps) => (
  <Flex column spaceBetween="M">
    <H4>Roles</H4>
    <ol>
      {props.roles.map(({ id, name }) => (
        <li key={id}>{name}</li>
      ))}
    </ol>
  </Flex>
)
