import * as PIXI from 'pixi.js'
import * as R from 'ramda'
import { Ids } from '../../constants'
import { addDragSelectEvents } from './events'

const DRAG_ADDITIONAL_AREA = 50
const HIT_ADDITIONAL_AREA = 100000

export default class DragContainer {
  constructor(width, height, handlers) {
    this.width = width
    this.height = height
    this.displayObject = new PIXI.Container()
    this.displayObject.hitArea = new PIXI.Rectangle(
      -HIT_ADDITIONAL_AREA,
      -HIT_ADDITIONAL_AREA,
      width + HIT_ADDITIONAL_AREA * 2,
      height + HIT_ADDITIONAL_AREA * 2,
    )

    this.displayObject.interactive = true
    this.displayObject.id = Ids.DRAG_CONTAINER_ID

    addDragSelectEvents({
      displayObject: this.displayObject,
      onSelectHandler: handlers.onBlockSelectedHandler,
      onDragHandler: (id, position) => {
        return this.fit(position)
      },
    })
  }

  setPageSize(pageSize) {
    this.pageSetting = pageSize
    const freeSpaceX = this.width - pageSize.width
    const freeSpaceY = this.height - pageSize.height
    this.minX = Math.min(freeSpaceX - DRAG_ADDITIONAL_AREA, freeSpaceX / 2)
    this.maxX = Math.max(DRAG_ADDITIONAL_AREA, freeSpaceX / 2)
    this.minY = Math.min(freeSpaceY - DRAG_ADDITIONAL_AREA, freeSpaceY / 2)
    this.maxY = Math.max(DRAG_ADDITIONAL_AREA, freeSpaceY / 2)
    const position = this.fit(this.displayObject.position)
    this.displayObject.x = position.x
    this.displayObject.y = position.y
  }

  fit(position) {
    return {
      x: R.clamp(this.minX, this.maxX, position.x),
      y: R.clamp(this.minY, this.maxY, position.y),
    }
  }
}
