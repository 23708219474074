// @flow

import { Content } from '@r1/wireframe-primary'
import styled from '@emotion/styled'

const HEADER_HEIGHT = '56px'

export const StyledContent = styled(Content)`
  width: 100%;
  height: calc(100vh - ${HEADER_HEIGHT});
`
