import * as React from 'react'
import styled from '@emotion/styled'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import { withTheme, Flex, Input, Select, Text, NotificationSystem } from '@r1/ui-kit'
import { ContentHeader } from '@r1/wireframe-primary'
import { FamilySelect } from '../../../../../components/selects/FamilySelect.tsx'
import { ManufacturerSelect } from '../../../../../components/selects/Manufacturer'
import { ProductModelSelect } from '../../../../../components/selects/ProductModel'
import { CategoriesSelect } from '../../../../../components/categories/Select'
import { CountrySelect } from '../../../../../components/countries/Select'
import { Identifiers, renderIdentifierAttributes } from '../../Identifiers'
import { changeCategoryId, changeFamilyId } from '../../../module'
import { renderEditor, renderInput, renderCheckbox, createFieldRenderer } from '../../fieldRenderer'
import * as actions from './module'

const weightMeasures = ['Kg', 'Lb', 'Oz']
const dimensionMeasures = ['Cm', 'Inch']

const Separator = styled('span')`
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.grey[500]};
`

const InputLetter = styled('div')`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.grey[50]};
  position: relative;
  left: 8px;
`

const selector = formValueSelector('mainInfoForm')

const mapStateToProps = ({ mainInfo, productTemplate, ...state }) => ({
  manufacturerId: selector(state, 'productInfo.manufacturer.id'),
  modelId: selector(state, 'productInfo.model.id'),
  categoryId: selector(state, 'productInfo.categoryId'),
  shortDescription: selector(state, 'productInfo.shortDescription'),
  longDescription: selector(state, 'productInfo.longDescription'),
  initialValues: mainInfo.model,
  idsTextState: mainInfo.idsTextState,
  needSubmitForReview: productTemplate.needSubmitForReview,
})

const formActions = {
  setTitle(title) {
    return change('mainInfoForm', 'productInfo.title', title)
  },
  clearProductModel() {
    return change('mainInfoForm', 'productInfo.model.id', null)
  },
  setManufacturer(id) {
    return change('mainInfoForm', 'productInfo.manufacturer.id', id)
  },
  setProductModel(id) {
    return change('mainInfoForm', 'productInfo.model.id', id)
  },
  setCategory(id) {
    return change('mainInfoForm', 'productInfo.categoryId', id)
  },
  setLongDescription(desc) {
    return change('mainInfoForm', 'productInfo.longDescription', desc)
  },
  setShortDescription(desc) {
    return change('mainInfoForm', 'productInfo.shortDescription', desc)
  },
  setCountry(id) {
    return change('mainInfoForm', 'productInfo.originCountryId', id)
  },
  setProductWeight(weight) {
    return change('mainInfoForm', 'packaging.productWeight', weight)
  },
  setProductDimensions(dimensions) {
    return change('mainInfoForm', 'packaging.productDimension', dimensions)
  },
}

const enhance = compose(
  withTheme,
  connect(mapStateToProps, { ...actions, ...formActions }),
  reduxForm({
    form: 'mainInfoForm',
    onSubmit: (values, dispatch, { handleSubmit, id }) => handleSubmit(values, id),
  }),
  withPropsOnChange(['dirty'], ({ dirty, onAddDirtyTab }) => {
    if (dirty) onAddDirtyTab('main info')
  }),
)

const renderCategory = createFieldRenderer({ Control: CategoriesSelect })
const renderFamily = createFieldRenderer({ Control: FamilySelect })
const renderManufacturer = createFieldRenderer({ Control: ManufacturerSelect })
const renderCountry = createFieldRenderer({ Control: CountrySelect })
const renderModel = createFieldRenderer({ Control: ProductModelSelect })

const renderAdditionalIds = createFieldRenderer({
  Control: ({
    onChange,
    value,
    onChangeIdentifiers,
    idsTextState,
    onToggleIdsTextState,
    ...props
  }) => (
    <Identifiers
      identifiers={value}
      isTextState={idsTextState}
      toggleTextState={onToggleIdsTextState}
      onChange={curValue => onChangeIdentifiers(curValue, onChange)}
      {...props}
    />
  ),
})

const renderPrimaryId = theme =>
  createFieldRenderer({
    Control: ({ value }) => (
      <Flex spaceBetween="S">
        <Text weight="bold" type="paragraph">
          {value.type}
        </Text>
        <Text type="paragraph" color={theme.palette.grey[600]}>
          {value.value}
        </Text>
        {renderIdentifierAttributes(value.type, value.attributes)}
      </Flex>
    ),
  })

const renderWeight = createFieldRenderer({
  Control: ({ onChange, value, validationFailed, disabled }) => (
    <Flex spaceBetween="S">
      <Input
        width="80px"
        disabled={disabled}
        value={value.amount || ''}
        validationFailed={validationFailed}
        onChange={eventValue => onChange({ ...value, amount: eventValue })}
      />
      <Select
        width="150px"
        disabled={disabled}
        value={value.measure}
        options={weightMeasures.map(id => ({ id, name: id }))}
        onChange={measure => onChange({ ...value, measure })}
      />
    </Flex>
  ),
})

const renderDimensions = createFieldRenderer({
  Control: ({ onChange, value, validationFailed, disabled }) => (
    <Flex minWidth={420} maxWidth={420} spaceBetween="M">
      <Flex minWidth={270} maxWidth={270}>
        <Input
          width="80px"
          disabled={disabled}
          rightIcons={<InputLetter>L</InputLetter>}
          value={value.length || ''}
          validationFailed={validationFailed}
          onChange={eventValue => onChange({ ...value, length: eventValue })}
        />
        <Separator>x</Separator>
        <Input
          width="80px"
          disabled={disabled}
          rightIcons={<InputLetter>W</InputLetter>}
          value={value.width || ''}
          validationFailed={validationFailed}
          onChange={eventValue => onChange({ ...value, width: eventValue })}
        />
        <Separator>x</Separator>
        <Input
          width="80px"
          disabled={disabled}
          rightIcons={<InputLetter>H</InputLetter>}
          value={value.height || ''}
          validationFailed={validationFailed}
          onChange={eventValue => onChange({ ...value, height: eventValue })}
        />
      </Flex>
      <Select
        width="150px"
        disabled={disabled}
        value={value.measure}
        options={dimensionMeasures.map(id => ({ id, name: id }))}
        onChange={measure => onChange({ ...value, measure })}
      />
    </Flex>
  ),
})

class MainInfo extends React.Component {
  componentDidMount() {
    const { id, loadModel } = this.props

    loadModel(id)
  }

  onAddManufacturer = id => {
    const { manufacturerId, setManufacturer, setProductModel } = this.props

    if (id !== manufacturerId) {
      setManufacturer(id)
      setProductModel(null)
    }
  }

  onRemoveManufacturer = id => {
    const { manufacturerId, setManufacturer, setProductModel } = this.props

    if (manufacturerId === id) {
      setManufacturer(null)
      setProductModel(null)
    }
  }

  onRemoveProductModel = id => {
    const { modelId, setProductModel } = this.props

    if (modelId === id) {
      setProductModel(null)
    }
  }

  onError = error => {
    NotificationSystem.addNotification({ level: 'error', ...error })
  }

  onToggleIdsTextState = idsTextState => {
    const { toggleIdsTextState } = this.props
    toggleIdsTextState(idsTextState)
  }

  onChangeIdentifiers = (identifiers, onChange) => {
    const { id, submitIdentifiers } = this.props
    submitIdentifiers(id, identifiers, onChange)
  }

  onVerifyProduct = isVerified => {
    const { id, verifyProduct } = this.props
    verifyProduct(id, isVerified)
  }

  render() {
    const {
      theme,
      manufacturerId,
      clearProductModel,
      setProductModel,
      categoryId,
      idsTextState,
      disabled,
      initialValues,
      shortDescription,
      longDescription,
      setShortDescription,
      setLongDescription,
    } = this.props

    return (
      <form style={{ width: '100%' }}>
        {initialValues && (
          <ContentHeader.Title
            browserTabTitle={initialValues?.productInfo?.title || 'Untitled Product UPC'}
          />
        )}
        <Flex column spaceBetween="M">
          <Field
            counter
            label="Title"
            name="productInfo.title"
            placeholder="Enter value"
            disabled={disabled}
            component={renderInput}
            style={{ flexBasis: '300px' }}
          />
          <Field
            ignoreBranchNodes
            label="Category"
            name="productInfo.categoryId"
            disabled={disabled}
            component={renderCategory}
            handleChange={value => this.props.dispatch(changeCategoryId(value))}
          />
          <Field
            label="Family"
            name="productInfo.familyId"
            disabled={disabled}
            component={renderFamily}
            handleChange={value => this.props.dispatch(changeFamilyId(value))}
          />
          <Field
            label="Manufacturer"
            name="productInfo.manufacturer.id"
            help="Manufacturer helper"
            handleChange={clearProductModel}
            disabled={disabled}
            component={renderManufacturer}
            onAddManufacturer={this.onAddManufacturer}
            onRemoveManufacturer={this.onRemoveManufacturer}
          />
          <Field
            label="Model"
            name="productInfo.model.id"
            categoryId={categoryId}
            manufacturerId={manufacturerId}
            disabled={disabled}
            component={renderModel}
            onAddProductModel={setProductModel}
            onRemoveProductModel={this.onRemoveProductModel}
          />
          <Field
            label="Primary identifier"
            name="identifier"
            placeholder="Enter value"
            help="Primary identifier helper"
            component={renderPrimaryId(theme)}
          />
          <Field
            label="Additional Identifiers"
            name="identifiers"
            idsTextState={idsTextState}
            disabled={disabled}
            component={renderAdditionalIds}
            onError={this.onError}
            onToggleIdsTextState={this.onToggleIdsTextState}
            onChangeIdentifiers={this.onChangeIdentifiers}
          />
          <Field
            label="Physically verified"
            name="physicallyVerified"
            disabled={disabled}
            component={renderCheckbox}
            onVerifyProduct={this.onVerifyProduct}
          />
          <Field
            label="Manufacturer Retail Case Pack"
            name="productInfo.isManufacturerRetailCasePack"
            disabled={disabled}
            component={renderCheckbox}
          />
          {renderEditor({
            disabled,
            label: 'Short Description',
            value: shortDescription,
            onChange: setShortDescription,
          })}
          {renderEditor({
            disabled,
            label: 'Full Description',
            value: longDescription,
            onChange: setLongDescription,
          })}
          <Field
            label="Country of Origin"
            name="productInfo.originCountryId"
            disabled={disabled}
            component={renderCountry}
          />
          <Field
            label="Product weight"
            name="packaging.productWeight"
            disabled={disabled}
            component={renderWeight}
          />
          <Field
            label="Shipping weight"
            name="packaging.packageWeight"
            disabled={disabled}
            component={renderWeight}
          />
          <Field
            label="Product Dimensions"
            name="packaging.productDimension"
            disabled={disabled}
            component={renderDimensions}
          />
          <Field
            label="Shipping Dimensions"
            name="packaging.packageDimension"
            disabled={disabled}
            component={renderDimensions}
          />
          <Field
            label="WMMP Tax Code"
            name="productInfo.taxCode"
            disabled={disabled}
            component={renderInput}
          />
          <Field
            label="Supporting URL"
            name="productInfo.supportingUrl"
            disabled={disabled}
            component={renderInput}
          />
        </Flex>
      </form>
    )
  }
}

export const MainInfoEnhanced = enhance(MainInfo)
