import * as React from 'react'
import { Control, useController } from 'react-hook-form'
import { Box, Button, Flex, HR, Icon } from '@r1/ui-kit'
import { Facility, Shift as ShiftType } from '@r1-webui/facilities-v1'
import { Shift } from './Shift'

export const Shifts = ({
  editable,
  shifts = [],
  control,
}: {
  editable?: boolean
  shifts?: Facility['shifts']
  control: Control
}) => {
  const { field: shiftField } = useController({
    name: 'shifts',
    control,
    defaultValue: shifts,
  })
  const shiftsValue = shiftField.value as ShiftType[]

  const onAddShift = React.useCallback(() => {
    const emptyShift = {
      name: '',
      periods: [],
    }
    const updatedShifts = [...shiftsValue, emptyShift]
    shiftField.onChange(updatedShifts)
  }, [shiftField, shiftsValue])

  const onRemoveShift = React.useCallback(
    (index: number) => () => {
      const updatedShifts = shiftsValue.filter((_, idx) => idx !== index)
      shiftField.onChange(updatedShifts)
    },
    [shiftField, shiftsValue],
  )

  return (
    <Flex column>
      {shiftsValue.map((shift, index) => {
        const isLastShift = index === shiftsValue.length - 1
        return (
          <React.Fragment key={`${shift.name}-${index + 1}`}>
            <Shift
              editable={editable}
              shift={shift}
              control={control}
              indexPrefix={index}
              onRemoveShift={onRemoveShift(index)}
            />
            {!isLastShift && <HR />}
          </React.Fragment>
        )
      })}
      {editable && (
        <Box mt="XS">
          <Button data-test-id="facility__shift__add" onClick={onAddShift}>
            <Icon type="plus" /> Add shift
          </Button>
        </Box>
      )}
    </Flex>
  )
}
