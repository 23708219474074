import * as React from 'react'

import { MasterProgramRule, SubStampValue } from '@r1-webui/masterprograms-v1'
import { useController, useFormContext } from 'react-hook-form'
import { Box, Checkbox, Chip, Flex, FormField, Select } from '@r1/ui-kit'

type Props = {
  fieldIdx: number
  subStampIndex: number
  editable: boolean
  subStampValues: SubStampValue[]
}

export const SubStampsAdditional = ({
  fieldIdx,
  editable,
  subStampIndex,
  subStampValues,
}: Props) => {
  const { control } = useFormContext<MasterProgramRule>()
  const [isChecked, setIsChecked] = React.useState<boolean>(false)

  const {
    field: { ref: _ref, ...notRecalculateValueIds },
  } = useController({
    name: `subStamps.${subStampIndex}.values.${fieldIdx}.notRecalculateValueIds`,
    control,
    defaultValue: [],
  })

  React.useEffect(() => {
    if (!isChecked && !!notRecalculateValueIds.value?.length) {
      setIsChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckbox = React.useCallback(
    (checked: boolean) => {
      notRecalculateValueIds.onChange([])
      setIsChecked(checked)
    },
    [notRecalculateValueIds],
  )

  const isViewModeCheckboxActive = !!notRecalculateValueIds.value?.length
  const notRecalculateValues = subStampValues.filter(({ id }) =>
    (notRecalculateValueIds.value || []).includes(id),
  )

  return editable ? (
    <FormField>
      <Flex mt="L" mb="M">
        <Checkbox
          label="Re-apply previous and do not apply new substamp if previous substamp value is:"
          checked={isChecked}
          onChange={handleCheckbox}
        />
      </Flex>
      {isChecked && (
        <Flex mb="L">
          <Select
            {...notRecalculateValueIds}
            multiple
            clearable
            data-test-id={`sub-stamp-${subStampIndex}-value-${fieldIdx}__select-additional-substamps`}
            options={subStampValues}
            value={notRecalculateValueIds.value || []}
          />
        </Flex>
      )}
    </FormField>
  ) : (
    <FormField>
      <Flex mt="L" mb="M">
        <Checkbox
          disabled
          label="Re-apply previous and do not apply new substamp if previous substamp value is:"
          checked={isViewModeCheckboxActive}
        />
      </Flex>
      {isViewModeCheckboxActive && (
        <Flex mb="L">
          {notRecalculateValues.map(({ name }) => (
            <Box key={name} mr="XS">
              <Chip text={name} />
            </Box>
          ))}
        </Flex>
      )}
    </FormField>
  )
}
