import React from 'react'
import { connect } from 'react-redux'

import { FormField, Flex } from '@r1/ui-kit'
import { InputType, BlockType, Ids } from '../../constants'
import { changeBlockProperty } from '../../actions'
import Container from './Container'
import Input from './Input'

const mapStateToProps = state => {
  const documentTemplate = state.labelManagement.documentTemplate.currentState

  if (documentTemplate.selectedBlockId !== Ids.DRAG_CONTAINER_ID) {
    const block = documentTemplate.blocks.find(b => b.id === documentTemplate.selectedBlockId)

    if (block.type === BlockType.RECTANGLE_BLOCK) {
      return {
        blockId: block.id,
        lineThickness: block.lineThickness,
      }
    }
  }

  return {}
}

const mapDispatchToProps = dispatch => ({
  onChange: (blockId, oldLineThickness, newLineThickness) =>
    dispatch(changeBlockProperty(blockId, ['lineThickness'], oldLineThickness, newLineThickness)),
})

export const RectangleBlockPanel = connect(
  mapStateToProps,
  mapDispatchToProps,
)(({ blockId, lineThickness = 0, onChange, ...props }) => {
  if (typeof blockId === 'undefined') {
    return null
  }

  const handleChange = newLineThickness => {
    onChange(blockId, lineThickness, newLineThickness)
  }

  return (
    <Container {...props}>
      <Flex>
        <FormField.Label>Line thickness</FormField.Label>
        <Input type={InputType.NUMBER} data={lineThickness} onChange={handleChange} />
      </Flex>
    </Container>
  )
})
/*
RectangleBlockPanel.propTypes = {
  blockId: PropTypes.number,
  lineThickness: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}
*/
