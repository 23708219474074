import * as React from 'react'
import * as R from 'ramda'
import { Flex, H4 } from '@r1/ui-kit'
import { Facility } from '@r1-webui/facilities-v1'
import { Country } from '@r1-webui/addressmanagement-v1'
import { Control } from 'react-hook-form'
import { Address } from './Address'

export const Addresses = ({
  editable,
  control,
  addresses = [],
  countries,
}: {
  editable?: boolean
  control: Control
  addresses?: Facility['addresses']
  countries: Array<Country> | null
}) => {
  const groupedAdresses = R.groupBy(({ addressType }) => addressType, addresses)

  return (
    <Flex column>
      {Object.entries(groupedAdresses).map(([title, addressGroup]) => (
        <React.Fragment key={title}>
          <H4>{title}</H4>
          {addressGroup.map(address => (
            <Address
              key={`${address.postalCode}${address.addressType}`}
              control={control}
              {...{ editable, countries, address }}
            />
          ))}
        </React.Fragment>
      ))}
    </Flex>
  )
}
