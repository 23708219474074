// @flow

import { baseUri, createPricingRulesApi } from '@r1-webui/priceconsolidator-rulemanagement-v1'
import type { Api as PriceConsolidatorRuleManagementApi } from '@r1-webui/priceconsolidator-rulemanagement-v1/src/pricingRules'
import { UI_API_PREFIXES } from '../../../api'

export const API_PREFIX_CONSOLIDATION = `${UI_API_PREFIXES.PRICING_CONSOLIDATION}/${baseUri}`

export const createPriceConsolidatorRuleManagementApi = (
  httpClient: $AnyObject,
): PriceConsolidatorRuleManagementApi =>
  createPricingRulesApi({ http: httpClient, urlPrefix: API_PREFIX_CONSOLIDATION })
