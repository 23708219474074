// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { Drawer, Flex, Box, FormField, Button, HR } from '@r1/ui-kit'

import { Constraint } from '../../Constraint'
import { FormatAttributeValue } from '../FormatAttributeValue'
import { getAttributeValueTypeText, getAttributeBindingType } from '../module/attributeViewMapper'
import type { ExtendedAttributeDefinition } from '../../../../services/categoryAttributesService'
import { ChildAttributes } from './ChildAttributes'

const Text = styled('span')`
  color: ${({ theme }) => theme.palette.grey[800]};
  line-height: 24px;
  font-size: 16px;
  word-break: break-word;
`

type Props = {|
  show: boolean,
  onClose: () => void,
  attributeDefinition: ExtendedAttributeDefinition,
|}

export const CategoriesAttributeDrawer = (props: Props) => (
  <Drawer.Form
    closeButton
    show={props.show}
    title={`Details «
      ${props.attributeDefinition.meta.binding.displayName || props.attributeDefinition.name}»`}
    placement="right"
    size={704}
    backdrop="closing"
    footer={
      <Flex justify="flex-end">
        <Button color="secondary" onClick={props.onClose}>
          Close
        </Button>
      </Flex>
    }
    onClose={props.onClose}
  >
    <Flex column minWidth={623} maxWidth={623} m="XXL">
      <Flex column>
        <Flex spaceBetween="XXS">
          <FormField.Label>Type</FormField.Label>
          <Text data-test-id="type">
            {getAttributeValueTypeText(props.attributeDefinition.valueType)}
          </Text>
        </Flex>
        <HR />
      </Flex>
      <Flex column>
        <Flex spaceBetween="XXS">
          <FormField.Label>Inherited</FormField.Label>
          <Text data-test-id="inherited">
            {props.attributeDefinition.meta.inherited ? 'Yes' : 'No'}
          </Text>
        </Flex>
        <HR />
      </Flex>
      <Flex column>
        <Flex spaceBetween="XXS">
          <FormField.Label>Binding</FormField.Label>
          <Text data-test-id="binding">
            {getAttributeBindingType(props.attributeDefinition.type)}
          </Text>
        </Flex>
        <HR />
      </Flex>
      <Flex column>
        <Flex spaceBetween="XXS">
          <FormField.Label>Original Title</FormField.Label>
          <Text data-test-id="original-title">{props.attributeDefinition.name}</Text>
        </Flex>
        <HR />
      </Flex>

      {props.attributeDefinition.meta.binding.defaultValue && (
        <Flex column>
          <Flex spaceBetween="XXS">
            <FormField.Label>Default Value</FormField.Label>
            <Text data-test-id="default-value">
              <FormatAttributeValue
                value={props.attributeDefinition.meta.binding.defaultValue}
                definition={props.attributeDefinition}
              />
            </Text>
          </Flex>
          <HR />
        </Flex>
      )}

      <Box mb="XL">
        <Flex spaceBetween="XXS">
          <FormField.Label>Required</FormField.Label>
          <Text data-test-id="required">
            {props.attributeDefinition.meta.binding.required ? 'Yes' : 'No'}
          </Text>
        </Flex>
      </Box>

      {props.attributeDefinition.meta.childAttributes && (
        <ChildAttributes childAttributes={props.attributeDefinition.meta.childAttributes} />
      )}

      {props.attributeDefinition.meta.binding.valueConstraint && (
        <Constraint
          value={props.attributeDefinition.meta.binding.valueConstraint}
          enumValues={props.attributeDefinition.enumValues}
        />
      )}
    </Flex>
  </Drawer.Form>
)
