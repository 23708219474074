// @flow

import * as React from 'react'

import { Flex } from '@r1/ui-kit'

import type { StepsProps } from '../types'
import { OrderInfo } from '../../OrderInfo'
import { ClaimInfo } from '../../ClaimInfo'
import { OrderLine } from '../../OrderLine'
import { OrderLineNotesModal } from '../../OrderLineNotesModal'
import { OrderLineReplacementsModal } from '../../OrderLineReplacementsModal'
import { Property } from '../../StyledComponents'
import {
  OrderLineSelectionListWrapper,
  ErrorMessage,
  OrderInfoWrapper,
  ClaimInfoWrapper,
} from './StyledComponents'

export const ClaimDetailsStep = (props: StepsProps) => {
  const { httpClient, claimCreationInfo } = props
  const {
    claimType,
    expectedDecisionTypeTitle,
    claimDescription,
    order,
    selectedOrderLines,
    currentOrderLine,
    isOrderLineNotesModalVisible,
    isOrderLineReplacementsModalVisible,
    orderStatus,
    rmaProfile,
    shippingAddress,
    returnReasons,
    claimTotal,
    onOrderLineQuantityChange,
    onOrderLineReasonChange,
    onOrderLineNotesModalShow,
    onOrderLineNotesChange,
    onOrderLineAmountChange,
    onOrderLineReplacementsModalShow,
    onOrderLineReplacementsChange,
  } = claimCreationInfo

  return (
    <>
      <OrderInfoWrapper>
        <OrderInfo
          order={order}
          rmaProfile={rmaProfile}
          orderStatus={orderStatus}
          shippingAddress={shippingAddress}
        />
        <ClaimInfo claimTotal={claimTotal} />
      </OrderInfoWrapper>
      <ClaimInfoWrapper column mb="XL">
        <Flex>
          <Property>Decision:</Property>
          {expectedDecisionTypeTitle}
        </Flex>
        <Flex>
          <Property>Description:</Property>
          {claimDescription.value || 'N/A'}
        </Flex>
      </ClaimInfoWrapper>
      <OrderLineSelectionListWrapper error={selectedOrderLines.error}>
        {selectedOrderLines.values.map(orderLine => (
          <OrderLine
            key={orderLine.lineId}
            claimType={claimType}
            order={order}
            orderLine={orderLine}
            orderStatus={orderStatus}
            rmaProfile={rmaProfile}
            returnReasons={returnReasons}
            onOrderLineSelection={selectedOrderLines.onChange}
            onOrderLineQuantityChange={onOrderLineQuantityChange}
            onOrderLineReasonChange={onOrderLineReasonChange}
            onOrderLineNotesModalShow={onOrderLineNotesModalShow}
            onOrderLineAmountChange={onOrderLineAmountChange}
            onOrderLineReplacementsModalShow={onOrderLineReplacementsModalShow}
          />
        ))}
      </OrderLineSelectionListWrapper>
      <ErrorMessage>{selectedOrderLines.error}</ErrorMessage>
      <OrderLineNotesModal
        httpClient={httpClient}
        orderLine={currentOrderLine}
        isVisible={isOrderLineNotesModalVisible}
        onOrderLineNotesModalShow={onOrderLineNotesModalShow}
        onOrderLineNotesChange={onOrderLineNotesChange}
      />
      <OrderLineReplacementsModal
        httpClient={httpClient}
        orderLine={currentOrderLine}
        isVisible={isOrderLineReplacementsModalVisible}
        onOrderLineReplacementsModalShow={onOrderLineReplacementsModalShow}
        onOrderLineReplacementsChange={onOrderLineReplacementsChange}
      />
    </>
  )
}
