import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/css'
import {
  H3,
  Flex,
  Box,
  Switch,
  Icon,
  FormField,
  Image,
  Uploader,
  NotificationSystem,
  Format,
  useConfigurationProvider,
  HR,
} from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import { fileToBase64 } from '../../../../utils/fileToBase64'

import type { UserCardProps } from '../../types/UserCard'

import { TextReadOnly } from '../common'

const PROFILE_IMAGE_CONTAINER_SIZE = 352

const ContainerStyled = styled('div')`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  padding: ${({ theme }) => `${theme.space.XL} ${theme.space.XL} ${theme.space.XXXL}`};
`

const ProfileImageContainer = styled.div<{ size: number }>`
  color: ${({ theme }) => theme.palette.grey[600]};
  position: relative;
  ${({ size }) => `
    width:  ${size}px;
    height: ${size}px;
  `}

  & img {
    object-fit: contain;
    ${({ size }) => `
      width:  ${size}px;
      height: ${size}px;
    `}
  }
`

const IconRemoveWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;

  ${({ theme }) => css`
    width: ${theme.space.XL};
    height: ${theme.space.XL};
    background-color: ${theme.palette.grey[500]};
  `}
`

const FullName = styled(H3)`
  max-width: ${PROFILE_IMAGE_CONTAINER_SIZE}px;
  word-wrap: break-word;
`

type Props = {
  editable: boolean
  profileImageSrc?: string
  onDeleteImage: () => void
  onUploadImage: (base64string: string) => void
}

type ImageResolution = {
  width: number
  height: number
}

const getImageResolution = (base64Image: string): Promise<ImageResolution | null> => {
  return new Promise(resolve => {
    const imageNode = document.createElement('img')
    imageNode.onload = () => {
      const { naturalWidth: width, naturalHeight: height } = imageNode
      resolve({ width, height })
    }
    imageNode.onerror = () => {
      resolve(null)
    }
    imageNode.setAttribute('src', base64Image)
  })
}

const MIN_SIDE_LENGTH = 500
const isAvailableResolution = async (base64Image: string) => {
  const resolution = await getImageResolution(base64Image)
  if (!resolution) return false

  const { width, height } = resolution
  return width >= MIN_SIDE_LENGTH && height >= MIN_SIDE_LENGTH
}

const MAX_IMAGE_WEIGHT = 5 // megabytes
const isAvailableImageSize = (size: number): boolean => {
  const availableSize = 1024 * 1024 * MAX_IMAGE_WEIGHT // megabytes
  return availableSize > size
}

const ProfileImageContent = (props: Props) => {
  const { editable, profileImageSrc, onDeleteImage, onUploadImage } = props
  const { palette } = useConfigurationProvider()
  const imageSize = PROFILE_IMAGE_CONTAINER_SIZE

  const onChangeUploader = async (uploaderPayload: { file: File }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const base64Image = await fileToBase64(uploaderPayload.file).then(response => {
      if (response.$type === 'Success') return response.payload as string
      handleServerError(response)
      return undefined
    })

    if (base64Image) {
      if (await isAvailableResolution(base64Image)) {
        onUploadImage(base64Image)
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: 'Image resolution is less then 500x500px',
        })
      }
    }
  }

  const onChange = (uploaderPayload: { file: File }) => {
    const { file } = uploaderPayload
    if (isAvailableImageSize(file.size)) onChangeUploader(uploaderPayload)
    else {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Image exceed max size 5mb',
      })
    }
  }

  if (editable) {
    if (profileImageSrc) {
      return (
        <>
          <IconRemoveWrapper>
            <Icon type="trash" color={palette.white} onClick={onDeleteImage} />
          </IconRemoveWrapper>
          <Image src={profileImageSrc} width={imageSize} height={imageSize} />
        </>
      )
    }
    return (
      <Uploader
        accept="image/jpeg,image/png"
        width={imageSize}
        height={imageSize}
        onChange={onChange}
      />
    )
  }

  return <Image src={profileImageSrc || ''} width={imageSize} height={imageSize} />
}

export const UserCard = (props: UserCardProps) => {
  const {
    fullName,
    profileImageSrc,
    activationInfo,
    createdOn,
    lastLoggedIn,
    onUploadImage,
    onDeleteImage,
    editable,
  } = props

  return (
    <ContainerStyled>
      <Flex column>
        <Box mb="M">
          <ProfileImageContainer size={PROFILE_IMAGE_CONTAINER_SIZE}>
            <ProfileImageContent
              editable={editable}
              profileImageSrc={profileImageSrc}
              onUploadImage={onUploadImage}
              onDeleteImage={onDeleteImage}
            />
          </ProfileImageContainer>
        </Box>

        {fullName && <FullName>{fullName}</FullName>}
        {fullName ? <HR /> : null}
        <Flex column spaceBetween="S">
          <Flex justify="space-between" align="center" mt="S" mb="S">
            <FormField.Label>Active</FormField.Label>
            <Switch
              disabled={activationInfo.disabled || !editable}
              checked={activationInfo.active}
              onChange={activationInfo.onToggle}
            />
          </Flex>
        </Flex>
        <HR />
        {createdOn && (
          <React.Fragment>
            <Flex column spaceBetween="S">
              <Flex justify="space-between" align="center" mt="S" mb="S">
                <FormField.Label>Date Created</FormField.Label>
                <TextReadOnly>
                  <Format.Date>{createdOn}</Format.Date>
                </TextReadOnly>
              </Flex>
            </Flex>
            <HR />
          </React.Fragment>
        )}
        {lastLoggedIn && (
          <React.Fragment>
            <Flex column spaceBetween="S">
              <Flex justify="space-between" align="center" mt="S" mb="S">
                <FormField.Label>Last Logged In</FormField.Label>
                <TextReadOnly>
                  <Format.DateTime>{lastLoggedIn}</Format.DateTime>
                </TextReadOnly>
              </Flex>
            </Flex>
            <HR />
          </React.Fragment>
        )}
      </Flex>
    </ContainerStyled>
  )
}
