import * as React from 'react'

const UPCLink =
  'https://wwwapps.ups.com/WebTracking?HTMLVersion=5.0&TypeOfInquiryNumber=T&InquiryNumber1='

function formatLink(trackingNumber) {
  return `${UPCLink}${trackingNumber}`
}

export const TrackingNumber = ({ trackingNumber, index }) => {
  return (
    <a
      data-test-id={`tracking-number-${index}`}
      rel="noopener noreferrer"
      href={`${formatLink(trackingNumber)}`}
      target="_blank"
    >
      {trackingNumber}
    </a>
  )
}
