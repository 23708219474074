// @flow

import styled from '@emotion/styled'

const Container = styled('div')`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;

  > video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .shot-effect {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(2);
    z-index: 9;
    display: none;

    &.animation {
      display: flex;
      animation: animation 1s forwards;
    }
  }

  @keyframes animation {
    50% {
      transform: translate(-50%, -50%) scale(1.2);
      opacity: 1;
      top: 35%;
    }
    100% {
      top: -50%;
      transform: translate(-50%, -50%) scale(1);
    }
  }
`

const ConnectError = styled('div')`
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 18px;
  color: #fff;
`

export { Container, ConnectError }
