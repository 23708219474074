// @flow

import * as React from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'
import { CategoryViewScreen } from '../../../containers/CategoryContainer'

import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'

const getAttributeDetailsUrl = attributeId =>
  generatePath(ROUTES.ATTRIBUTE_DEFINITION_DETAILS, { attributeId })

export const CategoryView = () => {
  const { categoryId } = useParams()
  const navigate = useNavigate()
  return (
    <PageAccessControl permissions={[PERMISSIONS.allowCategoryView]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.CATEGORIES}>Categories</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <CategoryViewScreen
            categoryId={categoryId || ''}
            httpClient={httpClient}
            renderTitle={ContentHeader.Title}
            renderActionsButtons={ContentHeader.ActionButtons}
            getAttributeDetailsUrl={getAttributeDetailsUrl}
            onClose={() => navigate(ROUTES.CATEGORIES)}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
