// @flow

import styled from '@emotion/styled'

import { Flex } from '@r1/ui-kit'

export const OrderSearchForm = styled('form')`
  display: flex;
  width: 100%;
`

export const OrderSelectionListWrapper = styled(Flex)`
  border: 2px solid ${({ error }) => (error ? 'red' : 'transparent')};
  flex-direction: column;
  & > div {
    width: 100%;
  }
`

export const OrderLineSelectionListWrapper = styled(Flex)`
  border: 2px solid ${({ error }) => (error ? 'red' : 'transparent')};
  flex-direction: column;
  padding: ${({ theme }) => theme.space.XXS};
`

export const InputField = styled(Flex)`
  flex-direction: column;
  width: 100%;
`

export const ErrorMessage = styled('div')`
  margin: 0;
  font-family: 'Roboto', sans-serif;
  height: 1rem;
  color: ${({ theme }) => theme.palette.red[500]};
  margin-right: ${props => props.marginRight};
`
export const OrderInfoWrapper = styled(Flex)`
  position: relative;
`

export const ClaimInfoWrapper = styled(Flex)`
  position: relative;
`

export const ClaimLinesWrapper = styled(Flex)`
  border: 2px solid transparent;
  padding: ${({ theme }) => theme.space.XXS};
  flex-direction: column;
`

export const TitleLabel = styled('div')`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-bottom: ${({ theme }) => theme.space.XS};
`

export { Property } from '../../StyledComponents'
