// @flow

import { createSuccessHandler, createErrorHandler } from '../errorHandler'

type Notification = {|
  level: 'success' | 'error' | 'warning' | 'info',
  title: string,
  message?: string,
|}

type OnNotify = Notification => void

export type Options = {|
  onError?: (error: any) => void,
  onNotify: OnNotify,
|}

const baseURL = '/uisvc/tcr/api/v1/purchase-orders-grid'
const addressManagementURL = '/uisvc/address-management/iso-restrictive/api/v1'
export const createApi = (httpClient: Object, { onError, onNotify }: Options): any => {
  const { get, post } = httpClient
  const successHandler = createSuccessHandler(onNotify)
  const errorHandler = createErrorHandler({ onError, onNotify })

  return {
    getPurchaseOrders: obj =>
      post(`${baseURL}/purchase-orders/query`, obj)
        .then(({ data }) => data)
        .catch(errorHandler),
    submitPurchaseOrders: obj =>
      post(`${baseURL}/purchase-orders/submitted`, obj)
        .then(successHandler('Purchase orders has been successfully submitted'))
        .catch(errorHandler),

    getIdToR1Orders: obj =>
      post(`/searchfilter/api/SearchOrdersB2CFilter`, {
        Warehouse: [-1],
        ...obj,
      }).catch(errorHandler),

    voidTaxCommitmentDocument: (taxCommitmentDocumentId, obj) =>
      post(`${baseURL}/tax-commitment-documents/${taxCommitmentDocumentId}/voided`, obj)
        .then(successHandler('Success'))
        .catch(errorHandler),

    getSubdivisions: ({ code }) =>
      get(`${addressManagementURL}/countries/${code}/subdivisions`, {
        headers: {
          'Accept-Language': 'usa',
        },
      }).catch(errorHandler),
  }
}
