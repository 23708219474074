/* eslint-disable no-nested-ternary */
// @flow

import React from 'react'
import invariant from 'invariant'
import styled from '@emotion/styled'
import {
  FormField,
  Text,
  NumericInput,
  DatePicker,
  Box,
  Icon,
  Switch,
  TagInput,
  Flex,
  Select,
} from '@r1/ui-kit'
import { Section } from '../../../Section'

import type { ValueConstraintFieldProps, AllowedNumbersProps, AllowedDatesProps } from './types'

const regexp = {
  //    TODO change, when forbiddenValues in NumberConstraint (in RAML) will devided into integer and decimal instead of decimal only
  //    Integer: /\d+?/,
  Integer: /^\d+\.?\d*$|\^.\d+$/,
  Decimal: /^\d+\.?\d*$|\^.\d+$/,
}

const MAX_NUMBER = 2147483647

const message = 'Type of attribute value should correspond to type of attribute definition'

const ColorSpan = styled('span')`
  color: ${({ theme }) => theme.palette.grey[800]};
  padding: ${({ theme }) => `${theme.space.M} 0 ${theme.space.XS}`};
`

const Title = props => (
  <ColorSpan>
    <Text type="caption-caps">{props.children}</Text>
  </ColorSpan>
)

const AllowedNumbers = ({
  valueType,
  valueConstraint,
  handleChange,
  error,
}: AllowedNumbersProps) => {
  const isDecimal = valueType === 'DecimalRange' || valueType === 'Decimal'
  return (
    <Box my="S">
      <FormField>
        <FormField.Label>
          <Flex>
            <Box mr="XXL">From</Box>
            <Box minWidth={100} />
            <Box ml="XXL">To</Box>
          </Flex>
        </FormField.Label>
        <Box minWidth={188}>
          <NumericInput
            data-test-id="minAllowed"
            value={
              valueConstraint.valueType === 'StringConstraint'
                ? valueConstraint.minAllowedLength != null
                  ? parseFloat(valueConstraint.minAllowedLength)
                  : null
                : valueConstraint.minAllowedValue != null
                ? parseFloat(valueConstraint.minAllowedValue)
                : null
            }
            maxFractionDigits={isDecimal ? 100 : 0}
            min={valueConstraint.valueType === 'StringConstraint' ? 0 : -1 * MAX_NUMBER}
            max={MAX_NUMBER}
            placeholder="Enter number"
            onChange={(handleChange: any)([
              'valueConstraint',
              valueConstraint.valueType === 'StringConstraint'
                ? 'minAllowedLength'
                : 'minAllowedValue',
            ])}
          />
        </Box>
        <Icon type="minus" />
        <Box minWidth={188}>
          <NumericInput
            data-test-id="maxAllowed"
            value={
              valueConstraint.valueType === 'StringConstraint'
                ? valueConstraint.maxAllowedLength != null
                  ? parseFloat(valueConstraint.maxAllowedLength)
                  : null
                : valueConstraint.maxAllowedValue != null
                ? parseFloat(valueConstraint.maxAllowedValue)
                : null
            }
            min={valueConstraint.valueType === 'StringConstraint' ? 0 : -1 * MAX_NUMBER}
            max={MAX_NUMBER}
            maxFractionDigits={isDecimal ? 100 : 0}
            placeholder="Enter number"
            onChange={(handleChange: any)([
              'valueConstraint',
              valueConstraint.valueType === 'StringConstraint'
                ? 'maxAllowedLength'
                : 'maxAllowedValue',
            ])}
          />
        </Box>
        <FormField.Error>{error}</FormField.Error>
      </FormField>
    </Box>
  )
}

const AllowedDates = ({ valueConstraint, handleChange, error }: AllowedDatesProps) => (
  <FormField>
    <FormField.Label>
      <Flex>
        <Box mr="XXL">From</Box>
        <Box minWidth={78} />
        <Box ml="XXL">To</Box>
      </Flex>
    </FormField.Label>
    <Box minWidth={188}>
      <DatePicker
        data-test-id="minAllowedDate"
        placeholder="YYYY-MM-DD"
        value={valueConstraint.minAllowedDate}
        onChange={(handleChange: any)(['valueConstraint', 'minAllowedDate'])}
        onRemove={(handleChange: any)(['valueConstraint', 'minAllowedDate'])}
      />
    </Box>
    <Box minWidth={188}>
      <DatePicker
        data-test-id="maxAllowedDate"
        placeholder="YYYY-MM-DD"
        value={valueConstraint.maxAllowedDate}
        onChange={(handleChange: any)(['valueConstraint', 'maxAllowedDate'])}
        onRemove={(handleChange: any)(['valueConstraint', 'maxAllowedDate'])}
      />
    </Box>
    <FormField.Error>{error}</FormField.Error>
  </FormField>
)

const ValueConstraintInput = ({
  valueType,
  valueConstraint,
  defaultValue,
  handleChange,
  enumValues,
  error,
}: ValueConstraintFieldProps) => {
  const errorMsg = error
    ? error.maxAllowedDate ||
      error.minAllowedDate ||
      error.minAllowedValue ||
      error.maxAllowedValue ||
      error.minAllowedLength ||
      error.maxAllowedLength ||
      ''
    : ''

  switch (valueType) {
    case 'IntEnumeration':
    case 'DecimalEnumeration':
    case 'StringEnumeration':
      invariant(valueConstraint.valueType === 'EnumerationConstraint', message)
      invariant(defaultValue.valueType === 'EnumValue', message)
      return (
        <>
          <Title>Allowed THE FOLLOWING (all allowed if nothing is selected)</Title>
          <FormField>
            <FormField.Label>
              {valueType === 'StringEnumeration' ? 'Text String' : 'Numbers'}
            </FormField.Label>
            {/*  */}
            {/* $FlowFixMe[incompatible-type] */}
            <Select
              multiple
              data-test-id="allowedVariants"
              options={enumValues ? enumValues.enumValues : []}
              labelKey="value"
              value={valueConstraint.allowedVariants || []}
              onChange={val => {
                ;(handleChange: any)(['valueConstraint', 'allowedVariants'])(val)
                if (val.includes(defaultValue.value)) {
                  ;(handleChange: any)(['defaultValue', 'value'])(null)
                }
              }}
            />
          </FormField>
        </>
      )
    case 'Date':
    case 'DateRange':
      invariant(
        valueConstraint.valueType === 'DateRangeConstraint' ||
          valueConstraint.valueType === 'DateConstraint',
        message,
      )
      return (
        <>
          <Title>Allowed Dates</Title>
          <AllowedDates
            valueConstraint={valueConstraint}
            handleChange={handleChange}
            error={errorMsg}
          />
        </>
      )
    case 'IntRange':
    case 'DecimalRange':
      invariant(valueConstraint.valueType === 'NumberRangeConstraint', message)
      return (
        <>
          <Title>Allowed Numbers</Title>
          <AllowedNumbers
            error={errorMsg}
            valueType={valueType}
            valueConstraint={valueConstraint}
            handleChange={handleChange}
          />
        </>
      )
    case 'Integer':
    case 'Decimal':
      invariant(valueConstraint.valueType === 'NumberConstraint', message)
      return (
        <>
          <Title>ALLOWED numbers</Title>
          <AllowedNumbers
            error={errorMsg}
            valueType={valueType}
            valueConstraint={valueConstraint}
            handleChange={handleChange}
          />
          <Title>ForbidDEN certain numbers</Title>
          <FormField>
            <FormField.Label>Numbers</FormField.Label>
            <TagInput
              data-test-id="forbiddenValues"
              $type="simpleTagValue"
              value={valueConstraint.forbiddenValues || []}
              accept={regexp[valueType]}
              placeholder="Enter values"
              onChange={(handleChange: any)(['valueConstraint', 'forbiddenValues'])}
            />
          </FormField>
        </>
      )
    case 'String':
      invariant(valueConstraint.valueType === 'StringConstraint', message)
      return (
        <>
          <Title>Allowed String Length</Title>
          <AllowedNumbers
            error={errorMsg}
            valueType={valueType}
            valueConstraint={valueConstraint}
            handleChange={handleChange}
          />
          <Title>ForbidDEN certain words & symbols (only latin)</Title>
          <FormField>
            <FormField.Label>Words & Symbols</FormField.Label>
            <TagInput
              data-test-id="words"
              $type="simpleTagValue"
              value={valueConstraint.forbiddenWords}
              placeholder="Enter values"
              onChange={(handleChange: any)(['valueConstraint', 'forbiddenWords'])}
            />
          </FormField>
        </>
      )
    default:
      throw new Error(`Unknown attribute definition type ${valueType}`)
  }
}

export const ValueConstraintField = (props: ValueConstraintFieldProps) => (
  <Section
    title={SectionTitle => (
      <Flex align="center" justify="space-between" mb="M" mt="M">
        <Flex column>
          <SectionTitle>Constraints</SectionTitle>
          <FormField.Description>
            {/* eslint-disable-next-line no-irregular-whitespace */}
            Set specific constraints to hide some values ​​ from your audience
          </FormField.Description>
        </Flex>
        <Switch checked={props.hasConstraint} onChange={props.handleChange('hasConstraint')} />
      </Flex>
    )}
  >
    {props.hasConstraint && <ValueConstraintInput {...props} />}
  </Section>
)
