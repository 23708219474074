import { createRulesApi, createSubStampsApi } from '@r1-webui/masterprograms-v1'
import { httpClient } from '../../../../httpClient'

const API_PREFIX = `/uisvc/master-program`

export const masterProgramRulesApi = createRulesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export const subStampsApi = createSubStampsApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
