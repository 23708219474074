import * as React from 'react'
import * as R from 'ramda'
import { FormField, Input, Select } from '@r1/ui-kit'
import { Address as FacilityAddress } from '@r1-webui/facilities-v1'
import { Country } from '@r1-webui/addressmanagement-v1'
import { useController, Control } from 'react-hook-form'
import { Value } from '../../Value'

export const Address = ({
  editable,
  control,
  countries,
  address,
}: {
  editable?: boolean
  control: Control
  countries?: Array<Country> | null
  address: FacilityAddress
}) => {
  const {
    countryCode,
    addressType,
    cityName = '',
    addressLine1 = '',
    addressLine2 = '',
    postalCode = '',
  } = address
  const formPrefix = 'addresses'
  const countryName = countries?.find(country => country.code === countryCode)?.name
  const {
    field: { ref: _ref, ...countrySelect },
    formState: { errors },
  } = useController({
    name: `${formPrefix}.${addressType}.countryCode`,
    control,
    defaultValue: countryCode,
  })
  const { field: address1 } = useController({
    name: `${formPrefix}.${addressType}.addressLine1`,
    control,
    defaultValue: addressLine1,
  })
  const { field: address2 } = useController({
    name: `${formPrefix}.${addressType}.addressLine2`,
    control,
    defaultValue: addressLine2,
  })
  const { field: cityInput } = useController({
    name: `${formPrefix}.${addressType}.cityName`,
    control,
    defaultValue: cityName,
  })
  const { field: zipInput } = useController({
    name: `${formPrefix}.${addressType}.postalCode`,
    control,
    defaultValue: postalCode,
  })

  const dataTestIdPrefix = React.useMemo(
    () => `facility__address-${addressType.toLowerCase()}`,
    [addressType],
  )

  return (
    <React.Fragment>
      <FormField>
        <FormField.Label>Country</FormField.Label>
        {editable ? (
          <Select
            multiple={false}
            clearable={false}
            loading={!countries}
            options={countries}
            data-test-id={`${dataTestIdPrefix}__country`}
            {...countrySelect}
          />
        ) : (
          <Value>{countryName}</Value>
        )}
        <FormField.Error>
          {R.pathOr(null, [formPrefix, addressType, 'country', 'message'], errors)}
        </FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Address Line 1</FormField.Label>
        {editable ? (
          <Input {...address1} data-test-id={`${dataTestIdPrefix}__address1`} />
        ) : (
          <Value>{addressLine1}</Value>
        )}
        <FormField.Error>
          {R.pathOr(null, [formPrefix, addressType, 'addressLine1', 'message'], errors)}
        </FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Address Line 2</FormField.Label>
        {editable ? (
          <Input {...address2} data-test-id={`${dataTestIdPrefix}__address2`} />
        ) : (
          <Value>{addressLine2}</Value>
        )}
        <FormField.Error>
          {R.pathOr(null, [formPrefix, addressType, 'addressLine2', 'message'], errors)}
        </FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>City</FormField.Label>
        {editable ? (
          <Input {...cityInput} data-test-id={`${dataTestIdPrefix}__city`} />
        ) : (
          <Value>{cityName}</Value>
        )}
        <FormField.Error>
          {R.pathOr(null, [formPrefix, addressType, 'cityName', 'message'], errors)}
        </FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Zip</FormField.Label>
        {editable ? (
          <Input {...zipInput} data-test-id={`${dataTestIdPrefix}__zip`} />
        ) : (
          <Value>{postalCode}</Value>
        )}
        <FormField.Error>
          {R.pathOr(null, [formPrefix, addressType, 'postalCode', 'message'], errors)}
        </FormField.Error>
      </FormField>
    </React.Fragment>
  )
}
