import React from 'react'
import styled from '@emotion/styled'
import { Report, ReportSection } from '@r1-webui/reports-v1'
import { Text, Flex } from '@r1/ui-kit'
import { useReportSectionViews } from '../reports.utils'
import { defaultReportSection } from '../reports.const'
import { ReportCard } from './ReportCard'
import { CardsLayout } from './CardsLayout'

type Props = {
  reports: Report[]
  sections: ReportSection[]
}

const ReportsCounter = styled.div`
  background-color: ${({ theme }) => theme.palette.grey[300]};
  border-radius: 2px;
  padding: 2px 4px;
`

export function ReportGroupPage({ reports, sections }: Props) {
  const reportSectionViews = useReportSectionViews({ reports, sections })

  return (
    <Flex column spaceBetween="XL">
      {reportSectionViews.map(view => {
        if (!view.reports.length) return null

        return (
          <Flex key={view.section.id} column spaceBetween="S">
            {view.section.id !== defaultReportSection.id && (
              <Flex align="center">
                <Text>{view.section.name}&nbsp;/&nbsp;</Text>
                <ReportsCounter>
                  <Text>{view.reports.length}</Text>
                </ReportsCounter>
              </Flex>
            )}

            <CardsLayout>
              {view.reports.map(
                ({ name, url, version, description, lastRefreshDate, lastRefreshSuccess }) => (
                  <ReportCard
                    key={url}
                    name={name}
                    reportUrl={url}
                    version={version}
                    description={description}
                    lastRefreshDate={lastRefreshDate}
                    lastRefreshSuccessful={lastRefreshSuccess}
                  />
                ),
              )}
            </CardsLayout>
          </Flex>
        )
      })}
    </Flex>
  )
}
