// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Table, AsyncTree } from '@r1/grid'
import { Box, Flex, Button } from '@r1/ui-kit'

import type { ExtendedAttributeDefinition } from '../../../services/categoryAttributesService'
import { getGridScheme } from './module/getGridScheme'

const AttributesCounter = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 24px;
  background-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: ${({ theme }) => theme.space.XXS};
  margin-right: auto;
`

type Props = {
  mode: 'edit' | 'view',
  count: number,
  getRoot: () => Promise<ExtendedAttributeDefinition[]>,
  getChildren: (parentAttributeDefinitionId: string) => Promise<ExtendedAttributeDefinition[]>,
  onAddClick?: ?() => void,
  onEditClick: (id: string) => void,
  onDeleteBinding: (id: string) => void,
  onViewDetailsClick: (id: string) => void,
  getAttributeDetailsUrl: (id: string) => string,
  // eslint-disable-next-line react/boolean-prop-naming
  externalLoading: boolean,
}

export function CategoryAttributesGridView({
  mode,
  count,
  getRoot,
  getChildren,
  getAttributeDetailsUrl,
  onAddClick,
  onDeleteBinding,
  onEditClick: onEditBinding,
  onViewDetailsClick: onViewBinding,
  externalLoading,
}: Props) {
  return (
    <Flex column spaceBetween="M" minWidth={1}>
      <Box>
        <Box grow>
          <Flex spaceBetween="XS" align="center" minWidth={1}>
            All Attributes
            <AttributesCounter>{count}</AttributesCounter>
            {onAddClick && (
              <Button size="S" onClick={onAddClick}>
                + Add Attribute
              </Button>
            )}
          </Flex>
        </Box>
      </Box>

      <AsyncTree
        idKey="id"
        nameKey="name"
        parentIdKey="parentId"
        hasChildrenKey="hasChildren"
        getRootNodes={getRoot}
        getChildNodes={getChildren}
      >
        {({ data, loading }) => (
          <Table
            displaySettings={{
              showCounter: false,
              columnsSettings: getGridScheme({
                mode,
                getAttributeDetailsUrl,
                onEditBinding,
                onDeleteBinding,
                onViewBinding,
              }),
            }}
            loading={loading || externalLoading}
            data={data}
            height={400}
          />
        )}
      </AsyncTree>
    </Flex>
  )
}
