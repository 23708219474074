// @flow

import { createPresetColumnsApi, baseUri } from '@r1-webui/fmsiexportpresets'

const API_PREFIX = '/uisvc/fmsi-export'

export const presetColumnsApi = (http: $AnyObject) =>
  createPresetColumnsApi({
    http,
    urlPrefix: `${API_PREFIX}/${baseUri}`,
  })
