import * as React from 'react'

import { Table } from '@r1/grid'

import { MoneyCell } from '../Cells/MoneyCell'

const columnsSettings = [
  {
    width: 200,

    header: {
      content: 'Orders principal totals',
    },
    cell: MoneyCell('totalOrdersPrincipal'),
  },
  {
    width: 200,

    header: {
      content: 'Orders tax totals',
    },
    cell: MoneyCell('totalOrdersTax'),
  },
  {
    width: 200,

    header: {
      content: 'Orders tax withheld totals',
    },
    cell: MoneyCell('totalOrdersTaxWithheld'),
  },
  {
    width: 200,

    header: {
      content: 'Orders shipping cost totals',
    },
    cell: MoneyCell('totalOrdersShippingCost'),
  },
  {
    width: 200,

    header: {
      content: 'Orders shipping tax totals',
    },
    cell: MoneyCell('totalOrdersShippingTax'),
  },
  {
    width: 200,

    header: {
      content: 'Orders shipping tax withheld totals',
    },
    cell: MoneyCell('totalOrdersShippingTaxWithheld'),
  },
  {
    width: 200,

    header: {
      content: 'Orders total',
    },
    cell: MoneyCell('totalOrdersTotal'),
  },
]

const tableProps = {
  displaySettings: {
    showCounter: false,
    columnsSettings,
  },
}

export const TotalsBar = props => {
  return <Table {...tableProps} data={props.data} loading={props.loading} />
}
