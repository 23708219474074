import * as React from 'react'

import { DataProvider } from '../DataProvider'
import type { UserAssignRolesControllerProps } from '../../types/UserAssignRoles'
import { UserAssignRolesController as Controller } from './UserAssignRolesController'

export const UserAssignRolesController = (props: UserAssignRolesControllerProps) => {
  return (
    <DataProvider httpClient={props.httpClient} onError={props.onError}>
      {providerProps => (
        <Controller userId={props.userId} onCancelForm={props.onCancelForm} {...providerProps}>
          {formProps => props.children(formProps)}
        </Controller>
      )}
    </DataProvider>
  )
}
