// @flow

import type { AttributeType } from '../../../types/public/attributeDefinitionsCommon'

export function attributeCanHaveUnitOfMeasurement(attributeType: AttributeType) {
  return (
    attributeType === 'Decimal' ||
    attributeType === 'DecimalRange' ||
    attributeType === 'DecimalEnumeration'
  )
}
