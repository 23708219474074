import { createDiscountRulesApi } from '@r1-webui/discountsrulesmanagement-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/discount-rules-management`

export const discountsRulesApi = createDiscountRulesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
