// @flow

import * as React from 'react'
import { useNavigate } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'
import { CategoryCreationScreen } from '../../containers/CategoryContainer'

import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'

export const CategoryCreate = () => {
  const navigate = useNavigate()
  return (
    <PageAccessControl
      permissions={[PERMISSIONS.allowCategoryCreate, PERMISSIONS.allowProductEdit]}
    >
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.CATEGORIES}>Categories</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <CategoryCreationScreen
            httpClient={httpClient}
            renderTitle={ContentHeader.Title}
            renderActionsButtons={ContentHeader.ActionButtons}
            // $FlowFixMe[prop-missing]
            onSuccessfulSubmit={({ categoryId }) => navigate(`${ROUTES.CATEGORIES}/${categoryId}`)}
            onCancel={() => navigate(ROUTES.CATEGORIES)}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
