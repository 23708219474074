// @flow

import React, { memo, useCallback, useContext, useState, useEffect } from 'react'
import { FormManager } from '@r1/form-manager'
import { Flex, H2, NotificationSystem, Placeholder } from '@r1/ui-kit'
import { ServiceContext } from '../../provider'

import { clientValidate } from '../../../../utils/validation'
import { schema } from '../VariationCreate/schema'

import { BasicInfo } from '../VariationCreate/children/BasicInfo'
// import { Images } from '../VariationCreate/children/Images'
import { Settings } from '../VariationCreate/children/Settings'

import type { VariationEditProps } from '../../types/variation.type'

export const VariationEdit = memo<VariationEditProps>(props => {
  const { variationId, onSuccessfulSubmit, onCancel, children } = props

  const {
    variationsService,
    // imageService: { prepareAndUploadImages },
  } = useContext(ServiceContext)

  const [isLoading, setIsLoading] = useState(false)
  const [disabled] = useState(false)
  const [screenType, setScreenType] = useState('placeholder')
  const [variationName, setVariationName] = useState('')
  const [variationFormValues, setVariationFormValues] = useState({
    Name: '',
    // Description: null,
    ProductsFilter: {
      type: 'Filter',
      productIdentifierValues: [],
    },
    Variations: {
      attributeDefinitionIds: [],
    },
  })

  const fetchData = useCallback(async () => {
    const variation = await variationsService.getVariation(variationId)

    if (variation) {
      setVariationName(variation.Name)
      setVariationFormValues({ ...variation })
    } else {
      NotificationSystem.addNotification({ level: 'error', title: 'Variation not found' })
    }
  }, [variationId, variationsService])

  useEffect(() => {
    setScreenType('placeholder')
    fetchData().then(() => setScreenType('default'))
  }, [fetchData])

  const onSubmitCallback = useCallback(
    // eslint-disable-next-line consistent-return
    async (variation, { createSubmitError }) => {
      setIsLoading(true)

      // const
      //   status: imagesSttus,
      //   errorFields: imageErroFields,
      //   images,
      // } = await prepareAndUploadImagsfamily.Images)

      // if (imgesStatus !== 200) {
      //   setIsLoading(false)
      //   return createubmitEro(imageErrorFields)
      // }

      // variation.Images = images

      const { errorFields, status } = await variationsService.updateVariation(
        variationId,
        variation,
      )

      setIsLoading(false)

      if (status === 200) {
        NotificationSystem.addNotification({
          level: 'success',
          title: `${variation.Name} product variation was updated successfully`,
        })

        if (onSuccessfulSubmit) {
          onSuccessfulSubmit()
        }
      } else {
        NotificationSystem.addNotification({
          level: 'error',
          title: `Variation not updated`,
        })

        if (errorFields) {
          return createSubmitError(errorFields)
        }
      }
    },
    [onSuccessfulSubmit, variationId, variationsService],
  )

  const clientValidateCallback = useCallback(value => clientValidate(schema, value), [])

  const onDelete = useCallback(() => {
    if (!variationId) return
    variationsService
      .deleteVariation(variationId)
      .then(() => {
        NotificationSystem.addNotification({
          level: 'success',
          title: `Variation was deleted successfully`,
        })
        if (onCancel) {
          onCancel()
        }
      })
      .catch(error => {
        NotificationSystem.addNotification({
          level: 'error',
          title: `Variation not deleted. ${error}`,
        })
      })
  }, [onCancel, variationId, variationsService])

  switch (screenType) {
    case 'placeholder':
      return <Placeholder type="form" height={5} />
    case 'error':
      return <H2>Sorry, something went wrong</H2>
    case 'default':
      return (
        <Flex column>
          <FormManager
            initialValues={variationFormValues}
            clientValidate={clientValidateCallback}
            onSubmit={onSubmitCallback}
          >
            {({ values, getError, push, remove, handleChange, handleBlur, handleSubmit }) => (
              <>
                <BasicInfo
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                  }}
                />
                {/* <Images
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                  }}
                /> */}
                <Settings
                  {...{
                    values,
                    remove,
                    push,
                    getError,
                    handleChange,
                    handleBlur,
                    disabled,
                    variationId,
                  }}
                />
                {children({
                  isLoading,
                  variationName,
                  handlers: { handleSubmit, handleDelete: onDelete },
                })}
              </>
            )}
          </FormManager>
        </Flex>
      )
    default:
      return null
  }
})
