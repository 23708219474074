// @flow

import React, { memo, useState, useContext, useEffect, useCallback } from 'react'
import { join } from 'ramda'
import { Flex, FormField, Format, Placeholder, Box, H2, HR } from '@r1/ui-kit'
import { ServiceContext } from '../../provider'
import { Text } from '../../../../components/Category/Constraint/Text'
import type { CategoryViewProps, CategoryFormManagerValuesState } from '../../types/category.type'
import { CategoryAttributesGrid } from '../../../../components/Category/CategoryAttributesGrid'
import { PartsCategories } from '../CategoryEdit/children/PartsCategories'

const CategoryView = ({ categoryId, children, getAttributeDetailsUrl }: CategoryViewProps) => {
  const { categoryService } = useContext(ServiceContext)

  const [isLoading, setIsLoading] = useState(false)

  const [categoryFormValues, setCategoryFormValues]: CategoryFormManagerValuesState = useState({
    id: '',
    version: '',
    name: '',
    parentId: null,
    description: '',
    lastModifiedDate: '',
    hasSubcategories: false,
    fitments: [],
  })

  const fetchData = useCallback(async () => {
    const [{ error, data }, fitments] = await Promise.all([
      categoryService.getCategoryInfo({ categoryId }),
      categoryService.getDirectFitments({ categoryId }),
    ])

    if (!error) {
      setCategoryFormValues({
        ...data,
        fitments,
      })
    }
  }, [categoryId, categoryService])

  useEffect(() => {
    setIsLoading(true)
    fetchData().then(() => setIsLoading(false))
  }, [categoryId, fetchData])

  return (
    <Flex column>
      <Box>
        <H2>General Information</H2>
      </Box>
      <Box>
        <Flex column minWidth={824} maxWidth={824}>
          {isLoading ? (
            <Placeholder type="form" height={2} />
          ) : (
            <>
              <Flex column spaceBetween="XXS">
                <Flex spaceBetween="XXS">
                  <FormField.Label>Title</FormField.Label>
                  <Text>{categoryFormValues.name}</Text>
                </Flex>
                <HR />
              </Flex>
              <Flex column spaceBetween="XXS">
                <Flex spaceBetween="XXS">
                  <FormField.Label>Parent Category</FormField.Label>
                  <Text>{join(' / ', categoryFormValues.parentPath || [])}</Text>
                </Flex>
                <HR />
              </Flex>
              <Flex column spaceBetween="XXS">
                <Flex spaceBetween="XXS">
                  <FormField.Label>Description</FormField.Label>
                  {categoryFormValues.description ? (
                    <Text dangerouslySetInnerHTML={{ __html: categoryFormValues.description }} />
                  ) : (
                    <Text>Not specified</Text>
                  )}
                </Flex>
                <HR />
              </Flex>
              <Flex spaceBetween="XXS">
                <FormField.Label>Last modified</FormField.Label>
                <Text>
                  {categoryFormValues.lastModifiedDate && (
                    <Format.DateTime>{categoryFormValues.lastModifiedDate}</Format.DateTime>
                  )}
                </Text>
              </Flex>
              <PartsCategories values={categoryFormValues} />
            </>
          )}
        </Flex>
        {children({
          isLoading,
          category: categoryFormValues,
        })}
      </Box>
      <Box mt="XXL" mb="XL">
        <H2>Attributes</H2>
      </Box>
      <Box>
        <CategoryAttributesGrid
          mode="view"
          categoryId={categoryId}
          getAttributeDetailsUrl={getAttributeDetailsUrl}
        />
      </Box>
    </Flex>
  )
}

export const MemoCategoryView = memo<CategoryViewProps>(CategoryView)
