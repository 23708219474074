// @flow

import { generateTestIds } from '../../utils/generateTestIds'

export const testIds = generateTestIds({
  scope: ['attribute-creation'],
  components: {
    nameInput: 'name-input',
    typeSelect: 'type-select',
    valuesInput: 'values-input',
    unitOfMeasurementTypeSelect: 'unit-of-measurement-type-select',
    unitOfMeasurementSelect: 'unit-of-measurement-select',
    bindingTypeSelect: 'binding-type-select',
  },
})
