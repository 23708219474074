// @flow

import * as React from 'react'

import {
  Flex,
  Button,
  FormField,
  Input,
  Select,
  Radio,
  Checkbox,
  Format,
  Tooltip,
  Icon,
} from '@r1/ui-kit'

import type { OrderLineProps } from '../../types/ClaimCreation'
import { PriceDetails } from '../PriceDetails'

import {
  ActionBlock,
  Property,
  Img,
  FormFieldWrapper,
  AddNotesButtonWrapper,
  ReplacementsPickerButtonWrapper,
  ItemInfo,
  Currency,
} from './StyledComponents'

const renderSelector = (props: OrderLineProps) => {
  const { rmaProfile, orderLine, onOrderLineSelection } = props

  switch (rmaProfile.claimCreationPolicy) {
    case 'SingleLine':
      return (
        <Radio
          value
          checked={orderLine.selected}
          label="Add to Claim"
          onChange={value => onOrderLineSelection(orderLine.lineId, value)}
        />
      )
    case 'MultiLine':
      return (
        <Checkbox
          checked={orderLine.selected}
          label="Add to Claim"
          onChange={value => onOrderLineSelection(orderLine.lineId, value)}
        />
      )
    default:
      window.console.error(`Unknown claim creation policy "${rmaProfile.claimCreationPolicy}".`)
      return null
  }
}

const renderControls = (props: OrderLineProps) => {
  const {
    claimType,
    orderLine,
    returnReasons,
    onOrderLineQuantityChange,
    onOrderLineReasonChange,
    onOrderLineAmountChange,
  } = props

  switch (claimType) {
    case 'RefundOnly':
      return (
        <FormField>
          <FormField.Label>
            <Flex>
              <Flex minWidth={124} mr="XL">
                Refund:
              </Flex>
              <Flex>Reason:</Flex>
            </Flex>
          </FormField.Label>
          <FormFieldWrapper>
            <Flex minWidth={124} maxWidth={124} mr="XL" align="center">
              <Currency>{orderLine.refundAmount.currency} </Currency>
              <Input
                data-test-id="refund-amount"
                error={!!orderLine.refundAmountError}
                disabled={!orderLine.selected}
                value={orderLine.refundAmount.amount}
                onChange={value => onOrderLineAmountChange(orderLine.lineId, value)}
              />
            </Flex>
            <Select
              data-test-id="reason"
              disabled={!orderLine.selected}
              options={returnReasons}
              value={orderLine.returnReasonId}
              error={!!orderLine.returnReasonIdError}
              valueKey="id"
              labelKey="title"
              placeholder="Select Reason"
              onChange={value => onOrderLineReasonChange(orderLine.lineId, value)}
            />
          </FormFieldWrapper>
        </FormField>
      )

    case 'ReplacementOnly':
      return (
        <FormField disabled={!orderLine.selected} error={!!orderLine.returnReasonIdError}>
          <FormField.Label>
            <Flex>
              <Flex>Reason:</Flex>
            </Flex>
          </FormField.Label>
          <FormFieldWrapper>
            <Select
              options={returnReasons}
              value={orderLine.returnReasonId}
              valueKey="id"
              labelKey="title"
              placeholder="Select Reason"
              onChange={value => onOrderLineReasonChange(orderLine.lineId, value)}
            />
          </FormFieldWrapper>
        </FormField>
      )

    default:
      return (
        <FormField>
          <FormField.Label>
            <Flex>
              <Flex minWidth={74} mr="XL">
                Quantity:
              </Flex>
              <Flex>Reason:</Flex>
            </Flex>
          </FormField.Label>
          <FormFieldWrapper>
            <Flex minWidth={74} maxWidth={74} mr="XL">
              <Input
                error={!!orderLine.returnQuantityError}
                disabled={!orderLine.selected}
                value={orderLine.returnQuantity}
                onChange={value => onOrderLineQuantityChange(orderLine.lineId, value)}
              />
            </Flex>
            <Select
              disabled={!orderLine.selected}
              options={returnReasons}
              value={orderLine.returnReasonId}
              error={!!orderLine.returnReasonIdError}
              valueKey="id"
              labelKey="title"
              placeholder="Select Reason"
              onChange={value => onOrderLineReasonChange(orderLine.lineId, value)}
            />
          </FormFieldWrapper>
        </FormField>
      )
  }
}

export const OrderLine = (props: OrderLineProps) => {
  const { claimType, orderLine, onOrderLineNotesModalShow, onOrderLineReplacementsModalShow } =
    props

  const hasNotes =
    orderLine.notes &&
    (orderLine.notes.message || (orderLine.notes.images && orderLine.notes.images.length > 0))

  const hasReplacements = orderLine.replacements && orderLine.replacements.length

  return (
    <Flex mb="XL" pb="XL" justify="space-between">
      <ItemInfo justify="space-between">
        <Flex justify="flex-start">
          <Flex align="center" justify="center" minWidth={160} maxWidth={160} mr="XL">
            <Img src={orderLine.productInfo.imageUrls[0]} />
          </Flex>
          <Flex column>
            <Flex mb="M">
              <Property>Product:</Property>
              {orderLine.productInfo.title}
            </Flex>
            <Flex mb="M" align="center">
              <Property>Price:</Property>
              <Format.Money value={orderLine.lineInfo.singleItemPrice.total} />
              <Tooltip
                text={
                  <PriceDetails
                    price={orderLine.lineInfo.singleItemPrice.price}
                    tax={orderLine.lineInfo.singleItemPrice.tax}
                    shippingPrice={orderLine.lineInfo.singleItemPrice.shippingPrice}
                    shippingTax={orderLine.lineInfo.singleItemPrice.shippingTax}
                    discount={orderLine.lineInfo.singleItemPrice.discount}
                    singleItemTotal={orderLine.lineInfo.singleItemPrice.total}
                  />
                }
              >
                <Icon type="info" />
              </Tooltip>
            </Flex>
            <Flex mb="M">
              <Property>Quantity:</Property>
              {orderLine.lineInfo.quantity}
            </Flex>
          </Flex>
        </Flex>
      </ItemInfo>

      <ActionBlock column pl="XL" ml="XL" minWidth={475} maxWidth={475}>
        <Flex mb="L">{renderSelector(props)}</Flex>

        <Flex>{renderControls(props)}</Flex>

        <AddNotesButtonWrapper>
          <Button
            transparent
            disabled={!orderLine.selected}
            onClick={() => onOrderLineNotesModalShow(orderLine)}
          >
            {hasNotes
              ? `Edit Notes and Photos (${
                  orderLine.notes.images ? orderLine.notes.images.length : 0
                })`
              : '+ Add Notes and Photos'}
          </Button>
        </AddNotesButtonWrapper>
        {(claimType === 'ReplacementAfterReturn' || claimType === 'ReplacementOnly') && (
          <ReplacementsPickerButtonWrapper error={!!orderLine.replacementsError}>
            <Button
              transparent
              disabled={!orderLine.selected}
              onClick={() => onOrderLineReplacementsModalShow(orderLine)}
            >
              {hasReplacements
                ? `Edit Replacements (${
                    orderLine.replacements ? orderLine.replacements.length : 0
                  })`
                : '+ Pick Replacements'}
            </Button>
          </ReplacementsPickerButtonWrapper>
        )}
      </ActionBlock>
    </Flex>
  )
}
