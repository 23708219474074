import * as React from 'react'

import type { Lib$Optional } from '@r1/types/typescript'
import { Tooltip } from '@r1/ui-kit'

import { ActionTagStyled } from './ActionTag.styled'

type Props = {
  tipText: string
  active?: Lib$Optional<boolean, false>
  onClick?: () => void
  children: React.ReactNode
}

export const ActionTag = ({ children, tipText, active = false, onClick }: Props) => {
  return (
    <Tooltip disabled={!tipText} text={tipText}>
      <ActionTagStyled active={active} selectable={Boolean(onClick)} onClick={onClick}>
        {children}
      </ActionTagStyled>
    </Tooltip>
  )
}
