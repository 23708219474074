import * as React from 'react'
import { connect } from 'react-redux'
import { compose, withPropsOnChange } from 'recompose'
import { Field, reduxForm } from 'redux-form'
import { Flex, Text } from '@r1/ui-kit'
import * as appActions from '../../../../App/module'
import { renderInput, renderCheckbox, renderTextarea } from '../../fieldRenderer'
import * as actions from './module'

const mapStateToProps = ({ otherSettings }) => ({
  initialValues: otherSettings.model,
})

const enhance = compose(
  connect(mapStateToProps, { ...actions, ...appActions }),
  reduxForm({
    form: 'otherSettingsForm',
    onSubmit: (values, dispatch, { handleSubmit, id }) => handleSubmit(values, id),
    enableReinitialize: true,
  }),
  withPropsOnChange(['dirty'], ({ dirty, onAddDirtyTab }) => {
    if (dirty) onAddDirtyTab('other settings')
  }),
)

class OtherSettings extends React.Component {
  componentDidMount() {
    const { id, loadModel } = this.props

    loadModel(id)
  }

  render() {
    const { handleSubmit, disabled } = this.props

    return (
      <form style={{ width: '100%' }} onSubmit={handleSubmit}>
        <Flex column spaceBetween="S">
          <Field disabled label="Unsellable" name="unsellable" component={renderCheckbox} />
          <Flex mt="XL" mb="M">
            <Text weight="bold">Major classifications</Text>
          </Flex>
          <Field
            label="WM department"
            name="wmDepartment"
            disabled={disabled}
            component={renderInput}
          />
          <Field
            label="Supplier name"
            name="supplierName"
            disabled={disabled}
            component={renderInput}
          />
          <Field
            label="WM item number"
            name="wmItemNumber"
            disabled={disabled}
            component={renderInput}
          />
          <Flex mt="XL" mb="M">
            <Text weight="bold">Description</Text>
          </Flex>
          <Field
            label="Reference notes"
            name="referenceNotes"
            disabled={disabled}
            component={renderTextarea}
          />
          <Flex mt="XL" mb="M">
            <Text weight="bold">Marketplace settings & info</Text>
          </Flex>
          <Field label="TRG UPC" name="trgUpc" disabled={disabled} component={renderInput} />
          <Field
            label="Retailer item ID"
            name="retailerItemId"
            disabled={disabled}
            component={renderInput}
          />
          <Field label="WM item ID" name="wmItemId" disabled={disabled} component={renderInput} />
          <Field
            label="WM Canada item ID"
            name="wmCanadaItemId"
            disabled={disabled}
            component={renderInput}
          />
        </Flex>
      </form>
    )
  }
}

export const OtherSettingsEnhanced = enhance(OtherSettings)
