import React, { useEffect, useState } from 'react'

import { Tabs, Tab, Placeholder } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'

import { httpClient } from '../../httpClient'

type Page = {
  htmlContent: string
}

const HomePage: React.FC = () => {
  const [announcements, setAnnouncements] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchAnnouncements() {
      setIsLoading(true)
      try {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        const pages = await httpClient.get<Page[]>('/ui/api/home/pages')
        // TODO fix response on backend side
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (pages.length !== 0) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          setAnnouncements(pages[0].htmlContent)
        }
      } catch (err) {
        handleServerError(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchAnnouncements()
  }, [])

  return (
    <Main fullScreen>
      <ContentHeader.Title />
      <Content>
        <Tabs>
          <Tab id="announcements" title="Announcements">
            {isLoading ? (
              <Placeholder type="rectangle" height={300} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: announcements }} />
            )}
          </Tab>
        </Tabs>
      </Content>
    </Main>
  )
}

export { HomePage }
