import type { UnauthorizedError, CommonError } from '@r1/types/typescript'

import type { HttpClientError, ServerValidationError, UnhandledServerError } from './contracts'

export type CustomErrorHandler = (
  arg0: HttpClientError,
) => ServerValidationError | UnhandledServerError

export function createErrorHandler(
  customHandler?: CustomErrorHandler,
): (err: HttpClientError) => ServerValidationError | CommonError | UnauthorizedError {
  return err => {
    if (err.response) {
      const { status } = err.response
      if (status === 401)
        return {
          $type: 'Unauthorized',
          message: 'Unauthorized',
        }

      if (customHandler) {
        const processedError = customHandler(err)
        if (processedError.$type === 'Validation') return processedError
      }
    }

    return {
      $type: 'Common',
      payload: err,
    }
  }
}
