/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback } from 'react'
import { MarketplaceProfilesIds, RuleConstraints } from '@r1-webui/shipping-returnshippingrules-v1'

import { WeightMeasure } from '@r1-webui/discountsrulesmanagement-v1'
import { ProgramTreeItem } from '../../../services/helpers'

export const useRuleConstrainsHandlers = (
  ruleConstraints: RuleConstraints,
  onChange: (ruleConstraints: Record<string, unknown>) => void,
  weightRangeMeasure: WeightMeasure,
  salesPriceCurrency: string,
  dimensionalWeightRangeMeasure: WeightMeasure,
  maximumShippingPriceCurrency: string,
) => {
  const onChangeMarketplaces = useCallback(
    (marketplaceProfilesIds: MarketplaceProfilesIds) =>
      onChange({
        ...ruleConstraints,
        marketplaceProfilesIds,
      }),
    [onChange, ruleConstraints],
  )

  const onChangePrograms = useCallback(
    (programsIds: string[], programs: ProgramTreeItem[]) => {
      let plainIds: string[] = []

      programsIds.forEach((id: string) => {
        const isBranch = Number.isNaN(parseInt(id, 10)) // branches are not integers
        if (isBranch) {
          const nodeIds = programs
            .filter((program: ProgramTreeItem) => program.parentId === id)
            .map((program: ProgramTreeItem) => program.id)

          plainIds = plainIds.concat(nodeIds)
        } else {
          plainIds = plainIds.concat(id)
        }
      })

      onChange({
        ...ruleConstraints,
        programsIds: plainIds,
      })
    },

    [onChange, ruleConstraints],
  )

  const onChangeWeightRangeFrom = useCallback(
    (from: string | null) =>
      onChange({
        ...ruleConstraints,
        weightRange: {
          measure: weightRangeMeasure,
          ...ruleConstraints.weightRange,
          from,
        },
      }),
    [onChange, ruleConstraints, weightRangeMeasure],
  )

  const onChangeWeightRangeTo = useCallback(
    (to: string | null) =>
      onChange({
        ...ruleConstraints,
        weightRange: {
          measure: weightRangeMeasure,
          ...ruleConstraints.weightRange,
          to,
        },
      }),
    [onChange, ruleConstraints, weightRangeMeasure],
  )

  const onChangeSalesRangeFrom = useCallback(
    (from: string | null) =>
      onChange({
        ...ruleConstraints,
        salePriceRange: {
          currency: salesPriceCurrency,
          ...ruleConstraints.salePriceRange,
          from,
        },
      }),
    [onChange, ruleConstraints, salesPriceCurrency],
  )

  const onChangeSalesRangeTo = useCallback(
    (to: string | null) =>
      onChange({
        ...ruleConstraints,
        salePriceRange: {
          currency: salesPriceCurrency,
          ...ruleConstraints.salePriceRange,
          to,
        },
      }),
    [onChange, ruleConstraints, salesPriceCurrency],
  )

  const onChangeWeightDimensionFrom = useCallback(
    (from: string | null) =>
      onChange({
        ...ruleConstraints,
        dimensionalWeightRange: {
          measure: dimensionalWeightRangeMeasure,
          ...ruleConstraints.dimensionalWeightRange,
          from,
        },
      }),
    [dimensionalWeightRangeMeasure, onChange, ruleConstraints],
  )

  const onChangeWeightDimensionTo = useCallback(
    (to: string | null) =>
      onChange({
        ...ruleConstraints,
        dimensionalWeightRange: {
          measure: dimensionalWeightRangeMeasure,
          ...ruleConstraints.dimensionalWeightRange,
          to,
        },
      }),
    [dimensionalWeightRangeMeasure, onChange, ruleConstraints],
  )

  const onChangeMaximumShippingPrice = useCallback(
    (amount: string | null) =>
      onChange({
        ...ruleConstraints,
        maximumShippingPrice: {
          currency: maximumShippingPriceCurrency,
          ...ruleConstraints.maximumShippingPrice,
          amount,
        },
      }),
    [maximumShippingPriceCurrency, onChange, ruleConstraints],
  )

  const onChangeMaximumShippingPricePercent = useCallback(
    (maximumShippingPricePercent: string | null) =>
      onChange({
        ...ruleConstraints,
        maximumShippingPricePercent,
      }),
    [onChange, ruleConstraints],
  )

  return {
    onChangeMarketplaces,
    onChangePrograms,
    onChangeWeightRangeFrom,
    onChangeWeightRangeTo,
    onChangeSalesRangeFrom,
    onChangeSalesRangeTo,
    onChangeWeightDimensionFrom,
    onChangeWeightDimensionTo,
    onChangeMaximumShippingPrice,
    onChangeMaximumShippingPricePercent,
  }
}
