/* eslint-disable flowtype/no-types-missing-file-annotation */

import * as React from 'react'

import { Table } from '@r1/grid'
import { Button, Icon, Modal, Tooltip } from '@r1/ui-kit'

import { MoneyCell } from '../Cells/MoneyCell'
import { ActionsCell } from '../Cells/ActionsCell'
import { BooleanCell } from '../Cells/BooleanCell'
import { DateCell } from '../Cells/DateCell'
import { InlineModal } from './InlineModal'

const TaxCommitmentDocumentDependentDocumentIsSubmittedIssue =
  'R1.TaxCommitmentReporter.TaxCommitmentDocumentDependentDocumentIsSubmitted'
const columnsSettings = [
  {
    width: 80,
    header: {
      content: 'Type',
    },
    cell: {
      $type: 'text',
      field: 'type',
    },
  },
  {
    width: 100,
    header: {
      content: 'Date created',
    },
    cell: DateCell('createdOn'),
  },
  {
    width: 150,
    header: {
      content: 'Tax remittance system transaction',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => dataItem.taxCommitmentSystemTransactionIds.join(', '),
    },
  },
  {
    width: 90,
    header: {
      content: 'Principal amount',
    },
    cell: MoneyCell('principal'),
  },
  {
    width: 90,
    header: {
      content: 'Sales tax amount',
    },
    cell: MoneyCell('tax'),
  },
  {
    width: 90,
    header: {
      content: 'Sales tax withheld',
    },
    cell: MoneyCell('taxWithheld'),
  },
  {
    width: 90,
    header: {
      content: 'Shipping cost',
    },
    cell: MoneyCell('shippingCost'),
  },
  {
    width: 90,
    header: {
      content: 'Shipping tax amount',
    },
    cell: MoneyCell('shippingTax'),
  },
  {
    width: 90,

    header: {
      content: 'Shipping tax withheld',
    },
    cell: MoneyCell('shippingTaxWithheld'),
  },
  {
    width: 110,

    header: {
      content: 'Totals',
    },
    cell: MoneyCell('total'),
  },
  {
    width: 100,

    header: {
      content: 'Submitted',
    },
    cell: BooleanCell('submittedOn'),
  },
  {
    width: 80,

    header: {
      content: 'Locked',
    },
    cell: BooleanCell('locked'),
  },
  {
    width: 80,

    header: {
      content: 'Voided',
    },
    cell: BooleanCell('voided'),
  },
  {
    width: 150,

    header: {
      content: 'Actions',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <ActionsCell>
          <InlineModal>
            {({ show, hide, value }) => (
              <React.Fragment>
                <Button
                  disabled={!dataItem.canBeVoided}
                  size="S"
                  onClick={() => {
                    dataItem.voidIssues.length === 1 &&
                    dataItem.voidIssues[0].issue.noticeTypeCode ===
                      TaxCommitmentDocumentDependentDocumentIsSubmittedIssue
                      ? show()
                      : dataItem.voidTaxCommitmentDocument({
                          voidDependentSubmittedIssues: false,
                        })
                  }}
                >
                  Void
                </Button>
                <Modal
                  isControlled
                  show={value}
                  title="Dependent documents already submitted"
                  actionButtons={[
                    {
                      align: 'right',
                      title: 'Close',
                      color: 'secondary',
                      onClick: hide,
                    },
                    {
                      align: 'right',
                      title: 'Void',
                      color: 'danger',
                      onClick: () => {
                        dataItem.voidTaxCommitmentDocument({
                          voidDependentSubmittedIssues: true,
                        })
                        hide()
                      },
                    },
                  ]}
                  onEscapeKeyDown={hide}
                >
                  All submitted, not voided and not locked refunds will also be voided. Void?
                </Modal>
                {!dataItem.canBeVoided && (
                  <Tooltip
                    text={dataItem.voidIssues.map(issueObj => (
                      <div key={issueObj.issue.defaultPlatform}>{issueObj.issue.defaultFormat}</div>
                    ))}
                  >
                    <Icon type="info" />
                  </Tooltip>
                )}
              </React.Fragment>
            )}
          </InlineModal>
        </ActionsCell>
      ),
    },
  },
]

const tableProps = {
  displaySettings: {
    showCounter: false,
    columnsSettings,
  },
  height: 400,
}

export function TaxCommitmentGrid(props) {
  const docs = props.data.map(doc => ({
    ...doc,
    voidTaxCommitmentDocument: props.voidTaxCommitmentDocument(doc.taxCommitmentDocumentId),
  }))
  return <Table {...tableProps} data={docs} />
}
