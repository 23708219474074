// @flow

type SelectOption = { id: string | number, name: string }

export type Profile = SelectOption

export type PickUpSetting = {|
  $type: 'OrderProductPickUpSettings',
  profileIds: string[],
  pickUpStrategy: number,
|}

export type State = {|
  profiles: Profile[],
  pickUpSettings: PickUpSetting[],
  isFetching: boolean,
  isUpdating: boolean,
|}

type SET_PROFILES_TYPE = 'setProfiles'
type SET_PICKUP_SETTINGS_TYPE = 'setPickUpSettings'
type SET_IS_FETCHING_TYPE = 'setIsFetching'
type SET_IS_UPDATING_TYPE = 'setIsUpdating'

type ActionTypes = {|
  SET_PROFILES: SET_PROFILES_TYPE,
  SET_PICKUP_SETTINGS: SET_PICKUP_SETTINGS_TYPE,
  SET_IS_FETCHING: SET_IS_FETCHING_TYPE,
  SET_IS_UPDATING: SET_IS_UPDATING_TYPE,
|}

export const actionTypes: ActionTypes = {
  SET_PROFILES: 'setProfiles',
  SET_PICKUP_SETTINGS: 'setPickUpSettings',
  SET_IS_FETCHING: 'setIsFetching',
  SET_IS_UPDATING: 'setIsUpdating',
}

export type ReducerAction =
  | {|
      type: SET_PROFILES_TYPE,
      profiles: Profile[],
    |}
  | {|
      type: SET_PICKUP_SETTINGS_TYPE,
      pickUpSettings: PickUpSetting[],
    |}
  | {|
      type: SET_IS_FETCHING_TYPE,
      isFetching: boolean,
    |}
  | {|
      type: SET_IS_UPDATING_TYPE,
      isUpdating: boolean,
    |}
