import { createProfilesApi } from '@r1-webui/marketplaceprofilemanagement-provider-v1'
import { httpClient } from '../../httpClient'

const API_PREFIX = `/uisvc/marketplace-profile-management`

export const profilesApi = createProfilesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
