import * as React from 'react'

export class InlineModal extends React.Component {
  state = {
    show: false,
  }

  showModal = () => this.setState({ show: true })

  hideModal = () => this.setState({ show: false })

  render() {
    return this.props.children({
      show: this.showModal,
      hide: this.hideModal,
      value: this.state.show,
    })
  }
}
