/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { compose, withProps, withPropsOnChange } from 'recompose'
import { map, path, when, head } from 'ramda'

import { Flex, Box, Button, FormField, Input } from '@r1/ui-kit'

import { Select } from '../components/Select'

const ControlTypeNotFound = props => <span key={props.name}>Input type not found</span>

const InputSecretString = ({ name, label, value, error, autoFocus, vertical, onChange }) => (
  <Box key={name} grow maxWidth={0.5} minWidth={300}>
    <Flex spaceBetween="XXS" column={vertical}>
      <FormField.Label>{label}</FormField.Label>
      <Input
        data-test-id={label}
        autoFocus={autoFocus}
        error={!!error}
        name={name}
        placeholder="••••••"
        type="password"
        value={value}
        onChange={onChange}
      />
      <FormField.Error>{error}</FormField.Error>
    </Flex>
  </Box>
)

const InputString = ({ name, label, value, error, autoFocus, vertical, onChange }) => (
  <Box key={name} grow maxWidth={0.5} minWidth={300}>
    <Flex spaceBetween="XXS" column={vertical}>
      <FormField.Label>{label}</FormField.Label>
      <Input
        autoFocus={autoFocus}
        error={!!error}
        name={name}
        type="text"
        value={value}
        onChange={onChange}
      />
      <FormField.Error>{error}</FormField.Error>
    </Flex>
  </Box>
)

const ComboBox = ({
  httpClient,
  name,
  label,
  value,
  error,
  vertical,
  onChange,
  autocomplete,
  requestDataUrl,
  wizardState,
}) => (
  <Box key={name} grow maxWidth={0.5} minWidth={300}>
    <Flex column={vertical}>
      <FormField.Label>{label}</FormField.Label>
      <Select
        httpClient={httpClient}
        name={name}
        value={value}
        validationError={error}
        autocomplete={autocomplete}
        requestBody={{ wizardState }}
        optionsUrl={requestDataUrl}
        onChange={onChange}
      />
      <FormField.Error>{error}</FormField.Error>
    </Flex>
  </Box>
)

const ButtonControl = ({ value, label, actionData, onClick }) => (
  <Box key={value + label} minWidth={200}>
    <Button
      transparent
      name="nearestButton"
      size="L"
      value={value}
      onClick={() => onClick(value, actionData)}
    >
      {label}
    </Button>
  </Box>
)

const getControlByType = type =>
  ({
    inputString: InputString,
    inputSecretString: InputSecretString,
    comboBox: ComboBox,
  }[type] || ControlTypeNotFound)

const enhance = compose(
  withPropsOnChange(['fields'], ({ fields }) => ({
    maxLines: Math.max(...map(path(['position', 'line']), fields)),
  })),
  withProps(
    ({
      httpClient,
      wizardState,
      fields,
      maxLines,
      fieldsData,
      errors,
      onFieldChange,
      onButtonClick,
      isNewEntity,
    }) => {
      const lines = Array.from({ length: maxLines + 1 }, () => [])

      fields.forEach((field, index) => {
        const { line, order } = field.position

        if (field.action) {
          const props = {
            value: field.action,
            label: field.title,
            onClick: onButtonClick,
            actionData: field.actionData,
          }

          lines[line][order] = ButtonControl(props)
        } else {
          const props = {
            name: field.id,
            label: field.required ? `${field.title} *` : field.title,
            value: fieldsData[field.id],
            error: when(Array.isArray, head)(errors[field.id]),
            onChange: onFieldChange,
            autoFocus: index === 0,
            vertical: field.titlePosition === 'top',
            requestDataUrl: field.requestDataUrl,
            httpClient,
            isNewEntity,
            wizardState,
          }

          lines[line][order] = getControlByType(field.type)(props)
        }
      })

      return { lines }
    },
  ),
)

const Standard = ({ lines }) => (
  <Flex column spaceBetween="M">
    {lines.map((line, index) => (
      <Flex key={index} align="flex-end" spaceBetween="S">
        {line}
      </Flex>
    ))}
  </Flex>
)

const standard = enhance(Standard)

export { standard }
