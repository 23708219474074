// @flow

import * as React from 'react'
import { Button, Icon } from '@r1/ui-kit'

type Props = {|
  onClick: () => void,
|}

export const AddMessageButton = (props: Props) => {
  return (
    <Button color="secondary" onClick={props.onClick}>
      <Icon type="plus" />
      Add message
    </Button>
  )
}
