// @flow

import styled from '@emotion/styled'
import { Flex } from '@r1/ui-kit'

export const ActionBlock = styled(Flex)`
  transition: all 0.3s ease;
  @media (max-width: 1140px) {
    min-width: 400px;
    margin-left: ${({ theme }) => theme.space.M};
  }
`

export const Img = styled('img')`
  max-width: 160px;
  max-height: 153px;
  border-radius: ${({ theme }) => theme.space.XXS};

  @media (max-width: 1140px) {
    max-width: 134px;
  }
`

export const FormFieldWrapper = styled(Flex)`
  width: 100%;
`

export const AddNotesButtonWrapper = styled(Flex)`
  & {
    button {
      padding: ${({ theme }) => theme.space.S};
      font-size: ${({ theme }) => theme.space.M};
    }
  }
`

export const ReplacementsPickerButtonWrapper = styled(Flex)`
  & {
    button {
      padding: ${({ theme }) => theme.space.S};
      font-size: ${({ theme }) => theme.space.M};
      border: 2px solid ${({ error }) => (error ? 'red' : 'inherited')};
    }
  }
`

export const ItemInfo = styled(Flex)`
  width: 100%;
`

export { Property } from '../StyledComponents'

export const Currency = styled('div')`
  font-size: ${({ theme }) => theme.space.M};
  margin-right: ${({ theme }) => theme.space.S};
`
