// @flow

import {
  createCountriesApi,
  createDimensionMeasuresApi,
  createWeightMeasuresApi,
  createProductIdentifierTypesApi,
  createLockReasonsApi,
  createReviewReasonsApi,
  baseUri as productsBaseUri,
} from '@r1-webui/productcatalog-products-v1'

import { createUserApi, baseUri as userBaseUri } from '@r1-webui/userprincipalmetadataprovider-v1'

import {
  createManufacturersApi,
  createModelsApi,
  baseUri as manufacturersBaseUri,
} from '@r1-webui/productcatalog-manufacturers-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const referenceApi = (httpClient: any): WebUiApi => {
  const { getAllCountries } = createCountriesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { getLockReasons } = createLockReasonsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { searchManufacturers, searchManufacturersByIds } = createManufacturersApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${manufacturersBaseUri}`,
  })

  const { getAllDimensionMeasures } = createDimensionMeasuresApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { getAllWeightMeasures } = createWeightMeasuresApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { getProductIdentifierTypes } = createProductIdentifierTypesApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { searchModels } = createModelsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${manufacturersBaseUri}`,
  })

  const { getReviewReasons } = createReviewReasonsApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG}/${productsBaseUri}`,
  })

  const { getUsers } = createUserApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.USER_PRINCIPAL}/${userBaseUri}`,
  })

  return {
    searchModels,
    getLockReasons,
    searchManufacturers,
    searchManufacturersByIds,
    getAllCountries,
    getAllDimensionMeasures,
    getAllWeightMeasures,
    getProductIdentifierTypes,
    getReviewReasons,
    getUsers,
  }
}
