import * as React from 'react'
import { format } from 'date-fns'
import { Flex, Link, Placeholder, StructuredList } from '@r1/ui-kit'
import { StructuredListColumnSettings } from '@r1/ui-kit/contracts/ts/StructuredList'
import { MasterProgramRuleHistoryEvent } from '@r1-webui/masterprograms-v1'
import { useMasterProgramHistory } from '../../hooks/useMasterProgramHistory'

type Props = {
  masterProgramHistoryId: string
  onSelectEvent: (eventId: string) => () => void
}

export const MasterProgramHistory = ({ masterProgramHistoryId, onSelectEvent }: Props) => {
  const { masterProgramHistory, loading } = useMasterProgramHistory({
    masterProgramId: masterProgramHistoryId,
  })

  const columnSettings: StructuredListColumnSettings<MasterProgramRuleHistoryEvent>[] =
    React.useMemo(
      () => [
        {
          cell: {
            $type: 'custom',
            renderer: ({ raisedOn }) => format(new Date(raisedOn), 'yyyy-MM-dd hh:mm a'),
          },
          header: 'Changed on',
        },
        {
          cell: {
            $type: 'text',
            field: 'raisedBy',
          },
          header: 'Changed by',
        },
        {
          cell: {
            $type: 'text',
            field: 'eventType',
          },
          header: 'Action',
        },
        {
          cell: {
            $type: 'custom',
            renderer: ({ id }) => <Link onClick={onSelectEvent(id)}>Click to see details</Link>,
          },
          header: '',
        },
      ],
      [onSelectEvent],
    )

  return (
    <Flex>
      {loading ? (
        <Placeholder type="form" height={5} />
      ) : (
        <StructuredList columnsSettings={columnSettings} data={masterProgramHistory} />
      )}
    </Flex>
  )
}
