import { useEffect, useState, useCallback } from 'react'
import { uniq } from 'ramda'
import { CarrierShippingService } from '@r1-webui/shipping-carriers-v1'
import {
  getShippingProfiles,
  getThirdPartyShippingProfiles,
  Profile,
} from '../../../services/espmService'
import { getAllCarriersShippingServices } from '../../../services/carrierService'
import { DrawerType } from '../types'

const isShippingProfile = (profile: Profile) => profile.type.purpose.id === 'ShippingProfile'

const isThirdPartyProfileShippingProfile = (profile: Profile) =>
  profile.type.purpose.id === 'ThirdPartyShippingProfile'

const mapProfileOption = (profile: Profile): Profile => ({
  id: profile.id,
  type: profile.type,
  name: `(${profile.type.name}) ${profile.name}`,
})

const formatCarrierName = (carrier: CarrierShippingService) => {
  const namesArray = carrier.names.map(({ name }) => name)
  const uniqNames = uniq(namesArray).join(' / ')
  return `(${carrier.carrierId}) ${uniqNames} (${carrier.serviceCode})`
}

export type ShippingCarrier = {
  id: string
  name: string
  carrierId: string
}

export const useShippingOptions = (type: DrawerType | null) => {
  const [shippingProfiles, setShippingProfiles] = useState<Profile[]>([])
  const [areShippingProfilesLoading, setShippingProfilesLoading] = useState<boolean>(false)

  const [thirdPartyShippingProfiles, setThirdPartyShippingProfiles] = useState<Profile[]>([])
  const [areThirdPartyShippingProfilesLoading, setThirdPartyShippingProfilesLoading] =
    useState<boolean>(false)

  const [shippingCarriers, setShippingCarriers] = useState<ShippingCarrier[]>([])
  const [areShippingCarriersLoading, setShippingCarriersLoading] = useState<boolean>(false)

  const fetchProfiles = useCallback(async () => {
    setShippingProfilesLoading(true)

    const profiles = (await getShippingProfiles()).filter(isShippingProfile).map(mapProfileOption)

    setShippingProfiles(profiles)
    setShippingProfilesLoading(false)
    setThirdPartyShippingProfilesLoading(true)

    const thirdPartyProfiles = (await getThirdPartyShippingProfiles())
      .filter(isThirdPartyProfileShippingProfile)
      .map(mapProfileOption)

    setThirdPartyShippingProfiles(thirdPartyProfiles)
    setThirdPartyShippingProfilesLoading(false)
    setShippingCarriersLoading(true)

    const carriers = (await getAllCarriersShippingServices())
      .map(carrier => ({
        id: carrier.serviceCode,
        name: formatCarrierName(carrier),
        carrierId: carrier.carrierId,
      }))
      .sort((a, b) => a.name.localeCompare(b.name))

    setShippingCarriers(carriers)
    setShippingCarriersLoading(false)
  }, [])

  useEffect(() => {
    if (type) {
      fetchProfiles()
    }
  }, [fetchProfiles, type])

  return {
    shippingProfiles,
    thirdPartyShippingProfiles,
    shippingCarriers,
    areShippingProfilesLoading,
    areThirdPartyShippingProfilesLoading,
    areShippingCarriersLoading,
  }
}
