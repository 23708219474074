import { RulePriority } from '@r1-webui/shipping-returnshippingrules-v1'
import { useEffect, useState } from 'react'
import { getPriorities } from '../../../services/shippingRulesService'
import { DrawerType } from '../types'

export const usePriorities = (type: DrawerType | null) => {
  const [rulePriorities, setRulePriorities] = useState<RulePriority[]>([])
  const [arePrioritiesLoading, setLoading] = useState<boolean>(false)
  useEffect(() => {
    const fetchPriorities = async () => {
      setLoading(true)
      setRulePriorities(await getPriorities())
      setLoading(false)
    }
    if (type) fetchPriorities()
  }, [type])

  return { rulePriorities, arePrioritiesLoading }
}
