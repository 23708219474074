// @flow

import { compose, withHandlers, withState } from 'recompose'
import * as React from 'react'
import { Select, Textarea, FormField, Flex, Icon } from '@r1/ui-kit'

const formatUpcs = upcs => {
  let upcsString = ''
  if (Array.isArray(upcs) && upcs.length > 0) upcsString = upcs.join().replace(/,/g, '\n')
  return upcsString
}

const enhanceConditionMappingsItem = compose(
  // $FlowFixMe[missing-annot]
  withState('UpcValue', 'setUpc', ({ UPC }) => formatUpcs(UPC)),

  // $FlowFixMe[incompatible-use]
  withHandlers({
    onInputChange:
      ({ setUpc }) =>
      (type, newValue) => {
        switch (type) {
          case 'UPC': {
            setUpc(newValue)
            break
          }
          default:
            break
        }
      },
    onDelete:
      ({ onDeleteRow }) =>
      index => {
        onDeleteRow(index)
      },
    onSelectChange:
      ({ onChange }) =>
      (type, index, newValue) => {
        onChange({ index, type, newValue })
      },
    onInputChangeComplete:
      ({ columnData, index, onChange }) =>
      (type, newValue) => {
        onChange({ columnData, index, type, newValue })
      },
  }),
)

const ConditionMappingsItem = ({
  availableConditions,
  marketplaceConditions,
  onInputChange,
  onSelectChange,
  onDelete,
  onInputChangeComplete,
  index,
  UpcValue,
  marketplaceConditionType,
  condition,
  physicalCondition,
  technicalFunctionality,
}) => (
  <Flex spaceBetween="S">
    <FormField>
      <FormField.Label>Marketplace Condition</FormField.Label>
      <Select
        data-test-id="marketplace-condition"
        simpleValue={false}
        options={marketplaceConditions}
        width={200}
        value={marketplaceConditions.filter(c => c.name === marketplaceConditionType)[0]}
        onChange={value => onSelectChange('marketplaceConditionType', index, value)}
      />
    </FormField>
    <FormField>
      <FormField.Label>Condition</FormField.Label>
      <Select
        data-test-id="condition"
        simpleValue={false}
        width={200}
        options={availableConditions.conditions}
        value={availableConditions.conditions.filter(c => c.name === condition)[0]}
        onChange={value => onSelectChange('condition', index, value)}
      />
    </FormField>
    <FormField>
      <FormField.Label>Physical Condition</FormField.Label>
      <Select
        data-test-id="physical-condition"
        simpleValue={false}
        width={200}
        options={availableConditions.physicalConditions}
        value={availableConditions.physicalConditions.filter(c => c.name === physicalCondition)[0]}
        onChange={value => onSelectChange('physicalCondition', index, value)}
      />
    </FormField>
    <FormField>
      <FormField.Label>Technical Functionality</FormField.Label>
      <Select
        data-test-id="technical-functionality"
        simpleValue={false}
        width={200}
        options={availableConditions.technicalFunctionalities}
        value={
          availableConditions.technicalFunctionalities.filter(
            c => c.name === technicalFunctionality,
          )[0]
        }
        onChange={value => onSelectChange('technicalFunctionality', index, value)}
      />
    </FormField>
    <Flex minWidth={220}>
      <FormField>
        <FormField.Label>UPCs</FormField.Label>
        <Textarea
          data-test-id="UPC"
          value={UpcValue}
          rows={8}
          onChange={value => onInputChange('UPC', value)}
          onBlur={_ => onInputChangeComplete('UPC', UpcValue)}
        />
      </FormField>
    </Flex>
    <FormField>
      <FormField.Label>#</FormField.Label>
      <Icon type="trash" onClick={event => onDelete(index, event)} />
    </FormField>
  </Flex>
)

export default enhanceConditionMappingsItem(ConditionMappingsItem)
