import { handleServerError } from '@r1/core-blocks'
import { MasterProgramCheckResponse } from '@r1-webui/masterprograms-v1'
import { NotificationSystem } from '@r1/ui-kit'
import { masterProgramRulesApi } from '../api/MasterProgram'

export const checkMasterProgramForProduct = async (
  trgProductId: number,
): Promise<MasterProgramCheckResponse | null> => {
  const res = await masterProgramRulesApi.checkMasterProgramForProduct({
    trgProductId,
  })

  if (res.status !== 200) {
    handleServerError(res)
    return null
  }

  return res.body
}

export const deleteMasterProgramRule = async (ruleId: string): Promise<void> => {
  const res = await masterProgramRulesApi.deleteMasterProgramRule({
    ruleId,
  })

  if (res.status !== 200) {
    handleServerError(res)
    return
  }

  NotificationSystem.addNotification({
    level: 'success',
    title: 'Rule has been deleted successfully',
  })
}
