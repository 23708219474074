import { BulkActionsProps } from '@r1/grid-generator'
import { NotificationSystem } from '@r1/ui-kit'

export const getGridBulkActions = (successCallback: () => void): BulkActionsProps => {
  return {
    actions: [
      {
        $type: 'DefaultBulkAction',
        bulkActionKey: 'overrideManuallyMpActiveInventory',
        title: 'Override manually assigned MP for active inventory',
        confirmModal: {
          title: 'Override manually assigned MP for active inventory',
          text: 'Do you want to recalculate manually set master programs?',
        },
        onError: options => {
          NotificationSystem.addNotification({
            title: 'Error',
            message: options.message,
            level: 'error',
          })
        },
        onDone: _ => {
          NotificationSystem.addNotification({
            title: 'Success',
            message:
              'Master programs will be recalculated for current inventory on next processing',
            level: 'success',
          })
          successCallback()
        },
      },
      {
        $type: 'DefaultBulkAction',
        bulkActionKey: 'overrideManuallyMpAllInventory',
        title:
          'Override manually assigned MP for all (include deleted and sold products) inventory',
        confirmModal: {
          title:
            'Override manually assigned MP for all (include deleted and sold products) inventory',
          text: 'Do you want to recalculate manually set master programs?',
        },
        onError: options => {
          NotificationSystem.addNotification({
            title: 'Error',
            message: options.message,
            level: 'error',
          })
        },
        onDone: _ => {
          NotificationSystem.addNotification({
            title: 'Success',
            message: 'Master programs will be recalculated for all inventory on next processing',
            level: 'success',
          })
          successCallback()
        },
      },
      {
        $type: 'DefaultBulkAction',
        bulkActionKey: 'overrideMpAllInventory',
        title: 'Clear and recalculate MP for all inventory',
        confirmModal: {
          title: 'Clear and recalculate MP for all inventory',
          text: 'Do you want to recalculate all inventory master programs?',
        },
        onError: options => {
          NotificationSystem.addNotification({
            title: 'Error',
            message: options.message,
            level: 'error',
          })
        },
        onDone: _ => {
          NotificationSystem.addNotification({
            title: 'Success',
            message: 'Master programs will be recalculated for all inventory on next processing',
            level: 'success',
          })
          successCallback()
        },
      },
    ],
  }
}
