import { createReducer, createTypes } from '../../../../redux/utils'
import apiRoutes from '../resources/apiRoutes'

const initialState = {
  profiles: [],
}

const types = createTypes(['loadProfiles'], 'conditionMappings')

export const reducer = createReducer(initialState, {
  [types.loadProfiles]: (state, { profiles }) => ({ ...state, profiles }),
})

export function loadProfiles() {
  return async (dispatch, getState, api) => {
    const url = apiRoutes.getProfiles()

    const profiles = await api.client.get(url)

    dispatch({
      type: types.loadProfiles,
      profiles,
    })
  }
}
