// @flow

export const schema = {
  VariationId: {
    value: {
      getPath: [],
      setPath: ['id'],
    },
  },
}
