// @flow

import { NotificationSystem } from '@r1/ui-kit'
import {
  createCategoryMappingApi,
  baseUri as categoryMappingBaseUri,
} from '@r1-webui/datasourceinfo-categorymapping-v1'
import {
  createDataProvidersApi,
  baseUri as dataProvidersBaseUri,
} from '@r1-webui/globalcatalog-dataproviderinfo-v1'
import {
  createCategoryTreeApi,
  baseUri as createCategoryTreeBaseUri,
} from '@r1-webui/globalcatalog-datasourcecategories-v1'
import {
  createCategoriesApi,
  baseUri as categoriesBaseUri,
} from '@r1-webui/productcatalog-categories-public-v1'
import {
  createFilesApi,
  baseUri as fileStorageBaseUri,
} from '@r1-webui/privatefilestorage-download-v2'
import type { Mapping, FilterStatus } from '@r1-webui/datasourceinfo-categorymapping-v1/src/types'
import { httpClient as http } from '../../../../httpClient'

// $FlowFixMe[cannot-resolve-module]
import { replaceTemplateValues } from '../../../../utils/responseUtils.ts'

const categoryMappingApi = createCategoryMappingApi({
  http,
  urlPrefix: `/uisvc/data-source-info/${categoryMappingBaseUri}`,
})

const dataProvidersApi = createDataProvidersApi({
  http,
  urlPrefix: `/uisvc/data-source-info/${dataProvidersBaseUri}`,
})

const categoryTreeApi = createCategoryTreeApi({
  http,
  urlPrefix: `/uisvc/data-source-info/${createCategoryTreeBaseUri}`,
})

const categoriesApi = createCategoriesApi({
  http,
  urlPrefix: `/uisvc/company-catalog/${categoriesBaseUri}`,
})

const fileStorageApi = createFilesApi({
  http,
  urlPrefix: `/uisvc/company-catalog/${fileStorageBaseUri}`,
})

type Status = any // 200 | 400 | 500 | 401 | 403 | 705 | 404

type SetCategoryMappingProps = {
  dataProviderId: string,
  mappings: Mapping[],
}

const processResponse = ({ status, body }: { status: Status, body: any }) => {
  if (status === 200) return body

  NotificationSystem.addNotification({
    level: 'error',
    title: 'Error',
    message: replaceTemplateValues(body),
  })

  // avoid consistent-return (lint)
  return null
}

const processSetMappingResponse = ({ status, body }: { status: Status, body: any }) => {
  if (status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Success',
      message: 'Categories succesfully mapped',
    })
  } else {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: replaceTemplateValues(body),
    })
  }
}

export const getDataProviderMappings = async ({ dataProviderId }: { dataProviderId: string }) =>
  categoryMappingApi
    .getDataProviderMappings({
      dataProviderId,
    })
    .then(processResponse)

export const setCategoryMapping = async ({ dataProviderId, mappings }: SetCategoryMappingProps) =>
  categoryMappingApi
    .setCategoryMapping({ dataProviderId }, mappings)
    .then(processSetMappingResponse)

export const deleteCategoryMapping = async ({
  dataProviderId,
  mappings,
}: SetCategoryMappingProps) =>
  categoryMappingApi.deleteCategoryMapping({ dataProviderId }, mappings).then(processResponse)

export const deleteAllCategoryMappings = async ({ dataProviderId }: { dataProviderId: string }) =>
  categoryMappingApi.deleteAllCategoryMappings({ dataProviderId }).then(processResponse)

export const getAllDataProviders = async () =>
  dataProvidersApi.getAllDataProviders().then(processResponse)

export const getAllCategoryTrees = async () =>
  categoryTreeApi.getAllCategoryTrees().then(processResponse)

export const getAllTreeCategories = async ({ categoryTreeId }: { categoryTreeId: string }) =>
  categoryTreeApi.getAllCategories({ categoryTreeId }).then(processResponse)

export const getCategoryPath = async ({
  categoryTreeId,
  categoryId,
}: {
  categoryTreeId: string,
  categoryId: string,
}) => categoryTreeApi.getCategoryPath({ categoryTreeId, categoryId }).then(processResponse)

export const getAllCategories = async () => categoriesApi.getAllCategories().then(processResponse)

export const getChildsPath = async ({
  dataProviderId,
  isAllSelected,
  excludedCategoryIds,
  includedCategoryIds,
  filterByStatus,
  skip,
  take,
}: {
  dataProviderId: string,
  isAllSelected: boolean,
  excludedCategoryIds: string[],
  includedCategoryIds: string[],
  filterByStatus?: FilterStatus,
  skip: number,
  take: number,
}) =>
  categoryMappingApi
    .getChildsPath(
      { dataProviderId },
      { isAllSelected, excludedCategoryIds, includedCategoryIds, filterByStatus, skip, take },
    )
    .then(processResponse)

export const getDowloadLinkToken = async ({ categoryTreeId }: { categoryTreeId: string }) =>
  categoryTreeApi.downloadTree({ categoryTreeId }).then(processResponse)

export const getFile = async ({ fileToken }: { fileToken: string }) =>
  fileStorageApi.getFile({ fileToken })
