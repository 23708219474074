// @flow

import * as React from 'react'
import styled from '@emotion/styled'
import { FormField, Flex, HR } from '@r1/ui-kit'
import { getTextOfAttribute } from '../helpers'
import type {
  ProductAttributesProps,
  UnitAttributesProps,
} from '../../../types/public/ProductCardView'
import { NotSpecified } from './NotSpecified'

export const Text = styled('span')`
  font-style: italic;
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 16px;
  line-height: 24px;
`

export const Value = styled('span')`
  color: ${({ theme }) => theme.palette.grey[800]};
`

export const Header = styled('h4')`
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.grey[700]};
  text-transform: uppercase;
  margin: 24px 0 8px;
`

export const CollapseWrapper = styled('div')`
  margin: 12px 0;
  > a {
    font-size: 14px;
    margin: 24px 0;
    display: block;
  }
`

type AttributesProps = UnitAttributesProps | ProductAttributesProps

export const Attributes = (props: AttributesProps) => {
  const attributes = props.productAttributes || props.unitAttributes
  return (
    <React.Fragment>
      {attributes.length === 0 && <Text>There are no assigned attributes yet</Text>}
      {attributes.map((attribute, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Flex key={index} column spaceBetween="S">
            <Flex spaceBetween="XXS">
              <FormField.Label>{attribute.name}</FormField.Label>
              <Value>
                {typeof attribute.value !== 'undefined' ? (
                  getTextOfAttribute(attribute)
                ) : (
                  <NotSpecified />
                )}
              </Value>
            </Flex>
            {index !== attributes.length - 1 ? <HR /> : null}
          </Flex>
        )
      })}
    </React.Fragment>
  )
}
