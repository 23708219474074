import type { Notice } from '@r1/types/typescript'
import { getBadPropertiesErrors } from './getBadProperties'
import type {
  HttpClientError,
  ServerValidationError,
  UnhandledServerError,
  ErrorData,
} from './contracts'

export type UserCreateError = ServerValidationError | UnhandledServerError

const userCreateErrors: { [key: string]: { message: string; data?: ErrorData } } = {
  'R1.HeadOffice.RoleAlreadyExist': {
    message: 'Role already exist',
    data: {
      errors: [{ fieldName: 'name', message: 'Role already exist' }],
    },
  },
  'R1.HeadOffice.UserAlreadyExist': {
    message: 'User already exist',
    data: {
      errors: [{ fieldName: 'login', message: 'User already exist' }],
    },
  },
  'R1.HeadOffice.EmailAlreadyUsed': {
    message: 'Email is already used by another user',
    data: {
      errors: [{ fieldName: 'email', message: 'Email is already used by another user' }],
    },
  },
  'R1.HeadOffice.InvalidLogin': {
    message: 'Login is invalid',
    data: {
      errors: [{ fieldName: 'login', message: 'Login is invalid' }],
    },
  },
  'R1.HeadOffice.PasswordComplexityError': {
    message: 'Password is invalid',
    data: {
      errors: [{ fieldName: 'password', message: 'Password is invalid' }],
    },
  },
  'R1.HeadOffice.InvalidEmail': {
    message: 'Email is invalid',
    data: {
      errors: [{ fieldName: 'email', message: 'Email is invalid' }],
    },
  },
  'R1.HeadOffice.ManagerNotFound': {
    message: 'Manager not found',
    data: {
      errors: [{ fieldName: 'manager', message: 'Manager not found' }],
    },
  },
  'R1.HeadOffice.RoleNotFound': {
    message: 'Role not found',
    data: {
      errors: [{ fieldName: 'role', message: 'Role not found' }],
    },
  },
  'R1.HeadOffice.UserGroupNotFound': {
    message: 'Group not found',
    data: {
      errors: [{ fieldName: 'group', message: 'Group not found' }],
    },
  },
  'R1.HeadOffice.RoleHasPermissions': {
    message: 'The role has associated permissions',
    data: {
      errors: [{ fieldName: 'role', message: 'The role has associated permissions' }],
    },
  },
  'R1.HeadOffice.RoleHasUserGroups': {
    message: 'The role has associated groups',
    data: {
      errors: [{ fieldName: 'role', message: 'The role has associated groups' }],
    },
  },
  'R1.HeadOffice.RoleHasUsers': {
    message: 'The role has associated users',
    data: {
      errors: [{ fieldName: 'role', message: 'The role has associated users' }],
    },
  },
  'R1.HeadOffice.PrinterSettingsNotFound': {
    message: 'Printer settings not found',
  },
}

export function createErrorHandler(err: HttpClientError): UserCreateError {
  const unhandledError: UserCreateError = {
    $type: 'Unhandled',
    message: 'Unhandled error',
    data: {},
  }

  if (!err.response) return unhandledError
  const { status, data } = err.response

  switch (status) {
    case 409:
    case 422: {
      const { noticeTypeCode } = data as Notice
      return {
        $type: 'Validation',
        ...userCreateErrors[noticeTypeCode],
      }
    }

    case 400: {
      // TODO will be moved to raml generated api
      // @ts-expect-error
      return getBadPropertiesErrors(data.badProperties)
    }

    default:
      return unhandledError
  }
}
