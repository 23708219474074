// @flow

import { reverse } from 'ramda'
import { NotificationSystem } from '@r1/ui-kit'

type Entity = { Id: string, Value: string }

export function arrayToTree(plainTree: any, { maxLevel, parseItem }: any = {}) {
  const itemMap = {}
  const rootItems = []

  plainTree.forEach(({ id, ...otherProps }, index) => {
    const existingMapKey = itemMap[`#${id}`]
    const key = existingMapKey ? `_${index}` : `#${id}`

    itemMap[key] = { item: { id, ...otherProps }, children: null }
  })

  const getItemLevel = mapKey => {
    let nextNode = itemMap[mapKey]
    let level = 0

    while (nextNode && nextNode.item.parentId !== null && nextNode.item.parentId !== undefined) {
      nextNode = itemMap[`#${nextNode.item.parentId}`]
      level += 1
    }

    return level
  }

  Object.keys(itemMap).forEach(mapKey => {
    const node = itemMap[mapKey]
    const { item } = node
    const { parentId } = item

    if (maxLevel && getItemLevel(mapKey) > maxLevel) {
      throw new Error(`Tree must be maximum ${maxLevel} level deep`)
    }

    if (parseItem) {
      node.item = parseItem(item)
    }

    if (parentId !== null && parentId !== undefined) {
      const parentNode = itemMap[`#${parentId}`]

      if (parentNode.children) {
        parentNode.children.push(node)
      } else {
        parentNode.children = [node]
      }
    } else {
      rootItems.push(node)
    }
  })

  return rootItems
}

export function getArrayDiff(oldArr: any, newArr: any, comparer: any) {
  const added = newArr.filter(newItem => !oldArr.find(oldItem => comparer(newItem, oldItem)))
  const removed = oldArr.filter(oldItem => !newArr.find(newItem => comparer(newItem, oldItem)))

  return { added, removed }
}

export function removeObjPropertyNested(object: Entity, property: string) {
  for (const [key, value] of Object.entries(object)) {
    if (key === property) {
      // eslint-disable-next-line no-param-reassign
      delete object[property]
    }
    if (typeof value === 'object' && value instanceof Object) {
      removeObjPropertyNested(value, property)
    }
  }
  return object
}

function checkUpcChecksum(strUpc: string) {
  let result = 0
  const numberForCheck = strUpc.substring(0, strUpc.length - 1)

  // $FlowFixMe[incompatible-call]
  const rs = reverse(numberForCheck)
  for (let counter = 0; counter < rs.length; counter += 1) {
    result += parseInt(rs.charAt(counter), 10) * 3 ** ((counter + 1) % 2)
  }
  return (10 - (result % 10)) % 10 === Number(strUpc[strUpc.length - 1])
}

export function transformAndCheckId(value: string, existType: string) {
  let type = ''
  const isValid = Number.isInteger(Number(value)) && checkUpcChecksum(value)
  const lengthToType = {
    '10': 'ISBN',
    '12': 'UPC',
    '13': 'EAN',
    '14': 'GTIN',
  }

  if (isValid) {
    type = lengthToType[value.length]
  } else if (value.length === 10) {
    type = 'ASIN'
  } else if (existType) {
    type = existType
  }

  if (!type) {
    NotificationSystem.addNotification({
      level: 'error',
      title: 'Error',
      message: 'Incorrect identifier(s)',
    })
    throw new Error('Incorrect identifier(s)')
  }

  return { type, value }
}

export const formatDateToString = (date: Date) => {
  if (!date) return ''

  return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}-${date.getFullYear().toString().padStart(4, '0')} ${date
    .getHours()
    .toString()
    .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`
}
