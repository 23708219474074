// @flow

import {
  createProductManagementApi,
  baseUri as productManagementBaseUri,
} from '@r1-webui/productcatalog-productmanagement-v1'
import { httpClient } from '../../httpClient'

export const productManagementApi = createProductManagementApi({
  http: httpClient,
  urlPrefix: `/uisvc/company-catalog-management/${productManagementBaseUri}`,
})
