import * as React from 'react'
import { TimeZone } from '@r1-webui/usermanagement-v1'
import { showNotification, Notification } from '../../../utils/notifications/showNotification'
import { usersApi } from '../../../api/users'

export const useTimezones = () => {
  const [timezones, setTimezones] = React.useState<Array<TimeZone> | null>(null)

  React.useEffect(() => {
    const countriesErrorMessage: Notification = {
      level: 'error',
      message: 'Failed to load timezones',
    }

    usersApi
      .getTimeZones()
      .then(({ body, status }) => {
        if (status !== 200) {
          showNotification(countriesErrorMessage)
        } else {
          setTimezones(body)
        }
      })
      .catch(() => {
        showNotification(countriesErrorMessage)
      })
  }, [])

  return { timezones }
}
