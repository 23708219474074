import React from 'react'
import { Text } from '@r1/ui-kit'

export const EdgeActiveOfferInfo = React.memo<{ qty?: number; price?: string }>(
  ({ qty, price }) => {
    if (!qty && !price) return <Text>-</Text>

    return (
      <Text>
        {qty ? `${qty} ` : ''}
        {price ? `$${price}` : ''}
      </Text>
    )
  },
)
