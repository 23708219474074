import { Action } from '../constants'

const initialState = []

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case Action.LOAD_TEMPLATE_MODELS:
      return action.templateModels

    default:
      return state
  }
}
