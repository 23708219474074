// @flow

import React, { useState, useEffect } from 'react'
import { has } from 'ramda'
import { Table } from '@r1/grid'
import { handleServerError } from '@r1/core-blocks'

import { Label } from '../Label'
import { TrackingNumber } from '../TrackingNumber'

const columnsSettings = [
  {
    header: {
      content: 'Tracking Number',
    },
    cell: {
      $type: 'custom',
      renderer: ({ trackingNumber }, index) =>
        trackingNumber && <TrackingNumber index={index} trackingNumber={trackingNumber} />,
    },
  },
  {
    header: {
      content: 'Label',
    },
    cell: {
      $type: 'custom',
      renderer: ({ label, trackingNumber }, index) =>
        label && <Label index={index} label={label} trackingNumber={trackingNumber} />,
    },
  },
]

const tableProps = {
  displaySettings: {
    showCounter: false,
    columnsSettings,
  },
}

const hasLabelId = has('labelId')

type Props = {|
  api: any,
  claimId: string,
|}

export const ReturnGrid = ({ api, claimId }: Props) => {
  const [gridData, setGridData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const getLabel = async labelId => {
      const response = await api.labels.getLabelById({ labelId })
      if (response && response.status === 200) return response.body
    }

    setLoading(true)

    const fetchData = async () => {
      const response = await api.claims.getReturnLabelData({ claimId })

      if (response && response.status === 200) {
        const gridDataWithLabel = await Promise.all(
          response.body.map(async el =>
            hasLabelId(el) ? { ...el, label: await getLabel(el.labelId) } : el,
          ),
        )

        setGridData(gridDataWithLabel)
        setLoading(false)
      } else {
        handleServerError(response)
        setLoading(false)
      }
    }

    fetchData()
  }, [api.claims, claimId])

  return <Table loading={loading} {...tableProps} data={gridData} />
}
