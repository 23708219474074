// @flow

import type { WebUiApi } from '../api/attributes/types'

export const attributesService = (api: WebUiApi) => {
  const findAllAttributeDefinitions = async () =>
    api
      .findAllAttributeDefinitions()
      .then(response => (response.status === 200 ? response.body : []))

  return {
    findAllAttributeDefinitions,
  }
}
