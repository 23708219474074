import React from 'react'
import { connect } from 'react-redux'
import { compose, mapProps, renameProp } from 'recompose'
import { pathOr, evolve, when, is } from 'ramda'
import { TemplateAttributesForm } from '@r1/core-blocks'

import { httpClient } from '../../../../../httpClient'

const mapStateToProps = state => ({
  categoryId: pathOr(null, ['productTemplate', 'categoryId'], state),
})

const enhance = compose(
  EnhancedComp => React.forwardRef((props, ref) => <EnhancedComp {...props} forwardedRef={ref} />),
  connect(mapStateToProps),
  mapProps(evolve({ categoryId: when(is(Number), String) })),
  renameProp('id', 'productTemplateId'),
)

const TemplateAttributesFormWrapper = ({
  productTemplateId,
  categoryId,
  onAddDirtyTab,
  disabled,
  forwardedRef,
}) => {
  return (
    <TemplateAttributesForm
      ref={forwardedRef}
      httpClient={httpClient}
      productTemplateId={productTemplateId}
      categoryId={categoryId}
      getCategoryId={() => categoryId}
      disabled={disabled}
      onFormDefaultStateChange={() => onAddDirtyTab('attributes')}
    />
  )
}

export default enhance(TemplateAttributesFormWrapper)
