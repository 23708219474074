import React, { useMemo, useCallback, useState } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'

import { Content, Main, ContentHeader, useBackgroundTasksProvider } from '@r1/wireframe-primary'
import type { BottomActionButton } from '@r1/ui-kit/contracts/ts/common/common'
import { Flex, Box, Modal, Button, Link, Checkbox, NotificationSystem, Icon } from '@r1/ui-kit'
import { PageAccessControl, useAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'

import { UserHistoryModal } from '../../components'
import { httpClient } from '../../../../httpClient'
import { ROUTES } from '../../../../navigation'

const USER_ID_FIELD = 'public_id'

const Paragraph = styled('p')`
  margin: 10px 0;
  color: ${({ theme }) => theme.palette.grey[700]};
`

const ModalText = (
  <Paragraph>
    Pay attention that by duplicating this user, you will also copy old settings of this user.
    Please make sure that you understand it before continuing.
  </Paragraph>
)

const warningText =
  'Unable to find user with corresponding id. You will be redirected to user CREATION page without duplicated properties'
const ModalLinkText = 'Go to old settings of the user'
const CheckboxText = 'Do not show it again'

const usersGridStorageApi = {
  isConfirmationModalSuppressed: () => {
    return Boolean(localStorage && localStorage.getItem('users-grid:suppress_confirmation'))
  },
  suppressConfirmationForCurrentUser: () => {
    if (localStorage) {
      localStorage.setItem('users-grid:suppress_confirmation', 'true')
    }
  },
  discardConfirmationSettingForCurrentUser: () => {
    if (localStorage) {
      localStorage.removeItem('users-grid:suppress_confirmation')
    }
  },
}

const ActionButton = ({
  redirectToFamilyCreatePage,
}: {
  redirectToFamilyCreatePage: () => void
}) => (
  <Button data-test-id="um-add-new-user-btn" onClick={redirectToFamilyCreatePage}>
    <Icon type="plus" color="inverted" />
    Add new
  </Button>
)

export const ViewUsersGrid = () => {
  const navigate = useNavigate()
  const initialStateQuery = useMemo(parseQuery, [])
  const [isModalShown, toggleModal] = useState(false)
  const [activePublicId, setActivePublicId] = useState<string | null | undefined>(null)
  const [confirmationCheckboxValue, setConfirmationCheckboxValue] = useState(
    usersGridStorageApi.isConfirmationModalSuppressed(),
  )
  const [historyModalState, setHistoryModalState] = useState<{ userId: string } | null>(null)
  const [{ allowUserCreate }] = useAccessControl()
  const { showBackgroundTasks } = useBackgroundTasksProvider()

  const redirectToUserCreatePage = useCallback(() => navigate(ROUTES.USER_CREATE), [navigate])

  const redirectToUserDuplicationPage = useCallback(
    (id?: string | null) => {
      if (id) navigate(ROUTES.USER_DUPLICATE.replace(':userId', id))
    },
    [navigate],
  )
  const confirmDuplication = useCallback(
    (id?: string | null) => {
      if (!id) {
        NotificationSystem.addNotification({
          level: 'warning',
          message: warningText,
        })
        redirectToUserCreatePage()
      }

      if (confirmationCheckboxValue) {
        setActivePublicId(id)
        redirectToUserDuplicationPage(id)
      } else {
        setActivePublicId(id)
        toggleModal(true)
      }
    },
    [confirmationCheckboxValue, redirectToUserCreatePage, redirectToUserDuplicationPage],
  )

  const cancelDuplication = useCallback(() => {
    toggleModal(false)
    setActivePublicId(null)
  }, [])

  const toggleConfirmationCheckbox = useCallback(() => {
    if (confirmationCheckboxValue === true) {
      setConfirmationCheckboxValue(false)
      usersGridStorageApi.discardConfirmationSettingForCurrentUser()
    }

    if (confirmationCheckboxValue === false) {
      setConfirmationCheckboxValue(true)
      usersGridStorageApi.suppressConfirmationForCurrentUser()
    }
  }, [confirmationCheckboxValue])

  const getShowHistoryModalCallback = useCallback(
    (userId: string) => () => {
      setHistoryModalState({ userId })
    },
    [],
  )

  const closeHistoryModal = useCallback(() => setHistoryModalState(null), [])

  const customCellRenderer = useCallback(
    (data: Record<string, unknown>) => (
      <>
        <Link onClick={() => confirmDuplication(String(data[USER_ID_FIELD]))}>Duplicate</Link>
        &nbsp;/&nbsp;
        <Link onClick={getShowHistoryModalCallback(String(data[USER_ID_FIELD]))}>View History</Link>
      </>
    ),
    [confirmDuplication, getShowHistoryModalCallback],
  )

  const modalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: cancelDuplication,
        align: 'right',
      },
      {
        title: 'I understand and confirm',
        onClick: () => redirectToUserDuplicationPage(activePublicId),
        align: 'right',
      },
    ],
    [activePublicId, cancelDuplication, redirectToUserDuplicationPage],
  )

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowUserView]}>
      <Main fullScreen>
        <ContentHeader />
        <ContentHeader.Title>Users</ContentHeader.Title>
        <Content>
          <Flex column>
            <GridGenerator
              exportable
              gridId="users"
              gridEngineBaseUrl="/uisvc/user-management-grid"
              initialStateQuery={initialStateQuery}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              httpClient={httpClient}
              aggregationCount={{
                title: 'All Users',
              }}
              initialCustomColumnsSettings={[
                {
                  columnId: 'Actions',
                  cell: {
                    $type: 'custom',
                    renderer: customCellRenderer,
                  },
                },
              ]}
              toolbarActionButtons={
                allowUserCreate && (
                  <ActionButton redirectToFamilyCreatePage={redirectToUserCreatePage} />
                )
              }
              onOpenBackgroundTasks={showBackgroundTasks}
              onChangeState={setQuery}
            />
          </Flex>
          <Modal
            isControlled
            show={isModalShown}
            title="Different settings between new and old user interfaces"
            actionButtons={modalActionButtons}
            onEscapeKeyDown={cancelDuplication}
          >
            <Flex column>
              <Box>{ModalText}</Box>
              <Box mt="XXS" mb="XXS">
                {activePublicId && (
                  <Link href={`/Modules/Admin/UserDetails.aspx?id=${activePublicId}`}>
                    {ModalLinkText}
                  </Link>
                )}
              </Box>
              <Flex>
                <Box mr="XS">
                  <Checkbox
                    checked={confirmationCheckboxValue}
                    onChange={toggleConfirmationCheckbox}
                  />
                </Box>
                <Flex align="center">{CheckboxText}</Flex>
              </Flex>
            </Flex>
          </Modal>
          {historyModalState && (
            <UserHistoryModal
              isControlled
              show // to init fetch on componentDidMount
              userId={historyModalState.userId}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              httpClient={httpClient}
              onClose={closeHistoryModal}
            />
          )}
        </Content>
      </Main>
    </PageAccessControl>
  )
}
