// @flow

import * as React from 'react'
import { useMemo } from 'react'
import styled from '@emotion/styled'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Select, Input, Button, Modal, Flex, Text } from '@r1/ui-kit'
import { enhance } from '../module/enhance'
import type { ConsigneeUPCItemProps, ConsigneeUPCItemPublicProps } from '../types'
import Classifications from './Classifications'

const ItemContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.palette.grey[100]};
`

const ConsigneeUPCItemComponent = ({
  show,
  onToggleShow,
  consigneeUpc,
  onChangeConsigneeUPC,
  classifications,
  classificationsMeta,
  onSaveConsigneeUpc,
  onDeleteConsigneeUpc,
  selectedMarketplaces,
  marketplacesMeta,
  onChangeMarketplaces,
  selectedWarehouses,
  selectedExWarehouses,
  onChangeIncludeWarehouses,
  onChangeExcludeWarehouses,
  index,
  disabled,
  onChangeClassifications,
  allWarehouses,
}: ConsigneeUPCItemProps) => {
  const deleteModalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'NO, CANCEL',
        color: 'secondary',
        onClick: () => onToggleShow(false),
        align: 'right',
      },
      {
        title: 'YES, DELETE IT',
        color: 'danger',
        onClick: () => {
          onDeleteConsigneeUpc(consigneeUpc, index)
          onToggleShow(false)
        },
        align: 'right',
      },
    ],
    [onToggleShow, onDeleteConsigneeUpc, consigneeUpc, index],
  )

  return (
    <>
      <ItemContainer column p="S" spaceBetween="XL">
        <Flex align="center" spaceBetween="S" maxWidth={400}>
          <Text type="paragraph">{index + 1}</Text>
          <Text>Consignee UPC:</Text>
          <Input disabled={disabled} value={consigneeUpc} onChange={onChangeConsigneeUPC} />
        </Flex>

        <Flex flexWrap spaceBetween="L">
          <Flex column spaceBetween="XS" minWidth={300}>
            <Text>Warehouses (to include)</Text>
            <Select
              multiple
              disabled={disabled}
              placeholder="Choose warehouse(s)"
              value={selectedWarehouses}
              options={allWarehouses}
              onChange={onChangeIncludeWarehouses}
            />
          </Flex>

          <Flex column spaceBetween="XS" minWidth={300}>
            <Text>Warehouses (to exclude)</Text>
            <Select
              multiple
              disabled={disabled}
              placeholder="Choose warehouse(s)"
              value={selectedExWarehouses}
              options={allWarehouses}
              onChange={onChangeExcludeWarehouses}
            />
          </Flex>

          <Flex column spaceBetween="XS" minWidth={300}>
            <Text>Marketplaces</Text>
            <Select
              multiple
              disabled={disabled}
              placeholder="Choose marketplace(s)"
              value={selectedMarketplaces}
              options={marketplacesMeta}
              onChange={onChangeMarketplaces}
            />
          </Flex>
        </Flex>

        <Classifications
          disabled={disabled}
          classifications={classifications}
          classificationsMeta={classificationsMeta}
          onChange={onChangeClassifications}
        />

        <Flex spaceBetween="S">
          <Button disabled={disabled} onClick={onSaveConsigneeUpc}>
            Save consignee UPC
          </Button>

          <Button color="danger" disabled={disabled} onClick={() => onToggleShow(true)}>
            Delete
          </Button>
        </Flex>
      </ItemContainer>

      <Modal
        isControlled
        show={show}
        actionButtons={deleteModalActionButtons}
        onEscapeKeyDown={() => onToggleShow(false)}
      >
        Are you sure you want to delete?
      </Modal>
    </>
  )
}

export const ConsigneeUPCItem: React$ComponentType<ConsigneeUPCItemPublicProps> =
  enhance(ConsigneeUPCItemComponent)
