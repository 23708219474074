// @flow

import type { DataProvider } from '@r1-webui/globalcatalog-dataproviderinfo-v1/src/types'
import type { DataSource } from '@r1-webui/globalcatalog-datasourceinfo-v1/src/types'
import type { WebUiApi as PriceApi } from '../api/price/types'

type DataProviders = {| ...DataProvider, dataSource: ?DataSource |}

export const priceService = (api: PriceApi) => {
  const getPricingRulesInfo = async () =>
    api.getPricingRuleInfos().then(response => (response.status === 200 ? response.body : []))

  const getPricingRuleDetail = async (pricingRuleId: string) =>
    api
      .getPricingRule({ pricingRuleId })
      .then(response => (response.status === 200 ? response.body : []))

  const getAllDataSources = async () =>
    api.getAllDataSources().then(response => (response.status === 200 ? response.body : []))

  const getAllDataProviders = async (): Promise<DataProviders[]> => {
    const dataProviders: DataProvider[] = await api
      .getAllDataProviders()
      .then(response => (response.status === 200 ? response.body : []))

    const dataSources: DataSource[] = await getAllDataSources()

    return dataProviders.map(value => ({
      ...value,
      dataSource: dataSources.find(({ id }) => id === value.dataSourceId),
    }))
  }

  return {
    getPricingRulesInfo,
    getPricingRuleDetail,
    getAllDataProviders,
    getAllDataSources,
  }
}
