// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Flex, Link, Text } from '@r1/ui-kit'
import { GenericIcon } from '@r1/core-blocks'
import { ROUTES } from '../../../../../navigation'

type PurposeProps = {
  purposeId: string | null,
  items: Array<any>,
  getProfileCreationUrl: (profileId: string, profileTypeId: string) => string,
}

const Item = styled(Link)`
  padding: 0 10px 10px 0;
  text-align: center;
  text-decoration: none;

  &:last-child {
    padding-right: 0;
  }
`

const Thumbnail = styled('button')`
  display: flex;
  width: 132px;
  height: 82px;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  border-radius: 2px;
  background-color: ${({ theme: { palette } }) => palette.white};
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.05);
  border: ${({ theme: { palette } }) => `solid 1px ${palette.grey[100]}`};
  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }

  &:active {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  }

  background: none;
`

export const ProfileTemplatesCard = ({ purposeId, items, getProfileCreationUrl }: PurposeProps) => {
  const viewItems = React.useMemo(() => {
    const viewItemsList = []

    // https://jira.trgdev.com/browse/WLM-4157
    const hasDl = items.some(({ id }) => id === 'DL')
    if (hasDl) {
      viewItemsList.push(
        <Item key="gws-id" href={ROUTES.GOWHOLESALE_ROOT}>
          <Thumbnail type="button">
            <GenericIcon width={110} height={75} svgId="gwsLogo" />
          </Thumbnail>
          <Text color="#848484">goWholesale</Text>
        </Item>,
      )
    }

    if (items) {
      items.forEach(({ id, image, name }) => {
        if (id) {
          viewItemsList.push(
            <Item
              key={id}
              data-test-id="profile-template-card-item"
              href={getProfileCreationUrl(purposeId || '', id)}
            >
              <Thumbnail type="button">
                {image && <GenericIcon width={110} height={75} svgId={image.value} />}
              </Thumbnail>
              <Text color="#848484">{name}</Text>
            </Item>,
          )
        }
      })
    }

    return viewItemsList
  }, [getProfileCreationUrl, items, purposeId])

  if (viewItems.length === 0) return null

  return <Flex flexWrap>{viewItems}</Flex>
}
