import * as React from 'react'
import * as R from 'ramda'
import { Control, useController } from 'react-hook-form'
import { Period as PeriodType, Shift as ShiftType } from '@r1-webui/facilities-v1'
import { Box, Button, Flex, FormField, Icon, Input, Text } from '@r1/ui-kit'
import { Value } from '../../Value'
import { Period } from '../Period'

export const Shift = ({
  shift,
  control,
  editable,
  indexPrefix,
  onRemoveShift,
}: {
  shift: ShiftType
  control: Control
  editable?: boolean
  indexPrefix: number
  onRemoveShift: () => void
}) => {
  const formPrefix = 'shifts'
  const {
    field: name,
    formState: { errors },
  } = useController({
    name: `${formPrefix}.${indexPrefix}.name`,
    control,
    defaultValue: shift.name,
  })
  const { field: periodsField } = useController({
    name: `${formPrefix}.${indexPrefix}.periods`,
    control,
    defaultValue: shift.periods,
  })

  const periodsValue = React.useMemo(() => {
    return periodsField.value as PeriodType[]
  }, [periodsField.value])

  const onAddPeriod = React.useCallback(() => {
    const newPeriod = {
      startDay: '',
      from: '09:00 AM',
      finishDay: '',
      to: '09:00 PM',
    }
    const updatedPeriods = [...periodsValue, newPeriod]
    periodsField.onChange(updatedPeriods)
  }, [periodsField, periodsValue])

  const onRemovePeriod = React.useCallback(
    (index: number) => () => {
      const updatedPeriods = periodsValue.filter((_, idx) => idx !== index)
      periodsField.onChange(updatedPeriods)
    },
    [periodsField, periodsValue],
  )

  const dataTestIdPrefix = `facility__shift-${indexPrefix}`

  return (
    <Flex column>
      <Flex spaceBetween="XS" align="center">
        <FormField>
          <FormField.Label>Shift name</FormField.Label>
          {editable ? (
            <Input {...name} data-test-id={`${dataTestIdPrefix}__name`} />
          ) : (
            <Value>{shift.name}</Value>
          )}
          <FormField.Error>
            {R.pathOr(null, [formPrefix, indexPrefix, 'name', 'message'], errors)}
          </FormField.Error>
        </FormField>
        {editable && (
          <Box mt="XS">
            <Button
              transparent
              shape="square"
              color="secondary"
              data-test-id={`${dataTestIdPrefix}__remove`}
              onClick={onRemoveShift}
            >
              <Icon type="clear" size="S" />
            </Button>
          </Box>
        )}
      </Flex>
      <Flex column>
        {editable && periodsValue.length === 0 && (
          <Flex align="center" justify="flex-end">
            <Box>
              <Text>No periods were set for the shift. Tap + to add.</Text>{' '}
            </Box>
            <Box ml="XS">
              <Button
                transparent
                shape="square"
                color="secondary"
                data-test-id={`${dataTestIdPrefix}__add-period`}
                onClick={onAddPeriod}
              >
                <Icon type="plus" size="S" />
              </Button>
            </Box>
          </Flex>
        )}
        {periodsValue.map((period, idx) => (
          <Period
            key={`${period.from}-${period.to}-${idx + 1}`}
            period={period}
            control={control}
            editable={editable}
            indexPrefix={idx}
            dataTestIdPrefix={dataTestIdPrefix}
            formPrefix={`${formPrefix}.${indexPrefix}.periods`}
            onAddPeriod={onAddPeriod}
            onRemovePeriod={onRemovePeriod(idx)}
          />
        ))}
      </Flex>
    </Flex>
  )
}
