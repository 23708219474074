// @flow

import * as React from 'react'

import { Exporter } from './Exporter'
import { ExportSettingsView } from './ExportView'

import type { ExportConfig, Export, FileFormat } from './types'

export type ExportContext<Filter: {}> = $Exact<{
  startExport: Filter => void,
  exportInProgress: boolean,
  exports: Array<Export> | null,
  error: boolean,
}>

type State<Filter: {}> = {
  filter: Filter | null,
  fileFormat: FileFormat,
}

type ExportHOC<Filter: {}, OwnProps> = (
  React.ComponentType<{ ...$Exact<OwnProps>, ...ExportContext<Filter> }>,
) => React.ComponentType<OwnProps>

export function withExport<Filter: {}, Props: {}>(
  configFactory: Props => ExportConfig<Filter>,
): ExportHOC<Filter, Props> {
  return Component =>
    class extends React.Component<Props, State<Filter>> {
      config: ExportConfig<Filter>

      state = {
        filter: null,
        fileFormat: 'XLSX',
      }

      constructor(props: Props) {
        super(props)
        this.config = configFactory(props)
      }

      closeExport = () => {
        this.setState({ filter: null })
      }

      setFilter = (filter: Filter) => {
        this.setState({ filter })
      }

      setFileFormat = fileFormat => {
        this.setState({ fileFormat })
      }

      render() {
        return (
          <Exporter exportConfig={this.config}>
            {({ doExport, ...exportProps }) => (
              <React.Fragment>
                <Component {...this.props} {...exportProps} startExport={this.setFilter} />

                <ExportSettingsView
                  isVisible={!!this.state.filter}
                  fileFormat={this.state.fileFormat}
                  setFileFormat={this.setFileFormat}
                  isExportInProgress={exportProps.exportInProgress}
                  doExport={() => {
                    const { filter, fileFormat } = this.state
                    if (filter) {
                      doExport({
                        filter,
                        fileFormat,
                      })
                    }
                  }}
                  onClose={this.closeExport}
                />
              </React.Fragment>
            )}
          </Exporter>
        )
      }
    }
}
