// @flow

import * as R from 'ramda'
import { replaceTemplateValues } from '../../../../utils'

const getErrorValue = (errorValue: string, message: string) => {
  return errorValue ? { [errorValue]: message } : message
}

export const serializeErrors = <O: $ReadOnlyArray<Object> | Object, S: Object>(
  data: O,
  schema: S,
  parentKey?: string,
): any => {
  // eslint-disable-next-line array-callback-return, consistent-return
  return Object.keys(schema).reduce((acc, key) => {
    if (key === 'value') {
      if (Array.isArray(data)) {
        return data.reduce((arr, { error, index }) => {
          const res = [...arr]
          res[index] = getErrorValue(schema[key].errorValue, replaceTemplateValues(error))

          return res
        }, [])
      } else if (Object.prototype.hasOwnProperty.call(data, parentKey)) {
        return getErrorValue(schema[key].errorValue, replaceTemplateValues(data[parentKey].error))
      } else if (
        schema[key].setPath &&
        Object.prototype.hasOwnProperty.call(data, schema[key].setPath[0])
      ) {
        return getErrorValue(
          schema[key].errorValue,
          replaceTemplateValues(data[schema[key].setPath[0]].error),
        )
      }
    } else {
      const serialized = serializeErrors(data, schema[key], key)

      return !serialized || R.isEmpty(serialized) ? acc : Object.assign(acc, { [key]: serialized })
    }
  }, {})
}
