import { handleServerError } from '@r1/core-blocks'
import { createHistoryApi } from '@r1-webui/usermanagement-v1'

import type { Lib$Id } from '@r1/types/typescript'
import { httpClient } from '../../../../../../httpClient'
import { RoleId } from '../types'

export const API_PREFIX = '/uisvc/user-management'

const roleHistoryApi = createHistoryApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})

export async function getRoleHistoryReceivingToken(roleId: RoleId): Promise<Lib$Id> {
  const resp = await roleHistoryApi.getRoleHistoryReceivingToken({ roleId })
  if (resp.status === 200) {
    return resp.body.token
  }
  handleServerError(resp)
  return ''
}
