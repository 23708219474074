function decamelize(text: string, { separator = '_' } = {}) {
  if (!(typeof text === 'string' && typeof separator === 'string')) {
    throw new TypeError('The `text` and `separator` arguments should be of type `string`')
  }

  // Checking the second character is done later on. Therefore process shorter strings here.
  if (text.length < 2) {
    return text
  }

  const replacement = `$1${separator}$2`

  // Split lowercase sequences followed by uppercase character.
  // `dataForUSACounties` → `data_For_USACounties`
  // `myURLstring → `my_URLstring`
  const decamelized = text.replace(
    /([\p{Lowercase_Letter}\d])(\p{Uppercase_Letter})/gu,
    replacement,
  )

  // Remaining uppercase sequences will be separated from lowercase sequences.
  // `data_For_USACounties` → `data_For_USA_Counties`
  const separated = decamelized.replace(
    /(\p{Uppercase_Letter}+)(\p{Uppercase_Letter}\p{Lowercase_Letter}+)/gu,
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    (_, $1, $2) => $1 + separator + $2,
  )

  return separated
}

export function humanizeString(string: string) {
  if (typeof string !== 'string') {
    throw new TypeError('Expected a string')
  }
  let humanizedString = string

  humanizedString = decamelize(humanizedString)
  humanizedString = humanizedString
    // Replace snake and kebab cases to space
    .replace(/[_-]+/g, ' ')
    // Replace double spaces to single space
    .replace(/\s{2,}/g, ' ')
    // Format prepositions. Example: data For USA Countries At Night => data for USA Countries at Night
    .replace(/\s*(Of|For|At)\s*/g, (_, $1) => {
      // eslint-disable-next-line prefer-template, @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      return ' ' + $1.charAt(0).toLowerCase() + $1.slice(1) + ' '
    })
    .trim()
  humanizedString = humanizedString.charAt(0).toUpperCase() + humanizedString.slice(1)

  return humanizedString
}
