// @flow

import { css } from '@emotion/css'
import styled from '@emotion/styled'

import { Flex, Icon } from '@r1/ui-kit'

export const ReplacementList = styled(Flex)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey[300]};
    border-bottom: 1px solid ${theme.palette.grey[300]};
    padding: ${theme.space.L} 0;
  `}
`

export const Buttons = styled(Flex)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.grey[300]};
  `}
`

export const IconWrapper = styled(Icon)`
  position: absolute;
  top: 5px;
  right: 17px;
`

export const EmptyList = styled('div')`
  height: 50px;
`
