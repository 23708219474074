import { Box, Button, Flex, Icon, Text } from '@r1/ui-kit'
import React from 'react'
import { SubStampValue } from '@r1-webui/masterprograms-v1'
import {
  addSubStampValue,
  removeSubStampValue,
  updateSubStampValue,
} from '../../../../services/subStampService'
import { SubStampModel } from './types'
import { CreateSubStampValueModal, EditSubStampValueModal } from './SubStampValueModal'

export type SubStampValuesProps = {
  subStamp: SubStampModel
  onChange: (subStampId: string, subStampValues: SubStampValue[]) => void
}

export const SubStampValues = (props: SubStampValuesProps) => {
  const onAddValue = async (value: string) => {
    const newId = await addSubStampValue(props.subStamp.id, props.subStamp.name, value)
    if (newId) {
      props.onChange(props.subStamp.id, props.subStamp.values.concat([{ id: newId, name: value }]))
      return true
    }
    return false
  }

  const onEditValue = (valueId: string) => async (value: string) => {
    const success = await updateSubStampValue(props.subStamp.id, valueId, value)
    if (success) {
      const newValues = props.subStamp.values.map(x => {
        if (x.id === valueId) {
          return { id: valueId, name: value }
        }
        return x
      })
      props.onChange(props.subStamp.id, newValues)
    }

    return success
  }

  const onRemoveValue = (valueId: string) => async () => {
    const success = await removeSubStampValue(props.subStamp.id, valueId)
    if (success) {
      props.onChange(
        props.subStamp.id,
        props.subStamp.values.filter(x => x.id !== valueId),
      )
    }
  }

  return (
    <Flex column mt="L" ml="XL">
      {props.subStamp.values.map(subStampValue => {
        return (
          <Flex
            key={subStampValue.id}
            minWidth={1}
            justify="space-between"
            align="center"
            spaceBetween="L"
          >
            <Box>
              <Text>{subStampValue.name}</Text>
            </Box>
            <Box>
              <Flex>
                <EditSubStampValueModal
                  subStampId={props.subStamp.id}
                  value={subStampValue.name}
                  onUpdateValue={onEditValue(subStampValue.id)}
                />
                <Button color="secondary" shape="square" onClick={onRemoveValue(subStampValue.id)}>
                  <Icon type="trash" />
                </Button>
              </Flex>
            </Box>
          </Flex>
        )
      })}
      <CreateSubStampValueModal subStampId={props.subStamp.id} onAddValue={onAddValue} />
    </Flex>
  )
}
