// @flow

import * as React from 'react'
import styled from '@emotion/styled'

import { Select as SelectInternal } from '@r1/ui-kit'

import debounce from '../../../../utils/debounce'

const Error = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  font-size: 12px;
  color: red;
`

type SelectEvent = {
  target: {
    type: 'select',
    name: string,
    value?: string | null,
  },
}

type State = {|
  options: Array<{ key: [string], value: [string] }>,
  loading: boolean,
  requestError: string,
  searchText: string,
|}

type Props = {|
  name: string,
  value?: string | null,
  validationError: string,
  autocomplete?: boolean,
  onChange: (_: mixed, event: SelectEvent) => void,
  requestBody: Object,
  optionsUrl: string,
  httpClient: any,
|}

class SelectWithAutocomplete extends React.Component<Props, State> {
  state = { options: [], loading: false, requestError: '', searchText: '' }

  debouncedLoad = debounce(this.load, 400)

  componentDidMount() {
    if (this.props.autocomplete) return
    this.loadOptions()
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { searchText } = this.state
    const { autocomplete, value } = this.props
    const emptyValue = value === null || value === undefined

    if (autocomplete && searchText !== '' && searchText !== prevState.searchText) this.loadOptions()

    if (autocomplete && emptyValue && searchText === '' && value !== prevProps.value)
      this.setState({ options: [] })
  }

  get error(): string {
    return this.props.validationError || this.state.requestError
  }

  load = async () => {
    const { optionsUrl, requestBody, httpClient } = this.props
    const { searchText } = this.state

    try {
      const data = await httpClient.put(optionsUrl, {
        ...requestBody,
        searchText,
      })

      this.setState({ options: data || data.options, requestError: '', loading: false })
    } catch (err) {
      this.setState({ requestError: err.message, loading: false })
    }
  }

  loadOptions = () => {
    this.setState({ loading: true })

    if (this.props.autocomplete) this.debouncedLoad()
    else this.load()
  }

  onInputChange = (searchText: string) => {
    const { autocomplete, value, onChange } = this.props
    const event = {
      target: {
        type: 'select',
        name: this.props.name,
        value: null,
      },
    }

    this.setState({ searchText })

    if (autocomplete && searchText.length === 1 && value) onChange(undefined, event)
  }

  onChange = (_: mixed, event: Object) => this.props.onChange(_, event)

  render() {
    return (
      <React.Fragment>
        <SelectInternal
          valueKey="key"
          labelKey="value"
          name={this.props.name}
          options={this.state.options}
          // $FlowFixMe[incompatible-type]
          value={this.props.value}
          error={!!this.error}
          loading={this.state.loading}
          placeholder={this.props.autocomplete ? 'Start typing...' : undefined}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
        />
        {this.error && <Error>{this.error}</Error>}
      </React.Fragment>
    )
  }
}

const Select = SelectWithAutocomplete

export { Select }
