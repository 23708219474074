export type Field<Value> = {
  value: Value
  onChange: (value: Value) => void
  error: string
}

export type RoleId = string

export enum PageMode {
  Creating = 'Creating',
  View = 'View',
  Editing = 'Editing',
}

export type ActionId = string
export type Action = {
  id: ActionId
  name: string
  description: string
}

export type EntityId = string
type Entity = {
  id: EntityId
  name: string
  actions: Record<ActionId, Action>
}

export type AllEntityActionsInfo = Record<EntityId, Entity>

export type SelectedEntityActions = Record<EntityId, ActionId[]>

export type Role = {
  name: string
  description: string
  entityTypeActions: SelectedEntityActions
}
