// @flow

import React from 'react'
import { Box, Flex, FormField, TagInput, Text } from '@r1/ui-kit'
import { ColoredText } from '../../ColoredText'

import type { IDValuesFormProps } from '../../../../../../types/variation.type'

import { testIds } from '../../../testIds'

export const ProductIdentifierValuesFilter = (props: IDValuesFormProps) => {
  const { values, handleChange, disabled, getError, path } = props

  return (
    <>
      <Flex>
        <ColoredText>
          <Text type="paragraph">
            Include products that have following <b>identifiers</b>:
          </Text>
        </ColoredText>
      </Flex>
      <Flex justify="space-between" mt="S">
        {/* <Box basis={180} mr="M" mt={'S'}>
          <ColoredText>Include products that have following identifiers:</ColoredText>
        </Box>
        <Box basis={180} mr="M" mt={'S'}>
          <ColoredText>In list</ColoredText>
        </Box> */}
        <Box shrink grow mr="S">
          <FormField>
            <TagInput
              $type="simpleTagValue"
              data-test-id={`${testIds.identifierTagInput}`}
              disabled={disabled}
              value={values.productIdentifierValues}
              placeholder="Enter values"
              // TODO fix index
              error={!!getError(path)}
              onChange={handleChange(path)}
            />
            <FormField.Error>{getError(path)}</FormField.Error>
          </FormField>
        </Box>
      </Flex>
    </>
  )
}
