import React, { useState, useCallback, useEffect } from 'react'
import { ContentHeader } from '@r1/wireframe-primary'
import {
  Button,
  Input,
  Flex,
  FormField,
  Select,
  TagInput,
  Placeholder,
  NumericInput,
  TreeSelect,
  Modal,
  Icon,
} from '@r1/ui-kit'
import { FormManager } from '@r1/form-manager'
import { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { SupplyLevelRuleFormProps, Categories, Facilities } from '../types'
import { supplyLevelRulesService } from '../services'
import { validate } from './validation'

export const SupplyLevelRuleForm = (props: SupplyLevelRuleFormProps) => {
  const { initialValue, isSubmitting, onSubmit, onClose, onDelete } = props
  const [facilities, setFacilities] = useState<Facilities>([])
  const [categories, setCategories] = useState<Categories>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    const [allFacilities, categoryTree] = await Promise.all([
      supplyLevelRulesService.getAllFacilities(),
      supplyLevelRulesService.getPartCategoryTree(),
    ])
    setFacilities(allFacilities)
    setCategories(categoryTree)
    setIsLoading(false)
  }, [])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  const leaveModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose: uncontrolledOnClose }) => {
      return [
        {
          title: 'No',
          color: 'secondary',
          onClick: uncontrolledOnClose,
          align: 'right',
        },
        {
          title: 'Yes',
          transparent: false,
          onClick: () => {
            onClose()
            uncontrolledOnClose()
          },
          align: 'right',
        },
      ]
    },
    [onClose],
  )

  const deleteModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose: uncontrolledOnClose }) => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: uncontrolledOnClose,
          align: 'right',
        },
        {
          title: 'Delete',
          color: 'danger',
          transparent: false,
          onClick: () => {
            if (onDelete) {
              onDelete()
            }
            uncontrolledOnClose()
          },
          align: 'right',
        },
      ]
    },
    [onDelete],
  )

  return isLoading ? (
    <Placeholder type="form" height={5} />
  ) : (
    <FormManager initialValues={initialValue} clientValidate={validate} onSubmit={onSubmit}>
      {({ values, dirty, getError, handleChange, handleBlur, handleSubmit }) => {
        const nameError = getError('name')
        const facilityIdError = getError('facilityId')
        const identifierValuesError = getError('identifierValues')
        const partCategoryIdError = getError('partCategoryId')
        const requiredCountError = getError('requiredCount')
        return (
          <>
            <ContentHeader.ActionButtons>
              <Modal
                isControlled={false}
                title="You have unsaved data"
                actionButtons={leaveModalActionButtons}
                buttonElement={({ onOpen }) => (
                  <Button transparent color="secondary" onClick={onOpen}>
                    Cancel
                  </Button>
                )}
              >
                Are you sure you want to leave without saving?
              </Modal>
              <Button
                disabled={isSubmitting || !dirty}
                loading={isSubmitting}
                onClick={handleSubmit}
              >
                Save
              </Button>
              {onDelete && (
                <Modal
                  isControlled={false}
                  title="Warning"
                  actionButtons={deleteModalActionButtons}
                  buttonElement={({ onOpen }) => (
                    <Button transparent color="secondary" shape="square" onClick={onOpen}>
                      <Icon type="trash" />
                    </Button>
                  )}
                >
                  Are you sure you want to delete rule?
                </Modal>
              )}
            </ContentHeader.ActionButtons>
            <Flex column minWidth={1}>
              <FormField>
                <FormField.Label>Supply Level Rule Name</FormField.Label>
                <Input
                  data-test-id="supply-level-rule-name"
                  value={values.name}
                  error={!!nameError}
                  // @ts-ignore
                  onChange={name => handleChange(['name'], name)}
                  onBlur={handleBlur(['name'])}
                />
                <FormField.Error>{nameError}</FormField.Error>
              </FormField>
              <FormField>
                <FormField.Label>Warehouse</FormField.Label>
                <Select
                  data-test-id="warehouse"
                  placeholder="Choose Warehouse"
                  options={facilities}
                  value={values.facilityId}
                  error={!!facilityIdError}
                  // @ts-ignore
                  onChange={handleChange(['facilityId'])}
                  onBlur={handleBlur(['facilityId'])}
                />
                <FormField.Error>{facilityIdError}</FormField.Error>
              </FormField>
              <FormField>
                <FormField.Label>For these UPCs</FormField.Label>
                <TagInput
                  data-test-id="UPCs"
                  $type="simpleTagValue"
                  value={values.identifierValues}
                  error={!!identifierValuesError}
                  delimiter={['\n', ' ', ',', ':']}
                  // @ts-ignore
                  onChange={handleChange(['identifierValues'])}
                  onBlur={handleBlur(['identifierValues'])}
                />
                <FormField.Error>{identifierValuesError}</FormField.Error>
              </FormField>
              <FormField>
                <FormField.Label>For quantity of</FormField.Label>
                <NumericInput
                  data-test-id="quantity"
                  min={1}
                  value={values.requiredCount}
                  error={!!requiredCountError}
                  onChange={value =>
                    // @ts-ignore
                    handleChange(['requiredCount'], value === null ? 1 : parseInt(value, 10))
                  }
                  onBlur={handleBlur(['requiredCount'])}
                />
                <FormField.Error>{requiredCountError}</FormField.Error>
              </FormField>
              <FormField>
                <FormField.Label>Of parts category</FormField.Label>
                <TreeSelect
                  ignoreBranchNodes
                  data-test-id="of-parts-category"
                  placeholder="Choose Categories"
                  options={categories}
                  value={values.partCategoryId}
                  error={!!partCategoryIdError}
                  // @ts-ignore
                  onChange={handleChange(['partCategoryId'])}
                  onBlur={handleBlur(['partCategoryId'])}
                />
                <FormField.Error>{partCategoryIdError}</FormField.Error>
              </FormField>
            </Flex>
          </>
        )
      }}
    </FormManager>
  )
}
