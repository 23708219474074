// @flow

import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { Link, Icon, Tooltip, Modal, Button } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { AsyncTree } from '@r1/grid'

import type { ColumnSettings } from '@r1/grid/build/types/displaySettings'
import { FormatAttributeValue } from '../FormatAttributeValue'
import type { ExtendedAttributeDefinition } from '../../../../services/categoryAttributesService'
import { getAttributeValueTypeText, getAttributeBindingType } from './attributeViewMapper'

const canEditBinding = definition =>
  !definition.meta.inherited ||
  ((definition.valueType === 'IntEnumeration' ||
    definition.valueType === 'DecimalEnumeration' ||
    definition.valueType === 'StringEnumeration' ||
    definition.valueType === 'Boolean') &&
    !definition.meta.binding.parentBinding)

const Ellipse = styled('div')`
  height: 12px;
  width: 12px;
  border-radius: 50%;
`
const GreenEllipse = styled(Ellipse)`
  background: ${({ theme }) => theme.palette.green[400]};
`
const GrayEllipse = styled(Ellipse)`
  ${({ theme }) => `
    border: 2px solid ${theme.palette.grey[600]};
`}
`

const Container = styled('div')`
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
`

const DeleteIcon = ({ onDeleteBinding }) => {
  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Delete',
          color: 'danger',
          transparent: false,
          onClick: () => {
            onDeleteBinding()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onDeleteBinding],
  )

  return (
    <Modal
      isControlled={false}
      title="Warning"
      actionButtons={modalActionButtons}
      buttonElement={({ onOpen }) => (
        <Tooltip text="Delete Binding" placement="top">
          <Button transparent size="S" color="secondary" onClick={onOpen}>
            <Icon type="trash" />
          </Button>
        </Tooltip>
      )}
    >
      Deleting of attribute binding will remove related products attribute values. Are you sure?
    </Modal>
  )
}

const createRenderName =
  (getAttributeDetailsUrl: (id: string) => string) =>
  ({ id, meta, name }: ExtendedAttributeDefinition) =>
    <Link href={getAttributeDetailsUrl(id)}>{meta.binding.displayName || name}</Link>

type Options = {
  mode: 'edit' | 'view',
  onViewBinding: (id: string) => void,
  onEditBinding: (id: string) => void,
  onDeleteBinding: (id: string) => void,
  getAttributeDetailsUrl: (id: string) => string,
}

export const getGridScheme = ({
  mode,
  onViewBinding,
  onEditBinding,
  onDeleteBinding,
  getAttributeDetailsUrl,
}: Options): ColumnSettings<any>[] => [
  {
    width: 220,
    header: {
      content: 'Title',
    },
    cell: {
      $type: 'custom',
      renderer: (definition: ExtendedAttributeDefinition) =>
        AsyncTree.createNodeRenderer(createRenderName(getAttributeDetailsUrl))(definition),
    },
  },
  {
    width: 140,
    header: {
      content: 'Type',
    },
    cell: {
      $type: 'custom',
      renderer: ({ valueType }: ExtendedAttributeDefinition) =>
        getAttributeValueTypeText(valueType),
    },
  },
  {
    width: 120,
    header: {
      content: 'Inherited',
    },
    cell: {
      $type: 'custom',
      renderer: ({ meta }: ExtendedAttributeDefinition) => (
        <Container>
          {meta.inherited ? (
            <React.Fragment>
              <GreenEllipse />
              Yes
            </React.Fragment>
          ) : (
            <React.Fragment>
              <GrayEllipse />
              No
            </React.Fragment>
          )}
        </Container>
      ),
    },
  },
  {
    width: 115,
    header: {
      content: 'Binding',
    },
    cell: {
      $type: 'custom',
      renderer: (definition: ExtendedAttributeDefinition) =>
        getAttributeBindingType(definition.type),
    },
  },
  {
    width: 180,
    header: {
      content: 'Default Value',
    },
    cell: {
      $type: 'custom',
      renderer: (definition: ExtendedAttributeDefinition) =>
        definition.meta.binding.defaultValue ? (
          <FormatAttributeValue
            value={definition.meta.binding.defaultValue}
            definition={definition}
          />
        ) : null,
    },
  },
  {
    width: 98,
    header: {
      content: 'Required',
    },
    cell: {
      $type: 'custom',
      renderer: ({ meta }: ExtendedAttributeDefinition) => (meta.binding.required ? 'Yes' : 'No'),
    },
  },
  {
    width: 115,
    header: {
      content: 'Constraints',
    },
    cell: {
      $type: 'custom',
      renderer: ({ meta }: ExtendedAttributeDefinition) =>
        meta.binding.valueConstraint ? 'Yes' : 'No',
    },
  },
  {
    width: 100,
    header: {
      content: 'Actions',
    },
    cell: {
      $type: 'custom',
      renderer: (definition: ExtendedAttributeDefinition) => (
        <>
          {mode === 'edit' && canEditBinding(definition) ? (
            <Tooltip text="Setup Binding" placement="top">
              <Button
                transparent
                size="S"
                color="secondary"
                onClick={() => onEditBinding(definition.id)}
              >
                <Icon type="gear" />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip text="Show Details" placement="top">
              <Button
                transparent
                size="S"
                color="secondary"
                onClick={() => onViewBinding(definition.id)}
              >
                <Icon type="info" />
              </Button>
            </Tooltip>
          )}
          {!definition.meta.inherited && (
            <DeleteIcon onDeleteBinding={() => onDeleteBinding(definition.id)} />
          )}
        </>
      ),
    },
  },
]
