import * as React from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Tooltip, withTheme, Link, Flex, Text, Icon } from '@r1/ui-kit'
import { actions } from '../../../../modules/products'
import { actions as classificationsActions } from '../../../../modules/products/classifications'
import { EnterModal } from './EnterModal'

const enhance = compose(
  withTheme,
  connect(null, {
    saveUpc: actions.saveUpc,
    fetchClassifications: classificationsActions.fetchItem,
  }),
)

const TooltipText = <Text>Change product template</Text>

class UpcFieldComponent extends React.Component {
  state = {
    visible: false,
  }

  async handleButtonClick(inputValue) {
    const { saveUpc, onChange, productId, fetchClassifications } = this.props
    const productTemplateId = await saveUpc(productId, inputValue.trim())
    onChange({ templateIdentifierValue: inputValue, productTemplateId })
    await fetchClassifications({ productId }, { force: true })
  }

  onButtonClick = this.handleButtonClick.bind(this)

  toggleDialog = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  render() {
    const {
      value: { templateIdentifierValue, productTemplateId },
      theme,
    } = this.props
    const { visible } = this.state
    return (
      <>
        <Flex spaceBetween="S" align="center">
          <Link to={`/r1/producttemplate/${productTemplateId}`}>{templateIdentifierValue}</Link>
          <Tooltip text={TooltipText} style={{ display: 'block' }} place="right">
            <Icon
              type="pencil"
              size="XS"
              cursor="pointer"
              color={theme.primaryColor[600]}
              onClick={this.toggleDialog}
            />
          </Tooltip>
        </Flex>
        <EnterModal
          showModal={visible}
          title="Change product template identifier"
          inputLabel="Enter new identifier"
          onClose={this.toggleDialog}
          onContinue={this.onButtonClick}
        />
      </>
    )
  }
}

export const UpcField = enhance(UpcFieldComponent)
