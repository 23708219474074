import { productTemplateAdditionalSettingsApi } from '../../../../../api/productTemplate/index.ts'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { proceedError, showErrors } from '../../../utils'

const initialState = {
  model: null,
  isFetched: true,
}

const types = createTypes(['setFetching', 'loadModel'], 'otherSettings')

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.loadModel]: (state, payload) => ({ ...state, ...payload, isFetched: true }),
})

export function loadModel(id) {
  return async (dispatch, getState, _api) => {
    if (getState().mainInfo.model) return

    dispatch({ type: types.setFetching })

    const settings = await productTemplateAdditionalSettingsApi.get({ id }).then(({ body }) => body)

    dispatch({
      type: types.loadModel,
      model: { ...settings },
    })
  }
}

export function handleSubmit({ $type: _, ...formModel }, id) {
  return async (_dispatch, _getState, _api) => {
    const {
      supportingUrl,
      taxCode,
      unsellable,
      trgUpc = null,
      supplierName = null,
      referenceNotes = null,
      retailerItemId = null,
      wmDepartment = null,
      wmItemNumber = null,
      wmItemId = null,
      wmCanadaItemId = null,
    } = formModel

    try {
      await productTemplateAdditionalSettingsApi.save(
        { id },
        {
          supportingUrl,
          taxCode,
          unsellable,
          trgUpc,
          supplierName,
          referenceNotes,
          retailerItemId,
          wmDepartment,
          wmItemNumber,
          wmItemId,
          wmCanadaItemId,
        },
      )
    } catch (error) {
      showErrors(proceedError(error))
    }
  }
}
