import React from 'react'
import { Modal } from '@r1/ui-kit'
import { useUserSettings } from '@r1/core-blocks'
import { DialogModalProps } from './types'

export const DialogModal = ({ message, show, onClose, onConfirm }: DialogModalProps) => {
  const {
    currentUser: { userInfo },
  } = useUserSettings()

  return (
    <Modal
      isControlled
      zIndex={1000}
      show={show}
      title={userInfo?.fullName}
      actionButtons={[
        {
          title: 'Close',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        ...(onConfirm
          ? [
              {
                title: 'Confirm',
                color: 'primary' as const,
                onClick: onConfirm,
                align: 'right' as const,
              },
            ]
          : []),
      ]}
      onEscapeKeyDown={onClose}
    >
      {message}
    </Modal>
  )
}
