/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { queryParametersUtils } from '../../../utils/queryParametersUtils'

const REPORT_ID_PARAM_NAME = 'reportId'
const WORKSPACE_ID_PARAM_NAME = 'workspaceId'

export function getReportParamsFromUrlAndSaveToLocalStorage() {
  const reportId: string | null =
    queryParametersUtils.getValueByKey(REPORT_ID_PARAM_NAME) ||
    localStorage.getItem(REPORT_ID_PARAM_NAME)
  const workspaceId: string | null =
    queryParametersUtils.getValueByKey(WORKSPACE_ID_PARAM_NAME) ||
    localStorage.getItem(WORKSPACE_ID_PARAM_NAME)
  if (reportId && workspaceId) {
    window.localStorage.setItem(REPORT_ID_PARAM_NAME, reportId)
    window.localStorage.setItem(WORKSPACE_ID_PARAM_NAME, workspaceId)
  }
  return {
    reportId,
    workspaceId,
  }
}
