import * as React from 'react'
import { handleServerError } from '@r1/core-blocks'
import { masterProgramRulesApi } from '../api/masterProgramRulesApi'
import { processMasterProgramRuleFromApi } from '../utils/processMasterProgramRule'
import { MasterProgramModel } from '../types'

export const useMasterProgramRule = ({
  masterProgramId,
  onError,
}: {
  masterProgramId?: string | null
  onError?: () => void
}) => {
  const [masterProgram, setMasterProgram] = React.useState<MasterProgramModel | null>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  const getMasterProgram = React.useCallback(
    async (ruleId: string) => {
      setLoading(true)
      const handleError = typeof onError === 'function' ? onError : () => {}
      const response = await masterProgramRulesApi.getMasterProgramRule({ ruleId })
      setLoading(false)

      if (response.status !== 200) {
        handleServerError(response)
        handleError()
        return
      }

      const bodyWithProcessedFields = processMasterProgramRuleFromApi(response.body)
      setMasterProgram(bodyWithProcessedFields)
    },
    [onError],
  )

  React.useEffect(() => {
    if (masterProgramId) {
      getMasterProgram(masterProgramId)
    } else {
      setMasterProgram(null)
    }
  }, [getMasterProgram, masterProgramId])

  return { masterProgram, loading }
}
