// @flow

import * as React from 'react'
import { Button, Flex, Format, Icon, Tooltip } from '@r1/ui-kit'

import type { ClaimMessage } from '../../types/ClaimMessage'
import { DateElem, Username } from './StyledComponents'

type Props = {|
  message: ClaimMessage,
  canEdit: boolean,
  onEditClick: (messageId: string) => void | Promise<void>,
|}

export const MessageLogItem = React.memo<Props>(({ message, canEdit, onEditClick }: Props) => {
  const handleEdit = React.useCallback(
    function handleEdit() {
      onEditClick(message.id)
    },
    [message.id, onEditClick],
  )
  return (
    <Flex column spaceBetween="XXS">
      <Flex spaceBetween="S" justify="flex-start" align="center">
        <Username>{message.userName || 'system'}</Username>
        <DateElem>
          <Format.DateTime>{message.messageOn}</Format.DateTime>
        </DateElem>
        {canEdit && (
          <Tooltip text="Edit">
            <Button transparent size="S" shape="circle" color="secondary" onClick={handleEdit}>
              <Icon type="pencil" />
            </Button>
          </Tooltip>
        )}
      </Flex>
      <Flex justify="flex-start">{message.text}</Flex>
    </Flex>
  )
})
