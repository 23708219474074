// @flow

import { is, pipe, isEmpty, propOr } from 'ramda'

import { baseUrl } from '../../constants'
import { getData } from '../../utils'

export const getPurposeType = ({
  httpClient,
  purposeId,
  onError,
}: {
  httpClient: any,
  purposeId: ?string,
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    defaultValue: { purposes: [], purposeInfo: {} },
    transformData: purposes => {
      const getInfo = pipe(
        items => items.find(item => !isEmpty(propOr({}, 'purpose')(item))),
        info => (is(Object, info) ? info.purpose : {}),
      )
      const purposeInfo = is(Array, purposes) ? getInfo(purposes) : {}

      return { purposes, purposeInfo }
    },
    options: {
      method: 'GET',
      url: `${baseUrl}/purpose/${purposeId || ''}/type`,
      forceUpdate: true,
    },
  }).then(({ value }) => value)

export const getProfiles = ({
  httpClient,
  purposeId,
  onError,
}: {
  httpClient: any,
  purposeId: ?string,
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    defaultValue: [],
    options: {
      method: 'GET',
      url: `${baseUrl}/purpose/${purposeId || ''}/profile`,
      forceUpdate: true,
    },
  }).then(({ value }) => value)

export const approveProfileSharing = ({
  httpClient,
  profileId,
  onError,
}: {
  httpClient: any,
  profileId: ?(string | number),
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'PUT',
      url: `${baseUrl}/profile/${profileId || ''}/sharing/approve`,
    },
  }).then(({ value }) => value)

export const cancelProfileSharing = ({
  httpClient,
  profileId,
  onError,
}: {
  httpClient: any,
  profileId: ?(string | number),
  onError: any,
}) =>
  getData({
    httpClient,
    onError,
    options: {
      method: 'PUT',
      url: `${baseUrl}/profile/${profileId || ''}/sharing/approve/cancel`,
    },
  }).then(({ value }) => value)

export const getSharedCompanies = (httpClient: any, onError: any) =>
  getData({
    httpClient,
    onError,
    defaultValue: [],
    options: {
      method: 'GET',
      url: '/uisvc/espm/api/profile/shared',
    },
  }).then(({ value }) => value)

export const getInitialData = ({
  purposeId,
  httpClient,
  onError,
}: {
  purposeId: ?string,
  httpClient: any,
  onError: any,
}) =>
  Promise.all([
    getProfiles({ purposeId, httpClient, onError }),
    getPurposeType({ purposeId, httpClient, onError }),
    getSharedCompanies(httpClient, onError),
  ]).then(([profiles, { purposes, purposeInfo }, sharedCompanies]) => {
    const result = {
      profiles,
      purposes,
      purposeInfo,
      sharedCompanies,
      profilesLoading: { all: false },
    }

    return result
  })
