// @flow

import type {
  AttributeBinding,
  InheritedAttributeBinding,
} from '@r1-webui/productcatalog-categories-v1/src/types'

import { toObject } from '../../../utils/toObject'
import type { ExtendedAttributeDefinition } from '../../../../services/categoryAttributesService'

export function getAvailableChildAttributes({
  attributes,
  targetAttributeId,
  bindings,
  inheritedBindings,
}: {
  attributes: ExtendedAttributeDefinition[],
  bindings: AttributeBinding[],
  targetAttributeId: string,
  inheritedBindings: InheritedAttributeBinding[],
}): ExtendedAttributeDefinition[] {
  const attributesMap = toObject(attributes, 'id')
  const attributesToExclude = [targetAttributeId]

  bindings.forEach(binding => {
    const isProduct =
      attributesMap[binding.attributeDefinition.id].type === 'ProductAttributeDefinition'
    const hasChildren = binding.childBindings.length > 0

    if (hasChildren || isProduct) {
      attributesToExclude.push(binding.attributeDefinition.id)
    }
  })

  inheritedBindings.forEach(binding => {
    attributesToExclude.push(binding.attributeDefinition.id)
  })

  return attributes.filter(item => !attributesToExclude.includes(item.id))
}
