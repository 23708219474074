// @flow

import { getAttributeBindingType } from '../../../AttributeDefinition/utils'

import type { AttributeDefinition } from '../../../../types/internal/api/attributeDefinitions'
import type { AttributeInfo } from '../../../../types/public/AttributeEditFormController'

export function createAttributeInfo(attributeDefinition: AttributeDefinition): AttributeInfo {
  return {
    attributeName: attributeDefinition.name,
    attributeType: attributeDefinition.valueType,
    attributeBindingType: getAttributeBindingType(attributeDefinition),
    unitOfMeasurement: attributeDefinition.unitOfMeasurement
      ? attributeDefinition.unitOfMeasurement.id
      : null,
    /**
     * @warning
     *
     * attributeDefinition.unitOfMeasurement.type.name using because its need only for view in disabled type select
     *
     */
    unitOfMeasurementType: attributeDefinition.unitOfMeasurement
      ? attributeDefinition.unitOfMeasurement.type.name
      : null,
    attributeEnumValues: attributeDefinition.enumValues
      ? attributeDefinition.enumValues.enumValues.map(v => ({
          id: v.id,
          value: v.value.toString(),
        }))
      : [],

    productUniqueness: attributeDefinition.productUniqueness,
  }
}
