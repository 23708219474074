// @flow

import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'
import { PricingRuleViewScreen } from '../../../containers/PriceContainer'

import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'

export const GlobalPricingRuleView = () => {
  const { pricingRuleId } = useParams()
  const navigate = useNavigate()

  return (
    // <PageAccessControl permissions={[PERMISSIONS.GlobalPriceAggregationRuleView]}>
    <Main>
      <ContentHeader />
      <ContentHeader.Breadcrumbs>
        <Link to={ROUTES.GLOBAL_PRICING_RULES}>Pricing Rules</Link>
      </ContentHeader.Breadcrumbs>
      <Content>
        <PricingRuleViewScreen
          pricingRuleId={pricingRuleId || ''}
          httpClient={httpClient}
          renderTitle={ContentHeader.Title}
          renderActionsButtons={ContentHeader.ActionButtons}
          onClose={() => navigate(ROUTES.GLOBAL_PRICING_RULES)}
        />
      </Content>
    </Main>
    // </PageAccessControl>
  )
}
