import { NotificationSystem } from '@r1/ui-kit'
import { createReducer, createTypes } from '../../../../../redux/utils'
import { proceedError, showErrors } from '../../../utils'
import {
  marketplaceCategoryDataApi,
  marketplaceCategorySchemesApi,
} from '../../../../../api/productTemplate/index.ts'
import { removeEmptyValues } from './utils'

const initialState = {
  schema: null,
  data: null,
  initialData: null,
  initialWalmartCategoryId: null,
  isFetched: false,
  isDirty: false,
  errors: [],
}

const types = createTypes(
  [
    'setFetching',
    'setIsDirty',
    'setInitialData',
    'setErrors',
    'loadSchema',
    'loadData',
    'changeData',
  ],
  'walmartAttributes',
)

export const reducer = createReducer(initialState, {
  [types.setFetching]: state => ({ ...state, isFetched: false }),
  [types.setIsDirty]: (state, { isDirty }) => ({ ...state, isDirty }),
  [types.setInitialData]: (state, { initialData }) => ({ ...state, initialData }),
  [types.setErrors]: (state, { errors }) => ({ ...state, errors }),
  [types.loadSchema]: (state, { schema }) => ({ ...state, schema, isFetched: true }),
  [types.loadData]: (state, { data, walmartCategoryId }) => ({
    ...state,
    data,
    initialData: data,
    initialWalmartCategoryId: walmartCategoryId,
    isFetched: true,
  }),
  [types.changeData]: (state, { data }) => ({ ...state, isDirty: true, data }),
})

export function changeData(data) {
  return async dispatch => {
    dispatch({
      type: types.changeData,
      data: removeEmptyValues(data),
    })
  }
}

export function setErrors(errors) {
  return async dispatch => {
    dispatch({
      type: types.setErrors,
      errors,
    })
  }
}

function loadData(productTemplateId, walmartDataSourceId, walmartCategoryId) {
  return async dispatch => {
    dispatch({ type: types.setFetching })

    marketplaceCategoryDataApi
      .getMarketplaceProductTemplateData({
        id: walmartDataSourceId,
        productTemplateId,
      })
      .then(_res => {
        if (_res.status === 200) {
          dispatch({
            type: types.loadData,
            data: JSON.parse(_res.body.data?.jsonObject ?? '{}'),
            walmartCategoryId,
          })
        }
      })
      .catch(err => {
        showErrors(proceedError(err))
      })
  }
}

export function fetchSchemaAndData(productTemplateId, walmartCategoryId) {
  return async (dispatch, getState) => {
    const { initialData, initialWalmartCategoryId } = getState().walmartAttributes
    const { walmartDataSourceId } = getState().categoryMapping

    dispatch({ type: types.setFetching })

    // we should load schema every time
    await marketplaceCategorySchemesApi
      .getMarketplaceCategoryScheme({
        id: walmartDataSourceId,
        categoryId: walmartCategoryId,
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({
            type: types.loadSchema,
            schema: JSON.parse(res.body || '{}'),
          })

          // if we don't have initial data - we initiate form with it
          // otherwise - we empty data state (set it to {})
          // if user chosen categoryId same as initialized one - restore data then
          if (!initialData) {
            dispatch(loadData(productTemplateId, walmartDataSourceId, walmartCategoryId))
          } else {
            const needRestore = walmartCategoryId === initialWalmartCategoryId

            dispatch({
              type: types.changeData,
              data: needRestore ? initialData : {},
            })
            dispatch({
              type: types.setIsDirty,
              isDirty: !needRestore,
            })
          }
        }
      })
      .catch(err => {
        showErrors(proceedError(err))
      })
  }
}

export function submitWalmartAttributes(productTemplateId) {
  return async (dispatch, getState) => {
    const { isDirty, errors, data, initialData } = getState().walmartAttributes
    const { walmartCategoryId, walmartDataSourceId } = getState().categoryMapping

    dispatch({ type: types.setFetching })

    // this case means that user removed walmart category
    if (!walmartCategoryId && initialData) {
      await marketplaceCategoryDataApi
        .deleteMarketplaceProductTemplateData({
          id: walmartDataSourceId,
          productTemplateId,
        })
        .then(() => {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Success',
            message: `Walmart attributes successfully removed.`,
          })
          dispatch({ type: types.setInitialData, initialData: null })
        })
        .catch(err => {
          showErrors(proceedError(err))
        })
    } else if (isDirty) {
      await marketplaceCategoryDataApi
        .saveMarketplaceProductTemplateData(
          {
            id: walmartDataSourceId,
            productTemplateId,
          },
          {
            categoryId: walmartCategoryId,
            jsonObject: JSON.stringify(data),
            valid: !errors.length,
          },
        )
        .then(() => {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Success',
            message: `Walmart attributes successfully updated. ${
              errors.length ? 'It has validation errors' : ''
            }`,
          })
          dispatch(loadData(productTemplateId, walmartDataSourceId, walmartCategoryId))
        })
        .catch(err => {
          showErrors(proceedError(err))
        })
    }

    dispatch({
      type: types.setIsDirty,
      isDirty: false,
    })
  }
}
