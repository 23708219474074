// @flow

import React, { memo } from 'react'
import styled from '@emotion/styled'
import { Flex, Text, Box, Format } from '@r1/ui-kit'

const Panel = styled('div')`
  background-color: #fff1c5;
  width: 100%;
  height: 24px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  z-index: 100;
`

type FooterPanelProps = {
  type: 'lock' | 'review' | '',
  date?: string,
  user?: string,
  description?: string,
}

export const FooterPanel = memo<FooterPanelProps>(
  ({ type, description, user, date }: FooterPanelProps) => {
    return (
      type && (
        <Panel>
          <Flex spaceBetween="S" justify="center">
            <Text type="paragraph">
              {(() => {
                switch (type) {
                  case 'lock':
                    return 'Product template is locked.'
                  case 'review':
                    return 'Submitted for review.'
                  default:
                    return ''
                }
              })()}
            </Text>
            {user && <Text type="paragraph">By: {user}</Text>}
            {description && (
              <Box maxWidth={200}>
                <Text type="paragraph" overflow="ellipsis">
                  Reason: {description}
                </Text>
              </Box>
            )}
            {date && (
              <Text type="paragraph">
                Date: <Format.DateTime>{date}</Format.DateTime>
              </Text>
            )}
          </Flex>
        </Panel>
      )
    )
  },
)
