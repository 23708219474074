import * as React from 'react'
import { HistoryModal } from '@r1/core-blocks'
import { UserHistoryModalProps } from '../../types/UserHistoryModal'
import { createApi as createUserManagementApi } from '../../api/userManagement'
import { extractPayload } from '../../api/helpers'

const getTitle = (fullname?: string) => {
  return fullname ? `User ${fullname} History` : 'User History'
}

export const UserHistoryModal = (props: UserHistoryModalProps) => {
  const { httpClient, userId, username } = props
  const [title, setTitle] = React.useState(getTitle(username))
  const api = createUserManagementApi(httpClient)

  React.useEffect(() => {
    async function fetchName() {
      const result = await api.getUser(userId)
      const payload = extractPayload(result)
      if (payload) {
        setTitle(getTitle(payload.info.fullName))
      }
    }

    if (!username) {
      fetchName()
    }
  }, [userId, username, api])

  const fetchUserHistoryReceivingToken = React.useCallback(async () => {
    const result = await api.getUserHistoryReceivingToken(userId)
    const payload = extractPayload(result)
    if (payload) {
      return payload.token
    }

    return ''
  }, [userId, api])

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {props.isControlled ? (
        <HistoryModal
          isControlled
          show={props.show}
          title={title}
          httpClient={httpClient}
          getHistoryReceivingToken={fetchUserHistoryReceivingToken}
          onClose={props.onClose}
        />
      ) : (
        <HistoryModal
          isControlled={false}
          title={title}
          httpClient={httpClient}
          getHistoryReceivingToken={fetchUserHistoryReceivingToken}
          buttonElement={props.buttonElement}
        />
      )}
    </>
  )
}
