// @flow

import * as React from 'react'
import { generatePath, useParams, useNavigate } from 'react-router-dom'

import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import { Link } from '@r1/ui-kit'
import { ProductEditScreen } from '../../../containers/ProductContainer'

import { httpClient } from '../../../../../httpClient'
import { ROUTES } from '../../../../../navigation'

const getProductDetailsUrl = productId => generatePath(ROUTES.PRODUCT_DETAILS, { productId })

export const ProductEdit = () => {
  const { productId } = useParams()
  const navigate = useNavigate()
  const [{ allowProductSourceDataView, allowProductApprove, allowProductUnlock }] =
    useAccessControl()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowProductEdit]}>
      <Main>
        <ContentHeader />
        <ContentHeader.Breadcrumbs>
          <Link to={ROUTES.PRODUCTS}>Products</Link>
        </ContentHeader.Breadcrumbs>
        <Content>
          <ProductEditScreen
            productId={productId || ''}
            httpClient={httpClient}
            renderTitle={ContentHeader.Title}
            renderActionsButtons={ContentHeader.ActionButtons}
            permissions={{ allowProductSourceDataView, allowProductApprove, allowProductUnlock }}
            getProductDetailsUrl={getProductDetailsUrl}
            onChangeScreenType={screenType => {
              if (screenType === 'forbidden') navigate(ROUTES.NO_PERMISSION)
              if (screenType === 'notFound') navigate(ROUTES.NOT_FOUND)
            }}
            onSuccessfulSubmit={() => navigate(ROUTES.PRODUCTS)}
            onCancel={() => navigate(ROUTES.PRODUCTS)}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
