// @flow

import React, { memo, useCallback, useContext, useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Format, Label, Modal, Text, Link, Icon } from '@r1/ui-kit'
import { Pagination, Table } from '@r1/grid'
import { ServiceContext } from '../../../../provider'
import type { PriceHistoryProps } from '../../../../types/product.type'

const ModalContainer = styled('div')`
  width: 760px;
`
const TextContainer = styled('div')`
  padding-right: ${({ theme }) => theme.space.S};
  display: inline;
`

const ControlBar = styled('div')`
  height: 44px;
  margin: 23px 0 8px;
  display: flex;
  align-items: center;
`

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

export const SourcePriceHistory = memo<PriceHistoryProps>((props: PriceHistoryProps) => {
  const { isShow, title, productIdentifierValue, sourceProvider, onClose } = props
  const {
    productService: { fetchSourcePriceHistory },
  } = useContext(ServiceContext)

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [loading, setLoading] = useState(false)

  const onChangePagination = useCallback(
    ({ pageSize: newSize, page: newPage }) => {
      const startIdx = (newPage - 1) * newSize
      const endIdx = startIdx + newSize

      setPage(newPage)
      setPageSize(newSize)
      setTableData(data.slice(startIdx, endIdx))
    },
    [data],
  )

  const fetchData = useCallback(async () => {
    const { body } = await fetchSourcePriceHistory(sourceProvider, productIdentifierValue)
    setData(body)
    setTotalCount(body.length)
    setTableData(body.slice(DEFAULT_PAGE - 1, DEFAULT_PAGE_SIZE))
  }, [fetchSourcePriceHistory, productIdentifierValue, sourceProvider])

  const getTableSettings = useCallback(() => {
    return {
      columnsSettings: [
        {
          header: { content: 'Price', horizontalAlign: 'right' },
          horizontalAlign: 'right',
          cell: {
            $type: 'custom',
            renderer: ({ price: { amount, currency } }) =>
              `${currency} ${Number(amount).toFixed(2)}`,
          },
        },
        {
          header: { content: 'Date' },
          cell: {
            $type: 'custom',
            renderer: ({ receivedOn }) => <Format.Date>{receivedOn}</Format.Date>,
          },
        },
        {
          header: { content: 'Condition' },
          cell: {
            $type: 'custom',
            renderer: ({ productCondition }) => productCondition,
          },
        },
        {
          width: 100,
          header: { content: 'Link' },
          cell: {
            $type: 'custom',
            renderer: ({ url }) =>
              url && (
                <Link href={url} target="_blank">
                  <Icon type="externalLink" color="primary" />
                </Link>
              ),
          },
        },
      ],
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [fetchData, productIdentifierValue, sourceProvider])

  const modalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'Cancel',
        onClick: onClose,
        align: 'right',
      },
    ],
    [onClose],
  )

  return (
    <Modal
      isControlled
      show={isShow}
      title={title}
      size="M"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      <ModalContainer>
        <ControlBar>
          <TextContainer>
            <Text>All Entries</Text>
          </TextContainer>
          <Label type="default">{totalCount}</Label>
        </ControlBar>

        <Pagination
          loading={loading}
          page={page}
          pageSize={pageSize}
          rowCount={totalCount}
          onChange={onChangePagination}
        >
          <Table
            resizable
            height={500}
            loading={loading}
            data={tableData}
            suppressVirtualization={false}
            displaySettings={getTableSettings()}
          />
        </Pagination>
      </ModalContainer>
    </Modal>
  )
})
