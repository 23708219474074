import PropTypes from 'prop-types'
import { withTheme } from '@r1/ui-kit'
// eslint-disable-next-line import/no-cycle
import * as icons from './index'

const Icons = ({ name, ...props }) => {
  // eslint-disable-next-line import/namespace
  const renderIcon = icons[name] || icons.notFound
  return renderIcon(props)
}

Icons.propTypes = {
  name: PropTypes.string,
}

export default withTheme(Icons)
