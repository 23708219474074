/* eslint-disable react/no-unused-prop-types */
// @flow

import React from 'react'
import styled from '@emotion/styled'
import type { TestableProps } from '@r1/types/flow/libTypes'
import { Icon, useConfigurationProvider } from '@r1/ui-kit'
import type { IconType } from '@r1/ui-kit/build/types/Icon'

type ClearButtonProps = {
  ...TestableProps,
  children: React$Node,
  icon: IconType,
  disabled?: boolean,
  onRemove: () => void,
}

const ClearButtonContainer = styled('div')`
  position: relative;
  box-sizing: border-box;
`
const ClearIconButton = styled('button')`
  position: absolute;
  top: 0;
  right: 12px;
  padding: 0;
  outline: 0;
  margin: 4px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.palette.grey[500]};
  border: none;
  opacity: 0.7;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  ${({ theme }) => {
    return `
      transition: opacity ${theme.animationDuration} ${theme.animationTimingFunction};
    `
  }};
`

const IconButton = (props: ClearButtonProps) => {
  const { icon, onRemove } = props
  const { palette } = useConfigurationProvider()

  return (
    <ClearIconButton onClick={onRemove}>
      <Icon color={palette.white} type={icon} />
    </ClearIconButton>
  )
}

export const ClearButton = (props: ClearButtonProps) => {
  const { icon, children, disabled } = props

  return (
    <ClearButtonContainer>
      {icon && !disabled && IconButton(props)}
      {children}
    </ClearButtonContainer>
  )
}
