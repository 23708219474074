import * as React from 'react'
import { branch } from 'recompose'
import { TreeSelect } from '@r1/ui-kit'
import { withModule } from '../utils'
import * as categoriesModule from '../../modules/products/categories'
import * as catalogCategoriesModule from '../../modules/productCatalog/dataSources/categories'

const withCategories = withModule(categoriesModule)
const withCatalogCategories = withModule(catalogCategoriesModule)

const enhance = branch(({ dataSourceId }) => !!dataSourceId, withCatalogCategories, withCategories)

const CategoriesComponent = ({
  isFetching,
  value,
  onChange,
  items,
  dataSourceId,
  fetchAll,
  disabled,
  ignoreBranchNodes,
}) => {
  // Dirty hack - prop value must have a value | null, otherwise TreeSelect will break
  let newValue

  if (value === 0) {
    newValue = value
  } else if (value === undefined || value === '') {
    newValue = null
  } else {
    newValue = value
  }

  const isError =
    isFetching === false && newValue != null && items.filter(t => t.id === newValue).length === 0
  return (
    <TreeSelect
      disabled={disabled}
      loading={isFetching}
      placeholder="Add category"
      value={isError ? null : newValue}
      options={isFetching ? [] : items}
      error={isError && !!dataSourceId}
      ignoreBranchNodes={ignoreBranchNodes}
      onChange={onChange}
      onOpen={() => fetchAll({ dataSourceId })}
    />
  )
}

export const CategoriesSelect = enhance(CategoriesComponent)
