import * as React from 'react'
import { useMemo } from 'react'
import styled from '@emotion/styled'
import { FormField, Select, Flex, Button, Box } from '@r1/ui-kit'
import { ShippingOption as OptionType } from '@r1-webui/shipping-returnshippingrules-v1'
import { ChangeValueChildrenHandler } from '@r1/form-manager'
import { Profile } from '../../../../services/espmService'
import { ShippingCarrier } from '../../hooks/useShippingOptions'
import { FormValues } from '../../types'
import { Subheader } from './Subheader'
import { ShippingServicesLabel } from './ShippingServicesLabel'

const Wrapper = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
`

type Props = {
  index: number
  option: OptionType
  hideDeleteButton: boolean
  shippingProfiles: Profile[]
  shippingCarriers: ShippingCarrier[]
  thirdPartyShippingProfiles: Profile[]
  areShippingProfilesLoading: boolean
  areThirdPartyShippingProfilesLoading: boolean
  areShippingCarriersLoading: boolean
  getShippingOptionsError: () => string | null
  onChange: ChangeValueChildrenHandler<FormValues>
  onDeleteShippingOption: (index: number) => void
}

const getProfile = (shippingProfileId: string, shippingProfiles: Profile[]) => {
  const selectedProfileId = shippingProfileId
  const selectedProfile = shippingProfiles.find(({ id }) => id === selectedProfileId)
  return selectedProfile
}

export const ShippingOption = ({
  index,
  option,
  hideDeleteButton,
  shippingProfiles,
  shippingCarriers,
  thirdPartyShippingProfiles,
  areShippingProfilesLoading,
  areThirdPartyShippingProfilesLoading,
  areShippingCarriersLoading,
  getShippingOptionsError,
  onDeleteShippingOption,
  onChange,
}: Props) => {
  const thirdPartyShippingProfilesFiltered = useMemo(() => {
    const selectedProfile = getProfile(option.shippingProfileId, shippingProfiles)

    if (selectedProfile && selectedProfile.type.relatedProfileType) {
      const relatedProfileTypeId = selectedProfile.type.relatedProfileType.profileTypeId
      return thirdPartyShippingProfiles.filter(
        profile => profile.type.id?.toLowerCase() === relatedProfileTypeId?.toLowerCase(),
      )
    }

    return []
  }, [option.shippingProfileId, shippingProfiles, thirdPartyShippingProfiles])

  const shippingServicesOptions = useMemo(() => {
    const selectedProfile = getProfile(option.shippingProfileId, shippingProfiles)

    if (selectedProfile && selectedProfile.type.id) {
      return shippingCarriers.filter(
        ({ carrierId }) => carrierId?.toLowerCase() === selectedProfile.type.id?.toLowerCase(),
      )
    }
    return shippingCarriers
  }, [option.shippingProfileId, shippingCarriers, shippingProfiles])

  const shippingServicesDescription =
    option.shippingServices?.type === 'ExcludeShippingServices'
      ? 'Selected options will be excluded'
      : 'Selected options will be included'

  return (
    <Wrapper column mt="M" p="L" spaceBetween="S">
      <Flex align="center" justify="space-between" mb="L">
        <Subheader>Shipping Option {index + 1}</Subheader>
        <Box>
          {!hideDeleteButton && (
            <Button transparent onClick={() => onDeleteShippingOption(index)}>
              Delete
            </Button>
          )}
        </Box>
      </Flex>
      <FormField>
        <FormField.Label>Shipping Profile</FormField.Label>
        <Select
          data-test-id="shipping-profile"
          loading={areShippingProfilesLoading}
          value={option.shippingProfileId || null}
          options={shippingProfiles}
          clearable={false}
          error={!!getShippingOptionsError()}
          onChange={(shippingProfileId: string) =>
            /** @ts-ignore */
            onChange(['shippingOptions', index], {
              ...option,
              shippingProfileId,
              thirdPartyShippingProfileId: null,
              shippingServices: {
                type: 'ExcludeShippingServices',
                shippingServices: [],
              },
            })
          }
        />
        <FormField.Error>{getShippingOptionsError()}</FormField.Error>
      </FormField>
      <FormField>
        <FormField.Label>Third-Party Shipping Profile (Optional)</FormField.Label>
        <Select
          data-test-id="thirdPartyShippingProfileId"
          loading={areThirdPartyShippingProfilesLoading}
          value={option.thirdPartyShippingProfileId || null}
          options={thirdPartyShippingProfilesFiltered}
          onChange={(thirdPartyShippingProfileId: string) =>
            /** @ts-ignore */
            onChange(['shippingOptions', index], {
              ...option,
              thirdPartyShippingProfileId,
            })
          }
        />
      </FormField>
      {option.shippingServices && (
        <FormField>
          <FormField.Label>
            <ShippingServicesLabel
              option={option}
              index={index}
              /** @ts-ignore */
              onToggleStatus={(status: boolean) => onChange(['active'], status)}
              onChange={onChange}
            />
          </FormField.Label>
          <Select
            multiple
            value={shippingServicesOptions.length ? option.shippingServices?.shippingServices : []}
            loading={areShippingCarriersLoading}
            options={shippingServicesOptions}
            onChange={(shippingServices: string) =>
              /** @ts-ignore */
              onChange(['shippingOptions', index], {
                ...option,
                shippingServices: {
                  type: option.shippingServices?.type ?? [],
                  shippingServices,
                },
              })
            }
          />
          <FormField.Description>{shippingServicesDescription}</FormField.Description>
        </FormField>
      )}
    </Wrapper>
  )
}
