// @flow

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Pagination, Table } from '@r1/grid'
import { Label, Text, Link } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { ServiceContext } from '../../provider'

const TextContainer = styled('div')`
  padding-right: ${({ theme }) => theme.space.S};
  display: inline;
`

const ControlBar = styled('div')`
  height: 44px;
  margin: 23px 0 8px;
  display: flex;
  align-items: center;
`

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

type PricingRulesProps = {| getPricingRuleViewUrl: (pricingRuleId: string) => string |}

export const PricingRules = (props: PricingRulesProps) => {
  const { getPricingRuleViewUrl } = props
  const {
    priceService: { getPricingRulesInfo },
  } = useContext(ServiceContext)

  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [loading, setLoading] = useState(false)

  const onChangePagination = useCallback(
    ({ pageSize: newSize, page: newPage }) => {
      const startIdx = (newPage - 1) * newSize
      const endIdx = startIdx + newSize

      setPage(newPage)
      setPageSize(newSize)
      setTableData(data.slice(startIdx, endIdx))
    },
    [data],
  )

  const getTableSettings = useCallback(() => {
    return {
      columnsSettings: [
        {
          header: { content: 'Pricing Rule Name' },
          cell: {
            $type: 'custom',
            renderer: ({ priceRuleId, name }) => (
              <Link href={getPricingRuleViewUrl(priceRuleId)}>{name}</Link>
            ),
          },
        },
        {
          header: { content: 'Description' },
          cell: {
            $type: 'custom',
            renderer: ({ description }) => `${description}`,
          },
        },
      ],
    }
  }, [])

  const fetchData = useCallback(async () => {
    try {
      const res = await getPricingRulesInfo()
      setData(res)
      setTotalCount(res.length)
      setTableData(res.slice(DEFAULT_PAGE - 1, DEFAULT_PAGE_SIZE))
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }, [getPricingRulesInfo])

  useEffect(() => {
    setLoading(true)
    fetchData().then(() => {
      setLoading(false)
    })
  }, [fetchData])

  return (
    <>
      <ControlBar>
        <TextContainer>
          <Text weight="medium">All Rules</Text>
        </TextContainer>
        <Label type="default">
          <Text type="paragraph">{totalCount}</Text>
        </Label>
      </ControlBar>
      <Pagination
        loading={loading}
        page={page}
        pageSize={pageSize}
        rowCount={totalCount}
        onChange={onChangePagination}
      >
        <Table
          resizable
          suppressVirtualization
          loading={loading}
          data={tableData}
          displaySettings={getTableSettings()}
        />
      </Pagination>
    </>
  )
}
