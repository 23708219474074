import { Address } from '@r1-webui/facilities-v1'

export const defaultActivePanels = [
  'mainSettings',
  'addresses',
  'schedule',
  'nonWorkingDays',
  'shifts',
  'shippingCutOffTimes',
]

export const defaultMasterProgramActivePanels = ['mainSettings', 'filters']

export const defaultEditAdresses: Array<Address> = [
  {
    addressType: 'Physical',
  },
  {
    addressType: 'Shipping',
  },
]
