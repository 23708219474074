import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { ContentHeader } from '@r1/wireframe-primary'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Modal, Flex, Button, DualList, Collapse, Placeholder, Text, H3 } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

import type { BaseUserAssignRolesProps } from '../../../types/UserAssignRoles'
import type { Role, MultiselectField } from '../../../types/common'
import { DualListContainer } from '../../../components/common'

const CollapseContainer = styled('div')`
  width: 100%;
`

const getFullSelectedData = (fieldData: MultiselectField<Role>): Role[] => {
  const { selected, availableOptions } = fieldData
  return availableOptions.filter(({ id }: Role) => selected.includes(id))
}

const RolesPlaceholder = () => {
  const [{ allowUserAssignRole }] = useAccessControl()

  return (
    <Flex justify="space-between" pt="XL">
      <Placeholder type="form" height={5} />
      <ContentHeader.ActionButtons>
        <Button disabled color="secondary">
          Close
        </Button>
        {allowUserAssignRole && (
          <Button disabled color="primary">
            Edit
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </Flex>
  )
}

const ReadOnlyRoles = ({ roles }: { roles: MultiselectField<Role> }) => {
  const selectedRoles = getFullSelectedData(roles)

  return (
    <CollapseContainer>
      <Collapse accordion={false}>
        {selectedRoles.map(role => (
          <Collapse.Panel key={role.id} id={role.id} title={role.name}>
            {role.description ? (
              <React.Fragment>
                <H3>Role description</H3>
                <Text>{role.description}</Text>
              </React.Fragment>
            ) : (
              'No description'
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    </CollapseContainer>
  )
}

const EditableRoles = ({ roles }: { roles: MultiselectField<Role> }) => (
  <DualListContainer>
    <DualList
      options={roles.availableOptions}
      value={roles.selected}
      orderable={false}
      data-test-id="um-edit-user-assign-roles"
      // @ts-expect-error
      onChange={roles.onChange}
    />
  </DualListContainer>
)

type Props = BaseUserAssignRolesProps & {
  isActiveTab: boolean
}

const Roles = (props: Props) => {
  const { roles, formActions, formEditMode, hasUnsavedChanges, isActiveTab } = props
  const UserRolesView = formEditMode ? EditableRoles : ReadOnlyRoles
  const [{ allowUserAssignRole }] = useAccessControl()

  const modalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'NO',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'YES',
          onClick: () => {
            formActions.switchOffEditMode.dispatch()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [formActions],
  )

  return (
    <Flex column minWidth={1100}>
      <Flex justify="space-between" pt="XL" pb="XXL">
        <UserRolesView roles={roles} />
      </Flex>
      {isActiveTab && (
        <ContentHeader.ActionButtons>
          {formActions.cancelForm.visible && (
            <Button
              color="secondary"
              disabled={formActions.cancelForm.disabled}
              onClick={formActions.cancelForm.dispatch}
            >
              Close
            </Button>
          )}

          {formActions.switchOffEditMode.visible && (
            <Modal
              isControlled={false}
              title="You have unsaved data"
              actionButtons={modalActionButtons}
              buttonElement={({ onOpen }) => (
                <Button
                  color="secondary"
                  disabled={formActions.switchOffEditMode.disabled}
                  onClick={() => {
                    hasUnsavedChanges ? onOpen() : formActions.switchOffEditMode.dispatch()
                  }}
                >
                  Cancel
                </Button>
              )}
            >
              Are you sure you want to discard unsaved data?
            </Modal>
          )}
          {formActions.switchOnEditMode.visible && allowUserAssignRole && (
            <Button
              color="primary"
              disabled={formActions.switchOnEditMode.disabled}
              onClick={formActions.switchOnEditMode.dispatch}
            >
              Edit
            </Button>
          )}

          {formActions.saveRoles.visible && (
            <Button
              color="primary"
              disabled={formActions.saveRoles.disabled}
              onClick={formActions.saveRoles.dispatch}
            >
              Save
            </Button>
          )}
        </ContentHeader.ActionButtons>
      )}
    </Flex>
  )
}

export { Roles, RolesPlaceholder }
