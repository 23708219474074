// @flow

import styled from '@emotion/styled'

import { Flex } from '@r1/ui-kit'

import { Property as CommonProperty } from '../StyledComponents'

export const ClaimTotal = styled(Flex)`
  flex-direction: column;
  position: absolute;
  padding: ${({ theme }) => theme.space.L};
  right: 0;
  margin-right: 0;
`

export { InfoBlockTitle } from '../StyledComponents'

export const Property = styled(CommonProperty)`
  font-size: ${({ theme }) => theme.space.S};
`

export const Value = styled('span')`
  font-size: ${({ theme }) => theme.space.L};
`
