import { Action, Ids } from '../constants'
import { update, addToArray, removeFromArray } from '../utils/reducerUtils'

const BLOCKS_PROPERTY = 'blocks'

const initialState = {
  id: '',
  name: '',
  templateModel: {},
  pageSetting: {
    width: 5,
    height: 5,
  },
  blocks: [],
  selectedBlockId: Ids.DRAG_CONTAINER_ID,
  zoom: 1,
  images: [],
}

function addBlock(state, action) {
  return {
    ...addToArray(state, [BLOCKS_PROPERTY], action.block),
    selectedBlockId: action.block.id,
  }
}

function removeBlock(state, action) {
  return {
    ...removeFromArray(state, [BLOCKS_PROPERTY, action.id]),
    selectedBlockId: Ids.DRAG_CONTAINER_ID,
  }
}

function selectBlock(state, action) {
  return {
    ...state,
    selectedBlockId: action.id,
  }
}

function changeBlockTransform(state, action) {
  return update(state, [BLOCKS_PROPERTY, action.id], block => {
    const newBlock = {
      ...block,
      position: action.transform.position || block.position,
      rotation:
        typeof action.transform.rotation !== 'undefined'
          ? action.transform.rotation
          : block.rotation,
      size: action.transform.size || block.size,
    }

    return newBlock
  })
}

function changeBlockProperty(state, action) {
  return update(state, [BLOCKS_PROPERTY, action.id, ...action.propsIdsPath], () => action.value)
}

function addBlockValue(state, action) {
  return addToArray(state, [BLOCKS_PROPERTY, action.id, ...action.propsIdsPath], action.value)
}

function removeBlockValue(state, action) {
  return removeFromArray(state, [
    BLOCKS_PROPERTY,
    action.id,
    ...action.propsIdsPath,
    action.valueId,
  ])
}

function changeBlockValue(state, action) {
  return update(
    state,
    [BLOCKS_PROPERTY, action.id, ...action.propsIdsPath, action.value.id],
    value => ({
      ...value,
      ...action.value,
    }),
  )
}

// eslint-disable-next-line default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case Action.CLEAR_TEMPLATE_MODELS:
      return { ...initialState }

    case Action.CREATE_DOCUMENT_TEMPLATE:
      return { ...state, id: action.id }

    case Action.LOAD_DOCUMENT_TEMPLATE:
      return { ...state, ...action.template }

    case Action.CHANGE_ZOOM:
      return { ...state, zoom: action.zoom }

    case Action.ADD_BLOCK:
      return addBlock(state, action)

    case Action.REMOVE_BLOCK:
      return removeBlock(state, action)

    case Action.SELECT_BLOCK:
      return selectBlock(state, action)

    case Action.CHANGE_BLOCK_TRANSFORM:
      return changeBlockTransform(state, action)

    case Action.CHANGE_BLOCK_PROPERTY:
      return changeBlockProperty(state, action)

    case Action.ADD_BLOCK_VALUE:
      return addBlockValue(state, action)

    case Action.REMOVE_BLOCK_VALUE:
      return removeBlockValue(state, action)

    case Action.CHANGE_BLOCK_VALUE:
      return changeBlockValue(state, action)

    case Action.CHANGE_NAME:
      return { ...state, name: action.name }

    case Action.CHANGE_TEMPLATE_MODEL:
      return { ...state, templateModel: action.templateModel }

    case Action.CHANGE_PAGE_SETTING:
      return { ...state, pageSetting: action.pageSetting }

    default:
      return state
  }
}
