// @flow

import * as React from 'react'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import { Box, Button, Flex, Icon, Modal, Select, Tooltip } from '@r1/ui-kit'
import { useCallback, useState } from 'react'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import type { ExpectedDecision } from '@r1-webui/rmavendorportal-v1/src/types'
import type { DecisionTypeData } from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { ClaimInfo, OrderInfo } from '../../types/common'
import { getClaimType } from '../../utils'
import { ClaimLine } from './ClaimLine'

type Props = {
  claim: ClaimInfo,
  order: OrderInfo,
  availableDecisionTypes: ExpectedDecision[],
  onDecisionTypeSelection: (
    decisionTypeId: Lib$Id,
    decisionData: DecisionTypeData[],
  ) => Promise<void>,
}

const getInitialDecisionData = (claim, order) =>
  claim.claimItems.map((claimItem): DecisionTypeData => {
    const orderLine = order.orderLines.find(line => line.lineId === claimItem.orderLineId)
    if (!orderLine) {
      return {
        claimItemId: claimItem.id,
        quantity: 0,
        refund: {
          amount: '0.00',
          currency: 'USD',
        },
      }
    }
    return {
      claimItemId: claimItem.id,
      quantity: orderLine.lineInfo.quantity,
      refund: {
        amount: (
          parseFloat(orderLine.lineInfo.singleItemPrice.total.amount) * orderLine.lineInfo.quantity
        ).toFixed(2),
        currency: orderLine.lineInfo.singleItemPrice.total.currency,
      },
    }
  })

export const ExpectedDecisionTypeModal = (props: Props) => {
  const { claim, order, availableDecisionTypes, onDecisionTypeSelection } = props

  const decisionTypesWithoutReplacement = availableDecisionTypes.filter(
    type => !type.title.toUpperCase().includes('replacement'.toUpperCase()),
  )
  const [currentDecisionTypeId, setCurrentDecisionTypeId] = useState(
    decisionTypesWithoutReplacement[0].id,
  )
  const claimType = React.useMemo(
    () => getClaimType(currentDecisionTypeId),
    [currentDecisionTypeId],
  )
  const [loading, setLoading] = useState(false)
  const [decisionData, setDecisionData] = useState(getInitialDecisionData(claim, order))

  const onRefundChange = useCallback(
    (claimItemId, value) => {
      const newDecisionData = decisionData.map(data => {
        if (data.claimItemId === claimItemId) {
          return {
            ...data,
            refund: {
              ...data.refund,
              amount: value,
            },
          }
        }
        return data
      })
      setDecisionData(newDecisionData)
    },
    [decisionData],
  )

  const onQuantityChange = useCallback(
    (claimItemId, value) => {
      const newDecisionData = decisionData.map(data => {
        if (data.claimItemId === claimItemId) {
          return {
            ...data,
            quantity: value,
          }
        }
        return data
      })
      setDecisionData(newDecisionData)
    },
    [decisionData],
  )

  const setDecisionType = useCallback(
    (onClose: () => void) => async () => {
      const decisionDataToSave = decisionData.map(data => {
        return {
          ...data,
          quantity: claimType !== 'RefundOnly' ? data.quantity : 0,
          refund: {
            ...data.refund,
            amount: claimType === 'RefundOnly' ? data.refund.amount : '0.00',
          },
        }
      })
      setLoading(true)
      try {
        await onDecisionTypeSelection(currentDecisionTypeId, decisionDataToSave)
        onClose()
      } finally {
        setLoading(false)
      }
    },
    [onDecisionTypeSelection, currentDecisionTypeId, decisionData, claimType],
  )

  const modalActionButtons = useCallback(
    ({ onClose }): BottomActionButton[] => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: onClose,
          align: 'right',
        },
        {
          title: 'Proceed',
          onClick: setDecisionType(onClose),
          loading,
          disabled: loading,
          align: 'right',
        },
      ]
    },
    [loading, setDecisionType],
  )

  return (
    <Modal
      size="L"
      isControlled={false}
      actionButtons={modalActionButtons}
      title="Claim Decision"
      description="Please enter what decision will be used for the claim:"
      buttonElement={({ onOpen }) => (
        <Tooltip text="Edit Decision">
          <Button transparent shape="square" size="S" color="secondary" onClick={onOpen}>
            <Icon type="pencil" />
          </Button>
        </Tooltip>
      )}
    >
      <Flex column spaceBetween="XXL">
        <Flex align="flex-start">
          <Box>
            <Select
              options={decisionTypesWithoutReplacement}
              value={currentDecisionTypeId}
              valueKey="id"
              labelKey="title"
              onChange={value => setCurrentDecisionTypeId(value)}
            />
          </Box>
        </Flex>
        {claim.claimItems.map(claimItem => {
          const orderLine = order.orderLines.find(line => line.lineId === claimItem.orderLineId)
          const claimLineData = decisionData.find(line => line.claimItemId === claimItem.id)
          if (!orderLine || !claimLineData) return null

          return (
            <ClaimLine
              key={claimItem.id}
              claimLineData={claimLineData}
              orderLine={orderLine}
              decisionType={claimType}
              onRefundChange={onRefundChange}
              onQuantityChange={onQuantityChange}
            />
          )
        })}
      </Flex>
    </Modal>
  )
}
