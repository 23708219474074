// @flow

import { format } from 'date-fns'

export function randomString(length: number = 10) {
  let text = ''
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }

  return `${text}_${format(new Date(), 'MM_dd_yy')}`
}

type LongPollingArgs<D> = {
  asyncCall: () => Promise<D>,
  stopWhen: D => boolean,
  pollingInterval: number,
}

// eslint-disable-next-line no-promise-executor-return
const sleep = time => new Promise(resolve => setTimeout(resolve, time))

export function longPolling<D>({
  asyncCall,
  stopWhen,
  pollingInterval,
}: LongPollingArgs<D>): Promise<D> {
  return asyncCall().then(res =>
    stopWhen(res)
      ? res
      : sleep(pollingInterval).then(() => longPolling({ asyncCall, stopWhen, pollingInterval })),
  )
}
