import React, { useCallback } from 'react'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Button, Icon, Modal } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'

type Props = {
  close: () => void
  switchToEditMode: () => void
  openHistory: () => void
  deleteRole: () => void
}

export const ActionButtonsViewMode: React.FC<Props> = ({
  close,
  switchToEditMode,
  openHistory,
  deleteRole,
}) => {
  const [{ allowRoleEdit, allowRoleDelete }] = useAccessControl()
  const modalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'Cancel',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'Delete',
          onClick: () => {
            deleteRole()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [deleteRole],
  )

  return (
    <>
      <Button color="secondary" onClick={close}>
        Close
      </Button>

      {allowRoleEdit && (
        <Button color="primary" onClick={switchToEditMode}>
          Edit
        </Button>
      )}

      {allowRoleDelete && (
        <Modal
          isControlled={false}
          title="Warning"
          actionButtons={modalActionButtons}
          buttonElement={({ onOpen }) => (
            <Button transparent color="secondary" shape="square" onClick={onOpen}>
              <Icon type="trash" />
            </Button>
          )}
        >
          Are you sure you want to delete role?
        </Modal>
      )}

      <Button transparent color="secondary" shape="square" onClick={openHistory}>
        <Icon type="history" />
      </Button>
    </>
  )
}
