import { createModelModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateIdentifiersApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, actions, selectors } = createModelModule({
  name: '@productTemplates/identifiers',
  params: ['id'],
  fetchItemFunc: async ({ id }, _api) =>
    productTemplateIdentifiersApi.getAdditionalIdentifiers({ id }).then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { actions, selectors }
