// @flow

import * as React from 'react'

type ServiceData = {|
  variationsService: $AnyObject,
  imageService: $AnyObject,
  attributesService: $AnyObject,
  httpClient: $AnyObject,
|}

export const ServiceContext: React.Context<ServiceData> = React.createContext({
  variationsService: {},
  imageService: {},
  attributesService: {},
  httpClient: {},
})
