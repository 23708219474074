import { createCollectionModule, mapReducers } from '../utils'
import { createReducer } from '../../redux/utils'
import { productTemplateConsigneeUpcApi } from '../../api/productTemplate/index.ts'

const { initialState, types, reducers, name, actions, selectors } = createCollectionModule({
  name: '@productTemplates/consigneeUpc',
  keyField: 'id',
  params: ['id'],
  fetchAllFunc: async ({ id }, _api) =>
    productTemplateConsigneeUpcApi.getAll({ id }).then(({ body }) => body),
})

export default {
  [name]: createReducer(initialState, mapReducers(types, reducers)),
}

export { actions, selectors }
