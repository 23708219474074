/* eslint-disable no-underscore-dangle */
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'

import { GenericIconsProvider, UserStateProvider } from '@r1/core-blocks'
import { ConfigurationProvider, Link } from '@r1/ui-kit'

import { isRouterLink } from './modules/menu'
import { App, reducers } from './routes/App'
import { setDialogDispatch } from './redux/dialogs'
import { showDialog } from './routes/App/module'
import { httpClient } from './httpClient'
import { NoPermission } from './routes/NoPermission/NoPermission'
import { createErrorMiddleware } from './redux/utils'

const container = document.getElementById('react')
const root = createRoot(container)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  combineReducers({
    ...reducers,
  }),
  composeEnhancers(
    applyMiddleware(createErrorMiddleware(), thunk.withExtraArgument({ client: httpClient })),
  ),
)

const { dispatch } = store

setDialogDispatch(options => dispatch(showDialog(options)))

const UniversalRouterLink = React.memo(props => {
  if (props.href) {
    if (isRouterLink(props.href)) {
      return <RouterLink to={props.href} {...props} />
    }
    return <a {...props} />
  }
  if (props.to) {
    if (isRouterLink(props.to)) {
      return <RouterLink {...props} to={props.to} />
    }
    const { to, ...otherProps } = props
    return <a href={to} {...otherProps} />
  }
  return <span {...props} />
})

Link.setLinkComponent(UniversalRouterLink)

const page403Renderer = () => <NoPermission />

if (container) {
  root.render(
    <Provider store={store}>
      <ConfigurationProvider>
        <GenericIconsProvider httpClient={httpClient}>
          <UserStateProvider httpClient={httpClient} page403Renderer={page403Renderer}>
            <App />
          </UserStateProvider>
        </GenericIconsProvider>
      </ConfigurationProvider>
    </Provider>,
  )
}
