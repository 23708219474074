// @flow

import * as React from 'react'

import { ContentHeader } from '@r1/wireframe-primary'
import { Box, Button, Flex, Stepper } from '@r1/ui-kit'

import type { StepsProps } from '../types'

import { OrderSelectionStep } from './OrderSelectionStep'
import { ClaimInfoStep } from './ClaimInfoStep'
import { ClaimDetailsStep } from './ClaimDetailsStep'
import { SummaryStep } from './SummaryStep'

export const Steps = (props: StepsProps) => {
  const { stepInfo, formActions, hasUnsavedChanges } = props

  const { steps, currentStep } = stepInfo
  const currentStepIdx = steps.findIndex(step => step.id === currentStep)
  const stepsProps = steps.map((step, idx) => {
    const { id, ...stepProps } = step
    return {
      ...stepProps,
      complete: idx < currentStepIdx,
    }
  })

  return (
    <>
      <Flex column pt="M">
        <Box mb="XXL">
          <Stepper currentStep={currentStepIdx}>
            <Stepper.Step {...stepsProps[0]} title="Order Selection" />
            <Stepper.Step {...stepsProps[1]} title="Claim Info" />
            <Stepper.Step {...stepsProps[2]} title="Claim Details" />
            <Stepper.Step {...stepsProps[3]} title="Summary" />
          </Stepper>
        </Box>
        {currentStepIdx === 0 && <OrderSelectionStep {...props} />}
        {currentStepIdx === 1 && <ClaimInfoStep {...props} />}
        {currentStepIdx === 2 && <ClaimDetailsStep {...props} />}
        {currentStepIdx === 3 && <SummaryStep {...props} />}
      </Flex>
      <ContentHeader.ActionButtons>
        {formActions.cancelForm.visible && (
          <Button
            transparent
            data-test-id="rma-create-claim-cancel-btn"
            color="secondary"
            disabled={formActions.cancelForm.disabled}
            onClick={hasUnsavedChanges ? props.cancelCreation : formActions.cancelForm.dispatch}
          >
            Cancel
          </Button>
        )}

        <Button
          data-test-id="rma-create-claim-prevstep-btn"
          color="secondary"
          disabled={formActions.prevStep.disabled}
          onClick={formActions.prevStep.dispatch}
        >
          Previous
        </Button>

        {formActions.nextStep.visible && (
          <Button
            data-test-id="rma-create-claim-nextstep-btn"
            color="primary"
            disabled={formActions.nextStep.disabled}
            onClick={formActions.nextStep.dispatch}
          >
            Next
          </Button>
        )}

        {formActions.submitForm.visible && (
          <Button
            data-test-id="rma-create-claim-save-btn"
            color="primary"
            loading={formActions.submitForm.isExecuting}
            onClick={formActions.submitForm.dispatch}
          >
            Save
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </>
  )
}
