import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { propOr, pathOr } from 'ramda'
import { Field } from 'redux-form'
import { Link, FormField, Flex, Text } from '@r1/ui-kit'
import styled from '@emotion/styled'
import { selectors } from '../../../../modules/products'
import { renderInput, renderTextarea, renderUpcInfo } from '../../fieldRenderer'
import { ProductImages } from './ProductImages'
import { ProductField } from './ProductField'

const mapStateToProps = (state, { productId }) => ({
  productCard: selectors.getItem(state, { id: productId }),
})

const enhance = compose(connect(mapStateToProps))

const Label = styled(FormField.Label)`
  width: 320px;
`

const MainInfoComponent = ({ productCard, productId }) => {
  const companyProductId = propOr('None', 'companyProductId', productCard)
  const facility = propOr('None', 'facility', productCard)
  const locationName = pathOr('None', ['location', 'name'], productCard)
  const orderId = propOr('', 'orderId', productCard)
  const rmaClaimId = propOr('', 'rmaClaimId', productCard)
  const movingInfo = propOr(null, 'movingInfo', productCard)

  return (
    <Flex column maxWidth={1011} spaceBetween="XL">
      <Flex align="center">
        <Label>Product #</Label>
        <ProductField companyProductId={companyProductId} movingInfo={movingInfo} />
      </Flex>
      <Field
        label="Template identifier"
        name="upcInfo"
        productId={productId}
        component={renderUpcInfo}
      />
      <Flex align="center">
        <Label>Program</Label>
        <Text>{facility}</Text>
      </Flex>
      <Flex align="center">
        <Label>Location</Label>
        <Text>{locationName}</Text>
      </Flex>
      <Field label="Serial number" name="serialNumber" component={renderInput} />
      <Field label="SLP" name="slp" component={renderInput} />
      <Flex align="center">
        <Label>Product images</Label>
        <ProductImages productId={productId} />
      </Flex>
      <Flex align="center">
        <Label>Order #</Label>
        {orderId ? (
          <Link href={`/Modules/B2B/OrderDetails.aspx?id=${orderId}`}>{orderId}</Link>
        ) : (
          <Text>None</Text>
        )}
      </Flex>
      <Flex align="center">
        <Label>RMA claim #</Label>
        {rmaClaimId ? (
          <Link href={`/Modules/Orders/RMADetails.aspx?id=${rmaClaimId}`}>{rmaClaimId}</Link>
        ) : (
          <Text>None</Text>
        )}
      </Flex>
      <Field label="Internal note" name="internalNote" component={renderTextarea} />
    </Flex>
  )
}

export const MainInfo = enhance(MainInfoComponent)
