// @flow

import * as React from 'react'

import { Box, Flex, FormField, NumericInput, Text } from '@r1/ui-kit'

import { DiscountStep as DiscountStepType } from '../types'

type Props = {
  value: DiscountStepType
  minValue: DiscountStepType | null
  maxValue: DiscountStepType | null
  onChange: (step: DiscountStepType) => void
}

type InexactDiscountStep = Partial<DiscountStepType>

export const DiscountStep = (props: Props) => {
  const { minValue, maxValue, onChange } = props

  const minCount = minValue ? minValue.count : 2
  const minPercent = minValue ? minValue.percent : 0

  const maxCount = maxValue ? maxValue.count : 100000
  const maxPercent = maxValue ? maxValue.percent : 99

  const handleChange = (changes: InexactDiscountStep) => {
    onChange({
      ...props.value,
      ...changes,
    })
  }

  return (
    <Flex mr="L">
      <Flex spaceBetween="XXS" align="center">
        <FormField.Label>For quantity of</FormField.Label>
        <Box maxWidth={110} ml="L">
          <NumericInput
            min={minCount}
            max={maxCount}
            value={props.value.count}
            onChange={value => handleChange({ count: value === null ? 0 : parseInt(value, 10) })}
          />
        </Box>
      </Flex>
      <Flex spaceBetween="XXS" align="center" ml="L">
        <FormField.Label>or higher apply discount of</FormField.Label>
        <Box maxWidth={195} ml="L" mr="L">
          <NumericInput
            min={minPercent}
            max={maxPercent}
            value={props.value.percent}
            onChange={value => handleChange({ percent: value === null ? 0 : parseInt(value, 10) })}
          />
        </Box>
        <Text>%</Text>
      </Flex>
    </Flex>
  )
}
