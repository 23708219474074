import { prop, compose, reject, map, test, identity, filter } from 'ramda'

const createRegExp = regex => {
  const reg = regex.substring(1, regex.length - 1)
  return new RegExp(reg)
}

const isRequired = a => a !== null && a !== undefined && a.toString().length > 0
const requiredRule = [isRequired, 'Field is required.']

const createRule = rule => [test(createRegExp(rule.regex)), rule.errorDescription]

const createRules = compose(map(createRule), reject(prop('applicableInProgress')))

export const createValidationRules = fields => {
  if (!Array.isArray(fields)) return {}

  return fields.reduce((acc, field) => {
    acc[field.id] = [field.required && requiredRule, ...createRules(field.validation || [])].filter(
      identity,
    )

    return acc
  }, {})
}

export const createRuntimeInputConstraint = fields => {
  if (!Array.isArray(fields)) return {}

  return fields.reduce((acc, field) => {
    const constrait = compose(
      map(compose(test, createRegExp, prop('regex'))),
      filter(prop('applicableInProgress')),
    )(field.validation)

    if (constrait.length) {
      acc[field.id] = constrait
    }

    return acc
  }, {})
}
