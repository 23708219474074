import * as React from 'react'
import { DatePicker, Textarea, Input, Flex, FormField } from '@r1/ui-kit'
import { UpcField } from './blocks/MainInfo/UpcField'
import { Label } from './components'

function createFieldRenderer(Control) {
  return ({ input: { value, onChange }, label, meta: { touched, error }, ...props }) => (
    <Flex align="center">
      <Label>{label}</Label>
      <Flex column basis={1}>
        <Control
          value={value}
          error={touched ? !!error : undefined}
          onChange={onChange}
          {...props}
        />
        <FormField.Error>{error}</FormField.Error>
      </Flex>
    </Flex>
  )
}

export const renderUpcInfo = createFieldRenderer(UpcField)
export const renderInput = createFieldRenderer(Input)
export const renderDatePicker = createFieldRenderer(DatePicker)
export const renderTextarea = createFieldRenderer(Textarea)
