// @flow

import {
  createImageManagementApi,
  baseUri as productManagementBaseUri,
} from '@r1-webui/productcatalog-productmanagement-v1'

import { UI_API_PREFIXES } from '../constants'
import type { WebUiApi } from './types'

export const imageApi = (httpClient: any): WebUiApi => {
  const { uploadImages } = createImageManagementApi({
    http: httpClient,
    urlPrefix: `${UI_API_PREFIXES.CATALOG_MANAGEMENT}/${productManagementBaseUri}`,
  })

  return {
    uploadImages,
  }
}
