// @flow

import * as React from 'react'
import { Format, Flex, DatePicker } from '@r1/ui-kit'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { handleServerError } from '@r1/core-blocks'
import { Table } from '@r1/grid'
import { compareAsc, compareDesc } from 'date-fns'
import { getClaimStateText, getClaimType } from '../../utils'
import { createApi } from '../../api/api'
import { httpClient } from '../../../../httpClient'
import { FormattedLink } from './StyledComponents'

type ClaimHistoryProps = {|
  showDatePicker?: boolean,
  purchaseOrderId?: string,
  rmaProfileId?: string,
  customerEmail?: string,
|}

const getDisplaySettings = () => ({
  columnsSettings: [
    {
      width: 120,
      header: {
        content: 'Claim Id',
      },
      cell: {
        $type: 'custom',
        renderer: ({ claimId }) => (
          <FormattedLink href={claimId} target="_blank">
            {claimId}
          </FormattedLink>
        ),
      },
    },
    {
      width: 120,
      header: {
        content: 'Date Created',
        sorting: {
          field: 'createdOn',
          type: 'none',
        },
      },
      cell: {
        $type: 'custom',
        renderer: ({ createdOn }) => <Format.Date>{createdOn}</Format.Date>,
      },
    },
    {
      width: 170,
      header: {
        content: 'RequestedDecision',
      },
      cell: {
        $type: 'custom',
        // $FlowFixMe[incompatible-call]
        renderer: ({ expectedDecisionTypeId }) => <div>{getClaimType(expectedDecisionTypeId)}</div>,
      },
    },
    {
      width: 120,
      header: {
        content: 'Claim Amount',
      },
      cell: {
        $type: 'custom',
        renderer: ({ amount }) => <div>{amount ? `${amount.currency}${amount.amount}` : ''}</div>,
      },
    },
    {
      width: 170,
      header: {
        content: 'Claim Type',
      },
      cell: {
        $type: 'text',
        field: 'sourceType',
      },
    },
    {
      width: 120,
      header: {
        content: 'Claim Status',
      },
      cell: {
        $type: 'custom',
        renderer: ({ state }) => <div>{getClaimStateText(state)}</div>,
      },
    },
    {
      width: 250,
      header: {
        content: 'Product Title',
      },
      cell: {
        $type: 'text',
        field: 'productTitle',
      },
    },
  ],
})

export const ClaimHistory = (props: ClaimHistoryProps) => {
  const { customerEmail, purchaseOrderId, rmaProfileId, showDatePicker } = props
  const api = useMemo(() => createApi(httpClient), [])

  const [claimHistoryInfo, setClaimHistoryInfo] = useState([])
  const [claimHistoryInfoView, setClaimHistoryInfoView] = useState([])
  const [isClaimHistorySearchExecuting, setIsClaimHistorySearchExecuting] = useState(false)
  const [displaySettings, setDisplaySettings] = useState(getDisplaySettings())

  useEffect(() => {
    function getFetchFunction() {
      if (purchaseOrderId && rmaProfileId)
        return api.claimHistory.getClaimHistoryByPurchaseOrder({
          rmaProfileId,
          purchaseOrderId,
        })
      return api.claimHistory.getClaimHistoryByCustomer({
        customerEmail: customerEmail ?? '',
      })
    }

    async function fetchClaimHistory() {
      setIsClaimHistorySearchExecuting(true)
      const response = await getFetchFunction()
      if (response.status !== 200) {
        handleServerError(response)
        return
      }
      setClaimHistoryInfo(response.body)
      setClaimHistoryInfoView(response.body)
      setIsClaimHistorySearchExecuting(false)
    }
    fetchClaimHistory()
  }, [api.claimHistory, customerEmail, purchaseOrderId, rmaProfileId])

  const clearDateFilter = useCallback(() => {
    setClaimHistoryInfoView(claimHistoryInfo)
  }, [claimHistoryInfo])

  const filterByDate = useCallback((claimHistoryItem, dateString: string, older) => {
    const comparisonResult = compareAsc(new Date(claimHistoryItem.createdOn), new Date(dateString))

    if (comparisonResult === 0) return true

    if (older && comparisonResult === 1) return true

    return !older && comparisonResult === -1
  }, [])

  const applyDateFilter = useCallback(
    value => {
      if (value) {
        const { from, to } = value
        let filteredClaimHistory = claimHistoryInfo
        if (from) {
          filteredClaimHistory = filteredClaimHistory.filter(claimHistoryItem =>
            filterByDate(claimHistoryItem, from, true),
          )
        }
        if (to) {
          filteredClaimHistory = filteredClaimHistory.filter(claimHistoryItem =>
            filterByDate(claimHistoryItem, to, false),
          )
        }
        setClaimHistoryInfoView(filteredClaimHistory)
      }
    },
    [claimHistoryInfo, filterByDate],
  )

  const compareByDate = useCallback((claimHistoryItemA, claimHistoryItemB, orderDirection) => {
    if (orderDirection === 'asc')
      return compareAsc(
        new Date(claimHistoryItemA.createdOn),
        new Date(claimHistoryItemB.createdOn),
      )
    return compareDesc(new Date(claimHistoryItemA.createdOn), new Date(claimHistoryItemB.createdOn))
  }, [])

  const onChangeSorting = useCallback(
    ({ type, field }) => {
      setClaimHistoryInfoView(
        claimHistoryInfoView.slice().sort((i1, i2) => compareByDate(i1, i2, type)),
      )
      setClaimHistoryInfo(claimHistoryInfo.slice().sort((i1, i2) => compareByDate(i1, i2, type)))

      const columnsSettings = displaySettings.columnsSettings.map(x => {
        if (x.header.sorting && x.header.sorting.field === 'createdOn') {
          return {
            ...x,
            header: {
              ...x.header,
              sorting: {
                type,
                field,
              },
            },
          }
        }
        return x
      })

      setDisplaySettings({ ...displaySettings, columnsSettings })
    },
    [claimHistoryInfo, claimHistoryInfoView, compareByDate, displaySettings],
  )

  return (
    <div>
      {showDatePicker && (
        <Flex mb="XL">
          <DatePicker range onChange={applyDateFilter} onRemove={clearDateFilter} />
        </Flex>
      )}
      <Table
        suppressVirtualization
        loading={isClaimHistorySearchExecuting}
        data={claimHistoryInfoView}
        displaySettings={displaySettings}
        onChangeSorting={onChangeSorting}
      />
    </div>
  )
}
