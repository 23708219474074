/* eslint-disable flowtype/no-types-missing-file-annotation */
import * as React from 'react'
import styled from '@emotion/styled'

import { Table } from '@r1/grid'
import { Button, Checkbox, Icon, Link, Tooltip } from '@r1/ui-kit'

import { MoneyCell } from '../Cells/MoneyCell'
import { ActionsCell } from '../Cells/ActionsCell'
import { DateCell } from '../Cells/DateCell'
import { BooleanCell } from '../Cells/BooleanCell'

const BreakText = styled('div')`
  word-break: break-all;
  word-break: break-word;
`

const columnsSettings = [
  {
    width: 36,
    header: {
      content: '',
    },
    cell: {
      $type: 'custom',

      renderer: dataItem => (
        <Checkbox
          disabled={!dataItem.canBeSubmitted}
          checked={dataItem.isChosen}
          onChange={dataItem.onSelect({
            purchaseOrderUniqueId: {
              purchaseOrderId: dataItem.purchaseOrderId,
              venueProfileId: dataItem.venueProfileId,
            },
            taxCommitmentDocumentIds: dataItem.taxCommitmentDocuments.map(
              doc => doc.taxCommitmentDocumentId,
            ),
          })}
        />
      ),
    },
  },
  {
    width: 320,
    header: {
      content: 'Marketplace PO',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <Tooltip text="Open R1 Orders">
          <BreakText>
            <Link
              onClick={() =>
                dataItem.openR1Orders({
                  venueProfileId: dataItem.venueProfileId,
                  purchaseOrderId: dataItem.purchaseOrderId,
                })
              }
            >
              {dataItem.purchaseOrderId}
            </Link>
          </BreakText>
        </Tooltip>
      ),
    },
  },
  {
    width: 95,
    header: {
      content: 'Tax Docs',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <Tooltip text="Tax Commitment Documents">
          <BreakText>
            <Link onClick={dataItem.showTaxCommitmentGridModal}>Docs</Link>
          </BreakText>
        </Tooltip>
      ),
    },
  },
  {
    width: 115,
    header: {
      content: 'Marketplace profile',
    },
    cell: {
      $type: 'text',
      field: 'venueProfileName',
    },
  },
  {
    width: 125,
    header: {
      content: 'Destination',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => {
        if (
          dataItem.destinationSubdivisionFull &&
          dataItem.destinationSubdivisionFull.countryCode &&
          dataItem.destinationSubdivisionFull.countrySubdivisionCode
        ) {
          return `${dataItem.destinationSubdivisionFull.countryName} / ${dataItem.destinationSubdivisionFull.countrySubdivisionName}`
        }
        return ''
      },
    },
  },
  {
    width: 105,

    header: {
      content: 'Reconciled',
    },
    cell: BooleanCell('reconciled'),
  },
  {
    width: 110,
    header: {
      content: 'PO Created',
    },
    cell: DateCell('createdOn'),
  },
  {
    width: 110,

    header: {
      content: 'PO Closed',
    },
    cell: DateCell('closedOn'),
  },
  {
    width: 100,
    header: {
      content: 'Financial Events',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <Link to={`/r1/tax/financialEvents?purchaseOrderId=${dataItem.purchaseOrderId}`}>
          {dataItem.saleFinancialEventCount + dataItem.refundFinancialEventCount}
        </Link>
      ),
    },
  },
  {
    width: 125,

    header: {
      content: 'Order total',
    },
    cell: MoneyCell('total'),
  },
  {
    width: 125,

    header: {
      content: 'Sales tax',
    },
    cell: MoneyCell('tax'),
  },
  {
    width: 125,

    header: {
      content: 'Tax withheld',
    },
    cell: MoneyCell('taxWithheld'),
  },
  {
    width: 125,

    header: {
      content: 'Shipping cost',
    },
    cell: MoneyCell('shippingCost'),
  },
  {
    width: 125,

    header: {
      content: 'Shipping tax',
    },
    cell: MoneyCell('shippingTax'),
  },
  {
    width: 125,

    header: {
      content: 'Shipping tax withheld',
    },
    cell: MoneyCell('shippingTaxWithheld'),
  },
  {
    width: 220,

    header: {
      content: '',
    },
    cell: {
      $type: 'custom',
      renderer: dataItem => (
        <ActionsCell>
          <Button
            disabled={!dataItem.canBeSubmitted}
            size="S"
            loading={dataItem.isSubmitting}
            onClick={() =>
              dataItem.submitPurchaseOrder({
                purchaseOrderUniqueId: {
                  purchaseOrderId: dataItem.purchaseOrderId,
                  venueProfileId: dataItem.venueProfileId,
                },
                taxCommitmentDocumentIds: dataItem.taxCommitmentDocuments
                  .filter(doc => !doc.submittedOn)
                  .map(doc => doc.taxCommitmentDocumentId),
              })
            }
          >
            Submit
          </Button>
          {!dataItem.canBeSubmitted && (
            <Tooltip
              text={dataItem.submissionIssues.map(issueObj => (
                <div key={issueObj.issue.defaultFormat}>{issueObj.issue.defaultFormat}</div>
              ))}
            >
              <Icon type="info" />
            </Tooltip>
          )}
        </ActionsCell>
      ),
    },
  },
]

const tableProps = {
  displaySettings: {
    showCounter: false,
    columnsSettings,
  },
}

export function PurchaseOrdersGrid(props) {
  window.scrollBy(0, 1)
  window.scrollBy(0, -1) // Hack to trigger virtualized
  return <Table {...tableProps} data={props.data} loading={props.loading} />
}
