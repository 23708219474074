// @flow

const debounce = (func: Function, wait: number, immediate?: boolean) => {
  let timeout

  return function () {
    const callNow = immediate && !timeout
    // eslint-disable-next-line prefer-rest-params
    const callFunc = () => func.apply(this, arguments)

    const later = () => {
      timeout = null
      if (!immediate) callFunc()
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) callFunc()
  }
}

export default debounce
