import * as React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withTheme, Text } from '@r1/ui-kit'
import { actions, selectors } from '../../modules/products/categories'

function getCategoryPath(state, categoryId) {
  const item = selectors.getItem(state, { value: categoryId })

  if (!item) return ''

  if (item.parentId) return `${getCategoryPath(state, item.parentId)} ${'•'.repeat(3)} ${item.name}`

  return item.name
}

const mapStateToProps = (state, { categoryId }) => ({
  text: categoryId ? getCategoryPath(state, categoryId) : ' - ',
})

const enhance = compose(withTheme, connect(mapStateToProps, { restoreItems: actions.restoreItems }))

class CategoryComponent extends React.Component {
  componentDidMount() {
    const { categoryId, restoreItems } = this.props
    if (categoryId) restoreItems(categoryId)
  }

  componentDidUpdate(prevProps) {
    const { categoryId, restoreItems } = this.props

    if (prevProps.categoryId !== categoryId) {
      if (categoryId) restoreItems(categoryId)
    }
  }

  render() {
    const { theme } = this.props

    return (
      <Text color={theme.palette.grey[500]} type="paragraph">
        {this.props.text}
      </Text>
    )
  }
}

export const CategoryTextPath = enhance(CategoryComponent)
