// @flow

import { merge } from 'ramda'
import { differenceInCalendarDays } from 'date-fns'
import { handleServerError } from '@r1/core-blocks'
import type {
  ClaimCreationItem,
  Image,
} from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import { orderId, getClaimType } from '../../../utils'
import { createApi } from '../../../api/api'
import type { HttpClient, BusinessOrderType } from '../../../types/common'

import type { Order, OrderLine, OrderStatusInfo } from '../../../types/ClaimCreation'

// eslint-disable-next-line import/no-unused-modules
export class ApiController {
  api: any

  constructor(httpClient: HttpClient) {
    this.api = createApi(httpClient)
  }

  searchOrdersByIdentifier = async (orderType: BusinessOrderType, identifier: string) => {
    const response = await this.api.orderSearch.getOrdersByIdentifier({
      type: orderType,
      identifier,
    })

    if (response.status !== 200) {
      this.processErrorResp(response)
      return []
    }

    const orders = response.body
    return orders
  }

  getOrderStatuses = (orders: Order[]) => {
    const orderStatuses = orders.map(this.getOrderStatus)

    const map = new Map<string, OrderStatusInfo>()
    orderStatuses.forEach(orderStatus => {
      map.set(orderId(orderStatus), orderStatus)
    })
    return map
  }

  getOrderStatus = (order: Order) => {
    const statusInfo = {
      rmaProfileId: order.rmaProfileId,
      purchaseOrderId: order.purchaseOrderId,
      orderStatus: {
        status: order.info.status,
      },
    }

    if (statusInfo.orderStatus.status === 'Shipped') {
      const expiringDate = order.info.serviceCoverageExpiresOn
      if (expiringDate) {
        const diffDays = differenceInCalendarDays(new Date(expiringDate), new Date())
        if (diffDays <= 0) {
          return merge(statusInfo, {
            orderStatus: { status: 'Expired', daysAfterExpiring: -diffDays },
          })
        }
      }
    }

    return statusInfo
  }

  fetchProfiles = async (rmaProfileIds: string[]) => {
    const tasks = rmaProfileIds.map(async rmaProfileId => {
      const [rmaProfile, returnReasons, expectedDecisionTypes] = await Promise.all([
        this.fetchProfile(rmaProfileId),
        this.fetchReturnReasons(rmaProfileId),
        this.fetchExpectedDecisionTypes(rmaProfileId),
      ])
      return {
        rmaProfileId,
        rmaProfile,
        returnReasons,
        expectedDecisionTypes,
      }
    })

    const profiles = await Promise.all(tasks)

    const map = new Map<string, any>()
    profiles.forEach(profile => {
      if (!profile.rmaProfile) {
        return
      }
      map.set(profile.rmaProfileId, profile)
    })
    return map
  }

  fetchProfile = async (rmaProfileId: string) => {
    const response = await this.api.rmaProfiles.getProfileInfo({ rmaProfileId })

    if (response.status !== 200) {
      this.processErrorResp(response)
      return null
    }

    const rmaProfile = response.body
    return rmaProfile
  }

  fetchReturnReasons = async (rmaProfileId: string) => {
    const response = await this.api.rmaProfiles.getReturnReasons({ rmaProfileId })

    if (response.status !== 200) {
      this.processErrorResp(response)
      return []
    }

    const returnReasons = response.body
    return returnReasons
  }

  fetchExpectedDecisionTypes = async (rmaProfileId: string) => {
    const response = await this.api.rmaProfiles.getExpectedDecisionVariants({ rmaProfileId })

    if (response.status !== 200) {
      this.processErrorResp(response)
      return []
    }

    const expectedDecisionTypes = response.body
    return expectedDecisionTypes
  }

  fetchShippingAddresses = async (addressIds: string[]) => {
    const response = await this.api.address.searchAddressesByIds(
      { acceptLanguage: 'en' },
      { addressIds },
    )

    if (response.status !== 200) {
      this.processErrorResp(response)
      return []
    }

    const addresses = response.body.map(a => ({
      id: a.id,
      shippingAddress: this.convertAddressToString(a),
    }))

    const map = new Map<string, any>()
    addresses.forEach(address => {
      map.set(address.id, address)
    })
    return map
  }

  convertAddressToString = (address: any) => {
    const parts = []

    if (address.addressLine1) parts.push(address.addressLine1)
    if (address.addressLine2) parts.push(address.addressLine2)
    if (address.addressLine3) parts.push(address.addressLine3)

    if (address.cityName) parts.push(address.cityName)
    if (address.subdivisionCode) parts.push(address.subdivisionCode)
    if (address.postalCode) parts.push(address.postalCode)
    if (address.countryCode) parts.push(address.countryCode)

    return parts.join(', ')
  }

  createClaim = async (
    order: Order,
    orderLines: OrderLine[],
    expectedDecisionTypeId: string,
    claimDescription: string,
  ) => {
    const request = this.getClaimCreationRequest(
      order,
      orderLines,
      expectedDecisionTypeId,
      claimDescription,
    )
    const response = await this.api.claims.createClaim(request)
    if (response.status !== 200) {
      this.processErrorResp(response)
      return ''
    }

    const { claimId } = response.body
    return claimId
  }

  getClaimCreationRequest = (
    order: Order,
    orderLines: OrderLine[],
    expectedDecisionTypeId: string,
    description: string,
  ) => {
    const claimType = getClaimType(expectedDecisionTypeId)

    const request = {
      rmaProfileId: order.rmaProfileId,
      purchaseOrderId: order.purchaseOrderId,
      customerContactEmail: order.customer.email,
      description,
      expectedDecisionTypeId,
      shippingAddressId: order.shippingInfo.addressId,
      items: orderLines
        .filter(orderLine => orderLine.selected)
        .map<ClaimCreationItem>(orderLine => ({
          orderLineId: orderLine.lineId,
          returnReasonId: orderLine.returnReasonId,
          customerNote: (orderLine.notes && orderLine.notes.message) || '',
          quantity: ['RefundOnly', 'ReplacementOnly'].includes(claimType)
            ? undefined
            : orderLine.returnQuantity,
          refundAmounts:
            claimType === 'RefundOnly' ? this.getRefundAmountsForOrderLine(orderLine) : [],
          replacementUnitIds: orderLine.replacements.map(r => r.unitId),
          images: orderLine.notes.images.map<Image>(image => ({
            base64Data: image.imageData,
            format: image.fileType,
          })),
        })),
    }

    return request
  }

  getRefundAmountsForOrderLine = (orderLine: OrderLine) => {
    const zeroAmount = {
      amount: '0',
      currency: orderLine.refundAmount.currency,
    }

    return [
      {
        type: 'ProductCost',
        amount: orderLine.refundAmount,
      },
      {
        type: 'ProductTax',
        amount: zeroAmount,
      },
      {
        type: 'ShippingCost',
        amount: zeroAmount,
      },
      {
        type: 'ShippingTax',
        amount: zeroAmount,
      },
    ]
  }

  processErrorResp = (resp: any) => {
    handleServerError(resp)
  }
}

export const createApiController = (httpClient: HttpClient) => new ApiController(httpClient)
