// @flow

import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import type {
  ClaimCreationItem,
  DecisionTypeData,
} from '@r1-webui/rmavendorportal-claimmanagement-v1/src/types'
import type { Lib$Id } from '@r1/types/flow/libTypes'
import { ROUTES } from '../../../../../../navigation'
import { getClaimType } from '../../../../utils'
import type { OrderInfo, ClaimType } from '../../../../types/common'
import type { NewClaimItem } from '../../../../types/ClaimScreen'

import type { WithController } from '../types'

export class ClaimController {
  component: WithController

  constructor(component: WithController) {
    this.component = component
  }

  setExpectedDecisionTypeId = async (decisionTypeId: Lib$Id, decisionData: DecisionTypeData[]) => {
    if (this.component.state.data == null) {
      return
    }

    const { claim } = this.component.state.data

    const response = await this.component.api.claims.setExpectedDecisionTypeId(
      { claimId: claim.id },
      {
        expectedDecisionTypeId: decisionTypeId,
        data: decisionData,
      },
    )
    switch (response.status) {
      case 200:
        NotificationSystem.addNotification({
          level: 'success',
          message: 'Decision set',
        })
        break
      case 409:
        NotificationSystem.addNotification({
          level: 'error',
          message: 'Decision is already set',
        })
        break
      case 404:
        NotificationSystem.addNotification({
          level: 'error',
          message: 'Claim is not found',
        })
        break
      default:
        NotificationSystem.addNotification({
          level: 'error',
          message: 'Something went wrong. Decision was not set, try again later',
        })
    }
  }

  getClaimCreationItems = (
    order: OrderInfo,
    claimType: ClaimType,
    newClaimItems: NewClaimItem[],
  ): ClaimCreationItem[] => {
    const claimCreationItems = newClaimItems.map((item: NewClaimItem): ClaimCreationItem => ({
      orderLineId: item.orderLineId,
      returnReasonId: item.returnReasonId,
      customerNote: '',
      quantity: ['RefundOnly', 'ReplacementOnly'].includes(claimType) ? undefined : item.quantity,
      refundAmounts: claimType === 'RefundOnly' ? this.getRefundAmountsForOrderLine(item) : [],
      replacementUnitIds: [], // TODO: newClaimItem.replacements.map(r => r.unitId),
      images: [],
    }))
    return claimCreationItems
  }

  createClaim = async (
    chatMessageId: Lib$Id,
    expectedDecisionTypeId: Lib$Id,
    description: string,
    claimItems: NewClaimItem[],
  ): Promise<string | null> => {
    if (!this.component.state.data) {
      throw new Error(`Broken state: Context is empty but required for a claim creation.`)
    }
    const { order, customer, claim } = this.component.state.data
    if (!order || !customer || !claim) {
      throw new Error(
        `Broken state: Order and customer are empty but required for a claim creation.`,
      )
    }

    const claimType = getClaimType(expectedDecisionTypeId)
    const claimCreationItems = this.getClaimCreationItems(order, claimType, claimItems)
    const request = {
      rmaProfileId: order.rmaProfileId,
      purchaseOrderId: order.purchaseOrderId,
      customerContactEmail: customer.email,
      description,
      expectedDecisionTypeId,
      shippingAddressId: order.shippingAddressId,
      items: claimCreationItems,
      origin: {
        claimId: claim.id,
        chatMessageId,
      },
    }

    const response = await this.component.api.claims.createClaim(request)
    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    const { claimId: createdClaimId } = response.body

    const { businessClaimType } = this.component.props
    const path = businessClaimType === 'B2C' ? ROUTES.CLAIM_VIEW_B2C : ROUTES.CLAIM_VIEW_B2B
    const win = window.open(path.replace(':claimId', createdClaimId), '_blank')
    if (win != null) {
      win.focus()
    }

    return createdClaimId
  }

  getRefundAmountsForOrderLine = (newClaimItem: NewClaimItem) => {
    const zeroAmount = {
      amount: '0',
      currency: newClaimItem.refund.currency,
    }

    return [
      {
        type: 'ProductCost',
        amount: newClaimItem.refund,
      },
      {
        type: 'ProductTax',
        amount: zeroAmount,
      },
      {
        type: 'ShippingCost',
        amount: zeroAmount,
      },
      {
        type: 'ShippingTax',
        amount: zeroAmount,
      },
    ]
  }
}

export const createClaimController = (component: WithController) => new ClaimController(component)
