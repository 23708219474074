// @flow

import React, { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import styled from '@emotion/styled'
import { Table } from '@r1/grid'
import { Drawer, Flex, Box, Checkbox, Button, Input, Icon, Text } from '@r1/ui-kit'
import type { AttributeDefinition } from '@r1-webui/productcatalog-attributedefinitions-v1/src/types'

import { getAttributeValueTypeText } from '../module/attributeViewMapper'

type BindingAttributeDrawerProps = {|
  attributes: Array<AttributeDefinition>,
  attributesIsLoading?: boolean,
  onAddAttributes: (ids: Array<string>) => void,
  show: boolean,
  onClose: () => void,
  isSubmitting?: boolean,
|}

const NotFoundText = styled('div')`
  color: ${({ theme }) => theme.palette.grey[800]};
  width: 312px;
  text-align: center;
`

const FlexWrapper = styled(Flex)`
  height: 100%;
`

const Footer = styled(Flex)`
  width: 100%;
`

const HomeImage = styled('img')`
  padding-top: 88px;
  object-position: center;
  object-fit: scale-down;
`

const getTableSettings = ({ onSelect, onSelectAll, checkedAll, indeterminateAll }) => ({
  displaySettings: {
    columnsSettings: [
      {
        width: 60,
        header: {
          content: indeterminateAll ? (
            <Checkbox indeterminate onChange={onSelectAll} />
          ) : (
            <Checkbox checked={checkedAll} onChange={onSelectAll} />
          ),
        },
        cell: {
          $type: 'custom',
          renderer: ({ id, selected }) => <Checkbox checked={selected} onChange={onSelect(id)} />,
        },
      },
      {
        header: {
          content: 'Title',
        },
        cell: { $type: 'text', field: 'name' },
      },
      {
        header: {
          content: 'Type',
        },
        cell: {
          $type: 'custom',
          renderer: ({ valueType }) => getAttributeValueTypeText(valueType),
        },
      },
    ],
  },
})

export const BindingAttributeDrawer = (props: BindingAttributeDrawerProps) => {
  const { attributesIsLoading, attributes, onClose, isSubmitting, onAddAttributes, show } = props
  const [searchValue, setSearchValue] = useState('')
  const [filteredAttributes, setFilteredAttributes] = useState(attributes)
  const [selectedAttributes, setSelectedAttributes] = useState([])

  useEffect(() => {
    setFilteredAttributes(attributes)
  }, [attributes])

  const debouncedSearch = debounce((value: string) => {
    let filtered = attributes
    if (value.length > 0) {
      filtered = attributes.filter(({ name, type }) => {
        const search = new RegExp(value, 'gi')
        return !!name.match(search) || !!type.match(search)
      })
    }
    setFilteredAttributes(filtered)
  }, 500)

  const onSelect = id => checked =>
    setSelectedAttributes(
      checked ? [...selectedAttributes, id] : selectedAttributes.filter(val => val !== id),
    )

  const onSelectAll = checked => {
    const visibleIds = filteredAttributes.map(({ id }) => id)
    if (checked) {
      setSelectedAttributes([...selectedAttributes, ...visibleIds])
    } else {
      setSelectedAttributes(selectedAttributes.filter(val => !visibleIds.includes(val)))
    }
  }

  const onSearch = value => {
    setSearchValue(value)
    debouncedSearch(value)
  }

  let checkedAll = true
  let indeterminateAll = false

  if (filteredAttributes.length > 0) {
    filteredAttributes.forEach(({ id }) => {
      if (selectedAttributes.includes(id)) indeterminateAll = true
      else checkedAll = false
    })
  } else {
    checkedAll = false
  }
  if (checkedAll) indeterminateAll = false

  return (
    <Drawer.Form
      closeButton
      title="Add Attributes"
      show={show}
      placement="right"
      size={704}
      backdrop="closing"
      footer={
        <Footer justify="flex-end" spaceBetween="S">
          <Button transparent color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isSubmitting} onClick={() => onAddAttributes(selectedAttributes)}>
            Add
          </Button>
        </Footer>
      }
      onClose={onClose}
    >
      <FlexWrapper column>
        <Box pb="M" px="XXL">
          <Input
            placeholder="Search"
            after={<Icon type="search" />}
            value={searchValue}
            onChange={onSearch}
          />
        </Box>
        {attributes.length === 0 ? (
          <Box px="XXL" py="XS" alignSelf="center">
            <Flex column>
              {/* remove 'ui-static' in dev mode */}
              <HomeImage src="/ui-static/assets/launch_not_found.png" />
              <NotFoundText>
                <Text type="paragraph">
                  Oops, we have not found any available attributes for you
                </Text>
              </NotFoundText>
            </Flex>
          </Box>
        ) : (
          <Box px="XXL" py="XS">
            <Table
              height={500}
              {...getTableSettings({
                onSelect,
                onSelectAll,
                checkedAll,
                indeterminateAll,
              })}
              loading={attributesIsLoading}
              data={filteredAttributes.map(value => ({
                ...value,
                selected: selectedAttributes.includes(value.id),
              }))}
            />
          </Box>
        )}
      </FlexWrapper>
    </Drawer.Form>
  )
}
