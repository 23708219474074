export const COOKIE_LIST = [
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'username',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'userCurrency',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'userEmail',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'userId',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'thorRefreshToken',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'refreshToken',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'accessExpirationTime',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'userFullName',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'UserPrincipalId',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'ASP.NET_SessionId',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'thorAccessToken',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'refreshExpirationTime',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'ITEMS_PER_PAGE_COOKIE',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'refreshTime',
  },
  {
    group: 'essential' as const,
    party: 'firstParty' as const,
    owner: 'sys.therecongroup.com',
    name: 'accessToken',
  },
  {
    group: 'performance' as const,
    party: 'thirdParty' as const,
    owner: 'Google analytics',
    name: '_ga_QPSJD4JHBG',
    privacyPolicyLink: 'https://support.google.com/analytics/answer/6004245',
  },
  {
    group: 'performance' as const,
    party: 'thirdParty' as const,
    owner: 'Google analytics',
    name: '_ga',
    privacyPolicyLink: 'https://support.google.com/analytics/answer/6004245',
  },
]
