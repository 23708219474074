// @flow

import * as React from 'react'
import { Flex, FormField, HR } from '@r1/ui-kit'
import { Text } from '../Text'
import { NumberRange } from '../../../../utils/utils'
import type { NumberRangeConstraint as NumberRangeConstraintValue } from '../types'

type Props = {| value: NumberRangeConstraintValue |}

export const NumberRangeConstraint = ({ value }: Props) =>
  (value.minAllowedValue || value.maxAllowedValue) && (
    <Flex column spaceBetween="S">
      <HR />
      <Flex spaceBetween="XXS">
        <FormField.Label>Allowed Numbers</FormField.Label>
        <Text>
          <NumberRange from={value.minAllowedValue} to={value.maxAllowedValue} />
        </Text>
      </Flex>
    </Flex>
  )
